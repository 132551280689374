import { defineStore } from "pinia";
import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import DateHelper from "@/helpers/DateHelper";
import { nextTick } from "vue";
import CustomWindow from "../CustomWindow";
import SemanticsBackupState from "./states/SemanticsBackupState";
import { SemanticsBackupEntity } from "@/models/nav-tree/SemanticsBackupEntity";
import { RestoreSemanticsBackupOpitions } from "@/models/nav-tree/RestoreSemanticsBackupOpitions";

declare const window: CustomWindow;

function strToDate(model: SemanticsBackupEntity): void {
  if (typeof model.DateCreated === "string") {
    model.DateCreated = DateHelper.parseFromMicrosoftString(model.DateCreated);
  }
}

export const useSemanticsBackupStore = defineStore("semanticsBackup", {
  state: (): SemanticsBackupState => ({
    isLoaded: false,
    data: [] as SemanticsBackupEntity[],
    guid: "",

    inProgressCreateBackup: false,
    inProgressDeleteBackup: false,
    inProgressRestoreBackup: false
  }),

  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        const url = `rest/BitPool_V2/semantics-backup`;
        const response = await axios.get<SemanticsBackupEntity[]>(url);
        if (this.guid === guid) {
          response.data.forEach(element => {
            strToDate(element);
          });
          this.data = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load Semantics backups",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = [];
        this.isLoaded = true;
      }
    },
    async createBackup() {
      try {
        this.inProgressCreateBackup = true;
        const url = `rest/BitPool_V2/semantics-backup/create`;
        const response = await axios.post<SemanticsBackupEntity>(url, null);
        strToDate(response.data);
        this.data.splice(0, 0, response.data);
        this.inProgressCreateBackup = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't create Semantics backup",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressCreateBackup = false;
      }
    },
    async deleteBackup(id: string) {
      try {
        this.inProgressDeleteBackup = true;
        const url = `rest/BitPool_V2/semantics-backup/${id}/delete`;
        await axios.delete(url);
        for (let i = this.data.length - 1; i >= 0; --i) {
          if (this.data[i].Id === id) {
            this.data.splice(i, 1);
            break;
          }
        }
        this.inProgressDeleteBackup = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete Semantics backup",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressDeleteBackup = false;
      }
    },
    async restoreBackup(id: string, options: RestoreSemanticsBackupOpitions) {
      try {
        this.inProgressRestoreBackup = true;
        const url = `rest/BitPool_V2/semantics-backup/${id}/restore`;
        await axios.post(url, options);
        this.inProgressRestoreBackup = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
        window.vuexStore?.commit("auth/setOrganisationChangingInProgress", true, { root: true });
        await nextTick();
        window.vuexStore?.commit("auth/setOrganisationChangingInProgress", false, { root: true });
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't restore Semantics backup",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressRestoreBackup = false;
      }
    }
  },
});