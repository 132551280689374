import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { GenericTree } from "@/models/tree/GenericTree";
import { Stat } from '@he-tree/tree-utils';

class TreeHelper {
  search(nodes: Stat<TreeNodeForUI>[], search: string, tags: string[] | undefined = undefined): void {
    nodes.forEach(node => {
      node.hidden = false;
    });
    if (search || tags?.length) {
      let chain: Stat<TreeNodeForUI>[] = [];
      // this array will help to hide node siblings
      let visibilityMap: boolean[] = [];
      nodes.forEach(node => {
        if (node.level === 1) {
          chain = [node];
          visibilityMap = [false];
        } else {
          const lastLevel = chain[chain.length - 1].level;
          if (lastLevel === node.level) {
            chain[chain.length - 1] = node;

            visibilityMap[visibilityMap.length - 1] = false;
          } else if (lastLevel < node.level) {
            chain.push(node);

            visibilityMap.push(false);
          } else {
            const levelsToDelete = lastLevel - node.level;
            chain.splice(chain.length - levelsToDelete, levelsToDelete);
            chain[chain.length - 1] = node;

            visibilityMap.splice(visibilityMap.length - levelsToDelete, levelsToDelete);
            visibilityMap[visibilityMap.length - 1] = false;
          }
        }
        const parentIsVisible = visibilityMap.length > 1 && visibilityMap[visibilityMap.length - 2];
        if (parentIsVisible) {
          node.hidden = false;

          visibilityMap[visibilityMap.length - 1] = true;
        } else {
          const searchLower = search.toLocaleLowerCase();
          if ((node.data.label?.toLocaleLowerCase().includes(searchLower) || node.data.key?.toLocaleLowerCase().includes(searchLower)) && 
            (!tags?.length || tags.every(tag => {
              const tagsOr = tag.split("|");
              for (let i = 0; i < tagsOr.length; i++) {
                const tagOr = tagsOr[i];
                const tagsAnd = tagOr.split("&");
                if (tagsAnd.every(x => node.data.tags?.includes(x))) {
                  return true;
                }
              }
              return false;
            }))) {
            chain.forEach(element => {
              element.hidden = false;
            });
  
            visibilityMap[visibilityMap.length - 1] = true;
          } else {
            node.hidden = true;
          }
        }
      });
    }
  }

  searchGeneric<Type>(nodes: Stat<GenericTree<Type>>[], search: string): void {
    nodes.forEach(node => {
      node.hidden = false;
    });
    if (search ) {
      let chain: Stat<GenericTree<Type>>[] = [];
      // this array will help to hide node siblings
      let visibilityMap: boolean[] = [];
      nodes.forEach(node => {
        if (node.level === 1) {
          chain = [node];
          visibilityMap = [false];
        } else {
          const lastLevel = chain[chain.length - 1].level;
          if (lastLevel === node.level) {
            chain[chain.length - 1] = node;

            visibilityMap[visibilityMap.length - 1] = false;
          } else if (lastLevel < node.level) {
            chain.push(node);

            visibilityMap.push(false);
          } else {
            const levelsToDelete = lastLevel - node.level;
            chain.splice(chain.length - levelsToDelete, levelsToDelete);
            chain[chain.length - 1] = node;

            visibilityMap.splice(visibilityMap.length - levelsToDelete, levelsToDelete);
            visibilityMap[visibilityMap.length - 1] = false;
          }
        }
        const parentIsVisible = visibilityMap.length > 1 && visibilityMap[visibilityMap.length - 2];
        if (parentIsVisible) {
          node.hidden = false;

          visibilityMap[visibilityMap.length - 1] = true;
        } else {
          const searchLower = search.toLocaleLowerCase();
          if (node.data.label?.toLocaleLowerCase().includes(searchLower) || node.data.key?.toLocaleLowerCase().includes(searchLower)) {
            chain.forEach(element => {
              element.hidden = false;
            });
  
            visibilityMap[visibilityMap.length - 1] = true;
          } else {
            node.hidden = true;
          }
        }
      });
    }
  }
}

export default new TreeHelper();