import { ColorTheme } from "@/models/ColorTheme";
import { contrastColor } from 'contrast-color';
import CustomWindow from "../CustomWindow";

declare const window: CustomWindow;

class ColorHelper {
  private themes: ColorTheme[] = [
    {
      name: "Bitpool",
      description: "",
      background: "#fff",
      colors: [
        "#3A3D42",
        "#00B1F0",
        "#d7d7d7",
        "#ade039",
        "#de4f61",
        "#a7a9ac",
        "#9ce1f9",
        "#f4777c",
        "#0f1f41",
        "#c0812a",
        "#f47820",
        "#f46423",
        "#f53619",
        "#ff6651",
        "#2f4d8e",
        "#211608",
      ],
      title1: "#646464",
      text1: "#646464",
    },
    {
      name: "PacificSunset",
      description: "",
      background: "#fff",
      colors: [
        "#1F4199",
        "#8DE8FF",
        "#C6C8CC",
        "#FF691D",
        "#CC3917",
        "#686a70",
        "#c3dfe5",
        "#10214d",
        "#BDDFB3",
        "#2BAA9C",
        "#2F2E2E",
        "#0F2625",
        "#465F3F",
        "#6abfb6",
        "#828282",
        "#8dbc80",
      ],
      title1: "#646464",
      text1: "#646464",
    },
    {
      name: "MorningSun",
      description: "",
      background: "#fff",
      colors: [
        "#0f1f41",
        "#c0812a",
        "#f47820",
        "#f46423",
        "#f53619",
        "#ff6651",
        "#2f4d8e",
        "#211608",
      ],
      title1: "#0f1f41",
      text1: "#0f1f41",
    },
    {
      name: "VintageGreen",
      description: "",
      background: "#fff",
      colors: [
        "#BDDFB3",
        "#2BAA9C",
        "#2F2E2E",
        "#0F2625",
        "#465F3F",
        "#6abfb6",
        "#828282",
        "#8dbc80",
      ],
      title1: "#0f1f41",
      text1: "#0f1f41",
    },
    {
      name: "ProcurementGreens",
      description: "",
      background: "#fff",
      colors: [
        "#5FADB4",
        "#FFFFFF",
        "#AEC7BA",
        "#4A7061",
        "#234F33",
        "#bfbfbf",
        "#30575a",
        "#dfe9e3",
      ],
      title1: "#646464",
      text1: "#646464",
    },
    {
      name: "FrankyDemon",
      description: "",
      background: "#fff",
      colors: [
        "#22475E",
        "#75B08A",
        "#F0E797",
        "#FF9D84",
        "#FF5460",
        "#2d7aab",
        "#7ecca7",
        "#c8c39a",
      ],
      title1: "#0f1f41",
      text1: "#0f1f41",
    },
    {
      name: "TypeColors",
      description: "",
      background: "#fff",
      colors: [
        "#A9A9A9",
        "#888888",
        "#696969",
        "#4A8AC2",
        "#94BE43",
        "#F5BB4F",
        "#FFF373",
        "#DC4949",
      ],
      title1: "#0f1f41",
      text1: "#0f1f41",
    },
    {
      name: "SituationIndicators",
      description: "",
      background: "#fff",
      colors: [
        "#FFFFFF",
        "#888888",
        "#000000",
        "#0468BF",
        "#0BD904",
        "#F29F05",
        "#FFE100",
        "#D90404",
      ],
      title1: "#0f1f41",
      text1: "#0f1f41",
    },
    {
      name: "AustralandTheme",
      description: "",
      background: "#fff",
      colors: [
        "#CA003A",
        "#A51030",
        "#800E28",
        "#F39501",
        "#E5441A",
        "#D6001A",
        "#3C3941",
        "#19171C",
      ],
      title1: "#0f1f41",
      text1: "#0f1f41",
    },
  ];
  getThemes(): ColorTheme[] {
    return this.themes;
  }
  getDefautTextColor(): string {
    const isDark = !!(window.vuexStore?.state as any).auth.userSettings.darkTheme;
    return isDark ? this.defaultLightFontColor : this.defaultDarkFontColor;
  }
  getDefaultContrastColor(): string {
    const isDark = !!(window.vuexStore?.state as any).auth.userSettings.darkTheme;
    return isDark ? this.defaultDarkFontColor : this.defaultLightFontColor;
  }
  get defaultDarkFontColor(): string {
    return "#333";
  }
  get defaultLightFontColor(): string {
    return "#FFFFFF";
  }
  getContrastColor(backgroundColor: string): string {
    const isDark = !!(window.vuexStore?.state as any).auth.userSettings.darkTheme;
    const bg = backgroundColor ? backgroundColor : (isDark ? this.defaultDarkFontColor : this.defaultLightFontColor);
    return contrastColor.call({}, { bgColor: bg, fgDarkColor: this.defaultDarkFontColor, fgLightColor: this.defaultLightFontColor });
  }
  random(): string {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }
}

export default new ColorHelper();
