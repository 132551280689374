import { defineStore } from "pinia";
import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { NubeIOConfig } from "@/models/NubeIOConfig";
import NubeIOClientState from "./states/NubeIOClientState";

const useNubeIOClientStore = defineStore("nubeIOClient", {
  state: (): NubeIOClientState => ({
    configs: [] as NubeIOConfig[],
    isLoaded: false,
    updateInProgress: false,
    updateError: false,
    testInProgress: false,
    testError: false,
  }),
  actions: {
    async load() {
      try {
        this.isLoaded = false;
        const url = `rest/nube-io/client`;
        const response = await axios.get<NubeIOConfig[]>(url);
        this.configs = response.data;
        this.isLoaded = true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load Nube iO client config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.configs = [];
        this.isLoaded = true;
      }
    },
    async createUpdate(body: NubeIOConfig) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/nube-io/client`;
        const response = await axios.post<NubeIOConfig>(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.updateInProgress = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
        if (body.Id) {
          const index = this.configs.findIndex((x) => x.Id === body.Id);
          if (index >= 0) {
            this.configs[index] = response.data;
          }
        } else {
          this.configs.push(response.data);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save Nube iO client config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateInProgress = false;
        this.updateError = true;
      }
    },
    async createUpdateAndImport(body: NubeIOConfig) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        
        const urlImport = `rest/nube-io/client/import-structure`;
        const importResponse = await axios.post<number>(urlImport, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });

        const url = `rest/nube-io/client`;
        const response = await axios.post<NubeIOConfig>(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.updateInProgress = false;
        ToastService.showToast("success", "", `Changes saved, ${importResponse.data} nodes imported`, 5000);
        if (body.Id) {
          const index = this.configs.findIndex((x) => x.Id === body.Id);
          if (index >= 0) {
            this.configs[index] = response.data;
          }
        } else {
          this.configs.push(response.data);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save Nube iO client config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateInProgress = false;
        this.updateError = true;
      }
    },
    async test(body: NubeIOConfig) {
      try {
        this.testInProgress = true;
        this.testError = false;
        const url = `rest/nube-io/client/test`;
        await axios.post(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.testInProgress = false;
        ToastService.showToast("success", "", "Valid endpoint", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.testInProgress = false;
        this.testError = true;
      }
    },
    async delete(id: string) {
      try {
        const url = `rest/nube-io/client/${id}`;
        await axios.delete(url);
        ToastService.showToast("success", "", "Changes saved", 5000);
        for (let i = this.configs.length - 1; i >= 0; --i) {
          if (this.configs[i].Id === id) {
            this.configs.splice(i, 1);
            break;
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete Nube iO client config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
  },
});

export default useNubeIOClientStore;