<template>
  <Dialog header="Add Tag" v-model:visible="displayAddTagDialog" :modal="true" :style="{width: '36rem'}" >
    <div class="dialog-content">
      <div class="flex align-items-center column-gap-2">
        <AutoComplete 
          v-model="addFieldValue" 
          dropdown 
          optionLabel="label" 
          :optionGroupLabel="autocompleteWithGroups ? 'label' : ''" 
          :optionGroupChildren="autocompleteWithGroups ? 'items' : ''"
          :suggestions="filteredCompatibleFields"
          :virtualScrollerOptions="{ itemSize: 38 }"
          @complete="filterCompatibleFields"
          :id="`${inputId}-add-tag`"
          class="flex-auto"
        >
          <template #optiongroup="slotProps">
            <div class="flex align-items-center font-bold">
              <i class="pi pi-folder font-bold mr-2 flex-shrink-0"></i>
              <div class="flex-auto">{{ slotProps.option.label }}</div>
            </div>
          </template>
        </AutoComplete>
      </div>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" @click="closeAddTagDialog" class="p-button-text p-button-secondary"/>
      <Button label="Add" icon="pi pi-check" @click="addField" :disabled='disabledFields.includes(addFieldValueStr)' />
    </template>
  </Dialog>
  <div v-if="!externalTopControls" class="semantics-config-search-add-field col-12" :class="{ 'semantics-config-search-add-field-child': !isFirstLevel}">
    <!-- Search -->
    <div class="semantics-config-search px-1" v-if="isFirstLevel">
      <IconField iconPosition="left" class="search-input-box">
        <InputIcon class="pi pi-search "></InputIcon>
        <InputText
          class="inputfield"
          placeholder="Search"
          type="text"
          v-model="search"
          @input="debounceSearch()"
        />
      </IconField>
    </div>

    <!-- Add Field -->
    <div class="semantics-config-add-field" :class="{ 'semantics-config-add-field-child flex-auto': !isFirstLevel}">
      <Button label="Add Tag" icon="pi pi-plus" class="p-button-secondary" @click="openAddTagDialog"/>
    </div>
  </div>

  <!-- Fields -->
  <div class="field col-12 semantics-tags-field" v-for="key in editableSortedKeys" :key="key">
    <div class="semantics-tags-field-inner">
      <div class="flex justify-content-between align-items-center column-gap-2">
        <div class="flex align-items-center mr-2 with-inline-btn semantics-tags-field-header">       
          <h5
            class="flex-auto semantics-tags-field-title"
          >
            {{ key }}
          </h5>
          <span
            v-tippy="findFieldDescription(key)"
            class="p-button p-component p-button-icon-only p-button-rounded p-button-outlined"
          >
            <i class="pi pi-info"></i>
          </span>
        </div>
        <Button 
            v-tippy="'Delete'"
            icon="pi pi-trash" 
            class="semantics-tags-control-btn p-button-icon-only p-button-rounded p-button-danger p-button-outlined flex-shrink-0" 
            @click="deleteFieldFromHayson(key)" 
            :disabled="mandatoryFields.includes(key) || disabledFields.includes(field)"
          />
      </div>

      <div>
        <div class="formgrid grid pt-2 row-gap-2">
          <HaystackValueEditView
            v-model="valueHayson" 
            :field="key" 
            :inputId="`${idPrefix}-edit-${key}`"
            :def="def"
            :disabledFields="disabledFields"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonDict, Kind, HDict, HMarker } from "haystack-core";
import HaystackDefsService from '@/services/HaystackDefsService';
import ToastService from '@/services/ToastService';
import ErrorHelper from '@/helpers/ErrorHelper';
import { useHaystackDefsStore } from '@/stores/haystackDefs';
import { HaystackDefsEntity } from '@/models/nav-tree/HaystackDefsEntity';
import { DefineComponent, defineAsyncComponent } from 'vue';
import { AutoCompleteModel } from '@/models/nav-tree/AutoCompleteModel';
import { useTagManagerStore } from '@/stores/tagManager';
import { debounce } from 'throttle-debounce';
// using import as any to avoid circular dependency
//import HaystackValueEditView from "@/components/views/tags/haystack-edit/HaystackValueEditView.vue";
const HaystackValueEditView = defineAsyncComponent<DefineComponent<any>>(() => import("./HaystackValueEditView.vue") as any);

@Component({
  components: {
    AutoComplete,
    Button,
    InputText,
    Dialog,
    IconField,
    InputIcon,
    HaystackValueEditView
  },
})
class HaystackDictEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  
  @Prop idPrefix!: string;
  @Prop({ default: [] }) excludeFields!: string[];
  @Prop({ default: [] }) mandatoryFields!: string[];
  @Prop({ default: [] }) disabledFields!: string[];
  @Prop({ default: false }) isLib!: [];
  @Prop({ default: false }) allowAny!: boolean;
  @Prop({ default: false }) allowRef!: boolean;
  @Prop({ default: false }) parentList!: boolean;
  @Prop({ default: false }) isFirstLevel!: boolean;
  @Prop({ default: false }) externalTopControls!: boolean;

  
  haystackDefsStore = useHaystackDefsStore();
  tagManagerStore = useTagManagerStore();

  get valueHayson(): HaysonDict {
    if (this.haysonDictModel) {
      return this.haysonDictModel[this.field] as HaysonDict;
    }
    return {};
  }

  set valueHayson(value: HaysonDict) {
    if (this.haysonDictModel) {
      this.haysonDictModel[this.field] = value;
    }
  }

  get keys(): string[] {
    const result: string[] = [];
    const haysonDict = this.valueHayson;
    for (const key in haysonDict) {
      result.push(key);
    }
    return result;
  }

  findFieldDescription(field: string): string {
    return HaystackDefsService.findFieldDescription(field);
  }

  // #region search
  search = '';
  searchFinal = '';

  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }
  // #endregion search

  // #region Dict
  // hashmap of name/value pairs
  addedFields: string[] = [];

  deleteFieldFromHayson(def: string): void {
    delete this.valueHayson[def];
    const index = this.addedFields.findIndex(x => x === def);
    if (index > -1) {
      this.addedFields.splice(index, 1);
    }
  }

  addFieldToHayson(def: string, value: any): void {
    this.valueHayson[def] = value;
    this.addedFields.push(def);
  }

  addFieldValue: string | AutoCompleteModel = "";
  get addFieldValueStr(): string {
    if (this.addFieldValue) {
      if (typeof this.addFieldValue === "string") {
        return this.addFieldValue;
      } else {
        return this.addFieldValue.label;
      }
    }
    return "";
  }

  displayAddTagDialog = false;

  openAddTagDialog(): void {
    this.search = "";
    this.searchFinal = "";
    this.displayAddTagDialog = true;
  }

  closeAddTagDialog(): void {
    this.displayAddTagDialog = false;
  }

  addField(): void {
    const def = this.addFieldValueStr;
    let isOk = false;
    try {
      // check for site, space, equip, point markers
      const entitiesToCheck = ["site", "space", "equip", "point"];
      const defs = HaystackDefsService.getDefs();
      const children = def.includes(",") ? def.split(",").map(x => x.trim()) : [];
      const childrenNames = children.map(x => x.split("=")[0]);
      if (defs && (this.compatibleFields.find(x => x.Def === def) || childrenNames.length)) {
        const implementation = childrenNames.length ? childrenNames.flatMap(x => defs.implementation(x)) : defs.implementation(def);
        implementation.forEach(element => {
          if (this.keys.find(x => entitiesToCheck.includes(x)) && entitiesToCheck.includes(element.defName)) {
            if (!this.keys.find(x => x === element.defName)) {
              throw new Error(`Field ${element.defName} is not compatible`);
            }
          }
        });
        implementation.forEach(element => {
          if (!this.keys.find(x => x === element.defName)) {
            if (HaystackDefsService.isChoice(element)) {
              // choice is not supported by defs.newDict
              this.addFieldToHayson(element.defName, "");
            } else {
              const childrenValue = children.find(x => x.startsWith(`${element.defName}=`));
              if (typeof childrenValue === "undefined") {
                const newDict = defs.newDict([element.defName]);
                const haysonVal = newDict.toJSON();
                this.addFieldToHayson(element.defName, haysonVal[element.defName]);
              } else {
                const temp = HaystackDefsService.tagStringToHaysonVal(childrenValue);
                if (temp) {
                  this.addFieldToHayson(temp[0], temp[1]);
                }
              }
            }
            isOk = true;
          }
        });
        if (isOk) {
          this.closeAddTagDialog();
        } else {
          ToastService.showToast("warn", "", "Nothing to add", 5000);
        }
      } else if (this.allowAny) {
        const implementation = def.split("-");
        implementation.forEach(element => {
          if (this.keys.find(x => entitiesToCheck.includes(x)) && entitiesToCheck.includes(element)) {
            if (!this.keys.find(x => x === element)) {
              throw new Error(`Field ${element} is not compatible`);
            }
          }
          const errorMessage = this.validateTag(element);
          if (errorMessage) {
            throw new Error(errorMessage);
          }
        });
        implementation.forEach(element => {
          if (element) {
            if (!this.keys.find(x => x === element)) {
              this.addFieldToHayson(element, "");
              isOk = true;
            }
          }
        });
        if (isOk) {
          this.closeAddTagDialog();
        } else {
          ToastService.showToast("warn", "", "Nothing to add", 5000);
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't add field",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  }

  validateTag(tag: string): string {
    return HaystackDefsService.validateTag(tag);
  }

  filteredCompatibleFields: AutoCompleteModel[] = [];
  autocompleteWithGroups = false;

  filterCompatibleFields(event: AutoCompleteCompleteEvent): void {
    const query = event.query ? event.query.toLowerCase() : "";
    let recommended: string[] = [];
    let result = (event.query ? this.compatibleFields.filter(x => x.Def.toLowerCase().includes(query)) : this.compatibleFields).map(x => x.Def);
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      if (this.keys.includes("def")) {
        // recommended for defentions
        recommended = defs.tags("def").map(x => x.defName).filter(x => !this.keys.includes(x));
      } else {
        const entityTypes = ["point", "equip", "space", "site"];
        const entityType = entityTypes.find(x => this.keys.includes(x));
        const recommendedSet: Set<string> = new Set<string>();
        // try to extract recommendations from name
        const name = this.valueHayson["dis"];
        if (name && typeof name === "string") {
          const parts = name.split(/[-_,.: ]+/);
          for (const part of parts) {
            // just add, we will check if it is a tag later. Look at filteredRecommended.
            recommendedSet.add(part);
          }
        }
        // recommended for entities
        const allEntitySubTypes = defs.allSubTypesOf("entity").filter(x => !x.defName.includes("-"));
        for (const key of this.keys) {
          const subtype = allEntitySubTypes.find(x => x.defName === key)
          if (subtype) {
            const currentRecommended = defs.tags(key).map(x => x.defName).filter(x => !this.keys.includes(x));
            currentRecommended.forEach(x => recommendedSet.add(x));
          }
        }
        // try to extract recommendations from parent
        if (this.tagManagerStore.activeParent) {
          const parent = this.tagManagerStore.activeParent;
          const parentTags = parent.data.tags ?? [];
          // only markers
          let markers: string[] = [];
          for (const tag of parentTags) {
            if (!tag.includes("=")) {
              markers.push(tag);
            }
          }  
          if (markers.length) {
            // let's try to add conjuncts
            const conjuncts: HDict[] = [];
            HaystackDefsService.findConjuncts(markers, conjuncts);
            if (conjuncts.length) {
              markers = HaystackDefsService.hDictArrayToStringArray(conjuncts).concat(markers);
            }
            // let's create HDict and find protos
            const obj: Record<string, any> = {};
            for (const marker of markers) {
              obj[marker] = HMarker.make()
            }
            const parentHDict = HDict.make(obj);
            const protos = defs.protos(parentHDict);
            if (protos.length) {
              const hs = protos.map(x => x.toJSON());
              for (const proto of hs) {
                // don't add if entity is not same
                const children = Object.keys(proto);
                if (!children.some(x => this.disabledFields.includes(x))) {
                  const childrenEntityType = entityTypes.find(x => children.includes(x));
                  if ((!childrenEntityType || childrenEntityType === entityType) && !children.every(x => this.keys.includes(x))) {
                    const childrenStr = Object.keys(proto).map(x => { 
                      const value = proto[x];
                      if (typeof value === "object") {
                        // marker
                        return x;
                      } else {
                        return `${x}=${value}`;
                      }
                    }).join(", ");
                    if (!query || childrenStr.includes(query)) {
                      recommendedSet.add(childrenStr);
                    }
                  }
                }
              }
            }
          }        
        }

        recommended = Array.from(recommendedSet);
      }
    }
    if (recommended.length) {
      const filteredRecommended = [];
      for (const tag of result) {
        if (recommended.some(x => x.toLowerCase() === tag.toLowerCase())) {
          filteredRecommended.push(tag);
        }
      }
      const children = recommended.filter(x => x.includes(","));
      if (children.length) {
        children.forEach(x => filteredRecommended.push(x));
      }
      recommended = filteredRecommended;
    }
    if (recommended.length) {
      result = result.filter(x => !recommended.includes(x));
      this.filteredCompatibleFields = [
        { label: "Recommended", items: recommended.map(x => { return { label: x, items: [] }})},
        { label: "All", items: result.map(x => { return { label: x, items: [] }})}
      ];
      this.autocompleteWithGroups = true;
    } else {
      this.filteredCompatibleFields = result.map(x => { return { label: x, items: [] }});
      this.autocompleteWithGroups = false;
    }
  }

  get compatibleFields(): HaystackDefsEntity[] {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      if (this.haystackDefsStore.data?.length) {
        const entities: HaystackDefsEntity[] = [];
        if (this.isLib) {
          const libFields = ["baseUri", "def", "depends", "doc", "is", "lib", "version", "wikipedia"];
          libFields.forEach(field => {
            if (this.haystackDefsStore.data?.length) {
              if (!this.keys.some(x => x === field) && !this.disabledFields.includes(field)) {
                const defEntity = this.haystackDefsStore.data.find(x => x.Def === field);
                if (defEntity) {
                  entities.push(defEntity);
                }
              }
            }
          });
        } else {
          this.haystackDefsStore.data.forEach(defEntity => {
            if (!this.keys.some(x => x === defEntity.Def) && 
              (this.allowRef || !defEntity.Def.endsWith("Ref")) && 
              (this.allowRef || !defEntity.Def.includes("Ref-")) &&
              !this.disabledFields.includes(defEntity.Def)) {
              const kind = defs.defToKind(defEntity.Def);
              if (this.allowRef || kind !== Kind.Ref) {
                entities.push(defEntity);
              }
            }
          });
        }
        return entities;
      }
    }
    return [];
  }

  get editableSortedKeys(): string[] {
    const result: Set<string> = new Set<string>();
    for (let index = this.addedFields.length - 1; index >= 0; index--) {
      if (this.keys.includes(this.addedFields[index])) {
        result.add(this.addedFields[index]);
      }
    }
    for (const key of this.keys) {
      result.add(key);
    }
    const array = Array.from(result);
    const searchLower = this.searchFinal.toLowerCase();
    return array.filter(item => !this.excludeFields.includes(item) && item.toLowerCase().includes(searchLower));
  }
  // #endregion Dict
}

export default HaystackDictEditView;
</script>