<template>
  <div class="he-tree-with-checkbox">
    <div class="he-tree-search">
      <InputText
        :placeholder="placeholder"
        type="text"
        v-model="search"
        @input="debounceSearch()"
      />
      <span class="pi pi-search"></span>
    </div>
    <div class="he-tree-wrapper">
      <BaseTree 
        v-model="nodes" 
        :defaultOpen="false"
        :statHandler="statHandler"
        textKey="label"
        :indent="22"
        :virtualizationPrerenderCount="50" 
        :watermark="false" 
        ref="tree"
        virtualization 
      >
        <template #default="{ node, stat }">
          <Button 
              :icon="stat.open ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" 
              text 
              rounded
              @click="toggleNode(stat)" v-if="node.children?.length"
              class="p-link"
          />
          <div 
            :class="{ 'tree-node-selected': stat.checkedSingle, 'tree-node-selectable': node.selectable, 'tree-node-without-children': !node.children?.length }"
            @contextmenu="openMenu($event, stat)"
            @touchstart="touchStartContextMenu($event, stat)"
            @touchmove="touchMoveContextMenu($event, stat)"
            @touchend="touchEndContextMenu($event, stat)"
          >
            <Checkbox v-model="stat.checkedSingle" :binary="true" @change="checkedIsChanged(stat)" v-if="node.selectable"/>
            <span  
              @click="itemSelected(stat)"
              class="flex align-items-center"
            >
              <span v-if="node.icon" :class="node.icon" class="tree-node-icon"></span>
              <span class="tree-node-label">{{ node.label }}</span>
            </span>
          </div>
        </template>
      </BaseTree>
    </div>
    <ContextMenu ref="menu" :model="menuItems" class="tree-context-menu" />
  </div>
</template>

<script lang="ts">
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import ContextMenu from 'primevue/contextmenu';
import { MenuItem } from 'primevue/menuitem';
import { Component, Prop, Ref, Vue } from "vue-facing-decorator";
import { GenericTree } from "@/models/tree/GenericTree";
import { BaseTree } from '@he-tree/vue';
import { Stat } from '@he-tree/tree-utils';
import { debounce } from 'throttle-debounce';
import TreeHelper from '@/helpers/TreeHelper';
import { useSystemStore } from '@/stores/system';

@Component({
  components: {
    OverlayPanel,
    InputText,
    Button,
    Checkbox,
    ContextMenu,
    BaseTree
  },
})
class TreeGenericWithCheckboxesView extends Vue {
  @Prop({ required: false, default: [] }) selectedNodes!: GenericTree<any>[];
  @Prop({ required: true }) nodes!: GenericTree<any>[];
  @Prop({ required: true }) changeSelected!: (nodes: GenericTree<any>[]) => void;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ required: false, default: false }) openRoot!: boolean;

  checkedNodes: Record<string, Stat<GenericTree<any>>> = {};
    
  systemStore = useSystemStore();

  checkedIsChanged(stat: Stat<GenericTree<any>>): void {
    if (stat.data.selectable && stat.data.key) {
      if (stat.checkedSingle) {
        this.checkedNodes[stat.data.key] = stat;
      } else {
        delete this.checkedNodes[stat.data.key];
      }
      const nodes = Object.entries(this.checkedNodes).map(([key, value]) => value.data);
      this.changeSelected(nodes);
    }
  }

  checkedIsChangedForMany(): void {
    const nodes = Object.entries(this.checkedNodes).map(([key, value]) => value.data);
    this.changeSelected(nodes);
  }

  itemSelected(node: Stat<GenericTree<any>>): void {
    if (node.data.selectable && node.data.key) {
      node.checkedSingle = !node.checkedSingle;
      this.checkedIsChanged(node);
    }
  }

  getTree(): any {
    return (this.$refs.tree as any);
  }

  statHandler(stat: Stat<GenericTree<any>>): Stat<GenericTree<any>> {
    if (this.selectedNodes.length && stat.data.key) {
      const isSelected = this.selectedNodes.some(node => node.key === stat.data.key);
      if (isSelected) {
        this.checkedNodes[stat.data.key] = stat;
      }
    }
    if (stat.data.children?.length) {
      stat.class = "has-children";
    }
    stat.checkedSingle = (stat.data.key && this.checkedNodes[stat.data.key]) ? true : false;
    if (this.openRoot && stat.data.isRoot) {
      stat.open = true;
    }
    return stat;
  }

  toggleNode(node: Stat<GenericTree<any>>): void {
    node.open = !node.open;
  }

  search = '';
  searchFinal = '';
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
    const nodes = this.getTree().statsFlat as Stat<GenericTree<any>>[];
    TreeHelper.searchGeneric<GenericTree<any>>(nodes, this.searchFinal);
  }

  // #region context menu
  @Ref() readonly menu!: ContextMenu;
  // Timer for long touch detection
  timerLongTouch: number | undefined = undefined;
  // Long touch flag for preventing "normal touch event" trigger when long touch ends
  longTouch = false;
  contextMenuNode: Stat<GenericTree<any>> | undefined;

  openMenu(event: Event, node: Stat<GenericTree<any>>) {
    this.contextMenuNode = node;
    this.menu.show(event);
  }

  touchStartContextMenu(event: Event, node: Stat<GenericTree<any>>) {
    // contextmenu event is not compatible with iOS - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event 
    if (this.systemStore.isIOs) {
      // Timer for long touch detection
      this.timerLongTouch = window.setTimeout(() => {
        // Flag for preventing "normal touch event" trigger when touch ends.
        this.longTouch = true;
      }, 700);
    }
  }

  touchMoveContextMenu(event: Event, node: Stat<GenericTree<any>>) {
    // contextmenu event is not compatible with iOS - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event 
    if (this.systemStore.isIOs) {
      // If timerLongTouch is still running, then this is not a long touch
      // (there is a move) so stop the timer
      clearTimeout(this.timerLongTouch);

      if (this.longTouch) {
        this.longTouch = false;
      }
    }
  }

  touchEndContextMenu(event: Event, node: Stat<GenericTree<any>>) {
    // contextmenu event is not compatible with iOS - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event 
    if (this.systemStore.isIOs) {
      // If timerLongTouch is still running, then this is not a long touch
      // so stop the timer
      clearTimeout(this.timerLongTouch);

      if (this.longTouch) {
        this.longTouch = false;
        this.openMenu(event, node);
      }
    }
  }

  get tree(): any {
    return (this.$refs.tree as any);
  }

  getSelectedStats(): Stat<GenericTree<any>>[] {
    return Object.entries(this.checkedNodes).map(([key, value]) => value);
  }
  
  get menuItems(): MenuItem[] {
    return [{
      label: 'Select All',
      icon: undefined,
      command: async () => {
        const stats = this.tree.statsFlat as Stat<GenericTree<any>>[];
        stats.forEach(stat => {
          if (stat.data?.key && stat.data.selectable) {
            stat.checkedSingle = true;
            this.checkedNodes[stat.data.key] = stat;
          }
        });
        this.checkedIsChangedForMany();
      }
    }, {
      label: 'Unselect All',
      icon: undefined,
      command: async () => {
        const stats = this.tree.statsFlat as Stat<GenericTree<any>>[];
        stats.forEach(stat => {
          if (stat.data?.key && stat.data.selectable) {
            stat.checkedSingle = false;
          }
        });
        this.checkedNodes = {};
        this.checkedIsChangedForMany();
      }
    }, {
      label: 'Select with Siblings',
      icon: undefined,
      command: async () => {
        if (this.contextMenuNode) {
          let newSelectedNodes: Stat<GenericTree<any>>[] = [];
          if (this.contextMenuNode.parent) {
            // select all children
            newSelectedNodes = [...this.contextMenuNode.parent.children];
          } else {
            // select all root
            newSelectedNodes = [...this.tree.stats]
          }
          newSelectedNodes.forEach(stat => {
            if (stat.data?.key && stat.data.selectable) {
              stat.checkedSingle = true;
              this.checkedNodes[stat.data.key] = stat;
            }
          });
          this.checkedIsChangedForMany();
        }
      }
    }, {
      label: 'Unselect with Siblings',
      icon: undefined,
      command: async () => {
        if (this.contextMenuNode) {
          let newSelectedNodes: Stat<GenericTree<any>>[] = [];
          if (this.contextMenuNode.parent) {
            // select all children
            newSelectedNodes = [...this.contextMenuNode.parent.children];
          } else {
            // select all root
            newSelectedNodes = [...this.tree.stats]
          }
          newSelectedNodes.forEach(stat => {
            if (stat.data?.key && stat.data.selectable) {
              stat.checkedSingle = false;
              delete this.checkedNodes[stat.data.key];
            }
          });
          this.checkedIsChangedForMany();
        }
      }
    }, {
      label: 'Select with Children',
      icon: undefined,
      command: async () => {
        if (this.contextMenuNode) {
          const newSelectedNodes = [this.contextMenuNode, ...this.treeToFlat(this.contextMenuNode.children)];
          newSelectedNodes.forEach(stat => {
            if (stat.data?.key && stat.data.selectable) {
              stat.checkedSingle = true;
              this.checkedNodes[stat.data.key] = stat;
            }
          });
          this.checkedIsChangedForMany();
        }
      }
    }, {
      label: 'Unselect with Children',
      icon: undefined,
      command: async () => {
        if (this.contextMenuNode) {
          const newSelectedNodes = [this.contextMenuNode, ...this.treeToFlat(this.contextMenuNode.children)];
          newSelectedNodes.forEach(stat => {
            if (stat.data?.key && stat.data.selectable) {
              stat.checkedSingle = false;
              delete this.checkedNodes[stat.data.key];
            }
          });
          this.checkedIsChangedForMany();
        }
      }
    }];
  }

  treeToFlat(stats: Stat<GenericTree<any>>[]): Stat<GenericTree<any>>[] {
    const result: Stat<GenericTree<any>>[] = [];
    if (stats?.length) {
      stats.forEach(stat => {
        result.push(stat);
        if (stat.children?.length) {
          result.push(...this.treeToFlat(stat.children));
        }
      })
    }
    return result;
  }
  // #endregion context menu
}

export default TreeGenericWithCheckboxesView;
</script>
