<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 20"><path class="add-widget-grid-icon-fill" d="M5.656 19.332H1.772a1.726 1.726 0 0 1-1.726-1.726V9.838a1.726 1.726 0 0 1 1.726-1.726h3.884a1.726 1.726 0 0 1 1.726 1.726v7.768a1.726 1.726 0 0 1-1.726 1.726Zm-2.923-9.4a.863.863 0 0 0-.863.863v5.847a.863.863 0 0 0 .863.863h1.963a.863.863 0 0 0 .863-.863v-5.847a.863.863 0 0 0-.863-.863H2.733Z"/><path class="add-widget-grid-icon-fill" d="M3.717 17.168a.564.564 0 1 1 0-1.128.564.564 0 0 1 0 1.128ZM21.586 16.003h-14v-2.012h13.116a1.128 1.128 0 0 0 1.127-1.127v-9.5a1.128 1.128 0 0 0-1.127-1.127h-14.2a1.127 1.127 0 0 0-1.127 1.127v4.577h-2.02V2.476A2.255 2.255 0 0 1 5.61.221h15.973a2.255 2.255 0 0 1 2.255 2.255v11.273a2.255 2.255 0 0 1-2.255 2.254h.003ZM17.501 19.319h-7.228a.711.711 0 0 1-.626-.442 1.058 1.058 0 0 1 0-.884.712.712 0 0 1 .626-.442h7.228a.712.712 0 0 1 .626.442 1.059 1.059 0 0 1 0 .884.711.711 0 0 1-.626.442Z"/><path class="add-widget-grid-icon-fill" d="M13.97 18.667a1.119 1.119 0 0 1-.624-.178.535.535 0 0 1-.26-.43V15.62a.612.612 0 0 1 .443-.527c.285-.109.6-.109.884 0a.611.611 0 0 1 .442.527v2.435a.534.534 0 0 1-.26.43 1.118 1.118 0 0 1-.624.182ZM17.166 6.985a.564.564 0 0 1-.564-.564V5.012h-1.41a.564.564 0 0 1 0-1.127h1.974a.563.563 0 0 1 .564.564v1.973a.563.563 0 0 1-.564.564v-.001ZM11.81 12.337H9.84a.562.562 0 0 1-.564-.564V9.8a.564.564 0 0 1 1.127 0v1.409h1.409a.564.564 0 1 1 0 1.127v.001Z"/><path class="add-widget-grid-icon-fill" d="m9.728 11.093 7.044-6.9.789.805-7.044 6.9-.79-.805Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class SizeSvg extends Vue {
}

export default SizeSvg;
</script>