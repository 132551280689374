<template>
  <div class="notifications-container">
    <div class="notifications-container-head">
      <h1 class="mb-2">Notifications</h1>
      <div v-if="notificationGroupStore.isLoadedUser && notificationStore.isLoaded">
        <div class="notifications-filters flex flex-wrap md:flex-nowrap gap-3 md:align-items-end lg:align-items-center">
          <div class="flex flex-column lg:flex-row align-items-start lg:align-items-center gap-2 w-full md:w-auto">
            <label for="filterStatus" class="flex-shrink-0 w-full lg:w-auto font-medium">Display</label>
            <Dropdown 
              v-model="filterStatus" 
              :options="filterStatuses"
              inputId="filterStatus"
              @change="filterByStatus"
              class="w-full md:w-17rem flex-shrink-0"
            />
          </div>
          <IconField iconPosition="left" class="w-full md:w-auto lg:w-24rem xl:w-17rem xxl:w-24rem flex-shrink-0 md:flex-auto lg:flex-shrink-0 lg:flex-grow-0 lg:ml-auto">
            <InputIcon class="pi pi-search"></InputIcon>
            <InputText
              placeholder="Search"
              type="text"
              v-model="search"
              @input="debounceSearch()"
              class="w-full"
            />
          </IconField>
          <div class="flex flex-nowrap align-items-center gap-2 md:gap-3 w-full md:w-auto">
            <Button
              :label="`Filter (${selectedFiltersCount})`"
              icon="pi pi-sliders-h"
              class="p-button-secondary p-button-outlined flex-auto md:flex-shrink-0 w-full md:w-auto justify-content-center label-flex-none"
              @click="toggleFilterMenu"
            />
            <OverlayPanel 
              ref="filterOverlayPanel" 
              appendTo="#overlayPanelPlaceholder" 
              :showCloseIcon="false" 
              :dismissable="true" 
              :breakpoints="{'470px': '320px'}" 
              :style="{width: '346px'}" 
              class="notifications-filters-overlaypanel"
            >
              <h4>Filter ({{ selectedFiltersCount }})</h4>
              <div class="field">
                <label for="filterNotificationOrganisation">Organisations</label>
                <div>
                  <MultiSelect
                    v-model="organisationIds"
                    :options="organisationStore.entities"
                    optionLabel="Name"
                    optionValue="Id"
                    :filter="true"
                    :loading="!organisationStore.entities?.length"
                    @change="onFilterChange"
                    class="w-full"
                    inputId="filterNotificationOrganisation"
                    placeholder="All"
                  />
                </div>
              </div>
              <div class="field">
                <label for="filterNotificationFlow">Flows</label>
                <div>
                  <MultiSelect
                    v-model="sourceIds"
                    :options="flows"
                    optionLabel="Name"
                    optionValue="_id"
                    :filter="true"
                    @change="onFilterChange"
                    class="w-full"
                    inputId="filterNotificationFlow"
                    placeholder="All"
                  />
                </div>
              </div>
              <div class="field">
                <label for="filterNotificationAlarmType">Alarm Type</label>
                <div>
                  <MultiSelect
                    v-model="subtitles"
                    :options="alarmTypes"
                    :filter="true"
                    @change="onFilterChange"
                    class="w-full"
                    inputId="filterNotificationAlarmType"
                    placeholder="All"
                  />
                </div>
              </div>
              <div class="flex justify-content-end align-items-center gap-2 mt-4 pt-3">
                <Button
                  label="Reset"
                  class="p-button-outlined"
                  @click="resetFilters"
                  :disabled="organisationIds.length === 0 && subtitles.length === 0 && sourceIds.length === 0"
                />
                <Button
                  label="Apply filters"
                  @click="applyFilters"
                  :disabled="!isFilterChanged"
                />
              </div>
            </OverlayPanel>
            <Button
              label="Actions" 
              icon="pi pi-ellipsis-h text-lg"
              @click="toggleActionsMenu"
              class="p-button-icon-only flex-shrink-0"
            />
            <Menu 
              id="notifications_actions" 
              ref="notifications_actions" 
              :model="actionsMenuItems" 
              :popup="true" 
            />
          </div>
        </div>
      </div>

      <div v-if="notificationStore.isLoaded" class="notifications-filters-groups">
        <label class="font-medium">Notification Groups</label>
        <div v-if="notificationGroupStore.isLoadedUser">
          <div v-if="notificationGroupStore.entitiesUser?.length" class="flex flex-wrap align-items-center gap-2">
            <span 
              v-for="notificationGroup in notificationGroupStore.entitiesUser" 
              class="notification-badge group-badge cursor-pointer"
              :style="getChipStyles(notificationGroup)"
              @click="toggleGroup(notificationGroup.Id)"
            >
              {{ notificationGroup.Name }} <i>({{ getChipCount(notificationGroup) }})</i>
            </span>
          </div>
          <div v-else>
            No notification groups available
          </div>
        </div>
        <div v-else>loading...</div>
      </div>
    </div>

    <div class="notifications-container-body">
      <div v-if="notificationStore.isLoaded">
        <div class="notifications-list">
          <template
            v-for="notification in notificationStore.data?.Items"
            :key="notification.Id"
          >
            <NotificationsItemView :notification="notification"/>
          </template>
        </div>
        <Paginator 
          v-model:first="notificationStore.skip" 
          :rows="notificationStore.take" 
          :totalRecords="notificationStore.data?.Total" 
          @page="onPageChange" 
          template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
          class="flex-shrink-0"
        />
      </div>

      <div v-else class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;">
        <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { useNotificationStore } from "@/stores/notification";
import Paginator, { PageState } from "primevue/paginator";
import { Component, Vue } from "vue-facing-decorator";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import Dropdown from "primevue/dropdown";
import IconField from 'primevue/iconfield';
import InputText from "primevue/inputtext";
import InputIcon from 'primevue/inputicon';
import Menu from "primevue/menu";
import { NotificationEntity } from "@/models/notification/NotificationEntity";
import NotificationsItemView from "./NotificationsItemView.vue";
import { debounce } from "throttle-debounce";
import { MenuItem } from "primevue/menuitem";
import { reactive } from "vue";
import ConfirmationService from "@/services/ConfirmationService";
import { useNotificationGroupStore } from "@/stores/notificationGroup";
import chroma from "chroma-js";
import { NotificationGroupEntity } from "@/models/notification/NotificationGroupEntity";
import ColorHelper from "@/helpers/ColorHelper";
import OverlayPanel from "primevue/overlaypanel";
import MultiSelect from 'primevue/multiselect';
import { useOrganisationStore } from "@/stores/organisation";
import { useOrganisationFlowsStore } from "@/stores/organisationFlows";
import { FlowEntity } from "@/models/flow/FlowEntity";

@Component({
  components: {
    ProgressSpinner,
    Paginator,
    Button,
    Dropdown,
    IconField,
    InputText,
    InputIcon,
    Menu,
    OverlayPanel,
    MultiSelect,
    NotificationsItemView
  },
})
class NotificationsView extends Vue {
  organisationStore = useOrganisationStore();
  organisationFlowsStore = useOrganisationFlowsStore();
  notificationStore = useNotificationStore();
  notificationGroupStore = useNotificationGroupStore();

  created(): void {
    this.notificationGroupStore.loadUser();
  }

  mounted(): void {
    this.notificationStore.load(0, this.notificationStore.take, null, "");
    if (this.organisationStore.currentOrganisation) {
      this.organisationFlowsStore.load(this.organisationStore.currentOrganisation?.Id);
    }
  }

  unmounted(): void {
    this.organisationFlowsStore.$reset();
  }

  onPageChange(event: PageState): void {
    this.notificationStore.load(
      event.first, 
      this.notificationStore.take, 
      this.notificationStore.isRead, 
      this.notificationStore.search,
      this.notificationStore.groupsIds,
      this.notificationStore.organisationIds,
      this.notificationStore.subtitles,
      this.notificationStore.sourceIds
    );
  }

  get selectedNotifications(): Record<string, NotificationEntity> {
    return this.notificationStore.selectedNotifications;
  }

  set selectedNotifications(value: Record<string, NotificationEntity>) {
    this.notificationStore.selectedNotifications = value;
  }

  get selectedNotificationsCount(): number {
    return Object.keys(this.selectedNotifications).length;
  }

  selectAll(): void {
    if (this.notificationStore.data?.Items.length) {
      for (const notification of this.notificationStore.data.Items) {
        this.selectedNotifications[notification.Id] = notification;
      }
    }
  }

  selectNone(): void {
    this.selectedNotifications = {};
  }

  markAsSelected(value: "read" | "unread"): void {
    if (this.selectedNotificationsCount > 0) {
      this.notificationStore.markAs(Object.values(this.selectedNotifications), value);
    }
  }

  markAsAll(value: "read" | "unread"): void {
    this.notificationStore.markAllAs(value);
  }
  
  deleteSelected(): void {
    const message = `Are you sure you want to delete selected notifications?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Notifications',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.selectedNotificationsCount > 0) {
          const result = await this.notificationStore.delete(Object.values(this.selectedNotifications));
          if (result) {
            this.selectedNotifications = {};
          }
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteAll(): void {
    const message = `Are you sure you want to delete all notifications?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Notifications',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.notificationStore.deleteAll();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  toggleActionsMenu(event: Event): void {
    if (this.$refs.notifications_actions) {
      (this.$refs.notifications_actions as Menu).toggle(event);
    }
  }

  get actionsMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    result.push({ 
      label: "Mark all as Read",
      command: () => this.markAsAll('read'), 
      disabled: !this.notificationStore.data?.Total
    });
    result.push({ 
      label: "Mark all as Unread",
      command: () => this.markAsAll('unread'), 
      disabled: !this.notificationStore.data?.Total
    });
    result.push({ 
      label: "Delete all",
      class: "delete-item",
      command: () => this.deleteAll(), 
      disabled: !this.notificationStore.data?.Total
    });
    result.push({ 
      label: "Mark selected as Read",
      command: () => this.markAsSelected('read'), 
      disabled: !this.selectedNotificationsCount
    });
    result.push({ 
      label: "Mark selected as Unread",
      command: () => this.markAsSelected('unread'), 
      disabled: !this.selectedNotificationsCount
    });
    result.push({ 
      label: "Delete selected",
      class: "delete-item",
      command: () => this.deleteSelected(), 
      disabled: !this.selectedNotificationsCount
    });
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  filterStatus = "All";
  filterStatuses = ["All", "Read", "Unread"];

  getStatus(): boolean | null {
    switch (this.filterStatus) {
      case "Read":
        return true;
      case "Unread":
        return false;
      default:
        return null;
    }
  }

  filterByStatus(): void {
    this.notificationStore.load(
      0, 
      this.notificationStore.take, 
      this.getStatus(), 
      this.notificationStore.search, 
      this.notificationStore.groupsIds,
      this.notificationStore.organisationIds,
      this.notificationStore.subtitles,
      this.notificationStore.sourceIds
    );
  }

  search = "";
  searchFinal = "";
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
    this.notificationStore.load(
      0, 
      this.notificationStore.take, 
      this.notificationStore.isRead, 
      this.searchFinal, 
      this.notificationStore.groupsIds,
      this.notificationStore.organisationIds,
      this.notificationStore.subtitles,
      this.notificationStore.sourceIds
    );
  }

  selectedGroups: string[] = [];

  toggleGroup(groupId: string): void {
    const index = this.selectedGroups.findIndex(x => x === groupId);
    if (index < 0) {
      this.selectedGroups.push(groupId);
    } else {
      this.selectedGroups.splice(index, 1);
    }
    this.notificationStore.load(
      0, 
      this.notificationStore.take, 
      this.notificationStore.isRead, 
      this.notificationStore.search, 
      this.selectedGroups,
      this.notificationStore.organisationIds,
      this.notificationStore.subtitles,
      this.notificationStore.sourceIds
    );
  }

  getChipCount(item: NotificationGroupEntity): number {
    let result = 0;
    if (this.notificationStore.data?.GroupsTotal) {
      if (this.notificationStore.data.GroupsTotal[item.Id]) {
        result = this.notificationStore.data.GroupsTotal[item.Id];
      }
    }
    return result;
  }

  getChipStyles(item: NotificationGroupEntity): string {
    let result: string = "";
    if (item.Color) {
      const colorRGB = chroma.hex(item.Color).rgb();
      result = `--colored-chip-selected-bg-color: ${colorRGB[0]}, ${colorRGB[1]}, ${colorRGB[2]}; --colored-chip-selected-bg-color-with-opacity: rgba(var(--colored-chip-selected-bg-color), var(--colored-chip-selected-bg-color-opacity));`;
      const contrastColor = ColorHelper.getContrastColor(item.Color);
      result = `${result}color: ${contrastColor};`;
    }
    if (this.selectedGroups.includes(item.Id)) {
      const colorRGB = chroma.hex(item.Color).darken(0.6).rgb();
      result = `${result}--colored-chip-selected-darken-color: ${colorRGB[0]}, ${colorRGB[1]}, ${colorRGB[2]};box-shadow: inset 0 0 0 .1786rem rgba(var(--colored-chip-selected-darken-color), 1); --colored-chip-selected-bg-color-with-opacity: rgba(var(--colored-chip-selected-bg-color), var(--colored-chip-selected-bg-color-active-opacity));`;
    }
    return result;
  }

  toggleFilterMenu(event: Event): void {
    if (this.$refs.filterOverlayPanel) {
      (this.$refs.filterOverlayPanel as OverlayPanel).toggle(event);
    }
  }

  isFilterChanged = false;

  organisationIds: number[] = [];
  subtitles: string[] = []; 
  sourceIds: string[] = [];

  get selectedFiltersCount(): number {
    return (this.organisationIds.length > 0 ? 1 : 0) + (this.subtitles.length >  0 ? 1 : 0) + (this.sourceIds.length > 0 ? 1 : 0);
  }

  get flows(): FlowEntity[] {
    const result: FlowEntity[] = [];
    if (this.organisationIds.length === 0) {
      const orgIdStr = this.organisationStore.currentOrganisation?.Id ?? "";
      const orgFlows: FlowEntity[] = this.organisationFlowsStore.data[orgIdStr].entities ?? [];
      if (orgFlows.length) {
        result.push(...orgFlows);
      }
    } else {
      this.organisationIds.forEach((organisationId) => {
        const orgIdStr = organisationId.toString();
        const orgFlows: FlowEntity[] = this.organisationFlowsStore.data[orgIdStr].entities ?? [];
        if (orgFlows.length) {
          result.push(...orgFlows);
        }
      });
    }
    return result;
  }

  alarmTypes: string[] = [
    "Out of Limits",
    "Data Anomaly",
    "Data Quality",
    "AI Insight",
    "CSV Export",
    "Tag Value"
  ];

  async onFilterChange(): Promise<void> {
    this.isFilterChanged = true;
    const promises: Promise<void>[] = [];
    for (const organisationId of this.organisationIds) {
      const orgIdStr = organisationId.toString();
      if (!this.organisationFlowsStore.data[orgIdStr]?.isLoaded && !this.organisationFlowsStore.data[orgIdStr]?.isLoadingInProgress) {
        promises.push(this.organisationFlowsStore.load(organisationId));
      }
      if (promises.length >= 10) {
        // don't load flows for more than 10 organisations
        break;
      }
    }
    await Promise.all(promises);
  }

  resetFilters(): void {
    this.organisationIds = [];
    this.subtitles = []; 
    this.sourceIds = [];
    this.applyFilters();
  }

  applyFilters(): void {
    this.isFilterChanged = false;
    // remove flows that not in selected organisations
    for (let i = this.sourceIds.length - 1; i >= 0; i--) {
      const id = this.sourceIds[i];
      const flow = this.flows.find(x => x._id === id);
      if (!flow) {
        this.sourceIds.splice(i, 1);
      }
    }
    this.notificationStore.load(
      0, 
      this.notificationStore.take, 
      this.notificationStore.isRead, 
      this.notificationStore.search, 
      this.notificationStore.groupsIds,
      this.organisationIds,
      this.subtitles,
      this.sourceIds
    );
  }
}

export default NotificationsView;
</script>