<template>
  <div class="default-view-container">
    <h1 class="mb-0">Url Shortener</h1>
    <div v-if="auth.permissions?.BitpoolAdmin" class="default-view increase-padding-bottom mt-4 lg:mt-5">   
      <header class="default-view-header">
        <h2>Enter URL</h2>
      </header>
      <div class="default-view-body" style="max-width: 28rem; min-height: 16rem;">
        <div class="flex align-items-stretch p-inputgroup flex-initial w-full enter-url-for-shorten">
          <InputText
            class="inputfield flex-grow-1"
            placeholder="Url"
            type="text"
            v-model="url"
            :disabled="inProgress"
            @input="resetShortUrl"
          />
        
          <Button :disabled="!url || inProgress" :icon="inProgress ? 'pi pi-spin pi-spinner text-xl' : 'pi pi-send text-xl'" label="Shorten" @click="generateShortUrl" class="flex-shrink-0 p-button-icon-only w-4rem" />
        </div>

        <div v-if="shortUrl" class="flex align-items-stretch p-inputgroup flex-initial w-full mt-4">
          <a :href="shortUrl" target="_blank" class="flex-grow-1 p-component copy-value">{{shortUrl}}</a>
          <Button @click="copyValue(shortUrl)" icon="pi pi-copy text-2xl" class="copy-button flex-shrink-0 p-button-icon-only w-4rem"></Button>
        </div>
      </div>
    </div>
    <div v-else class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ProgressSpinner from "primevue/progressspinner";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import axios from "axios";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import copy from 'copy-to-clipboard';
import { ShortUrlModel } from "@/models/ShortUrlModel";
import AuthState from "@/store/states/AuthState";

@Component({
  
  components: {
    ProgressSpinner,
    InputText,
    Button
  },
})
class AdminShortUrlView extends Vue {
  get auth(): AuthState {
    return this.$store.state.auth;
  }
  
  url = "";
  shortUrl = "";
  inProgress = false;

  async generateShortUrl(): Promise<void> {
    try {
      this.inProgress = true;
      this.shortUrl = "";
      const requestBody: ShortUrlModel = {
        Url: this.url,
        ValidTo: null
      };
      const url = `rest/BitPool_V2/ShortUrl`;
      const response = await axios.post<string>(url, requestBody);
      this.shortUrl = response.data;
      this.inProgress = false;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't generate short url",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      this.shortUrl = "";
      this.inProgress = false;
    }
  }

  resetShortUrl(): void {
    this.shortUrl = "";
  }

  copyValue(value: string | null): void {
    if (value) {
      copy(value);
      ToastService.showToast("success", "", "Copied!", 5000);
    }
  }
}

export default AdminShortUrlView;
</script>
