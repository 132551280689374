<template>
  <div class="default-view-container flow-view-container">
    <div class="default-view-head-section align-items-center">
      <h1 class="mb-0 mr-5">Flows</h1>

      <IconField iconPosition="left" class="search-input-box mb-0">
        <InputIcon class="pi pi-search lg:text-xl"></InputIcon>
        <InputText
          class="inputfield lg:text-lg"
          placeholder="Search"
          type="text"
          v-model="search"
          @input="debounceSearch()"
          style="border-radius: 3rem;"
        />
      </IconField>
    </div>
    <div class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Flow List</h2>
      </header>
      <div class="default-view-body">
        <DataTable
          :value="flowsPageData"
          v-model:expandedRows="expandedRows" 
          dataKey="_id"
          :totalRecords="flowsPageTotal"
          :paginator="true"
          :rows="10"
          :lazy="true"
          @page="onPage($event)"
          @sort="onSort($event)"
          sortField="CreatedDate" 
          :sortOrder="1"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="850px" 
          class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width flows-table">
          <template #header>
            <div class="table-header">
              <Button
                label="Add"
                icon="pi pi-plus-circle"
                class="my-1"
                @click="openFlow(null)"
              />
              <div class="field-checkbox alarm-filter mb-0 my-1">
                <Checkbox
                  inputId="alarmOnly"
                  :binary="true"
                  v-model="alarmOnly"
                ></Checkbox>
                <label class="text-lg font-light" for="alarmOnly">Show Insights only</label>
              </div>
            </div>
          </template>
          <template #empty>
            <div v-if="isLoadedPage" class="w-full" style="min-height: 50vh">
              <span class="inline-block py-2">Create your first flow</span>
            </div>
            <div
              class="w-full flex justify-content-center align-items-center flex-auto"
              style="min-height: 50vh"
              v-else
            >
              <ProgressSpinner
                class="spinner-primary"
                style="width: 100px; height: 100px"
                strokeWidth="4"
                animationDuration="1s"
              />
            </div>
          </template>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 48px;" bodyClass="no-label-in-stack">
            <template #body="slotProps">
              <div class="inline-flex expand-toggler-btn">
                <Button :icon="(preCloseState.findIndex(x => x === slotProps.data._id) >= 0 || !expandedRows[slotProps.data._id]) ? 'pi pi-chevron-right' : 'pi pi-chevron-down'" class="p-button-icon-only p-button-rounded p-button-text p-button-secondary" @click="toggleRow(slotProps.data)" />
              </div>
            </template>
          </Column>
          <Column field="Name" header="Name" :sortable="true" headerStyle="width: 20%; min-width: min-content;"></Column>
          <Column field="Tree.Options.PoolName" header="Pool" :sortable="true" headerStyle="width: 25%; min-width: min-content;">
            <template #body="slotProps">
              {{getPoolName(slotProps.data)}}
            </template>
          </Column>
          <Column field="HasAlarm" header="Alarm" :sortable="true" headerStyle="width: 17%; min-width: min-content;">
            <template #body="slotProps">
              <div>
                <template v-for="item in getAlarms(slotProps.data)" :key="item">
                  <span class="notification-badge notification-badge-alert">
                    {{ item }}
                  </span>
                </template>
              </div>
            </template>
          </Column>
          <Column field="HasAIData" header="AI & CSV" :sortable="true" headerStyle="width: 17%; min-width: min-content;" headerClass="no-break-word">
            <template #body="slotProps">
              <div>
                <template v-for="item in getAiAndDataInsights(slotProps.data)" :key="item">
                  <span class="notification-badge">
                    {{ item }}
                  </span>
                </template>
              </div>
            </template>
          </Column>
          <Column field="CreatedDate" header="Date Created" :sortable="true" headerStyle="width: 15%;">
            <template #body="slotProps">
              <DateTimezoneView :date="slotProps.data.CreatedDate" timezone="local"/>
            </template>
          </Column>
          <Column
            :exportable="false"
            headerStyle="width: 1%; min-width: 88px;" 
            bodyStyle="text-align: right; justify-content: flex-end;"
          >
            <template #body="slotProps">
              <div class="inline-flex">
                <Button
                  icon="pi pi-pencil"
                  class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                  @click="openFlow(slotProps.data)"
                />
                <Button
                  icon="pi pi-trash"
                  class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                  @click="openConfirmation(slotProps.data)"
                />
              </div>
            </template>
          </Column>
          <template #expansion="slotProps">
            <transition name="p-toggleable-content" appear>
              <ul class="flows-table-other-fields" v-if="preCloseState.findIndex(x => x === slotProps.data._id) < 0">
                <li class="pt-3">
                  <span><b>Modified By</b></span>
                  <span><span class="break-word">{{slotProps.data.ModifiedBy ? slotProps.data.ModifiedBy : '-'}}</span></span>
                </li>  
                <li class="pb-3">
                  <span><b>Modified Date</b></span>
                  <span><span class="break-word"><DateTimezoneView :date="slotProps.data.ModifiedDate" timezone="local"/></span></span>
                </li>                                
              </ul>
            </transition>
          </template>
        </DataTable>
      </div>
    </div>

    <Dialog header="Confirmation" v-model:visible="displayConfirmation" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="flex align-items-center">
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete flow?</span>
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary"/>
        <Button label="Yes" icon="pi pi-check" @click="deleteRecord" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Checkbox from "primevue/checkbox";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { Component, Vue } from "vue-facing-decorator";
import FlowState from "@/store/states/FlowState";
import { FlowEntity } from "@/models/flow/FlowEntity";
import { debounce } from 'throttle-debounce';
import { Watch } from "vue-facing-decorator";
import DateTimezoneView from '@/components/views/DateTimezoneView.vue';
import CustomWindow from '@/CustomWindow';
import PoolsState from "@/store/states/PoolsState";
import NavigationHelper from "@/helpers/NavigationHelper";
import { FlowOptions } from "@/models/flow/FlowOptions";

declare const window: CustomWindow;

@Component({

  components: {
    Button,
    DataTable,
    Column,
    ProgressSpinner,
    Dialog,
    InputText,
    Checkbox,
    IconField,
    InputIcon,
    DateTimezoneView
  },
})
class FlowListView extends Vue {
  get flowState(): FlowState {
    return this.$store.state.flow;
  }

  get flowsPageData(): FlowEntity[] {
    return this.flowState.isLoaded &&
      this.flowState.flows &&
      this.flowState.flows.Items
      ? this.flowState.flows.Items
      : [];
  }

  get flowsPageTotal(): number {
    return this.flowState.isLoaded && this.flowState.flows
      ? this.flowState.flows.Total
      : 0;
  }

  get isLoadedPage(): boolean {
    return this.flowState.isLoaded && this.poolsState.isLoadedList;
  }

  get poolsState(): PoolsState {
    return this.$store.state.pools;
  }

  take = 10;
  skip = 0;
  orderBy = "CreatedDate";
  desc = false;
  search = '';
  searchFinal = '';
  alarmOnly = false;

  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  @Watch('alarmOnly', { immediate: false, deep: false })
  onInputChanged(): void {
    this.loadData();
  }

  created(): void {
    this.loadData();
    this.$store.dispatch("pools/loadPoolsList");
  }

  loadData(): void {
    this.$store.dispatch("flow/load", {
      search: this.search,
      take: this.take,
      skip: this.skip,
      orderBy: this.orderBy,
      desc: this.desc,
      alarmOnly: this.alarmOnly
    });
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.skip = event.page * event.rows;
    this.take = event.rows;
    this.loadData();
  }

  onSort(event: any): void {
    // event.sortField: "Name", etc
    // event.sortOrder: 1 or -1
    this.orderBy = event.sortField === "Tree" ? "Tree.Options.PoolName" : event.sortField;
    this.desc = event.sortOrder < 0;
    this.loadData();
  }

  openFlow(item: FlowEntity | null): void {
    NavigationHelper.goTo(`/data/flow/${item ? item._id : 'new'}`);
  }

  getPoolName(item: FlowEntity): string {
    const pools = new Set<string>();
    if (item.Tree && item.Tree.length) {
      item.Tree.forEach(node => {
        const poolKey = (node.Options as FlowOptions)?.PoolKey;
        if (poolKey) {
          const pool = this.poolsState.poolsList?.Pools.find(x => x.Id === poolKey);
          if (pool) {
            pools.add(pool.Name); 
          }
        }
      });
    }
    return pools.size > 0 ? Array.from(pools).join(", ") : "";
  }

  getAlarms(item: FlowEntity): string[] {
    const result: string[] = [];
    if (item.Tree && item.Tree.length) {
      item.Tree.forEach(node => {
        if (this.flowState.alarmTypes.includes(node.NodeType) && !node.NodeType.startsWith("ai_") && !node.NodeType.startsWith("csv_")) {
          result.push(this.getNodeFullName(node.NodeType));
        }
      });
    }
    // remove duplicates
    result.filter((v, i, a) => a.indexOf(v) === i);
    return result;
  }

  getAiAndDataInsights(item: FlowEntity): string[] {
    const result: string[] = [];
    if (item.Tree && item.Tree.length) {
      item.Tree.forEach(node => {
        if (this.flowState.alarmTypes.includes(node.NodeType) && (node.NodeType.startsWith("ai_") || node.NodeType.startsWith("csv_"))) {
          result.push(this.getNodeFullName(node.NodeType));
        }
      });
    }
    // remove duplicates
    result.filter((v, i, a) => a.indexOf(v) === i);
    return result;
  }

  getNodeFullName(nodeType: string | null): string {
    return this.$store.getters["flow/getNodeFullName"](nodeType);
  }
  
  expandedRows: Record<string, boolean> = {};
  preCloseState: string[] = [];

  toggleRow(row: FlowEntity): void {
    if (row._id) {
      if (this.expandedRows[row._id]) {
        // close
        this.preCloseState.push(row._id);
        window.setTimeout(() => {
          const idIndex = this.preCloseState.findIndex(x => x === row._id);
          if (idIndex >= 0 && row._id && this.expandedRows[row._id]) {
            const newExpandedRows = { ...this.expandedRows };
            delete newExpandedRows[row._id];
            this.expandedRows = newExpandedRows;
            this.preCloseState.splice(idIndex, 1);
          }
        }, 450);
      } else {
        // open
        const idIndex = this.preCloseState.findIndex(x => x === row._id);
        if (idIndex >= 0) {
          this.preCloseState.splice(idIndex, 1);
        }
        this.expandedRows = { ...this.expandedRows, [row._id]: true };
      }
    }
  }

  // #region flow delete
  displayConfirmation = false;
  flowDelete: FlowEntity | null = null;

  openConfirmation(item: FlowEntity | null): void {
    this.flowDelete = item;
    this.displayConfirmation = true;
  }

  closeConfirmation(): void {
    this.displayConfirmation = false;
  }

  deleteRecord(): void {
    this.closeConfirmation();
    if (this.flowDelete) {
      this.$store.dispatch("flow/delete", this.flowDelete._id);
    }
  }
  // #endregion flow delete
}

export default FlowListView;
</script>
