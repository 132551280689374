import CustomWindow from "@/CustomWindow";
import DateHelper from "@/helpers/DateHelper";
import { PoolsListModelLite } from "@/models/PoolsListModelLite";
import { PoolsListModelPage } from "@/models/PoolsListModelPage";
import PoolsSearchPageQuery from "@/models/PoolsSearchPageQuery";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { v4 as uuidv4 } from "uuid";
import { BP_TagChangePools } from "@/models/BP_TagChangePools";
import EditPoolTimezoneRequest from "@/models/EditPoolTimezoneRequest";
import RenamePoolRequest from "@/models/RenamePoolRequest";
import HidePoolRequest from "@/models/HidePoolRequest";
import MergePoolsRequest from "@/models/MergePoolsRequest";
import { PoolModelLite } from "@/models/PoolModelLite";
import DeletePoolRequest from "@/models/DeletePoolRequest";
import { BP_RegisterPool } from "@/models/BP_RegisterPool";
import { BitPoolEntity } from "@/models/BitPoolEntity";
import ErrorHelper from "@/helpers/ErrorHelper";
import { PoolModel } from "@/models/PoolModel";
import PoolsState from "../states/PoolsState";
import NavigationHelper from "@/helpers/NavigationHelper";

declare const window: CustomWindow;

const getters = <GetterTree<PoolsState, any>>{};

const mutations = <MutationTree<PoolsState>>{
  selectPool(state, value) {
    state.selectedPoolKeys.push(value);
  },
  unselectPool(state, value) {
    const index = state.selectedPoolKeys.findIndex((poolKey) => {
      return poolKey === value;
    });
    if (index > -1) {
      state.selectedPoolKeys.splice(index, 1);
    }
  },
  selectManyPools(state, values) {
    state.selectedPoolKeys = [
      ...new Set(state.selectedPoolKeys.concat(values)),
    ];
  },
  selectNonePools(state) {
    state.selectedPoolKeys = [];
  },
};

const actions = <ActionTree<PoolsState, any>>{
  async loadPoolsList({ state }) {
    try {
      const guid = uuidv4();
      state.guidList = guid;
      state.isLoadedList = false;
      if (state.poolsList) {
        state.poolsList.Pools = [];
      }
      const response = await axios.get<PoolsListModelLite>(
        "rest/BitPool_V1/pools/web-lite"
      );
      if (state.guidList === guid) {
        if (
          response.data &&
          response.data.Pools &&
          response.data.Pools.length
        ) {
          response.data.Pools = response.data.Pools.sort(
            (a: PoolModelLite, b: PoolModelLite) => {
              if (a.Name < b.Name) {
                return -1;
              }
              if (a.Name > b.Name) {
                return 1;
              }
              return 0;
            }
          );
        }
        state.poolsList = response.data;
        state.isLoadedList = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load pools",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async loadPoolsPage({ state }, query: PoolsSearchPageQuery) {
    try {
      const guid = uuidv4();
      state.guidPage = guid;
      state.pageQuery = query;
      state.isLoadedPage = false;
      if (state.poolsPage) {
        state.poolsPage.Pools = [];
      }
      const url = `rest/BitPool_V1/pools/web-search?includeHidden=${query.includeHidden}&organisationOnly=${query.organisationOnly}&search=${query.search}&orderBy=${query.orderBy}&desc=${query.desc}&take=${query.take}&skip=${query.skip}`;
      const response = await axios.get<PoolsListModelPage>(url);
      if (state.guidPage === guid) {
        if (
          response.data &&
          response.data.Pools &&
          response.data.Pools.length
        ) {
          response.data.Pools.forEach((pool) => {
            if (pool.LastUpdates) {
              pool.LastUpdates = DateHelper.parseFromMicrosoftString(
                pool.LastUpdates as string
              );
            }
            if (pool.Tags && pool.Tags.length) {
              pool.Tags = pool.Tags.sort();
            }
          });
        }
        state.poolsPage = response.data;
        state.isLoadedPage = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load pools",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async refreshPool({ state, commit }, poolKey: string) {
    try {
      const url = `rest/BitPool_V1/pool/${poolKey}/web`;
      const response = await axios.get<PoolModel>(url);
      if (response.data) {
        if (response.data.LastUpdates) {
          response.data.LastUpdates = DateHelper.parseFromMicrosoftString(
            response.data.LastUpdates as string
          );
        }
        if (response.data.Tags && response.data.Tags.length) {
          response.data.Tags = response.data.Tags.sort();
        }
        if (state.isLoadedPage && state.poolsPage && state.poolsPage.Pools) {
          for (const pool of state.poolsPage.Pools) {
            if (pool.Id === response.data.Id) {
              if (pool.LastUpdates !== response.data.LastUpdates) {
                pool.LastUpdates = response.data.LastUpdates;
              }
              if (pool.Records !== response.data.Records) {
                pool.Records = response.data.Records;
              }
              if (pool.Streams !== response.data.Streams) {
                pool.Streams = response.data.Streams;
              }
              pool.Tags = response.data.Tags;
              break;
            }
          }
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load pool",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async changeTags({ state }, body: BP_TagChangePools) {
    try {
      const url = `public/v3/pools/tags/change`;
      const response = await axios.post<Record<string, string[]>>(url, body);
      if (response && response.data) {
        for (const key in response.data) {
          if (
            state.poolsPage &&
            state.poolsPage.Pools &&
            state.poolsPage.Pools.length
          ) {
            const pool = state.poolsPage.Pools.find((pool) => {
              return pool.Id === key;
            });
            if (pool) {
              pool.Tags = response.data[key].sort();
            }
          }
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't change tags",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async changeTimezone({ state }, request: EditPoolTimezoneRequest) {
    try {
      const url = `public/v2/pools/${request.poolKey}/timezone`;
      await axios.put(url, request.body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (
        state.poolsPage &&
        state.poolsPage.Pools &&
        state.poolsPage.Pools.length
      ) {
        const pool = state.poolsPage.Pools.find((pool) => {
          return pool.Id === request.poolKey;
        });
        if (pool) {
          pool.TimeZone = request.body;
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save timezone",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async rename({ state }, request: RenamePoolRequest) {
    try {
      const url = `public/v2/pools/${request.poolKey}/rename`;
      await axios.put(url, request.body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (
        state.poolsPage &&
        state.poolsPage.Pools &&
        state.poolsPage.Pools.length
      ) {
        const pool = state.poolsPage.Pools.find((pool) => {
          return pool.Id === request.poolKey;
        });
        if (pool) {
          pool.Name = request.body;
        }
      }
      if (
        state.poolsList &&
        state.poolsList.Pools &&
        state.poolsList.Pools.length
      ) {
        const pool = state.poolsList.Pools.find((pool) => {
          return pool.Id === request.poolKey;
        });
        if (pool) {
          pool.Name = request.body;
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't rename pool",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async delete({ state, dispatch }, request: DeletePoolRequest) {
    try {
      const url = `public/v2/pools/${request.poolKey}`;
      await axios.delete(url);
      if (
        state.poolsList &&
        state.poolsList.Pools &&
        state.poolsList.Pools.length
      ) {
        const poolArrayIndex = state.poolsList.Pools.findIndex((pool) => {
          return pool.Id === request.poolKey;
        });
        if (poolArrayIndex > -1) {
          state.poolsList.Pools.splice(poolArrayIndex, 1);
        }
      }
      if (state.isLoadedPage && request.reload) {
        await dispatch("loadPoolsPage", state.pageQuery);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete pool",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async hidePool({ state, dispatch }, request: HidePoolRequest) {
    try {
      const url = `rest/BitPool_V1/pool/${request.poolKey}/hide`;
      await axios.put(url, request.body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (state.isLoadedPage) {
        await dispatch("loadPoolsPage", state.pageQuery);
      }
      if (state.isLoadedList) {
        await dispatch("loadPoolsList");
      }
      ToastService.showToast("success", "", "Changes saved.", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't update pool",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async mergePools({ state, dispatch }, request: MergePoolsRequest) {
    try {
      const url = `rest/BitPool_V1/pool/${request.poolKey}/mergewith/${request.poolKeyTo}`;
      await axios.post(url, null, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (state.isLoadedPage) {
        await dispatch("loadPoolsPage", state.pageQuery);
      }
      if (
        state.poolsList &&
        state.poolsList.Pools &&
        state.poolsList.Pools.length
      ) {
        const poolArrayIndex = state.poolsList.Pools.findIndex((pool) => {
          return pool.Id === request.poolKey;
        });
        if (poolArrayIndex > -1) {
          state.poolsList.Pools.splice(poolArrayIndex, 1);
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't update pool",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async registerPool({ state }, body: BP_RegisterPool) {
    try {
      const url = `public/v2/pools`;
      const response = await axios.post<BitPoolEntity>(url, body);
      if (response && response.data) {
        if (state.poolsList && state.poolsList.Pools) {
          state.poolsList.Pools.push({
            Id: response.data.PoolKey,
            Name: response.data.Name,
          });
        }
        NavigationHelper.goTo(`/data/pools/${response.data.PoolKey}`);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't register pool",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
};

const PoolsModule = {
  namespaced: true,
  state: new PoolsState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default PoolsModule;
