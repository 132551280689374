<template>
  <!-- Kind -->
  <div 
    :class="{ 
      'col-12': true, 
      'md:col-4': kind !== 'marker' && kind !== 'dict' && kind !== 'coord' && kind !== 'number' && kind !== 'dateTime' && kind !== 'xstr', 
      'hd:col-4': kind === 'marker' || kind === 'dict' || kind === 'coord' || kind === 'number' || kind === 'dateTime' || kind === 'xstr'
    }"
  >
    <label :for="inputId + '-kind'">Kind</label>
    <Dropdown
      :inputId="inputId + '-kind'"
      v-model="kind"
      :options="compatibleKinds"
      :optionLabel="(x: string): string => { return x; }"
      class="inputfield w-full semantics-kind-dropdown"
      placeholder="Kind"
    />
  </div>

  <!-- Bool -->
  <HaystackBoolEditView
    v-if="kind === 'bool'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- Number -->
  <HaystackNumEditView 
    v-else-if="kind === 'number'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- Str -->
  <HaystackStrEditView
    v-else-if="kind === 'str'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- Uri-->
  <HaystackUriEditView
    v-else-if="kind === 'uri'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- Ref -->
  <HaystackRefEditView
    v-else-if="kind === 'ref'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- Symbol -->
  <HaystackSymbolEditView
    v-else-if="kind === 'symbol'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- Date -->
  <HaystackDateEditView
    v-else-if="kind === 'date'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- Time -->
  <HaystackTimeEditView
    v-else-if="kind === 'time'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- DateTime -->
  <HaystackDateTimeEditView
    v-else-if="kind === 'dateTime'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- Coord -->
  <HaystackCoordEditView
    v-else-if="kind === 'coord'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- XStr -->
  <HaystackXStrEditView
    v-else-if="kind === 'xstr'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- List -->
  <HaystackListEditView
    v-else-if="kind === 'list'"
    :inputId="inputId"
    v-model="haysonDictModel" 
    :field="field" 
    :def="def"
    :disabledFields="disabledFields"
  />
  <!-- Dict -->
  <div v-else-if="kind === 'dict'" class="semantics-tags-field-children col-12">
    <div 
      class="semantics-tags-field-child"
      :class="{ 'is-expanded': !isCollapsedDict }"
    >
    <div class="semantics-tags-field-child-inner">
      <div class="formgrid grid">
        <div class="col-12 flex align-items-center gap-2 semantics-tags-field-child-header">
          <h5 class="flex-auto semantics-tags-field-child-title">
            Value
          </h5>
          
          <span @click="isCollapsedDict = !isCollapsedDict" class="semantics-tags-control-btn p-button p-component p-button-icon-only p-button-rounded p-button-outlined" :class="{ 'is-open': !isCollapsedDict }">
            <span class="pi p-button-icon" :class="{ 'pi-chevron-up': !isCollapsedDict, 'pi-chevron-down': isCollapsedDict }"></span>
            <span class="p-button-label">&nbsp;</span>
          </span>
        </div>
        <transition name="p-toggleable-content">
          <div v-show="!isCollapsedDict" class="semantics-tags-field-child-body col-12">
            <div class="formgrid grid mt-3 flex-auto">
              <HaystackDictEditView
                :inputId="inputId"
                v-model="haysonDictModel" 
                :field="field" 
                :def="def"
                :idPrefix="inputId"
                :excludeFields="[]"
                :mandatoryFields="[]"
                :isLib="false"
                :allowAny="true"
                :disabledFields="disabledFields"
              />
            </div>
          </div>
        </transition>
      </div>
    </div>
    </div>
  </div>
  <!-- Grid -->
  <!-- two dimension table. -->
  <!-- Usually used for http api. Will not implement grid for now. -->
</template>

<script lang="ts">
import Dropdown from 'primevue/dropdown';
import { Component, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import HaystackDefsService from "@/services/HaystackDefsService";
import { HDict, Kind, makeDefaultValue, HaysonVal, toKind, zinc, HaysonDict } from "haystack-core";
import HaystackBoolEditView from "@/components/views/tags/haystack-edit/HaystackBoolEditView.vue";
import HaystackNumEditView from "@/components/views/tags/haystack-edit/HaystackNumEditView.vue";
import HaystackStrEditView from "@/components/views/tags/haystack-edit/HaystackStrEditView.vue";
import HaystackUriEditView from "@/components/views/tags/haystack-edit/HaystackUriEditView.vue";
import HaystackRefEditView from "@/components/views/tags/haystack-edit/HaystackRefEditView.vue";
import HaystackSymbolEditView from "@/components/views/tags/haystack-edit/HaystackSymbolEditView.vue";
import HaystackDateEditView from "@/components/views/tags/haystack-edit/HaystackDateEditView.vue";
import HaystackTimeEditView from "@/components/views/tags/haystack-edit/HaystackTimeEditView.vue";
import HaystackDateTimeEditView from "@/components/views/tags/haystack-edit/HaystackDateTimeEditView.vue";
import HaystackCoordEditView from "@/components/views/tags/haystack-edit/HaystackCoordEditView.vue";
import HaystackXStrEditView from "@/components/views/tags/haystack-edit/HaystackXStrEditView.vue";
import HaystackListEditView from "@/components/views/tags/haystack-edit/HaystackListEditView.vue";
import HaystackDictEditView from "@/components/views/tags/haystack-edit/HaystackDictEditView.vue";

@Component({
  components: {
    Dropdown,
    HaystackBoolEditView,
    HaystackNumEditView,
    HaystackStrEditView,
    HaystackUriEditView,
    HaystackRefEditView,
    HaystackSymbolEditView,
    HaystackDateEditView,
    HaystackTimeEditView,
    HaystackDateTimeEditView,
    HaystackCoordEditView,
    HaystackXStrEditView,
    HaystackListEditView,
    HaystackDictEditView
  },
})
class HaystackValueEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      const kind = this.extractKindFromHaysonVal(this.haysonDictModel[this.field]);
      if (kind === Kind.Str) {
        // haystack-core can't parse empty symbol like "^" as HSymbol and "@" as HRef, so we have to do this
        const strVal = this.haysonDictModel[this.field] as string;
        if (this.isExpectedKind(Kind.Symbol)) {
          this.haysonDictModel[this.field] = {
            _kind: Kind.Symbol,
            val: strVal === "^" ? "" : strVal
          };
          return this.haysonDictModel[this.field];
        }
        if (this.isExpectedKind(Kind.Ref)) {
          this.haysonDictModel[this.field] = {
            _kind: Kind.Ref,
            val: strVal === "@" ? "" : strVal
          };
          return this.haysonDictModel[this.field];
        }
        if (this.isExpectedKind(Kind.XStr)) {
          if (strVal && strVal.length > 4) {
            const idx = strVal.indexOf("(");
            if (idx >= 0) {
              const type = strVal.substring(0, idx);
              const value = strVal.substring(idx + 1, strVal.length - 1);
              const parsedStr = zinc(value)?.toString();
              this.haysonDictModel[this.field] = {
                _kind: Kind.XStr,
                val: parsedStr,
                type: type
              };
              return this.haysonDictModel[this.field];
            }
          }
          this.haysonDictModel[this.field] = {
            _kind: Kind.XStr,
            val: "",
            type: ""
          };
          return this.haysonDictModel[this.field];
        }
      }

      return this.haysonDictModel[this.field];
    }
    return "";
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }

  get fieldDef(): HDict | undefined {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      const def = defs.get(this.field);
      return def;
    }
    return undefined;
  }

  get compatibleKinds(): Kind[] {
    return HaystackDefsService.getCompatibleKinds(this.field, this.kind);
  }

  isExpectedKind(kind: Kind): boolean {
    const def = this.fieldDef;
    if (def) {
      const result = HaystackDefsService.defToKinds(this.field);
      if (result.length === 1 && result[0] === kind) {
        return true;
      }
    }
    return false;
  }

  extractKindFromHaysonVal(val: HaysonVal): Kind | undefined {
    if (typeof val === 'undefined') {
      return undefined;
    }
    switch (typeof val) {
      case 'string':
        return Kind.Str;
      case 'number':
        return Kind.Number;
      case 'boolean':
        return Kind.Bool;
    }
    const result = toKind((val as any)?._kind) ?? 
      (Array.isArray(val) ? Kind.List : Kind.Dict);
    return result;
  }

  get kind(): Kind | undefined {
    return this.extractKindFromHaysonVal(this.valueHayson);
  }

  set kind(value: Kind | undefined) {
    if (value) {
      const newValue = makeDefaultValue(value);
      this.valueHayson = newValue?.toJSON() ?? null;
    } else {
      this.valueHayson = null;
    }
  }

  isCollapsedDict = true;
}

export default HaystackValueEditView;
</script>