<template>
  <div ref="root" v-if="isMounted" class="widget-type-chart widget-type-column-range">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="chart-element column-range" v-else>
      <highcharts v-if="redrawChartToggle" ref="chartElement" class="w-full h-full flex-auto" :options="chartOptions"></highcharts>
      <Button 
        v-if="isZoomed" 
        @click="resetZoom" 
        icon="pi pi-search-minus text-xl font-bold" 
        v-tippy="'Reset Zoom'" 
        class="p-button-icon-only p-button-rounded p-button-light chart-zoom-out"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import moreModule from 'highcharts/highcharts-more';
moreModule(Highcharts);
import DateHelper from '@/helpers/DateHelper';
import { nextTick, PropType } from 'vue';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { BasicWidgetSettings } from '@/models/dashboard/BasicWidgetSettings';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import DashboardState from '@/store/states/DashboardState';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import { Ref, Watch } from 'vue-facing-decorator';
import DataHelper from '@/helpers/DataHelper';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import ColorHelper from '@/helpers/ColorHelper';

@Component({
  components: {
    highcharts: Chart,
    ProgressSpinner,
    Button,
    WidgetNoDataView
  }
})
class ColumnRangeWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  streamColors: string[] = [];
  names: string[] = [];
  categories: string[] = [];
  dates: [Date, Date] | null = null;
  series: Highcharts.SeriesOptionsType[] = [];

  isMounted = false;
  @Ref() readonly root!: HTMLDivElement;
  rootWidth = 150;

  resetZoom(): void {
    const chart = this.getChartElement()
    if (chart && chart.chart) {
      chart.chart.zoomOut();
    }
  }

  isZoomed = false;

  get chartOptions(): Highcharts.Options {
    const units = this.aws ? this.aws.widgetUnit : "";
    const decimals = this.aws ? this.aws.decimals : 0;
    const categories = this.categories;
    const dates = this.dates;
    const series = this.series;
    const halfWidth = this.rootWidth / 2;
    const width = halfWidth < 150 ? halfWidth : 150;
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "columnrange",
        animation: true,
        zooming: {
          type: 'y'
        },
        style: {
          color: ColorHelper.getDefautTextColor(),
        },
        inverted: true,
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          },
          selection: (event: Highcharts.SelectEventObject): (boolean | undefined) => {
            if (event.resetSelection) {
              this.isZoomed = false;
            } else {
              this.isZoomed = true;
            }
            return true;
          }
        }
      },
      plotOptions: {
        columnrange: {
          cursor: 'pointer',
          borderRadius: 3,
          minPointLength: 3,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `<span >${this.y ? this.y.toFixed(decimals) : '0'} ${units}</span>`;
            }
          },
          showInLegend: true,
          borderWidth: 0
        }
      },
      tooltip: {
        formatter: function() {
          const point = this.point as any;
          let datesStr = '';
          if (dates && dates.length > 1) {
            const startDate = dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000);
            const endDate = dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000);
            datesStr = `${Highcharts.dateFormat('%b %e,%Y %H:%M', startDate)}-${Highcharts.dateFormat('%b %e,%Y %H:%M', endDate)}</small><br/>`;
          }
          return `${datesStr}<span style="color:${this.color};">\u25CF </span><b style="word-wrap: break-word; white-space: normal;">${this.x}</b><br/>Min: ${point.low.toFixed(decimals)} ${units}<br/>Max: ${point.high.toFixed(decimals)} ${units}<br/>Avg: ${point.custom.avg.toFixed(decimals)} ${units}`;
        },
        useHTML: true,
        outside: true
      },
      xAxis: {
        type: 'category',
        title: {
          text: ''
        },
        categories: categories,
        labels: {
          formatter: function () {
            const text = this.value;
            return `<div class="column-range-legend" style="width:${width}px; word-wrap: break-word; white-space: normal;" title="${text}">${text}</div>`;
          },
          style: {
            fontSize: "12px",
            width: width
          },
          useHTML: true
        }
      },
      yAxis: {
        type: 'linear',
        title: {
          text: units
        },
      },
      legend: {
        enabled: false
      },
      series: series
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }
  
  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataRefreshInterval = 0;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
    this.isMounted = true;
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
    this.updateRootWidth();
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.resizeWidgetEvent();
  }

  loadConfig(): void {
    if (this.wds &&
      this.wds.streamOptions) {
      const streams = this.wds.streamOptions;
      this.names = [];
      this.streamColors = [];
      streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
        this.streamColors.push(stream.hexStreamColor);
      });
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.loadConfig();
    this.categories = [];
    this.dates = DateHelper.extractDateFromRequestBody(requestBody);
    let i = 0;
    const result: Array<Highcharts.PointOptionsObject> = [];
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length) {
        let minValue: number | undefined = undefined;
        let maxValue: number | undefined = undefined;
        let avg = 0;
        let actualCount = 0;
        streamData.Data.forEach((record) => {
          if (typeof record.y === "number") {
            avg += record.y;
            actualCount++;
            if (minValue === undefined || maxValue === undefined) {
              minValue = record.y;
              maxValue = record.y;
            } else {
              if (minValue > record.y) {
                minValue = record.y;
              }
              if (maxValue < record.y) {
                maxValue = record.y;
              }
            }
          }
        });
        if (minValue !== undefined) {
          result.push({
            low: minValue,
            high: maxValue,
            y: i,
            color: this.streamColors.length > i ? this.streamColors[i] : '',
            custom: { avg: avg / (actualCount ? actualCount : 1) }
          });
        }
        this.categories.push(this.names[i]);
      }
      i++;
    });
    this.series = [{
      dataLabels: {
        enabled: true
      },
      type: 'columnrange',
      data: result
    }];
    if (result.length) {
      this.isNoData = false;
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  redrawChartToggle = true;

  // chart reflow on widget resize
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async resizeWidgetEvent(): Promise<void> {
    this.redrawChartToggle = false;
    // uncomment in case of updateRootWidth removal
    // await nextTick();
    await this.updateRootWidth();
    this.redrawChartToggle = true;
  }

  async updateRootWidth(): Promise<void> {
    await nextTick();
    this.rootWidth = this.root ? this.root.clientWidth : 150;
  }
}

export default ColumnRangeWidget;
</script>
