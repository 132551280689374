<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 34" class="add-widget-grid-icon"><path class="add-widget-grid-icon-stroke" d="M17.172 22.374h-10.3v10.3h10.3v-10.3ZM32.172 22.374h-10.3v10.3h10.3v-10.3Z"/><path class="add-widget-grid-icon-fill add-widget-grid-icon-stroke plus" d="m34.992 13.01-8.485-8.486-8.485 8.485 8.485 8.486 8.485-8.486ZM13.251 6.248h-5.01V1.236a1.076 1.076 0 1 0-2.154 0v5.012h-5.01A1.03 1.03 0 0 0 0 7.324 1.042 1.042 0 0 0 1.076 8.4h5.012v5.011a1.043 1.043 0 0 0 1.076 1.076 1.071 1.071 0 0 0 1.076-1.076v-5.01h5.011a1.077 1.077 0 0 0 0-2.154v.001Z"/><path class="add-widget-grid-icon-stroke" d="M15.293 7.324h1.956v10.351H6.8v-2.17"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class AddWidgetSvg extends Vue {
}

export default AddWidgetSvg;
</script>