<template>
  <div ng-non-bindable>
    {{name}} - unknown component, please check your code
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component({
})
class UnknownComponent extends Vue {
  @Prop({ required: true }) name!: string;
}

export default UnknownComponent;
</script>