export default class SilenceDetector {
  private onSoundStart: () => void;
  private onSoundEnd: () => void;
  private silenceDelay: number;
  private minDecibels: number;
  private silenceStart = 0;
  private silenceStop = 0;
  private isSilentState = true;
  private audioContext: AudioContext | undefined = undefined;
  private analyser: AnalyserNode | undefined = undefined;
  private streamNode: MediaStreamAudioSourceNode | undefined = undefined;
  private data: Uint8Array = new Uint8Array();

  constructor (onSoundStart: () => void, onSoundEnd: () => void, silenceDelay = 1000, minDecibels = -80) {
    this.onSoundStart = onSoundStart;
    this.onSoundEnd = onSoundEnd;
    this.silenceDelay = silenceDelay;
    this.minDecibels = minDecibels;
  }
  
  public async start(): Promise<void> {
    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    this.audioContext = new AudioContext();
    this.analyser = this.audioContext.createAnalyser();
    this.streamNode = this.audioContext.createMediaStreamSource(stream);
    this.streamNode.connect(this.analyser);
    this.analyser.minDecibels = this.minDecibels;

    this.data = new Uint8Array(this.analyser.frequencyBinCount); // will hold our data
    this.silenceStart = 0;
    this.silenceStop = 0;
    this.isSilentState = true;
    window.requestAnimationFrame((x) => this.loop(x));
  }

  public stop(): void {
    if (this.streamNode) {
      this.streamNode.disconnect();
      this.streamNode = undefined;
    }
    if (this.analyser) {
      this.analyser.disconnect();
      this.analyser = undefined;
    }
    if (this.audioContext) {
      this.audioContext.close();
      this.audioContext = undefined;
    }
  }

  private loop(time: number) {
    if (!this.analyser) {
      return;
    }
    this.analyser.getByteFrequencyData(this.data); // get current data
    const notSilent = this.data.some((v) => v); // if there is data above the given db limit
    if (notSilent) {
      if (this.isSilentState && (this.silenceStop === 0 || (time - this.silenceStop > this.silenceDelay))) {
        this.isSilentState = false;
        this.onSoundStart();
      }
      this.silenceStart = time; // set it to now
    }
    if (!this.isSilentState && (this.silenceStart !== 0 && (time - this.silenceStart > this.silenceDelay))) {
      this.onSoundEnd();
      this.isSilentState = true;
      this.silenceStop = time;
    }
    requestAnimationFrame((x) => this.loop(x));
  }
}