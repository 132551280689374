import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import { AlarmStatus } from "@/models/AlarmStatus";
import { StreamModel } from "@/models/StreamModel";

export default class WidgetDataState {
  isLoaded: Record<string, boolean> = {};
  requestGuid: Record<string, string> = {};
  data: Record<string, AggregatedDataHighchartsResponse[] | null> = {};
  exportInProgress: Record<string, boolean> = {};
  error: Record<string, string | null> = {};
  // guid, date, request body as string
  requestBody: Record<string, [Date, string]> = {};
  // guid, isLoaded, streams
  streams: Record<string, [boolean, StreamModel[] | null]> = {};
  // guid, isLoaded, streamKey, last date on current time range
  lastDateOnCurrentTimeRange: Record<string, [boolean, Record<string, number | null> | null]> = {};
  // guid, isLoaded, alarm state
  dataAlarm: Record<string, [boolean, AlarmStatus | null]> = {};
  // guid, isLoaded, tariffId, value
  dataTariffSimulation: Record<string, [boolean, Record<string, number | null> | null]> = {};
  // guid, isLoaded, value
  dataTariffCalculator: Record<string, [boolean, number | null]> = {};
  // guid, isLoaded, pdf link
  dataReport: Record<string, [boolean, string | null]> = {};
  // guid
  maximizedWidget: string | null = null;
}
