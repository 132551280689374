import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { v4 as uuidv4 } from "uuid";
import SpaceCommentsState from "../states/SpaceCommentsState";
import { SpaceCommentsEntity } from "@/models/dashboard/SpaceCommentsEntity";
import DateHelper from "@/helpers/DateHelper";
import { SpaceCommentsReactionEntity } from "@/models/dashboard/SpaceCommentsReactionEntity";

function strToDate(model: SpaceCommentsEntity): void {
  if (typeof model.DateCreated === "string") {
    model.DateCreated = DateHelper.parseFromMicrosoftString(model.DateCreated);
  }
}

const getters = <GetterTree<SpaceCommentsState, any>>{};

const mutations = <MutationTree<SpaceCommentsState>> {
  addComment(state, comment: SpaceCommentsEntity): void {
    const commentIndex = state.data.findIndex(x => x.Id === comment.Id);
    if (commentIndex >= 0) {
      state.data.splice(commentIndex, 1, comment);
    } else {
      state.data.splice(0, 0, comment);
    }
  },
  deleteComment(state, id: string): void {
    for (let i = state.data.length - 1; i >= 0; --i) {
      if (state.data[i].Id === id) {
        state.data.splice(i, 1);
        break;
      }
    }
  },
  updateReaction(state, reaction: SpaceCommentsReactionEntity): void {
    const oldReactionIndex = state
      .dataReaction
      .findIndex(x => x.Username === reaction.Username && x.CommentId === reaction.CommentId);
    if (reaction.Reaction) {
      if (oldReactionIndex >= 0) {
        // update
        state.dataReaction.splice(oldReactionIndex, 1, reaction);
      } else {
        // add
        state.dataReaction.push(reaction);
      }
    } else {
      // delete
      if (oldReactionIndex >= 0) {
        state.dataReaction.splice(oldReactionIndex, 1);
      }
    }
  },
};

const actions = <ActionTree<SpaceCommentsState, any>>{
  async loadData({ state }, spaceId: string) {
    try {
      const guid = uuidv4();
      state.guid = guid;
      state.isLoaded = false;
      const url = `rest/BitPool_V2/os/space-comments/${spaceId}`;
      const response = await axios.get<SpaceCommentsEntity[]>(url);
      if (state.guid === guid) {
        response.data.forEach(element => {
          strToDate(element);
        });
        state.data = response.data;
        state.isLoaded = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load comments",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.data = [];
      state.isLoaded = true;
    }
  },
  async saveComment({ state, commit }, body: SpaceCommentsEntity) {
    try {
      state.inProgressSave = true;
      const url = `rest/BitPool_V2/os/space-comments`;
      const response = await axios.post<string>(url, body);
      if (!body.Id) {
        body.Id = response.data;
        commit("addComment", body);
      }
      state.inProgressSave = false;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save comment",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.inProgressSave = false;
    }
  },
  async delete({ state }, id: string) {
    try {
      state.inProgressDelete = true;
      const url = `rest/BitPool_V2/os/space-comments/delete/${id}`;
      await axios.delete(url);
      for (let i = state.data.length - 1; i >= 0; --i) {
        if (state.data[i].Id === id) {
          state.data.splice(i, 1);
          break;
        }
      }
      state.inProgressDelete = false;
      ToastService.showToast("success", "", "Changes saved", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete comment",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.inProgressDelete = false;
    }
  },
  async loadReaction({ state }, spaceId: string) {
    try {
      const guid = uuidv4();
      state.guidReaction = guid;
      state.isLoadedReaction = false;
      const url = `rest/BitPool_V2/os/space-comments/reaction/${spaceId}`;
      const response = await axios.get<SpaceCommentsReactionEntity[]>(url);
      if (state.guidReaction === guid) {
        state.dataReaction = response.data;
        state.isLoadedReaction = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load reactions",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.dataReaction = [];
      state.isLoadedReaction = true;
    }
  },
  async reaction({ state, commit }, body: SpaceCommentsReactionEntity) {
    try {
      state.inProgressReaction = true;
      const url = `rest/BitPool_V2/os/space-comments/reaction`;
      const response = await axios.post<string>(url, body);
      if (!body.Id) {
        body.Id = response.data;
        commit("updateReaction", body);
      }
      state.inProgressReaction = false;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save changes",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.inProgressReaction = false;
    }
  },
  async deleteReaction({ state }, id: string) {
    try {
      state.inProgressDeleteReaction = true;
      const url = `rest/BitPool_V2/os/space-comments/reaction/delete/${id}`;
      await axios.delete(url);
      for (let i = state.dataReaction.length - 1; i >= 0; --i) {
        if (state.dataReaction[i].Id === id) {
          state.dataReaction.splice(i, 1);
          break;
        }
      }
      state.inProgressDeleteReaction = false;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save changes",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.inProgressDeleteReaction = false;
    }
  },
};

const SpaceCommentsModule = {
  namespaced: true,
  state: new SpaceCommentsState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default SpaceCommentsModule;
