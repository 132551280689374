<template>
  <div ng-non-bindable v-if="isMounted" class="fullWidthHeight basicChart">
    <highcharts ref="chartElement" class="fullWidthHeight" :options="chartOptions"></highcharts>
    <Button 
      v-if="isZoomed" 
      @click="resetZoom" 
      icon="pi pi-search-minus text-md font-bold" 
      v-tippy="'Reset Zoom'" 
      class="p-button-icon-only p-button-rounded p-button-light chart-zoom-out old-dashboard"/>
    <div class="plot-legend" v-if="plotLegend && plotLegend.length">
      <div>
        <label class="plot-legend-item" v-for="item in plotLegend" :key="item[0]">
          <span class="plot-legend-color" :style="{ backgroundColor: item[1].color }"></span><b>{{item[1].name ? item[1].name : 'Range ' + item[0]}}</b>
        </label>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import AngularOptions from '../../models/AngularOptions';
import ChartDataResponse from '../../models/ChartDataResponse';
import ChartRequstBody from '../../models/ChartRequstBody';
import StreamOption from '../../models/dashboard/StreamOption';
import AxisData from '../../models/AxisData';
import TriggerLevel from '../../models/TriggerLevel';
import DateHelper from '../../helpers/DateHelper';
import moment from 'moment';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import ColorHelper from '../../helpers/ColorHelper';
import { AggregationPeriod } from '@/models/enums/AggregationPeriod';
import { AllUserData } from '@/models/user/AllUserData';
import CustomWindow from '@/CustomWindow';
import Button from 'primevue/button';

declare const window: CustomWindow;

@Component({

  components: {
    highcharts: Chart,
    Button
  }
})
class BasicChartWidget extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  chartData: ChartDataResponse[] = [];
  streams: StreamOption[] = [];
  streamColors: string[] = [];
  names: string[] = [];
  dates: [Date, Date] | null = null;
  series: Highcharts.SeriesOptionsType[] = [];
  yAxis: Highcharts.YAxisOptions[] = [];
  legendLayout: Highcharts.OptionsLayoutValue = 'horizontal';
  legendAlign: Highcharts.AlignValue = 'center';
  plotLegend: [number, TriggerLevel][] | null = null;
  requestBody: ChartRequstBody | null = null;

  isMounted = false;

  get allUserData() : AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  resetZoom(): void {
    const chart = this.getChartElement()
    if (chart && chart.chart) {
      chart.chart.zoomOut();
    }
  }

  isZoomed = false;

  get chartOptions(): Highcharts.Options {
    const legendLayout = this.legendLayout;
    const legendAlign = this.legendAlign;
    const dates = this.dates;
    const series = this.series;
    const yAxis = this.yAxis;
    const theme = this.angularOptions.angularScope.theme[this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor];
    const xAxisLabelsFormat = this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.aggPeriod == 5 ? 'Total' : undefined;

    let startDate = (dates && dates.length > 0) ?
      (dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000)) : 
      undefined;
    if (startDate && this.requestBody && this.requestBody.AggregationPeriod === AggregationPeriod.Weekly) {
      const startOfWeek = moment.utc(startDate).startOf("isoWeek");
      startDate = startOfWeek.unix() * 1000;
    }
    

    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "column",
        animation: true,
        zooming: {
          type: 'x'
        },
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          },
          selection: (event: Highcharts.SelectEventObject): (boolean | undefined) => {
            if (event) {
              this.isZoomed = false;
            } else {
              this.isZoomed = true;
            }
            return true;
          }
        }
      },
      tooltip: {
        useHTML: true,
        outside: true,
        shared: (this.allUserData.userSetup) ? 
          this.allUserData.userSetup.groupTooltips : 
          true,
        valueDecimals: 2,
        formatter: function() {
          if (this.points) {
            let result = `<small>${typeof this.x === "number" ? Highcharts.dateFormat('%b %e,%Y %H:%M', this.x) : this.x}</small>`;
            this.points.forEach(point => {
              result += `<br/><span style="color:${point.color};">\u25CF </span>${point.series.name}: <b>${(point.y ?? 0).toFixed(2)}</b>`
            });
            return result;
          } else {
            return `<small>${typeof this.x === "number" ? Highcharts.dateFormat('%b %e,%Y %H:%M', this.x) : this.x}</small><br/><span style="color:${this.color};">\u25CF </span>${this.series.name}: <b>${(this.y ?? 0).toFixed(2)}</b>`;
          }
        },
      },
      yAxis: yAxis,
      xAxis: {
        type: 'datetime',
        min: startDate,
        labels: {
          style: {
            font: "500 12px Arial, sans-serif",
            color: theme.text1
          },
          format: xAxisLabelsFormat
        },
        lineColor: "#888",
        tickWidth: 2,
        tickLength: 5,
        tickColor: "#888",
        minorGridLineWidth: 0,
        minorTickWidth: 0
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        layout: legendLayout,
        align: legendAlign,
        itemStyle: {
          color: theme.title1,
          font: "500 10px Arial, sans-serif",
          verticalAlign: 'middle'
        },
        symbolHeight: 8,
        symbolWidth: 8,
        maxHeight: 40
      },
      plotOptions: {
        series: {
          pointStart: startDate,
          dataGrouping: {
            enabled: this.angularOptions.angularScope.widget.widgetOptions.widgetDataSettings.autoAggPeriod,
            approximation: 'average'
          },
          turboThreshold: 0
        },
        line: {
          marker: {
            enabled: (this.allUserData.userSetup) ? !this.allUserData.userSetup.disablePlotMarks : false
          }
        }
      },
      series: series
    }
  }

  created(): void {
    if (this.angularOptions && this.angularOptions.angularScope) {
      if (this.angularOptions.angularScope.vueData && this.angularOptions.angularScope.vueRequestBody) {
        this.dataUpdate(
          this.angularOptions.angularScope.vueData as ChartDataResponse[],
          this.angularOptions.angularScope.vueRequestBody as ChartRequstBody);
      }
      if (this.angularOptions.angularScope.subscribeToDataUpdate) {
        this.angularOptions.angularScope.subscribeToDataUpdate(this.dataUpdate);
      }
      if (this.angularOptions.angularScope.subscribeToMinMode) {
        this.angularOptions.angularScope.subscribeToMinMode(this.minMode);
      }
    }
  }

  mounted(): void {
    this.isMounted = true;
    if (this.angularOptions && this.angularOptions.angularScope && this.angularOptions.angularScope.subscribeToWidgetResize) {
      this.angularOptions.angularScope.subscribeToWidgetResize(this.resizeWidgetEvent);
    }
  }

  loadConfig(): void {
    if (this.angularOptions && 
      this.angularOptions.angularScope &&
      this.angularOptions.angularScope.Widget &&
      this.angularOptions.angularScope.Widget.config &&
      this.angularOptions.angularScope.Widget.config.widgetOptions &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions) {
      this.streams = this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions as StreamOption[];
      this.names = [];
      this.streamColors = [];
      this.streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
        if (stream.hexStreamColor) {
          this.streamColors.push(stream.hexStreamColor);
        } else {
          if (this.angularOptions.angularScope.theme &&
              this.angularOptions.angularScope.Widget &&
              this.angularOptions.angularScope.Widget.config &&
              this.angularOptions.angularScope.Widget.config.widgetOptions &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor) {
            const theme = this.angularOptions.angularScope.theme[this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor]
            this.streamColors.push(theme.colors[stream.streamColor]);
          } else {
            this.streamColors.push(ColorHelper.random());
          }
        }
      });
    }
  }

  dataUpdate(data: ChartDataResponse[], requestBody: ChartRequstBody): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.loadConfig();
    this.dates = DateHelper.extractDateFromRequestBody(requestBody);
    const series: Highcharts.SeriesOptionsType[] = [];
    const yAxis: Highcharts.YAxisOptions[] = [];
    const axisData =  this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.yaxis ?
      this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.yaxis as AxisData[] :
      [];
    let i = 0;
    this.plotLegend = [];
    const formatLongNumber = this.formatLongNumber;
    const plotBands: Highcharts.YAxisPlotBandsOptions[] = [];
    if (this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.triggerLevels) {  
      (this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.triggerLevels as TriggerLevel[]).forEach(trigger => {
        i++;
        const start = trigger.start;
        const end = trigger.end;
        const color = trigger.color;
        plotBands.push({
          from: start,
          to: end,
          color: color
        });
        if (this.plotLegend) {
          this.plotLegend.push([i, trigger]);
        }
      });
    }
    i = 0;
    const theme = this.angularOptions.angularScope.theme[this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor];
    if (axisData.length) {
      axisData.forEach(axis => {
        yAxis.push({
          id: i.toString(),
          opposite: (i % 2 === 1),
          gridLineWidth: 1,
          lineColor: "#888",
          lineWidth: 1,
          tickColor: "#888",
          tickWidth: 1,
          tickLength: 5,
          tickPixelInterval: 36,
          title: {
            text: axis.name,
            style: {
              font: "600 16px Arial, sans-serif",
              color: theme.title1
            }
          },
          labels: {
            style: {
              font: "500 12px Arial, sans-serif",
              color: theme.text1
            },
            formatter: function () {
              return formatLongNumber(this.value);
            }
          },
          plotBands: plotBands
        });
        i++;
      });   
    } else {
      yAxis.push({
        gridLineWidth: 1,
        lineColor: "#888",
        tickColor: "#888",
        title: {
          text: ' ',
          style: {
            font: "600 16px Arial, sans-serif",
            color: theme.title1
          }
        },
        labels: {
          style: {
            font: "500 12px Arial, sans-serif",
            color: theme.text1
          },
          formatter: function () {
            return formatLongNumber(this.value)
          }
        },
        plotBands: plotBands
      });
    }
    i = 0;
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        // angular should show error message to user
        //console.error(streamData.Error);
      } else if (streamData.Data && streamData.Data.length) {
        const data = streamData.Data.slice();
        // time offset
        const currentStreamOptions = this.streams[i];
        if (currentStreamOptions.Params.timeOffset) {
          const timeOffsetValue =
            typeof currentStreamOptions.Params.timeOffset.value === "string" ?
              parseInt(currentStreamOptions.Params.timeOffset.value ? currentStreamOptions.Params.timeOffset.value : "0") :
              currentStreamOptions.Params.timeOffset.value;
          if (timeOffsetValue) {
            for (let j = 0; j < data.length; j++) {
              const el = data[j];
              const time = moment.utc(el.x);
              // "-" - shift date back to selected period
              const shiftedTime = DateHelper.shiftTime(time, currentStreamOptions.Params.timeOffset.period, -timeOffsetValue);
              data[j] = { x: shiftedTime.unix() * 1000, y: el.y };
            }
          }
        }
        
        const axisId = currentStreamOptions.Params.yaxis;
        let seriesType: "column" | "line" | "area" | "scatter" | "spline" = "column";
        switch (currentStreamOptions.type) {
          case "line": {
            seriesType = "line";
            break;
          }
          case "area": {
            seriesType = "area";
            break;
          }
          case "scatter": {
            seriesType = "scatter";
            break;
          }
          case "spline": {
            seriesType = "spline";
            break;
          }
        }
        series.push({
          name: this.names.length > i ? this.names[i] : '',
          color: this.streamColors.length > i ? this.streamColors[i] : '',
          type: seriesType,
          data: data,
          yAxis: (axisData.length && axisData.length > axisId) ? axisId.toString() : ''
        });
      }
      i++;
    });
    this.series = series;
    this.yAxis = yAxis;
    if (!series.length && this.angularOptions.angularScope.showNoDataMessage) {
      this.angularOptions.angularScope.showNoDataMessage();
    } else {
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  // chart reflow on widget resize
  resizeWidgetEvent(): void {
    const chartElement = this.getChartElement();
    if (chartElement) {
      chartElement.chart.reflow();
    }
  }

  minMode(enabled: boolean): void {
    if (enabled) {
      this.legendLayout = 'vertical';
      this.legendAlign = 'right';
    } else {
      this.legendLayout = 'horizontal';
      this.legendAlign = 'center';
    }
  }

  formatLongNumber(value: string | number): string {
    if (typeof value === "string") {
      return value;
    }
    if (value == 0) {
      return '0';
    }
    else {
      // hundreds
      if (value <= 999) {
        return `${value}`;
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return (value / 1000) + 'K';
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return (value / 1000000) + 'M';
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return (value / 1000000000) + 'B';
      }
      else
        return `${value}`;
    }
  }
}

export default BasicChartWidget;
</script>

<style lang="scss" scoped>
  .basicChart {
    display: flex;
    flex-direction: column;
  }

  .plot-legend {
    flex: 0 0 auto;
    padding: .625rem 5% 0;

    &-item {
      display: inline-flex;
      align-items: center;
      margin-bottom: .625rem;
      margin-right: .75rem;
      font-size: .875em;
      color: #646464;
    }

    &-color {
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border: 1px solid #646464;
      margin-right: .3125rem;
      flex: 0 0 auto;
    }

    b {
      display: inline-block;
    }
  }
</style>