<template>
  <div>
    <DataTable
      :value="emailImportStore.scripts" 
      dataKey="Id"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint email-import-table">
      <template #header>
        <div class="table-header">
          <Button label="Add Script" icon="pi pi-plus-circle" class="my-1" @click="openCreateUpdateDialog(null)" />
        </div>
      </template>
      <template #empty>
        <div v-if="emailImportStore.isLoadedScripts" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Name" header="Name" headerStyle="min-width: min-content;" headerClass="no-break-word">
        <template #body="slotProps">
          <span class="block with-inline-btn">
            {{ slotProps.data.Name }}
            <span v-if="slotProps.data.OrganisationId === 0" v-tippy="'Public'" class="table-cell-icon">
              <i class="pi pi-globe"></i>
            </span>
          </span>
        </template>
      </Column>
      <Column field="DefaultExtension" header="Default File Extension"></Column>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <div>
            <div class="inline-flex">
              <Button 
                icon="pi pi-clone" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCloneDialog(slotProps.data)"
                v-tippy="'Copy'"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCreateUpdateDialog(slotProps.data)"
                v-tippy="'Edit'"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                :disabled="slotProps.data.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin"
                v-tippy="'Delete'"
              />
            </div>
          </div>
        </template>
      </Column>
    </DataTable>

    <Dialog header="Script" v-model:visible="displayCreateUpdateDialog" :modal="true" class="email-import-config-dialog w-11 xl:w-10">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <div class="field col-12 md:col-8">
              <label for="editRecordName">Name</label>
              <div>
                <InputText 
                  id="editRecordName" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Name"
                  placeholder="Name"
                />
              </div>
            </div>
            <div class="field col-12 md:col-4">
              <label for="editRecordExtension">Default File Extension</label>
              <div>
                <InputText 
                  id="editRecordExtension" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.DefaultExtension"
                  placeholder="Example: csv, xml, json"
                />
              </div>
            </div>
            <ScriptAdditionalParameterView 
              v-if="editRecord" 
              v-model="editRecord.AdditionalParameters" 
              class="field col-12 mb-0"
            />
            <div class="field col-12 mb-0">
              <div class="formgrid grid">
                <div class="field col-12 md:col-6 md:mb-0">
                  <label for="editRecordScript">Data Parser Script (JavaScript)</label>
                  <div>
                    <Codemirror
                      v-model="editRecord.Script"
                      placeholder="Data Parser Script"
                      class="w-full"
                      :style="{ height: 'auto' }"
                      :indent-with-tab="false"
                      :tab-size="2"
                      :extensions="extensionsScript"
                    />
                  </div>
                </div>
                <div class="field col-12 md:col-6 mb-0">
                  <div class="field">
                    <label for="editRecordDataExample">Data Example</label>
                    <div>
                      <Codemirror
                        v-model="editRecord.DataExample"
                        placeholder="Please insert a Data Example here"
                        class="w-full"
                        :style="{ height: '200px' }"
                        :indent-with-tab="false"
                        :tab-size="2"
                        :extensions="extensionsData"
                      />
                    </div>
                  </div>
                  <div class="field mb-0">
                    <label for="editRecordTestResult">Test Result</label>
                    <div>
                      <Codemirror
                        v-model="testResult"
                        placeholder="Please click Test to see the test result"
                        class="w-full"
                        :style="{ height: '200px' }"
                        :indent-with-tab="false"
                        :tab-size="2"
                        :extensions="extensionsTestResult"
                        :disabled="true"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #header>
        <div class="flex-auto flex justify-content-between align-items-center gap-4">
          <span class="p-dialog-title">Script</span>
          <div class="email-import-logs-switch-field pr-3" v-if="canEditGlobal">
            <div class="flex align-items-center">
              <InputSwitch 
                  inputId="email-import-edit-global"
                  v-model="selectedEntityIsGlobal"
                  class="vertical-align-top"
                  :disabled="updateInProgress"
                />
              <label for="email-import-edit-global" class="mb-0 ml-2">Public</label>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button 
              :icon="emailImportStore.inProgressScriptTest ? 'pi pi-spin pi-spinner' : 'pi pi-play'"
              label="Test" 
              class="p-button-outlined"
              @click="testScript"
              :disabled="emailImportStore.inProgressScriptTest"
            />
            <Button 
              v-if="editRecord"
              class="ml-2"
              :label="editRecord.Id ? 'Update' : 'Create'" 
              :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
              @click="saveRecord" 
              :disabled='!editRecord.Name || !editRecord.DataExample || !editRecord.DefaultExtension || !editRecord.Script || updateInProgress || editRecord.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin' 
            />
          </span>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import { EmailImportScriptEntity } from "@/models/email-import/EmailImportScriptEntity";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import moment from "moment";
import ToastService from "@/services/ToastService";
import copy from 'copy-to-clipboard';
import { useEmailImportStore } from "@/stores/emailImport";
import ConfirmationService from "@/services/ConfirmationService";
import { Codemirror } from 'vue-codemirror';
import { xcodeDark, xcodeLight } from '@uiw/codemirror-theme-xcode';
import { javascript } from '@codemirror/lang-javascript';
import { json } from '@codemirror/lang-json';
import { xml } from '@codemirror/lang-xml';
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { ScriptAdditionalParameter } from "@/models/script/ScriptAdditionalParameter";
import ScriptAdditionalParameterView from "@/components/views/ScriptAdditionalParameterView.vue";
import { useOrganisationStore } from "@/stores/organisation";

@Component({
  components: {
    Button,
    InputText,
    InputNumber,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    Message,
    ProgressSpinner,
    Dropdown,
    Codemirror,
    ScriptAdditionalParameterView
  },
  directives: {
  }
})
class EmailImport2ScriptsView extends Vue { 
  emailImportStore = useEmailImportStore();

  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }

  // #region new/edit
  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get selectedEntityIsGlobal(): boolean {
    return this.editRecord?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.editRecord) {
      if (value) {
        this.editRecord.OrganisationId = 0;
      } else {
        this.editRecord.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }

  get extensionsData(): any[] {
    if (!this.editRecord) {
      return [];
    }
    const result = [];
    if (this.isDarkTheme) {
      result.push(xcodeDark);
    } else {
      result.push(xcodeLight);
    }
    switch (this.editRecord.DefaultExtension) {
      case "json":
        result.push(json());
        return result;
      case "xml":
        result.push(xml());
        return result;
      default:
        return result;
    }
  }
  
  get extensionsTestResult(): any[] {
    const result = [];
    if (this.isDarkTheme) {
      result.push(xcodeDark);
    } else {
      result.push(xcodeLight);
    }
    result.push(json());
    return result;
  }

  get extensionsScript(): any[] {
    const result = [];
    if (this.isDarkTheme) {
      result.push(xcodeDark);
    } else {
      result.push(xcodeLight);
    }
    result.push(javascript());
    return result;
  }

  displayCreateUpdateDialog = false;
  editRecord: EmailImportScriptEntity | null = null;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  openCloneDialog(record: EmailImportScriptEntity): void {
    const newRecord: EmailImportScriptEntity = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    if (!this.canEditGlobal) {
      newRecord.OrganisationId = this.currentOrganisation?.Id ?? -1;
    }
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: EmailImportScriptEntity | null): void {
    const nowUtc = moment.utc().toDate();
    this.editRecord = record ? JSON.parse(JSON.stringify(record)) : {
      Id: "",
      Name: "",
      Script: `/**
 * Parses the given data and returns an array of objects representing the parsed data.
 * Each object contains the name, date, value, and valueStr properties.
 *
 * @param data - The data to be parsed
 * @param parameters - The parameters to be used
 * @returns An array of objects representing the parsed data
 */
function parseData(data, parameters) {
  const result = [];
  // todo: parse data
  // number example:
  // result.push({
  //   name: 'stream name 1',
  //   date: date,
  //   value: value,
  //   valueStr: null,
  // });
  // string example:
  // result.push({
  //   name: 'stream name 2',
  //   date: date,
  //   value: null,
  //   valueStr: valueStr,
  // });
  return result;
}`,
      DataExample: "",
      DefaultExtension: "",
      OrganisationId: this.currentOrganisation ? this.currentOrganisation.Id : -1,
      Created: nowUtc,
      Updated: nowUtc,
      CreatedBy: this.allUserData.userName,
      UpdatedBy: this.allUserData.userName,
      AdditionalParameters: []
    };
    this.emailImportStore.inProgressScriptTest = false;
    this.emailImportStore.testResult = null;
    this.emailImportStore.testError = "";
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.emailImportStore.updateInProgressScripts;
  }

  get updateError(): boolean {
    return this.emailImportStore.updateErrorScripts;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.emailImportStore.createUpdateScript(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }

  async testScript(): Promise<void> {
    if (this.editRecord) {
      await this.emailImportStore.testScript(this.editRecord);
    }
  }

  get testResult(): string {
    if (this.emailImportStore.testResult) {
      return JSON.stringify(this.emailImportStore.testResult, null, 2);
    } else{
      return this.emailImportStore.testError ? this.emailImportStore.testError : "";
    }
  }

  ScriptAdditionalParameterType = ScriptAdditionalParameterType;

  get scriptAdditionalParameterTypes(): [ScriptAdditionalParameterType, string][] {
    const result = Object
      .keys(ScriptAdditionalParameterType)
      .map(key => [ScriptAdditionalParameterType[key as any], key])
      .filter(value => typeof value[0] === 'number') as [ScriptAdditionalParameterType, string][];
    return result;
  }

  validateAdditionalParameterName(event: Event, data: ScriptAdditionalParameter): void {
    // Remove any non-alphanumeric characters
    const sanitizedValue = (event.target as HTMLInputElement).value.replace(/[^a-zA-Z0-9_]/g, "");

    // Ensure the first character is alphabetic
    const firstChar = sanitizedValue.charAt(0);
    const isAlpha = /^[a-zA-Z]+$/.test(firstChar);

    if (isAlpha) {
      data.Name = sanitizedValue;
    } else {
      // If the first character is not alphabetic, remove it
      data.Name = sanitizedValue.slice(1);
    }
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: EmailImportScriptEntity | null = null;

  openConfirmation(record: EmailImportScriptEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete script?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Script',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.emailImportStore.deleteScript(this.selectedRecord);
    }
  }
  // #endregion delete
}

export default EmailImport2ScriptsView;
</script>