import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import NotificationGroupState from "./states/NotificationGroupState";
import { NotificationGroupEntity } from "@/models/notification/NotificationGroupEntity";
import DateHelper from "@/helpers/DateHelper";

export const useNotificationGroupStore = defineStore('notificationGroup', {
  state: (): NotificationGroupState => ({ 
    isLoaded: false,
    loadingInProgress: false,
    guid: "",
    entities: null,
    updateInProgress: false,
    updateError: false,

    isLoadedUser: false,
    guidUser: "",
    entitiesUser: null
  }),
  getters: {
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.loadingInProgress = true;
        const url = `rest/NotificationGroup_V1/organisation`;
        const response = await axios.get<NotificationGroupEntity[]>(url);
        if (this.guid === guid) {
          response.data.forEach(item => {
            item.Created = DateHelper.parseFromMongoDate(item.Created);
            item.Updated = DateHelper.parseFromMongoDate(item.Updated);
          });
          this.entities = response.data;
          this.isLoaded = true;
          this.loadingInProgress = false;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load notification groups",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
        this.loadingInProgress = false;
      }
    },
    async loadUser() {
      try {
        const guid = uuidv4();
        this.guidUser = guid;
        this.isLoadedUser = false;
        const url = `rest/NotificationGroup_V1/user`;
        const response = await axios.get<NotificationGroupEntity[]>(url);
        if (this.guidUser === guid) {
          response.data.forEach(item => {
            item.Created = DateHelper.parseFromMongoDate(item.Created);
            item.Updated = DateHelper.parseFromMongoDate(item.Updated);
            item.Users.forEach(user => {
              user.DateAdded = DateHelper.parseFromMongoDate(user.DateAdded);
            });
          });
          this.entitiesUser = response.data;
          this.isLoadedUser = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load notification groups",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entitiesUser = null;
        this.isLoadedUser = true;
      }
    },
    async createUpdate(entity: NotificationGroupEntity): Promise<NotificationGroupEntity | null> {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/NotificationGroup_V1`;
        const response = await axios.post<NotificationGroupEntity>(url, entity);
        ToastService.showToast("success", "Notification Groups", "Changes saved", 5000);
        this.updateInProgress = false;
        response.data.Created = DateHelper.parseFromMongoDate(response.data.Created);
        response.data.Updated = DateHelper.parseFromMongoDate(response.data.Updated);
        response.data.Users.forEach(user => {
          user.DateAdded = DateHelper.parseFromMongoDate(user.DateAdded);
        });
        if (this.isLoaded && this.entities) {
          if (entity.Id) {
            const index = this.entities.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.entities[index] = response.data;
            } else {
              this.entities.push(response.data);
            }
          } else {
            this.entities.push(response.data);
          }
        }
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save notification group",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return null;
      }
    },
    async delete(entities: NotificationGroupEntity[]): Promise<boolean> {
      try {
        const url = `rest/NotificationGroup_V1`;
        const ids = entities.map((x) => x.Id);
        await axios.delete(url, { data: ids });
        ToastService.showToast("success", "Notification Groups", `${entities.length} groups are deleted`, 5000);
        if (this.isLoaded && this.entities) {
          for (const entity of entities) {
            const index = this.entities.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.entities.splice(index, 1);
            }
          }
        }
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete notification groups`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return false;
      }
    }
  },
})
