<template>
  <BlockUI 
    :blocked="!reports3Elements.isLoadedOne || updateInProgress" 
    :autoZIndex="false" 
    :baseZIndex="100" 
    class="blockui-with-spinner blockui-with-fixed-spinner" 
    :class="(!reports3Elements.isLoadedOne || updateInProgress) ? 'blockui-blocked' : ''"
  >
    <div v-if="editRecord" class="flex flex-wrap lg:flex-nowrap h-full">
      <div class="p-3 w-full lg:w-6 bg-white">
        <div class="h-full relative">
          <div class="report-manager-sidebar">
            <Accordion :activeIndex="0" expandIcon="pi pi-plus" collapseIcon="pi pi-minus">
              <AccordionTab header="General">
                <div class="formgrid grid">
                  <div class="field col-12" v-if="canEditGlobal">
                    <div class="flex align-items-center">
                      <InputSwitch 
                        inputId="editRecordGlobal"
                        v-model="selectedEntityIsGlobal"
                        class="vertical-align-top"
                        :disabled="updateInProgress"
                      />
                      <label for="editRecordGlobal" class="mb-0 ml-2">Public</label>
                    </div>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="editRecordName">Name</label>
                    <div>
                      <InputText 
                        id="editRecordName" 
                        class="inputfield w-full"
                        type="text" 
                        v-model="editRecord.Name"
                        placeholder="Name"
                      />
                    </div>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="editRecordDescription">Description</label>
                    <div>
                      <InputText 
                        id="editRecordDescription" 
                        class="inputfield w-full"
                        type="text" 
                        v-model="editRecord.Description"
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div class="field col-12 md:col-6">
                    <label for="editRecordRole">Role</label>
                    <div>
                      <Dropdown 
                        inputId="editRecordRole"
                        v-model="editRecord.Role"
                        :options="roles" 
                        optionValue="key" 
                        optionLabel="name" 
                        placeholder="Select roel" 
                        class="w-full"
                        @change="onRoleChange()"
                      />
                    </div>
                  </div>
                  <div class="field col-12 md:col-6" v-if="editRecord.Role === Reports3ElementRole.Body">
                    <label for="editRecordDefaultSize">Default Size</label>
                    <div>
                      <Dropdown 
                        inputId="editRecordDefaultSize"
                        v-model="editRecord.DefaultSize"
                        :options="columns" 
                        optionValue="key" 
                        optionLabel="name" 
                        placeholder="Select size" 
                        class="w-full"
                      />
                    </div>
                  </div>
                  <div class="field col-12 md:col-6" v-if="editRecord.Role !== 0">
                    <label for="editRecordHeight">Height</label>
                    <div>
                      <InputNumber 
                        inputId="editRecordHeight" 
                        class="inputfield w-full"
                        v-model="editRecord.Height"
                        placeholder="Height"
                        suffix="cm"
                        :min="0"
                      />
                    </div>
                  </div>
                </div>
              </AccordionTab>

              <AccordionTab header="Data" v-if="editRecord.Role === Reports3ElementRole.Body">
                <div class="formgrid grid">
                  <div class="field col-12">
                    <div class="flex align-items-center">
                      <InputSwitch 
                        inputId="editRecordEnableData"
                        v-model="editRecord.EnableData"
                        class="vertical-align-top"
                        :disabled="updateInProgress"
                      />
                      <label for="editRecordEnableData" class="mb-0 ml-2">Enable</label>
                    </div>
                  </div>
                  <!-- <div class="field col-12" v-if="editRecord.EnableData">
                    <div class="flex align-items-center">
                      <InputSwitch 
                        inputId="editRecordDataOnlyTotalAggregation"
                        v-model="editRecord.DataOnlyTotalAggregation"
                        class="vertical-align-top"
                        :disabled="updateInProgress"
                      />
                      <label for="editRecordDataOnlyTotalAggregation" class="mb-0 ml-2">Data only total</label>
                    </div>
                  </div> -->
                  <div class="field col-12 md:col-6" v-if="editRecord.EnableData">
                    <label for="editRecordMaxCount">Max count</label>
                    <div>
                      <InputNumber 
                        inputId="editRecordMaxCount" 
                        class="inputfield w-full"
                        v-model="editRecord.DataStreamsMaxCount"
                        placeholder="Max count"
                        :min="0"
                      />
                    </div>
                  </div>
                </div>
              </AccordionTab>

              <AccordionTab header="Parameters">
                <div class="mb-0 formgrid grid">
                  <ScriptAdditionalParameterView 
                    v-if="editRecord" 
                    v-model="editRecord.AdditionalParameters" 
                    class="field col-12 mb-0"
                  />
                </div>
              </AccordionTab>

              <AccordionTab header="Code">
                <TabView class="mqtt-script-tabs field col-12 mb-0">
                  <TabPanel header="HTML">
                    <Codemirror
                      v-model="editRecord.HTML"
                      placeholder="HTML"
                      class="w-full"
                      :style="{ height: 'auto', 'min-height': '200px' }"
                      :indent-with-tab="false"
                      :tab-size="2"
                      :extensions="extensionsHTML"
                    />
                  </TabPanel>
                  <TabPanel header="JavaScript" v-if="editRecord.Role === Reports3ElementRole.Body">
                    <Codemirror
                      v-model="editRecord.JavaScript"
                      placeholder="JavaScript"
                      class="w-full"
                      :style="{ height: 'auto', 'min-height': '200px' }"
                      :indent-with-tab="false"
                      :tab-size="2"
                      :extensions="extensionsScript"
                    />
                  </TabPanel>
                  <TabPanel header="CSS">
                    <Codemirror
                      v-model="editRecord.CSS"
                      placeholder="CSS"
                      class="w-full"
                      :style="{ height: 'auto', 'min-height': '200px' }"
                      :indent-with-tab="false"
                      :tab-size="2"
                      :extensions="extensionsCSS"
                    />
                  </TabPanel>
                </TabView>
              </AccordionTab>
            </Accordion>
          </div>
        </div>
      </div>

      <div class="p-3 w-full lg:w-6">
        <div class="h-full relative">
          <div class="report-manager-content">
            <div class="formgrid grid">
              <div class="field col-12 mb-0">
                <div class="overflow-x-auto">
                  <div class="report-constructor-area" :style="editRecord.Role === Reports3ElementRole.Body ? 'padding: 10mm;' : ''" >
                    <ReportsHtmlElementView 
                      :element="editRecord"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import { Codemirror } from 'vue-codemirror';
import { xcodeDark, xcodeLight } from '@uiw/codemirror-theme-xcode';
import { javascript } from '@codemirror/lang-javascript';
import { css } from '@codemirror/lang-css';
import { html } from '@codemirror/lang-html';
import { indentWithTab } from "@codemirror/commands";
import { keymap } from "@codemirror/view";
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { ScriptAdditionalParameter } from "@/models/script/ScriptAdditionalParameter";
import ScriptAdditionalParameterView from "@/components/views/ScriptAdditionalParameterView.vue";
import { useOrganisationStore } from "@/stores/organisation";
import { useReports3ElementsStore } from "@/stores/reports3Elements";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { nextTick } from "vue";
import WidgetHelper from "@/helpers/WidgetHelper";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import ReportsHtmlElementView from "@/components/views/reports/ReportsHtmlElementView.vue";
import { useReports3Store } from "@/stores/reports3";

@Component({
  components: {
    Accordion,
    AccordionTab,
    Button,
    InputText,
    InputNumber,
    Dialog,
    BlockUI,
    InputSwitch,
    Message,
    ProgressSpinner,
    Dropdown,
    TabView,
    TabPanel,
    Codemirror,
    ScriptAdditionalParameterView,
    ReportsHtmlElementView
  },
  directives: {
  }
})
class ReportsElementView extends Vue { 
  @Prop({ required: true }) elementId!: string;

  reports3 = useReports3Store();
  reports3Elements = useReports3ElementsStore();

  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get selectedEntityIsGlobal(): boolean {
    return this.editRecord?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.editRecord) {
      if (value) {
        this.editRecord.OrganisationId = 0;
      } else {
        this.editRecord.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }

  get extensionsScript(): any[] {
    const result = [];
    if (this.isDarkTheme) {
      result.push(xcodeDark);
    } else {
      result.push(xcodeLight);
    }
    result.push(javascript());
    result.push(keymap.of([indentWithTab]));
    return result;
  }

  get extensionsHTML(): any[] {
    const result = [];
    if (this.isDarkTheme) {
      result.push(xcodeDark);
    } else {
      result.push(xcodeLight);
    }
    result.push(html());
    result.push(keymap.of([indentWithTab]));
    return result;
  }

  get extensionsCSS(): any[] {
    const result = [];
    if (this.isDarkTheme) {
      result.push(xcodeDark);
    } else {
      result.push(xcodeLight);
    }
    result.push(css());
    result.push(keymap.of([indentWithTab]));
    return result;
  }

  columns = WidgetHelper.getWidgetSizes();
  roles = [
    { name: 'Body', key: 0 },
    { name: 'Header', key: 1 },
    { name: 'Footer', key: 2 }
  ];

  get editRecord(): Reports3ElementEntity | null {
    return this.reports3Elements.dataOne;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  @Watch('elementId', { immediate: false, deep: false })
  async onIdChanged(val: string, oldVal: string): Promise<void> {
    this.reports3Elements.$reset();
    await nextTick();
    this.init();
  }

  @Watch('editRecord', { immediate: false, deep: true })
  onEditRecordChanged(val: Reports3ElementEntity | null, oldVal: Reports3ElementEntity | null): void {
    if (this.reports3Elements.isLoadedOne && oldVal !== null && val !== null && val.Id === oldVal.Id) {
      this.reports3Elements.isOneDirty = true;
    }
  }

  created() {
    this.init();
  }

  async init(): Promise<void> {
    if (this.authState.permissions?.FullAccess) {
      await this.reports3Elements.loadOne(this.elementId);
    }
  }

  Reports3ElementRole = Reports3ElementRole;

  onRoleChange(): void {
    if (this.editRecord) {
      if (!this.editRecord.Height) {
        switch (this.editRecord.Role) {
          case Reports3ElementRole.Header:
            this.editRecord.Height = 2;
            break;
          case Reports3ElementRole.Footer:
            this.editRecord.Height = 2;
            break;
        }
      }
    }
  }

  get updateInProgress(): boolean {
    return this.reports3Elements.updateInProgress;
  }

  ScriptAdditionalParameterType = ScriptAdditionalParameterType;

  get scriptAdditionalParameterTypes(): [ScriptAdditionalParameterType, string][] {
    const result = Object
      .keys(ScriptAdditionalParameterType)
      .map(key => [ScriptAdditionalParameterType[key as any], key])
      .filter(value => typeof value[0] === 'number') as [ScriptAdditionalParameterType, string][];
    return result;
  }

  validateAdditionalParameterName(event: Event, data: ScriptAdditionalParameter): void {
    // Remove any non-alphanumeric characters
    const sanitizedValue = (event.target as HTMLInputElement).value.replace(/[^a-zA-Z0-9_]/g, "");

    // Ensure the first character is alphabetic
    const firstChar = sanitizedValue.charAt(0);
    const isAlpha = /^[a-zA-Z]+$/.test(firstChar);

    if (isAlpha) {
      data.Name = sanitizedValue;
    } else {
      // If the first character is not alphabetic, remove it
      data.Name = sanitizedValue.slice(1);
    }
  }
}

export default ReportsElementView;
</script>