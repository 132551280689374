<template>
  <div>
    <div class="flex column-gap-3 align-items-center mb-2 pb-1">
      <label class="mb-0">Parameters</label>
      <Button
        @click="() => { additionalParameters.push({ Name: `parameter${(additionalParameters.length + 1)}`, Label: `Parameter ${(additionalParameters.length + 1)}`, DefaultValue: '', Type: ScriptAdditionalParameterType.String }); }"
        class="p-button-outlined p-button-sm text-sm flex-shrink-0"
        icon="pi pi-plus"
        label="Add"
      />
    </div>
    <div v-if="additionalParameters.length">
      <DataTable :value="additionalParameters" showGridlines responsiveLayout="stack" breakpoint="600px"
        class="mqtt-script-small-table responsive-breakpoint p-datatable-sm">
        <Column field="Name" header="Name" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 30%;">
          <template #body="{ data, field }">
            <InputText class="w-full" v-model="data[field]" @input="validateAdditionalParameterName($event, data)" />
          </template>
        </Column>
        <Column field="Label" header="Label" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 30%;">
          <template #body="{ data, field }">
            <InputText class="w-full" v-model="data[field]" />
          </template>
        </Column>
        <Column field="Type" header="Type" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 15%;">
          <template #body="{ data, field }">
            <Dropdown 
              v-model="data[field]" 
              :options="scriptAdditionalParameterTypes" 
              :optionLabel="(x: string): string => { return x[1]; }"
              :optionValue="(x: string): string => { return x[0]; }"
              @change="() => data.DefaultValue = data.Type === ScriptAdditionalParameterType.String ? '' : data.Type === ScriptAdditionalParameterType.Number ? 0 : false" 
              placeholder="Select type" 
              class="w-full"
            />
          </template>
        </Column>
        <Column field="DefaultValue" header="Default Value" headerClass="text-sm" bodyClass="text-sm" headerStyle="width: 25%;">
          <template #body="{ data, field }">
            <InputText 
              v-if="data.Type === ScriptAdditionalParameterType.String"
              class="w-full" 
              v-model="data[field]"
            />
            <InputNumber
              v-else-if="data.Type === ScriptAdditionalParameterType.Number"
              class="w-full" 
              v-model="data[field]"
              :minFractionDigits="2"
              :maxFractionDigits="20"
            />
            <InputSwitch 
              v-else-if="data.Type === ScriptAdditionalParameterType.Boolean"
              v-model="data[field]"
              class="vertical-align-middle"
            />
          </template>
        </Column>
        <Column field="" header="" headerStyle="width: 1%; min-width: 44px; border-left-color: transparent;"
          bodyStyle="text-align: right; justify-content: flex-end;">
          <template #body="{ index }">
            <Button icon="pi pi-trash"
              class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
              @click="() => { additionalParameters.splice(index, 1) }" />
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Model, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { ScriptAdditionalParameter } from "@/models/script/ScriptAdditionalParameter";

@Component({
  components: {
    Button,
    InputText,
    InputNumber,
    DataTable,
    Column,
    InputSwitch,
    Dropdown
  },
  directives: {
  }
})
class ScriptAdditionalParameterView extends Vue { 
  @Model({ required: true }) additionalParameters!: ScriptAdditionalParameter[];

  ScriptAdditionalParameterType = ScriptAdditionalParameterType;

  get scriptAdditionalParameterTypes(): [ScriptAdditionalParameterType, string][] {
    const result = Object
      .keys(ScriptAdditionalParameterType)
      .map(key => [ScriptAdditionalParameterType[key as any], key])
      .filter(value => typeof value[0] === 'number') as [ScriptAdditionalParameterType, string][];
    return result;
  }

  validateAdditionalParameterName(event: Event, data: ScriptAdditionalParameter): void {
    // Remove any non-alphanumeric characters
    const sanitizedValue = (event.target as HTMLInputElement).value.replace(/[^a-zA-Z0-9_]/g, "");

    // Ensure the first character is alphabetic
    const firstChar = sanitizedValue.charAt(0);
    const isAlpha = /^[a-zA-Z]+$/.test(firstChar);

    if (isAlpha) {
      data.Name = sanitizedValue;
    } else {
      // If the first character is not alphabetic, remove it
      data.Name = sanitizedValue.slice(1);
    }
  }
}

export default ScriptAdditionalParameterView;
</script>