<template>
  <div v-if="!listOptions.length && (of==='dict' || of==='list')" class="col-12 md:col-8 align-self-end semantics-edit-list-add">
    <!-- dict add button -->
    <Button label="Add Element to List" class="w-full add-element-to-list" @click="addItem" :disabled="disabledFields.includes(field)"/>
  </div>

  <div class="col-12 md:col-8 semantics-edit-list" v-if="listOptions.length > 0">
    <label :for="inputId">Value</label>
    <MultiSelect 
      :inputId="inputId"
      v-model="listVal"
      :options="listOptions"
      placeholder="Select Options"
      display="chip"
      :filter="true"
      :optionLabel="(x: string): string => { return x; }"
      class="p-multiselect-multiline inputfield w-full"
      :disabled="disabledFields.includes(field)"
    />
  </div>
  <div v-else-if="of==='dict' || of==='list'" class="semantics-tags-field-children col-12 semantics-edit-list">
    <!-- dict values -->
    <div
      v-for="(item, index) in valueHayson"
      :key="index"
      class="semantics-tags-field-child"
      :class="{ 'is-expanded': !collapse[index] }"
    >
      <div class="semantics-tags-field-child-inner">
        <div class="formgrid grid">
          <div class="col-12 flex align-items-center gap-2 semantics-tags-field-child-header">
            <h5 class="flex-auto semantics-tags-field-child-title">
              <span style="text-transform: capitalize;">{{ field }}</span> Element {{ index + 1 }}
            </h5>
            <Button 
              v-tippy="'Delete'"
              icon="pi pi-trash" 
              class="semantics-tags-control-btn p-button-icon-only p-button-rounded p-button-danger p-button-outlined flex-shrink-0" 
              @click="deleteItem(index)"
              :disabled="disabledFields.includes(field)"
            />
            
            <span @click="toggleCollapse(index)" class="semantics-tags-control-btn p-button p-component p-button-icon-only p-button-rounded p-button-outlined" :class="{ 'is-open': !collapse[index] }">
              <span class="pi p-button-icon" :class="{ 'pi-chevron-up': !collapse[index], 'pi-chevron-down': collapse[index] }"></span>
              <span class="p-button-label">&nbsp;</span>
            </span>
          </div>
          <transition name="p-toggleable-content">
            <div v-show="!collapse[index]" class="semantics-tags-field-child-body col-12">
              <div class="formgrid grid mt-3 flex-auto">
                <HaystackDictEditView
                  v-if="of==='dict'"
                  :inputId="`${inputId}-${index}`"
                  v-model="valueHayson" 
                  :field="index" 
                  :def="def"
                  :idPrefix="`${inputId}-${index}`"
                  :excludeFields="[]"
                  :mandatoryFields="[]"
                  :isLib="false"
                  :allowAny="true"
                  :parentList="true"
                />
                <HaystackListEditView
                  v-else
                  :inputId="`${inputId}-${index}`"
                  v-model="valueHayson" 
                  :field="index" 
                  :def="def"
                />
              </div>
            </div>
          </transition>
        </div>
      </div>
    </div>
  </div>
  <div class="col-12 md:col-6 semantics-edit-list" v-else>
    <label :for="inputId">Value</label>
    <Chips 
      :id="inputId"
      class="inputfield w-full"
      v-model="listVal"
      :disabled="disabledFields.includes(field)"
    />
  </div>
</template>

<script lang="ts">
import MultiSelect from 'primevue/multiselect';
import Chips from 'primevue/chips';
import Button from 'primevue/button';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonVal, Kind, HaysonList, HaysonKindVal, HDict, HList, HaysonDict } from "haystack-core";
import HaystackDefsService from '@/services/HaystackDefsService';
import { useUnitsStore } from '@/stores/units';
import HaystackDictEditView from "@/components/views/tags/haystack-edit/HaystackDictEditView.vue";

@Component({
  components: {
    MultiSelect,
    Chips,
    Button,
    HaystackDictEditView
  },
})
class HaystackListEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal[] {
    if (this.haysonDictModel) {
      return this.haysonDictModel[this.field] as HaysonVal[];
    }
    return [];
  }

  set valueHayson(value: HaysonVal[]) {
    if (this.haysonDictModel) {
      this.haysonDictModel[this.field] = value;
    }
  }

  unitsStore = useUnitsStore();

  get fieldDef(): HDict | undefined {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      const def = defs.get(this.field);
      return def;
    }
    return undefined;
  }

  get of(): string | undefined {
    const def = this.fieldDef;
    if (def) {
      const defOf = def.get("of");
      return defOf?.toString();
    }
    return undefined;
  }

  get tagOn(): string[] {
    const result: string[] = [];
    const def = this.fieldDef;
    if (def) {
      const defTagOn = def.get("tagOn");
      if (defTagOn) {
        (defTagOn as HList).forEach(x => {
          if (x) {
            result.push(x.toString());
          }
        });
      }
    }
    return result;
  }

  created(): void {
    this.initCollapse();
  }

  addItem(): void {
    if (this.of === "dict") {
      this.valueHayson.push({});
    } else {
      this.valueHayson.push([]);
    }
    this.collapse.push(false);
  }

  deleteItem(index: number): void {
    this.valueHayson.splice(index, 1);
    this.collapse.splice(index, 1);
  }

  // #region List
  // linear sequence of zero or more items
  get listVal(): any[] {
    if (this.valueHayson) {
      const result: any[] = [];
      (this.valueHayson as HaysonList).forEach(x => {
        if (x) {
          if (Object.prototype.hasOwnProperty.call(x, "val")) {
            result.push((x as HaysonKindVal).val);
          } else {
            let item: string | boolean | number | undefined = undefined;
            switch (typeof x) {
              case 'string':
                item = x;
                break;
              case 'number':
                item = x;
                break;
              case 'boolean':
                item = x;
                break;
            }
            if (item === undefined) {
              for (const key in (x as any)) {
                result.push(key);
              }
            } else {
              result.push(item);
            }
          }
        }
      });
      return result;
    }
    return [];
  }

  set listVal(value: any[]) {
    if (this.of === "ref" || this.field === "systemRef") {
      if (this.tagOn.includes("lib")) {
        this.valueHayson = value.map(x => { 
          return {
            _kind: Kind.Symbol,
            val: x
          }
        });
      } else {
        this.valueHayson = value.map(x => { 
          return {
            _kind: Kind.Ref,
            val: x
          }
        });
      }
    } else if (this.of === "symbol") {
      // examples: association, is, quantities, childrenFlatten
      this.valueHayson = this.valueHayson = value.map(x => { 
        return {
          _kind: Kind.Symbol,
          val: x
        }
      });
    } else if (this.of === "uri") {
      this.valueHayson = value.map(x => { 
        return {
          _kind: Kind.Uri,
          val: x
        }
      });
    } else {
      this.valueHayson = value;
    }
  }

  get listOptions(): string[] {
    const result: string[] = [];
    if (this.field !== "systemRef") {
      if (this.tagOn.includes("lib")) {
        const defs = HaystackDefsService.getDefs();
        if (defs) {
          const dependentLibs = HaystackDefsService.findDependentLibs(this.def);
          defs.libs.forEach(x => {
            if (x.defName !== this.def && !dependentLibs.includes(x.defName)) {
              result.push(x.defName);
            }
          });
        }
      } else if (this.tagOn.includes("def")) {
        const defs = HaystackDefsService.getDefs();
        if (defs) {
          for (const key in defs.defs) {
            if (key !== this.def) {
              result.push(key);
            }
          }
        }
      } else if (this.of) {
        if (this.of === "unit") {
          if (this.unitsStore.isLoaded && this.unitsStore.data) {
            this.unitsStore.data.forEach(x => {
              result.push(x.Value);
            })
          }
        } else if (this.of === "phenomenon") {
          const defs = HaystackDefsService.getDefs();
          if (defs) {
            defs.allSubTypesOf("phenomenon").forEach(x => result.push(x.defName));
          }
        } else if (this.of !== "dict" && this.of !== "list") {
          const defs = HaystackDefsService.getDefs();
          if (defs) {
            for (const key in defs.defs) {
              result.push(key);
            }
          }
        }
      }
    }
    return result;
  }
  // #endregion List

  // #region collapse
  collapse: boolean[] = [];

  initCollapse(): void {
    this.collapse = [];
    if (this.valueHayson.length) {
      this.valueHayson.forEach(element => {
        this.collapse.push(true);
      });
    }
  }

  toggleCollapse(index: number): void {
    this.collapse[index] = !this.collapse[index];
  }
  // #endregion collapse
}

export default HaystackListEditView;
</script>