<template>
  <svg id="widget" xmlns="http://www.w3.org/2000/svg" width="74.368" height="74.367" viewBox="0 0 74.368 74.367"><path id="Path_19610" data-name="Path 19610" d="M175.279,361.2a6.489,6.489,0,0,0-6.467,6.467v19.4a6.489,6.489,0,0,0,6.467,6.467h19.4a6.489,6.489,0,0,0,6.467-6.467v-19.4a6.489,6.489,0,0,0-6.467-6.467Zm0,3.234h19.4a3.193,3.193,0,0,1,3.234,3.234v19.4a3.193,3.193,0,0,1-3.234,3.234h-19.4a3.193,3.193,0,0,1-3.234-3.234v-19.4A3.192,3.192,0,0,1,175.279,364.434Z" transform="translate(-133.246 -319.166)" fill="none"/><path id="Path_19606" data-name="Path 19606" d="M175.279,361.2a6.489,6.489,0,0,0-6.467,6.467v19.4a6.489,6.489,0,0,0,6.467,6.467h19.4a6.489,6.489,0,0,0,6.467-6.467v-19.4a6.489,6.489,0,0,0-6.467-6.467Zm0,3.234h19.4a3.193,3.193,0,0,1,3.234,3.234v19.4a3.193,3.193,0,0,1-3.234,3.234h-19.4a3.193,3.193,0,0,1-3.234-3.234v-19.4A3.192,3.192,0,0,1,175.279,364.434Z" transform="translate(-168.812 -319.166)" fill="none"/><path id="Path_19608" data-name="Path 19608" d="M175.279,198.41a6.491,6.491,0,0,0-6.467,6.469v19.4a6.49,6.49,0,0,0,6.467,6.469h19.4a6.491,6.491,0,0,0,6.467-6.469v-19.4a6.49,6.49,0,0,0-6.467-6.469Zm0,3.235h19.4a3.193,3.193,0,0,1,3.234,3.235v19.4a3.193,3.193,0,0,1-3.234,3.235h-19.4a3.193,3.193,0,0,1-3.234-3.235v-19.4A3.192,3.192,0,0,1,175.279,201.645Z" transform="translate(-168.812 -191.943)" fill="none"/><path id="Path_19609" data-name="Path 19609" d="M338.062,168.81a6.482,6.482,0,0,0-6.457,6.469v25.864a6.481,6.481,0,0,0,6.457,6.469h25.875a6.492,6.492,0,0,0,6.469-6.469V175.279a6.492,6.492,0,0,0-6.469-6.469Zm0,3.235h25.875a3.193,3.193,0,0,1,3.234,3.235v25.864a3.193,3.193,0,0,1-3.234,3.235H338.062a3.193,3.193,0,0,1-3.235-3.235V175.278a3.193,3.193,0,0,1,3.235-3.235Z" transform="translate(-296.038 -168.81)" fill="none"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class WidgetSvg extends Vue {
}

export default WidgetSvg;
</script>