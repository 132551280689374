import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import NavTreeState from "./states/NavTreeState";
import { v4 as uuidv4 } from "uuid";
import { NavTree } from "@/models/nav-tree/NavTree";
import { NavTreeNode } from "@/models/nav-tree/NavTreeNode";
import { NavTreeNodeType } from "@/models/nav-tree/NavTreeNodeType";
import { defineStore } from "pinia";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";

export const useNavTreeStore = defineStore('navTree', {
  state: (): NavTreeState => ({ 
    isLoaded: false,
    loadingInProgress: false,
    guid : "",
    structuredDataForUI: null,
  
    unstructuredIsLoaded: false,
    unstructuredLoadingInProgress: false,
    unstructuredGuid: "",
    unstructuredDataForUI: null
  }),
  getters: {
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.loadingInProgress = true;
        const url = `public/v3/nav-tags?tagged=true`;
        const response = await axios.get<NavTree>(url);
        if (this.guid === guid) {
          // this.structuredData = response.data;
          this.structuredDataForUI = this.navTreeToUIStructure(response.data.Nodes, true);
          this.isLoaded = true;
          this.loadingInProgress = false;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load structured data",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        // this.structuredData = null;
        this.structuredDataForUI = null;
        this.isLoaded = true;
        this.loadingInProgress = false;
      }
    },
    async loadUnstructured() {
      try {
        const guid = uuidv4();
        this.unstructuredGuid = guid;
        this.unstructuredIsLoaded = false;
        this.unstructuredLoadingInProgress = true;
        const url = `public/v3/nav-tags?tagged=false`;
        const response = await axios.get<NavTree>(url);
        if (this.unstructuredGuid === guid) {
          // this.unstructuredData = response.data;
          this.unstructuredDataForUI = this.navTreeToUIStructure(response.data.Nodes, true);
          this.unstructuredIsLoaded = true;
          this.unstructuredLoadingInProgress = false;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load unstructured data",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        // this.unstructuredData = null;
        this.unstructuredDataForUI = null;
        this.unstructuredIsLoaded = true;
        this.unstructuredLoadingInProgress = false;
      }
    },
    navTreeToUIStructure(source: NavTreeNode[], root = false): TreeNodeForUI[] {
      const result: TreeNodeForUI[] = [];
      if (source?.length) {
        source.forEach(element => {
          result.push({
            key: element.Key, 
            label: element.Name,
            icon: (root || element.NodeType === NavTreeNodeType.Pool) ? 
              "pi pi-fw pi-building" : 
              (element.NodeType === NavTreeNodeType.Stream ? 
                `pi pi-fw pi-circle-fill ${element.Active ? "text-green-500" : "text-red-500"}` : 
                element.Tags.includes("equip") ?
                  element.Tags.includes("elec") ? 
                    "pi pi-fw pi-bolt" : 
                    (element.Tags.includes("light") || element.Tags.includes("lighting")) ?
                      "pi pi-fw pi-lightbulb" :
                      (element.Tags.includes("water") && element.Tags.includes("meter")) ?
                        "pi pi-fw pi-gauge" :
                        element.Tags.includes("sensor") ?
                          "pi pi-fw pi-microchip" :
                    "pi pi-fw pi-wrench" :
                  "pi pi-fw pi-box"
              ),
            children: element.Nodes?.length ? this.navTreeToUIStructure(element.Nodes) : [],
            leaf: !element.Nodes?.length,
            selectable: element.NodeType === NavTreeNodeType.Stream,
            isRoot: root || element.NodeType === NavTreeNodeType.Pool,
            tags: element.Tags?.length ? element.Tags : [],
            dataType: element.DataType
          });
        });
      }
      return result;
    }
  },
})
