import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { GenericTree } from "@/models/tree/GenericTree";
import DashboardState from "./states/DashboardState";
import { Space } from "@/models/dashboard/Space";
import { useOrganisationStore } from "./organisation";

export const useDashboardStore = defineStore('dashboard', {
  state: (): DashboardState => ({ 
    isLoaded: false,
    guid: "",
    entities: null
  }),
  getters: {
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.entities = null;
        const organisationStore = useOrganisationStore();
        const organisationId = organisationStore.currentOrganisation?.Id;
        const url = `rest/BitPool_V2/os/space2?organisationId=${organisationId}`;
        const response = await axios.get<Space[]>(url);
        if (this.guid === guid) {
          this.entities = response.data as Space[];
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load dashboards",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
      }
    },
    buildTree(): [GenericTree<Space>[], GenericTree<Space>[]] {
      const result: GenericTree<Space>[] = [];
      const resultFlat: GenericTree<Space>[] = [];

      if (this.entities?.length) {
        const holder: GenericTree<Space> = {
          key: "_holder", 
          label: "Holder",
          icon: undefined,
          children: [],
          leaf: false,
          selectable: false,
          isRoot: true,
          originalData: undefined
        };
        const sortedEntities = this.entities
          .sort((a, b) => (a.path ?? "_root").localeCompare(b.path ?? "_root"));
        for (const entity of sortedEntities) {
          const item: GenericTree<Space> = {
            key: entity._id,
            label: entity.spaceName,
            icon: undefined,
            children: [],
            leaf: true,
            selectable: !!entity.path,
            isRoot: !entity.path,
            originalData: entity
          };
          if (entity.path) {
            const pathParts = entity.path.split(",").filter(x => x);
            // Search parents
            let parent = holder;
            for (const part of pathParts) {
              const child = parent.children?.find(x => x.key === part);
              if (child) {
                parent = child;
              } else {
                break;
              }
            }
            if (!item.isRoot) {
              resultFlat.push(item);
            }
            parent.children?.push(item);
            parent.leaf = false;
          } else {
            if (!item.isRoot) {
              resultFlat.push(item);
            }
            holder.children?.push(item);
          }
        }
        if (holder.children) {
          for (const item of holder.children) {
            if (item.children) {
              for (const item2 of item.children) {
                result.push(item2);
              }
            }
          }
        }
      }

      return [result, resultFlat];
    }
  },
})
