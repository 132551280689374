<template>
  <div class="stream-details stream-details-visual mt-5 md:mt-4 xl:mt-5">
    <header class="stream-details-visual-header">
      <h2 class="mb-0">Stream Quality</h2>
    </header>
    <div  class="stream-details-visual-body">
      <div v-if="isLoaded" class="stream-details-quality grid">
        <div class="stream-details-quality-item col-12 md:col-6 lg:col-4 hd:col-3">
          <h3>Source Data Breakdown</h3>
          <div class="flex justify-content-center align-items-center pb-3 md:pb-5 lg:pb-0">
            <highcharts 
              v-if="redrawChartToggle"
              ref="chartSourceDataBreakdown" 
              class="" 
              :options="chartOptionsSourceDataBreakdown"
            >
            </highcharts>
          </div>
        </div>
        <div class="stream-details-quality-item col-12 md:col-6 lg:col-4 hd:col-4 fhd:col-3">
          <h3>Data Type Breakdown</h3>
          <div class="flex justify-content-center align-items-center pb-5 lg:pb-0">
            <div class="stream-details-data-type-chart">
              <div class="stream-details-data-type-chart-value">
                <div :style="{ height: monthSourcePercentStr }">
                </div>
                <div :style="{ height: monthMLPercentStr }">
                  <label :style="monthMLPercentRounded === 0 ? 'display: none' : ''"><span>Machine Learned</span><span>{{ monthMLPercentStr }}</span></label>
                </div>
                <div :style="{ height: monthEstimatedPercentStr }">
                </div>
              </div>
              <span class="stream-details-data-type-chart-bg">
                <span :style="{ height: monthSourcePercentStr }"></span>
                <span :style="{ height: monthMLPercentStr }"></span>
                <span :style="{ height: monthEstimatedPercentStr }"></span>
              </span>
              <div class="stream-details-data-type-chart-value">
                <div :style="{ height: monthSourcePercentStr}">
                  <label :style="monthSourcePercentRounded === 0 ? 'display: none' : ''"><span>Source</span><span>{{ monthSourcePercentStr }}</span></label>
                </div>
                <div :style="{ height: monthMLPercentStr }">
                </div>
                <div :style="{ height: monthEstimatedPercentStr }">
                  <label :style="monthEstimatedPercentRounded === 0 ? 'display: none' : ''"><span>Estimated</span><span>{{ monthEstimatedPercentStr }}</span></label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="stream-details-quality-item with-border-top col-12 lg:col-4 hd:col-5 fhd:col-6">
          <h3><span>Data Quality Rating</span><span v-tippy="'The Data Quality Rating is a measure of how satisfactory a data stream is. A lower number indicates a lower stream quality. A higher number indicates a higher stream quality.'"><HelpIconSvg/></span></h3>
          <div class="flex justify-content-center align-items-center pb-3 lg:pb-0">
            <div class="stream-details-data-quality-rating" :class="dataQualityRatingClass">
              <div class="stream-details-data-quality-rating-progressbar">
                <div class="stream-details-data-quality-rating-track">
                  <div :style="{ 'width': `${dataQualityRating}%` }" class="stream-details-data-quality-rating-progress">
                    <span :class="dataQualityRating < 5 ? 'translate-right' : dataQualityRating > 90 ? 'translate-left' : ''">{{ dataQualityRating }}</span>
                  </div>
                </div>
              </div>
              <div class="stream-details-data-quality-rating-legend">
                <i v-if="dataQualityRating < 20">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M31.333 16.656 16.667 31.323m0-14.667 14.666 14.667M46 24c0 12.15-9.85 22-22 22S2 36.15 2 24 11.85 2 24 2s22 9.85 22 22Z"/></svg>
                </i>
                <i v-if="dataQualityRating >= 20 && dataQualityRating < 40">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M24 15.2V24m0 8.8h.022M46 24c0 12.15-9.85 22-22 22S2 36.15 2 24 11.85 2 24 2s22 9.85 22 22Z"/></svg>
                </i>
                <i v-if="dataQualityRating >= 40 && dataQualityRating < 60">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M32.8 24H15.2M46 24c0 12.15-9.85 22-22 22S2 36.15 2 24 11.85 2 24 2s22 9.85 22 22Z"/></svg>
                </i>
                <i v-if="dataQualityRating >= 60 && dataQualityRating < 80">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 48 48"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M35 18.49 21.135 31.322l-6.302-5.834M46 24c0 12.15-9.85 22-22 22S2 36.15 2 24 11.85 2 24 2s22 9.85 22 22Z"/></svg>
                </i>
                <i v-if="dataQualityRating >= 80">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 49 49"><path fill-rule="evenodd" d="M24.5 48.677c13.352 0 24.176-10.824 24.176-24.176C48.676 11.148 37.852.324 24.5.324 11.147.324.323 11.148.323 24.501c0 13.352 10.824 24.176 24.177 24.176Zm0-3c11.695 0 21.176-9.48 21.176-21.176 0-11.696-9.48-21.177-21.176-21.177-11.696 0-21.177 9.481-21.177 21.177 0 11.695 9.481 21.176 21.177 21.176Zm1.246-34.96 10.065 5.809v.001a2.503 2.503 0 0 1 1.248 2.161v11.625c0 .887-.478 1.716-1.248 2.16l-10.065 5.811a2.493 2.493 0 0 1-2.494 0l-10.064-5.81a2.503 2.503 0 0 1-1.247-2.161V18.688c0-.888.478-1.718 1.247-2.162l10.064-5.81a2.5 2.5 0 0 1 2.494 0Zm9.385 20.577c.35-.201.567-.578.567-.981V18.686c0-.403-.218-.78-.567-.98l-10.065-5.813a1.13 1.13 0 0 0-1.134 0l-10.064 5.814c-.35.2-.566.577-.566.981v11.625c0 .403.216.78.566.981l10.064 5.812c.35.201.785.201 1.134 0l10.065-5.812Zm-6.898-4.64v-4.31l-3.734-2.156-3.734 2.156v4.31l3.734 2.157 3.734-2.156Zm-8.783-9.382-3.733 2.155V29.57l2.57 1.485V20.912l3.733-2.156-2.57-1.484Zm11.26 10.815L24.5 31.675l-3.734-2.157v2.969l3.734 2.156 8.782-5.072v-2.97l-2.57 1.483v.003ZM24.5 14.357h-.002.002Zm0 0 8.78 5.072v4.309l-2.57 1.485v-4.31l-6.212-3.586-2.571-1.485 2.572-1.485Z" clip-rule="evenodd"/></svg>
                </i>
                <div>
                  The DQR of this stream is 
                  <span class="stream-details-data-quality-rating-legend-value">{{ dataQualityRatingText }}</span>
                  <span class="stream-details-data-quality-rating-legend-help-text"><b>Learn more</b> about how you can improve the quality of your data.</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="progress-spinner-container">
        <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { StreamModel } from "@/models/StreamModel";
import { Component, Vue } from "vue-facing-decorator";
import ProgressSpinner from 'primevue/progressspinner';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import StreamState from "@/store/states/StreamState";
import ColorHelper from "@/helpers/ColorHelper";
import { nextTick } from "vue";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import HelpIconSvg from "@/components/svg/HelpIconSvg.vue";

@Component({
  components: {
    ProgressSpinner,
    highcharts: Chart,
    HelpIconSvg
  }
})
class StreamDetailsQualityView extends Vue {
  get isLoaded(): boolean {
    return this.$store.state.stream.isLoaded && this.$store.state.stream.isLoadedStreamQualityMetrics;
  }

  get stream(): StreamModel | null {
    return this.$store.state.stream.stream;
  }

  get streamState(): StreamState {
    return this.$store.state.stream;
  }

  created(): void {
    this.loadData();
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
  }
  
  redrawChartToggle = true;

  async resizeWidgetEvent(): Promise<void> {
    this.redrawChartToggle = false;
    await nextTick();
    this.redrawChartToggle = true;
  }

  loadData(): void {
    this.$store.dispatch("stream/loadStreamQualityMetrics");
  }

  get chartOptionsSourceDataBreakdown(): Highcharts.Options {
    const data: Array<Highcharts.PointOptionsObject> = [
      {
        name: "Source", 
        y: 100 - this.streamState.monthMissingPercent,
        color: "#7ed4ea"
      },
      {
        name: "Missing", 
        y: this.streamState.monthMissingPercent,
        color: "#1793ba"
      }
    ];
    const series: Highcharts.SeriesOptionsType[] = [{
      name: '',
      colorByPoint: true,
      innerSize: "72%",
      type: 'pie',
      data: data
    }];
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "pie",
        animation: true,
        style: {
          color: ColorHelper.getDefautTextColor(),
        },
        plotShadow: false,
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          }
        }
      },
      tooltip: {
        formatter: function() {
          return `<span style="color:${this.color};">\u25CF </span><b>${this.key}:</b> ${(this.y ?? 0).toFixed(2)}%`;
        },
        useHTML: true,
        outside: true
      },
      plotOptions: {
        pie: {
          size: 176,
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: true,
            format: '{point.percentage:.0f} %',
            style: {
              fontSize: "16px"
            }
          },
          showInLegend: true,
          borderWidth: 0
        }
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        itemStyle: {
          font: "300 14px 'Poppins', 'Arial, sans-serif",
          verticalAlign: 'middle'
        },
        symbolHeight: 12,
        symbolWidth: 12,
        maxHeight: 40
      },
      series: series
    }
  }

  get monthEstimatedPercentRounded(): number {
    return Math.round(this.streamState.monthEstimatedPercent * 100) / 100;
  }

  get monthEstimatedPercentStr(): string {
    return `${this.monthEstimatedPercentRounded}%`;
  }

  get monthSourcePercentRounded(): number {
    return Math.round((this.streamState.monthMissingPercent === 100 ? 0 : (100 - this.monthEstimatedPercentRounded)) * 100) / 100;
  }

  get monthSourcePercentStr(): string {
    return `${this.monthSourcePercentRounded}%`;
  }

  get monthMLPercentRounded(): number {
    return 0;
  }

  get monthMLPercentStr(): string {
    return `${this.monthMLPercentRounded}%`;
  }

  get dataQualityRating(): number {
    if (this.stream?.Virtual) {
      // all data in virtual stream is estimated, so we should not use estimated percent to calculate rating
      let result = 100 - this.streamState.monthMissingPercent;
      result = Math.round(result);
      return result;
    } else {
      const v1 = this.streamState.monthMissingPercent === 100 ? 0 : (100 - this.streamState.monthEstimatedPercent);
      const v2 = 100 - this.streamState.monthMissingPercent;
      let result = (v1 + v2) / 2;
      result = Math.round(result);
      return result;
    }
  }

  get dataQualityRatingText(): string {
    if (this.dataQualityRating < 20) {
      return "Very Poor";
    } else if (this.dataQualityRating < 40) {
      return "Poor";
    } else if (this.dataQualityRating < 60) {
      return "Average";
    } else if (this.dataQualityRating < 80) {
      return "Good";
    } else {
      return "Excellent";
    }
  }

  get dataQualityRatingClass(): string {
    if (this.dataQualityRating < 20) {
      return "very-poor-rating";
    } else if (this.dataQualityRating < 40) {
      return "poor-rating";
    } else if (this.dataQualityRating < 60) {
      return "average-rating";
    } else if (this.dataQualityRating < 80) {
      return "good-rating";
    } else {
      return "excellent-rating";
    }
  }
}

export default StreamDetailsQualityView;
</script>
