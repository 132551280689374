import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import UnitsState from "./states/UnitsState";
import { UnitsModel } from "@/models/nav-tree/UnitsModel";

export const useUnitsStore = defineStore('units', {
  state: (): UnitsState => ({ 
    isLoaded: false,
    guid : "",
    data: null
  }),
  getters: {
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        const url = `rest/Units_V1/units`;
        const response = await axios.get<UnitsModel[]>(url);
        if (this.guid === guid) {
          this.data = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load units",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = null;
        this.isLoaded = true;
      }
    }
  },
})
