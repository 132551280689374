<template>
  <aside class="sidebar sidebar-with-nav h-full" :class="showDashboardTreeWithDelay && displaySection === 'dashboard' ? 'has-dashboard-menu' : ''">
    <Button type="button" @click="onMinimizeClick" v-if="!auth.jailMode && !isSidebarMode" class="p-button-text p-button-icon-only p-button-lg minimize-menu">
        <span class="p-button-icon-group p-button-icon-left flex justify-content-center align-items-center w-full h-full">
          <i class="pi pi-angle-left p-button-icon -mr-3"></i>
          <i class="pi pi-angle-left p-button-icon"></i>
        </span>
    </Button>
    <div class="logo-container">
      <img 
        v-if="auth.jailMode"
        :src="'/assets/bitpool-logo-white.svg'"
        width="180"
        alt="Bitpool"
      />
      <a v-else href="/">
        <img
          :src="'/assets/bitpool-logo-white.svg'"
          width="180"
          alt="Bitpool"
        />
        <span class="logo-mini">
          <img
            :src="'/assets/bitpool-icon-white.svg'"
            width="43"
            alt="Bitpool"
          />
        </span>
      </a>
    </div>
    <h4 v-if="displaySection === 'presentation'" class="menu-presentation-title">Slides</h4>
    <nav class="main-navigation">
      <transition name="slide-out">
        <div v-if="showDashboardTreeWithDelay && displaySection === 'dashboard' || auth.jailMode && displaySection !== 'presentation'" key="dashboard" class="dashboard-menu">
          <DashboardTreeRootView :showMainMenu="showMainMenu" :afterNavigateTo="afterNavigateTo"/>
        </div>
        <PanelMenu v-else :class="`menu-${displaySection}`" key="navigation" :model="navigationItems" v-model:expandedKeys="expandedKeys"></PanelMenu>
      </transition>
    </nav>
  </aside>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';
import PanelMenu from 'primevue/panelmenu';
import DashboardTreeRootView from "./dashboards/DashboardTreeRootView.vue";
import DashboardState from "@/store/states/DashboardState";
import { Watch } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import NavigationHelper from "@/helpers/NavigationHelper";
import { reactive } from "vue";
import { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";
import PresentationState from "@/store/states/PresentationState";
import RootState from "@/store/states/RootState";
import EventBusHelper from "@/helpers/EventBusHelper";
import { Emitter } from "mitt";
import localeManager from "@/localeManager";

@Component({
  components: {
    Button,
    Sidebar,
    PanelMenu,
    DashboardTreeRootView
  },
})
class NavMenuView extends Vue {
  @Prop({ required: true }) displaySection!: string;
  @Prop({ required: true }) afterNavigateTo!: () => void;
  
  expandedKeys: Record<string, boolean> = {};
  showDashboardTreeInternal = false;
  get showDashboardTree(): boolean {
    return this.showDashboardTreeInternal;
  }
  set showDashboardTree(value: boolean) {
    this.showDashboardTreeInternal = value;
    this.onShowDashboardTreeChanged();
  }

  get rootState(): RootState {
    return this.$store.state;
  }

  get minimizeNav(): boolean {
    return this.rootState.minimizeNav;
  }

  set minimizeNav(value: boolean) {
    this.$store.commit("setMinimizeNav", value);
  }

  get isSidebarMode(): boolean {
    return this.rootState.isSidebarMode;
  }

  set isSidebarMode(value: boolean) {
    this.$store.commit("setIsSidebarMode", value);
  }

  get isMiniMode(): boolean {
    return this.minimizeNav && !this.isSidebarMode;
  }

  get showDashboardTreeCalc(): boolean {
    return this.showDashboardTree && !this.isMiniMode;
  }

  showDashboardTreeWithDelay = this.showDashboardTreeCalc;
  showDashboardTreeWithDelayTimeout: number | undefined = undefined;

  // watch is repalced by setter to prevent delay and animation
  //@Watch('showDashboardTree', { immediate: false, deep: false })
  onShowDashboardTreeChanged(): void {
    this.showDashboardTreeWithDelay = this.showDashboardTreeCalc;
  }

  @Watch('isMiniMode', { immediate: false, deep: false })
  onIsMiniModeChanged(): void {
    if (this.showDashboardTreeWithDelayTimeout) {
      clearTimeout(this.showDashboardTreeWithDelayTimeout);
      this.showDashboardTreeWithDelayTimeout = undefined;
    }
    if (this.isMiniMode) {
      this.showDashboardTreeWithDelay = this.showDashboardTreeCalc;
    } else {
      this.showDashboardTreeWithDelayTimeout = window.setTimeout(() => {
        this.showDashboardTreeWithDelay = this.showDashboardTreeCalc;
      }, 200);
    }
  }

  get auth(): AuthState {
    return this.$store.state.auth;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get presentationState(): PresentationState {
    return this.$store.state.presentation;
  }

  get dashboardType(): string {
    return this.dashboardState.dashboardType;
  }

  @Watch('displaySection', { immediate: false, deep: false })
  onDisplaySectionChanged(): void {
    this.showDashboardTree = this.displaySection === 'dashboard';
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created() : void {
    this.refresh();
    this.onDisplaySectionChanged();
  }

  refresh(): void {
    for (const item of this.navigationItems) {
      if (item.items && item.items.length) {
        for (const child of item.items)
          if (child.class === "active" && item.key) {
            this.expandedKeys[item.key] = true;
            break;
          }
      }
    }
  }

  showMainMenu(): void {
    this.showDashboardTree = false;
  }

  sizeChangeTimeout: number | undefined = undefined;

  onMinimizeClick(): void {
    this.minimizeNav = !this.minimizeNav;
    if (this.sizeChangeTimeout) {
      clearTimeout(this.sizeChangeTimeout);
      this.sizeChangeTimeout = undefined;
    }
    this.sizeChangeTimeout = window.setTimeout(() => {
      this.sizeChangeTimeout = undefined;
      this.emitter.emit("size_changed", "nav-menu");
    }, 250);
  }

  navigateTo(url: string): boolean {
    const result = NavigationHelper.goTo(url);
    if (result) {
      if (this.afterNavigateTo) {
        this.afterNavigateTo();
      }
    }
    return result;
  }

  get navigationItems(): MenuItem[] {
    if (this.displaySection === 'presentation') {
      const result: MenuItem[] = [];
      const urlSearchParams = new URLSearchParams(window.location.search);
      const currentSlide = urlSearchParams?.get("slide") ?? "1";
      if (this.presentationState.isLoadedCurrentPresentation && this.presentationState.currentPresentation?.dashboards.length) {
        this.presentationState.currentPresentation.dashboards.forEach((slide, index) => {
          const slideStr = `${index + 1}`;
          result.push({
            key: `slide-${index}`,
            label: `${slide.name ? slide.name : "NO NAME"}`,
            icon: undefined,
            command: (event: MenuItemCommandEvent) => {
              NavigationHelper.goToSlide(slideStr);
              if (this.afterNavigateTo) {
                this.afterNavigateTo();
              }
            },
            url: `${window.location.pathname}?slide=${slideStr}`,
            class: (currentSlide === slideStr) ? "active" : ""
          });
        });
      }
      // https://github.com/primefaces/primevue/issues/2268
      return result.map((item) => reactive(item));
    } else {
      const isAdminOrOrgAdmin = !!this.auth.permissions?.FullAccess
      const isGroupAdmin = !!this.auth.permissions?.GroupAccess;
      const result: MenuItem[] = [
        location.hostname.includes("tsv.") ? {
          key: "Dashboards",
          label: localeManager.t("navigation.dashboards"),
          icon: "dashboard-icon",
          url: "/dashboard"
        } : {
          key: "DashboardNext",
          label: localeManager.t("navigation.dashboards"),
          icon: "dashboard-icon",
          command: (event: MenuItemCommandEvent) => {
            if (this.displaySection === "dashboard") {
              this.showDashboardTree = true;
            } else {
              if (!this.navigateTo("/dashboards")) {
                event.originalEvent.preventDefault();
              }
            }
            if (this.minimizeNav) {
              window.setTimeout(() => {
                this.onMinimizeClick();
              }, 1);
            }
          },
          url: this.displaySection === "dashboard" ? undefined : "/dashboards",
          class: (this.displaySection === "dashboard") ? "active" : ""
        },
        {
          key: "Data",
          label: localeManager.t("navigation.data"),
          icon: "data-icon",
          command: (event: MenuItemCommandEvent) => {
            if (!this.navigateTo("/data/pools")) {
              event.originalEvent.preventDefault();
            }
          },
          url: "/data/pools",
          class: (this.displaySection === "pools" || this.displaySection === "pool" || this.displaySection === "stream") ? "active" : ""
        },
        {
          key: "Flows",
          label: localeManager.t("navigation.flows"),
          icon: "flows-icon",
          command: (event: MenuItemCommandEvent) => {
            if (!this.navigateTo("/data/flow")) {
              event.originalEvent.preventDefault();
            }
          },
          url: "/data/flow",
          class: (this.displaySection === "flows" || this.displaySection === "flow") ? "active" : "",
          visible: isAdminOrOrgAdmin
        },
        {
          key: "Presentations",
          label: localeManager.t("navigation.presentations"),
          icon: "presentation-icon",
          command: (event: MenuItemCommandEvent) => {
            if (!this.navigateTo("/dashboards/presentation")) {
              event.originalEvent.preventDefault();
            }
          },
          visible: !location.hostname.includes("tsv."),
          url: "/dashboards/presentation",
          class: this.displaySection === "presentations" || this.displaySection === "presentation-edit" ? "active" : ""
        },
        {
          separator: true,
          class: "p-menuitem-separator"
        },
        {
          key: "Tools",
          label: localeManager.t("navigation.tools"),
          icon: "",
          class: "p-menuitem-section-title"
        },
        {
          key: "MQTTScheduler",
          label: localeManager.t("navigation.scheduler"),
          icon: "mqtt-scheduler-icon",
          command: (event: MenuItemCommandEvent) => {
            if (!this.navigateTo("/data/scheduler")) {
              event.originalEvent.preventDefault();
            }
          },
          url: "/data/scheduler",
          class: (this.displaySection === "mqtt-scheduler" || this.displaySection === "mqtt-scheduler-edit") ? "active" : "",
          // primevue bug, submenu using className instead of class
          className: (this.displaySection === "mqtt-scheduler" || this.displaySection === "mqtt-scheduler-edit") ? "active" : ""
        },
        {
          key: "Notifications",
          label: localeManager.t("navigation.notifications"),
          icon: "notifications-icon",
          command: (event: MenuItemCommandEvent) => {
            if (!this.navigateTo("/notifications")) {
              event.originalEvent.preventDefault();
            }
          },
          url: "/notifications",
          class: this.displaySection === "notifications" ? "active" : "",
        },
        {
          key: 'Connections',
          label: localeManager.t("navigation.connections"),
          icon: "connections-icon",
          class: (
            this.displaySection === "mqtt" || 
            this.displaySection === "haystack" || 
            this.displaySection === "nube-io" || 
            this.displaySection === "bintracker" || 
            this.displaySection === "api-keys" || 
            this.displaySection === "import" || 
            this.displaySection === "uploader" ||
            this.displaySection === "email") ? "has-children active" : "has-children",
          visible: isAdminOrOrgAdmin,
          items: [
            {
              key: "MQTT",
              label: localeManager.t("navigation.mqtt"),
              icon: "mqtt-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/data/mqtt")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/data/mqtt",
              class: (this.displaySection === "mqtt") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "mqtt") ? "active" : ""
            },
            {
              key: "TheThingsNetwork",
              label: localeManager.t("navigation.ttn"),
              icon: "things-network-icon",
              command: (event: MenuItemCommandEvent) => {
                window.open("https://ttn.bitpool.com/", "_blank");
              },
              visible: this.auth.permissions?.BitpoolAdmin
            },
            {
              key: "Haystack",
              label: localeManager.t("navigation.haystack"),
              icon: "haystack-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/data/haystack")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/data/haystack",
              class: (this.displaySection === "haystack") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "haystack") ? "active" : ""
            },
            {
              key: "Nube iO",
              label: localeManager.t("navigation.nubeIO"),
              icon: "nube-io-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/data/nube-io")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/data/nube-io",
              class: (this.displaySection === "nube-io") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "nube-io") ? "active" : ""
            },
            {
              key: "Bintracker",
              label: localeManager.t("navigation.bintracker"),
              icon: "bintracker-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/data/bintracker")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/data/bintracker",
              class: (this.displaySection === "bintracker") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "bintracker") ? "active" : ""
            },
            {
              key: "ApiKeys",
              label: localeManager.t("navigation.apiKeys"),
              icon: "apikeys-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/data/api-keys")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/data/api-keys",
              class: (this.displaySection === "api-keys") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "api-keys") ? "active" : ""
            },
            {
              key: "WindowsUploader",
              label: localeManager.t("navigation.virtualEdge"),
              icon: "windows-uploader-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/data/uploader")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/data/uploader",
              class: this.displaySection === "uploader" ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: this.displaySection === "uploader" ? "active" : "",
              visible: this.auth.permissions?.FullAccess
            },
            {
              key: "EmailImport",
              label: localeManager.t("navigation.emailImport"),
              icon: "email-import-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/data/email")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/data/email",
              class: this.displaySection === "email" ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: this.displaySection === "email" ? "active" : "",
              visible: this.auth.permissions?.FullAccess
            },
            {
              key: "Import",
              label: localeManager.t("navigation.importer"),
              icon: "import-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/data/import")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/data/import",
              class: this.displaySection === "import" ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: this.displaySection === "import" ? "active" : ""
            }
          ]
        },
        // {
        //   key: "Reports",
        //   label: localeManager.t("navigation.reports"),
        //   icon: "reports-icon",
        //   url: "/reports"
        // },
        {
          key: "Semantics",
          label: localeManager.t("navigation.semantics"),
          icon: "tag-manager-icon",
          command: (event: MenuItemCommandEvent) => {
            if (!this.navigateTo("/semantics")) {
              event.originalEvent.preventDefault();
            }
          },
          url: "/semantics",
          class: (this.displaySection === "tags" || this.displaySection === "tags-config") ? "active" : "",
        },
        {
          key: "Manage",
          label: localeManager.t("navigation.manage"),
          icon: "",
          class: "p-menuitem-section-title",
          visible: isAdminOrOrgAdmin || isGroupAdmin,
        },
        {
          key: 'Organisations',
          label: localeManager.t("navigation.organisation"),
          icon: "organisation-icon",
          class: this.displaySection.startsWith("organisations-") ? "has-children active" : "has-children",
          visible: isAdminOrOrgAdmin || isGroupAdmin,
          items: [
            {
              key: "organisations-edit",
              label: localeManager.t("navigation.editProfile"),
              icon: "organisation-edit-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/organisations/edit")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/organisations/edit",
              class: (this.displaySection === "organisations-edit") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "organisations-edit") ? "active" : "",
              visible: isAdminOrOrgAdmin
            },
            {
              key: "organisations-users",
              label: localeManager.t("navigation.userManagement"),
              icon: "organisation-users-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/organisations/users")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/organisations/users",
              class: (this.displaySection === "organisations-users") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "organisations-users") ? "active" : "",
            },
            // {
            //   key: "organisations-users-old",
            //   label: localeManager.t("navigation.userManagement"),
            //   icon: "organisation-users-icon",
            //   url: "/organisationManager/members"
            // },
            {
              key: "organisations-tariffs",
              label: localeManager.t("navigation.tariffs"),
              icon: "organisation-tariffs-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/organisations/tariffs")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/organisations/tariffs",
              class: (this.displaySection === "organisations-tariffs") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "organisations-tariffs") ? "active" : "",
              visible: isAdminOrOrgAdmin
            },
            {
              key: "organisations-colors",
              label: localeManager.t("navigation.colorThemes"),
              icon: "organisation-color-themes-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/organisations/colors")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/organisations/colors",
              class: (this.displaySection === "organisations-colors") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "organisations-colors") ? "active" : "",
              visible: isAdminOrOrgAdmin
            },
            {
              key: "organisations-graphic-library",
              label: localeManager.t("navigation.graphicLibrary"),
              icon: "organisation-graphic-library-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/organisations/graphic-library")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/organisations/graphic-library",
              class: (this.displaySection === "organisations-graphic-library") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "organisations-graphic-library") ? "active" : ""
            },
            {
              key: "organisations-insights-library",
              label: localeManager.t("navigation.insightsLibrary"),
              icon: "organisation-insights-library-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/organisations/insights")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/organisations/insights",
              class: (this.displaySection === "organisations-insights-library") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "organisations-insights-library") ? "active" : ""
            }
          ]
        },
        {
          key: "Administration",
          label: localeManager.t("navigation.admin"),
          icon: "admin-icon",
          class: this.displaySection.startsWith("admin-") ? "has-children active" : "has-children",
          visible: this.auth.permissions?.BitpoolAdmin,
          items: [
            {
              key: "admin-organisation-stats",
              label: localeManager.t("navigation.organisationStats"),
              icon: "admin-organisation-stats-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/admin/organisation-stats")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/admin/organisation-stats",
              class: (this.displaySection === "admin-organisation-stats") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "admin-organisation-stats") ? "active" : ""
            },
            {
              key: "admin-users",
              label: localeManager.t("navigation.users"),
              icon: "admin-users-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/admin/users")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/admin/users",
              class: (this.displaySection === "admin-users") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "admin-users") ? "active" : ""
            },
            {
              key: "admin-bitpool-ai",
              label: localeManager.t("navigation.bitpoolAI"),
              icon: "admin-bitpool-ai-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/admin/bitpool-ai")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/admin/bitpool-ai",
              class: this.displaySection === "admin-bitpool-ai" ? "active" : ""
            },
            {
              key: "admin-api-status",
              label: localeManager.t("navigation.apiStatus"),
              icon: "admin-api-status-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/admin/api-status")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/admin/api-status",
              class: (this.displaySection === "admin-api-status") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "admin-api-status") ? "active" : ""
            },
            {
              key: "protect",
              label: localeManager.t("navigation.protect"),
              icon: "admin-security-icon",
              command: (event: MenuItemCommandEvent) => {
                window.open("https://protect.bitpool.com/", "_blank");
              },
              visible: this.auth.permissions?.BitpoolAdmin
            },
            {
              key: "admin-url-short",
              label: localeManager.t("navigation.urlShortener"),
              icon: "admin-url-shortener-icon",
              command: (event: MenuItemCommandEvent) => {
                if (!this.navigateTo("/admin/url-short")) {
                  event.originalEvent.preventDefault();
                }
              },
              url: "/admin/url-short",
              class: (this.displaySection === "admin-url-short") ? "active" : "",
              // primevue bug, submenu using className instead of class
              className: (this.displaySection === "admin-url-short") ? "active" : ""
            }
          ]
        }
      ];
      // https://github.com/primefaces/primevue/issues/2268
      return result.map((item) => reactive(item));
    }
  }
}

export default NavMenuView;
</script>