<template>
  <div class="col-12 md:col-8 semantics-edit-ref">
    <label :for="inputId">Value</label>
    <Dropdown 
      v-if="isTariff"
      :inputId="inputId"
      :loading="!tariffState.isLoaded"
      v-model="refVal" 
      :options="allTariffs" 
      optionLabel="Name" 
      optionValue="Id" 
      placeholder="Select a Tariff" 
      :filter="true" 
      filterPlaceholder="Find Tariff" 
      class="w-full"
      :disabled="disabledFields.includes(field)"
    />
    <TreeSelectWithTagFilterView 
      v-else-if="inputType.length > 0 && tagManagerStore.activeSite"
      :selectedId="refVal" 
      :nodes="nodesForTree"
      :changeSelectedId="nodeFromTreeSelected"
      placeholder="Select Entity"
      :tags="inputType"
      :id="inputId"
      :disabled="disabledFields.includes(field)"
    />
    <InputText
      v-else
      :id="inputId"
      class="inputfield w-full"
      type="text"
      v-model="refVal"
      :disabled="disabledFields.includes(field)"
    />
  </div>
</template>

<script lang="ts">
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HDict, HaysonDict, HaysonRef, HaysonVal, Kind } from "haystack-core";
import { TariffModel } from '@/models/tariff/TariffModel';
import { useTagManagerStore } from '@/stores/tagManager';
import HaystackDefsService from '@/services/HaystackDefsService';
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { NavTreeNode } from '@/models/nav-tree/NavTreeNode';
import TreeSelectWithTagFilterView from '@/components/views/TreeSelectWithTagFilterView.vue';
import { useTariffStore } from '@/stores/tariff';
import TariffState from '@/stores/states/TariffState';

@Component({
  components: {
    InputText,
    Dropdown,
    TreeSelectWithTagFilterView
  },
})
class HaystackRefEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }
  
  get fieldDef(): HDict | undefined {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      const def = defs.get(this.field);
      return def;
    }
    return undefined;
  }

  tariffStore = useTariffStore();

  created() {
    if (this.isTariff) {
      if (!this.tariffState.isLoaded) {
        this.tariffStore.loadTariffs();
      }
    }
  }

  // #region ref with inputs field
  tagManagerStore = useTagManagerStore();

  get inputType(): string[] {
    let result: string[] = [];
    const def = this.fieldDef;
    if (def?.keys.includes("of")) {
      const inputType = def.get("of");
      if (inputType) {
        const inputTypeStr = inputType?.toString();
        result = inputTypeStr.split("-");
      }
    }
    return result;
  }

  get nodesForTree(): TreeNodeForUI[] {
    if (this.tagManagerStore.activeSite && this.inputType.length) {
      const currentNode = this.tagManagerStore.activeSite;
      return [currentNode.data];
    }
    return [];
  }

  nodeFromTreeSelected(node: TreeNodeForUI): void {
    const idTag = node.tags?.find(x => x.startsWith("id="));
    if (idTag) {
      this.refVal = idTag.replace("id=", "");
    } else {
      this.refVal = node.key ?? ""; 
    }
  }
  // #endregion ref with inputs field

  // #region tariff
  get tariffState(): TariffState {
    return this.tariffStore;
  }

  get isTariff(): boolean {
    return this.field === "tariffRef";
  }

  get allTariffs(): TariffModel[] {
    let result: TariffModel[] = [];
    if (this.tariffState.data) {
      for (const retailer in this.tariffState.data) {
        const tariffs = this.tariffState.data[retailer];
        if (tariffs && tariffs.length) {
          result = result.concat(tariffs);
        }
      }
    }
    return result;
  }
  // #endregion tariff
  
  // #region Ref
  // reference used to identify an entity instance
  get refVal(): string {
    if (this.valueHayson) {
      return (this.valueHayson as HaysonRef).val;
    }
    return "";
  }

  set refVal(value: string) {
    this.valueHayson = { 
      _kind: Kind.Ref,
      val: value
    };
  }
  // #endregion Ref
}

export default HaystackRefEditView;
</script>