import * as htmlToImage from 'html-to-image';
import {
  Canvg
} from 'canvg';
import ToastService from '@/services/ToastService';
import ErrorHelper from './ErrorHelper';

class PrintHelper {
  async printHtml(element: Element | null, darkMode = false, pageSelector = ""): Promise<void> {
    try {
      const backgroundColor = darkMode ? '#1B3440' : '#f8f9fa';
      if (element) {
        const pagesHtml: Element[] = [];
        if (pageSelector) {
          const children = element.querySelectorAll(pageSelector);
          for (let i = 0; i < children.length; i++) {
            pagesHtml.push(children[i]);
          }
        } else {
          pagesHtml.push(element);
        }
        const pages: string[] = [];
        for (let i = 0; i < pagesHtml.length; i++) {
          if (pagesHtml[i].clientHeight > 0) {
            const canvas = await htmlToImage.toCanvas(
              pagesHtml[i] as HTMLElement, 
              { 
                backgroundColor: backgroundColor
              }
            );
            const canvasW = canvas.width + 30;
            const canvasH = canvas.height + 30;
            // Throw error: Failed to execute 'convertToBlob' on 'OffscreenCanvas': Tainted "OffscreenCanvas" may not be exported.
            // const canvasWithBorder = new OffscreenCanvas(canvasW, canvasH);
            
            // let's use regular canvas and remove after usage
            const canvasWithBorder = document.createElement('canvas');
            canvasWithBorder.width = canvasW;
            canvasWithBorder.height = canvasH;
            canvasWithBorder.style.display = 'none';
            const ctx = canvasWithBorder.getContext('2d');
            if (ctx) {
              ctx.lineWidth = 30;
              ctx.strokeStyle = backgroundColor;
              ctx.strokeRect(0, 0, canvasW, canvasH);
              ctx.drawImage(canvas, 15, 15);
            }
            // this is for offscreen canvas
            // const blob = await canvasWithBorder.convertToBlob();
            // const imageSrc = URL.createObjectURL(blob);
            
            // this is for regular canvas
            const imageSrc = canvasWithBorder.toDataURL();
            pages.push(imageSrc);
            // cleanup
            canvasWithBorder.remove();
          }
        }

        // create a new window
        const nWindow = window.open('');
        if (nWindow) {
          for (let i = 0; i < pages.length; i++) {
            // append the canvas to the body
            const image = new Image();
            image.src = pages[i];
            image.setAttribute('style', "width: 100%; vertical-align: top;");
            nWindow.document.body.appendChild(image);
          }
          // styles
          const style = nWindow.document.createElement('style');
          style.textContent = `
            body {
              margin: 0;
              padding: 0;
              background-color: ${backgroundColor};
            }
          `;
          nWindow.document.head.appendChild(style);

          // focus on the window
          nWindow.focus();

          // wait for 1 second to let the window load
          await this.delay(1000);

          // print the window
          nWindow.print();
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't print",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  }

  delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }
  
  async svgToPng(width: number, height: number, svg: string, border: boolean, canvasSelector: string): Promise<string> {
    // whould be better to use offscreencanvas https://canvg.js.org/examples/offscreen
    // but safari is new ie https://bugs.webkit.org/show_bug.cgi?id=183720 https://caniuse.com/offscreencanvas
    const canvas = document.querySelector(canvasSelector) as HTMLCanvasElement;
    const ctx = canvas.getContext('2d');
    if (ctx) {
      const v = await Canvg.from(ctx, svg);

      // https://canvg.js.org/examples/resize
      v.resize(width, height, 'xMidYMid meet');

      // Render only first frame, ignoring animations and mouse.
      await v.render();

      if (border) {
        // draw border
        ctx.lineWidth = 3;
        ctx.strokeStyle='#fff';
        ctx.strokeRect(0, 0, width, height);
      }
    
      const pngUrl = await canvas.toDataURL();    
      return pngUrl;
    } else {
      return "";
    }
  }

}

export default new PrintHelper();
