<template>
  <div class="widget-type-alarm">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="alarm-block" v-show="!isLodingData && !isNoData" :class="alarmStatus?.Alarm ? 'has-alarm' : ''">
      <div class="alarm-block-top">
        <div>
          <BellSvg/>
        </div>
        <div>
          <span v-if="alarmStatus?.Alarm" class="alarm-status no-break-word">Alarm</span>
          <span v-else class="alarm-status no-break-word">Normal</span>
          <span>{{valueStr}}</span>
        </div>
      </div>
      <div class="alarm-block-bottom" :class="alarmStatus?.Alarm && !aws?.alarmShowTime && !aws?.alarmShowDate ? 'hidden' : ''">
        <span v-if="alarmStatus?.Alarm" class="alarm-time">
          <span v-if="aws?.alarmShowTime">
            <i>In Alarm Time:</i><span>{{runtime}}</span>
          </span>
          <span v-if="aws?.alarmShowDate">
            <i>Last Time Normal: </i><DateTimezoneView :date="alarmStatus.LastNormalDate" timezone="local" />
          </span>
        </span>
        <span class="no-alarm" v-else>There are no current alarms</span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import WidgetDataState from '@/store/states/WidgetDataState';
import ProgressSpinner from 'primevue/progressspinner';
import numbro from "numbro";
import moment, { Moment } from 'moment';
import prettyMilliseconds from 'pretty-ms';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import LoadAlarmRequest from '@/models/LoadAlarmRequest';
import { AlarmOperators } from '@/models/enums/AlarmOperators';
import { AggregationType } from '@/models/enums/AggregationType';
import { AggregationPeriod } from '@/models/enums/AggregationPeriod';
import { AlarmStatus } from '@/models/AlarmStatus';
import BellSvg from "@/components/svg/BellSvg.vue";
import DateTimezoneView from '@/components/views/DateTimezoneView.vue';

@Component({
  components: {
    ProgressSpinner,
    WidgetNoDataView,
    BellSvg,
    DateTimezoneView
  }
})
class AlarmWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  value : number | string = "";
  alarmStatus: AlarmStatus | null = null;
  dateDataRecieved: Moment | null = null;

  runtime = "";

  get valueStr(): string {
    let result: string;
    if (typeof this.value === "string") {
      result = this.value;
    } else {
      const decimals = this.aws?.decimals ? this.aws.decimals : 0;
      result = numbro(this.value).format({
        thousandSeparated: true,
        mantissa: decimals < 0 ? 0 : decimals
      });
    }
    if (result && this.aws?.widgetUnit) {
      result = this.aws?.widgetUnitPos ? `${result} ${this.aws.widgetUnit}` : `${this.aws.widgetUnit} ${result}`;
    }
    return result;
  }

  dataRefreshInterval = 0;
  timeRefreshInterval = 0;

  get reloadDataEverySeconds(): number {
    const result = (this.aws?.alarmCheckMins ? this.aws.alarmCheckMins : 5) * 60;
    return result;
  }

  created(): void {
    this.reloadData(false, true);
  }

  mounted(): void {
    this.runDataRefreshInterval();
    this.timeRefreshInterval = window.setInterval(() => {
      this.refreshTime();
    }, 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    if (this.timeRefreshInterval) {
      clearInterval(this.timeRefreshInterval);
      this.timeRefreshInterval = 0;
    }
  }

  runDataRefreshInterval(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  refreshTime(): void {
    if (this.alarmStatus && this.alarmStatus.LastNormalDate) {
      const lastNormal = moment(this.alarmStatus.LastNormalDate);
      const now = moment();
      const duration = moment.duration(now.diff(lastNormal)).asMilliseconds();
      const result = prettyMilliseconds(duration, { secondsDecimalDigits: 0 });
      this.runtime = result;
    } else {
      this.runtime = "-";
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions[0].StreamKey) {
      const streamOptions = this.wds.streamOptions[0];
      const requestBody: LoadAlarmRequest = {
        streamKey: streamOptions.StreamKey,
        alarmOperator: this.aws?.alarmOperator ? this.aws.alarmOperator : AlarmOperators.gte,
        trigger: this.aws?.alarmValue ? this.aws.alarmValue : 0,
        aggregation: !!this.aws?.aggregating,
        aggregationType: (typeof streamOptions.Params.aggType === "undefined" || streamOptions.Params.aggType === null) ? AggregationType.Max : streamOptions.Params.aggType,
        aggregationPeriod: AggregationPeriod.None // total
      }
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadDataAlarm", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.dataAlarm[this.widgetConfig.guid];
      if (data && data[1]) {
        this.dataUpdate(data);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataUpdate(data: [boolean, AlarmStatus | null]): void {
    const alarmStatus = data[1];
    this.alarmStatus = alarmStatus;
    this.dateDataRecieved = moment();
    this.value = alarmStatus && typeof alarmStatus.Value !== "undefined" && alarmStatus.Value !== null ? alarmStatus.Value : "";  
    this.refreshTime();
  }
}

export default AlarmWidget;
</script>