<template>
  <div class="default-view-container">
    <h1 class="mb-0">Email Import</h1>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <div class="default-view-body">
        <TabView lazy v-if="poolsState.isLoadedList && emailImportStore.isLoadedConfigs && emailImportStore.isLoadedScripts">
          <TabPanel header="Import Configuration">
            <EmailImport2ConfigsView/>
          </TabPanel>
          <TabPanel header="Data Parser Scripts">
            <EmailImport2ScriptsView/>
          </TabPanel>
        </TabView>
        <div v-else class="progress-spinner-container min-h-full">
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import { useEmailImportStore } from "@/stores/emailImport";
import PoolsState from "@/store/states/PoolsState";
import AuthState from "@/store/states/AuthState";
import EmailImport2ConfigsView from "@/components/views/connections/EmailImport2ConfigsView.vue";
import EmailImport2ScriptsView from "@/components/views/connections/EmailImport2ScriptsView.vue";

@Component({
  components: {
    TabView,
    TabPanel,
    Message,
    ProgressSpinner,
    EmailImport2ConfigsView,
    EmailImport2ScriptsView
  },
})
class EmailImport2View extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get poolsState(): PoolsState {
    return this.$store.state.pools;
  }

  emailImportStore = useEmailImportStore();

  created() {
    if (this.authState.permissions?.FullAccess) {
      this.emailImportStore.loadConfigs();
      this.emailImportStore.loadScripts();
      this.$store.dispatch("pools/loadPoolsList");
    }
  }
}

export default EmailImport2View;
</script>
