<template>
  <div>
    <DataTable
      :value="emailImportStore.configs" 
      v-model:expandedRows="expandedRows" 
      dataKey="Id"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint email-import-table">
      <template #header>
        <div class="table-header">
          <Button label="Add Configuration" icon="pi pi-plus-circle" class="my-1" @click="openCreateUpdateDialog(null)" />
        </div>
      </template>
      <template #empty>
        <div v-if="emailImportStore.isLoadedConfigs" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 48px;" bodyClass="no-label-in-stack">
        <template #body="slotProps">
          <div class="inline-flex expand-toggler-btn">
            <Button :icon="(preCloseState.findIndex(x => x === slotProps.data.Id) >= 0 || !expandedRows[slotProps.data.Id]) ? 'pi pi-chevron-right' : 'pi pi-chevron-down'" class="p-button-icon-only p-button-rounded p-button-text p-button-secondary" @click="toggleRow(slotProps.data)" />
          </div>
        </template>
      </Column>
      <Column field="Enabled" header="Enabled" headerStyle="width: 1%; min-width: 65px;">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.Enabled ? 'Yes' : 'No' }}
          </div>
        </template>
      </Column>
      <Column field="Name" header="Name"></Column>
      <Column field="Email" header="Email" bodyStyle="word-break: break-all;">
        <template #body="slotProps">
          <span class="block with-inline-btn">
            <span>{{ slotProps.data.Email }}</span>
            <Button
              icon="pi pi-copy text-lg" 
              class="p-button-icon-only p-button-rounded p-button-text" 
              @click="copyKey(slotProps.data.Email)"
            />
          </span>
        </template>
      </Column>
      <Column field="Pool" header="Pool"></Column>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 169px;" bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <div>
            <div class="inline-flex">
              <Button 
                icon="pi pi-book" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openLogsDialog(slotProps.data)"
                v-tippy="'Logs'"
              />
              <Button 
                icon="pi pi-clone" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCloneDialog(slotProps.data)"
                v-tippy="'Copy'"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openCreateUpdateDialog(slotProps.data)"
                v-tippy="'Edit'"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                :disabled="slotProps.data.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin"
                v-tippy="'Delete'"
              />
            </div>
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <transition name="p-toggleable-content" appear>
          <ul class="email-import-table-other-fields" v-if="preCloseState.findIndex(x => x === slotProps.data.Id) < 0">
            <li class="pt-3">
              <span><b>Script</b></span>
              <span class="flex align-items-center">
                <span class="inline-block with-inline-btn">
                  <span class="break-word">{{getScriptName(slotProps.data.ScriptId)}}</span>
                </span>
              </span>
            </li>
            <li class="pb-3">
              <span><b>File Extension</b></span>
              <span class="flex align-items-center">
                <span class="inline-block with-inline-btn">
                  <span class="break-word">{{slotProps.data.Extension}}</span>
                </span>
              </span>
            </li>                               
          </ul>
        </transition>
      </template>
    </DataTable>

    <Dialog header="Configuration" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="email-import-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <div class="field col-12">
              <label for="editRecordName">Name</label>
              <div>
                <InputText 
                  id="editRecordName" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Name"
                  placeholder="Name"
                />
              </div>
            </div>
            <div class="field col-fixed mr-5 pt-1">
              <div class="flex align-items-center">
                <InputSwitch 
                  inputId="editRecordClean"
                  v-model="editRecord.Enabled"
                  class="vertical-align-top"
                />
                <label for="editRecordClean" class="mb-0 ml-2">Enabled</label>
              </div>
            </div>
            <div class="field col-fixed md:col-7 pt-1">
              <div class="flex align-items-center">
                <InputSwitch 
                  inputId="editRecordNewPool"
                  v-model="isNewPool"
                  @change="onIsNewPoolChange"
                  class="vertical-align-top"
                />
                <label for="editRecordNewPool" class="mb-0 ml-2">New Pool</label>
              </div>
            </div>
            <div v-if="isNewPool" class="field col-12">
              <label for="editRecordPool">New Pool Name</label>
              <div>
                <InputText 
                  id="editRecordPool" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Pool"
                  placeholder="New Pool Name"
                />
              </div>
            </div>
            <div v-else class="field col-12">
              <label for="editRecordEndpointPool">Select Pool</label>
              <div>
                <Dropdown 
                  id="editRecordEndpointPool" 
                  v-model="selectedPool" 
                  @change="onSelectPool" 
                  :options="pools" 
                  optionLabel="Name"
                  placeholder="Select pool" 
                  :filter="true" 
                  filterPlaceholder="Find pool" 
                  class="w-full dropdown-lg"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6 md:mb-0">
              <label for="editRecordScript">Script</label>
              <div>
                <Dropdown 
                  id="editRecordScript" 
                  v-model="editRecord.ScriptId"
                  @change="onSelectScript" 
                  v-if="emailImportStore.scripts"
                  :options="emailImportStore.scripts" 
                  optionLabel="Name"
                  optionValue="Id"
                  placeholder="Select script" 
                  :filter="true" 
                  filterPlaceholder="Find script" 
                  class="w-full dropdown-lg"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6 mb-0">
              <label for="editRecordExtension">File Extension</label>
              <div>
                <InputText 
                  id="editRecordExtension" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Extension"
                  placeholder="Example: csv, xml, json"
                />
              </div>
            </div>
          </div>
          <div v-if="editRecordScript?.AdditionalParameters.length" class="formgrid grid">
            <div 
              class="field col-12 md:col-6 mb-0 field-margin-top flex flex-column" 
              v-for="(additionalParameter, index) in editRecordScript.AdditionalParameters" 
              :key="index"
            >
              <label :for="`editRecordParam-${additionalParameter.Name}`" class="flex-shrink-0">
                {{ additionalParameter.Label }}
              </label>
              <div class="flex-auto flex align-items-center">
                <InputText 
                  v-if="additionalParameter.Type === ScriptAdditionalParameterType.String"
                  :id="`editRecordParam-${additionalParameter.Name}`"
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.AdditionalParameters[additionalParameter.Name]"
                />
                <InputNumber
                  v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Number"
                  :id="`editRecordParam-${additionalParameter.Name}`"
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.AdditionalParameters[additionalParameter.Name]"
                  :minFractionDigits="2"
                  :maxFractionDigits="20"
                />
                <InputSwitch
                  v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Boolean"
                  :id="`editRecordParam-${additionalParameter.Name}`"
                  v-model="editRecord.AdditionalParameters[additionalParameter.Name]"
                  class="vertical-align-middle"
                />
              </div>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button 
              v-if="editRecord" 
              :label="editRecord.Id ? 'Update' : 'Create'" 
              :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
              @click="saveRecord" 
              :disabled='!editRecord.Pool || !editRecord.Name || !editRecord.ScriptId || !editRecord.Extension || updateInProgress' 
            />
          </span>
        </div>
      </template>
    </Dialog>

    <Dialog header="Logs" v-model:visible="displayLogsDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="email-import-logs-config-dialog">
      <div class="dialog-content" v-if="selectedRecord">
        <EmailImport2HistoryView v-model="selectedRecord.Id"/>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import InlineMessage from 'primevue/inlinemessage';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import SelectButton from 'primevue/selectbutton';
import { EmailImportConfigEntity } from "@/models/email-import/EmailImportConfigEntity";
import { PoolModelLite } from "@/models/PoolModelLite";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import CustomWindow from '@/CustomWindow';
import AuthState from "@/store/states/AuthState";
import moment from "moment";
import ToastService from "@/services/ToastService";
import copy from 'copy-to-clipboard';
import { useEmailImportStore } from "@/stores/emailImport";
import ConfirmationService from "@/services/ConfirmationService";
import EmailImport2HistoryView from "@/components/views/connections/EmailImport2HistoryView.vue";
import { EmailImportScriptEntity } from "@/models/email-import/EmailImportScriptEntity";
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { useOrganisationStore } from "@/stores/organisation";

declare const window: CustomWindow;

@Component({
  components: {
    Button,
    InputText,
    InputNumber,
    Dropdown,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    InlineMessage,
    Message,
    ProgressSpinner,
    SelectButton,
    EmailImport2HistoryView
  },
  directives: {
  }
})
class EmailImport2ConfigsView extends Vue { 
  emailImportStore = useEmailImportStore();

  expandedRows: Record<string, boolean> = {};
  preCloseState: string[] = [];

  toggleRow(row: EmailImportConfigEntity): void {
    if (this.expandedRows[row.Id]) {
      // close
      this.preCloseState.push(row.Id);
      window.setTimeout(() => {
        const idIndex = this.preCloseState.findIndex(x => x === row.Id);
        if (idIndex >= 0 && this.expandedRows[row.Id]) {
          const newExpandedRows = { ...this.expandedRows };
          delete newExpandedRows[row.Id];
          this.expandedRows = newExpandedRows;
          this.preCloseState.splice(idIndex, 1);
        }
      }, 450);
    } else {
      // open
      const idIndex = this.preCloseState.findIndex(x => x === row.Id);
      if (idIndex >= 0) {
        this.preCloseState.splice(idIndex, 1);
      }
      this.expandedRows = { ...this.expandedRows, [row.Id]: true };
    }
  }

  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }

  getScriptName(scriptId: string): string {
    const script = this.emailImportStore.scripts?.find(x => x.Id === scriptId);
    return script ? script.Name : "-";
  }

  // #region new/edit
  displayCreateUpdateDialog = false;
  editRecord: EmailImportConfigEntity | null = null;
  selectedPool: PoolModelLite | null | undefined = null;
  isNewPool = false;

  get editRecordScript(): EmailImportScriptEntity | null | undefined {
    return this.emailImportStore.scripts?.find(x => x.Id === this.editRecord?.ScriptId);
  }

  get pools(): PoolModelLite[] {
    const pools = this.$store.state.pools.poolsList && this.$store.state.pools.poolsList.Pools ?
      this.$store.state.pools.poolsList.Pools as PoolModelLite[] :
      [];
    return pools;
  }
  
  onSelectPool(event: any): void {
    // event.originalEvent: Original event
    // event.value: Selected option value
    if (this.editRecord) {
      this.editRecord.PoolKey = event.value.Id;
      this.editRecord.Pool = event.value.Name;
    } 
  }

  onIsNewPoolChange(): void {
    if (this.editRecord) {
      this.editRecord.PoolKey = "";
      this.editRecord.Pool = "";
    }
    this.selectedPool = null;
  }

  onSelectScript(event: any): void {
    // event.originalEvent: Original event
    // event.value: Selected option value
    if (this.editRecord) {
      const script = this.emailImportStore.scripts?.find(x => x.Id === event.value);
      if (script) {
        this.editRecord.Extension = script.DefaultExtension;
        this.prepareAdditionalParameters(this.editRecord, script);
      }
    } 
  }

  ScriptAdditionalParameterType = ScriptAdditionalParameterType;

  prepareAdditionalParameters(config: EmailImportConfigEntity, script: EmailImportScriptEntity): void {
    // romeve filds
    for (const key in config.AdditionalParameters) {
      if (!script.AdditionalParameters.some(x => x.Name === key)) {
        delete config.AdditionalParameters[key];
      }
    }
    // add fields
    script.AdditionalParameters.forEach(x => {
      if (typeof config.AdditionalParameters[x.Name] === "undefined") {
        config.AdditionalParameters[x.Name] = x.DefaultValue;
      }
    });
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  openCloneDialog(record: EmailImportConfigEntity): void {
    const newRecord: EmailImportConfigEntity = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: EmailImportConfigEntity | null): void {
    this.isNewPool = false;
    this.selectedPool = record ? this.pools.find(x => x.Id === record.PoolKey) : null;
    const nowUtc = moment.utc().toDate();
    this.editRecord = record ? JSON.parse(JSON.stringify(record)) : {
      Id: "",
      Name: "",
      ScriptId: "",
      Email: "",
      Password: "",
      Pool: "",
      PoolKey: "",
      Extension: "",
      Enabled: true,
      OrganisationId: this.currentOrganisation ? this.currentOrganisation.Id : -1,
      Created: nowUtc,
      Updated: nowUtc,
      CreatedBy: this.allUserData.userName,
      UpdatedBy: this.allUserData.userName,
      AdditionalParameters: {}
    };
    if (this.editRecord && this.editRecordScript) {
      this.prepareAdditionalParameters(this.editRecord, this.editRecordScript);
    }
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.emailImportStore.updateInProgressConfigs;
  }

  get updateError(): boolean {
    return this.emailImportStore.updateErrorConfigs;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.emailImportStore.createUpdateConfig(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: EmailImportConfigEntity | null = null;

  openConfirmation(record: EmailImportConfigEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete configuration?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete configuration',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.emailImportStore.deleteConfig(this.selectedRecord);
    }
  }
  // #endregion delete

  // #region logs
  displayLogsDialog = false;

  openLogsDialog(record: EmailImportConfigEntity): void {
    this.selectedRecord = record;
    this.displayLogsDialog = true;
  }
  // #endregion logs
}

export default EmailImport2ConfigsView;
</script>