<template>
  <section class="organisation-profile-users-members">
    <div>
      <TreeGenericWithCheckboxesView
        v-if="organisationSpaceAccessStore.isLoaded && dashboardStore.isLoaded && ready"
        :selectedNodes="dashboardTreeSelected"
        :nodes="dashboardTree"
        :changeSelected="treeChangeSelected"
        placeholder="Find Dashboards"
        :openRoot="true"
      />
      <div v-else class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 30vh;">
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Model, Vue, Watch } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import { useOrganisationStore } from "@/stores/organisation";
import { GroupFromDBDto } from "@/models/organisation/GroupFromDBDto";
import { useDashboardStore } from "@/stores/dashboard";
import { useOrganisationSpaceAccessStore } from "@/stores/organisationSpaceAccess";
import { OrganisationSpaceAccessModel } from "@/models/organisation/OrganisationSpaceAccessModel";
import { GenericTree } from "@/models/tree/GenericTree";
import TreeGenericWithCheckboxesView from "@/components/views/TreeGenericWithCheckboxesView.vue";
import { Space } from "@/models/dashboard/Space";

@Component({
  components: {
    Button,
    ProgressSpinner,
    TreeGenericWithCheckboxesView
  },
  directives: {
  }
})
class OrganisationUsersGroupsDashboards extends Vue {
  @Model model!: GroupFromDBDto;

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  dashboardStore = useDashboardStore();
  organisationSpaceAccessStore = useOrganisationSpaceAccessStore();

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get availableOrganisations(): OrganisationFullDto[] {
    let result = this.organisationStore.entities ?? [];
    result = result.filter(x => x.Id !== this.organisationStore.currentOrganisation?.Id);
    return result;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  created(): void {
    this.loadData();
  }

  unmounted(): void {
    this.dashboardStore.$reset();
    this.organisationSpaceAccessStore.$reset();
  }

  @Watch('model.Id', { immediate: false, deep: false })
  onModelChanged(val: number, oldVal: number): void {
    if (oldVal > 0) {
      this.loadData();
    } else {
      this.organisationSpaceAccessStore.groupId = this.model.Id;
    }
  }

  dashboardTree: GenericTree<Space>[] = [];
  dashboardTreeFlat: GenericTree<Space>[] = [];
  dashboardTreeSelected: GenericTree<Space>[] = [];
  ready = false;

  treeChangeSelected(nodes: GenericTree<Space>[]): void {
    this.dashboardTreeSelected = nodes;
  }

  /**
   * Load data for the component.
   * This function loads the dashboards and organisation dashboards access data and filters it to
   * obtain the groups and collections related to the current model.
   *
   * @return {Promise<void>} A promise that resolves when the data is loaded.
   */
  async loadData(): Promise<void> {
    this.ready = false;

    // Load dashboards and organisation dashboards access data
    await Promise.all([
      this.dashboardStore.load(), // Load dashboards data
      this.organisationSpaceAccessStore.load(this.model.OrganisationId, this.model.Id) // Load organisation dashboards access data
    ]);

    // Filter the organisation dashboards access data to obtain the groups and collections related to the current model
    const collectionsInGroups = (this.organisationSpaceAccessStore.entities ?? []) // Get the organisation dashboards access entities
      .filter(x => x.groups.includes(this.model.Id)); // Filter the entities to include only those with the current model's group id

    // Filter the dashboards data to obtain the collections related to the groups obtained in the previous step
    const collections: Space[] = (this.dashboardStore.entities ?? []) // Get the dashboards entities
      .filter(x => collectionsInGroups.find(y => y.spaceId === x._id)); // Filter the entities to include only those with a group id matching the ones obtained in the previous step

    // Build the tree
    const trees = this.dashboardStore.buildTree();
    this.dashboardTree = trees[0];
    this.dashboardTreeFlat = trees[1];

    // Build array of selected ids
    const selectedIds: string[] = [];
    for (const collection of collections) {
      if (collection._id) {
        selectedIds.push(collection._id);
      }
    }

    // Build array of selected nodes
    const selectedNodes: GenericTree<Space>[] = [];
    const treeFlat = trees[1];
    for (const node of treeFlat) {
      const index = selectedIds.findIndex(x => x === node.key);
      if (index >= 0) {
        selectedNodes.push(node);
      }
    }

    // Set the selected nodes
    this.dashboardTreeSelected = selectedNodes;

    this.ready = true;
  }

  /**
   * Saves changes to the organisation dashboards access.
   * It updates the collections in groups and adds new collections to the groups.
   * @returns {Promise<boolean>} A promise that resolves to a boolean indicating if the save was successful.
   */
  async saveChanges(): Promise<boolean> {
    // Create a deep copy of the organisation dashboards access entities
    const collectionsInGroups: OrganisationSpaceAccessModel[] = JSON.parse(JSON.stringify(this.organisationSpaceAccessStore.entities ?? []));
    const forSave: OrganisationSpaceAccessModel[] = [];
    
    // Update the collections in groups
    for (const collection of collectionsInGroups) {
      // Skip if the collection is in any of the dashboards groups
      if (this.dashboardTreeSelected.some(x => x.key === collection.spaceId)) continue;
      // Remove the collection from the groups if it exists
      const index = collection.groups.indexOf(this.model.Id);
      if (index > -1) {
        collection.groups.splice(index, 1);
        forSave.push(collection);
      }
    }

    // Add new collections to the groups
    for (const collection of this.dashboardTreeSelected) {
      // Find the index of the existing collection in the groups array
      const index = collectionsInGroups.findIndex(x => x.spaceId === collection.key);
      if (index > -1) {
        const existingCollection = collectionsInGroups[index];
        // Add the collection to the groups if it doesn't exist
        if (!existingCollection.groups.includes(this.model.Id)) {
          existingCollection.groups.push(this.model.Id);
          forSave.push(existingCollection);
        }
      } else {
        // Create a new collection and add it to the groups
        const newCollection: OrganisationSpaceAccessModel = {
          _id: "",
          organisationId: this.model.OrganisationId,
          spaceId: collection.key ?? "",
          groups: [this.model.Id]
        };
        collectionsInGroups.push(newCollection);
        forSave.push(newCollection);
      }
    }

    let isOk = true;
    // Save the changes if there are any collections to save
    if (forSave.length ) {
      isOk = await this.organisationSpaceAccessStore.save(forSave);
    }
    // Return the result of the save operation
    return isOk;
  }
}

export default OrganisationUsersGroupsDashboards;
</script>