import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios, { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import OrganisationBBEAccessState from "./states/OrganisationBBEAccessState";
import { OrganisationBBESmartAccessModel } from "@/models/organisation/OrganisationBBESmartAccessModel";
import { OrganisationBBEAccessModel } from "@/models/organisation/OrganisationBBEAccessModel";

export const useOrganisationBBEAccessStore = defineStore('organisationBBEAccess', {
  state: (): OrganisationBBEAccessState => ({ 
    isLoaded: false,
    guid: "",
    organisationId: 0,
    groupId: 0,
    entities: null,
    entitiesSmart: null,
    updateInProgress: false,
    updateError: false
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number, groupId: number) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.organisationId = organisationId;
        this.groupId = groupId;
        this.isLoaded = false;
        this.entities = null;
        this.entitiesSmart = null;
        const promises = [
          axios.get<OrganisationBBEAccessModel[]>(`rest/BitPool_V2/bbe/groups/access?organisationId=${organisationId}`),
          axios.get<OrganisationBBESmartAccessModel[]>(`rest/BitPool_V2/bbe/collections/smart/access?organisationId=${organisationId}`)
        ];
        const [response, responseSmart] = await Promise.all(promises);
        if (this.guid === guid) {
          this.entities = response.data as OrganisationBBEAccessModel[];
          this.entitiesSmart = responseSmart.data as OrganisationBBESmartAccessModel[];
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load accounts permissions",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.entitiesSmart = null;
        this.isLoaded = true;
      }
    },
    async save(body: OrganisationBBEAccessModel[], bodySmart: OrganisationBBESmartAccessModel[]): Promise<boolean> {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/bbe/groups/access`;
        const urlSmart = `rest/BitPool_V2/bbe/collections/smart/access`;
        const promises: [Promise<AxiosResponse<OrganisationBBEAccessModel[]>>, Promise<AxiosResponse<OrganisationBBESmartAccessModel[]>>] = [
          axios.put<OrganisationBBEAccessModel[]>(url, body),
          axios.put<OrganisationBBESmartAccessModel[]>(urlSmart, bodySmart)
        ];
        const [response, responseSmart] = await Promise.all(promises);
        if (response.data) {
          for (const item of response.data) {
            if (this.isLoaded && this.entities) {
              const index = this.entities.findIndex((x) => x._id === item._id);
              if (index < 0) {
                this.entities.push(item);
              } else {
                this.entities[index] = item;
              }
            }
          }
        }
        if (responseSmart.data) {
          for (const item of responseSmart.data) {
            if (this.isLoaded && this.entitiesSmart) {
              const index = this.entitiesSmart.findIndex((x) => x._id === item._id);
              if (index < 0) {
                this.entitiesSmart.push(item);
              } else {
                this.entitiesSmart[index] = item;
              }
            }
          }
        }
        this.updateInProgress = false;
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save accounts permissions",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return false;
      }
    }
  },
})
