<template>
  <div class="col-12 hd:col-8 semantics-edit-num">
    <div class="formgrid grid row-gap-2 flex-auto">
      <div class="col-12 md:col-6">
        <label :for="inputId">Value</label>
        <InputNumber
          :inputId="inputId"
          class="inputfield w-full"
          type="text"
          v-model="numVal"
          :minFractionDigits="0"
          :maxFractionDigits="isInt ? 0 : 20"
          :useGrouping="isInt ? false : true"
          :disabled="disabledFields.includes(field)"
        />
      </div>
      <div class="col-12 md:col-6">
        <label :for="inputId + '-unit'">Unit</label>
        <AutoComplete 
          :inputId="inputId + '-unit'"
          v-model="numUnit" 
          dropdown 
          :suggestions="filteredUnitOptions"
          :virtualScrollerOptions="{ itemSize: 38 }"
          @complete="filterUnitOptions"
          class="inputfield w-full"
          :disabled="disabledFields.includes(field)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import InputNumber from 'primevue/inputnumber';
import AutoComplete from 'primevue/autocomplete';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HDict, HList, HaysonDict, HaysonNum, HaysonVal, Kind } from "haystack-core";
import { useUnitsStore } from '@/stores/units';
import { AutoCompleteCompleteEvent } from 'primevue/autocomplete';
import HaystackDefsService from '@/services/HaystackDefsService';

@Component({
  components: {
    InputNumber,
    AutoComplete
  },
})
class HaystackNumEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }

  get fieldDef(): HDict | undefined {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      const def = defs.get(this.field);
      return def;
    }
    return undefined;
  }

  get isInt(): boolean {
    const result: string[] = [];
    const def = this.fieldDef;
    if (def) {
      const defTagIs = def.get("is");
      if (defTagIs) {
        (defTagIs as HList).forEach(x => {
          if (x) {
            result.push(x.toString());
          }
        });
      }
    }
    return (result.length === 1 && result[0] === "int");
  }

  unitsStore = useUnitsStore();

  // #region Number
  // floating point number annotated with an optional unit of measurement
  get numVal(): number {
    if (this.valueHayson) {
      const result = typeof this.valueHayson === "number" ?
        this.valueHayson :
        (this.valueHayson as HaysonNum).val;
      if (typeof result === "number") {
        return result;
      }
    }
    return 0;
  }

  set numVal(value: number) {
    this.valueHayson = { 
      _kind: Kind.Number,
      val: value,
      unit: this.numUnit
    };
  }

  get numUnit(): string {
    if (this.valueHayson) {
      const result = typeof this.valueHayson === "number" ?
        "" :
        (this.valueHayson as HaysonNum).unit;
      return result ?? "";
    }
    return "";
  }

  set numUnit(value: string) {
    this.valueHayson = { 
      _kind: Kind.Number,
      val: this.numVal,
      unit: value
    };
  }

  get unitOptions(): string[] {
    const result: string[] = [];
    if (this.unitsStore.isLoaded && this.unitsStore.data) {
      this.unitsStore.data.forEach(x => {
        result.push(x.Value);
      })
    }
    return result;
  }

  filteredUnitOptions: string[] = [];

  filterUnitOptions(event: AutoCompleteCompleteEvent): void {
    const query = event.query ? event.query.toLowerCase() : "";
    const result = event.query ? this.unitOptions.filter(x => x.toLowerCase().includes(query)) : this.unitOptions;
    this.filteredUnitOptions = [...result];
  }
  // #endregion Number
}

export default HaystackNumEditView;
</script>