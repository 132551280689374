<template>
  <div>
    <div class="field-checkbox mb-3">
      <Checkbox id="retryOnMergeErrorCheckbox" v-model="retryOnMergeError" :binary="true" />
      <label for="retryOnMergeErrorCheckbox">Retry on merge error</label>
    </div>
    
    <div class="mb-4">
      <Button label="Reload" class="p-button-outlined p-button-sm text-sm mr-2 mt-2" icon="pi pi-refresh" @click="loadDuplicates" :disabled="inProgressMerge"/>
      <Button label="Merge Streams to Fresh" class="p-button-outlined p-button-sm text-sm mr-2 mt-2" @click="mergeDuplicates(0)" :disabled="inProgressMerge" v-tippy="'by LastUpdate'"/>
      <Button label="Merge Streams to Old" class="p-button-outlined p-button-sm text-sm mr-2 mt-2" @click="mergeDuplicates(1)" :disabled="inProgressMerge" v-tippy="'by StreamIndex'"/>
      <Button label="Merge Streams to New" class="p-button-outlined p-button-sm text-sm mr-2 mt-2" @click="mergeDuplicates(2)" :disabled="inProgressMerge" v-tippy="'by StreamIndex'"/>
    </div>

    <div v-if="inProgressMerge || mergeComplete" class="mb-2">
      Records merged: <b>{{mergeAmount}}</b>
    </div>
    <div v-if="inProgressMerge" class="mb-2">
      <ProgressBar :value="mergeProgress" :showValue="false" />
    </div>

    <DataTable :value="streamDuplicates" responsiveLayout="stack" breakpoint="850px" class="mt-4">
      <template #empty>
        <div v-if="isLoadedDuplicates" class="w-full" style="min-height: 30vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 30vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Name" header="Name"></Column>
      <Column field="StreamsCount" header="Streams Count" headerStyle="text-align: right" bodyStyle="text-align: right"></Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import { StreamDuplicates } from "@/models/StreamDuplicates";
import ToastService from "@/services/ToastService";

@Component({
  components: {
    Button,
    Checkbox,
    DataTable,
    Column,
    ProgressBar,
    ProgressSpinner
  },
  directives: {
  }
})
class PoolDuplicatedStreamsView extends Vue {
  @Prop({ required: true }) poolKey!: string;

  retryOnMergeError = false;
  get isLoadedDuplicates(): boolean {
    return this.$store.state.poolTools.isLoadedDuplicates;
  }
  get streamDuplicates(): StreamDuplicates[] {
    return this.$store.state.poolTools.streamDuplicates;
  }
  inProgressMerge = false;
  mergeComplete = false;

  created(): void {
    this.loadDuplicates();
  }

  loadDuplicates(): void {
    this.$store.dispatch("poolTools/loadDuplicates", this.poolKey);
  }

  duplicateIndex = 0;
  mergeToOld = 0;
  mergeIndex = 0;
  get mergeAmount(): number {
    return this.$store.state.poolTools.mergeAmount;
  }
  get mergeError(): boolean {
    return this.$store.state.poolTools.mergeError;
  }
  get mergeProgress(): number {
    if (this.streamDuplicates.length > 0) {
      return 100 * this.mergeIndex / this.streamDuplicates.length;
    } else {
      return 100;
    }
  }

  mergeDuplicates(mergeToOld: number): void {
    if (this.streamDuplicates.length > 0) {
      this.mergeComplete = false;
      this.inProgressMerge = true;
      this.mergeToOld = mergeToOld;
      this.mergeIndex = 0;
      this.mergeNext(0);
    }
  }

  mergeNext(index: number): void {
    const data = { PoolKey: this.poolKey, StreamName: this.streamDuplicates[index].Name, MergeToOld: this.mergeToOld };
    this.$store.dispatch("poolTools/mergeStreamDuplicates", data).then(() => {
      if (this.mergeError) {
        if (this.retryOnMergeError) {
          this.mergeNext(index);
        }
        else {
          this.mergeComplete = true;
          this.inProgressMerge = false;
        }
      } else {
        const newIndex = index + 1;
        this.mergeIndex = newIndex;
        if (this.streamDuplicates.length > newIndex) {
          this.mergeNext(newIndex);
        } else {
          ToastService.showToast("success", "", "Merge Complete!", 5000);
          this.loadDuplicates();
          this.mergeComplete = true;
          this.inProgressMerge = false;
        }
      }
    });
  }
}

export default PoolDuplicatedStreamsView;
</script>