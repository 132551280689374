<template>
  <div class="dashboard default-view-container">
    <div v-if="dashboardState.isLoadedWidgets && element">
      <div>
        <div class="dashboard-area has-expand-widget">
          <div v-if="dashboardState.widgets && dashboardState.widgets.length" class="grid formgrid grid-body">
            <div class="col-12">
              <div class="widget-container widget-expanded">
                <div
                  :class="[
                    'widget',
                    hasGutter() ? '' : 'widget-nogutter',
                    getColorWidgetClass()
                  ]"
                  :style="{backgroundColor: getBackgroundColor()}"
                >
                  <div class="widget-head">
                    <div class="dashboard-control-buttons widget-control-buttons">
                      <Button v-if="!authState.jailMode && isBitpoolAIEnabled(element)" @click="openBitpoolAIDialog(element)" v-tippy="'Bitpool AI'" class="p-button-icon-only p-button-rounded p-button-light openai-button" style="margin-right: 4px;">
                        <span class="text-lg lg:text-2xl p-button-icon p-button-icon-left svg-icon">
                          <OpenAISvg/>
                        </span>
                        <span class="p-button-label">Bitpool AI</span>
                      </Button>
                    </div>
                  </div>

                  <DashboardWidgetView :dashboardId="dashboardId" :widget="element"/>
                </div>
              </div>
            </div>
          </div>
          <div v-else class="empty-data-area flex justify-content-center align-items-center flex-auto">
            <WidgetNoDataView :noDataType="noDataType" message="Please enable Edit Mode, then add grids and widgets." />
          </div>
        </div>
      </div>
    </div>
    <div v-else class="progress-spinner-container">
      <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
    </div>

    <OpenAIDialogView :dashboardId="dashboardId" :selectedWidget="selectedWidget" v-model="displayBitpoolAI" :openBitpoolAIDialog="openBitpoolAIDialog"/>
  </div>
</template>

<script lang="ts">
import DashboardState from "@/store/states/DashboardState";
import { Component, Vue } from "vue-facing-decorator";
import DashboardWidgetView from "./DashboardWidgetView.vue";
import WidgetNoDataView from '@/components/widgets-next/common/WidgetNoDataView.vue';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import OpenAIDialogView from "./OpenAIDialogView.vue";
import AuthState from "@/store/states/AuthState";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetDataState from "@/store/states/WidgetDataState";
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import { WidgetFeature } from "@/models/enums/WidgetFeature";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import WidgetHelper from "@/helpers/WidgetHelper";
import ColorHelper from "@/helpers/ColorHelper";
import RootState from "@/store/states/RootState";
import OpenAISvg from "@/components/svg/OpenAISvg.vue";

@Component({
  
  components: {
    DashboardWidgetView,
    WidgetNoDataView,
    ProgressSpinner,
    Button,
    OpenAIDialogView,
    OpenAISvg
  },
})
class SharedWidgetView extends Vue {
  dashboardId = "";
  element: SpaceWidgetConfig | null = null;
  
  noDataType = WidgetNoDataTypes.NotConfigured;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  created(): void {
    this.$store.dispatch("dashboard/loadSpaceSettings");
    this.loadWidget();
  }

  async loadWidget(): Promise<void> {
    await this.$store.dispatch("dashboard/loadSharedWidget");
    if (this.dashboardState.widgets?.length) {
      this.element = {
        guid: this.dashboardState.widgets[0].guid,
        type: "widget",
        size: {
          size: 12,
          sizeSM: 12,
          sizeMD: 12,
          sizeLG: 12,
          sizeXL: 12
        },
        widgets: undefined,
        widgetLoc: [],
        widgetSize: []
      };
    }
  }

  getWidgetConfig(): WidgetConfig | undefined {
    return this.dashboardState.widgets?.length ? this.dashboardState.widgets[0] : undefined;
  }

  getWidgetDescription(): WidgetDescription | undefined {
    const widgetConfig = this.getWidgetConfig();
    const widgetDescription = this.getWidgetDescriptionFromWidgetConfig(widgetConfig);
    return widgetDescription;
  }

  getWidgetDescriptionFromWidgetConfig(widgetConfig: WidgetConfig | null | undefined): WidgetDescription | undefined {
    const widgetDescription = widgetConfig && widgetConfig.widgetType ? WidgetHelper.getWidget(widgetConfig.widgetType) : undefined;
    return widgetDescription;
  }

  hasGutter(): boolean {
    const widgetDescription = this.getWidgetDescription();
    return !widgetDescription?.features.includes(WidgetFeature.nogutter);
  }

  getBackgroundColor(): string {
    const widgetConfig = this.getWidgetConfig();
    const aws = widgetConfig?.widgetOptions.advancedWidgetSettings;
    const result = aws?.widgetColorEnabled && aws?.widgetColorHex ? aws?.widgetColorHex : '';
    return result;
  }

  getColorWidgetClass(): string {
    const backgroundColor = this.getBackgroundColor();
    if (backgroundColor) {
      const color = ColorHelper.getContrastColor(backgroundColor);
      if (color === ColorHelper.getDefaultContrastColor()) {
        return "opposite-bg";
      }
    }
    return "";
  }

  // #region openai
  selectedWidget: SpaceWidgetConfig | null = null;

  get rootState(): RootState {
    return this.$store.state;
  }

  isBitpoolAIEnabled(widget: SpaceWidgetConfig): boolean {
    if (this.authState.permissions?.BitpoolAI) {
      const widgetConfig = this.getWidgetConfig();
      const widgetDescription = this.getWidgetDescription();
      return !!widgetConfig &&
        !!this.widgetDataState.data[widgetConfig.guid] &&
        !!widgetDescription?.features.includes(WidgetFeature.dataAnalyzeOpenAI) &&
        !!(!widgetDescription?.features.includes(WidgetFeature.dataOptional) || widgetConfig.widgetOptions.advancedWidgetSettings?.enableOptionalData);
    } else {
      return false;
    }
  }

  displayBitpoolAI = false;

  openBitpoolAIDialog(widget: SpaceWidgetConfig): void {
    if (!this.authState.jailMode) {
      this.selectedWidget = widget;
      this.displayBitpoolAI = true;
    }
  }
  // #endregion openai
}

export default SharedWidgetView;
</script>
