import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { v4 as uuidv4 } from "uuid";
import ShareState from "../states/ShareState";
import { IsSharedModel3 } from "@/models/dashboard/IsSharedModel3";
import { ShareSpacePublicModel } from "@/models/dashboard/ShareSpacePublicModel";
import { ShortUrlModel } from "@/models/ShortUrlModel";

const getters = <GetterTree<ShareState, any>>{
};

const mutations = <MutationTree<ShareState>>{
};

const actions = <ActionTree<ShareState, any>>{
  async loadIsShared({ state, dispatch }, dashboardId: string) {
    try {
      const guid = uuidv4();
      state.guidIsShared = guid;
      state.isLoadedShared = false;
      state.isShared = null;
      state.isLoadedShortUrl = false;
      state.shortUrl = null;
      state.isLoadedShortUrlTree = false;
      state.shortUrlTree = null;
      const url = `rest/BitPool_V2/os/space/${dashboardId}/IsShared3`;
      const response = await axios.get<IsSharedModel3>(url);
      if (state.guidIsShared === guid) {
        state.isShared = response.data;
        state.isLoadedShared = true;
        const promises: Promise<any>[] = [];
        if (state.isShared?.Single) {
          promises.push(dispatch("share", dashboardId));
        } else {
          state.shareId = null;
          state.isLoadedShareId = true;
          state.isLoadedShortUrl = true;
        }
        if (state.isShared?.Tree) {
          promises.push(dispatch("shareTree", dashboardId));
        } else {
          state.shareIdTree = null;
          state.isLoadedShareIdTree = true;
          state.isLoadedShortUrlTree = true;
        }
        if (promises.length) {
          await Promise.all(promises); 
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load share information",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.isShared = null;
      state.isLoadedShared = true;
    }
  },
  async share({ state, dispatch }, dashboardId: string) {
    try {
      const guid = uuidv4();
      state.guidShareId = guid;
      state.isLoadedShareId = false;
      state.shareId = null;
      const requestBody: ShareSpacePublicModel = {
        spaceId: dashboardId,
        single: true,
        enabled: true
      }
      const url = `rest/BitPool_V2/os/space/share`;
      const response = await axios.put<string>(url, requestBody);
      if (state.guidShareId === guid) {
        state.shareId = response.data;
        state.isLoadedShareId = true;
        await dispatch("generateShortUrl", state.shareId);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load share information",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.shareId = null;
      state.isLoadedShareId = true;
    }
  },
  async shareTree({ state, dispatch }, dashboardId: string) {
    try {
      const guid = uuidv4();
      state.guidShareIdTree = guid;
      state.isLoadedShareIdTree = false;
      state.shareIdTree = null;
      const requestBody: ShareSpacePublicModel = {
        spaceId: dashboardId,
        single: false,
        enabled: true
      }
      const url = `rest/BitPool_V2/os/space/share`;
      const response = await axios.put<string>(url, requestBody);
      if (state.guidShareIdTree === guid) {
        state.shareIdTree = response.data;
        state.isLoadedShareIdTree = true;
        await dispatch("generateShortUrlTree", state.shareIdTree);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load share information",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.shareIdTree = null;
      state.isLoadedShareIdTree = true;
    }
  },
  async unshare({ state }) {
    try {
      if (state.shareId) {
        const shareId = state.shareId;
        state.isLoadedShareId = true;
        state.shareId = null;
        state.isLoadedShortUrl = true;
        state.shortUrl = null;
        const url = `rest/BitPool_V2/os/space/unshare/${shareId}`;
        await axios.delete(url);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't unshare doashboard",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async unshareTree({ state }) {
    try {
      if (state.shareIdTree) {
        const shareId = state.shareIdTree;
        state.isLoadedShareIdTree = true;
        state.shareIdTree = null;
        state.isLoadedShortUrlTree = true;
        state.shortUrlTree = null;
        const url = `rest/BitPool_V2/os/space/unshare/${shareId}`;
        await axios.delete(url);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't unshare dashboard",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async reshare({ state, dispatch }, dashboardId: string) {
    try {
      if (state.shareId) {
        const shareId = state.shareId;
        state.isLoadedShareId = false;
        state.shareId = null;
        state.isLoadedShortUrl = false;
        state.shortUrl = null;
        const url = `rest/BitPool_V2/os/space/unshare/${shareId}?delete=true`;
        await axios.delete(url);
        await dispatch("share", dashboardId);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't refresh link",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async reshareTree({ state, dispatch }, dashboardId: string) {
    try {
      if (state.shareIdTree) {
        const shareId = state.shareIdTree;
        state.isLoadedShareIdTree = false;
        state.shareIdTree = null;
        state.isLoadedShortUrlTree = false;
        state.shortUrlTree = null;
        const url = `rest/BitPool_V2/os/space/unshare/${shareId}?delete=true`;
        await axios.delete(url);
        await dispatch("shareTree", dashboardId);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't refresh link",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async generateShortUrl({ state }, id: string) {
    try {
      const guid = uuidv4();
      state.guidShortUrl = guid;
      state.isLoadedShortUrl = false;
      state.shortUrl = null;
      const longUrl = `${window.location.protocol}//${window.location.host}/view/dashboard/${id}`;
      const requestBody: ShortUrlModel = {
        Url: longUrl,
        ValidTo: null
      };
      const url = `rest/BitPool_V2/ShortUrl`;
      const response = await axios.post<string>(url, requestBody);
      if (state.guidShortUrl === guid) {
        state.shortUrl = response.data;
        state.isLoadedShortUrl = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't generate short url",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.shortUrl = null;
      state.isLoadedShortUrl = true;
    }
  },
  async generateShortUrlTree({ state }, id: string) {
    try {
      const guid = uuidv4();
      state.guidShortUrlTree = guid;
      state.isLoadedShortUrlTree = false;
      state.shortUrlTree = null;
      const longUrl = `${window.location.protocol}//${window.location.host}/view/dashboard/${id}`;
      const requestBody: ShortUrlModel = {
        Url: longUrl,
        ValidTo: null
      };
      const url = `rest/BitPool_V2/ShortUrl`;
      const response = await axios.post<string>(url, requestBody);
      if (state.guidShortUrlTree === guid) {
        state.shortUrlTree = response.data;
        state.isLoadedShortUrlTree = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't generate short url",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.shortUrlTree = null;
      state.isLoadedShortUrlTree = true;
    }
  }
};

const ShareModule = {
  namespaced: true,
  state: new ShareState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default ShareModule;
