import { MQTTClientConfig } from "@/models/mqtt/MQTTClientConfig";

export default class MQTTState {
  isLoaded = false;
  configs: MQTTClientConfig[] = [];

  updateInProgress = false;
  updateError = false;

  testInProgress = false;
  testError = false;
}
