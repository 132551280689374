<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path stroke="var(--report-control-button-color)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M5.667 1h4.666M1 3.333h14m-1.556 0-.545 8.182c-.082 1.227-.123 1.841-.388 2.307-.233.41-.585.739-1.01.944C11.02 15 10.404 15 9.174 15H6.826c-1.23 0-1.845 0-2.327-.234a2.333 2.333 0 0 1-1.01-.944c-.265-.466-.306-1.08-.388-2.307l-.545-8.182m3.888 3.5v3.89m3.112-3.89v3.89"/>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class ReportDeleteSvg extends Vue {
}

export default ReportDeleteSvg;
</script>