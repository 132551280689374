<template>
  <div ng-non-bindable class="fullWidthHeight">
    <PDFViewer
      v-if="source"
      :source="source"
      style="height: 100%; width: 100%"
      @download="handleDownload"
    />
    <div v-else>Something is wrong</div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import AngularOptions from '../../models/AngularOptions';
import CustomWindow from '@/CustomWindow';
import PDFViewer from 'pdf-viewer-vue';

declare const window: CustomWindow;

@Component({

  components: {
    PDFViewer
  }
})
class ReportPdfWidget extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;
  source: string | null = null;

  created(): void {
    if (this.angularOptions.angularScope.pdfUrl) {
      this.source = this.angularOptions.angularScope.pdfUrl;
    }
  }

  handleDownload(): void {
    if (this.source) {
      window.open(this.source, '_blank');
    }
  }
}

export default ReportPdfWidget;
</script>