<template>
  <div class="default-view-container">
    <h1 class="mb-0">Bitpool AI</h1>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>AI Persona</h2>
      </header>
      <div class="default-view-body">
        <BitpoolAIPersonaView/>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import AuthState from "@/store/states/AuthState";
import BitpoolAIPersonaView from "@/components/views/bitpool-ai/BitpoolAIPersonaView.vue";

@Component({
  
  components: {
    TabView,
    TabPanel,
    Message,
    ProgressSpinner,
    BitpoolAIPersonaView
  },
})
class BitpoolAISetupView extends Vue { 
  get authState(): AuthState {
    return this.$store.state.auth;
  }
}

export default BitpoolAISetupView;
</script>