import { CurrentResponse } from "@/models/openweather/CurrentResponse";
import { GeoLocationResponse } from "@/models/openweather/GeoLocationResponse";
import { WeatherLocation } from "@/models/WeatherLocation";
import axios from "axios";

class WeatherHelper {
  async getCurrent(lat: number, lon: number): Promise<CurrentResponse> {
    const url = `rest/BitPool_V2/Weather_V1/Current?lat=${lat}&lon=${lon}`;
    const response = await axios.get<CurrentResponse>(url);
    return response.data;
  }
  async getForecast(lat: number, lon: number): Promise<CurrentResponse[]> {
    const url = `rest/BitPool_V2/Weather_V1/Forecast?lat=${lat}&lon=${lon}`;
    const response = await axios.get<CurrentResponse[]>(url);
    return response.data;
  }
  async getLocation(name: string): Promise<WeatherLocation[]> {
    const url = `rest/BitPool_V2/Weather_V1/FindCity?city=${name}`;
    const response = await axios.get<GeoLocationResponse[]>(url);
    return response.data.map(x => {
      return { 
        country: x.country,
        full: `${x.name}, ${this.getCountryName(x.country)}`,
        lat: x.lat,
        lng: x.lon
      }
    });
  }
  getCountryName(countryCode: string): string {
    // Intl.DisplayNames is not yet in typescript defenition https://github.com/microsoft/TypeScript/issues/41338
    // typescript throw error: "TS2339: Property 'DisplayNames' does not exist on type 'typeof Intl'."
    const regionNames = new (Intl as any).DisplayNames(
      ['en'], {type: 'region'}
    );
    const result = regionNames.of(countryCode);
    return result ? result : "";
  }
}

export default new WeatherHelper();
