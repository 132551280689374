<template>
  <div class="select-bg-color-field" :class="{'select-bg-color-field-not-selected': !colorHolder}" @click="toggleColorOverlay" :style="{backgroundColor: colorInternal}"></div>
  <OverlayPanel ref="colorOverlayPanel" appendTo="#overlayPanelPlaceholder" :showCloseIcon="true" :dismissable="true" :breakpoints="{'470px': '320px'}" :style="{width: '346px'}" class="color-picker-overlaypanel">
    <ColorPicker 
      :color="colorPicked" 
      :visibleFormats="[formatInternal]" 
      :defaultFormat="formatInternal" 
      :alphaChannel="alphaChannelInternal" 
      @color-change="updateColorPicked"
      :id="`colorInput-${uniqueKey}`" 
    >
      <template #copy-button>
        <span></span>
      </template>
    </ColorPicker>
    <div class="select-bg-color-theme" v-if="enableThemes">
      <label>Theme</label>
      <div>
        <Dropdown
          class="w-full mt-1"
          v-model="theme"
          :options="themes"
          optionLabel="Name"
          placeholder="Select Theme"
          filter
        />
      </div>
    </div>
    <div v-if="theme && enableThemes" class="select-bg-color-theme-colors">
      <div 
        v-for="(themeColor, index) in theme.Colors" 
        :key="index" 
        :style="{backgroundColor: themeColor}"
        @click="updateColorString(themeColor)"
      ></div>
    </div>
    <div class="flex justify-content-end align-items-center mt-4">
      <Button label="Close" icon="pi pi-times" @click="cancel" class="p-button-text p-button-secondary mr-2"/>
      <Button v-if="allowDelete" label="Delete" icon="pi pi-trash" @click="deleteColor" class="p-button-text p-button-danger mr-2" />
      <Button v-if="allowDisable" label="Disable" icon="pi pi-ban" @click="disableColor" class="p-button-text p-button-secondary mr-2" />
      <Button label="Save" icon="pi pi-check" @click="save" />
    </div>
  </OverlayPanel>
</template>


<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { v4 as uuidv4 } from 'uuid';
import ColorHelper from '@/helpers/ColorHelper';
import { ColorPicker } from 'vue-accessible-color-picker';
import OverlayPanel from 'primevue/overlaypanel';
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";
import { useColorThemeStore } from "@/stores/colorTheme";
import { ColorThemeEntity } from "@/models/organisation/ColorThemeEntity";

@Component({
  components: {
    ColorPicker,
    OverlayPanel,
    Dropdown,
    Button
  },
})
class ColorSelectView extends Vue {
  @Prop({ required: true }) color!: string;
  @Prop({ required: false, default: "hex" }) format!: string;
  @Prop({ required: false, default: "hide" }) alphaChannel!: string;
  @Prop({ required: true }) updateColor!: (newColor: string) => void;
  @Prop({ required: false, default: true }) enableThemes!: boolean;
  @Prop({ required: false, default: false }) allowDelete!: boolean;
  @Prop({ required: false, default: false }) allowDisable!: boolean;
  
  colorThemeStore = useColorThemeStore();

  uniqueKey = uuidv4();

  colorHolder = "";

  colorPicked = "";

  get colorInternal(): string {
    return this.colorHolder ?? "#ffffff";
  }
  set colorInternal(value: string) {
    this.colorHolder = value;
  }

  get formatInternal(): string {
    return this.format ? this.format : "hex"; 
  }

  get alphaChannelInternal(): string {
    return this.alphaChannel ? this.alphaChannel : "hide"; 
  }

  get themes(): ColorThemeEntity[] {
    return this.colorThemeStore.entities || [];
  }

  themeHolder: ColorThemeEntity | null | undefined = undefined;

  get theme(): ColorThemeEntity | null {
    let result = null;
    if (!this.themeHolder) {
      const colorThemeId = localStorage.getItem("colorThemeId");
      if (colorThemeId) {
        const theme = this.themes.find(x => x.Id === colorThemeId);
        if (theme) {
          result = theme;
        }
      }
      if (!result) {
        result = this.themes.length > 0 ? this.themes[0] : null;
      }
      this.themeHolder = result;
    }
    return this.themeHolder;
  }

  set theme(value: ColorThemeEntity | null) {
    if (value) {
      localStorage.setItem("colorThemeId", value.Id);
    } else {
      localStorage.removeItem("colorThemeId");
    }
    this.themeHolder = value;
  }

  created(): void {
    this.colorHolder = this.color ? this.color : (this.allowDisable ? "" : ColorHelper.random());
    this.colorPicked = this.colorHolder;

  }

  toggleColorOverlay(event: Event): void {
    if (this.$refs.colorOverlayPanel) {
      (this.$refs.colorOverlayPanel as OverlayPanel).toggle(event);
    }
  }
  
  updateColorPicked(eventData: any): void {
    this.updateColorString(eventData.cssColor);
  }

  updateColorString(color: string): void {
    this.colorPicked = color;
  }

  cancel(event: Event): void {
    this.toggleColorOverlay(event);
  }

  save(event: Event): void {
    this.colorInternal = this.colorPicked;
    if (this.updateColor) {
      this.updateColor(this.colorPicked);
    }
    this.toggleColorOverlay(event);
  }

  deleteColor(event: Event): void {
    if (this.updateColor) {
      this.updateColor("delete");
    }
    this.toggleColorOverlay(event);
  }

  disableColor(event: Event): void {
    if (this.updateColor) {
      this.updateColor("");
    }
    this.colorInternal = "";
    this.toggleColorOverlay(event);
  }
}

export default ColorSelectView;
</script>