<template>
  <section class="organisation-profile-users-groups">
    <h3>All Groups</h3>
    <div v-if="organisationGroupsStore.data[organisationIdStr]">
      <DataTable
        v-model:selection="selectedRecords"
        :value="organisationGroupsStore.data[organisationIdStr].entities" 
        dataKey="Id"
        showGridlines 
        responsiveLayout="stack" 
        breakpoint="850px" 
        class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width"
        sortField="Name" 
        :sortOrder="1"
        :paginator="true"
        :rows="20"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="menu"
        :globalFilterFields="['Name']"
        removableSort
      >
        <template #header>
          <div class="table-header">
            <div class="sm:flex sm:align-items-center sm:justify-content-between sm:gap-3">
              <div class="sm:flex-shrink-0">
                <Button 
                  :disabled="!organisationGroupsStore.data[organisationIdStr].isLoaded" 
                  label="Add Group" 
                  icon="pi pi-plus-circle" 
                  class="my-1 mr-2" 
                  @click="openCreateDialog"
                />
                <Button
                  label="Delete" 
                  icon="pi pi-trash" 
                  class="my-1 mr-2 p-button-outlined p-button-danger" 
                  @click="openDeleteSelectedRecordsConfirmation()"
                  :disabled="!selectedRecords.length"
                />
              </div>
              <div class="mt-3 sm:mt-0">
                <IconField iconPosition="left" class="w-full sm:w-auto">
                  <InputIcon class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Find Group" class="w-full sm:w-auto" />
                </IconField>
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          <div v-if="organisationGroupsStore.data[organisationIdStr].isLoaded" class="w-full" style="min-height: 50vh;">
            <span class="inline-block py-2">No data found.</span>
          </div>
          <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
            <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
          </div>
        </template>
        <Column selectionMode="multiple" headerStyle="width: 1%; min-width: 3rem;" headerClass="column-with-checkbox" bodyClass="column-with-checkbox"></Column>
        <Column :sortable="true" field="Name" header="Name" headerStyle="min-width: min-content; width: 59%;" headerClass="no-break-word" bodyClass="break-word"/>
        <Column :sortable="true" field="Default" header="Default" eaderStyle="min-width: min-content; width: 11%;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.Default ? "Yes" : "No" }}
            </div>
          </template>
        </Column>
        <Column :sortable="true" field="DateCreated" header="Date Created" headerStyle="min-width: min-content; width: 20%;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <DateTimezoneView :date="slotProps.data.DateCreated" timezone="local"/>
          </template>
        </Column>
        <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
          <template #body="slotProps">
            <div>
              <div class="inline-flex">
                <Button 
                  icon="pi pi-pencil" 
                  class="p-button-icon-only p-button-rounded p-button-outlined mr-2 p-button-opposite"
                  @click="openUpdateDialog(slotProps.data)"
                  v-tippy="'Edit'"
                />
                <Button 
                  icon="pi pi-trash" 
                  class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                  @click="openConfirmation(slotProps.data)" 
                  v-tippy="'Delete'"
                />
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </section>
  <Dialog :header="editRecord?.Id ? 'Edit Group' : 'Add New Group'" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'992px': '90vw'}" :style="{width: '60.5rem'}" class="organisation-profile-users-groups-config-dialog">
    <div class="dialog-content">
      <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="updateInProgress ? 'blockui-blocked' : ''">
        <div v-if="editRecord">
          <h4 class="organisation-profile-users-groups-section-title">Group Details</h4>
        
          <div class="formgrid grid align-items-end">
            <div class="field col md:mb-4">
              <label for="editRecordName">Name</label>
              <div>
                <InputText id="editRecordName" v-model="editRecord.Name" class="inputfield w-full" />
              </div>
            </div>
            <div class="field col-fixed w-full md:w-auto mb-4 md:pb-1">
              <div class="inline-flex align-items-center vertical-align-top" v-tippy="'All new organisation members will be added to this group'">
                <InputSwitch 
                  inputId="editRecordDefault"
                  v-model="editRecord.Default"
                  class="vertical-align-top"
                />
                <label for="editRecordDefault" class="mb-0 ml-2">Default</label>
              </div>
            </div>
          </div>

          <TabView :scrollable="true" class="organisation-profile-users-groups-tabs">
            <TabPanel header="General">
              <div>
                <h4 class="organisation-profile-users-groups-section-title">Dashboards</h4>
                <div class="grid mb-4 sm:mb-3">
                  <label for="editRecordDashboards" class="col-12 sm:col-5 lg:col-4">Access Level</label>
                  <div class="col-12 sm:col-7 md:col-6 md:col-offset-1 lg:col-5 lg:col-offset-3">
                    <SelectButton 
                      v-model="editRecord.Dashboards" 
                      :options="permissions" 
                      class="vertical-align-top"
                      id="editRecordDashboards"
                      :allowEmpty="false"
                    />
                  </div>
                </div>

                <div class="grid mb-4 sm:mb-3">
                  <label for="editRecordDashboardsScope" class="col-12 sm:col-5 lg:col-4">Access Scope</label>
                  <div class="col-12 sm:col-7 md:col-6 md:col-offset-1 lg:col-5 lg:col-offset-3">
                    <SelectButton 
                      v-model="editRecord.DashboardsScope" 
                      :options="permissionsScope" 
                      class="vertical-align-top"
                      id="editRecordDashboardsScope"
                      :allowEmpty="false"
                    />
                  </div>
                </div>

                <div class="grid mb-4 sm:mb-3">
                  <label for="editRecordComments" class="col-12 sm:col-5 lg:col-4">Comments</label>
                  <div class="col-12 sm:col-7 md:col-6 md:col-offset-1 lg:col-5 lg:col-offset-3">
                    <SelectButton 
                      v-model="editRecord.Comments" 
                      :options="permissions" 
                      class="vertical-align-top"
                      id="editRecordComments"
                      :allowEmpty="false"
                    />
                  </div>
                </div>

                <div class="grid mb-4 sm:mb-3">
                  <label for="editRecordSharing" class="col-12 sm:col-5 lg:col-4">Sharing and Presentations</label>
                  <div class="col-12 sm:col-7 md:col-6 md:col-offset-1 lg:col-5 lg:col-offset-3">
                    <SelectButton 
                      v-model="editRecord.Sharing"
                      :options="enableDisable" 
                      :optionLabel="x => x[1]"
                      :optionValue="x => x[0]"
                      class="vertical-align-top"
                      id="editRecordSharing"
                      :allowEmpty="false"
                    />
                  </div>
                </div>

                <hr>
                <h4 class="organisation-profile-users-groups-section-title">Data and Sites</h4>
                <div class="grid mb-4 sm:mb-3">
                  <label for="editRecordDataPools" class="col-12 sm:col-5 lg:col-4">Access Level</label>
                  <div class="col-12 sm:col-7 md:col-6 md:col-offset-1 lg:col-5 lg:col-offset-3">
                    <SelectButton 
                      v-model="editRecord.DataPools" 
                      :options="permissions" 
                      class="vertical-align-top"
                      id="editRecordDataPools"
                      :allowEmpty="false"
                    />
                  </div>
                </div>

                <hr>
                <h4 class="organisation-profile-users-groups-section-title">Reports Manager</h4>
                <div class="grid mb-0">
                  <label for="editRecordReports" class="col-12 sm:col-5 lg:col-4">Access Level</label>
                  <div class="col-12 sm:col-7 md:col-6 md:col-offset-1 lg:col-5 lg:col-offset-3">
                    <SelectButton 
                      v-model="editRecord.Reports" 
                      :options="permissions" 
                      class="vertical-align-top"
                      id="editRecordReports"
                      :allowEmpty="false"
                    />
                  </div>
                </div>

                <hr v-if="showTCCLegacy">
                <h4 class="organisation-profile-users-groups-section-title mt-4 sm:mt-3" v-if="showTCCLegacy">Bills Manager</h4>
                <div class="grid mb-0" v-if="showTCCLegacy">
                  <label for="editRecordBills" class="col-12 sm:col-5 lg:col-4">Access Level</label>
                  <div class="col-12 sm:col-7 md:col-6 md:col-offset-1 lg:col-5 lg:col-offset-3">
                    <SelectButton 
                      v-model="editRecord.Bills" 
                      :options="permissions" 
                      class="vertical-align-top"
                      id="editRecordBills"
                      :allowEmpty="false"
                    />
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Members">
              <OrganisationUsersGroupsMembers ref="groupMembers" v-model="editRecord"/>
            </TabPanel>
            <TabPanel header="Dashboards" v-if="editRecord.DashboardsScope === 'Custom'">
              <OrganisationUsersGroupsDashboards ref="groupDashboards" v-model="editRecord"/>
            </TabPanel>
            <TabPanel header="Data">
              <OrganisationUsersGroupsPools ref="groupPools" v-model="editRecord"/>
            </TabPanel>
            <TabPanel header="Sites">
              <OrganisationUsersGroupsSites ref="groupSites" v-model="editRecord"/>
            </TabPanel>
            <TabPanel header="Reports Manager">
              <OrganisationUsersGroupsReports ref="groupReports" v-model="editRecord"/>
            </TabPanel>
            <TabPanel header="Bills Manager" v-if="showTCCLegacy">
              <OrganisationUsersGroupsBills ref="groupBills" v-model="editRecord"/>
            </TabPanel>
          </TabView>
        </div>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #header>
      <div>
        <div class="p-dialog-title">{{ editRecord?.Id ? 'Edit Group' : 'Add New Group' }}</div>
        <div class="p-dialog-subtitle">Please enter the details of your {{ editRecord?.Id ? 'existing' : 'new' }} group.</div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
        <span class="block">
          <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        </span>
        <span class="block ml-2">
          <Button 
            :label="editRecord?.Id ? 'Save' : 'Add'" 
            :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
            @click="saveRecord" 
            :disabled='updateInProgress || !editRecord || !editRecord.Name' 
          />
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import SelectButton from "primevue/selectbutton";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import ConfirmationService from "@/services/ConfirmationService";
import { useOrganisationStore } from "@/stores/organisation";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";
import { useOrganisationGroupsStore } from "@/stores/organisationGroups";
import { GroupFromDBDto } from "@/models/organisation/GroupFromDBDto";
import { Permission } from "@/models/enums/Permission";
import OrganisationUsersGroupsMembers from "./OrganisationUsersGroupsMembers.vue";
import OrganisationUsersGroupsDashboards from "./OrganisationUsersGroupsDashboards.vue";
import OrganisationUsersGroupsPools from "./OrganisationUsersGroupsPools.vue";
import OrganisationUsersGroupsSites from "./OrganisationUsersGroupsSites.vue";
import OrganisationUsersGroupsReports from "./OrganisationUsersGroupsReports.vue";
import OrganisationUsersGroupsBills from "./OrganisationUsersGroupsBills.vue";
import ToastService from "@/services/ToastService";
import { nextTick } from "vue";
import { useOrganisationGroupsUsersStore } from "@/stores/organisationGroupsUsers";
import { useOrganisationGroupsPoolsStore } from "@/stores/organisationGroupsPools";
import { useOrganisationGroupsSitesStore } from "@/stores/organisationGroupsSites";
import { useOrganisationGroupsReportsStore } from "@/stores/organisationGroupsReports";
import { useOrganisationBBEAccessStore } from "@/stores/organisationBBEAccess";
import { PermissionScope } from "@/models/enums/PermissionScope";

@Component({
  components: {
    Button,
    InputText,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown,
    SelectButton,
    TabView,
    TabPanel,
    IconField,
    InputIcon,
    DateTimezoneView,
    OrganisationUsersGroupsMembers,
    OrganisationUsersGroupsDashboards,
    OrganisationUsersGroupsPools,
    OrganisationUsersGroupsSites,
    OrganisationUsersGroupsReports,
    OrganisationUsersGroupsBills
  },
  directives: {
  }
})
class OrganisationUsersGroups extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  organisationUsersStore = useOrganisationUsersStore();
  organisationGroupsStore = useOrganisationGroupsStore();
  organisationGroupsUsersStore = useOrganisationGroupsUsersStore();
  organisationGroupsPoolsStore = useOrganisationGroupsPoolsStore();
  organisationGroupsSitesStore = useOrganisationGroupsSitesStore();
  organisationGroupsReportsStore = useOrganisationGroupsReportsStore();
  organisationBBEAccessStore = useOrganisationBBEAccessStore();

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get availableOrganisations(): OrganisationFullDto[] {
    let result = this.organisationStore.entities ?? [];
    result = result.filter(x => x.Id !== this.organisationStore.currentOrganisation?.Id);
    return result;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get showTCCLegacy(): boolean {
    return location.hostname.includes("tsv.") || location.hostname === "localhost" || location.hostname.startsWith("192.");
  }

  // #region add/update
  displayCreateUpdateDialog = false;
  editRecord: GroupFromDBDto | null = null;
  filtersGroups = {
    'global': {value: null, matchMode: 'contains'}
  };

  get permissions(): Permission[] {
    return Object.values(Permission).reverse();
  }

  get permissionsScope(): PermissionScope[] {
    return Object.values(PermissionScope).reverse();
  }

  get enableDisable(): [boolean, string][] {
    return [[true, "Enable"], [false, "Disable"]];
  }

  get organisationGroups(): GroupFromDBDto[] {
    return this.organisationGroupsStore.data[this.organisationIdStr].entities?.sort((a, b) => {
      return Number(b.Default) - Number(a.Default) || a.Name.localeCompare(b.Name);
    }) ?? [];
  }

  openCreateDialog(): void {
    if (this.currentOrganisation) {
      this.editRecord = {
        DateCreated: new Date(),
        OrganisationId: this.currentOrganisation.Id,
        Default: false,
        Id: 0,
        Name: "",
        DataPools: Permission.Read,
        Dashboards: Permission.Read,
        DashboardsScope: PermissionScope.Custom,
        Comments: Permission.Read,
        Sharing: true,
        Bills: Permission.Read,
        InviteMembers: false,
        Reports: Permission.Read
      };
      this.displayCreateUpdateDialog = true;
    }
  }

  openUpdateDialog(record: GroupFromDBDto): void {
    if (this.currentOrganisation) {
      this.editRecord = JSON.parse(JSON.stringify(record));
      this.displayCreateUpdateDialog = true;
    }
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.updateInProgressLocal || 
      this.organisationGroupsStore.updateInProgress || 
      this.organisationGroupsUsersStore.updateInProgress ||
      this.organisationGroupsPoolsStore.updateInProgress ||
      this.organisationGroupsSitesStore.updateInProgress ||
      this.organisationGroupsReportsStore.updateInProgress ||
      this.organisationBBEAccessStore.updateInProgress;
  }

  updateInProgressLocal = false;

  async saveRecord(): Promise<void> {
    if (this.currentOrganisation && this.editRecord) {
      this.updateInProgressLocal = true;
      const groupId = await this.organisationGroupsStore.save(this.editRecord);
      if (!this.organisationGroupsStore.updateError) {
        if (!this.editRecord.Id) {
          this.editRecord.Id = groupId;
          await nextTick();
        }
        let isOk = true;
        if (this.$refs.groupMembers) {
          isOk = await (this.$refs.groupMembers as OrganisationUsersGroupsMembers).saveChanges();
        }
        if (isOk && this.$refs.groupDashboards) {
          isOk = await (this.$refs.groupDashboards as OrganisationUsersGroupsDashboards).saveChanges();
        }
        if (isOk && this.$refs.groupPools) {
          isOk = await (this.$refs.groupPools as OrganisationUsersGroupsPools).saveChanges();
        }
        if (isOk && this.$refs.groupSites) {
          isOk = await (this.$refs.groupSites as OrganisationUsersGroupsSites).saveChanges();
        }
        if (isOk && this.$refs.groupReports) {
          isOk = await (this.$refs.groupReports as OrganisationUsersGroupsReports).saveChanges();
        }
        if (isOk && this.$refs.groupBills) {
          isOk = await (this.$refs.groupBills as OrganisationUsersGroupsBills).saveChanges();
        }
        if (isOk) {
          ToastService.showToast("success", "", "Changes saved", 5000);
          this.closeCreateUpdateDialog();
        }
      }
      this.updateInProgressLocal = false;
    }
  }
  // #endregion add/update

  // #region delete
  selectedRecord: GroupFromDBDto | null = null;

  openConfirmation(record: GroupFromDBDto | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete group?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Group',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord && this.currentOrganisation) {
      this.organisationGroupsStore.delete(this.currentOrganisation.Id, [this.selectedRecord.Id]);
    }
  }

  selectedRecords: GroupFromDBDto[] = [];

  openDeleteSelectedRecordsConfirmation(): void {
    const message = `Are you sure you want to delete selected groups?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Groups',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteSelectedRecords();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async deleteSelectedRecords(): Promise<void> {
    if (this.selectedRecords.length && this.currentOrganisation) {
      await this.organisationGroupsStore.delete(this.currentOrganisation.Id, this.selectedRecords.map(x => x.Id));
      if (!this.organisationGroupsStore.deleteError) {
        this.selectedRecords = [];
      }
    }
  }
  // #endregion delete
}

export default OrganisationUsersGroups;
</script>