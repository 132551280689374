import { Space } from "@/models/dashboard/Space";
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import { AggregationType } from "@/models/enums/AggregationType";
import { IdName } from "@/models/IdName";
import { v4 as uuidv4 } from "uuid";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";

class SpaceHelper {
  getFakeRootId(): string {
    return '__fake_root__';
  }
  getRootSpace(spaces: Space[] | null | undefined): Space | null | undefined {
    let result = spaces?.find(x => !x.path);
    if (!result) {
      const fake = this.getFakeRootId();
      result = {
        _id: fake,
        path: null,
        spaceName: fake,
        spacesOrder: null,
        organisationId: -1,
        widgets: [],
        version: fake
      };
    }
    return result;
  }
  getParentId(item: Space | null | undefined): string | null {
    if (item) {
      const ids = item.path?.split(",").filter(x => x);
      if (ids && ids.length) {
        return ids[ids.length - 1];
      }
    }
    return null;
  }
  getParents(spaces: Space[] | null | undefined, item: Space | null | undefined): Space[] {
    if (spaces && item) {
      const ids = item.path?.split(",").filter(x => x);
      if (ids && ids.length) {
        const parents = spaces
          .filter(x => x._id && ids.includes(x._id))
          .sort((a, b) => a.path && b.path ? (`${a.path}${a._id},`).localeCompare(`${b.path}${b._id},`) : 0);
        return parents;
      }
    }
    return [];
  }
  getChildren(spaces: Space[] | null | undefined, item: Space | null | undefined): Space[] | null | undefined {
    if (!item) {
      return [];
    }
    if (item._id === this.getFakeRootId()) {
      // user without access to root node
      const result: Space[] = [];
      if (spaces && spaces.length) {
        const ids = spaces.map(x => x._id);
        spaces.forEach(x => {
          if (x._id && !ids.find(y => y && x.path?.includes(y))) {
            result.push(x);
          }
        });
      }
      return result;
    } else {
      // const path = item.path ? `${item.path}${item._id},` : `,${item._id},`;
      // const result = this.sortSpaces(spaces?.filter(x => x.path === path), item.spacesOrder);
      // becasue of bug -> some paths contain deleted ids
      const pathEnd = `,${item._id},`;
      const result = this.sortSpaces(spaces?.filter(x => x.path && x.path.endsWith(pathEnd)), item.spacesOrder);
      return result;
    }
  }
  moveSpace(parentSpace: Space | null | undefined, oldSpaces: Space[] | null | undefined, spaces: Space[] | null | undefined, allSpaces: Space[]): Space[] {
    // result will contain all changed spaces, you can save changes using vuex
    const result: Space[] = [];
    if (parentSpace && oldSpaces && spaces ) {
      const spacesOrder: string[] = spaces.filter(x => x._id).map(x => x._id) as string[];
      const path = parentSpace.path ? `${parentSpace.path}${parentSpace._id},` : `,${parentSpace._id},`;
      if (oldSpaces.length === spaces.length) {
        // move inside parentSpace
      } else if (oldSpaces.length < spaces.length) {
        // move to parentSpace
        const newSpaces = spaces.filter(x => x.path !== path);
        newSpaces.forEach(x => {
          // find grand children and update path
          const grandChildrenPath = `${x.path},${x._id},`;
          const grandChildrenPathNew = `${path},${x._id},`;
          const grandChildren = allSpaces.filter(x => x.path?.startsWith(grandChildrenPath))
          grandChildren.forEach(y => {
            y.path = y.path?.replace(grandChildrenPath, grandChildrenPathNew);
            // no need to save child dashboards, api will update path automatically
            // result.push(y);
          })
          x.path = path;
          result.push(x);
        })
      } else {
        // move from parentSpace
        // handled in another node "move to" block
      }
      parentSpace.spacesOrder = spacesOrder;
      result.push(parentSpace);
    }
    return result;
  }
  sortSpaces(spaces: Space[] | undefined, spacesOrder: string[] | null | undefined): Space[] | undefined {
    if (!spaces || spaces.length < 2 || !spacesOrder || !spacesOrder.length) {
      if (spaces && spaces.length > 1) {
        spaces.sort((a: Space, b: Space) => { return (!a._id || !b._id) ? 0 : a._id.localeCompare(b._id); });
      }
      return spaces;
    }
    const result: Space[] = [];
    for (let i = 0; i < spacesOrder.length; i++) {
      const id = spacesOrder[i];
      const spaceIndex = spaces.findIndex(x => x._id === id);
      if (spaceIndex >= 0) {
        result.push(spaces[spaceIndex]);
        spaces.splice(spaceIndex, 1);
      }
    }
    if (spaces.length) {
      spaces.sort((a: Space, b: Space) => { return (!a._id || !b._id) ? 0 : a._id.localeCompare(b._id); });
      result.push(...spaces)
    }
    return result;
  }
  getWidgets(items: SpaceWidgetConfig[] | null | undefined): SpaceWidgetConfig[] {
    let result: SpaceWidgetConfig[] = [];
    if (items && items.length) {
      items.forEach(item => {
        if (item.type === "grid") {
          result = result.concat(this.getWidgets(item.widgets));
        } else if (item.type === "widget") {
          result.push(item);
        }
      });
    }
    return result;
  } 
  createGrid(): SpaceWidgetConfig {
    return {
      guid: uuidv4(),
      type: "grid",
      size: {
        size: 12,
        sizeSM: 12,
        sizeMD: 12,
        sizeLG: 12,
        sizeXL: 12
      },
      widgets: [],
      // deprecated
      widgetLoc: [],
      widgetSize: []
    };
  }
  createDashboard(parent: Space, name: string): Space {
    return {
      _id: undefined,
      path: parent.path ? `${parent.path}${parent._id},` : `,${parent._id},`,
      spaceName: name,
      spacesOrder: [],
      organisationId: undefined,
      widgets: [],
      version: "vue_001"
    };
  }
  createWidget(widgetDescription: WidgetDescription): [SpaceWidgetConfig, WidgetConfig] {
    const guid = uuidv4();
    const date = (new Date()).valueOf();
    const result: [SpaceWidgetConfig, WidgetConfig] = [
      {
        guid: guid,
        type: "widget",
        size: JSON.parse(JSON.stringify(widgetDescription.defaultSize)),
        widgets: undefined,
        // deprecated fields
        widgetLoc: [],
        widgetSize: [],
      },
      {
        _id: undefined,
        widgetMins: [1, 1],
        widgetMaxs: [12, 12],
        widgetOptions: JSON.parse(JSON.stringify(widgetDescription.defaultOptions)),
        widgetRefreshSecs: 0,
        guid: guid,
        DateUpdated: date,
        DateCreated: date,
        widgetType: widgetDescription.name,
        version: widgetDescription.latestVersion
      }
    ];
    if (widgetDescription.streamSlots?.length && widgetDescription.defaultStreamOptions && result[1].widgetOptions.widgetDataSettings) {
      const wds = result[1].widgetOptions.widgetDataSettings;
      for (let i = 0; i < widgetDescription.streamSlots.length; i++) {
        wds.streamOptions.push(JSON.parse(JSON.stringify(widgetDescription.defaultStreamOptions)));
        if (widgetDescription.name === "tariffsimulator" && wds.streamOptions.length === 2) {
          wds.streamOptions[1].Params.aggType = AggregationType.Max; // max for kW
        }
      }
    }
    return result;
  }
  cloneWidget(spaceWidgetConfig: SpaceWidgetConfig, widgetConfigs: WidgetConfig[]): [SpaceWidgetConfig, WidgetConfig[]] {
    const result: [SpaceWidgetConfig, WidgetConfig[]] = [
      JSON.parse(JSON.stringify(spaceWidgetConfig)),
      JSON.parse(JSON.stringify(widgetConfigs))
    ];
    const date = (new Date()).valueOf();
    // remove _id
    result[1].forEach(widgetConfig => {
      widgetConfig._id = undefined;
    });
    // replace guid 
    function iterateTree(spaceWidgetConfig: SpaceWidgetConfig, widgetConfigs: WidgetConfig[]): void {
      const guid = spaceWidgetConfig.guid;
      const newGuid = uuidv4();
      spaceWidgetConfig.guid = newGuid;
      if (spaceWidgetConfig.type === "widget") {
        const widgeConfig = widgetConfigs.find(x => x.guid === guid);
        if (widgeConfig) {
          widgeConfig.guid = newGuid;
          widgeConfig.DateCreated = date;
          widgeConfig.DateUpdated = date;
        }
      } else if (spaceWidgetConfig.type === "grid" && spaceWidgetConfig.widgets?.length) {
        spaceWidgetConfig.widgets.forEach(swc => {
          iterateTree(swc, widgetConfigs);
        })
      }
    }
    iterateTree(result[0], result[1]);
    return result;
  }
  canMoveTo(spaceWidgetConfigSource: SpaceWidgetConfig, spaceWidgetConfigDestination: SpaceWidgetConfig): boolean {
    let result = spaceWidgetConfigSource.guid !== spaceWidgetConfigDestination.guid;
    if (result && spaceWidgetConfigSource.widgets?.length) {
      for (let i = 0; i < spaceWidgetConfigSource.widgets.length; i++) {
        result = this.canMoveTo(spaceWidgetConfigSource.widgets[i], spaceWidgetConfigDestination);
        if (!result) {
          break;
        }
      }
    }
    return result;
  }
  deleteWidget(spaceWidgetConfigs: SpaceWidgetConfig[], widgetGuid: string): boolean {
    for(let i = 0; i < spaceWidgetConfigs.length; i++) {
      const spaceWidgetConfig = spaceWidgetConfigs[i];
      if (spaceWidgetConfig.guid === widgetGuid) {
        spaceWidgetConfigs.splice(i, 1);
        return true;
      }
      if (spaceWidgetConfig.type === "grid" && spaceWidgetConfig.widgets?.length) {
        const result = this.deleteWidget(spaceWidgetConfig.widgets, widgetGuid);
        if (result) {
          return result;
        }
      }
    }
    return false;
  }
  buildFlatList(spaces: Space[] | null | undefined): IdName[] {
    const result: IdName[] = [];
    const spacesOrderByPath = spaces?.sort((a, b) => a.path && b.path ? (`${a.path}${a._id},`).localeCompare(`${b.path}${b._id},`) : 0);
    spacesOrderByPath?.forEach(x => {
      if (x._id && x.path) {
        const prefixLength = x.path.split(",").filter(x => x).length - 1;
        const prefix = prefixLength > 0 ? "-".repeat(prefixLength) : "";
        result.push({ id: x._id, name: `${prefix}${x.spaceName}` });
      }
    });
    return result;
  }
  private buildTreeForTreeSelectInternal(spaces: Space[], isOrganisation: boolean): [TreeNodeForUI[], Record<string, TreeNodeForUI>] {
    const result: TreeNodeForUI[] = [];
    const dictionary: Record<string, TreeNodeForUI> = {};
    const sorted = spaces?.sort((a, b) => a.path && b.path ? (`${a.path}${a._id},`).localeCompare(`${b.path}${b._id},`) : 0);
    for (let i = 0; i < sorted.length; i++) {
      const space = sorted[i];
      if (space._id) {
        dictionary[space._id] = {
          key: space._id, 
          label: space.path ? space.spaceName : (isOrganisation ? "Organisation" : "Personal"),
          additionalData: { isOrganisation: isOrganisation, space: space },
          icon: !space.path ? (isOrganisation ? "pi pi-fw pi-building" : "pi pi-fw pi-user") : undefined,
          children: [],
          leaf: true,
          selectable: !!space.path
        };
        if (space.path) {
          const parentId = this.getParentId(space);
          if (parentId) {
            dictionary[parentId]?.children?.push(dictionary[space._id]);
          }
        } else {
          result.push(dictionary[space._id]);
        }
      }
    }
    return [result, dictionary];
  }
  buildTreeForTreeSelect(organisationSpaces: Space[] | null | undefined, personalSpaces: Space[] | null | undefined): [TreeNodeForUI[], Record<string, TreeNodeForUI>, Record<string, TreeNodeForUI>]  {
    let result1: [TreeNodeForUI[], Record<string, TreeNodeForUI>] | null = null;
    let result2: [TreeNodeForUI[], Record<string, TreeNodeForUI>] | null = null;
    if (organisationSpaces?.length) {
      result1 = this.buildTreeForTreeSelectInternal(organisationSpaces, true);
    }
    if (personalSpaces?.length) {
      result2 = this.buildTreeForTreeSelectInternal(personalSpaces, false);
    }
    let tree = result1 ? result1[0] : [];
    if (result2) {
      tree = tree.concat(result2[0]);
    }
    return [tree, result1 ? result1[1] : {}, result2 ? result2[1] : {}];
  }
}

export default new SpaceHelper();
