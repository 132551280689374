<template>
<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M12.5 13C12.5 12.0696 12.5 11.6045 12.3816 11.2259C12.115 10.3737 11.4272 9.70667 10.5483 9.44813C10.1579 9.33333 9.67822 9.33333 8.71875 9.33333H5.28125C4.3218 9.33333 3.84207 9.33333 3.45172 9.44813C2.57281 9.70667 1.88503 10.3737 1.61841 11.2259C1.5 11.6045 1.5 12.0696 1.5 13M10.0938 4C10.0938 5.65685 8.70864 7 7 7C5.29137 7 3.90625 5.65685 3.90625 4C3.90625 2.34315 5.29137 1 7 1C8.70864 1 10.0938 2.34315 10.0938 4Z" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class UserSvg extends Vue {
}

export default UserSvg;
</script>