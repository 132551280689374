<template>
  <div 
    class="semantics-settings-container" 
    :class="Object.keys(haysonData[streamKey] as HaysonDict).length ? '' : 'is-empty'" 
    v-if="haysonData"
  >
    <div class="semantics-config-search-add-field">
      <!-- Search -->
      <div class="semantics-config-search px-1">
        <IconField iconPosition="left" class="search-input-box">
          <InputIcon class="pi pi-search"></InputIcon>
          <InputText
            class="inputfield"
            placeholder="Search"
            type="text"
            v-model="search"
            @input="debounceSearch()"
          />
        </IconField>
      </div>

      <!-- Add Field -->
      <div class="semantics-config-add-field">
        <Button label="Add Tag" icon="pi pi-plus" class="p-button-secondary" @click="openAddTagDialog"/>
      </div>
    </div>
    
    <div class="semantics-settings-container-inner relative flex-auto">
      <div>
        <div class="formgrid grid" id="semantics-tags">
          <HaystackDictEditView
            ref="tagEditor"
            :inputId="`root-${streamKey}`"
            v-model="haysonData" 
            :field="streamKey" 
            def=""
            :idPrefix="`tm-`"
            :excludeFields="excludeFields"
            :mandatoryFields="mandatoryFields"
            :disabledFields="disabledFields"
            :isLib="false"
            :allowAny="true"
            :allowRef="true"
            :isFirstLevel="true"
            :externalTopControls="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonDict } from "haystack-core";
import HaystackDictEditView from "@/components/views/tags/haystack-edit/HaystackDictEditView.vue";

@Component({
  components: {
    InputText,
    Button,
    IconField,
    InputIcon,
    HaystackDictEditView
  },
})
class TagManagerTagsView extends Vue {
  @Model haysonData!: HaysonDict;
  @Prop streamKey!: string;

  mandatoryFields = ["id", "site", "space", "equip", "point", "siteRef", "spaceRef", "equipRef"];
  excludeFields = ["ref"];
  disabledFields = ["id", "siteRef", "spaceRef", "equipRef", "system", "systemRef"];

  search = "";

  debounceSearch(): void {
    if (this.$refs.tagEditor) {
      (this.$refs.tagEditor as HaystackDictEditView).search = this.search;
      (this.$refs.tagEditor as HaystackDictEditView).debounceSearch();
    }
  }

  openAddTagDialog(): void {
    if (this.$refs.tagEditor) {
      this.search = "";
      (this.$refs.tagEditor as HaystackDictEditView).openAddTagDialog();
    }
  }
}

export default TagManagerTagsView;
</script>