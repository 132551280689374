<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36"><path fill="#fff" fill-rule="evenodd" d="M18.182 10.885c0 3.667-3.062 6.636-6.845 6.636-3.784 0-6.846-2.97-6.846-6.636S7.553 4.25 11.336 4.25c3.784 0 6.846 2.969 6.846 6.635ZM.75 28.71c0-5.515 4.737-9.995 10.587-9.995s10.586 4.48 10.586 9.995v2.06c0 .666-.572 1.205-1.282 1.205H2.032c-.71 0-1.282-.539-1.282-1.205v-2.06Zm22.713-16.705a.75.75 0 0 0-1.044-1.077 7.372 7.372 0 0 0-.001 10.627.75.75 0 0 0 1.046-1.074c-2.411-2.348-2.41-6.14 0-8.476Zm9.835-1.076a.75.75 0 1 0-1.046 1.075c2.411 2.348 2.41 6.14.001 8.476a.75.75 0 1 0 1.044 1.076 7.372 7.372 0 0 0 .001-10.627Zm-7.864 2.977a.75.75 0 1 0-1.044-1.076 4.724 4.724 0 0 0 0 6.814.75.75 0 1 0 1.044-1.076 3.224 3.224 0 0 1 0-4.662Zm5.903-1.065a.75.75 0 1 0-1.045 1.076 3.224 3.224 0 0 1 0 4.661.75.75 0 0 0 1.045 1.076 4.724 4.724 0 0 0 0-6.813Zm-3.479 1.299c-1.156 0-2.138.917-2.138 2.102a2.1 2.1 0 0 0 1.388 1.969v5.46a.75.75 0 0 0 1.5 0v-5.458a2.094 2.094 0 0 0 1.388-1.97c0-1.186-.982-2.103-2.138-2.103Zm-.638 2.102c0-.315.268-.602.638-.602s.638.287.638.602c0 .32-.261.603-.638.603a.621.621 0 0 1-.638-.603Z" clip-rule="evenodd"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class OrgUsersActiveMembersIconSvg extends Vue {
}

export default OrgUsersActiveMembersIconSvg;
</script>