import ErrorHelper from "@/helpers/ErrorHelper";
import { EncodingInfo } from "@/models/EncodingInfo";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import ImportDataState from "../states/ImportDataState";

const getters = <GetterTree<ImportDataState, any>>{};

const mutations = <MutationTree<ImportDataState>>{};

const actions = <ActionTree<ImportDataState, any>>{
  async loadEncodings({ state }) {
    try {
      state.isLoadedEncodings = false;
      state.encodings = [];
      const url = `rest/BitPool_V2/Import/Encoding`;
      const response = await axios.get<EncodingInfo[]>(url);
      state.encodings = response.data;
      state.isLoadedEncodings = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load encodings",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.encodings = [];
      state.isLoadedEncodings = true;
    }
  },
};

const ImportDataModule = {
  namespaced: true,
  state: new ImportDataState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default ImportDataModule;
