import ErrorHelper from "@/helpers/ErrorHelper";
import NavigationHelper from "@/helpers/NavigationHelper";
import { PresentationModel } from "@/models/dashboard/PresentationModel";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import PresentationState from "../states/PresentationState";
import { useOrganisationStore } from "@/stores/organisation";

const getters = <GetterTree<PresentationState, any>>{};

const mutations = <MutationTree<PresentationState>>{
  unloadCurrentPresentation(state) {
    if (state.currentPresentation) {
      state.currentPresentation = null;
      state.isLoadedCurrentPresentation = false;
    }
  },
  add(state, config: PresentationModel) {
    state.configs.push(config);
  },
  delete(state, id: string) {
    for (let i = state.configs.length - 1; i >= 0; --i) {
      if (state.configs[i]._id === id) {
        state.configs.splice(i, 1);
        break;
      }
    }
  },
};

const actions = <ActionTree<PresentationState, any>>{
  async load({ state }) {
    try {
      state.isLoaded = false;
      state.configs = [];
      const url = `rest/BitPool_V2/os/Presentations`;
      const response = await axios.get<PresentationModel[]>(url);
      response.data.forEach(element => {
        if (typeof element.autoStart === "undefined") {
          element.autoStart = true;
        }
        if (typeof element.loop === "undefined") {
          element.loop = true;
        }
      });
      state.configs = response.data;
      state.isLoaded = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load presentations",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.configs = [];
      state.isLoaded = true;
    }
  },
  async loadOne({ state, rootState }, id: string) {
    try {
      state.isLoadedCurrentPresentation = false;
      state.currentPresentation = null;
      if (id === "new") {
        const organisationStore = useOrganisationStore();
        state.currentPresentation = {
          _id: undefined,
          name: "",
          dashboards: [],
          share_id: undefined, // api will fill
          userId: undefined, // api will fill
          organisationId: organisationStore.currentOrganisation?.Id,
          autoStart: true,
          loop: true,
          enable: true
        };
      } else {
        let isDone = false;
        if (state.isLoaded && state.configs?.length) {
          const presentation = state.configs.find((x) => x._id === id);
          if (presentation) {
            state.currentPresentation = presentation;
            isDone = true;
          }
        }
        if (!isDone) {
          const url = `rest/BitPool_V2/os/Presentations/${id}`;
          const response = await axios.get<PresentationModel>(url);
          if (response.data) {
            if (typeof response.data.autoStart === "undefined") {
              response.data.autoStart = true;
            }
            if (typeof response.data.loop === "undefined") {
              response.data.loop = true;
            }
          }
          state.currentPresentation = response.data;
        }
      }
      state.isLoadedCurrentPresentation = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load presentation",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.currentPresentation = null;
      state.isLoadedCurrentPresentation = true;
    }
  },
  async loadOneByShareId({ state, rootState }, shareId: string) {
    try {
      state.isLoadedCurrentPresentation = false;
      state.currentPresentation = null;
      const url = `rest/BitPool_V2/os/Presentations/share/${shareId}`;
      const response = await axios.get<PresentationModel>(url);
      if (response.data) {
        if (typeof response.data.autoStart === "undefined") {
          response.data.autoStart = true;
        }
        if (typeof response.data.loop === "undefined") {
          response.data.loop = true;
        }
      }
      state.currentPresentation = response.data;
      state.isLoadedCurrentPresentation = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load presentation",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.currentPresentation = null;
      state.isLoadedCurrentPresentation = true;
    }
  },
  async createUpdate({ state }, body: PresentationModel) {
    try {
      state.updateInProgress = true;
      state.updateError = false;
      const url = `rest/BitPool_V2/os/Presentations2`;
      const response = await axios.put<string>(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      state.updateInProgress = false;
      ToastService.showToast("success", "", "Changes saved", 5000);
      if (body._id) {
        const index = state.configs.findIndex((x) => x._id === body._id);
        if (index >= 0) {
          state.configs[index] = body;
        }
      } else {
        NavigationHelper.goTo(`/dashboards/presentation/${response.data}`);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save presentation",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.updateInProgress = false;
      state.updateError = true;
    }
  },
  async delete({ state, commit }, id: string) {
    try {
      const url = `rest/BitPool_V2/os/Presentations/${id}`;
      await axios.delete(url);
      ToastService.showToast("success", "", "Changes saved", 5000);
      commit("presentation/delete", id, { root: true });
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete presentation",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
};

const PresentationModule = {
  namespaced: true,
  state: new PresentationState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default PresentationModule;
