<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 247 279">
    <g :clip-path="`url(#a-${guid})`">
      <path
      :fill="`url(#b-${guid})`"
        d="M74.7 70.8 37.5 92.5v99.3L62.2 206v-43.4l61.6 35.6 12.4-7.2v43.5l24.6-14.2v-99.7L74.7 70.8Zm49.1 99-36.9-21.3-24.7-14.2v-27.5l12.6-7.4 61.4 35.6v27.6l-12.4 7.2Z"
      />
      <path
        :fill="`url(#c-${guid})`"
        d="m148.5 56.8-24.7-14.3-24.6 14.2L123.8 71l24.7-14.2Z"
      />
      <path
        :fill="`url(#d-${guid})`"
        d="m171.2 70-24.6 14.2 38.7 22.4v99.7l24.6-14.3V92.4L171.2 70Z"
      />
      <path
        :fill="`url(#e-${guid})`"
        d="M236.9 61.4 133.9 2c-5.6-3.2-14.7-3.2-20.3 0l-103 59.5C4.8 64.8.4 72.3.4 79v119c0 6.7 4.4 14.2 10.1 17.6l103 59.5c2.8 1.6 6.4 2.5 10.1 2.5 3.7 0 7.3-.9 10.1-2.5l103-59.5c5.8-3.3 10.1-10.9 10.1-17.6V79c.3-6.7-4.1-14.2-9.9-17.6Zm-9.4 21.8v110.6c0 2.3-2 5.7-4 6.9L127.7 256c-1.9 1.1-6.1 1.1-7.9 0L24 200.7c-2-1.2-4-4.6-4-6.9V83.2c0-2.3 2-5.7 4-6.9L119.8 21c.9-.5 2.4-.9 4-.9s3 .3 4 .9l95.8 55.3c1.9 1.2 3.9 4.6 3.9 6.9Z"
      />
    </g>
    <defs>
      <linearGradient
        :id="`b-${guid}`"
        x1="37.534"
        x2="160.8"
        y1="152.691"
        y2="152.691"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--button-light-color)" />
        <stop offset="1" stop-color="var(--button-light-color)" />
      </linearGradient>
      <linearGradient
        :id="`c-${guid}`"
        x1="99.206"
        x2="148.454"
        y1="56.751"
        y2="56.751"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--button-light-color)" />
        <stop offset="1" stop-color="var(--button-light-color)" />
      </linearGradient>
      <linearGradient
        :id="`d-${guid}`"
        x1="146.589"
        x2="209.931"
        y1="138.128"
        y2="138.128"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--button-light-color)" />
        <stop offset="1" stop-color="var(--button-light-color)" />
      </linearGradient>
      <linearGradient
        :id="`e-${guid}`"
        x1=".406"
        x2="247.059"
        y1="138.55"
        y2="138.55"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="var(--button-light-color)" />
        <stop offset="1" stop-color="var(--button-light-color)" />
      </linearGradient>
      <clipPath :id="`a-${guid}`"><path fill="#fff" d="M0 0h247v279H0z" /></clipPath>
    </defs>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import { v4 as uuidv4 } from "uuid";

@Component
class OpenAISvg extends Vue {
  guid = uuidv4();
}

export default OpenAISvg;
</script>
