<template>
  <div class="widget-type-title">
    <div>
      <h2 
        ref="textContainer"
        :style="[ {color: textColor} ]"
      >
        <a :style="{color: textColor}" v-if="hasLink" :href="link" :target="target" @click="goToInternalDahboard">
          <span><i class="pi pi-link" style="font-size: 1em;"></i> <span>{{widgetConfig.widgetOptions.basicWidgetSettings.widgetName}}</span></span>
        </a>
        <span v-else>{{widgetConfig.widgetOptions.basicWidgetSettings.widgetName}}</span>
      </h2>
    </div>
  </div>
</template>

<script lang="ts">
import ColorHelper from '@/helpers/ColorHelper';
import NavigationHelper from '@/helpers/NavigationHelper';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { nextTick } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import fitty, { FittyInstance, FittyOptions } from 'fitty';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { DashboardType } from '@/models/dashboard/DashboardType';

@Component({
})
class TitleWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  get backgroundColor(): string {
    const result = this.aws?.widgetColorEnabled && this.aws?.widgetColorHex ? this.aws?.widgetColorHex : '';
    return result;
  }

  get textColor(): string {
    return ColorHelper.getContrastColor(this.backgroundColor);
  }

  get hasLink(): boolean {
    return this.aws?.linkType === 1 && !!this.aws?.urlLink || this.aws?.linkType === 0 && !!this.aws?.link;
  }

  get link(): string | undefined {
    if (this.aws?.linkType === 0) {
      const id = this.aws.link;
      const dashboardType = this.aws.linkDashboardType === DashboardType.Personal ? DashboardType.Personal : DashboardType.Organisation;
      return `/dashboards?pane=${dashboardType}&id=${id}`;
    } else {
      return this.aws?.urlLink;
    }
  }

  get target(): string {
    if (this.aws?.linkType === 0) {
      return "_self";
    } else {
      return "_blank";
    }
  }

  fittyInstance: FittyInstance | null = null;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  mounted(): void {
    this.initFitty();
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
  }

  unmounted(): void {
    if (this.fittyInstance) {
      this.fittyInstance.unsubscribe();
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.recalcFontSize();
  }

  initFitty(): void {
    if (this.$refs.textContainer) {
      const params = {
        minSize: 24,
        maxSize: 56,
        observeMutations: false
      } as unknown;
      (fitty as any).observeWindowDelay = 110;
      this.fittyInstance = fitty(this.$refs.textContainer as HTMLElement, params as FittyOptions);
    }
  }

  @Watch('aws', { immediate: false, deep: true })
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async recalcFontSize(): Promise<void> {
    if (this.fittyInstance) {
      this.fittyInstance.unsubscribe();
      await nextTick();
      this.initFitty();
    }     
  }

  goToInternalDahboard(event: Event): void {
    if (this.aws?.linkType === 0 && this.aws.link) {
      event.preventDefault();
      const id = this.aws.link;
      const dashboardType = this.aws.linkDashboardType === DashboardType.Personal ? DashboardType.Personal : DashboardType.Organisation;
      if (id) {
        NavigationHelper.goToDashboard(dashboardType, id);
      }
    }
  }
}

export default TitleWidget;
</script>