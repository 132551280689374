<template>
  <div>
    <DataTable
      :value="state.configs" 
      v-model:expandedRows="expandedRows" 
      dataKey="Id"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint haystack-table">
      <template #header>
        <div class="table-header">
          <Button label="Add Configuration" icon="pi pi-plus-circle" class="my-1" @click="openCreateUpdateDialog(null)" />
        </div>
      </template>
      <template #empty>
        <div v-if="state.isLoaded && stateHaystack.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 48px;" bodyClass="no-label-in-stack">
        <template #body="slotProps">
          <div class="inline-flex expand-toggler-btn">
            <Button :icon="(preCloseState.findIndex(x => x === slotProps.data.Id) >= 0 || !expandedRows[slotProps.data.Id]) ? 'pi pi-chevron-right' : 'pi pi-chevron-down'" class="p-button-icon-only p-button-rounded p-button-text p-button-secondary" @click="toggleRow(slotProps.data)" />
          </div>
        </template>
      </Column>
      <Column field="Endpoint" header="Endpoint" headerStyle="width: 30%;">
        <template #body="slotProps">
          <span class="block with-inline-btn">
            <span>{{buildEndpointUrl(slotProps.data)}}</span>
            <Button
              icon="pi pi-copy text-lg" 
              class="p-button-icon-only p-button-rounded p-button-text" 
              @click="copyKey(buildEndpointUrl(slotProps.data))"
            />
          </span>
        </template>
      </Column>
      <Column field="Organisation" header="Organisation" headerStyle="width: 15%;"></Column>
      <Column field="HaystackVersion" header="Version" headerStyle="width: 13%;"></Column>
      <Column field="Sites" header="Sites" headerStyle="width: 26%;">
        <template #body="slotProps">
          <span>
            {{slotProps.data.Sites ? slotProps.data.Sites.map((x: string) => { 
              const site = stateHaystack.sites.find(y => y.Key === x);
              if (site) {
                return site.Name;
              } else {
                return x;
              }
            }).join(', ') : ""}}
          </span>
        </template>
      </Column>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <div>
            <div class="inline-flex" v-tippy="{ content: (currentOrganisation?.Name === slotProps.data.Organisation && (userId === slotProps.data.UserId || authState.permissions && authState.permissions.FullAccess)) ? '' : (userId === slotProps.data.UserId || authState.permissions && authState.permissions.FullAccess) ? `Please switch to '${slotProps.data.Organisation}' organisation` : 'Only Owner and CoOwner can edit this record' }">
              <Button
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2" 
                @click="openCreateUpdateDialog(slotProps.data)" 
                :disabled="currentOrganisation?.Name !== slotProps.data.Organisation || (userId !== slotProps.data.UserId && !(authState.permissions && authState.permissions.FullAccess))"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                :disabled="currentOrganisation?.Name !== slotProps.data.Organisation || (userId !== slotProps.data.UserId && !(authState.permissions && authState.permissions.FullAccess))"
              />
            </div>
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <transition name="p-toggleable-content" appear>
          <ul class="haystack-table-other-fields" v-if="preCloseState.findIndex(x => x === slotProps.data.Id) < 0">
            <li class="pt-3">
              <span><b>Login</b></span>
              <span class="flex align-items-center">
                <span class="inline-block with-inline-btn">
                  <span class="break-word">{{slotProps.data.Login}}</span>
                  <Button
                    icon="pi pi-copy text-lg" 
                    class="p-button-icon-only p-button-rounded p-button-text" 
                    @click="copyKey(slotProps.data.Login)"
                  />
                </span>
              </span>
            </li>
            <li class="pb-3 my-3 sm:my-0" v-if="slotProps.data.Password">
              <span><b>Password</b></span>
              <span class="flex align-items-center"><HiddenTextView :text="slotProps.data.Password" :copy="true"/></span>
            </li>                                       
          </ul>
        </transition>
      </template>
    </DataTable>
    <Dialog header="Haystack Server Configuration" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="haystack-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="updateInProgress ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <div class="col-12">
              <div class="grid">
                <div class="field col">
                  <label for="editRecordName">Name</label>
                  <div>
                    <InputText 
                      id="editRecordName" 
                      class="inputfield p-inputtext-lg w-full"
                      type="text" 
                      v-model="editRecord.Name"
                    />
                  </div>
                </div>
                <div class="field col-fixed">
                  <label for="editRecordVersion">Version</label>
                  <div>
                    <SelectButton 
                      v-model="editRecord.HaystackVersion"
                      :options="['3', '4']"
                      class="selectbutton-lg"
                      :allowEmpty="false"
                    />
                  </div>
                </div>
              </div>
            </div>
            
            <div class="field col-12 md:col-6">
              <label for="editRecordLogin">Login</label>
              <div>
                <InputText 
                  id="editRecordLogin" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Login"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="editRecordPassword">Password</label>
              <div>
                <Password
                  id="editRecordPassword"
                  class="w-full"
                  inputClass="inputfield p-inputtext-lg w-full"
                  v-model="editRecord.Password"
                  :feedback="false"
                  toggleMask
                />
              </div>
            </div>
            <div class="field col-12 mb-0">
              <label for="editRecordEndpointSites">Select Sites</label>
              <div>
                <MultiSelect 
                  id="editRecordEndpointSites" 
                  v-model="editRecord.Sites" 
                  :options="groupedSites" 
                  optionGroupLabel="Key"
                  optionGroupChildren="items"
                  :loading="!stateHaystack.isLoaded" 
                  optionValue="Key" 
                  optionLabel="Name" 
                  :filter="true"
                  filterPlaceholder="Find sites"
                  placeholder="Select sites"
                  class="p-multiselect-multiline w-full dropdown-lg"
                >
                  <template #optiongroup="slotProps">
                    <div class="flex align-items-center font-bold">
                      <i class="pi pi-folder font-bold mr-2 flex-shrink-0"></i>
                      <div class="flex-auto">{{ slotProps.option.Key }}</div>
                    </div>
                  </template>
                </MultiSelect>
              </div>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button v-if="editRecord" :label="editRecord.Id ? 'Update' : 'Create'" :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveRecord" :disabled='!editRecord.Name || !editRecord.Login || !editRecord.Password || updateInProgress' />
          </span>
        </div>
      </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="displayConfirmation" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="flex align-items-center">
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete record?</span>
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary"/>
        <Button label="Yes" icon="pi pi-check" @click="deleteRecord" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import InlineMessage from 'primevue/inlinemessage';
import MultiSelect from 'primevue/multiselect';
import ProgressSpinner from 'primevue/progressspinner';
import SelectButton from 'primevue/selectbutton';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import HiddenTextView from "@/components/views/HiddenTextView.vue";
import CustomWindow from '@/CustomWindow';
import HaystackServerState from "@/stores/states/HaystackServerState";
import { HaystackConfig } from "@/models/HaystackConfig";
import HaystackState from "@/store/states/HaystackState";
import copy from 'copy-to-clipboard';
import ToastService from "@/services/ToastService";
import AuthState from "@/store/states/AuthState";
import { HaystackSimpleObject } from "@/models/HaystackSimpleObject";
import { useOrganisationStore } from "@/stores/organisation";
import useHaystackServerStore from "@/stores/haystackServer";

declare const window: CustomWindow;

@Component({
  
  components: {
    Button,
    InputText,
    Password,
    Dropdown,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    InlineMessage,
    MultiSelect,
    ProgressSpinner,
    SelectButton,
    HiddenTextView
  },
  directives: {
  }
})
class HaystackServerView extends Vue { 
  haystackServer = useHaystackServerStore();

  get state(): HaystackServerState {
    return this.haystackServer;
  }

  get stateHaystack(): HaystackState {
    return this.$store.state.haystack;
  }

  get groupedSites(): any[] {
    if (this.stateHaystack.sites?.length) {
      const result: any[] = [];
      const sites: HaystackSimpleObject[] = [];
      const pools: HaystackSimpleObject[] = [];
      this.stateHaystack.sites.forEach(x => {
        if (x.Key.startsWith("pool-")) {
          pools.push(x);
        } else {
          sites.push(x);
        }
      });
      if (sites.length) {
        result.push({
          Key: "Sites",
          items: sites
        });
      }
      if (pools.length) {
        result.push({
          Key: "Pools",
          items: pools
        });
      }
      return result;
    } else {
      return [];
    }
  }

  get userId(): string {
    const userData = this.allUserData;
    const result = userData && userData.userData ? userData.userData.UserId : "";
    return result;
  }

  expandedRows: Record<string, boolean> = {};
  preCloseState: string[] = [];

  toggleRow(row: HaystackConfig): void {
    if (this.expandedRows[row.Id]) {
      // close
      this.preCloseState.push(row.Id);
      window.setTimeout(() => {
        const idIndex = this.preCloseState.findIndex(x => x === row.Id);
        if (idIndex >= 0 && this.expandedRows[row.Id]) {
          const newExpandedRows = { ...this.expandedRows };
          delete newExpandedRows[row.Id];
          this.expandedRows = newExpandedRows;
          this.preCloseState.splice(idIndex, 1);
        }
      }, 450);
    } else {
      // open
      const idIndex = this.preCloseState.findIndex(x => x === row.Id);
      if (idIndex >= 0) {
        this.preCloseState.splice(idIndex, 1);
      }
      this.expandedRows = { ...this.expandedRows, [row.Id]: true };
    }
  }

  created(): void {
    this.haystackServer.load();
    this.$store.dispatch("haystack/load");
  }

  // #region new/edit
  displayCreateUpdateDialog = false;
  editRecord: HaystackConfig | null = null;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  openCreateUpdateDialog(record: HaystackConfig | null): void {
    this.editRecord = record ? Object.assign({}, record) : {
      Id: "",
      Login: "",
      Organisation: this.currentOrganisation ? this.currentOrganisation.Name : "",
      UserId: this.allUserData.userData.UserId,
      Password: "",
      Pool: "",
      PoolKey: "",
      AuthKey: "",
      Sites: [],
      HaystackVersion: "4",
      Name: ""
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.state.updateInProgress;
  }

  get updateError(): boolean {
    return this.state.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.haystackServer.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }
  // #endregion new/edit

  // #region delete
  displayConfirmation = false;
  selectedRecord: HaystackConfig | null = null;

  openConfirmation(record: HaystackConfig | null): void {
    this.selectedRecord = record;
    this.displayConfirmation = true;
  }

  closeConfirmation(): void {
    this.displayConfirmation = false;
  }

  deleteRecord(): void {
    this.closeConfirmation();
    if (this.selectedRecord) {
      this.haystackServer.delete(this.selectedRecord.Id);
    }
  }
  // #endregion delete

  buildEndpointUrl(model: HaystackConfig): string {
    const hsHost = location.hostname.includes("localhost") ?
      "http://localhost:3000" : 
      (location.hostname.includes("beta") || location.hostname.includes("test")) ? 
        "https://beta-haystack.bitpool.com" :
        "https://haystack.bitpool.com";
    return `${hsHost}/${model.Organisation.replace(/ /g, "%20")}/${model.Name.replace(/ /g, "%20")}`;
  }

  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }
}

export default HaystackServerView;
</script>