<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36"><path fill="#fff" d="M18.204 10.527c0 3.467-2.894 6.277-6.464 6.277-3.57 0-6.464-2.81-6.464-6.277S8.17 4.25 11.74 4.25c3.57 0 6.464 2.81 6.464 6.277ZM32.308 11.098c0 3.151-2.631 5.706-5.877 5.706-3.245 0-5.876-2.554-5.876-5.706 0-3.152 2.63-5.707 5.876-5.707 3.246 0 5.877 2.555 5.877 5.707ZM1.75 27.405c0-5.224 4.473-9.46 9.99-9.46 5.518 0 9.99 4.236 9.99 9.46v1.954c0 .63-.54 1.141-1.205 1.141H2.955c-.665 0-1.205-.511-1.205-1.141v-1.954ZM24.675 30.335h9.635c.657 0 1.19-.504 1.19-1.127v-1.745c0-5.256-4.5-9.517-10.052-9.517-1.85 0-3.583.473-5.072 1.298 2.628 2.094 4.299 5.231 4.299 8.738v2.353Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class OrgUsersGeneralMembersIconSvg extends Vue {
}

export default OrgUsersGeneralMembersIconSvg;
</script>