<template>
  <div class="widget-type-weather">
    <div v-if="isLoading" class="h-full flex justify-content-center align-items-center flex-auto">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-else-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType" message="Location have not been selected."/>
    </div>
    <div v-else-if="weather" class="weather">
      <div class="weather-info">
        <div class="weather-day">Today</div>
        <div class="weather-location" v-if="aws && aws.location">{{aws.location.full}}</div>
      </div>
      <div class="weather-temperature-cloudy">
        <span v-if="weather.weather && weather.weather.length" class="weather-icon">
          <img :src="`/assets/weather/${weather.weather[0].icon}.svg`"/>
        </span>
        <div>
          <span class="weather-temperature">{{Math.round(weather.main.temp)}}°</span>
          <span class="weather-cloudy" v-if="weather.weather && weather.weather.length">{{weather.weather[0].main}}, {{weather.weather[0].description}}</span>
        </div>
      </div>
      <div class="weather-humidity"><WaterDrop /> Humidity: {{weather.main.humidity}}%</div>
    </div>
    <div v-else>Can't load weather data</div>
  </div>
</template>

<script lang="ts">
import ProgressSpinner from 'primevue/progressspinner';
import ErrorHelper from '@/helpers/ErrorHelper';
import WeatherHelper from '@/helpers/WeatherHelper';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { CurrentResponse } from '@/models/openweather/CurrentResponse';
import ToastService from '@/services/ToastService';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import WaterDrop from "@/components/svg/WaterDrop.vue";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';

@Component({
  components: {
    ProgressSpinner,
    WaterDrop,
    WidgetNoDataView
  }
})
class WeatherWidget extends Vue {
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get isNoData(): boolean {
    return !this.aws?.location;
  }

  noDataType = WidgetNoDataTypes.NotConfigured;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  weather: CurrentResponse | null = null;
  isLoading = true;

  dataRefreshInterval = 0;

  created(): void {
    this.loadData();
  }

  reloadDataEverySeconds = 3600; // 1 hour

  mounted(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.loadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  @Watch('aws.location', { immediate: false, deep: true })
  onLocationChanged(): void {
    this.loadData();
  }

  async loadData(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
      this.weather = null;
    }
    if (this.aws && this.aws?.location) {
      try {
        this.weather = await WeatherHelper.getCurrent(this.aws.location.lat, this.aws.location.lng);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load weather data",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    }
    this.isLoading = false;
  }
}

export default WeatherWidget;
</script>