import { StreamsListModel } from "@/models/StreamsListModel";
import { StreamsListModelLite } from "@/models/StreamsListModelLite";
import StreamsSearchPageQuery from "@/models/StreamsSearchPageQuery";

export default class StreamsState {
  streamsList: StreamsListModelLite | null = null;
  isLoadedList = false;
  isLoadingList = false;
  guidList = "";

  streamsPage: StreamsListModel | null = null;
  isLoadedPage = false;
  guidPage = "";
  pageQuery: StreamsSearchPageQuery | null = null;

  selectedStreamKeys: string[] = [];
}
