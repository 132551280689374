import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios, { AxiosResponse } from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { OrganisationSpaceAccessModel } from "@/models/organisation/OrganisationSpaceAccessModel";
import OrganisationSpaceAccessState from "./states/OrganisationSpaceAccessState";

export const useOrganisationSpaceAccessStore = defineStore('organisationSpaceAccess', {
  state: (): OrganisationSpaceAccessState => ({ 
    isLoaded: false,
    guid: "",
    organisationId: 0,
    groupId: 0,
    entities: null,
    updateInProgress: false,
    updateError: false
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number, groupId: number) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.organisationId = organisationId;
        this.groupId = groupId;
        this.isLoaded = false;
        this.entities = null;
        const response = await axios.get<OrganisationSpaceAccessModel[]>(`rest/BitPool_V2/os/space/group?organisationId=${organisationId}`);
        if (this.guid === guid) {
          this.entities = response.data as OrganisationSpaceAccessModel[];
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load dashboards permissions",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
      }
    },
    async save(body: OrganisationSpaceAccessModel[]): Promise<boolean> {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/os/space/group/many`;
        const response = await axios.put<OrganisationSpaceAccessModel[]>(url, body);
        if (response.data) {
          for (const item of response.data) {
            if (this.isLoaded && this.entities) {
              const index = this.entities.findIndex((x) => x._id === item._id);
              if (index < 0) {
                this.entities.push(item);
              } else {
                this.entities[index] = item;
              }
            }
          }
        }
        this.updateInProgress = false;
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save dashboards permissions",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return false;
      }
    }
  },
})
