<template>
  <div class="default-view-container">
    <h1 class="mb-0">MQTT</h1>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <div class="default-view-body">
        <TabView lazy v-if="mqttStore.isLoadedScripts">
          <TabPanel header="Bitpool Endpoint">
            <MQTTServerView></MQTTServerView>
          </TabPanel>
          <TabPanel header="External Broker">
            <MQTTClientView></MQTTClientView>
          </TabPanel>
          <TabPanel header="Data Parser Scripts">
            <MQTTScriptsView></MQTTScriptsView>
          </TabPanel>
        </TabView>
        <div v-else class="progress-spinner-container min-h-full">
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import MQTTServerView from './MQTTServerView.vue';
import MQTTClientView from './MQTTClientView.vue';
import MQTTScriptsView from './MQTTScriptsView.vue';
import AuthState from "@/store/states/AuthState";
import { useMQTTStore } from "@/stores/mqtt";
import { useNavTreeStore } from "@/stores/navTree";

@Component({
  
  components: {
    TabView,
    TabPanel,
    Message,
    ProgressSpinner,
    MQTTServerView,
    MQTTClientView,
    MQTTScriptsView
  },
})
class MQTTView extends Vue { 
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  mqttStore = useMQTTStore();

  created() {
    if (this.authState.permissions?.FullAccess) {
      this.mqttStore.loadScripts();
    }
  }
}

export default MQTTView;
</script>