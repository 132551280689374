<template>
  <section class="login animate__animated animate__bounceInLeft">
    <div class="logo-container mb-4">
      <img
        :src="'/assets/bitpool-logo-dark.svg'"
        width="470"
        alt="Bitpool"
        class="logo-light-theme"
      />
      <img
        :src="'/assets/bitpool-logo-white.svg'"
        width="470"
        alt="Bitpool"
        class="logo-dark-theme"
      />
    </div>

    <div class="auth-form">
      <div class="auth-form-inner" v-if="waitFor2fa">
        <h1 class="text-center">{{ t('login.2faCode') }}</h1>
        <div class="field" ref="code2faInput">
          <InputText
            class="inputfield p-inputtext-lg w-full"
            placeholder="2FA code"
            type="text"
            v-model="code2fa"
            @keyup.enter="login2fa"
          />
        </div>
        <div class="field flex align-items-center justify-content-between gap-2 sm:gap-3 mt-4 pt-1">
          <Button
            class="p-button-lg p-button-light-secondary justify-content-center font-medium"
            @click="cancel2fa"
            :disabled="inProgress"
          >
            <span>{{ t('common.cancel') }}</span>
          </Button>
          <Button
            class="p-button-lg justify-content-center font-medium"
            @click="login2fa"
            :disabled="inProgress || !code2fa"
          >
            <span :class="{ 'opacity-0': inProgress }">{{ t('login.verifyCode') }}</span>
            <ProgressSpinner class="spinner-white" style="width: 40px; height: 40px" strokeWidth="6" animationDuration="1s" v-if="inProgress"/>
          </Button>
        </div>

      </div>
      <div class="auth-form-inner" v-else>
        <h1 class="text-center">{{ t('login.login') }}</h1>
        <div class="field">
          <InputText
            autofocus
            class="inputfield p-inputtext-lg w-full"
            :placeholder="t('common.email')"
            type="text"
            v-model="username"
          />
        </div>
        <div class="field">        
          <Password
            class="w-full"
            inputClass="inputfield p-inputtext-lg w-full"
            :placeholder="t('login.confirmPassword')"
            v-model="password"
            :feedback="false"
            @keyup.enter="login"
            toggleMask
          />
        </div>
        <div class="field-checkbox">
          <Checkbox
            inputId="rememberme"
            :binary="true"
            v-model="rememberMe"
          ></Checkbox>
          <label for="rememberme">{{ t('login.rememberMe') }}</label>
        </div>
        <div class="field mt-4 pt-1">
          <Button
            class="w-full p-button-lg justify-content-center font-medium"
            @click="login"
            :disabled="inProgress || !username || !password"
          >
            <span :class="{ 'opacity-0': inProgress }">{{ t('login.continue') }}</span>
            <ProgressSpinner class="spinner-white" style="width: 40px; height: 40px" strokeWidth="6" animationDuration="1s" v-if="inProgress"/>
          </Button>
        </div>
        <div class="field mb-0">
          <span class="login-signup-with-social"><span>{{ t('login.orLoginWith') }}</span></span>
          <ExternalLoginView :disabled="inProgress"/>
        </div>

        <div class="text-center mt-5 pt-2">
          <div>
            <span @click="goForgotPassword" class="link"
              >{{ t('login.forgotYourPassword') }}</span
            >
          </div>
          <div class="mt-3">
            {{ t('login.dontHaveAccount') }}
            <span @click="goSignup" class="link">{{ t('login.signUp') }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-5 pt-2 md:mt-3 md:pt-0 auth-bottom" v-html="t('login.clickHereForHelpHtml', { href: 'mailto:support@bitpool.com' })"></div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AngularOptions from "@/models/AngularOptions";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Password from 'primevue/password';
import ExternalLoginView from "@/components/views/auth/ExternalLoginView.vue";
import { useAuthStore } from "@/stores/auth";
import { AUTH_Login2 } from "@/models/auth/AUTH_Login2";
import { nextTick } from "vue";
import ToastService from "@/services/ToastService";
import { ComposerTranslation, useI18n } from "vue-i18n";
import { MessageSchema } from "@/localeManager";

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    InputText,
    Checkbox,
    Button,
    ProgressSpinner,
    Password,
    ExternalLoginView
  },
})
class LoginView extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  rememberMe = false;
  inProgress = false;
  
  authStore = useAuthStore();

  code2fa = "";
  waitFor2fa = false;

  get username(): string {
    return this.$store.state.login.username;
  }

  set username(value: string) {
    this.$store.commit("login/setUsername", value);
  }

  get password(): string {
    return this.$store.state.login.password;
  }

  set password(value: string) {
    this.$store.commit("login/setPassword", value);
  }

  get displaySection(): string {
    return this.$store.state.login.displaySection;
  }

  set displaySection(value: string) {
    this.$store.commit("login/setDisplaySection", value);
  }

  get loginSuccess(): boolean {
    return this.$store.state.login.loginSuccess;
  }

  set loginSuccess(value: boolean) {
    this.$store.commit("login/setLoginSuccess", value);
  }

  mounted(): void {
    if (this.$store.state.login.signupSuccess) {
      this.$store.commit("login/setSignupSuccess", false);
      this.login();
    }
  }

  goSignup(): void {
    this.displaySection = "sign";
  }

  goForgotPassword(): void {
    this.displaySection = "forgot";
  }

  async login(): Promise<void> {
    this.code2fa = "";
    if (this.username !== "" && this.password !== "") {
      this.inProgress = true;
      const body: AUTH_Login2 = {
        Username: this.username,
        Password: this.password,
        Organisation: "",
        Place: window.location.host
      };
      const response = await this.authStore.login(body, "");
      this.inProgress = false;
      if (response) {
        if (response.authKey === "2fa") {
          this.waitFor2fa = true;
          await nextTick();
          if (this.$refs.code2faInput) {
            (this.$refs.code2faInput as HTMLElement).getElementsByTagName("input")[0].focus();
          }
        } else if (response.authKey === "no-organisation") {
          this.loginSuccess = false;
          this.$store.commit("login/setDisplaySection", "no-organisation");
          ToastService.showToast(
            "error",
            "Can't login",
            "You are not a member of any organisation.",
            5000
          );
        } else {
          this.loginSuccess = true;
          const authKey = response.authKey;
          delete response.authKey;
          this.angularOptions.angularScope.processLogin(response, authKey, this.rememberMe);
        }
      }
    }
  }

  cancel2fa(): void {
    this.waitFor2fa = false;
  }

  async login2fa(): Promise<void> {
    if (this.username !== "" && this.password !== "" && this.code2fa) {
      this.inProgress = true;
      const body: AUTH_Login2 = {
        Username: this.username,
        Password: this.password,
        Organisation: "",
        Place: window.location.host
      };
      const response = await this.authStore.login(body, this.code2fa);
      this.inProgress = false;
      if (response) {
        this.loginSuccess = true;
        const authKey = response.authKey;
        delete response.authKey;
        this.angularOptions.angularScope.processLogin(response, authKey, this.rememberMe);
      }
    }
  }
}

export default LoginView;
</script>@/localeManager