<template>
  <DataTable
    v-model:selection="selectedRecords"
    :value="reportsCollections" 
    dataKey="Id"
    showGridlines 
    responsiveLayout="stack" 
    breakpoint="660px" 
    class="responsive-breakpoint p-datatable-sm organisation-profile-users-groups-reports-edit-table"
    sortField="DateAdded" 
    :sortOrder="-1"
    :paginator="true"
    :rows="20"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
    :rowsPerPageOptions="[10, 20, 50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    v-model:filters="filters"
    filterDisplay="menu"
    :globalFilterFields="['Name']"
    removableSort
  >
    <template #header>
      <div class="table-header">
        <div class="md:flex md:align-items-center md:justify-content-between md:gap-3">
          <div class="md:flex-shrink-0">
            <Button 
              :disabled="!organisationGroupsReportsStore.isLoaded || !reportsCollectionsStore.isLoaded" 
              label="Add Reports Collections" 
              icon="pi pi-plus-circle" 
              class="my-1 mr-2" 
              @click="openCreateDialog"
            />
            <Button 
              v-if="selectedRecords.length > 0"
              label="Delete" 
              icon="pi pi-trash" 
              class="my-1 mr-2 p-button-outlined p-button-danger" 
              @click="openDeleteSelectedRecordsConfirmation()"
            />
          </div>
          <div class="mt-3 md:mt-0">
            <IconField iconPosition="left" class="w-full md:w-auto">
              <InputIcon class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Find Reports Collection" class="w-full md:w-auto" />
            </IconField>
          </div>
        </div>
      </div>
    </template>
    <template #empty>
      <div v-if="organisationGroupsReportsStore.isLoaded && reportsCollectionsStore.isLoaded" class="w-full" style="min-height: 50vh;">
        <span class="inline-block py-2">No data found.</span>
      </div>
      <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 30vh;" v-else>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </div>
    </template>
    <Column selectionMode="multiple" headerStyle="width: 1%; min-width: 3rem;" headerClass="column-with-checkbox" bodyClass="column-with-checkbox"></Column>
    <Column :sortable="true" field="Name" header="Collection Name" headerStyle="min-width: min-content; width: 70%;" headerClass="no-break-word" bodyClass="break-word">
    </Column>
    <Column :exportable="false" headerStyle="width: 1%; min-width: 44px;" bodyStyle="text-align: right; justify-content: flex-end;">
      <template #body="slotProps">
        <div>
          <div class="inline-flex">
            <Button 
              icon="pi pi-trash" 
              class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
              @click="openConfirmation(slotProps.data)" 
              v-tippy="'Delete'"
            />
          </div>
        </div>
      </template>
    </Column>
  </DataTable>
  <Dialog header="Add Reports Collections" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'992px': '80vw'}" :style="{width: '56rem'}" class="organisation-profile-users-groups-reports-config-dialog">
    <div class="dialog-content">
      <BlockUI :blocked="organisationGroupsReportsStore.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="organisationGroupsReportsStore.updateInProgress ? 'blockui-blocked' : ''">
        <div>
          <div class="formgrid grid">
            <div class="field col-12 mb-0">
              <label for="addRecord">Collections</label>
              <div>
                <MultiSelect 
                  inputId="addRecord"
                  v-model="addRecord"
                  :options="availableReportsCollections"
                  placeholder="Select Collections"
                  display="chip"
                  :filter="true"
                  optionLabel="Name"
                  class="p-multiselect-multiline inputfield w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #header>
      <div>
        <div class="p-dialog-title">Add Reports Collections</div>
        <div class="p-dialog-subtitle">Please select new collections for the group.</div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
        <span class="block">
          <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        </span>
        <span class="block ml-2">
          <Button 
            label="Add" 
            :icon="organisationGroupsReportsStore.updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
            @click="saveRecord" 
            :disabled='organisationGroupsReportsStore.updateInProgress || !addRecord.length' 
          />
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Model, Vue, Watch } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Avatar from "primevue/avatar";
import SelectButton from "primevue/selectbutton";
import MultiSelect from "primevue/multiselect";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import ConfirmationService from "@/services/ConfirmationService";
import { useOrganisationStore } from "@/stores/organisation";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { GroupFromDBDto } from "@/models/organisation/GroupFromDBDto";
import { useReportsCollectionsStore } from "@/stores/reportsCollections";
import { useOrganisationGroupsReportsStore } from "@/stores/organisationGroupsReports";
import { Report2CollectionModel } from "@/models/reports/Report2CollectionModel";
import { OrganisationReportsCollectionModel } from "@/models/organisation/OrganisationReportsCollectionModel";

@Component({
  components: {
    Button,
    InputText,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown,
    Avatar,
    SelectButton,
    MultiSelect,
    IconField,
    InputIcon,
    DateTimezoneView
  },
  directives: {
  }
})
class OrganisationUsersGroupsReports extends Vue {
  @Model model!: GroupFromDBDto;

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  reportsCollectionsStore = useReportsCollectionsStore();
  organisationGroupsReportsStore = useOrganisationGroupsReportsStore();

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get availableOrganisations(): OrganisationFullDto[] {
    let result = this.organisationStore.entities ?? [];
    result = result.filter(x => x.Id !== this.organisationStore.currentOrganisation?.Id);
    return result;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  created(): void {
    this.loadData();
  }

  unmounted(): void {
    this.organisationGroupsReportsStore.$reset();
  }

  @Watch('model.Id', { immediate: false, deep: false })
  onModelChanged(val: number, oldVal: number): void {
    if (oldVal > 0) {
      this.loadData();
    } else {
      this.organisationGroupsReportsStore.groupId = this.model.Id;
    }
  }

  reportsCollections: Report2CollectionModel[] = [];

  /**
   * Load the data for the component.
   * Load the reports collections and the organisation groups reports entities.
   * Filter the reports collections based on the organisation groups reports entities.
   * Update the reportsCollections property with the filtered collections.
   *
   * @returns {Promise<void>} A promise that resolves when the data is loaded.
   */
  async loadData(): Promise<void> {
    // Load the reports collections and the organisation groups reports entities
    await Promise.all([
      this.reportsCollectionsStore.load(), // Load the reports collections
      this.organisationGroupsReportsStore.load(this.model.OrganisationId, this.model.Id), // Load the organisation groups reports entities
    ]);

    // Filter the organisation groups reports entities by this.model.Id
    const collectionsInGroups = (this.organisationGroupsReportsStore.entities ?? []).filter(x => x.Groups.includes(this.model.Id));

    // Filter the reports collections based on the organisation groups reports entities
    const collections = (this.reportsCollectionsStore.entities ?? []).filter(x => collectionsInGroups.find(y => y.ReportsCollectionId === x.Id));

    // Update the reportsCollections property with the filtered collections
    this.reportsCollections = collections;
  }

  /**
   * Saves the changes made to the organisation groups reports entities.
   * Creates a deep copy of the organisation groups reports entities to avoid modifying the state directly.
   * The copy is then used to perform the delete and create operations.
   *
   * @return {Promise<boolean>} A promise that resolves to a boolean indicating whether the changes were successfully saved.
   */
  async saveChanges(): Promise<boolean> {
    /**
     * Create a deep copy of the organisation groups reports entities.
     * This is done to avoid modifying the state directly.
     * The copy is then used to perform the delete and create operations.
     */
    const collectionsInGroups: OrganisationReportsCollectionModel[] = JSON.parse(JSON.stringify(this.organisationGroupsReportsStore.entities ?? []));
    /**
     * Array to store the collections that need to be saved.
     * The entities that need to be created/updated are added to this array.
     */
    const forSave: OrganisationReportsCollectionModel[] = [];

    // Delete reports collections associated with the group that are not present in this.reportsCollections
    // This loop iterates over collections in groups and removes the group if the corresponding collection is not found in this.reportsCollections
    for (const collection of collectionsInGroups) {
      // Check if the collection is present in this.reportsCollections
      if (this.reportsCollections.some(x => x.Id === collection.ReportsCollectionId)) {
        // If found, continue to the next iteration, do not delete the collection
        continue;
      }
      // If not found, find the index of the group in the groups array
      const index = collection.Groups.indexOf(this.model.Id);
      // If the group is found in the groups array, remove it
      if (index > -1) {
        collection.Groups.splice(index, 1);
        // Push the collection to the array of collections to be saved
        forSave.push(collection);
      }
    }

    // Create or update reports collections associated with the group
    // This loop iterates over this.reportsCollections
    for (const collection of this.reportsCollections) {
      // Find the index of the collection in collectionsInGroups array
      const index = collectionsInGroups.findIndex(x => x.ReportsCollectionId === collection.Id);
      // If the collection is found in collectionsInGroups array
      if (index > -1) {
        const existingCollection = collectionsInGroups[index];
        // Check if the group is already present in the collection's groups array
        if (!existingCollection.Groups.includes(this.model.Id)) {
          // If not present, add the group to the collection's groups array
          existingCollection.Groups.push(this.model.Id);
          // Push the collection to the array of collections to be saved
          forSave.push(existingCollection);
        }
      } else {
        // If the collection is not found in collectionsInGroups array, create a new collection
        const newCollection: OrganisationReportsCollectionModel = {
          Id: "",
          OrganisationId: this.model.OrganisationId,
          ReportsCollectionId: collection.Id,
          Groups: [this.model.Id]
        };
        // Push the new collection to collectionsInGroups array
        collectionsInGroups.push(newCollection);
        // Push the new collection to the array of collections to be saved
        forSave.push(newCollection);
      }
    }

    // Save changes
    let isOk = true;
    if (forSave.length) {
      isOk = await this.organisationGroupsReportsStore.save(forSave);
    }
    return isOk;
  }

  // #region add/update
  displayCreateUpdateDialog = false;
  addRecord: Report2CollectionModel[] = [];

  get availableReportsCollections(): Report2CollectionModel[] {
    const exclude = ["__sortedReports__", "__sortedCollections__"];
    const result = (this.reportsCollectionsStore.entities ?? [])
      .filter(x => !this.reportsCollections.find(y => y.Id === x.Id) && !exclude.includes(x.Name))
      .sort((a, b) => a.Name.localeCompare(b.Name));
    return result;
  }

  openCreateDialog(): void {
    if (this.currentOrganisation) {
      this.addRecord = [];
      this.displayCreateUpdateDialog = true;
    }
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  saveRecord(): void {
    for (const record of this.addRecord) {
      const copy = JSON.parse(JSON.stringify(record)) as Report2CollectionModel;
      this.reportsCollections.push(copy);
    }
    this.closeCreateUpdateDialog();
  }
  // #endregion add/update

  // #region delete
  selectedRecord: Report2CollectionModel | null = null;

  openConfirmation(record: Report2CollectionModel | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete reports collection?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Reports Collection',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    const index = this.reportsCollections.findIndex(x => x.Id === this.selectedRecord?.Id);
    if (index > -1) {
      this.reportsCollections.splice(index, 1);
    }
  }

  selectedRecords: Report2CollectionModel[] = [];

  openDeleteSelectedRecordsConfirmation(): void {
    const message = `Are you sure you want to delete selected reports collections?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Reports Collections',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteSelectedRecords();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteSelectedRecords(): void {
    if (this.selectedRecords.length) {
      for (const record of this.selectedRecords) {
        const index = this.reportsCollections.findIndex(x => x.Id === record.Id);
        if (index > -1) {
          this.reportsCollections.splice(index, 1);
        }
      }
    }
  }
  // #endregion delete
}

export default OrganisationUsersGroupsReports;
</script>