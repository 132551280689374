<template>
  <div class="dialog-content backup-restore-dashboard-dialog-content p-tabview" style="min-height: 30vh;">
    <BlockUI :blocked="inProgressChanges" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner blockui-with-overlay-z-index" :class="inProgressChanges ? 'blockui-blocked' : ''">
      <TabMenu v-model:activeIndex="activeTab" :model="tabItems" />
      <div class="p-tabview-panels">
        <Message severity="warn" :closable="false" v-if="activeTab === 0 && !authState.permissions?.FullAccess">
          You have not enough rights
        </Message>
        <DataTable
          v-else
          :value="backupList" 
          dataKey="Id"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="650px" 
          class="p-datatable-sm responsive-breakpoint backup-restore-dashboard-table">
          <template #header>
            <div class="table-header">
              <Button 
                label="Create Backup" 
                icon="pi pi-plus-circle" 
                class="my-1" 
                @click="createBackup" 
                :disabled="!isLoaded"
              />
            </div>
          </template>
          <template #empty>
            <div v-if="isLoaded" class="w-full" style="min-height: 100px;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 100px;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
            </div>
          </template>
          <Column field="DateCreated" header="Date" headerStyle="width: 18%;">
            <template #body="slotProps">
              <DateTimezoneView :date="slotProps.data.DateCreated" timezone="local"/>
            </template>
          </Column>
          <Column field="Creator" header="Creator" headerStyle="width: 32%;"></Column>
          <Column field="SpacesCount" header="Dashboards" headerStyle="width: 15%;"></Column>
          <Column field="WidgetsCount" header="Widgets" headerStyle="width: 15%;"></Column>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div class="inline-flex">
                <Button 
                  v-tippy="'Restore'"
                  icon="pi pi-history" 
                  class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                  @click="openRestoreDialog(slotProps.data)" 
                />
                <Button 
                  v-tippy="'Delete'"
                  icon="pi pi-trash" 
                  class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                  @click="openDeleteConfirmation(slotProps.data)" 
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>

      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
    </BlockUI>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import TabMenu from 'primevue/tabmenu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import { useSpaceBackupStore } from "@/stores/spaceBackup";
import SpaceBackupState from "@/stores/states/SpaceBackupState";
import { SpaceBackupEntity } from "@/models/dashboard/SpaceBackupEntity";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import AuthState from "@/store/states/AuthState";
import ConfirmationService from "@/services/ConfirmationService";

@Component({
  components: {
    Button,
    BlockUI,
    ProgressSpinner,
    TabMenu,
    DataTable,
    Column,
    Message,
    DateTimezoneView
  },
})
class BackupRestoreDashboardView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  spaceBackupStore = useSpaceBackupStore();
  
  get spaceBackupState(): SpaceBackupState {
    return this.spaceBackupStore;
  }

  get isLoaded(): boolean {
    return this.activeTab === 0 ? this.spaceBackupState.isLoadedOrganisation : this.spaceBackupState.isLoadedPersonal;
  }

  get backupList(): SpaceBackupEntity[] {
    return this.activeTab === 0 ? this.spaceBackupState.dataOrganisation : this.spaceBackupState.dataPersonal;
  }

  get inProgressChanges(): boolean {
    return this.spaceBackupState.inProgressCreateBackupOrganisation || 
      this.spaceBackupState.inProgressCreateBackupPersonal ||
      this.spaceBackupState.inProgressDeleteBackupOrganisation ||
      this.spaceBackupState.inProgressDeleteBackupPersonal ||
      this.spaceBackupState.inProgressRestoreBackupOrganisation ||
      this.spaceBackupState.inProgressRestoreBackupPersonal;
  }

  activeTab = 0;

  tabItems = [
    { label: 'Organisation' },
    { label: 'Personal' }
  ]

  created(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    if (!this.spaceBackupState.isLoadedOrganisation && this.authState.permissions?.FullAccess) {
      await this.spaceBackupStore.loadOrganisation();
    }
    if (!this.spaceBackupState.isLoadedPersonal) {
      await this.spaceBackupStore.loadPersonal();
    }
  }

  createBackup(): void {
    if (this.activeTab === 0) {
      this.spaceBackupStore.createBackupOrganisation();
    } else {
      this.spaceBackupStore.createBackupPersonal();
    }
  }

  openRestoreDialog(item: SpaceBackupEntity): void {
    const message = `Are you sure you want to restore ${this.activeTab === 0 ? 'Organisation' : 'Personal'} dashboard backup?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Restore Backup',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (this.activeTab === 0) {
          this.spaceBackupStore.restoreBackupOrganisation(item.Id);
        } else {
          this.spaceBackupStore.restoreBackupPersonal(item.Id);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  openDeleteConfirmation(item: SpaceBackupEntity): void {
    const message = `Are you sure you want to delete ${this.activeTab === 0 ? 'Organisation' : 'Personal'} dashboard backup?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Backup',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (this.activeTab === 0) {
          this.spaceBackupStore.deleteBackupOrganisation(item.Id);
        } else {
          this.spaceBackupStore.deleteBackupPersonal(item.Id);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
}

export default BackupRestoreDashboardView;
</script>
