<template>
  <div v-if="isMounted" class="widget-type-chart widget-type-heatmap">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="chart-element" v-else>
      <highcharts v-if="redrawChartToggle" ref="chartElement" class="w-full h-full flex-auto" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import StreamOption from '../../models/dashboard/StreamOption';
import DateHelper from '../../helpers/DateHelper';
import moment from 'moment';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import heatmapModule from 'highcharts/modules/heatmap';
heatmapModule(Highcharts);
import ChartRecord from '@/models/ChartRecord';
import { nextTick, PropType } from 'vue';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { BasicWidgetSettings } from '@/models/dashboard/BasicWidgetSettings';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import DashboardState from '@/store/states/DashboardState';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import { Watch } from 'vue-facing-decorator';
import DataHelper from '@/helpers/DataHelper';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import ColorHelper from '@/helpers/ColorHelper';
import { AggregationPeriod } from '@/models/enums/AggregationPeriod';

@Component({
  components: {
    highcharts: Chart,
    ProgressSpinner,
    WidgetNoDataView
  }
})
class HeatmapWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  streams: StreamOption[] = [];
  names: string[] = [];
  chartRecords: ChartRecord[] = [];
  yCategories: string[] = [];
  xCategories: string[] = [];
  dates: [Date, Date] | null = null;

  isMounted = false;

  get chartOptions(): Highcharts.Options {
    const chartRecords = this.chartRecords;
    const yCategories = this.yCategories;
    const xCategories = this.xCategories;
    const colorRange = this.aws?.gaugeRange ? 
      this.aws.gaugeRange.sort((a, b) => { return a.fromP - b.fromP; }) :
      [{
        from: 0,
        fromP: 0,
        to: 50,
        toP: 50,
        color: "#4CAF50"
      }, {
        from: 50,
        fromP: 50,
        to: 100,
        toP: 100,
        color: "#f44336"
      }];
    let min: number | undefined = undefined;
    let max: number | undefined = undefined;
    if (this.aws?.autoMinMax) {
      chartRecords.forEach(chartRecord => {
        if (typeof (chartRecord as any).value === "number") {
          const value = (chartRecord as any).value as number;
          if (min === undefined || value < min) {
            min = value;
          }
          if (max === undefined || value > max) {
            max = value;
          }
        }
      });
      if (min !== undefined && max !== undefined) {
        if (min > 0 && max > 0) {
          min = 0;
          max = Math.ceil(max);
        } else if (min < 0 && max < 0) {
          min = Math.floor(min);
          max = 0;
        } else {
          min = Math.floor(min);
          max = Math.ceil(max);
        }
      }
    } else {
      min = colorRange[0].from;
      max = colorRange[colorRange.length - 1].to;
    }
    const stops = this.getStops();
    const decimalPlaces = this.aws?.decimals ? this.aws.decimals : 0;
    const units = this.aws ? this.aws.widgetUnit : "";
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "heatmap",
        animation: true,
        style: {
          color: ColorHelper.getDefautTextColor(),
        },
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          }
        }
      },
      colorAxis: {
        reversed: false,
        min: min,
        max: max,
        stops: stops
      },
      tooltip: {
        useHTML: true,
        outside: true,
        formatter: function() {
          const timezoneOffset = (new Date()).getTimezoneOffset() * 60000;
          const value = (this.point.value === null || this.point.value === undefined) ? 'Null' : this.point.value.toFixed(decimalPlaces);
          const dateM = moment(((this.point as any).timestamp as number) + timezoneOffset);
          const date = dateM.format('ddd, MMM DD yyyy');
          const time = dateM.format('HH:mm');
          return `<b>${value}</b> ${units}<br><b>${time}</b><br><b>${date}</b>`;
        },
      },
      yAxis: [{
        title: {
          text: null
        },
        reversed: true,
        type: "category",
        // custom implementation in labels.formatter
        //categories: yCategories,
        
        labels: {
          formatter: function () {
            // hide category if this.value is too big
            const index = this.value as number;
            return index >= yCategories.length ? "" : yCategories[index];
          }
        }
      }],
      xAxis: {
        opposite: true,
        type: "category",
        categories: xCategories
      },
      legend: {
        enabled: true,
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'middle',
        y: 15
      },
      series: [{
        type: "heatmap",
        name: "",
        borderWidth: 1,
        borderColor: 'white',
        turboThreshold: 0,
        data: chartRecords
      }]
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }
  
  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataRefreshInterval = 0;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
    this.isMounted = true;
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.resizeWidgetEvent();
  }

  loadConfig(): void {
    if (this.wds &&
      this.wds.streamOptions) {
      this.streams = this.wds.streamOptions;
      this.names = [];
      this.streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
      });
    }
  }

  getStops(): [number, string][] {
    const stops: [number, string][] = [];
    if (this.aws?.gaugeRange) {
      this.aws.gaugeRange.sort((a, b) => { return a.fromP - b.fromP; }).forEach((value, key) => {
        stops.push([(value.fromP / 100), value.color]);
      });
    }
    return stops;
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.loadConfig();
    this.dates = DateHelper.extractDateFromRequestBody(requestBody);

    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      }
    });
    
    const seriesDataSource = [];
    const yCategories = [];
    let row = -1;
    let col = 0;
    const inactiveColor = this.aws?.inactiveColor ? this.aws.inactiveColor : "rgb(150,150,150)";

    const xCategoriesSet = new Set<number>();
    let xCategories: number[] = [];

    const timezoneOffset = (new Date()).getTimezoneOffset() * 60000;
    if (data.length) {
      switch (requestBody.AggregationPeriod) {
        case AggregationPeriod.Minute: {
          for (let i = 0; i < 60; i++) {
            xCategoriesSet.add(i);
          }
          break;
        }
        case AggregationPeriod.Minutes5: {
          for (let i = 0; i < 60; i+=5) {
            xCategoriesSet.add(i);
          }
          break;
        }
        case AggregationPeriod.Minutes10: {
          for (let i = 0; i < 60; i+=10) {
            xCategoriesSet.add(i);
          }
          break;
        }
        case AggregationPeriod.Minutes15: {
          for (let i = 0; i < 60; i+=15) {
            xCategoriesSet.add(i);
          }
          break;
        }
        case AggregationPeriod.Minutes30: {
          for (let i = 0; i < 60; i+=30) {
            xCategoriesSet.add(i);
          }
          break;
        }
        case AggregationPeriod.Hourly: {
          for (let i = 0; i < 24; i++) {
            xCategoriesSet.add(i);
          }
          break;
        }
        case AggregationPeriod.Daily: {
          for (let i = 0; i < data[0].Data.length; i++) {
            const dateM = moment((data[0].Data[i].x ?? 0) + timezoneOffset);
            xCategoriesSet.add(dateM.date());
          }
          break;
        }
        case AggregationPeriod.Weekly: {
          for (let i = 0; i < data[0].Data.length; i++) {
            const dateM = moment((data[0].Data[i].x ?? 0) + timezoneOffset);
            const week = DateHelper.calcWeeksInMonth(dateM);
            xCategoriesSet.add(week);
          }
          break;
        }
        case AggregationPeriod.Monthly: {
          for (let i = 0; i < data[0].Data.length; i++) {
            const dateM = moment((data[0].Data[i].x ?? 0) + timezoneOffset);
            xCategoriesSet.add(dateM.month() + 1);
          }
          break;
        }
        case AggregationPeriod.Yearly:
        case AggregationPeriod.FinancialYearly: {
          for (let i = 0; i < data[0].Data.length; i++) {
            const dateM = moment((data[0].Data[i].x ?? 0) + timezoneOffset);
            xCategoriesSet.add(dateM.year());
          }
          break;
        }
      }

      xCategories = Array.from(xCategoriesSet).sort((a, b) => a - b);
      
      for (let i = 0; i < data[0].Data.length; i++) {
        const dateM = moment((data[0].Data[i].x ?? 0) + timezoneOffset);
        let yCategory = "";
        switch (requestBody.AggregationPeriod) {
          case AggregationPeriod.Minutes30:
          case AggregationPeriod.Minutes15:
          case AggregationPeriod.Minutes10:
          case AggregationPeriod.Minutes5:
          case AggregationPeriod.Minute: {
            col = dateM.minute();
            yCategory = dateM.format('MMM DD-HH') + "h";
            break;
          }
          case AggregationPeriod.Hourly: {
            col = dateM.hour();
            yCategory = dateM.format('MMM DD');
            break;
          }
          case AggregationPeriod.Daily: {
            col = dateM.date();
            yCategory = dateM.format('yyyy MMM');
            break;
          }
          case AggregationPeriod.Weekly: {
            col = DateHelper.calcWeeksInMonth(dateM);
            yCategory = dateM.format('yyyy MMM');
            break;
          }
          case AggregationPeriod.Monthly: {
            col = dateM.month() + 1;
            yCategory = dateM.format('yyyy');
            break;
          }
          case AggregationPeriod.Yearly:
          case AggregationPeriod.FinancialYearly: {
            col = dateM.year();
            yCategory = dateM.format('yyyy');
            break;
          }
        }
        col = xCategories.findIndex(x => x === col);
        row = yCategories.findIndex(y => y === yCategory);
        if (row < 0) {
          yCategories.push(yCategory);
          row = yCategories.length - 1;
        }
        seriesDataSource.push({
          x: col,
          y: row,
          value: data[0].Data[i].y,
          timestamp: data[0].Data[i].x,
          color: ""
        });
        if (data[1] && data[1].Data[i].y == 0) {
          seriesDataSource[seriesDataSource.length - 1].color = inactiveColor;
        }
      }
    }
    this.chartRecords = seriesDataSource;
    this.yCategories = yCategories;
    this.xCategories = xCategories.map(x => x.toString());

    if (seriesDataSource.length) {
      this.isNoData = false;
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  redrawChartToggle = true;

  // chart reflow on widget resize
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async resizeWidgetEvent(): Promise<void> {
    this.redrawChartToggle = false;
    await nextTick();
    this.redrawChartToggle = true;
  }
}

export default HeatmapWidget;
</script>