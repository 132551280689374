import mitt, { Emitter } from 'mitt';

class EventBusHelper {
  private emitter: Emitter<Record<string, string>> = mitt<Record<string, string>>();

  getEmmiter(): Emitter<Record<string, string>> {
    return this.emitter;
  }
}

export default new EventBusHelper();