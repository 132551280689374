<template>
  <div ng-non-bindable ref="root" class="fullWidthHeight chart-with-text-data">
    <span v-if="isMounted" class="text-data" :style="{ color: valueColor, top: vertivalCenter + 'px', fontSize: valueFontSize + 'px' }">{{centredValue.toFixed(2)}} {{units}}</span>
    <highcharts v-if="isMounted" ref="chartElement" class="fullWidthHeight chart-top" :options="chartOptions"></highcharts>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Ref } from 'vue-facing-decorator';
import AngularOptions from '../../models/AngularOptions';
import ChartDataResponse from '../../models/ChartDataResponse';
import ChartRequstBody from '../../models/ChartRequstBody';
import StreamOption from '../../models/dashboard/StreamOption';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import moreModule from 'highcharts/highcharts-more';
import solidGaugeModule from 'highcharts/modules/solid-gauge';
moreModule(Highcharts);
solidGaugeModule(Highcharts);
import ColorHelper from '../../helpers/ColorHelper';

(function(H: any) {
  H.wrap(H.Legend.prototype, 'colorizeItem', function(this:any, proceed: any, item: any, visible: boolean) {
    if (this.chart.options.chart.overwriteLegendColor) {
      item.color = item.userOptions.color;
    }
    // eslint-disable-next-line
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));
  });
}(Highcharts));

@Component({

  components: {
    highcharts: Chart 
  }
})
class ActivityGaugeWidget extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  chartData: ChartDataResponse[] = [];
  streamColors: string[] = [];
  names: string[] = [];
  series: Highcharts.SeriesSolidgaugeOptions[] = [];
  centredValue = 0;
  centredValueNoHower = 0;
  vertivalCenter = 0;
  valueFontSize = 0;
  valueColor = "#000";
  units = '';

  isMounted = false;
  @Ref() readonly root!: HTMLDivElement;

  get chartOptions(): Highcharts.Options {
    const units = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetUnit as string;
    const calculateVericalCenter = this.calculateVericalCenter;
    const series = this.series;
    const pane: Array<Highcharts.PaneBackgroundOptions> = [];
    let minValue: number | null | undefined = 0;
    let maxValue: number | null | undefined = null;
    series.forEach(element => {
      const point = element.data && element.data.length ? element.data[0] as Highcharts.PointOptionsObject : null;
      pane.push({
        outerRadius: point ? point.radius : "115%",
        innerRadius: point ? point.innerRadius : "35%",
        backgroundColor: Highcharts.color(point ? point.color as string : "#007ACC")
          .setOpacity(0.3)
          .get(),
        borderWidth: 0
      });
      if (point && typeof point.y === "number") {
        if (minValue === null) {
          minValue = point.y;
        }
        if (maxValue === null) {
          maxValue = point.y;
        }
        if (minValue !== null && maxValue !== null) {
          minValue = Math.min(minValue ?? 0, point.y);
          maxValue = Math.max(maxValue ?? 0, point.y);
        }
      }
    });
    const result: Highcharts.Options = {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "solidgauge",
        animation: true,
        plotShadow: false,
        events: {
          load() {
            window.setTimeout(() => {
              this.reflow.bind(this); 
              calculateVericalCenter(); 
            }); 
          }
        }
      },
      tooltip: {
        enabled: true,
        formatter: function() {
          return `<span style="color:${this.color};">\u25CF </span><b>${this.series.name}:</b> ${(this.y ?? 0).toFixed(2)} ${units}`;
        },
        useHTML: true,
        followPointer: true,
        outside: true
      },
      plotOptions: {
        solidgauge: {
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
          showInLegend: true
        }
      },
      yAxis: {
        min: minValue,
        max: maxValue,
        lineWidth: 0,
        tickPositions: []
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        itemStyle: {
          color: this.angularOptions.angularScope.theme[this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor].title1,
          font: "500 10px Arial, sans-serif",
          verticalAlign: 'middle'
        },
        symbolHeight: 8,
        symbolWidth: 8,
        maxHeight: 40
      },
      series: series,
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: pane
      }
    };
    (result.chart as any).overwriteLegendColor = true;
    return result;
  }

  calculateVericalCenter(): void {
    if (this.root) {
      const chartElement = this.root.querySelector('.highcharts-plot-background');
      const chartTopElement = this.root.querySelector('.chart-top');
      let vertivalCenter = 0;
      let valueFontSize = 12;
      if (chartElement && chartTopElement) {
        const chartElementRect =  chartElement.getBoundingClientRect();
        const chartTopElementRect =  chartTopElement.getBoundingClientRect();
        const win = chartElement.ownerDocument.defaultView;
        if (win) {
          vertivalCenter = - chartTopElementRect.top + chartElementRect.top + win.pageYOffset + chartElementRect.height / 2;
          valueFontSize = Math.round(chartElementRect.height / 15);
        }
      }
      this.vertivalCenter = vertivalCenter;
      this.valueFontSize = valueFontSize;
    } else {
      this.vertivalCenter = 0;
      this.valueFontSize = 12;
    }
  }

  created(): void {
    if (this.angularOptions && this.angularOptions.angularScope) {
      if (this.angularOptions.angularScope.vueData && this.angularOptions.angularScope.vueRequestBody) {
        this.dataUpdate(
          this.angularOptions.angularScope.vueData as ChartDataResponse[],
          this.angularOptions.angularScope.vueRequestBody as ChartRequstBody);
      }
      if (this.angularOptions.angularScope.subscribeToDataUpdate) {
        this.angularOptions.angularScope.subscribeToDataUpdate(this.dataUpdate);
      }
      this.units = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetUnit as string;
    }
  }
  
  mounted(): void {
    this.isMounted = true;
    if (this.angularOptions && this.angularOptions.angularScope && this.angularOptions.angularScope.subscribeToWidgetResize) {
      this.angularOptions.angularScope.subscribeToWidgetResize(this.resizeWidgetEvent);
    }
  }

  loadConfig(): void {
    if (this.angularOptions && 
      this.angularOptions.angularScope &&
      this.angularOptions.angularScope.Widget &&
      this.angularOptions.angularScope.Widget.config &&
      this.angularOptions.angularScope.Widget.config.widgetOptions &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions) {
      const streams = this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions as StreamOption[];
      this.names = [];
      this.streamColors = [];
      streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
        if (stream.hexStreamColor) {
          this.streamColors.push(stream.hexStreamColor);
        } else {
          if (this.angularOptions.angularScope.theme &&
              this.angularOptions.angularScope.Widget &&
              this.angularOptions.angularScope.Widget.config &&
              this.angularOptions.angularScope.Widget.config.widgetOptions &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor) {
            const theme = this.angularOptions.angularScope.theme[this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor]
            this.streamColors.push(theme.colors[stream.streamColor]);
          } else {
            this.streamColors.push(ColorHelper.random());
          }
        }
      });
    }
  }

  resetCentredValue(): void {
    this.centredValue = this.centredValueNoHower;
    this.valueColor = "#000";
  }

  updateCentredValue(value: number | undefined, color: string | undefined): void {
    this.centredValue = value ? value : 0;
    this.valueColor = color ? color : "#000";
  }

  dataUpdate(data: ChartDataResponse[], requestBody: ChartRequstBody): void {
    this.chartData = data;
    this.loadConfig();
    let i = 0;
    const series: Highcharts.SeriesSolidgaugeOptions[] = [];
    const centerValueType = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetActivityGaugeCenterValueType ?
      this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetActivityGaugeCenterValueType :
      "Max";
    let centredValueNoHower = 0;
    const minRadius = 35;
    const maxRadius = 115;
    const maxThickness = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetActivityGaugeMaxThickness ?
      this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetActivityGaugeMaxThickness :
      20;
    let step = (maxRadius - minRadius) / (this.chartData.length ? this.chartData.length : 1);
    if (step > maxThickness) {
      step = maxThickness;
    }
    let currentRadius = maxRadius;
    const updateCentredValue = this.updateCentredValue;
    const resetCentredValue = this.resetCentredValue;
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        // angular should show error message to user
        //console.error(streamData.Error);
      } else if (streamData.Data && streamData.Data.length) {
        series.push({
          name: this.names.length > i ? this.names[i] : '',
          color: this.streamColors.length > i ? this.streamColors[i] : '',
          colorByPoint: true,
          type: 'solidgauge',
          data: [{
            y: streamData.Data[0].y ? streamData.Data[0].y : 0,
            color: this.streamColors.length > i ? this.streamColors[i] : '',
            radius: `${currentRadius}%`,
            innerRadius: `${currentRadius - step}%`,
          }],
          point: {
            events: {
              mouseOver: function () {
                updateCentredValue(this.y, this.color as string);
              }
            }
          },
          events: {
            mouseOut: function () {
              resetCentredValue();
            }
          }
        });
        if (streamData.Data[0].y) {
          switch (centerValueType) {
            default:{
            // Max
              if (i === 0) {
                centredValueNoHower = streamData.Data[0].y;
              } else {
                if (centredValueNoHower < streamData.Data[0].y) {
                  centredValueNoHower = streamData.Data[0].y
                }
              }
              break;
            }
            case "Min": {
              if (i === 0) {
                centredValueNoHower = streamData.Data[0].y;
              } else {
                if (centredValueNoHower > streamData.Data[0].y) {
                  centredValueNoHower = streamData.Data[0].y
                }
              }
              break;
            }
            case "Avg": {
              centredValueNoHower += streamData.Data[0].y;
              break;
            }
          }
        }
      }
      i++;
      currentRadius = currentRadius - step;
    });
    if (centerValueType === "Avg") {
      centredValueNoHower /= series.length ? series.length : 1;
    }
    this.centredValueNoHower = centredValueNoHower;
    this.centredValue = centredValueNoHower;

    this.series = series;
    let allZero = true;
    for(let i = 0; i < series.length; i++) {
      const s = series[i];
      if (s.data && s.data.length) {
        const point = s.data[0] as Highcharts.PointOptionsObject;
        if (point && point.y) {
          allZero = false;
          break;
        }
      }
    }
    if ((!series.length || allZero) && this.angularOptions.angularScope.showNoDataMessage) {
      this.angularOptions.angularScope.showNoDataMessage();
    } else {
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }
  
  // chart reflow on widget resize
  resizeWidgetEvent(): void {
    const chartElement = this.getChartElement();
    if (chartElement) {
      chartElement.chart.reflow();
    }
    window.setTimeout(() => {
      this.calculateVericalCenter();
    }, 100);
  }
}

export default ActivityGaugeWidget;
</script>
<style lang="scss" scoped>
  .chart-with-text-data {
    position: relative;

    .text-data {
      position: absolute;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      font-size: 3.25vmin;
      line-height: 1.15;
      font-weight: 700;
      color: #000;
      text-shadow: 1px 1px 2px #fff, 0 0 1em #fff;
    }

    .chart-top {
      position: relative;
      z-index: 1;
    }
  }
  .highcharts-tooltip-container {
    z-index: 100 !important;
  }
</style>
