<template>
  <div class="flex align-items-center justify-content-start sm:justify-content-end w-full lg:w-auto column-gap-2 mt-3 lg:mt-0 lg:ml-5 lg:flex-shrink-0" v-if="editRecord">
    <SelectButton 
      v-model="reports3.editMode" 
      :options="editModes" 
      optionLabel="name"
      optionValue="key"
      class="vertical-align-top flex-shrink-0 full-size-btns"
      id="reportsEditMode"
      :allowEmpty="false"
    />
    <Button 
      label="Preview PDF" 
      class="p-button-outlined flex-shrink-0 ml-auto sm:ml-0"
      @click="openPreviewPDFDialog"
      :disabled="reports3.inProgressTest"
    />
    <Button
      class="flex-shrink-0"
      :label="editRecord.Id ? 'Save' : 'Create'"  
      @click="saveRecord" 
      :disabled='!reports3.isOneDirty || !editRecord.Name || updateInProgress || editRecord.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin' 
    />

    <Dialog 
      header="Preview PDF" 
      v-model:visible="displayPreviewPDFDialog" 
      :modal="true" 
      :style="{width: '90vw'}"
      class="reports-pdf-preview-dialog"
    >
      <div class="dialog-content">
        <div v-if="pdfLink" class="pdf-viewer-wrapper">
          <PDFViewer
            ref="pdfViewerRef"
            :source="pdfLink"
            style="height: 90vh; width: 100%"
            @download="handleDownload"
            :settings="pdfViewerSettings"
          />
        </div>
        <div v-else-if="reports3.inProgressTest">
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </div>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import SelectButton from 'primevue/selectbutton';
import ProgressSpinner from 'primevue/progressspinner';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import { useOrganisationStore } from "@/stores/organisation";
import { useReports3Store } from "@/stores/reports3";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import  secureRandomPassword from 'secure-random-password';
import PDFViewer from 'pdf-viewer-vue';
import { nextTick } from "vue";
import ReportsHtmlGridView from "@/components/views/reports/ReportsHtmlGridView.vue";
import ReportsSelectElementDialogView from "@/components/views/reports/ReportsSelectElementDialogView.vue";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import ReportPdfHelper from "@/helpers/ReportPdfHelper";
import { HtmlToPdfReportParameters } from "@/models/reports/v3/HtmlToPdfReportParameters";
import moment from "moment";
import ReportsHtmlElementView from "@/components/views/reports/ReportsHtmlElementView.vue";
import ReportElementPdfHelper from "@/helpers/ReportElementPdfHelper";
import AddReportElementSvg from "@/components/svg/AddReportElementSvg.vue";
import AddReportGridSvg from "@/components/svg/AddReportGridSvg.vue";
import ReportMoreActionsSvg from "@/components/svg/ReportMoreActionsSvg.vue";
import ReportDeleteSvg from "@/components/svg/ReportDeleteSvg.vue";

@Component({
  components: {
    Button,
    Dialog,
    SelectButton,
    ProgressSpinner,
    PDFViewer,
    ReportsHtmlGridView,
    ReportsSelectElementDialogView,
    ReportsHtmlElementView,
    AddReportElementSvg,
    AddReportGridSvg,
    ReportMoreActionsSvg,
    ReportDeleteSvg
  },
  directives: {
  }
})
class ReportsReportControlsView extends Vue {
  reports3 = useReports3Store();

  get editRecord(): Reports3Entity | null {
    return this.reports3.dataOne;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get updateInProgress(): boolean {
    return this.reports3.updateInProgress;
  }

  get updateError(): boolean {
    return this.reports3.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.reports3.createUpdate(this.editRecord);
      this.reports3.isOneDirty = false;
    }
  }

  editModes = [
    { name: 'Edit', key: true },
    { name: 'Preview', key: false }
  ];

  displayPreviewPDFDialog = false;

  openPreviewPDFDialog(): void {
    this.generatePdf();
    this.displayPreviewPDFDialog = true;
  }

  pdfLink: string | null = null;
  pdfViewerSettings: any = { defaultZoom: 175 };

  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3.dataOneElements?.length) {
      const element = this.reports3.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }

  async generatePdf(): Promise<void> {
    if (this.editRecord) {
      this.pdfLink = null;
      const rnd = secureRandomPassword.randomPassword(
        { 
          length: 5, 
          characters: [secureRandomPassword.upper, secureRandomPassword.digits] 
        }
      );
      const headerElement = this.editRecord.Header ? this.getElement(this.editRecord.Header.ElementId) : null;
      const header = headerElement ?
        ReportElementPdfHelper.generateHtmlForPdfPreview(
          headerElement, 
          this.editRecord.Header, 
          `${this.reports3.cssPdf ?? ''}\n${this.reports3.cssGrid ?? ''}`,
          this.currentOrganisation?.Name ?? 'Organisation',
          this.editRecord.Name
        ) :
        "";
      const footerElement = this.editRecord.Footer ? this.getElement(this.editRecord.Footer.ElementId) : null;
      const footer = footerElement ?
        ReportElementPdfHelper.generateHtmlForPdfPreview(
          footerElement, 
          this.editRecord.Footer, 
          `${this.reports3.cssPdf ?? ''}\n${this.reports3.cssGrid ?? ''}`,
          this.currentOrganisation?.Name ?? 'Organisation',
          this.editRecord.Name
        ) :
        "";
      const html = ReportPdfHelper.generateHtmlForPdfPreview(
        this.editRecord, 
        this.reports3.dataOneElements ?? [], 
        `${this.reports3.cssPdf ?? ''}\n${this.reports3.cssGrid ?? ''}`,
        this.currentOrganisation?.Name ?? 'Organisation'
      );
      const body: HtmlToPdfReportParameters = {
        HeaderHeight: headerElement?.Height ?? 0,
        Header: header,
        Body: html,
        MarginTop: this.editRecord.MarginTop,
        MarginBottom: this.editRecord.MarginBottom,
        MarginLeft: this.editRecord.MarginLeft,
        MarginRight: this.editRecord.MarginRight,
        FooterHeight: footerElement?.Height ?? 0,
        Footer: footer,
        Filename: `report-${moment().format("YYYY-MM-DDTHH-mm-ss")}-${rnd}.pdf`
      }
      await this.reports3.generate(body);
      if (this.reports3.testResult) {
        this.pdfLink = this.reports3.testResult;
        await nextTick();
        if (this.$refs.pdfViewerRef) {
          // defaultZoom option doesn't work, this is workaround
          (this.$refs.pdfViewerRef as any).handleUpdateZoom(175);
        }
      }
    }
  }

  handleDownload(): void {
    if (this.pdfLink) {
      window.open(this.pdfLink, '_blank');
    }
  }
}

export default ReportsReportControlsView;
</script>