<template>
  <div class="widget-type-reporting">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType" :title="noDataTitle" :message="noDataMessage"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="widget-type-reporting-inner" v-else>
      <div class="field">
        <label>Select Month</label>
        <div>
          <Calendar 
            v-model="month" 
            inputId="monthpicker"
            view="month" 
            dateFormat="mm/yy"
            class="w-full"
            panelClass="with-max-width"
          />
        </div>
      </div>
      <div class="pdf-viewer-wrapper">
        <PDFViewer
          ref="pdfViewerRef"
          :source="pdfLink"
          style="height: 90vh; width: 100%"
          @download="handleDownload"
          :settings="pdfViewerSettings"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { nextTick, PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import WidgetDataState from '@/store/states/WidgetDataState';
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Calendar from 'primevue/calendar';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import StreamOption from '@/models/dashboard/StreamOption';
import { ReportInput } from '@/models/reports/ReportInput';
import { StreamInfo } from '@/models/reports/StreamInfo';
import PDFViewer from 'pdf-viewer-vue';
import moment from 'moment';

@Component({
  components: {
    ProgressSpinner,
    DataTable,
    Column,
    Button,
    Calendar,
    WidgetNoDataView,
    PDFViewer
  }
})
class ReportingWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;
  @Prop({ required: true }) dashboardId!: string;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get month(): Date {
    const result = this.wds?.Month ? moment(this.wds.Month, "MM/yyyy") : moment();
    return result.toDate();
  }

  set month(value: Date) {
    if (this.wds) {
      const m = moment(value);
      this.wds.Month = m.format("MM/yyyy");
      this.saveWidget();
    }
  }

  get canEditDashboard(): boolean {
    const canEditDashboard = this.$store.getters["dashboard/canEditDashboard"] as (id: string) => boolean;
    const result = canEditDashboard(this.dashboardId);
    return result;
  }

  async saveWidget(): Promise<void> {
    if (this.canEditDashboard && this.widgetConfig) {
      await this.$store.dispatch("dashboard/saveWidget", this.widgetConfig);
      const state = this.dashboardState.widgetState[this.widgetConfig.guid];
      if (state && state[0] && !state[2]) {
        // ok
      }
    }
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  noDataTitle = "";
  noDataMessage = "";
  pdfLink: string | null = null;
  pdfViewerSettings: any = { defaultZoom: 175 };

  created(): void {
    this.reloadData(false, true);
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.configuredStreams.length) {
      const streamsGroups: Record<string, StreamInfo[]> = {};
      if (this.wds.groupNames) {
        this.wds.groupNames.forEach(group => {
          streamsGroups[group] = [];
        });
      }
      const requestBody: ReportInput = {
        BuildingName: this.wds.BuildingName ?? "",
        Month: this.wds.Month ?? moment().format("MM/YYYY"),
        PeriodType: null,
        From: null,
        To: null,
        Round: this.wds.round ?? false,
        Streams: streamsGroups,
        PoolKey: null,
        Auto: false,
        StreamKey:null,
        Haystack: false
      };
      this.configuredStreams.forEach(so => {
        const groupName = (this.wds?.groupNames && typeof so.Params.groupIndex === "number" && this.wds?.groupNames.length > so.Params.groupIndex) ? this.wds?.groupNames[so.Params.groupIndex] : "Group is not specified";
        const stream: StreamInfo = {
          StreamKey: so.StreamKey,
          IsAccumulating: !!so.Params.IsAccumulating,
          DisplayName: so.Label,
          GroupName: groupName,
          SkipInTotal: !!so.Params.skipInTotal,
          PostProcessIfAvailable: !!so.Params.postProcessIfAvailable,
          Multiplier: so.Params.multiplier
        };
        if (!requestBody.Streams[groupName]) {
          requestBody.Streams[groupName] = [];
        }
        requestBody.Streams[groupName].push(stream);
      });
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          if (requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadDataReport", [this.widgetConfig.guid, requestBody]);        
      }
      const data = this.widgetDataState.dataReport[this.widgetConfig.guid];
      if (data && data[1]) {
        this.pdfLink = data[1];
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
        this.noDataTitle = "Error";
        this.noDataMessage = "Can't generate report";
      }
      this.isLodingData = false;
      
      await nextTick();
      if (this.$refs.pdfViewerRef) {
        // defaultZoom option doesn't work, this is workaround
        (this.$refs.pdfViewerRef as any).handleUpdateZoom(175);
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.noDataTitle = "";
      this.noDataMessage = "";
      this.isLodingData = false;
    }
  }

  get configuredStreams(): StreamOption[] {
    const result = this.wds?.streamOptions?.filter(x => x.StreamKey);
    return result ? result : [];
  }

  handleDownload(): void {
    if (this.pdfLink) {
      window.open(this.pdfLink, '_blank');
    }
  }
}

export default ReportingWidget;
</script>