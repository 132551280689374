<template>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 391 519"><path fill="#000" fill-rule="evenodd" stroke="#000" stroke-width="14" d="m239.95 230 78.693-183.572v-.002c5.347-12.45 1.361-26.921-9.605-34.884-10.963-7.958-25.913-7.286-36.162 1.634l-.006.005L16.908 237.156a29.093 29.093 0 0 0-8.056 32.089C13.121 280.525 23.972 288 35.1 288h116.95L73.347 471.582v.003c-5.336 12.409-1.352 26.912 9.609 34.889v.001c10.935 7.966 25.917 7.256 36.176-1.639l.002-.002 255.984-223.986a.027.027 0 0 1 .006-.005c9.055-7.973 12.181-20.683 7.23-31.935l-.071-.161-.051-.168C378.85 237.544 368.094 230 356 230H239.95Zm137.15 53.1-256 224c-11.3 9.8-27.83 10.6-39.91 1.8-12.09-8.8-16.49-24.8-10.6-38.5L147.5 291H35.1c-12.43 0-24.367-8.3-29.056-20.7a32.094 32.094 0 0 1 8.886-35.4L270.9 10.918C282.2 1.08 298.7.331 310.8 9.114c12.1 8.786 16.5 24.756 10.6 38.496L244.5 227H356c13.3 0 25.3 8.3 29.1 20.7 5.5 12.5 2 26.6-8 35.4Z" clip-rule="evenodd"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class QuickActionsSvg extends Vue {
}

export default QuickActionsSvg;
</script>