<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" v-if="seriesType === 'column' || seriesType === 'column2'"><path d="M45 40.5a4.5 4.5 0 0 1-4.5 4.5h-36A4.5 4.5 0 0 1 0 40.5v-36A4.5 4.5 0 0 1 4.5 0h36A4.5 4.5 0 0 1 45 4.5z"/><path class="fill-change-color-opposite" d="M15.963 31.884H7.487v-16.66h8.476zM26.987 6.399H18.51v25.485h8.478V6.399Zm11.025 16.28h-8.475v9.205h8.475zm2.738 11.592h-36v3.932h36z"/></svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" v-else-if="seriesType === 'line'"><path d="M45 40.5a4.5 4.5 0 0 1-4.5 4.5h-36A4.5 4.5 0 0 1 0 40.5v-36A4.5 4.5 0 0 1 4.5 0h36A4.5 4.5 0 0 1 45 4.5z"/><path class="fill-change-color-opposite" d="M40.7 34.785v3.494h-36V6.521h3.496v28.264zM38.86 10.81l-8.327 1.574 2.008 1.982-7.791 7.715-3.515-3.305-9.455 9.432 2.963 2.973 6.566-6.543 3.503 3.293L35.531 17.32l1.86 1.838z"/></svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" v-else-if="seriesType === 'area'"><path d="M45 40.5a4.5 4.5 0 0 1-4.5 4.5h-36A4.5 4.5 0 0 1 0 40.5v-36A4.5 4.5 0 0 1 4.5 0h36A4.5 4.5 0 0 1 45 4.5z"/><path class="fill-change-color-opposite" d="M40.61 38.236h-36V19.239l5.73 5.828 7.688-8.391 11.184 10.982 11.398-6.775zm-30.336-17.85 7.655-8.355 11.68 11.47 11-6.54V12.3l-10.282 5.882L17.825 6.365 10.34 14.52 4.61 8.929v5.699z"/></svg>

  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.1 28" v-else-if="seriesType === 'scatter'"><path d="M28.1 25.2c0 1.6-1.3 2.8-2.8 2.8H2.8C1.2 28 0 26.7 0 25.2V2.8C0 1.2 1.3 0 2.8 0h22.5c1.6 0 2.8 1.3 2.8 2.8z"/><path class="fill-change-color-opposite" d="M25.4 21.7v2.2H2.9V4.1h2.2v17.7h20.3Zm-14.7-2.3c0 .5-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zm-1.8-2.1c0 .5-.4 1-1 1s-1-.4-1-1c0-.5.4-1 1-1s1 .5 1 1zm4.6 1.6c0 .5-.4 1-1 1s-1-.4-1-1c0-.5.4-1 1-1s1 .5 1 1zm-1.9-2c0 .5-.4 1-1 1s-1-.4-1-1c0-.5.4-1 1-1s1 .4 1 1zm1.9-1.7c0 .5-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .5 1 1zm-1.8-2c0 .5-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zm4.5 1.8c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1 1 .5 1 1zm-1.8-2c0 .5-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1zm4.7 2c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1 1 .5 1 1zm-1.8-2c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1zm2.3-.8c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1 1 .5 1 1zm-1.8-2c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1zm4.7 1.9c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1zm-1.8-2c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1 1 .4 1 1zm2.7-.8c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1c.5.1 1 .5 1 1zm-1.9-2c0 .5-.4 1-1 1-.5 0-1-.4-1-1s.4-1 1-1 1 .5 1 1z"/></svg>

  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28.1 28" v-else-if="seriesType === 'spline'"><g clip-path="url(#a)"><path class="fill-change-color" d="M28.1 25.2c0 1.6-1.3 2.8-2.8 2.8H2.8C1.2 28 0 26.7 0 25.2V2.8C0 1.2 1.3 0 2.8 0h22.5c1.6 0 2.8 1.3 2.8 2.8z"/><path class="fill-change-color-opposite" d="M25.4 21.6v2.2H2.9V4h2.2v17.7h20.3z"/><path class="stroke-change-color-opposite" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M7.6 19.2c1.3-3.4 3.3-7.1 5.7-7.1.9 0 2.4.6 3.3.8 1.7.3 3.2-.9 7-6.8"/></g><defs><clipPath id="a"><path class="fill-change-color-opposite" d="M0 0h29v28H0z"/></clipPath></defs></svg>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

@Component({
  components: {
  },
})
class SeriesTypeIconView extends Vue {
  @Prop({ required: true }) seriesType!: string;
}

export default SeriesTypeIconView;
</script>