<template>
  <div class="col-12 hd:col-8 semantics-edit-date-time">
    <div class="formgrid grid row-gap-2 flex-auto">
      <div class="col-12 md:col-6">
        <label :for="inputId">Value</label>
        <Calendar 
          :inputId="inputId"
          v-model="dateTimeVal"
          showTime
          hourFormat="24"
          :showSeconds="true"
          class="w-full"
          panelClass="with-max-width"
          :disabled="disabledFields.includes(field)"
        />
      </div>
      <div class="col-12 md:col-6">
        <label :for="inputId + '-timezone'">Timezone</label>
        <Dropdown
          :inputId="inputId + '-timezone'"
          v-model="dateTimeTimezoneVal"
          :options="timezones"
          optionValue="Id"
          optionLabel="Id"
          class="inputfield w-full"
          placeholder="Timezone"
          :filter="true"
          :disabled="disabledFields.includes(field)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonDateTime, HaysonDict, HaysonVal, Kind } from "haystack-core";
import moment from 'moment';
import { TimeZoneDto } from '@/models/TimeZoneDto';

@Component({
  components: {
    Calendar,
    Dropdown
  },
})
class HaystackDateTimeEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }
  
  // #region DateTime
  // an ISO 8601 timestamp followed by timezone name
  get dateTimeVal(): Date {
    if (this.valueHayson) {
      const utcOffsetLocal = moment().utcOffset();
      const date = (this.valueHayson as HaysonDateTime).val;
      const m = moment(date).subtract(utcOffsetLocal, "minutes");
      const timezone = this.timezones.find(x => x.Id === this.dateTimeTimezoneVal);
      if (timezone) {
        m.add(timezone.UtcOffset, "hours");
      }
      const result = m.toDate();
      return result;
    }
    return moment().toDate();
  }

  set dateTimeVal(value: Date) {
    const resultStr = this.dateAndTimezoneToStr(value, this.dateTimeTimezoneVal, false);
    this.valueHayson = { 
      _kind: Kind.DateTime,
      val: resultStr,
      tz: this.dateTimeTimezoneVal
    };
  }

  get dateTimeTimezoneVal(): string {
    if (this.valueHayson) {
      return (this.valueHayson as HaysonDateTime).tz ?? "UTC";
    }
    return "UTC";
  }

  set dateTimeTimezoneVal(value: string) {
    const resultStr = this.dateAndTimezoneToStr(this.dateTimeVal, value, false);
    this.valueHayson = { 
      _kind: Kind.DateTime,
      val: resultStr,
      tz: value
    };
  }

  dateAndTimezoneToStr(date: Date, timezoneStr: string, includeTZ: boolean): string {
    const m = moment(date);
    const timezone = this.timezones.find(x => x.Id === timezoneStr);
    let offsetStr = "Z";
    if (timezone && timezone.Id !== "UTC") {
      const n = new Date(0, 0);
      n.setMinutes(Math.abs(timezone.UtcOffset) * 60);
      offsetStr = n.toTimeString().slice(0, 5); // hh:mm
      if (timezone.UtcOffset < 0) {
        offsetStr = "-" + offsetStr;
      } else {
        offsetStr = "+" + offsetStr;
      }
    }
    const result = timezone ?
      `${m.format("YYYY-MM-DDTHH:mm:ss")}${offsetStr}${includeTZ ? (" " + timezone.Id) : ""}` :
      `${m.format("YYYY-MM-DDTHH:mm:ss")}${offsetStr}`;
    return result;
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }
  // #endregion DateTime
}

export default HaystackDateTimeEditView;
</script>