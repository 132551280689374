<template>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41.15 43.25"><path fill="#000" d="M3.95 4v14.35-.1V37.5 4v9.3Zm4.7 19.8h9.4q.6-.95 1.35-1.775.75-.825 1.7-1.525H8.65Zm0 8.5h7.7q-.1-.85-.15-1.675-.05-.825.05-1.625h-7.6zm-4.7 9.15q-1.6 0-2.775-1.175Q0 39.1 0 37.5V4q0-1.65 1.175-2.825Q2.35 0 3.95 0h18.1l11.4 11.4v8.05q-.95-.45-1.975-.725-1.025-.275-2.025-.375V13.3H20V4H3.95v33.5H19q.95 1.35 2.3 2.35 1.35 1 3 1.6zm24.2-6.2q2.15 0 3.575-1.425Q33.15 32.4 33.15 30.25q0-2.15-1.425-3.6Q30.3 25.2 28.2 25.2q-2.15 0-3.6 1.45-1.45 1.45-1.45 3.6 0 2.15 1.425 3.575Q26 35.25 28.15 35.25Zm10.25 8-5.45-5.45q-1.1.65-2.275 1.025-1.175.375-2.475.375-3.75 0-6.4-2.625-2.65-2.625-2.65-6.325 0-3.75 2.65-6.375t6.4-2.625q3.75 0 6.35 2.625 2.6 2.625 2.6 6.375 0 1.3-.4 2.5T35.7 35l5.45 5.45z" class="fill-color"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class FindReplaceSvg extends Vue {
}
export default FindReplaceSvg;
</script>