import { defineStore } from 'pinia';
import TariffState from './states/TariffState';
import { TariffModel } from '@/models/tariff/TariffModel';
import { TariffDebtorModel } from '@/models/tariff/TariffDebtorModel';
import { TariffHistoryModel } from '@/models/tariff/TariffHistoryModel';
import DateHelper from '@/helpers/DateHelper';
import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";

export const useTariffStore = defineStore('tariff', {
  state: (): TariffState => ({ 
    isLoaded: false,
    data: null,
    dataFlat: null,
    updateInProgress: false,
    updateError: false,
  
    isLoadedDebtors: false,
    dataDebtors: null,
    updateInProgressDebtor: false,
    updateErrorDebtor: false,
  
    isLoadedHistory: false,
    dataHistory: null,
    updateInProgressHistory: false,
    updateErrorHistory: false
  }),
  actions: {
    async loadTariffs() {
      try {
        this.isLoaded = false;
        this.data = null;
        this.dataFlat = null;
        const url = `rest/BitPool_V2/tariffs`;
        const response = await axios.get<Record<string, TariffModel[]>>(url);
        this.data = response.data;
        this.dataFlat = Object.values(this.data).flat();
        this.isLoaded = true;
      } catch (error) {
        const errorMessage = ErrorHelper.handleAxiosError(error).message;
        ToastService.showToast(
          "error",
          "Can't load tariffs",
          errorMessage,
          5000
        );
        this.data = null;
        this.dataFlat = null;
        this.isLoaded = true;
      }
    },
    async createUpdate(entity: TariffModel) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/tariffs`;
        const response = await axios.put<TariffModel>(url, entity);
        ToastService.showToast("success", "Tariffs", "Changes saved", 5000);
        this.updateInProgress = false;
        if (this.isLoaded && this.data && this.dataFlat) {
          if (!this.data[response.data.Retailer]) {
            this.data[response.data.Retailer] = [];
          }
          if (entity.Id) {
            const indexFlat = this.dataFlat.findIndex((x) => x.Id === entity.Id);
            if (indexFlat > -1) {
              this.dataFlat[indexFlat] = response.data;
            } else {
              this.dataFlat.push(response.data);
            }

            const index = this.data[response.data.Retailer].findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.data[response.data.Retailer][index] = response.data;
            } else {
              this.data[response.data.Retailer].push(response.data);
            }
          } else {
            this.data[response.data.Retailer].push(response.data);
            this.dataFlat.push(response.data);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save tariff",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
    },
    async delete(entity: TariffModel) {
      try {
        const url = `rest/BitPool_V2/tariffs/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Tariffs", `${entity.Name} is deleted`, 5000);
        if (this.isLoaded && this.data && this.dataFlat) {
          const indexFlat = this.dataFlat.findIndex((x) => x.Id === entity.Id);
          if (indexFlat > -1) {
            this.dataFlat.splice(indexFlat, 1);
          }

          const index = this.data[entity.Retailer].findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.data[entity.Retailer].splice(index, 1);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
    async loadDebtors() {
      try {
        this.isLoadedDebtors = false;
        this.dataDebtors = null;
        const url = `rest/BitPool_V2/tariffsDebtor`;
        const response = await axios.get<TariffDebtorModel[]>(url);
        this.dataDebtors = response.data;
        this.isLoadedDebtors = true;
      } catch (error) {
        const errorMessage = ErrorHelper.handleAxiosError(error).message;
        ToastService.showToast(
          "error",
          "Can't load debtors",
          errorMessage,
          5000
        );
        this.dataDebtors = null;
        this.isLoadedDebtors = true;
      }
    },
    async createUpdateDebtor(body: TariffDebtorModel) {
      try {
        this.updateInProgressDebtor = true;
        this.updateErrorDebtor = false;
        const url = `rest/BitPool_V2/tariffsDebtor`;
        const response = await axios.put<string>(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.updateInProgressDebtor = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
        if (this.dataDebtors) {
          if (body.Id) {
            const index = this.dataDebtors.findIndex((x) => x.Id === body.Id);
            if (index >= 0) {
              this.dataDebtors[index] = body;
            }
          } else {
            body.Id = response.data;
            this.dataDebtors.push(body);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save debtor",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateInProgressDebtor = false;
        this.updateErrorDebtor = true;
      }
    },
    async deleteDebtor(id: string) {
      try {
        const url = `rest/BitPool_V2/tariffsDebtor/${id}`;
        await axios.delete(url);
        ToastService.showToast("success", "", "Changes saved", 5000);
        if (this.dataDebtors) {
          const index = this.dataDebtors.findIndex((x) => x.Id === id);
          if (index >= 0) {
            this.dataDebtors.splice(index, 1);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete debtor",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
    async loadHistory() {
      try {
        this.isLoadedHistory = false;
        this.dataHistory = null;
        const url = `rest/BitPool_V2/tariffsHistory`;
        const response = await axios.get<TariffHistoryModel[]>(url);
        response.data.forEach(x => {
          if (x.Date && typeof x.Date === "string") {
            x.Date = DateHelper.parseFromMicrosoftString(x.Date);
          }
          if (x.StartDate && typeof x.StartDate === "string") {
            x.StartDate = DateHelper.parseFromMicrosoftString(x.StartDate);
          }
          if (x.StopDate && typeof x.StopDate === "string") {
            x.StopDate = DateHelper.parseFromMicrosoftString(x.StopDate);
          }
        });
        this.dataHistory = response.data;
        this.isLoadedHistory = true;
      } catch (error) {
        const errorMessage = ErrorHelper.handleAxiosError(error).message;
        ToastService.showToast(
          "error",
          "Can't load history",
          errorMessage,
          5000
        );
        this.dataHistory = null;
        this.isLoadedHistory = true;
      }
    },
    async createUpdateHistory(body: TariffHistoryModel) {
      try {
        this.updateInProgressHistory = true;
        this.updateErrorHistory = false;
        const url = `rest/BitPool_V2/tariffsHistory`;
        const response = await axios.put<string>(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.updateInProgressHistory = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
        if (this.dataHistory) {
          if (body.Id) {
            const index = this.dataHistory.findIndex((x) => x.Id === body.Id);
            if (index >= 0) {
              this.dataHistory[index] = body;
            }
          } else {
            body.Id = response.data;
            this.dataHistory.splice(0, 0, body);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save history",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateInProgressHistory = false;
        this.updateErrorHistory = true;
      }
    },
    async deleteHistory(id: string) {
      try {
        const url = `rest/BitPool_V2/tariffsHistory/${id}`;
        await axios.delete(url);
        ToastService.showToast("success", "", "Changes saved", 5000);
        if (this.dataHistory) {
          const index = this.dataHistory.findIndex((x) => x.Id === id);
          if (index >= 0) {
            this.dataHistory.splice(index, 1);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete history",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    }
  }
});