<template>
  <div class="tags-list">
    <span v-if="!tags.length" @click="openEditor('')" class="link-dark no-tag">Add tags</span>
    <Tag v-for="tag in tags" :key="tag" class="tag-with-close-btn">
      <span class="p-tag-value" @click="openEditor(tag)">{{tag}}</span>
      <Button @click="openConfirmation(tag)" label="Delete" icon="pi pi-times" class="p-button-secondary p-button-icon-only p-button-rounded p-button-sm" />
    </Tag> 
    <Button @click="openEditor('')" label="Add" icon="pi pi-plus" class="p-button-icon-only p-button-rounded p-button-light-primary add-tag" />

    <Dialog header="Confirmation" v-model:visible="displayConfirmation" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="flex align-items-center">
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete "<span class="break-word">{{tagDelete}}</span>" tag?</span>
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary"/>
        <Button label="Yes" icon="pi pi-check" @click="deleteTag" />
      </template>
    </Dialog>

    <Dialog :header="isNewTag ? 'New Tag' : 'Edit Tag'" v-model:visible="displayEditor" :modal="true" :style="{width: '44rem'}">
      <div class="dialog-content">
        <div class="semantics-settings-container">
          <div class="formgrid grid" id="semantics-tags">
            <StringTagEditorView 
              v-model="tagEdit" 
              :excludeFields="excludeFields"
              :disabledFields="disabledFields"
              :allowAny="true"
              :allowRef="true"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeEditor" class="p-button-text p-button-secondary"/>
        <Button label="Save" icon="pi pi-check" @click="editTag" :disabled='!tagEdit' />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import InputText from 'primevue/inputtext';
import Tag from 'primevue/tag';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import StringTagEditorView from '@/components/views/tags/StringTagEditorView.vue';
import HaystackDefsService from "@/services/HaystackDefsService";
import ToastService from "@/services/ToastService";

@Component({
  components: {
    InputText,
    Tag,
    Button,
    Dialog,
    StringTagEditorView
  },
})
class StreamTagsView extends Vue {
  @Prop({ required: true }) poolKey!: string;
  @Prop({ required: true }) streamKey!: string;
  @Prop({ required: true }) tags!: string[];

  displayConfirmation = false;
  tagDelete = "";

  openConfirmation(tag: string): void {
    this.tagDelete = tag;
    this.displayConfirmation = true;
  }

  closeConfirmation(): void {
    this.displayConfirmation = false;
  }

  deleteTag(): void {
    this.closeConfirmation();
    const request = {
      poolKey: this.poolKey,
      body: {
        StreamKeys: [this.streamKey],
        TagsRemove: [this.tagDelete],
        TagsAdd: null
      }
    };
    this.$store.dispatch("pool/changeTags", request);
  }
  
  displayEditor = false;
  tagEdit = "";
  isNewTag = true;
  excludeFields = ["ref"];
  disabledFields = ["id", "siteRef", "spaceRef", "equipRef", "system", "systemRef"];

  openEditor(tag: string): void {
    this.tagEdit = tag;
    this.tagDelete = tag;
    this.isNewTag = !tag;
    this.displayEditor = true;
  }

  closeEditor(): void {
    this.displayEditor = false;
  }

  validateTag(tag: string): string {
    return HaystackDefsService.validateTag(tag);
  }

  editTag(): void {
    if (this.tagEdit) {
      const errorMessage = this.validateTag(this.tagEdit);
      if (errorMessage) {
        ToastService.showToast("error", "Error", errorMessage, 5000);
      } else {
        this.closeEditor();
        const request = {
          poolKey: this.poolKey,
          body: {
            StreamKeys: [this.streamKey],
            TagsRemove: this.tagDelete ? [this.tagDelete] : null,
            TagsAdd: [this.tagEdit]//.split(';')
          }
        };
        this.$store.dispatch("pool/changeTags", request);
      }
    }
  }
}

export default StreamTagsView;
</script>