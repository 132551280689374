<template>
  <div class="default-view-container">
    <h1 class="mb-0">Haystack</h1>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <div class="default-view-body">
        <TabView lazy>
          <TabPanel header="Server">
            <HaystackServerView></HaystackServerView>
          </TabPanel>
          <TabPanel header="Client">
            <HaystackClientView></HaystackClientView>
          </TabPanel>
        </TabView>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import HaystackServerView from './HaystackServerView.vue';
import HaystackClientView from './HaystackClientView.vue';
import AuthState from "@/store/states/AuthState";

@Component({
  
  components: {
    TabView,
    TabPanel,
    Message,
    HaystackServerView,
    HaystackClientView
  },
})
class HaystackView extends Vue { 
  get authState(): AuthState {
    return this.$store.state.auth;
  }
}

export default HaystackView;
</script>