<template>
  <Dialog 
    header="Multi-Tagging" 
    v-model:visible="displayDialog" 
    :modal="true" 
    :style="{width: '90vw'}"
    class="multi-tagging-dialog"
  >
    <div class="multi-tagging-container">
      <BlockUI :blocked="saveInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(saveInProgress) ? 'blockui-blocked' : ''">
        
          <div class="formgrid grid flex-shrink-0" v-if="nodesFlat">
            <div class="field col-12 sm:col-6">
              <label for="mtSearch">Search</label>
              <IconField iconPosition="left" class="w-full">
                <InputIcon class="pi pi-search" />
                <InputText
                  id="mtSearch"
                  v-model="search"
                  type="text"
                  class="w-full inputfield"
                  @input="debounceSearch()"
                />
              </IconField>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="mtSelectedSites">Sites</label>
              <div>
                <MultiSelect 
                  inputId="mtSelectedSites"
                  v-model="selectedSites"
                  :options="availableSites"
                  placeholder="Select Sites"
                  display="chip"
                  :filter="true"
                  :optionValue="x => x.data.key"
                  :optionLabel="x => x.data.label"
                  class="p-multiselect-multiline inputfield w-full"
                />
              </div>
            </div>
            <div class="field col-12 sm:col-6">
              <label for="mtIncludeTags">Include Tags</label>
              <InputText
                id="mtIncludeTags"
                v-model="includeTags"
                type="text"
                class="w-full inputfield"
                @input="debounceIncludeTags()"
                placeholder="Example: tag1;tag2;tag3"
              />
            </div>
            <div class="field col-12 sm:col-6">
              <label for="mtExcludeTags">Exclude Tags</label>
              <InputText
                id="mtExcludeTags"
                v-model="excludeTags"
                type="text"
                class="w-full inputfield"
                @input="debounceExcludeTags()"
                placeholder="Example: tag4;tag5;tag6"
              />
            </div>
          </div>
          
          <div class="formgrid grid lg:flex-nowrap flex-auto" v-if="nodesFlat">
            <div class="field col-12 lg:col-6 lg:flex lg:mb-0 find-list-multi-tags-container">
              <div class="lg:flex flex-column lg:w-full lg:h-full">
                <div class="find-list-multi-tags-selected">
                  <Button @click="selectAll" label="Select All" class="p-button-light-primary"/>
                  <Button @click="selectNone" label="Deselect" class="p-button-light-primary mr-2"/>
                  <span class="white-space-nowrap">Selected: {{ selectedItems.length }} / {{ nodesFlat.length }}</span>
                  <div class="field-checkbox my-0 sm:ml-auto lg:ml-0 xl:ml-auto w-full sm:w-auto lg:w-full xl:w-auto">
                    <Checkbox
                      inputId="showSelectedOnly"
                      :binary="true"
                      v-model="showSelectedOnly"
                    />
                    <label for="showSelectedOnly">Selected Only</label>
                  </div>
                </div>
                <Listbox 
                  multiple 
                  v-model="selectedItems" 
                  :options="nodesFlatSearchedPaginated" 
                  optionLabel="data.label" 
                  optionValue="data.key"
                  class=" w-full lg:flex-auto"
                >
                  <template #option="slotProps">
                    <div>
                      <div class="flex align-items-end column-gap-2">
                        <span class="break-word">{{ getLongName(slotProps.option) }}</span>
                        <Button 
                          icon="pi pi-external-link" 
                          label="See Data" 
                          class="p-button-icon-only p-button-rounded p-button-sm action-button-size p-button-light-opposite flex-shrink-0" 
                          @click="seeData($event, slotProps.option.data.key)"
                        />
                      </div>
                      <div>
                        <span v-for="tag in slotProps.option.data.tags" :key="tag">
                          <Tag :value="tag" />
                        </span>
                      </div>
                    </div>
                  </template>
                </Listbox>
                <Paginator 
                  v-model:first="first" 
                  :rows="20" 
                  :totalRecords="nodesFlatSearched.length" 
                  @page="onPageChange" 
                  template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
                />
              </div>
            </div>
            <div class="field col-12 lg:col-6 lg:flex mb-0 replace-list-multi-tags-container semantics">
              <div class="lg:flex flex-column lg:w-full lg:h-full">
                <TabMenu v-model:activeIndex="activeTabIndex" :model="tabs" class="flex-shrink-0" />

                <div v-if="activeTabIndex === 0" class="flex-auto flex flex-column">
                  <TagManagerTagsEditView
                    v-model="haysonDataAdd"
                    streamKey="multi-tag"
                    class="flex-auto flex flex-column semantics-settings-middle"
                  />
                </div>
                <div v-else-if="activeTabIndex === 1" class="flex-auto flex flex-column">
                  <div class="flex-auto flex flex-column semantics-settings-container semantics-settings-container-remove-tag semantics-settings-middle">
                    <div class="semantics-config-search-add-field">
                      <!-- Add Field -->
                      <div class="semantics-config-add-field ml-auto">
                        <Button 
                          @click="() => dataRemove.push('')"
                          label="Add Tag"
                          icon="pi pi-plus"
                          class="p-button-secondary"
                        />
                      </div>
                    </div>
                    <div class="semantics-settings-container-inner relative flex-auto">
                      <div>
                        <div class="formgrid grid" id="semantics-tags">
                          <div v-for="(data, index) in dataRemove" :key="index" class="field col-12">
                            <div class="semantics-tags-field-inner flex align-items-center column-gap-3">
                              <Dropdown 
                                v-model="dataRemove[index]"
                                editable
                                :options="availableToRemoveTags"
                                :optionLabel="x => x"
                                :optionValue="x => x"
                                placeholder="Select a tag"
                                class="w-full flex-auto" 
                              />
                              <Button icon="pi pi-trash"
                                class="p-button-icon-only semantics-tags-control-btn p-button-icon-only p-button-rounded p-button-danger p-button-outlined flex-shrink-0"
                                @click="() => dataRemove.splice(index, 1)" :disabled="dataRemove.length === 1" 
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #footer>
        <Button
          @click="doClose()"
          label="Close"
          icon="pi pi-times"
          class="p-button-text p-button-secondary"
        />
        <Button
          @click="doSave()"
          label="Save"
          :icon="saveInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'"
          :disabled="saveInProgress || !readyToSave"
        />
      </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Listbox from 'primevue/listbox';
import Paginator, { PageState } from 'primevue/paginator';
import Tag from 'primevue/tag';
import InputText from 'primevue/inputtext';
import TabMenu from 'primevue/tabmenu';
import Dropdown from 'primevue/dropdown';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from "primevue/progressspinner";
import { MenuItem } from "primevue/menuitem";
import MultiSelect from "primevue/multiselect";
import Checkbox from "primevue/checkbox";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { Stat } from "@he-tree/vue/types/src/components/TreeProcessorVue";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { debounce } from "throttle-debounce";
import { reactive } from "vue";
import { HaysonDict, Kind } from "haystack-core";
import TagManagerTagsEditView from "./TagManagerTagsEditView.vue";
import { useHaystackDefsStore } from "@/stores/haystackDefs";
import HaystackDefsService from "@/services/HaystackDefsService";
import { BP_TagChangeStreams } from "@/models/BP_TagChangeStreams";
import { useTagManagerStore } from "@/stores/tagManager";
import ToastService from "@/services/ToastService";

@Component({
  components: {
    Button,
    Dialog,
    Listbox,
    Paginator,
    Tag,
    InputText,
    TabMenu,
    Dropdown,
    BlockUI,
    ProgressSpinner,
    MultiSelect,
    Checkbox,
    IconField,
    InputIcon,
    TagManagerTagsEditView
  },
})
class TagManagerMultiTaggingDialogView extends Vue {
  @Prop({ required: true }) nodesFlat!: Stat<TreeNodeForUI>[] | null;
  @Model({ required: true }) displayDialog!: boolean;

  haystackDefsStore = useHaystackDefsStore();
  tagManagerStore = useTagManagerStore();

  selectedItems: string[] = [];
  get readyToSave(): boolean {
    return this.selectedItems.length > 0 &&
      (this.haysonDataAdd["multi-tag"] && Object.keys(this.haysonDataAdd["multi-tag"] as HaysonDict).length > 0 || this.dataRemove.length > 0 && this.dataRemove.some(x => x));
  }
  get saveInProgress(): boolean {
    return this.tagManagerStore.changeTagsInProgress;
  }

  activeTabIndex = 0;
  get tabs(): MenuItem[] {
    const result: MenuItem[] = [
      {
        label: 'Add Tags'
      },
      {
        label: 'Remove Tags'
      }
    ];
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  includeTags = ""; 
  includeTagsFinal: string[] = [];
  debounceIncludeTags = debounce(500, this.updateFinalIncludeTags);
  updateFinalIncludeTags(): void {
    this.includeTagsFinal = this.includeTags.split(";").filter(x => x);
  }

  excludeTags = "";
  excludeTagsFinal: string[] = [];
  debounceExcludeTags = debounce(500, this.updateFinalExcludeTags);
  updateFinalExcludeTags(): void {
    this.excludeTagsFinal = this.excludeTags.split(";").filter(x => x);
  }

  search = "";
  searchFinal = "";
  debounceSearch = debounce(500, this.updateFinalSearch);
  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  selectedSites: string[] = [];
  get availableSites(): Stat<TreeNodeForUI>[] {
    if (!this.nodesFlat) {
      return [];
    }
    const result = this.nodesFlat.filter(x => x.data.isRoot);
    return result;
  }

  showSelectedOnly = false;

  get nodesFlatSearched(): Stat<TreeNodeForUI>[] {
    if (!this.nodesFlat) {
      return [];
    }
    if (this.showSelectedOnly) {
      const result: Stat<TreeNodeForUI>[] = [];
      for (const node of this.nodesFlat) {
        if (node.data.key && this.selectedItems.includes(node.data.key)) {
          result.push(node);
        }
      }
      return result;
    } else {
      if (!this.searchFinal && this.includeTags.length === 0 && this.excludeTags.length === 0 && this.selectedSites.length === 0) {
        return this.nodesFlat;
      }
      const result: Stat<TreeNodeForUI>[] = [];
      const searchLower = this.searchFinal.toLocaleLowerCase();
      const includeLower = this.includeTagsFinal.map(x => x.toLocaleLowerCase());
      const excludeLower = this.excludeTagsFinal.map(x => x.toLocaleLowerCase());
      for (const node of this.nodesFlat) {
        if ((this.selectedSites.length === 0 || this.isVisible(node)) &&
          (!searchLower ||
          node.data.label?.toLocaleLowerCase().includes(searchLower) || 
          node.data.key?.toLocaleLowerCase().includes(searchLower))
        ) {
          const tagsLower = node.data.tags?.map(x => x.toLocaleLowerCase());
          const tmp1 = (this.includeTags.length === 0 || tagsLower?.some(x => includeLower.some(y => x.includes(y))));
          const tmp2 = (this.excludeTags.length === 0 || !tagsLower?.some(x => excludeLower.some(y => x.includes(y))));
          if (tmp1 && tmp2) {
            result.push(node);
          }
        }
      }
      return result;
    }
  }

  first = 0;

  onPageChange(event: PageState): void {
    this.first = event.first;
  }

  get nodesFlatSearchedPaginated(): Stat<TreeNodeForUI>[] {
    return this.nodesFlatSearched.slice(this.first, this.first + 20);
  }


  selectAll(): void {
    const allKeys = this.nodesFlatSearched.map(x => x.data.key as string);
    const combinedArray: string[] = [...this.selectedItems, ...allKeys];
    const unique = new Set<string>(combinedArray);
    this.selectedItems = Array.from(unique);
  }

  selectNone(): void {
    this.selectedItems = [];
  }

  isVisible(node: Stat<TreeNodeForUI>): boolean {
    if (node.data.key && this.selectedSites.includes(node.data.key)) {
      return true;
    }
    if (node.parent) {
      return this.isVisible(node.parent);
    } else {
      return false;
    }
  }

  getLongName(node: Stat<TreeNodeForUI>): string {
    if (node.parent) {
      return `${this.getLongName(node.parent)}/${node.data.label ?? ""}`;
    } else {
      return node.data.label ?? "";
    }
  }

  haysonDataAdd: HaysonDict = {
    "multi-tag": {}
  };

  dataRemove: string[] = [""];

  allowRef = true;
  disabledFields = ["id", "siteRef", "spaceRef", "equipRef", "system", "systemRef", "ref", "site", "space", "equip", "point"];

  get availableToRemoveTags(): string[] {
    const result: string[] = [];
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      this.haystackDefsStore.data?.forEach(defEntity => {
        if (!this.dataRemove.some(x => x === defEntity.Def) && 
          (this.allowRef || !defEntity.Def.endsWith("Ref")) && 
          (this.allowRef || !defEntity.Def.includes("Ref-")) &&
          !this.disabledFields.includes(defEntity.Def)) {
          const kind = defs.defToKind(defEntity.Def);
          if (this.allowRef || kind !== Kind.Ref) {
            result.push(defEntity.Def);
          }
        }
      });
    }
    return result;
  }

  doClose(): void {
    this.displayDialog = false;
  }

  async doSave(): Promise<void> {
    // build stream keys array
    const streamKeys = this.selectedItems;
    // build tags add array
    const tagsAdd = new Set<string>();
    const hd = this.haysonDataAdd["multi-tag"] as HaysonDict;
    for (const key in hd) {
      const value = hd[key];
      const valueStr = HaystackDefsService.haysonValToString(value);
      tagsAdd.add(valueStr ? `${key}=${valueStr}` : key);
    }
    // build tags remove array
    const tagsRemove = new Set<string>();
    for (const tag of this.dataRemove) {
      const parts = tag.split("-");
      for (const part of parts) {
        if (part && !this.disabledFields.includes(part)) {
          tagsRemove.add(part);
        }
      }
    }
    // save changes
    const request: BP_TagChangeStreams = {
      StreamKeys: streamKeys,
      TagsRemove: Array.from(tagsRemove),
      TagsAdd: Array.from(tagsAdd)
    };
    if (request.StreamKeys.length > 0 && (request.TagsAdd.length > 0 || request.TagsRemove.length > 0)) {
      const updateResult = await this.tagManagerStore.changeTags(request);
      // apply changes to the tree
      if (updateResult) {
        const stats = (this.nodesFlat?.filter(x => streamKeys.includes(x.data.key ?? "")) ?? [])
          .reduce((obj: Record<string, Stat<TreeNodeForUI>>, item) => {
            if (item.data.key) {
              obj[item.data.key] = item;
            }
            return obj;
          }, {});
        for (const streamKey in stats) {
          const stat = stats[streamKey];
          const apiTags = updateResult[streamKey];
          if (apiTags && stat) {
            this.tagManagerStore.replaceTreeNodeTags(stat.data, apiTags);
          }
        }
        ToastService.showToast(
          "success", 
          "Success", 
          `Updated ${streamKeys.length} nodes`, 
          5000
        );
        this.displayDialog = false;
      }
    } else {
      ToastService.showToast(
        "warn", 
        "Warning", 
        `Please select entities and tags to update`, 
        5000
      );
    }
  }

  seeData(event: MouseEvent, streamKey?: string): void {
    event.preventDefault();
    event.stopPropagation();
    if (streamKey) {
      const newUrl = `/data/streams/${streamKey}`;
      window.open(newUrl, '_blank');
    }
  }
}

export default TagManagerMultiTaggingDialogView;
</script>