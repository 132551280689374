<template>
  <div>
    <div v-if="!reports3.dataOneSelectedElement && reports3.dataOne">
      Body
    </div>
    <div v-else-if="reports3.dataOneSelectedElement?.Role === Reports3ItemRole.Grid">
      Grid
    </div>
    <div v-else-if="reports3.dataOneSelectedElement?.Role === Reports3ItemRole.Element">
      {{ getElement(reports3.dataOneSelectedElement?.ElementId)?.Name ?? 'Element' }}
    </div>
  </div>
  <div v-if="!reports3.dataOneSelectedElement && reports3.dataOne">
    <div>Layout</div>
    <div class="formgrid grid">
      <div class="field col-12">
        <label for="editRecordItemSize">Margin top</label>
        <div>
          <InputNumber
            id="editRecordItemMarginTop"
            class="inputfield w-full"
            v-model="reports3.dataOne.MarginTop"
            :min="0"
            suffix="cm"
            :allowEmpty="false"
          />
        </div>
      </div>
      <div class="field col-12">
        <label for="editRecordItemSize">Margin bottom</label>
        <div>
          <InputNumber
            id="editRecordItemMarginBottom"
            class="inputfield w-full"
            v-model="reports3.dataOne.MarginBottom"
            :min="0"
            suffix="cm"
            :allowEmpty="false"
          />
        </div>
      </div>
      <div class="field col-12">
        <label for="editRecordItemSize">Margin left</label>
        <div>
          <InputNumber
            id="editRecordItemMarginLeft"
            class="inputfield w-full"
            v-model="reports3.dataOne.MarginLeft"
            :min="0"
            suffix="cm"
            :allowEmpty="false"
          />
        </div>
      </div>
      <div class="field col-12">
        <label for="editRecordItemSize">Margin right</label>
        <div>
          <InputNumber
            id="editRecordItemMarginRight"
            class="inputfield w-full"
            v-model="reports3.dataOne.MarginRight"
            :min="0"
            suffix="cm"
            :allowEmpty="false"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="reports3.dataOneSelectedElement">
    <div>Appearance</div>
    <div class="formgrid grid">
      <div class="field col-12" v-if="reports3.dataOneSelectedElement.Uid !== reports3.dataOne?.Header?.Uid && reports3.dataOneSelectedElement.Uid !== reports3.dataOne?.Footer?.Uid">
        <label for="editRecordItemSize">Size</label>
        <div>
          <Dropdown 
            intupId="editRecordItemSize"
            v-model="reports3.dataOneSelectedElement.Size"
            :options="columns" 
            optionValue="key" 
            optionLabel="name" 
            placeholder="Select size" 
            class="w-full"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-if="reports3.dataOneSelectedElement?.AdditionalParameters && reports3.getSelectedElementDescription?.AdditionalParameters?.length">
    <div>Parameters</div>
    <div class="formgrid grid">
      <div 
        class="field col-12 mb-0 field-margin-top flex flex-column" 
        v-for="(additionalParameter, index) in reports3.getSelectedElementDescription.AdditionalParameters" 
        :key="index"
      >
        <label :for="`editRecordParam-${additionalParameter.Name}`" class="flex-shrink-0">
          {{ additionalParameter.Label }}
        </label>
        <div class="flex-auto flex align-items-center">
          <InputText 
            v-if="additionalParameter.Type === ScriptAdditionalParameterType.String"
            :id="`editRecordParam-${additionalParameter.Name}`"
            class="inputfield p-inputtext-lg w-full"
            type="text" 
            v-model="reports3.dataOneSelectedElement.AdditionalParameters[additionalParameter.Name]"
          />
          <InputNumber
            v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Number"
            :id="`editRecordParam-${additionalParameter.Name}`"
            class="inputfield p-inputtext-lg w-full"
            type="text" 
            v-model="reports3.dataOneSelectedElement.AdditionalParameters[additionalParameter.Name]"
            :minFractionDigits="2"
            :maxFractionDigits="20"
          />
          <InputSwitch
            v-else-if="additionalParameter.Type === ScriptAdditionalParameterType.Boolean"
            :id="`editRecordParam-${additionalParameter.Name}`"
            v-model="reports3.dataOneSelectedElement.AdditionalParameters[additionalParameter.Name]"
            class="vertical-align-middle"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import { useReports3Store } from "@/stores/reports3";
import WidgetHelper from "@/helpers/WidgetHelper";
import { ScriptAdditionalParameterType } from "@/models/script/ScriptAdditionalParameterType";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";

@Component({
  components: {
    Button,
    InputText,
    InputNumber,
    InputSwitch,
    Dropdown
  },
  directives: {
  }
})
class ReportsReportLeftPanelView extends Vue { 
  reports3 = useReports3Store();

  columns = WidgetHelper.getWidgetSizes();
  ScriptAdditionalParameterType = ScriptAdditionalParameterType;
  Reports3ItemRole = Reports3ItemRole;

  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3.dataOneElements?.length) {
      const element = this.reports3.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }
}

export default ReportsReportLeftPanelView;
</script>