<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 35 36"><path fill="#fff" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" d="M28.94 28.381H6.06L2.5 8.551l9.661 8.135L17.754 5.5l5.593 11.186L32.5 8.551l-3.56 19.83Z"/><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" d="M6.568 31.432H28.94"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class OrgUsersAdminMembersIconSvg extends Vue {
}

export default OrgUsersAdminMembersIconSvg;
</script>