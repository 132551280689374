import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import HaystackDefsState from "./states/HaystackDefsState";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { HaystackDefsEntity } from "@/models/nav-tree/HaystackDefsEntity";
import HaystackDefsService from "@/services/HaystackDefsService";

function entitiesToTrio(source: HaystackDefsEntity[]): string {
  const result = source.map(x => x.Trio).join("---\n\r");
  return result;
}

export const useHaystackDefsStore = defineStore('haystackDefs', {
  state: (): HaystackDefsState => ({ 
    isLoaded: false,
    guid : "",
    data: null,
    updateInProgress: false,
    updateError: false
  }),
  getters: {
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        const url = `rest/HaystackDefs_V1/defs`;
        const response = await axios.get<HaystackDefsEntity[]>(url);
        if (this.guid === guid) {
          this.data = response.data;
          this.isLoaded = true;

          const trioStr = entitiesToTrio(response.data);
          HaystackDefsService.init(trioStr);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load haystack defs",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = null;
        this.isLoaded = true;
      }
    },
    async createUpdate(defEntity: HaystackDefsEntity) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/HaystackDefs_V1/defs`;
        await axios.post<HaystackDefsEntity>(url, defEntity);
        ToastService.showToast("success", "Defs", "Changes saved", 5000);
        this.updateInProgress = false;
        if (this.isLoaded) {
          this.load();
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save def",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
    },
    async delete(defEntity: HaystackDefsEntity) {
      try {
        const url = `rest/HaystackDefs_V1/defs/${defEntity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Defs", `${defEntity.Def} is deleted`, 5000);
        if (this.isLoaded) {
          this.load();
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${defEntity.Def}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
  },
})
