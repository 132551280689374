import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import BBEState from "./states/BBEState";
import { BBEGroupModel } from "@/models/bills/BBEGroupModel";
import { BBESmartModel } from "@/models/bills/BBESmartModel";
import { GenericTree } from "@/models/tree/GenericTree";

export const useBBEStore = defineStore('bbe', {
  state: (): BBEState => ({ 
    isLoaded: false,
    guid: "",
    entities: null,
    entitiesSmart: null
  }),
  getters: {
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.entities = null;
        this.entitiesSmart = null;
        const promises = [
          axios.get<BBEGroupModel[]>(`rest/BitPool_V2/bbe/groups`),
          axios.get<BBESmartModel[]>(`rest/BitPool_V2/bbe/collections/smart`)
        ];
        const [response, responseSmart] = await Promise.all(promises);
        if (this.guid === guid) {
          this.entities = response.data as BBEGroupModel[];
          this.entitiesSmart = responseSmart.data as BBESmartModel[];
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load accounts",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.entitiesSmart = null;
        this.isLoaded = true;
      }
    },
    buildTree(): [GenericTree<BBEGroupModel | BBESmartModel>[], GenericTree<BBEGroupModel | BBESmartModel>[]] {
      const result: GenericTree<BBEGroupModel | BBESmartModel>[] = [];
      const resultFlat: GenericTree<BBEGroupModel | BBESmartModel>[] = [];

      // Accounts
      if (this.entities?.length) {
        const accounts: GenericTree<BBEGroupModel> = {
          key: "_accounts", 
          label: "Accounts",
          icon: "pi pi-dollar",
          children: [],
          leaf: false,
          selectable: false,
          isRoot: true,
          originalData: undefined
        };
        const sortedAccounts = this.entities
          .sort((a, b) => (a.path ?? "_root").localeCompare(b.path ?? "_root"));
        for (const account of sortedAccounts) {
          const item: GenericTree<BBEGroupModel> = {
            key: account._id,
            label: account.accountDetails.accountName,
            icon: undefined,
            children: [],
            leaf: true,
            selectable: true,
            isRoot: false,
            originalData: account
          };
          if (account.path) {
            const pathParts = account.path.split(",").filter(x => x);
            // Search parents
            let parent = accounts;
            for (const part of pathParts) {
              const child = parent.children?.find(x => x.key === part);
              if (child) {
                parent = child;
              } else {
                break;
              }
            }
            resultFlat.push(item);
            parent.children?.push(item);
            parent.leaf = false;
          } else {
            resultFlat.push(item);
            accounts.children?.push(item);
          }
        }
        result.push(accounts);
      }

      // Smart Collections
      if (this.entitiesSmart?.length) {
        const smartCollections: GenericTree<BBESmartModel> = {
          key: "_smartCollections", 
          label: "Smart Collections",
          icon: "pi pi-graduation-cap",
          children: [],
          leaf: false,
          selectable: false,
          isRoot: true,
          originalData: undefined
        };
        for (const smartCollection of this.entitiesSmart) {
          const item: GenericTree<BBESmartModel> = {
            key: smartCollection._id,
            label: smartCollection.collectionDetails.collectionName,
            icon: undefined,
            children: [],
            leaf: true,
            selectable: true,
            isRoot: false,
            originalData: smartCollection
          };
          resultFlat.push(item);
          smartCollections.children?.push(item);
        }
        result.push(smartCollections);
      }

      return [result, resultFlat];
    }
  },
})
