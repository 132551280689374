<template>
  <div class="flex justify-content-between align-items-center sm:gap-2">
    <Button
      v-for="(item, index) in externalProviders"
      v-bind:key="index"
      class="p-button-outlined p-button-icon-only btn-social-login"
      :class="item[1]"
      @click="loginExternal(item[0])"
      :disabled="disabled || item[0] === 'facebook'"
    >
    </Button>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from "primevue/button";
import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import { useAuthStore } from "@/stores/auth";

@Component({
  
  components: {
    Button
  },
})
class ExternalLoginView extends Vue {
  @Prop({ required: false, default: "" }) registrationEmail!: string;
  @Prop({ required: false, default: false }) disabled!: boolean;

  authStore = useAuthStore();

  externalProviders: [string, string][] = [
    ["google", "btn-google"],
    ["microsoft", "btn-microsoft"],
    ["linkedin", "btn-linkedin"],
    ["facebook", "btn-facebook"],
    ["github", "btn-github"]
  ]

  loginExternal(provider: string): void {
    try {
      this.authStore.goExternalStep1(provider, this.registrationEmail);
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        "Can't login with " + provider,
        errorMessage,
        5000
      );
    }
  }
}

export default ExternalLoginView;
</script>