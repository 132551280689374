<template>
  <DataTable
    v-model:selection="selectedRecords"
    :value="sites" 
    dataKey="SiteKey"
    showGridlines 
    responsiveLayout="stack" 
    breakpoint="660px" 
    class="responsive-breakpoint p-datatable-sm organisation-profile-users-groups-sites-edit-table"
    sortField="DateAdded" 
    :sortOrder="-1"
    :paginator="true"
    :rows="20"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
    :rowsPerPageOptions="[10, 20, 50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    v-model:filters="filters"
    filterDisplay="menu"
    :globalFilterFields="['Name']"
    removableSort
  >
    <template #header>
      <div class="table-header">
        <div class="md:flex md:align-items-center md:justify-content-between md:gap-3">
          <div class="md:flex-shrink-0">
            <Button 
              :disabled="!organisationGroupsSitesStore.isLoaded" 
              label="Add Site" 
              icon="pi pi-plus-circle" 
              class="my-1 mr-2" 
              @click="openCreateDialog"
            />
            <Button
              label="Delete" 
              icon="pi pi-trash" 
              class="my-1 mr-2 p-button-outlined p-button-danger" 
              @click="openDeleteSelectedRecordsConfirmation()"
              :disabled="!selectedRecords.length"
            />
          </div>
          <div class="mt-3 md:mt-0">
            <IconField iconPosition="left" class="w-full md:w-auto">
              <InputIcon class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Find Site" class="w-full md:w-auto" />
            </IconField>
          </div>
        </div>
      </div>
    </template>
    <template #empty>
      <div v-if="organisationGroupsSitesStore.isLoaded" class="w-full" style="min-height: 50vh;">
        <span class="inline-block py-2">No data found.</span>
      </div>
      <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
        <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
      </div>
    </template>
    <Column selectionMode="multiple" headerStyle="width: 1%; min-width: 3rem;" headerClass="column-with-checkbox" bodyClass="column-with-checkbox"></Column>
    <Column :sortable="true" field="Name" header="Site Name" headerStyle="min-width: min-content; width: 55%;" headerClass="no-break-word" bodyClass="break-word">
    </Column>
    <Column :sortable="true" field="DateAdded" header="Date Added" headerStyle="min-width: min-content; width: 17%;" headerClass="no-break-word" bodyClass="no-break-word">
      <template #body="slotProps">
        <DateTimezoneView :date="slotProps.data.DateAdded" timezone="local"/>
      </template>
    </Column>
    <Column :exportable="false" headerStyle="width: 1%; min-width: 44px;" bodyStyle="text-align: right; justify-content: flex-end;">
      <template #body="slotProps">
        <div>
          <div class="inline-flex">
            <Button 
              icon="pi pi-trash" 
              class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
              @click="openConfirmation(slotProps.data)" 
              v-tippy="'Delete'"
            />
          </div>
        </div>
      </template>
    </Column>
  </DataTable>
  <Dialog header="Add Sites" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'992px': '80vw'}" :style="{width: '56rem'}" class="organisation-profile-users-groups-sites-config-dialog">
    <div class="dialog-content">
      <BlockUI :blocked="organisationGroupsSitesStore.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="organisationGroupsSitesStore.updateInProgress ? 'blockui-blocked' : ''">
        <div>
          <div class="formgrid grid">
            <div class="field col-12 mb-0">
              <label for="addRecord">Sites</label>
              <div>
                <MultiSelect 
                  inputId="addRecord"
                  v-model="addRecord"
                  :options="availableSites"
                  placeholder="Select Sites"
                  display="chip"
                  :filter="true"
                  optionLabel="Name"
                  class="p-multiselect-multiline inputfield w-full"
                />
              </div>
            </div>
          </div>
        </div>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #header>
      <div>
        <div class="p-dialog-title">Add Sites</div>
        <div class="p-dialog-subtitle">Please select new sites for the group.</div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
        <span class="block">
          <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        </span>
        <span class="block ml-2">
          <Button 
            label="Add" 
            :icon="organisationGroupsSitesStore.updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
            @click="saveRecord" 
            :disabled='organisationGroupsSitesStore.updateInProgress || !addRecord.length' 
          />
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Model, Vue, Watch } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Avatar from "primevue/avatar";
import SelectButton from "primevue/selectbutton";
import MultiSelect from "primevue/multiselect";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import ConfirmationService from "@/services/ConfirmationService";
import { useOrganisationStore } from "@/stores/organisation";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { useOrganisationSitesStore } from "@/stores/organisationSites";
import { GroupFromDBDto } from "@/models/organisation/GroupFromDBDto";
import DateHelper from "@/helpers/DateHelper";
import { SiteDto } from "@/models/organisation/SiteDto";
import { useOrganisationGroupsSitesStore } from "@/stores/organisationGroupsSites";

@Component({
  components: {
    Button,
    InputText,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown,
    Avatar,
    SelectButton,
    MultiSelect,
    IconField,
    InputIcon,
    DateTimezoneView
  },
  directives: {
  }
})
class OrganisationUsersGroupsSites extends Vue {
  @Model model!: GroupFromDBDto;

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  organisationSitesStore = useOrganisationSitesStore();
  organisationGroupsSitesStore = useOrganisationGroupsSitesStore();

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get availableOrganisations(): OrganisationFullDto[] {
    let result = this.organisationStore.entities ?? [];
    result = result.filter(x => x.Id !== this.organisationStore.currentOrganisation?.Id);
    return result;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  created(): void {
    this.organisationSitesStore.load(this.model.OrganisationId);
    this.loadData();
  }

  unmounted(): void {
    this.organisationGroupsSitesStore.$reset();
  }

  @Watch('model.Id', { immediate: false, deep: false })
  onModelChanged(val: number, oldVal: number): void {
    if (oldVal > 0) {
      this.loadData();
    } else {
      this.organisationGroupsSitesStore.groupId = this.model.Id;
    }
  }

  sites: SiteDto[] = [];

  async loadData(): Promise<void> {
    await this.organisationGroupsSitesStore.load(this.model.OrganisationId, this.model.Id);
    const sites = JSON.parse(JSON.stringify(this.organisationGroupsSitesStore.entities ?? [])) as SiteDto[];
    sites.forEach(x => {
      x.DateAdded = DateHelper.parseFromMicrosoftString(x.DateAdded as string);
    });
    this.sites = sites;
  }

  async saveChanges(): Promise<boolean> {
    const createUpdateRecords: SiteDto[] = [];
    const deleteRecotds: SiteDto[] = [];
    this.sites.forEach(x => {
      const oldRecord = this.organisationGroupsSitesStore.entities?.find(y => y.SiteKey === x.SiteKey);
      if (!oldRecord) {
        // create
        createUpdateRecords.push(x);
      }
    });
    this.organisationGroupsSitesStore.entities?.forEach(x => {
      if (!this.sites.find(y => y.SiteKey === x.SiteKey)) {
        // delete
        deleteRecotds.push(x);
      }
    })
    let isOk = true;
    if (createUpdateRecords.length) {
      isOk = await this.organisationGroupsSitesStore.save(createUpdateRecords);
    }
    if (isOk && deleteRecotds.length) {
      isOk = await this.organisationGroupsSitesStore.delete(deleteRecotds);
    }
    return isOk;
  }

  // #region add/update
  displayCreateUpdateDialog = false;
  addRecord: SiteDto[] = [];

  get availableSites(): SiteDto[] {
    return ((this.organisationSitesStore.data[this.organisationIdStr] ?? []).entities ?? []).filter(x => !this.sites.find(y => y.SiteKey === x.SiteKey)).sort((a, b) => a.Name.localeCompare(b.Name));
  }

  openCreateDialog(): void {
    if (this.currentOrganisation) {
      this.addRecord = [];
      this.displayCreateUpdateDialog = true;
    }
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  saveRecord(): void {
    for (const record of this.addRecord) {
      const copy = JSON.parse(JSON.stringify(record)) as SiteDto;
      copy.DateAdded = new Date();
      this.sites.push(copy);
    }
    this.closeCreateUpdateDialog();
  }
  // #endregion add/update

  // #region delete
  selectedRecord: SiteDto | null = null;

  openConfirmation(record: SiteDto | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete site?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Site',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    const index = this.sites.findIndex(x => x.SiteKey === this.selectedRecord?.SiteKey);
    if (index > -1) {
      this.sites.splice(index, 1);
    }
  }

  selectedRecords: SiteDto[] = [];

  openDeleteSelectedRecordsConfirmation(): void {
    const message = `Are you sure you want to delete selected sites?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Sites',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteSelectedRecords();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteSelectedRecords(): void {
    if (this.selectedRecords.length) {
      for (const record of this.selectedRecords) {
        const index = this.sites.findIndex(x => x.SiteKey === record.SiteKey);
        if (index > -1) {
          this.sites.splice(index, 1);
        }
      }
    }
  }
  // #endregion delete
}

export default OrganisationUsersGroupsSites;
</script>