<template>
  <div class="dashboard default-view-container">
    <div class="dashboard-head-section">
      <Breadcrumb 
        :home="breadcrumbHome" 
        :model="breadcrumbItems" 
        class="mb-0 page-breadcrumb reduce-last-item reduce-all-items"
      >
        <template #separator>
          <span class="pi pi-chevron-right" aria-hidden="true"></span>
        </template>
      </Breadcrumb>
      <Chip v-if="dashboardState.isLoaded && !dashboardReadOnlyMode" :label="`Access: ${isFullAccess ? 'Admin' : permissions}`"></Chip>
    </div>

    <Message severity="warn" :closable="false" v-if="dashboardReadOnlyMode && !authState.jailMode">
      Modern dashboard is in read only mode. You can edit dashboard on <a href="/dashboard">legacy dashboard page</a>.
    </Message>

    <div v-if="dashboardState.isLoaded && dashboardState.currentDashboard && dashboardState.isLoadedGDRS">
      <div v-if="dashboardState.isLoadedWidgets">
        <div v-if="dashboardState.currentDashboard.widgets">
          <div v-if="!(dashboardState.editMode || dashboardState.currentDashboard.widgets && dashboardState.currentDashboard.widgets.length)" class="dashboard-empty flex flex-column justify-content-center align-items-center h-full">
            <div class="dashboard-empty-img">
              <img
                :src="'/assets/dashboard-empty-placeholder.svg'"
                width="418"
                alt="Bitpool"
              />
            </div>
            <div class="flex-shrink-0">
              <h4 class="mb-2 mt-4 md:pt-1">Create your dashboard</h4>
              <p class="mb-0 mt-0">Create dashboards for this organisation by clicking on the "+" icon <br>in the dashboard tab located in the side navigation.</p>
            </div>
          </div>

          <div
            class="dashboard-area"            
            :class="{'edit-mode': dashboardState.editMode, 'has-expand-widget': hasMaximizedWidget}"
          >
            <DashboardGridView
              v-if="dashboardState.editMode || dashboardState.currentDashboard.widgets && dashboardState.currentDashboard.widgets.length"
              :dashboardId="dashboardState.currentDashboard._id"
              :widgets="dashboardState.currentDashboard.widgets"
            />
          </div>
          <DashboardAddWidgetView
            ref="addWidgetElement"
            :class="!dashboardState.editMode ? 'invisible' : ''"
            :dashboardId="dashboardState.currentDashboard._id"
            :widgets="dashboardState.currentDashboard.widgets"
            buttonClasses="p-button-raised text-lg font-light rounded-border-radius dashboard-control-btn lg:w-full"
            :noButton="true"
          />

          <SpeedDial 
            v-if="!authState.jailMode"
            :model="actionItems" 
            :direction="isSpeedDialSmallScreen ? 'down' : 'left'" 
            :hideOnClickOutside="false"
            :transitionDelay="20"
            class="dashboard-actions"
            :class="editMode ? 'edit-mode' : ''"
            @show="dashboardSpeedDialShow"
            @hide="dashboardSpeedDialHide"
            :pt="{ menu: { style: dashboardSpeedDialMenuStyle } }"
          >
            <template #button="slotProps">
              <Button 
                @click="slotProps.toggleCallback"
                v-tippy="'Dashboard Actions'"
                class="dashboard-actions-init-btn"
              >
                <i class="dashboard-actions-init-btn-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 26"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.955 4.718h15.778m0 0c0 1.867 1.413 3.381 3.155 3.381 1.743 0 3.156-1.514 3.156-3.381 0-1.868-1.413-3.382-3.156-3.382-1.742 0-3.155 1.514-3.155 3.382Zm-9.467 8.228h15.778m-15.778 0c0 1.868-1.413 3.382-3.155 3.382-1.743 0-3.156-1.514-3.156-3.382 0-1.867 1.413-3.381 3.156-3.381 1.742 0 3.155 1.514 3.155 3.381Zm-6.31 8.342h15.777m0 0c0 1.867 1.413 3.381 3.155 3.381 1.743 0 3.156-1.514 3.156-3.381 0-1.868-1.413-3.382-3.156-3.382-1.742 0-3.155 1.514-3.155 3.382Z"/></svg>
                </i>
                <i class="dashboard-actions-init-btn-close"></i>
              </Button>
            </template>
            <template #item="slotProps">
              <Button 
                @click="slotProps.item.command({ originalEvent: $event, item: slotProps.item })" 
                :label="(slotProps.item.key === 'Additional actions' || isSpeedDialSmallScreen) ? '' : slotProps.item.label"
                v-tippy="slotProps.item.label"
                :icon="slotProps.item.icon"
                :class="'animate__animated ' + slotProps.item.class"
              />
            </template>
          </SpeedDial>
          <Menu 
            ref="speedDialOtherMenu" 
            id="speedDialOtherMenu" 
            :model="speedDialOtherMenuItems" 
            :popup="true" 
            @show="onSpeedDialOtherMenuShow"
            @hide="onSpeedDialOtherMenuHide"
          />
          <OverlayPanel 
            ref="speedDialGDRSOverlayPanel" 
            appendTo="#overlayPanelPlaceholder" 
            @show="onSpeedDialGDRSOverlayPanelShow"
            @hide="onSpeedDialGDRSOverlayPanelHide"
            class="gdrs-overlaypanel"
          >
            <div v-if="dashboardState.gdrs">
              <DashboardWidgetGlobalDateRangeView :overlayFix="overlayFix"/>
            </div>
            <div v-else>
              <h3 class="gdrs-title">Enable GDRS (Global Date Range Setpoint)</h3>
              <p class="mb-0"><span>Something is wrong, please try to reload page</span></p>
            </div>
          </OverlayPanel>
          
          <Sidebar v-model:visible="dashboardState.visibleSidebarComments" position="right" class="dashboard-comments-sidebar">
            <h2>Comments</h2>

            <div v-if="commentsPermissions === Permission.Disable">
              <Message severity="warn" :closable="false" class="mt-4 mx-3 mb-3">Not enough rights</Message>
            </div>
            <DashboardCommentsView v-else :dashboardId="dashboardId"/>
          </Sidebar>
          <Dialog header="Share Dashboard" v-model:visible="displayShare" :modal="true" :breakpoints="{'1400px': '75vw', '1024px': '90vw'}" :style="{width: '60vw'}" class="share-dashboard-dialog">
            <ShareView/>
            <template #footer></template>
          </Dialog>
          <Dialog header="Add to Presentation" v-model:visible="displayPresent" :modal="true" :style="{width: '56rem'}" class="add-presentation-dialog">
            <div class="dialog-content" style="min-height: 80px;">
              <div v-if="presentationState.isLoaded" class="field mb-0">
                <label>Please select presentation from the list below</label>
                <Listbox  v-model="selectedPresentation" :options="presentations" optionLabel="name" />
              </div>
              <div v-else class="flex justify-content-center align-items-center h-full">
                <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
              </div>
            </div>
            <template #footer>
              <Button label="Close" icon="pi pi-times" @click="closeAddToPresentationDialog" class="p-button-text p-button-secondary"/>
              <Button label="Add" icon="pi pi-check" @click="addToPresentation" :disabled="!presentationState.isLoaded || !selectedPresentation" />
            </template>
          </Dialog>
          <Dialog header="Dashboards Backup" v-model:visible="displayBackupRestore" :modal="true" :breakpoints="{'1400px': '75vw', '1024px': '90vw'}" :style="{width: '60vw'}" class="backup-restore-dashboard-dialog">
            <BackupRestoreDashboardView/>
            <template #footer></template>
          </Dialog>
          <Dialog header="Find & Replace" v-model:visible="displayReplace" :modal="true" :style="{width: '90vw'}" class="find-replace-dashboard-dialog">
            <FindReplaceView ref="FindReplaceView" @close-dialog="closeReplaceDialog"/>
            <template #footer>
              <Button
                @click="doFindReplaceAll()"
                label="Replace all"
                class="p-button-outlined replaceAllButton" />
              <Button
                @click="doFindReplace()"
                label="Replace"
                class="replaceButton" />
            </template>
          </Dialog>
          <FirstRunView v-if="dashboardState.spaceSettings?.showWalkthrough"/>
        </div>
      </div>
      <div v-else class="progress-spinner-container">
        <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
      </div>
    </div>
    <div v-else-if="!dashboardState.isLoaded" class="progress-spinner-container">
      <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-else>Please select dashboard</div>
  </div>

  <BlockUI :blocked="blockPage" :fullScreen="true" :autoZIndex="true" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="blockPage ? 'blockui-blocked' : ''">
    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import DashboardState from "@/store/states/DashboardState";
import { Component, Prop, Vue } from "vue-facing-decorator";
import DashboardGridView from "./DashboardGridView.vue";
import DashboardAddWidgetView from "./DashboardAddWidgetView.vue";
import DashboardWidgetGlobalDateRangeView from "./DashboardWidgetGlobalDateRangeView.vue";
import GlobalDateSvg from "@/components/svg/GlobalDateSvg.vue";
import AddGridFullSvg from "@/components/svg/AddGridFullSvg.vue";
import AddWidgetFullSvg from "@/components/svg/AddWidgetFullSvg.vue";
import DashboardSettingsSvg from "@/components/svg/DashboardSettingsSvg.vue";
import ShareSvg from "@/components/svg/ShareSvg.vue";
import PrintSvg from "@/components/svg/PrintSvg.vue";
import BackupSvg from "@/components/svg/BackupSvg.vue";
import RestoreSvg from "@/components/svg/RestoreSvg.vue";
import PresentSvg from "@/components/svg/PresentSvg.vue";
import FindReplaceSvg from "@/components/svg/FindReplaceSvg.vue";
import WidgetNoDataView from '@/components/widgets-next/common/WidgetNoDataView.vue';
import InputSwitch from 'primevue/inputswitch';
import Button from 'primevue/button';
import Breadcrumb from 'primevue/breadcrumb';
import Message from 'primevue/message';
import Chip from 'primevue/chip';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';
import Sidebar from 'primevue/sidebar';
import Dialog from 'primevue/dialog';
import Listbox from 'primevue/listbox';
import SpeedDial from 'primevue/speeddial';
import { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";
import OverlayPanel from "primevue/overlaypanel";
import Menu from "primevue/menu";
import { Watch } from "vue-facing-decorator";
import SpaceHelper from "@/helpers/SpaceHelper";
import { Space } from "@/models/dashboard/Space";
import NavigationHelper from "@/helpers/NavigationHelper";
import { nextTick, reactive } from "vue";
import { GDRSModel } from "@/models/dashboard/GDRSModel";
import AuthState from "@/store/states/AuthState";
import { Permission } from "@/models/enums/Permission";
import PrintHelper from "@/helpers/PrintHelper";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetDataState from "@/store/states/WidgetDataState";
import FirstRunView from "./FirstRunView.vue";
import ShareView from "./ShareView.vue";
import BackupRestoreDashboardView from "./BackupRestoreDashboardView.vue";
import FindReplaceView from "@/components/views/FindReplaceView.vue";
import { DashboardType } from "@/models/dashboard/DashboardType";
import PresentationState from "@/store/states/PresentationState";
import { PresentationModel } from "@/models/dashboard/PresentationModel";
import { PresentationEffect } from "@/models/enums/PresentationEffect";
import DashboardCommentsView from "./DashboardCommentsView.vue";
import { useNavTreeStore } from '@/stores/navTree';
import { useTariffStore } from "@/stores/tariff";
import { useOrganisationStore } from "@/stores/organisation";
import EventBusHelper from "@/helpers/EventBusHelper";
import { Emitter } from "mitt";
import { useSpaceBackupStore } from "@/stores/spaceBackup";

@Component({
  components: {
    DashboardGridView,
    DashboardAddWidgetView,
    DashboardWidgetGlobalDateRangeView,
    GlobalDateSvg,
    AddWidgetFullSvg,
    AddGridFullSvg,
    ShareSvg,
    DashboardSettingsSvg,
    PrintSvg,
    PresentSvg,
    FindReplaceSvg,
    WidgetNoDataView,
    InputSwitch,
    Button,
    Breadcrumb,
    Message,
    ProgressSpinner,
    BlockUI,
    Chip,
    Sidebar,
    Dialog,
    Listbox,
    SpeedDial,
    Menu,
    OverlayPanel,
    FirstRunView,
    ShareView,
    BackupRestoreDashboardView,
    FindReplaceView,
    BackupSvg,
    RestoreSvg,
    DashboardCommentsView
  },
})
class DashboardView extends Vue {
  @Prop({ required: true }) pane!: string;
  @Prop({ required: true }) dashboardId!: string;
  
  noDataType = WidgetNoDataTypes.NotConfigured;

  navTreeStore = useNavTreeStore();
  tariffStore = useTariffStore();
  spaceBackupStore = useSpaceBackupStore();

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get dashboardReadOnlyMode(): boolean {
    return this.$store.getters["auth/getDashboardReadOnlyMode"];
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get dashboardType(): DashboardType {
    return this.dashboardState.dashboardType;
  }

  get dashboards(): Space[] | null | undefined {
    return this.$store.getters["dashboard/getDashboards"];
  }

  get hasMaximizedWidget(): boolean {
    return !!this.widgetDataState.maximizedWidget;
  }

  get isFullAccess(): boolean {
    return this.$store.getters["dashboard/isFullAccess"];
  }

  get permissions(): Permission {
    const getPermissions = this.$store.getters["dashboard/getPermissions"] as (id: string) => Permission;
    return getPermissions(this.dashboardId);
  }

  Permission = Permission;

  get commentsPermissions(): Permission {
    const result = this.$store.getters["dashboard/getCommentsPermissions"] as Permission;
    return result;
  }
  
  get canEditDashboard(): boolean {
    const canEditDashboard = this.$store.getters["dashboard/canEditDashboard"] as (id: string) => boolean;
    const result = canEditDashboard(this.dashboardId);
    return result;
  }

  get rootNode(): Space | null | undefined {
    return SpaceHelper.getRootSpace(this.dashboards);
  }

  get editMode(): boolean {
    return this.dashboardState.editMode;
  }

  set editMode(value: boolean) {
    this.$store.commit("dashboard/setEditMode", value);
    if (this.widgetDataState.maximizedWidget) {
      this.$store.commit("widgetData/unmaximizeWidget");
    }
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.$store.commit("dashboard/unloadDashboards");
    this.$store.commit("widgetData/unloadData");
    this.navTreeStore.$reset();
    this.spaceBackupStore.$reset();
    this.$store.dispatch("dashboard/loadGDRS");
    this.$store.dispatch("dashboard/loadSpaceSettings");
    const oldDashboardType = this.dashboardType;
    if (oldDashboardType !== this.pane && this.pane) {
      this.$store.commit("dashboard/setDashboardType", this.pane);
    }
    this.loadDashboards();
    this.emitter.on("window_size_changed_debounce", this.onResize);
    this.onResize();
  }

  @Watch('pane', { immediate: false, deep: false })
  onPaneChanged(val: string, oldVal: string): void {
    this.$store.commit("dashboard/setDashboardType", val);
    this.loadDashboards();
  }
  
  @Watch('dashboardId', { immediate: false, deep: false })
  onDashboardIdChanged(val: string, oldVal: string): void {
    this.$store.commit("widgetData/unmaximizeWidget");
    this.$store.commit("dashboard/setDashboardId", val);
    const dashboard = this.dashboards?.find(x => x._id === val);
    this.$store.dispatch("dashboard/setCurrentDashboard", dashboard);
  }

  async loadDashboards(): Promise<void> {
    if (this.dashboardType === DashboardType.Organisation) {
      if (!this.dashboardState.isLoaded || !this.dashboardState.dashboards) {
        await this.$store.dispatch("dashboard/loadOrganisation", { id: this.dashboardId, silent: false });
      }
    } else if (this.dashboardType === DashboardType.Personal) {
      if (!this.dashboardState.isLoaded || !this.dashboardState.dashboardsPersonal) {
        await this.$store.dispatch("dashboard/loadPersonal", { id: this.dashboardId, silent: false });
      }
    } else if (this.dashboardType === DashboardType.Shared) {
      if (!this.dashboardState.isLoaded || !this.dashboardState.dashboardsShared) {
        await this.$store.dispatch("dashboard/loadShared", { id: this.dashboardId, silent: false });
      }
    }
    if (this.dashboardState.searchDashboard) {
      this.$store.commit("dashboard/searchDashboard", this.dashboardState.searchDashboard);
    }
    if (!this.dashboardId || !this.dashboardState.currentDashboard) {
      // select first dashboard with respect to user order
      const root = this.rootNode;
      if (root) {
        const children = SpaceHelper.getChildren(this.dashboards, root);
        if (children && children.length) {
          const ordered = SpaceHelper.sortSpaces(children, root.spacesOrder);
          if (ordered && ordered.length) {
            const firstDashboard = ordered[0];
            if (firstDashboard && firstDashboard._id) {
              NavigationHelper.goToDashboard(this.dashboardType, firstDashboard._id);
            }
          }
        }
      }
    }
  }

  get parents(): Space[] {
    return SpaceHelper.getParents(this.dashboards, this.dashboardState.currentDashboard).filter(x => x.path);
  }

  buildDashboardUrl(dashboard: Space | null | undefined): string {
    return dashboard ? 
      `${window.location.pathname}?pane=${this.dashboardType}&id=${dashboard._id}` :
      `${window.location.pathname}?pane=${this.dashboardType}`;
  }

  get breadcrumbHome(): any {
    const home = this.parents.length ? this.parents[0] : this.dashboardState.currentDashboard;
    const url = this.buildDashboardUrl(home);
    return {
      label: home ? home.spaceName : "Dashboard",
      url: url,
      command: (event: MenuItemCommandEvent) => {
        if (!NavigationHelper.goTo(url)) {
          event.originalEvent.preventDefault();
        }
      }
    };
  }

  get breadcrumbItems(): MenuItem[] {
    const result = [];
    if (this.parents.length > 1) {
      for (let i = 1; i < this.parents.length; i++) {
        const url = this.buildDashboardUrl(this.parents[i]);
        result.push({
          label: this.parents[i].spaceName,
          url: url,
          command: (event: MenuItemCommandEvent) => {
            if (!NavigationHelper.goTo(url)) {
              event.originalEvent.preventDefault();
            }
          }
        });
      }
    }
    if (this.parents.length && this.dashboardState.currentDashboard) {
      const url = this.buildDashboardUrl(this.dashboardState.currentDashboard);
      result.push({
        label: this.dashboardState.currentDashboard.spaceName,
        url: url,
        command: (event: MenuItemCommandEvent) => {
          if (!NavigationHelper.goTo(url)) {
            event.originalEvent.preventDefault();
          }
        }
      });
    }
    return result;
  }

  // #region gdrs overlay
  dismissableOverlay = true;

  onCalendarShow(): void {
    this.dismissableOverlay = false;
  }

  async onCalendarHide() : Promise<void> {
    await nextTick();
    this.dismissableOverlay = true;
  }

  overlayFix(dismissableOverlay: boolean): void {
    if (dismissableOverlay) {
      this.onCalendarHide();
    } else {
      this.onCalendarShow();
    }
  }
  // #endregion gdrs overlay

  // #region dashboard sidebar
  isSpeedDialSmallScreen = false;

  onResize(): void {
    const windowWidth = window.innerWidth;
    this.isSpeedDialSmallScreen = windowWidth < 1200;
  }

  dashboardSpeedDialVisible = false;
  dashboardSpeedDialVisibleTimeout: number | undefined = undefined;

  dashboardSpeedDialShow(): void {
    clearTimeout(this.dashboardSpeedDialVisibleTimeout);
    this.dashboardSpeedDialVisible = true;
  }

  dashboardSpeedDialHide(): void {
    clearTimeout(this.dashboardSpeedDialVisibleTimeout);
    this.dashboardSpeedDialVisibleTimeout = window.setTimeout(() => {
      this.dashboardSpeedDialVisible = false;
    }, 300);
  }

  get dashboardSpeedDialMenuStyle(): string {
    const result = this.dashboardSpeedDialVisible ? '' : 'height: 0; width: 0; padding: 0; margin: 0;';
    return result;
  }

  get actionItems(): MenuItem[] {
    const result: MenuItem[] = [
      {
        key: "Edit",
        label: "Edit",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.editMode = !this.editMode;
        },
        class: "dashboard-actions-item-btn dashboard-actions-item-btn-edit",
        visible: !this.editMode
      },
      {
        key: "Display",
        label: "Display",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.editMode = !this.editMode;
        },
        class: "dashboard-actions-item-btn dashboard-actions-item-btn-display",
        visible: this.editMode
      },
      {
        key: "Add grid",
        label: "Add grid",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.addGrid();
        },
        class: "dashboard-actions-item-btn dashboard-actions-item-btn-add-grid",
        visible: this.editMode
      },
      {
        key: "Add widget",
        label: "Add widget",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.addWidget();
        },
        class: "dashboard-actions-item-btn dashboard-actions-item-btn-add-widget",
        visible: this.editMode
      },
      {
        key: "Create with AI",
        label: "Create with AI",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.addAI();
        },
        class: "dashboard-actions-item-btn dashboard-actions-item-btn-create-with-ai",
        visible: this.editMode && this.authState.permissions?.BitpoolAdmin
        // todo: allow for users with access to BitpoolAI after initial testing
      },
      {
        key: "GDRS",
        label: "GDRS",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.toggleSpeedDialGDRSOverlayPanel(event.originalEvent);
        },
        class: "dashboard-actions-item-btn dashboard-actions-item-btn-gdrs" + 
          (this.isSpeedDialGDRSOverlayPanelVisible ? " dashboard-actions-item-btn-gdrs-active" : ""),
        visible: !this.editMode
      },
      {
        key: "Print",
        label: "Print",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.print();
        },
        class: "dashboard-actions-item-btn dashboard-actions-item-btn-print",
        visible: !this.editMode
      },
      {
        key: "Share",
        label: "Share",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.openShare();
        },
        class: "dashboard-actions-item-btn dashboard-actions-item-btn-share",
        visible: !this.editMode && this.authState.permissions?.Sharing
      },
      {
        key: "Additional actions",
        label: "Additional actions",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.toggleSpeedDialOtherMenu(event.originalEvent);
        },
        class: "dashboard-actions-item-btn dashboard-actions-item-btn-additional" + 
          (this.isSpeedDialOtherMenuVisible ? " dashboard-actions-item-btn-additional-active" : ""),
        visible: !this.editMode
      }
    ];
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  get speedDialOtherMenuItems(): MenuItem[] {
    const result: MenuItem[] = [
      {
        key: "Backup & restore",
        label: "Backup & restore",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.openBackupRestore();
        },
        class: ""
      },
      {
        key: "Find & replace",
        label: "Find & replace",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.openReplaceDialog();
        },
        class: "",
        visible: this.authState.permissions?.BitpoolAdmin
      },
      {
        key: "Add to presentation",
        label: "Add to presentation",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.openAddToPresentationDialog();
        },
        class: "",
        visible: this.authState.permissions?.FullAccess || this.authState.permissions?.Sharing
      }
    ];
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  isSpeedDialGDRSOverlayPanelVisible = false;

  onSpeedDialGDRSOverlayPanelShow(): void {
    this.isSpeedDialGDRSOverlayPanelVisible = true;
  }
  
  onSpeedDialGDRSOverlayPanelHide(): void {
    this.isSpeedDialGDRSOverlayPanelVisible = false;
  }

  toggleSpeedDialGDRSOverlayPanel(event: Event): void {
    if (this.$refs.speedDialGDRSOverlayPanel) {
      (this.$refs.speedDialGDRSOverlayPanel as OverlayPanel).toggle(event);
    }
  }

  isSpeedDialOtherMenuVisible = false;

  onSpeedDialOtherMenuShow(): void {
    this.isSpeedDialOtherMenuVisible = true;
  }
  
  onSpeedDialOtherMenuHide(): void {
    this.isSpeedDialOtherMenuVisible = false;
  }

  toggleSpeedDialOtherMenu(event: Event): void {
    if (this.$refs.speedDialOtherMenu) {
      (this.$refs.speedDialOtherMenu as Menu).toggle(event);
    }
  }

  async addGrid(): Promise<void> {
    if (this.editMode && this.$refs.addWidgetElement) {
      await (this.$refs.addWidgetElement as DashboardAddWidgetView).addGrid();
    }
  }

  async addWidget(): Promise<void> {
    if (this.editMode && this.$refs.addWidgetElement) {
      await (this.$refs.addWidgetElement as DashboardAddWidgetView).openDialog();
    }
  }

  async addAI(): Promise<void> {
    if (this.editMode && this.$refs.addWidgetElement) {
      await (this.$refs.addWidgetElement as DashboardAddWidgetView).openAIDialog();
    }
  }

  displayShare = false;

  openShare(): void {
    this.displayShare = true;
  }

  blockPage = false;

  async print(): Promise<void> {
    const element = document.querySelector('.dashboard-area');
    this.blockPage = true;
    document.body.classList.add("without-widget-shadow");
    await nextTick();
    await PrintHelper.printHtml(element, !!this.authState.userSettings?.darkTheme, ":scope > .grid > div");
    document.body.classList.remove("without-widget-shadow");
    this.blockPage = false;
  }

  displayPresent = false;

  get presentationState(): PresentationState {
    return this.$store.state.presentation;
  }

  selectedPresentation: PresentationModel | null | undefined = null;

  get presentations(): PresentationModel[] {
    const orgId = this.organisationStore.currentOrganisation?.Id;
    return orgId ? this.$store.state.presentation.configs.filter(x => x.organisationId === orgId) : this.$store.state.presentation.configs;
  }

  openAddToPresentationDialog(): void {
    this.displayPresent = true;
    if (!this.presentationState.isLoaded) {
      this.$store.dispatch("presentation/load");
    }
  }

  closeAddToPresentationDialog(): void {
    this.displayPresent = false;
  }

  addToPresentation(): void {
    if (this.selectedPresentation) {
      this.selectedPresentation.dashboards.push({
        duration: 30,
        effect: PresentationEffect.Fade,
        spaceId: this.dashboardId,
        name: this.dashboardState.currentDashboard?.spaceName ?? ""
      });
      this.$store.dispatch("presentation/createUpdate", this.selectedPresentation);
      this.closeAddToPresentationDialog();
    }
  }

  displayBackupRestore = false;

  openBackupRestore(): void {
    this.displayBackupRestore = true;
  }

  displayReplace = false;

  openReplaceDialog(): void {
    this.displayReplace = true;
  }

  closeReplaceDialog(): void {
    this.displayReplace = false;
  }

  doFindReplace(): void {
    if(this.$refs.FindReplaceView) {
      (this.$refs.FindReplaceView as FindReplaceView).doReplace();
    }
  }

  doFindReplaceAll(): void {
    if(this.$refs.FindReplaceView) {
      (this.$refs.FindReplaceView as FindReplaceView).doReplaceAll();
    }
  }

  // #endregion dashboard sidebar
}

export default DashboardView;
</script>
