<template>
  <div>
    <TagManagerSelectorView 
      v-if="!tagManagerStore.isMiniMode"
      v-show="selectedStatNodes?.length"
      v-model="selectedStatNodes"
    />

    <TabMenu v-if="!tagManagerStore.isMiniMode && selectedStatNodes?.length" v-model:activeIndex="activeTabIndex" :model="tabs" class="flex-shrink-0" />

    <div v-if="activeTabIndex === 1 && selectedStatNodes?.length" class="flex-auto flex flex-column">
      <TagManagerTagsEditView
        v-if="selectedNode?.key"
        v-model="haysonData"
        :streamKey="selectedNode.key"
        class="flex-auto flex flex-column semantics-settings-middle"
      />
      <div v-if="selectedStatNodes.length > 0" class="flex-shrink-0 flex gap-2 align-items-center semantics-settings-bottom">
        <Button label="Revert" class="p-button-outlined p-button-secondary w-full" @click="revertChanges" :disabled="!isChanged"/>
        <Button label="Save" class="w-full" @click="saveChanges" :disabled="!isChanged"/>
      </div>
    </div>
    <div v-else-if="activeTabIndex === 0 && selectedStatNodes?.length" class="semantics-model-graph flex-auto">
      <TagManagerVisualizationView
        v-if="rootNode && selectedNode"
        v-model="rootNode"
        :selectedNode="selectedNode"
      />
    </div>
  </div>
</template>

<script lang="ts">
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import Sidebar from "primevue/sidebar";
import TabMenu from 'primevue/tabmenu';
import { Component, Model, Vue, Watch } from "vue-facing-decorator";
import { useTagManagerStore } from "@/stores/tagManager";
import TagManagerTagsEditView from "@/components/views/tags/TagManagerTagsEditView.vue";
import TagManagerVisualizationView from "@/components/views/tags/TagManagerVisualizationView.vue";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import HaystackDefsService from "@/services/HaystackDefsService";
import { HaysonDict } from "haystack-core";
import { BP_TagChangeStreams } from "@/models/BP_TagChangeStreams";
import { nextTick } from "vue";
import { Stat } from "@he-tree/vue/types/src/components/TreeProcessorVue";
import TagManagerSelectorView from "@/components/views/tags/TagManagerSelectorView.vue";
import { useNavTreeStore } from "@/stores/navTree";

@Component({
  components: {
    Button,
    InputText,
    Sidebar,
    TabMenu,
    TagManagerTagsEditView,
    TagManagerVisualizationView,
    TagManagerSelectorView
  },
})
class TagManagerTagsView extends Vue {
  @Model selectedStatNodes!: Stat<TreeNodeForUI>[];

  get selectedStatNode(): Stat<TreeNodeForUI> | null {
    return this.tagManagerStore.activeNode ? this.tagManagerStore.activeNode : null;
  }
  get selectedNode(): TreeNodeForUI | null {
    return (this.tagManagerStore.activeNode ? this.tagManagerStore.activeNode : null)?.data ?? null;
  }

  get rootNode(): TreeNodeForUI | null {
    // because he-tree doesn't update children array inside stat data
    return this.navTreeStore.structuredDataForUI?.find(x => x.key === this.tagManagerStore.activeSite?.data.key) ?? null;
  }
  
  haysonData: HaysonDict = {};
  isInternalChange = false;

  get isChanged(): boolean {
    return this.tagManagerStore.isActiveNodeChanged;
  }

  set isChanged(value: boolean) {
    this.tagManagerStore.isActiveNodeChanged = value;
  }

  navTreeStore = useNavTreeStore();
  tagManagerStore = useTagManagerStore();

  get activeTabIndex(): number {
    return this.tagManagerStore.isMiniMode ? (this.tagManagerStore.activeTabMiniMode - 1) : this.tagManagerStore.activeTab;
  }
  set activeTabIndex(value: number) {
    if (this.tagManagerStore.isMiniMode) {
      this.tagManagerStore.activeTabMiniMode = value + 1;
    } else {
      this.tagManagerStore.activeTab = value;
    }
  }
  tabs = ([
    {
      label: 'Model'
    },
    {
      label: 'Tag Editor'
    }
  ]);

  created(): void {
    this.refresh();
  }

  @Watch("selectedNode", { immediate: false, deep: true })
  onSelectedNodeChanged(): void {
    this.refresh();
  }

  @Watch("haysonData", { immediate: false, deep: true })
  onHaysonDataChanged(): void {
    if (this.isInternalChange) {
      this.isInternalChange = false;
    } else {
      this.isChanged = true;
    }
  }

  async refresh(): Promise<void> {
    const hd = this.buildHaysonData();
    this.isInternalChange = true;
    this.isChanged = false;
    this.haysonData = hd;
    await nextTick();
    document.querySelector("#semantics-tags >:first-child")?.scrollIntoView({ behavior: 'smooth' });
  }

  buildHaysonData(): HaysonDict {
    const result: HaysonDict = {};
    const node = this.selectedNode;
    if (node?.key) {
      const currentDict = HaystackDefsService.navTreeNodeToHaysonDict(node);
      if (currentDict) {
        result[node.key] = currentDict;
      }
    }
    return result;
  }

  async saveChanges(): Promise<void> {
    if (this.selectedNode?.key && this.haysonData[this.selectedNode.key]) {
      const tags: string[] = [];
      const haysonDict = this.haysonData[this.selectedNode.key] as HaysonDict;
      for (const key in haysonDict) {
        const value = haysonDict[key];
        const valueStr = HaystackDefsService.haysonValToString(value);
        tags.push(valueStr ? `${key}=${valueStr}` : key);
      }
      const tagsRemove: string[] = [];
      const tagsAdd: string[] = tags;
      this.selectedNode.tags?.forEach(tag => {
        const tagName = HaystackDefsService.extractTagName(tag);
        const tagNameWithEquals = tagName + "=";
        const newTag = tags.find(t => t === tagName || t.startsWith(tagNameWithEquals)); 
        if (!newTag) {
          tagsRemove.push(tag);
        }
      })
      const streamKey = this.selectedNode.key;
      const request: BP_TagChangeStreams = {
        StreamKeys: [streamKey],
        TagsRemove: tagsRemove,
        TagsAdd: tagsAdd
      };
      const updateResult = await this.tagManagerStore.changeTags(request);
      if (updateResult && updateResult[streamKey]) {
        const apiTags = updateResult[streamKey];
        this.isInternalChange = true;
        this.tagManagerStore.replaceTreeNodeTags(this.selectedNode, apiTags);
        this.isChanged = false;
      }
    }
  }

  revertChanges(): void {
    this.refresh();
  }
}

export default TagManagerTagsView;
</script>