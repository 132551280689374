<template>
  <div v-if="(widgetDescription?.hasTitle || widgetDescription?.features.includes(widgetFeatures.titleOptional) && aws?.displayTitle) && bws && bws.widgetName" class="widget-title mb-2">
    <Button 
      v-if="dataError"
      @click="copyError"
      icon="pi pi-exclamation-triangle text-xl font-bold" 
      :content="dataError"
      v-tippy="{ placement : 'bottom' }" 
      class="p-button-icon-only p-button-rounded p-button-light-danger data-error-btn mr-2"
    >
    </Button>
    <h3 class="mb-0">{{bws.widgetName}}</h3>
  </div>
  <div 
    class="widget-controls" 
    v-if="widgetDescription?.features.includes(widgetFeatures.dateRange) &&
      (!widgetDescription?.features.includes(widgetFeatures.dataOptional) || aws?.enableOptionalData)"
  >
    <DashboardWidgetDateRangeView
      :widgetConfig="widgetConfig"
      :dashboardId="dashboardId"
    />
  </div>
  <div class="widget-body">
    <TitleWidget 
      v-if="widgetConfig?.widgetType === 'titlewidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <TextBoxWidget 
      v-else-if="widgetConfig?.widgetType === 'textboxwidget'"
      :widgetConfig="widgetConfig"
    />
    <WebsiteWidget 
      v-else-if="widgetConfig?.widgetType === 'websiteview'"
      :widgetConfig="widgetConfig"
    />
    <ImageWidget 
      v-else-if="widgetConfig?.widgetType === 'imagewidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <AIButtonWidget
      v-else-if="widgetConfig?.widgetType === 'aibuttonwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
      :openBitpoolAIDialog="openBitpoolAIDialog"
    />
    <LimitTrackerWidget
      v-else-if="widgetConfig?.widgetType === 'limittrackerwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <MapWidget
      v-else-if="widgetConfig?.widgetType === 'mapwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <NotificationsStatusWidget 
      v-else-if="widgetConfig?.widgetType === 'notificationsstatuswidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <WeatherWidget 
      v-else-if="widgetConfig?.widgetType === 'cweather'"
      :widgetConfig="widgetConfig"
    />
    <WeatherForecastWidget 
      v-else-if="widgetConfig?.widgetType === 'fweather'"
      :widgetConfig="widgetConfig"
    />
    <DigitalClockWidget 
      v-else-if="widgetConfig?.widgetType === 'dclock'"
      :widgetConfig="widgetConfig"
    />
    <AnalogClockWidget 
      v-else-if="widgetConfig?.widgetType === 'aclock'"
      :widgetConfig="widgetConfig"
    />
    <DateWidget 
      v-else-if="widgetConfig?.widgetType === 'date'"
      :widgetConfig="widgetConfig"
    />
    <DataPointWidget 
      v-else-if="widgetConfig?.widgetType === 'pointwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <DataGridWidget 
      v-else-if="widgetConfig?.widgetType === 'datagridwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <DataRawWidget
      v-else-if="widgetConfig?.widgetType === 'rawdatawidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <DataLeaderboardWidget
      v-else-if="widgetConfig?.widgetType === 'leaderboardwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <BasicChartWidget 
      v-else-if="widgetConfig?.widgetType === 'dashboardwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <ConditionDurationWidget 
      v-else-if="widgetConfig?.widgetType === 'conditiondurationwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <PieWidget
      v-else-if="widgetConfig?.widgetType === 'piechartwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <ActivityGaugeWidget
      v-else-if="widgetConfig?.widgetType === 'activitygaugewidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <ImageFreeFormWidget
      v-else-if="widgetConfig?.widgetType === 'imagefreeformwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
      :dashboardId="dashboardId"
    />
    <ColumnRangeWidget
      v-else-if="widgetConfig?.widgetType === 'columnrangewidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <StackedChartWidget
      v-else-if="widgetConfig?.widgetType === 'stackedchartwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <FunnelWidget
      v-else-if="widgetConfig?.widgetType === 'funnelwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <HeatmapWidget
      v-else-if="widgetConfig?.widgetType === 'heatmap'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <GaugeWidget
      v-else-if="widgetConfig?.widgetType === 'radialgaugehc'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <StringChartWidget 
      v-else-if="widgetConfig?.widgetType === 'stringchart'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <BaselineChartWidget
      v-else-if="widgetConfig?.widgetType === 'baselinechart'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <PeakDemandWidget
      v-else-if="widgetConfig?.widgetType === 'peakdemand'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <TariffSimulatorWidget 
      v-else-if="widgetConfig?.widgetType === 'tariffsimulator'"
      :widgetConfig="widgetConfig"
      :widget="widget"
      :dashboardId="dashboardId"
    />
    <TariffCalculatorWidget 
      v-else-if="widgetConfig?.widgetType === 'tariffcalculator'"
      :widgetConfig="widgetConfig"
      :widget="widget"
      :dashboardId="dashboardId"
    />
    <AlarmWidget 
      v-else-if="widgetConfig?.widgetType === 'alarm'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <ReportingWidget 
      v-else-if="widgetConfig?.widgetType === 'reporting'"
      :widgetConfig="widgetConfig"
      :widget="widget"
      :dashboardId="dashboardId"
    />
    <FileAttachmentWidget 
      v-else-if="widgetConfig?.widgetType === 'fileattachment'"
      :widgetConfig="widgetConfig"
    />
    <TemperatureWidget
      v-else-if="widgetConfig?.widgetType === 'temperature'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <HumidityWidget
      v-else-if="widgetConfig?.widgetType === 'humidity'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <PowerUsedGeneratedWidget
      v-else-if="widgetConfig?.widgetType === 'powerusedgenerated'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <PeriodCompareWidget
      v-else-if="widgetConfig?.widgetType === 'powerusage'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <SolarGeneratedWidget
      v-else-if="widgetConfig?.widgetType === 'solargenerated'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <MQTTNumericControlWidget
      v-else-if="widgetConfig?.widgetType === 'mqttnumericcontrolwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <MQTTBooleanControlWidget
      v-else-if="widgetConfig?.widgetType === 'mqttbooleancontrolwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <MQTTStringControlWidget
      v-else-if="widgetConfig?.widgetType === 'mqttstringcontrolwidget'"
      :widgetConfig="widgetConfig"
      :widget="widget"
    />
    <div v-else-if="!widgetConfig">
      widget is missing
    </div>
    <div v-else-if="!widgetDescription">
      <b>{{widgetConfig?.widgetType}}</b> widget is deprecated
    </div>
    <div v-else>
      <b>{{widgetConfig?.widgetType}}</b> widget is not supported yet
    </div>
  </div>
</template>

<script lang="ts">
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import { Component, Prop, Vue } from "vue-facing-decorator";
import DashboardState from "@/store/states/DashboardState";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import TitleWidget from "@/components/widgets-next/TitleWidget.vue";
import TextBoxWidget from "@/components/widgets-next/TextBoxWidget.vue";
import WebsiteWidget from "@/components/widgets-next/WebsiteWidget.vue";
import ImageWidget from "@/components/widgets-next/ImageWidget.vue";
import AIButtonWidget from "@/components/widgets-next/AIButtonWidget.vue";
import MapWidget from "@/components/widgets-next/MapWidget.vue";
import NotificationsStatusWidget from "@/components/widgets-next/NotificationsStatusWidget.vue";
import WeatherWidget from "@/components/widgets-next/WeatherWidget.vue";
import WeatherForecastWidget from "@/components/widgets-next/WeatherForecastWidget.vue";
import DigitalClockWidget from "@/components/widgets-next/DigitalClockWidget.vue";
import AnalogClockWidget from "@/components/widgets-next/AnalogClockWidget.vue";
import DateWidget from "@/components/widgets-next/DateWidget.vue";
import DataPointWidget from "@/components/widgets-next/DataPointWidget.vue";
import DataGridWidget from "@/components/widgets-next/DataGridWidget.vue";
import DataRawWidget from "@/components/widgets-next/DataRawWidget.vue";
import DataLeaderboardWidget from "@/components/widgets-next/DataLeaderboardWidget.vue";
import BasicChartWidget from "@/components/widgets-next/BasicChartWidget.vue";
import ConditionDurationWidget from "@/components/widgets-next/ConditionDurationWidget.vue";
import PieWidget from "@/components/widgets-next/PieWidget.vue";
import ActivityGaugeWidget from "@/components/widgets-next/ActivityGaugeWidget.vue";
import ImageFreeFormWidget from "@/components/widgets-next/ImageFreeFormWidget.vue";
import ColumnRangeWidget from "@/components/widgets-next/ColumnRangeWidget.vue";
import StackedChartWidget from "@/components/widgets-next/StackedChartWidget.vue";
import FunnelWidget from "@/components/widgets-next/FunnelWidget.vue";
import HeatmapWidget from "@/components/widgets-next/HeatmapWidget.vue";
import GaugeWidget from "@/components/widgets-next/GaugeWidget.vue";
import StringChartWidget from "@/components/widgets-next/StringChartWidget.vue";
import BaselineChartWidget from "@/components/widgets-next/BaselineChartWidget.vue";
import PeakDemandWidget from "@/components/widgets-next/PeakDemandWidget.vue";
import TariffSimulatorWidget from "@/components/widgets-next/TariffSimulatorWidget.vue";
import TariffCalculatorWidget from "@/components/widgets-next/TariffCalculatorWidget.vue";
import AlarmWidget from "@/components/widgets-next/AlarmWidget.vue";
import ReportingWidget from "@/components/widgets-next/ReportingWidget.vue";
import FileAttachmentWidget from "@/components/widgets-next/FileAttachmentWidget.vue";
import TemperatureWidget from "@/components/widgets-next/TemperatureWidget.vue";
import HumidityWidget from "@/components/widgets-next/HumidityWidget.vue";
import PowerUsedGeneratedWidget from "@/components/widgets-next/PowerUsedGeneratedWidget.vue";
import PeriodCompareWidget from "@/components/widgets-next/PeriodCompareWidget.vue";
import SolarGeneratedWidget from "@/components/widgets-next/SolarGeneratedWidget.vue";
import LimitTrackerWidget from "@/components/widgets-next/LimitTrackerWidget.vue";
import MQTTNumericControlWidget from "@/components/widgets-next/MQTTNumericControlWidget.vue";
import MQTTStringControlWidget from "@/components/widgets-next/MQTTStringControlWidget.vue";
import MQTTBooleanControlWidget from "@/components/widgets-next/MQTTBooleanControlWidget.vue";
import WidgetHelper from "@/helpers/WidgetHelper";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import { BasicWidgetSettings } from "@/models/dashboard/BasicWidgetSettings";
import { WidgetFeature } from "@/models/enums/WidgetFeature";
import DashboardWidgetDateRangeView from "./DashboardWidgetDateRangeView.vue";
import { AdvancedWidgetSettings } from "@/models/dashboard/AdvancedWidgetSettings";
import WidgetDataState from "@/store/states/WidgetDataState";
import Button from 'primevue/button';
import ToastService from "@/services/ToastService";
import copy from "copy-to-clipboard";

@Component({
  components: {
    TitleWidget,
    TextBoxWidget,
    WebsiteWidget,
    ImageWidget,
    AIButtonWidget,
    LimitTrackerWidget,
    MapWidget,
    NotificationsStatusWidget,
    WeatherWidget,
    WeatherForecastWidget,
    DigitalClockWidget,
    AnalogClockWidget,
    DateWidget,
    DataPointWidget,
    DataGridWidget,
    DataRawWidget,
    DataLeaderboardWidget,
    BasicChartWidget,
    ConditionDurationWidget,
    PieWidget,
    ActivityGaugeWidget,
    ImageFreeFormWidget,
    ColumnRangeWidget,
    StackedChartWidget,
    FunnelWidget,
    HeatmapWidget,
    GaugeWidget,
    StringChartWidget,
    BaselineChartWidget,
    PeakDemandWidget,
    TariffSimulatorWidget,
    TariffCalculatorWidget,
    AlarmWidget,
    ReportingWidget,
    FileAttachmentWidget,
    TemperatureWidget,
    HumidityWidget,
    PowerUsedGeneratedWidget,
    PeriodCompareWidget,
    SolarGeneratedWidget,
    MQTTNumericControlWidget,
    MQTTStringControlWidget,
    MQTTBooleanControlWidget,
    DashboardWidgetDateRangeView,
    Button
  },
})
class DashboardWidgetView extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) dashboardId!: string;
  @Prop({ required: true }) openBitpoolAIDialog!: (widget: SpaceWidgetConfig) => void;

  widgetFeatures = WidgetFeature;
  
  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetConfig(): WidgetConfig | null | undefined {
    return this.dashboardState.widgets?.find(x => x.guid === this.widget.guid);
  }

  get dataError(): string | null | undefined {
    return this.widgetDataState.error[this.widget.guid];
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig?.widgetOptions.advancedWidgetSettings;
  }

  get bws(): BasicWidgetSettings | undefined {
    return this.widgetConfig?.widgetOptions.basicWidgetSettings;
  }

  get widgetDescription(): WidgetDescription | undefined {
    return this.widgetConfig && this.widgetConfig.widgetType ? WidgetHelper.getWidget(this.widgetConfig.widgetType) : undefined;
  }

  copyError(): void {
    if (this.dataError) {
      copy(this.dataError);
      ToastService.showToast("success", "", "Copied!", 5000);
    }
  }
}

export default DashboardWidgetView;
</script>
