<template>
  <div class="he-tree-search">
    <InputText
      :placeholder="placeholder"
      type="text"
      v-model="search"
      @input="debounceSearch()"
    />
    <span class="pi pi-search"></span>
  </div>
  <div class="he-tree-wrapper">
    <BaseTree 
      v-model="nodes" 
      :defaultOpen="false" 
      :statHandler="statHandler"
      textKey="label"
      :indent="22"
      :virtualizationPrerenderCount="50" 
      :watermark="false" 
      ref="tree"
      virtualization
    >
      <template #default="{ node, stat }">
        <Button 
            :icon="stat.open ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" 
            text 
            rounded
            @click="toggleNode(stat)" v-if="node.children?.length"
            class="p-link"
        />
        <div :class="{ 'tree-node-selected': node.key === selectedId, 'tree-node-selectable': node.selectable, 'tree-node-without-children': !node.children?.length }">
          <span  
            @click="itemSelected(node)"
            class="flex align-items-center"
          >
            <span v-if="node.icon" :class="node.icon" class="tree-node-icon"></span>
            <span class="tree-node-label">{{ node.label }}</span>
          </span>
        </div>
      </template>
    </BaseTree>
  </div>
</template>

<script lang="ts">
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import { Component, Prop, Vue } from "vue-facing-decorator";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { BaseTree } from '@he-tree/vue';
import { Watch } from 'vue-facing-decorator';
import { Stat } from '@he-tree/tree-utils';
import { debounce } from 'throttle-debounce';
import TreeHelper from '@/helpers/TreeHelper';

@Component({
  components: {
    OverlayPanel,
    InputText,
    Button,
    BaseTree
  },
})
class TreeSelectViewNoDropDown extends Vue {
  @Prop({ required: true }) selectedId!: string;
  @Prop({ required: true }) nodes!: TreeNodeForUI[];
  @Prop({ required: true }) changeSelectedId!: (node: TreeNodeForUI) => void;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ required: false, default: false }) openRootNodes!: boolean;

  selectedNode: TreeNodeForUI | undefined;

  model: Record<string, boolean> = {};
  expandedKeys: Record<string, boolean> = {};

  created(): void {
    if (this.selectedId) {
      this.model[this.selectedId] = true;
      this.selectedNode = this.findNodeById(this.nodes, this.selectedId);
    }
  }

  findNodeById(nodes: TreeNodeForUI[], id: string): TreeNodeForUI | undefined {
    let result = nodes.find(x => x.key === id);
    if (!result) {
      for (let index = 0; index < nodes.length; index++) {
        const element = nodes[index];
        if (element.children) {
          result = this.findNodeById(element.children, id);
          if (result) {
            if (element.key) {
              this.expandedKeys[element.key] = true;
            }
            break;
          }
        }
      }
    }
    return result;
  }

  minWidth = "420px";

  toggleOverlay(event: Event): void {
    if (this.$refs.treeOverlayPanel) {
      if (this.$refs.treeOverlayToggle) {
        this.minWidth = (this.$refs.treeOverlayToggle as HTMLElement).getBoundingClientRect().width + "px";
      } else {
        this.minWidth = "420px";
      }
      (this.$refs.treeOverlayPanel as OverlayPanel).toggle(event);
    }
  }

  isInternalChange = false;

  @Watch('selectedId', { immediate: false, deep: false })
  async onSelectedIdChanged(val: string, oldVal: string): Promise<void> {
    if (this.isInternalChange) {
      this.isInternalChange = false;
    } else {
      delete this.model[oldVal];
      this.model[val] = true;
      this.selectedNode = this.findNodeById(this.nodes, this.selectedId);
    }
  }

  itemSelected(node: TreeNodeForUI): void {
    if (node.selectable) {
      this.isInternalChange = true;
      this.selectedNode = node;
      this.changeSelectedId(node);
      if (this.$refs.treeOverlayPanel) {
        (this.$refs.treeOverlayPanel as OverlayPanel).hide();
      }
    }
  }

  getTree(): any {
    return (this.$refs.tree as any);
  }

  statHandler(node: Stat<TreeNodeForUI>): Stat<TreeNodeForUI> {
    if (node.data.key && this.expandedKeys[node.data.key]
      || this.openRootNodes && !node.parent) {
      node.open = true;
    }
    if (node.data.children?.length) {
      node.class = "has-children";
    }
    return node;
  }

  toggleNode(node: Stat<TreeNodeForUI>): void {
    node.open = !node.open;
    if (node.data.key) {
      this.expandedKeys[node.data.key] = node.open;
    }
  }

  search = '';
  searchFinal = '';
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
    const nodes = this.getTree().statsFlat as Stat<TreeNodeForUI>[];
    TreeHelper.search(nodes, this.searchFinal);
  }
}

export default TreeSelectViewNoDropDown;
</script>
