<template>
  <div ref="outputElement" :class="{ 'blinking-cursor': typingInProgress && timeoutEnabled }" @click="onClickEvent">
    <template v-for="(item, index) in outputContent" :key="index">
      <div v-if="item[0]" v-html="item[0]"></div>
      <div v-else-if="item[1]">
        <highcharts :ref="`chartElement${index}`" class="chart-element" :options="item[1]"></highcharts>
        <Button 
          v-if="item[1].customZoom" 
          @click="resetZoom(index)" 
          icon="pi pi-search-minus text-xl font-bold" 
          v-tippy="'Reset Zoom'" 
          class="p-button-icon-only p-button-rounded p-button-light chart-zoom-out"/>
      </div>
      <div v-else-if="item[3]">
        <LinkPreviewView v-model="item[3]"/>
      </div>
    </template>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import JSON5 from "json5";
import { ChatGPTHistory } from "@/models/bitpool-ai/ChatGPTHistory";
import Button from 'primevue/button';
import LinkPreviewView from "@/components/views/LinkPreviewView.vue";

@Component({
  components: {
    highcharts: Chart,
    Button,
    LinkPreviewView
  },
})
class TypingTextView extends Vue {
  @Prop({ required: true }) message!: ChatGPTHistory;
  @Prop({ required: false, default: "" }) scrollToParent!: string;
  timeoutEnabled = true;
  shouldStop = false;
  typingInProgress = true;
  // text, chart parameters, html element, preview link
  outputContent: [string, any, HTMLElement | undefined, string | undefined][] = [];

  mounted(): void {
    if (!this.message.enableTyping) {
      this.timeoutEnabled = false;
    }
    this.start();
  }

  unmounted(): void {
    this.stop();
  }

  start(): void {
    const parser = new DOMParser();
    const document = parser.parseFromString(this.message.message, "text/html");
    this.outputContent = [];
    this.scrollToBottom(true);
    this.iterate(document.body.childNodes, undefined, true);
  }

  stop(): void {
    this.timeoutEnabled = false;
    this.shouldStop = true;
    this.typingInProgress = false;
    this.message.enableTyping = false;
  }

  /**
   * Asynchronously iterates through the nodes and appends their content to the destination element.
   * @param nodes - The list of nodes to iterate through.
   * @param destinationElement - The element where the content will be appended.
   * @param root - Indicates if it's the root iteration.
   */
  async iterate(nodes: NodeList, destinationElement: HTMLElement | undefined, root: boolean): Promise<void> {
    for (const child of nodes) {
      if (this.shouldStop) {
        return;
      }
      const html = child as HTMLElement;
      if (html.tagName) {
        if (root && html.tagName === "PRE" && html.firstElementChild?.tagName === "CODE" && html.firstElementChild.classList.contains("ai-embed-highcharts")) {
          try {
            const parameters = this.parseChartParameters(html.firstElementChild as HTMLElement, this.outputContent.length);
            parameters.customZoom = false;
            this.outputContent.push(["", parameters, undefined, undefined]);
            await this.timeout(100);
            this.scrollToBottom(true);
          } catch {
            // Ignore invalid chart
          }
        } else if (html.classList.contains("preview-link-embed-content")) {
          const link = html.innerHTML.trim();
          if (link) {
            this.outputContent.push(["", undefined, undefined, link]);
            await this.timeout(100);
            this.scrollToBottom(true);
          }
        } else {
          const cloned = html.cloneNode(true) as HTMLElement;
          
          switch (html.tagName) {
            case "TABLE":
            case "IFRAME":
            case "IMG": {
              // Append cloned element to outputContent if root, otherwise append to destinationElement
              if (root) {
                this.outputContent.push(["", undefined, cloned, undefined]);
              } else if (destinationElement) {
                destinationElement.appendChild(cloned);
              }
              // Update outputContent with outerHTML and scroll to bottom
              this.outputContent[this.outputContent.length - 1][0] = this.outputContent[this.outputContent.length - 1][2]?.outerHTML ?? "";
              this.scrollToBottom();
              break;
            }
            default: {
              // Clear innerHTML if it has content and it's not a text node
              if (cloned.innerHTML && html.childNodes.length) {
                cloned.innerHTML = "";
              }
              // Append cloned element to outputContent if root, otherwise append to destinationElement
              if (root) {
                this.outputContent.push([cloned.outerHTML, undefined, cloned, undefined]);
              } else if (destinationElement) {
                destinationElement.appendChild(cloned);
              }
              // Iterate child nodes and update outputContent with outerHTML, then scroll to bottom
              if (html.childNodes.length) {
                await this.iterate(html.childNodes, cloned, false);
              }
              this.outputContent[this.outputContent.length - 1][0] = this.outputContent[this.outputContent.length - 1][2]?.outerHTML ?? "";
              this.scrollToBottom();
              break;
            }
          }
        }
      } else {
        // Process the text content
        if (root && (!this.outputContent.length || this.outputContent[this.outputContent.length - 1][1])) {
          // Create new paragraph element and append to outputContent if root, otherwise append empty element
          if (root) {
            const parser = new DOMParser();
            const document = parser.parseFromString("<p></p>", "text/html");
            this.outputContent.push(["", undefined, document.body.childNodes[0] as HTMLElement, undefined]);
          } else {
            this.outputContent.push(["", undefined, undefined, undefined]);
          }
        }
        // Split text content into words and append each word to destination element or outputContent
        const text = html.textContent;
        if (text) {
          const oc = this.outputContent[this.outputContent.length - 1];
          const words = text.split(" ");
          for (let index = 0; index < words.length; index++) {
            if (destinationElement) {
              destinationElement.innerHTML += ` ${words[index]}`;
            } else if (oc[2]) {
              oc[2].innerHTML += ` ${words[index]}`;
            }
            if (oc[2]) {
              oc[0] = oc[2].outerHTML;
            }
            this.scrollToBottom();
            await this.timeout(70);
            if (this.shouldStop) {
              return;
            }
          }
        }
      }
    }
    if (root) {
      this.typingInProgress = false;
      this.message.enableTyping = false;
    }
  }

  scrollToBottom(fast = false): void {
    if (this.$refs.outputElement && this.timeoutEnabled) {
      const element = this.$refs.outputElement as HTMLElement;
      let scrollToElement: HTMLElement;
      if (this.scrollToParent) {
        const parent = element.closest(this.scrollToParent);
        if (parent) {
          scrollToElement = parent as HTMLElement;
        } else {
          scrollToElement = element;
        }
      } else {
        scrollToElement = element;
      }
      scrollToElement.scrollIntoView({block: "end", inline: "nearest", behavior: fast ? "auto" : "smooth"});
    }
  }

  timeout(ms: number) {
    if (this.timeoutEnabled) {
      return new Promise(resolve => window.setTimeout(resolve, ms));
    } else {
      return Promise.resolve();
    }
  }

  parseChartParameters(codeHtml: HTMLElement, index: number): any {
    const json = codeHtml.innerHTML.trim();
    const parameters = JSON5.parse(json);
    parameters.credits = {
      enabled: false
    };
    parameters.chart.events = {
      load() {
        window.setTimeout(this.reflow.bind(this)); 
      },
      selection: (event: Highcharts.SelectEventObject): (boolean | undefined) => {
        if (event.resetSelection) {
          this.outputContent[index][1].customZoom = false;
        } else {
          this.outputContent[index][1].customZoom = true;
        }
        return true;
      }
    };
    parameters.chart.animation = false;
    parameters.chart.zooming = {
      type: 'x'
    };
    // fix bad ai tooltip formatting
    parameters.tooltip = {
      useHTML: false,
      outside: false
    };
    // fix bad ai plot formatting
    if (parameters.plotOptions) {
      for (const plotOption of Object.keys(parameters.plotOptions)) {
        if (parameters.plotOptions[plotOption].dataLabels) {
          parameters.plotOptions[plotOption].dataLabels = {
            enabled: parameters.plotOptions[plotOption].dataLabels.enabled ?? false
          };
        }
      }
    }
    return parameters;
  }

  async onClickEvent(): Promise<void> {
    this.timeoutEnabled = false;
  }

  @Watch('message.enableTyping', { immediate: false, deep: false })
  onMessageEnableTypingChanged(): void {
    this.timeoutEnabled = false;
  }

  getChartElement(index: number): any | null {
    const ref = `chartElement${index}`;
    if (this.$refs[ref]) {
      return this.$refs[ref] as any;
    } else {
      return null;
    }
  }

  resetZoom(index: number): void {
    const chart = this.getChartElement(index)
    if (chart && chart.length && chart[0].chart) {
      chart[0].chart.zoomOut();
    }
  }
}

export default TypingTextView;
</script>