<template>
  <div ref="htmlPreview">
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import ReportHtmlHelper from "@/helpers/ReportHtmlHelper";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { debounce } from "throttle-debounce";
import { nextTick } from "vue";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import { useReports3Store } from "@/stores/reports3";
import Highcharts from 'highcharts';
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import { useOrganisationStore } from "@/stores/organisation";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";

@Component({
  components: {
  },
  directives: {
  }
})
class ReportsHtmlElementView extends Vue { 
  @Prop({ required: true }) element!: Reports3ElementEntity;
  @Prop({ required: false, default: null }) configuration!: Reports3ElementConfiguration | null;

  reports3 = useReports3Store();
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  @Watch('element', { immediate: false, deep: true })
  @Watch('configuration', { immediate: false, deep: true })
  async onElementChanged(val: string, oldVal: string): Promise<void> {
    this.debouncePreview();
  }

  debouncePreview = debounce(1250, this.generatePreview);

  mounted() {
    this.generatePreview();
  }

  shadowRoot: ShadowRoot | null = null;

  async generatePreview(): Promise<void> {
    if (this.element) {
      const htmlPreview = this.$refs.htmlPreview as HTMLElement;
      if (htmlPreview) {
        if (!this.shadowRoot) {
          const shadowRoot = htmlPreview.attachShadow({ mode: 'open' }); // open, closed
          this.shadowRoot = shadowRoot;
        }
        this.shadowRoot.innerHTML = '';
        const html = ReportHtmlHelper.generateShadowPreview(
          this.element, 
          this.configuration, 
          this.reports3.cssHtml ?? "",
          this.currentOrganisation?.Name ?? 'Organisation',
          this.reports3.dataOne?.Name ?? 'Report'
        );
        const htmlElement = this.createElementFromHTML(html[0])
        this.shadowRoot.appendChild(htmlElement);
        if (html[1]) {
          await nextTick();
          try {
            const script = html[1];
            const func = new Function("shadowRoot", "libraries", script);
            const libraries = {
              Highcharts: Highcharts
            }
            func(this.shadowRoot, libraries);
          } catch (error) {
            const errorMessage = ErrorHelper.handleAxiosError(error).message;
            ToastService.showToast(
              "error",
              "Report Element Error",
              errorMessage,
              5000
            );
          }
        }
        if (this.element.Role !== Reports3ElementRole.Body) {
          await nextTick();
          const pageNumber = this.shadowRoot?.querySelector(".pageNumber") as HTMLElement;
          if (pageNumber) {
            pageNumber.innerHTML = "1";
          }
          const totalPages = this.shadowRoot?.querySelector(".totalPages") as HTMLElement;
          if (totalPages) {
            totalPages.innerHTML = "1";
          }
        }
      }      
    }
  }

  createElementFromHTML(htmlString: string): DocumentFragment {
    var template = document.createElement('template');
    template.innerHTML = htmlString;
    return template.content;
  }
}

export default ReportsHtmlElementView;
</script>