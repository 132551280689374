<template>
  <div>
    <div class="pools-streams-head-section streams">
      <div>
        <Breadcrumb 
          :home="breadcrumbHome" 
          :model="breadcrumbItems" 
          class="mb-0 lg:mr-5 page-breadcrumb"
        >
          <template #separator>
            <span class="pi pi-chevron-right" aria-hidden="true"></span>
          </template>
        </Breadcrumb>
        <span class="pools-streams-count">
          <span v-if="totalRecordsSelected > 0">Selected: {{totalRecordsSelected}} / </span><span>Total: {{totalRecords}}</span>
        </span>
      </div>

      <IconField iconPosition="left" class="search-input-box mt-4 lg:mt-0">
        <InputIcon class="pi pi-search lg:text-2xl"></InputIcon>
        <InputText
          class="inputfield text-lg lg:text-xl"
          placeholder="Search"
          type="text"
          v-model="search"
          @input="debounceSearch()"
        />
      </IconField>
    </div>
    <DataView :value="streams" dataKey="Id" layout="list" :paginator="true" :rows="20" :lazy="true" @page="onPage($event)" :totalRecords="totalRecords" class="pools-streams-view streams" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown" :rowsPerPageOptions="[10,20,50]" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}">
      <template #header>
        <div class="pools-streams-view-header-item mt-5 mb-4 lg:mb-5">
          <Button type="button" label="Add" @click="toggleAddMenu" icon="pi pi-plus-circle text-lg lg:text-2xl" class="p-button-raised text-lg font-light rounded-border-radius pools-streams-control-btn" aria-haspopup="true" aria-controls="overlay_add_menu"/>
          <Menu id="overlay_add_menu" ref="overlay_add_menu" :model="addMenuItems" :popup="true" />
          <Dropdown v-model="sortKey" :options="sortOptions" optionLabel="label" placeholder="Sort By" @change="onSortChange($event)" class="dropdown-lg box-shadow-container rounded-border-radius"/>
          <Button @click="selectAll" label="Select All" class="p-button-raised p-button-text p-button-secondary text-lg font-light rounded-border-radius pools-streams-control-btn"/>
          <Button v-if="totalRecordsSelected > 0" @click="selectNone" label="Deselect" class="p-button-raised p-button-text p-button-secondary text-lg font-light rounded-border-radius pools-streams-control-btn"/>
          <Button v-if="totalRecordsSelected > 0" @click="openConfirmation" label="Remove" class="p-button-raised p-button-text p-button-danger text-lg font-light rounded-border-radius pools-streams-control-btn"/>
        </div>
      </template>
      <template #list="slotProps">
        <div class="grid grid-nogutter">
          <div v-for="(item, index) in slotProps.items" :key="index" class="col-12">
            <StreamView :poolKey="poolKey" :stream="item" />
          </div>
        </div>
      </template>
      <template #empty>
        <div v-if="isLoadedPage">
          No streams found.
        </div>
        <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
    </DataView>

    <Dialog header="Delete Streams" v-model:visible="displayConfirmation" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div v-if="deleteStreamsInProgress">
          <ProgressBar :value="deleteStreamsProgress" :showValue="false" :mode="deleteStreamsProgressMode" />
        </div>
        <div class="flex align-items-center" v-else>
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete {{totalRecordsSelected}} streams?</span>
        </div>
      </div>
      <template #footer>
        <Button v-if="!deleteStreamsInProgress" label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary"/>
        <Button v-if="!deleteStreamsInProgress" label="Yes" icon="pi pi-check" @click="deleteStreams" />
        <Button v-if="!deleteStreamsInProgress && isVirtualPool" label="Yes, only from virtual pool" icon="pi pi-check" @click="deleteStreamsFromVirtualPool" />
      </template>
    </Dialog>

    <Dialog header="Tags for Streams" v-model:visible="displayTagsForStreams" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div v-if="tagsForStreamsInProgress">
          <ProgressBar :showValue="false" mode="indeterminate" />
        </div>
        <div v-else class="field mb-0">
          <label for="">You can enter one or several tags separated by ";"</label>
          <div>
            <InputText
              class="inputfield p-inputtext p-inputtext-lg w-full"
              placeholder="Tag"
              type="text"
              v-model="tagsEdit"
            />
          </div>
          <div class="text-bluegray-500 text-sm mt-2">Example: tag1;tag2;tag3</div>
        </div>
      </div>
      <template #footer>
        <Button v-if="!tagsForStreamsInProgress" label="Cancel" icon="pi pi-times" @click="closeTagsForStreams" class="p-button-text p-button-secondary"/>
        <Button v-if="!tagsForStreamsInProgress" :disabled="!tagsEdit" label="Add" icon="pi pi-check" @click="changeTagsForStreams(true)" />
        <Button v-if="!tagsForStreamsInProgress" :disabled="!tagsEdit" label="Delete" icon="pi pi-trash" @click="changeTagsForStreams(false)" class="p-button-danger" />
      </template>
    </Dialog>
    
    <Dialog header="Add Streams to Virtual Pool" v-model:visible="displayStreamsForVirtualPool" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div v-if="inProgressAddStreamToVirtualPool">
          <ProgressBar :showValue="false" mode="indeterminate" />
        </div>
        <div v-else>
          <div class="field mb-0">
            <label for="streamsForVirtualPool">Select Pool</label>
            <div>
              <Dropdown if="streamsForVirtualPool" v-model="selectedPoolForVirtualPoolStreams" @change="onSelectPoolForVirtualPoolStreams" :options="poolsForVirtualPoolStreams" optionLabel="Name" placeholder="Select pool" :filter="true" filterPlaceholder="Find pool" class="w-full dropdown-lg"/>
            </div>
          </div>
          <div v-if="selectedPoolForVirtualPoolStreams" class="field mt-3 pt-1">
            <label for="">Select Streams</label>
            <DataTable :value="streamsForVirtualPoolStreams" :paginator="true" :rows="10" v-model:filters="filtersVirtualPool" filterDisplay="menu" :globalFilterFields="['Name']" v-model:selection="newStreamsForVirtualPool" dataKey="Id" responsiveLayout="scroll" class="streams-virtual-pool-stream light-highlight">
              <template #header>
                <div>
                  <IconField iconPosition="left">
                    <InputIcon class="pi pi-search" />
                    <InputText v-model="filtersVirtualPool['global'].value" placeholder="Find Stream" />
                  </IconField>
                </div>
              </template>
              <template #empty>
                <div v-if="isLoadedStreamsForVirtualStream" class="w-full" style="min-height: 30vh;">
                  <span class="inline-block py-2">No data found.</span>
                </div>
                <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 30vh;" v-else>
                  <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
                </div>
              </template>
              <Column selectionMode="multiple" style="width: 3rem" :exportable="false" class="justify-content-start"></Column>
              <Column field="Name" header="Name"></Column>
            </DataTable>
          </div>
        </div>
      </div>
      <template #footer>
        <Button v-if="!inProgressAddStreamToVirtualPool" label="Cancel" icon="pi pi-times" @click="closeAddStreams" class="p-button-text p-button-secondary"/>
        <Button v-if="!inProgressAddStreamToVirtualPool" :disabled="!newStreamsForVirtualPool.length" label="Add" icon="pi pi-check" @click="addStreamsToVirtualPool" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import DataView from 'primevue/dataview';
import Breadcrumb from 'primevue/breadcrumb';
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import ProgressBar from 'primevue/progressbar';
import Menu from 'primevue/menu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { debounce, throttle } from 'throttle-debounce';
import { StreamModel } from "@/models/StreamModel";
import StreamsSearchPageQuery from "@/models/StreamsSearchPageQuery";
import { Watch } from "vue-facing-decorator";
import StreamView from "./StreamView.vue";
import { PoolModelLite } from "@/models/PoolModelLite";
import { StreamModelLite } from "@/models/StreamModelLite";
import SignalRDataService from "@/services/signalR/SignalRDataService";
import { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";
import NavigationHelper from "@/helpers/NavigationHelper";

@Component({
  components: {
    DataView,
    Breadcrumb,
    Dropdown,
    ProgressSpinner,
    InputText,
    Button,
    Dialog,
    ProgressBar,
    StreamView,
    Menu,
    DataTable,
    Column,
    IconField,
    InputIcon
  },
})
class StreamsView extends Vue {  
  @Prop({ required: true }) poolKey!: string;

  @Watch('poolKey', { immediate: false, deep: false })
  onPoolChanged(val: string, oldVal: string): void {
    this.$store.commit("pool/unload", oldVal);
    this.refresh();
    this.$store.dispatch("pool/loadStreamsList", this.poolKey);
  }

  breadcrumbHome = {
    label: "Your Pools",
    url: "/data/pools",
    command: (event: MenuItemCommandEvent) => {
      if (!NavigationHelper.goTo("/data/pools")) {
        event.originalEvent.preventDefault();
      }
    }
  };

  get breadcrumbItems(): MenuItem[] {
    const state = this.$store.state.pool.poolStreamsDictionary[this.poolKey];
    const url = `/data/pools/${this.poolKey}`;
    return state && state.streamsList ? [
      { 
        label: state.streamsList.PoolName, 
        url: url,
        command: (event: MenuItemCommandEvent) => {
          if (!NavigationHelper.goTo(url)) {
            event.originalEvent.preventDefault();
          }
        }
      }
    ] : [];
  }

  toggleAddMenu(event: Event): void {
    if (this.$refs.overlay_add_menu) {
      (this.$refs.overlay_add_menu as Menu).toggle(event);
    }
  }

  get addMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    if (this.isLoadedPage) {
      if (this.isVirtualPool) {
        result.push({
          label: 'Streams',
          icon: undefined,
          command: () => {
            this.openAddStreams();
          }
        });
      }
    }
    result.push({
      label: 'Tags',
      icon: undefined,
      disabled: !this.selectedStreamKeys.length,
      command: () => {
        this.openTagsForStreams();
      }
    });
    return result;
  }

  search = '';
  searchFinal = '';
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  get streams(): StreamModel[] {
    const state = this.$store.state.pool.poolStreamsDictionary[this.poolKey];
    const streams = state && state.streamsPage && state.streamsPage.Streams ?
      state.streamsPage.Streams as StreamModel[] :
      [];
    return streams;
  }

  get isVirtualPool(): boolean {
    const state = this.$store.state.pool.poolStreamsDictionary[this.poolKey];
    const result = state && state.streamsPage && state.streamsPage.Virtual;
    return !!result;
  }

  get totalRecords(): number {
    const state = this.$store.state.pool.poolStreamsDictionary[this.poolKey];
    return state && state.streamsPage ? state.streamsPage.StreamsCount : 0;
  }

  get totalRecordsSelected(): number {
    return this.selectedStreamKeys.length;
  }

  get selectedStreamKeys(): string[] {
    const streamsState = this.$store.state.pool.poolStreamsDictionary[this.poolKey];
    return streamsState ? streamsState.selectedStreamKeys : [];
  }

  get isLoadedPage(): boolean {
    const state = this.$store.state.pool.poolStreamsDictionary[this.poolKey];
    return state && state.isLoadedPage;
  }

  sortOrder = 1;
  sortField = 'Name';
  sortOptions = [
    {label: 'Name: A-Z', value: 'Name'},
    {label: 'Name: Z-A', value: '!Name'},
    {label: 'Last Update: Fresh First', value: '!LastUpdates'},
    {label: 'Last Update: Old First', value: 'LastUpdates'},
    {label: 'Records: Ascending', value: 'Records'},
    {label: 'Records: Descending', value: '!Records'}
  ]
  sortKey: any = this.sortOptions[0];

  take = 20;
  skip = 0;

  created(): void {
    this.refresh();
    this.$store.dispatch("pool/loadStreamsList", this.poolKey);
    this.$store.dispatch("pools/loadPoolsList");
  }

  unmounted(): void {
    this.$store.commit("pool/unload", this.poolKey);
    if (this.selectedPoolForVirtualPoolStreams) {
      this.$store.commit("pool/unload", this.selectedPoolForVirtualPoolStreams.Id);
    }
    SignalRDataService.removeCallback(this.$store.state.apiUrl, "poolHub", this.signalRCallback);
  }

  @Watch('streams', { immediate: false, deep: false })
  onChangeStreams(val: StreamModel[], oldVal: StreamModel[]): void {
    SignalRDataService.unsubscribe(this.$store.state.apiUrl, "streamHub", oldVal.map(x => x.Id));
    SignalRDataService.subscribe(this.$store.state.apiUrl, "streamHub", val.map(x => x.Id), this.signalRCallback);
  }

  signalRKeys = new Set();

  signalRCallback(streamKey: string): void {
    this.signalRKeys.add(streamKey);
    this.signalRCallbackThrottle();
  }

  signalRCallbackThrottle = throttle(1000, this.onSignalRCallback, { noLeading: true });

  signalRCallbackActual(): void {
    this.signalRKeys.forEach(streamKey => {
      this.$store.dispatch("pool/refreshStream", { poolKey: this.poolKey, streamKey: streamKey });
    });
    this.signalRKeys.clear();
  }

  onSignalRCallback(): void {
    this.signalRCallbackActual();
  }

  buildQuery(): StreamsSearchPageQuery { 
    return {
      poolKey: this.poolKey,
      search: this.searchFinal,
      orderBy: this.sortField,
      desc: this.sortOrder === -1,
      take: this.take,
      skip: this.skip
    }
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  onInputChanged(): void {
    this.refresh();
  }

  refresh(): void {
    this.$store.dispatch("pool/loadStreamsPage", this.buildQuery());
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.skip = event.page * event.rows;
    this.take = event.rows;
    this.refresh();
  }

  onSortChange(event: any): void {
    const value = event.value.value;
    const sortValue = event.value;

    if (value.indexOf('!') === 0) {
      this.sortOrder = -1;
      this.sortField = value.substring(1, value.length);
      this.sortKey = sortValue;
    }
    else {
      this.sortOrder = 1;
      this.sortField = value;
      this.sortKey = sortValue;
    }
    this.refresh();
  }

  selectAll(): void {
    const streamKeys = this.streams.map((stream) => stream.Id);
    this.$store.commit("pool/selectManyStreams", { poolKey: this.poolKey, values: streamKeys });
  }

  selectNone(): void {
    this.$store.commit("pool/selectNoneStreams", this.poolKey);
  }

  // #region delete streams
  displayConfirmation = false;

  openConfirmation(): void {
    this.deleteStreamsInProgress = false;
    this.displayConfirmation = true;
  }

  closeConfirmation(): void {
    this.displayConfirmation = false;
  }

  deleteStreamsInProgress = false;
  deleteStreamsProgress = 0;
  deleteStreamsProgressMode: 'determinate' | 'indeterminate' | undefined = undefined;

  async deleteStreams(): Promise<void> {
    this.deleteStreamsProgressMode = "determinate";
    this.deleteStreamsProgress = 0;
    this.deleteStreamsInProgress = true;
    const keys = this.selectedStreamKeys;
    for (let i = 0; i < keys.length; i++) {
      this.deleteStreamsProgress = i * 100 / keys.length;
      const request = {
        poolKey: this.poolKey,
        streamKey: keys[i],
        reload: false
      };
      await this.$store.dispatch("pool/delete", request);
    }
    this.deleteStreamsProgress = 100;
    this.selectNone();
    this.refresh();
    this.closeConfirmation();
  }

  async deleteStreamsFromVirtualPool(): Promise<void> {
    this.deleteStreamsProgressMode = "indeterminate";
    this.deleteStreamsProgress = 0;
    this.deleteStreamsInProgress = true;
    const keys = this.selectedStreamKeys;
    const request = {
      poolKey: this.poolKey,
      body: keys
    };
    await this.$store.dispatch("pool/deleteFromVirtualPool", request);
    this.selectNone();
    this.closeConfirmation();
  }
  // #endregion delete streams

  // #region tags for streams
  displayTagsForStreams = false;
  tagsEdit = "";
  tagsForStreamsInProgress = false;

  openTagsForStreams(): void {
    this.tagsForStreamsInProgress = false;
    this.tagsEdit = "";
    this.displayTagsForStreams = true;
  }

  closeTagsForStreams(): void {
    this.displayTagsForStreams = false;
  }

  async changeTagsForStreams(add: boolean): Promise<void> {
    this.tagsForStreamsInProgress = true;
    const keys = this.selectedStreamKeys;
    const tags = this.tagsEdit.split(';');
    const request = {
      poolKey: this.poolKey,
      body: {
        StreamKeys: keys,
        TagsRemove: add ? [] : tags,
        TagsAdd: add ? tags : []
      }
    };
    await this.$store.dispatch("pool/changeTags", request);
    this.closeTagsForStreams();
  }
  // #endregion tags for streams
  
  // #region streams for virtual pool
  displayStreamsForVirtualPool = false;
  
  get inProgressAddStreamToVirtualPool(): boolean {
    const result = this.$store.state.pool.inProgressAddStreamToVirtualPool;
    return result;
  }
  
  get errorAddStreamToVirtualPool(): boolean {
    const result = this.$store.state.pool.errorAddStreamToVirtualPool;
    return result;
  }

  get poolsForVirtualPoolStreams(): PoolModelLite[] {
    const pools = this.$store.state.pools.poolsList && this.$store.state.pools.poolsList.Pools ?
      this.$store.state.pools.poolsList.Pools as PoolModelLite[] :
      [];
    const result = [...pools];
    for (let index = 0; index < result.length; index++) {
      const element = result[index];
      if (element.Id === this.poolKey) {
        result.splice(index, 1);
        break;
      }
    }
    return result;
  }

  get isLoadedStreamsForVirtualStream(): boolean {
    if (this.selectedPoolForVirtualPoolStreams) {
      const poolKey = this.selectedPoolForVirtualPoolStreams.Id;
      const state = this.$store.state.pool.poolStreamsDictionary[poolKey];
      return state && state.isLoadedList;
    } else {
      return false;
    }
  }

  get streamsForVirtualPoolStreams(): StreamModelLite[] {
    if (this.selectedPoolForVirtualPoolStreams) {
      const poolKey = this.selectedPoolForVirtualPoolStreams.Id;
      const state = this.$store.state.pool.poolStreamsDictionary[poolKey];
      return state && state.streamsList && state.streamsList.Streams ? state.streamsList.Streams : [];
    } else {
      return [];
    }
  }

  selectedPoolForVirtualPoolStreams: PoolModelLite | null = null;
  newStreamsForVirtualPool: StreamModelLite[] = [];
  filtersVirtualPool = {
    'global': {value: null, matchMode: 'contains'}
  };

  openAddStreams(): void {
    this.newStreamsForVirtualPool = [];
    this.selectedPoolForVirtualPoolStreams = null;
    this.previousPoolForVirtualPoolStreams = null;
    this.displayStreamsForVirtualPool = true;
  }

  closeAddStreams(): void {
    this.displayStreamsForVirtualPool = false;
  }

  previousPoolForVirtualPoolStreams: PoolModelLite | null = null;

  onSelectPoolForVirtualPoolStreams(): void {
    if (this.previousPoolForVirtualPoolStreams) {
      // unload pool streams
      this.$store.commit("pool/unload", this.previousPoolForVirtualPoolStreams.Id);
    }
    this.previousPoolForVirtualPoolStreams = this.selectedPoolForVirtualPoolStreams;
    if (this.selectedPoolForVirtualPoolStreams) {
      // load pool streams
      this.$store.dispatch("pool/loadStreamsList", this.selectedPoolForVirtualPoolStreams.Id);
    }
  }

  async addStreamsToVirtualPool(): Promise<void> {
    const request = {
      poolKey: this.poolKey,
      body: this.newStreamsForVirtualPool.map(x => x.Id)
    };
    await this.$store.dispatch("pool/addToVirtualPool", request);
    if (!this.errorAddStreamToVirtualPool) {
      this.closeAddStreams();
    }
  }
  // #endregion streams for virtual pool
}

export default StreamsView;
</script>