<template>
  <div class="organisation-profile-users-summary">
    <section>
      <div class="organisation-profile-users-summary-inner">
        <i><OrgUsersAdminMembersIconSvg/></i>
        <div>
          <span class="organisation-profile-users-count">{{ adminMembersCount }}</span>
          <h4>Admin Members</h4>
        </div>
      </div>
    </section>

    <section>
      <div class="organisation-profile-users-summary-inner">
        <i><OrgUsersActiveMembersIconSvg/></i>
        <div>
          <span class="organisation-profile-users-count">{{ activeMembersCount }}</span>
          <h4>Active Members</h4>
        </div>
      </div>
    </section>

    <section>
      <div class="organisation-profile-users-summary-inner">
        <i><OrgUsersGeneralMembersIconSvg/></i>
        <div>
          <span class="organisation-profile-users-count">{{ allMembersCount }}</span>
          <h4>Total Members</h4>
        </div>
      </div>
      <Button
        label="View all"
        icon="pi pi-external-link" 
        class="p-button-outlined px-5"
        @click="changeTab(1)"
      />
    </section>

    <section>
      <div class="organisation-profile-users-summary-inner">
        <i><OrgUsersGroupsIconSvg/></i>
        <div>
          <span class="organisation-profile-users-count">{{ groupsCount }}</span>
          <h4>Groups</h4>
        </div>
      </div>
      <Button
        label="View all"
        icon="pi pi-external-link" 
        class="p-button-outlined px-5"
        @click="changeTab(2)"
      />
    </section>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import { useOrganisationStore } from "@/stores/organisation";
import OrgUsersAdminMembersIconSvg from "@/components/svg/OrgUsersAdminMembersIconSvg.vue";
import OrgUsersActiveMembersIconSvg from "@/components/svg/OrgUsersActiveMembersIconSvg.vue";
import OrgUsersGeneralMembersIconSvg from "@/components/svg/OrgUsersGeneralMembersIconSvg.vue";
import OrgUsersGroupsIconSvg from "@/components/svg/OrgUsersGroupsIconSvg.vue";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { OrganisationRoleString } from "@/models/user/OrganisationRoleString";
import moment from "moment";
import { useOrganisationGroupsStore } from "@/stores/organisationGroups";
import { GroupFromDBDto } from "@/models/organisation/GroupFromDBDto";
import { UserFullInOrganisation2Dto } from "@/models/user/UserFullInOrganisation2Dto";

@Component({
  components: {
    Button,
    OrgUsersAdminMembersIconSvg,
    OrgUsersActiveMembersIconSvg,
    OrgUsersGeneralMembersIconSvg,
    OrgUsersGroupsIconSvg
  },
})
class OrganisationUsersSummaryView extends Vue {
  organisationStore = useOrganisationStore();
  organisationUsersStore = useOrganisationUsersStore();
  organisationGroupsStore = useOrganisationGroupsStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }
  
  updateInProgress = false;

  get members(): UserFullInOrganisation2Dto[] {
    const result = this.organisationUsersStore.data[this.organisationIdStr].entities ?? [];
    return result;
  }

  get adminMembersCount(): number {
    return this.members.filter(x => x.Type !== OrganisationRoleString.User).length;
  }

  get activeMembersCount(): number {
    const m = moment();
    const date = m.add(-2, "month").toDate();
    return this.members.filter(x => x.LastLogin && typeof x.LastLogin !== "string" && x.LastLogin > date).length;
  }

  get allMembersCount(): number {
    return this.members.length;
  }

  get groups(): GroupFromDBDto[] {
    return this.organisationGroupsStore.data[this.organisationIdStr].entities ?? [];
  }

  get groupsCount(): number {
    return this.groups.length;
  }

  changeTab(index: number) {
    this.organisationStore.organisationUserMangmentTabIndex = index;
  }
}

export default OrganisationUsersSummaryView;
</script>