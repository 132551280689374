class ToastService {
  private showToastFunction: ((severity: string, summary: string, detail: string, life: number) => void) | null = null;

  registerToast(func: (severity: string, summary: string, detail: string, life: number) => void) {
    this.showToastFunction = func;
  }

  // Example:
  // import ToastService from '../services/ToastService';
  // ToastService.showToast('info', 'Info Message', 'Message Content', 3000);
  showToast(severity: string, summary: string, detail: string, life: number) {
    if (this.showToastFunction) {
      switch (severity) {
        case "success":
        case "info": {
          console.info(`${summary} - ${detail}`);
          break;
        }
        case "warn": {
          console.warn(`${summary} - ${detail}`);
          break;
        }
        case "error": {
          console.error(`${summary} - ${detail}`);
          break;
        }
      }
      this.showToastFunction(severity, summary, detail, life);
    }
  }
}

export default new ToastService();