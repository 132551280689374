<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 36 36"><path fill="#fff" fill-rule="evenodd" d="M17.75 34.25C26.725 34.25 34 26.975 34 18S26.725 1.75 17.75 1.75 1.5 9.025 1.5 18s7.275 16.25 16.25 16.25Zm0-20a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5ZM15.25 23a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM24 26.75a3.75 3.75 0 1 0 0-7.5 3.75 3.75 0 0 0 0 7.5Z" clip-rule="evenodd"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class OrgUsersGroupsIconSvg extends Vue {
}

export default OrgUsersGroupsIconSvg;
</script>