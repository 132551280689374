export enum PeriodType {
  Custom,
  Today,
  Yesterday,
  ThisWeek,
  LastWeek,
  ThisMonth,
  LastMonth,
  ThisYear,
  LastYear,
  Quarter,
  LastQuarter,
  FinancialYear,
  LastFinancialYear
}