<template>
  <div ng-non-bindable>
    <ConfirmDialog :class="dialogClass"/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import ConfirmDialog from 'primevue/confirmdialog';
import CustomWindow from '../CustomWindow';
import { ConfirmationOptions } from 'primevue/confirmationoptions';
import ConfirmationService from '@/services/ConfirmationService';

declare const window: CustomWindow;

@Component({
  
  components: {
    ConfirmDialog
  }
})
class ConfirmationRoot extends Vue {
  dialogClass = "";

  mounted(): void {
    // for vue
    ConfirmationService.registerConfirmation(this.showConfirmation, this.closeConfirmation);
  }

  showConfirmation(options: ConfirmationOptions, dialogClass = ""): void {
    this.dialogClass = dialogClass;
    this.$confirm.require(options);
  }

  closeConfirmation(): void {
    this.$confirm.close();
    this.dialogClass = "";
  }
}

export default ConfirmationRoot;
</script>