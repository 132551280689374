<template>
  <div class="widget-type-digital-clock">
    <div class="digital-clock">
      {{time[0]}}<span>:</span>{{time[1]}}
    </div>
    <div class="digital-clock-timezone" v-if="timezone">
      {{timezone.DisplayNameTitle.replace("/", ", ")}}
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { TimeZoneDto } from '@/models/TimeZoneDto';
import moment from 'moment';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';


@Component({
  components: {
  }
})
class DigitalClockWidget extends Vue {
  @Prop({ required: true }) widgetConfig!: WidgetConfig;
  
  date = moment().utc();

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }

  get timezoneId(): string {
    const timeZoneId = this.aws && this.aws.timeZoneId ? this.aws.timeZoneId : "Brisbane";
    return timeZoneId;
  }

  get timezone(): TimeZoneDto | undefined {
    const timezone = this.timezones.find((tz) => tz.Id === this.timezoneId);
    return timezone;
  }

  // hour, minute
  get time(): [string, string] {
    const utcOffset = this.timezone ? this.timezone.UtcOffset : 0;
    const m = this.date.clone().add(utcOffset, "hours");
    const result: [string, string] = [m.format("HH"), m.format("mm")];
    return result;
  }

  interval: number | undefined = undefined;

  created() {
    this.interval = window.setInterval(() => {
      this.date = moment().utc();
    }, 1000);
  }

  unmounted() {
    clearInterval(this.interval)
  }
}

export default DigitalClockWidget;
</script>