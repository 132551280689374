<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="3" viewBox="0 0 12 3" fill="none" style="transform: scale(1.1);">
    <path fill="var(--report-control-button-color)" fill-rule="evenodd" d="M10.376 2.875a1.167 1.167 0 1 1 0-2.333 1.167 1.167 0 0 1 0 2.333Zm-4.316 0a1.167 1.167 0 1 1 0-2.333 1.167 1.167 0 0 1 0 2.333ZM.46 1.71a1.167 1.167 0 1 0 2.333 0 1.167 1.167 0 0 0-2.333 0Z" clip-rule="evenodd"/>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class ReportMoreActionsSvg extends Vue {
}

export default ReportMoreActionsSvg;
</script>