<template>
  <div ng-non-bindable class="mini-layout hide-nav-menu">
    <DebugView/>

    <div class="page with-header legal-page" :class="{ 'with-footer': displayButtons }">
      <!-- Page header -->
      <header class="page-header is-fixed no-print">
        <div>
          <a href="/">
            <img
              :src="'/assets/bitpool-logo-white.svg'"
              width="134"
              alt="Bitpool"
            />
          </a>
        </div>
      </header>
      
      <div class="page-container">
        <main>
          <div>
            <div class="default-view-container">
              <h1 class="mb-0">Legal Information</h1>
              <div class="default-view increase-padding-bottom mt-4 lg:mt-5"> 
                <TabView>
                  <TabPanel header="Terms and Conditions">
                    <TermsView class="default-view-body"/>
                  </TabPanel>
                  <TabPanel header="Privacy Policy">
                    <PrivacyView class="default-view-body"/>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          </div>
        </main>
      </div>

      <footer class="page-footer no-print" :class="{ 'with-bg': displayButtons }">
        <div class="legal-actions-btns gap-3 ml-auto" v-if="displayButtons">
          <Button label="Decline" class="p-button-outlined" @click="decline"/>
          <Button label="Accept" @click="accept"/>
        </div>
      </footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AngularOptions from "@/models/AngularOptions";
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DebugView from "@/components/views/DebugView.vue";
import Ripple from 'primevue/ripple';
import NavigationHelper from "@/helpers/NavigationHelper";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import CustomWindow from "@/CustomWindow";
import TermsView from "@/components/views/legal/TermsView.vue";
import PrivacyView from "@/components/views/legal/PrivacyView.vue";
import { usePageStore } from "@/stores/page";

declare const window: CustomWindow;

@Component({
  components: {
    Button,
    TabView,
    TabPanel,
    DebugView,
    TermsView,
    PrivacyView
  },
  directives: {
    'ripple': Ripple
  }
})
class LegalPage extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  get auth(): AuthState {
    return this.$store.state.auth;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get displayButtons(): boolean {
    return !!(this.auth.authKey && this.allUserData.userData && !this.allUserData.userData.TermsAccepted);
  }

  pageStore = usePageStore();

  created(): void {
    this.pageStore.$reset();
  }

  decline(): void {
    NavigationHelper.logout();
  }

  async accept(): Promise<void> {
    // save to db
    //this.allUserData.userData.TermsAccepted = true;
    window.angularUserprofile.getAllUserData().userData.TermsAccepted = true;
    await this.$store.dispatch("auth/saveUserAdditionalData");
    if (!this.auth.userAdditionalDataSaveError) {
      // redirect
      NavigationHelper.goTo("/")
    }
  }
}

export default LegalPage;
</script>
