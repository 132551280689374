<template>
  <div class="widget-type-infographic period-compare-container" :class="{ 'arrows-mode': aws?.widgetMode === 'arrows' || aws?.widgetMode === 'arrowsOnly', 'icons-mode': aws?.widgetMode === 'arrowsOnly' }">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="widget-type-infographic-inner" v-show="!isLodingData && !isNoData">

      <!-- Icons (ex arrows only) -->
      <div v-if="aws?.widgetMode === 'arrowsOnly'" class="period-compare-data">
        <div class="period-compare-icon">
          <span :style="`background-color: ${iconStateBackgroundColor} !important;`">
            <DataIconView v-if="aws" :iconType="aws.dataIcon" :iconColor="iconStateColor"/>
          </span>
        </div>
        <span class="infographic-legend-value">
          <i><b>{{ formatValue(valueCurrent, false, false) }}</b><span v-if="aws?.widgetUnit">{{ aws.widgetUnit }}</span></i>
          <span class="period-compare-last-diff-value" :style="`color: ${textStateColor}`">
            <svg v-if="diffPercent > 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 9"><path fill="currentColor" d="m9.1 0 8.314 9H.786L9.1 0Z"/></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 9"><path fill="currentColor" d="M9.1 9 .786 0h16.628L9.1 9Z"/></svg>
            <span><b>{{ Math.abs(diffPercent) }}%</b> by {{ formatValue(diffValue, true) }}</span>
          </span>
          <span class="period-compare-last-read" v-if="lastTimestamp && aws?.enableOptionalTimestamp">Last read at <b>{{ lastTimestampTimeStr }}</b> on <b>{{ lastTimestampDateStr }}</b></span>
        </span>
      </div>

      <!-- Arrows -->
      <div v-if="aws?.widgetMode === 'arrows'" class="period-compare-data">
        <div class="period-compare-arrow">
          <span v-if="diffPercent > 0" class="period-compare-arrow-up" :style="`background-color: ${iconStateBackgroundColor} !important;`">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26"><path :stroke="iconStateColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="M2 24 24 2m0 0H9.333M24 2v14.667"/></svg>
          </span>
          <span v-else class="period-compare-arrow-down" :style="`background-color: ${iconStateBackgroundColor} !important;`">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26"><path :stroke="iconStateColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="4" d="m2 2 22 22m0 0V9.333M24 24H9.333"/></svg>
          </span>
        </div>
        <span class="infographic-legend-value">
          <i><b>{{ Math.abs(diffPercent) }}</b><span>%</span></i>
          <span class="period-compare-last-diff-value" :style="`color: ${textStateColor}`">
            <svg v-if="diffPercent > 0" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 9"><path fill="currentColor" d="m9.1 0 8.314 9H.786L9.1 0Z"/></svg>
            <svg v-else xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 9"><path fill="currentColor" d="M9.1 9 .786 0h16.628L9.1 9Z"/></svg>
            <span><b>{{ formatValue(diffValue, true) }}</b></span>
          </span>
          <span class="period-compare-last-read" v-if="lastTimestamp && aws?.enableOptionalTimestamp">Last read at <b>{{ lastTimestampTimeStr }}</b> on <b>{{ lastTimestampDateStr }}</b></span>
        </span>
      </div>

      <!-- Icons and Arrows, This & Last Period -->
      <div v-if="(aws?.widgetMode === 'arrowsOnly' || aws?.widgetMode === 'arrows') && aws?.enableOptionalLastPeriodValue" class="period-compare-this-last-period w-full">
        <div>
          <div class="period-compare-this-period" v-if="aws?.widgetMode === 'arrows'">
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="21" height="20" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M19.5 10h-3.6l-2.7 9L7.8 1l-2.7 9H1.5"/></svg>
            </i>
            <span><b>This period:</b> {{ formatValue(valueCurrent) }}</span>
          </div>
          <div class="period-compare-last-period">
            <i>
              <svg xmlns="http://www.w3.org/2000/svg" width="23" height="20" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m21.374 11.428-1.903-1.904-1.904 1.904M19.757 10a8.565 8.565 0 1 1-17.131 0 8.565 8.565 0 0 1 15.945-4.35m-7.38-.409v4.76l2.855 1.902"/></svg>
            </i>
            <span><b>Last period:</b> {{ formatValue(valuePrevious) }}</span>
          </div>
        </div>
      </div>

      <!-- Bars -->
      <div class="infographic-block reduce-gap" v-if="aws?.widgetMode === 'bars'">
        <div class="infographic-range period-compare">
          <div class="layer-1 this-period">
            <span :style="`width: ${calcValuePercent(valueCurrent)}%; background-color: ${iconColor} !important;`"></span>
          </div>
        </div>
        
        <div class="infographic-legend">
          <div class="this-period-legend">
            <span class="infographic-legend-img" :style="`background-color: ${iconBackgroundColor} !important;`"><DataIconView v-if="aws" :iconType="aws.dataIcon" :iconColor="iconColor"/></span>
            <div>
              <span class="infographic-legend-value white-space-nowrap">{{ formatValue(valueCurrent) }}</span>
              <span class="infographic-legend-description white-space-nowrap">This Period</span>
            </div>
          </div>
        </div>

        <div class="infographic-range period-compare">
          <div class="layer-2 last-period">
            <span :style="`width: ${calcValuePercent(valuePrevious)}%;`"></span>
          </div>
        </div>

        <div class="infographic-legend">
          <div class="last-period-legend">
            <span class="infographic-legend-img"><DataIconView v-if="aws" :iconType="aws.dataIcon"/></span>
            <div>
              <span class="infographic-legend-value white-space-nowrap">{{ formatValue(valuePrevious) }}</span>
              <span class="infographic-legend-description white-space-nowrap">Last Period</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import numbro from "numbro";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import DateHelper from '@/helpers/DateHelper';
import { TimeRange } from '@/models/enums/TimeRange';
import moment from 'moment';
import DataIconView from "./settings/DataIconView.vue";
import chroma from 'chroma-js';
import { StreamValuesRequest } from '@/models/StreamValuesRequest';
import { AggregationType } from '@/models/enums/AggregationType';

@Component({
  components: {
    ProgressSpinner,
    WidgetNoDataView,
    DataIconView
  }
})
class PeriodCompareWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get iconColor(): string {
    let result = "#FF8F2E";
    if (this.wds?.streamOptions?.length) {
      result = this.wds.streamOptions[0].hexStreamColor;
    }
    return result;
  }

  get iconBackgroundColor(): string {
    const result = chroma(this.iconColor).alpha(0.2).hex();
    return result;
  }

  get isBadState(): boolean {
    const result = this.diffPercent > 0 && this.aws?.dataNegativeState === "increase" || this.diffPercent < 0 && this.aws?.dataNegativeState === "decrease";
    return result;
  }

  get conditionColor(): string {
    const valueForCondition = this?.aws?.dataConditionsMode === "percent" ? this.diffPercent : this.valueCurrent;
    const result = this.getCondition(valueForCondition)[1];
    return result;
  }

  get textStateColor(): string {
    if (this.conditionColor) {
      return this.conditionColor;
    } else {
    const result = this.isBadState ? "var(--period-compare-error-color)" : "var(--period-compare-success-color)";
      return result;
    }
  }

  get iconStateColor(): string {
    if (this.conditionColor) {
      return this.conditionColor;
    } else {
      const result = this.isBadState ? "var(--period-compare-error-icon-color)" : "var(--period-compare-success-icon-color)";
      return result;
    }
  }

  get iconStateBackgroundColor(): string {
    if (this.conditionColor) {
      const color = chroma.hex(this.conditionColor).brighten(2.2);
      return color.hex();
    } else {
      const result = this.isBadState ? "var(--period-compare-error-icon-background-color)" : "var(--period-compare-success-icon-background-color)";
      return result;
    }
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;
  valueCurrent: number | undefined = undefined;
  valuePrevious: number | undefined = undefined;
  lastTimestamp: Date | undefined = undefined;

  get lastTimestampTimeStr(): string | undefined {
    if (typeof this.lastTimestamp === "undefined") {
      return "";
    } else {
      return moment.utc(this.lastTimestamp).format("hh:mm A");
    }
  }

  get lastTimestampDateStr(): string | undefined {
    if (typeof this.lastTimestamp === "undefined") {
      return "";
    } else {
      return moment.utc(this.lastTimestamp).format("DD/MM/YYYY");
    }
  }
  
  get diffValue(): number | undefined {
    if (typeof this.valueCurrent === "undefined" && typeof this.valuePrevious === "undefined") {
      return undefined;
    }
    if (typeof this.valueCurrent === "number" && typeof this.valuePrevious === "number") {
      return this.valueCurrent - this.valuePrevious;
      
    }
    if (typeof this.valueCurrent === "undefined" && typeof this.valuePrevious === "number") {
      return -this.valuePrevious;
    }
    return this.valueCurrent;
  }

  get diffPercent(): number {
    if (typeof this.valueCurrent === "undefined" && typeof this.valuePrevious === "undefined") {
      return 0;
    }
    if (typeof this.valueCurrent === "undefined") {
      return -100;
    }
    if (typeof this.valuePrevious === "undefined") {
      return 100;
    }
    if (this.valuePrevious === 0 && this.valueCurrent === 0) {
      return 0;
    }
    if (this.valuePrevious === 0 && this.valueCurrent > 0) {
      return 100;
    }
    if (this.valuePrevious === 0 && this.valueCurrent < 0) {
      return -100;
    }
    return Math.round((this.valueCurrent - this.valuePrevious) / this.valuePrevious * 100);
  }

  getMinMaxTemp(): [number, number] {
    const temps: number[] = [];
    if (typeof this.valueCurrent === "number") {
      temps.push(this.valueCurrent);
    }
    if (typeof this.valuePrevious === "number") {
      temps.push(this.valuePrevious);
    }
    if (temps.length) {
      const min = Math.floor(Math.min(...temps));
      const max = Math.ceil(Math.max(...temps));
      return [min > 0 ? 0 : min, max];
    } else {
      return [0, 100];
    }
  }

  calcValuePercent(value: number | undefined): number {
    if (typeof value === "undefined") {
      return 0;
    }
    const minMaxTemp = this.getMinMaxTemp();
    const min = minMaxTemp[0];
    let max = minMaxTemp[1];
    let valueVar = value;
    const offset = -min;
    // min += offset; = 0, not important for calc
    max += offset;
    valueVar += offset;
    const result = valueVar / max;
    return result * 100;
  }

  outsideZIndex = 1;

  insideZIndex = 2;

  updateZIndex(): void {
    if (typeof this.valuePrevious === "number" &&
      typeof this.valueCurrent === "number" &&
      this.valuePrevious > this.valueCurrent) {
      this.outsideZIndex = 2;
      this.insideZIndex = 1;
    } else {
      this.outsideZIndex = 1;
      this.insideZIndex = 2;
    }
  }

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      if (requestBody.Streams.length) {
        // previous period
        const previousPeriodRequest: StreamValuesRequest = JSON.parse(JSON.stringify(requestBody.Streams[0]));
        const dates = DateHelper.extractDateFromRequestBody(requestBody);
        const datesBefore = DateHelper.goBackForward(-1, dates[0], dates[1], true);
        const dateFromM = moment(datesBefore[0]);
        const dateToM = moment(datesBefore[1]);
        previousPeriodRequest.Time = {
          From: dateFromM.format("YYYY-MM-DDTHH:mm:ss"),
          To: dateToM.format("YYYY-MM-DDTHH:mm:ss"),
          Range: TimeRange.Custom,
        };
        requestBody.Streams.push(previousPeriodRequest);

        // last timestamp
        const lastTimestampRequest: StreamValuesRequest = JSON.parse(JSON.stringify(requestBody.Streams[0]));
        lastTimestampRequest.AggregationType = AggregationType.LastTimeOccurrence;
        requestBody.Streams.push(lastTimestampRequest);
      }
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.valueCurrent = undefined; 
    this.valuePrevious = undefined; 
    let hasData = false;
    this.chartData.forEach((streamData, i) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length) {
        const value = streamData.Data[0].y;
        switch (i) {
          case 0:
            if (typeof value === "number") {
              this.valueCurrent = value;
              hasData = true;
            }
            break;
          case 1:
            if (typeof value === "number") {
              this.valuePrevious = value;
              hasData = true;
            }
            break;
          case 2:
            if (typeof value === "number") {
              this.lastTimestamp = new Date(value * 1000);
              hasData = true;
            }
            break;
        }
      }
    });
    this.updateZIndex();
    if (!hasData) {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }

  formatValue(value: number | undefined, abs = false, includeUnits = true) : string {
    let result: string;
    const units = this.aws?.widgetUnit ? this.aws.widgetUnit : "";
    if (typeof value === "undefined" || value === null) {
      result = "-";
    } else if (typeof value === "string") {
      result = includeUnits ? `${value} ${units}` : `${value}`;
    } else {
      const num = numbro(abs ? Math.abs(value) : value).format({
        thousandSeparated: true,
        mantissa: 1
      });
      result = includeUnits ? `${num} ${units}` : `${num}`;
    }
    return result;
  }

  getCondition(value: number | string | undefined): [string, string] {
    // value, color
    const result: [string, string] = ["", ""];
    if (typeof value === "string") {
      if (this.aws?.dataConditions?.length) {
        for (const dataCondition of this.aws.dataConditions) {
          switch (dataCondition.rule) {
            case "=": {
              if (value === dataCondition.valueStr) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "!=": {
              if (value !== dataCondition.valueStr) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
          }
        }
      }
      result[0] = value;
    } else {
      if (this.aws?.dataConditions?.length) {
        for (const dataCondition of this.aws.dataConditions) {
          switch (dataCondition.rule) {
            case "=": {
              if (value === dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "!=": {
              if (value !== dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case ">=": {
              if (typeof value !== "undefined" && value >= dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case ">": {
              if (typeof value !== "undefined" && value > dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "<=": {
              if (typeof value !== "undefined" && value <= dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "<": {
              if (typeof value !== "undefined" && value < dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "Between": {
              if (typeof dataCondition.value2 === "number" && 
                typeof value !== "undefined" && 
                value >= dataCondition.value && value < dataCondition.value2) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "No data": {
              if (typeof value === "undefined" || value === null) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
          }
        }
      }
      result[0] = numbro(value).format({
        thousandSeparated: true,
        mantissa: 1
      });
    }
    if (result[0] && this.aws?.widgetUnit) {
      result[0] = `${result[0]} ${this.aws.widgetUnit}`;
    }
    return result;
  }
}

export default PeriodCompareWidget;
</script>