import ErrorHelper from "@/helpers/ErrorHelper";
import { FlowEntity } from "@/models/flow/FlowEntity";
import FlowQuery from "@/models/flow/FlowQuery";
import { MongoDymanicPage } from "@/models/MongoDymanicPage";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import FlowState from "../states/FlowState";
import { v4 as uuidv4 } from "uuid";
import CustomWindow from "@/CustomWindow";
import DateHelper from "@/helpers/DateHelper";
import { TestAlarmModel } from "@/models/TestAlarmModel";
import { UserFullDto } from "@/models/user/UserFullDto";
import NavigationHelper from "@/helpers/NavigationHelper";
import { FlowNode } from "@/models/flow/FlowNode";
import { useOrganisationStore } from "@/stores/organisation";

declare const window: CustomWindow;

function findLowestCoordinates(nodes: FlowNode[], coordinates: [number, number]): void {
  nodes.forEach(element => {
    if (element.PositionX < coordinates[0]) {
      coordinates[0] = element.PositionX;
    }
    if (element.PositionY < coordinates[1]) {
      coordinates[1] = element.PositionY;
    }
    if (element.Childs?.length) {
      findLowestCoordinates(element.Childs, coordinates);
    }
  });
}

function moveTree(nodes: FlowNode[], coordinates: [number, number]): void {
  nodes.forEach(element => {
    element.PositionX -= coordinates[0];
    element.PositionY -= coordinates[1];
    if (element.Childs?.length) {
      moveTree(element.Childs, coordinates);
    }
  });
}

function normalizeTreeCoordinates(tree: FlowNode[]): void {
  const coordinates: [number, number] = [0, 0];
  findLowestCoordinates(tree, coordinates);
  if (coordinates[0] < 0 || coordinates[1] < 0) {
    moveTree(tree, coordinates);
  }
}

const getters = <GetterTree<FlowState, any>>{
  getNodeFullName(state): (nodeType: string | null) => string {
    return (nodeType: string | null): string => {
      switch (nodeType) {
        case "virtual_stream": {
          return "Virtual Stream";
        }
        case "power_energy": {
          return "Power to Energy";
        }
        case "accumulating_interval": {
          return "Accumulating to Interval";
        }
        case "add": {
          return "Add";
        }
        case "subtract": {
          return "Subtract";
        }
        case "multiply": {
          return "Multiply";
        }
        case "divide": {
          return "Divide";
        }
        case "stream": {
          return "Stream";
        }
        case "value": {
          return "Value";
        }
        case "generator": {
          return "Model Generator";
        }
        case "tag_node": {
          return "Tag Node";
        }
        case "pool": {
          return "Pool";
        }
        case "out_of_limits": {
          return "Out of Limits";
        }
        case "data_anomaly": {
          return "Data Anomaly";
        }
        case "data_quality": {
          return "Data Quality";
        }
        case "ai_report": {
          return "AI Insights";
        }
        case "csv_export": {
          return "CSV Export";
        }
        case "tag_value": {
          return "Tag Value";
        }
        default: {
          return "";
        }
      }
    }
  }
};

const mutations = <MutationTree<FlowState>>{
  setCopiedNode(state, value: string | null) {
    state.copiedNode = value;
  },
  unloadCurrentFlow(state) {
    if (state.currentFlow) {
      state.currentFlow = null;
      state.isLoadedCurrentFlow = false;
    }
  }
};

const actions = <ActionTree<FlowState, any>>{
  async load({ state }, query: FlowQuery) {
    try {
      const guid = uuidv4();
      state.guidPage = guid;
      state.pageQuery = query;
      state.isLoaded = false;
      state.flows = null;
      const url = `rest/BitPool_V2/flow?search=${query.search}&take=${query.take}&skip=${query.skip}&insightOnly=${query.alarmOnly}&orderBy=${query.orderBy}&desc=${query.desc}`;
      const response = await axios.get<MongoDymanicPage<FlowEntity>>(url);
      if (state.guidPage === guid) {
        response.data.Items.forEach(item => {
          item.CreatedDate = DateHelper.parseFromMongoDate(item.CreatedDate);
          item.DeletedDate = DateHelper.parseFromMongoDate(item.DeletedDate);
          item.ModifiedDate = DateHelper.parseFromMongoDate(item.ModifiedDate);
        });
        state.flows = response.data;
        state.isLoaded = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load flows",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.flows = null;
      state.isLoaded = true;
    }
  },
  async loadOne({ state, rootState }, id: string) {
    try {
      state.isLoadedCurrentFlow = false;
      state.currentFlow = null;
      if (id === "new") {
        const organisationStore = useOrganisationStore();
        state.currentFlow = {
          _id: undefined,
          Name: "",
          CreatedDate: new Date(),
          Deleted: false,
          DeletedDate: null,
          ModifiedDate: null,
          ModifiedBy: null,
          Organisations: [organisationStore.currentOrganisation?.Id ?? -1],
          Tree: [],
          HasAlarm: false,
          HasAIData: false,
          HasInsight: false
        };
      } else {
        let isDone = false;
        if (state.isLoaded && state.flows && state.flows.Items) {
          const flow = state.flows.Items.find((x) => x._id === id);
          if (flow) {
            state.currentFlow = flow;
            isDone = true;
          }
        }
        if (!isDone) {
          const url = `rest/BitPool_V2/flow/${id}`;
          const response = await axios.get<FlowEntity>(url);
          response.data.CreatedDate = DateHelper.parseFromMongoDate(response.data.CreatedDate);
          response.data.DeletedDate = DateHelper.parseFromMongoDate(response.data.DeletedDate);
          response.data.ModifiedDate = DateHelper.parseFromMongoDate(response.data.ModifiedDate);
          // normalize coordinates
          if (response.data.Tree && response.data.Tree.length) {
            normalizeTreeCoordinates(response.data.Tree);
          }
          state.currentFlow = response.data;
        }
      }
      state.isLoadedCurrentFlow = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load flow",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.currentFlow = null;
      state.isLoadedCurrentFlow = true;
    }
  },
  async createUpdate({ state }, body: FlowEntity) {
    try {
      state.updateInProgress = true;
      state.updateError = false;
      const url = `rest/BitPool_V2/flow`;
      const response = await axios.post<FlowEntity>(url, body);
      ToastService.showToast("success", "Flow", "Changes saved", 5000);
      if (body.Tree && body.Tree.find(x => state.virtualStreamType.find(y => y === x.NodeType))) {
        ToastService.showToast("warn", "Flow", "Stream is flagged for recalculation!", 5000);
      }
      state.updateInProgress = false;
      if (!body._id) {
        // new
        NavigationHelper.goTo(`/data/flow/${response.data._id}`);
      } else {
        // exist
        state.currentFlow = response.data;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save flow",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.updateError = true;
      state.updateInProgress = false;
    }
  },
  async delete({ state, dispatch }, id: string) {
    try {
      const url = `rest/BitPool_V2/flow/${id}`;
      await axios.delete(url);
      ToastService.showToast("success", "Flow", "Flow is deleted", 5000);
      if (state.isLoaded) {
        await dispatch("load", state.pageQuery);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete flow",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async testAlarm({ state }, body: TestAlarmModel) {
    try {
      const url = `rest/BitPool_V2/flow/alarm/test`;
      await axios.post(url, body);
      ToastService.showToast("success", "Flow", "Please check email", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't send test alarm",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.updateError = true;
      state.updateInProgress = false;
    }
  },
  async testAlarmWithData({ state }, body: [string, FlowEntity]) {
    try {
      const url = `rest/BitPool_V2/flow/alarm/test/${body[0]}/with-data`;
      const response = await axios.post<number>(url, body[1]);
      ToastService.showToast("success", "Flow", `${response.data} insights sent`, 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't send test alarm",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.updateError = true;
      state.updateInProgress = false;
    }
  },
  async loadEmails({ state, rootState }) {
    try {
      state.emails = [];
      const organisationStore = useOrganisationStore();
      const url = `rest/BitPool_V2/organisations/${organisationStore.currentOrganisation?.Id}/users`;
      const response = await axios.get<UserFullDto[]>(url);
      let emails = response.data.map(x => x.UserName);
      const userData = window.angularUserprofile.getAllUserData()
      if (!emails.find(x => x === userData.userName)){
        emails.push(userData.userName);
      }
      emails = emails.sort((a, b) => a.localeCompare(b));
      state.emails = emails;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load emails",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  }
};

const FlowModule = {
  namespaced: true,
  state: new FlowState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default FlowModule;
