<template>
  <div ref="root" v-if="isMounted" class="widget-type-image-freeform">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-else class="image-freeform">
      <div v-if="canEdit" class="image-freeform-actions image-freeform-actions-top">
        <div class="flex align-items-center flex-shrink-0 py-2">
          <InputSwitch 
            inputId="enableEdit" 
            class="vertical-align-top"
            v-model="enableEdit"
            @change="enableEditChanged"
          />
          <label for="enableEdit"  class="text-lg font-light mb-0 ml-2">Edit</label>
        </div>
        <div v-if="enableEdit && availableStreams.length" style="min-width: 0;" class="mt-3 sm:mt-0">
          <Dropdown 
            v-model="selectedStreamIndex" 
            :options="availableStreams" 
            :optionValue="getStreamIndex"
            optionLabel="Label" 
            placeholder="Select Stream" 
            :filter="true"
            filterPlaceholder="Find Stream"
            class="flex">
            <template #value="slotProps">
              <div v-if="selectedStream" class="flex align-items-center">
                <span class="mr-2 flex-shrink-0" :style="{ width: '15px', height: '15px', backgroundColor: selectedStream.hexStreamColor }"></span>
                <span class="flex-auto" style="min-width: 0;"><span class="block overflow-hidden text-overflow-ellipsis">{{selectedStream.Label}}</span></span>
              </div>
              <span v-else>
                {{slotProps.placeholder}}
              </span>
            </template>
            <template #option="slotProps">
              <div class="flex align-items-center font-light text-sm">
                <span class="mr-2 flex-shrink-0" :style="{ width: '15px', height: '15px', backgroundColor: slotProps.option.hexStreamColor }"></span>
                <span class="flex-auto"><span class="block break-word">{{slotProps.option.Label}}</span></span>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>

      <div v-if="imageBase64" class="image-freeform-content">
        <LMap
          v-if="enableEdit"
          :style="{ height: `${imageHeight}px` }"
          ref="map"
          :crs="crs"
          @ready="hookUpDraw"
          :minZoom="minZoom"
          :maxZoom="maxZoom"
          :zoom="zoom"
          @update:zoom="zoomUpdate"
          :center="center"
          @update:center="centerUpdate"
          :options="{ attributionControl: false, keyboard: false, zoomSnap: 0.025, zoomDelta: 0.5 }"
        >
          <template v-if="mapReady">
            <LImageOverlay :url="imageBase64" :bounds="bounds"></LImageOverlay>
          </template>
        </LMap>
        <LMap
          v-else
          :style="{ height: `${imageHeight}px` }"
          :crs="crs"
          :minZoom="minZoom"
          :maxZoom="maxZoom"
          :zoom="zoom"
          @update:zoom="zoomUpdate"
          :center="center"
          @update:center="centerUpdate"
          :options="{ attributionControl: false, keyboard: false, zoomSnap: 0.025, zoomDelta: 0.5 }"
        >
          <template>
            <LImageOverlay :url="imageBase64" :bounds="bounds"></LImageOverlay>
            <LPolygon 
              v-for="(item, index) in polygons" 
              :key="index"
              :lat-lngs="item.latLngs"
              :color="item.color"
              :fill="true"
              :fillOpacity="0.5"
              :fillColor="item.color"
            >
              <LTooltip :options="{ sticky: true, className: `tooltip-colorized-${widgetGuid}-${item.streamIndex}` }">
                <span>
                  <b>{{ item.name }}</b><br/>
                  <span>
                    Value: {{ item.value }}
                  </span>
                </span>
              </LTooltip>
            </LPolygon>
            <LMarker
              v-for="(item, index) in markers"
              :key="index"
              :lat-lng="item.latLng"
              :icon="buildMarker(item.streamColor)"
            >
              <LTooltip :options="{ permanent: true, direction: 'top', className: `tooltip-colorized-${widgetGuid}-${item.streamIndex}` }">
                <span>
                  <b>{{ item.name }}</b><br/>
                  <span>
                    Value: {{ item.value }}
                  </span>
                </span>
              </LTooltip>
            </LMarker>
          </template>
        </LMap>
        <span style="display:none" v-html="styleTooltipStreamColors"></span>
      </div>

      <div v-if="!enableEdit && availableStreams.length" class="image-freeform-actions image-freeform-actions-bottom">
        <div class="image-freeform-slider">
          <span v-if="currentDuration >= 0.75" class="image-freeform-slider-counter">
            <RadialProgress 
              :diameter="36"
              :strokeWidth="4"
              :innerStrokeWidth="4"
              :startColor="'#00ADEF'"
              :stopColor="'#21C2F3'"
              :innerStrokeColor="'var(--widget-svg-color-seventh)'"
              :completed-steps="(counter === timerTimeout) ? currentDuration : (currentDuration - counter)"
              :total-steps="currentDuration"
              :animateSpeed="(counter === currentDuration || counter < timerTimeout) ? 0 : (900 * timerTimeout)">
            </RadialProgress>
          </span>
          <span class="image-freeform-slider-pager">{{ currentDateIndex + 1 }} / {{ availableStreamsDates.length }}</span>
          <div class="image-freeform-slider-controls">
            <div class="image-freeform-slider-controls-buttons">
              <Button v-if="isPaused || !interval" v-tippy="'Start'" icon="pi pi-play" class="p-button-rounded  p-button-light-primary p-button-icon-only p-button-lg" @click="play"></Button>
              <Button v-else v-tippy="'Pause'" icon="pi pi-pause" class="p-button-rounded  p-button-light-primary p-button-icon-only p-button-lg" @click="pause"></Button>
              <Button v-tippy="'Previous'" icon="pi pi-step-backward" class="p-button-rounded  p-button-light-primary p-button-icon-only p-button-lg" @click="prev"></Button>
            </div>
            <Slider v-model="currentDateIndex" :step="1" :min="0" :max="availableStreamsDates.length - 1" class="flex-auto" />
            <div class="image-freeform-slider-controls-buttons">
              <Button v-tippy="'Next'" icon="pi pi-step-forward" class="p-button-rounded  p-button-light-primary p-button-icon-only p-button-lg" @click="next"></Button>
            </div>
          </div>
        </div>

        <div class="image-freeform-slider-dropdowns">
          <div class="image-freeform-slider-dropdown-speed">
            <Dropdown 
              v-model="currentDuration"
              :options="durations"
              optionLabel="name" 
              optionValue="id"
              placeholder="Select Duration"
              class="w-full">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <i></i>
                  {{ durations.find(x => x.id === slotProps.value)?.name }}
                </div>
                <span v-else>
                  {{slotProps.placeholder}}
                </span>
              </template>
              <template #option="slotProps">
                <div>
                  {{slotProps.option.name}}
                </div>
              </template>
            </Dropdown>
          </div>
          <div class="image-freeform-slider-dropdown-date">
            <Dropdown 
              v-model="currentDate"
              :options="availableStreamsDates"
              optionLabel="name" 
              optionValue="id"
              :filter="true"
              filterPlaceholder="Find Date"
              placeholder="Select Date"
              class="w-full">
              <template #value="slotProps">
                <div v-if="slotProps.value" class="flex align-items-center">
                  <i></i>
                  {{ availableStreamsDates.find(x => x.id === slotProps.value)?.name }}
                </div>
                <span v-else>
                  {{slotProps.placeholder}}
                </span>
              </template>
              <template #option="slotProps">
                <div>
                  {{slotProps.option.name}}
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Ref } from 'vue-facing-decorator';
import StreamOption from '../../models/dashboard/StreamOption';
import { nextTick, PropType } from 'vue';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { BasicWidgetSettings } from '@/models/dashboard/BasicWidgetSettings';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import DashboardState from '@/store/states/DashboardState';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import { Watch } from 'vue-facing-decorator';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import { v4 as uuidv4 } from "uuid";
import ProgressSpinner from 'primevue/progressspinner';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Slider from 'primevue/slider';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import L from "leaflet";
import { LMap, LImageOverlay, LMarker, LPolygon, LTooltip, LIcon } from "@vue-leaflet/vue-leaflet";
import { Permission } from '@/models/enums/Permission';
import StreamOptionGeoData from '@/models/dashboard/StreamOptionGeoData';
import chroma from 'chroma-js';
import numbro from 'numbro';
import ColorHelper from '@/helpers/ColorHelper';
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import moment from 'moment';
import { IdNumberName } from '@/models/IdNumberName';
import RadialProgress from "vue3-radial-progress";

@Component({
  components: {
    ProgressSpinner,
    InputSwitch,
    Dropdown,
    Button,
    Slider,
    WidgetNoDataView,
    LMap,
    LImageOverlay,
    LMarker,
    LPolygon,
    LTooltip,
    LIcon,
    DateTimezoneView,
    RadialProgress
  }
})
class ImageFreeFormWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;
  @Prop({ required: true }) dashboardId!: string;

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get isFullAccess(): boolean {
    return this.$store.getters["dashboard/isFullAccess"];
  }

  get permissions(): Permission {
    const getPermissions = this.$store.getters["dashboard/getPermissions"] as (id: string) => Permission;
    return getPermissions(this.dashboardId);
  }

  get canEdit(): boolean {
    return this.isFullAccess || this.permissions === Permission.Write;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;

  isMounted = false;
  @Ref() readonly root!: HTMLDivElement;

  internalChanges = false;

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    if (this.internalChanges) {
      this.internalChanges = false;
    } else {
      if (typeof this.wds?.mapInitialZoom === "number" && this.wds?.mapInitialZoom !== this.zoom) {
        this.zoom = this.wds.mapInitialZoom;
      }
      this.initCounter();
      this.reloadData();
    }
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  imageBase64 = "";
  imageUrlCache = "";
  imageWidth = 0;
  imageHeight = 0;

  async reloadImage(): Promise<void> {
    if (this.aws?.widgetImageSrc && this.aws.widgetImageSrc !== this.imageUrlCache) {
      this.imageUrlCache = this.aws.widgetImageSrc;
      const response = await fetch(this.aws.widgetImageSrc);
      if (response.ok && this.imageUrlCache === this.aws.widgetImageSrc) {
        const blob = await response.blob();

        const originalBmp = await createImageBitmap(blob);
        this.imageHeight = originalBmp.height;
        this.imageWidth = originalBmp.width;
        this.center = [this.imageHeight / 2, this.imageWidth / 2];
       
        const canvas = document.createElement('canvas');
        canvas.height = originalBmp.height;
        canvas.width = originalBmp.width;
        const ctx = canvas.getContext('2d');
        if (ctx) {
          ctx.drawImage(originalBmp, 0, 0, canvas.width, canvas.height);
          const base64String = canvas.toDataURL();
          this.imageBase64 = base64String;
        }

        canvas.remove();
        originalBmp.close();
      }
    }
  }

  get widgetGuid(): string {
    return this.widgetConfig.guid;
  }

  get styleTooltipStreamColors(): string {
    let result = "";
    this.availableStreams.forEach((stream, streamIndex) => {
      const color = stream.hexStreamColor;
      const fontColor = ColorHelper.getContrastColor(color);
      result += `.tooltip-colorized-${this.widgetGuid}-${streamIndex} { --freeform-tooltip-bg-color: ${color}; --freeform-tooltip-color: ${fontColor}; }`
    });
    result = `<style>${result}</style>`;
    return result;
  }

  get markers(): any[] {
    const result: any[] = [];
    this.availableStreams.forEach((stream, streamIndex) => {
      if (stream.geoData) {
        stream.geoData.forEach(geoData => {
          if (geoData.shape === "Marker" && this.currentDate) {
            const data = this.availableStreamsData[this.currentDate];
            let value: string | number | undefined = data.length > streamIndex ? 
              data[streamIndex][1] :
              undefined;
            if (typeof value === "number") {
              value = stream.units ? 
                `${numbro(value).format({thousandSeparated: true, mantissa: 2})} ${stream.units}` :
                numbro(value).format({thousandSeparated: true, mantissa: 2});
            } else if (typeof value === "string" && stream.units) {
              value = `${value} ${stream.units}`;
            }
            result.push({ 
              streamIndex: streamIndex,
              latLng: geoData.coordinates, 
              color: data.length > streamIndex ? 
                data[streamIndex][0] :
                "#000",
              streamColor: stream.hexStreamColor,
              name: stream.Label ?? stream.Name,
              value: value ?? '-'
            });
          }
        });
      }
    });
    // result.push({ 
    //   streamIndex: 123,
    //   latLng: { lat: 0, lng: 0 }, 
    //   color: "#000",
    //   streamColor: "#000",
    //   name: "Test 0, 0",
    //   value: '0'
    // });
    return result;
  }

  get polygons(): any[] {
    const result: any[] = [];
    this.availableStreams.forEach((stream, streamIndex) => {
      if (stream.geoData) {
        stream.geoData.forEach(geoData => {
          if (geoData.shape !== "Marker" && this.currentDate) {
            const data = this.availableStreamsData[this.currentDate];
            let value: string | number | undefined = data.length > streamIndex ? 
              data[streamIndex][1] :
              undefined;
            if (typeof value === "number") {
              value = stream.units ? 
                `${numbro(value).format({thousandSeparated: true, mantissa: 2})} ${stream.units}` :
                numbro(value).format({thousandSeparated: true, mantissa: 2});
            } else if (typeof value === "string" && stream.units) {
              value = `${value} ${stream.units}`;
            }
            result.push({ 
              streamIndex: streamIndex,
              latLngs: geoData.coordinates, 
              color: data.length > streamIndex ? 
                data[streamIndex][0] :
                "#000",
              streamColor: stream.hexStreamColor,
              name: stream.Label ?? stream.Name,
              value: value ?? '-'
            });
          }
        });
      }
    });
    return result;
  }
  
  async reloadData(silent = false, init = false): Promise<void> {
    this.initStreamDropdown();
    this.reloadImage();
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.zoom = this.wds?.mapInitialZoom ?? -1;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;
  
  mounted(): void {
    this.initCounter();
    this.isMounted = true;
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }
  
  // timestamp, color, value
  availableStreamsData: Record<number, [string, string | number | undefined][]> = {};
  get availableStreamsDates(): IdNumberName[] {
    const result: IdNumberName[] = Object
      .keys(this.availableStreamsData)
      .map(x => parseInt(x))
      .map(x => { return { id: x, name: moment(x).utc().format("DD/MM/YYYY HH:mm") } });
    return result;
  }
  currentDate = 0;
  get currentDateIndex(): number {
    return this.availableStreamsDates.findIndex(x => x.id === this.currentDate);
  }
  set currentDateIndex(value: number) {
    if (value >= 0 && value < this.availableStreamsDates.length) {
      this.currentDate = this.availableStreamsDates[value].id;
    }
  }
  durations: IdNumberName[] = [
    { id: 0.25, name: "0.25 sec" },
    { id: 0.5, name: "0.5 sec" },
    { id: 0.75, name: "0.75 sec" },
    { id: 1, name: "1 sec" },
    { id: 2, name: "2 sec" },
    { id: 4, name: "4 sec" },
    { id: 8, name: "8 sec" }
  ];
  currentDuration = 0.25;

  // #region player
  isPaused = true;
  counter = 0;
  interval = 0;
  timerTimeout = 0.125;

  initCounter(): void {
    this.counter = this.currentDuration;
  }

  startTimer(): void {
    if (this.availableStreamsDates?.length) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = window.setInterval(() => {
        this.counter -= this.timerTimeout;
        if (this.counter <= 0) {
          clearInterval(this.interval);
          this.interval = 0;
          const currentDateIndex = this.availableStreamsDates.findIndex(x => x.id === this.currentDate);
          const nextDateIndex = (currentDateIndex + 1 >= this.availableStreamsDates.length) ?
            0 :
            currentDateIndex + 1;
          const nextDate = this.availableStreamsDates[nextDateIndex];
          this.currentDate = nextDate.id;
        }
      }, this.timerTimeout * 1000);
    }
  }

  stopTimer(): void {
    clearInterval(this.interval);
    this.interval = 0;
  }

  @Watch('currentDate', { immediate: false, deep: false })
  @Watch('currentDuration', { immediate: false, deep: false })
  onCurrentDateOrDurationChanged(val: string, oldVal: string): void {
    this.initCounter();
    if (!this.isPaused) {
      this.startTimer();
    }
  }

  play(): void {
    this.isPaused = false;
    if (this.counter < 1) {
      this.initCounter();
    }
    this.startTimer();
  }

  pause(): void {
    this.isPaused = true;
    this.stopTimer();
  }

  prev(): void {
    if (this.availableStreamsDates?.length) {
      const currentDateIndex = this.availableStreamsDates.findIndex(x => x.id === this.currentDate);
      const prevDateIndex = (currentDateIndex - 1 < 0) ?
        this.availableStreamsDates.length - 1 :
        currentDateIndex - 1;
      const prevDate = this.availableStreamsDates[prevDateIndex];
      this.currentDate = prevDate.id;
    }
  }

  next(): void {
    if (this.availableStreamsDates?.length) {
      const currentDateIndex = this.availableStreamsDates.findIndex(x => x.id === this.currentDate);
      const nextDateIndex = (currentDateIndex + 1 >= this.availableStreamsDates.length) ?
        0 :
        currentDateIndex + 1;
      const nextDate = this.availableStreamsDates[nextDateIndex];
      this.currentDate = nextDate.id;
    }
  }
  // #endregion player

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    const inactiveColor = this.aws?.inactiveColor ? this.aws.inactiveColor : "rgb(150,150,150)";
    const availableStreamsData: Record<number, [string, string | number | undefined][]> = {}
    if (data.length) {
      const dateKeys: Set<number> = new Set<number>();
      data.forEach((element, index) => {
        if (element.Data?.length) {
          element.Data.forEach(item => {
            if (typeof item.x === "number") {
              dateKeys.add(item.x);
            }
          });
        }
      });
      const sortedDates = Array.from(dateKeys).sort();
      if (sortedDates.length) {
        if (!this.currentDate || !sortedDates.includes(this.currentDate)) {
          this.currentDate = sortedDates[0];
        }
        sortedDates.forEach(date => {
          availableStreamsData[date] = [];
        })
        data.forEach((element, index) => {
          sortedDates.forEach(date => {
            availableStreamsData[date].push([inactiveColor, undefined]);
          });
          if (element.Data?.length) {
            const stream = this.availableStreams[index];
            const colorRange = stream.gaugeRange?.length ? 
              stream.gaugeRange.sort((a, b) => { return a.fromP - b.fromP; }) :
              [{
                from: 0,
                fromP: 0,
                to: 50,
                toP: 50,
                color: "#4CAF50"
              }, {
                from: 50,
                fromP: 50,
                to: 100,
                toP: 100,
                color: "#f44336"
              }];
            const colors = colorRange.map(x => x.color);
            let values = colorRange.map(x => x.from);
            if (stream.autoMinMax) {
              let min: number | undefined = undefined;
              let max: number | undefined = undefined;
              element.Data.forEach(item => {
                const value = item.y;
                if (typeof value === "number") {
                  if (min === undefined || value < min) {
                    min = value;
                  }
                  if (max === undefined || value > max) {
                    max = value;
                  }
                }
              });
              if (min !== undefined && max !== undefined) {
                if (min > 0 && max > 0) {
                  min = 0;
                  max = Math.ceil(max);
                } else if (min < 0 && max < 0) {
                  min = Math.floor(min);
                  max = 0;
                } else {
                  min = Math.floor(min);
                  max = Math.ceil(max);
                }
                const interval = max - min;
                values = colorRange.map(x => interval * x.fromP / 100 + (min ?? 0));
              }
            }
            const gradient = chroma.scale(colors).domain(values);

            element.Data.forEach(item => {
              if (typeof item.x === "number") {
                const date = item.x;
                const value = item.y;
                if (typeof value === "number") {
                  if (value < values[0]) {
                    availableStreamsData[date][index][0] = colors[0];
                    availableStreamsData[date][index][1] = value;
                  } else if (value > values[values.length - 1]) {
                    availableStreamsData[date][index][0] = colors[colors.length - 1];
                    availableStreamsData[date][index][1] = value;
                  } else {
                    availableStreamsData[date][index][0] = gradient(value).hex();
                    availableStreamsData[date][index][1] = value;
                  }
                } else if (typeof value === "string") {
                  availableStreamsData[date][index][0] = stream.hexStreamColor;
                  availableStreamsData[date][index][1] = value;
                }
              }
            });
          }
        });
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
    this.availableStreamsData = availableStreamsData;
  }

  crs = L.CRS.Simple;
  mapObject: any | null = null;
  mapReady = false;
  get bounds(): any {
    return [
      [0, 0],
      [this.imageHeight, this.imageWidth],
    ];
  }
  minZoom = -2;
  maxZoom = 5;
  zoom = -1;
  zoomUpdate(value: number): void {
    this.zoom = value;
  }

  center = [0, 0];
  centerUpdate(value: any): void {
    // example: {lat: 1091.1028537158786, lng: 420.04585553556507}
    this.center = [value.lat, value.lng];
  }

  getMarkerSvg(color: string): string {
    const guid = uuidv4();
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28"><g filter="url(#a-${guid})"><path fill="url(#b-${guid})" d="M13.5 3A7.539 7.539 0 1 1 6 10.54 7.52 7.52 0 0 1 13.5 3Z"/></g><g filter="url(#c-${guid})"><path fill="#fff" d="M13.5 15a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"/><path stroke="${color}" stroke-linecap="round" stroke-linejoin="round" d="M13.5 14.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"/></g><defs><filter id="a-${guid}" width="27.078" height="27.078" x="0" y="0" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="3"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_918_22"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_918_22" result="shape"/></filter><filter id="c-${guid}" width="21" height="21" x="3" y="3" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="3"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_918_22"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_918_22" result="shape"/></filter><radialGradient id="b-${guid}" cx="0" cy="0" r="1" gradientTransform="matrix(10.7959 0 0 8.25883 13.539 8.884)" gradientUnits="userSpaceOnUse"><stop stop-color="${color}"/><stop offset="1" stop-color="${color}" stop-opacity=".631"/></radialGradient></defs></svg>`;
    return svg;
  }

  buildMarker(color: string): L.DivIcon {
    // from https://stackoverflow.com/questions/23567203/leaflet-changing-marker-color
    const markerHtmlStyles = `
      width: 44px;
      height: 44px;
      display: block;
      position: relative;
      transform: translate(1px, 16px)`;
    const svg = this.getMarkerSvg(color);
    const icon = L.divIcon({
      className: "bitpool-custom-marker",
      iconSize: [44, 44],
      iconAnchor: [22, 44],
      tooltipAnchor: [0, -24],
      popupAnchor: [0, -24],
      html: `<span style="${markerHtmlStyles}">${svg}</span>`
    });
    return icon;
  }


  // #region edit map
  shapeCanged(e: any): void {
    // type: 'pm:create', 'pm:edit', 'pm:remove', 'pm:cut'
    // shape:
    // "Marker"
    // "Rectangle"
    // "Polygon"
    // coordinates:
    // 0,0 - left, bottom
    // shape = "Marker" example: {lat: 621.2850109277236, lng: 225.97339524102776}
    // Other shapes example: [{lat: 805.1634320795039, lng: 265.88747810384376}, ...]
    // layer.feature.geometry.type = "MultiPolygon": [[[{{lat: 805.1634320795039, lng: 265.88747810384376}, ...]],[[{...}, ...]]

    //console.log(e);
    if (e.type === 'pm:cut' && e.layer.feature && e.originalLayer.feature) {
      e.layer.feature.properties = e.originalLayer.feature.properties;
    }
    const layer = e.layer;
    if (layer.feature?.properties) {
      const streamIndex = layer.feature.properties.streamIndex;
      const stream = this.availableStreams[streamIndex];
      if (!stream.geoData) {
        stream.geoData = [];
      }
      const coordinates = e.shape === "Marker" ? layer.getLatLng() : layer.getLatLngs();
      const geometryType = layer.feature.geometry?.type ?? (e.shape === "Marker" ? "Point" : "Polygon");
      let needSave = false;
      switch (e.type) {
        case 'pm:create': { 
          const geoData: StreamOptionGeoData = {
            shape: e.shape,
            geometryType: geometryType,
            coordinates: coordinates,
            guid: uuidv4()
          };
          this.setLayerPropery(layer, "geoData", geoData);
          stream.geoData.push(geoData);
          needSave = true;
          break;
        }
        case 'pm:cut': {
          e.originalLayer.cutted = true;
          const geoData = this.getLayerPropery(e.originalLayer, "geoData") as StreamOptionGeoData;
          if (geoData) {
            geoData.shape = "Polygon";
            geoData.geometryType = geometryType;
            geoData.coordinates = coordinates;
            needSave = true;
          }
          break;
        }
        case 'pm:edit': {
          if (!layer.cutted) {
            const geoData = this.getLayerPropery(layer, "geoData") as StreamOptionGeoData;
            if (geoData) {
              geoData.shape = e.shape;
              geoData.geometryType = geometryType;
              geoData.coordinates = coordinates;
              needSave = true;
            }
          }
          break;
        }
        case 'pm:remove': {   
          const geoData = this.getLayerPropery(layer, "geoData") as StreamOptionGeoData;
          if (geoData) {
            const geoDataIndex = stream.geoData.findIndex(x => x.guid === geoData.guid);
            if (geoDataIndex >= 0) {
              stream.geoData.splice(geoDataIndex, 1);
              needSave = true;
            }
          }       
          break;
        }
      }
      // save widgetConfig
      if (needSave) {
        this.internalChanges = true;
        this.$store.dispatch("dashboard/saveWidget", this.widgetConfig);
      }
    }
  }

  convertCoordinatesToGeoDataFormat(coordinates: any): any[] {
    if (Array.isArray(coordinates)) {
      const result: any[] = [];
      coordinates.forEach(element => {
        result.push(this.convertCoordinatesToGeoDataFormat(element));
      });
      return result;
    } else {
      return [coordinates.lng, coordinates.lat]
    }
  }
  
  loadGeoJson(): void {
    if (this.availableStreams.length) {
      const features: any[] = [];
      const json = {
        type: "FeatureCollection",
        features: features
      };
      this.availableStreams.forEach((stream, streamIndex) => {
        if (stream.geoData?.length) {
          stream.geoData.forEach(geoData => {
            json.features.push({
              type: "Feature",
              properties: {
                streamIndex: streamIndex,
                geoData: geoData
              },
              geometry: {
                type: geoData.geometryType,
                coordinates: this.convertCoordinatesToGeoDataFormat(geoData.coordinates)
              }
            });
          });
        }
      });
      L.geoJSON(json as any, {
        onEachFeature: (feature: any, layer: any) => {
          const streamIndex = this.getLayerPropery(layer, "streamIndex");
          this.setupLayer(layer, streamIndex);
        }
      }).addTo(this.mapObject);
    }
  }

  async hookUpDraw(): Promise<void> {
    if (this.$refs.map) {
      this.mapObject = (this.$refs.map as any).leafletObject;
      this.loadGeoJson();
      this.mapReady = true;
      this.initControls();
      this.mapObject.on('pm:create',(e: any) => {
        const layer = e.layer;
        this.setupLayer(layer, this.selectedStreamIndex);
        this.shapeCanged(e);
      });
    }
  }

  setLayerPropery(layer: any, propName: string, propValue: any): void {
    const feature = layer.feature = layer.feature || {}; // Initialize feature
    feature.type = feature.type || "Feature"; // Initialize feature.type
    const props = feature.properties = feature.properties || {}; // Initialize feature.properties
    props[propName] = propValue;
  }

  getLayerPropery(layer: any, propName: string): any {
    const feature = layer.feature = layer.feature || {}; // Initialize feature
    feature.type = feature.type || "Feature"; // Initialize feature.type
    const props = feature.properties = feature.properties || {}; // Initialize feature.properties
    return props[propName];
  }

  setupLayer(layer: any, streamIndex: number): void {
    layer.on('pm:edit', (e: any) => {
      this.shapeCanged(e);
    });
    layer.on('pm:remove', (e: any) => {
      this.shapeCanged(e);
    });
    layer.on('pm:cut', (e: any) => {
      this.shapeCanged(e);
    });
    const stream = this.availableStreams[streamIndex];
    const color = stream?.hexStreamColor ?? "grey";
    if (layer.setStyle){
      layer.setStyle({color: color});
    }
    this.setLayerPropery(layer, "streamIndex", streamIndex);
    
    // colorized markers
    if (layer.options.icon) {
      const icon = this.buildMarker(color);
      layer.options.icon = icon;
    }
  }

  // external control of the map
  enableEdit = false;

  selectedStreamIndex = 0;

  get selectedStream(): StreamOption | undefined {
    return this.availableStreams[this.selectedStreamIndex];
  }

  getStreamIndex(stream: StreamOption): number {
    return this.availableStreams.findIndex(x => x === stream);
  }

  get availableStreams(): StreamOption[] {
    if (this.wds?.streamOptions?.length) {
      const result = this.wds.streamOptions.filter(x => x.StreamKey);
      return result;
    }
    return [];
  }

  @Watch('selectedStreamIndex', { immediate: false, deep: true })
  onSelectedStreamIndexChanged(): void {
    if (this.mapObject) {
      const color = this.selectedStream?.hexStreamColor ?? "grey";
      const icon = this.buildMarker(color);
      this.mapObject.pm.setGlobalOptions({markerStyle: {icon: icon}})
    }
  }

  enableEditChanged(): void {
    if (this.enableEdit) {
      if (!this.isPaused) {
        this.pause();
      }
    } else {
      this.mapReady = false;
      this.mapObject = null;
    }
    this.initStreamDropdown();
    this.initControls();
  }

  initStreamDropdown(): void {
    if (this.selectedStreamIndex >= this.availableStreams.length) {
      this.selectedStreamIndex = 0;
    } 
  }

  initControls(): void {
    if (this.mapObject?.pm) {
      if (this.enableEdit && this.availableStreams.length) {
        this.mapObject.pm.addControls({
          position: "topleft",
          drawCircle: false,
          drawCircleMarker: false,
          drawText: false,
          drawPolyline: false
        });
        this.onSelectedStreamIndexChanged();
      } else {
        this.mapObject.pm.removeControls();
      }
    }
  }
  // #endregion edit map
}

export default ImageFreeFormWidget;
</script>