<template>
  <div class="default-view-container">
    <div class="default-view-head-section">
      <h1 class="mb-0 mr-5">API Keys</h1>

      <IconField iconPosition="left" class="search-input-box mt-4 lg:mt-0" v-if="state.permissions?.FullAccess">
        <InputIcon class="pi pi-search lg:text-2xl"></InputIcon>
        <InputText
          class="inputfield text-lg lg:text-xl"
          placeholder="Search"
          type="text"
          v-model="search"
          @input="debounceSearch()"
        />
      </IconField>
    </div>
    <div v-if="state.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>API Keys List</h2>
      </header>
      <div class="default-view-body">
        <DataTable
          :value="authKeys" 
          dataKey="Value"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="850px" 
          sortField="Date" 
          :sortOrder="-1"
          class="p-datatable-sm default-visual-table responsive-breakpoint apikeys-table">
          <template #header>
            <div class="table-header">
              <Button 
                label="Generate Api Key" 
                icon="pi pi-plus-circle" 
                class="my-1" 
                @click="openCreateUpdateDialog(null)" 
              />
              <div class="field-checkbox alarm-filter mb-0 my-1">
                <Checkbox
                  id="organisationOnly"
                  :binary="true"
                  v-model="currentOrganisationOnly"
                ></Checkbox>
                <label class="text-lg font-light" for="organisationOnly">Show current organisation only</label>
              </div>
            </div>
          </template>
          <template #empty>
            <div v-if="state.isLoadedAuthKeys" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column :sortable="true" field="Organisation" header="Organisation" headerStyle="width: 18%;"></Column>
          <Column :sortable="true" field="Place" header="Place" headerStyle="width: 18%;"></Column>
          <Column :sortable="true" field="Value" header="Api Key" headerStyle="width: 20%;" bodyClass="apikey-cell">
            <template #body="slotProps">
              <div>
                Bitpool2 {{slotProps.data.Value}}
              </div>
            </template>
          </Column>
          <Column :sortable="true" field="KeyType" header="Key Type" bodyStyle="white-space: nowrap;">
            <template #body="slotProps">
              <div>
                {{keyTypeStr(slotProps.data.KeyType)}}
              </div>
            </template>
          </Column>
          <Column :sortable="true" field="Date" header="Date UTC" headerStyle="width: 15%;">
            <template #body="slotProps">
              <DateTimezoneView :date="slotProps.data.Date" timezone="UTC"/>
            </template>
          </Column>
          <Column :sortable="true" field="ImpersonatedBy" header="Impersonated By"></Column>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div class="inline-flex">
                <Button 
                  icon="pi pi-pencil" 
                  class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                  @click="openCreateUpdateDialog(slotProps.data)" 
                />
                <Button 
                  icon="pi pi-trash" 
                  class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                  @click="openConfirmation(slotProps.data)" 
                />
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>

    <Dialog :header="editApiKey ? 'Edit Api Key' : 'New Api Key'" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="apikeys-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <div v-if="state.newAuthKey" class="flex align-items-center">
          <span class="break-word mr-3">Api Key: {{state.newAuthKey}}</span>
          <Button icon="pi pi-copy" class="p-button-icon-only p-button-rounded p-button-outlined flex-none" @click="copyKey(state.newAuthKey ? state.newAuthKey : '')"></Button>
        </div>
        <BlockUI :blocked="updateInProgress" v-else :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="updateInProgress ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="editRecordPool">Place</label>
              <div>
                <InputText 
                  id="editRecordPool" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Place"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="editRecordType">Key Type</label>
              <div>
                <Dropdown id="editRecordType" v-model="editRecord.KeyType" :options="keyTypes" optionValue="key" optionLabel="name" placeholder="Select Key Type" class="w-full dropdown-lg"/>
              </div>
            </div>
            <div class="field col-12 mb-0">
              <InlineMessage class="m-0 justify-content-start" severity="info">
                <div class="mb-2 text-sm font-light"><b class="mr-1 font-medium">Standard</b>- default type for sites, expired after 7 days.</div>
                <div class="mb-2 text-sm font-light"><b class="mr-1 font-medium">ApiReadWrite</b>- never expired, allow read and write data.</div>
                <div class="mb-2 text-sm font-lightt"><b class="mr-1 font-medium">ApiRead</b>- never expired, allow only read.</div>
                <div class="text-sm font-light"><b class="mr-1 font-medium">ApiReadLimited</b>- never expired, allow only read. Data access is limited. For internal use only, please don't generate api key of this type.</div>
              </InlineMessage>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        <Button v-if="editRecord && !state.newAuthKey" :label="editApiKey ? 'Save' : 'Generate'" :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveRecord" :disabled='!editRecord.Place || updateInProgress' />
      </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="displayConfirmation" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="flex align-items-center">
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete record?</span>
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary"/>
        <Button label="Yes" icon="pi pi-check" @click="deleteRecord" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import InlineMessage from 'primevue/inlinemessage';
import ProgressSpinner from 'primevue/progressspinner';
import Checkbox from "primevue/checkbox";
import Message from 'primevue/message';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import AuthState from "@/store/states/AuthState";
import { CreateAuthKeyDto } from "@/models/user/CreateAuthKeyDto";
import { AuthKeyType } from "@/models/user/AuthKeyType";
import { AuthKeyDto } from "@/models/user/AuthKeyDto";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import copy from 'copy-to-clipboard';
import ToastService from "@/services/ToastService";
import { debounce } from 'throttle-debounce';
import { Watch } from "vue-facing-decorator";
import { useOrganisationStore } from "@/stores/organisation";

@Component({
  
  components: {
    Button,
    InputText,
    Dropdown,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    InlineMessage,
    ProgressSpinner,
    Checkbox,
    Message,
    IconField,
    InputIcon,
    DateTimezoneView
  },
})
class ApiKeysView extends Vue { 
  get state(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get authKeys(): AuthKeyDto[] {
    let result = this.state.authKeys;
    if (this.currentOrganisationOnly) {
      const organisationName = this.organisationStore.currentOrganisation?.Name;
      if (organisationName) {
        result = result.filter(x => x.Organisation === organisationName);
      }
    }
    if (this.searchFinal) {
      const searchLower = this.searchFinal.toLowerCase();
      result = result
        .filter(x => 
          x.Place.toLowerCase().includes(searchLower) || 
          x.Organisation.toLowerCase().includes(searchLower) || 
          x.Value.toLowerCase().includes(searchLower));
    }
    return result;
  }

  search = '';
  searchFinal = '';
  currentOrganisationOnly = false;
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  @Watch('searchFinal', { immediate: false, deep: false })
  @Watch('currentOrganisationOnly', { immediate: false, deep: false })
  onInputChanged(): void {
    //this.loadData();
  }

  created(): void {
    if (this.state.permissions?.FullAccess) {
      this.$store.dispatch("auth/loadAuthKeys");
    }
  }

  keyTypeStr(value: AuthKeyType | number | string): string {
    return typeof value === "string" ? value : AuthKeyType[value];
  }
  
  keyTypes = [
    {name: 'Standard', key: 0},
    {name: 'ApiReadWrite', key: 1},
    {name: 'ApiRead', key: 2},
    {name: 'ApiReadLimited', key: 3}
  ];

  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }

  // #region api key new
  displayCreateUpdateDialog = false;
  editRecord: CreateAuthKeyDto | null = null;
  editApiKey: AuthKeyDto | null = null;
  
  openCreateUpdateDialog(apiKey: AuthKeyDto | null): void {
    this.$store.commit("auth/resetNewAuthKey");
    this.editApiKey = apiKey;
    this.editRecord = {
      Place: apiKey ? apiKey.Place : "",
      KeyType: apiKey ? apiKey.KeyType : AuthKeyType.ApiReadWrite
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.state.createAuthKeyInProgress;
  }

  get updateError(): boolean {
    return this.state.createAuthKeyError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      if (this.editApiKey) {
        await this.$store.dispatch("auth/editAuthKey", { authKey: this.editApiKey.Value, body: this.editRecord });
      } else {
        await this.$store.dispatch("auth/createAuthKey", this.editRecord);
      }
    }
  }
  // #endregion api key new

  // #region api key delete
  displayConfirmation = false;
  selectedRecord: AuthKeyDto | null = null;

  openConfirmation(record: AuthKeyDto | null): void {
    this.selectedRecord = record;
    this.displayConfirmation = true;
  }

  closeConfirmation(): void {
    this.displayConfirmation = false;
  }

  deleteRecord(): void {
    this.closeConfirmation();
    if (this.selectedRecord) {
      this.$store.dispatch("auth/deleteAuthKey", this.selectedRecord.Value);
    }
  }
  // #endregion api key delete
}

export default ApiKeysView;
</script>