export enum WidgetFeature {
  title,
  titleOptional,
  backgroundColor,
  timezone,
  htmlContent,
  iframe,
  image,
  imageFillType,
  file,
  minHeight,
  nogutter,
  link,
  weather,
  units,
  unitsPosition,
  decimalPlaces,
  donut,
  activityGauge,
  dateRange,
  dateRangeCompact,
  dataAggregation,
  yAxis,
  chartBackgroundColorRange,
  dataConditions,
  dataConditionsImage,
  dataConditionsMode,
  gdrs,
  showNullValues,
  stacking,
  maxValue,
  minMaxRange,
  minMaxRangeAuto,
  gaugeInnerSize,
  inactiveColor,
  gaugeRange,
  tableFields,
  leaderboardDirection,
  leaderboardFilter,
  alarm,
  meteringReport,
  setPoint,
  dataIcon,
  widgetMode,
  target,
  text,
  mapInitialZoom,
  booleanValuesName,
  dataStreams,
  dataStreamsExportCSV,
  dataStreamsColor,
  dataStreamsColor2,
  dataStreamsColor2PreviousPeriod,
  dataStreamsTimeIntervals,
  dataStreamsSeriesType,
  dataStreamsSeriesTypeStacked,
  dataStreamsAggregationType,
  dataStreamsAggregationTypeString,
  dataStreamsMultiplier,
  dataStreamsSmooth,
  dataStreamsTimeOffset,
  dataStreamsConditionDuration,
  dataStreamsSkipInTotal,
  dataStreamsIsAccumulating,
  dataStreamsGroups,
  dataStreamsMinMaxRange,
  dataStreamsMinMaxRangeAuto,
  dataStreamsInactiveColor,
  dataStreamsGaugeRange,
  dataStreamsUnits,
  dataStreamsText,
  dataStreamsStateNameConverter,
  dataStreamsLink,
  dataAnalyzeOpenAI,
  dataOptional,
  dataOptionalTimestamp,
  dataOptionalLastPeriodValue,
  dataNegativeState,
  limitColorText,
  dataSites,
  dataSitesLink,
  mapType,
  aiCustomQuestion,
  notificationGroups,
  notificationStatus,
  notificationFilter
}