<template>
  <draggable
    tag="div"
    :list="items"
    handle=".handle"
    @end="dropEvent"
    :group="{ name: 'elements' }"
    :animation="150"
    :fallbackOnBody="true"
    :swapThreshold="0.65"
    :invertSwap="true"
    :direction="getSortDirection"
    :disabled="!reports3.editMode"
    ghostClass="dragging"
    class="bp-grid droppable-area"
    item-key="Uid"
  >
    <template #item="{ element }">
      <div :class="`bp-col-${element.Size}`">
        <!-- Element -->
        <div
          v-if="element.Role === reports3ItemRole.Element"
          class="report-element"
          :class="`${reports3.editMode && element.Uid === reports3.dataOneSelectedElement?.Uid ? 'is-selected' : ''}`"
          :id="`reportConstructorElementContainer-${element.Uid}`"
        >
          <div 
            v-if="reports3.editMode"
            class="report-element-head handle"
            @click="selectElement(element)"
          >
            <Button
              v-tippy="'Delete'"
              @click="deleteItem($event, element)"
              class="p-button-half-white"
            >
              <ReportDeleteSvg/>
            </Button>
            <Button
              v-if="reports3.isCompactMode"
              v-tippy="'Edit'"
              @click="openEditDialog($event, element)"
              class="p-button-half-white"
            >
              <ReportDeleteSvg/>
            </Button>
            <Button
              v-tippy="'More Actions'"
              class="p-button-half-white"
            >
              <ReportMoreActionsSvg/>
            </Button>
          </div>
          <ReportsHtmlElementView
            :element="getElement(element.ElementId)" 
            :configuration="element"
          />
        </div>

        <!-- Grid -->
        <div
          v-else-if="element.Role === reports3ItemRole.Grid"
          class="report-grid"
          :class="`${reports3.editMode && element.Uid === reports3.dataOneSelectedElement?.Uid ? 'is-selected' : ''}`"
          :id="`reportConstructorGridContainer-${element.Uid}`"
        >
          <div
            v-if="reports3.editMode"
            class="report-grid-head handle"
            @click="selectElement(element)"
          >
            <Button
              v-tippy="'Add Grid'"
              @click="addGrid($event, element)"
              class="p-button-half-white"
            >
              <AddReportGridSvg/>
            </Button>
            <Button
              v-tippy="'Add Element'"
              @click="openElementsDialog($event, element)"
              class="p-button-half-white"
            >
              <AddReportElementSvg/>
            </Button>
            <Button
              v-tippy="'Delete'"
              @click="deleteItem($event, element)"
              class="p-button-half-white"
            >
              <ReportDeleteSvg/>
            </Button>
            <Button
              v-if="reports3.isCompactMode"
              v-tippy="'Edit'"
              @click="openEditDialog($event, element)"
              class="p-button-half-white"
            >
              <ReportDeleteSvg/>
            </Button>
            <Button
              v-tippy="'More Actions'"
              class="p-button-half-white"
            >
              <ReportMoreActionsSvg/>
            </Button>
          </div>
          <ReportsHtmlGridView v-model="element.Items"/>
        </div>
      </div>
    </template>
  </draggable>

  <ReportsSelectElementDialogView
    v-model="displayElementsDialog"
    @elementSelected="addElement"
  />
</template>

<script lang="ts">
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { useReports3ElementsStore } from "@/stores/reports3Elements";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import ReportsHtmlElementView from "@/components/views/reports/ReportsHtmlElementView.vue";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { v4 as uuidv4 } from "uuid";
import { useReports3Store } from "@/stores/reports3";
import ReportsSelectElementDialogView from "@/components/views/reports/ReportsSelectElementDialogView.vue";
import draggable from "vuedraggable";
import AddReportElementSvg from "@/components/svg/AddReportElementSvg.vue";
import AddReportGridSvg from "@/components/svg/AddReportGridSvg.vue";
import ReportMoreActionsSvg from "@/components/svg/ReportMoreActionsSvg.vue";
import ReportDeleteSvg from "@/components/svg/ReportDeleteSvg.vue";
import { Report3ElementFeatures } from "@/models/reports/v3/Report3ElementFeatures";

@Component({
  components: {
    Button,
    ReportsHtmlElementView,
    ReportsSelectElementDialogView,
    draggable,
    AddReportElementSvg,
    AddReportGridSvg,
    ReportMoreActionsSvg,
    ReportDeleteSvg
  },
  directives: {
  }
})
class ReportsHtmlGridView extends Vue {
  @Model items!: Reports3ElementConfiguration[];

  reports3 = useReports3Store();
  reports3Elements = useReports3ElementsStore();

  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3.dataOneElements?.length) {
      const element = this.reports3.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }

  selectElement(element: Reports3ElementConfiguration): void {
    if (this.reports3.dataOneSelectedElement?.Uid !== element.Uid) {
      this.reports3.prepareAdditionalParameters(element);
      this.reports3.dataOneSelectedElement = element;
    }
  }

  deleteItem(event: Event, element: Reports3ElementConfiguration): void {
    event.stopPropagation();
    const index = this.items.findIndex((x) => x.Uid === element.Uid);
    if (index !== -1) {
      this.items.splice(index, 1);
    }
  }

  addGrid(event: Event, element: Reports3ElementConfiguration): void {
    event.stopPropagation();
    if (element.Role === Reports3ItemRole.Grid) {
      const item: Reports3ElementConfiguration = {
        Uid: uuidv4(),
        Size: 12,
        Role: Reports3ItemRole.Grid,
        ElementId: "",
        Items: [],
        DatasourceId: null,
        FeaturesConfiguration: null,
        AdditionalParameters: null
      };
      if (!element.Items) {
        element.Items = [];
      }
      element.Items.push(item);
      this.reports3.dataOneSelectedElement = item;
    }
  }

  displayElementsDialog = false;
  selectedElement: Reports3ElementConfiguration | null = null;

  openElementsDialog(event: Event, element: Reports3ElementConfiguration): void {
    event.stopPropagation();
    this.selectedElement = element;
    this.displayElementsDialog = true;
  }

  addElement(element: Reports3ElementEntity): void {
    if (this.selectedElement?.Role === Reports3ItemRole.Grid) {
      const item: Reports3ElementConfiguration = {
        Uid: uuidv4(),
        Size: element.DefaultSize,
        Role: Reports3ItemRole.Element,
        ElementId: element.Id,
        Items: null,
        DatasourceId: null,
        FeaturesConfiguration: {} as Record<Report3ElementFeatures, any>,
        AdditionalParameters: {}
      };
      if (!this.selectedElement.Items) {
        this.selectedElement.Items = [];
      }
      if (!this.reports3.dataOneElements) {
        this.reports3.dataOneElements = [];
      }
      if (!this.reports3.dataOneElements.find((x) => x.Id === element.Id)) {
        this.reports3.dataOneElements.push(element);
      }
      this.reports3.prepareAdditionalParameters(item);
      this.selectedElement.Items.push(item);
      this.reports3.dataOneSelectedElement = item;
      this.displayElementsDialog = false;
    }
  }

  reports3ItemRole = Reports3ItemRole;

  dropEvent(event: any): void {
    // nothing
  }

  getSortDirection(event: DragEvent, target: HTMLElement, dragEl: HTMLElement): string {
    // console.log(event);
    // console.log(target);
    // console.log(dragEl);
    if (target && target.classList.contains("grid")) {
      // drag to grid
      return "vertical";
    } else {
      // drag to widget
      return "horizontal";
    }
  }

  openEditDialog(event: Event, element: Reports3ElementConfiguration): void {
    event.stopPropagation();
    this.selectElement(element);
    if (this.reports3.isCompactMode) {
      this.reports3.displayConfigurationDialog = true;
    }
  }
}

export default ReportsHtmlGridView;
</script>