import { BitStreamEntity } from "@/models/BitStreamEntity";
import { PoolExportEntity } from "@/models/PoolExportEntity";
import { StreamDuplicates } from "@/models/StreamDuplicates";

export default class PoolToolsState {
  isLoadedDuplicates = false;
  mergeAmount = 0;
  mergeError = false;
  streamDuplicates: StreamDuplicates[] = [];

  isLoadedEmpty = false;
  streamsEmpty: BitStreamEntity[] = [];
  inProgressDeleteEmpty = false;

  isLoadedPoolExport = false;
  poolExports: PoolExportEntity[] = [];
  inProgressRequestPoolExport = false;
}
