
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { v4 as uuidv4 } from "uuid";

class ReportPdfHelper {  
  generateHtmlForPdfPreview(editRecord: Reports3Entity, elements: Reports3ElementEntity[],
    globalStyles: string = "", organisationName: string = "Organisation"
  ): string {
    const html = this.generateHtml(
      editRecord,
      elements,
      globalStyles,
      organisationName
    );
    return html;
  }

  private generateHtml(editRecord: Reports3Entity, elements: Reports3ElementEntity[],
    globalStyles: string = "", organisationName: string = "Organisation"
  ): string {
    const [gridHtml, gridCss, gridJs] = this.generateGridHtml(editRecord.Name, editRecord.Items, elements, organisationName);
    const globalJs = `const elementListReady = [];
      const elementList = [];
      async function reportReady() {
        await document.fonts.ready;
        while (true) {
          if (elementListReady.length >= elementList.length) {
            // check if all elements are ready
            let isOk = true;
            for (const element of elementList) {
              if (!elementListReady.includes(element)) {
                isOk = false;
                break;
              }
            }
            if (isOk) {
              break;
            }
          }
          await new Promise(resolve => setTimeout(resolve, 1000));
        }
      }`;
    return this.getGeneratedPage(gridHtml,`${globalStyles}\n${gridCss}`, `${globalJs}\n${gridJs}`);
  }

  private generateGridHtml(reportName: string,configurations: Reports3ElementConfiguration[], elements: Reports3ElementEntity[],
    organisationName: string = "Organisation"
  ): [string, string, string] {
    let resultHtml = "";
    let resultCss = "";
    let resultJs = "";
    for (const configuration of configurations) {
      if (configuration.Role === Reports3ItemRole.Grid) {
        if (configuration.Items?.length) {
          const [html, css, js] = this.generateGridHtml(reportName, configuration.Items, elements, organisationName);
          resultHtml = `${resultHtml}<div class="bp-col-${configuration.Size}">${html}</div>`;
          resultCss = `${resultCss}\n${css}`;
          resultJs = `${resultJs}\n${js}`;
        }
      } else {
        const [html, css, js] = this.generateElementHtml(reportName, configuration, elements);
        resultHtml = `${resultHtml}<div class="bp-col-${configuration.Size}">${html}</div>`;
        resultCss = `${resultCss}\n${css}`;
        resultJs = `${resultJs}\n${js}`;
      }
    }

    return [`<div class="bp-grid">${resultHtml}</div>`, resultCss, resultJs];
  }

  private generateElementHtml(reportName: string, configuration: Reports3ElementConfiguration, elements: Reports3ElementEntity[],
    organisationName: string = "Organisation"
  ): [string, string, string] {
    const element = configuration.ElementId ? elements.find(x => x.Id === configuration.ElementId) : undefined;
    if (!element) {
      return ["", "", ""];
    }
    const parentId = `report-element-${uuidv4()}`;
    const parameters: Record<string, any> = {};
    if (element.AdditionalParameters) {
      for (const param of element.AdditionalParameters) {
        parameters[param.Name] = param.DefaultValue;
      }
    }
    if (configuration?.AdditionalParameters) {
      for (const key in configuration.AdditionalParameters) {
        parameters[key] = configuration.AdditionalParameters[key];
      }
    }
    parameters.organisationName = organisationName;
    parameters.reportName = reportName;
    let htmlWithParameters = element.HTML;
    let cssWithParameters = element.CSS;
    for (const key in parameters) {
      htmlWithParameters = htmlWithParameters.replace(new RegExp(`\\{${key}\\}`, 'g'), parameters[key]);
      cssWithParameters = cssWithParameters.replace(new RegExp(`\\{${key}\\}`, 'g'), parameters[key]);
    }
    // function displayData(parent, parameters, data, dataConfiguration, libraries, onReadyEvent)
    const result: [string, string, string] = [
      `<div id="${parentId}" class="report-element bp-col-${configuration.Size}">${htmlWithParameters}</div>`,
      `#${parentId} { ${cssWithParameters} }`,
      `elementList.push("${parentId}");
      (function() { 
        ${element.JavaScript}
        if (displayData) {
          const parameters = ${JSON.stringify(parameters)};
          const libraries = {
            Highcharts: Highcharts
          };
          const onReadyEvent = function() {
            elementListReady.push("${parentId}");
          };
          displayData(document.querySelector("#${parentId}"), parameters, [], {}, libraries, onReadyEvent);
        } 
      })();`
    ];
    return result;
  }

  private getGeneratedPage(html: string, css: string, js: string): string {
    const cssLine = css && `<style>${css}</style>`;
    const jsLine = js && `<script>${js}</${"script"}>`; // note: regular script close tag breaks vue parser
    const source = `
      <html>
        <head>
          <script src="https://code.highcharts.com/11.4.8/highcharts.js"></script>
          ${cssLine}
        </head>
        <body>
          ${html || ''}
          ${jsLine}
        </body>
      </html>`;
    return source;
  }
}

export default new ReportPdfHelper();
