// Add data types to window.navigator for use in this file. See https://www.typescriptlang.org/docs/handbook/triple-slash-directives.html#-reference-types- for more info.
/// <reference types="user-agent-data-types" />

import { createApp, App, ComponentPublicInstance } from "vue";
import localeManager from "@/localeManager";
import PrimeVue from "primevue/config";
import BadgeDirective from 'primevue/badgedirective';
import { createPinia } from "pinia";
import ToastService from "primevue/toastservice";
import ConfirmationService from 'primevue/confirmationservice';
import VueTippy from 'vue-tippy';
import * as Highcharts from 'highcharts';
import VueSocialSharing from 'vue-social-sharing'
import store from "./store";
import CustomWindow from "./CustomWindow";
import DateHelper from "./helpers/DateHelper";
// pages
import LoginPage from "./components/pages/LoginPage.vue";
import DataPage from "./components/pages/DataPage.vue";
import ViewPage from "./components/pages/ViewPage.vue";
import LegalPage from "./components/pages/LegalPage.vue";
import ReportManagerPage from "./components/pages/ReportManagerPage.vue";
import AuthCallbackPage from "./components/pages/AuthCallbackPage.vue";
// helpers
import ToastRoot from "./components/ToastRoot.vue";
import ConfirmationRoot from "./components/ConfirmationRoot.vue";
import HotKeyRoot from "./components/HotKeyRoot.vue";
import HolidaysRoot from "./components/HolidaysRoot.vue";
// legacy widgets
import UnknownComponent from "./components/UnknownComponent.vue";
import BasicChartWidget from "./components/widgets/BasicChartWidget.vue";
import ConditionDurationWidget from "./components/widgets/ConditionDurationWidget.vue";
import DonutWidget from "./components/widgets/DonutWidget.vue";
import PieWidget from "./components/widgets/PieWidget.vue";
import FunnelWidget from "./components/widgets/FunnelWidget.vue";
import ActivityGaugeWidget from "./components/widgets/ActivityGaugeWidget.vue";
import ColumnRangeWidget from "./components/widgets/ColumnRangeWidget.vue";
import StackedChartWidget from "./components/widgets/StackedChartWidget.vue";
import HeatmapWidget from "./components/widgets/HeatmapWidget.vue";
import ReportPdfWidget from "./components/widgets/ReportPdfWidget.vue";

// player unlock for safari
import { Howl } from 'howler';
const palyerUnlock = new Howl({
  src: ['sound.mp3']
});

// maps
import "leaflet";
import "leaflet.gridlayer.googlemutant";
import "@geoman-io/leaflet-geoman-free";
// https://stackoverflow.com/questions/72904521/how-to-fix-default-marker-icon-png-not-found-in-vue-2-leaflet-app
import L from "leaflet";
delete (L.Icon.Default.prototype as any)._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'leaflet/dist/images/marker-icon-2x.png',
  iconUrl: 'leaflet/dist/images/marker-icon.png',
  shadowUrl: 'leaflet/dist/images/marker-shadow.png'
});

// moved to theme-light and theme-dark
//import "./app.scss";

import axios from "axios";
import { debounce, throttle } from 'throttle-debounce';
import NavigationHelper from "./helpers/NavigationHelper";
import { Emitter } from "mitt";
import EventBusHelper from "./helpers/EventBusHelper";
import { useOrganisationStore } from "./stores/organisation";
import { useSystemStore } from "./stores/system";

declare const window: CustomWindow;

Highcharts.Chart.prototype.showResetZoom = function (): void {
  // disable build in reset zoom button
};
Highcharts.setOptions({
  plotOptions: {
    pie: {
      borderRadius: 0
    },
    line: {
      lineWidth: 2
    },
    spline: {
      lineWidth: 2
    },
    series: {
      events: {
        mouseOver: function() {
          // Hack for https://bitpool.atlassian.net/browse/BT-504
          const tooltipContainer = document.getElementsByClassName("highcharts-tooltip-container");
          if (tooltipContainer && tooltipContainer.length > 0) {
            // Remove all tooltips
            for (let i = 0; i < tooltipContainer.length; i++) {
              tooltipContainer[i].remove();
            }
          }
        }
      }
    }
  }
});

// Add a 401 response interceptor
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      error.response.data =
        "Authorization key has expired, please log in again";
      NavigationHelper.logout();
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);
axios.defaults.baseURL = window.API.url;

const pinia = createPinia();
const systemStore = useSystemStore(pinia);

store.commit("setApiUrl", window.API.url);

systemStore.isMobile = window.mobilecheck();

function isIOs() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
  // iPad on iOS 13 detection
  || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
systemStore.isIOs = isIOs();

function getOS() {
  const userAgent = window.navigator.userAgent,
    platform = window.navigator?.userAgentData?.platform || window.navigator.platform,
    macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
    windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
    iosPlatforms = ['iPhone', 'iPad', 'iPod'];
  let os = null;

  if (macosPlatforms.indexOf(platform) !== -1) {
    os = 'Mac OS';
  } else if (iosPlatforms.indexOf(platform) !== -1) {
    os = 'iOS';
  } else if (windowsPlatforms.indexOf(platform) !== -1) {
    os = 'Windows';
  } else if (/Android/.test(userAgent)) {
    os = 'Android';
  } else if (/Linux/.test(platform)) {
    os = 'Linux';
  }

  return os;
}
systemStore.os = getOS() ?? "";

const minimizeNav = localStorage.getItem("minimizeNav");
store.commit("setMinimizeNav", minimizeNav === "true");
store.commit("auth/loadDevOptions");
window.vuexStore = store;
window.vueDateHelper = DateHelper;
window.vuePiniaStores = {
  // share pinia stores with angular
  "organisation": useOrganisationStore(pinia)
}

// ios top bar fix
// https://dev.to/maciejtrzcinski/100vh-problem-with-ios-safari-3ge9
const appHeight = () => {
  const appHeightPx = `${window.innerHeight}px`;
  store.commit("setAppHeight", appHeightPx);
};
const appHeightThrottle = throttle(100, () => {
  appHeight();
}, { noLeading: true });
appHeight();

// window resize events
const emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();
const resizeThrottle = throttle(1000, () => {
  emitter.emit("window_size_changed_throttle-1000", "");
}, { noLeading: true });
const resizeDebounce = debounce(100, () => {
  emitter.emit("window_size_changed_debounce", "");
});
window.addEventListener("resize", onResize);
function onResize(): void {
  appHeightThrottle();
  resizeThrottle();
  resizeDebounce();
}

function setupWidget(app: App, element: Element): ComponentPublicInstance {
  const result = app
    .use(store)
    .use(pinia)
    .use(localeManager.i18n)
    .use(PrimeVue, { ripple: true })
    .directive('badge', BadgeDirective)
    .use(VueTippy, { defaultProps: { theme: 'material', touch: 'hold' }})
    .use(VueSocialSharing)
    .mount(element);
  localeManager.primevue = app.config.globalProperties.$primevue;
  return result;
}

window.initVue = function (
  element: Element,
  componentName: string,
  angularScope: any
): ComponentPublicInstance | null {
  console.log(`VUE Init: ${componentName}`);
  let result: ComponentPublicInstance | null = null;
  switch (componentName) {
    // pages
    case "LoginPage": {
      result = setupWidget(
        createApp(LoginPage, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "DataPage": {
      result = setupWidget(
        createApp(DataPage, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "ViewPage": {
      result = setupWidget(
        createApp(ViewPage, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "LegalPage": {
      result = setupWidget(
        createApp(LegalPage, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "ReportManagerPage": {
      result = setupWidget(
        createApp(ReportManagerPage, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "AuthCallbackPage": {
      result = setupWidget(
        createApp(AuthCallbackPage, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    // helpers
    case "ToastRoot": {
      result = setupWidget(
        createApp(ToastRoot, { angularOptions: { angularScope } })
          .use(ToastService),
        element
      );
      break;
    }
    case "ConfirmationRoot": {
      result = setupWidget(
        createApp(ConfirmationRoot, { angularOptions: { angularScope } })
          .use(ConfirmationService),
        element
      );
      break;
    }
    case "HotKeyRoot": {
      result = setupWidget(
        createApp(HotKeyRoot, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "HolidaysRoot": {
      result = setupWidget(
        createApp(HolidaysRoot, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    default: {
      result = setupWidget(
        createApp(UnknownComponent, { name: componentName }),
        element
      );
      break;
    }
    // widgets
    case "BasicChartWidget": {
      result = setupWidget(
        createApp(BasicChartWidget, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "ConditionDurationWidget": {
      result = setupWidget(
        createApp(ConditionDurationWidget, {
          angularOptions: { angularScope },
        }),
        element
      );
      break;
    }
    case "DonutWidget": {
      result = setupWidget(
        createApp(DonutWidget, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "PieWidget": {
      result = setupWidget(
        createApp(PieWidget, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "FunnelWidget": {
      result = setupWidget(
        createApp(FunnelWidget, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "ActivityGaugeWidget": {
      result = setupWidget(
        createApp(ActivityGaugeWidget, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "ColumnRangeWidget": {
      result = setupWidget(
        createApp(ColumnRangeWidget, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "StackedChartWidget": {
      setupWidget(
        createApp(StackedChartWidget, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "HeatmapWidget": {
      result = setupWidget(
        createApp(HeatmapWidget, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
    case "ReportPdfWidget": {
      result = setupWidget(
        createApp(ReportPdfWidget, { angularOptions: { angularScope } }),
        element
      );
      break;
    }
  }
  return result;
};
