import { IsSharedModel3 } from "@/models/dashboard/IsSharedModel3";

export default class ShareState {
  isLoadedShared = false;
  isShared: IsSharedModel3 | null = null;
  guidIsShared = "";

  isLoadedShareId = false;
  isLoadedShareIdTree = false;
  shareId: string | null = null;
  shareIdTree: string | null = null;
  guidShareId = "";
  guidShareIdTree = "";

  isLoadedShortUrl = false;
  isLoadedShortUrlTree = false;
  shortUrl: string | null = null;
  shortUrlTree: string | null = null;
  guidShortUrl = "";
  guidShortUrlTree = "";

  // more networks examples https://nicolasbeauvais.github.io/vue-social-sharing/?path=/story/vuesocialsharing--multiple-share-networks
  // icons https://www.primefaces.org/primevue/icons
  networks = [
    { network: 'email', name: 'Email', icon: 'pi pi-envelope', color: '#057099' },
    { network: 'facebook', name: 'Facebook', icon: 'pi pi-facebook', color: '#1877f2' },
    { network: 'twitter', name: '', icon: 'icon-x', color: '#1B2732' },
    { network: 'linkedin', name: 'LinkedIn', icon: 'pi pi-linkedin', color: '#007bb5' },
    { network: 'whatsapp', name: 'Whatsapp', icon: 'pi pi-whatsapp', color: '#25d366' }
  ];
}
