import ErrorHelper from "@/helpers/ErrorHelper";
import { HaystackConfig } from "@/models/HaystackConfig";
import ToastService from "@/services/ToastService";
import axios from "axios";
import HaystackServerState from "./states/HaystackServerState";
import { defineStore } from 'pinia';

const useHaystackServerStore = defineStore('haystackServer', {
  state: (): HaystackServerState => ({
    configs: [] as HaystackConfig[],
    isLoaded: false,
    updateInProgress: false,
    updateError: false
  }),
  actions: {
    async load() {
      try {
        this.isLoaded = false;
        this.configs = [];
        const url = `rest/Haystack/server`;
        const response = await axios.get<HaystackConfig[]>(url);
        this.configs = response.data;
        this.isLoaded = true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load haystack server config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.configs = [];
        this.isLoaded = true;
      }
    },
    async createUpdate(body: HaystackConfig) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/Haystack/server`;
        const response = await axios.post<HaystackConfig>(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.updateInProgress = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
        if (body.Id) {
          const index = this.configs.findIndex((x) => x.Id === body.Id);
          if (index >= 0) {
            this.configs[index] = response.data;
          }
        } else {
          this.configs.push(response.data);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save haystack server config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateInProgress = false;
        this.updateError = true;
      }
    },
    async delete(id: string) {
      try {
        const url = `rest/Haystack/server/${id}`;
        await axios.delete(url);
        ToastService.showToast("success", "", "Changes saved", 5000);
        for (let i = this.configs.length - 1; i >= 0; --i) {
          if (this.configs[i].Id === id) {
            this.configs.splice(i, 1);
            break;
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete haystack server config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
  },
});

export default useHaystackServerStore;