import DateHelper from "@/helpers/DateHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
import { BitStreamEntity } from "@/models/BitStreamEntity";
import { MergeStreamDuplicate } from "@/models/MergeStreamDuplicate";
import { PoolExportEntity } from "@/models/PoolExportEntity";
import { StreamDuplicates } from "@/models/StreamDuplicates";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import PoolToolsState from "../states/PoolToolsState";

const getters = <GetterTree<PoolToolsState, any>>{};

const mutations = <MutationTree<PoolToolsState>>{};

const actions = <ActionTree<PoolToolsState, any>>{
  async loadDuplicates({ state }, poolKey: string) {
    try {
      state.isLoadedDuplicates = false;
      const url = `rest/BitPool_V1/pool/${poolKey}/duplicatesbyname`;
      const response = await axios.get<StreamDuplicates[]>(url);
      state.streamDuplicates = response.data;
      state.mergeAmount = 0;
      state.isLoadedDuplicates = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load duplicates",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.streamDuplicates = [];
      state.isLoadedDuplicates = true;
    }
  },
  async mergeStreamDuplicates({ state }, body: MergeStreamDuplicate) {
    try {
      state.mergeError = false;
      const url = `rest/BitPool_V1/pool/mergestreamduplicates`;
      const response = await axios.post<number>(url, body);
      state.mergeAmount += response.data;
    } catch (error) {
      state.mergeError = true;
      ToastService.showToast(
        "error",
        "Can't merge duplicates",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async loadEmpty({ state }, poolKey: string) {
    try {
      state.isLoadedEmpty = false;
      const url = `rest/BitPool_V1/pool/${poolKey}/empty`;
      const response = await axios.get<BitStreamEntity[]>(url);
      if (response.data && response.data.length) {
        response.data.forEach((stream) => {
          if (stream.LastTimestamp) {
            stream.LastTimestamp = DateHelper.parseFromMicrosoftString(
              stream.LastTimestamp as string
            );
          }
          if (stream.FirstTimestamp) {
            stream.FirstTimestamp = DateHelper.parseFromMicrosoftString(
              stream.FirstTimestamp as string
            );
          }
          if (stream.RegistrationDate) {
            stream.RegistrationDate = DateHelper.parseFromMicrosoftString(
              stream.RegistrationDate as string
            );
          }
        });
      }
      state.streamsEmpty = response.data;

      state.isLoadedEmpty = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load duplicates",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.streamsEmpty = [];
      state.isLoadedEmpty = true;
    }
  },
  async deleteEmpty({ state }, poolKey: string) {
    try {
      state.inProgressDeleteEmpty = true;
      const url = `rest/BitPool_V1/pool/${poolKey}/empty`;
      const response = await axios.delete<number>(url);
      state.streamsEmpty = [];
      ToastService.showToast(
        "success",
        "",
        `Streams deleted: ${response.data}`,
        5000
      );
      state.inProgressDeleteEmpty = false;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete empty streams",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.inProgressDeleteEmpty = false;
    }
  },
  async loadPoolExport({ state }, poolKey: string) {
    try {
      state.isLoadedPoolExport = false;
      const url = `rest/BitPool_V2/PoolExport/${poolKey}`;
      const response = await axios.get<PoolExportEntity[]>(url);
      if (response.data && response.data.length) {
        response.data.forEach((poolExport) => {
          if (poolExport.DateCreated) {
            poolExport.DateCreated = DateHelper.parseFromMicrosoftString(
              poolExport.DateCreated as string
            );
          }
          if (poolExport.DateCompleted) {
            poolExport.DateCompleted = DateHelper.parseFromMicrosoftString(
              poolExport.DateCompleted as string
            );
          }
        });
      }
      state.poolExports = response.data;

      state.isLoadedPoolExport = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load pool exports",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.poolExports = [];
      state.isLoadedPoolExport = true;
    }
  },
  async requestPoolExport({ state }, poolKey: string) {
    try {
      state.inProgressRequestPoolExport = true;
      const url = `rest/BitPool_V2/PoolExport`;
      const response = await axios.post<PoolExportEntity>(url, poolKey, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.data){
        if (response.data.DateCreated) {
          response.data.DateCreated = DateHelper.parseFromMicrosoftString(
            response.data.DateCreated as string
          );
        }
        state.poolExports.unshift(response.data);
      }
      
      ToastService.showToast(
        "info",
        "Pool Export",
        "Pool export is queued. Please wait. You will receive an email with your data.",
        8000
      );
      state.inProgressRequestPoolExport = false;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't add pool export to queue",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.inProgressRequestPoolExport = false;
    }
  }
};

const PoolToolsModule = {
  namespaced: true,
  state: new PoolToolsState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default PoolToolsModule;
