import DateHelper from "@/helpers/DateHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
import { CollectedDataInfo } from "@/models/CollectedDataInfo";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import LoginState from "../states/LoginState";
import localeManager from "@/localeManager";

const getters = <GetterTree<LoginState, any>>{};

const mutations = <MutationTree<LoginState>>{
  setUsername(state, value) {
    state.username = value;
  },
  setPassword(state, value) {
    state.password = value;
  },
  setDisplaySection(state, value) {
    state.displaySection = value;
  },
  setLoginSuccess(state, value) {
    state.loginSuccess = value;
  },
  setSignupSuccess(state, value) {
    state.signupSuccess = value;
  },
};

const actions = <ActionTree<LoginState, any>>{
  async loadCollectedDataInfo({ state }) {
    try {
      if (!state.collectedDataInfo) {
        const response = await axios.get<CollectedDataInfo>(
          "/rest/BitPool_V1/CollectedDataInfo"
        );
        if (response.data && response.data.Date) {
          response.data.Date = DateHelper.parseFromMicrosoftString(
            response.data.Date as string
          );
        }
        state.collectedDataInfo = response.data;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        localeManager.t("login.loadCollectedDataInfoError"),
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
};

const LoginModule = {
  namespaced: true,
  state: new LoginState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default LoginModule;
