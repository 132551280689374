export enum BitPoolUserRolesTypes {
  None = -1,

  Administrator = 0,

  Owner = 1,

  CoOwner = 2,

  Contributor = 3,

  Reader = 4,
}
