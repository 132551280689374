import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios, { AxiosResponse } from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { v4 as uuidv4 } from "uuid";
import WidgetDataState from "../states/WidgetDataState";
import { AggregatedDataRequest } from "@/models/AggregatedDataRequest";
import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import { StreamModel } from "@/models/StreamModel";
import LoadAlarmRequest from "@/models/LoadAlarmRequest";
import { AlarmStatus } from "@/models/AlarmStatus";
import { ApplyTariffDto } from "@/models/tariff/ApplyTariffDto";
import { ApplyTariffDataDto } from "@/models/tariff/ApplyTariffDataDto";
import DateHelper from "@/helpers/DateHelper";
import { ReportInput } from "@/models/reports/ReportInput";

const getters = <GetterTree<WidgetDataState, any>>{
};

const mutations = <MutationTree<WidgetDataState>>{
  maximizeWidget(state, id: string): void {
    state.maximizedWidget = id;
  },
  unmaximizeWidget(state): void {
    state.maximizedWidget = null;
  },
  unloadData(state): void {
    state.isLoaded = {};
    state.requestGuid = {};
    state.data = {};
    state.exportInProgress = {};
    state.error = {};
    state.requestBody = {};
    state.streams = {};
    state.lastDateOnCurrentTimeRange = {};
    state.dataAlarm = {};
    state.dataTariffSimulation = {};
    state.dataTariffCalculator = {};
    state.dataReport = {};
    state.maximizedWidget = null;
  }
};

const actions = <ActionTree<WidgetDataState, any>>{
  async loadWidgetData({ state }, data: [string, AggregatedDataRequest]) {
    const widgetGuid = data[0];
    const requestBody = data[1];
    try {
      const guid = uuidv4();
      state.requestGuid[widgetGuid] = guid;
      state.isLoaded[widgetGuid] = false;
      state.requestBody[widgetGuid] = [new Date(), JSON.stringify(requestBody)];
      const url = `rest/BitPool_V2/stream/values/Highcharts`;
      const response = await axios.post<AggregatedDataHighchartsResponse[]>(url, requestBody, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (state.requestGuid[widgetGuid] === guid) {
        state.data[widgetGuid] = response.data;
        if (response.data && response.data.length) {
          const errors: string[] = [];
          response.data.forEach(element => {
            if (element.Error) {
              errors.push(`Stream [${element.StreamKey}]: ${element.Error}`);
            }
          });
          if (errors.length) {
            state.error[widgetGuid] = errors.join("; ");
          } else {
            state.error[widgetGuid] = null;
          }
        }
        state.isLoaded[widgetGuid] = true;
      }
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        "Can't load data",
        errorMessage,
        5000
      );
      state.error[widgetGuid] = errorMessage;
      state.data[widgetGuid] = null;
      state.isLoaded[widgetGuid] = true;
    }
  },
  async exportWidgetData({ state }, data: [string, AggregatedDataRequest]) {
    const widgetGuid = data[0];
    const requestBody = data[1];
    try {
      if (state.exportInProgress[widgetGuid]) {
        ToastService.showToast(
          "warn",
          "Export",
          "Previous export in progress, please wait",
          5000
        );
      } else {
        ToastService.showToast(
          "info",
          "Export",
          "Export in progress, please wait",
          5000
        );
        state.exportInProgress[widgetGuid] = true;
        const url = `rest/BitPool_V2/stream/values/Export`;
        const response1 = await axios.post<string>(url, requestBody, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        if (response1.data) {
          const response = await axios.get(response1.data, { responseType: "blob" });
          const href = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = href;
          link.setAttribute("download", "export-widget-data.csv");
          document.body.appendChild(link);
          link.click();
          ToastService.showToast(
            "success",
            "Export",
            "Success",
            5000
          );
        }
        state.exportInProgress[widgetGuid] = false;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't export data",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.exportInProgress[widgetGuid] = false;
    }
  },
  async loadStreams({ state }, data: [string, string[]]) {
    const widgetGuid = data[0];
    const streamKeys = data[1];
    try {
      state.streams[widgetGuid] = [false, null];
      const url = `rest/BitPool_V1/stream/web`;
      const response = await axios.post<StreamModel[]>(url, streamKeys);
      // todo: move to common file
      // if (response.data.LastUpdates) {
      //   response.data.LastUpdates = DateHelper.parseFromMicrosoftString(
      //     response.data.LastUpdates as string
      //   );
      // }
      // if (response.data.FirstUpdates) {
      //   response.data.FirstUpdates = DateHelper.parseFromMicrosoftString(
      //     response.data.FirstUpdates as string
      //   );
      // }
      // if (response.data.Tags && response.data.Tags.length) {
      //   response.data.Tags = response.data.Tags.sort();
      // }
      // if (typeof response.data.DataType === "string") {
      //   response.data.DataType =
      //     StreamDataType[response.data.DataType as keyof typeof StreamDataType];
      // }
      // if (typeof response.data.PostProcessingType === "string") {
      //   response.data.PostProcessingType =
      //     PostProcessingType[
      //       response.data.PostProcessingType as keyof typeof PostProcessingType
      //     ];
      // }
      // if (typeof response.data.VirtualType === "string") {
      //   response.data.VirtualType =
      //     StreamVirtualType[
      //       response.data.VirtualType as keyof typeof StreamVirtualType
      //     ];
      // }
      state.streams[widgetGuid][1] = response.data;
      state.streams[widgetGuid][0] = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.streams[widgetGuid][1] = null;
      state.streams[widgetGuid][0] = true;
    }
  },
  async loadLastDateOnCurrentTimeRange({ state }, data: [string, AggregatedDataRequest]) {
    const widgetGuid = data[0];
    const requestBody = data[1];
    try {
      state.lastDateOnCurrentTimeRange[widgetGuid] = [false, null];
      const url = `rest/BitPool_V2/stream/values/LastDate`;
      const response = await axios.post<Record<string, number>>(url, requestBody);
      state.lastDateOnCurrentTimeRange[widgetGuid][1] = response.data;
      state.lastDateOnCurrentTimeRange[widgetGuid][0] = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load last date",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.lastDateOnCurrentTimeRange[widgetGuid][1] = null;
      state.lastDateOnCurrentTimeRange[widgetGuid][0] = true;
    }
  },
  async loadDataAlarm({ state }, data: [string, LoadAlarmRequest]) {
    const widgetGuid = data[0];
    const request = data[1];
    try {
      state.requestBody[widgetGuid] = [new Date(), JSON.stringify(request)];
      state.dataAlarm[widgetGuid] = [false, null];
      const url = `rest/BitPool_V2/stream/${request.streamKey}/alarm/status?alarmOperator=${request.alarmOperator}&trigger=${request.trigger}&aggregation=${request.aggregation}&aggregationType=${request.aggregationType}&aggregationPeriod=${request.aggregationPeriod}`;
      const response = await axios.get<AlarmStatus>(url);
      if (response.data.LastNormalDate && typeof response.data.LastNormalDate === "string") {
        response.data.LastNormalDate = DateHelper.parseFromMicrosoftString(response.data.LastNormalDate);
      }
      state.dataAlarm[widgetGuid][1] = response.data;
      state.dataAlarm[widgetGuid][0] = true;
      state.error[widgetGuid] = null;
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        "Can't load alarm state",
        errorMessage,
        5000
      );
      state.error[widgetGuid] = errorMessage;
      state.dataAlarm[widgetGuid][1] = null;
      state.dataAlarm[widgetGuid][0] = true;
    }
  },
  async loadDataTariffSimulation({ state }, data: [string, string[], ApplyTariffDto]) {
    const widgetGuid = data[0];
    const tariffIds = data[1];
    const requestBody = data[2];
    try {
      state.requestBody[widgetGuid] = [new Date(), `${JSON.stringify(tariffIds)}---${JSON.stringify(requestBody)}`];
      state.dataTariffSimulation[widgetGuid] = [false, null];
      if (tariffIds.length) {
        const promises: Promise<AxiosResponse<number>>[] = [];
        tariffIds.forEach(tariffId => {
          const url = `rest/BitPool_V2/tariffs/${tariffId}/apply`;
          promises.push(axios.post<number>(url, requestBody));
        });
        const responses = await Promise.all(promises);
        const result: Record<string, number> = {};
        tariffIds.forEach((tariffId, index) => {
          const response = responses[index];
          result[tariffId] = response.data;
        });
        state.dataTariffSimulation[widgetGuid][1] = result;
        state.dataTariffSimulation[widgetGuid][0] = true;
      } else {
        state.dataTariffSimulation[widgetGuid][0] = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't calculate tariff",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.dataTariffSimulation[widgetGuid][1] = null;
      state.dataTariffSimulation[widgetGuid][0] = true;
    }
  },
  async loadDataTariffCalculator({ state }, data: [string, string, ApplyTariffDataDto, boolean]) {
    const widgetGuid = data[0];
    const tariffId = data[1];
    const requestBody = data[2];
    const includeGST = data[3];
    try {
      state.requestBody[widgetGuid] = [new Date(), `${JSON.stringify(tariffId)}---${JSON.stringify(requestBody)}---${includeGST}`];
      state.dataTariffCalculator[widgetGuid] = [false, null];
      if (tariffId) {
        const guid = uuidv4();
        state.requestGuid[widgetGuid] = guid;
        const url = `rest/BitPool_V2/tariffs/${tariffId}/data/apply?includeGST=${includeGST}`;
        const response = await axios.post<number>(url, requestBody);
        if (state.requestGuid[widgetGuid] === guid) {
          state.dataTariffCalculator[widgetGuid][1] = response.data;
          state.dataTariffCalculator[widgetGuid][0] = true;
        }
      } else {
        state.dataTariffCalculator[widgetGuid][0] = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't calculate tariff",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.dataTariffCalculator[widgetGuid][1] = null;
      state.dataTariffCalculator[widgetGuid][0] = true;
    }
  },
  async loadDataReport({ state }, data: [string, ReportInput]) {
    const widgetGuid = data[0];
    const requestBody = data[1];
    try {
      state.requestBody[widgetGuid] = [new Date(), JSON.stringify(requestBody)];
      state.dataReport[widgetGuid] = [false, null];
      const guid = uuidv4();
      state.requestGuid[widgetGuid] = guid;
      const url = `rest/Reports_V1/MeteringReport`;
      const response = await axios.post<string>(url, requestBody);
      if (state.requestGuid[widgetGuid] === guid) {
        state.dataReport[widgetGuid][1] = response.data;
        state.dataReport[widgetGuid][0] = true;
      }
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        "Can't generate report",
        errorMessage,
        5000
      );
      state.error[widgetGuid] = errorMessage;
      state.dataReport[widgetGuid][1] = null;
      state.dataReport[widgetGuid][0] = true;
    }
  }
};

const WidgetDataModule = {
  namespaced: true,
  state: new WidgetDataState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default WidgetDataModule;
