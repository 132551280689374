import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import OrganisationPoolsState from "./states/OrganisationPoolsState";
import { PoolDto } from "@/models/organisation/PoolDto";
import { PoolsToOrganisationDto } from "@/models/organisation/PoolsToOrganisationDto";
import DateHelper from "@/helpers/DateHelper";

export const useOrganisationPoolsStore = defineStore('organisationPools', {
  state: (): OrganisationPoolsState => ({ 
    data: {},
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number) {
      const organisationIdStr = organisationId.toString();
      try {
        const guid = uuidv4();
        this.data[organisationIdStr] = {
          guid: guid,
          isLoaded: false,
          entities: null
        }
        const url = `rest/BitPool_V2/organisations/${organisationId}/pools`;
        const response = await axios.get<PoolDto[]>(url);
        if (this.data[organisationIdStr].guid === guid) {
          response.data.forEach(x => x.RegistrationDate = DateHelper.parseFromMicrosoftString(x.RegistrationDate as string));
          this.data[organisationIdStr].entities = response.data;
          this.data[organisationIdStr].isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load organisation pools",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data[organisationIdStr].entities = null;
        this.data[organisationIdStr].isLoaded = true;
      }
    },
    async add(body: PoolsToOrganisationDto) {
      const organisationIdStr = body.Id.toString();
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/organisations/pools`;
        const response = await axios.put<PoolDto[]>(url, body);
        ToastService.showToast("success", "Organisation Pool", "Changes saved", 5000);
        this.updateInProgress = false;
        const data = this.data[organisationIdStr];
        if (data?.isLoaded && data?.entities && response.data.length) {
          response.data.forEach(x => x.RegistrationDate = DateHelper.parseFromMicrosoftString(x.RegistrationDate as string));
          data.entities.push(...response.data);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save organisation pool",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
    },
    async delete(organisationId: number, poolKeys: string[]) {
      const organisationIdStr = organisationId.toString();
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/BitPool_V2/organisations/${organisationId}/pools`;
        await axios.delete(url, {
          headers: {
            "Content-Type": "application/json",
          },
          data: poolKeys,
        });
        ToastService.showToast("success", "Organisation", `Changes saved`, 5000);
        this.deleteInProgress = false;
        const data = this.data[organisationIdStr];
        if (data?.isLoaded && data?.entities) {
          for (const key of poolKeys) {
            const index = data.entities.findIndex((x) => x.PoolKey === key);
            if (index > -1) {
              data.entities.splice(index, 1);
            }
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete pool`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
      }
    }
  },
})
