<template>
  <div class="default-view-container">
    <h1 class="mb-0">Scheduler</h1>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Schedule List</h2>
      </header>
      <div class="default-view-body">
        <DataTable
          :value="mqttSchedulerStore.entities" 
          dataKey="Id"
          sortField="Created" 
          :sortOrder="1"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="850px" 
          class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width mqtt-scheduler-table">
          <template #header>
            <div class="table-header">
              <Button label="Add Schedule" icon="pi pi-plus-circle" class="my-1" @click="openSchedule('new')" />
            </div>
          </template>
          <template #empty>
            <div v-if="mqttSchedulerStore.isLoaded" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column field="Enabled" header="Enabled" :sortable="true" headerStyle="width: 1%; min-width: 65px;">
            <template #body="slotProps">
              <div>
                {{ slotProps.data.Enabled ? 'Yes' : 'No' }}
              </div>
            </template>
          </Column>
          <Column field="Name" header="Name" :sortable="true" headerStyle="width: 20%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
          </Column>
          <Column field="Created" header="Date Created" :sortable="true" headerStyle="width: 20%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
            <template #body="slotProps">
              <DateTimezoneView :date="slotProps.data.Created" timezone="local"/>
            </template>
          </Column>
          <Column field="CreatedBy" header="Created By" :sortable="true" headerStyle="width: 20%; min-width: min-content;" headerClass="no-break-word">
          </Column>
          <Column field="Events.length" header="Events" :sortable="true" headerStyle="width: 1%; min-width: 65px;">
            <template #body="slotProps">
              <div>
                {{ slotProps.data.Events.length }}
              </div>
            </template>
          </Column>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div>
                <div class="inline-flex">
                  <Button 
                    icon="pi pi-clone" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openClonePage(slotProps.data)"
                    v-tippy="'Copy'"
                    :disabled="slotProps.data.CreatedBy === 'Nube iO'"
                  />
                  <Button 
                    icon="pi pi-pencil" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openSchedule(slotProps.data.Id)"
                    v-tippy="'Edit'"
                  />
                  <Button 
                    icon="pi pi-trash" 
                    class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                    @click="openConfirmation(slotProps.data)" 
                    v-tippy="'Delete'"
                    :disabled="slotProps.data.CreatedBy === 'Nube iO'"
                  />
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div v-else class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Schedule List</h2>
      </header>
      <div class="default-view-body">
        <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import BlockUI from 'primevue/blockui';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import ConfirmationService from "@/services/ConfirmationService";
import { useMQTTSchedulerStore } from "@/stores/mqttScheduler";
import { MQTTSchedulerEntity } from "@/models/mqtt/MQTTSchedulerEntity";
import NavigationHelper from "@/helpers/NavigationHelper";
import DateTimezoneView from '@/components/views/DateTimezoneView.vue';
import { useOrganisationStore } from "@/stores/organisation";

@Component({
  components: {
    Button,
    DataTable,
    Column,
    BlockUI,
    Message,
    ProgressSpinner,
    DateTimezoneView
  },
  directives: {
  }
})
class MQTTSchedulerView extends Vue { 
  mqttSchedulerStore = useMQTTSchedulerStore();

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }
  
  created() {
    this.mqttSchedulerStore.load();
  }

  // #region new/edit
  openClonePage(record: MQTTSchedulerEntity): void {
    this.mqttSchedulerStore.copyEntity = record;
    this.openSchedule("new");
  }

  openSchedule(id: string): void {
    NavigationHelper.goTo(`/data/scheduler/${id}`);
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: MQTTSchedulerEntity | null = null;

  openConfirmation(record: MQTTSchedulerEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete schedule?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Schedule',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.mqttSchedulerStore.delete(this.selectedRecord);
    }
  }
  // #endregion delete
}

export default MQTTSchedulerView;
</script>