<template>
  <li
    v-if="item && isSearchResult"
    :class="{'has-children': children?.length, 'is-open': isOpen, 'is-active': dashboardState.currentDashboard?._id === item._id, 'wobble-animation': editModeTreeAnimation}"
    @dragenter="dragEnter"
    @touchmove="dragEnter"
  >
    <div>
      <span @click="toggle" v-if="children?.length" :class="isOpen ? 'is-open' : 'is-close'"></span>

      <div class="handle tree-handle" v-if="editModeTree"></div>
      <Checkbox v-if="!authState.jailMode" v-model="isSelected" :binary="true" class="dark-checkbox" />

      <a 
        ref="aHrefElement"
        style="user-select: none; -webkit-touch-callout: none;"
        :href="buildDashboardUrl(item)"
        class="dashboard-tree__link"
        @click="selectDashboard(item)"
      >
        {{ item.spaceName }}
      </a>
    </div>

    <draggable
      v-if="editModeTree || isOpen"
      tag="ul"
      handle=".handle"
      v-model="dragChildren"
      group="nav-tree"
      :animation="150"
      :disabled="!editModeTree"
      class="list-group dashboard-tree__list"
      item-key="_id"
      @start="onDragStart"
      @end="onDragEnd"
      ghostClass="dragging-tree"
    >
      <template #item="{ element }">
        <DashboardTreeItemView
          :dashboardId="element._id"
          :afterNavigateTo="afterNavigateTo"
          :openDashboardMenu="openDashboardMenu"
        />
      </template>
    </draggable>
  </li>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { Space } from "@/models/dashboard/Space";
import DashboardState from "@/store/states/DashboardState";
import SpaceHelper from "@/helpers/SpaceHelper";
import NavigationHelper from "@/helpers/NavigationHelper";
import draggable from "vuedraggable";
import AuthState from "@/store/states/AuthState";
import { Ref } from "vue-facing-decorator";
import RootState from "@/store/states/RootState";
import Checkbox from 'primevue/checkbox';
import { DashboardType } from "@/models/dashboard/DashboardType";
import { useOrganisationStore } from "@/stores/organisation";
import { useSystemStore } from "@/stores/system";

@Component({
  components: {
    draggable,
    Checkbox
  },
})
class DashboardTreeItemView extends Vue {
  @Prop({ required: false, default: null }) dashboardId!: string | null;
  @Prop({ required: true }) afterNavigateTo!: () => void;
  @Prop({ required: true }) openDashboardMenu!: (event: Event, dashboard: Space | null | undefined) => void;

  get isOpen(): boolean {
    return this.dashboardState.openDashboards[this.dashboardId ? this.dashboardId : "-"];
  }

  set isOpen(value: boolean) {
    if (value) {
      this.$store.commit("dashboard/openDashboard", this.dashboardId);
    } else {
      this.$store.commit("dashboard/closeDashboard", this.dashboardId);
    }
  }

  get selectedDashboards(): Record<string, boolean> {
    return this.$store.getters["dashboard/getSelectedDashboards"];
  }

  get isSelected(): boolean {
    return this.selectedDashboards[this.dashboardId ? this.dashboardId : "-"];
  }

  set isSelected(value: boolean) {
    if (value) {
      this.$store.commit("dashboard/selectDashboard", { dashboardType: this.dashboardType, id: this.dashboardId });
    } else {
      this.$store.commit("dashboard/unslectDashboard", { dashboardType: this.dashboardType, id: this.dashboardId });
    }
  }

  get isSearchResult(): boolean {
    if (this.dashboardId && this.dashboardState.searchDashboard) {
      return this.dashboardState.searchDashboardResult.includes(this.dashboardId);
    } else {
      return true;
    }
  }

  get rootState(): RootState {
    return this.$store.state;
  }
  
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();
  systemStore = useSystemStore();

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get editModeTree(): boolean {
    return this.dashboardState.editModeTree;
  }

  get editModeTreeAnimation(): boolean {
    return this.dashboardState.editModeTreeAnimation;
  }

  get dashboardType(): DashboardType {
    return this.dashboardState.dashboardType;
  }

  get dashboards(): Space[] | null | undefined {
    return this.$store.getters["dashboard/getDashboards"];
  }

  get item(): Space | null | undefined {
    return this.dashboards?.find(x => x._id === this.dashboardId);
  }

  get children(): Space[] {
    const item = this.item;
    if (item) {
      const result = SpaceHelper.getChildren(this.dashboards, item);
      if (result) {
        return result;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  get dragChildren(): Space[] {
    const item = this.item;
    if (item) {
      const result = this.isOpen ? SpaceHelper.getChildren(this.dashboards, item) : [];
      if (result) {
        return result;
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  set dragChildren(spaces: Space[]) {
    const parentSpace = this.item;
    const oldSpaces = this.children;
    let newSpaces: Space[];
    if (this.isOpen || !spaces.length) { 
      newSpaces = spaces;
    } else {
      newSpaces = spaces.concat(oldSpaces);
      this.isOpen = true;
    }
    const saveSpaces = SpaceHelper.moveSpace(parentSpace, oldSpaces, newSpaces, this.dashboards ? this.dashboards : []);
    this.$store.commit("dashboard/openDashboard", this.dashboardId);
    // save saveSpaces
    this.saveDashboards(saveSpaces);
  }

  async saveDashboards(dashboards: Space[]): Promise<void> {
    if (dashboards && dashboards.length) {
      await this.$store.dispatch(
        "dashboard/saveDashboards", 
        { 
          dashboards: dashboards,
          organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
        }
      );
    }
  }

  onDragStart(event: any): void {
    const space = this.children[event.oldIndex];
    this.$store.commit("dashboard/startDrag", space);
  }

  onDragEnd(): void {
    this.$store.commit("dashboard/stopDrag");
  }

  dragEnter(): void {
    if (this.item && this.dashboardState.spaceDragId) {
      this.$store.commit("dashboard/startDragTimer", this.item);
    }
  }

  toggle(): void {
    this.$store.commit("dashboard/toggleDashboard", this.dashboardId);
  }

  buildDashboardUrl(dashboard: Space | null | undefined): string {
    return dashboard ? 
      `${window.location.pathname}?pane=${this.dashboardType}&id=${dashboard._id}` :
      `${window.location.pathname}?pane=${this.dashboardType}`;
  }

  selectDashboard(dashboard: Space | null | undefined): void {
    if (NavigationHelper.goToDashboard(this.dashboardType, dashboard?._id)) {
      if (this.afterNavigateTo) {
        this.afterNavigateTo();
      }
    }
  }

  @Ref() readonly aHrefElement!: HTMLElement;

  mounted(): void {
    if (this.aHrefElement && !this.authState.jailMode) {
      this.aHrefElement.addEventListener("contextmenu", (event) => {
        this.openDashboardMenu(event, this.item);
      });
      // contextmenu event is not compatible with iOS - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event 
      if (this.systemStore.isIOs) {
        // Timer for long touch detection
        let timerLongTouch: number | undefined = undefined;
        // Long touch flag for preventing "normal touch event" trigger when long touch ends
        let longTouch = false;

        this.aHrefElement.addEventListener("touchstart", (event) => {
          // Timer for long touch detection
          timerLongTouch = window.setTimeout(function () {
            // Flag for preventing "normal touch event" trigger when touch ends.
            longTouch = true;
          }, 700);
        });
        this.aHrefElement.addEventListener("touchmove", (event) => {
          // If timerLongTouch is still running, then this is not a long touch
          // (there is a move) so stop the timer
          clearTimeout(timerLongTouch);

          if (longTouch) {
            longTouch = false;
          }
        });
        this.aHrefElement.addEventListener("touchend", (event) => {
          // If timerLongTouch is still running, then this is not a long touch
          // so stop the timer
          clearTimeout(timerLongTouch);

          if (longTouch) {
            longTouch = false;
            this.openDashboardMenu(event, this.item);
          }
        });
      }
    }
  }
}

export default DashboardTreeItemView;
</script>