<template>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 69 76"><path stroke="#cbcbcb" class="stroke-color" stroke-linecap="round" stroke-linejoin="round" stroke-width="6" d="M66 13.5a10.5 10.5 0 1 1-21 0 10.5 10.5 0 0 1 21 0ZM24 38a10.5 10.5 0 1 1-21 0 10.5 10.5 0 0 1 21 0v0ZM66 62.5a10.5 10.5 0 1 1-21 0 10.5 10.5 0 0 1 21 0ZM22.565 43.285l23.905 13.93M46.435 18.785l-23.87 13.93"/></svg>

</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class ShareSvg extends Vue {
}

export default ShareSvg;
</script>