<template>
  <div class="default-view-container">
    <h1 class="mb-0">Api Status</h1>
    <div v-if="auth.permissions?.BitpoolAdmin" class="default-view increase-padding-bottom mt-4 lg:mt-5">   
      <header class="default-view-header">
        <h2>Api Status List</h2>
      </header>
      <div class="default-view-body admin-api-links" style="max-width: 28rem; min-height: 16rem;">
        <div v-for="(item, i) in apiStatus" :key="i">
          <div class="mr-3">
            <ProgressSpinner v-if="(item[3] === null)" class="spinner-primary" style="width: 14px; height: 14px" strokeWidth="6" animationDuration="1s" />
            <i v-else class="pi pi-circle-fill" :class="item[3] ? 'text-green-500' : 'text-red-500'"></i>
          </div>
          <div class="mr-3">
            <a :href="`${item[2]}/diagnostic.html`" target="_blank" class="font-medium">{{item[0]}}</a>
            <span class="admin-api-links-source">{{item[1]}}</span>
          </div>
          <div class="ml-auto">
            <a :href="`${item[2]}/diagnostic.html`" target="_blank" class="p-button p-component p-button-icon-only p-button-rounded p-button-light-primary p-button-lg admin-api-links-external-link"><i class="pi pi-external-link"></i></a>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import ProgressSpinner from "primevue/progressspinner";
import AuthState from "@/store/states/AuthState";

@Component({
  
  components: {
    ProgressSpinner
  },
})
class AdminApiStatusView extends Vue {
  get auth(): AuthState {
    return this.$store.state.auth;
  }
  
  apiStatus: [string, string, string, boolean | null][] = [];

  created(): void {
    this.apiStatus.push(["Bitpool OS API", "Production", "https://api-ui.bitpool.com", null]);
    this.apiStatus.push(["Uploader API", "Production", "https://api.bitpool.com", null]);
    this.apiStatus.push(["Beta API", "Beta", "https://dev.api.bitpool.com", null]);
    this.updateApiStatus();
  }

  async updateApiStatus(): Promise<void> {
    const promises: Promise<boolean>[] = [];
    this.apiStatus.forEach(element => {
      promises.push(this.fetchAsync(`${element[2]}/api/health/availability`));
    });
    const responses = await Promise.all(promises);
    responses.forEach((response, index) => {
      this.apiStatus[index][3] = response;
    });
  }

  async fetchAsync(url: string): Promise<boolean> {
    try {
      const response = await fetch(url);
      return response.ok;
    } catch (error) {
      return false;
    }
  }
}

export default AdminApiStatusView;
</script>
