import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { MongoEntityPage } from "@/models/MongoEntityPage";
import NotificationState from "./states/NotificationState";
import { NotificationEntity } from "@/models/notification/NotificationEntity";
import DateHelper from "@/helpers/DateHelper";
import { MongoEntityPageWithGroupsTotal } from "@/models/MongoEntityPageWithGroupsTotal";

const topBarNotificationsLimit = 10;

export const useNotificationStore = defineStore('notification', {
  state: (): NotificationState => ({ 
    isLoaded: false,
    guid: "",
    data: null,
    take: 20,
    skip: 0,
    isRead: null,
    search: "",
    groupsIds: null,
    organisationIds: null,
    subtitles: null,
    sourceIds: null,

    selectedNotifications: {},

    isLoadedLastUnreadNotifications: false,
    guidLastUnreadNotifications: "",
    lastUnreadNotifications: null
  }),
  getters: {
  },
  actions: {
    async load(skip: number, take: number, isRead: boolean | null, search: string, groupsIds: string[] | null = null,
      organisationIds: number[] | null = null, subtitles: string[] | null = null, sourceIds: string[] | null = null
    ) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.skip = skip;
        this.take = take;
        this.isRead = isRead;
        this.search = search;
        this.groupsIds = groupsIds;
        this.organisationIds = organisationIds;
        this.subtitles = subtitles;
        this.sourceIds = sourceIds;
        let url = `rest/Notification_V1/user?skip=${skip}&limit=${take}&search=${search}`;
        if (typeof isRead === "boolean") {
          url += `&isRead=${isRead}`;
        }
        if (groupsIds?.length) {
          url += `&groupsIds=${groupsIds.join(",")}`
        }
        if (organisationIds?.length) {
          url += `&organisationIds=${organisationIds.join(",")}`
        }
        if (subtitles?.length) {
          url += `&subtitles=${subtitles.join(",")}`
        }
        if (sourceIds?.length) {
          url += `&sourceIds=${sourceIds.join(",")}`
        }
        const response = await axios.get<MongoEntityPageWithGroupsTotal<NotificationEntity>>(url);
        if (this.guid === guid) {
          if (response.data?.Items?.length) {
            response.data.Items.forEach((item) => {
              if (item.Date) {
                item.Date = DateHelper.parseFromMicrosoftString(
                  item.Date as string
                );
              }
            })
          }
          this.data = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load Notifications",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = null;
        this.isLoaded = true;
      }
    },
    async loadLastUnreadNotifications() {
      try {
        const guid = uuidv4();
        this.guidLastUnreadNotifications = guid;
        this.isLoadedLastUnreadNotifications = false;
        let url = `rest/Notification_V1/user?skip=0&limit=${topBarNotificationsLimit}&isRead=false&includeGroupsTotal=false`;
        const response = await axios.get<MongoEntityPage<NotificationEntity>>(url);
        if (this.guidLastUnreadNotifications === guid) {
          if (response.data?.Items?.length) {
            response.data.Items.forEach((item) => {
              if (item.Date) {
                item.Date = DateHelper.parseFromMicrosoftString(
                  item.Date as string
                );
              }
            })
          }
          this.lastUnreadNotifications = response.data;
          this.isLoadedLastUnreadNotifications = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load new Notifications",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.lastUnreadNotifications = null;
        this.isLoadedLastUnreadNotifications = true;
      }
    },
    async loadCustom(skip: number, take: number, isRead: boolean | null, search: string,
      groupsIds: string[] | null = null, includeGroupsTotal: boolean = false,
      organisationIds: number[] | null = null, subtitles: string[] | null = null, sourceIds: string[] | null = null): Promise<MongoEntityPageWithGroupsTotal<NotificationEntity> | null> {
      try {
        let url = `rest/Notification_V1/user?skip=${skip}&limit=${take}&search=${search}&includeGroupsTotal=${includeGroupsTotal}`;
        if (typeof isRead === "boolean") {
          url += `&isRead=${isRead}`;
        }
        if (groupsIds?.length) {
          url += `&groupsIds=${groupsIds.join(",")}`
        }
        if (organisationIds?.length) {
          url += `&organisationIds=${organisationIds.join(",")}`
        }
        if (subtitles?.length) {
          url += `&subtitles=${subtitles.join(",")}`
        }
        if (sourceIds?.length) {
          url += `&sourceIds=${sourceIds.join(",")}`
        }
        const response = await axios.get<MongoEntityPageWithGroupsTotal<NotificationEntity>>(url);
        if (response.data?.Items?.length) {
          response.data.Items.forEach((item) => {
            if (item.Date) {
              item.Date = DateHelper.parseFromMicrosoftString(
                item.Date as string
              );
            }
          })
        }
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load Notifications",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return null;
      }
    },
    async markAs(entities: NotificationEntity[], value: "read" | "unread"): Promise<boolean> {
      const oldValues = entities.map(x => x.IsRead);
      try {
        const isRead = value === "read";
        for (const item of entities) {
          item.IsRead = isRead;
        }
        const url = `rest/Notification_V1/user/${value}`;
        await axios.post(url, entities.map(x => x.Id));
        if (this.data?.Items.length) {
          // to make sure the state is updated (pagination can load different version of the same entity)
          this.data.Items.forEach((item) => {
            const updatedItem = entities.find(x => x.Id === item.Id);
            if (updatedItem) {
              item.IsRead = updatedItem.IsRead;
            }
          });
        }
        if (this.isLoadedLastUnreadNotifications) {
          if (value === "read") {
            if (this.lastUnreadNotifications?.Items.length) {
              for (const item of entities) {
                const index = this.lastUnreadNotifications.Items.findIndex(x => x.Id === item.Id);
                if (index > -1) {
                  this.lastUnreadNotifications.Items.splice(index, 1);
                }
                if (this.lastUnreadNotifications.Total > 0) {
                  this.lastUnreadNotifications.Total--;
                }
              }
            }
          }
          if (value === "unread" || this.lastUnreadNotifications && this.lastUnreadNotifications.Items.length < topBarNotificationsLimit && this.lastUnreadNotifications.Total >= topBarNotificationsLimit) {
            await this.loadLastUnreadNotifications();
          }
        }
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't change notification state`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        for (let index = 0; index < entities.length; index++) {
          const item = entities[index];
          const oldValue = oldValues[index];
          item.IsRead = oldValue;
        }
        return false;
      }
    },
    async markAllAs(value: "read" | "unread"): Promise<boolean> {
      try {
        const url = `rest/Notification_V1/user/all/${value}`;
        await axios.post(url);
        if (this.data?.Items.length) {
          this.data.Items.forEach((item) => {
            item.IsRead = value === "read";
          });
        }
        if (this.isLoadedLastUnreadNotifications) {
          if (value === "read") {
            if (this.lastUnreadNotifications) {
              this.lastUnreadNotifications.Items = [];
              this.lastUnreadNotifications.Total = 0;
            }
          } else {
            await this.loadLastUnreadNotifications();
          }
        }
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't change notification state`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return false;
      }
    },
    async delete(entities: NotificationEntity[]): Promise<boolean> {
      try {
        const url = `rest/Notification_V1/user`;
        await axios.delete(url, {
          headers: {
            "Content-Type": "application/json",
          },
          data: entities.map(x => x.Id),
        });
        if (this.data?.Items.length) {
          for (const item of entities) {
            const index = this.data?.Items.findIndex(x => x.Id === item.Id);
            if (index > -1) {
              this.data.Items.splice(index, 1);
              if (this.data.Total > 0) {
                this.data.Total--;
              }
            }
          }
        }
        if (this.isLoadedLastUnreadNotifications) {
          if (this.lastUnreadNotifications?.Items.length) {
            for (const item of entities) {
              const index = this.lastUnreadNotifications.Items.findIndex(x => x.Id === item.Id);
              if (index > -1) {
                this.lastUnreadNotifications.Items.splice(index, 1);
              }
              if (this.lastUnreadNotifications.Total > 0) {
                this.lastUnreadNotifications.Total--;
              }
            }
          }
          if (this.lastUnreadNotifications && this.lastUnreadNotifications.Items.length < topBarNotificationsLimit && this.lastUnreadNotifications.Total >= topBarNotificationsLimit) {
            await this.loadLastUnreadNotifications();
          }
        }
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete notification`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return false;
      }
    },
    async deleteAll(): Promise<boolean> {
      try {
        const url = `rest/Notification_V1/user/all`;
        await axios.delete(url);
        if (this.data) {
          this.data.Items = [];
          this.data.Total = 0;
        }
        if (this.lastUnreadNotifications) {
          this.lastUnreadNotifications.Items = [];
          this.lastUnreadNotifications.Total = 0;
        }
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete notification`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return false;
      }
    }
  }
})
