import ErrorHelper from "@/helpers/ErrorHelper";
import { MQTTClientConfig } from "@/models/mqtt/MQTTClientConfig";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import MQTTState from "../states/MQTTState";

const getters = <GetterTree<MQTTState, any>>{};

const mutations = <MutationTree<MQTTState>>{
  add(state, config: MQTTClientConfig) {
    state.configs.push(config);
  },
  delete(state, id: string) {
    for (let i = state.configs.length - 1; i >= 0; --i) {
      if (state.configs[i].Id === id) {
        state.configs.splice(i, 1);
        break;
      }
    }
  },
};

const actions = <ActionTree<MQTTState, any>>{
  async load({ state }) {
    try {
      state.isLoaded = false;
      state.configs = [];
      const url = `rest/BitPool_V2/mqtt/client`;
      const response = await axios.get<MQTTClientConfig[]>(url);
      state.configs = response.data;
      state.isLoaded = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load MQTT configurations",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.configs = [];
      state.isLoaded = true;
    }
  },
  async createUpdate({ state }, body: MQTTClientConfig) {
    try {
      state.updateInProgress = true;
      state.updateError = false;
      const url = `rest/BitPool_V2/mqtt/client`;
      const response = await axios.post<MQTTClientConfig>(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      state.updateInProgress = false;
      ToastService.showToast("success", "", "Changes saved", 5000);
      if (body.Id) {
        const index = state.configs.findIndex((x) => x.Id === body.Id);
        if (index >= 0) {
          state.configs[index] = response.data;
        }
      } else {
        state.configs.push(response.data);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save MQTT configuration",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.updateInProgress = false;
      state.updateError = true;
    }
  },
  async test({ state }, body: MQTTClientConfig) {
    try {
      state.testInProgress = true;
      state.testError = false;
      const url = `rest/BitPool_V2/mqtt/client/test`;
      await axios.post(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      state.testInProgress = false;
      ToastService.showToast("success", "", "Valid endpoint", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.testInProgress = false;
      state.testError = true;
    }
  },
  async delete({ state, commit }, id: string) {
    try {
      const url = `rest/BitPool_V2/mqtt/client/${id}`;
      await axios.delete(url);
      ToastService.showToast("success", "", "Changes saved", 5000);
      commit("mqtt/delete", id, { root: true });
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete MQTT configuration",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
};

const MQTTModule = {
  namespaced: true,
  state: new MQTTState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default MQTTModule;
