import ErrorHelper from "@/helpers/ErrorHelper";
import { MQTTServerConfig } from "@/models/mqtt/MQTTServerConfig";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import MQTTServerState from "../states/MQTTServerState";

const getters = <GetterTree<MQTTServerState, any>>{};

const mutations = <MutationTree<MQTTServerState>>{
  add(state, config: MQTTServerConfig) {
    state.configs.push(config);
  },
  delete(state, id: string) {
    for (let i = state.configs.length - 1; i >= 0; --i) {
      if (state.configs[i].Id === id) {
        state.configs.splice(i, 1);
        break;
      }
    }
  },
};

const actions = <ActionTree<MQTTServerState, any>>{
  async load({ state }) {
    try {
      state.isLoaded = false;
      state.configs = [];
      const url = `rest/BitPool_V2/mqtt/server`;
      const response = await axios.get<MQTTServerConfig[]>(url);
      state.configs = response.data;
      state.isLoaded = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load MQTT config",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.configs = [];
      state.isLoaded = true;
    }
  },
  async createUpdate({ state }, body: MQTTServerConfig) {
    try {
      state.updateInProgress = true;
      state.updateError = false;
      const url = `rest/BitPool_V2/mqtt/server`;
      const response = await axios.post<MQTTServerConfig>(url, body, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      state.updateInProgress = false;
      ToastService.showToast("success", "", "Changes saved", 5000);
      if (body.Id) {
        const index = state.configs.findIndex((x) => x.Id === body.Id);
        if (index >= 0) {
          state.configs[index] = response.data;
        }
      } else {
        state.configs.push(response.data);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save MQTT config",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.updateInProgress = false;
      state.updateError = true;
    }
  },
  async delete({ state, commit }, id: string) {
    try {
      const url = `rest/BitPool_V2/mqtt/server/${id}`;
      await axios.delete(url);
      ToastService.showToast("success", "", "Changes saved", 5000);
      commit("mqttServer/delete", id, { root: true });
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete MQTT config",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
};

const MQTTServerModule = {
  namespaced: true,
  state: new MQTTServerState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default MQTTServerModule;
