import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { v4 as uuidv4 } from "uuid";
import ShareWidgetState from "../states/ShareWidgetState";
import { ShortUrlModel } from "@/models/ShortUrlModel";
import ShareWidgetRequest from "@/models/ShareWidgetRequest";
import { IsSharedWidgetModel } from "@/models/dashboard/IsSharedWidgetModel";

const getters = <GetterTree<ShareWidgetState, any>>{
};

const mutations = <MutationTree<ShareWidgetState>>{
};

const actions = <ActionTree<ShareWidgetState, any>>{
  async loadIsShared({ state, dispatch }, widgetId: string) {
    try {
      const guid = uuidv4();
      state.guidIsShared = guid;
      state.isLoadedShared = false;
      state.isShared = null;
      state.isLoadedShortUrl = false;
      state.shortUrl = null;
      const url = `rest/BitPool_V2/os/widget/${widgetId}/IsShared2`;
      const response = await axios.get<IsSharedWidgetModel>(url);
      if (state.guidIsShared === guid) {
        state.isShared = response.data;
        state.isLoadedShared = true;
        if (state.isShared?.Shared) {
          const request: ShareWidgetRequest = {
            widgetId: widgetId,
            withHeader: state.isShared.WithHeader
          };
          await dispatch("share", request);
        } else {
          state.shareId = null;
          state.isLoadedShareId = true;
          state.isLoadedShortUrl = true;
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load share information",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.isShared = null;
      state.isLoadedShared = true;
    }
  },
  async share({ state, dispatch }, request: ShareWidgetRequest) {
    try {
      const guid = uuidv4();
      state.guidShareId = guid;
      state.isLoadedShareId = false;
      state.shareId = null;
      state.shortUrl = null;
      const url = `rest/BitPool_V2/os/widget/${request.widgetId}/share?withHeader=${request.withHeader}`;
      const response = await axios.get<string>(url);
      if (state.guidShareId === guid) {
        state.shareId = response.data;
        state.isLoadedShareId = true;
        await dispatch("generateShortUrl", state.shareId);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load share information",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.shareId = null;
      state.isLoadedShareId = true;
    }
  },
  async unshare({ state }) {
    try {
      if (state.shareId) {
        const shareId = state.shareId;
        state.isLoadedShareId = true;
        state.shareId = null;
        state.isLoadedShortUrl = true;
        state.shortUrl = null;
        const url = `rest/BitPool_V2/os/widget/unshare/${shareId}`;
        await axios.delete(url);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't unshare widget",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async reshare({ state, dispatch }, request: ShareWidgetRequest) {
    try {
      if (state.shareId) {
        const shareId = state.shareId;
        state.isLoadedShareId = false;
        state.shareId = null;
        state.isLoadedShortUrl = false;
        state.shortUrl = null;
        const url = `rest/BitPool_V2/os/widget/unshare/${shareId}?delete=true`;
        await axios.delete(url);
        await dispatch("share", request);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't refresh link",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async generateShortUrl({ state }, id: string) {
    try {
      const guid = uuidv4();
      state.guidShortUrl = guid;
      state.isLoadedShortUrl = false;
      state.shortUrl = null;
      const longUrl = `${window.location.protocol}//${window.location.host}/view/widget/${id}`;
      const requestBody: ShortUrlModel = {
        Url: longUrl,
        ValidTo: null
      };
      const url = `rest/BitPool_V2/ShortUrl`;
      const response = await axios.post<string>(url, requestBody);
      if (state.guidShortUrl === guid) {
        state.shortUrl = response.data;
        state.isLoadedShortUrl = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't generate short url",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.shortUrl = null;
      state.isLoadedShortUrl = true;
    }
  }
};

const ShareWidgetModule = {
  namespaced: true,
  state: new ShareWidgetState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default ShareWidgetModule;
