<template>
  <div ng-non-bindable class="mini-layout hide-nav-menu">
    <DebugView/>

    <div class="page with-header report-manager-page">
      <!-- Page header -->
      <header class="page-header is-fixed no-print">
        <div class="flex flex-wrap sm:flex-nowrap align-items-center justify-content-between w-full lg:w-auto lg:flex-auto gap-3">
          <div class="flex align-items-center w-full sm:w-auto flex-order-2 sm:flex-order-1">
            <img
              :src="'/assets/bitpool-icon-white.svg'"
              alt="Bitpool"
              class="logo-icon flex-shrink-0"
            />
            <h1>Report Builder</h1>
          </div>

          <a href="/report-manager" class="report-manager-back flex-order-1 sm:flex-order-2 ml-auto">
            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="14" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 7H1m0 0 6 6M1 7l6-6"/></svg>
            <span>Back to Report Manager</span>
          </a>
        </div>
        <ReportsReportControlsView v-if="displaySection === 'report'"/>
        <ReportsElementControlsView v-else-if="displaySection === 'element'"/>
      </header>
      
      <div class="page-container">
        <main>
          <div>
            <div class="report-manager-container">
              <div class="report-manager-container-inner"> 
                <div v-if="displaySection === 'element'" class="report-manager-element">
                  <ReportsElementView v-if="parts.length > 3" :elementId="parts[3].split('?')[0]"></ReportsElementView>
                  <div v-else>Invalid url</div>
                </div>
                
                <div v-else-if="displaySection === 'report'" class="report-manager-report">
                  <ReportsReportView v-if="parts.length > 3" :reportId="parts[3].split('?')[0]"></ReportsReportView>
                  <div v-else>Invalid url</div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<style>
@import '/assets/report-constructor-grid.css';
</style>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AngularOptions from "@/models/AngularOptions";
import DebugView from "@/components/views/DebugView.vue";
import Ripple from 'primevue/ripple';
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import CustomWindow from "@/CustomWindow";
import { usePageStore } from "@/stores/page";
import ReportsElementView from "@/components/views/reports/ReportsElementView.vue";
import ReportsReportView from "@/components/views/reports/ReportsReportView.vue";
import SelectButton from 'primevue/selectbutton';
import Button from 'primevue/button';
import ReportsReportControlsView from "@/components/views/reports/ReportsReportControlsView.vue";
import ReportsElementControlsView from "@/components/views/reports/ReportsElementControlsView.vue";

declare const window: CustomWindow;

@Component({
  components: {
    DebugView,
    ReportsElementView,
    ReportsReportView,
    SelectButton,
    Button,
    ReportsReportControlsView,
    ReportsElementControlsView
  },
  directives: {
    'ripple': Ripple
  }
})
class ReportManagerPage extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  pageStore = usePageStore();

  displaySection = "";
  parts: string[] = [];

  oldPath = "";
  oldSearch = "";

  urlSearchParams: URLSearchParams | null = null;

  getUrlParameter(name: string, toLower: boolean): string | null | undefined {
    if (!this.urlSearchParams) {
      this.urlSearchParams = new URLSearchParams(window.location.search);
    }
    let value = this.urlSearchParams?.get(name);
    if (value && toLower) {
      value = value.toLowerCase();
    }
    return value;
  }

  updateState(): void {
    this.urlSearchParams = new URLSearchParams(window.location.search);
    const path = window.location.pathname;
    const search = window.location.search;
    if (this.oldPath !== path || this.oldSearch !== search) {
      this.oldPath = path;
      this.oldSearch = search;
      this.parts = path.split("/");
      let newDisplaySection = "";
      // routes here, becase vue native routes conflicting with angularjs routes
      if (this.parts.length > 3 && this.parts[1].toLowerCase() === "report-manager") {
        if (this.parts[2] === "element") {
          newDisplaySection = "element";
        } else if (this.parts[2] === "report") {
          newDisplaySection = "report";
        }
      }
      if (newDisplaySection) {
        this.displaySection = newDisplaySection;
      } else {
        this.displaySection = "not_found";
      }
      const pageName = this.pageNames[this.displaySection] ?? "";
      document.title = window.angularUtilsService.getInstanceTitle() + (pageName ? ` - ${pageName}` : "");
    }
  }

  pageNames: Record<string, string> = {
    "not_found": "Not Found",
    "element": "Report Element",
    "report": "Report"
  };

  unsubscribeLocationChange: any = null;

  created(): void {
    this.pageStore.$reset();
    this.updateState();
    // subscribe to angularjs route change event
    this.unsubscribeLocationChange = this.angularOptions.angularScope.$on('$locationChangeSuccess', ($event: any, next: any, current: any) => { 
      this.updateState();
      if (this.$refs.pageContainer) {
        (this.$refs.pageContainer as HTMLElement).scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  }

  unmounted(): void {
    if (this.unsubscribeLocationChange) {
      this.unsubscribeLocationChange();
    }
  }
}

export default ReportManagerPage;
</script>
