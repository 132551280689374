<template>
  <div class="widget-type-tariff-calculator widget-with-datatable">   
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="widget-type-tariff-calculator-inner" v-show="!isLodingData">
      <div class="widget-type-tariff-calculator-top">
        <div>
          <span>COST: </span>
          <ProgressSpinner v-if="isCalculating" class="spinner-primary m-0" style="width: 22px; height: 22px;" strokeWidth="6" animationDuration="1s" />
          <b v-else>{{valueStr}}</b>
        </div>
        <div>
          <Button
            :disabled="widgetData === null || isCalculating"
            label="Snapshot"
            icon="pi pi-camera"
            @click="createSnapshot"
          />
          <Button
            label="History"
            icon="pi pi-history"
            @click="openHistoryDialog"
            class="p-button-outlined"
          />
        </div>
      </div>
      <div class="widget-type-tariff-calculator-middle">
        <div class="formgrid grid">
          <!-- Start Date -->
          <div class="field col-12 sm:col-6" >
            <label>Start Date</label>
            <div>
              <Calendar
                class="w-full"
                ref="calendarFrom"
                v-model="dateFrom"
                :maxDate="dateTo"
                :showTime="false"
                dateFormat="dd/mm/yy"
                @date-select="onChangeDate()"
                @change="onChangeDate()" 
                panelClass="with-max-width"
              >
                <template #footer>
                  <div class="flex justify-content-end pb-3">
                    <Button
                      label="Close"
                      icon="pi pi-times"
                      @click="closeCalendar"
                      class="p-button-text p-button-secondary"
                    />
                  </div>
                </template>
              </Calendar>
            </div>
          </div>
          <!-- End Date -->
          <div class="field col-12 sm:col-6">
            <label>End date</label>
            <div>
              <Calendar
                class="w-full"
                ref="calendarTo"
                v-model="dateTo"
                :minDate="dateFrom"
                :showTime="false"
                dateFormat="dd/mm/yy"
                @date-select="onChangeDate()"
                @change="onChangeDate()" 
                panelClass="with-max-width"
              >
                <template #footer>
                  <div class="flex justify-content-end pb-3">
                    <Button
                      label="Close"
                      icon="pi pi-times"
                      @click="closeCalendar"
                      class="p-button-text p-button-secondary"
                    />
                  </div>
                </template>
              </Calendar>
            </div>
          </div>
          <!-- Start Value -->
          <div class="field col-12 sm:col-6" v-if="wds">
            <label for="startValueInput">Start Reading</label>
            <div class="p-inputgroup">
              <InputNumber
                class="inputfield w-full"
                id="startValueInput"
                v-model="readingStart" 
                mode="decimal" 
                :minFractionDigits="2"
                @input="readingStartChanged($event)"
                :allowEmpty="false"
              />
              <span class="p-inputgroup-addon white-space-nowrap">kWh</span>
            </div>
          </div>
          <!-- End Value -->
          <div class="field col-12 sm:col-6" v-if="wds">
            <label for="endValueInput">End Reading</label>
            <div class="p-inputgroup">
              <InputNumber
                class="inputfield w-full"
                id="endValueInput"
                v-model="readingEnd" 
                mode="decimal" 
                :minFractionDigits="2"
                @input="readingEndChanged($event)"
                :allowEmpty="false"
              />
              <span class="p-inputgroup-addon white-space-nowrap">kWh</span>
            </div>
          </div>
          <!-- Demand -->
          <div class="field col-12 sm:col-6" v-if="wds">
            <label for="demandInput">Demand</label>
            <div class="p-inputgroup">
              <InputNumber
                class="inputfield w-full"
                id="demandInput"
                v-model="kW" 
                mode="decimal" 
                :minFractionDigits="2"
                @input="kWChanged($event)"
                :allowEmpty="false"
              />
              <span class="p-inputgroup-addon white-space-nowrap">kW</span>
            </div>
          </div>
          <!-- GST -->
          <div class="field col-12 sm:col-6 sm:pb-1 sm:align-self-end" v-if="wds">
            <div class="flex align-items-center">
              <InputSwitch 
                inputId="includeGST"
                v-model="wds.includeGST"
                @change="gstChanged()"
                class="vertical-align-top"
              />
              <label for="includeGST" class="mb-0 ml-2">Include GST</label>
            </div>
          </div>
        </div>
      </div>
      <div class="widget-type-tariff-calculator-bottom">
        <!-- Debtor -->
        <label class="block mb-2">Debtor</label>
        <div class="widget-datatable-container h-auto">
          <DataTable :value="debtors" showGridlines removableSort responsiveLayout="scroll" class="p-datatable-sm default-visual-table">
            <template #header>
              <div class="table-header">
                <Button
                  label="Add"
                  icon="pi pi-plus-circle"
                  class="my-1"
                  @click="openDebtorDialog(null)"
                />
              </div>
            </template>
            <template #empty>
              <div class="flex justify-content-center align-items-center h-7rem">Please add debtors</div>
            </template>
            <Column field="Id" header="Current" bodyClass="text-center" headerClass="no-break-word white-space-nowrap" headerStyle="width: 1%; min-width: 48px;">
              <template #body="slotProps">
                <span v-if="wds">
                  <RadioButton name="currentDebtor" :value="slotProps.data.Id" v-model="wds.DebtorId" @change="debtorChanged()" />
                </span>
              </template>
            </Column>
            <Column :sortable="true" field="Debtor" header="Name" headerClass="no-break-word white-space-nowrap"></Column>
            <Column :sortable="true" field="Tariff" header="Tariff" headerClass="no-break-word white-space-nowrap" headerStyle="min-width: 15%;">
              <template #body="slotProps">
                <span class="block with-inline-btn stack-small-screens">
                  <span>{{getTariffName(slotProps.data.Tariff)}}</span>
                  <Button
                    icon="pi pi-info-circle text-lg" 
                    class="p-button-icon-only p-button-rounded p-button-text"
                    v-tippy="{ content: getTariffDescription(slotProps.data.Tariff), touch: true, hideOnClick: false }"
                  />
              </span>
              </template>
            </Column>
            <Column :sortable="true" field="Site" header="Site" headerClass="no-break-word white-space-nowrap" headerStyle="min-width: 15%;"></Column>
            <Column
                :exportable="false"
                headerStyle="width: 1%; min-width: 44px;" 
                bodyStyle="text-align: right; justify-content: flex-end;"
              >
                <template #body="slotProps">
                  <div class="inline-flex">
                    <Button
                      icon="pi pi-pencil"
                      class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                      @click="openDebtorDialog(slotProps.data)"
                    />
                    <Button
                      icon="pi pi-trash"
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                      @click="openDeleteConfirmation(slotProps.index)"
                    />
                  </div>
                </template>
              </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog :header="selectedDebtor?.Id ? 'Edit Debtor' : 'Add Debtor'" v-model:visible="displayDebtorDialog" :modal="true" :style="{width: '56rem'}" class="add-debtor-dialog" >
      <div class="dialog-content" v-if="selectedDebtor">
        <div class="formgrid grid">
          <div class="field col-12 sm:col-6">
            <label for="debtorNameInput">Name</label>
            <div>
              <InputText
                id="debtorNameInput"
                class="inputfield w-full"
                placeholder="Name"
                type="text"
                v-model="selectedDebtor.Debtor"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="debtorSiteInput">Site</label>
            <div>
              <AddressAutocompleteView id="debtorSiteInput" v-model="selectedDebtorAddress" placeholder="Site" inputClass="p-inputtext p-component inputfield w-full" />
            </div>
          </div>
          <div class="field col-12 md:col-6">
            <label for="debtorTariffDropdown">Tariff</label>
            <div>
              <Dropdown id="debtorTariffDropdown" v-model="selectedDebtor.Tariff" :options="allTariffs" optionLabel="Name" optionValue="Id" placeholder="Select a Tariff" :filter="true" filterPlaceholder="Find Tariff" class="w-full" />
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="selectedDebtor.Tariff">
            <label class="tariff-description-label">Tariff Description</label>
            <div>
              {{getTariffDescription(selectedDebtor.Tariff)}}
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeDebtorDialog" class="p-button-text p-button-secondary"/>
        <Button :label="selectedDebtor?.Id ? 'Save' : 'Add'" icon="pi pi-check" @click="saveDebtor" />
      </template>
    </Dialog>

    <Dialog header="History" v-model:visible="displayHistoryDialog" :modal="true" :style="{width: '70rem'}" class="history-dialog">
      <div class="dialog-content">
        <div class="widget-datatable-container width-auto">
          <DataTable :value="history" showGridlines removableSort responsiveLayout="scroll" class="p-datatable-sm default-visual-table">
            <template #empty>
              <div class="flex justify-content-center align-items-center h-7rem">No data</div>
            </template>
            <Column :sortable="true" field="Tariff" header="Tariff" headerClass="no-break-word white-space-nowrap" headerStyle="min-width: 12%;" bodyClass="no-break-word">
              <template #body="slotProps">
                {{getTariffName(slotProps.data.Tariff)}}
              </template>
            </Column>
            <Column :sortable="true" field="StartDate" header="Start Date" headerClass="no-break-word" bodyClass="no-break-word white-space-nowrap">
              <template #body="slotProps">
                <DateTimezoneView :date="slotProps.data.StartDate" timezone="local" :hideTime="true" />
              </template>
            </Column>
            <Column :sortable="true" field="StopDate" header="End Date" headerClass="no-break-word" bodyClass="no-break-word white-space-nowrap">
              <template #body="slotProps">
                <DateTimezoneView :date="slotProps.data.StopDate" timezone="local" :hideTime="true" />
              </template>
            </Column>
            <Column :sortable="true" field="StartValue" header="Start kWh" headerClass="no-break-word" bodyClass="no-break-word white-space-nowrap">
              <template #body="slotProps">
                {{formatFloat(slotProps.data.StartValue)}}
              </template>
            </Column>
            <Column :sortable="true" field="StopValue" header="End kWh" headerClass="no-break-word" bodyClass="no-break-word white-space-nowrap">
              <template #body="slotProps">
                {{formatFloat(slotProps.data.StopValue)}}
              </template>
            </Column>
            <Column :sortable="true" field="kW" header="kW" headerClass="no-break-word" bodyClass="no-break-word white-space-nowrap">
              <template #body="slotProps">
                {{formatFloat(slotProps.data.kW)}}
              </template>
            </Column>
            <Column :sortable="true" field="Cost" header="Cost" headerClass="no-break-word" bodyClass="no-break-word white-space-nowrap">
              <template #body="slotProps">
                ${{formatFloat(slotProps.data.Cost)}}
              </template>
            </Column>
            <Column :sortable="true" field="Date" header="Date" headerClass="no-break-word" bodyClass="no-break-word" bodyStyle="min-width: 84px;">
              <template #body="slotProps">
                <DateTimezoneView :date="slotProps.data.Date" timezone="local" />
              </template>
            </Column>
            <Column
                :exportable="false"
                headerStyle="width: 1%; min-width: 44px;" 
                bodyStyle="text-align: right; justify-content: flex-end;"
              >
                <template #body="slotProps">
                  <div class="inline-flex">
                    <Button
                      icon="pi pi-trash"
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                      @click="openDeleteHistoryConfirmation(slotProps.index)"
                    />
                  </div>
                </template>
              </Column>
          </DataTable>
        </div>
      </div>
      <template #footer></template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputNumber from 'primevue/inputnumber';
import Calendar from 'primevue/calendar';
import InputSwitch from 'primevue/inputswitch';
import InputText from "primevue/inputtext";
import numbro from "numbro";
import DateTimezoneView from '@/components/views/DateTimezoneView.vue';
import { TariffModel } from '@/models/tariff/TariffModel';
import ConfirmationService from '@/services/ConfirmationService';
import ToastService from '@/services/ToastService';
import moment from 'moment';
import { debounce } from 'throttle-debounce';
import { v4 as uuidv4 } from 'uuid';
import { TariffDebtorModel } from '@/models/tariff/TariffDebtorModel';
import { TariffHistoryModel } from '@/models/tariff/TariffHistoryModel';
import { ApplyTariffDataDto } from '@/models/tariff/ApplyTariffDataDto';
import { PeriodType } from '@/models/enums/PeriodType';
import AuthState from '@/store/states/AuthState';
import AddressAutocompleteView from '@/components/views/AddressAutocompleteView.vue';
import { useTariffStore } from '@/stores/tariff';
import TariffState from '@/stores/states/TariffState';
import { useOrganisationStore } from '@/stores/organisation';

@Component({
  components: {
    ProgressSpinner,
    DataTable,
    Column,
    Button,
    RadioButton,
    Dialog,
    Dropdown,
    InputNumber,
    Calendar,
    InputSwitch,
    InputText,
    DateTimezoneView,
    AddressAutocompleteView
  }
})
class TariffCalculatorWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;
  @Prop({ required: true }) dashboardId!: string;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }
  
  tariffStore = useTariffStore();

  get tariffState(): TariffState {
    return this.tariffStore;
  }

  getTariffName(id: string): string {
    const tariff = this.allTariffs.find(x => x.Id === id);
    return tariff ? tariff.Name : "-";
  }

  getTariffDescription(id: string): string {
    const tariff = this.allTariffs.find(x => x.Id === id);
    return tariff ? tariff.Notes : "-";
  }

  get allTariffs(): TariffModel[] {
    let result: TariffModel[] = [];
    if (this.tariffState.data) {
      for (const retailer in this.tariffState.data) {
        const tariffs = this.tariffState.data[retailer];
        if (tariffs && tariffs.length) {
          result = result.concat(tariffs);
        }
      }
    }
    return result;
  }

  get currentDebtor(): TariffDebtorModel | null {
    let result: TariffDebtorModel | null = null;
    if (this.wds?.DebtorId) {
      result = this.debtors.find(x => x.Id === this.wds?.DebtorId) ?? null;
    }
    return result;
  }

  get debtors(): TariffDebtorModel[] {
    return this.tariffState.dataDebtors ?? [];
  }

  get history(): TariffHistoryModel[] {
    let result = this.tariffState.dataHistory ?? [];
    result = result.filter(x => x.Profile === this.wds?.DebtorId)
    return result;
  }

  widgetData: number | null = null;

  get valueStr(): string {
    let result: string;
    const data = this.widgetData;
    if (data !== null) {
      result = `$${this.formatFloat(data)}`;
    } else {
      result = "";
    }
    return result;
  }

  isLodingData = false;

  get isCalculating(): boolean {
    const data = this.widgetDataState.dataTariffCalculator[this.widgetConfig.guid];
    return !data || !data[0];
  }

  created(): void {
    this.fromWidgetConfigToVariables();
    if (this.wds) {
      this.readingStart = this.wds.ReadingStart ?? 0;
      this.readingEnd = this.wds.ReadingEnd ?? 0;
      this.kW = this.wds.kW ?? 0;
    }
    this.reloadData(false, true);
  }

  get canEditDashboard(): boolean {
    const canEditDashboard = this.$store.getters["dashboard/canEditDashboard"] as (id: string) => boolean;
    const result = canEditDashboard(this.dashboardId);
    return result;
  }

  async saveWidget(): Promise<void> {
    if (this.canEditDashboard && this.widgetConfig) {
      await this.$store.dispatch("dashboard/saveWidget", this.widgetConfig);
      const state = this.dashboardState.widgetState[this.widgetConfig.guid];
      if (state && state[0] && !state[2]) {
        // ok
      }
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
    }
    if (this.wds) {
      const requestBody: ApplyTariffDataDto = {
        Period: PeriodType.Custom,
        StartDate: `${this.wds.startDate}T00:00:00`,
        StopDate: `${this.wds.endDate}T00:00:00`,
        StartValue: this.wds.ReadingStart ?? 0,
        StopValue: this.wds.ReadingEnd ?? 0,
        kW: this.wds.ReadingEnd ?? 0
      };
      const promises: Promise<any>[] = [];
      if (!this.tariffState.isLoaded) {
        promises.push(this.tariffStore.loadTariffs());
      }
      if (!this.tariffState.isLoadedDebtors) {
        promises.push(this.tariffStore.loadDebtors());
      }
      if (!this.tariffState.isLoadedHistory) {
        promises.push(this.tariffStore.loadHistory());
      }
      if (promises.length) {
        await Promise.all(promises); 
      }
      this.isLodingData = false;
      const debtor = this.debtors.find(x => x.Id === this.wds?.DebtorId);
      let isReady = false;
      const previousData = this.widgetDataState.dataTariffCalculator[this.widgetConfig.guid];
      if (init && previousData && previousData[0]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = `${JSON.stringify(debtor?.Tariff)}---${JSON.stringify(requestBody)}---${this.wds.includeGST}`;
          if (requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadDataTariffCalculator", [this.widgetConfig.guid, debtor?.Tariff, requestBody, this.wds.includeGST]);
      }
      const data = this.widgetDataState.dataTariffCalculator[this.widgetConfig.guid];
      if (data) {
        this.widgetData = data[1];
      } else {
        this.widgetData = null;
      }
    } else {
      this.isLodingData = false;
    }
  }

  formatFloat(value: number): string {
    let result: string;
    if (typeof value === "undefined" || value === null) {
      result = "";
    } else if (typeof value === "string") {
      result = value;
    } else {
      const decimals = this.aws?.decimals ? this.aws.decimals : 0;
      result = numbro(value).format({
        thousandSeparated: true,
        mantissa: decimals < 0 ? 0 : decimals
      });
    }
    return result;
  }

  // #region dates
  dateFrom: Date = new Date();
  dateTo: Date = new Date();

  internalWDSUpdate = false;
  @Watch('wds', { immediate: false, deep: true })
  fromWidgetConfigToVariables(): void {
    if (this.wds && !this.internalWDSUpdate) {
      const startDateM = moment(`${this.wds.startDate}T00:00:00`);
      const endDateM = moment(`${this.wds.endDate}T00:00:00`);
      this.dateFrom = startDateM.toDate();
      this.dateTo = endDateM.toDate();
    }
    this.internalWDSUpdate = false;
  }

  fromVariablesToWidgetConfig(): void {
    if (this.wds) {
      const startDateM = moment(this.dateFrom);
      const endDateM = moment(this.dateTo);
      this.wds.startDate = startDateM.format("YYYY-MM-DD");
      this.wds.endDate = endDateM.format("YYYY-MM-DD");
      this.saveWidget();
      this.reloadData();
      this.internalWDSUpdate = true;
    }
  }

  closeCalendar(): void {
    if (this.$refs.calendarFrom) {
      (this.$refs.calendarFrom as any).overlayVisible = false;
    }
    if (this.$refs.calendarTo) {
      (this.$refs.calendarTo as any).overlayVisible = false;
    }
  }

  onChangeDate(): void {
    this.debounceSelectedDateRangeTrigger();
  }

  debounceSelectedDateRangeTrigger = debounce(1000, this.onSelectedDateRangeChanged);

  onSelectedDateRangeChanged(): void {
    this.fromVariablesToWidgetConfig();
  }
  // #endregion dates

  // #region values
  readingStart = 0;
  readingEnd = 0;
  kW = 0;

  readingStartChanged(event: any): void {
    if (this.wds) {
      this.wds.ReadingStart = event.value;
      this.debounceFieldChangedTrigger();
    }
  }

  readingEndChanged(event: any): void {
    if (this.wds) {
      this.wds.ReadingEnd = event.value;
      this.debounceFieldChangedTrigger();
    }
  }

  kWChanged(event: any): void {
    if (this.wds) {
      this.wds.kW = event.value;
      this.debounceFieldChangedTrigger();
    }
  }

  gstChanged(): void {
    //this.debounceFieldChangedTrigger();
    this.onFieldChanged();
  }

  debtorChanged(): void {
    //this.debounceFieldChangedTrigger();
    this.onFieldChanged();
  }

  debounceFieldChangedTrigger = debounce(1000, this.onFieldChanged);

  onFieldChanged(): void {
    this.saveWidget();
    this.reloadData();
  }
  // #endregion values

  // #region add/remove debtor
  displayDebtorDialog = false;

  selectedDebtor: TariffDebtorModel | null = null;

  get selectedDebtorAddress(): string {
    return this.selectedDebtor ? this.selectedDebtor.Site : "";
  }

  set selectedDebtorAddress(value: string) {
    if (this.selectedDebtor) {
      this.selectedDebtor.Site = value;
    }
  }

  openDebtorDialog(debtor: TariffDebtorModel | null): void {
    if (debtor) {
      this.selectedDebtor = JSON.parse(JSON.stringify(debtor));
    } else {
      this.selectedDebtor = {
        Id: "",
        OrganisationId: this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : 0,
        Debtor: "",
        Tariff: this.allTariffs.length ? this.allTariffs[0].Id : "",
        Site: ""
      };
    }
    this.displayDebtorDialog = true;
  }

  closeDebtorDialog(): void {
    this.displayDebtorDialog = false;
  }

  async saveDebtor(): Promise<void> {
    if (this.selectedDebtor) {
      if (!this.selectedDebtor.Debtor) {
        ToastService.showToast("warn", "Debtor", "Please enter Debtor name", 5000);
      } else if (!this.selectedDebtor.Tariff) {
        ToastService.showToast("warn", "Debtor", "Please select tariff", 5000);
      } else {
        this.displayDebtorDialog = false;
        await this.tariffStore.createUpdateDebtor(this.selectedDebtor);
      }
    }
  }

  openDeleteConfirmation(index: number): void {
    ConfirmationService.showConfirmation({
      message: "Are you sure you want to delete debtor?",
      header: 'Delete Debtor',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        const item = this.debtors[index];
        const isCurrent = this.wds && item.Id == this.wds.DebtorId;
        await this.tariffStore.deleteDebtor(item.Id);
        // if current debtor is deleted, then reset current debtor
        if (this.wds && isCurrent) {
          this.wds.DebtorId = undefined;
          this.reloadData();
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion add/remove debtor

  // #region history
  displayHistoryDialog = false;

  openHistoryDialog(): void {
    this.displayHistoryDialog = true;
  }

  async createSnapshot(): Promise<void> {
    const body: TariffHistoryModel = {
      Id: "",
      OrganisationId: 0, // api will fill it
      Profile: this.wds?.DebtorId ?? "",
      Site: this.currentDebtor?.Site ?? "",
      Tariff: this.currentDebtor?.Tariff ?? "",
      StartDate: this.dateFrom,
      StartValue: this.readingStart,
      StopDate: this.dateTo,
      StopValue: this.readingEnd,
      kW: this.kW,
      Cost: this.widgetData ?? 0,
      Date: new Date()
    };
    await this.tariffStore.createUpdateHistory(body);
  }

  openDeleteHistoryConfirmation(index: number): void {
    ConfirmationService.showConfirmation({
      message: "Are you sure you want to delete record?",
      header: 'Delete History',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        const item = this.history[index];
        await this.tariffStore.deleteHistory(item.Id);
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion history
}

export default TariffCalculatorWidget;
</script>
