<template>
  <Accordion :activeIndex="0">
    <template #collapseicon>
      <i class="p-accordion-toggle-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="2" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 1H1"/></svg>
      </i>
    </template>
    <template #expandicon>
      <i class="p-accordion-toggle-icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M17 9H1m8-8v16"/></svg>
      </i>
    </template>
    <AccordionTab header="General" v-if="reports3.dataOne">
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          <label for="editRecordName">Name</label>
          <div>
            <InputText 
              id="editRecordName" 
              class="inputfield w-full"
              type="text" 
              v-model="reports3.dataOne.Name"
              placeholder="Name"
            />
          </div>
        </div>
      </div>
    </AccordionTab>

    <AccordionTab header="Schedule" v-if="reports3.dataOne">
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          todo
        </div>
      </div>
    </AccordionTab>

    <AccordionTab header="Report date range" v-if="reports3.dataOne">
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          todo
        </div>
      </div>
    </AccordionTab>

    <AccordionTab header="Data sources" v-if="reports3.dataOne">
      <div class="formgrid grid">
        <div class="field col-12 mb-0">
          todo
        </div>
      </div>
    </AccordionTab>
  </Accordion>
  <div v-if="reports3.dataOne?.Id">
    <Button
      label="Delete report"
      icon="pi pi-trash"
      class="p-button-danger"
      @click="deleteReport"
    />
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import InputText from 'primevue/inputtext';
import Button from "primevue/button";
import { useReports3Store } from "@/stores/reports3";
import ConfirmationService from "@/services/ConfirmationService";
import NavigationHelper from "@/helpers/NavigationHelper";

@Component({
  components: {
    Accordion,
    AccordionTab,
    InputText,
    Button
  },
  directives: {
  }
})
class ReportsReportRightPanelView extends Vue {
  reports3 = useReports3Store();

  deleteReport(): void {
    const message = `Are you sure you want to delete ${this.reports3.dataOne?.Name}?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.reports3.dataOne) {
          await this.reports3.delete(this.reports3.dataOne);
          if (!this.reports3.deleteError) {
            NavigationHelper.goTo('/report-manager');
          }
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
}

export default ReportsReportRightPanelView;
</script>