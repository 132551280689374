<template>
  <div 
    ref="treeOverlayToggle" 
    @click="toggleOverlay" 
    tabindex="0" 
    class="p-treeselect p-component p-inputwrapper p-inputwrapper-filled w-full flex cursor-pointer select-none relative"
    :class="{ 'p-disabled': disabled }"
  >
    <div class="p-treeselect-label-container flex-auto cursor-pointer overflow-hidden">
      <div class="p-treeselect-label block cursor-pointer overflow-hidden white-space-nowrap text-overflow-ellipsis">
        <span v-if="selectedNode">{{ selectedNode.label }}</span>
        <span v-else>{{ placeholder }}</span>
      </div>
    </div>
    <div class="p-treeselect-trigger flex justify-content-center align-items-center flex-shrink-0" role="button" aria-haspopup="tree">
      <span class="p-treeselect-trigger-icon pi pi-chevron-down"></span>
    </div>
  </div>
  <OverlayPanel ref="treeOverlayPanel" appendTo="#overlayPanelPlaceholder" :showCloseIcon="false" :dismissable="true" :style="{width: minWidth}" class="tree-overlaypanel he-tree-overlaypanel set-width-height">
    <div class="he-tree-search">
      <InputText
        :placeholder="placeholder"
        type="text"
        v-model="search"
        @input="debounceSearch()"
      />
      <span class="pi pi-search"></span>
    </div>
    <div class="flex align-items-center p-2 flex-shrink-0" v-if="allowIncompatible">
      <Checkbox v-model="showCompatible" inputId="showCompatible" :binary="true" />
      <label for="showCompatible" class="ml-2">Only Compatible</label>
    </div>
    <div class="he-tree-wrapper flex-auto">
      <BaseTree 
        v-model="nodes" 
        :defaultOpen="false" 
        :statHandler="statHandler"
        textKey="label"
        :indent="22"
        :virtualizationPrerenderCount="50" 
        :watermark="false" 
        ref="tree"
        virtualization
      >
        <template #default="{ node, stat }">
          <Button 
              :icon="stat.open ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" 
              text 
              rounded
              @click="toggleNode(stat)" v-if="node.children?.length"
              class="p-link"
          />
          <div :class="{ 'tree-node-selected': node.key === selectedId, 'tree-node-selectable': isSelectable(node), 'tree-node-without-children': !node.children?.length }">
            <span  
              @click="itemSelected(node)"
              class="flex align-items-center"
            >
              <span v-if="node.icon" :class="node.icon" class="tree-node-icon"></span>
              <span class="tree-node-label">{{ node.label }}</span>
            </span>
          </div>
        </template>
      </BaseTree>
    </div>
  </OverlayPanel>
</template>

<script lang="ts">
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import { Component, Prop, Vue } from "vue-facing-decorator";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { BaseTree } from '@he-tree/vue';
import { Watch } from 'vue-facing-decorator';
import { Stat } from '@he-tree/tree-utils';
import { debounce } from 'throttle-debounce';
import TreeHelper from '@/helpers/TreeHelper';
import { nextTick } from 'vue';

@Component({
  components: {
    OverlayPanel,
    InputText,
    Button,
    Checkbox,
    BaseTree
  },
})
class TreeSelectWithTagFilterView extends Vue {
  @Prop({ required: true }) selectedId!: string;
  @Prop({ required: true }) nodes!: TreeNodeForUI[];
  @Prop({ required: true }) changeSelectedId!: (node: TreeNodeForUI) => void;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ required: false, default: [] }) tags!: string[];
  @Prop({ required: false, default: false }) disabled!: boolean;
  @Prop({ required: false, default: true }) allowIncompatible!: boolean;


  selectedNode: TreeNodeForUI | undefined;

  model: Record<string, boolean> = {};
  expandedKeys: Record<string, boolean> = {};

  created(): void {
    if (this.selectedId) {
      this.model[this.selectedId] = true;
      this.selectedNode = this.findNodeById(this.nodes, this.selectedId);
    }
  }

  findNodeById(nodes: TreeNodeForUI[], id: string): TreeNodeForUI | undefined {
    const idTag = `id=${id}`;
    let result = nodes.find(x => x.key === id || x.tags?.includes(idTag));
    if (!result) {
      for (let index = 0; index < nodes.length; index++) {
        const element = nodes[index];
        if (element.children) {
          result = this.findNodeById(element.children, id);
          if (result) {
            if (element.key) {
              this.expandedKeys[element.key] = true;
            }
            break;
          }
        }
      }
    }
    return result;
  }

  minWidth = "420px";

  async toggleOverlay(event: Event): Promise<void> {
    if (this.$refs.treeOverlayPanel && !this.disabled) {
      if (this.$refs.treeOverlayToggle) {
        this.minWidth = (this.$refs.treeOverlayToggle as HTMLElement).getBoundingClientRect().width + "px";
      } else {
        this.minWidth = "420px";
      }
      (this.$refs.treeOverlayPanel as OverlayPanel).toggle(event);
      await nextTick();
      this.updateFinalSearch();
    }
  }

  isInternalChange = false;

  @Watch('selectedId', { immediate: false, deep: false })
  async onSelectedIdChanged(val: string, oldVal: string): Promise<void> {
    if (this.isInternalChange) {
      this.isInternalChange = false;
    } else {
      delete this.model[oldVal];
      this.model[val] = true;
      this.selectedNode = this.findNodeById(this.nodes, this.selectedId);
    }
  }

  itemSelected(node: TreeNodeForUI): void {
    if (this.isSelectable(node)) {
      this.isInternalChange = true;
      this.selectedNode = node;
      this.changeSelectedId(node);
      if (this.$refs.treeOverlayPanel) {
        (this.$refs.treeOverlayPanel as OverlayPanel).hide();
      }
    }
  }

  getTree(): any {
    return (this.$refs.tree as any);
  }

  statHandler(node: Stat<TreeNodeForUI>): Stat<TreeNodeForUI> {
    if (node.data.key && this.expandedKeys[node.data.key]) {
      node.open = true;
    }
    if (node.data.children?.length) {
      node.class = "has-children";
    }
    return node;
  }

  toggleNode(node: Stat<TreeNodeForUI>): void {
    node.open = !node.open;
    if (node.data.key) {
      this.expandedKeys[node.data.key] = node.open;
    }
  }

  search = '';
  searchFinal = '';
  debounceSearch = debounce(500, this.updateFinalSearch);
  showCompatible = true;

  @Watch('showCompatible', { immediate: false, deep: false })
  onShowCompatibleChanged(val: string, oldVal: string): void {
    this.updateFinalSearch();
  }

  updateFinalSearch(): void {
    this.searchFinal = this.search;
    const tree = this.getTree();
    if (tree) {
      const nodes = tree.statsFlat as Stat<TreeNodeForUI>[];
      TreeHelper.search(nodes, this.searchFinal, this.showCompatible ? this.tags : undefined);
    }
  }

  isSelectable(node: TreeNodeForUI): boolean {
    if (this.showCompatible) {
      if (this.tags.every(tag => {
        if (tag.includes("|")) {
          return tag.split("|").some(x => node.tags?.includes(x));
        } else {
          return node.tags?.includes(tag);
        }
      })) {
        return true;
      }
      return false;
    } else {
      return true;
    }
  }
}

export default TreeSelectWithTagFilterView;
</script>
