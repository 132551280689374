/* eslint-disable no-undef */
<template>
  <div ref="root" v-if="!isEndOfLifeState">
    <div class="title-box">{{ nodeName }}</div>
    <div class="body-box">
      <div class="field mb-0 mt-2">
        <div class="flex align-items-center">
          <InputSwitch 
            v-model="enabled"
            @change="onEnabledChange"
            class="vertical-align-top mr-2"
            inputId="sendNotificationSwitch"
          />
          <label for="sendNotificationSwitch">Send notification</label>
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1">
        <label for="">Emails</label>
        <div>
          <MultiSelect 
            v-model="selectedEmails" 
            :loading="!flowState.emails.length" 
            :options="emailsAndGroups"
            placeholder="Select Emails"
            optionLabel="label" 
            optionValue="value"
            optionGroupLabel="label" 
            optionGroupChildren="items"
            display="chip"
            :filter="true"
            filterPlaceholder="Find Emails"
            @change="onEmailsChange"
            class="p-multiselect-multiline inputfield w-full"
          >
            <template #optiongroup="slotProps">
              <div class="flex align-items-center font-bold">
                <i class="pi pi-folder font-bold mr-2 flex-shrink-0"></i>
                <div class="flex-auto">{{ slotProps.option.label }}</div>
              </div>
            </template>
            <template #option="slotProps">
              <Chip 
                :label="slotProps.option.label"
                :style="getChipStyles(slotProps.option)"
                :class="slotProps.option.color ? 'chip-colored' : ''"
              />
            </template>
            <template #value="slotProps">
              <tamplate v-if="!slotProps.value.length">
                {{ slotProps.placeholder }}
              </tamplate>
              <template v-for="(item, index) in slotProps.value" :key="`${item}-${index}`">
                <Chip 
                  :label="getLabelByValue(item)"
                  :style="getChipStylesByValue(item)"
                  :class="hasColorByValue(item) ? 'chip-colored' : ''"
                  removable
                  @remove="slotProps.value.splice(index, 1)"
                />
              </template>
            </template>
          </MultiSelect>

        </div>
      </div>

      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'out_of_limits'">
        <label for="">When consumption</label>
        <div>
          <Dropdown
            v-model="selectedValue"
            :options="outOfLimitsTypes"
            optionLabel="label"
            optionValue="value"
            @change="onValueChange"
            panelClass="reduce-options-font-size"
            class="w-full"
          />
        </div>
        <div class="mt-1">
          <InputNumber
            v-model="selectedValue2" 
            mode="decimal" 
            :minFractionDigits="2" 
            class="inputfield w-full" 
            @input="onValue2Change"
          />
        </div>
        <div class="mt-1" v-if="selectedValue === 3 || selectedValue === 4">
          <InputNumber
            v-model="selectedValue3" 
            mode="decimal" 
            :minFractionDigits="2" 
            class="inputfield w-full" 
            @input="onValue3Change"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'out_of_limits'">
        <label for="">Time between</label>
        <div class="drawflow-node-time-fields">
          <InputMask 
            class="inputfield p-inputtext w-full" 
            v-model="selectedTimeFrom" 
            @change="onTimeFromChange"
            mask="99:99:99" 
          />
          <InputMask 
            class="inputfield p-inputtext w-full" 
            v-model="selectedTimeTo" 
            @change="onTimeToChange"
            mask="99:99:99" 
          />
        </div>
      </div>

      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'data_anomaly'">
        <label for="">When</label>
        <div class="p-inputgroup w-full">
          <InputNumber
            v-model="selectedValue" 
            mode="decimal" 
            :min="1"
            :minFractionDigits="2" 
            class="inputfield w-full" 
            @input="onValueChange"
          />
          <span class="p-inputgroup-addon text-sm font-medium">%</span>
        </div>
        <span class="block mt-1">above/under normal</span>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'data_anomaly'">
        <label for="">For</label>
        <div>
          <Dropdown
            v-model="selectedValue2"
            :options="dataAnomalyHours"
            optionLabel="label"
            optionValue="value"
            @change="onValue2Change"
            panelClass="reduce-options-font-size"
            class="w-full"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'data_anomaly'">
        <label for="">Time Period</label>
        <div>
          <Dropdown
            v-model="selectedValue3"
            :options="dataAnomalyPeriods"
            optionLabel="label"
            optionValue="value"
            @change="onValue3Change"
            panelClass="reduce-options-font-size"
            class="w-full"
          />
        </div>
      </div>

      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'data_quality'">
        <label for="">No data received</label>
        <div>
          <Dropdown
            v-model="selectedValue"
            :options="dataQualityTypes"
            optionLabel="label"
            optionValue="value"
            @change="onValueChange"
            panelClass="reduce-options-font-size"
            class="w-full"
          />
        </div>
      </div>

      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'ai_report' || nodeType === 'csv_export'">
        <label for="">Frequency</label>
        <div>
          <Dropdown
            v-model="selectedValue"
            :options="aiReportFrequency"
            optionLabel="label"
            optionValue="value"
            @change="onValueChange"
            panelClass="reduce-options-font-size"
            class="w-full"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'ai_report' || nodeType === 'csv_export'">
        <label for="">Time</label>
        <div>
          <InputMask 
            class="inputfield p-inputtext w-full" 
            v-model="selectedTimeFrom" 
            @change="onTimeFromChange"
            mask="99:99:99" 
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'ai_report'">
        <label for="">Tags Only</label>
        <div>
          <InputSwitch
            v-model="selectedBool"
            @change="onBoolChange"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'ai_report' && !selectedBool">
        <label for="">Aggregation Period</label>
        <div>
          <Dropdown
            v-model="selectedValue2"
            :options="aiDataAggregations"
            optionLabel="name"
            optionValue="key"
            @change="onValue2Change"
            panelClass="reduce-options-font-size"
            class="w-full"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'ai_report' && !selectedBool || nodeType === 'csv_export'">
        <label for="">Date Range</label>
        <div>
          <Dropdown
            v-model="selectedValue3"
            :options="aiDateRanges"
            optionLabel="name"
            optionValue="key"
            @change="onValue3Change"
            panelClass="reduce-options-font-size"
            class="w-full"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'ai_report'">
        <label for="">Dashboard</label>
        <div>
          <TreeSelectView 
            :selectedId="selectedText2" 
            :nodes="dashboardNodes"
            :changeSelectedId="onDashboardNodeChange"
            placeholder="Select Dashboard"
            :openRootNodes="true"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'ai_report'">
        <label for="">Bitpool AI Skill</label>
        <div>
          <Dropdown
            v-model="selectedText3"
            :options="availablePersonas"
            optionLabel="label"
            optionValue="value"
            @change="onText3Change"
            panelClass="reduce-options-font-size"
            class="w-full"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'ai_report'">
        <label for="">Question</label>
        <div>
          <Dropdown
            v-model="selectedText4"
            :options="availableInsights"
            optionLabel="label"
            optionValue="value"
            @change="onText4Change"
            panelClass="reduce-options-font-size"
            class="w-full"
            :loading="!aiInsightLibraryStore.isLoaded"
          />
        </div>
        <div v-if="selectedText4 === 'custom'" class="mt-2">
          <Textarea
            v-model="selectedText" 
            rows="3"
            :autoResize="true"
            class="inputfield w-full" 
            @input="onTextChange"
          />
        </div>
      </div>

      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'tag_value'">
        <label for="">Tag Name</label>
        <div>
          <InputText
            v-model="selectedText"
            class="inputfield w-full" 
            @input="onTextChange"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'tag_value'">
        <label for="">When tag value</label>
        <div>
          <Dropdown
            v-model="selectedValue"
            :options="outOfLimitsTypes"
            optionLabel="label"
            optionValue="value"
            @change="onValueChange"
            panelClass="reduce-options-font-size"
            class="w-full"
          />
        </div>
        <div class="mt-1">
          <InputText
            v-model="selectedText3"
            class="inputfield w-full" 
            @input="onText3Change"
          />
        </div>
        <div class="mt-1" v-if="selectedValue === 3 || selectedValue === 4">
          <InputText
            v-model="selectedText2" 
            class="inputfield w-full" 
            @input="onText2Change"
          />
        </div>
      </div>
      
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'out_of_limits' || nodeType === 'data_anomaly' || nodeType === 'data_quality' || nodeType === 'tag_value'">
        <label for="">Custom Message</label>
        <div>
          <Textarea
            v-model="selectedCustomMessage" 
            rows="1"
            :autoResize="true"
            class="inputfield w-full" 
            @input="onCustomMessageChange"
          />
        </div>
      </div>

      <div class="field text-right mb-0 mt-2 pt-1">
      <SplitButton :disabled="testAlarmInProgress" icon="pi pi-send" label="Test" @click="test" :model="additionalActions" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Dropdown from "primevue/dropdown";
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import InputSwitch from 'primevue/inputswitch';
import InputMask from 'primevue/inputmask';
import MultiSelect from 'primevue/multiselect';
import SplitButton from 'primevue/splitbutton';
import Chip from 'primevue/chip';
import { Ref } from "vue-facing-decorator";
import { Component, Vue } from "vue-facing-decorator";
import { ComponentInternalInstance, getCurrentInstance, nextTick, reactive } from "vue";
import Drawflow from "drawflow";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import { FlowAlarmOptions } from '@/models/flow/FlowAlarmOptions';
import { AllUserData } from '@/models/user/AllUserData';
import CustomWindow from '@/CustomWindow';
import { FlowEntity } from '@/models/flow/FlowEntity';
import FlowState from '@/store/states/FlowState';
import { TestAlarmModel } from '@/models/TestAlarmModel';
import ToastService from '@/services/ToastService';
import DateHelper from '@/helpers/DateHelper';
import { TimeRange } from '@/models/enums/TimeRange';
import { TreeNodeForUI } from '@/models/nav-tree/NavTreeForUI';
import SpaceHelper from '@/helpers/SpaceHelper';
import DashboardState from '@/store/states/DashboardState';
import TreeSelectView from "@/components/views/TreeSelectView.vue";
import { useOpenAIChatStore } from '@/stores/openAIChat';
import { useAIPersonaStore } from '@/stores/aiPersona';
import { AIPersonaEntity } from '@/models/bitpool-ai/AIPersonaEntity';
import { FlowNode } from '@/models/flow/FlowNode';
import { MenuItem } from 'primevue/menuitem';
import { useNotificationGroupStore } from "@/stores/notificationGroup";
import ColorHelper from "@/helpers/ColorHelper";
import chroma from "chroma-js";
import { useAIInsightLibraryStore } from "@/stores/aiInsightLibrary";

declare const window: CustomWindow;

@Component({
  components: {
    Dropdown,
    InputText,
    InputNumber,
    Textarea,
    InputSwitch,
    InputMask,
    MultiSelect,
    SplitButton,
    Chip,
    TreeSelectView
  },
})
class FlowAlarmNodeView extends Vue {
  @Ref() readonly root!: HTMLDivElement;
  nodeId = "";
  nodeKey = "";
  nodeType = "";
  nodeName = "";
  nodeOptions: FlowAlarmOptions | null = null;
  buildTreeFromNodes: (() => FlowNode[]) | null = null;
  currentInstance: ComponentInternalInstance | null = null;
  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();
  isEndOfLifeState = false;
  enabled: boolean | null | undefined = true;
  selectedEmails: string[] = [];
  selectedEmail: string | null | undefined = null;
  selectedValue: number | null | undefined = null;
  selectedValue2: number | null | undefined = null;
  selectedValue3: number | null | undefined = null;
  selectedTimeFrom: string | null | undefined = null;
  selectedTimeTo: string | null | undefined = null;
  selectedText: string | null | undefined = null;
  selectedText2: string | null | undefined = null;
  selectedText3: string | null | undefined = null;
  selectedText4: string | null | undefined = null;
  selectedBool: boolean | null | undefined = null;
  selectedCustomMessage: string | null | undefined = null;

  outOfLimitsTypes = [
    { label: 'Greater than', value: 0 },
    { label: 'Less than', value: 1 },
    { label: 'Equal', value: 2 },
    { label: 'Between', value: 3 },
    { label: 'Not between', value: 4 }
  ];

  dataQualityTypes = [
    { label: 'Last 24 hours', value: 0 },
    { label: 'Last 7 days', value: 1 },
    { label: 'Last 30 Days', value: 2 },
    { label: 'Last 90 Days', value: 3 },
    { label: 'Last 12 Months', value: 4 }
  ];

  dataAnomalyPeriods = [
    { label: 'Last 1 day', value: 0 },
    { label: 'Last 7 days', value: 1 },
    { label: 'Last 30 Days', value: 2 },
    { label: 'Last 90 Days', value: 3 }
  ];

  dataAnomalyHours = [
    { label: 'Last 1 hour', value: 0 },
    { label: 'Last 6 hours', value: 1 },
    { label: 'Last 12 hours', value: 2 },
    { label: 'Last 24 hours', value: 3 }
  ];

  // same as ScheduleType enum
  aiReportFrequency = [
    { label: 'Disable', value: 0 },
    { label: 'Daily', value: 1 },
    { label: 'First Day of Week', value: 2 },
    { label: 'Last Day of Week', value: 3 },
    { label: 'First Day of Month', value: 4 },
    { label: 'Last Day of Month', value: 5 }
  ];

  aiDataAggregations = DateHelper.getDataAggregations();

  dashboardNodes: TreeNodeForUI[] = [];

  get aiDateRanges(): any[] {
    const result = DateHelper.getDateRanges();
    const customIndex = result.findIndex(x => x.name === "Custom");
    if (customIndex > -1) {
      result.splice(customIndex, 1);
    }
    return result;
  }

  openAIChatStore = useOpenAIChatStore();
  aiPersonaStore = useAIPersonaStore();
  notificationGroupStore = useNotificationGroupStore();
  aiInsightLibraryStore = useAIInsightLibraryStore();

  get defaultPersona(): string {
    const defaultPersona: AIPersonaEntity | undefined = this.aiPersonaStore.entities ? this.aiPersonaStore.entities.find(x => x.Enabled && x.Default) : undefined;
    return defaultPersona ? defaultPersona.Id : ""; 
  }

  get availablePersonas(): any[] {
    return (this.aiPersonaStore.entities ? this.aiPersonaStore.entities.filter(x => x.Enabled) : [])
      .map(x => { return { label: x.Name, value: x.Id }; });
  }

  get availableInsights(): any[] {
    const result = (this.aiInsightLibraryStore.entities ? this.aiInsightLibraryStore.entities : [])
      .map(x => { return { label: x.Name, value: x.Id }; });
    result.push({ label: "Custom", value: "custom" });
    return result;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }
  
  get flowState(): FlowState {
    return this.$store.state.flow;
  }

  get currentFlow(): FlowEntity | null | undefined {
    return this.flowState.currentFlow;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get emailsAndGroups(): any[] {
    const result = [];
    if (this.notificationGroupStore.entities?.length) {
      result.push({
        label: "Groups",
        items: this.notificationGroupStore.entities.map(x => {
          return { label: x.Name, value: x.Id, backgroundColor: x.Color, color: ColorHelper.getContrastColor(x.Color) };
        })
      });
    }
    if (this.flowState.emails?.length) {
      result.push({
        label: "Emails",
        items: this.flowState.emails.map(x => {
          return { label: x, value: x };
        })
      });
    }
    return result;
  }

  getLabelByValue(value: string): string {
    const group = this.emailsAndGroups.find(x => x.label === value.includes("@") ? "Emails" : "Groups");
    if (group) {
      const result = group.items.find((x: any) => x.value === value);
      return result ? result.label : value;
    }
    return value;
  }

  hasColorByValue(value: string): boolean {
    const group = this.emailsAndGroups.find(x => x.label === value.includes("@") ? "Emails" : "Groups");
    if (group) {
      const result = group.items.find((x: any) => x.value === value);
      return result ? !!result.color : false;
    }
    return false;
  }

  getChipStylesByValue(value: string): string {
    const group = this.emailsAndGroups.find(x => x.label === value.includes("@") ? "Emails" : "Groups");
    if (group) {
      const result = group.items.find((x: any) => x.value === value);
      return result ? this.getChipStyles(result) : "";
    }
    return "";
  }

  getChipStyles(item: any): string {
    let result: string = "";
    if (item.backgroundColor) {
      const colorRGB = chroma.hex(item.backgroundColor).rgb();
      result = `--colored-chip-selected-bg-color: ${colorRGB[0]}, ${colorRGB[1]}, ${colorRGB[2]}; --colored-chip-selected-bg-color-with-opacity: rgba(var(--colored-chip-selected-bg-color), var(--colored-chip-selected-bg-color-opacity));`;
    }
    if (item.color) {
      result = `${result}color: ${item.color};`;
    }
    return result;
  }

  get df(): Drawflow | null {
    return this.currentInstance
      ? (this.currentInstance.appContext.config.globalProperties.$df as Drawflow)
      : null;
  }

  unmountNodeEvent(e: string): void {
    if (this.nodeId === e) {
      this.emitter.off("unmount_df_node", this.unmountNodeEvent);
      const ci = this.currentInstance as any;
      if (ci.um && ci.um.length) {
        for (const um of ci.um) {
          um();
        }
      }
    }
  }

  async mounted(): Promise<void> {
    this.currentInstance = getCurrentInstance();
    this.emitter.on("unmount_df_node", this.unmountNodeEvent);
    await nextTick();
    if (this.root) {
      const nodeElement = this.root.closest(".drawflow-node");
      if (nodeElement) {
        this.nodeId = nodeElement.id.slice(5);
        const node = this.df?.getNodeFromId(this.nodeId);
        if (node) {
          this.nodeKey = node.data.nodeKey;
          this.nodeType = node.data.nodeType;
          this.nodeName = node.data.nodeName;
          this.buildTreeFromNodes = (window as any).bpFlowBuildTreeFromNodes; // can't transfer via props
          if (this.nodeType === "ai_report") {
            const treeAndDictionaries = SpaceHelper.buildTreeForTreeSelect(this.dashboardState.dashboards, null);
            this.dashboardNodes = treeAndDictionaries[0];
          }
          if (node.data.nodeOptions) {
            this.nodeOptions = node.data.nodeOptions;
            if (this.nodeOptions && (this.nodeType === "ai_report" || this.nodeType === "csv_export")) {
              if (typeof this.nodeOptions.TimeFrom === "undefined") {
                this.nodeOptions.TimeFrom = "00:00:00";
              }
              if (typeof this.nodeOptions.Value2 === "undefined" && this.nodeType === "ai_report") {
                this.nodeOptions.Value2 = -1; // auto
              }
              if (typeof this.nodeOptions.Value3 === "undefined") {
                this.nodeOptions.Value3 = TimeRange.LastWeek;
              }
              if (!this.nodeOptions.Text && this.nodeType === "ai_report") {
                this.nodeOptions.Text = "Create a report from the data";
              }
              if (!this.nodeOptions.Text3 && this.nodeType === "ai_report") {
                this.nodeOptions.Text3 = "";
              }
            } else if (this.nodeOptions && this.nodeType === "tag_value") {
              if (typeof this.nodeOptions.Value !== "number") {
                this.nodeOptions.Value = 2;
              }
              if (!this.nodeOptions.Text) {
                this.nodeOptions.Text = "";
              }
              if (!this.nodeOptions.Text3) {
                this.nodeOptions.Text3 = "";
              }
              if (!this.nodeOptions.Text2) {
                this.nodeOptions.Text2 = "";
              }
            }
          } else {
            this.nodeOptions = { 
              Enabled: true, 
              Email: this.allUserData.userName, 
              Value: 
                (this.nodeType === "out_of_limits" || this.nodeType === "data_quality") ? 
                  0 : 
                  this.nodeType === "data_anomaly" ? 
                    50 : 
                    (this.nodeType === "ai_report" || this.nodeType === "csv_export") ? 
                      2 : // First Day of Week
                      this.nodeType === "tag_value" ? 
                        2 : // Equal
                        undefined, 
              Value2: 
                this.nodeType === "out_of_limits" ? 
                  100 : 
                  this.nodeType === "data_anomaly" ? 
                    0 : 
                    this.nodeType === "ai_report" ? 
                      -1 : // auto
                      undefined,
              Value3: 
                this.nodeType === "data_anomaly" ? 
                  2 : 
                  (this.nodeType === "ai_report" || this.nodeType === "csv_export") ? 
                    TimeRange.LastWeek : 
                    undefined, 
              TimeFrom: 
                this.nodeType === "out_of_limits" ? 
                  "06:00:00" : 
                  (this.nodeType === "ai_report" || this.nodeType === "csv_export") ? 
                    "00:00:00" :
                    undefined, 
              TimeTo: this.nodeType === "out_of_limits" ? "18:00:00" : undefined,
              Text: 
                this.nodeType === "ai_report" ? 
                  "Create a report from the data" : 
                  this.nodeType === "tag_value" ? "" : undefined,
              Text2: this.nodeType === "tag_value" ? "" : undefined,
              Text3: (this.nodeType === "ai_report" || this.nodeType === "tag_value") ? "" : undefined,
              Bool: this.nodeType === "ai_report" ? false : undefined,
              CustomMessage: this.nodeType === "out_of_limits" || this.nodeType === "data_anomaly" || this.nodeType === "data_quality" || this.nodeType === "tag_value" ?
                "" : undefined
            };
            this.updateNodeOptions();
          }

          this.enabled = this.nodeOptions?.Enabled;
          if (this.nodeOptions && this.nodeOptions.Email) {
            this.selectedEmails = this.nodeOptions.Email.split(/\r?\n/);
          }
          this.selectedEmail = this.nodeOptions?.Email;
          this.selectedValue = this.nodeOptions?.Value;
          this.selectedValue2 = this.nodeOptions?.Value2;
          this.selectedValue3 = this.nodeOptions?.Value3;
          this.selectedTimeFrom = this.nodeOptions?.TimeFrom;
          this.selectedTimeTo = this.nodeOptions?.TimeTo;
          this.selectedText = this.nodeOptions?.Text;
          this.selectedText2 = this.nodeOptions?.Text2;
          if (this.nodeType === "ai_report") {
            this.selectedText3 = this.nodeOptions?.Text3 ? this.nodeOptions.Text3 : this.defaultPersona;
            this.selectedText4 = this.nodeOptions?.Text4 ? this.nodeOptions.Text4 : "custom";
          } else {
            this.selectedText3 = this.nodeOptions?.Text3;
            this.selectedText4 = this.nodeOptions?.Text4;
          }
          this.selectedBool = this.nodeOptions?.Bool;
          if (this.nodeType === "out_of_limits" || this.nodeType === "data_anomaly" || this.nodeType === "data_quality" || this.nodeType === "tag_value") {
            this.selectedCustomMessage = this.nodeOptions?.CustomMessage ?? "";
          } else {
            this.selectedCustomMessage = this.nodeOptions?.CustomMessage;
          }
        }
        await nextTick();
        // https://github.com/jerosoler/Drawflow/issues/405
        this.df?.updateConnectionNodes(nodeElement.id);
      }
    }
  }

  unmounted(): void {
    this.isEndOfLifeState = true;
  }

  getNodeData(): any  {
    const node = this.df?.getNodeFromId(this.nodeId);
    if (node) {
      const newData = Object.assign({}, node.data);
      return newData;
    } else {
      return null;
    }
  }

  updateNodeOptions(): void {
    const nodeData = this.getNodeData();
    if (nodeData) {
      nodeData.nodeOptions = this.nodeOptions;
      this.df?.updateNodeDataFromId(this.nodeId, nodeData);
      // call nodeDataChanged event https://github.com/jerosoler/Drawflow/issues/287
      // dispatch is missing in typescript defenition
      (this.df as any)?.dispatch('nodeDataChanged', this.nodeId );
    }
  }

  onEnabledChange(event: Event): void {
    if (this.nodeOptions) {
      this.nodeOptions.Enabled = this.enabled;
      this.updateNodeOptions();
    }
  }

  onEmailsChange(): void {
    this.selectedEmail = this.selectedEmails.join("\r\n");
    if (this.nodeOptions) {
      this.nodeOptions.Email = this.selectedEmail;
      this.updateNodeOptions();
    }
  }
  
  onValueChange(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.Value = event.value;
      this.updateNodeOptions();
    }
  }

  onValue2Change(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.Value2 = event.value;
      this.updateNodeOptions();
    }
  }

  onValue3Change(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.Value3 = event.value;
      this.updateNodeOptions();
    }
  }

  onTimeFromChange(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.TimeFrom = this.selectedTimeFrom;
      this.updateNodeOptions();
    }
  }

  onTimeToChange(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.TimeTo = this.selectedTimeTo;
      this.updateNodeOptions();
    }
  }

  onTextChange(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.Text = this.selectedText;
      this.updateNodeOptions();
    }
  }

  onText2Change(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.Text2 = this.selectedText2;
      this.updateNodeOptions();
    }
  }

  onDashboardNodeChange(node: TreeNodeForUI): void {
    this.selectedText2 = node.key ?? "";
    this.onText2Change(null);
  }

  onText3Change(event: any): void {
    if (this.nodeOptions) {
      if (this.nodeType === "ai_report" && this.selectedText3 === this.defaultPersona) {
        this.nodeOptions.Text3 = "";
      } else {
        this.nodeOptions.Text3 = this.selectedText3;
      }
      this.updateNodeOptions();
    }
  }

  onText4Change(event: any): void {
    if (this.nodeOptions) {
      if (this.nodeType === "ai_report" && this.selectedText4 === "custom") {
        this.nodeOptions.Text4 = "";
      } else {
        this.nodeOptions.Text4 = this.selectedText4;
      }
      this.updateNodeOptions();
    }
  }

  onBoolChange(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.Bool = this.selectedBool;
      this.updateNodeOptions();
    }
  }

  onCustomMessageChange(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.CustomMessage = this.selectedCustomMessage;
      this.updateNodeOptions();
    }
  }

  testAlarmInProgress = false;
  async test(): Promise<void> {
    if (this.selectedEmail) {
      // find connected node
      let poolName = "";
      let poolKey = "";
      let streamName = "";
      let streamKey = "";
      const alarmNode = this.df?.getNodeFromId(this.nodeId);
      if (alarmNode){
        for (const key in alarmNode.inputs) {
          if (alarmNode.inputs[key].connections.length > 0) {
            const connectionInfo = alarmNode.inputs[key].connections[0];
            const connectedNode = this.df?.getNodeFromId(connectionInfo.node);
            if (connectedNode) {
              poolName = connectedNode.data?.nodeOptions?.PoolName;
              poolKey = connectedNode.data?.nodeOptions?.PoolKey;
              streamName = connectedNode.data?.nodeOptions?.StreamName;
              streamKey = connectedNode.data?.nodeOptions?.StreamKey;
            }
          }
        }
      }

      this.testAlarmInProgress = true;
      const body: TestAlarmModel = {
        FlowId: this.currentFlow && this.currentFlow._id ? this.currentFlow._id : "new",
        FlowName: this.currentFlow && this.currentFlow.Name ? this.currentFlow.Name : "New",
        PoolName: poolName ? poolName : "Test Pool",
        PoolKey: poolKey ? poolKey : "00000000-0000-0000-0000-000000000000",
        StreamName: streamName ? streamName : "Test Stream",
        StreamKey: streamKey ? streamKey : "00000000-0000-0000-0000-000000000000",
        AlarmType: this.nodeType,
        Emails: this.selectedEmail,
        BitpoolOSDomain: window.location.protocol + '//' + window.location.host
      };
      await this.$store.dispatch("flow/testAlarm", body);
      this.testAlarmInProgress = false;
    } else {
      ToastService.showToast(
        "error",
        "Can't send test insight",
        "Please enter email for testing",
        5000
      );
    }
  }
  async testWithData(): Promise<void> {
    if (this.selectedEmail) {
      if (this.buildTreeFromNodes) {
        const tree = this.buildTreeFromNodes();
        const flow = Object.assign({}, this.currentFlow);
        flow.Tree = tree;
        this.testAlarmInProgress = true;
        await this.$store.dispatch("flow/testAlarmWithData", [this.nodeKey, flow]);
        this.testAlarmInProgress = false;
      }
    } else {
      ToastService.showToast(
        "error",
        "Can't send test insight",
        "Please enter email for testing",
        5000
      );
    }
  }
  get additionalActions(): MenuItem[] {
    const result = [
      {
        label: this.nodeType === "ai_report" ? "Generate" : "Test with data",
        command: () => {
          this.testWithData();
        }
      }
    ];
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }
}

export default FlowAlarmNodeView;
</script>
