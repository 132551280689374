<template>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M1 6.72c0-2.002 0-3.003.4-3.768A3.622 3.622 0 0 1 3.002 1.39C3.786 1 4.813 1 6.867 1h10.266c2.054 0 3.08 0 3.865.39.69.342 1.25.89 1.602 1.562.4.765.4 1.766.4 3.769v6.435c0 2.003 0 3.004-.4 3.769a3.623 3.623 0 0 1-1.602 1.562c-.784.39-1.811.39-3.865.39H9.17c-.763 0-1.144 0-1.509.073a3.733 3.733 0 0 0-.93.318c-.332.165-.63.398-1.226.862L2.59 22.405c-.509.396-.763.595-.977.595a.617.617 0 0 1-.479-.224C1 22.613 1 22.296 1 21.66V6.72Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class CommentsIconSvg extends Vue {
}

export default CommentsIconSvg;
</script>