<template>
  <div class="widget-type-data-leaderboard widget-with-datatable">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-show="!isLodingData && !isNoData" class="widget-datatable-container widget-datatable-container-vertical-scrollable">
      <div class="widget-data-sample text-center">
        Date sample was taken: 
        <DateTimezoneView v-if="dateTaken" :date="dateTaken" timezone="local"/>
        <span v-else>-</span>
      </div>
      <DataTable :value="unlockedRows" :frozenValue="lockedRows" stripedRows showGridlines removableSort responsiveLayout="scroll" class="p-datatable-sm">
        <Column headerStyle="width: 1%; min-width: 48px;">
          <template #body="{data,frozenRow,index}">
            <Button v-tippy="frozenRow ? 'Unlock' : 'Lock'" type="button" :icon="frozenRow ? 'pi pi-lock-open' : 'pi pi-lock'" :disabled="frozenRow ? false : lockedRows.length >= 2" class="p-button-sm p-button-icon-only p-button-rounded p-button-text p-button-secondary button-lock" @click="toggleLock(data, frozenRow, index)"/>
          </template>
        </Column>        
        <Column :sortable="true" field="rank" header="Rank" headerStyle="min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            {{formatInt(slotProps.data.rank)}}
          </template>
        </Column>
        <Column :sortable="true" field="name" header="Name" headerStyle="min-width: min-content;" headerClass="no-break-word" bodyClass="break-word">
          <template #body="slotProps">
            <a target="_blank" :href="slotProps.data.link">{{slotProps.data.name}}</a>
          </template>
        </Column>
        <Column v-if="aws?.dataConditions?.length" :sortable="true" field="valueCondition" header="Condition" headerStyle="min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <Chip 
              v-if="slotProps.data.backgroundColor" 
              :label="slotProps.data.valueCondition ?? ''" 
              class="text-xs"
              :style="`${slotProps.data.backgroundColor ? `background-color: ${slotProps.data.backgroundColor};` : ''} ${slotProps.data.color ? `color: ${slotProps.data.color};` : ''}`"
            />
            <span v-else>
              {{ slotProps.data.valueCondition ?? ""}}
            </span>
          </template>
        </Column>
        <Column :sortable="true" field="value" header="Value" headerStyle="min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            {{ formatFloat(slotProps.data.value)}}
          </template>
        </Column>
        <Column field="unit" header="Unit" headerStyle="min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word"></Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import Chip from 'primevue/chip';
import numbro from "numbro";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import { AggregationType } from '@/models/enums/AggregationType';
import StreamOption from '@/models/dashboard/StreamOption';
import DateTimezoneView from '@/components/views/DateTimezoneView.vue';
import ColorHelper from '@/helpers/ColorHelper';
import { DashboardType } from '@/models/dashboard/DashboardType';

@Component({
  components: {
    ProgressSpinner,
    DataTable,
    Column,
    Button,
    Chip,
    WidgetNoDataView,
    DateTimezoneView
  }
})
class DataLeaderboardWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.lockedIndexes = [];
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.configuredStreams.length) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await Promise.all([
          this.$store.dispatch("widgetData/loadLastDateOnCurrentTimeRange", [this.widgetConfig.guid, requestBody]),
          this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody])
        ]);        
      }
      const lastDates = this.widgetDataState.lastDateOnCurrentTimeRange[this.widgetConfig.guid][1];
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody, lastDates);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dateTaken: number | null = null;

  tableValues: any[] = [];

  lockedIndexes: number[] = [];

  get lockedRows(): any[] {
    const result: any[] = [];
    if (this.tableValues) {
      this.tableValues.forEach(row => {
        if (this.lockedIndexes.includes(row.rowIndex)) {
          result.push(row);
        }
      });
    }
    return result;
  }

  get unlockedRows(): any[] {
    const result: any[] = [];
    if (this.tableValues) {
      this.tableValues.forEach(row => {
        if (!this.lockedIndexes.includes(row.rowIndex)) {
          result.push(row);
        }
      });
    }
    return result;
  }

  toggleLock(data: any, frozen: boolean, index: number): void {
    const lockIndex = this.lockedIndexes.findIndex(x => x === data.rowIndex);
    if (lockIndex >= 0) {
      this.lockedIndexes.splice(lockIndex, 1);
    } else {
      this.lockedIndexes.push(data.rowIndex);
    }
  }

  get configuredStreams(): StreamOption[] {
    const result = this.wds?.streamOptions?.filter(x => x.StreamKey);
    return result ? result : [];
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest, lastDates: Record<string, number | null> | null): void {
    this.chartData = data;
    this.requestBody = requestBody;
    let lastDate: number | null = null;
    if (lastDates) {
      for (const key in lastDates) {
        const value = lastDates[key];
        if (lastDate === null) {
          lastDate = value;
        } else if (value !== null) {
          if (value > lastDate) {
            lastDate = value;
          }
        }
      }
    }
    this.dateTaken = lastDate;
    let tableValues: any[] = [];
    if (this.wds && this.configuredStreams.length) {
      this.configuredStreams.forEach((streamOption, index) => {
        tableValues.push({
          rowIndex: index,
          rank: 0,
          streamKey: streamOption.StreamKey,
          name: streamOption.Label,
          value: undefined,
          valueCondition: undefined,
          backgroundColor: undefined,
          color: undefined,
          unit: this.aws?.widgetUnit ? this.aws.widgetUnit : "",
          link: this.buildHref(streamOption)
        });
      });
    }
    this.chartData.forEach((streamData, index) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length) {
        const tableValue = tableValues[index];
        let aggregationType = streamData.StreamValuesRequest.AggregationType;
        if (typeof aggregationType === "string") {
          const aggregationTypeStr = aggregationType as unknown as string;
          aggregationType = AggregationType[aggregationTypeStr as keyof typeof AggregationType];
        }
        tableValue.value = streamData.Data[0].y;
      }
    });
    // conditions and colors
    if (this.aws?.dataConditions?.length) {
      tableValues.forEach((tableValue, index) => {
        const condition = this.getCondition(tableValue.value);
        tableValue.valueCondition = condition[0];
        if (condition[1]) {
          tableValue.backgroundColor = condition[1];
          tableValue.color = ColorHelper.getContrastColor(condition[1]);
        }
      });
    }
    // apply filter
    if (this.aws?.leaderboardFilterRule) {
      if (this.aws?.leaderboardFilterRule) {
        tableValues.forEach((tableValue, index) => {
          if (tableValue.value !== undefined) {
            const rule = this.aws?.leaderboardFilterRule;
            const filterValue = 
              typeof tableValue.value === "string" ? 
                (this.aws?.leaderboardFilterValueStr ?? "") as string :
                (this.aws?.leaderboardFilterValue ?? 0) as number;
            const filterValue2 = (this.aws?.leaderboardFilterValue2 ?? 0) as number;
            let isPassed = false;
            if (rule === "=") {
              isPassed = tableValue.value === filterValue;
            } else if (rule === "!=") {
              isPassed = tableValue.value !== filterValue;
            } else if (rule === ">=") {
              isPassed = tableValue.value >= filterValue;
            } else if (rule === ">") {
              isPassed = tableValue.value > filterValue;
            } else if (rule === "<=") {
              isPassed = tableValue.value <= filterValue;
            } else if (rule === "<") {
              isPassed = tableValue.value < filterValue;
            } else if (rule === "Between") {
              isPassed = tableValue.value > filterValue && tableValue.value < filterValue2;
            } else if (rule === "Not between") {
              isPassed = tableValue.value <= filterValue || tableValue.value >= filterValue2;
            } else if (rule === "No data") {
              isPassed = typeof tableValue.value === "undefined" || tableValue.value === null;
            }
            if (!isPassed) {
              tableValue.value = undefined;
            }
          }
        });
      }
      tableValues = tableValues.filter(x => x.value !== undefined);
    }
    // sort values
    tableValues.sort((a, b) => { return this.aws?.leaderboardDirection === "desc" ? b.value - a.value : a.value - b.value; });
    tableValues.forEach((x, index) => {
      x.rank = index + 1;
    });
    this.tableValues = tableValues;
    if (!tableValues.length) {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }

  getCondition(value: number | string): [string, string] {
    // value, color
    const result: [string, string] = ["", ""];
    if (typeof value === "string") {
      if (this.aws?.dataConditions?.length) {
        for (const dataCondition of this.aws.dataConditions) {
          switch (dataCondition.rule) {
            case "=": {
              if (value === dataCondition.valueStr) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "!=": {
              if (value !== dataCondition.valueStr) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
          }
        }
      }
      result[0] = value;
    } else {
      if (this.aws?.dataConditions?.length) {
        for (const dataCondition of this.aws.dataConditions) {
          switch (dataCondition.rule) {
            case "=": {
              if (value === dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "!=": {
              if (value !== dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case ">=": {
              if (value >= dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case ">": {
              if (value > dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "<=": {
              if (value <= dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "<": {
              if (value < dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "Between": {
              if (typeof dataCondition.value2 === "number" && 
                value >= dataCondition.value && value < dataCondition.value2) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "No data": {
              if (typeof value === "undefined" || value === null) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
          }
        }
      }
      const decimals = this.aws?.decimals ? this.aws.decimals : 0;
      result[0] = numbro(value).format({
        thousandSeparated: true,
        mantissa: decimals < 0 ? 0 : decimals
      });
    }
    return result;
  }

  formatFloat(value: number): string {
    let result: string;
    if (typeof value === "undefined" || value === null) {
      result = "";
    } else if (typeof value === "string") {
      result = value;
    } else {
      const decimals = this.aws?.decimals ? this.aws.decimals : 0;
      result = numbro(value).format({
        thousandSeparated: true,
        mantissa: decimals < 0 ? 0 : decimals
      });
    }
    return result;
  }

  formatInt(value: number): string {
    let result: string;
    if (typeof value === "undefined" || value === null) {
      result = "";
    } else if (typeof value === "string") {
      result = value;
    } else {
      result = numbro(value).format({
        thousandSeparated: true,
        mantissa: 0
      });
    }
    return result;
  }

  buildHref(streamOption: StreamOption): string {
    if ((streamOption.linkType === 0 || !streamOption.link)) {
      return `/data/streams/${streamOption.StreamKey}`;
    } else {
      const id = streamOption.link;
      const dashboardType = streamOption.linkDashboardType === DashboardType.Personal ? DashboardType.Personal : DashboardType.Organisation;
      return `/dashboards?pane=${dashboardType}&id=${id}`;
    }
  }
}

export default DataLeaderboardWidget;
</script>
