<template>
<svg width="86" height="70" viewBox="0 0 86 70" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_514_407)">
<path d="M42.7778 44.3333L47.5417 33.9306L57.9444 29.1667L47.5417 24.4028L42.7778 14L38.0139 24.4028L27.6111 29.1667L38.0139 33.9306L42.7778 44.3333ZM9.72222 58.3333C8.16667 58.3333 6.80556 57.75 5.63889 56.5833C4.47222 55.4167 3.88889 54.0556 3.88889 52.5V5.83333C3.88889 4.27778 4.47222 2.91667 5.63889 1.75C6.80556 0.583333 8.16667 0 9.72222 0H75.8333C77.3889 0 78.75 0.583333 79.9167 1.75C81.0833 2.91667 81.6667 4.27778 81.6667 5.83333V52.5C81.6667 54.0556 81.0833 55.4167 79.9167 56.5833C78.75 57.75 77.3889 58.3333 75.8333 58.3333H9.72222ZM9.72222 52.5H75.8333V5.83333H9.72222V52.5ZM9.72222 52.5V5.83333V52.5ZM2.91667 70C2.07407 70 1.37731 69.7245 0.826389 69.1736C0.275463 68.6227 0 67.9259 0 67.0833C0 66.2407 0.275463 65.544 0.826389 64.9931C1.37731 64.4421 2.07407 64.1667 2.91667 64.1667H82.6389C83.4815 64.1667 84.1782 64.4421 84.7292 64.9931C85.2801 65.544 85.5556 66.2407 85.5556 67.0833C85.5556 67.9259 85.2801 68.6227 84.7292 69.1736C84.1782 69.7245 83.4815 70 82.6389 70H2.91667Z" class="fill-color" fill="#CBCBCB"/>
</g>
<defs>
<clipPath id="clip0_514_407">
<rect width="86" height="70" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class PresentSvg extends Vue {
}

export default PresentSvg;
</script>