<template>
  <header>
    <h2 class="mb-0" v-if="imageGalleryStore.search">Search Results</h2>
    <div v-else-if="selectedItem">
      <div class="mb-1 w-full flex-auto">
        <Button label="Back to library" icon="pi pi-angle-left text-lg md:text-xl -ml-1" class="p-button-outlined go-back-link" @click="backClick" />
      </div>

      <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center row-gap-3 md:column-gap-4 md:w-full md:flex-auto">
        <h2 class="mb-0">{{ selectedItem?.Name }}</h2>
        <div v-if="selectedItem.OrganisationId !== 0 || canEditGlobal" class="flex flex-column md:flex-row md:align-items-center row-gap-3 md:column-gap-2 md:flex-shrink-0">
          <FileUpload
            mode="basic" 
            name="files[]" 
            :url="uploadImageUrl" 
            @before-send="beforeSend" 
            @upload="onUploadImage"
            @error="onError" 
            :multiple="true" 
            :auto="true" 
            accept="image/*" 
            :withCredentials="true"
            chooseLabel="Upload image"
            class="graphic-library-new-item-button"
          >
            <template #uploadicon>
              <i class="pi pi-upload"></i>
            </template>
          </FileUpload>

          <Button
            label="Edit group"
            icon="pi pi-pencil"
            @click="openEditGroupDialog"
            class="p-button-secondary p-button-outlined graphic-library-edit-item-button"
          />
        </div>
      </div>
    </div>
    
    <div v-else>
      <h2 class="mb-0">Groups List</h2>
      <Button label="Create new group" icon="pi pi-plus-circle" @click="openNewGroupDialog" class="graphic-library-new-item-button" />
    </div>

    <Dialog 
      header="New Group" 
      v-model:visible="displayNewGroupDialog" 
      :modal="true" 
      :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}"
    >
      <div class="dialog-content">
        <BlockUI 
          :blocked="saveInProgress" 
          :autoZIndex="false" 
          :baseZIndex="100"  
          class="blockui-with-spinner blockui-with-fixed-spinner" 
          :class="saveInProgress ? 'blockui-blocked' : ''"
        >
          <div class="field mb-0">
            <label for="newGroupName">Group Name</label>
            <div>
              <InputText
                id="newGroupName"
                class="inputfield w-full"
                type="text"
                v-model="newGroupName"
                @keyup.enter="createGroup"
              />
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <Button 
          label="Close" 
          icon="pi pi-times" 
          @click="displayNewGroupDialog = false" 
          class="p-button-text p-button-secondary"
        />
        <Button 
          label="Save" 
          :icon="saveInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
          @click="createGroup" 
          :disabled='saveInProgress || !newGroupName' 
        />
      </template>
    </Dialog>

    <Dialog 
      header="Edit Group" 
      v-model:visible="displayEditGroupDialog" 
      :modal="true" 
      :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}"
    >
      <div class="dialog-content">
        <BlockUI 
          :blocked="saveInProgress || deleteInProgress" 
          :autoZIndex="false" 
          :baseZIndex="100"  
          class="blockui-with-spinner blockui-with-fixed-spinner" 
          :class="saveInProgress ? 'blockui-blocked' : ''"
        >
          <div class="field mb-0">
            <label for="newGroupName">Group Name</label>
            <div>
              <InputText
                id="newGroupName"
                class="inputfield w-full"
                type="text"
                v-model="groupName"
                @keyup.enter="createGroup"
              />
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #header>
        <div class="flex-auto flex justify-content-between align-items-center gap-4">
          <span class="p-dialog-title">Edit Group</span>
          <div class="pr-3">
            <div class="flex align-items-center">
              <InputSwitch 
                inputId="image-gallery-group-edit-global"
                v-model="groupPublic"
                class="vertical-align-top"
                :disabled="saveInProgress || deleteInProgress"
              />
              <label for="image-gallery-group-edit-global" class="mb-0 ml-2">Public</label>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <Button
          label="Delete"
          :icon="deleteInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-trash'" 
          severity="danger"
          @click="deleteGroup" 
          :disabled="saveInProgress || deleteInProgress" 
          class="flex-shrink-0 mr-auto "
        />
        <Button 
          label="Close" 
          icon="pi pi-times" 
          @click="displayEditGroupDialog = false" 
          class="p-button-text p-button-secondary flex-shrink-0"
        />
        <Button 
          label="Save" 
          :icon="saveInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
          @click="saveGroup" 
          :disabled='saveInProgress || deleteInProgress || !groupName' 
          class="flex-shrink-0"
        />
      </template>
    </Dialog>
  </header>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import ImageGalleryGroupsView from "@/components/views/image-gallery/ImageGalleryGroupsView.vue";
import { useImageGalleryStore } from "@/stores/imageGallery";
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";
import { useImageGalleryGroupStore } from "@/stores/imageGalleryGroup";
import { ImageGalleryGroupEntity } from "@/models/image-gallery/ImageGalleryGroupEntity";
import { AllUserData } from "@/models/user/AllUserData";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { useOrganisationStore } from "@/stores/organisation";
import moment from "moment";
import AuthState from "@/store/states/AuthState";
import FileUpload, { FileUploadBeforeSendEvent, FileUploadErrorEvent } from "primevue/fileupload";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import Dialog from 'primevue/dialog';
import BlockUI from "primevue/blockui";
import Button from "primevue/button";
import InputSwitch from "primevue/inputswitch";
import ProgressSpinner from "primevue/progressspinner";
import ConfirmationService from "@/services/ConfirmationService";

@Component({
  components: {
    InputText,
    Dialog,
    BlockUI,
    FileUpload,
    Button,
    InputSwitch,
    ProgressSpinner,
    ImageGalleryGroupsView
  },
})
class ImageGalleryHeaderView extends Vue {  
  imageGalleryGroupStore = useImageGalleryGroupStore();
  imageGalleryStore = useImageGalleryStore();
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }
  
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }
  
  // #region new group
  displayNewGroupDialog = false;
  newGroupName = "";

  openNewGroupDialog(): void {
    this.newGroupName = "New group";
    this.displayNewGroupDialog = true;
  }

  async createGroup(): Promise<void> {
    if (this.newGroupName) {
      const nowUtc = moment.utc().toDate();
      const result = await this.imageGalleryGroupStore.createUpdate({
        Id: "",
        Name: this.newGroupName,
        CoverBase64: "",
        OrganisationId: this.currentOrganisation?.Id ?? 0,
        Created: nowUtc,
        Updated: nowUtc,
        CreatedBy: this.allUserData.userName,
        UpdatedBy: this.allUserData.userName
      });

      if (result) {
        this.displayNewGroupDialog = false;
      }
    }
  }
  // #endregion new group

  // #region edit group
  get saveInProgress(): boolean {
    const result = this.imageGalleryGroupStore.updateInProgress;
    return result;
  }

  get deleteInProgress(): boolean {
    const result = this.imageGalleryGroupStore.deleteInProgress;
    return result;
  }

  get selectedItem(): ImageGalleryGroupEntity | null {
    return this.imageGalleryGroupStore.selectedItem;
  }

  set selectedItem(value: ImageGalleryGroupEntity | null) {
    this.imageGalleryGroupStore.selectedItem = value;
  }

  displayEditGroupDialog = false;
  groupName = "";
  groupPublic = false;

  openEditGroupDialog(): void {
    if (this.selectedItem) {
      this.groupName = this.selectedItem.Name;
      this.groupPublic = this.selectedItem.OrganisationId === 0;
      this.displayEditGroupDialog = true;
    }
  }
  
  async saveGroup(): Promise<void> {
    if (this.selectedItem && this.groupName) {
      const copy: ImageGalleryGroupEntity = JSON.parse(JSON.stringify(this.selectedItem));
      copy.Name = this.groupName;
      copy.OrganisationId = this.groupPublic ? 0 : this.currentOrganisation?.Id ?? 0;
      const result = await this.imageGalleryGroupStore.createUpdate(copy);
      if (result) {
        this.selectedItem = result;
        this.displayEditGroupDialog = false;
      }
    }
  }

  deleteGroup(): void {
    const message = `Are you sure you want to delete Group?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Group',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.selectedItem) {
          const result = await this.imageGalleryGroupStore.delete(this.selectedItem);
          if (result) {
            this.displayEditGroupDialog = false;
            this.backClick();
          }
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion edit group

  // #region navigation
  backClick(): void {
    this.selectedItem = null;
    this.groupName = "";
    this.loadData();
  }

  loadData(): void {
    if (this.imageGalleryStore.search || this.selectedItem) {
      this.imageGalleryStore.load(this.selectedItem ? this.selectedItem.Id : "", 0, this.imageGalleryStore.take, this.imageGalleryStore.search);
    } else {
      this.imageGalleryStore.$reset();
    }
  }
  // #endregion navigation

  // #region upload
  get uploadImageUrl() {
    return `${this.$store.state.apiUrl}/rest/BitPool_V2/os/ImageGallery/${(this.imageGalleryGroupStore.selectedItem ? this.imageGalleryGroupStore.selectedItem.Id : 'default')}/Upload`;
  }
  
  // Callback to invoke before file send begins to customize the request such as adding headers.
  beforeSend(event: FileUploadBeforeSendEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.formData: FormData object.
    event.xhr.setRequestHeader("Authorization", this.$store.state.auth.authKey);
    event.xhr.setRequestHeader("Accept", "application/json");
  }

  // Callback to invoke when file upload is complete.
  onUploadImage(event: FileUploadErrorEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Uploaded files.
    const response: ImageGalleryEntity[] = JSON.parse(event.xhr.response);
    if (response && response.length && this.imageGalleryStore.data) {
      const group = this.selectedItem;
      if (group) {
        for (let i = 0; i < response.length; i++) {
          const element = response[i];
          this.imageGalleryStore.data.Items.splice(0, 0, element);
          this.imageGalleryStore.data.Total++;
          if (group && element.GroupId === group.Id && !group.CoverBase64 && element.PreviewBase64) {
            group.CoverBase64 = element.PreviewBase64;
          }
        }
      }
    }
    ToastService.showToast("success", "", "Upload complete!", 5000);
  }

  // Callback to invoke if file upload fails.
  onError(event: any): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Files that are not uploaded.
    ToastService.showToast("error", "Can't upload file", ErrorHelper.handleAxiosError(event.xhr).message, 5000);
  }
  // #endregion upload
}

export default ImageGalleryHeaderView;
</script>