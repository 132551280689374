<template>
  <div class="default-view-container">
    <h1 class="mb-0">CSV Importer</h1>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Import CSV File to Bitpool Cloud</h2>
      </header>
      <div class="default-view-body">
        <div class="import-form">
          <Fieldset legend="Destination" class="mb-4">
            <div class="formgrid grid">
              <div class="field col-12">
                <div class="flex align-items-center">
                  <InputSwitch 
                    inputId="importNewPool"
                    v-model="isNewPool"
                    @change="onIsNewPoolChange"
                    class="vertical-align-top"
                  />
                  <label for="importNewPool" class="mb-0 ml-2">New Pool</label>
                </div>
              </div>
              <div v-if="isNewPool" class="field col-12 lg:col-6 lg:mb-0">
                <label for="importPool">New Pool Name</label>
                <div>
                  <InputText 
                    id="importPool" 
                    class="inputfield p-inputtext-lg w-full"
                    type="text" 
                    v-model="pool"
                  />
                </div>
              </div>
              <div v-else class="field col-12 lg:col-6 lg:mb-0">
                <label for="importExistPool">Select Pool</label>
                <div>
                  <Dropdown 
                    id="importExistPool" 
                    v-model="selectedPool" 
                    @change="onSelectPool" 
                    :options="pools" 
                    optionLabel="Name"
                    placeholder="Select pool" 
                    :filter="true" 
                    filterPlaceholder="Find pool" 
                    class="w-full dropdown-lg"
                  />
                </div>
              </div>
              <div class="field col-12 lg:col-6 mb-0">
                <label for="importStation">Station Name</label>
                <div>
                  <InputText 
                    id="importStation" 
                    class="inputfield p-inputtext-lg w-full"
                    type="text" 
                    v-model="stationName"
                  />
                </div>
              </div>
            </div>
          </Fieldset>

          <Fieldset legend="CSV Map - Dates" class="mb-4">
            <div class="formgrid grid">
              <div class="field col-12">
                <label class="mb-3">Date and Time stored in</label>
                <div class="import-form-radiobutton-list mb-2">
                  <div class="field-radiobutton">
                    <RadioButton inputId="dateTimeStoredIn1" name="dateTimeStoredIn" value="oneColumn" v-model="dateTimeStoredIn" />
                    <label for="dateTimeStoredIn1">One Column</label>
                  </div>
                  <div class="field-radiobutton">
                    <RadioButton inputId="dateTimeStoredIn2" name="dateTimeStoredIn" value="twoColumns" v-model="dateTimeStoredIn" />
                    <label for="dateTimeStoredIn2">Two Columns</label>
                  </div>
                  <div class="field-radiobutton mb-0">
                    <RadioButton inputId="dateTimeStoredIn3" name="dateTimeStoredIn" value="header" v-model="dateTimeStoredIn" />
                    <label for="dateTimeStoredIn3">Header</label>
                  </div>
                </div>
              </div>
              <div class="field col-12 lg:col-6" v-if="dateTimeStoredIn !== 'header'" :class="(dateTimeStoredIn !== 'header' && dateTimeStoredIn !== 'oneColumn') ? 'md:col-6' : ''">
                <label for="importDateColumn">Date in Column</label>
                <div>
                  <InputNumber
                    id="importDateColumn"
                    mode="decimal"
                    :min="1"
                    class="inputfield p-inputtext-lg w-full"
                    v-model="dateColumn"
                    :useGrouping="false"
                  />
                </div>
              </div>
              <div class="field col-12 md:col-6" v-if="dateTimeStoredIn !== 'header' && dateTimeStoredIn !== 'oneColumn'">
                <label for="importTimeColumn">Time in Column</label>
                <div>
                  <InputNumber
                    id="importTimeColumn"
                    mode="decimal"
                    :min="1"
                    class="inputfield p-inputtext-lg w-full"
                    v-model="timeColumn"
                    :useGrouping="false"
                  />
                </div>
              </div>
              <div class="field col-12">
                <label for="importDateFormats">
                  <span class="block with-inline-btn">
                    <span>Date Formats</span>
                    <Button 
                      icon="pi pi-info text-lg" 
                      class="p-button-icon-only p-button-rounded p-button-text"
                      @click="openInfoDialog" 
                    />
                  </span>
                </label>
                <div>
                  <Textarea 
                    v-model="dateFormats"
                    rows="3"
                    class="inputfield p-inputtext-lg w-full" />
                </div>
              </div>
              <div class="field col-12 lg:col-6 mb-0">
                <label for="importTimezone">Timezone</label>
                <div>
                  <Dropdown 
                    id="importTimezone" 
                    v-model="selectedTimezone" 
                    :options="timezones" 
                    optionValue="Id"
                    optionLabel="DisplayName"
                    placeholder="Select timezone" 
                    :filter="true" 
                    filterPlaceholder="Find timezone" 
                    class="w-full dropdown-lg"
                  />
                </div>
              </div>
            </div>
          </Fieldset>

          <Fieldset legend="CSV Map - Streams" class="mb-4">
            <div class="formgrid grid">
              <div class="field col-12">
                <label class="mb-3">Streams Names in</label>
                <div class="import-form-radiobutton-list mb-2">
                  <div class="field-radiobutton">
                    <RadioButton inputId="streamNamesStoredIn1" name="streamNamesStoredIn" value="column" v-model="streamNamesStoredIn" />
                    <label for="streamNamesStoredIn1">Column</label>
                  </div>
                  <div class="field-radiobutton">
                    <RadioButton inputId="streamNamesStoredIn2" name="streamNamesStoredIn" value="row" v-model="streamNamesStoredIn" />
                    <label for="streamNamesStoredIn2">Row</label>
                  </div>
                  <div class="field-radiobutton mb-0">
                    <RadioButton inputId="streamNamesStoredIn3" name="streamNamesStoredIn" value="rowColumn" v-model="streamNamesStoredIn" />
                    <label for="streamNamesStoredIn3">Row + Column</label>
                  </div>
                </div>
              </div>
              <div class="field col-12 md:col-6" v-if="streamNamesStoredIn !== 'column'">
                <label for="importStreamNameForRowInColumn">Stream Name for Row in Column</label>
                <div>
                  <InputNumber
                    id="importStreamNameForRowInColumn"
                    mode="decimal"
                    :min="1"
                    class="inputfield p-inputtext-lg w-full"
                    v-model="streamNameForRowInColumn"
                    :useGrouping="false"
                  />
                </div>
              </div>
              <div class="field col-12 lg:col-6" :class="streamNamesStoredIn !== 'column' ? 'md:col-6' : ''">
                <label for="importStreamNamePrefix">Stream Name Prefix</label>
                <div>
                  <InputText 
                    id="importStreamNamePrefix" 
                    class="inputfield p-inputtext-lg w-full"
                    type="text" 
                    v-model="streamNamePrefix"
                  />
                </div>
              </div>
              <div class="field col-12">
                <div class="flex align-items-center">
                  <InputSwitch 
                    inputId="importValuesInColumnsAuto"
                    v-model="valuesInColumnsAuto"
                    class="vertical-align-top"
                  />
                  <label for="importValuesInColumnsAuto" class="mb-0 ml-2">Values in Columns - Auto</label>
                </div>
              </div>
              <div class="field col-12 md:col-6 md:mb-0" v-if="!valuesInColumnsAuto">
                <label for="importValuesInColumns">Values in Columns</label>
                <div>
                  <InputText
                    id="importValuesInColumns"
                    class="inputfield p-inputtext-lg w-full"
                    type="text"
                    v-model="valuesInColumns"
                  />
                </div>
                <div class="text-bluegray-500 text-sm mt-2">Example: 1,2,3...</div>
              </div>
              <div class="field col-12 lg:col-6 mb-0" :class="!valuesInColumnsAuto ? 'md:col-6' : ''">
                <label for="importCalculatedInColumn">Calculated in Column</label>
                <div>
                  <InputNumber
                    id="importCalculatedInColumn"
                    mode="decimal"
                    :min="-1"
                    class="inputfield p-inputtext-lg w-full"
                    v-model="calculatedInColumn"
                    :useGrouping="false"
                  />
                </div>
                <div class="text-bluegray-500 text-sm mt-2">-1 if column not exist in file</div>
              </div>
            </div>
          </Fieldset>

          <Fieldset legend="CSV Map - Format" class="mb-4">
            <div class="formgrid grid">
              <div class="field col-12 md:col-6">
                <label for="importEncoding">Encoding</label>
                <div>
                  <Dropdown 
                    id="importEncoding" 
                    v-model="selectedEncoding"
                    :options="state.encodings" 
                    :loading="!state.isLoadedEncodings"
                    optionLabel="DisplayName"
                    optionValue="CodePage"
                    placeholder="Encoding" 
                    :filter="true" 
                    filterPlaceholder="Find encoding" 
                    class="w-full dropdown-lg"
                  />
                </div>
              </div>
              <div class="field col-12 md:col-6">
                <label for="importColumnsSeparateSymbol">Columns Separated with</label>
                <div>
                  <InputText 
                    id="importColumnsSeparateSymbol" 
                    class="inputfield p-inputtext-lg w-full"
                    type="text" 
                    v-model="columnsSeparator"
                  />
                </div>
              </div>
              <div class="field col-12 md:col-6 md:mb-0">
                <label for="importLinesTerminateSymbol">Lines Terminated with</label>
                <div>
                  <InputText 
                    id="importLinesTerminateSymbol" 
                    class="inputfield p-inputtext-lg w-full"
                    type="text" 
                    v-model="linesSeparator"
                  />
                </div>
              </div>
              <div class="field col-12 md:col-6 mb-0">
                <label for="importSkipLines">Skip Lines</label>
                <div>
                  <InputNumber
                    id="importSkipLines"
                    mode="decimal"
                    :min="0"
                    class="inputfield p-inputtext-lg w-full"
                    v-model="skipLines"
                    :useGrouping="false"
                  />
                </div>
                <div class="text-bluegray-500 text-sm mt-2">
                  If stream names in columns, first not skipped row should contain headers
                </div>
              </div>
            </div>
          </Fieldset>

          <Fieldset legend="Upload file">
            <div class="formgrid grid">
              <div class="field col-12 mb-0">
                <label for="importEncoding">Select CSV File for Uploading</label>
                <div>
                  <div class="fileupload-no-img">
                    <FileUpload name="files[]" :url="uploadUrl" @before-upload="beforeUpload" @before-send="beforeSend" @upload="onUpload" @error="onError" :multiple="true" accept=".csv" :withCredentials="true" chooseIcon="pi pi-plus-circle">
                      <template #empty>
                          <p class="mt-0 mb-5 mx-2 md:mx-3">Drag and drop files to here to upload.</p>
                      </template>
                    </FileUpload>
                  </div>
                </div>
              </div>
            </div>
          </Fieldset>
        </div>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>

    <Dialog header="Date format help" v-model:visible="displayInfoDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="mqtt-config-dialog">
      <div class="dialog-content">
        <div class="grid grid-nogutter">
          <div class="col-12 md:col-6">
            <strong>d</strong> - Numeric day of the month without a leading zero.<br />
            <strong>dd</strong> - Numeric day of the month with a leading zero.<br />
            <strong>ddd</strong> - Abbreviated name of the day of the week.<br />
            <strong>dddd</strong> - Full name of the day of the week.<br />
            <br />
            <strong>f,ff,fff,ffff,fffff,ffffff,fffffff</strong> -<br />
            Fraction of a second. The more Fs the higher the precision.<br />
            <br />
            <strong>h</strong> - 12 Hour clock, no leading zero.<br />
            <strong>hh</strong> - 12 Hour clock with leading zero.<br />
            <strong>H</strong> - 24 Hour clock, no leading zero.<br />
            <strong>HH</strong> - 24 Hour clock with leading zero.<br />
            <br />
            <strong>m</strong> - Minutes with no leading zero.<br />
            <strong>mm</strong> - Minutes with leading zero.<br />
          </div>
          <div class="col-12 md:col-6">
            <strong>M</strong> - Numeric month with no leading zero.<br />
            <strong>MM</strong> - Numeric month with a leading zero.<br />
            <strong>MMM</strong> - Abbreviated name of month.<br />
            <strong>MMMM</strong> - Full month name.<br />
            <br />
            <strong>s</strong> - Seconds with no leading zero.<br />
            <strong>ss</strong> - Seconds with leading zero.<br />
            <br />
            <strong>t</strong> - AM/PM but only the first letter.<br />
            <strong>tt</strong> - AM/PM ( a.m. / p.m.)<br />
            <br />
            <strong>y</strong> - Year with out century and leading zero.<br />
            <strong>yy</strong> - Year with out century, with leading zero.<br />
            <strong>yyyy</strong> - Year with century.<br />
            <br />
            <strong>zz</strong> - Time zone off set with +/-.
          </div>
        </div>
      </div>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Close" icon="pi pi-times" @click="closeInfoDialog" class="p-button-text p-button-secondary"/>
          </span>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import InputSwitch from 'primevue/inputswitch';
import InputText from 'primevue/inputtext';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import RadioButton from 'primevue/radiobutton';
import InputNumber from 'primevue/inputnumber';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import Message from 'primevue/message';
import FileUpload, { FileUploadBeforeSendEvent, FileUploadErrorEvent } from 'primevue/fileupload';
import { PoolModelLite } from "@/models/PoolModelLite";
import { Component, Vue } from "vue-facing-decorator";
import ImportDataState from '@/store/states/ImportDataState';
import ToastService from '@/services/ToastService';
import ErrorHelper from '@/helpers/ErrorHelper';
import { TimeZoneDto } from '@/models/TimeZoneDto';
import AuthState from '@/store/states/AuthState';

@Component({
  
  components: {
    InputSwitch,
    InputText,
    Dropdown,
    Fieldset,
    RadioButton,
    InputNumber,
    Textarea,
    Button,
    Dialog,
    FileUpload,
    Message
  },
})
class ImportView extends Vue { 
  isNewPool = false;
  selectedPool: PoolModelLite | null | undefined = null;
  pool = "";
  stationName = "Import Station";
  dateTimeStoredIn = "oneColumn";
  dateColumn = 4;
  timeColumn = 2;
  dateFormats = "dd/MM/yyyy HH:mm:ss";
  streamNamesStoredIn = "row";
  streamNameForRowInColumn = 2;
  streamNamePrefix = "";
  valuesInColumnsAuto = false;
  valuesInColumns = "6";
  calculatedInColumn = -1;
  selectedEncoding = 65001;
  columnsSeparator = ",";
  linesSeparator = '\\r\\n';
  skipLines = 0;

  get state():ImportDataState {
    return this.$store.state.importData;
  }
  
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get pools(): PoolModelLite[] {
    const pools = this.$store.state.pools.poolsList && this.$store.state.pools.poolsList.Pools ?
      this.$store.state.pools.poolsList.Pools as PoolModelLite[] :
      [];
    return pools;
  }

  selectedTimezone = "UTC";

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }

  created(): void {
    this.$store.dispatch("pools/loadPoolsList");
    if (!this.state.isLoadedEncodings) {
      this.$store.dispatch("importData/loadEncodings");
    }
  }
  
  onSelectPool(event: any): void {
    // event.originalEvent: Original event
    // event.value: Selected option value
    this.pool = event.value.Name;
  }

  onIsNewPoolChange(): void {
    this.pool = "";
    this.selectedPool = null;
  }

  displayInfoDialog = false;

  openInfoDialog() {
    this.displayInfoDialog = true;
  }
  
  closeInfoDialog() {
    this.displayInfoDialog = false;
  }

  get uploadUrl() {
    return `${this.$store.state.apiUrl}/rest/BitPool_V2/Import`;
  }

  // Callback to invoke before file upload begins to customize the request such as post parameters before the files.
  beforeUpload(event: FileUploadBeforeSendEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.formData: FormData object.
    event.formData.append("data", JSON.stringify({
      poolName: this.isNewPool ? this.pool : "",
      poolKey: this.isNewPool ? "" : this.selectedPool?.Id,
      importStationName: this.stationName,

      dateColumnNumber: this.dateColumn,
      timeColumnNumber: this.timeColumn,
      dateFormat: this.dateFormats,
      dateTimeInOneColumn: this.dateTimeStoredIn === "oneColumn",
      dateTimeInHeader: this.dateTimeStoredIn === "header",
      utcOffset: this.timezones.find(x => x.Id === this.selectedTimezone)?.UtcOffset,

      streamNameForRowInColumn: this.streamNameForRowInColumn,
      streamNameIn: this.streamNamesStoredIn,
      streamNamePrefix: this.streamNamePrefix,
      valuesInColumnsAuto: this.valuesInColumnsAuto,
      valuesInColumns: this.valuesInColumns,
      calculatedInColumn: this.calculatedInColumn,

      codePage: this.selectedEncoding,
      columnsSeparator: this.columnsSeparator,
      linesSeparator: this.linesSeparator,
      skipLines: this.skipLines
    }));
  }

  // Callback to invoke before file send begins to customize the request such as adding headers.
  beforeSend(event: FileUploadBeforeSendEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.formData: FormData object.
    event.xhr.setRequestHeader("Authorization", this.$store.state.auth.authKey);
    event.xhr.setRequestHeader("Accept", "application/json");
  }

  // Callback to invoke when file upload is complete.
  onUpload(event: FileUploadErrorEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Uploaded files.
    ToastService.showToast("success", "", "Import complete!", 5000);
  }

  // Callback to invoke if file upload fails.
  onError(event: any): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Files that are not uploaded.
    ToastService.showToast("error", "Can't import csv file", ErrorHelper.handleAxiosError(event.xhr).message, 5000);
  }
}

export default ImportView;
</script>