import { DashboardType } from "@/models/dashboard/DashboardType";
import { GDRSModel } from "@/models/dashboard/GDRSModel";
import { Space } from "@/models/dashboard/Space";
import { SpaceSettingsModel } from "@/models/dashboard/SpaceSettingsModel";
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { StreamModel } from "@/models/StreamModel";
import { WeatherLocation } from "@/models/WeatherLocation";

export default class DashboardState {
  editMode = false;
  editModeTree = false;
  editModeTreeAnimation = false;

  dashboardId: string | null | undefined = null;
  dashboardType = DashboardType.Organisation;

  isLoaded = false;
  dashboards: Space[] | null = null;
  guidDashboard = "";

  dashboardsPersonal: Space[] | null = null;
  guidDashboardPersonal = "";

  dashboardsShared: Space[] | null = null;
  guidDashboardShared = "";

  dashboardsSlides: Space[] | null = null;
  guidDashboardSlides = "";

  currentDashboard: Space | null = null;
  openDashboards: Record<string, boolean> = {};
  selectedDashboardsOrganisation: Record<string, boolean> = {};  
  selectedDashboardsPersonal: Record<string, boolean> = {};
  copiedDashboards: Space[] | null = null;
  copiedWithChildren: boolean = false;

  isLoadedWidgets = false;
  widgets: WidgetConfig[] | null = null;
  guidWidgets = "";
  // cut, dashboardType, dashboardId, spaceWidgetConfig, widgetConfigs
  copiedWidget: [boolean, string, string, SpaceWidgetConfig, WidgetConfig[]] | null = null;

  // id, isSaved, saveInProgress, error
  dashboardState: Record<string, [boolean, boolean, string | null]> = {};
  // guid, isSaved, saveInProgress, error
  widgetState: Record<string, [boolean, boolean, string | null]> = {};

  searchDashboard = "";
  searchDashboardResult: string[] = [];

  isLoadedGDRS = false;
  gdrs: GDRSModel | null = null;
  guidGDRS = "";

  isLoadedSpaceSettings = false;
  spaceSettings: SpaceSettingsModel | null = null;
  guidSpaceSettings = "";
  userSettingsSaveError = false;

  // streamkey, isLoaded, stream
  streams: Record<string, [boolean, StreamModel | null]> = {};

  spaceDragAutoOpenTimeout = 0;
  spaceDragId: string | undefined = undefined;
  spaceDragToId: string | undefined = undefined;

  guidWeatherLocations = "";
  weatherLocations: WeatherLocation[] | undefined = undefined;

  visibleSidebarComments = false;
}
