<template>
  <div class="flow-view">
    <div class="flow-view-header">
      <div class="mr-2">
        <Button :disabled="!isFlowChanged" icon="pi pi-save" label="Save Flow" class="my-1 mr-2" @click="saveChanges" />
        <Button icon="pi pi-cloud-download" label="Export" class="p-button-outlined my-1 mr-2" @click="exportFlow" />
        <FileUpload
          uploadIcon="pi pi-cloud-upload"
          chooseLabel="Import"
          class="p-button-outlined my-1"
          name="flow[]"
          mode="basic"
          :auto="true"
          :customUpload="true" 
          accept=".json"
          @uploader="importFlow"
        />
        </div>
      <div>
        <Button 
          :icon="rootState.isFullscreen ? 'pi pi-window-minimize' : 'pi pi-window-maximize'" 
          :label="rootState.isFullscreen ? 'Close fullscreen mode' : 'View in fullscreen mode'" 
          :title="rootState.isFullscreen ? 'Close fullscreen mode' : 'View in fullscreen mode'"
          class="p-button-icon-only p-button-outlined my-1"
          @click="toggleFullscreen"
        />
      </div>
    </div>
    
    <div class="flow-view-body">           
      <div class="field">
        <label for="" class="inline-block font-semibold">Flow Name</label>
        <div v-if="flowState.currentFlow">
          <InputText
            class="inputfield p-inputtext w-full"
            placeholder="Flow Name"
            type="text"
            @input="isChangedEvent"
            v-model="flowState.currentFlow.Name"
          />
        </div>
      </div>
      <div class="field relative">
        <label class="inline-block font-semibold mb-2">Drag and Drop nodes from below categories to the flow</label>
          <TabView lazy :scrollable="true">
            <TabPanel header="Stream Types">
              <div class="drag-drawflow-container">
                <div
                  v-for="nodeType in virtualStreamType"
                  :key="nodeType"
                  class="drag-drawflow drag-drawflow-stream-types"
                  :draggable="true"
                  @dragstart="drag"
                  @touchend="drop"
                  @touchmove="saveTouchPosition"
                  @touchstart="drag"
                  :data-node="nodeType"
                >
                  <span>{{ getNodeFullName(nodeType) }}</span>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Math">
              <div class="drag-drawflow-container">
                <div
                  v-for="nodeType in virtualStreamOperations"
                  :key="nodeType"
                  class="drag-drawflow drag-drawflow-operations"
                  :draggable="true"
                  @dragstart="drag"
                  @touchend="drop"
                  @touchmove="saveTouchPosition"
                  @touchstart="drag"
                  :data-node="nodeType"
                >
                  <span>{{ getNodeFullName(nodeType) }}</span>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Data">
              <div class="drag-drawflow-container">
                <div
                  v-for="nodeType in virtualStreamData"
                  :key="nodeType"
                  class="drag-drawflow drag-drawflow-data"
                  :draggable="true"
                  @dragstart="drag"
                  @touchend="drop"
                  @touchmove="saveTouchPosition"
                  @touchstart="drag"
                  :data-node="nodeType"
                >
                  <span>{{ getNodeFullName(nodeType) }}</span>
                </div>
              </div>
            </TabPanel>
            <TabPanel header="Insights">
              <div class="drag-drawflow-container">
                <div
                  v-for="nodeType in alarmTypesAvailable"
                  :key="nodeType"
                  class="drag-drawflow drag-drawflow-alarm"
                  :class="{ 'drag-drawflow-ai': nodeType === 'ai_report' }"
                  :draggable="true"
                  @dragstart="drag"
                  @touchend="drop"
                  @touchmove="saveTouchPosition"
                  @touchstart="drag"
                  :data-node="nodeType"
                >
                  <span>{{ getNodeFullName(nodeType) }}</span>
                </div>
              </div>
            </TabPanel>
          </TabView>

          <div class="bar-zoom" v-if="editor">
            <Button icon="pi pi-home text-lg" class="p-button-rounded p-button-text" @click="resetPosition" />
            <Button icon="pi pi-search-minus" class="p-button-rounded p-button-text" @click="editor.zoom_out()" />
            <Button icon="pi pi-search" class="p-button-rounded p-button-text" @click="editor.zoom_reset()" />
            <Button icon="pi pi-search-plus" class="p-button-rounded p-button-text" @click="editor.zoom_in()" />
          </div>
      </div>
    </div>
    <div class="flow-view-draw-area">
      <div class="col-right-drawflow">
        <div
          id="drawflowVirtualStream"
          @drop="drop"
          @dragover="allowDrop"
        >
          <div class="flow-position">
            <i v-if="indicateLeft" class="on-left">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 448 384"><path fill="none" stroke="none" stroke-width="5" d="M109.3 221.5h-6.036l4.268 4.268 105.4 105.4c11.522 11.521 11.524 30.183.007 41.707-5.812 5.766-13.371 8.625-20.939 8.625-7.564 0-15.099-2.88-20.851-8.641l-.001-.002-160-160c-11.524-11.523-11.524-30.191 0-41.714l160-160c11.523-11.524 30.191-11.524 41.714 0 11.524 11.523 11.524 30.19 0 41.714l-105.33 105.376-4.266 4.267H416c16.227 0 28.6 13.086 28.6 29.5s-12.373 29.5-28.6 29.5H109.3Z"/></svg>
            </i>
            <i v-if="indicateRight" class="on-right">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 448 385"><path fill="none" stroke="none" stroke-width="5" d="m276.832 373.832-.007.007-.007.007c-5.702 5.795-13.247 8.654-20.818 8.654-7.564 0-15.099-2.88-20.851-8.641l-.001-.002c-11.524-11.523-11.524-30.19-.001-41.714h.001l105.419-105.375 4.27-4.268H32c-16.29 0-29.498-13.182-29.498-29.5 0-16.318 13.207-29.5 29.498-29.5h312.836l-4.268-4.268-105.4-105.4c-11.524-11.523-11.524-30.19 0-41.714 11.523-11.524 30.191-11.524 41.714 0L436.875 172.11l.004.003c11.481 11.577 11.474 30.197-.047 41.718l-160 160Z"/></svg>
            </i>
            <i v-if="indicateTop" class="on-top">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 384 449"><path fill="none" stroke="none" stroke-width="5" d="m372.832 213.832-.007.007-.007.007c-5.702 5.795-13.247 8.654-20.818 8.654-7.564 0-15.099-2.88-20.851-8.641l-.002-.002-105.38-105.325-4.267-4.265V417c0 16.261-13.162 28.6-28.6 28.6-15.623 0-30.4-12.503-30.4-28.6V104.267l-4.267 4.265-105.37 105.3c-11.524 11.524-30.191 11.524-41.715 0-11.524-11.523-11.524-30.191 0-41.714l160-160c11.523-11.524 30.191-11.524 41.714 0l159.995 159.994.002.002c11.5 11.577 11.495 30.197-.027 41.718Z"/></svg>
            </i>
            <i v-if="indicateBottom" class="on-bottom">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 384 448"><path fill="none" stroke="none" stroke-width="5" d="m212.832 436.832-.007.007-.007.007c-5.702 5.795-13.247 8.654-20.818 8.654-7.564 0-15.099-2.88-20.851-8.641l-.001-.002-160-160c-11.524-11.523-11.524-30.191 0-41.714 11.523-11.524 30.19-11.524 41.714-.001v.001l105.37 105.424 4.268 4.271V32c0-16.261 13.162-28.6 28.6-28.6 15.622 0 30.4 12.513 30.4 28.6v312.836l4.268-4.268 105.4-105.4c11.523-11.524 30.191-11.524 41.714 0 11.519 11.518 11.479 30.135-.05 41.664l-160 160Z"/></svg>
            </i>
          </div>
          <InlineMessage v-if="isFlowChanged" severity="warn" class="drawflow-not-saved my-0">Changes not saved</InlineMessage>
        </div>
        <ContextMenu ref="menu" :model="menuItems" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import InlineMessage from 'primevue/inlinemessage';
import ContextMenu from 'primevue/contextmenu';
import { MenuItem } from "primevue/menuitem";
import FileUpload, { FileUploadRemoveEvent } from 'primevue/fileupload';
import FlowNodeView from "./FlowNodeView.vue";
import FlowAlarmNodeView from "./FlowAlarmNodeView.vue";
import Drawflow, { DrawflowConnectionDetail, DrawflowNode } from "drawflow";
import { h, getCurrentInstance, render } from "vue";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import FlowState from "@/store/states/FlowState";
import { FlowEntity } from "@/models/flow/FlowEntity";
import { FlowNode } from "@/models/flow/FlowNode";
import { FlowOptions } from "@/models/flow/FlowOptions";
import ToastService from "@/services/ToastService";
import RootState from "@/store/states/RootState";
import { Ref, Watch } from "vue-facing-decorator";
import { saveAs } from 'file-saver';
import { throttle } from 'throttle-debounce';
import { v4 as uuidv4 } from 'uuid';
import { FlowAlarmOptions } from "@/models/flow/FlowAlarmOptions";
import AuthState from "@/store/states/AuthState";
import { useSystemStore } from "@/stores/system";

@Component({

  components: {
    TabView,
    TabPanel,
    InputText,
    Button,
    InlineMessage,
    ContextMenu,
    FileUpload
  },
})
class FlowGraphView extends Vue {
  editor: Drawflow | null = null;
  dragName: string | null = null;
  touchLastPosition: TouchEvent | null = null;
  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();
  get virtualStreamType(): string[] {
    return this.flowState.virtualStreamType;
  }
  get virtualStreamOperations(): string[] {
    return this.flowState.virtualStreamOperations;
  }
  get virtualStreamData(): string[] {
    return this.flowState.virtualStreamData;
  }
  get alarmTypesAvailable(): string[] {
    return this.flowState.alarmTypes;
  }
  get insightTypes(): string[] {
    return this.isAIEnabled ? this.flowState.alarmTypes : this.flowState.alarmTypes.filter(x => !x.startsWith("ai_"));
  }
  get alarmTypes(): string[] {
    return this.flowState.alarmTypes.filter(x => !x.startsWith("ai_") && !x.startsWith("csv_"));
  }
  get aiDataTypes(): string[] {
    return this.isAIEnabled ?
      this.flowState.alarmTypes.filter(x => x.startsWith("ai_") || x.startsWith("csv_")) :
      this.flowState.alarmTypes.filter(x => x.startsWith("csv_"));
  }
  currentRootNode: string | null = null;
  nodeCount = 0;
  isBuildingFlowInProgress = true;
  isFlowChanged = false;
  indicateTop = false;
  indicateRight = false;
  indicateBottom = false;
  indicateLeft = false;
  
  get rootState(): RootState {
    return this.$store.state;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  systemStore = useSystemStore();

  get isAIEnabled(): boolean {
    return !!this.authState.permissions?.BitpoolAI;
  }

  get flowState(): FlowState {
    return this.$store.state.flow;
  }

  get currentFlow(): FlowEntity | null | undefined {
    return this.flowState.currentFlow;
  }

  isChangedEvent(): void {
    if (!this.isBuildingFlowInProgress) {
      this.isFlowChanged = true;
      this.indicateNodesOutsideOfVisibleAreaThrottle();
    }
  }

  indicateNodesOutsideOfVisibleAreaThrottle = throttle(1000, this.indicateNodesOutsideOfVisibleArea, { noLeading: true });

  indicateNodesOutsideOfVisibleArea(): void {
    if (this.editor) {
      const editorAny = this.editor as any;
      const { width, height } = editorAny.container.getBoundingClientRect();
      const zoom = editorAny.zoom;

      const centerX = width / 2;
      const centerY = height / 2;
      const newZeroX = centerX - centerX / zoom;
      const newZeroY = centerY - centerY / zoom;
      const newEndX = width / zoom + newZeroX;
      const newEndY = height / zoom + newZeroY;

      const canvasX = editorAny.canvas_x / zoom;
      const canvasY = editorAny.canvas_y / zoom;
      let indicateTop = false;
      let indicateRight = false;
      let indicateBottom = false;
      let indicateLeft = false;
      for (const key in this.editor.drawflow.drawflow.Home.data) {
        const node = this.editor.drawflow.drawflow.Home.data[key];
        const nodeHtmlElement = document.getElementById(`node-${node.id}`);
        let elementWidth = 0;
        let elementHeight = 0;
        if (nodeHtmlElement) {
          elementWidth = nodeHtmlElement.clientWidth;
          elementHeight = nodeHtmlElement.clientHeight;
        }
        const posX = node.pos_x;
        const posY = node.pos_y;
        if (posX + canvasX < newZeroX) {
          indicateLeft = true;
        }
        if (posX + canvasX + elementWidth > newEndX) {
          indicateRight = true;
        }
        if (posY + canvasY < newZeroY) {
          indicateTop = true;
        }
        if (posY + canvasY + elementHeight > newEndY) {
          indicateBottom = true;
        }
      }
      this.indicateTop = indicateTop;
      this.indicateRight = indicateRight;
      this.indicateBottom = indicateBottom;
      this.indicateLeft = indicateLeft;
    }
  }

  resetPosition(): void {
    if (this.editor) {
      // https://github.com/jerosoler/Drawflow/issues/88
      const editorAny = this.editor as any;
      editorAny.canvas_x = 0;
      editorAny.canvas_y  = 0;
      editorAny.zoom_refresh();
    }
  }

  async mounted(): Promise<void> {
    (window as any).bpFlowBuildTreeFromNodes = this.buildTreeFromNodes; // for "test with data" inside alarm nodes
    const internalInstance = getCurrentInstance();
    window.setTimeout(() => {
      const VueDrawflow = { version: 3, h, render };
      const element = document.getElementById("drawflowVirtualStream");
      if (element) {
        if (internalInstance) {
          const editor = new Drawflow(
            element,
            VueDrawflow,
            internalInstance.appContext.app._context
          );
          // https://github.com/jerosoler/Drawflow/issues/35
          editor.on("connectionCreated", (info) => {
            // remove more than 1 input connections, exception - alarms
            const nodeInfoInput = editor.getNodeFromId(info.input_id);
            const isInsight = !!this.insightTypes.find(x => x === nodeInfoInput.data.nodeType);
            if (!isInsight && nodeInfoInput.inputs[info.input_class].connections.length > 1) {
              const removeConnectionInfo =
                nodeInfoInput.inputs[info.input_class].connections[0];
              editor.removeSingleConnection(
                removeConnectionInfo.node,
                info.input_id,
                removeConnectionInfo.input,
                info.input_class
              );
            }
          });
          // https://github.com/jerosoler/Drawflow/issues/35
          editor.on("connectionCreated", (info) => {
            // remove more than 1 output connections(except of alarm connections)
            const nodeInfoOutput = editor.getNodeFromId(info.output_id);
            const nodeInfoInput = editor.getNodeFromId(info.input_id);

            const isInsight = !!this.insightTypes.find(x => x === nodeInfoInput.data.nodeType);

            if (nodeInfoOutput.outputs[info.output_class].connections.length > 1 && !isInsight) {
              // find non-alarm nodes and remove
              const removeConnections: DrawflowConnectionDetail[] = [];
              const connectionsLength = nodeInfoOutput.outputs[info.output_class].connections.length;
              nodeInfoOutput.outputs[info.output_class].connections.forEach((connection, index) => {
                const nodeInfoOutputOld = editor.getNodeFromId(connection.node);
                if (!this.insightTypes.find(x => x === nodeInfoOutputOld.data.nodeType) && index < connectionsLength - 1) {
                  removeConnections.push(connection);
                }
              });
              removeConnections.forEach(connection => {
                // .output is missing in typescript defenition
                const removeConnectionInfo = connection as any;
                editor.removeSingleConnection(
                  info.output_id,
                  removeConnectionInfo.node,
                  info.output_class,
                  removeConnectionInfo.output
                );
              });
            }
          });
          editor.on("connectionCreated", (info) => {
            // if nodes are not compatible, remove connection
            const nodeInfoInput = editor.getNodeFromId(info.input_id);
            const nodeInfoOutput = editor.getNodeFromId(info.output_id);
            let errorMessage = "";
            if ((nodeInfoInput.data.nodeType === "power_energy" || 
              nodeInfoInput.data.nodeType === "accumulating_interval") &&
              nodeInfoOutput.data.nodeType !== "stream") {
              // power_energy and accumulating_interval can accept only single stream as input
              errorMessage = "You can connect only 'Stream' node here";
            } else if (this.virtualStreamType.find(x => x === nodeInfoOutput.data.nodeType) &&
              !this.insightTypes.find(x => x === nodeInfoInput.data.nodeType)) {
              // allow virtal stream -> alarm, deny everything else
              errorMessage = "You can't connect 'Virtual Stream' node to this node";
            } else if (!this.virtualStreamType.find(x => x === nodeInfoOutput.data.nodeType) &&
              (nodeInfoOutput.data.nodeType !== "stream" && nodeInfoOutput.data.nodeType !== "tag_node" && nodeInfoOutput.data.nodeType !== "pool") &&
              this.insightTypes.find(x => x === nodeInfoInput.data.nodeType)) {
              // allow stream & tag_node -> alarm, deny everything else
              errorMessage = "You can't connect this node to 'Insight' node";
            } else if (nodeInfoInput.data.nodeType !== "virtual_stream" &&
              nodeInfoOutput.data.nodeType === "generator") {
              errorMessage = "You can connect 'Model Generator' to 'Virtual stream' only";
            } else if (nodeInfoOutput.data.nodeType === "tag_node" && 
              !this.insightTypes.find(x => x === nodeInfoInput.data.nodeType)) {
              // allow tag_node -> alarm, deny everything else
              errorMessage = "You can connect this node to 'Insight' nodes only";
            } else if (nodeInfoOutput.data.nodeType === "pool" && 
              nodeInfoInput.data.nodeType !== "data_quality" && nodeInfoInput.data.nodeType !== "ai_report" && nodeInfoInput.data.nodeType !== "tag_value") {
              // allow pool -> data_quality, deny everything else
              errorMessage = `You can connect this node to '${this.getNodeFullName("data_quality")}', '${this.getNodeFullName("ai_report")}', and '${this.getNodeFullName("tag_value")}' nodes only`;
            }
            if (errorMessage) {
              const connections = nodeInfoInput.inputs[info.input_class].connections;
              const removeConnectionInfo = connections[connections.length - 1];
              editor.removeSingleConnection(
                removeConnectionInfo.node,
                info.input_id,
                removeConnectionInfo.input,
                info.input_class
              );
              ToastService.showToast("error", "Flow Validation", errorMessage, 5000);
            }
          });
          editor.on("nodeRemoved", (id) => {
            this.emitter.emit("unmount_df_node", `${id}`);
            this.nodeCount--;
          });
          editor.on('translate', () => {
            this.indicateNodesOutsideOfVisibleAreaThrottle();
          });

          // #region context menu          
          // custom context menu https://github.com/jerosoler/Drawflow/issues/187
          editor.on('contextmenu', (event) => this.contextmenuEvent(event));
          // contextmenu event is not compatible with iOS - https://developer.mozilla.org/en-US/docs/Web/API/Element/contextmenu_event 
          if (this.systemStore.isIOs) {
            // Timer for long touch detection
            let timerLongTouch: number | undefined = undefined;
            // Long touch flag for preventing "normal touch event" trigger when long touch ends
            let longTouch = false;

            element.addEventListener("touchstart", (event) => {
              // Timer for long touch detection
              timerLongTouch = window.setTimeout(function () {
                // Flag for preventing "normal touch event" trigger when touch ends.
                longTouch = true;
              }, 700);
            });
            element.addEventListener("touchmove", (event) => {
              // If timerLongTouch is still running, then this is not a long touch
              // (there is a move) so stop the timer
              clearTimeout(timerLongTouch);

              if (longTouch) {
                longTouch = false;
              }
            });
            element.addEventListener("touchend", (event) => {
              // If timerLongTouch is still running, then this is not a long touch
              // so stop the timer
              clearTimeout(timerLongTouch);

              if (longTouch) {
                longTouch = false;
                this.contextmenuEvent(event);
              }
            });
          }
          // #endregion context menu

          // #region detect changes
          editor.on('nodeCreated', (id) => {
            this.isChangedEvent();
          });
          editor.on('nodeRemoved', (id) => {
            this.isChangedEvent();
          });
          // nodeDataChanged is missing in typescript defenition
          (editor as any).on('nodeDataChanged', () => {
            this.isChangedEvent();
          });
          editor.on('nodeMoved', (id) => {
            this.isChangedEvent();
          });
          editor.on('connectionCreated', (id) => {
            this.isChangedEvent();
          });
          editor.on('connectionRemoved', (id) => {
            this.isChangedEvent();
          });
          editor.on('zoom', () => {
            this.indicateNodesOutsideOfVisibleAreaThrottle();
          });
          this.emitter.on("window_size_changed_throttle-1000", this.indicateNodesOutsideOfVisibleArea);
          // #endregion detect changes
          editor.registerNode(
            "FlowNodeView",
            FlowNodeView,
            undefined,
            undefined
          );
          editor.registerNode(
            "FlowAlarmNodeView",
            FlowAlarmNodeView,
            undefined,
            undefined
          );
          editor.start();
          internalInstance.appContext.app._context.config.globalProperties.$df =
            editor;
          this.editor = editor;
          this.dbStructureToFlow();
          this.isBuildingFlowInProgress = false;
          this.indicateNodesOutsideOfVisibleAreaThrottle();
        }
      }
    }, 200);
  }

  unmounted(): void {
    delete (window as any).bpFlowBuildTreeFromNodes;
    if (this.editor) {
      this.editor.clear();
    }
    this.$store.commit("setIsFullscreen", false);
    this.emitter.off("window_size_changed_throttle-1000", this.indicateNodesOutsideOfVisibleArea);
  }

  contextmenuEvent(event: any): void {
    if (event.target.closest(".drawflow_content_node") != null || event.target.classList.contains('drawflow-node') ||
      event.target.closest(".connection") != null || event.target.classList.contains('connection')) {
      this.showContextmenu(event);
    } else if (event.currentTarget.classList.contains('parent-drawflow')) {
      // showContextmenu - area
      this.showContextmenu(event);
    }
  }

  toggleFullscreen() {
    this.$store.commit("setIsFullscreen", !this.rootState.isFullscreen);
  }

  dbStructureToFlow(): void {
    if (this.currentFlow && this.currentFlow.Tree && this.currentFlow.Tree.length) {
      const alarms: FlowNode[] = [];
      this.currentFlow.Tree.forEach(node => {
        if (this.insightTypes.includes(node.NodeType)) {
          alarms.push(node);
        } else {
          this.addFlowNode(node);
        }
      });
      alarms.forEach(node => {
        this.addFlowNode(node);
      });
    }
  }

  addFlowNode(
    node: FlowNode | null
  ): number | null {
    if (node) {
      const id = this.addNodeToDrawFlow(
        node.NodeKey,
        node.NodeType,
        node.Options,
        this.editorPosXtoPosX(node.PositionX),
        this.editorPosYtoPosY(node.PositionY)
      );
      // add childs
      const childIds: (number | null)[] = [];
      if ("Childs" in node && node.Childs && node.Childs.length) {
        node.Childs.forEach((child) =>
          childIds.push(this.addFlowNode(child))
        );
      }
      if (id != null) {
        childIds.forEach((childId, i) => {
          if (childId != null) {
            this.editor?.addConnection(
              childId,
              id,
              `output_1`,
              `input_${i + 1}`
            );
          }
        });
      }
      if (id != null) {
        // connect alarms
        if ("NodeRefs" in node && node.NodeRefs && node.NodeRefs.length) {
          node.NodeRefs.forEach((nodeRef) => {
            // find connected node by nodeRef
            if (this.editor) {
              for (const key in this.editor.drawflow.drawflow.Home.data) {
                const dfNode = this.editor.drawflow.drawflow.Home.data[key];
                if (dfNode.data.nodeKey === nodeRef) {
                  // connect alarm
                  this.editor?.addConnection(
                    dfNode.id,
                    id,
                    `output_1`,
                    `input_1`
                  );
                  break;
                }
              }
            }
          });
        }
      }
      return id;
    }
    return null;
  }

  getNodeFullName(nodeType: string | null): string {
    return this.$store.getters["flow/getNodeFullName"](nodeType);
  }

  saveTouchPosition(event: TouchEvent): void {
    this.touchLastPosition = event;
    event.preventDefault();
  }

  drag(event: DragEvent | TouchEvent): void {
    if (event.target) {
      const target = event.target as HTMLElement;
      if (event.type === "touchstart") {
        const closest = target.closest(".drag-drawflow");
        if (closest) {
          this.dragName = closest.getAttribute("data-node");
        }
      } else {
        this.dragName = target.getAttribute("data-node");
      }
    }
  }

  drop(event: DragEvent | TouchEvent): void {
    if (event.type === "touchend") {
      if (
        this.touchLastPosition &&
        this.touchLastPosition.touches &&
        this.touchLastPosition.touches.length &&
        this.touchLastPosition.touches[0]
      ) {
        const touch = this.touchLastPosition.touches[0];
        const elementFromPoint = document.elementFromPoint(
          touch.clientX,
          touch.clientY
        );
        if (elementFromPoint) {
          const parentDrawflow = elementFromPoint.closest(
            "#drawflowVirtualStream"
          );
          if (parentDrawflow) {
            this.addNodeToDrawFlow(
              uuidv4(),
              this.dragName,
              null,
              touch.clientX,
              touch.clientY
            );
          }
        }
      }
      this.touchLastPosition = null;
      this.dragName = null;
    } else {
      event.preventDefault();
      const dragEvent = event as DragEvent;
      this.addNodeToDrawFlow(
        uuidv4(),
        this.dragName,
        null,
        dragEvent.clientX,
        dragEvent.clientY
      );
      this.dragName = null;
    }
  }

  allowDrop(event: Event): void {
    event.preventDefault();
  }

  posXtoEditorPosX(pos_x: number): number {
    if (this.editor) {
      const editor = this.editor;
      const result =
        pos_x *
          (editor.precanvas.clientWidth /
            (editor.precanvas.clientWidth * editor.zoom)) -
          editor.precanvas.getBoundingClientRect().x *
            (editor.precanvas.clientWidth /
              (editor.precanvas.clientWidth * editor.zoom));
      return result;
    } else {
      return 0;
    }
  }

  posYtoEditorPosY(pos_y: number): number {
    if (this.editor) {
      const editor = this.editor;
      const result =
        pos_y *
          (editor.precanvas.clientHeight /
            (editor.precanvas.clientHeight * editor.zoom)) -
          editor.precanvas.getBoundingClientRect().y *
            (editor.precanvas.clientHeight /
              (editor.precanvas.clientHeight * editor.zoom));
      return result;
    } else {
      return 0;
    }
  }

  editorPosXtoPosX(editor_pos_x: number): number {
    if (this.editor) {
      const editor = this.editor;
      const result =
          (editor_pos_x +
          editor.precanvas.getBoundingClientRect().x *
            (editor.precanvas.clientWidth /
              (editor.precanvas.clientWidth * editor.zoom))) / 
          (editor.precanvas.clientWidth /
            (editor.precanvas.clientWidth * editor.zoom));
      return result;
    } else {
      return 0;
    }
  }

  editorPosYtoPosY(editor_pos_y: number): number {
    if (this.editor) {
      const editor = this.editor;
      const result =
          (editor_pos_y +
          editor.precanvas.getBoundingClientRect().y *
            (editor.precanvas.clientHeight /
              (editor.precanvas.clientHeight * editor.zoom))) /
          (editor.precanvas.clientHeight /
            (editor.precanvas.clientHeight * editor.zoom));
      return result;
    } else {
      return 0;
    }
  }

  addNodeToDrawFlow(
    nodeKey: string,
    nodeType: string | null,
    options: FlowOptions | FlowAlarmOptions | null,
    pos_x: number,
    pos_y: number
  ): number | null {
    if (this.editor) {
      const classes = (this.virtualStreamType.find(x => x === nodeType) ?
        'drawflow-node-stream-types' :
        this.virtualStreamOperations.find(x => x === nodeType) ?
          'drawflow-node-operations' :
          this.insightTypes.find(x => x === nodeType) ?
            'drawflow-node-alarm' :
            'drawflow-node-data') + ` drawflow-node-${nodeType}`;
      const result = this.editor.addNode(
        "FlowNodeView",
        this.getInputCount(nodeType),
        this.getOutputCount(nodeType),
        this.posXtoEditorPosX(pos_x),
        this.posYtoEditorPosY(pos_y),
        classes,
        {
          nodeKey: nodeKey,
          nodeType: nodeType,
          nodeName: this.getNodeFullName(nodeType),
          nodeOptions: options
        },
        this.insightTypes.find(x => x === nodeType) ? "FlowAlarmNodeView" : "FlowNodeView",
        "vue"
      );
      this.nodeCount++;
      return result;
    }
    return null;
  }

  getInputCount(nodeType: string | null): number {
    return this.virtualStreamType.find((x) => x === nodeType) || this.insightTypes.find((x) => x === nodeType)
      ? 1
      : this.virtualStreamOperations.find((x) => x === nodeType)
        ? 4
        : 0;
  }

  getOutputCount(nodeType: string | null): number {
    return this.insightTypes.find((x) => x === nodeType) ? 0 : 1;
  }

  nodeToTree(key: string): FlowNode | null {
    if (this.editor) {
      const node = this.editor.drawflow.drawflow.Home.data[key];
      const isInsight = !!this.insightTypes.find(x => x === node.data.nodeType);
      const isAlarm = !!this.alarmTypes.find(x => x === node.data.nodeType);
      const isAiData = !!this.aiDataTypes.find(x => x === node.data.nodeType);
      const childs: FlowNode[] = [];
      const nodeRefs: string[] = [];
      if (isInsight) {
        for (const keyInput in node.inputs) {
          const input = node.inputs[keyInput];
          input.connections.forEach(connection => {
            const connectedNode = this.editor?.drawflow.drawflow.Home.data[connection.node];
            if (connectedNode) {
              nodeRefs.push(connectedNode.data.nodeKey);
            }
          });
        }
      } else {
        for (const keyInput in node.inputs) {
          const input = node.inputs[keyInput];
          input.connections.forEach(connection => {
            const child = this.nodeToTree(connection.node);
            if (child) {
              childs.push(child);
            }
          });
        }
      }
      const result: FlowNode = {
        NodeKey: node.data.nodeKey,
        NodeType: node.data.nodeType,
        Options: node.data.nodeOptions,
        PositionX: node.pos_x,
        PositionY: node.pos_y
      };
      if (isInsight) {
        result.NodeRefs = nodeRefs;
        this.hasAlarm = this.hasAlarm || isAlarm;
        this.hasAIData = this.hasAIData || isAiData;
        this.hasInsight = this.hasInsight || isInsight;
      } else {
        result.Childs = childs;
      }
      this.validateNode(result);
      return result;
    }
    return null;
  }

  buildTreeFromNodes(): FlowNode[] {
    if (this.editor) {
      const rootKeys = new Set<string>();
      for (const key in this.editor.drawflow.drawflow.Home.data) {
        const node = this.editor.drawflow.drawflow.Home.data[key];
        if (node.data && this.virtualStreamType.findIndex(x => x === node.data.nodeType) >= 0) {
          // virtual streams
          rootKeys.add(key);
        } else if (node.data && this.insightTypes.find(x => x === node.data.nodeType)) {
          // alarms
          rootKeys.add(key);
          // nodes output connected to alarms only
          for (const keyInput in node.inputs) {
            const input = node.inputs[keyInput];
            input.connections.forEach(connection => {
              let isOk = true;
              const childNode = this.editor?.getNodeFromId(connection.node);
              if (childNode && childNode.outputs)  {
                for (const keyOutput in childNode.outputs) {
                  const output = childNode.outputs[keyOutput];
                  const containNonAlarmConnection = output.connections.find(outputConnection => {
                    const outputNode = this.editor?.getNodeFromId(outputConnection.node);
                    if (outputNode && outputNode.data && !this.insightTypes.find(x => x === outputNode.data.nodeType)) {
                      return true;
                    } else {
                      return false;
                    }
                  });
                  if (containNonAlarmConnection) {
                    isOk = false;
                  }
                }
              }
              if (isOk) {
                rootKeys.add(connection.node);
              }
            });
          }
        } else if (node.outputs["output_1"] && !node.outputs["output_1"].connections.length) {
          // nodes output is not connected
          rootKeys.add(key);
        }
      }
      if (rootKeys.size) {
        const result = Array.from(rootKeys).map(x => this.nodeToTree(x));
        return result as FlowNode[];
      } else {
        this.treeErrors.add("Please add nodes");
      }
    }
    return [];
  }

  validateNode(node: FlowNode | null): void {
    if (node) {
      switch (node.NodeType) {
        case "virtual_stream":
        case "power_energy":
        case "accumulating_interval": {
          if (!node.Options || !(node.Options as FlowOptions).PoolKey) {
            this.treeErrors.add("Please select pool");
          }
          if (!node.Options || !(node.Options as FlowOptions).StreamName) {
            this.treeErrors.add("Please enter stream name");
          }
          break;
        }
        case "stream":{
          if (!node.Options || !(node.Options as FlowOptions).PoolKey) {
            this.treeErrors.add("Please select pool");
          }
          if (!node.Options || !(node.Options as FlowOptions).StreamKey) {
            this.treeErrors.add("Please select stream");
          }
          break;
        }
        case "value": {
          if (!node.Options || node.Options.Value === null || node.Options.Value === undefined) {
            this.treeErrors.add("Please enter value");
          }
          break;
        }
        case "generator": {
          if (!node.Options || !(node.Options as FlowOptions).GeneratorOptions?.length) {
            this.treeErrors.add("Please setup model generator");
          }
          break;
        }
        case "tag_node": {
          if (!node.Options || !(node.Options as FlowOptions).StreamKey) {
            this.treeErrors.add("Please select entity");
          }
          if (!node.Options || !(node.Options as FlowOptions).Tags?.length) {
            this.treeErrors.add("Please enter tags");
          }
          break;
        }
        case "pool":{
          if (!node.Options || !(node.Options as FlowOptions).PoolKey) {
            this.treeErrors.add("Please select pool");
          }
          break;
        }
        case "out_of_limits": {
          if (!node.Options || 
            node.Options.Value === null || node.Options.Value === undefined ||
            (node.Options as FlowAlarmOptions).Value2 === null || (node.Options as FlowAlarmOptions).Value2 === undefined ||
            !(node.Options as FlowAlarmOptions).TimeFrom || !(node.Options as FlowAlarmOptions).TimeTo)
            this.treeErrors.add("Please fill all fields for 'Out of Limits' alarm");
          break;
        }
        case "data_anomaly":{
          if (!node.Options || 
            node.Options.Value === null || node.Options.Value === undefined ||
            (node.Options as FlowAlarmOptions).Value2 === null || (node.Options as FlowAlarmOptions).Value2 === undefined ||
            (node.Options as FlowAlarmOptions).Value3 === null || (node.Options as FlowAlarmOptions).Value3 === undefined)
            this.treeErrors.add("Please fill all fields for 'Data Anomaly' alarm");
          break;
        }
        case "data_quality": {
          if (!node.Options || 
            node.Options.Value === null || node.Options.Value === undefined)
            this.treeErrors.add("Please fill all fields for 'Data Quality' alarm");
          break;
        }
        case "ai_report": {
          if (!node.Options || 
            node.Options.Value === null || node.Options.Value === undefined ||
            (node.Options as FlowAlarmOptions).Value2 === null || (node.Options as FlowAlarmOptions).Value2 === undefined ||
            (node.Options as FlowAlarmOptions).Value3 === null || (node.Options as FlowAlarmOptions).Value3 === undefined ||
            (node.Options as FlowAlarmOptions).TimeFrom === null || (node.Options as FlowAlarmOptions).TimeFrom === undefined ||
            (node.Options as FlowAlarmOptions).Text === null || (node.Options as FlowAlarmOptions).Text === undefined)
            this.treeErrors.add("Please fill all fields for 'AI Insights' block");
          break;
        }
        case "csv_export": {
          if (!node.Options || 
            node.Options.Value === null  || node.Options.Value === undefined ||
            (node.Options as FlowAlarmOptions).Value3 === null || (node.Options as FlowAlarmOptions).Value3 === undefined ||
            (node.Options as FlowAlarmOptions).TimeFrom === null || (node.Options as FlowAlarmOptions).TimeFrom === undefined)
            this.treeErrors.add("Please fill all fields for 'CSV Export' block");
          break;
        }
        case "tag_value": {
          if (!node.Options ||
            !(node.Options as FlowAlarmOptions).Text ||
            (node.Options as FlowAlarmOptions).Text3 === null || (node.Options as FlowAlarmOptions).Text3 === undefined)
            this.treeErrors.add("Please fill all fields for 'Tag Value' block");
          break;
        }
      }
    }
  }

  treeErrors: Set<string> = new Set<string>();
  hasAlarm = false;
  hasAIData = false;
  hasInsight = false;

  async saveChanges(): Promise<void> {
    this.treeErrors.clear();
    this.hasAlarm = false;
    this.hasAIData = false;
    this.hasInsight = false;
    if (!this.flowState.currentFlow || !this.flowState.currentFlow.Name) {
      this.treeErrors.add("Please enter flow name");
    }
    const tree = this.buildTreeFromNodes();
    const errors = Array.from(this.treeErrors); 
    if (errors.length === 0) {
      const saveFlow = Object.assign({}, this.flowState.currentFlow);
      saveFlow.Tree = tree;
      saveFlow.HasAlarm = this.hasAlarm;
      saveFlow.HasAIData = this.hasAIData;
      saveFlow.HasInsight = this.hasInsight;
      const isNew = !saveFlow._id;
      await this.$store.dispatch("flow/createUpdate", saveFlow);
      if (!this.flowState.updateError) {
        if (!isNew) {
          this.clearFlow();
          this.refillFlow();
        }
        this.isFlowChanged = false;
      }
    } else {
      errors.forEach(error => {
        ToastService.showToast("error", "Flow Validation", error, 5000);
      });
    }
  }

  // #region export/import
  exportFlow(): void {
    this.treeErrors.clear();
    this.hasAlarm = false;
    const tree = this.buildTreeFromNodes();
    const errors = Array.from(this.treeErrors); 
    if (errors.length === 0) {
      const str = JSON.stringify(tree);
      const blob = new Blob([str], {type: "text/plain;charset=utf-8"});
      saveAs(blob, "flow.json");
    } else {
      errors.forEach(error => {
        ToastService.showToast("error", "Flow Validation", error, 5000);
      });
    }
  }
  clearFlow(): void {
    if (this.editor) {
      const removeNodes: DrawflowNode[] = [];
      for (const key in this.editor.drawflow.drawflow.Home.data) {
        const node = this.editor.drawflow.drawflow.Home.data[key];
        removeNodes.push(node);
      }
      if (removeNodes.length > 0) {
        removeNodes.forEach((node) => {
          this.editor?.removeNodeId(`node-${node.id}`);
        });
      }
    }
  }
  refillFlow(): void {
    this.dbStructureToFlow();
  }
  async importFlow(event: FileUploadRemoveEvent): Promise<void> {
    if (event.files && event.files.length) {
      const str = await event.files[0].text();
      const nodesForImport: FlowNode[] = JSON.parse(str);
      if (nodesForImport && nodesForImport.length) {
        // replace tree and regenerate drawflow
        if (this.editor && this.currentFlow) {
          this.clearFlow();
          nodesForImport.forEach(nodeForImport => {
            if (nodeForImport.Options && (nodeForImport.Options as FlowOptions).StreamKey &&
              this.virtualStreamType.find(x => x === nodeForImport.NodeType) &&
              !this.currentFlow?.Tree?.find(x => x.Options && (x.Options as FlowOptions).StreamKey === (nodeForImport.Options as FlowOptions).StreamKey)) {
              (nodeForImport.Options as FlowOptions).StreamKey = undefined;
            }
          });
          this.currentFlow.Tree = nodesForImport;
          this.refillFlow();
        }
      }
    }
  }
  // #endregion export/import

  // #region context menu
  @Ref() readonly menu!: ContextMenu;
  get menuItems(): MenuItem[] {
    return [{
      label: 'Copy',
      icon: undefined,
      command: () => {
        if (this.editor) {
          const node = this.editor.drawflow.drawflow.Home.data[this.selectedNodeId.slice(5)];
          if (node) {
            const result = {
              NodeType: node.data.nodeType,
              Options: node.data.nodeOptions
            };
            this.copiedNode = JSON.stringify(result);
          } else {
            this.copiedNode = null;
          }
        }
      },
      disabled: this.contextmenuTarget !== "node"
    }, {
      label: 'Paste',
      icon: undefined,
      command: () => {
        if (this.copiedNode && this.conextmenuEvent) {
          const parsed = JSON.parse(this.copiedNode);
          if (this.virtualStreamType.find(x => x === parsed.NodeType)) {
            if (parsed.Options?.StreamKey) {
              parsed.Options.StreamKey = undefined;
            }
          }
          let clientX: number;
          let clientY: number;
          if (this.systemStore.isIOs) {
            const eventAny = this.conextmenuEvent as any;
            const touch = eventAny.changedTouches ? eventAny.changedTouches[0] : undefined;
            clientX = touch ? touch.clientX : this.conextmenuEvent.clientX;
            clientY = touch ? touch.clientY : this.conextmenuEvent.clientY;
          } else {
            clientX = this.conextmenuEvent.clientX;
            clientY = this.conextmenuEvent.clientY;
          }
          this.addNodeToDrawFlow(uuidv4(), parsed.NodeType, parsed.Options, clientX, clientY);
        }
      },
      disabled: this.contextmenuTarget !== "area" || !this.copiedNode
    }, {
      label: 'Delete',
      icon: undefined,
      command: () => {
        if (this.selectedNodeId) {
          this.editor?.removeNodeId(this.selectedNodeId);
          this.selectedNodeId = "";
        } else if (this.selectedConnectionElement) {
          let id1;
          let id2;
          let connectionClass1;
          let connectionClass2;
          this.selectedConnectionElement.classList.forEach(x => {
            if (x.startsWith("node_out_node-")) {
              id1 = x.slice(14);
            } else if (x.startsWith("node_in_node-")) {
              id2 = x.slice(13);
            } else if (x.startsWith("output_")) {
              connectionClass1 = x;
            } else if (x.startsWith("input_")) {
              connectionClass2 = x;
            }
          });
          if (id1 && id2 && connectionClass1 && connectionClass2) {
            this.editor?.removeSingleConnection(
              id1,
              id2,
              connectionClass1,
              connectionClass2
            );
          }
          this.selectedConnectionElement = null;
        }
      },
      disabled: this.contextmenuTarget !== "node" && this.contextmenuTarget !== "connection"
    }];
  }

  // values: node, area, connection
  contextmenuTarget = "";
  selectedNodeId = "";
  selectedConnectionElement: Element | null = null;
  conextmenuEvent: PointerEvent | null = null;
  get copiedNode(): string | null {
    return this.flowState.copiedNode;
  }
  set copiedNode(value: string | null) {
    this.$store.commit("flow/setCopiedNode", value);
  }

  showContextmenu(event: PointerEvent): void {
    if (event.target) {
      this.conextmenuEvent = event; 
      const target = event.target as HTMLElement;
      const currentTarget = event.currentTarget as HTMLElement;
      const node = target.closest(".drawflow-node");
      const connection = target.closest(".connection");
      if (node) {
        this.contextmenuTarget = "node"; 
        this.selectedNodeId = node.id;
        this.selectedConnectionElement = null;
      } else if (connection) {
        this.contextmenuTarget = "connection"; 
        this.selectedNodeId = "";
        this.selectedConnectionElement = connection;
      } else if (currentTarget && currentTarget.classList.contains('parent-drawflow')) {
        this.contextmenuTarget = "area"; 
        this.selectedNodeId = "";
        this.selectedConnectionElement = null;
      } else {
        this.contextmenuTarget = ""; 
        this.selectedNodeId = "";
        this.selectedConnectionElement = null;
      }
      if (this.contextmenuTarget) {
        this.menu.show(event);
      }
    } else {
      this.conextmenuEvent = null;
      this.contextmenuTarget = ""; 
      this.selectedNodeId = "";
    }
  }
  // #endregion context menu
}

export default FlowGraphView;
</script>
