<template>
  <div class="default-view-container organisation-profile-container">
    <div class="default-view-head-section">
      <h1 class="mb-0">User Management</h1>   
    </div>
    <div v-if="authState.permissions?.FullAccess || authState.permissions?.GroupAccess" class="organisation-profile mt-4 lg:mt-5">
      <BlockUI :blocked="!isLoaded" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index main-bg-color" :class="!isLoaded ? 'blockui-blocked' : ''">
        <TabView lazy :activeIndex="organisationStore.organisationUserMangmentTabIndex" @update:activeIndex="changeTab">
          <TabPanel header="Summary">
            <OrganisationUsersSummaryView/>
          </TabPanel>
          <TabPanel header="Members">
            <OrganisationUsersMembers/>
          </TabPanel>
          <TabPanel header="Access Control">
            <OrganisationUsersGroups/>
          </TabPanel>
        </TabView>

        <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
      </BlockUI>
    </div>
    <section class="mt-4 lg:mt-5 mb-0" v-else>
      <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
    </section>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import AuthState from "@/store/states/AuthState";
import OrganisationUsersSummaryView from "@/components/views/organisation/OrganisationUsersSummaryView.vue";
import OrganisationUsersMembers from "@/components/views/organisation/OrganisationUsersMembers.vue";
import OrganisationUsersGroups from "@/components/views/organisation/OrganisationUsersGroups.vue";
import { useOrganisationStore } from "@/stores/organisation";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { useOrganisationGroupsStore } from "@/stores/organisationGroups";

@Component({
  components: {
    TabView,
    TabPanel,
    Message,
    BlockUI,
    ProgressSpinner,
    OrganisationUsersSummaryView,
    OrganisationUsersMembers,
    OrganisationUsersGroups
  },
})
class OrganisationUsersView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();
  organisationUsersStore = useOrganisationUsersStore();
  organisationGroupsStore = useOrganisationGroupsStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get isLoaded(): boolean {
    return this.organisationUsersStore.data[this.organisationIdStr]?.isLoaded &&
      this.organisationGroupsStore.data[this.organisationIdStr]?.isLoaded;
  }

  created(): void {
    if (!this.organisationUsersStore.data[this.organisationIdStr]?.isLoaded && this.organisationStore.currentOrganisation) {
      this.organisationUsersStore.load(this.organisationStore.currentOrganisation.Id);
    }
    if (!this.organisationGroupsStore.data[this.organisationIdStr]?.isLoaded && this.organisationStore.currentOrganisation) {
      this.organisationGroupsStore.load(this.organisationStore.currentOrganisation.Id);
    }
  }

  unmounted() {
    this.organisationUsersStore.$reset();
    this.organisationGroupsStore.$reset();
  }

  changeTab(index: number): void {
    // primevue bug: not updating activeIndex automatically
    if (this.organisationStore.organisationUserMangmentTabIndex !== index) {
      this.organisationStore.organisationUserMangmentTabIndex = index;
    }
  }
}

export default OrganisationUsersView;
</script>