<template>
  <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index" :class="updateInProgress ? 'blockui-blocked' : ''">
    <h2 class="account-settings-title hidden xl:block">{{ t('accountSettings.appearance.title') }}</h2>
    <p class="account-settings-description hidden xl:block">{{ t('accountSettings.appearance.subTitle') }}</p>

    <section>
      <h3>{{ t('accountSettings.appearance.userInterfaceOptions') }}</h3>
      <div class="flex flex-wrap row-gap-3 xl:column-gap-4 xxl:column-gap-6 align-items-center">
        <div class="field w-full xl:w-auto flex-shrink-0 pt-1 xl:pt-0 mb-0">
          <div class="flex align-items-center">
            <InputSwitch v-model="darkTheme" inputId="changeTheme" class="vertical-align-top change-theme-switch p-inputswitch-lg" @change="uiSettingsChange"/>
            <label for="changeTheme" class="mb-0 ml-2">{{ darkTheme ? t('accountSettings.appearance.darkTheme') : t('accountSettings.appearance.lightTheme') }}</label>
          </div>
        </div>
        <div class="field w-full xl:w-auto flex-shrink-0 pt-1 xl:pt-0 mb-0">
          <div class="flex align-items-center">
            <InputSwitch v-model="showHelp" inputId="showHelp" class="vertical-align-top p-inputswitch-lg" @change="uiSettingsChange"/>
            <label for="showHelp" class="mb-0 ml-2">{{ t('accountSettings.appearance.showHelp') }}</label>
          </div>
        </div>
        <div class="field w-full xl:w-auto flex-shrink-0 pt-1 xl:pt-0 mb-0">
          <div class="flex align-items-center">
            <InputSwitch v-model="showHolidays" inputId="showHolidays" class="vertical-align-top p-inputswitch-lg" @change="uiSettingsChange"/>
            <label for="showHolidays" class="mb-0 ml-2">{{ t('accountSettings.appearance.showHolidays') }}</label>
          </div>
        </div>
      </div>
    </section>
    <section class="xl:mb-0">
      <h3>{{ t('accountSettings.appearance.language') }}</h3>
      <div class="formgrid grid">
        <div class="field col-12 md:col-8 xl:col-7 mb-0">
          <label for="language">{{ t('accountSettings.appearance.chooseLanguage') }}</label>
          <div>
            <Dropdown
              inputId="language"
              v-model="locale" 
              :options="locales" 
              :optionLabel="(x: string): string => { return x[1]; }"
              :optionValue="(x: string): string => { return x[0]; }"
              @change="uiSettingsChange" 
              :placeholder="t('accountSettings.appearance.chooseLanguage')" 
              class="w-full"
            />
          </div>
        </div>
      </div>
    </section>
    <div class="block md:flex align-items-center justify-content-end account-settings-button-container">
      <Button 
        @click="closeDialog" 
        :label="t('common.close')" 
        icon="pi pi-times" 
        class="hidden md:inline-flex p-button-text p-button-secondary mr-2"
      />
      <Button 
        :label="t('common.saveChanges')" 
        :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-save'" 
        @click="saveAll"
        class="account-settings-save-button"
        :disabled='updateInProgress || !isUISettingsChaged' 
      />
    </div>

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import AuthState from "@/store/states/AuthState";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import CustomWindow from '@/CustomWindow';
import localeManager from "@/localeManager";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';

declare const window: CustomWindow;

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    Button,
    BlockUI,
    ProgressSpinner,
    InputSwitch,
    Dropdown
  },
})
class UserSettingsAppearanceView extends Vue {
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;
    
  get auth(): AuthState {
    return this.$store.state.auth;
  }

  isUISettingsChaged = false;
  showHelp = false;
  showHolidays = false;
  darkTheme = false;
  locale = "";
  updateInProgress = false;

  created(): void {
    this.showHelp = !!this.auth.userSettings?.showHelp;
    this.showHolidays = !!this.auth.userSettings?.showHolidays;
    this.darkTheme = !!this.auth.userSettings?.darkTheme;
    this.locale = this.auth.userSettings?.locale ?? "";
  }

  uiSettingsChange(): void {
    this.isUISettingsChaged = true;
  }

  async saveAll(): Promise<boolean> {
    this.updateInProgress = true;
    let isOk = true;
    try {
      // check what need to save and call api
      let needThemeReloading = false;
      if (this.isUISettingsChaged && this.auth.userSettings) {
        // save ui settings
        this.auth.userSettings.showHelp = this.showHelp;
        this.auth.userSettings.showHolidays = this.showHolidays;
        needThemeReloading = this.auth.userSettings.darkTheme !== this.darkTheme;
        this.auth.userSettings.darkTheme = this.darkTheme;
        this.auth.userSettings.locale = this.locale;
        await this.$store.dispatch("auth/saveUserSettings");
        if (this.auth.userSettingsSaveError) {
          isOk = false;
        } else {
          this.isUISettingsChaged = false;
        }
      }
      if (needThemeReloading) {
        window.switchTheme(this.darkTheme);
      }
      localeManager.changeLocale(this.locale);
      if (isOk) {
        ToastService.showToast("success", "", this.t("common.changesSaved"), 5000);
      }
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        this.t("common.cantSaveChanges"),
        errorMessage,
        5000
      );
    } finally {
      this.updateInProgress = false;
    }
    return isOk
  }

  get locales(): [string, string][] {
    return localeManager.supportedLocales;
  }

  @Emit
  closeDialog(): void {
    // nothing to do, just emit an event
  }
}

export default UserSettingsAppearanceView;
</script>