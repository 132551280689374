<template>
  <span>
    {{timezoneDisplayname}}
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { TimeZoneDto } from "@/models/TimeZoneDto";

@Component({
  components: {
  },
})
class StreamTimezoneView extends Vue {
  @Prop({ required: true }) timezone!: string;

  get timezoneDisplayname(): string {
    const timezone = this.timezones.find((tz) => tz.Id === this.timezone);
    return timezone ? timezone.DisplayName : this.timezone;
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }
}

export default StreamTimezoneView;
</script>