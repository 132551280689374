<template>
  <div>
    <DataTable
      :value="pageData"
      v-model:expandedRows="expandedRows" 
      dataKey="Id"
      :totalRecords="pageTotal"
      :paginator="true"
      :rows="10"
      :lazy="true"
      @page="onPage($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width email-import-logs-table"
    >
      <template #empty>
        <div v-if="isLoadedPage" class="w-full" style="min-height: 100%;">
          <span class="inline-block py-2">No logs</span>
        </div>
        <div
          class="email-import-logs-table-loader"
          style="min-height: 100%;"
          v-else
        >
          <ProgressSpinner
            class="spinner-primary"
            style="width: 100px; height: 100px"
            strokeWidth="4"
            animationDuration="1s"
          />
        </div>
      </template>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 48px;" bodyClass="no-label-in-stack">
        <template #body="slotProps">
          <div class="inline-flex expand-toggler-btn">
            <Button :icon="(preCloseState.findIndex(x => x === slotProps.data.Id) >= 0 || !expandedRows[slotProps.data.Id]) ? 'pi pi-chevron-right' : 'pi pi-chevron-down'" class="p-button-icon-only p-button-rounded p-button-text p-button-secondary" @click="toggleRow(slotProps.data)" />
          </div>
        </template>
      </Column>
      <Column field="Imported" header="Status" headerStyle="width: 1%; min-width: 48px;" bodyStyle="text-align: center;">
        <template #body="slotProps">
          <i 
            class="pi pi-circle-fill vertical-align-middle" 
            :class="slotProps.data.Imported ? (slotProps.data.FilesFailed.length ? 'text-yellow-500' : 'text-green-500') : 'text-red-500'"
            v-tippy="slotProps.data.Imported ? (slotProps.data.FilesFailed.length ? 'Partially Imported' : 'Imported') : 'Failed'"
          ></i>
        </template>
      </Column>
      <Column field="FromEmail" header="From"></Column>
      <Column field="MessageSubject" header="Subject"></Column>
      <Column field="Date" header="Date" headerStyle="width: 17%;">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.Date" timezone="local"/>
        </template>
      </Column>
      <template #expansion="slotProps">
        <transition name="p-toggleable-content" appear>
          <ul class="flows-table-other-fields" v-if="preCloseState.findIndex(x => x === slotProps.data.Id) < 0">
            <li class="pt-3">
              <span><b>Imported</b></span>
              <span><span class="break-word">{{slotProps.data.FilesImported.join(", ")}}</span></span>
            </li>
            <li>
              <span><b>Failed</b></span>
              <span><span class="break-word">{{slotProps.data.FilesFailed.join(", ")}}</span></span>
            </li>
            <li>
              <span><b>Ignored</b></span>
              <span><span class="break-word">{{slotProps.data.FilesIgnored.join(", ")}}</span></span>
            </li>
            <li class="pb-3">
              <span><b>Error</b></span>
              <span><span class="break-word">{{slotProps.data.Error}}</span></span>
            </li>                                
          </ul>
        </transition>
      </template>
    </DataTable>
  </div>
</template>

<script lang="ts">
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Checkbox from "primevue/checkbox";
import { Component, Model, Vue } from "vue-facing-decorator";
import DateTimezoneView from '@/components/views/DateTimezoneView.vue';
import CustomWindow from '@/CustomWindow';
import { useEmailImportStore } from "@/stores/emailImport";
import { EmailImportHistoryEntity } from "@/models/email-import/EmailImportHistoryEntity";

declare const window: CustomWindow;

@Component({
  components: {
    Button,
    DataTable,
    Column,
    ProgressSpinner,
    Dialog,
    InputText,
    Checkbox,
    DateTimezoneView
  },
})
class EmailImport2HistoryView extends Vue {
  @Model configId!: string;

  emailImportStore = useEmailImportStore();

  get pageData(): EmailImportHistoryEntity[] {
    return this.emailImportStore.isLoadedHistory &&
      this.emailImportStore.history &&
      this.emailImportStore.history.Items
      ? this.emailImportStore.history.Items
      : [];
  }

  get pageTotal(): number {
    return this.emailImportStore.isLoadedHistory && this.emailImportStore.history
      ? this.emailImportStore.history.Total
      : 0;
  }

  get isLoadedPage(): boolean {
    return this.emailImportStore.isLoadedHistory;
  }

  take = 10;
  skip = 0;

  created(): void {
    this.loadData();
  }

  loadData(): void {
    this.emailImportStore.loadHistory(this.configId, this.skip, this.take);
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.skip = event.page * event.rows;
    this.take = event.rows;
    this.loadData();
  }
  
  expandedRows: Record<string, boolean> = {};
  preCloseState: string[] = [];

  toggleRow(row: EmailImportHistoryEntity): void {
    if (row.Id) {
      if (this.expandedRows[row.Id]) {
        // close
        this.preCloseState.push(row.Id);
        window.setTimeout(() => {
          const idIndex = this.preCloseState.findIndex(x => x === row.Id);
          if (idIndex >= 0 && this.expandedRows[row.Id]) {
            const newExpandedRows = { ...this.expandedRows };
            delete newExpandedRows[row.Id];
            this.expandedRows = newExpandedRows;
            this.preCloseState.splice(idIndex, 1);
          }
        }, 450);
      } else {
        // open
        const idIndex = this.preCloseState.findIndex(x => x === row.Id);
        if (idIndex >= 0) {
          this.preCloseState.splice(idIndex, 1);
        }
        this.expandedRows = { ...this.expandedRows, [row.Id]: true };
      }
    }
  }
}

export default EmailImport2HistoryView;
</script>
