<template>
  <Dialog 
    header="Graphic Library" 
    v-model:visible="displayDialog" 
    :modal="true" 
    :style="{width: '90vw'}"
    class="graphic-library-dialog"
    :class="(imageGalleryStore.isLoaded && (searchFinal || selectedGroup)) ? 'with-padding-bottom' : ''"
  >
    <template #header>
      <div class="sm:flex justify-content-between align-items-center w-full flex-auto">
        <span class="p-dialog-title mb-0 mr-5">Graphic Library</span>

        <IconField iconPosition="left" class="search-input-box mb-0">
          <InputIcon class="pi pi-search lg:text-xl"></InputIcon>
          <InputText
            class="inputfield lg:text-lg"
            placeholder="Search"
            type="text"
            v-model="search"
            @input="debounceSearch()"
            style="border-radius: 3rem;"
          />
        </IconField>
      </div>
    </template>

    <div class="dialog-content">
      <div class="graphic-library">
        <ImageGalleryHeaderView/>
        <ImageGalleryGroupsView v-model="searchFinal" :selectable="true" @imageSelected="imageSelected"/>
      </div>
    </div>
  </Dialog>
</template>

<script lang="ts">
import { Component, Emit, Model, Vue } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Dialog from 'primevue/dialog';
import ImageGalleryGroupsView from "@/components/views/image-gallery/ImageGalleryGroupsView.vue";
import { debounce } from "throttle-debounce";
import { useImageGalleryStore } from "@/stores/imageGallery";
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";
import { useImageGalleryGroupStore } from "@/stores/imageGalleryGroup";
import { ImageGalleryGroupEntity } from "@/models/image-gallery/ImageGalleryGroupEntity";
import ImageGalleryHeaderView from "@/components/views/image-gallery/ImageGalleryHeaderView.vue";

@Component({
  components: {
    InputText,
    IconField,
    InputIcon,
    Dialog,
    ImageGalleryGroupsView,
    ImageGalleryHeaderView
  },
})
class ImageGalleryDialogView extends Vue {
  @Model displayDialog!: boolean;

  @Emit imageSelected(entity: ImageGalleryEntity | null): ImageGalleryEntity | null {
    return entity;
  }
  
  imageGalleryGroupStore = useImageGalleryGroupStore();
  imageGalleryStore = useImageGalleryStore();
  
  search = "";
  searchFinal = "";
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  get selectedGroup(): ImageGalleryGroupEntity | null {
    const group = this.imageGalleryGroupStore.selectedItem;
    return group;
  }
}

export default ImageGalleryDialogView;
</script>