<template>
  <div ref="root" v-if="!isEndOfLifeState">
    <div class="title-box">{{ nodeName }}</div>
    <div class="body-box">
      <div class="field mb-0 mt-2" v-if="nodeType === 'stream' || nodeType === 'pool' || isVirtualStreamNode()">
        <label for="" class="block with-inline-btn">
          <span class="flex align-items-center gap-2">
            <span>Pool</span>
          </span>
          <Button
            v-if="selectedPoolKey"
            @click="viewPool(selectedPoolKey)"
            label="View Pool"
            icon="pi pi-external-link" 
            class="p-button-icon-only p-button-rounded p-button-text action-button-size"
          />
        </label>
        <div>
          <Dropdown
            v-model="selectedPoolKey"
            @change="onPoolChange($event)"
            :options="allPools"
            optionValue="Id"
            optionLabel="Name"
            placeholder="Select Pool"
            :filter="true"
            filterPlaceholder="Find Pool"
            class="w-full"
            scrollHeight="300px"
            panelClass="reduce-options-font-size set-width"
            :virtualScrollerOptions="{ itemSize: 30 }"
            :disabled="selectedAllPools"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'pool'">
        <div class="flex align-items-center">
          <InputSwitch 
            v-model="selectedAllPools"
            class="vertical-align-top"
            @change="onAllPoolsChange"
          />
          <label class="mb-0 ml-2">All Pools</label>
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'pool'">
        <label for="">Include Tags</label>
        <div>
          <Chips 
            v-model="selectedIncludeTags" 
            separator=","
            class="w-full"
            @add="onIncludeTagsChange"
            @remove="onIncludeTagsChange"
          />
          <div class="text-xs font-italic">*for AI Insights block</div>
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'stream'">
        <label for="" class="block with-inline-btn">
          <span class="flex align-items-center gap-2">
            <span>Stream</span>
            <span 
              class="tree-node-icon" 
              :class="getReadingStatusColorClass(readingStatus)"
              v-tippy="readingStatus"
              v-if="stream"
            ></span>
          </span>
          <Button
            v-if="selectedStreamKey"
            @click="viewStream(selectedStreamKey)"
            label="View Stream"
            icon="pi pi-external-link" 
            class="p-button-icon-only p-button-rounded p-button-text action-button-size"
          />
        </label>
        <div>
          <Dropdown
            :disabled="!selectedPoolKey"
            v-model="selectedStreamKey"
            @change="onStreamChange($event)"
            :loading="!!selectedPoolKey && !getStreamsForPoolIsLoaded(selectedPoolKey)"
            :options="getStreamsForPool(selectedPoolKey)"
            optionValue="Id"
            optionLabel="Name"
            placeholder="Select Stream"
            :filter="true"
            filterPlaceholder="Find Stream"
            class="w-full"
            scrollHeight="300px"
            panelClass="reduce-options-font-size set-width"
            :virtualScrollerOptions="{ itemSize: 30 }"
          >
            <template #option="slotProps">
              <div class="flex align-items-center">
                <span 
                  class="mr-2 tree-node-icon" 
                  :class="getReadingStatus(slotProps.option) === 'Normal' ? 'bg-green-500' : 'bg-red-500'"
                ></span>
                <div>{{ slotProps.option.Name }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'stream'">
        <label for="">Display Name</label>
        <div>
          <InputText
            class="inputfield w-full"
            placeholder="Display Name"
            type="text"
            v-model="selectedDisplayName"
            @input="onDisplayNameChange"
          />
        </div>
        <div class="text-xs font-italic">*for insight blocks</div>
      </div>
      <div class="field mb-0 mt-2" v-if="nodeType === 'tag_node'">
        <label for="">Entity</label>
        <div>
          <TreeSelectWithTagFilterView 
            :selectedId="selectedStreamKey" 
            :nodes="navTreeStore.structuredDataForUI"
            :changeSelectedId="nodeFromTreeSelected"
            placeholder="Select Entity"
            :tags="['site|space|equip']"
            :allowIncompatible="false"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2" v-if="nodeType === 'tag_node'">
        <label for="">Tags</label>
        <div>
          <div class="tags-list" v-if="selectedTags">
            <span v-if="!selectedTags.length" @click="openEditor('')" class="link-dark no-tag">Add tags</span>
            <Tag v-for="tag in selectedTags" :key="tag" class="tag-with-close-btn">
              <span class="p-tag-value" @click="openEditor(tag)">{{tag}}</span>
              <Button @click="deleteTag(tag)" label="Delete" icon="pi pi-times" class="p-button-secondary p-button-icon-only p-button-rounded p-button-sm" />
            </Tag> 
            <Button @click="openEditor('')" label="Add" icon="pi pi-plus" class="p-button-icon-only p-button-rounded p-button-text add-tag" />
          </div>
        </div>
        <Dialog :header="isNewTag ? 'New Tag' : 'Edit Tag'" v-model:visible="displayEditor" :modal="true" :style="{width: '56rem'}" class="drawflow-tag-dialog">
          <div class="dialog-content">
            <div class="semantics-settings-container">
              <div class="formgrid grid" id="semantics-tags">
                <StringTagEditorView 
                  v-model="tagEdit" 
                  :excludeFields="excludeFields"
                  :disabledFields="disabledFields"
                  :allowAny="true"
                  :allowRef="true"
                />
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="Cancel" icon="pi pi-times" @click="closeEditor" class="p-button-text p-button-secondary"/>
            <Button label="Save" icon="pi pi-check" @click="editTag" :disabled='!tagEdit' />
          </template>
        </Dialog>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="nodeType === 'stream' || nodeType === 'tag_node'">
        <label for="">Aggregation Type</label>
        <div>
          <Dropdown
            v-model="selectedAggregationType"
            @change="onAggregationTypeChange"
            :options="aggregationTypes"
            optionValue="key"
            optionLabel="name"
            placeholder="Select Aggregation Type"
            :filter="true"
            filterPlaceholder="Find Aggregation Type"
            class="w-full"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2 pt-1" v-if="isVirtualStreamNode()">
        <label for="" class="block with-inline-btn">
          <span>Stream Name</span>
          <Button
            v-if="selectedStreamKey"
            @click="viewStream(selectedStreamKey)"
            label="View Stream"
            icon="pi pi-external-link" 
            class="p-button-icon-only p-button-rounded p-button-text action-button-size"
          />
        </label>
        <div>
          <InputText
            class="inputfield w-full"
            placeholder="Stream Name"
            type="text"
            v-model="selectedStreamName"
            @input="onStreamNameChange"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2" v-if="nodeType === 'value'">
        <label for="">Value</label>
        <div>
          <InputNumber
            v-model="selectedValue" 
            mode="decimal" 
            :minFractionDigits="2" 
            class="inputfield w-full" 
            placeholder="Value"
            @input="onValueChange"
          />
        </div>
      </div>
      <div class="field mb-0 mt-2" v-if="nodeType === 'generator'">
        <div class="mb-2 pb-1">
          <Button label="Add" icon="pi pi-plus-circle" @click="openEditGeneratorOptionsDialog(-1)" />
        </div>
        <div class="flow-datatable-container flow-datatable-container-vertical-scrollable">
          <DataTable 
            v-if="selectedGeneratorOptions"
            :value="selectedGeneratorOptions" 
            showGridlines 
            responsiveLayout="scroll"
            class="p-datatable-sm"
          >
            <Column field="FirstYear" header="Years" headerClass="no-break-word" bodyClass="no-break-word" headerStyle="border: 0;">
              <template #body="{ data }">
                {{ data.FirstYear }} - {{ data.LastYear }}
              </template>
            </Column>
            <Column field="" header="" headerStyle="width: 1%; min-width: 88px; border: 0;" bodyStyle="text-align: right; justify-content: flex-end;">
              <template #body="{ index }">
                <div class="inline-flex">
                  <Button 
                    icon="pi pi-pencil" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openEditGeneratorOptionsDialog(index)" 
                  />
                  <Button 
                      icon="pi pi-trash" 
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                      @click="() => selectedGeneratorOptions?.splice(index, 1)"
                      :disabled="selectedGeneratorOptions.length === 1"
                    />
                  </div>
              </template>
            </Column>
          </DataTable>
        </div>
      </div>
    </div>

    <Dialog header="Generator Setup" v-model:visible="displayGeneratorModal" :modal="true" :style="{width: '48rem'}" class="generator-setup-dialog">
      <div class="dialog-content">
        <div class="formgrid grid" v-if="editGeneratorOptions">
          <div class="field col-12 sm:col-6">
            <label for="firstYearPicker">First Year</label>
            <div>
              <Calendar 
                v-model="firstYear" 
                inputId="firstYearPicker" 
                view="year" 
                dateFormat="yy"
                class="w-full"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="lastYearPicker">Last Year (inclusive)</label>
            <div>
              <Calendar 
                v-model="lastYear" 
                inputId="lastYearPicker" 
                view="year" 
                dateFormat="yy" 
                class="w-full"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6 md:col-4" v-for="(monthData, index) in editGeneratorOptions.Data" :key="monthData.Month">
            <label :for="`month${index}`">{{ monthNumberToString(monthData.Month) }}</label>
            <div>
              <InputNumber
                :inputId="`month${index}`"
                v-model="monthData.Value"
                :allowEmpty="false"
                :min="0"
                :minFractionDigits="2"
                class="inputfield w-full"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="baseLoad">Base Load</label>
            <div>
              <InputNumber
                inputId="baseLoad"
                v-model="editGeneratorOptions.BaseLoad"
                :allowEmpty="false"
                :min="0"
                :minFractionDigits="2"
                class="inputfield w-full"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6 pt-1 sm:pt-0 switch-align-end sm:align-self-end">
            <div class="flex align-items-center">
              <InputSwitch 
                inputId="excludeWeekends"
                v-model="editGeneratorOptions.ExcludeWeekends"
                class="vertical-align-top"
              />
              <label for="excludeWeekends" class="mb-0 ml-2">Exclude Weekends</label>
            </div>
          </div>
        </div>
        <div class="formgrid grid">
          <div class="field col-12 sm:col-6 sm:mb-0">
            <label for="workHoursStart">Work Hours Start</label>
            <div>
              <Calendar 
                v-model="workHoursStart" 
                inputId="workHoursStart" 
                :timeOnly="true"
                hourFormat="24"
                class="w-full"
              />
            </div>
          </div>
          <div class="field col-12 sm:col-6 mb-0">
            <label for="workHoursEnd">Work Hours End</label>
            <div>
              <Calendar 
                v-model="workHoursEnd" 
                inputId="workHoursEnd" 
                :timeOnly="true"
                hourFormat="24"
                class="w-full"
              />
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeEditGeneratorOptionsDialog" class="p-button-text p-button-secondary"/>
        <Button label="Save" icon="pi pi-check" @click="applyGeneratorOptions" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import Button from 'primevue/button';
import Dropdown from "primevue/dropdown";
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import InputSwitch from 'primevue/inputswitch';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Calendar from 'primevue/calendar';
import Tag from 'primevue/tag';
import Chips from 'primevue/chips';
import { Ref } from "vue-facing-decorator";
import { Component, Vue } from "vue-facing-decorator";
import { ComponentInternalInstance, getCurrentInstance, nextTick } from "vue";
import Drawflow from "drawflow";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import { FlowOptions } from "@/models/flow/FlowOptions";
import { PoolModelLite } from "@/models/PoolModelLite";
import { StreamModelLite } from "@/models/StreamModelLite";
import FlowState from '@/store/states/FlowState';
import { FlowGeneratorOptions } from '@/models/flow/FlowGeneratorOptions';
import moment from 'moment';
import ToastService from '@/services/ToastService';
import { AggregationType } from '@/models/enums/AggregationType';
import { useNavTreeStore } from '@/stores/navTree';
import { TreeNodeForUI } from '@/models/nav-tree/NavTreeForUI';
import TreeSelectWithTagFilterView from '@/components/views/TreeSelectWithTagFilterView.vue';
import StringTagEditorView from '@/components/views/tags/StringTagEditorView.vue';
import HaystackDefsService from '@/services/HaystackDefsService';
import DataHelper from '@/helpers/DataHelper';

@Component({
  components: {
    Button,
    Dropdown,
    InputText,
    InputNumber,
    InputSwitch,
    DataTable,
    Column,
    Dialog,
    Calendar,
    Tag,
    Chips,
    TreeSelectWithTagFilterView,
    StringTagEditorView
  },
})
class FlowNodeView extends Vue {
  @Ref() readonly root!: HTMLDivElement;
  nodeId = "";
  nodeType = "";
  nodeName = "";
  nodeOptions: FlowOptions | null = null;
  currentInstance: ComponentInternalInstance | null = null;
  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();
  isEndOfLifeState = false;
  selectedPoolName: string | null | undefined = null;
  selectedPoolKey: string | null | undefined = null;
  selectedStreamName: string | null | undefined = null;
  selectedStreamKey: string | null | undefined = null;
  selectedDisplayName: string | null | undefined = null;
  selectedAggregationType: AggregationType | number = -1;
  selectedValue: number | null | undefined = null;
  selectedGeneratorOptions: FlowGeneratorOptions[] | undefined | null;
  selectedTags: string[] | undefined | null;
  selectedAllPools: boolean | undefined | null;
  selectedIncludeTags: string[] | undefined | null;

  aggregationTypes = [
    {name: 'Default', key: -1},
    {name: 'Sum', key: AggregationType.Sum},
    {name: 'Diff', key: AggregationType.Diff},
    {name: 'First', key: AggregationType.First},
    {name: 'Last', key: AggregationType.Last},
    {name: 'Average', key: AggregationType.Avg},
    {name: 'Maximum', key: AggregationType.Max},
    {name: 'Minimum', key: AggregationType.Min}
  ];

  get virtualStreamType(): string[] {
    return this.flowState.virtualStreamType;
  }

  navTreeStore = useNavTreeStore();
  
  get flowState(): FlowState {
    return this.$store.state.flow;
  }

  get allPools(): PoolModelLite[] {
    const pools =
      this.$store.state.pools.poolsList &&
      this.$store.state.pools.poolsList.Pools
        ? (this.$store.state.pools.poolsList.Pools as PoolModelLite[])
        : [];
    return pools;
  }

  get df(): Drawflow | null {
    return this.currentInstance
      ? (this.currentInstance.appContext.config.globalProperties.$df as Drawflow)
      : null;
  }

  unmountNodeEvent(e: string): void {
    if (this.nodeId === e) {
      this.emitter.off("unmount_df_node", this.unmountNodeEvent);
      const ci = this.currentInstance as any;
      if (ci.um && ci.um.length) {
        for (const um of ci.um) {
          um();
        }
      }
    }
  }

  async mounted(): Promise<void> {
    this.currentInstance = getCurrentInstance();
    this.emitter.on("unmount_df_node", this.unmountNodeEvent);
    await nextTick();
    if (this.root) {
      const nodeElement = this.root.closest(".drawflow-node");
      if (nodeElement) {
        this.nodeId = nodeElement.id.slice(5);
        const node = this.df?.getNodeFromId(this.nodeId);
        if (node) {
          this.nodeType = node.data.nodeType;
          this.nodeName = node.data.nodeName;
          this.nodeOptions = node.data.nodeOptions ?
            node.data.nodeOptions :
            { 
              PoolName: undefined, 
              PoolKey: undefined, 
              StreamName: undefined, 
              StreamKey: undefined, 
              DisplayName: undefined,
              Value: undefined, 
              GeneratorOptions: this.nodeType === "generator" ?
                [this.createNewGeneratorOptions()] : 
                undefined,
              Tags: this.nodeType === "tag_node" ?
                [] : 
                undefined,
              AllPools: undefined,
              IncludeTags: undefined
            };
          if (this.nodeType === "generator" && !node.data.nodeOptions) {
            this.updateNodeOptions();
          }

          this.selectedPoolName = this.nodeOptions?.PoolName;
          this.selectedPoolKey = this.nodeOptions?.PoolKey;
          this.selectedStreamName = this.nodeOptions?.StreamName;
          this.selectedStreamKey = this.nodeOptions?.StreamKey;
          this.selectedDisplayName = this.nodeOptions?.DisplayName;
          this.selectedAggregationType = this.nodeOptions?.AggregationType ?? -1;
          this.selectedValue = this.nodeOptions?.Value;
          this.selectedGeneratorOptions = this.nodeOptions?.GeneratorOptions;
          this.selectedTags = (this.nodeType === "tag_node" && !this.nodeOptions?.Tags) ? [] : this.nodeOptions?.Tags;
          this.selectedAllPools = this.nodeOptions?.AllPools;
          if (this.nodeType === "pool") {
            this.selectedIncludeTags = this.nodeOptions?.IncludeTags ?? [];
          } else {
            this.selectedIncludeTags = this.nodeOptions?.IncludeTags;
          }
          if (this.selectedPoolKey && this.nodeType === "stream") {
            this.loadStreamsForPool(this.selectedPoolKey);
          }
        }
        await nextTick();
        // https://github.com/jerosoler/Drawflow/issues/405
        this.df?.updateConnectionNodes(nodeElement.id);
      }
    }
  }

  unmounted(): void {
    this.isEndOfLifeState = true;
  }

  viewPool(poolKey: string | null | undefined): void {
    if (poolKey) {
      const newUrl = `/data/pools/${poolKey}`;
      window.open(newUrl, '_blank');
    }
  }

  viewStream(streamKey: string | null | undefined): void {
    if (streamKey) {
      const newUrl = `/data/streams/${streamKey}`;
      window.open(newUrl, '_blank');
    }
  }

  getNodeData(): any  {
    const node = this.df?.getNodeFromId(this.nodeId);
    if (node) {
      const newData = Object.assign({}, node.data);
      return newData;
    } else {
      return null;
    }
  }

  updateNodeOptions(): void {
    const nodeData = this.getNodeData();
    if (nodeData) {
      nodeData.nodeOptions = this.nodeOptions;
      this.df?.updateNodeDataFromId(this.nodeId, nodeData);
      // call nodeDataChanged event https://github.com/jerosoler/Drawflow/issues/287
      // dispatch is missing in typescript defenition
      (this.df as any)?.dispatch('nodeDataChanged', this.nodeId );
    }
  }

  onPoolChange(event: any): void {
    const poolKey = event.value as string;
    if (this.selectedStreamKey && !this.isVirtualStreamNode()) {
      this.selectedStreamName = null;
      this.selectedStreamKey = null;
    }
    if (this.nodeType !== "pool") {
      this.loadStreamsForPool(poolKey);
    }
    
    if (this.nodeOptions) {
      const pool = this.allPools.find(x => x.Id === poolKey);
      if (pool) {
        this.selectedPoolName = pool.Name;
        this.nodeOptions.PoolName = pool.Name;
      }
      this.nodeOptions.PoolKey = poolKey;
      this.updateNodeOptions();
    }
  }

  onStreamChange(event: any): void {
    const streamKey = event.value as string;
    if (this.nodeOptions) {
      const stream = this.getStreamsForPool(this.selectedPoolKey).find(x => x.Id === streamKey);
      if (stream) {
        this.selectedStreamName = stream.Name;
        this.nodeOptions.StreamName = stream.Name;
      }
      this.nodeOptions.StreamKey = streamKey;
      this.updateNodeOptions();
    }
  }

  onAggregationTypeChange(): void {
    if (this.nodeOptions) {
      this.nodeOptions.AggregationType = this.selectedAggregationType === -1 ? null : this.selectedAggregationType;
      this.updateNodeOptions();
    }
  }

  onStreamNameChange(): void {
    if (this.nodeOptions) {
      this.nodeOptions.StreamName = this.selectedStreamName;
      this.updateNodeOptions();
    }
  }

  onDisplayNameChange(): void {
    if (this.nodeOptions) {
      this.nodeOptions.DisplayName = this.selectedDisplayName;
      this.updateNodeOptions();
    }
  }

  onValueChange(event: any): void {
    if (this.nodeOptions) {
      this.nodeOptions.Value = event.value;
      this.updateNodeOptions();
    }
  }

  onAllPoolsChange(): void {
    if (this.nodeOptions) {
      this.nodeOptions.AllPools = this.selectedAllPools;
      this.updateNodeOptions();
    }
  }

  onIncludeTagsChange(): void {
    if (this.nodeOptions) {
      this.nodeOptions.IncludeTags = this.selectedIncludeTags;
      this.updateNodeOptions();
    }
  }

  loadStreamsForPool(poolKey: string): void {
    if (!this.getStreamsForPoolIsLoaded(poolKey) && !this.getStreamsForPoolIsLoading(poolKey)) {
      this.$store.dispatch("pool/loadStreamsList", poolKey);
    }
  }

  getStreamsForPoolIsLoaded(poolKey: string | null | undefined): boolean {
    const state = poolKey ? this.$store.state.pool.poolStreamsDictionary[poolKey] : null;
    const result = !!state && state.isLoadedList;
    return result;
  }

  getStreamsForPoolIsLoading(poolKey: string | null | undefined): boolean {
    const state = poolKey ? this.$store.state.pool.poolStreamsDictionary[poolKey] : null;
    const result = !!state && state.isLoadingList;
    return result;
  }

  getStreamsForPool(poolKey: string | null | undefined): StreamModelLite[] {
    const state = poolKey ? this.$store.state.pool.poolStreamsDictionary[poolKey] : null;
    const streams = !!state && state.isLoadedList && state.streamsList && state.streamsList.Streams ?
      state.streamsList.Streams as StreamModelLite[] :
      [];
    return streams;
  }

  get stream(): StreamModelLite | null {
    if (this.nodeOptions) {
      const streamKey = this.nodeOptions.StreamKey;
      const stream = this.getStreamsForPool(this.selectedPoolKey).find(x => x.Id === streamKey);
      if (stream) {
        return stream;
      }
    }
    return null;
  }

  get readingStatus(): string {
    return this.getReadingStatus(this.stream);
  }

  getReadingStatus(stream: StreamModelLite | null): string {
    return DataHelper.readingStatusLite(stream);
  }

  getReadingStatusColorClass(status: string): string {
    return DataHelper.readingStatusColorClass(status);
  }

  isVirtualStreamNode(): boolean {
    return this.virtualStreamType.findIndex(x => x === this.nodeType) >= 0;
  }

  nodeFromTreeSelected(node: TreeNodeForUI): void {
    this.selectedStreamKey = node.key ?? "";
    this.selectedStreamName = node.label ?? "";
    if (this.nodeOptions) {
      this.nodeOptions.StreamKey = this.selectedStreamKey;
      this.nodeOptions.StreamName = this.selectedStreamName;
      this.updateNodeOptions();
    }
  }

  // #region tags
  displayEditor = false;
  tagEdit = "";
  tagOrigin = "";
  isNewTag = true;
  excludeFields = ["ref"];
  disabledFields = ["id", "siteRef", "spaceRef", "equipRef", "system", "systemRef"];

  openEditor(tag: string): void {
    this.tagEdit = tag;
    this.tagOrigin = tag;
    this.isNewTag = !tag;
    this.displayEditor = true;
  }

  closeEditor(): void {
    this.displayEditor = false;
  }

  validateTag(tag: string): string {
    return HaystackDefsService.validateTag(tag);
  }

  editTag(): void {
    if (this.selectedTags) {
      if (this.tagEdit) {
        const errorMessage = this.validateTag(this.tagEdit);
        if (errorMessage) {
          ToastService.showToast("error", "Error", errorMessage, 5000);
        } else {
          if (this.tagOrigin) {
            const index = this.selectedTags.indexOf(this.tagOrigin);
            if (index >= 0) {
              this.selectedTags[index] = this.tagEdit;
            } else {
              this.selectedTags.push(this.tagEdit);
            }
          } else {
            this.selectedTags.push(this.tagEdit);
          }
          this.onTagsChange();
          this.closeEditor();
        }
      } else {
        ToastService.showToast("error", "Error", "Please enter tag.", 5000);
      }
    }
  }

  deleteTag(tag: string): void {
    this.selectedTags = this.selectedTags?.filter(x => x !== tag);
    this.onTagsChange();
  }

  onTagsChange(): void {
    if (this.nodeOptions) {
      this.nodeOptions.Tags = this.selectedTags;
      this.updateNodeOptions();
    }
  }
  // #endregion tags

  // #region model generator
  displayGeneratorModal = false;
  editGeneratorOptions: FlowGeneratorOptions | null | undefined = null;
  editGeneratorOptionsIndex = -1;

  get firstYear(): Date | undefined {
    if (this.editGeneratorOptions) {
      return new Date(`${this.editGeneratorOptions.FirstYear}-01-01T00:00:00`);
    } else {
      return undefined;
    }
  }
  
  set firstYear(value: Date | undefined) {
    if (this.editGeneratorOptions) {
      if (value) {
        this.editGeneratorOptions.FirstYear = value.getFullYear();
      } else {
        this.editGeneratorOptions.FirstYear = (new Date()).getFullYear();
      }
    }
  }

  get lastYear(): Date | undefined {
    if (this.editGeneratorOptions) {
      return new Date(`${this.editGeneratorOptions.LastYear}-01-01T00:00:00`);
    } else {
      return undefined;
    }
  }
  
  set lastYear(value: Date | undefined) {
    if (this.editGeneratorOptions) {
      if (value) {
        this.editGeneratorOptions.LastYear = value.getFullYear();
      } else {
        this.editGeneratorOptions.LastYear = (new Date()).getFullYear();
      }
    }
  }

  get workHoursStart(): Date | undefined {
    if (this.editGeneratorOptions) {
      return new Date(`2023-01-01T${this.editGeneratorOptions.WorkHoursStart}`);
    } else {
      return undefined;
    }
  }
  
  set workHoursStart(value: Date | undefined) {
    if (this.editGeneratorOptions) {
      if (value) {
        this.editGeneratorOptions.WorkHoursStart = moment(value).format("HH:mm:ss");
      } else {
        this.editGeneratorOptions.WorkHoursStart = "00:00:00";
      }
    }
  }

  get workHoursEnd(): Date | undefined {
    if (this.editGeneratorOptions) {
      return new Date(`2023-01-01T${this.editGeneratorOptions.WorkHoursEnd}`);
    } else {
      return undefined;
    }
  }
  
  set workHoursEnd(value: Date | undefined) {
    if (this.editGeneratorOptions) {
      if (value) {
        this.editGeneratorOptions.WorkHoursEnd = moment(value).format("HH:mm:ss");
      } else {
        this.editGeneratorOptions.WorkHoursEnd = "00:00:00";
      }
    }
  }

  openEditGeneratorOptionsDialog(index: number): void {
    if (this.selectedGeneratorOptions) {
      const item = index >= 0 ?
        JSON.parse(JSON.stringify(this.selectedGeneratorOptions[index])) :
        this.createNewGeneratorOptions();
      this.editGeneratorOptions = item ? item : null;
      this.editGeneratorOptionsIndex = index;

      this.displayGeneratorModal = true;
    }
  }

  closeEditGeneratorOptionsDialog(): void {
    this.displayGeneratorModal = false;
  }

  applyGeneratorOptions(): void {
    if (this.selectedGeneratorOptions && this.editGeneratorOptions) {
      // validate
      let isOk = true;
      if (this.editGeneratorOptions.FirstYear > this.editGeneratorOptions.LastYear) {
        ToastService.showToast("error", "Invalid Date Range", "First Year must be before Last Year.", 5000);
        isOk = false;
      }
      if (this.editGeneratorOptions.FirstYear < 2010 || this.editGeneratorOptions.LastYear < 2010) {
        ToastService.showToast("error", "Invalid Date Range", "Years prior to 2010 cannot be generated at this time.", 5000);
        isOk = false;
      }
      if (this.editGeneratorOptions.FirstYear > 2040 || this.editGeneratorOptions.LastYear > 2040) {
        ToastService.showToast("error", "Invalid Date Range", "Years after 2040 cannot be generated at this time.", 5000);
        isOk = false;
      }
      // find overlapped date ranges
      if (isOk) {
        let arrayLength = this.selectedGeneratorOptions.length;
        if (this.editGeneratorOptionsIndex < 0) {
          arrayLength++;
        }
        if (arrayLength > 1) {
          const years: number[] = [];
          for (let j = this.editGeneratorOptions.FirstYear; j <= this.editGeneratorOptions.LastYear; j++) {
            years.push(j);
          }
          for (let i = 0; i < this.selectedGeneratorOptions.length; i++) {
            if (i !== this.editGeneratorOptionsIndex) {
              const item = this.selectedGeneratorOptions[i];
              if (item.LastYear >= item.FirstYear) {
                for (let j = item.FirstYear; j <= item.LastYear; j++) {
                  if (years.includes(j)) {
                    ToastService.showToast("error", "Invalid Date Range", "Date Range intervals can't overlap.", 5000);
                    isOk = false;
                    break;
                  } else {
                    years.push(j);
                  }
                }
              }
              if (!isOk) {
                break;
              }
            }
          }
        }
      }
      // save
      if (isOk) {
        if (this.editGeneratorOptionsIndex >= 0) {
          this.selectedGeneratorOptions[this.editGeneratorOptionsIndex] = this.editGeneratorOptions;
        } else {
          this.selectedGeneratorOptions.push(this.editGeneratorOptions);
        }
        this.updateNodeOptions();
        this.closeEditGeneratorOptionsDialog();
      }
    }
  }

  createNewGeneratorOptions(): FlowGeneratorOptions {
    let year = moment().year();
    if (this.selectedGeneratorOptions?.length) {
      year = Math.max(...this.selectedGeneratorOptions.map(x => x.LastYear)) + 1;
    }
    return {
      FirstYear: year,
      LastYear: year,
      Data: [
        {Month: 1, Value: 100000},
        {Month: 2, Value: 100000},
        {Month: 3, Value: 100000},
        {Month: 4, Value: 100000},
        {Month: 5, Value: 100000},
        {Month: 6, Value: 100000},
        {Month: 7, Value: 100000},
        {Month: 8, Value: 100000},
        {Month: 9, Value: 100000},
        {Month: 10, Value: 100000},
        {Month: 11, Value: 100000},
        {Month: 12, Value: 100000}
      ],
      BaseLoad: 0,
      WorkHoursStart: "06:00:00",
      WorkHoursEnd: "18:00:00",
      ExcludeWeekends: true
    };
  }

  monthNumberToString(value: number): string {
    const date = new Date();
    date.setMonth(value - 1);
    return date.toLocaleString('en-US', { month: 'long' });
  }
  // #endregion model generator
}

export default FlowNodeView;
</script>
