<template>
  <div ng-non-bindable v-if="isMounted" class="fullWidthHeight basicChart">
    <highcharts ref="chartElement" class="fullWidthHeight" :options="chartOptions"></highcharts>
    <Button 
      v-if="isZoomed" 
      @click="resetZoom" 
      icon="pi pi-search-minus text-md font-bold" 
      v-tippy="'Reset Zoom'" 
      class="p-button-icon-only p-button-rounded p-button-light chart-zoom-out old-dashboard"/>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import AngularOptions from '../../models/AngularOptions';
import ChartDataResponse from '../../models/ChartDataResponse';
import ChartRequstBody from '../../models/ChartRequstBody';
import StreamOption from '../../models/dashboard/StreamOption';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import moreModule from 'highcharts/highcharts-more';
import DateHelper from '@/helpers/DateHelper';
moreModule(Highcharts);
import ColorHelper from '../../helpers/ColorHelper';
import Button from 'primevue/button';

@Component({

  components: {
    highcharts: Chart,
    Button
  }
})
class ColumnRangeWidget extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  chartData: ChartDataResponse[] = [];
  streamColors: string[] = [];
  categories: string[] = [];
  dates: [Date, Date] | null = null;
  series: Highcharts.SeriesOptionsType[] = [];

  isMounted = false;

  resetZoom(): void {
    const chart = this.getChartElement()
    if (chart && chart.chart) {
      chart.chart.zoomOut();
    }
  }

  isZoomed = false;

  get chartOptions(): Highcharts.Options {
    const units = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetUnit as string;
    const decimals = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.decimals as number;
    const categories = this.categories;
    const dates = this.dates;
    const series = this.series;
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "columnrange",
        animation: true,
        zooming: {
          type: 'y'
        },
        inverted: true,
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          },
          selection: (event: Highcharts.SelectEventObject): (boolean | undefined) => {
            if (event.resetSelection) {
              this.isZoomed = false;
            } else {
              this.isZoomed = true;
            }
            return true;
          }
        }
      },
      plotOptions: {
        columnrange: {
          cursor: 'pointer',
          borderRadius: 3,
          minPointLength: 3,
          dataLabels: {
            enabled: true,
            formatter: function () {
              return `<span >${this.y ? this.y.toFixed(decimals) : '0'} ${units}</span>`;
            }
          },
          showInLegend: true
        }
      },
      tooltip: {
        formatter: function() {
          const point = this.point as any;
          let datesStr = '';
          if (dates && dates.length > 1) {
            const startDate = dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000);
            const endDate = dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000);
            datesStr = `${Highcharts.dateFormat('%b %e,%Y %H:%M', startDate)}-${Highcharts.dateFormat('%b %e,%Y %H:%M', endDate)}</small><br/>`;
          }
          return `${datesStr}<span style="color:${this.color};">\u25CF </span><b>${this.x}</b><br/>Min: ${point.low.toFixed(decimals)} ${units}<br/>Max: ${point.high.toFixed(decimals)} ${units}<br/>Avg: ${point.custom.avg.toFixed(decimals)} ${units}`;
        },
        useHTML: true,
        outside: true
      },
      xAxis: {
        type: 'category',
        title: {
          text: ''
        },
        categories: categories
      },
      yAxis: {
        type: 'linear',
        title: {
          text: units
        },
      },
      legend: {
        enabled: false
      },
      series: series
    }
  }

  created(): void {
    if (this.angularOptions && this.angularOptions.angularScope) {
      if (this.angularOptions.angularScope.vueData && this.angularOptions.angularScope.vueRequestBody) {
        this.dataUpdate(
          this.angularOptions.angularScope.vueData as ChartDataResponse[],
          this.angularOptions.angularScope.vueRequestBody as ChartRequstBody);
      }
      if (this.angularOptions.angularScope.subscribeToDataUpdate) {
        this.angularOptions.angularScope.subscribeToDataUpdate(this.dataUpdate);
      }
    }
  }

  mounted(): void {
    this.isMounted = true;
    if (this.angularOptions && this.angularOptions.angularScope && this.angularOptions.angularScope.subscribeToWidgetResize) {
      this.angularOptions.angularScope.subscribeToWidgetResize(this.resizeWidgetEvent);
    }
  }

  loadConfig(): void {
    if (this.angularOptions && 
      this.angularOptions.angularScope &&
      this.angularOptions.angularScope.Widget &&
      this.angularOptions.angularScope.Widget.config &&
      this.angularOptions.angularScope.Widget.config.widgetOptions &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions) {
      const streams = this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions as StreamOption[];
      this.categories = [];
      this.streamColors = [];
      streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.categories.push(name);
        if (stream.hexStreamColor) {
          this.streamColors.push(stream.hexStreamColor);
        } else {
          if (this.angularOptions.angularScope.theme &&
              this.angularOptions.angularScope.Widget &&
              this.angularOptions.angularScope.Widget.config &&
              this.angularOptions.angularScope.Widget.config.widgetOptions &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor) {
            const theme = this.angularOptions.angularScope.theme[this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor]
            this.streamColors.push(theme.colors[stream.streamColor]);
          } else {
            this.streamColors.push(ColorHelper.random());
          }
        }
      });
    }
  }

  dataUpdate(data: ChartDataResponse[], requestBody: ChartRequstBody): void {
    this.chartData = data;
    this.loadConfig();
    this.dates = DateHelper.extractDateFromRequestBody(requestBody);
    let i = 0;
    const result: Array<Highcharts.PointOptionsObject> = [];
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        // angular should show error message to user
        //console.error(streamData.Error);
      } else if (streamData.Data && streamData.Data.length) {
        let minValue: number | undefined = undefined;
        let maxValue: number | undefined = undefined;
        let avg = 0;
        let actualCount = 0;
        streamData.Data.forEach((record) => {
          if (record.y !== null) {
            avg += record.y;
            actualCount++;
            if (minValue === undefined || maxValue === undefined) {
              minValue = record.y;
              maxValue = record.y;
            } else {
              if (minValue > record.y) {
                minValue = record.y;
              }
              if (maxValue < record.y) {
                maxValue = record.y;
              }
            }
          }
        });
        if (minValue !== undefined) {
          result.push({
            low: minValue,
            high: maxValue,
            y: i,
            color: this.streamColors.length > i ? this.streamColors[i] : '',
            custom: { avg: avg / (actualCount ? actualCount : 1) }
          });
        }
      }
      i++;
    });
    this.series = [{
      dataLabels: {
        enabled: true
      },
      type: 'columnrange',
      data: result
    }];
    if (!result.length && this.angularOptions.angularScope.showNoDataMessage) {
      this.angularOptions.angularScope.showNoDataMessage();
    } else {
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  // chart reflow on widget resize
  resizeWidgetEvent(): void {
    const chartElement = this.getChartElement();
    if (chartElement) {
      chartElement.chart.reflow();
    }
  }
}

export default ColumnRangeWidget;
</script>

<style lang="scss" scoped>
  .basicChart {
    display: flex;
    flex-direction: column;
  }
</style>