import ErrorHelper from "@/helpers/ErrorHelper";
import { HaystackSimpleObject } from "@/models/HaystackSimpleObject";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import HaystackState from "../states/HaystackState";

const getters = <GetterTree<HaystackState, any>>{};

const mutations = <MutationTree<HaystackState>>{};

const actions = <ActionTree<HaystackState, any>>{
  async load({ state }) {
    try {
      state.isLoaded = false;
      const url = `rest/Haystack/Nav?allowInternalTags=true`;
      const response = await axios.get<Record<string, string>[]>(url);
      state.objects["root"] = response.data;
      const simpleObjects: HaystackSimpleObject[] = [];
      response.data.forEach((element) => {
        simpleObjects.push({
          Key: element.key,
          Name: element.dis ?? "",
        });
      });
      state.sites = simpleObjects.sort(
        (a: HaystackSimpleObject, b: HaystackSimpleObject) => {
          if (a.Name < b.Name) {
            return -1;
          }
          if (a.Name > b.Name) {
            return 1;
          }
          return 0;
        }
      );
      state.isLoaded = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load haystack objects",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.objects = {};
      state.sites = [];
      state.isLoaded = true;
    }
  },
};

const HaystackModule = {
  namespaced: true,
  state: new HaystackState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default HaystackModule;
