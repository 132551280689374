<template>
   <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 22"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.4" d="M12.098 21H7.902m8.392-13.684A6.327 6.327 0 0 0 14.45 2.85 6.283 6.283 0 0 0 10 1c-1.669 0-3.27.665-4.45 1.85a6.327 6.327 0 0 0-1.843 4.466c0 3.253-.818 5.48-1.732 6.953C1.205 15.51.82 16.133.834 16.306c.016.192.056.265.21.38.14.104.767.104 2.022.104h13.869c1.255 0 1.882 0 2.021-.104.154-.115.195-.188.21-.38.015-.173-.37-.795-1.141-2.037-.914-1.473-1.731-3.7-1.731-6.953Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class BellWithoutShadowSvg extends Vue {
}

export default BellWithoutShadowSvg;
</script>