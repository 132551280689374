<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13 16.604a3.6 3.6 0 1 0 0-7.2 3.6 3.6 0 0 0 0 7.2Z"/><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M21.073 16.277a1.8 1.8 0 0 0 .36 1.985l.065.066a2.183 2.183 0 1 1-3.087 3.087l-.066-.066a1.8 1.8 0 0 0-1.985-.36 1.8 1.8 0 0 0-1.09 1.648v.185a2.182 2.182 0 0 1-4.364 0v-.098a1.8 1.8 0 0 0-1.179-1.647 1.8 1.8 0 0 0-1.985.36l-.066.065a2.183 2.183 0 1 1-3.087-3.087l.066-.066a1.8 1.8 0 0 0 .36-1.985 1.8 1.8 0 0 0-1.648-1.091h-.185a2.182 2.182 0 0 1 0-4.364h.098a1.8 1.8 0 0 0 1.647-1.178 1.8 1.8 0 0 0-.36-1.985l-.065-.066a2.182 2.182 0 1 1 3.087-3.087l.066.065a1.8 1.8 0 0 0 1.985.36h.087a1.8 1.8 0 0 0 1.091-1.647v-.185a2.182 2.182 0 0 1 4.364 0v.098a1.8 1.8 0 0 0 1.09 1.647 1.8 1.8 0 0 0 1.986-.36l.066-.065a2.183 2.183 0 1 1 3.087 3.087l-.066.065a1.8 1.8 0 0 0-.36 1.986v.087a1.8 1.8 0 0 0 1.648 1.091h.185a2.182 2.182 0 0 1 0 4.364h-.098a1.8 1.8 0 0 0-1.647 1.09Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class CogwheelSvg extends Vue {
}

export default CogwheelSvg;
</script>