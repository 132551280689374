import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { Report2CollectionModel } from "@/models/reports/Report2CollectionModel";
import ReportsCollectionsState from "./states/ReportsCollectionsState";

export const useReportsCollectionsStore = defineStore('reportsCollections', {
  state: (): ReportsCollectionsState => ({ 
    isLoaded: false,
    guid: "",
    entities: null
  }),
  getters: {
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.entities = null;
        const url = `rest/Reports_V2/Collection`;
        const response = await axios.get<Report2CollectionModel[]>(url);
        if (this.guid === guid) {
          this.entities = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load reports collections",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
      }
    }
  },
})
