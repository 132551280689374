import { Space } from "@/models/dashboard/Space";
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import { SpaceWidgetResponsive } from "@/models/dashboard/SpaceWidgetResponsive";
import { v4 as uuidv4 } from "uuid";

class DashboardMigrationHelper {
  gridSize = 12;

  buildSizeFromMap(sizeXL: number): SpaceWidgetResponsive {
    switch (sizeXL) {
      case 1: {
        return {
          size: 12,
          sizeSM: 7,
          sizeMD: 5,
          sizeLG: 3,
          sizeXL: sizeXL
        };
      }
      case 2: {
        return {
          size: 12,
          sizeSM: 8,
          sizeMD: 6,
          sizeLG: 4,
          sizeXL: sizeXL
        };
      }
      case 3: {
        return {
          size: 12,
          sizeSM: 9,
          sizeMD: 7,
          sizeLG: 5,
          sizeXL: sizeXL
        };
      }
      case 4: {
        return {
          size: 12,
          sizeSM: 12,
          sizeMD: 8,
          sizeLG: 6,
          sizeXL: sizeXL
        };
      }
      case 5: {
        return {
          size: 12,
          sizeSM: 12,
          sizeMD: 9,
          sizeLG: 7,
          sizeXL: sizeXL
        };
      }
      case 6: {
        return {
          size: 12,
          sizeSM: 12,
          sizeMD: 10,
          sizeLG: 8,
          sizeXL: sizeXL
        };
      }
      case 7: {
        return {
          size: 12,
          sizeSM: 12,
          sizeMD: 11,
          sizeLG: 9,
          sizeXL: sizeXL
        };
      }
      case 8: {
        return {
          size: 12,
          sizeSM: 12,
          sizeMD: 12,
          sizeLG: 10,
          sizeXL: sizeXL
        };
      }
      case 9: {
        return {
          size: 12,
          sizeSM: 12,
          sizeMD: 12,
          sizeLG: 12,
          sizeXL: sizeXL
        };
      }
      case 10: {
        return {
          size: 12,
          sizeSM: 12,
          sizeMD: 12,
          sizeLG: 12,
          sizeXL: sizeXL
        };
      }
      case 11: {
        return {
          size: 12,
          sizeSM: 12,
          sizeMD: 12,
          sizeLG: 12,
          sizeXL: sizeXL
        };
      }
      default:
      case 12: {
        return {
          size: 12,
          sizeSM: 12,
          sizeMD: 12,
          sizeLG: 12,
          sizeXL: 12
        };
      }
    }
  }
  
  mergeFunction(line: SpaceWidgetConfig[], row: SpaceWidgetConfig, nextRow: SpaceWidgetConfig) {
    if (nextRow && nextRow.widgets) {
      const sizeX = line.length ? line.map(x => x.widgetSize[0]).reduce((a, b) => { return a + b; }) : 0;
      const sizeY = line.length ? line.map(x => x.widgetSize[1]).reduce((a, b) => { return a > b ? a : b; }) : 0;
      const rowBottom: SpaceWidgetConfig = {
        guid: uuidv4(),
        type: "grid",
        size: this.buildSizeFromMap(this.gridSize),
        widgets: line,
        widgetLoc: line[0].widgetLoc,
        widgetSize: [
          sizeX,
          sizeY
        ]
      };
      const nextRowLine: SpaceWidgetConfig[] = [];
      let previousWidget: SpaceWidgetConfig | null = null;
      let insertIndex = -1;
      for (let g = 0; g < nextRow.widgets.length; g++) {
        const nextRowWidget = nextRow.widgets[g];
        let insertIndexAfter = 0;
        if (nextRowWidget.widgetLoc[0] >= rowBottom.widgetLoc[0] &&
          nextRowWidget.widgetLoc[0] + nextRowWidget.widgetSize[0] <= rowBottom.widgetLoc[0] + rowBottom.widgetSize[0]) {
          nextRowLine.push(nextRowWidget);
        } else {
          let holeSize = -1;
          let holeStart = -1;
          if (previousWidget) {
            // check for hole
            if (previousWidget.widgetLoc[0] + previousWidget.widgetSize[0] !== nextRowWidget.widgetLoc[0]) {
              // hole!
              holeSize = nextRowWidget.widgetLoc[0] - (previousWidget.widgetLoc[0] + previousWidget.widgetSize[0]);
              holeStart = previousWidget.widgetLoc[0] + previousWidget.widgetSize[0];
            }
          } else {
            // possible hole from 0, let's check
            if (nextRowWidget.widgetLoc[0] !== 0) {
              // hole!
              holeSize = nextRowWidget.widgetLoc[0];
              holeStart = 0;
            }
          }
          if (holeStart < 0 && g === nextRow.widgets.length - 1) {
            // last widget
            if (nextRowWidget.widgetLoc[0] + nextRowWidget.widgetSize[0] !== this.gridSize) {
              // hole in the end!
              holeStart = nextRowWidget.widgetLoc[0] + nextRowWidget.widgetSize[0];
              holeSize = this.gridSize - holeStart;
              insertIndexAfter++;
            }
          }
          if (holeStart >= 0 && holeStart >= rowBottom.widgetLoc[0] &&
            holeStart + holeSize <= rowBottom.widgetLoc[0] + rowBottom.widgetSize[0]) {
            // push empty line
            const emptyLineSize = rowBottom.widgetSize[0];
            nextRowLine.push({
              guid: uuidv4(),
              type: "grid",
              size: this.buildSizeFromMap(emptyLineSize),
              widgets: [],
              widgetLoc: [holeStart, nextRowWidget.widgetLoc[1]],
              widgetSize: [
                emptyLineSize,
                1
              ]
            });
          }
        }
        previousWidget = nextRowWidget;
        if (insertIndex < 0 && nextRowLine.length) {
          insertIndex = g + insertIndexAfter;
        }
      }
      const nextRowSizeX = nextRowLine.length ? nextRowLine.map(x => x.widgetSize[0]).reduce((a, b) => { return a + b; }) : 0;
      const nextRowSizeY = nextRowLine.length ? nextRowLine.map(x => x.widgetSize[1]).reduce((a, b) => { return a > b ? a : b; }) : 0;
      if (nextRowSizeX >=sizeX) {
        // merge!
        const rowTop: SpaceWidgetConfig = {
          guid: uuidv4(),
          type: "grid",
          size: this.buildSizeFromMap(this.gridSize),
          widgets: nextRowLine,
          widgetLoc: nextRowLine[0].widgetLoc,
          widgetSize: [
            nextRowSizeX,
            nextRowSizeY
          ]
        };
        // edit widgets size inside rowTop, rowBottom
        rowTop.widgets?.forEach(widget => {
          const newSizeX = Math.round(this.gridSize * widget.widgetSize[0] / nextRowSizeX);
          widget.size = this.buildSizeFromMap(newSizeX);
        });
        rowBottom.widgets?.forEach(widget => {
          const newSizeX = Math.round(this.gridSize * widget.widgetSize[0] / sizeX);
          widget.size = this.buildSizeFromMap(newSizeX);
        });
        const rowMerged: SpaceWidgetConfig = {
          guid: uuidv4(),
          type: "grid",
          size: this.buildSizeFromMap(sizeX > nextRowSizeX ? sizeX : nextRowSizeX),
          widgets: [rowTop, rowBottom],
          widgetLoc: nextRowLine[0].widgetLoc,
          widgetSize: [
            sizeX > nextRowSizeX ? sizeX : nextRowSizeX,
            sizeY + nextRowSizeY
          ]
        };
        // remove widgets from rows, push rowMerged to top row
        if (rowTop.widgets && rowTop.widgets.length) {
          for (let i = rowTop.widgets.length - 1; i >= 0; i--) {
            const widget = rowTop.widgets[i]; 
            if (nextRow.widgets) {
              const index = nextRow.widgets.findIndex(x => x.guid === widget.guid);
              if (index > -1) {
                nextRow.widgets.splice(index, 1);
              }
            }
          }
          nextRow.widgets.splice(insertIndex, 0, rowMerged);
        }
        if (rowBottom.widgets && rowBottom.widgets.length) {
          for (let i = rowBottom.widgets.length - 1; i >= 0; i--) {
            const widget = rowBottom.widgets[i]; 
            if (row.widgets) {
              const index = row.widgets.findIndex(x => x.guid === widget.guid);
              if (index > -1) {
                row.widgets.splice(index, 1);
              }
            }
          }
        }
      }
    }
  }

  buildGrid(dashboard: Space, widgetsMap: string[][]): SpaceWidgetConfig[] {
    const fromX = 0;
    const fromY = 0;
    const usedWidgets: string[] = [];
    const grid: SpaceWidgetConfig[] = [];
    for (let y = fromY; y < this.gridSize; y++) {
      const row: SpaceWidgetConfig[] = [];
      for (let x = fromX; x < this.gridSize; x++) {
        if (widgetsMap[x][y]) {
          const currentWidget = dashboard.widgets.find(widget => widget.guid === widgetsMap[x][y]);
          if (currentWidget && !usedWidgets.find(guid => guid === currentWidget.guid)) {
            usedWidgets.push(currentWidget.guid);
            currentWidget.type = "widget";
            currentWidget.size = this.buildSizeFromMap(currentWidget.widgetSize[0]);
            row.push(currentWidget);
          }
        } else {
          // fill empty space with empty grid
          if (row.length && row[row.length - 1].type === "grid") {
            // increase empty grid size
            const emptyGrid = row[row.length - 1];
            emptyGrid.widgetSize[0]++;
            emptyGrid.size = this.buildSizeFromMap(emptyGrid.widgetSize[0])
          } else {
            // create empty grid
            row.push({
              guid: uuidv4(),
              type: "grid",
              size: this.buildSizeFromMap(1),
              widgets: [],
              widgetLoc: [x, y],
              widgetSize: [1, 1]
            });
          }
        }
      }
      if (row.length === 1 && row[0].type === "grid") {
        row.splice(0, 1);
      } 
      if (row.length) {
        grid.push({
          guid: uuidv4(),
          type: "grid",
          size: this.buildSizeFromMap(this.gridSize),
          widgets: row,
          widgetLoc: [],
          widgetSize: []
        });
      }
    }
    return grid;
  }

  defragmentation(migratedWidgets: SpaceWidgetConfig[]): void {
    if (migratedWidgets.length > 1) {
      for (let i = migratedWidgets.length - 1; i > 0; i--) {
        // if row contain holes, look up and try to merge
        const row = migratedWidgets[i];
        if (row.widgets) {
          let rowSize = 0;
          row.widgets.forEach(widget => {
            rowSize += widget.size.sizeXL;
          });
          if (rowSize < this.gridSize) {
            // let's try to merge
            const nextRow = migratedWidgets[i - 1];
            if (nextRow && nextRow.widgets) {
              let line: SpaceWidgetConfig[] = [];
              for (let j = 0; j < row.widgets.length; j++) {
                const widget = row.widgets[j];
                if (!line.length) {
                  line.push(widget);
                } else {
                  const lastInLine = line[line.length - 1];
                  if (lastInLine.widgetLoc[0] + lastInLine.widgetSize[0] === widget.widgetLoc[0]) {
                    line.push(widget);
                  } else {
                    // merge
                    this.mergeFunction(line, row, nextRow);
                    // refill line
                    line = [widget];
                  }
                }
              }
              if (line.length) {
                // merge
                this.mergeFunction(line, row, nextRow);
              }
            }
          }
          // remove empty row
          if (!row.widgets.length) {
            migratedWidgets.splice(i, 1);
          }
        }
      }
    }
  }

  containsWidget(item: SpaceWidgetConfig): boolean {
    let result = false;
    if (item.type === "widget"){
      result = true;
    } else if (item.type === "grid") {
      if (item.widgets  && item.widgets.length) {
        for (let i = 0; i < item.widgets.length; i++) {
          const contains = this.containsWidget(item.widgets[i]);
          result ||= contains;
        }
      }
      if (!result) {
        item.widgets = [];
      }
    }
    return result;
  }

  removeRedundantEmptyGrids(migratedWidgets: SpaceWidgetConfig[]): void {
    migratedWidgets.forEach(grid => {
      if (grid.type === "grid" && grid.widgets && grid.widgets.length) {
        for (let i = 0; i < grid.widgets.length; i++) { 
          const item = grid.widgets[i];
          if (item.widgets && item.widgets.length && !this.containsWidget(item)) {
            item.widgets = [];
          }
        }
      }
    });
  }

  removeGridsWithFullSizeChild(migratedWidgets: SpaceWidgetConfig[]): void {
    migratedWidgets.forEach(grid => {
      if (grid.type === "grid" && grid.widgets && grid.widgets.length) {
        grid.widgets = this.removeGridsWithFullSizeChildInternal(grid.widgets);
      }
    });
  }

  removeGridsWithFullSizeChildInternal(configs: SpaceWidgetConfig[]): SpaceWidgetConfig[] {
    const result: SpaceWidgetConfig[] = [];
    configs.forEach((config, index) => {
      if (config.type === "grid" && config.widgets && config.widgets.length) {
        let isAll12 = config.size.sizeXL === 12;
        if (isAll12) {
          for (let i = 0; i < config.widgets.length; i++) {
            const current = config.widgets[i];
            if (current.size.sizeXL !== 12) {
              isAll12 = false;
              break;
            }
          }
        }
        if (isAll12) {
          for (let i = 0; i < config.widgets.length; i++) {
            const current = config.widgets[i];
            current.size = config.size;
            if (current.widgets) {
              current.widgets = this.removeGridsWithFullSizeChildInternal(current.widgets);
            }
            result.push(current);
          }
        } else if (config.widgets.length === 1 && config.widgets[0].size.sizeXL === 12) {
          const child = config.widgets[0];
          child.size = config.size;
          if (child.type === "grid" && child.widgets) {
            child.widgets = this.removeGridsWithFullSizeChildInternal(child.widgets);
          }
          result.push(child);
        } else {
          config.widgets = this.removeGridsWithFullSizeChildInternal(config.widgets);
          result.push(config);
        }
      } else {
        result.push(config);
      }
    });
    return result;
  }

  migrate(dashboard: Space): void {
    let migratedWidgets: SpaceWidgetConfig[] | undefined;
    const dashboardAny = dashboard as any;
    if (dashboardAny.spaces) {
      dashboardAny.spaces = undefined;
    }
    dashboardAny.widgetsBackup = JSON.stringify(dashboardAny.widgets);
    if (dashboard.widgets) {
      // map of guid
      const widgetsMap: string[][] = Array(this.gridSize).fill("").map(x => Array(this.gridSize).fill(""));
      const overlappedSet: Set<string> = new Set<string>();
      dashboard.widgets.forEach((widget, index) => {
        // fill widgetsMap
        if (widget.widgetLoc[0] > 11 || widget.widgetLoc[1] > 11) {
          // widget outside of 12x12 field
          overlappedSet.add(widget.guid);
        } else {
          // find widgets with equal coordinates keep last
          let isSameCoord = false;
          if (index < dashboard.widgets.length - 1) {
            for (let i = index + 1; i < dashboard.widgets.length - 1; i++) {
              const nextWidget = dashboard.widgets[i];
              if (nextWidget.widgetLoc[0] === widget.widgetLoc[0] && nextWidget.widgetLoc[1] === widget.widgetLoc[1]) {
                overlappedSet.add(widget.guid);
                isSameCoord = true;
                break;
              }
            }
          }
          if (!isSameCoord) {
            // fill map
            for (let x = 0; x < widget.widgetSize[0]; x++){
              const mapX = widget.widgetLoc[0] + x;
              if (mapX > 11) {
                break;
              }
              for (let y = 0; y < widget.widgetSize[1]; y++) {
                const mapY = widget.widgetLoc[1] + y;
                if (mapY > 11) {
                  break;
                }
                if (widgetsMap[mapX][mapY]) {
                  // overlapped widgets -> invalid situation -> move to the end
                  overlappedSet.add(widget.guid);
                } else {
                  widgetsMap[mapX][mapY] = widget.guid;
                }
              }
            }
          }
        }
      });
      const overlapped = Array.from(overlappedSet);
      // remove parts of overlapped widgets
      for (let x = 0; x < this.gridSize; x++) {
        for (let y = 0; y < this.gridSize; y++) {
          if (overlapped.find(guid => guid === widgetsMap[x][y])) {
            widgetsMap[x][y] = "";
          }
        }
      }
      // build new widgets array
      migratedWidgets = this.buildGrid(dashboard, widgetsMap);
      // defragmentation
      this.defragmentation(migratedWidgets);
      // defragmentation again, because sometimes 1 is not enough
      this.defragmentation(migratedWidgets);
      // remove redundant empty grids
      this.removeRedundantEmptyGrids(migratedWidgets);
      // remove grids with full size child
      this.removeGridsWithFullSizeChild(migratedWidgets);
      this.removeGridsWithFullSizeChild(migratedWidgets);
      this.removeGridsWithFullSizeChild(migratedWidgets);
      // add overlapped widgets as new row
      if (overlapped.length) {
        const overlappedWidgets = overlapped.map(guid => dashboard.widgets.find(x => x.guid === guid)) as SpaceWidgetConfig[];
        const sizeX = overlappedWidgets.length ? overlappedWidgets.map(x => x.widgetSize[0]).reduce((a, b) => { return a + b; }) : 0;
        const sizeY = overlappedWidgets.length ? overlappedWidgets.map(x => x.widgetSize[1]).reduce((a, b) => { return a > b ? a : b; }) : 0;
        overlappedWidgets.forEach(widget => {
          widget.type = "widget";
          widget.size = this.buildSizeFromMap(widget.widgetSize[0]);
        });
        const overlappedRow: SpaceWidgetConfig = {
          guid: uuidv4(),
          type: "grid",
          size: this.buildSizeFromMap(this.gridSize),
          widgets: overlappedWidgets,
          widgetLoc: [13, 0],
          widgetSize: [
            sizeX,
            sizeY
          ]
        };
        migratedWidgets.push(overlappedRow);
      }
    }
    dashboard.widgets = migratedWidgets ? migratedWidgets : [];
    dashboard.version = "vue_001";
  }
}

export default new DashboardMigrationHelper();
