<template>
  <div class="findNrParent find-replace-container">
    <div class="formgrid grid sm:flex-nowrap flex-shrink-0">
      <div class="field col lg:col-6 mt-0">
        <label for="SourceTypeSelector">Select source</label>
        <div>
          <Dropdown
            v-model="sourceType"
            :options="findSourceTypes"
            optionValue="id"
            optionLabel="name"
            placeholder="Select source type"
            class="w-full sourceTypeSelector"
            id="SourceTypeSelector"
            @change="sourceTypeChange()" />
        </div>
      </div>
      <div class="field col-12 sm:col-fixed sm:mt-0 sm:align-self-end sm:w-auto" v-if="sourceType == 0">
        <div class="flex align-items-center pb-1">
          <InputSwitch v-model="updateLabels" inputId="updateLabels" class="vertical-align-top" />
          <label for="updateLabels" class="mb-0 ml-2">Update Labels</label>
          <span
            v-tippy="updateLabelsToolTip"
            class="p-button p-component p-button-icon-only p-button-rounded p-button-text border-none w-auto h-auto ml-2">
            <i class="pi pi-info-circle text-2xl"></i>
          </span>
        </div>
      </div>
    </div>

    <div class="formgrid grid lg:flex-nowrap listBoxParent flex-auto">
      <div class="field col-12 lg:col-6 lg:flex findList find-list-container">
        <div class="lg:flex flex-column lg:w-full lg:h-full">
          <h3 class="hidden sm:block flex-shrink-0">Find</h3>
          <Listbox
            v-model="selectedItems"
            ref="findList"
            :options="items"
            optionValue="id"
            optionLabel="name"
            class="w-full lg:flex-auto"
            :multiple="sourceType == 0 ? true : false"
            filter
            filterPlaceholder="Find"
            @filter="sourceType !== 0 ? debounceDoSearch($event) : updateFoundItems($event)">
            <template #header>
              <div class="searchToggleButtonDiv">
                <h3 class="block sm:hidden mb-0 mr-auto">Find</h3>
                <Button
                  @click="toggleMatchCase()"
                  label="Aa"
                  v-tippy="matchCaseTooltip"
                  :class="toMatchCase ? 'matchCaseButton toggledOn' : 'matchCaseButton p-button-light-primary'" />

                <Button
                  @click="toggleMatchWholeWord()"
                  label="ab"
                  v-tippy="matchWholeWordTooltip"
                  :class="
                    toMatchWholeWord ? 'matchWholeWordButton toggledOn' : 'matchWholeWordButton p-button-light-primary'
                  " />
                <span class="foundSearchItemsCount pl-2"> {{ foundItems }} / {{ totalItems }}</span>
              </div>
            </template>
            <template #option="slotProps">
              <div v-if="sourceType == 0" class="find-list-option">
                <span class="find-list-option-name" v-html="findMarkedText(slotProps.option)"></span>
                <span class="optionMeta find-list-option-meta">
                  <span class="block md:inline lg:block xl:inline">
                    <b>Widget Type:</b> {{ slotProps.option.widgetType }}
                  </span>
                  <i class="hidden md:inline lg:hidden xl:inline px-2">|</i>
                  <span class="block md:inline lg:block xl:inline">
                    <b>Widget Title:</b> {{ slotProps.option.widgetTitle }}
                  </span>
                </span>
              </div>
              <div v-if="sourceType == 1" class="find-list-option">
                <span class="find-list-option-name" v-html="findMarkedText(slotProps.option)"></span>
                <span class="optionMeta find-list-option-meta">
                  <span class="block md:inline lg:block xl:inline">
                    <b>Widget Type:</b> {{ slotProps.option.widgetType }}
                  </span>
                  <i class="hidden md:inline lg:hidden xl:inline px-2">|</i>
                  <span class="block md:inline lg:block xl:inline">
                    <b>Widget Title:</b> {{ slotProps.option.widgetTitle }}
                  </span>
                </span>
              </div>
              <div v-if="sourceType == 2" class="find-list-option">
                <span class="find-list-option-name" v-html="findMarkedText(slotProps.option)"></span>
                <span class="optionMeta find-list-option-meta">
                  <span class="block md:inline lg:block xl:inline">
                    <b>Widget Type:</b> {{ slotProps.option.widgetType }}
                  </span>
                </span>
              </div>
            </template>
          </Listbox>
        </div>
      </div>

      <div class="field col-12 lg:col-6 lg:flex replaceList replace-list-container">
        <div class="lg:flex flex-column lg:w-full lg:h-full">
          <h3 class="hidden sm:block flex-shrink-0" v-if="sourceType == 0">Replace with</h3>
          <h3 class="flex-shrink-0" v-else>Replace with</h3>

          <div
            class="flex justify-content-between align-items-center mb-3 sm:mb-0 structuredDataSwitch"
            v-if="sourceType == 0">
            <h3 class="block sm:hidden mb-0 mr-auto">Replace with</h3>
            <div class="flex align-items-center" v-if="navTreeState.isLoaded && navTreeState.unstructuredIsLoaded">
              <InputSwitch v-model="structuredView" inputId="structuredView" class="vertical-align-top" />
              <label for="structuredView" class="mb-0 ml-2">Structured Data</label>
            </div>
          </div>

          <IconField iconPosition="left" class="inputDiv w-full replace-list-input" v-if="sourceType !== 0">
            <InputIcon class="pi pi-arrow-right"></InputIcon>
            <InputText
              v-model="replaceField"
              class="inputReplacefield w-full"
              placeholder="Replace with"
              type="text"
              @input="debounceDoReplace()" />
          </IconField>

          <!-- Structured/not-structured switch -->
          <div v-else-if="sourceType == 0" class="relative lg:h-full">
            <div v-if="structuredView" class="treeContainer">
              <TreeSelectViewNoDropDown
                v-if="navTreeState.isLoaded && !isCollapsed"
                :selectedId="selectedStreamKey"
                :nodes="navTreeState.structuredDataForUI"
                :changeSelectedId="streamFromTreeSelected"
                placeholder="Select Stream"
                id="structuredData" />
              <div class="replace-list-loader" v-else>
                <ProgressSpinner
                  class="spinner-primary"
                  style="width: 60px; height: 60px"
                  strokeWidth="3"
                  animationDuration="1s" />
              </div>
            </div>
            <div v-else class="treeContainer">
              <TreeSelectViewNoDropDown
                v-if="navTreeState.unstructuredIsLoaded && !isCollapsed"
                :selectedId="selectedStreamKey"
                :nodes="navTreeState.unstructuredDataForUI"
                :changeSelectedId="streamFromTreeSelected"
                placeholder="Select Stream"
                id="unstructuredData" />
              <div class="replace-list-loader" v-else>
                <ProgressSpinner
                  class="spinner-primary"
                  style="width: 60px; height: 60px"
                  strokeWidth="3"
                  animationDuration="1s" />
              </div>
            </div>
          </div>

          <Listbox
            v-if="sourceType !== 0"
            v-model="replacedItems"
            ref="replaceList"
            :options="replacedItemsList"
            optionValue="id"
            optionLabel="name"
            emptyMessage="Search for items to be replaced"
            class="w-full lg:flex-auto">
            <template #option="slotProps">
              <div v-if="sourceType == 1" class="replace-list-option">
                <span class="replace-list-option-name" v-html="replaceMarkedText(slotProps.option)"></span>
                <span class="optionMeta replace-list-option-meta">
                  <span class="block md:inline lg:block xl:inline"
                    ><b>Widget Type:</b> {{ slotProps.option.widgetType }}</span
                  >
                  <i class="hidden md:inline lg:hidden xl:inline px-2">|</i>
                  <span class="block md:inline lg:block xl:inline"
                    ><b>Widget Title:</b> {{ slotProps.option.widgetTitle }}</span
                  >
                </span>
              </div>
              <div v-if="sourceType == 2" class="replace-list-option">
                <span class="replace-list-option-name" v-html="replaceMarkedText(slotProps.option)"></span>
                <span class="optionMeta replace-list-option-meta">
                  <span class="block md:inline lg:block xl:inline"
                    ><b>Widget Type:</b> {{ slotProps.option.widgetType }}</span
                  >
                </span>
              </div>
            </template>
            <template #empty>
              <div class="replace-list-empty h-full relative">
                <div class="flex flex-column justify-content-center align-items-center h-full">
                  <div class="replace-list-empty-img">
                    <img :src="'/assets/findreplace-empty-replace-state.svg'" width="218" alt="Bitpool" />
                  </div>
                  <div class="flex-shrink-0">
                    <h4 class="mb-1 mt-4">No options available</h4>
                    <p class="my-0">Start typing to find items that you want to replace with.</p>
                  </div>
                </div>
              </div>
            </template>
          </Listbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Ref } from "vue-facing-decorator";
import DashboardState from "@/store/states/DashboardState";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import InputSwitch from "primevue/inputswitch";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import TreeSelectView from "@/components/views/TreeSelectView.vue";
import TreeSelectViewNoDropDown from "@/components/views/TreeSelectViewNoDropDown.vue";
import ProgressSpinner from "primevue/progressspinner";
import { useNavTreeStore } from "@/stores/navTree";
import NavTreeState from "@/stores/states/NavTreeState";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import Listbox from "primevue/listbox";
import { debounce } from "throttle-debounce";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { FindnReplaceListItem } from "@/models/dashboard/FindnReplaceListItem";
import { FindnReplaceChangeItem } from "@/models/dashboard/FindnReplaceChangeItem";
import { FindnReplaceSelectedStream } from "@/models/dashboard/FindnReplaceSelectedStream";
import ToastService from "@/services/ToastService";
import ConfirmationService from "@/services/ConfirmationService";
import StreamOption from "@/models/dashboard/StreamOption";

@Component({
  components: {
    InputText,
    Dropdown,
    Textarea,
    InputSwitch,
    Button,
    Dialog,
    TreeSelectView,
    ProgressSpinner,
    Listbox,
    IconField,
    InputIcon,
    TreeSelectViewNoDropDown,
  },
})
class FindReplaceView extends Vue {
  @Prop({ required: true }) isCollapsed!: boolean;

  displayConfirmation = false;
  toMatchCase = false;
  matchCaseTooltip = "Match Case";
  matchWholeWordTooltip = "Match Whole Word";
  toMatchWholeWord = false;
  sourceType = 0;
  findSourceTypes: object[] = [
    { name: "Data Streams", id: 0 },
    { name: "Stream Labels", id: 1 },
    { name: "Widget Titles", id: 2 },
  ];
  toShowAll = false;
  totalItems?: number | undefined;
  foundItems = 0;
  selectedStreamKey: string | null | undefined = null;
  navTreeStore = useNavTreeStore();
  items: FindnReplaceListItem[] = [{}];
  selectedItems: string[] = [];
  replacedItems: string[]  = [];
  replacedItemsList: FindnReplaceListItem[] | undefined;
  searchField = "";
  replaceField = "";
  debounceDoSearch = debounce(500, this.doSearch);
  debounceDoReplace = debounce(500, this.doReplaceInput);
  structuredView = false;
  updateLabels = true;
  updateLabelsToolTip =
    `Data Stream Labels are automatically updated to the new Data Stream Names. To keep the configured Data Stream Lables in the Widgets, disable this option using the Toggle button`;

  mounted(): void {
    if (!this.navTreeState.isLoaded) {
      this.navTreeStore.load();
    }
    if (!this.navTreeState.unstructuredIsLoaded) {
      this.navTreeStore.loadUnstructured();
    }
    this.sourceTypeChange();
  }

  findMarkedText(item: FindnReplaceListItem): string {
    //Return string with <mark> highlighting found search text
    let result = "";
    if (this.findSearchValue && this.findSearchValue !== "" && item.name) {
      const splitString = item.name.split(this.findSearchValue);
      if (splitString.length > 1) {
        const length = splitString.length - 1;
        splitString.forEach((str: string, index: number) => {
          if (index !== length) {
            result += `${str}<mark>${this.findSearchValue}</mark>`;
          } else if (index == length) {
            result += str;
          }
        });
      } else {
        result = item.name;
      }
    } else if (item.name) {
      result = item.name;
    }
    return result;
  }

  replaceMarkedText(item: FindnReplaceListItem): string {
    //Return string with <mark> highlighting Replace fill text
    let result = "";
    if (this.replaceField && this.replaceField !== "" && item.displayName) {
      const splitString = item.displayName.split(this.replaceField);
      if (splitString.length > 1) {
        const length = splitString.length - 1;
        splitString.forEach((str: string, index: number) => {
          if (index !== length) {
            result += `${str}<mark>${this.replaceField}</mark>`;
          } else if (index == length) {
            result += str;
          }
        });
      } else {
        result = item.displayName;
      }
    } else if (item.displayName) {
      result = item.displayName;
    }
    return result;
  }

  addToReplaceList(item: FindnReplaceListItem): void {
    if (this.sourceType !== 0) {
      if (this.replacedItemsList) {
        const foundIndex = this.replacedItemsList.findIndex((ele) => ele.id == item.id);
        if (foundIndex == -1) {
          this.replacedItemsList.push(item);
        }
      } else {
        this.replacedItemsList = [];
        this.replacedItemsList.push(item);
      }
    }
  }

  removeFromReplaceList(item: FindnReplaceListItem): void {
    if (this.replacedItemsList) {
      const foundIndex = this.replacedItemsList.findIndex((ele) => ele.id == item.id);
      if (foundIndex !== -1) {
        this.replacedItemsList.splice(foundIndex, 1);
      }
    }
  }

  sourceTypeChange(): void {
    this.selectedItems = [];

    if (this.sourceType == 0) {
      //data streams
      this.addStreamsToFindView();
    } else if (this.sourceType == 1) {
      //labels
      this.addContentToFindView(true, false);
    } else if (this.sourceType == 2) {
      //titles
      this.addContentToFindView(false, true);
    }

    this.setTotalItemCount();
    this.replacedItemsList = [];
    this.selectedStreamKey = "";
  }

  setTotalItemCount(): void {
    this.totalItems = this.items.length;
  }

  addStreamsToFindView(): void {
    //add streams to find view
    const result = this.streams;
    this.items = result;
  }

  addContentToFindView(label: boolean, title: boolean): void {
    if (label && !title) {
      //add label to find view
      const result = this.labels;
      this.items = result;
    }
    if (title && !label) {
      //add title to find view
      const result = this.titles;
      this.items = result;
    }
  }

  findSearchValue = "";

  updateFoundItems(filterEvent: any): void {
    const findList = this.$refs.findList as any;
    this.foundItems = findList.visibleOptions.length;
    if (filterEvent) {
      this.findSearchValue = filterEvent.value;
    }
  }

  doSearch(filterEvent: any): void {
    this.updateFoundItems(filterEvent);
    this.replacedItemsList = [];
    const findList = this.$refs.findList as any;
    if (this.findSearchValue !== "" && findList.visibleOptions) {
      if (this.foundItems > 0) {
        findList.visibleOptions.forEach((item: FindnReplaceListItem, index: number) => {
          if (!this.toMatchCase && !this.toMatchWholeWord && item.name) {
            //NOT match case and NOT match whole word, regular search
            const a = item.name.toLowerCase();
            const b = this.findSearchValue.toLowerCase();
            if (a.includes(b)) {
              this.addToReplaceList(item);
            } else {
              this.removeFromReplaceList(item);
            }
          } else if (this.toMatchCase && !this.toMatchWholeWord && item.name) {
            //match case and NOT match whole word
            if (item.name.includes(this.findSearchValue)) {
              this.addToReplaceList(item);
            } else {
              this.removeFromReplaceList(item);
            }
          } else if (!this.toMatchCase && this.toMatchWholeWord && item.name) {
            //NOT match case and match whole word
            const a = item.name.toLowerCase();
            const b = this.findSearchValue.toLowerCase();
            if (a === b) {
              this.addToReplaceList(item);
            } else {
              this.removeFromReplaceList(item);
            }
          } else if (this.toMatchCase && this.toMatchWholeWord && item.name) {
            //match case and match whole word, strictest search
            if (item.name === this.findSearchValue) {
              this.addToReplaceList(item);
            } else {
              this.removeFromReplaceList(item);
            }
          }
        });
      } else if (this.foundItems == 0) {
        this.replacedItemsList = [];
      }
    } else if (this.findSearchValue == "") {
      this.replacedItemsList = [];
    }
  }

  doReplaceInput(): void {
    this.replacedItemsList?.forEach((option: FindnReplaceListItem) => {
      if (this.replaceField !== "" && option.name) {
        if (!this.toMatchCase && !this.toMatchWholeWord) {
          //NOT match case and NOT match whole word, regular search
          const a = option.name.toLowerCase();
          const b = this.findSearchValue.toLowerCase();
          if (a.includes(b)) {
            const name = option.name;
            const regex = new RegExp(this.findSearchValue, "ig");
            option.displayName = name.replaceAll(regex, this.replaceField);
          }
        } else if (this.toMatchCase && !this.toMatchWholeWord) {
          //match case and NOT match whole word
          if (option.name.includes(this.findSearchValue)) {
            const name = option.name;
            const regex = new RegExp(this.findSearchValue, "ig");
            option.displayName = name.replaceAll(regex, this.replaceField);
          }
        } else if (!this.toMatchCase && this.toMatchWholeWord) {
          //NOT match case and match whole word
          const a = option.name.toLowerCase();
          const b = this.findSearchValue.toLowerCase();
          if (a === b) {
            const name = option.name;
            const regex = new RegExp(this.findSearchValue, "ig");
            option.displayName = name.replaceAll(regex, this.replaceField);
          }
        } else if (this.toMatchCase && this.toMatchWholeWord) {
          //match case and match whole word, strictest search
          if (option.name === this.findSearchValue) {
            const name = option.name;
            const regex = new RegExp(this.findSearchValue, "ig");
            option.displayName = name.replaceAll(regex, this.replaceField);
          }
        }
      } else if (this.replaceField == "" && option.name) {
        option.displayName = option.name;
      }
    });
  }

  doReplace(): void {
    const selectedItem = this.selectedItems;
    if (this.sourceType == 0) {
      //data streams
      if (this.selectedStreamKey && selectedItem && selectedItem.length > 0) {
        const foundItem = this.items.find((item) => item.id == selectedItem[0]);
        if (foundItem && foundItem.streamKey && foundItem.widgetGuid && typeof foundItem.widgetGuid == "string") {
          this.updateWidgetStreamOption(foundItem.streamKey, this.selectedStreamKey, foundItem.widgetGuid);
        }
      }
    } else if (this.sourceType == 1) {
      //labels
      this.updateStreamLabel(false);
    } else if (this.sourceType == 2) {
      //update widget titles based on replacedItemsList
      this.updateWidgetTitle(false);
    }

    if (this.changeList.length > 0) {
      this.openConfirmDialog();
    } else if (this.changeList.length == 0 && this.sourceType == 0) {
      ToastService.showToast("info", "", "Please select a Stream in both lists", 5000);
    }
  }

  doReplaceAll(): void {
    const selectedItem = this.selectedItems;
    if (this.sourceType == 0) {
      //data streams
      if (this.selectedStreamKey && selectedItem && selectedItem.length > 0) {
        selectedItem.forEach((selection) => {
          const foundItem = this.items.find((item) => item.id == selection);
          if (
            foundItem &&
            foundItem.streamKey &&
            foundItem.widgetGuid &&
            typeof foundItem.widgetGuid == "string" &&
            typeof this.selectedStreamKey == "string"
          ) {
            this.updateWidgetStreamOption(foundItem.streamKey, this.selectedStreamKey, foundItem.widgetGuid);
          }
        });
      }
    } else if (this.sourceType == 1) {
      //labels
      this.updateStreamLabel(true);
    } else if (this.sourceType == 2) {
      //update widget titles based on replacedItemsList
      this.updateWidgetTitle(true);
    }

    if (this.changeList.length > 0) {
      this.openConfirmDialog();
    }
  }

  batchQueue: WidgetConfig[] = [];

  changeList: FindnReplaceChangeItem[] = [];

  addWidgetConfigToQueue(widget: WidgetConfig): void {
    const foundIndex = this.batchQueue.findIndex((ele) => ele.guid == widget.guid);
    if (foundIndex == -1) {
      //add to queue
      this.batchQueue.push(widget);
    } else {
      //update queue
      this.batchQueue[foundIndex] = widget;
    }
  }

  updateWidgetStreamOption(oldKey: string, newKey: string, widgetGuid: string): void {
    if (this.dashboardState.isLoadedWidgets && this.dashboardState.widgets?.length) {
      const foundIndex = this.batchQueue.findIndex((ele) => ele.guid == widgetGuid);
      if (foundIndex !== -1) {
        //found widget in batch queue
        const widget = this.batchQueue[foundIndex];
        if (widget.widgetOptions?.widgetDataSettings?.streamOptions?.length) {
          widget.widgetOptions.widgetDataSettings.streamOptions.forEach((streamOption) => {
            if (streamOption.StreamKey == oldKey) {
              this.changeList.push({
                old: streamOption.Name,
                new: this.selectedStreamOption.Name,
                type: "Data Stream",
              });
              streamOption.StreamKey = newKey;
              streamOption.Name = this.selectedStreamOption.Name;
              streamOption.structured = this.structuredView;
              if (this.updateLabels == true) {
                streamOption.Label = this.selectedStreamOption.Label;
              }
            }
          });
        }
      } else {
        this.dashboardState.widgets.forEach((widget) => {
          if (widget.guid == widgetGuid && widget.widgetOptions?.widgetDataSettings?.streamOptions?.length) {
            const clonedWidget = JSON.parse(JSON.stringify(widget));
            clonedWidget.widgetOptions.widgetDataSettings?.streamOptions.forEach((streamOption: StreamOption) => {
              if (streamOption.StreamKey == oldKey) {
                this.changeList.push({
                  old: streamOption.Name,
                  new: this.selectedStreamOption.Name,
                  type: "Data Stream",
                });
                streamOption.StreamKey = newKey;
                streamOption.Name = this.selectedStreamOption.Name;
                streamOption.structured = this.structuredView;
                if (this.updateLabels == true) {
                  streamOption.Label = this.selectedStreamOption.Label;
                }
                this.addWidgetConfigToQueue(clonedWidget);
              }
            });
          }
        });
      }
    }
  }

  updateStreamLabel(isAll: boolean): void {
    if (
      this.dashboardState.isLoadedWidgets &&
      this.dashboardState.widgets?.length &&
      this.replacedItemsList &&
      this.replacedItemsList.length > 0
    ) {
      const length = isAll ? this.replacedItemsList.length : 1;
      for (let i = 0; i < length; i++) {
        const replacedItem = this.replacedItemsList[i];
        const foundIndex = this.batchQueue.findIndex((ele) => ele.guid == replacedItem.widgetGuid);
        if (foundIndex !== -1) {
          //found widget in batch queue
          const widget = this.batchQueue[foundIndex];
          if (widget.widgetOptions?.widgetDataSettings?.streamOptions?.length) {
            widget.widgetOptions.widgetDataSettings.streamOptions.forEach((streamOptions: StreamOption) => {
              if (
                streamOptions.StreamKey == replacedItem.streamKey &&
                replacedItem.displayName &&
                typeof replacedItem.displayName == "string" &&
                replacedItem.name &&
                typeof replacedItem.name == "string"
              ) {
                this.changeList.push({
                  old: replacedItem.name,
                  new: replacedItem.displayName,
                  type: "Stream Label",
                });
                const replacement = replacedItem.displayName;
                streamOptions.Label = replacement;
              }
            });
          }
        } else {
          this.dashboardState.widgets.forEach((widget) => {
            if (widget.guid == replacedItem.widgetGuid && widget.widgetOptions?.widgetDataSettings?.streamOptions?.length) {
              const clonedWidget = JSON.parse(JSON.stringify(widget));
              clonedWidget.widgetOptions.widgetDataSettings?.streamOptions.forEach((streamOptions: StreamOption) => {
                if (
                  streamOptions.StreamKey == replacedItem.streamKey &&
                  replacedItem.displayName &&
                  typeof replacedItem.displayName == "string" &&
                  replacedItem.name &&
                  typeof replacedItem.name == "string"
                ) {
                  this.changeList.push({
                    old: replacedItem.name,
                    new: replacedItem.displayName,
                    type: "Stream Label",
                  });
                  const replacement = replacedItem.displayName;
                  streamOptions.Label = replacement;
                  this.addWidgetConfigToQueue(clonedWidget);
                }
              });
            }
          });
        }
      }
    }
  }

  updateWidgetTitle(isAll: boolean): void {
    if (
      this.dashboardState.isLoadedWidgets &&
      this.dashboardState.widgets?.length &&
      this.replacedItemsList &&
      this.replacedItemsList.length > 0
    ) {
      const length = isAll ? this.replacedItemsList.length : 1;
      for (let i = 0; i < length; i++) {
        const replacedItem = this.replacedItemsList[i];
        const foundIndex = this.batchQueue.findIndex((ele) => ele.guid == replacedItem.id);
        if (foundIndex !== -1) {
          //found widget in batch queue
          const widget = this.batchQueue[foundIndex];
          if (
            widget.widgetOptions.basicWidgetSettings.widgetName == replacedItem.name &&
            replacedItem.displayName &&
            typeof replacedItem.displayName == "string"
          ) {
            this.changeList.push({
              old: replacedItem.name,
              new: replacedItem.displayName,
              type: "Widget Title",
            });
            const replacement = replacedItem.displayName;
            widget.widgetOptions.basicWidgetSettings.widgetName = replacement;
          }
        } else {
          //not found in batch queue
          this.dashboardState.widgets.forEach((widget) => {
            if (
              widget.guid == replacedItem.id &&
              widget.widgetOptions.basicWidgetSettings.widgetName == replacedItem.name &&
              replacedItem.displayName &&
              typeof replacedItem.displayName == "string"
            ) {
              this.changeList.push({
                old: replacedItem.name,
                new: replacedItem.displayName,
                type: "Widget Title",
              });
              const replacement = replacedItem.displayName;
              const clonedWidget = JSON.parse(JSON.stringify(widget));
              clonedWidget.widgetOptions.basicWidgetSettings.widgetName = replacement;
              this.addWidgetConfigToQueue(clonedWidget);
            }
          });
        }
      }
    }
  }

  selectedStreamOption: FindnReplaceSelectedStream = {
    StreamKey: "",
    Name: "",
    Label: "",
  };

  streamFromTreeSelected(node: TreeNodeForUI): void {
    this.selectedStreamKey = node.key ?? "";
    this.selectedStreamOption.Label = node.label ?? "";
    this.selectedStreamOption.Name = node.label ?? "";
    this.selectedStreamOption.StreamKey = node.key ?? "";
  }

  toggleMatchCase(): void {
    this.toMatchCase = !this.toMatchCase;
    this.doSearch(null);
  }

  toggleMatchWholeWord(): void {
    this.toMatchWholeWord = !this.toMatchWholeWord;
    this.doSearch(null);
  }

  openConfirmDialog(): void {
    let message = "You are about to make the following changes: \n\n";
    const finalLine = "Are you sure?";

    this.changeList.forEach((update) => {
      message += `TYPE: ${update.type}
      OLD: ${update.old}
      NEW: ${update.new}

      `;
    });

    message += finalLine;

    ConfirmationService.showConfirmation(
      {
        message: message,
        header: "Confirm Change",
        icon: "pi pi-exclamation-triangle text-4xl text-red-500",
        acceptIcon: "pi pi-check",
        rejectIcon: "pi pi-times",
        rejectClass: "p-button-secondary p-button-text",
        accept: async () => {
          // callback to execute when user confirms the action
          await this.$store.dispatch("dashboard/saveWidgets", this.batchQueue);
          //this.$emit("close-dialog");
          if (this.dashboardState.widgets) {
            const widgetGuids: string[] = this.dashboardState.widgets.map((widget) => widget.guid);
            this.$store.dispatch("dashboard/loadWidgets", widgetGuids);
          }
          window.setTimeout(() => {
            ToastService.showToast("success", "", "Find and replace operation successful", 5000);
          }, 500);
          
          this.sourceTypeChange();
        },
        reject: () => {
          // callback to execute when user rejects the action
          this.changeList = [];
          this.batchQueue = [];
        },
      },
      "find-replace-confirm-dialog"
    );
  }

  doConfirmNo(): void {
    this.displayConfirmation = false;
    this.changeList = [];
    this.batchQueue = [];
  }

  async doConfirmYes(): Promise<void> {
    await this.$store.dispatch("dashboard/saveWidgets", this.batchQueue);
    this.displayConfirmation = false;
    this.$emit("close-dialog");
    if (this.dashboardState.widgets) {
      const widgetGuids: string[] = this.dashboardState.widgets.map((widget) => widget.guid);
      this.$store.dispatch("dashboard/loadWidgets", widgetGuids);
    }
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get streams(): FindnReplaceListItem[] {
    const result: FindnReplaceListItem[] = [];
    if (this.dashboardState.isLoadedWidgets && this.dashboardState.widgets?.length) {
      this.dashboardState.widgets.forEach((widget) => {
        if (widget.widgetOptions?.widgetDataSettings?.streamOptions?.length) {
          widget.widgetOptions.widgetDataSettings.streamOptions.forEach((streamOptions) => {
            if (streamOptions.StreamKey && streamOptions.Name) {
              result.push({
                id: `${widget.guid}-${streamOptions.StreamKey}`,
                name: streamOptions.Name,
                displayName: streamOptions.Name,
                widgetTitle: widget.widgetOptions.basicWidgetSettings.widgetName,
                widgetType: widget.widgetType,
                streamKey: streamOptions.StreamKey,
                widgetGuid: widget.guid,
              });
            }
          });
        }
      });
    }
    return result;
  }

  get titles(): FindnReplaceListItem[] {
    const result: FindnReplaceListItem[] = [];
    if (this.dashboardState.isLoadedWidgets && this.dashboardState.widgets?.length) {
      this.dashboardState.widgets.forEach((widget: WidgetConfig) => {
        if (widget.widgetOptions.basicWidgetSettings.widgetName && widget.guid && widget.widgetType) {
          result.push({
            id: widget.guid,
            name: widget.widgetOptions.basicWidgetSettings.widgetName,
            displayName: widget.widgetOptions.basicWidgetSettings.widgetName,
            widgetType: widget.widgetType,
          });
        }
      });
    }
    return result;
  }

  get labels(): FindnReplaceListItem[] {
    const result: FindnReplaceListItem[] = [];
    if (this.dashboardState.isLoadedWidgets && this.dashboardState.widgets?.length) {
      this.dashboardState.widgets.forEach((widget) => {
        if (widget.widgetOptions?.widgetDataSettings?.streamOptions?.length) {
          widget.widgetOptions.widgetDataSettings.streamOptions.forEach((streamOptions) => {
            if (streamOptions.StreamKey && streamOptions.Label) {
              result.push({
                id: `${widget.guid}-${streamOptions.StreamKey}`,
                streamKey: streamOptions.StreamKey,
                name: streamOptions.Label,
                displayName: streamOptions.Label,
                widgetGuid: widget.guid,
                widgetTitle: widget.widgetOptions.basicWidgetSettings.widgetName,
                widgetType: widget.widgetType,
              });
            }
          });
        }
      });
    }
    return result;
  }

  get navTreeState(): NavTreeState {
    return this.navTreeStore;
  }
}

export default FindReplaceView;
</script>
