<template>
  <div class="col-12 md:col-8 semantics-edit-bool">
    <label :for="inputId">Value</label>
    <SelectButton 
      :id="inputId"
      v-model="boolVal" 
      :options="[false, true]"
      class="w-full"
      :disabled="disabledFields.includes(field)"
      :allowEmpty="true"
    />
  </div>
</template>

<script lang="ts">
import SelectButton from 'primevue/selectbutton';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonDict, HaysonVal } from "haystack-core";

@Component({
  components: {
    SelectButton
  },
})
class HaystackBoolEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }

  // #region Bool
  // boolean "true" or "false"
  get boolVal(): boolean {
    return !!this.valueHayson;
  }

  set boolVal(value: boolean) {
    this.valueHayson = value;
  }
  // #endregion Bool
}

export default HaystackBoolEditView;
</script>