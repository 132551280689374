import { SpaceCommentsEntity } from "@/models/dashboard/SpaceCommentsEntity";
import { SpaceCommentsReactionEntity } from "@/models/dashboard/SpaceCommentsReactionEntity";

export default class SpaceCommentsState {
  isLoaded = false;
  guid = "";
  data: SpaceCommentsEntity[] = [];
  inProgressSave = false;
  inProgressDelete = false;

  isLoadedReaction = false;
  guidReaction = "";
  dataReaction: SpaceCommentsReactionEntity[] = [];
  inProgressReaction = false;
  inProgressDeleteReaction = false;
}
