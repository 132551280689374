<template>
  <div ng-non-bindable :class="{ 'fullscreen-mode': rootState.isFullscreen }">
    <DebugView/>

    <div v-if="authState.organisationChangingInProgress" style="height: 100vh;" class="min-h-full flex justify-content-center align-items-center flex-auto">
      <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-else :class="`page with-header ${isMiniMode ? 'has-nav-menu-mini' : ''}`">
      <!-- Page header -->
      <header class="page-header is-fixed no-print">
        <div v-if="isSidebarMode">
          <Button 
            icon="pi pi-bars" 
            class="p-button-rounded p-button-primary p-button-icon-only p-button-text p-button-lg mr-2 sm:mr-3 md:mr-4" 
            @click="onBurgerClick"
          />
          <a href="/">
            <img
              :src="'/assets/bitpool-logo-white.svg'"
              width="134"
              alt="Bitpool"
            />
          </a>
        </div>
        <div class="ml-auto">
          <Button v-if="displaySection === 'dashboard'" v-tippy="t('topBar.comments')" class="p-button-rounded p-button-secondary p-button-icon-only p-button-text ml-2 comments-img-btn" @click="openComments">
            <span class="comments-img">
              <CommentsIconSvg/>
            </span>
          </Button>
          <span class="ml-2 relative">
            <Badge v-if="unreadNotificationsCount" :value="unreadNotificationsCount" severity="danger" @click="openSidebarNotifications"></Badge>
            <Button 
              v-tippy="t('topBar.notifications')" 
              class="p-button-rounded p-button-secondary p-button-icon-only p-button-text notification-img-btn" 
              @click="openSidebarNotifications"
            >
              <span class="notification-img">
                <BellWithoutShadowSvg/>
              </span>
            </Button>
          </span>
          <Button v-if="authState.userSettings?.showHelp" v-tippy="t('topBar.help')" class="p-button-rounded p-button-secondary p-button-icon-only p-button-text ml-2 help-img-btn" @click="openHelp">
            <span class="help-img">
              <HelpIconSvg/>
            </span>
          </Button>
          <div :content="t('topBar.userAndOrganisation')" v-tippy="{ placement : 'bottom' }" class="user-container ml-2" :class="{'is-expanded': isUserPanelVisible}" @click="toggleUserPanel">
            <div class="user-avatar" v-if="avatarUrl">
              <img :src="avatarUrl" />
            </div>
            <div class="user-icon" v-else>
              <span class="user-img">
                <UserSvg/>
              </span>
            </div>
            <div class="user-name">{{fullUsername}}</div>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 16" class="chevron"><path stroke="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.667 5.332 8 10.665l5.333-5.333"/></svg>
          </div>
        </div>
      </header>

      <!--  Menu in left aside or sidebar-->
      <NavMenuView 
        :displaySection="displaySection"
        :afterNavigateTo="afterNavigateTo"
        v-if="!isSidebarMode"
        :class="{ 'no-print': true, 'nav-menu-mini': isMiniMode}"
      />
      <Sidebar v-model:visible="visibleSidebar" v-if="isSidebarMode" class="sidebar-menu sidebar-dark">
        <NavMenuView 
          :displaySection="displaySection"
          :afterNavigateTo="afterNavigateTo"
        />
      </Sidebar>
      
      <div class="page-container" ref="pageContainer">
        <main>
          <div v-if="displaySection === 'pools'">
            <PoolsView></PoolsView>
          </div>
          <div v-else-if="displaySection === 'pool'">
            <StreamsView v-if="parts.length > 3" :poolKey="parts[3]"></StreamsView>
            <div v-else>Invalid url</div>
          </div>
          <div v-else-if="displaySection === 'stream'">
            <StreamDetailsView v-if="parts.length > 3" :streamKey="parts[3].split('?')[0]"></StreamDetailsView>
            <div v-else>Invalid url</div>
          </div>
          <div v-else-if="displaySection === 'mqtt'">
            <MQTTView></MQTTView>
          </div>
          <div v-else-if="displaySection === 'haystack'">
            <HaystackView></HaystackView>
          </div>
          <div v-else-if="displaySection === 'nube-io'">
            <NubeIOView></NubeIOView>
          </div>
          <div v-else-if="displaySection === 'bintracker'">
            <BintrackerView></BintrackerView>
          </div>
          <div v-else-if="displaySection === 'api-keys'">
            <ApiKeysView></ApiKeysView>
          </div>
          <div v-else-if="displaySection === 'import'">
            <ImportView></ImportView>
          </div>
          <div v-else-if="displaySection === 'uploader'">
            <WindowsUploaderView></WindowsUploaderView>
          </div>
          <div v-else-if="displaySection === 'email-legacy'">
            <EmailImportView></EmailImportView>
          </div>
          <div v-else-if="displaySection === 'email'">
            <EmailImport2View></EmailImport2View>
          </div>
          <div v-else-if="displaySection === 'flows'">
            <FlowListView/>
          </div>
          <div v-else-if="displaySection === 'flow'">
            <FlowView v-if="parts.length > 3" :flowId="parts[3].split('?')[0]"></FlowView>
            <div v-else>Invalid url</div>
          </div>
          <div v-else-if="displaySection === 'admin-users'">
            <AdminUsersView/>
          </div>
          <div v-else-if="displaySection === 'admin-organisation-stats'">
            <AdminOrganisationStatsView/>
          </div>
          <div v-else-if="displaySection === 'admin-api-status'">
            <AdminApiStatusView/>
          </div>
          <div v-else-if="displaySection === 'admin-url-short'">
            <AdminShortUrlView/>
          </div>
          <div v-else-if="displaySection === 'admin-bitpool-ai'">
            <BitpoolAISetupView/>
          </div>
          <div v-else-if="displaySection === 'organisations-edit'">
            <OrganisationEditView/>
          </div>
          <div v-else-if="displaySection === 'organisations-users'">
            <OrganisationUsersView/>
          </div>
          <div v-else-if="displaySection === 'organisations-colors'">
            <OrganisationColorsView/>
          </div>
          <div v-else-if="displaySection === 'organisations-graphic-library'">
            <OrganisationImageGalleryView/>
          </div>
          <div v-else-if="displaySection === 'organisations-insights-library'">
            <OrganisationInsightsLibraryView/>
          </div>
          <div v-else-if="displaySection === 'organisations-tariffs'">
            <OrganisationTariffsView/>
          </div>
          <div v-else-if="displaySection === 'dashboard'">
            <DashboardView
              :pane="getUrlParameter('pane', true)"
              :dashboardId="getUrlParameter('id', true)"
            ></DashboardView>
          </div>
          <div v-else-if="displaySection === 'presentations'">
            <PresentationsView/>
          </div>
          <div v-else-if="displaySection === 'presentation-edit'">
            <PresentationView v-if="parts.length > 3" :presentationId="parts[3].split('?')[0]"></PresentationView>
            <div v-else>Invalid url</div>
          </div>
          <div v-else-if="displaySection === 'tags'">
            <TagManagerView/>
          </div>
          <div v-else-if="displaySection === 'tags-config'">
            <TagManagerConfigView/>
          </div>
          <div v-else-if="displaySection === 'mqtt-scheduler'">
            <MQTTSchedulerView/>
          </div>
          <div v-else-if="displaySection === 'mqtt-scheduler-edit'">
            <MQTTSchedulerEditView v-if="parts.length > 3" :schedulerId="parts[3].split('?')[0]"/>
            <div v-else>Invalid url</div>
          </div>
          <div v-else-if="displaySection === 'notifications'">
            <NotificationsView/>
          </div>
          <div v-else-if="displaySection === 'not_found'">Not found</div>
        </main>

        <!--  Widgets (including User card) inside overlay panel -->
        <Sidebar v-model:visible="visibleSidebarNotifications" position="right" class="notifications-unread-sidebar">
          <h2>Notifications</h2>
          <NotificationsLastUnreadView :afterNavigateTo="afterNavigateTo"/>
        </Sidebar>
        <OverlayPanel ref="userOverlayPanel" appendTo="#overlayPanelPlaceholder" @show="onOverlayPanelShow" @hide="onOverlayPanelHide" :showCloseIcon="true" :dismissable="true" :breakpoints="{'470px': '320px'}" :style="{width: '346px'}" class="user-view-overlaypanel">
          <UserView :openUserSettings="openUserSettings" :afterNavigateTo="afterNavigateTo" />
        </OverlayPanel>
        <Dialog :header="t('accountSettings.dialogName')" v-model:visible="displayUserSettings" :modal="true" class="user-settings-dialog">
          <UserSettingsView ref="userSettingsView" @closeDialog="closeUserSettings" />
          <template #footer>
          </template>
        </Dialog>
      </div>

      <footer class="page-footer no-print"></footer>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import AngularOptions from "@/models/AngularOptions";
import Button from 'primevue/button';
import Sidebar from 'primevue/sidebar';
import NavMenuView from '@/components/views/NavMenuView.vue';
import Menu from 'primevue/menu';
import ProgressSpinner from 'primevue/progressspinner';
import Dialog from 'primevue/dialog';
import PoolsView from "@/components/views/data/PoolsView.vue";
import StreamsView from "@/components/views/data/StreamsView.vue";
import StreamDetailsView from "@/components/views/data/StreamDetailsView.vue";
import MQTTView from "@/components/views/mqtt/MQTTView.vue";
import HaystackView from "@/components/views/haystack/HaystackView.vue";
import NubeIOView from "@/components/views/nube-io/NubeIOView.vue";
import BintrackerView from "@/components/views/bintracker/BintrackerView.vue";
import ApiKeysView from "@/components/views/connections/ApiKeysView.vue";
import ImportView from "@/components/views/connections/ImportView.vue";
import WindowsUploaderView from "@/components/views/connections/WindowsUploaderView.vue";
import EmailImportView from "@/components/views/connections/EmailImportView.vue";
import EmailImport2View from "@/components/views/connections/EmailImport2View.vue";
import FlowListView from '@/components/views/flow/FlowListView.vue';
import FlowView from '@/components/views/flow/FlowView.vue';
import AdminUsersView from "@/components/views/admin/AdminUsersView.vue";
import AdminOrganisationStatsView from "@/components/views/admin/AdminOrganisationStatsView.vue";
import AdminApiStatusView from "@/components/views/admin/AdminApiStatusView.vue";
import AdminShortUrlView from "@/components/views/admin/AdminShortUrlView.vue";
import OrganisationEditView from '@/components/views/organisation/OrganisationEditView.vue';
import OrganisationUsersView from '@/components/views/organisation/OrganisationUsersView.vue';
import OrganisationColorsView from '@/components/views/organisation/OrganisationColorsView.vue';
import OrganisationImageGalleryView from '@/components/views/organisation/OrganisationImageGalleryView.vue';
import OrganisationInsightsLibraryView from '@/components/views/organisation/OrganisationInsightsLibraryView.vue';
import OrganisationTariffsView from '@/components/views/organisation/OrganisationTariffsView.vue';
import PresentationsView from '@/components/views/dashboards/PresentationsView.vue';
import PresentationView from '@/components/views/dashboards/PresentationView.vue';
import DashboardView from '@/components/views/dashboards/DashboardView.vue';
import TagManagerView from '@/components/views/tags/TagManagerView.vue';
import TagManagerConfigView from '@/components/views/tags/TagManagerConfigView.vue';
import MQTTSchedulerView from '@/components/views/mqtt/MQTTSchedulerView.vue';
import MQTTSchedulerEditView from '@/components/views/mqtt/MQTTSchedulerEditView.vue';
import BitpoolAISetupView from '@/components/views/bitpool-ai/BitpoolAISetupView.vue';
import NotificationsView from '@/components/views/notifications/NotificationsView.vue';
import UserView from "@/components/views/UserView.vue";
import UserSettingsView from "@/components/views/user-settings/UserSettingsView.vue";
import DebugView from "@/components/views/DebugView.vue";
import UserSvg from "@/components/svg/UserSvg.vue";
import WidgetSvg from "@/components/svg/WidgetSvg.vue";
import CommentsIconSvg from "@/components/svg/CommentsIconSvg.vue";
import HelpIconSvg from "@/components/svg/HelpIconSvg.vue";
import BellWithoutShadowSvg from "@/components/svg/BellWithoutShadowSvg.vue";
import QuickActionsSvg from "@/components/svg/QuickActionsSvg.vue";
import CustomWindow from '@/CustomWindow';
import OverlayPanel from 'primevue/overlaypanel';
import RootState from "@/store/states/RootState";
import AuthState from "@/store/states/AuthState";
import Ripple from 'primevue/ripple';
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import DashboardState from "@/store/states/DashboardState";
import { useHaystackDefsStore } from "@/stores/haystackDefs";
import { useUnitsStore } from "@/stores/units";
import { useColorThemeStore } from "@/stores/colorTheme";
import { useAIPersonaStore } from "@/stores/aiPersona";
import { useTariffStore } from "@/stores/tariff";
import { usePageStore } from "@/stores/page";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';
import NotificationsLastUnreadView from "@/components/views/notifications/NotificationsLastUnreadView.vue";
import { useNotificationStore } from "@/stores/notification";
import Badge from 'primevue/badge';
import { useNotificationGroupStore } from "@/stores/notificationGroup";

declare const window: CustomWindow;

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    Button,
    Sidebar,
    NavMenuView,
    Menu,
    ProgressSpinner,
    Dialog,
    PoolsView,
    StreamsView,
    StreamDetailsView,
    MQTTView,
    HaystackView,
    NubeIOView,
    BintrackerView,
    ApiKeysView,
    ImportView,
    WindowsUploaderView,
    EmailImportView,
    EmailImport2View,
    FlowListView,
    FlowView,
    AdminUsersView,
    AdminOrganisationStatsView,
    AdminApiStatusView,
    AdminShortUrlView,
    OrganisationEditView,
    OrganisationUsersView,
    OrganisationColorsView,
    OrganisationImageGalleryView,
    OrganisationInsightsLibraryView,
    OrganisationTariffsView,
    PresentationsView,
    PresentationView,
    DashboardView,
    TagManagerView,
    TagManagerConfigView,
    MQTTSchedulerView,
    MQTTSchedulerEditView,
    BitpoolAISetupView,
    NotificationsView,
    UserView,
    UserSettingsView,
    DebugView,
    UserSvg,
    WidgetSvg,
    CommentsIconSvg,
    HelpIconSvg,
    BellWithoutShadowSvg,
    QuickActionsSvg,
    OverlayPanel,
    NotificationsLastUnreadView,
    Badge
  },
  directives: {
    'ripple': Ripple
  }
})
class DataPage extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  displaySection = "";
  parts: string[] = [];
  visibleSidebar = false;
  sidebarDisabledFrom = 1200;
  windowWidth = window.innerWidth;

  get minimizeNav(): boolean {
    return this.rootState.minimizeNav;
  }

  set minimizeNav(value: boolean) {
    this.$store.commit("setMinimizeNav", value);
  }

  get isSidebarMode(): boolean {
    return this.rootState.isSidebarMode;
  }

  set isSidebarMode(value: boolean) {
    this.$store.commit("setIsSidebarMode", value);
  }

  get isMiniMode(): boolean {
    return this.minimizeNav && !this.isSidebarMode;
  }

  get rootState(): RootState {
    return this.$store.state;
  }
  
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  unitsStore = useUnitsStore();
  haystackDefsStore = useHaystackDefsStore();
  tariffStore = useTariffStore();
  colorThemeStore = useColorThemeStore();
  aiPersonaStore = useAIPersonaStore();
  pageStore = usePageStore();
  notificationStore = useNotificationStore();
  notificationGroupStore = useNotificationGroupStore();

  fullUsername = "";
  avatarUrl = "";

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  updateUser(): void {
    this.fullUsername = this.$store.getters["auth/getFullUsername"];
    this.avatarUrl = this.$store.getters["auth/getAvatarUrl"];
  }

  afterNavigateTo(): void {
    this.visibleSidebar = false;
    if (this.$refs.userOverlayPanel) {
      (this.$refs.userOverlayPanel as OverlayPanel).hide();
    }
    this.visibleSidebarNotifications = false;
  }

  oldPath = "";
  oldSearch = "";

  updateState(): void {
    this.urlSearchParams = new URLSearchParams(window.location.search);
    const path = window.location.pathname;
    const search = window.location.search;
    if (this.oldPath !== path || this.oldSearch !== search) {
      this.oldPath = path;
      this.oldSearch = search;
      this.parts = path.split("/");
      let newDisplaySection = "";
      // routes here, becase vue native routes conflicting with angularjs routes
      if (this.parts.length > 1 && this.parts[1].toLowerCase() === "dashboards") {
        if (this.parts.length > 2 && this.parts[2].toLowerCase() === "presentation") {
          if (this.parts.length > 3) {
            newDisplaySection = "presentation-edit";
          } else {
            newDisplaySection = "presentations";
          }
        } else {
          newDisplaySection = "dashboard";
        }
      } else if (this.parts.length > 1 && this.parts[1].toLowerCase() === "notifications") {
        newDisplaySection = "notifications";
      } else if (this.parts.length > 1 && this.parts[1].toLowerCase() === "semantics") {
        if (this.parts.length === 3 && this.parts[2].toLowerCase() === "settings") {
          newDisplaySection = "tags-config";
        } else if (this.parts.length === 2) {
          newDisplaySection = "tags";
        }
      } if (this.parts.length > 2 && this.parts[1].toLowerCase() === "data" && this.parts[2].toLowerCase() === "scheduler") {
        if (this.parts.length > 3) {
          newDisplaySection = "mqtt-scheduler-edit";
        } else {
          newDisplaySection = "mqtt-scheduler";
        }
      } else if (this.parts.length === 3) {
        if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "pools"
        ) {
          newDisplaySection = "pools";
        } else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "mqtt") {
          newDisplaySection = "mqtt";
        } else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "haystack") {
          newDisplaySection = "haystack";
        } else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "nube-io") {
          newDisplaySection = "nube-io";
        } else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "bintracker") {
          newDisplaySection = "bintracker";
        }  else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "api-keys") {
          newDisplaySection = "api-keys";
        } else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "import") {
          newDisplaySection = "import";
        }  else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "uploader") {
          newDisplaySection = "uploader";
        }  else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "email") {
          newDisplaySection = "email";
        }  else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "email-legacy") {
          newDisplaySection = "email-legacy";
        } else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "flow") {
          newDisplaySection = "flows";
        } else if (
          this.parts[1].toLowerCase() === "admin" &&
          this.parts[2].toLowerCase() === "users"
        ) {
          newDisplaySection = "admin-users";
        } else if (
          this.parts[1].toLowerCase() === "admin" &&
          this.parts[2].toLowerCase() === "organisation-stats"
        ) {
          newDisplaySection = "admin-organisation-stats";
        } else if (
          this.parts[1].toLowerCase() === "admin" &&
          this.parts[2].toLowerCase() === "api-status"
        ) {
          newDisplaySection = "admin-api-status";
        } else if (
          this.parts[1].toLowerCase() === "admin" &&
          this.parts[2].toLowerCase() === "url-short"
        ) {
          newDisplaySection = "admin-url-short";
        } else if (
          this.parts[1].toLowerCase() === "admin" &&
          this.parts[2].toLowerCase() === "bitpool-ai"
        ) {
          newDisplaySection = "admin-bitpool-ai";
        } else if (
          this.parts[1].toLowerCase() === "organisations" &&
          this.parts[2].toLowerCase() === "colors"
        ) {
          newDisplaySection = "organisations-colors";
        } else if (
          this.parts[1].toLowerCase() === "organisations" &&
          this.parts[2].toLowerCase() === "graphic-library"
        ) {
          newDisplaySection = "organisations-graphic-library";
        } else if (
          this.parts[1].toLowerCase() === "organisations" &&
          this.parts[2].toLowerCase() === "insights"
        ) {
          newDisplaySection = "organisations-insights-library";
        } else if (
          this.parts[1].toLowerCase() === "organisations" &&
          this.parts[2].toLowerCase() === "tariffs"
        ) {
          newDisplaySection = "organisations-tariffs";
        } else if (
          this.parts[1].toLowerCase() === "organisations" &&
          this.parts[2].toLowerCase() === "edit"
        ) {
          newDisplaySection = "organisations-edit";
        } else if (
          this.parts[1].toLowerCase() === "organisations" &&
          this.parts[2].toLowerCase() === "users"
        ) {
          newDisplaySection = "organisations-users";
        }
      } else if (this.parts.length === 4) {
        if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "pools"
        ) {
          newDisplaySection = "pool";
        } else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "streams"
        ) {
          newDisplaySection = "stream";
        } else if (
          this.parts[1].toLowerCase() === "data" &&
          this.parts[2].toLowerCase() === "flow"
        ) {
          newDisplaySection = "flow";
        }
      }
      if (newDisplaySection) {
        this.displaySection = newDisplaySection;
      } else {
        this.displaySection = "not_found";
      }
      const pageName = this.pageNames[this.displaySection] ?? "";
      document.title = window.angularUtilsService.getInstanceTitle() + (pageName ? ` - ${pageName}` : "");
    }
  }

  pageNames: Record<string, string> = {
    "not_found": "Not Found",
    "dashboard": "Dashboard",
    "presentation-edit": "Presentation",
    "presentations": "Presentations",
    "pools": "Pools",
    "pool": "Pool",
    "stream": "Stream",
    "mqtt": "MQTT",
    "mqtt-scheduler": "Scheduler",
    "mqtt-scheduler-edit": "Schedule Edit",
    "haystack": "Haystack",
    "nube-io": "Nube iO",
    "bintracker": "Bintracker",
    "api-keys": "API Keys",
    "import": "Import",
    "uploader": "Uploader",
    "email": "Email Import",
    "email-legacy": "Email Import - Legacy",
    "admin-users": "Users",
    "admin-organisation-stats": "Organisation Stats",
    "admin-api-status": "Api Status",
    "admin-url-short": "Url Shortener",
    "admin-bitpool-ai": "Bitpool AI",
    "organisations-edit": "Organisations - Edit",
    "organisations-users": "Organisations - Users",
    "organisations-colors": "Organisations - Colors",
    "organisations-tariffs": "Organisations - Tariffs",
    "organisations-graphic-library": "Organisations - Graphic Library",
    "tags": "Semantics",
    "tags-config": "Semantics Settings",
    "flows": "Flows",
    "flow": "Flow",
    "notifications": "Notifications"
  };

  unsubscribeLocationChange: any = null;

  created(): void {
    this.pageStore.$reset();
    this.emitter.on("window_size_changed_debounce", this.onResize);
    this.onResize();
    this.updateUser();
    this.emitter.on("user_updated", this.updateUser);
    this.updateState();
    // subscribe to angularjs route change event
    this.unsubscribeLocationChange = this.angularOptions.angularScope.$on('$locationChangeSuccess', ($event: any, next: any, current: any) => { 
      this.updateState();
      if (this.$refs.pageContainer) {
        (this.$refs.pageContainer as HTMLElement).scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
    this.haystackDefsStore.load();
    this.colorThemeStore.load();
    if (!this.unitsStore.isLoaded) {
      this.unitsStore.load();
    }
    if (!this.aiPersonaStore.isLoaded || this.aiPersonaStore.entities == null) {
      this.aiPersonaStore.load();
    }
    this.startReloadUnreadNotifications();
  }

  unmounted(): void {
    this.stopReloadingUnreadNotifications();
    this.emitter.off("window_size_changed_debounce", this.onResize);
    this.emitter.off("user_updated", this.updateUser);
    if (this.unsubscribeLocationChange) {
      this.unsubscribeLocationChange();
    }
    this.haystackDefsStore.$reset();
    this.tariffStore.$reset();
    this.colorThemeStore.$reset();
    this.$store.dispatch("auth/unloadAllUsers");
    this.notificationStore.$reset();
    this.notificationGroupStore.$reset();
  }

  get organisationChangingInProgress(): boolean {
    return this.authState.organisationChangingInProgress;
  }

  @Watch('authState.organisationChangingInProgress', { immediate: false, deep: false })
  onOrganisationChangingInProgressChanged(val: boolean, oldVal: boolean): void {
    if (!val) {
      this.haystackDefsStore.load();
      this.colorThemeStore.load();
      if (this.tariffStore.isLoaded) {
        this.tariffStore.$reset();
      }
      if (this.authState.isLoadedAllUsers) {
        this.$store.dispatch("auth/unloadAllUsers");
      }
    }
  }

  onResize(): void {
    this.windowWidth = window.innerWidth;
    const oldValue = this.isSidebarMode;
    this.isSidebarMode = this.windowWidth < this.sidebarDisabledFrom;
    if (this.isSidebarMode && !oldValue) {
      this.visibleSidebar = false;
    }
  }

  isUserPanelVisible = false;

  onOverlayPanelShow(): void {
    this.isUserPanelVisible = true;
  }

  onOverlayPanelHide(): void {
    this.isUserPanelVisible = false;
  }

  toggleUserPanel(event: Event): void {
    if (this.$refs.userOverlayPanel) {
      (this.$refs.userOverlayPanel as OverlayPanel).toggle(event);
    }
  }

  urlSearchParams: URLSearchParams | null = null;

  getUrlParameter(name: string, toLower: boolean): string | null | undefined {
    if (!this.urlSearchParams) {
      this.urlSearchParams = new URLSearchParams(window.location.search);
    }
    let value = this.urlSearchParams?.get(name);
    if (value && toLower) {
      value = value.toLowerCase();
    }
    return value;
  }

  displayUserSettings = false;

  openUserSettings(): void {
    this.afterNavigateTo();
    this.displayUserSettings = true;
  }

  closeUserSettings(): void {
    this.displayUserSettings = false;
  }

  getUserSettingsView(): UserSettingsView | null {
    if (this.$refs.userSettingsView) {
      return this.$refs.userSettingsView as UserSettingsView;
    } else {
      return null;
    }
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  openComments(): void {
    this.dashboardState.visibleSidebarComments = true;
  }

  openHelp(): void {
    window.open("https://wiki-cloud.bitpool.com/", '_blank');
  }

  onBurgerClick(): void {
    if (this.isSidebarMode) {
      this.visibleSidebar = !this.visibleSidebar;
    } else {
      this.minimizeNav = !this.minimizeNav;
    }
  }

  openLegalInformation(): void {
    const url = `${window.location.protocol}//${window.location.host}/legal`;
    window.open(url, '_blank');
  }

  // #region Notifications
  visibleSidebarNotifications = false;

  openSidebarNotifications(event: Event): void {
    this.visibleSidebarNotifications = true;
  }

  get unreadNotificationsCount(): string {
    const result = this.notificationStore.lastUnreadNotifications?.Total;
    if (result) {
      return result.toString();
    } else {
      return "";
    }
  }

  notificationReloadingInterval: number = 0;

  startReloadUnreadNotifications(): void {
    this.stopReloadingUnreadNotifications();
    this.notificationStore.loadLastUnreadNotifications();
    // every minute
    this.notificationReloadingInterval = window.setInterval(() => {
      this.notificationStore.loadLastUnreadNotifications();
    }, 60000);
  }

  stopReloadingUnreadNotifications(): void {
    if (this.notificationReloadingInterval) {
      window.clearInterval(this.notificationReloadingInterval);
      this.notificationReloadingInterval = 0;
    }
  }
  // #endregion Notifications
}

export default DataPage;
</script>
