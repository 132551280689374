<template>
  <span class="p-button-container">
    <Button 
      @click="showAddMenu($event)"
      :class="buttonClasses"
      v-if="!noButton"
    >
      <span class="text-lg lg:text-2xl p-button-icon p-button-icon-left svg-icon">
        <AddWidgetSvg/>
      </span>
      <span class="p-button-label">Add</span>
    </Button>
    <Menu ref="addMenu" :model="addMenuItems" :popup="true" />

    <Dialog header="Add Widget" v-model:visible="displayDialog" :modal="true" :breakpoints="{'992px': '90vw'}" :style="{width: '60.5rem'}" class="add-widget-dialog">
      <template #header>
        <div class="flex flex-wrap sm:flex-nowrap sm:flex-auto sm:justify-content-between sm:align-items-center">
          <span class="p-dialog-title mb-3 sm:mb-0">Add Widget</span>
          <IconField iconPosition="left" class="search-input-box">
            <InputIcon class="pi pi-search lg:text-xl"></InputIcon>
            <InputText
              class="inputfield text-base lg:text-lg"
              placeholder="Search"
              type="text"
              v-model="search"
              @input="debounceSearch()"
            />
          </IconField>
        </div>
      </template>
      <div class="dialog-content">
        <BlockUI :blocked="addWidgetInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner blockui-with-overlay-z-index" :class="addWidgetInProgress ? 'blockui-blocked' : ''"> 

          <div class="add-widget-content">
            <div class="add-widget-container add-widget-group">
              <div class="add-widget-container-header">
                <h3>Widget Group</h3>
                <span @click="toggleHideGroupSelect" class="link" :class="{ 'is-open': !hideGroupSelect }">
                  {{hideGroupSelect ? "Show" : "Hide"}} <i class="pi" :class="{ 'pi-chevron-up': !hideGroupSelect, 'pi-chevron-down': hideGroupSelect }"></i>
                </span>
              </div>
              <transition name="p-toggleable-content">
                <div class="add-widget-container-body" v-if="!hideGroupSelect">
                  <div class="add-widget-group-list">
                    <div v-for="group in groups" :key="group.name" @click="selectGroup(group.name)" :class="{'is-active': group.name === selectedGroup}">
                      <span>
                        <InlineSvg :src="group.icon"/>
                      </span>
                      <h4>{{group.name}}</h4>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
            <div class="add-widget-types">
            <div v-for="group in groups" :key="group.name">
              <div v-if="(!selectedGroup || group.name === selectedGroup) && getWidgets(group.name).length" class="add-widget-container add-widget-type">
                <div class="add-widget-container-header">
                  <h3>{{group.name}}</h3>
                  <span @click="toggleHideGroups(group.name)" class="link" :class="{ 'is-open': !hideGroups[group.name] }">
                    {{hideGroups[group.name] ? "Show" : "Hide"}} <i class="pi" :class="{ 'pi-chevron-up': !hideGroups[group.name], 'pi-chevron-down': hideGroups[group.name] }"></i>
                  </span>
                </div>
                <transition name="p-toggleable-content">
                  <div class="add-widget-container-body" v-if="!hideGroups[group.name]">
                    <div class="add-widget-type-list">
                      <div v-for="widget in getWidgets(group.name)" :key="widget.name" @click="addWidget(widget)" :class="widget.iconClass">
                        <span>
                          <InlineSvg :src="widget.icon"/>
                        </span>
                        <div>
                          <h5>{{widget.displayName}}</h5>
                          <p>{{widget.description}}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>
              </div>
            </div>
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
          
        </BlockUI>
      </div>
      <template #footer></template>
    </Dialog>

    <Dialog header="Create Dashboard with AI" v-model:visible="displayAIDialog" :modal="true" :style="{width: '56rem'}" class="create-dashboard-with-ai-dialog">
      <template #header>
        <div class="flex align-items-center">
          <span class="p-dialog-title">Create Dashboard with AI</span>
        </div>
      </template>
      <div class="dialog-content">
        <BlockUI :blocked="aiGenerationInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner blockui-with-overlay-z-index" :class="(aiGenerationInProgress) ? 'blockui-blocked' : ''">
          <div class="widget-settings-container">
            <div class="formgrid grid">
              <div class="field col-12">
                <label for="create-dashboard-with-ai-message">Message</label>
                <div>
                  <Textarea 
                    id="create-dashboard-with-ai-message" 
                    v-model="aiMessage" 
                    placeholder="Enter message"
                    class="inputfield w-full"
                    style="white-space: pre-wrap;" 
                    :autoResize="true" 
                    :rows="3"
                  />
                </div>
              </div>
              <div class="field col-12">
                <label for="create-dashboard-with-ai-widgets">Widgets</label>
                <div>
                  <MultiSelect 
                    inputId="create-dashboard-with-ai-widgets"
                    v-model="selectedWidgets"
                    :options="aiWidgets"
                    placeholder="Select Widgets"
                    optionLabel="displayName" 
                    optionValue="name"
                    display="chip"
                    :filter="true"
                    filterPlaceholder="Find Widgets"
                    class="p-multiselect-multiline inputfield w-full"
                  />
                </div>
              </div>
              <div class="field col-12">
                <label for="create-dashboard-with-ai-data">Select Data</label>
                <div>
                  <TreeWithCheckboxesView 
                    v-if="navTreeStore.isLoaded && nodesForAIDashboard"
                    :nodes="nodesForAIDashboard" 
                    :changeSelected="structuredChangeSelected"
                    placeholder="Find Entities"
                    :selectableWithTags="['point|equip']"
                  />
                  <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6"
                    animationDuration="1s" />
                </div>
              </div>
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeAIDialog" class="p-button-text p-button-secondary"/>
        <Button 
          label="Create" 
          :icon="aiGenerationInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
          @click="createDashboardWithAI" 
          :disabled='!aiMessage || !structuredSelectedNodes.length || aiGenerationInProgress || !selectedWidgets.length'
        />
      </template>
    </Dialog>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import Menu from 'primevue/menu';
import { MenuItem } from "primevue/menuitem";
import InputText from 'primevue/inputtext';
import Textarea from 'primevue/textarea';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import MultiSelect from 'primevue/multiselect';
import { Ref } from "vue-facing-decorator";
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import SpaceHelper from "@/helpers/SpaceHelper";
import WidgetHelper from "@/helpers/WidgetHelper";
import { WidgetGroupDescription } from "@/models/dashboard/WidgetGroupDescription";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import { debounce } from 'throttle-debounce';
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import AddWidgetSvg from "@/components/svg/AddWidgetSvg.vue";
import DashboardState from "@/store/states/DashboardState";
import { Space } from "@/models/dashboard/Space";
import InlineSvg from 'vue-inline-svg';
import AuthState from "@/store/states/AuthState";
import { DashboardType } from "@/models/dashboard/DashboardType";
import { useOrganisationStore } from "@/stores/organisation";
import { useNavTreeStore } from "@/stores/navTree";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import TreeWithCheckboxesView from "@/components/views/TreeWithCheckboxesView.vue";
import { StreamDataTypeStr } from "@/models/enums/StreamDataTypeStr";
import { useOpenAIChatStore } from "@/stores/openAIChat";
import ToastService from "@/services/ToastService";
import StreamOption from "@/models/dashboard/StreamOption";
import { AggregationType } from "@/models/enums/AggregationType";
import { AggregationTypeString } from "@/models/enums/AggregationTypeString";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { generateColorRGB } from "@marko19907/string-to-color";
import chroma from "chroma-js";
import { WidgetFeature } from "@/models/enums/WidgetFeature";

@Component({
  components: {
    Dropdown,
    Tag,
    Button,
    Dialog,
    Menu,
    InputText,
    Textarea,
    BlockUI,
    ProgressSpinner,
    IconField,
    InputIcon,
    MultiSelect,
    AddWidgetSvg,
    InlineSvg,
    TreeWithCheckboxesView
  },
})
class DashboardAddWidgetView extends Vue {
  @Prop({ required: true }) dashboardId!: string;
  @Prop({ required: true }) widgets!: SpaceWidgetConfig[];
  @Prop({ required: true }) buttonClasses!: string;
  @Prop({ required: true }) noButton!: boolean;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();
  navTreeStore = useNavTreeStore();

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  @Ref() readonly addMenu!: Menu;
  get addMenuItems(): MenuItem[] {
    const result: MenuItem[] = [
      {
        label: 'Add Grid',
        icon: undefined,
        command: () => {
          this.addGrid();
        },
      },
      {
        label: 'Add Widget',
        icon: undefined,
        command: () => {
          this.openDialog();
        },
      }
    ];
    return result;
  }

  search = '';
  searchFinal = '';
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
    if (this.searchFinal) {
      this.hideGroupSelect = true;
    } else {
      this.hideGroupSelect = false;
    }
  }

  selectedGroup = "";
  selectGroup(group: string): void {
    if (this.selectedGroup === group) {
      this.selectedGroup = "";
    } else {
      this.selectedGroup = group;
    }
  }

  hideGroupSelect = false;
  toggleHideGroupSelect(): void {
    this.hideGroupSelect = !this.hideGroupSelect;
  }
  
  hideGroups: Record<string, boolean> = {};
  toggleHideGroups(name: string): void {
    this.hideGroups[name] = !this.hideGroups[name];
  }

  showAddMenu(event: Event): void {
    this.addMenu.show(event);
  }

  displayDialog = false;

  openDialog(): void {
    this.displayDialog = true;
  }

  closeDialog(): void {
    this.displayDialog = false;
  }

  get groups(): WidgetGroupDescription[] {
    return WidgetHelper.getGroups();
  }

  getWidgets(group: string): WidgetDescription[] {
    let result = WidgetHelper.getWidgets(group);
    if (this.searchFinal) {
      const regex = new RegExp(this.searchFinal, "i");
      result = result.filter(x => x.displayName.match(regex));
    }
    return result;
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  get dashboards(): Space[] | null | undefined {
    return this.$store.getters["dashboard/getDashboards"];
  }
  
  addWidgetInProgress = false;

  async addGrid(): Promise<void> {
    const dashboard = this.dashboards?.find(x => x._id === this.dashboardId);
    if (this.widgets && dashboard) {
      const newGrid = SpaceHelper.createGrid();
      this.widgets.push(newGrid);
      let isOk = false;
      await this.$store.dispatch(
        "dashboard/saveDashboard", 
        { 
          dashboard: dashboard,
          organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
        }
      );
      const state = this.dashboardState.dashboardState[this.dashboardId];
      if (state && state[0] && !state[2]) {
        isOk = true;
      }
      if (isOk) {
        this.emitter.emit("grid_created", newGrid.guid);
      } else {
        const index = this.widgets.findIndex(x => x.guid === newGrid.guid);
        if (index >= 0) {
          this.widgets.splice(index, 1);
        }
      }
    }
  }

  async addWidget(widget: WidgetDescription): Promise<void> {
    const dashboard = this.dashboards?.find(x => x._id === this.dashboardId);
    if (widget.defaultOptions && dashboard) {
      const newWidget = SpaceHelper.createWidget(widget);
      this.addWidgetInProgress = true;
      this.widgets.push(newWidget[0]);
      this.dashboardState.widgets?.push(newWidget[1]);
      await this.$store.dispatch("dashboard/saveWidget", newWidget[1]);
      const state1 = this.dashboardState.widgetState[newWidget[1].guid];
      let isOk = false;
      if (state1 && state1[0] && !state1[2]) {
        await this.$store.dispatch(
          "dashboard/saveDashboard", 
          { 
            dashboard: dashboard,
            organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
          }
        );
        const state2 = this.dashboardState.dashboardState[this.dashboardId];
        if (state2 && state2[0] && !state2[2]) {
          isOk = true;
        }
      }
      this.addWidgetInProgress = false;
      if (isOk) {
        this.closeDialog();
        this.emitter.emit("widget_created", newWidget[0].guid);
      } else {
        const index1 = this.widgets.findIndex(x => x.guid === newWidget[0].guid);
        if (index1 >= 0) {
          this.widgets.splice(index1, 1);
        }
        if (this.dashboardState.widgets) {
          const index2 = this.dashboardState.widgets.findIndex(x => x.guid === newWidget[1].guid);
          if (index2 >= 0) {
            this.dashboardState.widgets.splice(index2, 1);
          }
        }
      }
    } else {
      alert("Not yet implemented")
    }
  }

  // #region AI
  displayAIDialog = false;

  openAIDialog(): void {
    const dashboard = this.dashboards?.find(x => x._id === this.dashboardId);
    if (dashboard) {
      if (!this.navTreeStore.isLoaded) {
        this.navTreeStore.load();
      }
      this.aiMessage = `Design a visually appealing and informative dashboard for time series data visualization. The dashboard should effectively communicate trends, patterns, and insights from the data. Dashboard name: ${dashboard.spaceName}`;
      this.structuredSelectedNodes = []
      this.displayAIDialog = true;
    }
  }

  closeAIDialog(): void {
    this.displayAIDialog = false;
  }

  aiMessage = "";

  get nodesForAIDashboard(): TreeNodeForUI[] | null {
    return this.navTreeStore.structuredDataForUI;
  }

  structuredSelectedNodes: TreeNodeForUI[] = [];

  structuredChangeSelected(nodes: TreeNodeForUI[]): void {
    this.structuredSelectedNodes = nodes;
  }

  openAIChatStore = useOpenAIChatStore();
  aiGenerationInProgress = false;

  get aiWidgets(): WidgetDescription[] {
    return WidgetHelper.getWidgetsListForAI();
  }

  selectedWidgets: string[] = this.aiWidgets.map(x => x.name);

  buildAIMessage(nodes: TreeNodeForUI[]): string {
    let result = "";
    for (const node of nodes) {
      const tags = node.tags?.filter(x => !x.startsWith("ref=") && !x.includes("Ref=")) ?? [];
      if (tags.includes("equip")) {
        if (node.children?.length) {
          const children = this.buildAIMessage(node.children);
          if (children) {
            result += `\n(Equip) Name: ${node.label} with tags: [${tags.join(", ")}] contains:${children}\n`;
          }
        }
      } else {
        result += `\n(Stream) StreamKey: ${node.key}, Name: ${node.label}, Stream data type: ${node.dataType === StreamDataTypeStr.Double ? "numeric" : "string"}, Tags: [${tags.join(", ")}]`;
      }
    }
    return result;
  }

  findSelectedStream(nodes: TreeNodeForUI[], streamKey: string): TreeNodeForUI | undefined {
    for (const node of nodes) {
      if (node.key === streamKey) {
        return node;
      }
      if (node.children) {
        const result = this.findSelectedStream(node.children, streamKey);
        if (result) {
          return result;
        }
      }
    }
    return undefined;
  }

  async createDashboardWithAI(): Promise<void> {
    const dashboard = this.dashboards?.find(x => x._id === this.dashboardId);
    if (!dashboard) {
      return;
    }
    this.aiGenerationInProgress = true;
    let message = this.aiMessage;
    if (this.structuredSelectedNodes.length > 0) {
      const dataMessage = this.buildAIMessage(this.structuredSelectedNodes);
      if (!dataMessage) {
        ToastService.showToast("error", "Error", "No stream selected", 5000);
        this.aiGenerationInProgress = false;
        return;
      }
      message = `${message}\nData:${dataMessage}`;
    }
    const widgetsTypes = this.selectedWidgets;
    const widgetsString = WidgetHelper.getWidgetsStringForAI(widgetsTypes);
    const response = await this.openAIChatStore.generateDashboard({
      Message: message,
      WidgetTypes: widgetsTypes,
      Widgets: widgetsString
    });
    if (!response) {
      this.aiGenerationInProgress = false;
      return;
    }
    // the default values of saturation: 75, lightness: 50, and alpha: 100
    const colorOptions = { saturation: 75, lightness: 48, alpha: 100 };
    const grids: SpaceWidgetConfig[] = [];
    const widgets: WidgetConfig[] = [];
    response.Dashboard.Childrens?.forEach(x => {
      const newGrid = SpaceHelper.createGrid();
      // disabled to prevent visualization issues
      // newGrid.size.size = x.Size.Size;
      // newGrid.size.sizeSM = x.Size.SizeSM;
      // newGrid.size.sizeMD = x.Size.SizeMD;
      // newGrid.size.sizeLG = x.Size.SizeLG;
      // newGrid.size.sizeXL = x.Size.SizeXL;
      
      // x.Name - title widget
      const titleWidgetDescription = WidgetHelper.getWidget("titlewidget");
      if (titleWidgetDescription) {
        const titleWidget = SpaceHelper.createWidget(titleWidgetDescription);
        titleWidget[1].widgetOptions.basicWidgetSettings.widgetName = x.Name;
        titleWidget[0].size.size = 12;
        titleWidget[0].size.sizeSM = 12;
        titleWidget[0].size.sizeMD = 12;
        titleWidget[0].size.sizeLG = 12;
        titleWidget[0].size.sizeXL = 12;
        newGrid.widgets?.push(titleWidget[0]);
        widgets.push(titleWidget[1]);
      }
      x.Childrens?.forEach(y => {
        const widgetDescription = WidgetHelper.getWidget(y.WidgetType);
        if (widgetDescription) {
          const newWidget = SpaceHelper.createWidget(widgetDescription);
          newWidget[0].size.size = y.Size.Size;
          newWidget[0].size.sizeSM = y.Size.SizeSM;
          newWidget[0].size.sizeMD = y.Size.SizeMD;
          newWidget[0].size.sizeLG = y.Size.SizeLG;
          newWidget[0].size.sizeXL = y.Size.SizeXL;
          newWidget[1].widgetOptions.basicWidgetSettings.widgetName = y.Name;
          if (newWidget[1].widgetOptions.widgetDataSettings?.streamOptions) {
            if (newWidget[1].widgetOptions.widgetDataSettings.streamOptions.length) {
              // for stream slots
              newWidget[1].widgetOptions.widgetDataSettings.streamOptions = [];
            }
            let maxStreamsCount = widgetDescription.streamSlots?.length ?? 0;
            if (widgetDescription.name === "heatmap") {
              maxStreamsCount = 1; // ai can't fill second stream correctly
            }
            y.Streams?.forEach((z, indexStream) => {
              if (!z.StreamKey) {
                return;
              }
              if (maxStreamsCount > 0 && indexStream >= maxStreamsCount) {
                return;
              }
              const streamOption: StreamOption = JSON.parse(JSON.stringify(widgetDescription.defaultStreamOptions));
              streamOption.StreamKey = z.StreamKey;
              var stream = this.findSelectedStream(this.structuredSelectedNodes, z.StreamKey);
              if (!stream) {
                return;
              }
              // fill name
              streamOption.Name = stream.label ?? z.StreamKey;
              streamOption.Label = stream.label ?? z.StreamKey;
              streamOption.structured = true;
              // stream units
              if (typeof streamOption.units !== "undefined" || 
                typeof newWidget[1].widgetOptions.advancedWidgetSettings?.widgetUnit !== "undefined"
              ) {
                const unitTag = stream.tags?.find(t => t.startsWith("unit="));
                if (unitTag) {
                  if (typeof streamOption.units !== "undefined") {
                    streamOption.units = unitTag.substring(5);
                  }
                  if (typeof newWidget[1].widgetOptions.advancedWidgetSettings?.widgetUnit !== "undefined") {
                    newWidget[1].widgetOptions.advancedWidgetSettings.widgetUnit = unitTag.substring(5);
                  }
                }
              }
              // color
              if (streamOption.hexStreamColor) {
                const colorRGBA = generateColorRGB(z.StreamKey, colorOptions);
                const values = colorRGBA.replace("rgba(", "").replace(")", "").split(",");
                const colorHex = chroma.rgb(parseFloat(values[0]), parseFloat(values[1]), parseFloat(values[2]), parseFloat(values[3])).hex();
                streamOption.hexStreamColor = colorHex;
              }
              if (z.AggregationType) {
                // "sum", "avg", "min", "max", "diff", "first", "last"
                switch (z.AggregationType) {
                  case "sum":
                    streamOption.Params.aggType = AggregationType.Sum;
                    break;
                  case "avg":
                    streamOption.Params.aggType = AggregationType.Avg;
                    break;
                  case "min":
                    streamOption.Params.aggType = AggregationType.Min;
                    break;
                  case "max":
                    streamOption.Params.aggType = AggregationType.Max;
                    break;
                  case "diff":
                    streamOption.Params.aggType = AggregationType.Diff;
                    break;
                  case "first":
                    streamOption.Params.aggType = AggregationType.First;
                    break;
                  case "last":
                    streamOption.Params.aggType = AggregationType.Last;
                    break;
                }
              }
              if (z.AggregationTypeString) {
                // "most", "least", "first", "last"
                switch (z.AggregationTypeString) {
                  case "most":
                    streamOption.Params.aggTypeString = AggregationTypeString.Most;
                    break;
                  case "least":
                    streamOption.Params.aggTypeString = AggregationTypeString.Least;
                    break;
                  case "first":
                    streamOption.Params.aggTypeString = AggregationTypeString.First;
                    break;
                  case "last":
                    streamOption.Params.aggTypeString = AggregationTypeString.Last;
                    break;
                }
              }
              if (z.SeriesType) {
                if (widgetDescription.features.includes(WidgetFeature.dataStreamsSeriesType)) {
                  streamOption.type = z.SeriesType;
                } else if (widgetDescription.features.includes(WidgetFeature.dataStreamsSeriesTypeStacked)) {
                  streamOption.stackedType = z.SeriesType;
                }
              }
              newWidget[1].widgetOptions.widgetDataSettings?.streamOptions.push(streamOption);
            });
          }

          newGrid.widgets?.push(newWidget[0]);
          widgets.push(newWidget[1]);
        }
      });
      grids.push(newGrid);
    });
    // save widgets
    this.dashboardState.widgets?.push(...widgets);
    await this.$store.dispatch("dashboard/saveWidgets", widgets);
    widgets.forEach(x => {
      const state1 = this.dashboardState.widgetState[x.guid];
      if (state1 && state1[0] && !state1[2]) {
        // ok
      } else {
        const index = this.dashboardState.widgets?.findIndex(y => y.guid === x.guid);
        if (typeof index === "number" && index >= 0) {
          this.dashboardState.widgets?.splice(index, 1);
        }
      }
    })
    // save grids
    dashboard.widgets.push(...grids);
    await this.$store.dispatch(
      "dashboard/saveDashboard", 
      { 
        dashboard: dashboard,
        organisationId: this.dashboardState.dashboardType === DashboardType.Organisation && this.organisationStore.currentOrganisation ? this.organisationStore.currentOrganisation.Id : undefined
      }
    );
    this.closeAIDialog();
    grids.forEach(x => {
      this.emitter.emit("grid_created", x.guid);
    });
    ToastService.showToast("success", "Bitpool AI", response.Comment, 10000);
    this.aiGenerationInProgress = false;
  }
  // #endregion AI
}

export default DashboardAddWidgetView;
</script>