import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import OrganisationDetailsState from "./states/OrganisationDetailsState";
import { OrganisationDetailsEntity } from "@/models/organisation/OrganisationDetailsEntity";

export const useOrganisationDetailsStore = defineStore('organisationDetails', {
  state: (): OrganisationDetailsState => ({ 
    isLoaded: false,
    guid: "",
    entity: null,
    updateInProgress: false,
    updateError: false,
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        const url = `rest/BitPool_V2/organisations/${organisationId}/details`;
        const response = await axios.get<OrganisationDetailsEntity>(url);
        if (this.guid === guid) {
          if (response.data) {
            this.entity = response.data;
          } else {
            this.entity = {
              Id: "",
              organisationId: organisationId,
              organisation: "",
              person: "",
              email: "",
              phone: ""
            }
          }
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load organisation details",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entity = null;
        this.isLoaded = true;
      }
    },
    async createUpdate(entity: OrganisationDetailsEntity, silentOnSuccess = false) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/organisations/details`;
        const response = await axios.put<OrganisationDetailsEntity>(url, entity);
        if (!silentOnSuccess) {
          ToastService.showToast("success", "Organisation Details", "Changes saved", 5000);
        }
        this.updateInProgress = false;
        if (this.isLoaded) {
          this.entity = response.data;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save organisation details",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
    }
  },
})
