<template>
  <div ng-non-bindable>
    <Toast :baseZIndex="2050"/>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator';
import Toast from 'primevue/toast';
import CustomWindow from '../CustomWindow';
import ToastService from '../services/ToastService';

type ToastMessageSeverityType = 'success' | 'info' | 'warn' | 'error' | undefined;

declare const window: CustomWindow;

@Component({
  
  components: {
    Toast
  }
})
class ToastRoot extends Vue {
  mounted(): void {
    // for angular
    window.showToast = this.showToast;
    // for vue
    ToastService.registerToast(this.showToast);
  }

  showToast(severity: string, summary: string, detail: string, life: number): void {
    this.$toast.add({ severity: severity as unknown as ToastMessageSeverityType, summary: summary, detail: detail, life: life });
  }
}

export default ToastRoot;
</script>