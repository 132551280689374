import { SignalRConnection } from "./SignalRConnection";

class SignalRDataService {
  connections: Record<string, SignalRConnection> = {};
  callbacks: Record<string, ((...args: any[]) => void)[]> = {};

  buildHubName(apiUrl: string, hub: string): string {
    return `${apiUrl}/signalr/${hub}`;
  }

  subscribe(
    apiUrl: string,
    type: string,
    ids: string[],
    callback: (...args: any[]) => void
  ): void {
    const hubName = this.buildHubName(apiUrl, type);
    let connection = this.connections[hubName];
    let callbacks = this.callbacks[hubName];
    if (callbacks) {
      const index = callbacks.indexOf(callback);
      if (index < 0) {
        callbacks.push(callback);
      }
    } else {
      callbacks = [callback];
      this.callbacks[hubName] = callbacks;
    }
    if (!connection) {
      connection = new SignalRConnection();
      const methods = this.getMethodNames(type);
      connection.connectToHub(
        hubName,
        methods[0],
        methods[1],
        methods[2],
        (hubName: string, ...args: any[]) => {
          this.callback(hubName, ...args);
        }
      );
      this.connections[hubName] = connection;
    }
    connection.subscribeMany(ids, false);
  }

  getMethodNames(type: string): [string, string, string] {
    switch (type) {
      case "spaceCommentsHub":
        return ["Updated,Deleted,Reaction", "Join", "Leave"];
      case "poolHub":
        return ["PoolUpdated", "JoinPools", "LeavePools"];
      default:
      case "streamHub":
        return ["StreamUpdated", "JoinStreams", "LeaveStreams"];
    }
  }

  unsubscribe(apiUrl: string, type: string, ids: string[]): void {
    const hubName = this.buildHubName(apiUrl, type);
    const connection = this.connections[hubName];
    if (connection) {
      connection.unsubscribeMany(ids);
    }
  }

  callback(hubName: string, ...args: any[]): void {
    const callbacks = this.callbacks[hubName];
    if (callbacks) {
      for (const callback of callbacks) {
        callback(...args);
      }
    }
  }

  removeCallback(
    apiUrl: string,
    type: string,
    callback: (...args: any[]) => void
  ): void {
    const hubName = this.buildHubName(apiUrl, type);
    const callbacks = this.callbacks[hubName];
    if (callbacks) {
      const index = callbacks.indexOf(callback);
      if (index >= 0) {
        callbacks.splice(index, 1);
      }
    }
  }
}

export default new SignalRDataService();
