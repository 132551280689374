export enum AggregationType {
  Avg = 0,
  Sum = 1,
  Diff = 2,
  Min = 3,
  Max = 4,
  // deprecated
  HighDuration = 5,
  // deprecated
  LowDuration = 6,
  None = 7,
  MinTimeOccurrence = 8,
  MaxTimeOccurrence = 9,
  First = 10,
  Last = 11,
  Accum = 12,
  FirstTimeOccurrence = 13,
  LastTimeOccurrence = 14
}
