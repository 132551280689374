<template>
  <div v-if="notificationGroupStore.isLoadedUser && (notificationStore.isLoadedLastUnreadNotifications || notificationStore.lastUnreadNotifications)">
    <div class="notifications-unread-sidebar-head">
      <div class="flex flex-column align-items-start sm:flex-row sm:justify-content-between sm:align-items-center gap-2 sm:gap-3">
        <p class="my-0">You have <b>{{ notificationStore.lastUnreadNotifications?.Total ?? 0 }}</b> unread notifications.</p>
      
        <Button 
          v-if="notificationStore.lastUnreadNotifications?.Total"
          link 
          label="Mark all as read"
          @click="markAsAll('read')"
          class="link p-0"
        />
      </div>
      <div class="mt-3 sm:mt-4 sm:text-right">
        <a href="/notifications" @click="viewAll" class="link notifications-unread-sidebar-view-all">View all notifications <i class="pi pi-external-link"></i></a>
      </div>
    </div>
    <div class="notifications-list">
      <template 
        v-for="notification in notificationStore.lastUnreadNotifications?.Items" 
        :key="notification.Id"
      >
        <NotificationsItemView :notification="notification" :selectable="false" :afterNavigateTo="afterNavigateTo"/>
      </template>
    </div>
  </div>
  <div v-else class="w-full h-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;">
    <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
  </div>
</template>

<script lang="ts">
import NavigationHelper from "@/helpers/NavigationHelper";
import { useNotificationStore } from "@/stores/notification";
import { Component, Prop, Vue } from "vue-facing-decorator";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import NotificationsItemView from "./NotificationsItemView.vue";
import { useNotificationGroupStore } from "@/stores/notificationGroup";

@Component({
  components: {
    ProgressSpinner,
    Button,
    NotificationsItemView
  },
})
class NotificationsLastUnreadView extends Vue {
  @Prop({ required: true }) afterNavigateTo!: () => void;
  
  notificationStore = useNotificationStore();
  notificationGroupStore = useNotificationGroupStore();

  created(): void {
    if (!this.notificationGroupStore.isLoadedUser) {
      this.notificationGroupStore.loadUser();
    }
  }

  viewAll(event: Event): void {
    event.preventDefault();
    NavigationHelper.goTo("/notifications");
    if (this.afterNavigateTo) {
      this.afterNavigateTo();
    }
  }

  markAsAll(value: "read" | "unread"): void {
    this.notificationStore.markAllAs(value);
  }
}

export default NotificationsLastUnreadView;
</script>