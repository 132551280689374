<template>
  <section class="organisation-profile-users-members">
    <div>
      <TreeGenericWithCheckboxesView
        v-if="organisationBBEAccessStore.isLoaded && bbeStore.isLoaded && bbeReady"
        :selectedNodes="bbeTreeSelected"
        :nodes="bbeTree"
        :changeSelected="treeChangeSelected"
        placeholder="Find Accounts"
        :openRoot="true"
      />
      <div v-else class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 30vh;">
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Model, Vue, Watch } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import { useOrganisationStore } from "@/stores/organisation";
import { GroupFromDBDto } from "@/models/organisation/GroupFromDBDto";
import { useBBEStore } from "@/stores/bbe";
import { useOrganisationBBEAccessStore } from "@/stores/organisationBBEAccess";
import { BBEGroupModel } from "@/models/bills/BBEGroupModel";
import { OrganisationBBEAccessModel } from "@/models/organisation/OrganisationBBEAccessModel";
import { GenericTree } from "@/models/tree/GenericTree";
import { BBESmartModel } from "@/models/bills/BBESmartModel";
import TreeGenericWithCheckboxesView from "@/components/views/TreeGenericWithCheckboxesView.vue";
import { OrganisationBBESmartAccessModel } from "@/models/organisation/OrganisationBBESmartAccessModel";

@Component({
  components: {
    Button,
    ProgressSpinner,
    TreeGenericWithCheckboxesView
  },
  directives: {
  }
})
class OrganisationUsersGroupsBills extends Vue {
  @Model model!: GroupFromDBDto;

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  bbeStore = useBBEStore();
  organisationBBEAccessStore = useOrganisationBBEAccessStore();

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get availableOrganisations(): OrganisationFullDto[] {
    let result = this.organisationStore.entities ?? [];
    result = result.filter(x => x.Id !== this.organisationStore.currentOrganisation?.Id);
    return result;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  created(): void {
    this.loadData();
  }

  unmounted(): void {
    this.bbeStore.$reset();
    this.organisationBBEAccessStore.$reset();
  }

  @Watch('model.Id', { immediate: false, deep: false })
  onModelChanged(val: number, oldVal: number): void {
    if (oldVal > 0) {
      this.loadData();
    } else {
      this.organisationBBEAccessStore.groupId = this.model.Id;
    }
  }

  bbeTree: GenericTree<BBEGroupModel | BBESmartModel>[] = [];
  bbeTreeFlat: GenericTree<BBEGroupModel | BBESmartModel>[] = [];
  bbeTreeSelected: GenericTree<BBEGroupModel | BBESmartModel>[] = [];
  bbeReady = false;

  treeChangeSelected(nodes: GenericTree<BBEGroupModel | BBESmartModel>[]): void {
    this.bbeTreeSelected = nodes;
  }

  /**
   * Load data for the component.
   * This function loads the BBE and organisation BBE access data and filters it to
   * obtain the groups and collections related to the current model.
   *
   * @return {Promise<void>} A promise that resolves when the data is loaded.
   */
  async loadData(): Promise<void> {
    this.bbeReady = false;

    // Load BBE and organisation BBE access data
    await Promise.all([
      this.bbeStore.load(), // Load BBE data
      this.organisationBBEAccessStore.load(this.model.OrganisationId, this.model.Id) // Load organisation BBE access data
    ]);

    // Filter the organisation BBE access data to obtain the groups and collections related to the current model
    const collectionsInGroups = (this.organisationBBEAccessStore.entities ?? []) // Get the organisation BBE access entities
      .filter(x => x.groups.includes(this.model.Id)); // Filter the entities to include only those with the current model's group id

    // Filter the BBE data to obtain the collections related to the groups obtained in the previous step
    const collections: BBEGroupModel[] = (this.bbeStore.entities ?? []) // Get the BBE entities
      .filter(x => collectionsInGroups.find(y => y.groupId === x._id)); // Filter the entities to include only those with a group id matching the ones obtained in the previous step

    // Filter the organisation BBE Smart Collections access data to obtain the groups and collections related to the current model
    const collectionsInGroupsSmart = (this.organisationBBEAccessStore.entitiesSmart ?? []) // Get the organisation BBE access entities
      .filter(x => x.groups.includes(this.model.Id)); // Filter the entities to include only those with the current model's group id

    // Filter the BBE Smart data to obtain the collections related to the groups obtained in the previous step
    const collectionsSmart: BBESmartModel[] = (this.bbeStore.entitiesSmart ?? []) // Get the BBE Smart entities
      .filter(x => collectionsInGroupsSmart.find(y => y.collectionId === x._id)); // Filter the entities to include only those with a group id matching the ones obtained in the previous step

    // Build the tree
    const trees = this.bbeStore.buildTree();
    this.bbeTree = trees[0];
    this.bbeTreeFlat = trees[1];

    // Build array of selected ids
    const selectedIds: string[] = [];
    for (const collection of collections) {
      if (collection._id) {
        selectedIds.push(collection._id);
      }
    }
    for (const collection of collectionsSmart) {
      if (collection._id) {
        selectedIds.push(collection._id);
      }
    }

    // Build array of selected nodes
    const selectedNodes: GenericTree<BBEGroupModel | BBESmartModel>[] = [];
    const treeFlat = trees[1];
    for (const node of treeFlat) {
      const index = selectedIds.findIndex(x => x === node.key);
      if (index >= 0) {
        selectedNodes.push(node);
      }
    }

    // Set the selected nodes
    this.bbeTreeSelected = selectedNodes;

    this.bbeReady = true;
  }

  /**
   * Saves changes to the organisation BBE access.
   * It updates the collections in groups and adds new collections to the groups.
   * @returns {Promise<boolean>} A promise that resolves to a boolean indicating if the save was successful.
   */
  async saveChanges(): Promise<boolean> {
    // Create a deep copy of the organisation BBE access entities
    const collectionsInGroups: OrganisationBBEAccessModel[] = JSON.parse(JSON.stringify(this.organisationBBEAccessStore.entities ?? []));
    const collectionsInGroupsSmart: OrganisationBBESmartAccessModel[] = JSON.parse(JSON.stringify(this.organisationBBEAccessStore.entitiesSmart ?? []));
    const forSave: OrganisationBBEAccessModel[] = [];
    const forSaveSmart: OrganisationBBESmartAccessModel[] = [];

    // Update the collections in groups
    for (const collection of collectionsInGroups) {
      // Skip if the collection is in any of the BBE groups
      if (this.bbeTreeSelected.some(x => x.key === collection.groupId)) continue;
      // Remove the collection from the groups if it exists
      const index = collection.groups.indexOf(this.model.Id);
      if (index > -1) {
        collection.groups.splice(index, 1);
        forSave.push(collection);
      }
    }
    
    // Update the collections in groups smart
    for (const collection of collectionsInGroupsSmart) {
      // Skip if the collection is in any of the BBE groups
      if (this.bbeTreeSelected.some(x => x.key === collection.collectionId)) continue;
      // Remove the collection from the groups if it exists
      const index = collection.groups.indexOf(this.model.Id);
      if (index > -1) {
        collection.groups.splice(index, 1);
        forSaveSmart.push(collection);
      }
    }

    // Add new collections to the groups ang groups smart
    for (const collection of this.bbeTreeSelected) {
      if (collection.originalData?._type === "smart_collection") {
        // Smart Collection
        // Find the index of the existing collection in the groups array
        const index = collectionsInGroupsSmart.findIndex(x => x.collectionId === collection.key);
        if (index > -1) {
          const existingCollection = collectionsInGroupsSmart[index];
          // Add the collection to the groups if it doesn't exist
          if (!existingCollection.groups.includes(this.model.Id)) {
            existingCollection.groups.push(this.model.Id);
            forSaveSmart.push(existingCollection);
          }
        } else {
          // Create a new collection and add it to the groups
          const newCollection: OrganisationBBESmartAccessModel = {
            _id: "",
            organisationId: this.model.OrganisationId,
            collectionId: collection.key ?? "",
            groups: [this.model.Id]
          };
          collectionsInGroupsSmart.push(newCollection);
          forSaveSmart.push(newCollection);
        }
      } else {
        // Accounts
        // Find the index of the existing collection in the groups array
        const index = collectionsInGroups.findIndex(x => x.groupId === collection.key);
        if (index > -1) {
          const existingCollection = collectionsInGroups[index];
          // Add the collection to the groups if it doesn't exist
          if (!existingCollection.groups.includes(this.model.Id)) {
            existingCollection.groups.push(this.model.Id);
            forSave.push(existingCollection);
          }
        } else {
          // Create a new collection and add it to the groups
          const newCollection: OrganisationBBEAccessModel = {
            _id: "",
            organisationId: this.model.OrganisationId,
            groupId: collection.key ?? "",
            groups: [this.model.Id]
          };
          collectionsInGroups.push(newCollection);
          forSave.push(newCollection);
        }
      }
    }

    let isOk = true;
    // Save the changes if there are any collections to save
    if (forSave.length || forSaveSmart.length) {
      isOk = await this.organisationBBEAccessStore.save(forSave, forSaveSmart);
    }
    // Return the result of the save operation
    return isOk;
  }
}

export default OrganisationUsersGroupsBills;
</script>