<template>
  <div>
    <h2 class="account-settings-title hidden xl:block">{{ t('accountSettings.terms.title') }}</h2>
    <p class="account-settings-description hidden xl:block">{{ t('accountSettings.terms.subTitle') }}</p>

    <Accordion class="terms-use-accordion" expandIcon="" collapseIcon="" v-model:activeIndex="active">
      <AccordionTab>
        <template #header>
          <div class="flex justify-content-between align-items-center w-full column-gap-3">
            <h3 class="mb-0">{{ t('accountSettings.terms.termsAndConditions') }}</h3>
            <Button :label="active === 0 ? t('common.close') : t('accountSettings.terms.seeDetails')" class="p-button-outlined flex-shrink-0"/>
          </div>
        </template>
        <TermsView/>
      </AccordionTab>
      <AccordionTab>
        <template #header>
          <div class="flex justify-content-between align-items-center w-full column-gap-3">
            <h3 class="mb-0">{{ t('accountSettings.terms.privacyPolicy') }}</h3>
            <Button :label="active === 1 ? t('common.close') : t('accountSettings.terms.seeDetails')" class="p-button-outlined flex-shrink-0"/>
          </div>
        </template>
        <PrivacyView/>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from "primevue/button";
import TermsView from "@/components/views/legal/TermsView.vue";
import PrivacyView from "@/components/views/legal/PrivacyView.vue";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    Accordion,
    AccordionTab,
    Button,
    TermsView,
    PrivacyView
  },
})
class UserSettingsTermsUseView extends Vue {
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  active = -1;
}

export default UserSettingsTermsUseView;
</script>