<template>
  <div ng-non-bindable v-if="isMounted" class="fullWidthHeight basicChart">
    <highcharts ref="chartElement" class="fullWidthHeight" :options="chartOptions"></highcharts>
    <Button 
      v-if="isZoomed" 
      @click="resetZoom" 
      icon="pi pi-search-minus text-md font-bold" 
      v-tippy="'Reset Zoom'" 
      class="p-button-icon-only p-button-rounded p-button-light chart-zoom-out old-dashboard"/>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import AngularOptions from '../../models/AngularOptions';
import ChartDataResponse from '../../models/ChartDataResponse';
import ChartRequstBody from '../../models/ChartRequstBody';
import StreamOption from '../../models/dashboard/StreamOption';
import HCPoint from '../../models/HCPoint';
import DateHelper from '../../helpers/DateHelper'
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import xRangeModule from 'highcharts/modules/xrange';
xRangeModule(Highcharts);
import ColorHelper from '../../helpers/ColorHelper';
import Button from 'primevue/button';

@Component({

  components: {
    highcharts: Chart,
    Button
  }
})
class ConditionDurationWidget extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  chartData: ChartDataResponse[] = [];
  streamColors: string[] = [];
  rules: [string, number, string, number][] = [];
  categories: string[] = [];
  dates: [Date, Date] | null = null;
  series: Highcharts.SeriesOptionsType[] = [];

  isMounted = false;

  resetZoom(): void {
    const chart = this.getChartElement()
    if (chart && chart.chart) {
      chart.chart.zoomOut();
    }
  }

  isZoomed = false;

  get chartOptions(): Highcharts.Options {
    const categories = this.categories;
    const rules = this.rules;
    const dates = this.dates;
    const series = this.series;
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "xrange",
        animation: true,
        zooming: {
          type: 'x'
        },
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          },
          selection: (event: Highcharts.SelectEventObject): (boolean | undefined) => {
            if (event.resetSelection) {
              this.isZoomed = false;
            } else {
              this.isZoomed = true;
            }
            return true;
          }
        }
      },
      tooltip: {
        formatter: function() {
          // x2 is missing, this is workaround
          const point = this as unknown as HCPoint;
          const pointDateDiff = point.x2 - (typeof this.x === "number" ? this.x : 0);
          const hours = pointDateDiff ? ((pointDateDiff) / (1000 * 60 * 60)).toFixed(2) : "0";
          let datesStr = ''
          if (dates) {
            const diff = dates[1].getTime() - dates[0].getTime();
            const percent = diff ? (100 * pointDateDiff / diff).toFixed(2) : "0"; 
            datesStr = ` - ${percent}% of selected date range`;
          }
          return `<small>${typeof this.x === "number" ? Highcharts.dateFormat('%b %e,%Y %H:%M', this.x) : this.x}-${Highcharts.dateFormat('%b %e,%Y %H:%M', point.x2)}</small><br/><span style="color:${this.color};">\u25CF </span><b>${categories[this.y ?? 0]}:</b> ${rules[this.y ?? 0][2]}<br/>${hours} hours${datesStr}`;
        },
        useHTML: true,
        outside: true
      },
      xAxis: {
        type: 'datetime'
      },
      yAxis: {
        title: {
          text: ''
        },
        categories: categories,
        reversed: true
      },
      legend: {
        enabled: false
      },
      series: series
    }
  }

  created(): void {
    if (this.angularOptions && this.angularOptions.angularScope) {
      if (this.angularOptions.angularScope.vueData && this.angularOptions.angularScope.vueRequestBody) {
        this.dataUpdate(
          this.angularOptions.angularScope.vueData as ChartDataResponse[],
          this.angularOptions.angularScope.vueRequestBody as ChartRequstBody);
      }
      if (this.angularOptions.angularScope.subscribeToDataUpdate) {
        this.angularOptions.angularScope.subscribeToDataUpdate(this.dataUpdate);
      }
    }
  }

  mounted(): void {
    this.isMounted = true;
    if (this.angularOptions && this.angularOptions.angularScope && this.angularOptions.angularScope.subscribeToWidgetResize) {
      this.angularOptions.angularScope.subscribeToWidgetResize(this.resizeWidgetEvent);
    }
  }

  loadConfig(): void {
    if (this.angularOptions && 
      this.angularOptions.angularScope &&
      this.angularOptions.angularScope.Widget &&
      this.angularOptions.angularScope.Widget.config &&
      this.angularOptions.angularScope.Widget.config.widgetOptions &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions) {
      const streams = this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions as StreamOption[];
      this.categories = [];
      this.streamColors = [];
      this.rules = [];
      streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.categories.push(name);
        if (stream.hexStreamColor) {
          this.streamColors.push(stream.hexStreamColor);
        } else {
          if (this.angularOptions.angularScope.theme &&
              this.angularOptions.angularScope.Widget &&
              this.angularOptions.angularScope.Widget.config &&
              this.angularOptions.angularScope.Widget.config.widgetOptions &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor) {
            const theme = this.angularOptions.angularScope.theme[this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor]
            this.streamColors.push(theme.colors[stream.streamColor]);
          } else {
            this.streamColors.push(ColorHelper.random());
          }
        }
        this.rules.push([stream.durationRule ?? "", stream.durationRuleValue ?? 0, stream.durationRuleText ?? "", stream.durationRuleValue2 ?? 0]);
      });
    }
  }

  dataUpdate(data: ChartDataResponse[], requestBody: ChartRequstBody): void {
    this.chartData = data;
    this.loadConfig();
    this.dates = DateHelper.extractDateFromRequestBody(requestBody);
    let i = 0;
    const result: Array<Highcharts.XrangePointOptionsObject> = [];
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        // angular should show error message to user
        //console.error(streamData.Error);
      } else if (streamData.Data && streamData.Data.length) {
        let periodStart: number | null = null;
        const ruleTuple = this.rules[i];
        if (ruleTuple) {
          const rule = ruleTuple[0];
          const ruleValue = ruleTuple[1];
          const ruleValue2 = ruleTuple[3];
          streamData.Data.forEach((record) => {
            let isOk = false;
            switch (rule){
              case ">": {
                isOk = record.y !== null && record.y > ruleValue;
                break;
              }
              case ">=": {
                isOk = record.y !== null && record.y >= ruleValue;
                break;
              }
              case "<": {
                isOk = record.y !== null && record.y < ruleValue;
                break;
              }
              case "<=": {
                isOk = record.y !== null && record.y <= ruleValue;
                break;
              }
              case "=": {
                isOk = record.y !== null && record.y === ruleValue;
                break;
              }
              case "!=": {
                isOk = record.y !== null && record.y !== ruleValue;
                break;
              }
              case "Between": {
                isOk = record.y !== null && record.y > ruleValue && record.y < ruleValue2;
                break;
              }
            }
            if (isOk) {
              if (!periodStart) {
                periodStart = record.x;
              }
            } else {
              if (periodStart) {
                result.push({
                  x: periodStart,
                  x2: record.x,
                  y: i,
                  color: this.streamColors.length > i ? this.streamColors[i] : ''
                });
                periodStart = null;
              }
            }
          });
          if (periodStart) {
            result.push({
              x: periodStart,
              x2: streamData.Data[streamData.Data.length - 1].x,
              y: i,
              color: this.streamColors.length > i ? this.streamColors[i] : ''
            });
          }
        }
      }
      i++;
    });
    this.series = [{
      dataLabels: {
        enabled: true
      },
      type: 'xrange',
      data: result
    }];
    if (!result.length && this.angularOptions.angularScope.showNoDataMessage) {
      this.angularOptions.angularScope.showNoDataMessage();
    } else {
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  // chart reflow on widget resize
  resizeWidgetEvent(): void {
    const chartElement = this.getChartElement();
    if (chartElement) {
      chartElement.chart.reflow();
    }
  }
}

export default ConditionDurationWidget;
</script>

<style lang="scss" scoped>
  .basicChart {
    display: flex;
    flex-direction: column;
  }
</style>