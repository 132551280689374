<template>
  <div class="widget-type-website">
    <iframe v-if="aws?.web_url" :src="aws.web_url"></iframe>
    <div v-else class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType" message="Embed Web Url have not been selected."/>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';

@Component({
  components: {
    WidgetNoDataView
  }
})
class WebsiteWidget extends Vue {
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  noDataType = WidgetNoDataTypes.NotConfigured;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }
}

export default WebsiteWidget;
</script>