<template>
  <BlockUI :blocked="authStore.is2faEnabled === null" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index" :class="authStore.is2faEnabled === null ? 'blockui-blocked' : ''">
    <h2 class="account-settings-title hidden xl:block">{{ t('accountSettings.2fa.title') }}</h2>
    <p class="account-settings-description hidden xl:block">{{ t('accountSettings.2fa.subTitle') }}</p>

    <section class="xl:mb-0">
      <h3 class="flex align-items-center gap-3">
        {{ t('accountSettings.2fa.sectionTitle') }}
        <Tag severity="info" :value="t('accountSettings.2fa.inactive')" style="padding: .3rem .75rem; background-color: var(--inactive-tag-bg-color); color: var(--inactive-tag-color);" v-if="current2faStep === 0"></Tag>
        <Tag severity="success" :value="t('accountSettings.2fa.active')" style="padding: .3rem .75rem;" v-else-if="current2faStep === 2"></Tag>
      </h3>
      <div v-if="authStore.is2faEnabled === null">
      </div>
      <div class="formgrid grid" v-else-if="current2faStep === 0">
        <div class="field col-12 md:col-8 xl:col-7 mb-0">
          <label for="currentPassword">{{ t('common.password') }}</label>
          <div>
            <Password
              class="w-full"
              id="currentPassword"
              inputClass="inputfield w-full"
              v-model="currentPassword"
              :feedback="false"
              toggleMask
              @keyup.enter="generateSecret"
            />
          </div>
        </div>
      </div>
      <div class="formgrid grid" v-else-if="current2faStep === 1">
        <div class="field col-12" v-if="currentSecret">
          <div class="formgrid grid">
            <div class="field col-12 validation-code validation-code-pre-text">
              <div>
                <p class="my-0" v-html="t('accountSettings.2fa.step2Help1Html')"></p>
              </div>
            </div>
            <div class="field col-12 sm:col-6 sm:mb-0 validation-code validation-code-qr">
              <div>
                <div class="flex justify-content-center align-items-center">
                  <img :src="currentSecret.SecretQR" style="width: 150px; height: auto;"/>
                </div>
              </div>
            </div>
            <div class="field col-12 sm:col-6 mb-0 validation-code validation-code-text">
              <div>
                <p class="mt-0 mb-2" v-html="t('accountSettings.2fa.step2Help2Html')"></p>
                <div class="white-space-nowrap">
                  <b class="mx-1" v-for="(item, i) in currentSecret.Secret.match(/.{1,4}/g)" :key="i">
                    {{ item }}
                  </b>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="field col-12 mb-0 field-2fa-validate-code">
          <div class="formgrid grid">
            <div class="field col-12 md:col-8 xl:col-7 mb-0">
              <label for="current2faCode">{{ t('accountSettings.2fa.2FACode') }}</label>
              <div>
                <InputText
                  class="w-full"
                  id="current2faCode"
                  inputClass="inputfield w-full"
                  v-model="current2faCode"
                  @keyup.enter="validateCode"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="formgrid grid" v-else-if="current2faStep === 2">
        <div class="field col-12 md:col-8 xl:col-7">
          <label for="currentPassword">{{ t('common.password') }}</label>
          <div>
            <Password
              class="w-full"
              id="currentPassword"
              inputClass="inputfield w-full"
              v-model="currentPassword"
              :feedback="false"
              toggleMask
            />
          </div>
        </div>
        <div class="field col-12 md:col-8 xl:col-7 mb-0">
          <label for="current2faCode">{{ t('accountSettings.2fa.2FACode') }}</label>
          <div>
            <InputText
              class="w-full"
              id="current2faCode"
              inputClass="inputfield w-full"
              v-model="current2faCode"
              @keyup.enter="disable2fa"
            />
          </div>
        </div>
      </div>
    </section>
    <div class="block md:flex align-items-center justify-content-end account-settings-button-container">
      <Button 
        @click="closeDialog" 
        :label="t('common.close')" 
        icon="pi pi-times" 
        class="hidden md:inline-flex p-button-text p-button-secondary mr-2"
      />
      <div v-if="authStore.is2faEnabled === null">
      </div>
      <Button 
        :label="t('accountSettings.2fa.setup2FA')" 
        icon="pi pi-cog"
        @click="generateSecret"
        :disabled="!currentPassword"
        class="account-settings-save-button user-settings-2fa"
        v-else-if="current2faStep === 0"
      />
      <Button 
        :label="t('accountSettings.2fa.validateCode')"
        icon="pi pi-check"
        @click="validateCode"
        :disabled="!current2faCode"
        class="account-settings-save-button user-settings-2fa"
        v-else-if="current2faStep === 1"
      />
      <Button 
        :label="t('accountSettings.2fa.disable2FA')"
        icon="pi pi-times" 
        class="p-button-danger account-settings-save-button user-settings-2fa"
        @click="disable2fa"
        :disabled="!currentPassword || !current2faCode"
        v-else-if="current2faStep === 2"
      />
    </div>

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import Password from 'primevue/password';
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import Tag from 'primevue/tag';
import ProgressSpinner from 'primevue/progressspinner';
import { useAuthStore } from "@/stores/auth";
import { Secret2faModel } from "@/models/auth/Secret2faModel";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    InputText,
    Password,
    Button,
    BlockUI,
    Tag,
    ProgressSpinner
  },
})
class UserSettings2faView extends Vue {
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  created(): void {
    this.load2faStatus();
  }

  authStore = useAuthStore();
  
  currentPassword = "";
  current2faCode = "";
  current2faStep = 0;
  currentSecret: Secret2faModel | null = null;

  async load2faStatus(): Promise<void> {
    await this.authStore.load2faStatus();
    if (this.authStore.is2faEnabled) {
      this.current2faStep = 2;
    }
  }

  async generateSecret(): Promise<void> {
    if (this.currentPassword) {
      const response = await this.authStore.generateSecret(this.currentPassword);
      if (response) {
        this.currentSecret = response;
        this.current2faStep += 1;
      }
    }
  }

  async validateCode(): Promise<void> {
    if (this.current2faCode) {
      const response = await this.authStore.confirm2fa(this.current2faCode);
      if (response) {
        this.current2faStep += 1;
        this.currentSecret = null;
        this.currentPassword = "";
        this.current2faCode = "";
      }
    }
  }

  async disable2fa(): Promise<void> {
    if (this.currentPassword && this.current2faCode) {
      await this.authStore.disable2fa(this.currentPassword, this.current2faCode);
      if (!this.authStore.is2faEnabled) {
        this.current2faStep = 0;
        this.currentPassword = "";
        this.current2faCode = "";
      }
    }
  }

  @Emit
  closeDialog(): void {
    // nothing to do, just emit an event
  }
}

export default UserSettings2faView;
</script>