<template>
  <div class="widget-component-date-range">
    <div class="flex align-items-center mr-auto" v-if="aws && gdrs?.active">
      <InputSwitch 
        :inputId="`activateDeactivateGdrs-${uniqueKey}`" 
        class="vertical-align-top"
        v-model="useGDRS"
        @change="saveWidget"
      />
      <label :for="`activateDeactivateGdrs-${uniqueKey}`"  class="text-lg font-light mb-0 ml-2">GDRS</label>
    </div>


    <!-- gdrs enabled-->
    <div v-if="aws?.useGDRS && gdrs?.active" class="widget-date-range" :class="widgetDescription?.features.includes(widgetFeatures.dateRangeCompact) ? '' : 'has-min-height'">
      <!-- custom date range - display two dates -->
      <div class="data-range" v-if="isDateRangeCustom(gdrs.rangePreset)">
        <span class="flex align-items-center column-gap-1"><DateTimezoneView :date="dateFromGDRS" timezone="local" :hideSeconds="true"/> <span>-</span></span> <DateTimezoneView :date="dateToGDRS" timezone="local" :hideSeconds="true"/>
      </div>
      <!-- pre-defined date range - display date range name, ie Today -->
      <div class="data-range" v-else>{{getDateRangeLabel(gdrs.rangePreset)}}</div>
    </div>
    
    <!-- gdrs disabled -->
    <div v-else class="widget-date-range" :class="widgetDescription?.features.includes(widgetFeatures.dateRangeCompact) ? '' : 'has-min-height widget-date-range-with-btns'">
      <!-- buttons, show only for charts, hide for data points -->
      <div v-if="!widgetDescription?.features.includes(widgetFeatures.dateRangeCompact)" class="widget-date-range-btns">
        <Button @click="goBackForwardEvent(false)" v-tippy="'Go back'" icon="pi pi-chevron-left" class="p-button-rounded p-button-icon-only p-button-outlined"/>
        <Button @click="goBackForwardEvent(true)" v-tippy="'Go forward'" icon="pi pi-chevron-right" class="p-button-rounded p-button-icon-only p-button-outlined"/>
        <Button :disabled="wds?.rangePresetHolder === wds?.rangePreset" @click="resetFromHolder" v-tippy="'Reset'" icon="pi pi-history" class="p-button-rounded p-button-icon-only p-button-outlined"/>
      </div>
      <!-- click show date range select dialog -->
      <div class="data-range-container" @click="toggleOverlay" v-tippy="'Date Range'">
        <!-- custom date range - display two dates -->
        <div class="data-range" v-if="isDateRangeCustom(selectedDateRange)">
          <span class="flex align-items-center column-gap-1"><DateTimezoneView :date="dateFrom" timezone="local" :hideSeconds="true"/> <span>-</span></span> <DateTimezoneView :date="dateTo" timezone="local" :hideSeconds="true"/>
        </div>
        <!-- pre-defined date range - display date range name, ie Today -->
        <div class="data-range" v-else>{{getDateRangeLabel(selectedDateRange)}}</div>
        <i class="pi pi-chevron-down"></i>
      </div>
    </div>

    <OverlayPanel ref="dateRangeOverlayPanel" appendTo="#overlayPanelPlaceholder" :showCloseIcon="true" :dismissable="dismissableOverlay" :breakpoints="{'470px': '310px'}" :style="{width: '400px'}">
      <div class="widget-date-range-controls formgrid grid">
        <div class="col-12 mb-0">
          <div class="formgrid grid align-items-end">
            <div class="field col-6">
              <label for="selectDateRangeWidget">Date Range</label>
              <div>
                <Dropdown
                  v-model="selectedDateRange"
                  :options="dateRanges"
                  optionValue="key"
                  optionLabel="name"
                  placeholder="Select Date Range"
                  @change="setDates(false)"
                  id="selectDateRangeWidget"
                  class="w-full"
                />
              </div>
            </div>
            <div class="field col-6" v-if="hasDataAggregation">
              <label for="dataAggregationWidget">Data Aggregation</label>
              <div>
                <Dropdown
                  v-model="selectedDataAggregation"
                  :options="dataAggregations"
                  optionValue="key"
                  optionLabel="name"
                  placeholder="Select Data Aggregation"
                  @change="setDataAggregation"
                  id="dataAggregationWidget"
                  class="w-full"
                />
              </div>
            </div>
            <div class="field col-6" v-if="widgetDescription?.features.includes(widgetFeatures.dateRangeCompact)">
              <div class="widget-date-range widget-date-range-with-btns">
                <div class="widget-date-range-btns">
                  <Button :disabled="wds?.rangePresetHolder === wds?.rangePreset" @click="resetFromHolder" v-tippy="'Reset'" icon="pi pi-history" class="p-button-rounded p-button-icon-only p-button-outlined"/>  
                  <Button @click="goBackForwardEvent(false)" v-tippy="'Go back'" icon="pi pi-chevron-left" class="p-button-rounded p-button-icon-only p-button-outlined"/>
                  <Button @click="goBackForwardEvent(true)" v-tippy="'Go forward'" icon="pi pi-chevron-right" class="p-button-rounded p-button-icon-only p-button-outlined"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 mb-0">
          <div class="formgrid grid">
            <div class="field col-6 mb-0">
              <label for="startDateWidget">Start Date</label>
              <div>
                <Calendar
                  ref="calendarFrom"
                  v-model="dateFrom"
                  :maxDate="dateTo"
                  :showTime="true"
                  dateFormat="dd/mm/yy"
                  @date-select="onChangeDate()"
                  @change="onChangeDate()" 
                  @show="onCalendarShow"
                  @hide="onCalendarHide"
                  panelClass="with-max-width"
                  id="startDateWidget"
                >
                  <template #footer>
                    <div class="flex justify-content-end pb-3">
                      <Button
                        label="Close"
                        icon="pi pi-times"
                        @click="closeCalendar"
                        class="p-button-text p-button-secondary"
                      />
                    </div>
                  </template>
                </Calendar>
              </div>
            </div>
            <div class="field col-6 mb-0">
              <label for="endDateWidget">End date</label>
              <div>
                <Calendar
                  ref="calendarTo"
                  v-model="dateTo"
                  :minDate="dateFrom"
                  :showTime="true"
                  dateFormat="dd/mm/yy"
                  @date-select="onChangeDate()"
                  @change="onChangeDate()" 
                  @show="onCalendarShow"
                  @hide="onCalendarHide"
                  panelClass="with-max-width"
                  id="endDateWidget"
                >
                  <template #footer>
                    <div class="flex justify-content-end pb-3">
                      <Button
                        label="Close"
                        icon="pi pi-times"
                        @click="closeCalendar"
                        class="p-button-text p-button-secondary"
                      />
                    </div>
                  </template>
                </Calendar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </OverlayPanel>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import { nextTick, PropType } from "vue";
import DashboardState from "@/store/states/DashboardState";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { WidgetFeature } from "@/models/enums/WidgetFeature";
import { BasicWidgetSettings } from "@/models/dashboard/BasicWidgetSettings";
import { AdvancedWidgetSettings } from "@/models/dashboard/AdvancedWidgetSettings";
import { WidgetDataSettings } from "@/models/dashboard/WidgetDataSettings";
import moment from "moment";
import { debounce } from 'throttle-debounce';
import { Watch } from "vue-facing-decorator";
import { v4 as uuidv4 } from 'uuid';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import { TimeRange } from "@/models/enums/TimeRange";
import OverlayPanel from "primevue/overlaypanel";
import Tag from 'primevue/tag';
import InputSwitch from 'primevue/inputswitch';
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { WidgetDescription } from "@/models/dashboard/WidgetDescription";
import WidgetHelper from "@/helpers/WidgetHelper";
import DateHelper from "@/helpers/DateHelper";
import { GDRSModel } from "@/models/dashboard/GDRSModel";

@Component({
  components: {
    Dropdown,
    Calendar,
    Button,
    OverlayPanel,
    Tag,
    InputSwitch,
    DateTimezoneView
  },
  directives: {
  }
})
class DashboardWidgetDateRangeView extends Vue {
  @Prop({ required: true }) widgetConfig!: WidgetConfig;
  @Prop({ required: true }) dashboardId!: string;

  uniqueKey = uuidv4();

  widgetFeatures = WidgetFeature;

  get widgetDescription(): WidgetDescription | undefined {
    return WidgetHelper.getWidget(this.widgetConfig.widgetType);
  }

  get hasDataAggregation(): boolean | undefined {
    return this.widgetDescription?.features.includes(WidgetFeature.dataAggregation);
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get useGDRS(): boolean {
    return !!this.aws?.useGDRS;
  }

  set useGDRS(value: boolean) {
    if (this.aws) {
      this.aws.useGDRS = value ? 1 : 0;
    }
  }

  dateFromGDRS: Date = new Date();
  dateToGDRS: Date = new Date();

  created() {
    this.fromWidgetConfigToVariables();
    this.fromGDRSToVariables();
  }

  get canEditDashboard(): boolean {
    const canEditDashboard = this.$store.getters["dashboard/canEditDashboard"] as (id: string) => boolean;
    const result = canEditDashboard(this.dashboardId);
    return result;
  }

  async saveWidget(): Promise<void> {
    if (this.canEditDashboard && this.widgetConfig) {
      await this.$store.dispatch("dashboard/saveWidget", this.widgetConfig);
      const state = this.dashboardState.widgetState[this.widgetConfig.guid];
      if (state && state[0] && !state[2]) {
        // ok
      }
    }
  }

  @Watch('gdrs', { immediate: false, deep: true })
  fromGDRSToVariables(): void {
    if (this.gdrs) {
      const selectedDateRange = this.gdrs.rangePreset;
      if (this.gdrs.rangePreset === TimeRange.Custom) {
        const startDateM = moment(`${this.gdrs.startDate}T${this.gdrs.startTime}`);
        const endDateM = moment(`${this.gdrs.endDate}T${this.gdrs.endTime}`);
        this.dateFromGDRS = startDateM.toDate();
        this.dateToGDRS = endDateM.toDate();
      } else {
        const dates = DateHelper.timeRangeToMoments(selectedDateRange);
        const start = dates[0];
        const end = dates[1];
        if (start) {
          this.dateFromGDRS = start.toDate();
        }
        if (end) {
          this.dateToGDRS = end.toDate();
        }
      }
    }
  }

  internalWDSUpdate = false;
  @Watch('wds', { immediate: false, deep: true })
  fromWidgetConfigToVariables(): void {
    if (this.wds && !this.internalWDSUpdate) {
      this.selectedDateRange = this.wds.rangePreset;
      if (this.wds.rangePreset === TimeRange.Custom) {
        const startDateM = moment(`${this.wds.startDate}T${this.wds.startTime}`);
        const endDateM = moment(`${this.wds.endDate}T${this.wds.endTime}`);
        this.dateFrom = startDateM.toDate();
        this.dateTo = endDateM.toDate();
      } else {
        this.setDates(true);
      }
      if (this.hasDataAggregation) {
        if (this.wds.autoAggPeriod) {
          this.selectedDataAggregation = -1;
        } else {
          this.selectedDataAggregation = this.wds.aggPeriod;
        }
      }
    }
    this.internalWDSUpdate = false;
  }

  fromVariablesToWidgetConfig(): void {
    if (this.wds) {
      if (this.selectedDateRange !== TimeRange.Custom) {
        this.wds.rangePresetHolder = this.selectedDateRange;
      }
      this.wds.rangePreset = this.selectedDateRange;
      if (this.selectedDateRange === TimeRange.Custom) {
        const startDateM = moment(this.dateFrom);
        const endDateM = moment(this.dateTo);
        this.wds.startDate = startDateM.format("YYYY-MM-DD");
        this.wds.startTime = startDateM.format("HH:mm:ss");
        this.wds.endDate = endDateM.format("YYYY-MM-DD");
        this.wds.endTime = endDateM.format("HH:mm:ss");
      } else {
        this.wds.startDate = "";
        this.wds.startTime = "";
        this.wds.endDate = "";
        this.wds.endTime = "";
      }
      if (this.hasDataAggregation) {
        this.wds.autoAggPeriod = this.selectedDataAggregation === -1;
        if (this.selectedDataAggregation !== -1) {
          this.wds.aggPeriod = this.selectedDataAggregation;
        }
      }
      this.internalWDSUpdate = true;
      this.saveWidget();
    }
  }

  resetFromHolder(): void {
    if (this.wds) {
      if (typeof this.wds.rangePresetHolder === "undefined") {
        this.wds.rangePresetHolder = TimeRange.Today;
      }
      this.wds.rangePreset = this.wds.rangePresetHolder;
      this.fromWidgetConfigToVariables();
    }
  }

  debounceGoBackForward = debounce(500, this.goBackForward);

  goBackForwardCounter = 0;

  goBackForwardEvent(forward: boolean): void {
    if (forward) {
      this.goBackForwardCounter++;
    } else {
      this.goBackForwardCounter--;
    }
    this.debounceGoBackForward();
  }

  goBackForward(): void {
    const counter = this.goBackForwardCounter;
    if (counter !== 0) {
      this.goBackForwardCounter = 0;
      const dates = DateHelper.goBackForward(counter, this.dateFrom, this.dateTo);
      this.dateFrom = dates[0];
      this.dateTo = dates[1];
      this.selectedDateRange = TimeRange.Custom;
      this.fromVariablesToWidgetConfig();
    }
  }

  dateFrom: Date = new Date();
  dateTo: Date = new Date();
  selectedDateRange = TimeRange.Custom;

  dateRanges = DateHelper.getDateRanges();

  isDateRangeCustom(timeRange: TimeRange): boolean {
    return timeRange === TimeRange.Custom;
  }

  getDateRangeLabel(timeRange: TimeRange): string {
    const label = this.dateRanges.find(x => x.key === timeRange);
    return label ? label.name : "-";
  }

  setDates(silent: boolean): void {    
    const dates = DateHelper.timeRangeToMoments(this.selectedDateRange);
    const start = dates[0];
    const end = dates[1];
    if (start) {
      this.dateFrom = start.toDate();
    }
    if (end) {
      this.dateTo = end.toDate();
    }
    if (!silent) {
      this.fromVariablesToWidgetConfig();
    }
  }

  closeCalendar(): void {
    if (this.$refs.calendarFrom) {
      (this.$refs.calendarFrom as any).overlayVisible = false;
    }
    if (this.$refs.calendarTo) {
      (this.$refs.calendarTo as any).overlayVisible = false;
    }
  }

  onChangeDate(): void {
    this.debounceSelectedDateRangeTrigger();
  }

  debounceSelectedDateRangeTrigger = debounce(500, this.onSelectedDateRangeChanged);

  onSelectedDateRangeChanged(): void {
    this.selectedDateRange = TimeRange.Custom;
    
    this.fromVariablesToWidgetConfig();
  }

  // #region overlay
  dismissableOverlay = true;

  onCalendarShow(): void {
    this.dismissableOverlay = false;
  }

  async onCalendarHide() : Promise<void> {
    await nextTick();
    this.dismissableOverlay = true;
  }

  toggleOverlay(event: Event): void {
    if (this.$refs.dateRangeOverlayPanel) {
      (this.$refs.dateRangeOverlayPanel as OverlayPanel).toggle(event);
    }
  }
  // #endregion overlay

  // #region data aggregation
  selectedDataAggregation = -1;

  dataAggregations = DateHelper.getDataAggregations();

  setDataAggregation(): void { 
    this.fromVariablesToWidgetConfig();
  }
  // #endregion data aggregation
}

export default DashboardWidgetDateRangeView;
</script>
