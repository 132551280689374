<template>
  <div>
    <DataTable
      v-if="haystackDefsStore.isLoaded"
      :value="libs" 
      dataKey="Id"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="750px" 
      class="p-datatable-sm responsive-breakpoint semantics-config-libs-table">
      <template #header>
        <div class="table-header">
          <div class="sm:flex sm:align-items-center sm:justify-content-between sm:gap-3">
            <div class="sm:flex-shrink-0">
              <Button 
                label="Create Lib" 
                icon="pi pi-plus-circle" 
                class="my-1" 
                @click="openEditDialog(null)" 
                :disabled="!(canEditGlobal || canEditOrganisation)"
              />
              <Button 
                label="Import" 
                icon="pi pi-cloud-upload" 
                class="my-1 ml-2 p-button-outlined" 
                @click="openImportTrioDialog"
                :disabled="!(canEditGlobal || canEditOrganisation)"
              />
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div v-if="haystackDefsStore.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Def" header="Def" headerStyle="width: 12%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <span class="block with-inline-btn">
            {{ slotProps.data.Def }}
            <span v-if="slotProps.data.OrganisationId === 0" v-tippy="'Public'" class="table-cell-icon">
              <i class="pi pi-globe"></i>
            </span>
          </span>
        </template>
      </Column>
      <Column field="Trio" header="Version" headerStyle="width: 10%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <span>{{ getCell(slotProps.data, "version") }}</span>
        </template>
      </Column>
      <Column field="Trio" header="Depends" headerStyle="width: 20%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <span>{{ getCell(slotProps.data, "depends") }}</span>
        </template>
      </Column>
      <Column field="Trio" header="Doc" headerStyle="width: 30%; min-width: min-content;" headerClass="no-break-word">
        <template #body="slotProps">
          <div>
            <span class="semantics-config-doc" v-html="docToHtml(getCell(slotProps.data, 'doc'))"></span>
            <a 
              v-if="getCell(slotProps.data, 'wikipedia')" 
              :href="getCell(slotProps.data, 'wikipedia')" 
              target="_blank"
            >
              Wikipedia
            </a>
          </div>
        </template>
      </Column>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <div class="inline-flex">
            <Button 
              v-tippy="'Export'"
              icon="pi pi pi-cloud-download" 
              class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
              @click="startExport(slotProps.data)" 
            />
            <Button 
              v-tippy="'Edit'"
              icon="pi pi-pencil" 
              class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
              @click="openEditDialog(slotProps.data)" 
              :disabled="!(canEditGlobal || slotProps.data.OrganisationId > 0 && canEditOrganisation)"
            />
            <Button 
              v-tippy="'Delete'"
              icon="pi pi-trash" 
              class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
              @click="openDeleteConfirmation(slotProps.data)" 
              :disabled="!(canEditGlobal || slotProps.data.OrganisationId > 0 && canEditOrganisation)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
      <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
    </div>
    
    <Dialog header="Lib" v-model:visible="displayEditDialog" class="semantics-config-dialog" :modal="true" :breakpoints="{'1599.98px': '56rem','991.98px': '90%'}" :style="{width: '62rem'}">
      <div class="dialog-content" v-if="selectedEntity">
        <BlockUI :blocked="haystackDefsStore.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="haystackDefsStore.updateInProgress ? 'blockui-blocked' : ''">
          <div class="semantics-settings-container" v-if="editorValue === 'Basic'">
            <div class="formgrid grid" v-if="keys.length">
              <HaystackDictEditView
                inputId="root"
                v-model="selectedEntityHaysonDict" 
                field="root" 
                :def="selectedEntity.Def"
                idPrefix="lib"
                :excludeFields="excludeFields"
                :mandatoryFields="mandatoryFields"
                :isLib="true"
                :isFirstLevel="true"
              />
            </div>
          </div>
          <div class="semantics-settings-container" v-else>
            <div class="field mt-0 mb-0 semantics-expert-field">
              <label>Trio</label>
              <div>
                <Textarea 
                  id="lib-edit-trio"
                  rows="3"
                  class="inputfield w-full"
                  v-model="selectedEntity.Trio"
                  :autoResize="true"
                />
              </div>
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #header>
        <div class="flex flex-wrap column-gap-4 sm:flex-nowrap sm:flex-auto sm:justify-content-between sm:align-items-center">
          <span class="p-dialog-title w-full sm:w-auto mb-3 sm:mb-0">Lib</span>
          <div class="w-full sm:w-auto gap-4 flex align-items-center">
            <div>
              <div class="semantics-public-switch-field" v-if="canEditGlobal">
                <div class="flex align-items-center">
                  <InputSwitch 
                    inputId="lib-edit-global"
                    v-model="selectedEntityIsGlobal"
                    class="vertical-align-top"
                    :disabled="haystackDefsStore.updateInProgress"
                  />
                  <label for="lib-edit-global" class="mb-0 ml-2">Public</label>
                </div>
              </div>
            </div>
            <SelectButton 
              v-model="editorValue" 
              :options="editorOptions"
              class="w-10rem"
              :allowEmpty="false"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeEditDialog" class="p-button-text p-button-secondary"/>
        <Button label="Save" :icon="haystackDefsStore.updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveChanges" :disabled='haystackDefsStore.updateInProgress' />
      </template>
    </Dialog>
    
    <Dialog header="Import" v-model:visible="displayImportDialog" class="import-lib-dialog" :modal="true" :breakpoints="{'991.98px': '36rem'}" :style="{width: '56rem'}">
      <div class="dialog-content">
        <BlockUI :blocked="haystackDefsStore.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="haystackDefsStore.updateInProgress ? 'blockui-blocked' : ''">
          <div class="field mb-0">
            <label 
              for="lib-import-file"
            >
              Trio File
            </label>
            <div class="fileupload-no-img">
              <FileUpload 
                name="files[]" 
                :url="uploadUrl" 
                @before-send="beforeSend" 
                @upload="onUpload" 
                @error="onError" 
                :multiple="true"
                :auto="false" 
                accept=".trio" 
                :withCredentials="true" 
                chooseIcon="pi pi-plus-circle"
                :maxFileSize="500000"
                chooseLabel="Select Trio File"
              >
                <template #empty>
                    <p class="mt-0 mb-5 mx-2 md:mx-3">Drag and drop files to here to upload.</p>
                </template>
              </FileUpload>
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #header>
        <div class="flex-auto flex justify-content-between align-items-center gap-4">
          <span class="p-dialog-title">Import</span>
          <div class="semantics-public-switch-field pr-3" v-if="canEditGlobal">
            <div class="flex align-items-center">
              <InputSwitch 
                inputId="lib-edit-global"
                v-model="globalImport"
                class="vertical-align-top"
                :disabled="haystackDefsStore.updateInProgress"
              />
              <label for="lib-edit-global" class="mb-0 ml-2">Public</label>
            </div>
          </div>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import BlockUI from 'primevue/blockui';
import Dialog from 'primevue/dialog';
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import { Component, Vue, Watch } from "vue-facing-decorator";
import { useHaystackDefsStore } from "@/stores/haystackDefs";
import HaystackDefsService from "@/services/HaystackDefsService";
import { HaystackDefsEntity } from "@/models/nav-tree/HaystackDefsEntity";
import ConfirmationService from "@/services/ConfirmationService";
import moment from "moment";
import { AllUserData } from "@/models/user/AllUserData";
import { HDict, HaysonDict, HaysonKindVal, HaysonVal } from 'haystack-core';
import HaystackValueEditView from "@/components/views/tags/haystack-edit/HaystackValueEditView.vue";
import AuthState from '@/store/states/AuthState';
import ToastService from '@/services/ToastService';
import FileUpload, { FileUploadBeforeSendEvent, FileUploadErrorEvent } from 'primevue/fileupload';
import ErrorHelper from '@/helpers/ErrorHelper';
import saveAs from 'file-saver';
import HaystackDictEditView from "@/components/views/tags/haystack-edit/HaystackDictEditView.vue";
import { useOrganisationStore } from '@/stores/organisation';

@Component({
  components: {
    BlockUI,
    Dialog,
    Button,
    DataTable,
    Column,
    ProgressSpinner,
    InputText,
    InputSwitch,
    HaystackValueEditView,
    FileUpload,
    SelectButton,
    Textarea,
    HaystackDictEditView
  },
})
class TagManagerConfigLibsView extends Vue {
  haystackDefsStore = useHaystackDefsStore();

  get auth(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get canEditGlobal(): boolean {
    return !!this.auth.permissions?.BitpoolAdmin;
  }

  get canEditOrganisation(): boolean {
    return !!this.auth.permissions?.FullAccess;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  editorValue = "Basic";
  editorOptions = ["Basic", "Expert"];
  @Watch('editorValue', { immediate: false, deep: false })
  onIsChristmasChanged(val: string, oldVal: string): void {
    if (val === "Basic") {
      this.selectedEntityToTuples();
    } else {
      this.tuplesToSelectedEntity();
    }
  }

  getCell(defEntity: HaystackDefsEntity, column: string): string {
    const grid = HaystackDefsService.trioStringToHGrid(defEntity.Trio);
    const rows = grid.getRows();
    if (rows.length) {
      const docCell = rows[0].get(column);
      if (docCell) {
        let result = docCell.toString();
        if (result.startsWith("[")) {
          result = result.replaceAll("[", "").replaceAll("]", "").trim()
        }
        return result;
      }
    }
    return "";
  }

  docToHtml(doc: string): string {
    return HaystackDefsService.docToHtml(doc);
  }

  get libs(): HaystackDefsEntity[] {
    const defs = HaystackDefsService.getDefs();
    if (defs && this.haystackDefsStore.data?.length) {
      const libs = HaystackDefsService.hDictArrayToStringArray(defs.libs);
      const entities: HaystackDefsEntity[] = [];
      this.haystackDefsStore.data.forEach(defEntity => {
        if (libs.includes(defEntity.Def)) {
          entities.push(defEntity);
        }
      });
      return entities;
    }
    return [];
  }

  findFieldDescription(field: string): string {
    return HaystackDefsService.findFieldDescription(field);
  }

  // #region Import
  displayImportDialog = false;
  globalImport = false;

  openImportTrioDialog(): void {
    this.displayImportDialog = true;
  }

  get uploadUrl() {
    return `${this.$store.state.apiUrl}/rest/HaystackDefs_V1/import-trio?global=${this.globalImport}`;
  }

  // Callback to invoke before file send begins to customize the request such as adding headers.
  beforeSend(event: FileUploadBeforeSendEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.formData: FormData object.
    event.xhr.setRequestHeader("Authorization", this.$store.state.auth.authKey);
    event.xhr.setRequestHeader("Accept", "application/json");
  }

  // Callback to invoke when file upload is complete.
  onUpload(event: FileUploadErrorEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Uploaded files.
    this.haystackDefsStore.load();
    ToastService.showToast("success", "", "Upload complete!", 5000);
  }

  // Callback to invoke if file upload fails.
  onError(event: any): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Files that are not uploaded.
    ToastService.showToast("error", "Can't upload trio", ErrorHelper.handleAxiosError(event.xhr).message, 5000);
  }
  // #endregion Import

  // #region Export
  startExport(defEntity: HaystackDefsEntity): void {
    const libName = defEntity.Def;
    const entities = this.haystackDefsStore.data?.filter(defEntity => defEntity.Lib === libName) ?? [];
    if (entities.length) {
      const trioString = entities.map(x => x.Trio).join("---\r\n");
      const blob = new Blob([trioString], {type: "text/plain;charset=utf-8"});
      saveAs(blob, `${libName}.trio`);
      ToastService.showToast("success", "", "Export complete!", 5000);
    } else {
      ToastService.showToast("warn", "", "Nothing to export", 5000);
    }
  }
  // #endregion Export

  // #region Edit
  displayEditDialog = false;
  selectedEntity: HaystackDefsEntity | null = null;
  selectedEntityHaysonDict: HaysonDict = {};
  
  get keys(): string[] {
    const result: string[] = [];
    const haysonDict = this.selectedEntityHaysonDict;
    for (const key in haysonDict) {
      result.push(key);
    }
    return result;
  }

  excludeFields = ["is", "lib"];
  mandatoryFields = ["def", "doc", "version", "baseUri"];

  get selectedEntityIsGlobal(): boolean {
    return this.selectedEntity?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.selectedEntity) {
      if (value) {
        this.selectedEntity.OrganisationId = 0;
      } else {
        this.selectedEntity.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  openEditDialog(defEntity: HaystackDefsEntity | null): void {
    if (defEntity) {
      this.selectedEntity = JSON.parse(JSON.stringify(defEntity));
    } else {
      const nowUtc = moment.utc().toDate();
      const trio = `baseUri:\`https://example.com/def/myLib/\`
def:^lib:myLib
depends:[^lib:ph,^lib:phIoT,^lib:phIct,^lib:phScience,^lib:bp]
doc:My Lib description
is:[^lib]
lib:^lib:myLib
version:"1.0.0"`;
      this.selectedEntity = {
        Id: "",
        Def: "",
        ContainedBy: "",
        TagOn: [],
        Is: [],
        Lib: "",
        Trio: trio,
        OrganisationId: this.organisationStore.currentOrganisation?.Id ?? -1,
        Created: nowUtc,
        Updated: nowUtc,
        CreatedBy: this.allUserData.userName,
        UpdatedBy: this.allUserData.userName
      };
    }
    this.selectedEntityToTuples();
    this.displayEditDialog = true;
  }

  selectedEntityToTuples(): void {
    if (this.selectedEntity) {
      const grid = HaystackDefsService.trioStringToHGrid(this.selectedEntity.Trio);
      const rows = grid.getRows();
      if (rows.length) {
        const row = rows[0];
        const rowHayson = row.toJSON();
        this.selectedEntityHaysonDict = { root: rowHayson };
      } else {
        this.selectedEntityHaysonDict = {};
      }
    }
  }

  closeEditDialog(): void {
    this.displayEditDialog = false;
    this.selectedEntity = null;
  }

  async saveChanges(): Promise<void> {
    if (this.selectedEntity) {
      if (this.editorValue !== "Basic") {
        this.selectedEntityToTuples();
      }
      this.selectedEntity.UpdatedBy = this.allUserData.userName;
      const nowUtc = moment.utc().toDate();
      this.selectedEntity.Updated = nowUtc;
      // lib = def
      const defHaysonDict= (this.selectedEntityHaysonDict["root"] as HaysonDict)["def"];
      if (defHaysonDict) {
        const newLibValue: HaysonVal = JSON.parse(JSON.stringify(defHaysonDict));
        (this.selectedEntityHaysonDict["root"] as HaysonDict)["lib"] = newLibValue;
      }
      const values: Record<string, HaysonVal> = {};
      let def = "";
      let baseUri = "";
      let baseUriUnique = true;
      let version = "";
      const haysonDict: HaysonDict = this.selectedEntityHaysonDict["root"] ? this.selectedEntityHaysonDict["root"] as HaysonDict : {};
      for (const key in haysonDict) {
        const value = haysonDict[key];
        values[key] = value;

        // validation
        if (value){
          if (key === "def") {
            def = typeof value === "string" ? value : (value as HaysonKindVal).val;
          } else if (key === "baseUri") {
            baseUri = typeof value === "string" ? value : (value as HaysonKindVal).val;
          } else if (key === "version") {
            version = typeof value === "string" ? value : (value as HaysonKindVal).val;
          }
        }
      }
      if (baseUri && def) {
        const defs = HaystackDefsService.getDefs();
        if (defs) {
          const libs = defs.libs;
          for (let j = 0; j < libs.length; j++) {
            const lib = libs[j];
            if (lib.get("def")?.toString() !== this.selectedEntity.Def && lib.get("baseUri")?.toString() === baseUri) {
              baseUriUnique = false;
            }
          }
        }
      }
      if (!def || def === "^") {
        ToastService.showToast("error", "", "Please enter Def", 5000);
      } else if (!baseUri) {
        ToastService.showToast("error", "", "Please enter BaseUri", 5000);
      } else if (!baseUriUnique) {
        ToastService.showToast("error", "", "BaseUri must be unique", 5000);
      } else if (!version) {
        ToastService.showToast("error", "", "Please enter Version", 5000);
      } else {
        const hDict = new HDict(values);
        this.selectedEntity.Trio = HaystackDefsService.hGridToTrioString(hDict.toGrid());
        
        await this.haystackDefsStore.createUpdate(this.selectedEntity);
        if (!this.haystackDefsStore.updateError) {
          this.closeEditDialog()
        }
      }
    }
  }

  tuplesToSelectedEntity(): void {
    if (this.selectedEntity) {
      const values: Record<string, HaysonVal> = {};
      const haysonDict: HaysonDict = this.selectedEntityHaysonDict["root"] ? this.selectedEntityHaysonDict["root"] as HaysonDict : {};
      for (const key in haysonDict) {
        const value = haysonDict[key];
        values[key] = value;
      }
      const hDict = new HDict(values);
      let result = HaystackDefsService.hGridToTrioString(hDict.toGrid());
      // if last line equal to "---" then remove line
      if (result.endsWith("---")) {
        result = result.substring(0, result.length - 3).trim();
      }
      this.selectedEntity.Trio = result;
    }
  }
  // #endregion Edit

  // #region Delete
  openDeleteConfirmation(defEntity: HaystackDefsEntity): void {
    const message = `Are you sure you want to delete Lib?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Lib',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (defEntity) {
          this.haystackDefsStore.delete(defEntity);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion Delete
}

export default TagManagerConfigLibsView;
</script>