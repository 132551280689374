export enum AggregateType {
  None,
  Average,
  Maximum,
  Minimum,
  Difference,
  Sum,
  // deprecated
  HighDuration,
  // deprecated
  LowDuration,
  Accum,
  First,
  Last
}
