<template>
  <highcharts ng-non-bindable v-if="isMounted" ref="chartElement" class="fullWidthHeight" :options="chartOptions"></highcharts>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import AngularOptions from '../../models/AngularOptions';
import ChartDataResponse from '../../models/ChartDataResponse';
import ChartRequstBody from '../../models/ChartRequstBody';
import StreamOption from '../../models/dashboard/StreamOption';
import DateHelper from '../../helpers/DateHelper';
import moment from 'moment';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import heatmapModule from 'highcharts/modules/heatmap';
heatmapModule(Highcharts);
import HeatmapGaugeRange from '../../models/HeatmapGaugeRange';
import ChartRecord from '@/models/ChartRecord';

@Component({

  components: {
    highcharts: Chart 
  }
})
class HeatmapWidget extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  chartData: ChartDataResponse[] = [];
  streams: StreamOption[] = [];
  names: string[] = [];
  chartRecords: ChartRecord[] = [];
  yCategories: string[] = [];
  dates: [Date, Date] | null = null;

  isMounted = false;

  get chartOptions(): Highcharts.Options {
    const chartRecords = this.chartRecords;
    const yCategories = this.yCategories;
    const colorRange = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.gaugeRange as HeatmapGaugeRange[];
    const stops = this.getStops();
    const decimalPlaces = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.decimals as number;
    const units = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetUnit as string;
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "heatmap",
        animation: true,
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          }
        }
      },
      colorAxis: {
        min: colorRange[0].from,
        max: colorRange[1].to,
        stops: stops
      },
      tooltip: {
        useHTML: true,
        outside: true,
        formatter: function() {
          const timezoneOffset = (new Date()).getTimezoneOffset() * 60000;
          const value = (this.point.value === null || this.point.value === undefined) ? 'Null' : this.point.value.toFixed(decimalPlaces);
          const date = moment(((this.point as any).timestamp as number) + timezoneOffset).format('ddd, DD MMM');
          const time = this.series.xAxis.categories[this.point.x];
          return `<b>${value}</b> ${units}<br><b>${time}:00</b><br><b>${date}</b>`;
        },
      },
      yAxis: [{
        title: {
          text: null
        },
        reversed: true,
        type: "category",
        // custom implementation in labels.formatter
        //categories: yCategories,
        
        labels: {
          style: {
            fontFamily: 'Consolas'
          },
          formatter: function () {
            // hide category if this.value is too big
            const index = this.value as number;
            return index >= yCategories.length ? "" : yCategories[index];
          }
        }
      }],
      xAxis: {
        opposite: true,
        type: "category",
        categories: [
          '00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', 
          '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'
        ],
        labels: {
          style: {
            fontFamily: 'Consolas'
          }
        }
      },
      legend: {
        enabled: true,
        align: 'right',
        layout: 'vertical',
        margin: 0,
        verticalAlign: 'middle',
        y: 15
      },
      series: [{
        type: "heatmap",
        name: "",
        borderWidth: 1,
        borderColor: 'white',
        turboThreshold: 0,
        data: chartRecords
      }]
    }
  }

  created(): void {
    if (this.angularOptions && this.angularOptions.angularScope) {
      if (this.angularOptions.angularScope.vueData && this.angularOptions.angularScope.vueRequestBody) {
        this.dataUpdate(
          this.angularOptions.angularScope.vueData as ChartDataResponse[],
          this.angularOptions.angularScope.vueRequestBody as ChartRequstBody);
      }
      if (this.angularOptions.angularScope.subscribeToDataUpdate) {
        this.angularOptions.angularScope.subscribeToDataUpdate(this.dataUpdate);
      }
      if (this.angularOptions.angularScope.subscribeToMinMode) {
        this.angularOptions.angularScope.subscribeToMinMode(this.minMode);
      }
    }
  }

  mounted(): void {
    this.isMounted = true;
    if (this.angularOptions && this.angularOptions.angularScope && this.angularOptions.angularScope.subscribeToWidgetResize) {
      this.angularOptions.angularScope.subscribeToWidgetResize(this.resizeWidgetEvent);
    }
  }

  loadConfig(): void {
    if (this.angularOptions && 
      this.angularOptions.angularScope &&
      this.angularOptions.angularScope.Widget &&
      this.angularOptions.angularScope.Widget.config &&
      this.angularOptions.angularScope.Widget.config.widgetOptions &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions) {
      this.streams = this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions as StreamOption[];
      this.names = [];
      this.streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
      });
    }
  }

  getStops(): [number, string][] {
    const stops: [number, string][] = [];
    (this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.gaugeRange as HeatmapGaugeRange[]).forEach((value, key) => {
      stops.push([(value.fromP / 100), value.color]);
    });
    return stops;
  }

  dataUpdate(data: ChartDataResponse[], requestBody: ChartRequstBody): void {
    this.chartData = data;
    this.loadConfig();
    this.dates = DateHelper.extractDateFromRequestBody(requestBody);
    
    const seriesDataSource = [];
    const dateCategories = [];
    let row = -1;
    let col = 0;
    const inactiveColor = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.inactiveColor as string;

    for (let i = 0; i < data[0].Data.length; i++) {
      col = i % 24;
      if (i % 24 === 0) {
        row++;
        dateCategories.push(moment(data[0].Data[i].x).format('DD, ddd'));
      }
      seriesDataSource.push({
        x: col,
        y: row,
        value: data[0].Data[i].y,
        timestamp: data[0].Data[i].x,
        color: ""
      });
      if (data[1] && data[1].Data[i].y == 0) {
        seriesDataSource[seriesDataSource.length - 1].color = inactiveColor;
      }
    }
    this.chartRecords = seriesDataSource;
    this.yCategories = dateCategories;

    if (!seriesDataSource.length && this.angularOptions.angularScope.showNoDataMessage) {
      this.angularOptions.angularScope.showNoDataMessage();
    } else {
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  // chart reflow on widget resize
  resizeWidgetEvent(): void {
    const chartElement = this.getChartElement();
    if (chartElement) {
      chartElement.chart.reflow();
    }
  }

  minMode(enabled: boolean): void {
    // nothing to do here
  }
}

export default HeatmapWidget;
</script>