<template>
  <div class="semantics-settings-selector-view flex-shrink-0">
    <div v-if="selectedNodes.length === 0" class="semantics-settings-selector-view-empty mb-1">
      <span class="block">Selected entity</span>
      <h4 class="mb-0 font-normal font-italic">Please select entity in the tree</h4>
    </div>
    <div v-else class="mb-1">
      <div v-if="selectedNodes.length === 1" class="semantics-settings-selector-view-title">
        <span class="block">Selected entity</span>
        <h4 class="mb-0">{{ selectedNode?.label }}</h4>
      </div>
      <div v-else-if="selectedStatNodes.length > 1" class="semantics-settings-selector-view-dropdown">
        <span class="block">Selected entity</span>
        <Dropdown
          v-model="selectedKey"
          @change="onDropdownChangeEvent"
          :options="selectedStatNodes"
          optionValue="data.key"
          optionLabel="data.label"
          class="dropdown-lg w-full"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Dropdown from "primevue/dropdown"
import { Component, Model, Vue, Watch } from "vue-facing-decorator";
import { useTagManagerStore } from "@/stores/tagManager";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { Stat } from "@he-tree/vue/types/src/components/TreeProcessorVue";

@Component({
  components: {
    Dropdown
  },
})
class TagManagerSelectorView extends Vue {
  @Model selectedStatNodes!: Stat<TreeNodeForUI>[];
  get selectedNodes(): TreeNodeForUI[] {
    return this.selectedStatNodes.map((value) => value.data) as TreeNodeForUI[];
  }

  get selectedKey(): string | undefined | null {
    return this.selectedStatNode ? this.selectedStatNode.data.key : null;
  }

  set selectedKey(value: string | undefined | null) {
    if (value) {
      this.selectedStatNode = this.selectedStatNodes.find(x => x.data.key === value) ?? null;
    } else {
      this.selectedStatNode = null;
    }
  }

  changedFromDropdown = false;
  selectedStatNode: Stat<TreeNodeForUI> | null = null;
  get selectedNode(): TreeNodeForUI | null {
    return this.selectedStatNode ? this.selectedStatNode.data : null;
  }

  tagManagerStore = useTagManagerStore();

  created(): void {
    if (this.tagManagerStore.activeNode) {
      const selectedStat = this.selectedStatNodes.find(x => x.data.key === this.tagManagerStore.activeNode?.data.key);
      if (selectedStat) {
        this.selectedStatNode = selectedStat;
      } else {
        this.tagManagerStore.activeSite = null;
        this.tagManagerStore.activeParent = null;
        this.tagManagerStore.activeNode = null;
        this.tagManagerStore.isActiveNodeChanged = false;
      }
    }
    this.onSelectedNodesChanged();
  }

  async onDropdownChangeEvent(): Promise<void> {
    this.changedFromDropdown = true;
  }

  @Watch("selectedNodes", { immediate: false, deep: false })
  onSelectedNodesChanged(): void {
    if (this.selectedStatNodes.length > 0) {
      if (this.selectedStatNode) {
        const selected = this.selectedStatNodes.find(x => x.data.key === this.selectedNode?.key);
        if (!selected) {
          this.selectedStatNode = this.selectedStatNodes[0];
        }
      } else {
        this.selectedStatNode = this.selectedStatNodes[0];
      }
    } else {
      this.selectedStatNode = null;
    }
  }

  @Watch("selectedNode", { immediate: false, deep: true })
  async onSelectedNodeChanged(): Promise<void> {
    if (!this.selectedStatNode) {
      this.tagManagerStore.activeSite = null;
      this.tagManagerStore.activeParent = null;
      this.tagManagerStore.activeNode = null;
      this.tagManagerStore.isActiveNodeChanged = false;
    } else {
      if (this.selectedStatNode !== this.tagManagerStore.activeNode) {
        const isItOkToChangeSelection = !this.changedFromDropdown || await this.tagManagerStore.isItOkToChangeSelection();
        if (isItOkToChangeSelection) {
          let currentStat = this.selectedStatNode;
          while (currentStat.parent) {
            currentStat = currentStat.parent;
          }
          this.tagManagerStore.activeSite = currentStat;
          this.tagManagerStore.activeParent = this.selectedStatNode.parent ?? null;
          this.tagManagerStore.activeNode = this.selectedStatNode;
          this.tagManagerStore.isActiveNodeChanged = false;
        } else {
          this.selectedStatNode = this.tagManagerStore.activeNode;
        }
      }
    }
    this.changedFromDropdown = false;
  }
}

export default TagManagerSelectorView;
</script>