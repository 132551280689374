<template>
  <div class="dialog-content share-dashboard-dialog-content">
    <div class="share-dashboard-content share-widget-content">
      <div class="flex gap-5 sm:gap-6 align-items-center field share-dashboard-settings">
        <div class="flex-shrink-0 pt-1">
          <div class="flex align-items-center">
            <InputSwitch @change="shareChanged" v-model="enableShare" inputId="enableShare" class="vertical-align-top" :disabled="loadingInProgress" />
            <label for="enableShare" class="mb-0 ml-2">Enable</label>
          </div>
        </div>
        <div class="flex-shrink-0 pt-1" v-if="isReady && enableShare">
          <div class="flex align-items-center">
            <InputSwitch @change="shareChanged" v-model="enableWithHeader" inputId="enableWithHeader" class="vertical-align-top" :disabled="loadingInProgress" />
            <label for="enableWithHeader" class="mb-0 ml-2">With Header</label>
          </div>
        </div>
      </div>

      <div class="flex justify-content-center align-items-center" v-if="loadingInProgress">
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </div>
      <div v-else-if="!shortUrl" class="flex justify-content-center align-items-center text-center px-3 text-lg font-semibold">
        Start telling your story now, send dashboards to anyone
      </div>

      <div class="md:flex gap-5" v-if="enableShare && shortUrl">
        <section>
          <h3>Use the following link to share the selected widget.</h3>
          <div class="flex align-items-center">
            <div class="flex-initial">
              <span class="break-word">{{shortUrl}}</span>
            </div>
            <div class="flex-none ml-4">
              <Button v-tippy="'Copy link'" @click="copyValue(shortUrl)" icon="pi pi-copy text-2xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem"></Button>
              <Button v-tippy="'Refresh link'" @click="reshare" icon="pi pi-sync text-xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem ml-2"></Button>
            </div>
          </div>

          <div class="mt-4 inline-block cursor-pointer" @click="saveQR('widgetShareQR')">
            <QrcodeVue id="widgetShareQR" :value="shortUrl" render-as="svg" foreground="#133547" background="#f6f6f6" class="vertical-align-top"></QrcodeVue>
            <label class="block mt-2 text-center cursor-pointer">
              Click to save
            </label>
          </div>
        </section>
        <section>
          <h3>Use the following html code to embed the selected widget.</h3>
          <div class="flex align-items-center">
            <div class="flex-auto">
              <span class="break-word">{{urlToIFrame(shortUrl)}}</span>
            </div>
            <div class="flex-none ml-4">
              <Button v-tippy="'Copy HTML'" @click="copyValue(urlToIFrame(shortUrl))" icon="pi pi-copy text-2xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem"></Button>
            </div>
          </div>
        </section>
      </div>
      <div v-if="enableShare && shortUrl">
        <section>
          <h3>Share widget via:</h3>
          <div class="share-network-buttons">
            <ShareNetwork
              v-for="network in networks"
              :network="network.network"
              :key="network.network"
              :style="{ backgroundColor: network.color, borderColor: network.color }"
              :url="shortUrl"
              :title="`Bitpool: ${widgetConfig ? widgetConfig.widgetOptions.basicWidgetSettings.widgetName : 'widget'}`"
              hashtags="bitpool"
              class="share-network-button p-button p-component"
            >
              <i class="text-xl mr-2" :class="network.icon"></i>
              <span>{{ network.name }}</span>
            </ShareNetwork>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputSwitch from "primevue/inputswitch";
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import QrcodeVue from 'qrcode.vue'
import ShareWidgetState from "@/store/states/ShareWidgetState";
import copy from 'copy-to-clipboard';
import ToastService from "@/services/ToastService";
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import DashboardState from "@/store/states/DashboardState";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import PrintHelper from "@/helpers/PrintHelper";
import { saveAs } from 'file-saver';
import ShareWidgetRequest from "@/models/ShareWidgetRequest";
import ConfirmationService from "@/services/ConfirmationService";

@Component({
  components: {
    Button,
    InputSwitch,
    BlockUI,
    ProgressSpinner,
    TabView,
    TabPanel,
    QrcodeVue
  },
})
class ShareWidgetView extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig | null;

  enableShare = false;
  enableWithHeader = true;
  isReady = false;

  get widgetId(): string {
    return (this.widget ? this.widgetConfig?._id : undefined) ?? "";
  }

  get widgetConfig(): WidgetConfig | undefined {
    const widgetConfig = this.widget ? this.dashboardState.widgets?.find(x => x.guid === this.widget?.guid) : undefined;
    return widgetConfig;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get shareWidgetState(): ShareWidgetState {
    return this.$store.state.shareWidget;
  }

  get loadingInProgress(): boolean {
    return !this.shareWidgetState.isLoadedShared || 
      !this.shareWidgetState.isLoadedShareId || 
      !this.shareWidgetState.isLoadedShortUrl;
  }

  get isShared(): boolean {
    return !!this.shareWidgetState.isShared?.Shared;
  }

  get withHeader(): boolean {
    return !!this.shareWidgetState.isShared?.WithHeader;
  }

  get shortUrl(): string | null {
    return this.shareWidgetState.shortUrl;
  }

  created(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    await this.$store.dispatch("shareWidget/loadIsShared", this.widgetId);
    this.enableShare = this.isShared;
    this.enableWithHeader = this.withHeader;
    this.isReady = true;
  }

  async shareChanged(): Promise<void> {
    if (this.enableShare) {
      const request: ShareWidgetRequest = {
        widgetId: this.widgetId,
        withHeader: this.enableWithHeader
      };
      await this.$store.dispatch("shareWidget/share", request);
    } else {
      await this.$store.dispatch("shareWidget/unshare");
    }
  }

  async reshare(): Promise<void> {
    const message = `Are you sure you want to refresh the share link? The existing share link will be deleted.`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Refresh Share Link',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        const request: ShareWidgetRequest = {
          widgetId: this.widgetId,
          withHeader: this.enableWithHeader
        };
        await this.$store.dispatch("shareWidget/reshare", request);
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  copyValue(value: string | null): void {
    if (value) {
      copy(value);
      ToastService.showToast("success", "", "Copied!", 5000);
    }
  }

  urlToIFrame(url: string | null): string {
    if (url) {
      return `<iframe src="${url}" frameborder="0" style="width: 100%; height: auto; min-height: 300px; border: 0 none; vertical-align: top;" allowfullscreen></iframe>`;
    } else { 
      return "";
    }
  }

  async saveQR(id: string): Promise<void> {
    const element = document.getElementById(id);
    if (element) {
      const result = await PrintHelper.svgToPng(300, 300, element.outerHTML, true, "#printCanvas");
      saveAs(result, "widget-qr.png");
    }
  }

  get networks(): any[] {
    return this.$store.state.share.networks;
  }
}

export default ShareWidgetView;
</script>