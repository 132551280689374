<template>
  <div ref="root" v-if="isMounted" class="widget-type-map-freeform">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-else class="map-freeform">
      <div class="map-freeform-content">
        <LMap 
          v-if="redrawToggle"
          ref="map"
          @ready="onReady"
          :style="widgetMapStyles"
          :zoom="zoom"
          @update:zoom="zoomUpdate"
          :center="center"
          @update:center="centerUpdate"
          :options="{ attributionControl: false, keyboard: false }"
        >
          <template v-if="mapReady">        
            <LMarker
              v-for="(item, index) in markers"
              :key="index"
              :lat-lng="item.latLng"
              :icon="buildMarker(item.color)"
              @click="clickMarker(item)"
            >
              <LTooltip :options="{ permanent: true, direction: 'top', className: `tooltip-colorized-${widgetGuid}-${index}` }">
                <span>
                  <b>{{ item.name }}</b>
                </span>
              </LTooltip>
            </LMarker>
          </template>
        </LMap>
        <span style="display:none" v-html="styleTooltipSiteColors"></span>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import DashboardState from '@/store/states/DashboardState';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import { Watch } from 'vue-facing-decorator';
import ProgressSpinner from 'primevue/progressspinner';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";
import L from "leaflet";
import { v4 as uuidv4 } from "uuid";
import { TreeNodeForUI } from '@/models/nav-tree/NavTreeForUI';
import { useNavTreeStore } from '@/stores/navTree';
import { generateColorRGB } from '@marko19907/string-to-color';
import ColorHelper from '@/helpers/ColorHelper';
import chroma from 'chroma-js';
import { nextTick } from 'vue';
import EventBusHelper from '@/helpers/EventBusHelper';
import { Emitter } from 'mitt';
import NavigationHelper from '@/helpers/NavigationHelper';
import { DashboardType } from '@/models/dashboard/DashboardType';

@Component({
  components: {
    ProgressSpinner,
    WidgetNoDataView,
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  }
})
class MapWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get widgetGuid(): string {
    return this.widgetConfig.guid;
  }

  navTreeStore = useNavTreeStore();

  get modelForTree(): TreeNodeForUI[] {
    return this.navTreeStore.structuredDataForUI ?? [];
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  get widgetMapStyles(): string[] {
    const result: string[] = [
      `min-height: max(${this.aws?.minHeight ? this.aws?.minHeight : 400}px,100%)`,
      `height: ${this.aws?.minHeight ? this.aws?.minHeight : 400}px`,
      `max-height: none`
    ];
    return result;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoDataSites;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.reloadData(false);
  }

  isMounted = false;

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
    this.emitter.on("size_changed", this.resizeWidgetEvent);
    this.isMounted = true;
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
    this.emitter.off("size_changed", this.resizeWidgetEvent);
  }

  redrawToggle = true;

  // map reflow on widget resize
  @Watch("widgetSize", { immediate: false, deep: true })
  async resizeWidgetEvent(): Promise<void> {
    this.redrawToggle = false;
    await nextTick();
    this.redrawToggle = true;
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  async reloadData(silent = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds?.sites?.length === 0 || this.wds?.sites?.every(x => !x.key)) {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfiguredSites;
      this.isLodingData = false;
    }
    if (!this.navTreeStore.isLoaded) {
      if (this.navTreeStore.loadingInProgress) {
        let i = 0;
        while (this.navTreeStore.loadingInProgress) {
          // wait 0.5 second
          await new Promise(resolve => setTimeout(resolve, 500));
          i++;
          if (i > 60) {
            break;
          }
        }
      }
      if (!this.navTreeStore.isLoaded) {
        await this.navTreeStore.load();
      }
    }
    if (this.navTreeStore.structuredDataForUI === null || this.navTreeStore.structuredDataForUI.length === 0) {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoDataSites;
    }
    this.isLodingData = false;
  }
  
  zoom = 8;
  zoomUpdate(value: number): void {
    this.zoom = value;
  }
  
  center = [-27.3793729, 152.3337157];
  centerUpdate(value: any): void {
    // example: {lat: 1091.1028537158786, lng: 420.04585553556507}
    this.center = [value.lat, value.lng];
  }

  getMarkerSvg(color: string): string {
    const guid = uuidv4();
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28"><g filter="url(#a-${guid})"><path fill="url(#b-${guid})" d="M13.5 3A7.539 7.539 0 1 1 6 10.54 7.52 7.52 0 0 1 13.5 3Z"/></g><g filter="url(#c-${guid})"><path fill="#fff" d="M13.5 15a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"/><path stroke="${color}" stroke-linecap="round" stroke-linejoin="round" d="M13.5 14.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"/></g><defs><filter id="a-${guid}" width="27.078" height="27.078" x="0" y="0" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="3"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_918_22"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_918_22" result="shape"/></filter><filter id="c-${guid}" width="21" height="21" x="3" y="3" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="3"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_918_22"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_918_22" result="shape"/></filter><radialGradient id="b-${guid}" cx="0" cy="0" r="1" gradientTransform="matrix(10.7959 0 0 8.25883 13.539 8.884)" gradientUnits="userSpaceOnUse"><stop stop-color="${color}"/><stop offset="1" stop-color="${color}" stop-opacity=".631"/></radialGradient></defs></svg>`;
    return svg;
  }

  buildMarker(color: string): L.DivIcon {
    // from https://stackoverflow.com/questions/23567203/leaflet-changing-marker-color
    const markerHtmlStyles = `
      width: 44px;
      height: 44px;
      display: block;
      position: relative;
      transform: translate(1px, 16px)`;
    const svg = this.getMarkerSvg(color);
    const icon = L.divIcon({
      className: "bitpool-custom-marker",
      iconSize: [44, 44],
      iconAnchor: [22, 44],
      tooltipAnchor: [0, -24],
      popupAnchor: [0, -24],
      html: `<span style="${markerHtmlStyles}">${svg}</span>`
    });
    return icon;
  }

  mapObject: any | null = null;
  mapReady = false;

  onReady(): void {
    if (this.$refs.map) {
      this.mapObject = (this.$refs.map as any).leafletObject;

      L.gridLayer
        .googleMutant({
          type: this.wds?.mapType ? this.wds.mapType : "roadmap", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
        })
        .addTo(this.mapObject);

      this.mapReady = true;

      const coords: L.LatLng[] = [];
      this.markers.forEach((marker) => {
        coords.push(L.latLng(marker.latLng.lat, marker.latLng.lng));
      });
      const bounds = L.latLngBounds(coords)
      if (bounds.isValid()) {
        this.mapObject.fitBounds(bounds);
      }
    }
  }

  get markers(): any[] {
    const result: any[] = [];
    if (this.wds?.sites?.length === 0 || this.wds?.sites?.every(x => !x.key)) {
      // nothing
    } else {
      this.modelForTree.forEach((model, index) => {
        const config = this.wds?.sites?.find(x => x.key === model.key);
        if (config) {
          let geoCoordTag = model.tags?.find(x => x.startsWith("geoCoord="));
          if (!geoCoordTag) {
            // non standard
            const geoLatTag = model.tags?.find(x => x.startsWith("geoLat="));
            const geoLngTag = model.tags?.find(x => x.startsWith("geoLon="));
            if (geoLatTag && geoLngTag) {
              geoCoordTag = `geoCoord=${geoLatTag.replace("geoLat=", "")},${geoLngTag.replace("geoLon=", "")}`;
            }
          }
          if (geoCoordTag) {
            const coords = geoCoordTag.split("=")[1].split(",");
            if (coords.length === 2) {
              const lat = parseFloat(coords[0]);
              const lng = parseFloat(coords[1]);
              if (!isNaN(lat) && !isNaN(lng) && (lat !== 0 || lng !== 0)) {
                const name = model.label;
                // ColorHelper.getContrastColor doesn't work with hsl and rgbs, so we need to convert to hex
                // example: rgba(114.3853541438999, 223.125, 31.875, 1)
                const colorRGBA = generateColorRGB(name ?? model.key ?? "");
                const values = colorRGBA.replace("rgba(", "").replace(")", "").split(",");
                const colorHex = chroma.rgb(parseFloat(values[0]), parseFloat(values[1]), parseFloat(values[2]), parseFloat(values[3])).hex();
                result.push({ 
                  index: index,
                  latLng: { lat: lat, lng: lng }, 
                  color: colorHex,
                  name: name,
                  node: model,
                  dashboardLink: config.link
                });
              }
            }
          }
        }
      });
    }
    // result.push({ 
    //   index: 123,
    //   latLng: { lat: 0, lng: 0 }, 
    //   color: "#000",
    //   name: "Test 0, 0"
    // });
    return result;
  }

  clickMarker(item: any): void {
    if (item.dashboardLink) {
      const parts = item.dashboardLink.split(":");
      if (parts.length === 2) {
        NavigationHelper.goToDashboard(parts[0] === "o" ? DashboardType.Organisation : DashboardType.Personal, parts[1]);
      }
    }
  }

  get styleTooltipSiteColors(): string {
    let result = "";
    this.markers.forEach((marker, index) => {
      const color = marker.color;
      const fontColor = ColorHelper.getContrastColor(color);
      result += `.tooltip-colorized-${this.widgetGuid}-${index} { --freeform-tooltip-bg-color: ${color}; --freeform-tooltip-color: ${fontColor}; }`
    });
    result = `<style>${result}</style>`;
    return result;
  }
}

export default MapWidget;
</script>