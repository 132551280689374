export enum PresentationEffect {
  Fade,
  SlideLeft,
  SlideRight,
  SlideUp,
  SlideDown,
  ScaleUp,
  ScaleDown,
  Flip,
  Rotate
}