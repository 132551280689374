import { AggregateType } from "@/models/enums/AggregateType";
import { AggregationType } from "@/models/enums/AggregationType";

class EnumHelper {
  aggregateTypeToAggregationType(value: AggregateType): AggregationType {
    let result: AggregationType;
    switch (value) {
      case AggregateType.Average:
        {
          result = AggregationType.Avg;
        }
        break;

      case AggregateType.Sum:
        {
          result = AggregationType.Sum;
        }
        break;

      case AggregateType.Difference:
        {
          result = AggregationType.Diff;
        }
        break;

      case AggregateType.Minimum:
        {
          result = AggregationType.Min;
        }
        break;

      case AggregateType.Maximum:
        {
          result = AggregationType.Max;
        }
        break;

      case AggregateType.HighDuration:
        {
          result = AggregationType.HighDuration;
        }
        break;

      case AggregateType.LowDuration:
        {
          result = AggregationType.LowDuration;
        }
        break;

      default:
      case AggregateType.None:
        {
          result = AggregationType.None;
        }
        break;

      case AggregateType.Accum:
        {
          result = AggregationType.Accum;
        }
        break;

      case AggregateType.First:
        {
          result = AggregationType.First;
        }
        break;

      case AggregateType.Last:
        {
          result = AggregationType.Last;
        }
        break;
    }
    return result;
  }
}

export default new EnumHelper();
