<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path stroke="var(--report-control-button-color)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.335 5H1.668m3.333-3.333v6.667"/>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
    <path stroke="var(--report-control-button-color)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M6.361 1.667H3.575c-.411 0-.616 0-.773.084a.75.75 0 0 0-.32.335c-.08.163-.08.378-.08.807v2.913c0 .43 0 .644.08.808a.75.75 0 0 0 .32.335c.157.084.362.084.773.084H6.36c.411 0 .616 0 .773-.084a.75.75 0 0 0 .32-.335c.08-.164.08-.378.08-.808V2.893c0-.429 0-.643-.08-.807a.75.75 0 0 0-.32-.335c-.157-.084-.362-.084-.773-.084ZM10.655 6.317l2.35-4.234c.13-.234.195-.351.28-.39a.275.275 0 0 1 .232 0c.085.039.15.156.28.39l2.35 4.234c.131.235.196.352.187.449a.3.3 0 0 1-.116.21c-.076.057-.206.057-.466.057h-4.701c-.26 0-.39 0-.466-.057a.3.3 0 0 1-.116-.21c-.01-.097.056-.214.186-.449Z"/>
    <path stroke="var(--report-control-button-color)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M13.4 11.52c-.587-.716-1.565-.91-2.3-.253a1.884 1.884 0 0 0-.261 2.53c.48.645 1.932 2.006 2.407 2.447.054.049.08.074.111.083a.14.14 0 0 0 .084 0c.031-.01.058-.034.111-.083.476-.44 1.928-1.802 2.408-2.447a1.87 1.87 0 0 0-.261-2.53c-.748-.65-1.713-.463-2.3.253Z" clip-rule="evenodd"/>
    <path stroke="var(--report-control-button-color)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M4.717 9.649c.08-.18.12-.27.176-.298a.161.161 0 0 1 .15 0c.055.029.095.118.176.298l.766 1.7c.024.053.035.08.053.1a.177.177 0 0 0 .054.043c.023.012.05.016.103.025l1.713.274c.18.03.27.044.312.092.036.042.053.1.046.157-.007.066-.073.136-.203.275l-1.239 1.323a.41.41 0 0 0-.07.086.205.205 0 0 0-.021.07.458.458 0 0 0 .01.116l.293 1.868c.03.197.046.296.017.354a.177.177 0 0 1-.122.097c-.06.012-.14-.034-.302-.127l-1.53-.883a.365.365 0 0 0-.097-.046.16.16 0 0 0-.068 0 .365.365 0 0 0-.096.046l-1.531.883c-.162.093-.242.14-.302.127a.177.177 0 0 1-.122-.097c-.029-.058-.014-.157.017-.354l.293-1.868a.459.459 0 0 0 .01-.115.205.205 0 0 0-.02-.07.41.41 0 0 0-.071-.087l-1.239-1.323c-.13-.14-.196-.209-.204-.275a.204.204 0 0 1 .047-.157c.042-.048.132-.063.312-.092l1.713-.274a.354.354 0 0 0 .103-.025.177.177 0 0 0 .054-.043.431.431 0 0 0 .053-.1l.766-1.7Z"/>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class AddReportElementSvg extends Vue {
}

export default AddReportElementSvg;
</script>