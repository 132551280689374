import { defineStore } from "pinia";
import SystemState from "./states/SystemState";

export const useSystemStore = defineStore('System', {
  state: (): SystemState => ({ 
    isMobile: false,
    isIOs: false,
    os: ""
  }),
  getters: {
  },
  actions: {
  },
});
