import { GoogleMapLocation } from '@/models/ChangeLocationEvent';
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import DOMPurify from 'dompurify';
import { HGrid, trio, defs, HDict, HNamespace, TrioWriter, HList, Kind, HSymbol, HaysonCoord, HaysonDate, HaysonDict, HaysonMarker, HaysonRef, HaysonSymbol, HaysonTime, HaysonUri, HaysonVal, makeValue, toKind, zinc, memoize } from "haystack-core";
import { marked } from 'marked';

class HaystackDefsService {
  private hNamespace: HNamespace | null = null;

  public init(trioStr: string): void {
    const grid = this.trioStringToHGrid(trioStr);
    this.hNamespace = defs(grid);
  }

  public getDefs(): HNamespace | null {
    return this.hNamespace;
  }

  public trioStringToHGrid(trioStr: string): HGrid {
    const grid = trio(trioStr) as HGrid;
    return grid;
  }

  public hGridToTrioString(grid: HGrid): string {
    const writer = new TrioWriter();
    writer.addGrid(grid);
    return writer.toString();
  }

  public hDictToTrioString(dict: HDict): string {
    const writer = new TrioWriter();
    writer.addDict(dict);
    return writer.toString();
  }

  public hDictToString(dict: HDict): string {
    return dict.defName;
  }

  public hDictArrayToStringArray(dicts: HDict[]): string[] {
    return dicts.map((dict: HDict): string => this.hDictToString(dict));
  }

  public findDependentLibs(libName: string): string[] {
    const result = new Set<string>();
    if (this.hNamespace) {
      this.hNamespace.libs.forEach((x) => {
        const depends = x.get("depends");
        if (depends) {
          (depends as HList).forEach((y) => {
            if (y) {
              const name = y.toString();
              if (name === libName) {
                result.add(x.defName);
                this.findDependentLibs(x.defName).forEach((z) => result.add(z));
              }
            }
          });
        }
      });
    }
    return Array.from(result);
  }

  public findFieldDescription(field: string): string {
    const defs = this.hNamespace;
    if (defs) {
      const def = defs.get(field);
      if (def) {
        const doc = def.get("doc");
        if (doc) {
          // The first sentence up to the period is used as the summary.
          const markdown = doc
            .toString()
            .split(". ", 1)[0]
            .split(".\n", 1)[0]
            .replaceAll(
              "docHaystack::",
              "https://project-haystack.org/doc/docHaystack/"
            );
          return markdown;
        }
      }
    }
    return `${field} - unknown tag`;
  }

  public docToHtml(doc: string): string {
    let markdown = doc.replaceAll(
      "docHaystack::",
      "https://project-haystack.org/doc/docHaystack/"
    );
    // regexp to find all http and https links
    const regex1 = /`http(.*?)`/g;
    markdown = markdown.replaceAll(regex1, (match, p) => {
      const result = `(${match.replaceAll("`", "")})`;
      return result;
    });
    // regexp to find text between "pre>" and "<pre"
    const regex2 = /pre>(.*?)<pre/gs;
    markdown = markdown.replaceAll(regex2, (match, p) => {
      const result = `<pre>${p}</pre>`;
      return result;
    });
    const html = marked
      .parse(markdown)
      .replaceAll("<a href=", '<a target="_blank" href=');
    return DOMPurify.sanitize(html, { ADD_ATTR: ["target"] });
  }

  public isChoice(def: HDict): boolean {
    let result = false;
    const defTagIs = def.get("is");
    if (defTagIs) {
      (defTagIs as HList).forEach(x => {
        if (x) {
          if (x.toString() === "choice") {
            result = true;
          }
        }
      });
    }
    return result;
  }

  public defToKinds(name: string | HSymbol): Kind[] {
    const result: Kind[] = [];
    if (this.hNamespace) {
      const def = this.hNamespace.byName(name);
      if (def) {
        const hsTypeDefs = this.hNamespace.hsTypeDefs;

        const types = new Set<HDict>();
        types.add(def);
        this.hNamespace.allSuperTypesOf(def.defName).forEach(types.add, types);

        if (this.isChoice(def)) {
          result.push(Kind.Str);
        }
        if (types.has(hsTypeDefs.marker)) {
          result.push(Kind.Marker);
        }
        if (types.has(hsTypeDefs.bool)) {
          result.push(Kind.Bool);
        }
        if (types.has(hsTypeDefs.number)) {
          result.push(Kind.Number);
        }
        // weatherCond - super is marker, but weatherCond is enum
        if (types.has(hsTypeDefs.str) || def.keys.some(x => x === "enum")) {
          result.push(Kind.Str);
        }
        if (types.has(hsTypeDefs.coord)) {
          result.push(Kind.Coord);
        }
        if (types.has(hsTypeDefs.date)) {
          result.push(Kind.Date);
        }
        if (types.has(hsTypeDefs.dateTime)) {
          result.push(Kind.DateTime);
        }
        if (types.has(hsTypeDefs.dict)) {
          result.push(Kind.Dict);
        }
        if (types.has(hsTypeDefs.grid)) {
          result.push(Kind.Grid);
        }
        if (types.has(hsTypeDefs.list)) {
          result.push(Kind.List);
        }
        if (types.has(hsTypeDefs.na)) {
          result.push(Kind.NA);
        }
        if (types.has(hsTypeDefs.ref)) {
          result.push(Kind.Ref);
        }
        if (types.has(hsTypeDefs.symbol)) {
          result.push(Kind.Symbol);
        }
        if (types.has(hsTypeDefs.time)) {
          result.push(Kind.Time);
        }
        if (types.has(hsTypeDefs.uri)) {
          result.push(Kind.Uri);
        }
        if (types.has(hsTypeDefs.xstr)) {
          result.push(Kind.XStr);
        }
      }
    }
    return result;
  }

  public getCompatibleKinds(field: string, kind: Kind | undefined): Kind[] {
    const result = this.defToKinds(field);
    if (result.length === 0) {
      result.push(Kind.Marker);
      result.push(Kind.Number);
      result.push(Kind.Str);
      result.push(Kind.Bool);
      result.push(Kind.Date);
      result.push(Kind.Time);
      result.push(Kind.DateTime);
      result.push(Kind.XStr);
      result.push(Kind.Uri);
    }
    if (kind && !result.includes(kind)) {
      result.unshift(kind);
    }
    if (result.length === 1 && result[0] === Kind.Number) {
      result.push(Kind.Str); // for inf, -inf, nan
    }
    if (field === "systemRef" && result.length === 1 && result[0] === Kind.Ref) {
      result.push(Kind.List); // systemRef can be represented by list of refs
    }
    return result;
  }

  public detectKindFromString(valueStr: string): Kind | undefined {
    if (typeof valueStr === "undefined") {
      return Kind.Marker;
    }
    if (valueStr.startsWith("{") && valueStr.endsWith("}")) {
      return Kind.Dict;
    }
    if (valueStr.startsWith("[") && valueStr.endsWith("]")) {
      return Kind.List;
    }
    const valueStrLower = valueStr.toLowerCase();
    if (valueStrLower === "true" || valueStrLower === "t" || valueStrLower === "false" || valueStrLower === "f") {
      return Kind.Bool;
    }
    if (valueStr.split(",").length === 2 && valueStr.search(/[^0-9.,-]/) < 0) {
      return Kind.Coord;
    }
    const nonNumIndex = valueStr.search(/[^0-9.-]/);
    // starts from numeric and contains non-numeric or don't have any non-numeric
    if (nonNumIndex !== 0 && valueStrLower === "inf" || valueStrLower === "-inf" || valueStrLower === "nan") {
      return Kind.Number;
    }
    const dateTimeRegex = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})/;
    if (dateTimeRegex.test(valueStr)) {
      return Kind.DateTime;
    }
    const dateRegex = /^(\d{4})-(\d{2})-(\d{2})/;
    if (dateRegex.test(valueStr)) {
      return Kind.Date;
    }
    const timeRegex = /^(\d{2}):(\d{2}):(\d{2})/;
    if (timeRegex.test(valueStr)) {
      return Kind.Time;
    }
    const xStrIndex = valueStr.indexOf("(");
    const spaceIndex = valueStr.indexOf(" ");
    if (xStrIndex >= 0 && (spaceIndex > xStrIndex || xStrIndex < 0) && valueStr.endsWith(")")) {
      return Kind.XStr;
    }
    return undefined;
  }

  public navTreeNodeToHaysonDict(node: TreeNodeForUI): HaysonDict | undefined {
    const defs = this.getDefs();
    if (defs) {
      if (node) {
        const currentDict: HaysonDict = {};
        node.tags?.forEach(tag => {
          const temp = this.tagStringToHaysonVal(tag, node);
          if (temp) {
            currentDict[temp[0]] = temp[1];
          }
        });
        return currentDict;
      }
    }
    return undefined;
  }

  public tagStringToHaysonVal(tag: string, node: TreeNodeForUI | undefined = undefined): [string, HaysonVal] | undefined {
    let result: [string, HaysonVal] | undefined = undefined;
    const parts = tag.split("=");
    if (parts.length) {
      const tagName = parts[0];
      const tagValue = parts[1];
      let isOk = false;
      let kinds: Kind[] = [];
      const kindFromString = this.detectKindFromString(tagValue);
      const defKinds = this.defToKinds(tagName);
      if (defKinds.length) {
        kinds = defKinds;
      } else if (tagName.toLocaleLowerCase().endsWith("val")) {
        let kindTag = node?.tags?.find(x => x.startsWith("kind="));
        if (kindTag) {
          kindTag = kindTag.substring("kind=".length).toLowerCase();
        }
        kinds = this.getCompatibleKinds(
          tagName, 
          (kindTag as Kind) ?? undefined
        );
      }
      if (kindFromString && (kinds.includes(kindFromString) || !kinds.length)) {
        kinds = [kindFromString];
      }
      if (kinds.length) {
        if (kinds.includes(Kind.Marker) && !tagValue) {
          const haysonVal: HaysonMarker = {
            _kind: Kind.Marker
          };
          result = [tagName, haysonVal];
          isOk = true;
        } else if (kinds.includes(Kind.Number)) {
          let isStr = false;
          let value = 0;
          let units = "";
          if (typeof tagValue === "string") {
            const tagValueLower = tagValue.toLowerCase();
            if (tagValueLower === "inf" || tagValueLower === "-inf" || tagValueLower === "nan") {
              isStr = true;
            } else {
              const index = tagValue.search(/[^0-9.-]/);
              if (index >= 0) {
                value = parseFloat(tagValue.substring(0, index));
                units = tagValue.substring(index).trim();
              } else {
                value = parseFloat(tagValue);
              }
            }
          }
          const haysonVal: HaysonVal = isStr ?
            tagValue :
            {
              _kind: Kind.Number,
              val: value,
              unit: units
            };
          result = [tagName, haysonVal];
          isOk = true;
        } else if (kinds.includes(Kind.Str)) {
          result = [tagName, tagValue ?? ""];
          isOk = true;
        } else if (kinds.includes(Kind.Bool)) {
          let haysonVal = false;
          if (typeof tagValue === "string") {
            if (tagValue.toLowerCase() === "true" || tagValue.toLowerCase() === "t" || tagValue === "1") {
              haysonVal = true;
            }
          }
          result = [tagName, haysonVal];
          isOk = true;
        } else if (kinds.includes(Kind.Uri)) {
          const haysonVal: HaysonUri = {
            _kind: Kind.Uri,
            val: tagValue ?? ""
          };
          result = [tagName, haysonVal];
          isOk = true;
        } else if (kinds.includes(Kind.Ref)) {
          const haysonVal: HaysonRef = {
            _kind: Kind.Ref,
            val: tagValue ?? ""
          };
          result = [tagName, haysonVal];
          isOk = true;
        } else if (kinds.includes(Kind.Symbol)) {
          const haysonVal: HaysonSymbol = {
            _kind: Kind.Symbol,
            val: tagValue ?? ""
          };
          result = [tagName, haysonVal];
          isOk = true;
        } else if (kinds.includes(Kind.Date)) {
          const haysonVal: HaysonDate = {
            _kind: Kind.Date,
            val: tagValue ?? ""
          };
          result = [tagName, haysonVal];
          isOk = true;
        } else if (kinds.includes(Kind.Time)) {
          const haysonVal: HaysonTime = {
            _kind: Kind.Time,
            val: tagValue ?? ""
          };
          result = [tagName, haysonVal];
          isOk = true;
        } else if (kinds.includes(Kind.Coord) && (!tagValue || tagValue.split(",").length === 2)) {
          const parts = typeof tagValue === "string" ? tagValue.split(",") : ["0", "0"];
          const haysonVal: HaysonCoord = {
            _kind: Kind.Coord,
            lat: parseFloat(parts[0]),
            lng: parseFloat(parts[1])
          };
          result = [tagName, haysonVal];
          isOk = true;
        } else if (kinds.includes(Kind.DateTime) || kinds.includes(Kind.XStr) || kinds.includes(Kind.Dict) || kinds.includes(Kind.List)) {
          try {
            const haysonVal = zinc(tagValue)?.toJSON() ?? {};
            result = [tagName, haysonVal];
          } catch {
            result = [tagName, tagValue];
          }
          isOk = true;
        }
      } 
      if (!isOk) {
        if (tagValue) {
          result = [tagName, tagValue];
        } else {
          const haysonVal: HaysonMarker = {
            _kind: Kind.Marker
          }
          result = [tagName, haysonVal];
        }
      }
    }
    return result;
  }
  
  public extractTagName(tag: string): string {
    const index = tag.indexOf("=");
    if (index >= 0) {
      return tag.substring(0, index);
    }
    return tag;
  }

  validateTag(tag: string): string {
    if (!tag)
      return "Empty tag.";
    let result = tag.length <= 250 ? "" : `Tag [${tag}] exceed maximum length(250 chars).`;
    if (!result) {
      const eqIndex = tag.indexOf("=");
      const tagName = eqIndex > -1 ? tag.substring(0, eqIndex) : tag;
      if (!tagName[0].match(/[a-z]/)) {
        result += `Tag name [${tag}] is not valid. Must start with ASCII lower case letter (a-z). `;
      }
      //Must contain only ASCII letters, digits, or underbar (a-z, A-Z, 0-9, _)
      const regex = new RegExp("^[a-zA-Z0-9_]*$");
      if (!regex.test(tagName)) {
        result += `Tag name [${tag}] is not valid. Tag name must contain only ASCII letters, digits, or underbar (a-z, A-Z, 0-9, _).`;
      }
    }
    return result;
  }

  public haysonValToString(value: HaysonVal): string {
    const typeOf = typeof value;
    if (typeOf === "undefined" || value === null) {
      return "";
    }
    if (typeOf === "string" || typeOf === "number" || typeOf === "boolean") {
      return value.toString();
    }
    if (Array.isArray(value)) {
      const hVal = makeValue(value);
      if (hVal) {
        return hVal.toZinc();
      } else {
        return "";
      }
    }
    const obj = value as { _kind?: string }
    switch (toKind(obj._kind ?? '')) {
      case Kind.Marker:
      case Kind.Remove:
      case Kind.NA:
        return "";
      case Kind.Coord: {
        const haysonObj = value as HaysonCoord;
        return `${haysonObj.lat},${haysonObj.lng}`;
      }
      case Kind.XStr:
      case Kind.DateTime:
      case Kind.Number:
      case Kind.Dict:
      case undefined: {
        const hVal = makeValue(value);
        if (hVal) {
          return hVal.toZinc();
        } else {
          return "";
        }
      }
      case Kind.Date: {
        const haysonObj = value as HaysonDate;
        return haysonObj.val;
      }
      case Kind.Time: {
        const haysonObj = value as HaysonTime;
        return haysonObj.val;
      }
      case Kind.Ref: {
        const haysonObj = value as HaysonRef;
        return haysonObj.val;
      }
      case Kind.Symbol: {
        const haysonObj = value as HaysonSymbol;
        return haysonObj.val;
      }
      case Kind.Uri: {
        const haysonObj = value as HaysonUri;
        return haysonObj.val;
      }
      default:
        return "";
    }
  }

  public googleLocationToTags(location: GoogleMapLocation): string[] {
    const result: string[] = [];
    const locationTagsConfig = [
      {
        name: "geoCity",
        addressTypes: ["locality"],
      },
      {
        name: "geoCountry",
        addressTypes: ["country"],
      },
      {
        name: "geoPostalCode",
        addressTypes: ["postal_code"],
      },
      {
        name: "geoState",
        addressTypes: ["administrative_area_level_1"],
      },
      {
        name: "geoStreet",
        addressTypes: ["route", "street_address"],
      }
    ];
    if (location.geometry && location.geometry.location) {
      const geoCoord = location.geometry.location.lat() + ',' + location.geometry.location.lng();
      result.push(`geoCoord=${geoCoord}`);
    } else {
      result.push("geoCoord");
    }
    if (location.formatted_address) {
      result.push(`geoAddr=${location.formatted_address}`);
    } else {
      result.push("geoAddr");
    }
    if (location.address_components) {
      for (const config of locationTagsConfig) {
        const value = location.address_components.find(el =>{
          return config.addressTypes.find(type => {
            return el.types.indexOf(type) !== -1;
          });
        });
        if (value) {
          if (config.name === "geoCountry") {
            // ISO 3166-1 for country
            result.push(`${config.name}=${value.short_name}`);
          } else {
            result.push(`${config.name}=${value.long_name}`);
          }
        } else {
          result.push(`${config.name}`);
        }
      }
    } else {
      for (const config of locationTagsConfig) {
        result.push(`${config.name}`);
      }
    }
    return result;
  }

  

  /**
   * Cache built to speed calculation of conjuncts.
   *
   * Example:
   * ```
   * {
   *   "ac": [
   *   	["elec"],
   *   	["elec", "meter"],
   *   	["freq"]
   *   ],
   *   "active": [
   *   	["power"]
   *   ],
   *   "air": [
   *   	["temp"]
   *   ],
   *   ...
   * }
   * ```
   */
  // copied from HNamespace
  @memoize()
  private get $conjunctsKeyMap(): Record<string, string[][]> {
    const defs = this.getDefs();
    if (!defs) {
      return {};
    }
    return defs.conjuncts
      .map((d) => HNamespace.splitConjunct(d.defName))
      .reduce((a, c) => {
        const key = c[0];
        if (!a[key]) {
          a[key] = [];
        }
        a[key].push(c.slice(1));
        return a;
      }, {} as Record<string, string[][]>);
  }

  /**
   * Find all the conjuncts for the markers.
   *
   * @param markers The markers
   * @param conjuncts The found conjuncts.
   */
  // copied from HNamespace
  public findConjuncts(markers: string[], conjuncts: HDict[]): void {
    const defs = this.getDefs();
    if (defs) {
      const map = this.$conjunctsKeyMap;
      for (const marker of markers) {
        const match = map[marker];
        if (match) {
          match.forEach((m) => {
            if (m.every((t) => markers.includes(t))) {
              const def = defs.byName([marker, ...m].join("-"));
              if (def) {
                conjuncts.push(def);
              }
            }
          });
        }
      }
    }
  }
}

export default new HaystackDefsService();