<template>
  <div class="widget-type-infographic">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="widget-type-infographic-inner" v-show="!isLodingData && !isNoData">
      <div class="infographic-block reduce-gap">
          <div class="infographic-range">
            <div class="infographic-object solar-generator">
              <SolarGeneratorSvg/>
            </div>
          </div>

          <div class="infographic-legend">
            <div class="solar-energy-legend">
              <span class="infographic-legend-img"></span>
              <div>
                <span class="infographic-legend-value">{{formatValue(valueCurrent)}}</span>
                <span class="infographic-legend-description">Generated Energy</span>
              </div>
            </div>
            <div class="computers-count-legend">
              <span class="infographic-legend-img"></span>
              <div>
                <span class="infographic-legend-description">Enough to power&nbsp;up</span>
                <span class="infographic-legend-value no-break-word">{{formatPC(valuePC)}} <br>for 24 hours</span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import numbro from "numbro";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import DateHelper from '@/helpers/DateHelper';
import { TimeRange } from '@/models/enums/TimeRange';
import moment from 'moment';
import SolarGeneratorSvg from "@/components/svg/SolarGeneratorSvg.vue";

@Component({
  components: {
    ProgressSpinner,
    WidgetNoDataView,
    SolarGeneratorSvg
  }
})
class SolarGeneratedWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;
  valueCurrent : number | undefined = undefined;
  valuePC : number | undefined = undefined;

  getMinMaxTemp(): [number, number] {
    const temps: number[] = [];
    if (typeof this.valueCurrent === "number") {
      temps.push(this.valueCurrent);
    }
    if (typeof this.valuePC === "number") {
      temps.push(this.valuePC);
    }
    if (temps.length) {
      const min = Math.floor(Math.min(...temps));
      const max = Math.ceil(Math.max(...temps));
      return [min > 0 ? 0 : min, max];
    } else {
      return [0, 100];
    }
  }

  calcValuePercent(value: number | undefined): number {
    if (typeof value === "undefined") {
      return 0;
    }
    const minMaxTemp = this.getMinMaxTemp();
    const min = minMaxTemp[0];
    let max = minMaxTemp[1];
    let valueVar = value;
    const offset = -min;
    // min += offset; = 0, not important for calc
    max += offset;
    valueVar += offset;
    const result = valueVar / max;
    return result * 100;
  }

  get outsideZIndex(): number {
    return typeof this.valuePC === "number" &&
      typeof this.valueCurrent === "number" &&
      this.valuePC > this.valueCurrent ? 1 : 2;
  }

  get insideZIndex(): number {
    return typeof this.valuePC === "number" &&
      typeof this.valueCurrent === "number" &&
      this.valuePC > this.valueCurrent ? 2 : 1;
  }

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.valueCurrent = undefined; 
    this.valuePC = undefined; 
    let hasData = false;
    this.chartData.forEach((streamData, i) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length) {
        const value = streamData.Data[0].y;
        if (typeof value === "number") {
          this.valueCurrent = value; 
          this.valuePC = Math.floor(value * 1000 / 250 / 8);
          hasData = true;
        }
      }
    });
    if (!hasData) {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }

  formatValue(value: number | undefined) : string {
    let result: string;
    const units = "kWh";
    if (typeof value === "undefined" || value === null) {
      result = "-";
    } else if (typeof value === "string") {
      result = `${value} ${units}`;
    } else {
      result = `${numbro(value).format({
        thousandSeparated: true,
        mantissa: 0
      })} ${units}`;
    }
    return result;
  }

  formatPC(value: number | undefined) : string {
    let result: string;
    const units = value === 1 ? "Computer" : "Computers";
    if (typeof value === "undefined" || value === null) {
      result = "-";
    } else if (typeof value === "string") {
      result = `${value} ${units}`;
    } else {
      result = `${numbro(value).format({
        thousandSeparated: true,
        mantissa: 0
      })} ${units}`;
    }
    return result;
  }
}

export default SolarGeneratedWidget;
</script>