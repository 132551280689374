import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import ImageGalleryGroupState from "./states/ImageGalleryGroupState";
import { ImageGalleryGroupEntity } from "@/models/image-gallery/ImageGalleryGroupEntity";

export const useImageGalleryGroupStore = defineStore('imageGalleryGroup', {
  state: (): ImageGalleryGroupState => ({ 
    isLoaded: false,
    guid: "",
    entities: null,
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false,
    selectedItem: null
  }),
  getters: {
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        const url = `rest/BitPool_V2/os/ImageGalleryGroup`;
        const response = await axios.get<ImageGalleryGroupEntity[]>(url);
        if (this.guid === guid) {
          this.entities = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load Graphic Library Groups",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
      }
    },
    async createUpdate(entity: ImageGalleryGroupEntity): Promise<ImageGalleryGroupEntity | null> {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/os/ImageGalleryGroup`;
        const response = await axios.post<ImageGalleryGroupEntity>(url, entity);
        ToastService.showToast("success", "Graphic Library Groups", "Changes saved", 5000);
        this.updateInProgress = false;
        if (this.isLoaded && this.entities) {
          if (entity.Id) {
            const index = this.entities.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.entities[index] = response.data;
            } else {
              this.entities.splice(0, 0, response.data);
            }
          } else {
            this.entities.splice(0, 0, response.data);
          }
        }
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save Graphic Library Group",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return null;
      }
    },
    async delete(entity: ImageGalleryGroupEntity): Promise<boolean> {
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/BitPool_V2/os/ImageGalleryGroup/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Graphic Library Groups", `${entity.Name} is deleted`, 5000);
        if (this.isLoaded && this.entities) {
          const index = this.entities.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.entities.splice(index, 1);
          }
        }
        this.deleteInProgress = false;
        this.deleteError = false;
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
        return false;
      }
    }
  },
})
