<template>
  <BlockUI :blocked="inProgressChanges" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner blockui-with-overlay-z-index" :class="inProgressChanges ? 'blockui-blocked' : ''">
    <Message severity="warn" :closable="false" v-if="!authState.permissions?.FullAccess">
      You have not enough rights
    </Message>
    <DataTable
      v-else
      :value="backupList" 
      dataKey="Id"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm responsive-breakpoint backup-restore-semantics-table">
      <template #header>
        <div class="table-header">
          <Button 
            label="Create Backup" 
            icon="pi pi-plus-circle" 
            class="my-1" 
            @click="createBackup" 
            :disabled="!isLoaded"
          />
        </div>
      </template>
      <template #empty>
        <div v-if="isLoaded" class="w-full" style="min-height: 100px;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 100px;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </div>
      </template>
      <Column field="DateCreated" header="Date" headerStyle="min-width: min-content; width: 12%;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.DateCreated" timezone="local"/>
        </template>
      </Column>
      <Column field="Creator" header="Creator" headerStyle="min-width: min-content; width: 15%;" headerClass="no-break-word" bodyClass="break-word"></Column>
      <Column field="Sites" header="Sites" headerStyle="min-width: min-content; width: 40%;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <div>
            <span v-for="(site, index) in slotProps.data.Sites" key="StreamKey">
              <a :href="`/semantics?search=${site.StreamKey}`" target="_blank">{{site.Name}}</a>
              <template v-if="index < slotProps.data.Sites.length - 1">, </template>
            </span>
          </div>
        </template>
      </Column>
      <Column field="NodesCount" header="Nodes" headerStyle="min-width: min-content; width: 12%;" headerClass="no-break-word" bodyClass="no-break-word"></Column>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <div class="inline-flex">
            <Button 
              v-tippy="'Restore'"
              icon="pi pi-history" 
              class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
              @click="openRestoreDialog(slotProps.data)" 
            />
            <Button 
              v-tippy="'Delete'"
              icon="pi pi-trash" 
              class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
              @click="openDeleteConfirmation(slotProps.data)" 
            />
          </div>
        </template>
      </Column>
    </DataTable>

  <Dialog header="Restore Backup" v-model:visible="displayRestoreBackupModal" :modal="true" :style="{width: '56rem'}" class="semantics-config-dialog">
    <div class="dialog-content" v-if="restoreOptions && restoreBackupItem">
      <BlockUI :blocked="semanticsBackupState.inProgressRestoreBackup" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="semanticsBackupState.inProgressRestoreBackup ? 'blockui-blocked' : ''">
        <div class="semantics-restore">
          <section>
            <div class="flex align-items-center gap-2 mb-2">
              <label for="restoreSites" class="flex-shrink-0 inline-block mb-0 mr-2">Sites</label>
              <Button label="Select all" @click="selectAllRestoreSites" class="p-button-light-secondary p-button-rounded flex-shrink-0 ml-auto" />
              <Button label="Deselect" @click="deselectAllRestoreSites" class="p-button-light-secondary p-button-rounded flex-shrink-0" />
            </div>
            <div class="flex flex-column">
              <div v-for="(site, index) in restoreBackupItem.Sites" :key="site.StreamKey" style="display: flex; flex: 1; min-width: 0;">
                <div class="semantics-restore-item">
                  <Checkbox v-model="restoreSelectedSites[site.StreamKey]" :binary="true" :inputId="`restoreSite${index}`"/>
                  <label :for="`restoreSite${index}`">{{ site.Name }}</label>
                </div>
              </div>
            </div>
          </section>
          <hr>
          <section class="grid mb-4 sm:mb-3">
            <label for="restoreSitesAlgorithm" class="col-12 sm:col-5">Algorithm</label>
            <div class="col-12 sm:col-7 md:col-6 md:col-offset-1">
              <SelectButton 
                v-model="restoreOptions.Algorithm" 
                :options="restoreAlgorithmsOptions" 
                :optionLabel="(x) => x[0]"
                :optionValue="(x) => x[1]"
                class="vertical-align-top"
                id="restoreSitesAlgorithm"
                :allowEmpty="false"
              />
            </div>
          </section>
          <section class="grid mb-4 sm:mb-3">
            <label for="restoreSitesAttachPoints" class="col-12 sm:col-5">Attach Points</label>
            <div class="col-12 sm:col-7 md:col-6 md:col-offset-1">
              <SelectButton 
                v-model="restoreOptions.AttachPoints" 
                :options="yesNoOptions" 
                :optionLabel="(x) => x[0]"
                :optionValue="(x) => x[1]"
                class="vertical-align-top"
                id="restoreSitesAttachPoints"
                :allowEmpty="false"
              />
            </div>
          </section>
          <section class="grid">
            <label for="restoreSitesRestorePermissions" class="col-12 sm:col-5">Restore Permissions</label>
            <div class="col-12 sm:col-7 md:col-6 md:col-offset-1">
              <SelectButton 
                v-model="restoreOptions.RestorePermissions" 
                :options="yesNoOptions" 
                :optionLabel="(x) => x[0]"
                :optionValue="(x) => x[1]"
                class="vertical-align-top"
                id="restoreSitesRestorePermissions"
                :allowEmpty="false"
              />
            </div>
          </section>
        </div>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #footer>
      <Button 
        label="Close" 
        icon="pi pi-times" 
        @click="displayRestoreBackupModal = false" 
        class="p-button-text p-button-secondary"
      />
      <Button 
        label="Restore" 
        :icon="semanticsBackupState.inProgressRestoreBackup ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
        @click="restoreBackup" 
        :disabled='semanticsBackupState.inProgressRestoreBackup' 
      />
    </template>
  </Dialog>

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import TabMenu from 'primevue/tabmenu';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Message from 'primevue/message';
import Dialog from 'primevue/dialog';
import Checkbox from "primevue/checkbox";
import SelectButton from 'primevue/selectbutton';
import { useSemanticsBackupStore } from "@/stores/semanticsBackup";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import AuthState from "@/store/states/AuthState";
import ConfirmationService from "@/services/ConfirmationService";
import SemanticsBackupState from "@/stores/states/SemanticsBackupState";
import { SemanticsBackupEntity } from "@/models/nav-tree/SemanticsBackupEntity";
import { RestoreSemanticsBackupOpitions } from "@/models/nav-tree/RestoreSemanticsBackupOpitions";
import { RestoreSemanticsBackupOpitionsAlgorithm } from "@/models/nav-tree/RestoreSemanticsBackupOpitionsAlgorithm";

@Component({
  components: {
    Button,
    BlockUI,
    ProgressSpinner,
    TabMenu,
    DataTable,
    Column,
    Message,
    Dialog,
    Checkbox,
    SelectButton,
    DateTimezoneView
  },
})
class BackupRestoreSemanticsView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  semanticsBackupStore = useSemanticsBackupStore();
  
  get semanticsBackupState(): SemanticsBackupState {
    return this.semanticsBackupStore;
  }

  get isLoaded(): boolean {
    return this.semanticsBackupState.isLoaded;
  }

  get backupList(): SemanticsBackupEntity[] {
    return this.semanticsBackupState.data;
  }

  get inProgressChanges(): boolean {
    return this.semanticsBackupState.inProgressCreateBackup ||
      this.semanticsBackupState.inProgressDeleteBackup ||
      this.semanticsBackupState.inProgressRestoreBackup;
  }

  created(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    if (!this.semanticsBackupState.isLoaded && this.authState.permissions?.FullAccess) {
      await this.semanticsBackupStore.load();
    }
  }

  createBackup(): void {
    this.semanticsBackupStore.createBackup();
  }

  displayRestoreBackupModal = false;
  restoreBackupItem: SemanticsBackupEntity | null = null;
  restoreOptions: RestoreSemanticsBackupOpitions | null = null;
  restoreSelectedSites: Record<string, boolean> = {};

  get restoreAlgorithmsOptions(): [string, RestoreSemanticsBackupOpitionsAlgorithm][] {
    return [
      ["Merge", RestoreSemanticsBackupOpitionsAlgorithm.Merge],
      ["New", RestoreSemanticsBackupOpitionsAlgorithm.New]
    ]
  }

  get yesNoOptions(): [string, boolean][] {
    return [
      ["Yes", true],
      ["No", false]
    ]
  }

  openRestoreDialog(item: SemanticsBackupEntity): void {
    this.restoreBackupItem = item;
    this.restoreOptions = {
      StreamKeys: item.Sites.map(site => site.StreamKey),
      Algorithm: RestoreSemanticsBackupOpitionsAlgorithm.Merge,
      AttachPoints: true,
      RestorePermissions: true
    };

    const restoreSelectedSites: Record<string, boolean> = {};
    if (this.restoreBackupItem && this.restoreOptions) {
      for (const site of this.restoreBackupItem.Sites) {
        if (this.restoreOptions.StreamKeys.includes(site.StreamKey)) {
          restoreSelectedSites[site.StreamKey] = true;
        } else {
          restoreSelectedSites[site.StreamKey] = false;
        }
      }
    }
    this.restoreSelectedSites = restoreSelectedSites;

    this.displayRestoreBackupModal = true;
  }

  selectAllRestoreSites(): void {
    for (const site in this.restoreSelectedSites) {
      this.restoreSelectedSites[site] = true;
    }
  }

  deselectAllRestoreSites(): void {
    for (const site in this.restoreSelectedSites) {
      this.restoreSelectedSites[site] = false;
    }
  }

  restoreBackup(): void {
    if (this.restoreBackupItem && this.restoreOptions) {
      this.restoreOptions.StreamKeys = [];
      for (const site of this.restoreBackupItem.Sites) {
        if (this.restoreSelectedSites[site.StreamKey]) {
          this.restoreOptions.StreamKeys.push(site.StreamKey);
        }
      }

      this.semanticsBackupStore.restoreBackup(this.restoreBackupItem.Id, this.restoreOptions);
    }
  }

  openDeleteConfirmation(item: SemanticsBackupEntity): void {
    const message = `Are you sure you want to delete Semantics backup?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Backup',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.semanticsBackupStore.deleteBackup(item.Id);
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
}

export default BackupRestoreSemanticsView;
</script>
