<template>
  <div class="default-view-container">
    <div class="default-view-head-section">
      <h1 class="mb-0 mr-5">Color Themes</h1>
    </div>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Color Themes List</h2>
      </header>
      <div class="default-view-body">
        <DataTable
          :value="colorThemeStore.entities" 
          dataKey="Id"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="850px" 
          class="p-datatable-sm default-visual-table responsive-breakpoint color-themes-table">
          <template #header>
            <div class="table-header">
              <Button :disabled="!colorThemeStore.isLoaded" label="Add Theme" icon="pi pi-plus-circle" class="my-1" @click="openCreateUpdateDialog(null)" />
            </div>
          </template>
          <template #empty>
            <div v-if="colorThemeStore.isLoaded" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column field="Name" header="Name" headerStyle="min-width: min-content;" headerClass="no-break-word">
            <template #body="slotProps">
              <span class="block with-inline-btn">
                {{ slotProps.data.Name }}
                <span v-if="slotProps.data.OrganisationId === 0" v-tippy="'Public'" class="table-cell-icon">
                  <i class="pi pi-globe"></i>
                </span>
              </span>
            </template>
          </Column>
          <Column field="Description" header="Description"></Column>
          <Column field="Colors" header="Colors">
            <template #body="slotProps">
              <div class="select-bg-color-theme-colors mt-0">
                <div 
                  v-for="(themeColor, index) in slotProps.data.Colors" 
                  :key="index" 
                  :style="{backgroundColor: themeColor}"
                  @click="copyKey(themeColor)"
                  v-tippy="themeColor"
                ></div>
              </div>
            </template>
          </Column>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div>
                <div class="inline-flex">
                  <Button 
                    icon="pi pi-clone" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openCloneDialog(slotProps.data)"
                    v-tippy="'Copy'"
                  />
                  <Button 
                    icon="pi pi-pencil" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openCreateUpdateDialog(slotProps.data)"
                    v-tippy="'Edit'"
                  />
                  <Button 
                    icon="pi pi-trash" 
                    class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                    @click="openConfirmation(slotProps.data)" 
                    :disabled="slotProps.data.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin"
                    v-tippy="'Delete'"
                  />
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
    <Dialog header="Theme" v-model:visible="displayCreateUpdateDialog" :modal="true" :style="{width: '56rem'}" class="color-themes-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <div class="field col-12 md:col-6">
              <label for="editRecordName">Name</label>
              <div>
                <InputText 
                  id="editRecordName" 
                  class="inputfield w-full"
                  type="text" 
                  v-model="editRecord.Name"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="editRecordDescription">Description</label>
              <div>
                <InputText 
                  id="editRecordDescription" 
                  class="inputfield w-full"
                  type="text" 
                  v-model="editRecord.Description"
                />
              </div>
            </div>
            <div class="col-12">
              <div class="add-colors-colored-box">
                <div class="formgrid grid">
                  <div class="col-12">
                    <div class="inline-flex align-items-center">
                      <h4>Colors</h4>
                      <Button @click="() => editRecord && editRecord.Colors.push(colorHelper.random())"
                        class="p-button-outlined p-button-icon-only p-button-rounded add-colors-control-btn ml-3 flex-none" icon="pi pi-plus" />
                    </div>
                    <div>
                      <div class="select-bg-color-theme-colors mt-3">
                        <div 
                          v-for="(themeColor, index) in editRecord.Colors" 
                          :key="`color-${index}-${themeColor.replaceAll('#', '')}`" 
                        >
                          <ColorSelectView
                            :color="themeColor" 
                            :updateColor="(color: string) => { 
                              if (editRecord) {
                                if (color === 'delete') {
                                  editRecord.Colors.splice(index, 1);
                                } else {
                                  editRecord.Colors[index] = color;
                                }
                              }
                            }"
                            :enableThemes="false"
                            :allowDelete="true"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #header>
        <div class="flex-auto flex justify-content-between align-items-center gap-4">
          <span class="p-dialog-title">Theme</span>
          <div class="color-themes-logs-switch-field pr-3" v-if="canEditGlobal">
            <div class="flex align-items-center">
              <InputSwitch 
                  inputId="color-themes-edit-global"
                  v-model="selectedEntityIsGlobal"
                  class="vertical-align-top"
                  :disabled="updateInProgress"
                />
              <label for="color-themes-edit-global" class="mb-0 ml-2">Public</label>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button 
              v-if="editRecord" 
              :label="editRecord.Id ? 'Update' : 'Create'" 
              :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
              @click="saveRecord" 
              :disabled='!editRecord.Name || updateInProgress || editRecord.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin' 
            />
          </span>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Message from 'primevue/message';
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import moment from "moment";
import ToastService from "@/services/ToastService";
import copy from 'copy-to-clipboard';
import ConfirmationService from "@/services/ConfirmationService";
import { ColorThemeEntity } from "@/models/organisation/ColorThemeEntity";
import { useColorThemeStore } from "@/stores/colorTheme";
import ColorSelectView from "@/components/widgets-next/settings/ColorSelectView.vue";
import ColorHelper from "@/helpers/ColorHelper";
import { useOrganisationStore } from "@/stores/organisation";

@Component({
  components: {
    Message,
    Button,
    InputText,
    InputNumber,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown,
    ColorSelectView
  },
})
class OrganisationColorsView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  colorThemeStore = useColorThemeStore();

  colorHelper = ColorHelper;

  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }

  // #region new/edit
  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get selectedEntityIsGlobal(): boolean {
    return this.editRecord?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.editRecord) {
      if (value) {
        this.editRecord.OrganisationId = 0;
      } else {
        this.editRecord.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  displayCreateUpdateDialog = false;
  editRecord: ColorThemeEntity | null = null;

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  openCloneDialog(record: ColorThemeEntity): void {
    const newRecord: ColorThemeEntity = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    if (!this.canEditGlobal) {
      newRecord.OrganisationId = this.currentOrganisation?.Id ?? -1;
    }
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: ColorThemeEntity | null): void {
    const nowUtc = moment.utc().toDate();
    this.editRecord = record ? JSON.parse(JSON.stringify(record)) : {
      Id: "",
      Name: "",
      Description: "",
      Colors: [],
      OrganisationId: this.currentOrganisation ? this.currentOrganisation.Id : -1,
      Created: nowUtc,
      Updated: nowUtc,
      CreatedBy: this.allUserData.userName,
      UpdatedBy: this.allUserData.userName
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.colorThemeStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.colorThemeStore.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.colorThemeStore.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: ColorThemeEntity | null = null;

  openConfirmation(record: ColorThemeEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete theme?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Theme',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.colorThemeStore.delete(this.selectedRecord);
    }
  }
  // #endregion delete
}

export default OrganisationColorsView;
</script>