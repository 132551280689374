import ErrorHelper from "@/helpers/ErrorHelper";
import EnumHelper from "@/helpers/EnumHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { StreamModel } from "@/models/StreamModel";
import LoadStreamRequest from "@/models/LoadStreamRequest";
import DateHelper from "@/helpers/DateHelper";
import { StreamAdditionalDto } from "@/models/StreamAdditionalDto";
import { AggregateType } from "@/models/enums/AggregateType";
import { StreamDataType } from "@/models/enums/StreamDataType";
import { StreamLogDto } from "@/models/StreamLogDto";
import { StreamLogsListModel } from "@/models/StreamLogsListModel";
import { v4 as uuidv4 } from "uuid";
import { StreamlogDataModel } from "@/models/StreamlogDataModel";
import { PostProcessingType } from "@/models/enums/PostProcessingType";
import { DeleteDataModel } from "@/models/DeleteDataModel";
import { DeleteDataRangerModel } from "@/models/DeleteDataRangerModel";
import { ExportStreamValuesRequest } from "@/models/ExportStreamValuesRequest";
import { AggregatedDataHighchartsResponse } from "@/models/AggregatedDataHighchartsResponse";
import LoadChartDataRequest from "@/models/LoadChartDataRequest";
import LoadStreamLogsPageRequest from "@/models/LoadStreamLogsPageRequest";
import StreamState from "../states/StreamState";
import { StreamVirtualType } from "@/models/enums/StreamVirtualType";
import { StreamValuesHighchartsResponse } from "@/models/StreamValuesHighchartsResponse";
import { DeleteDataManyModel } from "@/models/DeleteDataManyModel";

const getters = <GetterTree<StreamState, any>>{};

const mutations = <MutationTree<StreamState>>{
  updateTags(state, { streamKey, tags }) {
    if (state.isLoaded && state.stream && state.stream.Id === streamKey) {
      state.stream.Tags = tags.sort();
    }
  },
  updatePostProcessingType(state, { streamKey, postProcessingType }) {
    if (state.isLoaded && state.stream && state.stream.Id === streamKey) {
      state.stream.PostProcessingType = postProcessingType;
    }
  },
  updateAllowArchiving(state, { streamKey, allow }) {
    if (state.isLoaded && state.stream && state.stream.Id === streamKey) {
      state.stream.AllowArchiving = allow;
    }
  },
  updateRecalculate(state, { streamKey, recalculate }) {
    if (state.isLoaded && state.stream && state.stream.Id === streamKey) {
      state.stream.Recalculate = recalculate;
    }
  },
  refreshStream(state, stream: StreamModel) {
    if (state.stream) {
      if (stream.LastUpdates !== state.stream.LastUpdates) {
        state.stream.LastUpdates = stream.LastUpdates;
      }
      if (stream.Records !== state.stream.Records) {
        state.stream.Records = stream.Records;
      }
      state.stream.Tags = stream.Tags;
      if (stream.LastValue !== state.stream.LastValue) {
        state.stream.LastValue = stream.LastValue;
      }
    }
  },
};

const actions = <ActionTree<StreamState, any>>{
  async loadStream({ state }, request: LoadStreamRequest) {
    try {
      state.isLoaded = false;
      state.stream = null;
      let url = `rest/BitPool_V1/stream/${request.streamKey}/web`;
      if (request.poolKey) {
        url += `?poolKeyVirtual=${request.poolKey}`;
      }
      const response = await axios.get<StreamModel>(url);
      if (response.data.LastUpdates) {
        response.data.LastUpdates = DateHelper.parseFromMicrosoftString(
          response.data.LastUpdates as string
        );
      }
      if (response.data.FirstUpdates) {
        response.data.FirstUpdates = DateHelper.parseFromMicrosoftString(
          response.data.FirstUpdates as string
        );
      }
      if (response.data.Tags && response.data.Tags.length) {
        response.data.Tags = response.data.Tags.sort();
      }
      if (typeof response.data.DataType === "string") {
        response.data.DataType =
          StreamDataType[response.data.DataType as keyof typeof StreamDataType];
      }
      if (typeof response.data.PostProcessingType === "string") {
        response.data.PostProcessingType =
          PostProcessingType[
            response.data.PostProcessingType as keyof typeof PostProcessingType
          ];
      }
      if (typeof response.data.VirtualType === "string") {
        response.data.VirtualType =
          StreamVirtualType[
            response.data.VirtualType as keyof typeof StreamVirtualType
          ];
      }
      state.stream = response.data;
      state.isLoaded = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.stream = null;
      state.isLoaded = true;
    }
  },
  async loadStreamAdditional({ state }, streamKey: string) {
    try {
      state.isLoadedStreamAdditional = false;
      state.streamAdditional = null;
      const response = await axios.get<StreamAdditionalDto>(
        `public/v2/streams/${streamKey}/additional`
      );
      state.streamAdditional = response.data;
      if (typeof state.streamAdditional.AggregateType === "string") {
        state.streamAdditional.AggregateType =
          AggregateType[
            state.streamAdditional.AggregateType as keyof typeof AggregateType
          ];
      }
      state.isLoadedStreamAdditional = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load stream additional data",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.streamAdditional = null;
      state.isLoadedStreamAdditional = true;
    }
  },
  async saveStreamAdditional({ state }) {
    try {
      state.inProgressSaveStreamAdditional = true;
      state.errorStreamAdditional = false;
      if (state.stream && state.streamAdditional) {
        await axios.post(
          `public/v2/streams/${state.stream.Id}/additional`,
          state.streamAdditional
        );
      }
      state.inProgressSaveStreamAdditional = false;
      ToastService.showToast("success", "", "Changes saved", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save stream additional data",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.errorStreamAdditional = true;
      state.inProgressSaveStreamAdditional = false;
    }
  },
  async loadStreamQualityMetrics({ state }) {
    try {
      state.isLoadedStreamQualityMetrics = false;
      state.monthMissingPercent = 0;
      state.monthEstimatedPercent = 0;
      if (
        state.stream &&
        state.stream.DataType === StreamDataType.Double
      ) {
        const urlMissing = `rest/BitPool_V2/stream/${state.stream.Id}/MissingPercent`;
        const urlCalculated = `rest/BitPool_V2/stream/${state.stream.Id}/CalculatedPercent`;
        const apiCalls = [
          axios.get<number>(urlMissing),
          axios.get<number>(urlCalculated)
        ];
        const responses = await Promise.all(apiCalls);
        const results: number[] = [];
        responses.forEach((response) => {
          results.push(response.data);
        });
        state.monthMissingPercent = results[0];
        state.monthEstimatedPercent = results[1];
      }
      state.isLoadedStreamQualityMetrics = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load stream quality metrics",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.monthMissingPercent = 0;
      state.monthEstimatedPercent = 0;
      state.isLoadedStreamQualityMetrics = true;
    }
  },
  async loadStreamLogsPage({ state }, request: LoadStreamLogsPageRequest) {
    try {
      const query = request.query;
      const guid = uuidv4();
      state.guidPage = guid;
      state.pageQuery = query;
      if (!request.silent) {
        state.isLoadedPage = false;
        if (state.logsPage) {
          state.logsPage.Data = [];
        }
      }
      let url = `public/v2/streams/${query.streamKey}/logs?from=${query.from}&to=${query.to}&take=${query.take}&skip=${query.skip}&postProcessIfAvailable=${query.postProcessIfAvailable}&showDifference=${query.showDifference}`;
      if (query.minValue !== null) {
        url += `&minValue=${query.minValue}`;
      }
      if (query.maxValue !== null) {
        url += `&maxValue=${query.maxValue}`;
      }
      const response = await axios.get<StreamLogsListModel>(url);
      if (state.guidPage === guid) {
        if (response.data && response.data.Data && response.data.Data.length) {
          response.data.Data.forEach((streamLog) => {
            if (streamLog.Timestamp) {
              streamLog.Timestamp = DateHelper.parseFromMicrosoftString(
                streamLog.Timestamp as string
              );
            }
          });
        }
        state.logsPage = response.data;
        state.isLoadedPage = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load stream logs",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.isLoadedPage = true;
    }
  },
  async createUpdateStreamLog({ state, dispatch }, body: StreamlogDataModel) {
    try {
      state.updateInProgress = true;
      state.updateError = false;
      const url = body.OldDate
        ? `rest/BitPool_V2/streamlog/EditData`
        : `rest/BitPool_V2/streamlog/AddData`;
      await axios.post(url, body);
      state.updateInProgress = false;
      ToastService.showToast("success", "", "Changes saved", 5000);
      // view will update dates and reload page, so no need to reload here
      // if (state.isLoadedPage) {
      //   await dispatch("loadStreamLogsPage", { silent: false, query: state.pageQuery });
      // }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save streamlog",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.updateInProgress = false;
      state.updateError = true;
    }
  },
  async deleteStreamLog({ state, dispatch }, body: DeleteDataModel) {
    try {
      const url = `rest/BitPool_V2/streamlog/DeleteData`;
      await axios.delete(url, {
        data: body,
      });
      ToastService.showToast("success", "", "Changes saved", 5000);
      if (state.isLoadedPage) {
        await dispatch("loadStreamLogsPage", { silent: false, query: state.pageQuery });
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete streamlog",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async deleteStreamLogs({ state, dispatch }, body: DeleteDataManyModel) {
    try {
      const url = `rest/BitPool_V2/streamlog/DeleteData/Many`;
      await axios.delete(url, {
        data: body,
      });
      ToastService.showToast("success", "", "Changes saved", 5000);
      if (state.isLoadedPage) {
        await dispatch("loadStreamLogsPage", { silent: false, query: state.pageQuery });
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete streamlogs",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async deleteStreamLogDateRange(
    { state, dispatch },
    body: DeleteDataRangerModel
  ) {
    try {
      const url = `rest/BitPool_V2/streamlog/DeleteDataRange`;
      await axios.delete(url, {
        data: body,
      });
      ToastService.showToast("success", "", "Changes saved", 5000);
      if (state.isLoadedPage) {
        await dispatch("loadStreamLogsPage", { silent: false, query: state.pageQuery });
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete streamlogs",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async resetStreamLogs({ state, dispatch }) {
    try {
      if (state.stream) {
        const url = `rest/BitPool_V2/streamlog/ResetStreamData`;
        await axios.post(url, state.stream.Id, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        ToastService.showToast("success", "", "Changes saved", 5000);
        if (state.isLoadedPage) {
          await dispatch("loadStreamLogsPage", { silent: false, query: state.pageQuery });
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't reset streamlogs",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async exportStreamLogs({ state }, body: ExportStreamValuesRequest) {
    try {
      state.exportInProgress = true;
      let url = `rest/BitPool_V2/streamlog/ExportData?StreamKey=${body.StreamKey}`;
      if (body.From) {
        url += `&From=${body.From}`;
      }
      if (body.To) {
        url += `&To=${body.To}`;
      }
      if (body.FromValue !== null) {
        url += `&FromValue=${body.FromValue}`;
      }
      if (body.ToValue !== null) {
        url += `&ToValue=${body.ToValue}`;
      }
      const response = await axios.get(url, { responseType: "blob" });
      const href = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = href;
      link.setAttribute("download", "export.csv");
      document.body.appendChild(link);
      link.click();
      state.exportInProgress = false;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't export streamlogs",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.exportInProgress = false;
    }
  },
  async loadChartData({ state }, request: LoadChartDataRequest) {
    try {
      const guid = uuidv4();
      state.guidChartData = guid;
      if (!request.silent) {
        state.isLoadedChartData = false;
        state.chartData = [];
      }
      const url = `rest/BitPool_V2/stream/values/Highcharts`;
      const response = await axios.post<AggregatedDataHighchartsResponse[]>(
        url,
        request.body
      );
      if (state.guidChartData === guid) {
        if (response.data && response.data.length) {
          const result: StreamValuesHighchartsResponse[][] = [];
          response.data.forEach((data) => {
            if (data.Error) {
              ToastService.showToast(
                "error",
                "Error loading chart data",
                data.Error,
                5000
              );
              result.push([]);
            } else {
              result.push(data.Data);
            }
          })
          state.chartData = result;
        }
        state.isLoadedChartData = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Error loading chart data",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.isLoadedChartData = true;
    }
  },
};

const StreamModule = {
  namespaced: true,
  state: new StreamState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default StreamModule;
