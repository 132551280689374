import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import OrganisationGroupsReportsState from "./states/OrganisationGroupsReportsState";
import { OrganisationReportsCollectionModel } from "@/models/organisation/OrganisationReportsCollectionModel";

export const useOrganisationGroupsReportsStore = defineStore('organisationGroupsReports', {
  state: (): OrganisationGroupsReportsState => ({ 
    isLoaded: false,
    guid: "",
    organisationId: 0,
    groupId: 0,
    entities: null,
    updateInProgress: false,
    updateError: false
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number, groupId: number) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.organisationId = organisationId;
        this.groupId = groupId;
        this.isLoaded = false;
        this.entities = null;
        const url = `rest/Reports_V2/Collection/group`;
        const response = await axios.get<OrganisationReportsCollectionModel[]>(url);
        if (this.guid === guid) {
          this.entities = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load group reports",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
      }
    },
    async save(body: OrganisationReportsCollectionModel[]): Promise<boolean> {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/Reports_V2/Collection/groups`;
        const response = await axios.put<OrganisationReportsCollectionModel[]>(url, body);
        if (response.data) {
          for (const item of response.data) {
            if (this.isLoaded && this.entities) {
              const index = this.entities.findIndex((x) => x.Id === item.Id);
              if (index < 0) {
                this.entities.push(item);
              } else {
                this.entities[index] = item;
              }
            }
          }
        }
        this.updateInProgress = false;
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save group reports",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return false;
      }
    }
  },
})
