<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="195.75" height="140" viewBox="0 0 195.75 140">
  <g id="Group_19482" data-name="Group 19482" transform="translate(-4091.75 -17649.25)">
    <path id="Path_19875" data-name="Path 19875" d="M2.847,0H118.153C119.725,0,121,.84,121,1.877V59.123C121,60.16,119.725,61,118.153,61H2.847C1.275,61,0,60.16,0,59.123V1.877C0,.84,1.275,0,2.847,0Z" transform="translate(4093 17650.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="2.5" stroke-dasharray="4.5 5"/>
    <path id="Path_19876" data-name="Path 19876" d="M1.882,0H78.118A1.88,1.88,0,0,1,80,1.877V59.123A1.88,1.88,0,0,1,78.118,61H1.882A1.88,1.88,0,0,1,0,59.123V1.877A1.88,1.88,0,0,1,1.882,0Z" transform="translate(4093 17720.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="2.5" stroke-dasharray="4.5 5"/>
    <path id="Path_19877" data-name="Path 19877" d="M1.915,0H79.477a1.9,1.9,0,0,1,1.915,1.877V59.123A1.9,1.9,0,0,1,79.477,61H1.915A1.9,1.9,0,0,1,0,59.123V1.877A1.9,1.9,0,0,1,1.915,0Z" transform="translate(4182 17720.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="2.5" stroke-dasharray="4.5 5"/>
    <path id="Path_19873" data-name="Path 19873" d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z" transform="translate(4237.5 17739.25)" fill="#00adee"/>
    <g id="Group_19470" data-name="Group 19470" transform="translate(4247 17748.75)">
      <line id="Line_174" data-name="Line 174" y2="31" transform="translate(15.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
      <line id="Line_175" data-name="Line 175" y2="31" transform="translate(15.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
      <line id="Line_176" data-name="Line 176" y2="31" transform="translate(31 15.5) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
    </g>
    <path id="Path_19884" data-name="Path 19884" d="M.941,0H39.059c.52,0,.941.84.941,1.877V59.123C40,60.16,39.579,61,39.059,61H.941C.421,61,0,60.16,0,59.123V1.877C0,.84.421,0,.941,0Z" transform="translate(4223 17650.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="2.5" stroke-dasharray="4.5 5"/>
  </g>
</svg>

</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class AddWidgetFullSvg extends Vue {
}

export default AddWidgetFullSvg;
</script>