import { ConfirmationOptions } from "primevue/confirmationoptions";

class ConfirmationService {
  private showConfirmationFunction: ((options: ConfirmationOptions, dialogClass: string) => void) | null = null;
  private closeConfirmationFunction: (() => void) | null = null;

  registerConfirmation(funcShow: (options: ConfirmationOptions, dialogClass: string) => void, funcClose: () => void) {
    this.showConfirmationFunction = funcShow;
    this.closeConfirmationFunction = funcClose;
  }

  // Example:
  // import ConfirmationService from '../services/ConfirmationService';
  // ConfirmationService.showConfirmation({ ... });
  showConfirmation(options: ConfirmationOptions, dialogClass = "") {
    if (this.showConfirmationFunction) {
      this.showConfirmationFunction(options, dialogClass);
    }
  }

  closeConfirmation() {
    if (this.closeConfirmationFunction) {
      this.closeConfirmationFunction();
    }
  }
}

export default new ConfirmationService();