import { StreamAdditionalDto } from "@/models/StreamAdditionalDto";
import { StreamLogsListModel } from "@/models/StreamLogsListModel";
import StreamLogsQuery from "@/models/StreamLogsQuery";
import { StreamModel } from "@/models/StreamModel";
import { StreamValuesHighchartsResponse } from "@/models/StreamValuesHighchartsResponse";

export default class StreamState {
  isLoaded = false;
  stream: StreamModel | null = null;

  isLoadedStreamAdditional = false;
  streamAdditional: StreamAdditionalDto | null = null;
  inProgressSaveStreamAdditional = false;
  errorStreamAdditional = false;

  isLoadedStreamQualityMetrics = false;
  monthMissingPercent = 0;
  monthEstimatedPercent = 0;

  isLoadedPage = false;
  logsPage: StreamLogsListModel | null = null;
  guidPage = "";
  pageQuery: StreamLogsQuery | null = null;

  updateInProgress = false;
  updateError = false;

  exportInProgress = false;

  isLoadedChartData = false;
  guidChartData = "";
  chartData: StreamValuesHighchartsResponse[][] = [];
}
