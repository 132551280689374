<template>
  <div class="default-view-container graphic-library-container">
    <div class="default-view-head-section align-items-center">
      <h1 class="mb-0 mr-5">Graphic Library</h1>
      
      <IconField iconPosition="left" class="search-input-box mb-0">
        <InputIcon class="pi pi-search lg:text-xl"></InputIcon>
        <InputText
          class="inputfield lg:text-lg"
          placeholder="Search"
          type="text"
          v-model="search"
          @input="debounceSearch()"
          style="border-radius: 3rem;"
        />
      </IconField>
    </div>
    <div class="default-view graphic-library mt-4 lg:mt-5">
      <ImageGalleryHeaderView class="default-view-header"/>
      <ImageGalleryGroupsView v-model="searchFinal" class="default-view-body"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import ImageGalleryGroupsView from "@/components/views/image-gallery/ImageGalleryGroupsView.vue";
import { debounce } from "throttle-debounce";
import { useImageGalleryStore } from "@/stores/imageGallery";
import { ImageGalleryGroupEntity } from "@/models/image-gallery/ImageGalleryGroupEntity";
import { useImageGalleryGroupStore } from "@/stores/imageGalleryGroup";
import ImageGalleryHeaderView from "@/components/views/image-gallery/ImageGalleryHeaderView.vue";

@Component({
  components: {
    InputText,
    IconField,
    InputIcon,
    ImageGalleryGroupsView,
    ImageGalleryHeaderView
  },
})
class OrganisationImageGallery extends Vue {
  imageGalleryGroupStore = useImageGalleryGroupStore();
  imageGalleryStore = useImageGalleryStore();
  
  search = "";
  searchFinal = "";
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
  }

  get selectedGroup(): ImageGalleryGroupEntity | null {
    const group = this.imageGalleryGroupStore.selectedItem;
    return group;
  }
}

export default OrganisationImageGallery;
</script>