<template>
  <div class="widget-type-weather-forecast">
    <div v-if="isLoading" class="h-full flex justify-content-center align-items-center flex-auto">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-else-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType" message="Location have not been selected."/>
    </div>
    <div v-else-if="weather && myForecast" class="weather-forecast">
      <!-- Current weather -->
      <div class="weather">
        <div class="weather-info">
          <div class="weather-day">Today</div>
          <div class="weather-location" v-if="aws && aws.location">{{aws.location.full}}</div>
        </div>
        <div class="weather-temperature-cloudy">
          <span v-if="weather.weather && weather.weather.length" class="weather-icon">
            <img :src="`/assets/weather/${weather.weather[0].icon}.svg`"/>
          </span>
          <div>
            <span class="weather-temperature">{{Math.round(weather.main.temp)}}°</span>
            <span class="weather-cloudy" v-if="weather.weather && weather.weather.length">{{weather.weather[0].main}}, {{weather.weather[0].description}}</span>
          </div>
        </div>
        <div class="weather-humidity"><WaterDrop /> Humidity: {{weather.main.humidity}}%</div>
      </div>

      <!-- Forecast -->
      <div class="forecast">
        <div v-for="oneDay, index in myForecast" :key="index" class="forecast-item">
          <div class="forecast-day">{{oneDay.date.format("DD MMMM")}}</div>
          <div v-if="oneDay.icon" class="forecast-icon">
            <img :src="`/assets/weather/${oneDay.icon}.svg`"/>
          </div>
          <div v-if="oneDay.weather" class="forecast-cloudy">{{oneDay.weather}}</div>
          <div class="forecast-temperature">{{Math.round(oneDay.tempDay)}}°/{{Math.round(oneDay.tempNight)}}°</div>
          <div class="forecast-humidity"><WaterDrop />{{oneDay.humidity}}%</div>
        </div>
      </div>
    </div>
    <div v-else>Can't load weather data</div>
  </div>
</template>

<script lang="ts">
import ProgressSpinner from 'primevue/progressspinner';
import ErrorHelper from '@/helpers/ErrorHelper';
import WeatherHelper from '@/helpers/WeatherHelper';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { CurrentResponse } from '@/models/openweather/CurrentResponse';
import ToastService from '@/services/ToastService';
import moment, { Moment } from 'moment';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import WaterDrop from "@/components/svg/WaterDrop.vue";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';

interface MyForecast {
  date: Moment,
  temp: number,
  tempDay: number,
  tempNight: number,
  humidity: number,
  weather: string,
  icon: string
}

@Component({
  components: {
    ProgressSpinner,
    WaterDrop,
    WidgetNoDataView
  }
})
class WeatherForecastWidget extends Vue {
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get isNoData(): boolean {
    return !this.aws?.location;
  }

  noDataType = WidgetNoDataTypes.NotConfigured;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  weather: CurrentResponse | null = null;
  myForecast: MyForecast[] | null = null;
  isLoading = true;

  dataRefreshInterval = 0;

  created(): void {
    this.loadData();
  }

  reloadDataEverySeconds = 3600; // 1 hour

  mounted(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.loadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  @Watch('aws.location', { immediate: false, deep: true })
  onLocationChanged(): void {
    this.loadData();
  }

  async loadData(silent = false): Promise<void> {
    if (!silent) {
      this.isLoading = true;
      this.weather = null;
      this.myForecast = null;
    }
    if (this.aws && this.aws?.location) {
      try {
        const thisDay = moment().format("YYY-MM-DD");
        this.weather = await WeatherHelper.getCurrent(this.aws.location.lat, this.aws.location.lng);
        const forecast = await WeatherHelper.getForecast(this.aws.location.lat, this.aws.location.lng);
        const shortForecast: MyForecast[] = forecast.map(x => {
          return { 
            date: moment(x.dt * 1000),
            temp: x.main.temp, 
            tempDay: 0,
            tempNight: 0,
            humidity: x.main.humidity,
            weather: x.weather && x.weather.length ? `${x.weather[0].main}, ${x.weather[0].description}` : "", 
            icon: x.weather && x.weather.length ? x.weather[0].icon : ""
          }
        });
        const myForecast: MyForecast[] = [];
        const days: Record<string, MyForecast[]> = {};
        shortForecast.forEach(x => {
          const key = x.date.format("YYY-MM-DD");
          if (thisDay !== key) {
            if (!days[key]) {
              days[key] = [];
            }
            days[key].push(x);
          }
        });
        for (const key in days) {
          const data = days[key];
          let minNightTemp = 100;
          let maxDayTemp = -100;
          let maxHumidity = 0;
          const weathers: Record<string, number> = {};
          const icons: Record<string, string> = {};
          data.forEach(x => {
            // day
            if (x.temp > maxDayTemp) {
              maxDayTemp = x.temp;
            }
            // night
            if (x.temp < minNightTemp) {
              minNightTemp = x.temp;
            }
            if (x.humidity > maxHumidity) {
              maxHumidity = x.humidity;
            }
            if (!weathers[x.weather]) {
              weathers[x.weather] = 0;
              // replace night icons by day icons
              const iconD = x.icon.replace("n", "d");
              if (!icons[x.weather]) {
                icons[x.weather] = iconD;
              }
            }
            weathers[x.weather]++;
          });
          let topWeather = "";
          let topIcon = "";
          let maxCount = -1;
          for (const key in weathers) {
            if (weathers[key] > maxCount) {
              topWeather = key;
              maxCount = weathers[key];
              topIcon = icons[key];
            }
          }
          myForecast.push({ 
            date: data[0].date,
            temp: 0, 
            tempDay: maxDayTemp,
            tempNight: minNightTemp,
            humidity: maxHumidity,
            weather: topWeather, 
            icon: topIcon
          });
        }
        if (myForecast.length > 2) {
          myForecast.length = 2;
        }
        this.myForecast = myForecast;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load weather data",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    }
    this.isLoading = false;
  }
}

export default WeatherForecastWidget;
</script>