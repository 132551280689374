import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import DateHelper from "@/helpers/DateHelper";
import OrganisationGroupsUsersState from "./states/OrganisationGroupsUsersState";
import { UserFullForGroupDto } from "@/models/organisation/UserFullForGroupDto";
import { UserInGroupRemove } from "@/models/organisation/UserInGroupRemove";
import { UserInGroupAddUpdate } from "@/models/organisation/UserInGroupAddUpdate";
import { useOrganisationUsersStore } from "./organisationUsers";

export const useOrganisationGroupsUsersStore = defineStore('organisationGroupsUsers', {
  state: (): OrganisationGroupsUsersState => ({ 
    isLoaded: false,
    guid: "",
    organisationId: 0,
    groupId: 0,
    entities: null,
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number, groupId: number) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.organisationId = organisationId;
        this.groupId = groupId;
        this.isLoaded = false;
        this.entities = null;
        if (groupId === 0) {
          this.entities = [];
          this.isLoaded = true;
        } else {
          const url = `rest/BitPool_V2/organisation/groups/${groupId}/users`;
          const response = await axios.get<UserFullForGroupDto[]>(url);
          if (this.guid === guid) {
            response.data.forEach(x => {
              x.DateAdded = DateHelper.parseFromMicrosoftString(x.DateAdded as string);
            });
            this.entities = response.data;
            this.isLoaded = true;
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load group members",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
      }
    },
    async save(items: UserFullForGroupDto[]): Promise<boolean> {
      try {
        const body: UserInGroupAddUpdate[] = items.map(x => {
          return {
            GroupId: this.groupId,
            Username: x.UserName,
            Access: x.Access
          }
        });
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/organisation/groups/users`;
        await axios.put(url, body);
        const organisationUsersStore = useOrganisationUsersStore();
        const organisationIdStr = this.organisationId.toString();
        const users = organisationUsersStore.data[organisationIdStr];
        for (const item of items) {
          if (this.isLoaded && this.entities) {
            const index = this.entities.findIndex((x) => x.UserName === item.UserName);
            if (index > -1) {
              this.entities[index].Access = item.Access;
            } else {
              this.entities.push(item);
            }
          }
          const user = users.entities?.find(x => x.Id === item.Id);
          if (user) {
            const group = user.Groups.find(x => x.GroupId === this.groupId);
            if (group) {
              group.Access = item.Access;
            } else {
              user.Groups.push({ GroupId: this.groupId, Access: item.Access });
            }
          }
        }
        this.updateInProgress = false;
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save members",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return false;
      }
    },
    async delete(items: UserFullForGroupDto[]): Promise<boolean> {
      try {
        const body: UserInGroupRemove[] = items.map(x => {
          return {
            GroupId: this.groupId,
            UserId: x.Id
          }
        });
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/BitPool_V2/organisation/groups/users`;
        await axios.delete(url, {
          headers: {
            "Content-Type": "application/json",
          },
          data: body,
        });
        this.deleteInProgress = false;
        const organisationUsersStore = useOrganisationUsersStore();
        const organisationIdStr = this.organisationId.toString();
        const users = organisationUsersStore.data[organisationIdStr];
        for (const item of items) {
          if (this.isLoaded && this.entities) {
            const index = this.entities.findIndex((x) => x.Id === item.Id);
            if (index > -1) {
              this.entities.splice(index, 1);
            }
          }
          const user = users.entities?.find(x => x.Id === item.Id);
          if (user) {
            const index = user.Groups.findIndex(x => x.GroupId === this.groupId);
            if (index > -1) {
              user.Groups.splice(index, 1);
            }
          }
        }
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete members`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
        return false;
      }
    }
  },
})
