<template>
  <div class="default-view-container">
    <div class="default-view-head-section">
      <h1 class="mb-0 mr-5">Tariffs</h1>
    </div>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Tariffs List</h2>
      </header>
      <div class="default-view-body">
        <DataTable
          :value="tariffStore.dataFlat" 
          dataKey="Id"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="850px" 
          class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width"
          sortField="Name" 
          :sortOrder="-1"
          :paginator="true"
          :rows="20"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          v-model:filters="filters"
          filterDisplay="menu"
          :globalFilterFields="['Name', 'Retailer', 'Category']"
          removableSort
        >
          <template #header>
            <div class="table-header">
              <div class="sm:flex sm:align-items-center sm:justify-content-between sm:gap-3">
                <div class="sm:flex-shrink-0">
                  <Button 
                    :disabled="!tariffStore.isLoaded" 
                    label="Add Tariff" 
                    icon="pi pi-plus-circle" 
                    class="my-1" 
                    @click="openCreateUpdateDialog(null)"
                  />
                </div>
                <div class="mt-3 sm:mt-0">
                  <IconField iconPosition="left" class="w-full sm:w-auto">
                    <InputIcon class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Find Tariff" class="w-full sm:w-auto" />
                  </IconField>
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            <div v-if="tariffStore.isLoaded" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column :sortable="true" field="Name" header="Name" headerStyle="width: 20%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
            <template #body="slotProps">
              <span class="block with-inline-btn">
                {{ slotProps.data.Name }}
                <span v-if="slotProps.data.OrganisationId === 0" v-tippy="'Public'" class="table-cell-icon">
                  <i class="pi pi-globe"></i>
                </span>
              </span>
            </template>
            <template #filter="{ filterModel }">
              <InputText 
                v-model="filterModel.value" 
                type="text" 
                class="p-column-filter" 
                placeholder="Filter" 
              />
            </template>
          </Column>
          <Column :sortable="true" field="Retailer" header="Retailer" headerStyle="width: 14%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
            <template #filter="{ filterModel }">
              <InputText 
                v-model="filterModel.value" 
                type="text" 
                class="p-column-filter" 
                placeholder="Filter" 
              />
            </template>
          </Column>
          <Column :sortable="true" field="Category" header="Category" headerStyle="width: 14%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
            <template #filter="{ filterModel }">
              <InputText 
                v-model="filterModel.value" 
                type="text" 
                class="p-column-filter" 
                placeholder="Filter" 
              />
            </template>
          </Column>
          <Column :sortable="true" field="Notes" header="Notes" headerStyle="width: 40%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
          </Column>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div>
                <div class="inline-flex">
                  <Button 
                    icon="pi pi-clone" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openCloneDialog(slotProps.data)"
                    v-tippy="'Copy'"
                  />
                  <Button 
                    icon="pi pi-pencil" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="openCreateUpdateDialog(slotProps.data)"
                    v-tippy="'Edit'"
                  />
                  <Button 
                    icon="pi pi-trash" 
                    class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                    @click="openConfirmation(slotProps.data)" 
                    :disabled="slotProps.data.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin"
                    v-tippy="'Delete'"
                  />
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
    <Dialog header="Tariff" v-model:visible="displayCreateUpdateDialog" :modal="true" :style="{width: '56rem'}" class="tariff-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(updateInProgress) ? 'blockui-blocked' : ''">
          <div class="tariff-settings-container">
            <div class="formgrid grid">
              <div class="field col-12">
                <label for="editRecordName">Name</label>
                <div>
                  <InputText 
                    id="editRecordName" 
                    class="inputfield w-full"
                    type="text" 
                    v-model="editRecord.Name"
                  />
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <label for="editRecordRetailer">Retailer</label>
                <div>
                  <InputText 
                    id="editRecordRetailer" 
                    class="inputfield w-full"
                    type="text" 
                    v-model="editRecord.Retailer"
                  />
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <label for="editRecordCategory">Category</label>
                <div>
                  <InputText 
                    id="editRecordCategory" 
                    class="inputfield w-full"
                    type="text" 
                    v-model="editRecord.Category"
                  />
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <label for="editRecordNumber">Number</label>
                <div>
                  <InputText 
                    id="editRecordNumber" 
                    class="inputfield w-full"
                    type="text" 
                    v-model="editRecord.Number"
                  />
                </div>
              </div>
              <div class="field col-12 sm:col-6">
                <label for="editRecordState">State</label>
                <div>
                  <InputText 
                    id="editRecordState" 
                    class="inputfield w-full"
                    type="text" 
                    v-model="editRecord.State"
                  />
                </div>
              </div>
              <div class="field col-12 mb-0">
                <label for="editRecordNotes">Notes</label>
                <div>
                  <Textarea 
                    id="editRecordNotes" 
                    class="inputfield w-full"
                    style="white-space: pre-wrap;" 
                    :autoResize="true" 
                    :rows="3"
                    v-model="editRecord.Notes"
                  />
                </div>
              </div>
              <!-- Rules -->
              <div class="col-12 tariff-settings-conversations-container">
                <div class="formgrid grid">
                  <div class="col-12">
                    <div class="inline-flex align-items-center">
                      <h4>Rules</h4>
                      <Button 
                        @click="editRecord.Rules.push(getNewRule())"
                        class="p-button-outlined p-button-rounded tariff-settings-control-btn ml-3 flex-none" 
                        icon="pi pi-plus"
                        label="Add" />
                    </div>
                  </div>
                  <div class="col-12">
                    <!-- Rules List -->
                    <div 
                      class="tariff-settings-colored-box tariff-settings-conversation-item" 
                      v-for="(rule, i) in editRecord.Rules"
                      :key="i" 
                      :id="`ruleContainer${i}`"
                    >
                      <div class="tariff-settings-conversation-item-header">
                        <h5 class="flex-auto">
                          Rule {{ i + 1 }}
                        </h5>
                        <Button
                          class="p-button-outlined p-button-danger p-button-icon-only p-button-rounded tariff-settings-control-btn flex-none"
                          icon="pi pi-trash" 
                          @click="editRecord.Rules.splice(i, 1)" 
                          v-tippy="'Delete'">
                        </Button>
                      </div>
                      <div class="formgrid grid tariff-settings-conversation-item-body">
                        <div class="field col-12 sm:col-6">
                          <label :for="`editRecordRuleDisplayName${i}`">Display Name</label>
                          <div>
                            <InputText 
                              :id="`editRecordRuleDisplayName${i}`" 
                              class="inputfield w-full"
                              type="text" 
                              v-model="rule.DisplayName"
                            />
                          </div>
                        </div>
                        <div class="field col-12 sm:col-6">
                          <label :for="`editRecordRuleType${i}`">Type</label>
                          <div>
                            <Dropdown
                              :inputId="`editRecordRuleType${i}`" 
                              class="inputfield w-full"
                              v-model="rule.Type"
                              :options="ruleTypes"
                              :optionLabel="x => x"
                              :optionValue="x => x"
                              @change="onRuleTypeChange(i)"
                            />
                          </div>
                        </div>

                        <div class="field col-12 sm:col-6" v-if="isFieldAvailable(rule.DollarsPerDay)">
                          <label :for="`editRecordRuleDollarsPerDay${i}`">Dollars per Day</label>
                          <div>
                            <InputNumber
                              :id="`editRecordRuleDollarsPerDay${i}`" 
                              class="inputfield w-full"
                              v-model="rule.DollarsPerDay"
                              :minFractionDigits="0"
                              :maxFractionDigits="20"
                            />
                          </div>
                        </div>
                        <div class="field col-12 sm:col-6" v-if="isFieldAvailable(rule.CentsPerkWh)">
                          <label :for="`editRecordRuleCentsPerkWh${i}`">Cents per kWh</label>
                          <div>
                            <InputNumber
                              :id="`editRecordRuleCentsPerkWh${i}`" 
                              class="inputfield w-full"
                              v-model="rule.CentsPerkWh"
                              :minFractionDigits="0"
                              :maxFractionDigits="20"
                            />
                          </div>
                        </div>
                        <div class="field col-12 sm:col-6" v-if="isFieldAvailable(rule.kWhUnits)">
                          <label :for="`editRecordRulekWhUnits${i}`">Up to kWh</label>
                          <div>
                            <InputNumber
                              :id="`editRecordRulekWhUnits${i}`" 
                              class="inputfield w-full"
                              v-model="rule.kWhUnits"
                              :minFractionDigits="0"
                              :maxFractionDigits="20"
                            />
                          </div>
                        </div>
                        <div class="field col-12 sm:col-6" v-if="isFieldAvailable(rule.Period)">
                          <label :for="`editRecordRulePeriod${i}`">Period</label>
                          <div>
                            <Dropdown
                              :inputId="`editRecordRulePeriod${i}`" 
                              class="inputfield w-full"
                              v-model="rule.Period"
                              :options="['All day', 'Custom']"
                              :optionLabel="x => x"
                              :optionValue="x => x"
                            />
                          </div>
                        </div>
                        <div class="field col-12 sm:col-6" v-if="isFieldAvailable(rule.StartTime) && rule.Period === 'Custom'">
                          <label :for="`editRecordRuleStartTime${i}`">Start Time</label>
                          <div>
                            <input
                              :id="`editRecordRuleStartTime${i}`" 
                              class="w-full p-inputtext p-component"
                              v-model="rule.StartTime"
                              v-maska data-maska="##:##:##"
                              @blur="validateTime(rule, 0)"
                            />
                          </div>
                        </div>
                        <div class="field col-12 sm:col-6" v-if="isFieldAvailable(rule.EndTime) && rule.Period === 'Custom'">
                          <label :for="`editRecordRuleEndTime${i}`">End Time</label>
                          <div>
                            <input
                              :id="`editRecordRuleEndTime${i}`" 
                              class="w-full p-inputtext p-component"
                              v-model="rule.EndTime"
                              v-maska data-maska="##:##:##"
                              @blur="validateTime(rule, 2)"
                            />
                          </div>
                        </div>
                        <div class="field col-12 sm:col-6" v-if="isFieldAvailable(rule.DollarsPerkW)">
                          <label :for="`editRecordRuleDollarsPerkW${i}`">Dollars per kW</label>
                          <div>
                            <InputNumber
                              :id="`editRecordRuleDollarsPerkW${i}`" 
                              class="inputfield w-full"
                              v-model="rule.DollarsPerkW"
                              :minFractionDigits="0"
                              :maxFractionDigits="20"
                            />
                          </div>
                        </div>
                        <div class="field col-12 sm:col-6" v-if="isFieldAvailable(rule.ThresholdkW)">
                          <label :for="`editRecordRuleThresholdkW${i}`">Threshold kW</label>
                          <div>
                            <InputNumber
                              :id="`editRecordRuleThresholdkW${i}`" 
                              class="inputfield w-full"
                              v-model="rule.ThresholdkW"
                              :minFractionDigits="0"
                              :maxFractionDigits="20"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #header>
        <div class="flex-auto flex justify-content-between align-items-center gap-4">
          <span class="p-dialog-title">Tariff</span>
          <div class="color-themes-logs-switch-field pr-3" v-if="canEditGlobal">
            <div class="flex align-items-center">
              <InputSwitch 
                  inputId="color-themes-edit-global"
                  v-model="selectedEntityIsGlobal"
                  class="vertical-align-top"
                  :disabled="updateInProgress"
                />
              <label for="color-themes-edit-global" class="mb-0 ml-2">Public</label>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button 
              v-if="editRecord" 
              :label="editRecord.Id ? 'Update' : 'Create'" 
              :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
              @click="saveRecord" 
              :disabled='!editRecord.Name || !editRecord.Retailer || updateInProgress || editRecord.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin' 
            />
          </span>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Message from 'primevue/message';
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import ToastService from "@/services/ToastService";
import copy from 'copy-to-clipboard';
import ConfirmationService from "@/services/ConfirmationService";
import { useTariffStore } from "@/stores/tariff";
import { TariffModel } from "@/models/tariff/TariffModel";
import { RuleModel } from "@/models/tariff/RuleModel";
import { vMaska } from "maska";
import { useOrganisationStore } from "@/stores/organisation";

@Component({
  components: {
    Message,
    Button,
    InputText,
    InputNumber,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown,
    Textarea,
    IconField,
    InputIcon
  },
  directives: {
    maska: vMaska
  }
})
class OrganisationTariffsView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  tariffStore = useTariffStore();

  filters = {
    'global': {value: null, matchMode: 'contains'},
    Name: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
    Category: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
    Retailer: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
  };

  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }

  // #region new/edit
  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get selectedEntityIsGlobal(): boolean {
    return this.editRecord?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.editRecord) {
      if (value) {
        this.editRecord.OrganisationId = 0;
      } else {
        this.editRecord.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  displayCreateUpdateDialog = false;
  editRecord: TariffModel | null = null;

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  created(): void {
    if (!this.tariffStore.isLoaded) {
      this.tariffStore.loadTariffs();
    }
  }

  openCloneDialog(record: TariffModel): void {
    const newRecord: TariffModel = JSON.parse(JSON.stringify(record));
    newRecord.Id = "";
    if (!this.canEditGlobal) {
      newRecord.OrganisationId = this.currentOrganisation?.Id ?? -1;
    }
    this.openCreateUpdateDialog(newRecord);
  }

  openCreateUpdateDialog(record: TariffModel | null): void {
    this.editRecord = record ? JSON.parse(JSON.stringify(record)) : {
      Id: "",
      Name: "",
      Category: "",
      Notes: "",
      Number: "",
      Retailer: "",
      Rules: [],
      State: "",
      OrganisationId: this.currentOrganisation ? this.currentOrganisation.Id : -1,
      creator: this.allUserData.userName
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.tariffStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.tariffStore.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.tariffStore.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }

  ruleTypes = [
    "Volume Flat",
    "Supply Charge",
    "Transitional (first)",
    "Transitional (next)",
    "Transitional (remaining)",
    "Weekdays",
    "Weekends",
    "Daily",
    "Other Times",
    "Demand Charge",
    "Minimum Charge"
  ]

  onRuleTypeChange(i: number): void {
    if (this.editRecord) {
      this.editRecord.Rules[i].DollarsPerDay = undefined;
      this.editRecord.Rules[i].CentsPerkWh = undefined;
      this.editRecord.Rules[i].kWhUnits = undefined;
      this.editRecord.Rules[i].Period = undefined;
      this.editRecord.Rules[i].StartTime = undefined;
      this.editRecord.Rules[i].EndTime = undefined;
      this.editRecord.Rules[i].DollarsPerkW = undefined;
      this.editRecord.Rules[i].ThresholdkW = undefined;
      this.editRecord.Rules[i].DateFrom = undefined;
      this.editRecord.Rules[i].DateTo = undefined;
      this.editRecord.Rules[i].Months = undefined;
      switch (this.editRecord.Rules[i].Type) {
        case "Volume Flat":
          this.editRecord.Rules[i].CentsPerkWh = 0;
          break;
        case "Supply Charge":
          this.editRecord.Rules[i].DollarsPerDay = 0;
          break;
        case "Transitional (first)":
          this.editRecord.Rules[i].CentsPerkWh = 0;
          this.editRecord.Rules[i].kWhUnits = 0;
          break;
        case "Transitional (next)":
          this.editRecord.Rules[i].CentsPerkWh = 0;
          this.editRecord.Rules[i].kWhUnits = 0;
          break;
        case "Transitional (remaining)":
          this.editRecord.Rules[i].CentsPerkWh = 0;
          break;
        case "Weekdays":
          this.editRecord.Rules[i].Period = "All day";
          this.editRecord.Rules[i].StartTime = "08:00:00";
          this.editRecord.Rules[i].EndTime = "20:00:00";
          this.editRecord.Rules[i].CentsPerkWh = 0;
          break;
        case "Weekends":
          this.editRecord.Rules[i].Period = "All day";
          this.editRecord.Rules[i].StartTime = "08:00:00";
          this.editRecord.Rules[i].EndTime = "20:00:00";
          this.editRecord.Rules[i].CentsPerkWh = 0;
          break;
        case "Daily":
          this.editRecord.Rules[i].Period = "All day";
          this.editRecord.Rules[i].StartTime = "08:00:00";
          this.editRecord.Rules[i].EndTime = "20:00:00";
          this.editRecord.Rules[i].CentsPerkWh = 0;
          break;
        case "Other Times":
          this.editRecord.Rules[i].CentsPerkWh = 0;
          break;
        case "Demand Charge":
          this.editRecord.Rules[i].DollarsPerkW = 0;
          this.editRecord.Rules[i].ThresholdkW = 0;
          break;
        case "Minimum Charge":
          this.editRecord.Rules[i].DollarsPerDay = 0;
          break;
      }
    }
  }

  getNewRule(): RuleModel {
    return { 
      DisplayName: '',
      Type: '',
      DollarsPerDay: undefined,
      CentsPerkWh: undefined,
      kWhUnits: undefined,
      Period: undefined,
      StartTime: undefined,
      EndTime: undefined,
      DollarsPerkW: undefined,
      ThresholdkW: undefined,
      DateFrom: undefined,
      DateTo: undefined,
      Months: undefined,
    }
  }

  isFieldAvailable(value: any): boolean {
    return value !== undefined && value !== null;
  }

  validateTime(rule: RuleModel, input: number) {
    const value = input === 0 ? rule.StartTime : rule.EndTime;
    let newValue = "";
    if (value) {
      const parts = value.split(":");
      let hours = parseInt(parts[0]);
      let minutes = parseInt(parts[1] ? parts[1] : "0");
      let seconds = parseInt(parts[2] ? parts[2] : "0");
      if (seconds > 59) {
        seconds = 59;
      }
      if (minutes > 59) {
        minutes = 59;
      }
      if (hours > 24) {
        hours = 24;
      }
      if (hours === 24) {
        minutes = 0;
        seconds = 0;
      }
      newValue = `${hours.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}:${minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}:${seconds.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}`
    } else {
      newValue = "00:00:00";
    }
    if (input === 0) {
      rule.StartTime = newValue;
    } else {
      rule.EndTime = newValue;
    }
  }
  // #endregion new/edit

  // #region delete
  selectedRecord: TariffModel | null = null;

  openConfirmation(record: TariffModel | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete tariff?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Tariff',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.tariffStore.delete(this.selectedRecord);
    }
  }
  // #endregion delete
}

export default OrganisationTariffsView;
</script>