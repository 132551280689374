<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10" fill="none">
    <path stroke="var(--report-control-button-color)" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M8.335 5H1.668m3.333-3.333v6.667"/>
  </svg>
  <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="none">
    <path fill="var(--report-control-button-color)" fill-rule="evenodd" d="M3.5.749a2.75 2.75 0 0 0-2.75 2.75v10.184a2.75 2.75 0 0 0 2.75 2.75h7.828c.556.322 1.2.507 1.889.507a3.79 3.79 0 0 0 3.782-3.796c0-.733-.207-1.417-.566-1.998V3.5a2.75 2.75 0 0 0-2.75-2.75H3.5Zm11.433 9.012V6.477h-3.728V9.93a3.753 3.753 0 0 1 2.011-.58c.618 0 1.202.148 1.717.412Zm-4.228.545V6.477H6.478v4.228h3.84a3.81 3.81 0 0 1 .387-.399Zm-.74.899H6.478v3.728H9.88a3.787 3.787 0 0 1-.446-1.789c0-.708.194-1.371.53-1.939ZM2.25 3.499c0-.69.56-1.25 1.25-1.25h2.478v3.728H2.25V3.5Zm4.228 2.478V2.25h4.227v3.728H6.478Zm-4.228.5h3.728v4.228H2.25V6.477Zm0 4.728h3.728v3.728H3.5c-.69 0-1.25-.56-1.25-1.25v-2.478Zm12.683 1.75h-1.527V11.5a.188.188 0 0 0-.19-.19.188.188 0 0 0-.189.19v1.454H11.45a.188.188 0 0 0-.19.19c0 .106.084.19.19.19h1.577v1.454c0 .059.026.11.067.145h.245a.189.189 0 0 0 .067-.146v-1.453h1.527v-.38Zm-3.728-6.978V2.25h2.478c.69 0 1.25.56 1.25 1.25v2.478h-3.728Z" clip-rule="evenodd"/>
  </svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class AddReportGridSvg extends Vue {
}

export default AddReportGridSvg;
</script>