export enum AggregationTypeString {
  /**retrieve all logs without aggregation */
  None,

  /**within an aggregation period, return the string which is most common (logged the most times) */
  Most,

  /**within an aggregation period, return the string which is least common (logged the least times) */
  Least,

  /**within an aggregation period, return the string which is first logged */
  First,

  /**within an aggregation period, return the string which is last logged */
  Last,
}
