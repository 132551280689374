<template>
  <div class="widget-type-ai-button"
    :class="[ aws?.fillType ]">
    <div v-if="isNoImage" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType" message="Image have not been selected."/>
    </div>
    <div 
      v-else 
      :style="widgetImageStyles" 
      :class="hasLink ? 'cursor-pointer' : ''" 
      @click="goToLink"
    ></div>
  </div>
</template>

<script lang="ts">
import WidgetHelper from '@/helpers/WidgetHelper';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { WidgetDescription } from '@/models/dashboard/WidgetDescription';
import { Component, Prop, Vue, Watch } from 'vue-facing-decorator';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import NavigationHelper from '@/helpers/NavigationHelper';
import { DashboardType } from '@/models/dashboard/DashboardType';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import WidgetDataState from '@/store/states/WidgetDataState';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import DashboardState from '@/store/states/DashboardState';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import DataHelper from '@/helpers/DataHelper';
import ToastService from '@/services/ToastService';

@Component({
  components: {
    WidgetNoDataView
  }
})
class AIButtonWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;
  @Prop({ required: true }) openBitpoolAIDialog!: (widget: SpaceWidgetConfig) => void;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get isNoImage(): boolean {
    return !this.aws?.widgetImageSrc;
  }

  noDataType = WidgetNoDataTypes.NotConfigured;

  get widgetDescription(): WidgetDescription | undefined {
    return this.widgetConfig && this.widgetConfig.widgetType ? WidgetHelper.getWidget(this.widgetConfig.widgetType) : undefined;
  }

  get widgetImageStyles(): string[] {
    const result: string[] = [
      `background-image: url("${this.aws?.widgetImageSrc ?? ''}")`,
      `min-height: ${this.aws?.minHeight ? this.aws?.minHeight : 200}px`
    ];
    return result;
  }

  get hasLink(): boolean {
    return this.aws?.linkType === 2 || this.aws?.linkType === 1 && !!this.aws?.urlLink || this.aws?.linkType === 0 && !!this.aws?.link;
  }

  get link(): string | undefined {
    if (this.aws?.linkType === 0) {
      const id = this.aws.link;
      const dashboardType = this.aws.linkDashboardType === DashboardType.Personal ? DashboardType.Personal : DashboardType.Organisation;
      return `/dashboards?pane=${dashboardType}&id=${id}`;
    } else {
      return this.aws?.urlLink;
    }
  }

  goToLink(event: Event): void {
    if (this.aws?.linkType === 0 && this.aws.link) {
      event.preventDefault();
      const id = this.aws.link;
      const dashboardType = this.aws.linkDashboardType === DashboardType.Personal ? DashboardType.Personal : DashboardType.Organisation;
      if (id) {
        NavigationHelper.goToDashboard(dashboardType, id);
      }
    } else if (this.aws?.linkType === 1 && this.aws.urlLink) {
      window.open(this.aws.urlLink, '_blank');
    } else if (this.aws?.linkType === 2) {
      this.openBitpoolAIDialog(this.widget);
    }
  }

  // #region optionsl data
  isLodingData = false;
  isNoData = false;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      }
    });
  }
  // #endregion optionsl data
}

export default AIButtonWidget;
</script>