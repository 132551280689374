<template>
<svg width="132" height="100" viewBox="0 0 132 100" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="132" height="99.8049" fill="var(--widget-svg-color-first)"/>
<g filter="url(#filter0_d_621_182)">
<path d="M81.7391 87.7223H46.7929C44.0487 87.7223 41.8161 85.4891 41.8161 82.7443C41.8161 80.0001 44.0487 77.7675 46.7929 77.7675H56.7967H57.9997V76.5644V68.819V67.6161H56.7967H22.7643C19.8796 67.6161 17.1655 66.4944 15.122 64.4577C13.1071 62.4493 11.9795 59.7862 11.939 56.9472L21.0153 11.9351H105.704L116.509 57.0675L116.58 57.362C116.437 60.1108 115.266 62.673 113.276 64.5897C111.249 66.5413 108.583 67.6161 105.768 67.6161H70.4913H69.2884V68.819V76.5644V77.7675H70.4913H81.7391C84.484 77.7675 86.717 80.0001 86.717 82.7443C86.717 85.4891 84.484 87.7223 81.7391 87.7223Z" fill="#FDC536"/>
<path d="M46.6588 87.9216H81.605C84.3498 87.9216 86.5829 85.6884 86.5829 82.9436C86.5829 80.1994 84.3498 77.9667 81.605 77.9667H70.3572H69.1543V76.7637V69.0183V67.8154H70.3572H105.634C108.448 67.8154 111.115 66.7406 113.142 64.789C115.132 62.8723 116.303 60.3101 116.445 57.5613L116.375 57.2668L11.8049 57.1465C11.8453 59.9855 12.9729 62.6486 14.9879 64.657C17.0313 66.6937 19.7455 67.8154 22.6302 67.8154H56.6625H57.8656V69.0183V76.7637V77.9667H56.6625H46.6588C43.9146 77.9667 41.6819 80.1994 41.6819 82.9436C41.6819 85.6884 43.9146 87.9216 46.6588 87.9216Z" fill="var(--widget-svg-color-first)"/>
<path d="M81.7391 86.5192C83.8207 86.5192 85.5141 84.8257 85.5141 82.7442C85.5141 80.6632 83.8207 78.9702 81.7391 78.9702H68.0855V66.413H105.768C108.27 66.413 110.64 65.4576 112.441 63.723C114.17 62.0586 115.203 59.8465 115.368 57.4678L104.755 13.1379H21.9999L13.1445 57.0549C13.2113 59.5314 14.2099 61.8498 15.9713 63.6054C17.7877 65.4159 20.2002 66.413 22.7644 66.413H59.2028V78.9702H46.793C44.7121 78.9702 43.019 80.6632 43.019 82.7442C43.019 84.8257 44.7121 86.5192 46.793 86.5192H81.7391ZM81.7391 88.925H46.793C43.3794 88.925 40.6132 86.1577 40.6132 82.7442C40.6132 79.3316 43.3794 76.5643 46.793 76.5643H56.7968V68.8189H22.7644C16.1358 68.8189 10.7587 63.4558 10.7351 56.8328C10.735 56.8186 10.7349 56.8037 10.7349 56.7894V56.7873H10.7441L20.0306 10.7319H106.653L117.679 56.7873L117.798 56.7894C117.798 56.9423 117.794 57.0949 117.789 57.2464L117.798 57.2821L117.787 57.282C117.529 63.6969 112.247 68.8189 105.768 68.8189H70.4913V76.5643H81.7391C85.1528 76.5643 87.9201 79.3316 87.9201 82.7442C87.9201 86.1577 85.1528 88.925 81.7391 88.925Z" fill="var(--widget-svg-color-fifth)"/>
</g>
<path fill-rule="evenodd" clip-rule="evenodd" d="M80.3879 57.2015L78.8012 11.1692L80.9463 11.0952L82.533 57.1276L80.3879 57.2015Z" fill="#EAEAEA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M46.3402 57.1066L48.8328 11.0742L50.976 11.1903L48.4834 57.2227L46.3402 57.1066Z" fill="#EAEAEA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M18.3399 24.9771H110.537V27.1234H18.3399V24.9771Z" fill="#EAEAEA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M14.8298 41.4707H114.314V43.617H14.8298V41.4707Z" fill="#EAEAEA"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M11.8729 55.5547H118.052V58.7742H11.8729V55.5547Z" fill="var(--widget-svg-color-fifth)"/>
<defs>
<filter id="filter0_d_621_182" x="4.73492" y="7.73193" width="119.063" height="90.1929" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="3"/>
<feColorMatrix v-if="isDarkTheme" type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.161 0"/>
<feColorMatrix v-else type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_621_182"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_621_182" result="shape"/>
</filter>
</defs>
</svg>
</template>

<script lang="ts">
import AuthState from "@/store/states/AuthState";
import { Component, Vue } from "vue-facing-decorator";

@Component
class SolarGeneratorSvg extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }
}

export default SolarGeneratorSvg;
</script>