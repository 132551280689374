import * as signalR from "@microsoft/signalr";

export class SignalRConnection {
  connection: signalR.HubConnection | null = null;
  initialized = false;
  hubName = "";
  eventName = "";
  subscribeName = "";
  unsubscribeName = "";
  eventCallback: ((...args: any[]) => void) | null = null;
  subscribers: string[] = [];

  connectToHub(
    hubName: string,
    eventName: string,
    subscribeName: string,
    unsubscribeName: string,
    eventCallback: (...args: any[]) => void
  ): void {
    this.hubName = hubName;
    this.eventName = eventName;
    this.subscribeName = subscribeName;
    this.unsubscribeName = unsubscribeName;
    this.eventCallback = eventCallback;
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(hubName)
      .configureLogging(signalR.LogLevel.Information)
      .build();
    connection.onclose(async () => {
      this.initialized = false;
      await this.start();
    });
    this.connection = connection;

    // Start the connection.
    this.start();
  }

  async start(): Promise<void> {
    try {
      if (this.connection) {
        await this.connection.start();
        console.log("SignalR Connected: " + this.hubName);

        const events = this.eventName.split(",");
        events.forEach(event => {
          this.connection?.on(event, (...args: any[]) => {
            if (events.length > 1) {
              this.callback(event, ...args);
            }
            else {
              this.callback(...args);
            }
          });
        });
        
        this.initialized = true;
        this.resubscribe();
      }
    } catch (err) {
      console.log(err);
      window.setTimeout(this.start, 5000);
    }
  }

  callback(...args: any[]): void {
    if (this.eventCallback) {
      this.eventCallback(this.hubName, ...args);
    }
  }

  resubscribe(): void {
    this.subscribeMany(this.subscribers, true);
  }

  subscribe(id: string): void {
    const index = this.subscribers.indexOf(id);
    if (index < 0) {
      if (this.initialized) {
        try {
          if (this.connection !== null) {
            this.connection.invoke(this.subscribeName, [id]);
          }
        } catch (exception) {
          console.error(`SignalR - can't subscribe ${this.hubName} - ${id}`);
        }
      }
      this.subscribers.push(id);
    }
  }

  subscribeMany(ids: string[], resubscribe: boolean): void {
    let newSubs: string[] = [];
    if (resubscribe) {
      newSubs = ids;
    } else {
      for (const id of ids) {
        const index = this.subscribers.indexOf(id);
        if (index < 0) {
          newSubs.push(id);
        }
      }
    }
    if (newSubs.length) {
      if (this.initialized) {
        try {
          if (this.connection !== null) {
            this.connection.invoke(this.subscribeName, newSubs);
          }
        } catch (exception) {
          console.error(`SignalR - can't subscribe ${this.hubName}`);
        }
      }
      if (!resubscribe) {
        for (const id of newSubs) {
          this.subscribers.push(id);
        }
      }
    }
  }

  unsubscribe(id: string): void {
    const index = this.subscribers.indexOf(id);
    if (index >= 0) {
      this.subscribers.splice(index, 1);
    }
    if (this.initialized && this.connection) {
      try {
        this.connection.invoke(this.unsubscribeName, [id]);
      } catch (exception) {
        console.error(`SignalR - can't unsubscribe ${this.hubName} - ${id}`);
      }
    }
  }

  unsubscribeMany(ids: string[]): void {
    const newUnsubs = [];
    for (const id of ids) {
      const index = this.subscribers.indexOf(id);
      if (index >= 0) {
        newUnsubs.push(id);
        this.subscribers.splice(index, 1);
      }
    }
    if (this.initialized && this.connection) {
      try {
        this.connection.invoke(this.unsubscribeName, newUnsubs);
      } catch (exception) {
        console.error(`SignalR - can't unsubscribe ${this.hubName}`);
      }
    }
  }
}