<template>
  <div ref="root" v-if="isMounted" class="widget-type-chart widget-type-activity-gauge">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-else class="chart-element activity-gauge chart-with-text-data">
      <span v-if="vertivalCenter > 0" class="text-data" :style="{ color: valueColor, top: vertivalCenter + 'px', fontSize: valueFontSize + 'px' }">{{centredValue.toFixed(2)}} {{units}}</span>
      <highcharts v-if="redrawChartToggle" ref="chartElement" class="chart-top w-full h-full flex-auto" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Ref } from 'vue-facing-decorator';
import StreamOption from '../../models/dashboard/StreamOption';
import { Chart } from 'highcharts-vue';
import Highcharts from 'highcharts';
import moreModule from 'highcharts/highcharts-more';
import solidGaugeModule from 'highcharts/modules/solid-gauge';
moreModule(Highcharts);
solidGaugeModule(Highcharts);
import ColorHelper from '../../helpers/ColorHelper';
import { nextTick, PropType } from 'vue';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { BasicWidgetSettings } from '@/models/dashboard/BasicWidgetSettings';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import DashboardState from '@/store/states/DashboardState';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import { Watch } from 'vue-facing-decorator';
import DataHelper from '@/helpers/DataHelper';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';

(function(H: any) {
  H.wrap(H.Legend.prototype, 'colorizeItem', function(this:any, proceed: any, item: any, visible: boolean) {
    if (this.chart.options.chart.overwriteLegendColor) {
      item.color = item.userOptions.color;
    }
    // eslint-disable-next-line
    proceed.apply(this, Array.prototype.slice.call(arguments, 1));
  });
}(Highcharts));

@Component({
  components: {
    highcharts: Chart,
    ProgressSpinner,
    WidgetNoDataView
  }
})
class ActivityGaugeWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  streamColors: string[] = [];
  names: string[] = [];
  series: Highcharts.SeriesSolidgaugeOptions[] = [];
  centredValue = 0;
  centredValueNoHower = 0;
  vertivalCenter = 0;
  valueFontSize = 0;
  get valueColor(): string {
    return this.selectedColor ? this.selectedColor : ColorHelper.getDefautTextColor();
  }
  selectedColor = "";
  units = '';

  isMounted = false;
  @Ref() readonly root!: HTMLDivElement;

  get chartOptions(): Highcharts.Options {
    const units = this.aws ? this.aws.widgetUnit : "";
    const calculateVericalCenter = this.calculateVericalCenter;
    const series = this.series;
    const pane: Array<Highcharts.PaneBackgroundOptions> = [];
    series.forEach(element => {
      const point = element.data && element.data.length ? element.data[0] as Highcharts.PointOptionsObject : null;
      pane.push({
        outerRadius: point ? point.radius : "115%",
        innerRadius: point ? point.innerRadius : "35%",
        backgroundColor: Highcharts.color(point ? point.color as string : "#0689BC")
          .setOpacity(0.3)
          .get(),
        borderWidth: 0
      });
    });
    const result: Highcharts.Options = {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "solidgauge",
        animation: true,
        style: {
          color: ColorHelper.getDefautTextColor(),
        },
        plotShadow: false,
        events: {
          load() {
            window.setTimeout(async () => {
              this.reflow.bind(this);
              await nextTick();
              calculateVericalCenter();
            }); 
          }
        }
      },
      tooltip: {
        enabled: true,
        formatter: function() {
          return `<span style="color:${this.color};">\u25CF </span><b>${this.series.name}:</b> ${(this.y ?? 0).toFixed(2)} ${units}`;
        },
        useHTML: true,
        followPointer: true,
        outside: true
      },
      plotOptions: {
        solidgauge: {
          cursor: 'pointer',
          dataLabels: {
            enabled: false
          },
          linecap: 'round',
          stickyTracking: false,
          rounded: true,
          showInLegend: true
        }
      },
      yAxis: {
        lineWidth: 0,
        tickPositions: []
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        itemStyle: {
          font: "500 10px Arial, sans-serif",
          verticalAlign: 'middle'
        },
        symbolHeight: 8,
        symbolWidth: 8,
        maxHeight: 40
      },
      series: series,
      pane: {
        startAngle: 0,
        endAngle: 360,
        background: pane
      }
    };
    (result.chart as any).overwriteLegendColor = true;
    return result;
  }

  async calculateVericalCenter(): Promise<void> {
    if (this.root) {
      this.vertivalCenter = 0;
      await nextTick();
      const chartElement = this.root.querySelector('.highcharts-plot-background');
      const chartTopElement = this.root.querySelector('.chart-top');
      let vertivalCenter = 0;
      let valueFontSize = 12;
      if (chartElement && chartTopElement) {
        const chartElementRect =  chartElement.getBoundingClientRect();
        const chartTopElementRect =  chartTopElement.getBoundingClientRect();
        vertivalCenter = - chartTopElementRect.top + chartElementRect.top + chartElementRect.height / 2;
        valueFontSize = Math.round(chartElementRect.width / 15);
      }
      this.vertivalCenter = vertivalCenter;
      this.valueFontSize = valueFontSize;
    } else {
      this.vertivalCenter = 0;
      this.valueFontSize = 12;
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }
  
  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    this.units = this.aws?.widgetUnit ? this.aws.widgetUnit : "";
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataRefreshInterval = 0;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;
  
  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
    this.isMounted = true;
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.resizeWidgetEvent();
  }

  loadConfig(): void {
    if (this.wds &&
      this.wds.streamOptions) {
      const streams = this.wds.streamOptions as StreamOption[];
      this.names = [];
      this.streamColors = [];
      streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
        this.streamColors.push(stream.hexStreamColor);
      });
    }
  }

  resetCentredValue(): void {
    this.centredValue = this.centredValueNoHower;
    this.selectedColor = "";
  }

  updateCentredValue(value: number | undefined, color: string | undefined): void {
    this.centredValue = value ? value : 0;
    this.selectedColor = color ? color : "";
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.loadConfig();
    let i = 0;
    const series: Highcharts.SeriesSolidgaugeOptions[] = [];
    const centerValueType = this.aws?.widgetActivityGaugeCenterValueType ?
      this.aws.widgetActivityGaugeCenterValueType :
      "Max";
    let centredValueNoHower = 0;
    const minRadius = 35;
    const maxRadius = 115;
    const maxThickness = this.aws?.widgetActivityGaugeMaxThickness ?
      this.aws.widgetActivityGaugeMaxThickness :
      20;
    let step = (maxRadius - minRadius) / (this.chartData.length ? this.chartData.length : 1);
    if (step > maxThickness) {
      step = maxThickness;
    }
    let currentRadius = maxRadius;
    const updateCentredValue = this.updateCentredValue;
    const resetCentredValue = this.resetCentredValue;
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length) {
        series.push({
          name: this.names.length > i ? this.names[i] : '',
          color: this.streamColors.length > i ? this.streamColors[i] : '',
          colorByPoint: true,
          type: 'solidgauge',
          data: [{
            y: typeof streamData.Data[0].y === "number" ? streamData.Data[0].y : 0,
            color: this.streamColors.length > i ? this.streamColors[i] : '',
            radius: `${currentRadius}%`,
            innerRadius: `${currentRadius - step}%`,
          }],
          point: {
            events: {
              mouseOver: function () {
                updateCentredValue(this.y, this.color as string);
              }
            }
          },
          events: {
            mouseOut: function () {
              resetCentredValue();
            }
          }
        });
        if (streamData.Data[0].y && typeof streamData.Data[0].y === "number") {
          switch (centerValueType) {
            default:{
            // Max
              if (i === 0) {
                centredValueNoHower = streamData.Data[0].y;
              } else {
                if (centredValueNoHower < streamData.Data[0].y) {
                  centredValueNoHower = streamData.Data[0].y
                }
              }
              break;
            }
            case "Min": {
              if (i === 0) {
                centredValueNoHower = streamData.Data[0].y;
              } else {
                if (centredValueNoHower > streamData.Data[0].y) {
                  centredValueNoHower = streamData.Data[0].y
                }
              }
              break;
            }
            case "Avg": {
              centredValueNoHower += streamData.Data[0].y;
              break;
            }
          }
        }
      }
      i++;
      currentRadius = currentRadius - step;
    });
    if (centerValueType === "Avg") {
      centredValueNoHower /= series.length ? series.length : 1;
    }
    this.centredValueNoHower = centredValueNoHower;
    this.centredValue = centredValueNoHower;

    this.series = series;
    let allZero = true;
    for(let i = 0; i < series.length; i++) {
      const s = series[i];
      if (s.data && s.data.length) {
        const point = s.data[0] as Highcharts.PointOptionsObject;
        if (point && point.y) {
          allZero = false;
          break;
        }
      }
    }
    if (series.length && !allZero) {
      this.isNoData = false;
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  redrawChartToggle = true;

  // chart reflow on widget resize
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async resizeWidgetEvent(): Promise<void> {
    this.redrawChartToggle = false;
    await nextTick();
    this.redrawChartToggle = true;
    this.calculateVericalCenter();
  }
}

export default ActivityGaugeWidget;
</script>
