<template>
  <div v-if="isMounted" class="widget-type-chart widget-type-gauge flex align-items-center">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="chart-element" style="height: 300px; min-height: 0;" v-else>
      <highcharts v-if="redrawChartToggle" ref="chartElement" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import StreamOption from '../../models/dashboard/StreamOption';
import DateHelper from '../../helpers/DateHelper';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import solidGaugeModule from 'highcharts/modules/solid-gauge';
solidGaugeModule(Highcharts);
import { nextTick, PropType } from 'vue';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { BasicWidgetSettings } from '@/models/dashboard/BasicWidgetSettings';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import DashboardState from '@/store/states/DashboardState';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import { Watch } from 'vue-facing-decorator';
import DataHelper from '@/helpers/DataHelper';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import ColorHelper from '@/helpers/ColorHelper';

@Component({
  components: {
    highcharts: Chart,
    ProgressSpinner,
    WidgetNoDataView
  }
})
class GaugeWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  streams: StreamOption[] = [];
  names: string[] = [];
  chartRecords: number[] = [];
  dates: [Date, Date] | null = null;

  isMounted = false;

  get chartOptions(): Highcharts.Options {
    const chartRecords = this.chartRecords;
    const stops = this.getStops();
    const minMax = this.getMinMax();
    const decimalPlaces = this.aws?.decimals ? this.aws.decimals : 0;
    const gaugeInnerSize = this.aws?.gaugeInnerSize ? this.aws.gaugeInnerSize : 60;
    const units = this.aws ? this.aws.widgetUnit : "";
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "solidgauge",
        animation: true,
        style: {
          color: ColorHelper.getDefautTextColor(),
        },
        spacingBottom: -100,
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          }
        }
      },
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: [{
          backgroundColor: '#EEE',
          innerRadius: `${gaugeInnerSize}%`,
          outerRadius: '100%',
          shape: 'arc'
        }]
      },
      tooltip: {
        enabled: true,
        useHTML: true,
        outside: true
      },
      yAxis: [{
        min: minMax[0],
        max: minMax[1],
        stops: stops,
        lineWidth: 0,
        tickWidth: 0,
        minorTickInterval: undefined,
        tickAmount: 2,
        title: {
          text: null
        },
        labels: {
          y: 16
        }
      }],
      plotOptions: {
        solidgauge: {
          innerRadius: `${gaugeInnerSize}%`,
          dataLabels: {
            y: 5,
            borderWidth: 0,
            useHTML: true
          }
        }
      },
      series: [{
        type: "solidgauge",
        name: this.streams.length ? this.streams[0].Label : "Value",
        data: chartRecords,
        dataLabels: {
          color: ColorHelper.getDefautTextColor(),
          format:
            `<div style="text-align:center"><span style="font-size:25px">{y:.${decimalPlaces}f} ${units}</span></div>`
        },
        tooltip: {
          pointFormat: `{series.name}: <b>{point.y:.${decimalPlaces}f} ${units}</b>`
        }
      }]
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }
  
  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataRefreshInterval = 0;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
    this.isMounted = true;
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.resizeWidgetEvent();
  }

  loadConfig(): void {
    if (this.wds &&
      this.wds.streamOptions) {
      this.streams = this.wds.streamOptions;
      this.names = [];
      this.streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
      });
    }
  }

  getStops(): [number, string][] {
    const stops: [number, string][] = [];
    if (this.aws?.gaugeRange) {
      this.aws.gaugeRange.sort((a, b) => { return a.fromP - b.fromP; }).forEach((value, key) => {
        stops.push([(value.fromP / 100), value.color]);
      });
    }
    return stops;
  }

  getMinMax(): [number, number] {
    if (this.aws?.minMaxRange) {
      return [this.aws?.minMaxRange[0], this.aws?.minMaxRange[1]];
    }
    return [0, 100];
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.loadConfig();
    this.dates = DateHelper.extractDateFromRequestBody(requestBody);

    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      }
    });
    
    const seriesDataSource: number[] = [];

    if (data.length) {
      for (let i = 0; i < data[0].Data.length; i++) {
        const item = data[0].Data[i];
        seriesDataSource.push(typeof item.y === "number" ? item.y : 0);
      }
    }
    this.chartRecords = seriesDataSource;

    if (seriesDataSource.length) {
      this.isNoData = false;
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  redrawChartToggle = true;

  // chart reflow on widget resize
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async resizeWidgetEvent(): Promise<void> {
    this.redrawChartToggle = false;
    await nextTick();
    this.redrawChartToggle = true;
  }
}

export default GaugeWidget;
</script>