import { defineStore } from "pinia";
import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { KoneConfigEntity } from "@/models/KoneConfigEntity";
import KoneClientState from "./states/KoneClientState";

const useKoneClientStore = defineStore("koneClient", {
  state: (): KoneClientState => ({
    configs: [] as KoneConfigEntity[],
    isLoaded: false,
    updateInProgress: false,
    updateError: false,
    testInProgress: false,
    testError: false,
  }),
  actions: {
    async load() {
      try {
        this.isLoaded = false;
        const url = `rest/kone/client`;
        const response = await axios.get<KoneConfigEntity[]>(url);
        this.configs = response.data;
        this.isLoaded = true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load Kone client config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.configs = [];
        this.isLoaded = true;
      }
    },
    async createUpdate(body: KoneConfigEntity) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/kone/client`;
        const response = await axios.post<KoneConfigEntity>(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.updateInProgress = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
        if (body.Id) {
          const index = this.configs.findIndex((x) => x.Id === body.Id);
          if (index >= 0) {
            this.configs[index] = response.data;
          }
        } else {
          this.configs.push(response.data);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save Kone client config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateInProgress = false;
        this.updateError = true;
      }
    },
    async test(body: KoneConfigEntity) {
      try {
        this.testInProgress = true;
        this.testError = false;
        const url = `rest/kone/client/test`;
        await axios.post(url, body, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        this.testInProgress = false;
        ToastService.showToast("success", "", "Valid endpoint", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.testInProgress = false;
        this.testError = true;
      }
    },
    async delete(id: string) {
      try {
        const url = `rest/kone/client/${id}`;
        await axios.delete(url);
        ToastService.showToast("success", "", "Changes saved", 5000);
        for (let i = this.configs.length - 1; i >= 0; --i) {
          if (this.configs[i].Id === id) {
            this.configs.splice(i, 1);
            break;
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete Kone client config",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
  },
});

export default useKoneClientStore;