import { AuthKeyDto } from "@/models/user/AuthKeyDto";
import { BitpoolOSPermissions } from "@/models/BitpoolOSPermissions";
import { SharedDashboardInfo } from "@/models/dashboard/SharedDashboardInfo";
import { SharedPresentationInfo } from "@/models/dashboard/SharedPresentationInfo";
import { SharedWidgetInfo } from "@/models/dashboard/SharedWidgetInfo";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { UserSettingsModel } from "@/models/user/UserSettingsModel";
import { UserData } from "@/models/user/UserData";

export default class AuthState {
  authKey = "";
  permissions: BitpoolOSPermissions | null = null;
  orgRole = "";
  organisationChangingInProgress = false;

  isLoadedAllUsers = false;
  allUsers: UserData[] = [];

  isLoadedAuthKeys = false;
  authKeys: AuthKeyDto[] = [];

  createAuthKeyInProgress = false;
  createAuthKeyError = false;
  newAuthKey: string | null = null;

  enableSaveDashboard = false;

  jailMode = false; // for pages with buildin authkey like share and presentaion
  sharedDashboardInfo: SharedDashboardInfo | null = null;
  sharedWidgetInfo: SharedWidgetInfo | null = null;
  sharedPresentationInfo: SharedPresentationInfo | null = null;

  isLoadedUserSettings = false;
  userSettings: UserSettingsModel | null = null;
  guidUserSettings = "";
  userSettingsSaveError: string | null = null;

  userAdditionalDataSaveError: string | null = null;
  changePasswordError: string | null = null;
}
