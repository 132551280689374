import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import AIModelState from "./states/AIModelState";
import { BitpoolAIEndpoint } from "@/models/bitpool-ai/BitpoolAIEndpoint";

export const useAIModelStore = defineStore('AIModel', {
  state: (): AIModelState => ({ 
    isLoaded: false,
    guid: "",
    endpoints: null,
    endpointModels: null
  }),
  getters: {
    // compatiblity with pre-assitants
    entities: (state) => (state.endpointModels && state.endpointModels["openai-chat"]) ? state.endpointModels["openai-chat"] : null
  },
  actions: {
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        const urlEndpoints = `rest/AI_V1/endpoint`;
        const responseEndpoints = await axios.get<BitpoolAIEndpoint[]>(urlEndpoints);
        this.endpoints = responseEndpoints.data;
        const endpointModels: Record<string, Record<string, string>> = {};
        for (const endpoint of this.endpoints) {
          const url = `rest/AI_V1/model?endpoint=${endpoint.Id}`;
          const response = await axios.get<Record<string, string>>(url);
          if (this.guid === guid) {
            endpointModels[endpoint.Id] = response.data;
          } else {
            return;
          }
        }
        this.endpointModels = endpointModels;
        this.isLoaded = true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load ai models",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.endpointModels = null;
        this.isLoaded = true;
      }
    }
  }
})
