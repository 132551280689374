<template>
  <div class="default-view-container">
    <div class="xl:flex align-items-start justify-content-between">
      <Button 
        label="Back to schedules" 
        icon="pi pi-angle-left text-lg -ml-1" 
        class="p-button-outlined flex-order-2 mb-3 xl:mb-0 go-back-btn flex-shrink-0" 
        @click="goBack"
      />
      <div class="flex align-items-center xl:align-items-start gap-3 flex-shrink-1 xl:mr-5">
        <Breadcrumb
          :home="breadcrumbHome"
          :model="breadcrumbItems"
          class="mb-0 page-breadcrumb flex-shrink-1"
        >
          <template #separator>
            <span class="pi pi-chevron-right" aria-hidden="true"></span>
          </template>
        </Breadcrumb>
        <Chip v-if="editRecord?.CreatedBy === 'Nube iO'" label="Nube iO" class="nube-io-chip"></Chip>
      </div>
    </div>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5 mqtt-schedule-item">
      <header class="default-view-header">
        <h2>Edit Schedule</h2>
      </header>
      <div class="default-view-body">
        <div v-if="editRecord">
          <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index blockui-with-spinner-100 no-animation" :class="(updateInProgress) ? 'blockui-blocked' : ''">

            <div class="mqtt-schedule-item-header">
              <section>
                <div class="mb-3 lg:mb-0 lg:flex-order-1">
                  <InputText 
                    id="editRecordName" 
                    class="inputfield w-full"
                    type="text" 
                    v-model="editRecord.Name"
                    placeholder="Enter schedule name"
                    @input="isChangedEvent"
                  />
                </div>
                <div class="flex align-items-center py-1 lg:py-0 lg:flex-order-2 lg:ml-2 flex-shrink-0">
                  <InputSwitch 
                    inputId="editRecordEnabled"
                    v-model="editRecord.Enabled"
                    class="vertical-align-top"
                    @change="isChangedEvent"
                  />
                  <label for="editRecordEnabled" class="mb-0 ml-2">Enabled</label>
                </div>
                <div class="flex justify-content-end lg:flex-order-3 lg:ml-auto lg:pl-4 column-gap-2 lg:flex-shrink-0  mqtt-schedule-buttons">
                  <Button
                    label="Add Event"
                    icon="pi pi-plus"
                    class="w-full p-button-secondary flex-auto lg:flex-shrink-0 justify-content-center label-flex-none"
                    @click="openCreateUpdateEventDialog(null)"
                  />
                  <Button 
                    v-if="editRecord" 
                    :label="editRecord.Id ? 'Save Schedule' : 'Create Schedule'" 
                    :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-save'"
                    class="w-full flex-auto lg:flex-shrink-0 justify-content-center label-flex-none"
                    @click="saveRecord" 
                    :disabled='!editRecord.Name || updateInProgress || !isChanged' 
                  />
                </div>
              </section>
              <section v-if="editRecord.AdditionalData">
                <div class="flex align-items-center">
                  <label for="editRecordAdditionalDataFallback" class="mr-2 flex-shrink-0">Fallback</label>
                  <InputNumber
                    id="editRecordAdditionalDataFallback"
                    class="inputfield w-full flex-auto sm:max-w-20rem "
                    v-model="editRecord.AdditionalData.Fallback"
                    placeholder="Fallback"
                    @input="isChangedEvent"
                    :minFractionDigits="2"
                    :maxFractionDigits="20"
                  />
                </div>
              </section>
            </div>

            <!-- Calendar -->
            <div class="mqtt-schedule-item-body">
              <div class="mqtt-schedule-calendar-header">
                <div class="mqtt-schedule-calendar-filters">
                  <Button label="Today" @click="goToToday" class="p-button-outlined p-button-rounded mqtt-schedule-calendar-today"/>
                  <Dropdown 
                    v-model="currentView" 
                    :options="calendarViews" 
                    optionLabel="label"
                    optionValue="value"
                    @change="currentViewChangedEvent"
                    class="mqtt-schedule-calendar-date-range"
                  />
                </div>
                <div class="mqtt-schedule-calendar-dates">
                  <Button icon="pi pi-chevron-left" @click="goToPrev" severity="secondary" text rounded aria-label="Go to Prev"/>
                  <span>{{ currentTitle }}</span>
                  <Button icon="pi pi-chevron-right" @click="goToNext" severity="secondary" text rounded aria-label="Go to Next"/>
                </div>
              </div>
              <div class="mqtt-schedule-calendar xl:flex">
                <div class="mqtt-schedule-calendar-body flex-auto">
                  <FullCalendar ref="fullCalendar" :options="calendarOptions">
                    <template v-slot:eventContent='arg'>
                      <div 
                        v-tippy="arg.event.title" 
                        class="w-full overflow-hidden cursor-pointer border-round-sm mqtt-schedule-calendar-time-name"
                      >
                        <b>
                          {{ arg.timeText }} - {{ extractTimeFromDate(arg.event.end) }}
                        </b>
                        <span class="ml-1">{{ arg.event.title }}</span>
                      </div>
                    </template>
                  </FullCalendar>
                </div>
                <div class="mqtt-schedule-calendar-sidebar flex-shrink-0">
                  <FullCalendar ref="fullCalendarListDay" :options="calendarOptionsListDay" class="mqtt-schedule-calendar-events-list">
                  </FullCalendar>
                  <FullCalendar ref="fullCalendarListPeriod" :options="calendarOptionsListPeriod" class="mqtt-schedule-calendar-events-list">
                  </FullCalendar>
                </div>
              </div>
            </div>

            <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
          </BlockUI>
        </div>
        <div
          v-else
          class="w-full flex justify-content-center align-items-center flex-auto"
          style="min-height: 50vh"
        >
          <ProgressSpinner
            class="spinner-primary"
            style="width: 100px; height: 100px"
            strokeWidth="4"
            animationDuration="1s"
          />
        </div>
      </div>
    </div>
    <div v-else class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Schedule List</h2>
      </header>
      <div class="default-view-body">
        <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
      </div>
    </div>

    <Dialog header="Event" v-model:visible="displayCreateUpdateEventDialog" :modal="true" class="mqtt-scheduler-event-dialog" :style="{width: '56rem'}">
      <div class="dialog-content" v-if="editEvent">
        <div class="formgrid grid align-items-end">
          <div class="field col">
            <label for="editEventName">Event Name</label>
            <div>
              <InputText 
                id="editEventName" 
                class="inputfield w-full"
                type="text" 
                v-model="editEvent.Title"
                placeholder="Name"
              />
            </div>
          </div>
          <div class="field col-fixed">
            <div class="form-element-size">
              <ColorSelectView
                :color="editEvent.BackgroundColor" 
                :updateColor="(color: string) => { 
                  if (editEvent) {
                    editEvent.BackgroundColor = color;
                  }
                }"
              />
            </div>
          </div>
        </div>
        <div class="formgrid grid">
          <!-- Event type -->
          <div class="field col-12" v-if="editEvent.AdditionalData">
            <label for="editEventEventType">Repeat</label>
            <div>
              <Dropdown 
                inputId="editEventEventType" 
                v-model="editEvent.AdditionalData.NubeIOEventType" 
                @change="onSelectEventTypeNubeIO" 
                :options="eventTypesNubeIO" 
                optionLabel="label"
                optionValue="value"
                placeholder="Select Event Type"
                class="w-full"
              />
            </div>
          </div>
          <div class="field col-12" v-else>
            <label for="editEventEventType">Repeat</label>
            <div>
              <Dropdown 
                inputId="editEventEventType" 
                v-model="editEvent.EventType" 
                @change="onSelectEventType" 
                :options="eventTypes" 
                optionLabel="label"
                optionValue="value"
                placeholder="Select Event Type"
                class="w-full"
              />
            </div>
          </div>
          
          <!-- One-Time -->
          <div class="field col-12 md:col-6" v-if="editEvent.Start && editEvent.End">
            <label for="editEventStart">Start Date</label>
            <div>
              <Calendar 
                class="w-full" 
                ref="editEventStart" 
                v-model="editEvent.Start"
                @update:model-value="onStartUpdate"
                :showTime="true"
                dateFormat="dd/mm/yy" 
                panelClass="with-max-width"
                showIcon 
                iconDisplay="input"
              >
                <template #footer>
                  <div class="flex justify-content-end pb-3">
                    <Button label="Save" icon="pi pi-check" @click="closeCalendar" />
                  </div>
                </template>
              </Calendar>
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editEvent.Start && editEvent.End">
            <label for="editEventEnd">End Date</label>
            <div>
              <Calendar 
                class="w-full" 
                ref="editEventEnd" 
                v-model="editEvent.End"
                @update:model-value="onEndUpdate"
                :showTime="true"
                dateFormat="dd/mm/yy" 
                panelClass="with-max-width"
                showIcon 
                iconDisplay="input"
              >
                <template #footer>
                  <div class="flex justify-content-end pb-3">
                    <Button label="Save" icon="pi pi-check" @click="closeCalendar" />
                  </div>
                </template>
              </Calendar>
            </div>
          </div>

          <!-- Recurrence -->
          <div class="field col-12" v-if="editEvent.RecurrenceSettings">
            <label for="editEventDaysOfWeek">Days of Week</label>
            <div>
              <MultiSelect 
                inputId="editEventDaysOfWeek"
                v-model="editEvent.RecurrenceSettings.DaysOfWeek"
                :options="daysOfWeek"
                placeholder="Select Days of Week"
                display="chip"
                :filter="true"
                optionValue="value"
                optionLabel="label"
                class="p-multiselect-multiline nowrap-token inputfield w-full"
              />
              <small>If omitted, the event is assumed to repeat <b>every day</b>.</small>
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editEvent.RecurrenceSettings">
            <label for="editEventStartTime">Start Time</label>
            <div>
              <input
                id="editEventStartTime"
                class="w-full p-inputtext p-component"
                v-model="editEvent.RecurrenceSettings.StartTime"
                v-maska data-maska="##:##:##"
                @blur="validateTime(editEvent.RecurrenceSettings, 'StartTime')"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editEvent.RecurrenceSettings">
            <label for="editEventEndTime">End Time</label>
            <div>
              <input
                id="editEventEndTime"
                class="w-full p-inputtext p-component"
                v-model="editEvent.RecurrenceSettings.EndTime"
                v-maska data-maska="##:##:##"
                @blur="validateTime(editEvent.RecurrenceSettings, 'EndTime')"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editEvent.RecurrenceSettings && !editEvent.AdditionalData">
            <label for="editEventStartRecurrences">Start Recurrences</label>
            <div>
              <Calendar 
                class="w-full" 
                ref="editEventStartRecurrences" 
                v-model="editEvent.RecurrenceSettings.StartRecur" 
                :showTime="true"
                dateFormat="dd/mm/yy" 
                panelClass="with-max-width"
                showIcon 
                iconDisplay="input"
              >
                <template #footer>
                  <div class="flex justify-content-between pb-3">
                    <Button label="Clear" icon="pi pi-trash" @click="editEvent.RecurrenceSettings.StartRecur = null; closeCalendar();"
                      class="p-button-text p-button-secondary" />
                    <Button label="Save" icon="pi pi-check" @click="closeCalendar" />
                  </div>
                </template>
              </Calendar>
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editEvent.RecurrenceSettings && !editEvent.AdditionalData">
            <label for="editEventEndRecurrences">End Recurrences</label>
            <div>
              <Calendar 
                class="w-full" 
                ref="editEventEndRecurrences" 
                v-model="editEvent.RecurrenceSettings.EndRecur" 
                :showTime="true"
                dateFormat="dd/mm/yy" 
                panelClass="with-max-width"
                showIcon 
                iconDisplay="input"
              >
                <template #footer>
                  <div class="flex justify-content-between pb-3">
                    <Button label="Clear" icon="pi pi-trash" @click="editEvent.RecurrenceSettings.EndRecur = null; closeCalendar();"
                      class="p-button-text p-button-secondary" />
                    <Button label="Save" icon="pi pi-check" @click="closeCalendar" />
                  </div>
                </template>
              </Calendar>
            </div>
          </div>   

          <!-- Values -->
          <div class="field col-12 mqtt-scheduler-event-value-type" v-if="!editEvent.AdditionalData">
            <label for="editRecordValueType">Value Type</label>
            <div>
              <SelectButton
                id="editRecordValueType" 
                v-model="editEvent.ValueType"
                :options="valueTypes"
                optionLabel="label"
                optionValue="value"
                :allowEmpty="false"
                @change="onSelectValueType"
                class="w-full" 
              />
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editEvent.ValueType === 0">
            <label for="editEventValue">Event Value</label>
            <div>
              <InputNumber 
                id="editEventValue" 
                class="inputfield w-full"
                v-model="editEvent.Value"
                placeholder="Event Value"
                :minFractionDigits="2"
                :maxFractionDigits="20"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editEvent.ValueType === 1">
            <label for="editEventValue">Event Value</label>
            <div>
              <InputText 
                id="editEventValue" 
                class="inputfield w-full"
                type="text" 
                v-model="editEvent.Value"
                placeholder="Event Value"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6 mqtt-scheduler-event-value" v-if="editEvent.ValueType === 2">
            <label for="editEventValue">Event Value</label>
            <div>
              <SelectButton
                id="editEventValue" 
                v-model="editEvent.Value"
                :options="booleanOptions"
                optionLabel="label"
                optionValue="value"
                :allowEmpty="false"
                class="w-full"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editEvent.ValueType === 0 && !editEvent.AdditionalData">
            <label for="editRecordDefaultValue">Fallback</label>
            <div>
              <InputNumber 
                id="editRecordDefaultValue" 
                class="inputfield w-full"
                v-model="editEvent.ValueFallback"
                placeholder="Fallback"
                :minFractionDigits="2"
                :maxFractionDigits="20"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6" v-if="editEvent.ValueType === 1 && !editEvent.AdditionalData">
            <label for="editRecordDefaultValue">Fallback</label>
            <div>
              <InputText 
                id="editRecordDefaultValue" 
                class="inputfield w-full"
                type="text" 
                v-model="editEvent.ValueFallback"
                placeholder="Fallback"
              />
            </div>
          </div>
          <div class="field col-12 md:col-6  mqtt-scheduler-event-fallback" v-if="editEvent.ValueType === 2 && !editEvent.AdditionalData">
            <label for="editRecordDefaultValue">Fallback</label>
            <div>
              <SelectButton
                id="editRecordDefaultValue" 
                v-model="editEvent.ValueFallback"
                :options="booleanOptions"
                optionLabel="label"
                optionValue="value"
                :allowEmpty="false"
                class="w-full"
              />
            </div>
          </div>

          <!-- Streams -->
          <div class="field col-12 mb-0" v-if="!editEvent.AdditionalData">
            <div class="mqtt-scheduler-streams-container mqtt-scheduler-colored-box">
              <div class="flex justify-content-between align-items-center mqtt-scheduler-streams-container-header">
                <h4>Streams</h4>
                <div>
                  <span @click="collapseStreams = !collapseStreams" class="link" :class="{ 'is-open': !collapseStreams }">
                    {{ collapseStreams ? "Show" : "Hide" }} <i class="pi"
                      :class="{ 'pi-chevron-up': !collapseStreams, 'pi-chevron-down': collapseStreams }"></i>
                  </span>
                </div>
              </div>

              <transition name="p-toggleable-content" v-show="!collapseStreams">
                <div class="mqtt-scheduler-streams-container-body">
                  <div>
                    <div 
                      v-for="(stream, i) in editEvent.Streams"
                      :key="i + stream.StreamKey" 
                      :id="`streamContainer${i}`"
                      class="mqtt-scheduler-stream-item field md:flex align-items-end column-gap-2">

                      <!-- Structured/not-structured switch -->
                      <div class="pb-1 md:align-self-end mb-1 md:mb-0 md:flex-order-2 flex-shrink-0">
                        <div class="flex align-items-center">
                          <InputSwitch v-model="stream.Structured" :inputId="`structuredView-${i}`" class="vertical-align-top" />
                          <label :for="`structuredView-${i}`" class="mb-0 ml-2">Structured Data</label>
                        </div>
                      </div>

                      <div class="flex align-items-end column-gap-1 sm:column-gap-2 flex-auto">
                        <!-- Structured -->
                        <div v-if="stream.Structured" class="flex-auto min-w-0">
                          <label :for="`structuredData${i}`" class="block mb-2">
                            <span>Structured Data</span>
                          </label>
                          <div class="flex align-items-center column-gap-1 sm:column-gap-2">
                            <div class="flex-auto min-w-0">
                              <TreeSelectView 
                                v-if="navTreeStore.isLoaded"
                                :selectedId="stream.StreamKey" 
                                :nodes="navTreeStore.structuredDataForUI"
                                :changeSelectedId="(node: TreeNodeForUI) => streamFromTreeSelected(stream, node)"
                                placeholder="Select Stream"
                                :id="`structuredData-${i}`"
                                :includeTags="includeTags"
                              />
                              <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6" animationDuration="1s" />
                            </div>
                            <div class="flex-shrink-0">
                              <Button
                                @click="viewStream(stream.StreamKey)"
                                label="View Stream"
                                icon="pi pi-external-link" 
                                class="p-button-icon-only p-button-rounded p-button-text action-button-size"
                                :disabled='!stream.StreamKey'
                              />
                            </div>
                          </div>
                        </div>

                        <!-- Not-structured -->
                        <div v-if="!stream.Structured" class="flex-auto min-w-0">
                          <label :for="`unstructuredData-${i}`" class="block mb-2">
                            <span>Unstructured Data</span>
                          </label>
                          <div class="flex align-items-center column-gap-1 sm:column-gap-2">
                            <div class="flex-auto min-w-0">
                              <TreeSelectView 
                                v-if="navTreeStore.unstructuredIsLoaded"
                                :selectedId="stream.StreamKey" 
                                :nodes="navTreeStore.unstructuredDataForUI"
                                :changeSelectedId="(node: TreeNodeForUI) => streamFromTreeSelected(stream, node)"
                                placeholder="Select Stream"
                                :id="`unstructuredData-${i}`"
                                :includeTags="includeTags"
                              />
                              <ProgressSpinner v-else class="spinner-primary" style="width: 28px; height: 28px" strokeWidth="6" animationDuration="1s" />
                            </div>
                            <div class="flex-shrink-0">
                              <Button
                                @click="viewStream(stream.StreamKey)"
                                label="View Stream"
                                icon="pi pi-external-link" 
                                class="p-button-icon-only p-button-rounded p-button-text action-button-size"
                                :disabled='!stream.StreamKey'
                              />
                            </div>
                          </div>
                        </div>

                        <div class="flex-shrink-0 align-self-end mqtt-scheduler-event-delete-stream">
                          <Button
                            class="p-button-icon-only p-button-rounded p-button-text p-button-secondary action-button-size"
                            icon="pi pi-times" @click="editEvent.Streams.splice(i, 1)" v-tippy="'Delete'">
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="mt-3">
                    <Button 
                      @click="addStream"
                      class="p-button-outlined p-button-rounded mqtt-scheduler-control-btn" 
                      icon="pi pi-plus"
                      label="Add Stream" />
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block mr-auto">
            <Button 
              label="Delete" 
              icon="pi pi-trash" 
              @click="deleteEvent" 
              class="p-button-text p-button-danger" 
            />
          </span>
          <span class="block ml-2">
            <Button 
              label="Cancel" 
              icon="pi pi-times" 
              @click="closeCreateUpdateEventDialog" 
              class="p-button-text p-button-secondary"
            />
          </span>
          <span class="block ml-2">
            <Button 
              v-if="editEvent" 
              label="Save" 
              icon="pi pi-check" 
              @click="saveEvent" 
              :disabled='!editEvent.Title'
            />
          </span>
        </div>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Calendar from 'primevue/calendar';
import MultiSelect from 'primevue/multiselect';
import SelectButton from 'primevue/selectbutton';
import Breadcrumb from 'primevue/breadcrumb';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import { useMQTTSchedulerStore } from "@/stores/mqttScheduler";
import { MQTTSchedulerEntity } from "@/models/mqtt/MQTTSchedulerEntity";
import { MQTTSchedulerValueType } from "@/models/mqtt/MQTTSchedulerValueType";
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import multiMonthPlugin from '@fullcalendar/multimonth';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Calendar as FullCalendarCalendar, EventClickArg, DatesSetArg, EventMountArg } from '@fullcalendar/core';
import { DateClickArg } from "@fullcalendar/interaction";
import { MQTTSchedulerEvent } from "@/models/mqtt/MQTTSchedulerEvent";
import { v4 as uuidv4 } from "uuid";
import { MQTTSchedulerEventType } from "@/models/mqtt/MQTTSchedulerEventType";
import ColorSelectView from "@/components/widgets-next/settings/ColorSelectView.vue";
import ColorHelper from "@/helpers/ColorHelper";
import { useNavTreeStore } from "@/stores/navTree";
import { MQTTSchedulerStream } from "@/models/mqtt/MQTTSchedulerStream";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import TreeSelectView from "@/components/views/TreeSelectView.vue";
import { vMaska } from "maska";
import { DayOfWeek } from "@/models/enums/DayOfWeek";
import { nextTick } from "vue";
import EventBusHelper from "@/helpers/EventBusHelper";
import { Emitter } from "mitt";
import NavigationHelper from "@/helpers/NavigationHelper";
import { usePageStore } from "@/stores/page";
import { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";
import { useOrganisationStore } from "@/stores/organisation";
import Chip from 'primevue/chip';

@Component({
  components: {
    Button,
    InputText,
    InputNumber,
    Dialog,
    BlockUI,
    InputSwitch,
    Message,
    ProgressSpinner,
    Dropdown,
    Calendar,
    MultiSelect,
    SelectButton,
    Breadcrumb,
    FullCalendar,
    ColorSelectView,
    TreeSelectView,
    Chip
  },
  directives: {
    maska: vMaska
  }
})
class MQTTSchedulerEditView extends Vue {
  @Prop({ required: true }) schedulerId!: string;

  mqttSchedulerStore = useMQTTSchedulerStore();
  navTreeStore = useNavTreeStore();
  pageStore = usePageStore();
  
  goBack(): void {
    NavigationHelper.goTo("/data/scheduler");
  }

  breadcrumbHome = {
    label: "Scheduler",
    url: "/data/scheduler",
    command: (event: MenuItemCommandEvent) => {
      if (!NavigationHelper.goTo("/data/scheduler")) {
        event.originalEvent.preventDefault();
      }
    }
  };

  get breadcrumbItems(): MenuItem[] {
    const url = `/data/scheduler/${this.schedulerId}`;
    return this.mqttSchedulerStore.currentEntity
      ? [
        {
          label: this.schedulerId === "new" ? "New" : this.mqttSchedulerStore.currentEntity.Name,
          url: url,
          command: (event: MenuItemCommandEvent) => {
            if (!NavigationHelper.goTo(url)) {
              event.originalEvent.preventDefault();
            }
          }
        },
      ]
      : [];
  }

  @Watch('schedulerId', { immediate: false, deep: false })
  async onIdChanged(val: string, oldVal: string): Promise<void> {
    this.mqttSchedulerStore.unloadCurrentEntity();
    await nextTick();
    this.mqttSchedulerStore.loadOne(this.schedulerId, this.currentOrganisation?.Id ?? -1);
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created() {
    this.init();
  }

  mounted(): void {
    if (!this.navTreeStore.isLoaded) {
      this.navTreeStore.load();
    }
    if (!this.navTreeStore.unstructuredIsLoaded) {
      this.navTreeStore.loadUnstructured();
    }
    this.emitter.on("size_changed", this.calendarUpdateSize);
  }

  unmounted() {
    this.emitter.off("size_changed", this.calendarUpdateSize);
    this.navTreeStore.$reset();
  }

  async init(): Promise<void> {
    await this.mqttSchedulerStore.loadOne(this.schedulerId, this.currentOrganisation?.Id ?? -1);
    this.eventsToCalendar();
    if (this.schedulerId === "new") {
      this.isChangedEvent();
    }
  }

  get isChanged(): boolean {
    return this.pageStore.dirty;
  }

  set isChanged(value: boolean) {
    this.pageStore.dirty = value;
  }

  isChangedEvent(): void {
    this.isChanged = true;
  }

  // #region calendar
  // type CalendarOptions - can't resolve type conflicts
  calendarOptions: any = {
    plugins: [ dayGridPlugin, multiMonthPlugin, interactionPlugin ],
    initialView: 'dayGridMonth',
    headerToolbar: false,
    eventClick: this.eventClick,
    dateClick: this.dateClick,
    datesSet: this.datesSet,
    events: [
    ],
    eventTimeFormat: {
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    },
    locale: 'en-GB', // otherwise 0 hours displayed as 24
    firstDay: 1, // Monday
    height: 'auto',
    selectable: true
  };

  // type CalendarOptions - can't resolve type conflicts
  get calendarOptionsListDay(): any {
    const result = {
      plugins: [ listPlugin ],
      initialView: 'listDay',
      headerToolbar: {
        start: 'title',
        center: '',
        end: ''
      },
      eventClick: this.eventClick,
      events: this.calendarOptions.events,
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      locale: 'en-GB', // otherwise 0 hours displayed as 24
      firstDay: 1, // Monday
      height: 'auto',
      eventDidMount: (event: EventMountArg) => {
        // if object.el first childern is empty, write "all-day" text
        if (event.el.children.length && !event.el.children[0].innerHTML) {
          event.el.children[0].innerHTML = "all-day";
        }
      }
    };
    return result;
  }

  // type CalendarOptions - can't resolve type conflicts
  get calendarOptionsListPeriod(): any {
    const result = {
      plugins: [ listPlugin ],
      initialView: 'listMonth',
      headerToolbar: {
        start: 'title',
        center: '',
        end: ''
      },
      eventClick: this.eventClick,
      events: this.calendarOptions.events,
      eventTimeFormat: {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      },
      locale: 'en-GB', // otherwise 0 hours displayed as 24
      firstDay: 1, // Monday
      height: 'auto',
      eventDidMount: (event: EventMountArg) => {
        // if object.el first childern is empty, write "all-day" text
        if (event.el.children.length && !event.el.children[0].innerHTML) {
          event.el.children[0].innerHTML = "all-day";
        }
      }
    };
    return result;
  }

  getFullCalendarApi(): FullCalendarCalendar | null {
    if (this.$refs.fullCalendar) {
      return ((this.$refs.fullCalendar as any).getApi() as FullCalendarCalendar);
    }
    return null;
  }

  getFullCalendarListDayApi(): FullCalendarCalendar | null {
    if (this.$refs.fullCalendarListDay) {
      return ((this.$refs.fullCalendarListDay as any).getApi() as FullCalendarCalendar);
    }
    return null;
  }

  getFullCalendarListPeriodApi(): FullCalendarCalendar | null {
    if (this.$refs.fullCalendarListPeriod) {
      return ((this.$refs.fullCalendarListPeriod as any).getApi() as FullCalendarCalendar);
    }
    return null;
  }

  goToPrev() {
    this.getFullCalendarApi()?.prev();
  }

  goToNext() {
    this.getFullCalendarApi()?.next();
  }

  goToToday() {
    this.selectedDate = null;
    this.getFullCalendarApi()?.today();
    this.getFullCalendarListDayApi()?.gotoDate(new Date());
  }

  calendarUpdateSize(): void {
    this.getFullCalendarApi()?.updateSize();
    this.getFullCalendarListDayApi()?.updateSize();
    this.getFullCalendarListPeriodApi()?.updateSize();
  }

  eventClick(info: EventClickArg) {
    this.openCreateUpdateEventDialog(info.event.extendedProps.bitpoolEvent);
  }

  selectedDate: Date | null = null;

  dateClick(info: DateClickArg) {
    this.selectedDate = info.date;
    this.getFullCalendarListDayApi()?.gotoDate(info.date);
  }

  currentTitle = '';
  currentView = 'dayGridMonth';

  calendarViews = [
    {
      value: "multiMonthYear",
      label: "Year"
    },
    {
      value: "dayGridMonth",
      label: "Month"
    },
    {
      value: "dayGridWeek",
      label: "Week"
    },
    {
      value: "dayGridDay",
      label: "Day"
    }
  ];

  currentViewChangedEvent(): void {
    this.getFullCalendarApi()?.changeView(this.currentView);
  }

  datesSet(info: DatesSetArg) {
    const api = this.getFullCalendarApi();
    const apiPeriod = this.getFullCalendarListPeriodApi();
    if (api) {
      this.currentTitle = api.view.title;
      this.currentView = info.view.type;
      if (apiPeriod) {
        switch (api.view.type) {
          case "multiMonthYear": {
            apiPeriod.changeView("listYear");
            apiPeriod.gotoDate(api.getDate());
            break;
          }
          case "dayGridMonth": {
            apiPeriod.changeView("listMonth");
            apiPeriod.gotoDate(api.getDate());
            break;
          }
          case "dayGridWeek": {
            apiPeriod.changeView("listWeek");
            apiPeriod.gotoDate(api.getDate());
            break;
          }
          case "dayGridDay": {
            apiPeriod.changeView("listDay");
            apiPeriod.gotoDate(api.getDate());
            break;
          }
        }
      }
    }
  }

  eventToCalendar(event: MQTTSchedulerEvent): any {
    const result = {
      id: event.Id,
      title: event.Title, 
      start: event.Start,
      end: event.End,
      display: "block",
      backgroundColor: event.BackgroundColor,
      borderColor: event.BackgroundColor,
      textColor: ColorHelper.getContrastColor(event.BackgroundColor),
      // Recurrence
      daysOfWeek: event.RecurrenceSettings?.DaysOfWeek,
      startTime: event.RecurrenceSettings?.StartTime,
      endTime: event.RecurrenceSettings?.EndTime,
      startRecur: event.RecurrenceSettings?.StartRecur,
      endRecur: event.RecurrenceSettings?.EndRecur,
      // Original data
      extendedProps: {
        bitpoolEvent: event
      }
    };
    if (result.daysOfWeek?.length === 0) {
      // If omitted, the event is assumed to repeat every day.
      result.daysOfWeek = undefined;
    }
    return result;
  }

  eventsToCalendar(): void {
    if (this.editRecord) {
      this.calendarOptions.events = this.editRecord.Events.map(x => {
        return this.eventToCalendar(x);
      });
    } else {
      this.calendarOptions.events = [];
    }
  }

  eventsToEntity(): void {
    if (this.editRecord) {
      const events: MQTTSchedulerEvent[] = [];
      this.calendarOptions.events.forEach((x: any) => {
        const event = x.extendedProps.bitpoolEvent;
        event.Id = x.id;
        event.Title = x.title;
        event.Start = x.start;
        event.End = x.end;
        event.BackgroundColor = x.backgroundColor;
        // Recurrence
        if (typeof x.daysOfWeek !== "undefined" || 
          typeof x.startTime !== "undefined" || 
          typeof x.endTime !== "undefined" || 
          typeof x.startRecur !== "undefined" || 
          typeof x.endRecur !== "undefined"
        ) {
          event.RecurrenceSettings = {
            DaysOfWeek: x.daysOfWeek ?? [],
            StartTime: x.startTime,
            EndTime: x.endTime,
            StartRecur: x.startRecur,
            EndRecur: x.endRecur
          };
        }
        events.push(event);
      });
      this.editRecord.Events = events;
    }
  }

  extractTimeFromDate(date: Date): string {
    // Get the hours and minutes from the date object
    const hours = date.getHours();
    const minutes = date.getMinutes();

    // Format the hours and minutes as two-digit strings
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');

    // Concatenate the hours and minutes with a colon separator
    const formattedTime = `${formattedHours}:${formattedMinutes}`;
    return formattedTime;
  }
  // #endregion calendar

  // #region new/edit event
  displayCreateUpdateEventDialog = false;
  editEvent: MQTTSchedulerEvent | null = null;

  buildStartDate(): Date {
    const start = this.selectedDate ? new Date(this.selectedDate) : new Date();
    // Set the hours to the 0 hour
    start.setHours(0);
    // Set the minutes to 0
    start.setMinutes(0);
    // Set the seconds to 0
    start.setSeconds(0);
    // Set the milliseconds to 0
    start.setMilliseconds(0);
    return start;
  }

  openCreateUpdateEventDialog(record: MQTTSchedulerEvent | null): void {
    if (record) {
      const r: MQTTSchedulerEvent = JSON.parse(JSON.stringify(record));
      this.mqttSchedulerStore.parseDatesEvent(r);
      this.editEvent  = r;
    } else {
      const start = this.buildStartDate();
      // Get the current time in milliseconds
      const time = start.getTime();
      // Add 15 minutes (15 minutes * 60 seconds * 1000 milliseconds) to the current time
      const newTime = time + (15 * 60 * 1000);
      // Create a new date object using the new time
      const end = new Date(newTime);
      this.editEvent = {
        Id: uuidv4(),
        Title: "",
        EventType: MQTTSchedulerEventType.OneTime,
        Start: start,
        End: end,
        BackgroundColor: ColorHelper.random(),
        RecurrenceSettings: null,
        ValueType: MQTTSchedulerValueType.Numeric,
        Value: 0,
        ValueFallback: 0,
        Streams: [],
        AdditionalData: this.editRecord?.AdditionalData ? { NubeIOEventType: "Event" } : null
      };
    }
    this.displayCreateUpdateEventDialog = true;
  }

  closeCreateUpdateEventDialog(): void {
    this.displayCreateUpdateEventDialog = false;
  }

  async saveEvent(): Promise<void> {
    if (this.editEvent) {
      const calendarEventIndex = this.calendarOptions.events.findIndex((x: any) => x.id === this.editEvent?.Id);
      if (calendarEventIndex >= 0) {
        // update event
        this.calendarOptions.events[calendarEventIndex] = this.eventToCalendar(this.editEvent);
      } else {
        // create event
        this.calendarOptions.events.push(this.eventToCalendar(this.editEvent));
      }
    }
    this.closeCreateUpdateEventDialog();
    this.isChangedEvent();
  }

  deleteEvent(): void {
    if (this.editEvent) {
      const calendarEventIndex = this.calendarOptions.events.findIndex((x: any) => x.id === this.editEvent?.Id);
      if (calendarEventIndex >= 0) {
        // delete event
        this.calendarOptions.events.splice(calendarEventIndex, 1);
      }
    }
    this.closeCreateUpdateEventDialog();
    this.isChangedEvent();
  }

  eventTypes = [
    {
      label: "One time",
      value: MQTTSchedulerEventType.OneTime
    },
    {
      label: "Recurring",
      value: MQTTSchedulerEventType.Recurrence
    }
  ];

  onSelectEventType(): void {
    if (this.editEvent) {
      if (this.editEvent.EventType === MQTTSchedulerEventType.OneTime) {
        this.editEvent.Start = this.buildStartDate();
        // Get the current time in milliseconds
        const time = this.editEvent.Start.getTime();
        // Add 15 minutes (15 minutes * 60 seconds * 1000 milliseconds) to the current time
        const newTime = time + (15 * 60 * 1000);
        // Create a new date object using the new time
        this.editEvent.End = new Date(newTime);
        this.editEvent.RecurrenceSettings = null;
      } else {
        this.editEvent.Start = null;
        this.editEvent.End = null;
        const startRecur = this.editRecord?.AdditionalData ? this.editRecord.Created : this.buildStartDate();
        this.editEvent.RecurrenceSettings = {
          DaysOfWeek: [],
          StartTime: "00:00:00",
          EndTime: "00:15:00",
          StartRecur: startRecur,
          EndRecur: null
        };
      }
    }
  }

  eventTypesNubeIO = [
    {
      label: "One time",
      value: "Event"
    },
    {
      label: "Recurring",
      value: "Weekly"
    },
    {
      label: "Exception",
      value: "Exception"
    }
  ];

  onSelectEventTypeNubeIO(): void {
    if (this.editEvent?.AdditionalData) {
      switch (this.editEvent.AdditionalData.NubeIOEventType) {
        case "Event":
        case "Exception":
          if (this.editEvent.EventType !== MQTTSchedulerEventType.OneTime) {
            this.editEvent.EventType = MQTTSchedulerEventType.OneTime;
            this.onSelectEventType();
          }
          break;
        case "Weekly":
          if (this.editEvent.EventType !== MQTTSchedulerEventType.Recurrence) {
            this.editEvent.EventType = MQTTSchedulerEventType.Recurrence;
            this.onSelectEventType();
          }
          break;
      }
    }
  }

  onStartUpdate(value: Date): void {
    if (this.editEvent) {
      if (!this.editEvent.End || this.editEvent.End < value) {
        const time = value.getTime();
        // Add 15 minutes (15 minutes * 60 seconds * 1000 milliseconds) to the time
        const newTime = time + (15 * 60 * 1000);
        // Create a new date object using the new time
        this.editEvent.End = new Date(newTime);
      }
    }
  }

  onEndUpdate(value: Date): void {
    if (this.editEvent) {
      if (!this.editEvent.Start || this.editEvent.Start > value) {
        const time = value.getTime();
        // Subtract 15 minutes (15 minutes * 60 seconds * 1000 milliseconds) to the time
        const newTime = time - (15 * 60 * 1000);
        // Create a new date object using the new time
        this.editEvent.Start = new Date(newTime);
      }
    }
  }

  closeCalendar(): void {
    if (this.$refs.editEventStart) {
      (this.$refs.editEventStart as any).overlayVisible = false;
    }
    if (this.$refs.editEventEnd) {
      (this.$refs.editEventEnd as any).overlayVisible = false;
    }
    if (this.$refs.editEventStartRecurrences) {
      (this.$refs.editEventStartRecurrences as any).overlayVisible = false;
    }
    if (this.$refs.editEventEndRecurrences) {
      (this.$refs.editEventEndRecurrences as any).overlayVisible = false;
    }
  }

  daysOfWeek = [
    {
      label: "Monday",
      value: DayOfWeek.Monday
    },
    {
      label: "Tuesday",
      value: DayOfWeek.Tuesday
    },
    {
      label: "Wednesday",
      value: DayOfWeek.Wednesday
    },
    {
      label: "Thursday",
      value: DayOfWeek.Thursday
    },
    {
      label: "Friday",
      value: DayOfWeek.Friday
    },
    {
      label: "Saturday",
      value: DayOfWeek.Saturday
    },
    {
      label: "Sunday",
      value: DayOfWeek.Sunday
    }
  ];

  validateTime(obj: any, field: string) {
    const value = obj[field] as string;
    let newValue = "";
    if (value) {
      const parts = value.split(":");
      let hours = parseInt(parts[0]);
      let minutes = parseInt(parts[1] ? parts[1] : "0");
      let seconds = parseInt(parts[2] ? parts[2] : "0");
      if (seconds > 59) {
        seconds = 59;
      }
      if (minutes > 59) {
        minutes = 59;
      }
      if (hours > 24) {
        hours = 24;
      }
      if (hours === 24) {
        minutes = 0;
        seconds = 0;
      }
      newValue = `${hours.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}:${minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}:${seconds.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}`
    } else {
      newValue = "00:00:00";
    }
    obj[field] = newValue;
  }

  valueTypes = [
    {
      label: "Numeric",
      value: MQTTSchedulerValueType.Numeric
    },
    {
      label: "String",
      value: MQTTSchedulerValueType.String
    },
    {
      label: "Boolean",
      value: MQTTSchedulerValueType.Boolean
    }
  ];

  booleanOptions = [
    {
      label: "True",
      value: "true"
    },
    {
      label: "False",
      value: "false"
    }
  ]

  onSelectValueType(): void {
    if (this.editEvent) {
      if (this.editEvent.ValueType === MQTTSchedulerValueType.Numeric) {
        this.editEvent.ValueFallback = 0;
        this.editEvent.Value = 0;
      } else if (this.editEvent.ValueType === MQTTSchedulerValueType.String) {
        this.editEvent.ValueFallback = "";
        this.editEvent.Value = "";
      } else { // MQTTSchedulerValueType.Boolean
        this.editEvent.ValueFallback = "true";
        this.editEvent.Value = "true";
      }
    }
  }
  // #endregion new/edit event

  // #region new/edit
  get editRecord(): MQTTSchedulerEntity | null {
    return this.mqttSchedulerStore.currentEntity;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get updateInProgress(): boolean {
    return this.mqttSchedulerStore.updateInProgress;
  }

  get updateError(): boolean {
    return this.mqttSchedulerStore.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      this.eventsToEntity();
      for (const event of this.editRecord.Events) {
        if (event.Streams.length > 0) {
          event.Streams = event.Streams.filter(x => x.StreamKey);
        }
      }
      const id = await this.mqttSchedulerStore.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.isChanged = false;
        if (!this.editRecord.Id) {
          NavigationHelper.goTo(`/data/scheduler/${id}`);
        }
      }
    }
  }
  // #endregion new/edit

  // #region streams
  collapseStreams = false;
  includeTags = ["mqtt|nubeIO&writeable=true"];

  addStream(): void {
    if (this.editEvent) {
      this.editEvent.Streams.push({
        StreamKey: "",
        Name: "",
        Structured: false
      });
    }
  }

  viewStream(streamKey: string | null | undefined): void {
    if (streamKey) {
      const newUrl = `/data/streams/${streamKey}`;
      window.open(newUrl, '_blank');
    }
  }

  streamFromTreeSelected(stream: MQTTSchedulerStream, node: TreeNodeForUI): void {
    stream.Name = node.label ?? "";
    stream.StreamKey = node.key ?? "";
  }
  // #endregion streams
}

export default MQTTSchedulerEditView;
</script>