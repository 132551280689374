<template>
  <div class="col-12 md:col-8 semantics-edit-uri">
    <label :for="inputId">Value</label>
    <InputText
      :id="inputId"
      class="inputfield w-full"
      type="text"
      v-model="uriVal"
      :disabled="disabledFields.includes(field)"
    />
  </div>
</template>

<script lang="ts">
import InputText from 'primevue/inputtext';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonDict, HaysonUri, HaysonVal, Kind } from "haystack-core";

@Component({
  components: {
    InputText
  },
})
class HaystackUriEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }
  
  // #region Uri
  // Universal Resource Identifier
  get uriVal(): string {
    if (this.valueHayson) {
      return (this.valueHayson as HaysonUri).val;
    }
    return "";
  }

  set uriVal(value: string) {
    this.valueHayson = { 
      _kind: Kind.Uri,
      val: value
    };
  }
  // #endregion Uri
}

export default HaystackUriEditView;
</script>