<template>
  <div class="widget-type-textbox" :class="{'pt-0': aws?.displayTitle}" v-html="sanitizedContent" :style="[ backgroundColor ? {backgroundColor: backgroundColor} : {}, {color: textColor}]">
  </div>
</template>

<script lang="ts">
import ColorHelper from '@/helpers/ColorHelper';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import DOMPurify from 'dompurify';
import { Component, Prop, Vue } from 'vue-facing-decorator';

@Component({
})
class TextBoxWidget extends Vue {
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get backgroundColor(): string {
    const result = this.aws?.widgetColorEnabled && this.aws?.widgetColorHex ? this.aws?.widgetColorHex : '';
    return result;
  }

  get textColor(): string {
    return ColorHelper.getContrastColor(this.backgroundColor);
  }

  get sanitizedContent(): string {
    if (this.aws?.widgetContent) {
      return DOMPurify.sanitize(this.aws.widgetContent);
    } else {
      return "";
    }
  }
}

export default TextBoxWidget;
</script>