<template>
  <div class="widget-type-data-raw widget-with-datatable">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-show="!isLodingData && !isNoData" class="widget-datatable-container widget-datatable-container-vertical-scrollable">
      <DataTable :value="tableValues" stripedRows showGridlines responsiveLayout="scroll" :paginator="true" :rows="30" paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown" class="p-datatable-sm has-paginator">
        <Column field="date" header="Timestamp" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <DateTimezoneView :date="slotProps.data.date" timezone="UTC"/>
          </template>
        </Column>
        <Column v-for="(col, index) of columns" :field="col.field" :header="col.header" :key="col.field + '_' + index" headerStyle="font-size: .715rem; min-width: 6rem;">
          <template #body="slotProps">
            {{formatFloat(slotProps.data[col.field])}}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import numbro from "numbro";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import DateTimezoneView from '@/components/views/DateTimezoneView.vue';
import StreamOption from '@/models/dashboard/StreamOption';

@Component({
  components: {
    ProgressSpinner,
    DataTable,
    Column,
    Button,
    WidgetNoDataView,
    DateTimezoneView
  }
})
class DataRawWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.configuredStreams.length) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);       
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data && data.length) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  tableValues: any[] = [];

  get configuredStreams(): StreamOption[] {
    const result = this.wds?.streamOptions?.filter(x => x.StreamKey);
    return result ? result : [];
  }

  get columns(): any[] {
    const result = this.configuredStreams.map((x, index) => { 
      return { field: `field_${index}`, header: x.Label };
    });
    return result ? result : [];
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    const tableValues: any[] = [];
    if (this.configuredStreams.length) {
      const datesSet = new Set<number>();
      this.chartData.forEach((streamData) => {
        if (streamData.Error) {
          ToastService.showToast(
            "error",
            "Error",
            streamData.Error,
            5000
          );
        } else if (streamData.Data && streamData.Data.length) {
          streamData.Data.forEach(element => {
            if (typeof element.x !== "undefined" && element.x !== null) {
              datesSet.add(element.x);
            }
          });
        }
      });
      const dates = Array.from(datesSet);
      dates.sort();
      const rows: Record<string, any> = {};
      dates.forEach(date => {
        const row: any = {
          date: date
        };
        this.configuredStreams.forEach((streamOption, index) => {
          row[`field_${index}`] = null;
        });
        rows[`${date}`] = row;
      });
      this.chartData.forEach((streamData, index) => {
        if (!streamData.Error && streamData.Data && streamData.Data.length) {
          streamData.Data.forEach(element => {
            const key = `${element.x}`;
            if (rows[key]) {
              rows[key][`field_${index}`] = element.y;
            }
          });
        }
      });
      for (const key in rows) {
        tableValues.push(rows[key]);
      }
    }
    this.tableValues = tableValues;
    if (!tableValues.length) {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }

  formatFloat(value: number): string {
    let result: string;
    if (typeof value === "undefined" || value === null) {
      result = "";
    } else if (typeof value === "string") {
      result = value;
    } else {
      const decimals = this.aws?.decimals ? this.aws.decimals : 0;
      result = numbro(value).format({
        thousandSeparated: true,
        mantissa: decimals < 0 ? 0 : decimals
      });
    }
    return result;
  }

  formatInt(value: number): string {
    let result: string;
    if (typeof value === "undefined" || value === null) {
      result = "";
    } else if (typeof value === "string") {
      result = value;
    } else {
      result = numbro(value).format({
        thousandSeparated: true,
        mantissa: 0
      });
    }
    return result;
  }

  buildStreamHref(streamKey: string): string {
    const href = `/data/streams/${streamKey}`;
    return href;
  }
}

export default DataRawWidget;
</script>