<template>
  <div id="sigma-container" v-if="redrawChartToggle" style="width:100%; height:100%;"></div>
</template>

<script lang="ts">
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { Component, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import * as Highcharts from 'highcharts';
import Sigma from "sigma";
import Graph from "graphology";
import circular from "graphology-layout/circular";
import forceAtlas2 from "graphology-layout-forceatlas2";
import { cropToLargestConnectedComponent } from "graphology-components";
import { CameraState } from "sigma/types";
import drawLabel from "sigma/rendering/canvas/label";
import AuthState from "@/store/states/AuthState";
import { nextTick } from "vue";

@Component({
  components: {
  },
})
class TagManagerVisualizationView extends Vue {
  @Model rootNode!: TreeNodeForUI;
  @Prop selectedNode!: TreeNodeForUI;

  redrawChartToggle = true;
  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  sigma: Sigma | null = null;
  sigmaCameraState: Partial<CameraState> | null = null;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.sigmaRefresh);
    // nav menu minimize
    this.emitter.on("size_changed", this.sigmaRefresh);
    this.nextTickRefreshData();
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.sigmaRefresh);
    // nav menu minimize
    this.emitter.off("size_changed", this.sigmaRefresh);
    if (this.sigma) {
      this.sigma.kill();
    }
  }

  sigmaRefresh(): void {
    if (this.sigma) {
      this.sigma.refresh();
    }
  }

  drawGraph(): void {
    const container = document.getElementById("sigma-container") as HTMLElement;
    if (container) {
      const graph: Graph = new Graph();

      // Build the graph:
      if (this.chartData) {
        this.chartData[0].forEach((line) => {
          const key = line[0];
          const name = line[1];
          const color = line[2];

          // Create the node:
          graph.addNode(key, {
            label: name,
            color: color,
          });
        });
        this.chartData[1].forEach((line) => {
          // Create edges:
          const parent = line[0];
          const child = line[1];
          graph.addEdge(parent, child, { weight: 1 });
        });
      }

      // Only keep the main connected component:
      cropToLargestConnectedComponent(graph);

      // Add colors to the nodes:
      graph.forEachNode((node, attributes) =>
        graph.setNodeAttribute(node, "color", attributes.color),
      );

      // Use degrees for node sizes:
      const degrees = graph.nodes().map((node) => graph.degree(node));
      const minDegree = Math.min(...degrees);
      const maxDegree = Math.max(...degrees);
      const minSize = 2,
        maxSize = 15;
      graph.forEachNode((node) => {
        const degree = graph.degree(node);
        graph.setNodeAttribute(
          node,
          "size",
          minSize + ((degree - minDegree) / (maxDegree - minDegree)) * (maxSize - minSize),
        );
      });

      // Position nodes on a circle, then run Force Atlas 2 for a while to get
      // proper graph layout:
      circular.assign(graph);
      const settings = forceAtlas2.inferSettings(graph);
      forceAtlas2.assign(graph, { settings, iterations: 600 });

      // Finally, draw the graph using sigma:
      if (this.sigma) {
        this.sigma.clear();
        this.sigma.setGraph(graph);
        if (this.sigmaCameraState) {
          this.sigma.getCamera().setState(this.sigmaCameraState);
        }
      } else {
        this.sigma = new Sigma(
          graph, 
          container,
          {
            // it's not possible to change hover color, so we override
            // https://github.com/jacomyal/sigma.js/issues/1210
            labelRenderer: (context,  data, settings) => {
              settings.labelColor = { color: this.isDarkTheme ? '#fff' : '#000' };
              drawLabel(context, data, settings);
            },
            hoverRenderer: (context,  data, settings) => {
              const size = settings.labelSize,
                font = settings.labelFont,
                weight = settings.labelWeight;

              context.font = `${weight} ${size}px ${font}`;

              // Then we draw the label background
              context.fillStyle = this.isDarkTheme ? '#133547' : '#fff';
              context.shadowOffsetX = 0;
              context.shadowOffsetY = 0;
              context.shadowBlur = 8;
              context.shadowColor = this.isDarkTheme ? '#fff' : '#133547';

              const PADDING = 2;

              if (typeof data.label === "string") {
                const textWidth = context.measureText(data.label).width,
                  boxWidth = Math.round(textWidth + 5),
                  boxHeight = Math.round(size + 2 * PADDING),
                  radius = Math.max(data.size, size / 2) + PADDING;

                const angleRadian = Math.asin(boxHeight / 2 / radius);
                const xDeltaCoord = Math.sqrt(Math.abs(Math.pow(radius, 2) - Math.pow(boxHeight / 2, 2)));

                context.beginPath();
                context.moveTo(data.x + xDeltaCoord, data.y + boxHeight / 2);
                context.lineTo(data.x + radius + boxWidth, data.y + boxHeight / 2);
                context.lineTo(data.x + radius + boxWidth, data.y - boxHeight / 2);
                context.lineTo(data.x + xDeltaCoord, data.y - boxHeight / 2);
                context.arc(data.x, data.y, radius, angleRadian, -angleRadian);
                context.closePath();
                context.fill();
              } else {
                context.beginPath();
                context.arc(data.x, data.y, data.size + PADDING, 0, Math.PI * 2);
                context.closePath();
                context.fill();
              }

              context.shadowOffsetX = 0;
              context.shadowOffsetY = 0;
              context.shadowBlur = 0;

              // And finally we draw the label
              settings.labelColor = { color: this.isDarkTheme ? '#fff' : '#000' };
              drawLabel(context, data, settings);
            }
          }
        );
        this.sigmaCameraState = this.sigma.getCamera().getState();
      }
    }
  }

  async nextTickRefreshData(): Promise<void> {
    await nextTick();
    this.refreshData();
  }

  @Watch("rootNode", { immediate: false, deep: false })
  refreshData(): void {
    const names: [string, string, string][] = [];
    const links: [string, string][] = [];
    const colors = Highcharts.getOptions().colors as string[];
    names.push([this.rootNode.key ?? "", this.rootNode.label ?? "", colors[0]]);
    const levels = this.iterate(this.rootNode, names, links);
    const graphNodes:[string, string, string][] = Array.from(names).map((x, index) => { return [x[0], x[1], x[2]]; });
    this.chartData = [graphNodes, links];
    this.chartLevels = levels + 1;
    this.drawGraph();
    this.gotToSelectedNode();
  }

  highlightedNode: TreeNodeForUI | null = null;

  @Watch("selectedNode", { immediate: false, deep: false })
  gotToSelectedNode(): void {
    if (this.sigma) {
      try {
        if (this.highlightedNode) {
          this.sigma.getGraph().setNodeAttribute(this.highlightedNode.key, "highlighted", false);
        }
      } catch {
        // nothing
      }
      try {
        this.sigma.getGraph().setNodeAttribute(this.selectedNode.key, "highlighted", true);
        const nodeDisplayData = this.sigma.getNodeDisplayData(this.selectedNode.key);
        this.highlightedNode = this.selectedNode;
        if (nodeDisplayData) {
          this.sigma.getCamera().animate(
            { ...nodeDisplayData, ratio: this.rootNode.key === this.selectedNode.key ? 1 : 0.1 },
            {
              duration: 600,
            },
          );
        }
        
      } catch {
        // nothing
      }
    }
  }

  iterate(parent: TreeNodeForUI, names: [string, string, string][], links: [string, string][] ): number {
    let result = 0;
    const children = parent.children;
    if (children?.length) {
      result++;
      const colors = Highcharts.getOptions().colors as string[];
      const levels: number[] = [];
      for (let i = 0; i < children.length; i++) {
        const child = children[i];
        names.push([child.key ?? "", child.label ?? "", colors[i % colors.length]]);
        links.push([parent.key ?? "", child.key ?? ""]);
        levels.push(this.iterate(child, names, links));
      }
      result += Math.max(...levels);
    }
    return result;
  }

  chartData: [[string, string, string][], [string, string][]] | null = null;
  chartLevels = 0;
}

export default TagManagerVisualizationView;
</script>