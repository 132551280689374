import { PresentationModel } from "@/models/dashboard/PresentationModel";

export default class PresentationState {
  isLoaded = false;
  configs: PresentationModel[] = [];

  currentPresentation: PresentationModel | null = null;
  isLoadedCurrentPresentation = false;
  updateInProgress = false;
  updateError = false;
}
