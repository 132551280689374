<template>
  <transition
    name="custom-classes"
    :enter-active-class="enterClass"
    :leave-active-class="leaveClass"  
    appear
  >
    <div 
      class="dashboard default-view-container"
      v-if="presentaionState.isLoadedCurrentPresentation && dashboardState.currentDashboard && dashboardState.isLoadedWidgets"
    >
      <div class="dashboard-head-section">
        <h1 class="mb-0 mr-5">
          {{dashboardState.currentDashboard.spaceName}}
        </h1>
      </div>
      
      <div >
        <div>
            <div class="dashboard-area">
              <DashboardGridView
                v-if="dashboardState.currentDashboard.widgets && dashboardState.currentDashboard.widgets.length"
                :dashboardId="dashboardState.currentDashboard._id"
                :widgets="dashboardState.currentDashboard.widgets"
              />
              <div v-else class="empty-data-area flex justify-content-center align-items-center flex-auto">
                <WidgetNoDataView :noDataType="noDataType" message="There are no widgets." />
              </div>
            </div>
        </div>
      </div>
    </div>
  </transition>
  <div v-if="!(presentaionState.isLoadedCurrentPresentation && dashboardState.currentDashboard && dashboardState.isLoadedWidgets)" class="progress-spinner-container">
    <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
  </div>
        
  <SpeedDial 
    :model="actionItems" 
    :direction="isSpeedDialSmallScreen ? 'down' : 'left'" 
    :hideOnClickOutside="false"
    class="dashboard-actions"
    @show="dashboardSpeedDialShow"
    @hide="dashboardSpeedDialHide"
    :pt="{ menu: { style: dashboardSpeedDialMenuStyle } }"
  >
    <template #button="slotProps">
      <Button 
        @click="slotProps.toggleCallback"
        v-tippy="'Dashboard Actions'"
        class="dashboard-actions-init-btn"
      >
        <i class="dashboard-actions-init-btn-icon">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 26"><path stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M.955 4.718h15.778m0 0c0 1.867 1.413 3.381 3.155 3.381 1.743 0 3.156-1.514 3.156-3.381 0-1.868-1.413-3.382-3.156-3.382-1.742 0-3.155 1.514-3.155 3.382Zm-9.467 8.228h15.778m-15.778 0c0 1.868-1.413 3.382-3.155 3.382-1.743 0-3.156-1.514-3.156-3.382 0-1.867 1.413-3.381 3.156-3.381 1.742 0 3.155 1.514 3.155 3.381Zm-6.31 8.342h15.777m0 0c0 1.867 1.413 3.381 3.155 3.381 1.743 0 3.156-1.514 3.156-3.381 0-1.868-1.413-3.382-3.156-3.382-1.742 0-3.155 1.514-3.155 3.382Z"/></svg>
        </i>
        <i class="dashboard-actions-init-btn-close"></i>
      </Button>
    </template>
    <template #item="slotProps">
      <Button 
        @click="slotProps.item.command({ originalEvent: $event, item: slotProps.item })" 
        :label="(slotProps.item.key === 'Additional actions' || isSpeedDialSmallScreen) ? '' : slotProps.item.label"
        v-tippy="slotProps.item.label"
        :icon="slotProps.item.icon"
        :class="slotProps.item.class"
      />
    </template>
  </SpeedDial>
  <OverlayPanel 
    ref="speedDialGDRSOverlayPanel" 
    appendTo="#overlayPanelPlaceholder" 
    @show="onSpeedDialGDRSOverlayPanelShow"
    @hide="onSpeedDialGDRSOverlayPanelHide"
    class="gdrs-overlaypanel"
  >
    <div v-if="dashboardState.gdrs">
      <DashboardWidgetGlobalDateRangeView :overlayFix="overlayFix"/>
    </div>
    <div v-else>
      <h3 class="gdrs-title">Enable GDRS (Global Date Range Setpoint)</h3>
      <p class="mb-0"><span>Something is wrong, please try to reload page</span></p>
    </div>
  </OverlayPanel>
</template>

<script lang="ts">
import DashboardState from "@/store/states/DashboardState";
import { Component, Prop, Vue } from "vue-facing-decorator";
import ProgressSpinner from 'primevue/progressspinner';
import { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";
import OverlayPanel from "primevue/overlaypanel";
import SpeedDial from 'primevue/speeddial';
import Button from 'primevue/button';
import AuthState from "@/store/states/AuthState";
import PresentationState from "@/store/states/PresentationState";
import { Watch } from "vue-facing-decorator";
import { PresentationPage } from "@/models/dashboard/PresentationPage";
import { WidgetNoDataTypes } from "@/models/enums/WidgetNoDataTypes";
import WidgetNoDataView from '@/components/widgets-next/common/WidgetNoDataView.vue';
import DashboardGridView from "./DashboardGridView.vue";
import { Space } from "@/models/dashboard/Space";
import { nextTick, reactive } from "vue";
import DashboardWidgetGlobalDateRangeView from "./DashboardWidgetGlobalDateRangeView.vue";
import { PresentationEffect } from "@/models/enums/PresentationEffect";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";

@Component({
  components: {
    ProgressSpinner,
    SpeedDial,
    OverlayPanel,
    Button,
    WidgetNoDataView,
    DashboardGridView,
    DashboardWidgetGlobalDateRangeView
  },
})
class SharedPresentationView extends Vue {
  @Prop({ required: true }) pane!: string;
  @Prop({ required: true }) slide!: string;

  noDataType = WidgetNoDataTypes.NotConfigured;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get presentaionState(): PresentationState {
    return this.$store.state.presentation;
  }

  get dashboards(): Space[] | null | undefined {
    return this.$store.getters["dashboard/getDashboards"];
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.$store.commit("dashboard/setDashboardType", this.pane);
    this.loadPresentation();
    this.$store.dispatch("dashboard/loadGDRS");
    this.$store.dispatch("dashboard/loadSpaceSettings");
    this.emitter.on("window_size_changed_debounce", this.onResize);
    this.onResize();
  }

  getSlideInfo(): PresentationPage | null {
    if (this.presentaionState.currentPresentation && this.presentaionState.currentPresentation.dashboards.length) {
      const slideNumber = parseInt(this.slide);
      let index = slideNumber ? (slideNumber - 1) : 0;
      if (index >= this.presentaionState.currentPresentation.dashboards.length || index < 0) {
        index = 0;
      }
      const slideInfo = this.presentaionState.currentPresentation.dashboards[index];
      return slideInfo;
    }
    return null;
  }

  getDashboardId(): string {
    const slideInfo = this.getSlideInfo();
    if (slideInfo) {
      return slideInfo.spaceId;
    } else {
      return "";
    }
  }
  
  @Watch('slide', { immediate: false, deep: false })
  onSlideChanged(val: string, oldVal: string): void {
    const dashboardId = this.getDashboardId();
    this.$store.commit("dashboard/setDashboardId", dashboardId);
    const dashboard = this.dashboards?.find(x => x._id === dashboardId);
    this.$store.dispatch("dashboard/setCurrentDashboard", dashboard);
    this.updateAnimateClasses();
  }

  async loadPresentation(): Promise<void> {
    if (this.authState.sharedPresentationInfo) {
      await this.$store.dispatch("presentation/loadOneByShareId", this.authState.sharedPresentationInfo?._id);
      const dashboardId = this.getDashboardId();
      await this.$store.dispatch("dashboard/loadSlides", { id: dashboardId, silent: false });
      this.updateAnimateClasses();
    }
  }

  enterClass = "";
  leaveClass = "";

  updateAnimateClasses(): void {
    const slideInfo = this.getSlideInfo();
    if (slideInfo) {
      switch (slideInfo.effect) {
        default:
        case PresentationEffect.Fade: {
          this.enterClass = "animate__animated animate__fadeIn";
          this.leaveClass = "animate__animated animate__fadeOut";
          break;
        }
        case PresentationEffect.SlideLeft: {
          this.enterClass = "animate__animated animate__slideInLeft";
          this.leaveClass = "animate__animated animate__slideOutRight";
          break;
        }
        case PresentationEffect.SlideRight: {
          this.enterClass = "animate__animated animate__slideInRight";
          this.leaveClass = "animate__animated animate__slideOutLeft";
          break;
        }
        case PresentationEffect.SlideUp: {
          this.enterClass = "animate__animated animate__slideInUp";
          this.leaveClass = "animate__animated animate__slideOutUp";
          break;
        }
        case PresentationEffect.SlideDown: {
          this.enterClass = "animate__animated animate__slideInDown";
          this.leaveClass = "animate__animated animate__slideOutDown";
          break;
        }
        case PresentationEffect.ScaleUp: {
          this.enterClass = "animate__animated animate__zoomInUp";
          this.leaveClass = "animate__animated animate__zoomOutUp";
          break;
        }
        case PresentationEffect.ScaleDown: {
          this.enterClass = "animate__animated animate__zoomInDown";
          this.leaveClass = "animate__animated animate__zoomOutDown";
          break;
        }
        case PresentationEffect.Flip: {
          this.enterClass = "animate__animated animate__flip";
          this.leaveClass = "animate__animated animate__flipOutY";
          break;
        }
        case PresentationEffect.Rotate: {
          this.enterClass = "animate__animated animate__rotateIn";
          this.leaveClass = "animate__animated animate__rotateOut";
          break;
        }
        
      }
      
    } else {
      this.enterClass = "animate__animated animate__fadeIn";
      this.leaveClass = "animate__animated animate__fadeOut";
    }
  }

  // #region dashboard actions
  isSpeedDialSmallScreen = false;

  onResize(): void {
    const windowWidth = window.innerWidth;
    this.isSpeedDialSmallScreen = windowWidth < 1200;
  }

  dashboardSpeedDialVisible = false;
  dashboardSpeedDialVisibleTimeout: number | undefined = undefined;

  dashboardSpeedDialShow(): void {
    clearTimeout(this.dashboardSpeedDialVisibleTimeout);
    this.dashboardSpeedDialVisible = true;
  }

  dashboardSpeedDialHide(): void {
    clearTimeout(this.dashboardSpeedDialVisibleTimeout);
    this.dashboardSpeedDialVisibleTimeout = window.setTimeout(() => {
      this.dashboardSpeedDialVisible = false;
    }, 300);
  }

  get dashboardSpeedDialMenuStyle(): string {
    const result = this.dashboardSpeedDialVisible ? '' : 'height: 0; width: 0; padding: 0; margin: 0;';
    return result;
  }

  get actionItems(): MenuItem[] {
    const result: MenuItem[] = [
      {
        key: "GDRS",
        label: "GDRS",
        icon: "",
        command: (event: MenuItemCommandEvent) => {
          this.toggleSpeedDialGDRSOverlayPanel(event.originalEvent);
        },
        class: this.isSpeedDialGDRSOverlayPanelVisible ? "dashboard-actions-item-btn dashboard-actions-item-btn-gdrs dashboard-actions-item-btn-gdrs-active" : "dashboard-actions-item-btn dashboard-actions-item-btn-gdrs"
      }
    ];
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  isSpeedDialGDRSOverlayPanelVisible = false;

  onSpeedDialGDRSOverlayPanelShow(): void {
    this.isSpeedDialGDRSOverlayPanelVisible = true;
  }
  
  onSpeedDialGDRSOverlayPanelHide(): void {
    this.isSpeedDialGDRSOverlayPanelVisible = false;
  }

  toggleSpeedDialGDRSOverlayPanel(event: Event): void {
    if (this.$refs.speedDialGDRSOverlayPanel) {
      (this.$refs.speedDialGDRSOverlayPanel as OverlayPanel).toggle(event);
    }
  }
  // #endregion dashboard actions
  
  // #region gdrs overlay
  dismissableOverlay = true;

  onCalendarShow(): void {
    this.dismissableOverlay = false;
  }

  async onCalendarHide() : Promise<void> {
    await nextTick();
    this.dismissableOverlay = true;
  }

  overlayFix(dismissableOverlay: boolean): void {
    if (dismissableOverlay) {
      this.onCalendarHide();
    } else {
      this.onCalendarShow();
    }
  }
  // #endregion gdrs overlay
}

export default SharedPresentationView;
</script>
