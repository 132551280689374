<template>
  <div class="default-view-container">
    <Breadcrumb
      :home="breadcrumbHome"
      :model="breadcrumbItems"
      class="mb-0 lg:mr-5 page-breadcrumb"
    >
      <template #separator>
        <span class="pi pi-chevron-right" aria-hidden="true"></span>
      </template>
    </Breadcrumb>
    <div v-if="presentationUrl && presentationState.currentPresentation?.enable" class="default-view inline-block increase-padding-bottom mt-4 lg:mt-5 dashboard-font-family">
      <header class="default-view-header">
        <h2>Presentation URL</h2>
      </header>
      <div class="default-view-body">
        <div class="share-presentation-content">
          <div class="md:flex gap-5">
            <section>
              <h3>Use the following link to share your presentation.</h3>
              <div class="flex align-items-center">
                <div class="flex-initial">
                  <a :href="presentationUrl" target="_blank" class="p-component break-word">{{presentationUrl}}</a>
                </div>
                <div class="flex-none ml-4">
                  <Button v-tippy="'Copy link'" @click="copyValue(presentationUrl)" icon="pi pi-copy text-2xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem"></Button>
                </div>
              </div>
            
              <div class="mt-4 inline-block cursor-pointer" @click="saveQR('dashboardShareQR')">
                <QrcodeVue id="presentationShareQR" :value="presentationUrl" render-as="svg" foreground="#133547" background="#f6f6f6" class="vertical-align-top"></QrcodeVue>
                <label class="block mt-2 text-center cursor-pointer">
                  Click to save
                </label>
              </div>
            </section>
            <section>
              <h3>Use the following html code to embed your presentation.</h3>
              <div class="flex align-items-center">
                <div class="flex-auto">
                  <span class="break-word">{{urlToIFrame(presentationUrl)}}</span>
                </div>
                <div class="flex-none ml-4">
                  <Button v-tippy="'Copy HTML'" @click="copyValue(urlToIFrame(presentationUrl))" icon="pi pi-copy text-2xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem"></Button>
                </div>
              </div>
            </section>
          </div>

          <div>
            <section>
              <h3>Share presentation via:</h3>
              <div class="share-network-buttons">
                <ShareNetwork
                  v-for="network in networks"
                  :network="network.network"
                  :key="network.network"
                  :style="{ backgroundColor: network.color, borderColor: network.color }"
                  :url="presentationUrl"
                  :title="`Bitpool: ${$store.state.dashboard.currentDashboard ? $store.state.dashboard.currentDashboard.spaceName : ''}`"
                  hashtags="bitpool"
                  class="share-network-button p-button p-component"
                >
                  <i class="text-xl mr-2" :class="network.icon"></i>
                  <span>{{ network.name }}</span>
                </ShareNetwork>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <div v-if="authState.permissions?.FullAccess || authState.permissions?.Sharing" class="default-view increase-padding-bottom mt-4 lg:mt-5 dashboard-font-family">
      <header class="default-view-header">
        <h2>{{`${presentationId === 'new' ? 'Add' : 'Edit'} Presentation`}}</h2>
      </header>
      <div class="default-view-body">
        <div v-if="presentationState.isLoadedCurrentPresentation && isDashboardsLoaded">
          <BlockUI :blocked="presentationState.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index" :class="presentationState.updateInProgress ? 'blockui-blocked' : ''">
            <div v-if="presentationState.currentPresentation">
              <div class="presentation-view-header">
                <div class="mr-2">
                  <Button :disabled="!isPresentationChanged" icon="pi pi-save" label="Save Presentation" class="my-1 mr-2" @click="saveChanges" />
                </div>
              </div>
              <div class="presentation-view-body">           
                <div class="field">
                  <label for="" class="font-semibold">Presentation Name</label>
                  <div>
                    <InputText
                      class="inputfield p-inputtext w-full"
                      placeholder="Presentation Name"
                      type="text"
                      @input="isChangedEvent"
                      v-model="presentationState.currentPresentation.name"
                    />
                  </div>
                </div>
                <div class="field">
                  <label for="" class="font-semibold">Options</label>
                  <div class="sm:flex gap-6 align-items-center">
                    <div class="field sm:mb-0 flex-shrink-0 pt-1">
                      <div class="flex align-items-center">
                        <InputSwitch v-model="presentationState.currentPresentation.enable" inputId="enableSwitch" class="vertical-align-top" @change="isChangedEvent" />
                        <label for="enableSwitch" class="mb-0 ml-2">Enable</label>
                      </div>
                    </div>
                    <div class="field sm:mb-0 flex-shrink-0 pt-1">
                      <div class="flex align-items-center">
                        <InputSwitch v-model="presentationState.currentPresentation.autoStart" inputId="autoStart" class="vertical-align-top" @change="isChangedEvent" />
                        <label for="autoStart" class="mb-0 ml-2">Auto Start</label>
                      </div>
                    </div>
                    <div class="flex-shrink-0 pt-1">
                      <div class="flex align-items-center">
                        <InputSwitch v-model="presentationState.currentPresentation.loop" inputId="loop" class="vertical-align-top" @change="isChangedEvent" />
                        <label for="loop" class="mb-0 ml-2">Loop</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="field mb-0">
                  <label for="" class="font-semibold">Slides</label>
                  <DataTable
                    :value="presentationState.currentPresentation.dashboards"
                    showGridlines 
                    responsiveLayout="stack" 
                    breakpoint="800px" 
                    class="p-datatable-sm responsive-breakpoint presentation-table">
                    <template #header>
                      <div class="table-header">
                        <Button label="Add Slide" icon="pi pi-plus-circle" class="my-1" @click="addDashboardRow" />
                      </div>
                    </template>
                    <template #empty>
                      <div class="w-full" style="min-height: 50vh;">
                        <span class="inline-block py-2">Please add slides.</span>
                      </div>
                    </template>
                    <Column bodyClass="text-center font-semibold line-number">
                      <template #body="{ index }">
                        {{index + 1}}
                      </template>
                    </Column>
                    <Column field="spaceId" header="Dashboard">
                      <template #body="{ data, field, index }">
                        <div class="block w-full with-inline-btn">
                          <TreeSelectView 
                            :selectedId="data[field]" 
                            :nodes="nodes"
                            :changeSelectedId="(node: TreeNodeForUI): void => dashboardSelected(index, node)"
                            placeholder="Select Dashboard"
                          />
                          <Button
                            icon="pi pi-external-link text-lg"
                            v-tippy="'View Dashboard'"
                            class="p-button-icon-only p-button-rounded p-button-text" 
                            @click="openDashboard(data[field])" 
                            :disabled="!data[field]"
                          />
                        </div>
                      </template>
                    </Column>
                    <Column field="effect" header="Effect" headerStyle="width: 20%; min-width: min-content;">
                      <template #body="{ data, field }">
                        <Dropdown
                          v-model="data[field]"
                          :options="presentationEffects"
                          optionLabel="label"
                          optionValue="value"
                          @change="isChangedEvent"
                          class="w-full"
                        />
                      </template>
                    </Column>
                    <Column field="duration" header="Duration" headerStyle="width: 15%; min-width: 150px;" bodyClass="duration-slide-col">
                      <template #body="{ data, field }">
                        <div class="p-inputgroup">
                          <InputNumber
                            class="inputfield w-full"
                            mode="decimal" 
                            :minFractionDigits="0"
                            @input="isChangedEvent"
                            v-model="data[field]"
                          />
                          <span class="p-inputgroup-addon white-space-nowrap">sec</span>
                        </div>
                      </template>
                    </Column>
                    <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
                      <template #body="slotProps">
                        <div>
                          <div class="inline-flex">
                            <Button 
                              icon="pi pi-arrow-up" 
                              v-tippy="'Move Up'"
                              class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                              @click="moveDashboard(slotProps.index, slotProps.index - 1)" 
                              :disabled="slotProps.index < 1"
                            />
                            <Button 
                              icon="pi pi-arrow-down" 
                              v-tippy="'Move Down'"
                              class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                              @click="moveDashboard(slotProps.index, slotProps.index + 1)"
                              :disabled="slotProps.index >= presentationState.currentPresentation.dashboards.length - 1"
                            />
                            <Button 
                              icon="pi pi-trash" 
                              v-tippy="'Delete'"
                              class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                              @click="openConfirmation(slotProps.index)"
                            />
                          </div>
                        </div>
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </div>
            <Message v-else severity="error" :closable="false" class="my-0">Presentation not found</Message>

            <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
          </BlockUI>
        </div>
        <div
          v-else
          class="w-full flex justify-content-center align-items-center flex-auto"
          style="min-height: 50vh"
        >
          <ProgressSpinner
            class="spinner-primary"
            style="width: 100px; height: 100px"
            strokeWidth="4"
            animationDuration="1s"
          />
        </div>
      </div>
    </div>

    <div v-else class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>{{`${presentationId === 'new' ? 'Add' : 'Edit'} Presentation`}}</h2>
      </header>
      <div class="default-view-body">
        <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import Button from 'primevue/button';
import Breadcrumb from "primevue/breadcrumb";
import ProgressSpinner from "primevue/progressspinner";
import Message from 'primevue/message';
import BlockUI from 'primevue/blockui';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import InputSwitch from 'primevue/inputswitch';
import { Component, Prop, Vue } from "vue-facing-decorator";
import { Watch } from "vue-facing-decorator";
import { nextTick } from "vue";
import PresentationState from "@/store/states/PresentationState";
import DashboardState from "@/store/states/DashboardState";
import { PresentationEffect } from '@/models/enums/PresentationEffect';
import ConfirmationService from '@/services/ConfirmationService';
import { arrayMoveMutable } from 'array-move';
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import SpaceHelper from "@/helpers/SpaceHelper";
import TreeSelectView from "@/components/views/TreeSelectView.vue";
import { DashboardType } from '@/models/dashboard/DashboardType';
import copy from 'copy-to-clipboard';
import ToastService from "@/services/ToastService";
import PrintHelper from "@/helpers/PrintHelper";
import { saveAs } from 'file-saver';
import QrcodeVue from 'qrcode.vue'
import AuthState from '@/store/states/AuthState';
import { MenuItem, MenuItemCommandEvent } from 'primevue/menuitem';
import NavigationHelper from '@/helpers/NavigationHelper';

@Component({
  components: {
    InputText,
    InputNumber,
    Dropdown,
    Button,
    Breadcrumb,
    ProgressSpinner,
    Message,
    BlockUI,
    DataTable,
    Column,
    TreeSelectView,
    QrcodeVue,
    InputSwitch
  },
})
class PresentationView extends Vue {
  @Prop({ required: true }) presentationId!: string;

  isDashboardsLoaded = false;
  isPresentationChanged = false;

  nodes: TreeNodeForUI[] = [];
  spaceDictionaries: [Record<string, TreeNodeForUI>, Record<string, TreeNodeForUI>] = [{}, {}];

  get presentationState(): PresentationState {
    return this.$store.state.presentation;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  breadcrumbHome = {
    label: "Presentations",
    url: "/dashboards/presentation",
    command: (event: MenuItemCommandEvent) => {
      if (!NavigationHelper.goTo("/dashboards/presentation")) {
        event.originalEvent.preventDefault();
      }
    }
  };

  get breadcrumbItems(): MenuItem[] {
    const url = `/dashboards/presentation/${this.presentationId}`;
    return this.presentationState && this.presentationState.currentPresentation
      ? [
        {
          label: this.presentationId === "new" ? "New" : this.presentationState.currentPresentation.name,
          url: url,
          command: (event: MenuItemCommandEvent) => {
            if (!NavigationHelper.goTo(url)) {
              event.originalEvent.preventDefault();
            }
          }
        },
      ]
      : [];
  }

  @Watch('presentationId', { immediate: false, deep: false })
  async onIdChanged(val: string, oldVal: string): Promise<void> {
    this.$store.commit("presentation/unloadCurrentPresentation");
    await nextTick();
    this.$store.dispatch("presentation/loadOne", this.presentationId);
  }

  created(): void {
    this.$store.dispatch("presentation/loadOne", this.presentationId);
    this.loadDashboards();
  }

  async loadDashboards(): Promise<void> {
    this.isDashboardsLoaded = false;
    await this.$store.dispatch("dashboard/loadOrganisation", { id: null, silent: true });
    await this.$store.dispatch("dashboard/loadPersonal", { id: null, silent: true });
    this.isDashboardsLoaded = true;
    const treeAndDictionaries = SpaceHelper.buildTreeForTreeSelect(this.dashboardState.dashboards, this.dashboardState.dashboardsPersonal);
    this.nodes = treeAndDictionaries[0];
    this.spaceDictionaries = [treeAndDictionaries[1], treeAndDictionaries[2]];
  }

  unmounted(): void {
    this.$store.commit("presentation/unloadCurrentPresentation");
  }

  isChangedEvent(): void {
    this.isPresentationChanged = true;
  }

  presentationEffects = [
    { label: 'Fade', value: PresentationEffect.Fade },
    { label: 'Slide Left', value: PresentationEffect.SlideLeft },
    { label: 'Slide Right', value: PresentationEffect.SlideRight },
    { label: 'Slide Up', value: PresentationEffect.SlideUp },
    { label: 'Slide Down', value: PresentationEffect.SlideDown },
    { label: 'Scale Up', value: PresentationEffect.ScaleUp },
    { label: 'Scale Down', value: PresentationEffect.ScaleDown },
    { label: 'Flip', value: PresentationEffect.Flip },
    { label: 'Rotate', value: PresentationEffect.Rotate }
  ];

  addDashboardRow(): void {
    this.presentationState.currentPresentation?.dashboards.push({
      duration: 30,
      effect: PresentationEffect.Fade,
      spaceId: "",
      name: ""
    });
    this.isChangedEvent();
  }

  dashboardSelected(index: number, node: TreeNodeForUI): void {
    if (this.presentationState.currentPresentation) {
      const id = node.key ?? "";
      this.presentationState.currentPresentation.dashboards[index].spaceId = id;
      let name = this.spaceDictionaries[0][id]?.label;
      if (!name) {
        name = this.spaceDictionaries[1][id]?.label;
      }
      this.presentationState.currentPresentation.dashboards[index].name = name ?? "NO NAME";
    }
    this.isChangedEvent();
  }

  openDashboard(id: string): void {
    const dashboardType = this.spaceDictionaries[1][id] ? DashboardType.Personal : DashboardType.Organisation;
    const newUrl = `/dashboards?pane=${dashboardType}&id=${id}`;
    window.open(newUrl, '_blank');
  }

  moveDashboard(fromIndex: number, toIndex: number): void {
    if (this.presentationState.currentPresentation) {
      arrayMoveMutable(this.presentationState.currentPresentation.dashboards, fromIndex, toIndex);
      this.isChangedEvent();
    }
  }

  openConfirmation(index: number): void {
    const message = `Are you sure you want to delete dashboard from presentation?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.presentationState.currentPresentation?.dashboards.splice(index, 1);
        this.isChangedEvent();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async saveChanges(): Promise<void> {
    await this.$store.dispatch("presentation/createUpdate", this.presentationState.currentPresentation);
    if (!this.presentationState.updateError) {
      this.isPresentationChanged = false;
    }
  }

  copyValue(value: string | null): void {
    if (value) {
      copy(value);
      ToastService.showToast("success", "", "Copied!", 5000);
    }
  }

  urlToIFrame(url: string | null): string {
    if (url) {
      return `<iframe src="${url}" frameborder="0" style="width: 100%; height: auto; min-height: 300px; border: 0 none; vertical-align: top;" allowfullscreen></iframe>`;
    } else { 
      return "";
    }
  }

  get presentationUrl(): string {
    const result = this.presentationState.currentPresentation?.share_id ? 
      `${window.location.protocol}//${window.location.host}/view/presentation/${this.presentationState.currentPresentation.share_id}`
      : "";
    return result;
  }

  async saveQR(id: string): Promise<void> {
    const element = document.getElementById(id);
    if (element) {
      const result = await PrintHelper.svgToPng(300, 300, element.outerHTML, true, "#printCanvas");
      saveAs(result, "presentation-qr.png");
    }
  }

  get networks(): any[] {
    return this.$store.state.share.networks;
  }
}

export default PresentationView;
</script>
