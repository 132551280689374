import { ComposerTranslation, createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';
import { PrimeVueChangeTheme, PrimeVueConfiguration } from 'primevue/config';
import enPrime from './locales/primevue/en.json';
import dePrime from './locales/primevue/de.json';

// Type-define 'en' as the master schema for the resource
export type MessageSchema = typeof en;

const browserLocale = navigator.language.slice(0, 2);
const savedLocale = localStorage.getItem("locale");
const initLocale = savedLocale ? savedLocale : browserLocale;

class localeManager {
  private _supportedLocales: [string, string][] = [['en', 'English'], ['de', 'German (partially translated)']];

  public get supportedLocales(): [string, string][] {
    return this._supportedLocales;
  }

  public get supportedLocalesList(): string[] {
    return this._supportedLocales.map((x) => x[0]);
  }

  private _currentLocale = this.supportedLocalesList.includes(initLocale) ? initLocale : 'en';

  public get currentLocale(): string {
    return this._currentLocale;
  }

  // https://vue-i18n.intlify.dev/guide/advanced/typescript.html
  private _i18n = createI18n<[MessageSchema], 'en' | 'de'>({
    legacy: false,
    locale: this.currentLocale,
    fallbackLocale: 'en',
    messages: {
      'en': en,
      'de': de
    }
  });


  public get i18n(): any {
    return this._i18n;
  }

  public get t(): ComposerTranslation<MessageSchema> {
    return this._i18n.global.t;
  }

  public changeLocale(locale: string): void {
    if (this._currentLocale !== locale) {
      this._currentLocale = this.supportedLocalesList.includes(locale) ? locale : 'en';
      localStorage.setItem("locale", this._currentLocale);
      (this.i18n.global.locale as any).value = locale;
      this.applyLocaleToPrimevue();
    }
  }

  private _primevue: {
    config: PrimeVueConfiguration;
    changeTheme: PrimeVueChangeTheme;
  } | null = null;
  
  public get primevue(): {
    config: PrimeVueConfiguration;
    changeTheme: PrimeVueChangeTheme;
  } | null {
    return this._primevue;
  }
  
  public set primevue(value: {
    config: PrimeVueConfiguration;
    changeTheme: PrimeVueChangeTheme;
  } | null) {
    this._primevue = value;
    this.applyLocaleToPrimevue();
  }

  public applyLocaleToPrimevue(): void {
    if (this._primevue?.config.locale) {
      if (this.currentLocale === 'de') {
        Object.assign(
          this._primevue.config.locale,
          enPrime.en, // fallback, an object like { emptyFilterMessage: 'Empty', emptyMessage: 'empty...' }
          dePrime.de, // locale, an object like { emptyFilterMessage: 'Leer' }
        );
      } else {
        // default locale is english
        Object.assign(
          this._primevue.config.locale,
          enPrime.en
        );
      }
      this._primevue.config.locale.firstDayOfWeek = 1;
    }
  }
}

export default new localeManager();