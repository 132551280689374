<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 521 521"><path stroke-width="8" d="M514.3 256.4 432.6 192c-2-1.6-4.9-1.2-6.5.8-.6.8-1 1.8-1 2.9V237h-142V94.9h41.5c3.8 0 6-4.5 3.6-7.5L263.7 5.8c-1.5-2-4.4-2.4-6.4-.8-.3.2-.6.5-.8.8L192 87.5c-1.6 2-1.2 4.9.8 6.5.8.6 1.8 1 2.9 1H237v142H94.9v-41.5c0-3.8-4.5-6-7.5-3.6L5.8 256.4c-2 1.5-2.4 4.4-.8 6.4.2.3.5.6.8.8l81.6 64.5c3 2.4 7.5.3 7.5-3.6v-41.4H237v142.1h-41.5c-3.8 0-6 4.5-3.6 7.5l64.5 81.6c1.9 2.4 5.4 2.4 7.2 0l64.5-81.6c2.4-3 .3-7.5-3.6-7.5h-41.3V283.1h142.1v41.5c0 3.8 4.5 6 7.5 3.6l81.6-64.5c2-1.6 2.4-4.5.8-6.6-.4-.3-.6-.5-.9-.7Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class HandleSvg extends Vue {
}

export default HandleSvg;
</script>