<template>
  <div class="field col-12 semantics-tags-field">
    <div class="semantics-tags-field-inner">
      <div class="formgrid grid">
        <div class="col-12">
          <label :for="`${inputId}-name`">Name</label>
          <div>
            <AutoComplete 
              :inputId="`${inputId}-name`"
              v-model="addFieldValue" 
              dropdown 
              optionLabel="label" 
              :optionGroupLabel="autocompleteWithGroups ? 'label' : ''" 
              :optionGroupChildren="autocompleteWithGroups ? 'items' : ''"
              :suggestions="filteredCompatibleFields"
              :virtualScrollerOptions="{ itemSize: 38 }"
              @complete="filterCompatibleFields"
              class="w-full"
              @change="tagNameChangeDebounce()"
            >
              <template #optiongroup="slotProps">
                <div class="flex align-items-center font-bold">
                  <i class="pi pi-folder font-bold mr-2 flex-shrink-0"></i>
                  <div class="flex-auto">{{ slotProps.option.label }}</div>
                </div>
              </template>
            </AutoComplete>
            <small class="block mt-1">{{ findFieldDescription(field) }}</small>
          </div>
        </div>
      </div>
        
      <div v-if="field" class="formgrid grid pt-2 row-gap-2">
        <HaystackValueEditView
          v-model="valueHayson" 
          :field="field" 
          :inputId="`${inputId}-edit-${field}`"
          :def="field"
          :disabledFields="disabledFields"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import AutoComplete, { AutoCompleteCompleteEvent } from 'primevue/autocomplete';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Dialog from 'primevue/dialog';
import { Component, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import { HaysonDict, Kind} from "haystack-core";
import HaystackDefsService from '@/services/HaystackDefsService';
import { useHaystackDefsStore } from '@/stores/haystackDefs';
import { HaystackDefsEntity } from '@/models/nav-tree/HaystackDefsEntity';
import { AutoCompleteModel } from '@/models/nav-tree/AutoCompleteModel';
import { useTagManagerStore } from '@/stores/tagManager';
import { debounce } from 'throttle-debounce';
import { v4 as uuidv4 } from 'uuid';
import HaystackValueEditView from "@/components/views/tags/haystack-edit/HaystackValueEditView.vue";

@Component({
  components: {
    AutoComplete,
    Button,
    InputText,
    Dialog,
    HaystackValueEditView
  },
})
class StringTagEditorView extends Vue {
  @Model stringTag!: string;
  @Prop({ default: [] }) excludeFields!: string[];
  @Prop({ default: [] }) disabledFields!: string[];
  @Prop({ default: false }) allowAny!: boolean;
  @Prop({ default: false }) allowRef!: boolean;
  
  haystackDefsStore = useHaystackDefsStore();
  tagManagerStore = useTagManagerStore();

  inputId = uuidv4();

  field = "";
  valueHayson: HaysonDict = {};

  created(): void {
    if (this.stringTag) {
      const temp = HaystackDefsService.tagStringToHaysonVal(this.stringTag, undefined) ?? ["", ""];
      if (temp) {
        this.addFieldToHayson(temp[0], temp[1]);
        this.addFieldValue = temp[0];
      }
    }
  }

  findFieldDescription(field: string): string {
    return field ? HaystackDefsService.findFieldDescription(field) : "Please enter tag name";
  }

  addFieldToHayson(def: string, value: any): void {
    this.valueHayson = {};
    this.valueHayson[def] = value;
    this.field = def;
  }

  @Watch("valueHayson", { deep: true, immediate: false })
  valueHaysonChanged(): void {
    if (this.valueHayson) {
      const valueStr = HaystackDefsService.haysonValToString(this.valueHayson[this.field]);
      this.stringTag = valueStr ? `${this.field}=${valueStr}` : this.field;
    }
  }

  tagNameChangeDebounce = debounce(500, this.tagNameChanged);

  tagNameChanged(): void {
    const def = this.addFieldValueStr;
    if (this.field !== def) {
      const defs = HaystackDefsService.getDefs();
      let isOk = false;
      if (defs && this.compatibleFields.find(x => x.Def === def)) {
        const element = defs.byName(def);
        if (element) {
          if (HaystackDefsService.isChoice(element)) {
            // choice is not supported by defs.newDict
            this.addFieldToHayson(element.defName, "");
            isOk = true;
          } else {
            const newDict = defs.newDict([element.defName]);
            const haysonVal = newDict.toJSON();
            this.addFieldToHayson(element.defName, haysonVal[element.defName]);
            isOk = true;
          }
        }
      } 
      if (!isOk && this.allowAny) {
        this.addFieldToHayson(def, "");
      }
    }
  }

  addFieldValue: string | AutoCompleteModel = "";
  get addFieldValueStr(): string {
    if (this.addFieldValue) {
      if (typeof this.addFieldValue === "string") {
        return this.addFieldValue;
      } else {
        return this.addFieldValue.label;
      }
    }
    return "";
  }

  filteredCompatibleFields: AutoCompleteModel[] = [];
  autocompleteWithGroups = false;

  filterCompatibleFields(event: AutoCompleteCompleteEvent): void {
    const query = event.query ? event.query.toLowerCase() : "";
    const result = (event.query ? this.compatibleFields.filter(x => x.Def.toLowerCase().includes(query)) : this.compatibleFields).map(x => x.Def);
    this.filteredCompatibleFields = result.map(x => { return { label: x, items: [] }});
    this.autocompleteWithGroups = false;
  }

  get compatibleFields(): HaystackDefsEntity[] {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      if (this.haystackDefsStore.data?.length) {
        const entities: HaystackDefsEntity[] = [];
        this.haystackDefsStore.data.forEach(defEntity => {
          if (this.field !== defEntity.Def && 
            !defEntity.Def.includes("-") && // exclude conjuncts
            (this.allowRef || !defEntity.Def.endsWith("Ref")) && 
            (this.allowRef || !defEntity.Def.includes("Ref-")) &&
            !this.disabledFields.includes(defEntity.Def)) {
            const kind = defs.defToKind(defEntity.Def);
            if (this.allowRef || kind !== Kind.Ref) {
              entities.push(defEntity);
            }
          }
        });
        return entities;
      }
    }
    return [];
  }
}

export default StringTagEditorView;
</script>