<template>
  <div class="he-tree-with-checkbox">
    <div class="he-tree-search">
      <InputText
        :placeholder="placeholder"
        type="text"
        v-model="search"
        @input="debounceSearch()"
      />
      <span class="pi pi-search"></span>
    </div>
    <div class="he-tree-wrapper">
      <BaseTree 
        v-model="nodes" 
        :defaultOpen="false"
        :statHandler="statHandler"
        textKey="label"
        :indent="22"
        :virtualizationPrerenderCount="50" 
        :watermark="false" 
        ref="tree"
        virtualization 
      >
        <template #default="{ node, stat }">
          <Button 
              :icon="stat.open ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'" 
              text 
              rounded
              @click="toggleNode(stat)" v-if="node.children?.length"
              class="p-link"
          />
          <div :class="{ 'tree-node-selected': stat.checkedSingle, 'tree-node-selectable': isSelectable(node), 'tree-node-without-children': !node.children?.length }">
            <Checkbox 
              v-model="stat.checkedSingle" 
              :binary="true" 
              @change="checkedIsChanged(stat)" 
              v-if="isSelectable(node)"
            />
            <span  
              @click="itemSelected(stat)"
              class="flex align-items-center"
            >
              <span v-if="node.icon" :class="node.icon" class="tree-node-icon"></span>
              <span class="tree-node-label">{{ node.label }}</span>
            </span>
          </div>
        </template>
      </BaseTree>
    </div>
  </div>
</template>

<script lang="ts">
import OverlayPanel from 'primevue/overlaypanel';
import InputText from 'primevue/inputtext';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import { Component, Prop, Vue } from "vue-facing-decorator";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { BaseTree } from '@he-tree/vue';
import { Stat } from '@he-tree/tree-utils';
import { debounce } from 'throttle-debounce';
import TreeHelper from '@/helpers/TreeHelper';
import { nextTick } from 'vue';

@Component({
  components: {
    OverlayPanel,
    InputText,
    Button,
    Checkbox,
    BaseTree
  },
})
class TreeWithCheckboxesView extends Vue {
  @Prop({ required: true }) nodes!: TreeNodeForUI[];
  @Prop({ required: true }) changeSelected!: (node: TreeNodeForUI[]) => void;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ required: false, default: [] }) includeTags!: string[];
  @Prop({ required: false, default: [] }) selectableWithTags!: string[];

  checkedNodes: Record<string, TreeNodeForUI> = {};

  mounted(): void {
    this.init();
  }

  async init(): Promise<void> {
    if (this.includeTags.length) {
      await nextTick();
      this.updateFinalSearch();
    }
  }

  checkedIsChanged(node: Stat<TreeNodeForUI>): void {
    if (this.isSelectable(node.data) && node.data.key) {
      if (node.checkedSingle) {
        this.checkedNodes[node.data.key] = node.data;
      } else {
        delete this.checkedNodes[node.data.key];
      }
      const nodes = Object.entries(this.checkedNodes).map(([key, value]) => value) as TreeNodeForUI[];
      this.changeSelected(nodes);
    }
  }

  itemSelected(node: Stat<TreeNodeForUI>): void {
    if (this.isSelectable(node.data) && node.data.key) {
      node.checkedSingle = !node.checkedSingle;
      this.checkedIsChanged(node);
    }
  }

  getTree(): any {
    return (this.$refs.tree as any);
  }

  statHandler(node: Stat<TreeNodeForUI>): Stat<TreeNodeForUI> {
    if (node.data.children?.length) {
      node.class = "has-children";
    }
    node.checkedSingle = false;
    return node;
  }

  toggleNode(node: Stat<TreeNodeForUI>): void {
    node.open = !node.open;
  }

  search = '';
  searchFinal = '';
  debounceSearch = debounce(500, this.updateFinalSearch);

  updateFinalSearch(): void {
    this.searchFinal = this.search;
    const nodes = this.getTree().statsFlat as Stat<TreeNodeForUI>[];
    TreeHelper.search(nodes, this.searchFinal, this.includeTags);
  }

  isSelectable(node: TreeNodeForUI): boolean {
    if (this.selectableWithTags.length) {
      if (this.selectableWithTags.every(tag => {
        if (tag.includes("|")) {
          return tag.split("|").some(x => node.tags?.includes(x));
        } else {
          return node.tags?.includes(tag);
        }
      })) {
        return true;
      }
      return false;
    } else {
      return !!node.selectable;
    }
  }
}

export default TreeWithCheckboxesView;
</script>
