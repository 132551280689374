<template>
<svg width="71" height="187" viewBox="0 0 71 187" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M0 0H71V186.549H0V0ZM12.5294 151.075C12.5294 163.747 22.8136 174.02 35.4996 174.02C48.1869 174.02 58.4711 163.747 58.4711 151.075C58.4711 142.402 53.6527 134.852 46.5431 130.952V23.5728C46.5431 17.4735 41.599 12.5294 35.4996 12.5294C29.4003 12.5294 24.4562 17.4735 24.4562 23.5728V130.953C17.3479 134.853 12.5294 142.402 12.5294 151.075Z" fill="var(--widget-svg-color-first)"/>
<path d="M44.6509 131.776V132.072L44.9104 132.215L45.633 132.611C52.3851 136.315 56.5789 143.391 56.5789 151.075C56.5789 156.699 54.3873 161.983 50.4059 165.96L50.7592 166.314L50.4058 165.96C46.4239 169.938 41.1316 172.127 35.4996 172.127C29.8681 172.127 24.5763 169.938 20.5944 165.96L20.241 166.314L20.5944 165.96C16.6131 161.983 14.4216 156.699 14.4216 151.075C14.4216 143.391 18.6149 136.316 25.3663 132.611C25.3663 132.611 25.3664 132.611 25.3664 132.611L26.0889 132.215L26.3484 132.073V131.777V23.5727C26.3484 18.5271 30.454 14.4215 35.4996 14.4215C40.5452 14.4215 44.6509 18.5271 44.6509 23.5727V131.776ZM12.0294 151.075C12.0294 164.023 22.538 174.519 35.4996 174.519C48.4625 174.519 58.9711 164.023 58.9711 151.075C58.9711 142.318 54.1636 134.683 47.0431 130.658V23.5727C47.0431 17.1973 41.8751 12.0293 35.4996 12.0293C29.1242 12.0293 23.9562 17.1973 23.9562 23.5727V130.659C16.837 134.684 12.0294 142.318 12.0294 151.075Z" fill="var(--widget-svg-color-fifth)" stroke="var(--widget-svg-color-first)"/>
<g filter="url(#filter0_d_545_88)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M46.543 130.952L46.543 23.5727C46.543 17.482 41.5901 12.5293 35.4996 12.5293C29.409 12.5293 24.4561 17.482 24.4561 23.5727L24.4562 130.953C17.1009 134.989 12.5294 142.699 12.5294 151.076C12.5294 157.202 14.9208 162.968 19.2572 167.299C23.5942 171.631 29.367 174.019 35.4996 174.019C41.6327 174.019 47.406 171.631 51.743 167.299C56.0796 162.968 58.471 157.202 58.471 151.076C58.471 142.698 53.8993 134.988 46.543 130.952Z" fill="var(--widget-svg-color-fifth)" fill-opacity="0.01" shape-rendering="crispEdges"/>
<path d="M45.918 130.952V131.322L46.2424 131.5C53.3992 135.426 57.846 142.927 57.846 151.076C57.846 157.036 55.5206 162.643 51.3013 166.857L51.743 167.299L51.3013 166.857C47.0816 171.072 41.4672 173.394 35.4996 173.394C29.5325 173.394 23.9186 171.072 19.6989 166.857L19.2572 167.299L19.6989 166.857C15.4798 162.643 13.1544 157.036 13.1544 151.076C13.1544 142.928 17.601 135.427 24.7568 131.501L25.0812 131.323V130.953L25.0811 23.5727C25.0811 17.8272 29.7542 13.1543 35.4996 13.1543C41.245 13.1543 45.918 17.8272 45.918 23.5727L45.918 130.952Z" stroke="url(#paint0_linear_545_88)" stroke-width="1.25" shape-rendering="crispEdges"/>
</g>
<defs>
<filter id="filter0_d_545_88" x="6.52942" y="9.5293" width="57.9417" height="173.49" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix v-if="isDarkTheme" type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.161 0"/>
<feColorMatrix v-else type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_545_88"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_545_88" result="shape"/>
</filter>
<linearGradient id="paint0_linear_545_88" x1="35.5002" y1="12.5293" x2="35.5002" y2="174.019" gradientUnits="userSpaceOnUse">
<stop stop-color="var(--widget-svg-color-fifth)" stop-opacity="0"/>
<stop offset="1" stop-color="var(--widget-svg-color-fifth)"/>
</linearGradient>
</defs>
</svg>

</template>

<script lang="ts">
import AuthState from "@/store/states/AuthState";
import { Component, Vue } from "vue-facing-decorator";

@Component
class ThermometerSvg extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }
}

export default ThermometerSvg;
</script>