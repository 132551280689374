<template>
  <div class="col-12 md:col-8 semantics-edit-time">
    <label :for="inputId">Value</label>
    <Calendar 
      :inputId="inputId"
      v-model="timeVal"
      timeOnly
      hourFormat="24"
      :showSeconds="true"
      class="w-full"
      panelClass="with-max-width"
      :disabled="disabledFields.includes(field)"
    />
  </div>
</template>

<script lang="ts">
import Calendar from 'primevue/calendar';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonDict, HaysonTime, HaysonVal, Kind } from "haystack-core";
import moment from 'moment';

@Component({
  components: {
    Calendar
  },
})
class HaystackTimeEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }
  
  // #region Time
  // implement an ISO 8601 time as hour, minute, seconds: 09:51:27.354.
  get timeVal(): Date {
    if (this.valueHayson) {
      const str = (this.valueHayson as HaysonTime).val;
      return new Date(`2023-01-01T${str ?? "00:00:00"}`);
    }
    return moment().toDate();
  }
  
  set timeVal(value: Date) {
    const m = moment(value);
    this.valueHayson = { 
      _kind: Kind.Time,
      val: m.format("HH:mm:ss")
    };
  }
  // #endregion Time
}

export default HaystackTimeEditView;
</script>