import ApiErrorModel from "@/models/ApiErrorModel";

class ErrorHelper {
  WEB_EXCEPTION_DELIMITER = "[BPEX]";

  parseError(bpex: string | unknown): ApiErrorModel {
    try {
      if (typeof bpex === "object") {
        const objEx = bpex as any;
        if (objEx.title) {
          let errorMessage = objEx.title;
          if (objEx.errors) {
            for (const field in objEx.errors) {
              errorMessage = `${errorMessage} ${field}: ${objEx.errors[field][0]}`;
            }
          }
          return {
            message: errorMessage,
            errorType: "",
            additionalInfo: "",
          };
        } else {
          const objEx = bpex as any;
          if (objEx.error) {
            return {
              message: objEx.error,
              errorType: "",
              additionalInfo: "",
            };
          }
        }
      } else if (typeof bpex === "string") {
        const array = bpex ? bpex.split(this.WEB_EXCEPTION_DELIMITER) : [];
        const result = {
          message: array.length > 0 ? array[0] : "Unknownt error",
          errorType: array.length > 1 ? array[1] : "",
          additionalInfo: array.length > 2 ? array[2] : "",
        };
        return result;
      }
    } catch {
      // nothing to do
    }
    return {
      message: "Unknownt error",
      errorType: "",
      additionalInfo: "",
    };
  }

  handleAxiosError(error: any): ApiErrorModel {
    if (error && error.response) {
      return error.response.data
        ? this.parseError(error.response.data)
        : this.parseError(error.response);
    }
    if (error && error instanceof Error) {
      const err = error as Error;
      return {
        message: err.message,
        errorType: "",
        additionalInfo: "",
      };
    }
    if (typeof error === "string") {
      return {
        message: error,
        errorType: "",
        additionalInfo: "",
      };
    } else {
      return {
        message: "Unknownt error",
        errorType: "",
        additionalInfo: "",
      };
    }
  }
}

export default new ErrorHelper();
