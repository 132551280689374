import { defineStore } from "pinia";
import ToastService from "@/services/ToastService";
import SpeechRecognitionState from "./states/SpeechRecognitionState";
import { useOpenAIChatStore } from "./openAIChat";
import { useSystemStore } from "./system";
import SilenceDetector from "@/helpers/SilenceDetector";
import { useAISpeechStore } from "./aiSpeech";

export const useSpeechRecognitionStore = defineStore('SpeechRecognition', {
  state: (): SpeechRecognitionState => ({ 
    speechRecognition: null,
    recognitionInProgress: false,
    originalText: "",
    silenceDetector: null,
    startedManually: true,
    stoppedManually: true,
    commandRecognized: false
  }),
  getters: {
    isSupported() {
      const windowAny = window as any;
      return !!(windowAny.SpeechRecognition || windowAny.webkitSpeechRecognition);
    }
  },
  actions: {
    createSpeechRecognition(): SpeechRecognition | null {
      const windowAny = window as any;
      const sr = (windowAny.SpeechRecognition || windowAny.webkitSpeechRecognition);
      if (sr) {
        return new sr();
      } else {
        return null;
      }
    },
    async startWaitingForSpeech(): Promise<void> {
      try {
        const systemStore = useSystemStore();
        if (systemStore.isMobile || !this.isSupported) {
          // disable for mobile and unsupported browsers
          return;
        }
        if (!this.silenceDetector) {
          this.silenceDetector = new SilenceDetector(
            this.startWaitingForHey,
            this.stopWaitingForHey
          )
        }
        await this.silenceDetector.start();
      } catch (e: any) {
        ToastService.showToast("error", "Speech Recognition", e.message ?? "Unknown error", 5000);
        return;
      }
    },
    stopWaitingForSpeech(): void {
      try {
        const systemStore = useSystemStore();
        if (systemStore.isMobile || !this.isSupported) {
          // disable for mobile and unsupported browsers
          return;
        }
        if (this.silenceDetector) {
          this.silenceDetector.stop();
        }
      } catch (e: any) {
        ToastService.showToast("error", "Speech Recognition", e.message ?? "Unknown error", 5000);
        return;
      }
    },
    startWaitingForHey(): void {
      this.start("", false);
    },
    stopWaitingForHey(): void {
      this.stop(false);
    },
    start(originalText: string, startedManually = true): void {
      const openAIChatStore = useOpenAIChatStore();
      const aiSpeechStore = useAISpeechStore();
      if (this.isSupported && !this.recognitionInProgress && !openAIChatStore.inProgress) {
        this.originalText = originalText;
        this.startedManually = startedManually;
        this.stoppedManually = true;
        this.commandRecognized = false;
        if (!this.speechRecognition) {
          this.speechRecognition = this.createSpeechRecognition();
          if (this.speechRecognition) {
            this.speechRecognition.lang = "en-US";
            this.speechRecognition.interimResults = true;
            this.speechRecognition.maxAlternatives = 1;
            this.speechRecognition.continuous = true;
            const systemStore = useSystemStore();
            this.speechRecognition.onresult = (event) => {
              if (!openAIChatStore.inProgress) {
                let newText = "";
                if (systemStore.os === "Android") {
                  const transcript = event.results[event.results.length - 1][0].transcript.trim();
                  newText = transcript;
                } else {
                  for (let i = 0; i < event.results.length; i++) {
                    const element = event.results[i];
                    const transcript = element[0].transcript.trim();
                    newText += (newText && newText[newText.length - 1] !== " ") ? ` ${transcript}` : transcript;
                  }
                }

                const magicWord = /*"hey boy"; //*/"hey bitpool";
                const magicWordLength = magicWord.length;
                if (!this.commandRecognized && !this.startedManually) {
                  // search for "Hey Bitpool" command in the transcript
                  if (newText.toLowerCase().includes(magicWord)) {
                    this.commandRecognized = true;
                    aiSpeechStore.stopSpeak();
                  }
                }
                if (this.commandRecognized || this.startedManually) {
                  // Started manually or command recognized
                  if (this.commandRecognized) {
                    const newTextLower = newText.toLowerCase();
                    const indexOfHey = newTextLower.indexOf(magicWord);
                    if (indexOfHey !== -1) {
                      newText = (newText.substring(indexOfHey + magicWordLength)).trim();
                      if (newText.length && (newText[0] === "," || newText[0] === ".")) {
                        newText = newText.substring(1).trim(); // for microsoft edge speech recognition
                      }
                    }
                  }
                  newText = `${this.originalText}${this.originalText && this.originalText[this.originalText.length - 1] !== " " ? " " : ""}${newText}`;
                  openAIChatStore.newMessage = newText;
                }
              }
            };
            this.speechRecognition.onstart = (event) => {
              this.recognitionInProgress = true;
            };
            this.speechRecognition.onend = (event) => {
              this.recognitionInProgress = false;
              if (this.commandRecognized && !this.startedManually && !this.stoppedManually && openAIChatStore.newMessage) {
                if (!openAIChatStore.inProgress) {
                  openAIChatStore.sendMessage();
                }
              }
            };
            this.speechRecognition.onerror = (event) => {
              if (event.error !== "aborted") {
                ToastService.showToast("error", "Speech Recognition", `Error: ${event.error}`, 5000);
              }
              this.recognitionInProgress = false;
            };
          }
        }
        try {
          if (this.speechRecognition) {
            this.speechRecognition.start();
          }
        } catch {
          // can throw exception if already started
        }
      }
    },
    stop(stoppedManually = true): void {
      if (this.speechRecognition && this.recognitionInProgress) {
        if (this.startedManually && !stoppedManually) {
          // don't stop on silence
          return;
        }
        this.stoppedManually = stoppedManually;
        this.speechRecognition.stop();
        this.recognitionInProgress = false;
      }
    }
  },
});
