<template>
  <div class="widget-type-data-point">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-show="!isLodingData && !isNoData">
      <span 
        ref="textContainer"
        :style="[ {color: textColor} ]"
      >
        <span>{{valueStr}}</span>
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import ColorHelper from '@/helpers/ColorHelper';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { nextTick } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import fitty, { FittyInstance, FittyOptions } from 'fitty';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import numbro from "numbro";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';

@Component({
  components: {
    ProgressSpinner,
    WidgetNoDataView
  }
})
class DataPointWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  get backgroundColor(): string {
    if (this.condition[1] === "NONE") {
      const result = this.aws?.widgetColorEnabled && this.aws?.widgetColorHex ? this.aws?.widgetColorHex : '';
      return result;
    } else {
      const result = this.condition[1];
      return result;
    }
  }

  get textColor(): string {
    return ColorHelper.getContrastColor(this.backgroundColor);
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;
  value : number | string = "";

  get condition(): [string, string] {
    // value, color
    const result: [string, string] = ["", "NONE"];
    if (typeof this.value === "string") {
      if (this.aws?.dataConditions?.length) {
        for (const dataCondition of this.aws.dataConditions) {
          switch (dataCondition.rule) {
            case "=": {
              if (this.value === dataCondition.valueStr) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "!=": {
              if (this.value !== dataCondition.valueStr) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
          }
        }
      }
      result[0] = this.value;
    } else {
      if (this.aws?.dataConditions?.length) {
        for (const dataCondition of this.aws.dataConditions) {
          switch (dataCondition.rule) {
            case "=": {
              if (this.value === dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "!=": {
              if (this.value !== dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case ">=": {
              if (this.value >= dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case ">": {
              if (this.value > dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "<=": {
              if (this.value <= dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "<": {
              if (this.value < dataCondition.value) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
            case "Between": {
              if (typeof dataCondition.value2 === "number" && 
                this.value >= dataCondition.value && this.value < dataCondition.value2) {
                result[0] = dataCondition.name;
                if (dataCondition.color) {
                  result[1] = dataCondition.color
                }
                return result;
              }
              break;
            }
          }
        }
      }
      const decimals = this.aws?.decimals ? this.aws.decimals : 0;
      result[0] = numbro(this.value).format({
        thousandSeparated: true,
        mantissa: decimals < 0 ? 0 : decimals
      });
    }
    if (result[0] && this.aws?.widgetUnit) {
      result[0] = this.aws?.widgetUnitPos ? `${result[0]} ${this.aws.widgetUnit}` : `${this.aws.widgetUnit} ${result[0]}`;
    }
    return result;
  }

  get valueStr(): string {
    return this.condition[0];
  }

  fittyInstance: FittyInstance | null = null;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.initFitty();
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
  }

  unmounted(): void {
    if (this.fittyInstance) {
      this.fittyInstance.unsubscribe();
    }
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.recalcFontSize();
  }

  initFitty(): void {
    if (this.$refs.textContainer) {
      const params = {
        minSize: 22,
        maxSize: 46,
        observeMutations: false
      } as unknown;
      (fitty as any).observeWindowDelay = 110;
      this.fittyInstance = fitty(this.$refs.textContainer as HTMLElement, params as FittyOptions);
    }
  }

  @Watch('aws', { immediate: false, deep: true })
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async recalcFontSize(): Promise<void> {
    if (this.fittyInstance) {
      this.fittyInstance.unsubscribe();
      await nextTick();
      this.initFitty();
    }     
  }

  async changeColor(): Promise<void> {
    await nextTick();
    const colorOverwriteArg = `guid:${this.widgetConfig.guid};color:${this.condition[1]}`;
    this.emitter.emit("widgetColorOverwrite", colorOverwriteArg);
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      //set color to default
      const colorOverwriteArg = `guid:${this.widgetConfig.guid};color:NONE`;
      this.emitter.emit("widgetColorOverwrite", colorOverwriteArg);
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length && 
        (typeof streamData.Data[0].y === "number" || typeof streamData.Data[0].y === "string")) {
        this.value = streamData.Data[0].y;  
        this.changeColor();
        this.recalcFontSize();      
      } else {
        this.value = 0;
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
        //set color to default
        const colorOverwriteArg = `guid:${this.widgetConfig.guid};color:NONE`;
        this.emitter.emit("widgetColorOverwrite", colorOverwriteArg);
      }
    });
  }
}

export default DataPointWidget;
</script>