<template>
  <div class="default-view-container">
    <h1 class="mb-0">Users</h1>
    <div v-if="auth.permissions?.BitpoolAdmin" class="default-view increase-padding-bottom mt-4 lg:mt-5">   
      <header class="default-view-header">
        <h2>Users List</h2>
      </header>
      <div class="default-view-body admin-users">
        <DataTable
          ref="dt"
          :value="adminUsersState.data" 
          dataKey="UserId"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="850px" 
          sortField="RegistrationDate" 
          :sortOrder="-1"
          :paginator="true"
          :rows="20"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          v-model:filters="filters"
          filterDisplay="menu"
          :globalFilterFields="['Username', 'Fullname']"
          removableSort
          class="p-datatable-sm default-visual-table responsive-breakpoint admin-users-table">
          <template #header>
            <div class="table-header">
              <div class="sm:flex sm:align-items-center sm:justify-content-between sm:gap-3">
                <div class="sm:flex-shrink-0">
                  <Button 
                    label="Register User" 
                    icon="pi pi-plus-circle" 
                    class="my-1" 
                    @click="openCreateDialog" 
                  />
                  <Button 
                    label="Export" 
                    icon="pi pi-cloud-download" 
                    class="my-1 ml-2 p-button-outlined" 
                    @click="exportCSV"
                  />
                </div>
                <div class="mt-3 sm:mt-0">
                  <IconField iconPosition="left" class="w-full sm:w-auto">
                    <InputIcon class="pi pi-search" />
                    <InputText v-model="filters['global'].value" placeholder="Find User" class="w-full sm:w-auto" />
                  </IconField>
                </div>
              </div>
            </div>
          </template>
          <template #empty>
            <div v-if="adminUsersState.isLoaded" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column :sortable="true" field="Username" header="Username" headerStyle="width: 26%; min-width: min-content;">
            <template #filter="{ filterModel }">
              <InputText 
                v-model="filterModel.value" 
                type="text" 
                class="p-column-filter" 
                placeholder="Filter" 
              />
            </template>
          </Column>
          <Column :sortable="true" field="Fullname" header="Fullname" headerStyle="width: 15%; min-width: min-content;">
            <template #filter="{ filterModel }">
              <InputText 
                v-model="filterModel.value" 
                type="text" 
                class="p-column-filter" 
                placeholder="Filter" 
              />
            </template>
          </Column>
          <Column :sortable="true" field="Role" header="Role" headerStyle="width: 13%; min-width: min-content;">
            <template #filter="{ filterModel }">
              <Dropdown
                v-model="filterModel.value"
                :options="['Admin', 'BitPool Users']"
                :optionLabel="(x: string): string => { return x; }"
                class="p-column-filter"
                placeholder="Filter"
              />
            </template>
          </Column>
          <Column :sortable="true" field="Organisations" header="Organisations" headerStyle="width: 15%; min-width: min-content;">
            <template #body="slotProps">
              <span>{{ slotProps.data.Organisations?.join(", ") }}</span>
            </template>
            <template #filter="{ filterModel }">
              <InputText 
                v-model="filterModel.value" 
                type="text" 
                class="p-column-filter" 
                placeholder="Filter" 
              />
            </template>
          </Column>
          <Column :sortable="true" field="RegistrationDate" header="Date of registration" headerStyle="width: 15%; min-width: min-content;">
            <template #body="slotProps">
              <DateTimezoneView :date="slotProps.data.RegistrationDate" timezone="local"/>
            </template>
          </Column>
          <Column :sortable="true" field="LastLogin" header="Last login date" headerStyle="width: 15%; min-width: min-content;">
            <template #body="slotProps">
              <DateTimezoneView :date="slotProps.data.LastLogin" timezone="local"/>
            </template>
          </Column>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div class="inline-flex">
                <Button 
                  v-tippy="'Edit'"
                  icon="pi pi-pencil" 
                  class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                  @click="openEditDialog(slotProps.data)" 
                />
                <Button 
                  v-tippy="'Delete'"
                  icon="pi pi-trash" 
                  class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                  @click="openDeleteConfirmation(slotProps.data)" 
                />
              </div>
            </template>
          </Column>
        </DataTable>

        <Dialog header="Register User" v-model:visible="displayCreateDialog" :modal="true" :style="{width: '36rem'}" class="register-user-dialog">
          <div class="dialog-content">
            <div class="field">
              <label for="registerUserEmail">Email</label>
              <div>
                <InputText
                  class="inputfield w-full"
                  type="text"
                  v-model="username"
                  id="registerUserEmail"
                />
              </div>
            </div>
            <div class="field"> 
              <label for="registerUserPassword">Password</label>
              <div>      
                <Password
                  class="w-full"
                  inputClass="inputfield w-full"
                  v-model="password"
                  toggleMask
                  id="registerUserPassword"
                />
              </div>
            </div>
            <div class="field mb-0">
              <label for="registerUserPasswordConfirm">Confirm Password</label>
              <div>       
                <Password
                  class="w-full"
                  :class="{ 'p-invalid': password2 && password !== password2 }"
                  inputClass="inputfield w-full"
                  v-model="password2"
                  :feedback="false"
                  toggleMask
                  @keyup.enter="createUser"
                  id="registerUserPasswordConfirm"
                />
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="Close" icon="pi pi-times" @click="closeCreateDialog" class="p-button-text p-button-secondary"/>
            <Button label="Register" :icon="adminUsersState.createInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="createUser" :disabled='adminUsersState.createInProgress || !isFormFilled()' />
          </template>
        </Dialog>

        <Dialog header="Edit User" v-model:visible="displayEditDialog" :modal="true" :style="{width: '36rem'}" class="edit-user-dialog">
          <div class="dialog-content">
            <div class="field">
              <label for="editUserEmail">Email</label>
              <div>
                <InputText
                  class="inputfield w-full"
                  type="text"
                  v-model="newUsername"
                  id="editUserEmail"
                />
              </div>
            </div>
            <div class="field">
              <label for="editUserPassword">Password</label>
              <div>      
                <Password
                  class="w-full"
                  inputClass="inputfield w-full"
                  v-model="newPassword"
                  toggleMask
                  id="editUserPassword"
                />
              </div>
            </div>
            <div class="field">
              <label for="editUserRole">Select Role</label>
              <div>
                <Dropdown
                  v-model="newRole"
                  :options="roles"
                  optionValue="key"
                  optionLabel="name"
                  class="w-full"
                  id="editUserRole"
                />
              </div>
            </div>
            <div class="field">
              <label for="editUserOrg">Organisations</label>
              <div>
                <MultiSelect 
                  inputId="editUserOrg"
                  v-model="selectedOrganisations"
                  :options="availableOrganisations"
                  placeholder="Select Organisations"
                  display="chip"
                  :filter="true"
                  :optionValue="x => x"
                  :optionLabel="x => x"
                  class="p-multiselect-multiline inputfield w-full"
                />
              </div>
            </div>
            <div class="field mb-0">
              <label>Permissions</label>
              <div class="pt-1">
                <div class="flex align-items-center">
                  <InputSwitch v-model="bitpoolAI" inputId="bitpoolAI" class="vertical-align-top" />
                  <label for="bitpoolAI" class="mb-0 ml-2">Bitpool AI</label>
                </div>
              </div>
            </div>
          </div>
          <template #footer>
            <Button label="Close" icon="pi pi-times" @click="closeEditDialog" class="p-button-text p-button-secondary"/>
            <Button label="Save" :icon="editInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveUser" :disabled='editInProgress' />
          </template>
        </Dialog>
      </div>
    </div>
    <div v-else class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Password from 'primevue/password';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import InputSwitch from 'primevue/inputswitch';
import MultiSelect from 'primevue/multiselect';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import AuthState from "@/store/states/AuthState";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { UserLastLoginEntity } from "@/models/user/UserLastLoginEntity";
import ConfirmationService from "@/services/ConfirmationService";
import { UserRenameModel } from "@/models/user/UserRenameModel";
import { AUTH_Login } from "@/models/user/AUTH_Login";
import { UserChangeRoleModel } from "@/models/user/UserChangeRoleModel";
import useAdminUsersStore from "@/stores/adminUsers";
import AdminUsersState from "@/stores/states/AdminUsersState";
import { useOrganisationStore } from "@/stores/organisation";

@Component({
  components: {
    InputText,
    Button,
    ProgressSpinner,
    Password,
    DataTable,
    Column,
    Dialog,
    Dropdown,
    InputSwitch,
    MultiSelect,
    IconField,
    InputIcon,
    DateTimezoneView
  },
})
class AdminUsersView extends Vue {
  get auth(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();
  adminUsersStore = useAdminUsersStore();

  get adminUsersState(): AdminUsersState {
    return this.adminUsersStore;
  }

  filters = {
    'global': {value: null, matchMode: 'contains'},
    Username: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
    Fullname: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
    Role: { operator: "and", constraints: [{ value: null, matchMode: "equals" }] },
    Organisations: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
  };

  created(): void {
    this.adminUsersStore.loadData();
  }

  // #region new user
  displayCreateDialog = false;

  username = "";
  password = "";
  password2 = "";
  
  openCreateDialog(): void {
    this.displayCreateDialog = true;
  }

  closeCreateDialog(): void {
    this.displayCreateDialog = false;
  }

  isFormFilled(): boolean {
    if (this.username &&
      this.password && 
      this.password2 &&
      this.password === this.password2) {
      return true;
    } else {
      return false;
    }
  }

  async createUser(): Promise<void> {
    if (this.isFormFilled()) {
      await this.adminUsersStore.createUser({Username: this.username, Password: this.password});
      if (!this.adminUsersState.createError) {
        this.closeCreateDialog();
      }
    }
  }
  // #endregion new user

  openDeleteConfirmation(record: UserLastLoginEntity | null): void {
    const message = `Are you sure you want to delete user?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete User',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (record) {
          this.adminUsersStore.delete(record.Username);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  // #region edit user
  displayEditDialog = false;
  selectedUser: UserLastLoginEntity | null = null;
  selectedOrganisations: string[] = [];

  get availableOrganisations(): string[] {
    return this.organisationStore.entities?.map(x => x.Name) ?? [];
  }

  get editInProgress(): boolean {
    return this.adminUsersState.inProgressChangeRole ||
      this.adminUsersState.inProgressRename ||
      this.adminUsersState.inProgressResetPassword;
  }

  newUsername = "";
  newPassword = "";
  newRole = "";
  bitpoolAI = false;

  roles = [
    {name: 'Admin', key: 'Admin'},
    {name: 'BitPool Users', key: 'BitPool Users'}
  ];

  openEditDialog(record: UserLastLoginEntity): void {
    this.selectedUser = record;
    this.selectedOrganisations = record.Organisations ? [...record.Organisations] : [];
    this.newUsername = record.Username;
    this.newPassword = "";
    this.newRole = record.Role ? record.Role : "BitPool Users";
    this.bitpoolAI = record.UserPermissions ? record.UserPermissions.BitpoolAI : false;
    this.displayEditDialog = true;
  }

  closeEditDialog(): void {
    this.displayEditDialog = false;
  }

  async saveUser(): Promise<void> {
    let isOk = true;
    if (this.selectedUser && this.newUsername && this.newUsername !== this.selectedUser.Username) {
      // save username
      const body: UserRenameModel = {
        Username: this.selectedUser.Username,
        NewUsername: this.newUsername
      }
      await this.adminUsersStore.rename(body);
      if (this.adminUsersState.errorRename) {
        isOk = false;
      }
    }
    if (isOk) {
      // save password
      if (this.selectedUser && this.newPassword) {
        const body: AUTH_Login = {
          Username: this.selectedUser.Username,
          Password: this.newPassword
        }
        await this.adminUsersStore.resetPassword(body);
        if (this.adminUsersState.errorResetPassword) {
          isOk = false;
        }
      }
    }
    if (isOk) {
      // save role
      if (this.selectedUser && this.newRole && this.newRole !== this.selectedUser.Role) {
        const body: UserChangeRoleModel = {
          Username: this.selectedUser.Username,
          NewRole: this.newRole
        }
        await this.adminUsersStore.changeRole(body);
        if (this.adminUsersState.errorChangeRole) {
          isOk = false;
        }
      }
    }
    if (isOk) {
      // save permissions
      if (this.selectedUser && (this.selectedUser.UserPermissions && this.bitpoolAI !== this.selectedUser.UserPermissions.BitpoolAI || !this.selectedUser.UserPermissions && this.bitpoolAI)) {
        const newPermissions = this.selectedUser.UserPermissions ?
          JSON.parse(JSON.stringify(this.selectedUser.UserPermissions)) :
          { UserId: this.selectedUser.UserId, BitpoolAI: false };
        newPermissions.BitpoolAI = this.bitpoolAI;
        await this.adminUsersStore.changePermissions(newPermissions);
        if (this.adminUsersState.errorChangePermissions) {
          isOk = false;
        }
      }
    }
    if (isOk) {
      // save organisations
      if (this.selectedUser) {
        const addOrganisations: string[] = [];
        const removeOrganisations: string[] = [];
        for (const selectedOrganisation of this.selectedOrganisations) {
          if (!this.selectedUser.Organisations?.includes(selectedOrganisation)) {
            addOrganisations.push(selectedOrganisation);
          }
        }
        if (this.selectedUser.Organisations) {
          for (const userOrganisation of this.selectedUser.Organisations) {
            if (!this.selectedOrganisations.includes(userOrganisation)) {
              removeOrganisations.push(userOrganisation);
            }
          }
        }
        if (addOrganisations.length || removeOrganisations.length) {
          await this.adminUsersStore.addRemoveToOrganisations(
            this.selectedUser.UserId,
            addOrganisations.length && this.organisationStore.entities ? this.organisationStore.entities.filter(x => addOrganisations.includes(x.Name)) : [],
            removeOrganisations.length && this.organisationStore.entities ? this.organisationStore.entities.filter(x => removeOrganisations.includes(x.Name)) : []
          );
          if (this.adminUsersState.errorAddRemoveOrganisations) {
            isOk = false;
          }
        }
      }
    }
    if (isOk) {
      this.closeEditDialog();
    }
  }
  // #endregion edit user

  exportCSV() {
    (this.$refs.dt as DataTable)?.exportCSV();
  }
}

export default AdminUsersView;
</script>
