<template>
  <div class="sidebar-widget user-profile">
    <div class="user-profile-inner">
      <div class="user-info">
        <div class="user-avatar" v-if="avatarUrl">
          <img :src="avatarUrl" />
        </div>
        <div class="user-icon" v-else>
          <span class="user-img">
            <UserSvg/>
          </span>
        </div>
        <div>
          <div class="user-name break-word">{{fullUsername}}</div>
          <div class="user-role">{{orgRole}}</div>
        </div>
      </div>
      <div class="user-actions">
        <ul>
          <li><div @click="openUserSettingsInternal" tabindex="auto">{{ t('topBar.accountSettings') }}</div></li>
          <li><div @click="logout" tabindex="auto">{{ t('topBar.signOut') }}</div></li>
        </ul>
      </div>
      <div class="user-manage-organisation" v-if="organisationStore.entities">
        <label for="userSelectOrganisation">Select organisation</label>
        <Dropdown
          v-model="selectedOrganisation"
          :options="organisationStore.entities"
          optionLabel="Name"
          :filter="true"
          :filterPlaceholder="t('topBar.findOrganisation')"
          :loading="!organisationStore.entities || !organisationStore.entities.length"
          @change="organisationChange($event)"
          appendTo="self"
          :placeholder="t('topBar.selectOrganisation')"
          class="w-full"
          inputId="userSelectOrganisation"
        >
          <template #value="slotProps">
            <div v-if="slotProps.value">
              <div v-if="slotProps.value.Logo"><img :src="getLogoUrl(slotProps.value.Logo)" /></div>
              <div v-else><i class="pi pi-building"></i></div>
              <span>{{slotProps.value.Name}}</span>
            </div>
            <span v-else>
              {{slotProps.placeholder}}
            </span>
          </template>
          <template #option="slotProps">
            <div class="organisation-dropdown-item">
              <div v-if="slotProps.option.Logo"><img :src="getLogoUrl(slotProps.option.Logo)" /></div>
              <div v-else><i class="pi pi-building"></i></div>
              <span>{{slotProps.option.Name}}</span>
            </div>
          </template>
        </Dropdown>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import Dropdown from 'primevue/dropdown';
import CustomWindow from '@/CustomWindow';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import AuthState from "@/store/states/AuthState";
import NavigationHelper from "@/helpers/NavigationHelper";
import UserSvg from "@/components/svg/UserSvg.vue";
import { useOrganisationStore } from "@/stores/organisation";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';

declare const window: CustomWindow;

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    Button,
    Dropdown,
    UserSvg,
  },
})
class UserView extends Vue {
  @Prop({ required: true }) openUserSettings!: () => void;
  @Prop({ required: true }) afterNavigateTo!: () => void;
  
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  get apiUrl(): string {
    return this.$store.state.apiUrl;
  }

  get fullUsername(): string {
    return this.$store.getters["auth/getFullUsername"];
  }

  get avatarUrl(): string {
    return this.$store.getters["auth/getAvatarUrl"];
  }

  get orgRole(): string {
    return this.$store.state.auth.orgRole;
  }

  get auth(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  selectedOrganisation: OrganisationFullDto | null = null;

  mounted(): void {
    this.selectedOrganisation = this.currentOrganisation;
  }

  logout(): void {
    NavigationHelper.logout();
  }

  organisationChange(event: any): void {
    const org = event.value as OrganisationFullDto;
    window.angularInitService.switchOrganisation(org);
  }

  getLogoUrl(logo: string): string {
    return logo ? 
      `${this.apiUrl}/rest/AWS_S3_V1/File/${logo}` :
      "";
  }

  openOrganisationSettings(): void {
    NavigationHelper.goTo("/organisations/edit");
    if (this.afterNavigateTo) {
      this.afterNavigateTo();
    }
  }

  openUserSettingsInternal(): void {
    if (this.openUserSettings) {
      this.openUserSettings();
    }
  }
}

export default UserView;
</script>