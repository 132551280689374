<template>
  <div class="pool-stream stream">
    <div class="pool-stream-main">
      <div class="pool-stream-info">

        <div class="pool-stream-info-top">
          <div>
            <Checkbox v-model="isSelected" :binary="true" class="mr-3" />
            <h2 class="flex align-items-start">
              <span 
                class="tree-node-icon" 
                :class="getReadingStatusColorClass(readingStatus)"
                v-tippy="readingStatus"
              ></span>
              <a :href="buildStreamHref()">{{stream.Name}}</a>
            </h2>
          </div>

          <div>
            <span>Records&nbsp;<b>{{stream.Records ? stream.Records.toLocaleString() : '0'}}</b></span>
          </div>
        </div>

        <ul class="pool-stream-info-bottom">
          <li class="access-mode"><span v-tippy="'Access mode'">{{accessStr(stream.AccessMode)}} access</span></li>
          <li class="virtual-pool-stream"><span v-tippy="'Virtual stream'">Virtual stream {{stream.Virtual}}</span></li>
          <li class="post-processing"><span v-tippy="'ML processing'">ML processing {{stream.PostProcessingType ? "enabled" : "disabled"}}</span></li>
          <li class="data-type"><span v-tippy="'Data type'">{{streamDataTypeStr(stream.DataType)}} data type</span></li>
          <!-- <li class="archive-old-records"><span v-tippy="'Archive old records'">Archive old records {{stream.AllowArchiving}}</span></li> -->
          <li class="last-record-date"><span v-tippy="'Last record date'"><DateTimezoneView :date="stream.LastUpdates" :timezone="stream.TimeZone"/></span></li>
          <li class="timezone"><span v-tippy="'Timezone'"><StreamTimezoneView :timezone="stream.TimeZone"/></span></li>
        </ul>
      </div>

      <div class="pool-stream-actions">
        <div>
          <span class="view-stream">
            <a class="p-button p-button-sm use-link text-sm flex" :href="buildStreamHref()">
              <span class="p-button-label">View Stream</span>
              <span class="p-ink"></span>
            </a>
          </span>
          <span>
            <span>
              <Button label="Copy Key" @click="copyKey(stream.Id)" class="p-button-outlined p-button-sm text-sm" />
            </span>
            <span>
              <Button label="Remove" class="p-button-outlined p-button-sm text-sm p-button-danger" @click="openConfirmation" />
            </span>
          </span>
          <span class="pool-stream-show-more pb-0">
            <span @click="showDetails = !showDetails" class="link-dark" :class="{ 'is-open': showDetails }">
              Show More Details <i class="pi" :class="{ 'pi-chevron-up': showDetails, 'pi-chevron-down': !showDetails }"></i>
            </span>
          </span>
        </div>
      </div>
    </div>
    
    <transition name="p-toggleable-content">
      <div v-if="showDetails" class="pool-stream-additional">
        <div class="pool-stream-additional-inner">
          <div class="pool-stream-additional-btns">
            <Button label="Rename" class="p-button-outlined p-button-sm text-sm" @click="openRenameStream(stream.Name)" :disabled="!canEdit()" />
            <Button label="Merge" v-if="!stream.Virtual" class="p-button-outlined p-button-sm text-sm" @click="openMergeStream" :disabled="!canEdit()" />
            <Button label="Repair" class="p-button-outlined p-button-sm text-sm" @click="repairStream" />
            <Button :label="postProcessChangeButtonText(stream.PostProcessingType)" @click="changePostProcessType" class="p-button-outlined p-button-sm text-sm" />
            <!-- <Button :label="stream.AllowArchiving ? 'Deny Archiving' : 'Allow Archiving'" @click="allowArchiving" class="p-button-outlined p-button-sm text-sm" /> -->
            <Button label="Recalculate" @click="markForRecalculate" class="p-button-outlined p-button-sm text-sm" />
            <Button label="Clear Stream" v-if="!stream.Virtual" @click="openClearStream" class="p-button-outlined p-button-sm text-sm" />
          </div>
          <div class="pool-stream-tags mt-5">
            <h3>Tags</h3>
            <StreamTagsView :poolKey="poolKey" :streamKey="stream.Id" :tags="stream.Tags"/>
          </div>
        </div>
      </div>
    </transition>

    <Dialog header="Rename Stream" v-model:visible="displayRenameStream" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="field mb-0">
          <label for="">Enter new name</label>
          <div>
            <InputText
              class="inputfield p-inputtext p-inputtext-lg w-full"
              placeholder="Stream Name"
              type="text"
              v-model="newStreamName"
            />
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeRenameStream" class="p-button-text p-button-secondary"/>
        <Button label="Rename" icon="pi pi-check" @click="renameStream" :disabled='!newStreamName' />
      </template>
    </Dialog>
    
    <Dialog header="Confirmation" v-model:visible="displayConfirmation" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="flex align-items-center">
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete "<span class="break-word">{{streamLongName(stream)}}</span>" stream?</span>
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary"/>
        <Button label="Yes" icon="pi pi-check" @click="deleteStream" />
        <Button label="Yes, only from virtual pool" icon="pi pi-check" @click="deleteStreamFromVirtualPool" v-if="stream.PoolVirtual" />
      </template>
    </Dialog>

    <Dialog header="Merge Streams" v-model:visible="displayMergeStream" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="field">
          <label for="">Merge <span class="break-word">{{streamLongName(stream)}}</span> stream with</label>
          <div>
            <Dropdown 
              v-model="mergeStreamWith" 
              :options="allStreams" 
              :optionLabel="streamLongName" 
              placeholder="Select stream" 
              :filter="true" 
              filterPlaceholder="Find stream" 
              class="w-full dropdown-lg"
            >
              <template #option="slotProps">
                <div class="flex align-items-center">
                  <span 
                    class="mr-2 tree-node-icon" 
                    :class="getReadingStatus(slotProps.option) === 'Normal' ? 'bg-green-500' : 'bg-red-500'"
                  ></span>
                  <div>{{ slotProps.option.Name }}</div>
                </div>
              </template>
            </Dropdown>
          </div>
        </div>
        <div class="field-checkbox mb-0">
          <Checkbox v-model="removeStreamAfterMerge" :binary="true" id="removeStreamCheckbox" />
          <label for="removeStreamCheckbox">Remove stream after merging</label>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeMergeStream" class="p-button-text p-button-secondary"/>
        <Button label="Merge" icon="pi pi-check" @click="mergeStream" :disabled='!mergeStreamWith'/>
      </template>
    </Dialog>

    <Dialog header="Clear Stream" v-model:visible="displayClearStream" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div v-if="clearStreamInProgress">
          <ProgressBar mode="indeterminate" :showValue="false" />
        </div>
        <div class="flex align-items-center" v-else>
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete all records from <span class="break-word">{{streamLongName(stream)}}</span> stream?</span>
        </div>
      </div>
      <template #footer>
        <Button v-if="!clearStreamInProgress" label="No" icon="pi pi-times" @click="closeClearStream" class="p-button-text p-button-secondary"/>
        <Button v-if="!clearStreamInProgress" label="Yes" icon="pi pi-check" @click="clearStream"/>
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import InputText from 'primevue/inputtext';
import Checkbox from "primevue/checkbox";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import { StreamModel } from "@/models/StreamModel";
import StreamTagsView from "./StreamTagsView.vue";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import StreamTimezoneView from "./StreamTimezoneView.vue";
import { BitPoolUserRolesTypes } from "@/models/enums/BitPoolUserRolesTypes";
import copy from 'copy-to-clipboard';
import ToastService from "@/services/ToastService";
import { PropType } from "vue";
import { StreamModelLite } from "@/models/StreamModelLite";
import { PostProcessingType } from "@/models/enums/PostProcessingType";
import { StreamDataType } from "@/models/enums/StreamDataType";
import moment from "moment";
import { TimeZoneDto } from "@/models/TimeZoneDto";
import DataHelper from "@/helpers/DataHelper";

@Component({
  components: {
    Dropdown,
    ProgressSpinner,
    InputText,
    Checkbox,
    Button,
    Dialog,
    StreamTagsView,
    DateTimezoneView,
    StreamTimezoneView
  },
  directives: {   
  }
})
class StreamView extends Vue {  
  @Prop({ required: true }) poolKey!: string;
  @Prop({ required: true }) stream!: StreamModel;

  get allStreams(): StreamModelLite[] {
    const state = this.$store.state.pool.poolStreamsDictionary[this.poolKey];
    const streams = state && state.streamsList && state.streamsList.Streams ?
      state.streamsList.Streams as StreamModelLite[] :
      [];
    return streams;
  }

  get isSelected(): boolean {
    const index = this.selectedStreamKeys.findIndex((streamKey) => {
      return streamKey === this.stream.Id;
    });
    return index > -1;
  }

  set isSelected(value: boolean) {
    if (value) {
      this.$store.commit("pool/selectStream", { poolKey: this.poolKey, value: this.stream.Id });
    } else {
      this.$store.commit("pool/unselectStream", { poolKey: this.poolKey, value: this.stream.Id });
    }
  }

  get selectedStreamKeys(): string[] {
    const streamsState = this.$store.state.pool.poolStreamsDictionary[this.poolKey];
    return streamsState ? streamsState.selectedStreamKeys : [];
  }

  dateToMomentWithTimezone(date: Date | string | null): moment.Moment | null {
    if (date && date instanceof Date) {
      const timezone = this.timezoneModel;
      const utcOffset = timezone ? timezone.UtcOffset : 0;
      const m = moment(date).utc().add(utcOffset, "hours");
      return m;
    }
    return null;
  }

  get timezoneModel(): TimeZoneDto | undefined {
    const timezoneStr = this.stream?.TimeZone;
    const timezone = this.stream ? this.timezones.find((tz) => tz.Id === timezoneStr) : undefined;
    return timezone;
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }

  get readingStatus(): string {
    return DataHelper.readingStatus(this.stream);
  }

  getReadingStatus(stream: StreamModelLite | null): string {
    return DataHelper.readingStatusLite(stream);
  }
  
  getReadingStatusColorClass(status: string): string {
    return DataHelper.readingStatusColorClass(status);
  }

  showDetails = false;

  accessStr(value: BitPoolUserRolesTypes | number | string): string {
    return typeof value === "string" ? value : BitPoolUserRolesTypes[value];
  }

  canEdit(): boolean {
    return this.stream.AccessMode === BitPoolUserRolesTypes.Administrator || 
      this.stream.AccessMode === BitPoolUserRolesTypes.Owner ||
      this.stream.AccessMode === BitPoolUserRolesTypes.CoOwner;
  }

  buildStreamHref(): string {
    const href = this.stream.PoolVirtual ? `/data/streams/${this.stream.Id}?poolKey=${this.poolKey}` : `/data/streams/${this.stream.Id}`;
    return href;
  }

  // #region rename stream
  displayRenameStream = false;
  newStreamName = "";

  openRenameStream(name: string): void {
    this.newStreamName = name;
    this.displayRenameStream = true;
  }

  closeRenameStream(): void {
    this.displayRenameStream = false;
  }

  renameStream(): void {
    if (this.newStreamName) {
      this.closeRenameStream();
      const request = {
        poolKey: this.poolKey,
        streamKey: this.stream.Id,
        body: { Name: this.newStreamName, Description: null }
      };
      this.$store.dispatch("pool/rename", request);
    }
  }
  // #endregion rename stream

  // #region delete stream
  displayConfirmation = false;

  openConfirmation(): void {
    this.displayConfirmation = true;
  }

  closeConfirmation(): void {
    this.displayConfirmation = false;
  }

  deleteStream(): void {
    this.closeConfirmation();
    const request = {
      poolKey: this.poolKey,
      streamKey: this.stream.Id,
      reload: true
    };
    this.$store.dispatch("pool/delete", request);
  }

  deleteStreamFromVirtualPool(): void {
    this.closeConfirmation();
    const request = {
      poolKey: this.poolKey,
      body: [this.stream.Id],
      reload: true
    };
    this.$store.dispatch("pool/deleteFromVirtualPool", request);
  }
  // #endregion delete stream

  // #region merge stream
  displayMergeStream = false;
  mergeStreamWith: StreamModelLite | null = null;
  removeStreamAfterMerge = false;

  openMergeStream(): void {
    this.mergeStreamWith = null;
    this.displayMergeStream = true;
  }

  closeMergeStream(): void {
    this.displayMergeStream = false;
  }

  mergeStream(): void {
    if (this.mergeStreamWith) {
      this.closeMergeStream();
      const request = {
        poolKey: this.poolKey,
        streamKey: this.stream.Id,
        streamKeyTo: this.mergeStreamWith.Id,
        removeFrom: this.removeStreamAfterMerge
      };
      this.$store.dispatch("pool/mergeStreams", request);
    }
  }
  // #endregion merge stream

  repairStream(): void {
    const request = {
      poolKey: this.poolKey,
      streamKey: this.stream.Id
    };
    this.$store.dispatch("pool/repairStream", request);
  }

  streamDataTypeStr(value: StreamDataType | number | string): string {
    return typeof value === "string" ? value : StreamDataType[value];
  }

  // #region post process
  postProcessStr(value: PostProcessingType | number | string): string {
    return typeof value === "string" ? value : PostProcessingType[value];
  }

  postProcessChangeButtonText(value: PostProcessingType | number | string): string {
    return this.postProcessStr(value) === PostProcessingType[0] ? "Enable ML Processing" : "Disable ML Processing";
  }

  changePostProcessType(): void {
    const request = {
      poolKey: this.poolKey,
      streamKey: this.stream.Id,
      postProcessType: (this.stream.PostProcessingType === PostProcessingType.None || this.stream.PostProcessingType === PostProcessingType[0]) ? 
        PostProcessingType.SmoothData : PostProcessingType.None
    };
    this.$store.dispatch("pool/changePostProcessType", request);
  }
  // #endregion post process

  allowArchiving(): void {
    const request = {
      poolKey: this.poolKey,
      streamKey: this.stream.Id,
      allow: !this.stream.AllowArchiving
    };
    this.$store.dispatch("pool/allowArchiving", request);
  }

  markForRecalculate(): void {
    const request = {
      poolKey: this.poolKey,
      streamKey: this.stream.Id
    };
    this.$store.dispatch("pool/recalculateStream", request);
  }

  // #region clear stream
  displayClearStream = false;

  openClearStream(): void {
    this.clearStreamInProgress = false;
    this.displayClearStream = true;
  }

  closeClearStream(): void {
    this.displayClearStream = false;
  }

  clearStreamInProgress = false;

  async clearStream(): Promise<void> {
    this.clearStreamInProgress = true;
    const request = {
      poolKey: this.poolKey,
      streamKey: this.stream.Id
    };
    await this.$store.dispatch("pool/cleanStream", request);
    this.closeClearStream();
  }
  // #endregion clear stream

  streamLongName(stream: StreamModelLite | StreamModel): string {
    return `${stream.Name} [${stream.Id}]`;
  }

  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }
}

export default StreamView;
</script>