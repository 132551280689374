import DateHelper from "@/helpers/DateHelper";
import { StreamsListModelLite } from "@/models/StreamsListModelLite";
import { StreamsListModel } from "@/models/StreamsListModel";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import StreamsSearchPageQuery from "@/models/StreamsSearchPageQuery";
import { v4 as uuidv4 } from "uuid";
import EditStreamTagsRequest from "@/models/EditStreamTagsRequest";
import { StreamModelLite } from "@/models/StreamModelLite";
import RenameStreamRequest from "@/models/RenameStreamRequest";
import DeleteStreamRequest from "@/models/DeleteStreamRequest";
import { VirtualPoolEditRequest } from "@/models/VirtualPoolEditRequest";
import MergeStreamsRequest from "@/models/MergeStreamsRequest";
import { PoolStreamRequest } from "@/models/PoolStreamRequest";
import { ChangePostProcessRequest } from "@/models/ChangePostProcessRequest";
import { AllowArchivingRequest } from "@/models/AllowArchivingRequest";
import ErrorHelper from "@/helpers/ErrorHelper";
import { StreamDataType } from "@/models/enums/StreamDataType";
import { PostProcessingType } from "@/models/enums/PostProcessingType";
import { StreamModel } from "@/models/StreamModel";
import StreamsState from "../states/StreamsState";
import PoolState from "../states/PoolState";
import { StreamVirtualType } from "@/models/enums/StreamVirtualType";

const getters = <GetterTree<PoolState, any>>{};

const mutations = <MutationTree<PoolState>>{
  unload(state, poolKey: string) {
    delete state.poolStreamsDictionary[poolKey];
  },
  selectStream(state, { poolKey, value }) {
    const streamsState = state.poolStreamsDictionary[poolKey];
    if (streamsState) {
      streamsState.selectedStreamKeys.push(value);
    }
  },
  unselectStream(state, { poolKey, value }) {
    const streamsState = state.poolStreamsDictionary[poolKey];
    if (streamsState) {
      const index = streamsState.selectedStreamKeys.findIndex((streamKey) => {
        return streamKey === value;
      });
      if (index > -1) {
        streamsState.selectedStreamKeys.splice(index, 1);
      }
    }
  },
  selectManyStreams(state, { poolKey, values }) {
    const streamsState = state.poolStreamsDictionary[poolKey];
    if (streamsState) {
      streamsState.selectedStreamKeys = [
        ...new Set(streamsState.selectedStreamKeys.concat(values)),
      ];
    }
  },
  selectNoneStreams(state, poolKey) {
    const streamsState = state.poolStreamsDictionary[poolKey];
    if (streamsState) {
      streamsState.selectedStreamKeys = [];
    }
  },
};

const actions = <ActionTree<PoolState, any>>{
  async loadStreamsList({ state }, poolKey: string) {
    try {
      if (!state.poolStreamsDictionary[poolKey]) {
        state.poolStreamsDictionary[poolKey] = new StreamsState();
      }
      const streamsState = state.poolStreamsDictionary[poolKey];
      const guid = uuidv4();
      streamsState.guidList = guid;
      streamsState.isLoadedList = false;
      streamsState.isLoadingList = true;
      if (streamsState.streamsList) {
        streamsState.streamsList.Streams = [];
      }
      const response = await axios.get<StreamsListModelLite>(
        `rest/BitPool_V1/pool/${poolKey}/streams/web-lite`
      );
      if (streamsState.guidList === guid) {
        if (
          response.data &&
          response.data.Streams &&
          response.data.Streams.length
        ) {
          response.data.Streams = response.data.Streams.sort(
            (a: StreamModelLite, b: StreamModelLite) => {
              if (a.Name < b.Name) {
                return -1;
              }
              if (a.Name > b.Name) {
                return 1;
              }
              return 0;
            }
          );
          response.data.Streams.forEach((stream) => {
            if (stream.LastUpdate) {
              stream.LastUpdate = DateHelper.parseFromMicrosoftString(
                stream.LastUpdate as string
              );
            }
          });
        }
        streamsState.streamsList = response.data;
        streamsState.isLoadedList = true;
        streamsState.isLoadingList = false;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load streams",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async loadStreamsPage({ state }, query: StreamsSearchPageQuery) {
    try {
      const poolKey = query.poolKey;
      if (!state.poolStreamsDictionary[poolKey]) {
        state.poolStreamsDictionary[poolKey] = new StreamsState();
      }
      const streamsState = state.poolStreamsDictionary[poolKey];
      const guid = uuidv4();
      streamsState.guidPage = guid;
      streamsState.pageQuery = query;
      streamsState.isLoadedPage = false;
      if (streamsState.streamsPage) {
        streamsState.streamsPage.Streams = [];
      }
      const url = `rest/BitPool_V1/pool/${query.poolKey}/streams/web-search?search=${query.search}&orderBy=${query.orderBy}&desc=${query.desc}&take=${query.take}&skip=${query.skip}`;
      const response = await axios.get<StreamsListModel>(url);
      if (streamsState.guidPage === guid) {
        if (
          response.data &&
          response.data.Streams &&
          response.data.Streams.length
        ) {
          response.data.Streams.forEach((stream) => {
            if (stream.LastUpdates) {
              stream.LastUpdates = DateHelper.parseFromMicrosoftString(
                stream.LastUpdates as string
              );
            }
            if (stream.FirstUpdates) {
              stream.FirstUpdates = DateHelper.parseFromMicrosoftString(
                stream.FirstUpdates as string
              );
            }
            if (stream.Tags && stream.Tags.length) {
              stream.Tags = stream.Tags.sort();
            }
            if (typeof stream.DataType === "string") {
              stream.DataType =
                StreamDataType[stream.DataType as keyof typeof StreamDataType];
            }
            if (typeof stream.PostProcessingType === "string") {
              stream.PostProcessingType =
                PostProcessingType[
                  stream.PostProcessingType as keyof typeof PostProcessingType
                ];
            }
            if (typeof stream.VirtualType === "string") {
              stream.VirtualType =
                StreamVirtualType[
                  stream.VirtualType as keyof typeof StreamVirtualType
                ];
            }
          });
        }
        streamsState.streamsPage = response.data;
        streamsState.isLoadedPage = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load streams",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async refreshStream({ state, commit }, request: PoolStreamRequest) {
    try {
      const streamsState = state.poolStreamsDictionary[request.poolKey];
      const url = `rest/BitPool_V1/stream/${request.streamKey}/web`;
      const response = await axios.get<StreamModel>(url);
      if (response.data) {
        if (response.data.LastUpdates) {
          response.data.LastUpdates = DateHelper.parseFromMicrosoftString(
            response.data.LastUpdates as string
          );
        }
        if (response.data.FirstUpdates) {
          response.data.FirstUpdates = DateHelper.parseFromMicrosoftString(
            response.data.FirstUpdates as string
          );
        }
        if (response.data.Tags && response.data.Tags.length) {
          response.data.Tags = response.data.Tags.sort();
        }
        if (typeof response.data.DataType === "string") {
          response.data.DataType =
            StreamDataType[
              response.data.DataType as keyof typeof StreamDataType
            ];
        }
        if (typeof response.data.PostProcessingType === "string") {
          response.data.PostProcessingType =
            PostProcessingType[
              response.data
                .PostProcessingType as keyof typeof PostProcessingType
            ];
        }
        if (typeof response.data.VirtualType === "string") {
          response.data.VirtualType =
            StreamVirtualType[
              response.data.VirtualType as keyof typeof StreamVirtualType
            ];
        }
        if (
          streamsState &&
          streamsState.isLoadedPage &&
          streamsState.streamsPage &&
          streamsState.streamsPage.Streams
        ) {
          for (const stream of streamsState.streamsPage.Streams) {
            if (stream.Id === response.data.Id) {
              if (stream.LastUpdates !== response.data.LastUpdates) {
                stream.LastUpdates = response.data.LastUpdates;
              }
              if (stream.Records !== response.data.Records) {
                stream.Records = response.data.Records;
              }
              stream.Tags = response.data.Tags;
              if (stream.LastValue !== response.data.LastValue) {
                stream.LastValue = response.data.LastValue;
              }
              break;
            }
          }
        }
        commit("stream/refreshStream", response.data, { root: true });
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async changeTags({ state, commit }, request: EditStreamTagsRequest) {
    try {
      const url = `public/v3/streams/tags/change`;
      const response = await axios.post<Record<string, string[]>>(
        url,
        request.body
      );
      if (response && response.data) {
        const streamsState = state.poolStreamsDictionary[request.poolKey];
        if (streamsState) {
          for (const key in response.data) {
            if (
              streamsState.streamsPage &&
              streamsState.streamsPage.Streams &&
              streamsState.streamsPage.Streams.length
            ) {
              const stream = streamsState.streamsPage.Streams.find((stream) => {
                return stream.Id === key;
              });
              if (stream) {
                stream.Tags = response.data[key].sort();
              }
            }
          }
        }
        request.body.StreamKeys.forEach((streamKey) => {
          if (response.data[streamKey]) {
            commit(
              "stream/updateTags",
              { streamKey: streamKey, tags: response.data[streamKey] },
              { root: true }
            );
          }
        });
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't change tags",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async rename({ state }, request: RenameStreamRequest) {
    try {
      const url = `public/v2/streams/${request.streamKey}`;
      await axios.put(url, request.body);
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (
        streamsState &&
        streamsState.streamsPage &&
        streamsState.streamsPage.Streams &&
        streamsState.streamsPage.Streams.length
      ) {
        const stream = streamsState.streamsPage.Streams.find((stream) => {
          return stream.Id === request.streamKey;
        });
        if (stream) {
          stream.Name = request.body.Name;
        }
      }
      if (
        streamsState &&
        streamsState.streamsList &&
        streamsState.streamsList.Streams &&
        streamsState.streamsList.Streams.length
      ) {
        const stream = streamsState.streamsList.Streams.find((stream) => {
          return stream.Id === request.streamKey;
        });
        if (stream) {
          stream.Name = request.body.Name;
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't rename stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async delete({ state, dispatch }, request: DeleteStreamRequest) {
    try {
      const url = `public/v2/streams/${request.streamKey}`;
      await axios.delete(url);
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (
        streamsState &&
        streamsState.streamsList &&
        streamsState.streamsList.Streams &&
        streamsState.streamsList.Streams.length
      ) {
        const streamArrayIndex = streamsState.streamsList.Streams.findIndex(
          (stream) => {
            return stream.Id === request.streamKey;
          }
        );
        if (streamArrayIndex > -1) {
          streamsState.streamsList.Streams.splice(streamArrayIndex, 1);
        }
      }
      if (streamsState && streamsState.isLoadedPage && request.reload) {
        await dispatch("loadStreamsPage", streamsState.pageQuery);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async deleteFromVirtualPool(
    { state, dispatch },
    request: VirtualPoolEditRequest
  ) {
    try {
      const url = `rest/BitPool_V1/pool/${request.poolKey}/detach/streams`;
      await axios.post(url, request.body);
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (streamsState && streamsState.isLoadedList) {
        await dispatch("loadStreamsList", request.poolKey);
      }
      if (streamsState && streamsState.isLoadedPage) {
        await dispatch("loadStreamsPage", streamsState.pageQuery);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete stream from virtual pool",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async addToVirtualPool({ state, dispatch }, request: VirtualPoolEditRequest) {
    try {
      state.inProgressAddStreamToVirtualPool = true;
      state.errorAddStreamToVirtualPool = false;
      const url = `rest/BitPool_V1/pool/${request.poolKey}/attach/streams`;
      await axios.post(url, request.body);
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (streamsState && streamsState.isLoadedList) {
        await dispatch("loadStreamsList", request.poolKey);
      }
      if (streamsState && streamsState.isLoadedPage) {
        await dispatch("loadStreamsPage", streamsState.pageQuery);
      }
      state.inProgressAddStreamToVirtualPool = false;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't add stream to virtual pool",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.inProgressAddStreamToVirtualPool = false;
      state.errorAddStreamToVirtualPool = true;
    }
  },
  async mergeStreams({ state, dispatch }, request: MergeStreamsRequest) {
    try {
      const url = `rest/BitPool_V1/stream/${request.streamKey}/mergewith/${request.streamKeyTo}?removeFrom=${request.removeFrom}`;
      await axios.post(url, null, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (streamsState && streamsState.isLoadedList) {
        await dispatch("loadStreamsList", request.poolKey);
      }
      if (streamsState && streamsState.isLoadedPage) {
        await dispatch("loadStreamsPage", streamsState.pageQuery);
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't update pool",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async repairStream({ state, dispatch }, request: PoolStreamRequest) {
    try {
      const url = `rest/BitPool_V1/stream/${request.streamKey}/repair`;
      await axios.post(url, null, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (streamsState && streamsState.isLoadedPage) {
        await dispatch("loadStreamsPage", streamsState.pageQuery);
      }
      ToastService.showToast("success", "", "Stream repaired", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't repair stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async changePostProcessType(
    { state, commit },
    request: ChangePostProcessRequest
  ) {
    try {
      const url = `rest/BitPool_V2/stream/${request.streamKey}/ChangePostProcessingType`;
      await axios.post(url, request.postProcessType, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (
        streamsState &&
        streamsState.streamsPage &&
        streamsState.streamsPage.Streams &&
        streamsState.streamsPage.Streams.length
      ) {
        const stream = streamsState.streamsPage.Streams.find((stream) => {
          return stream.Id === request.streamKey;
        });
        if (stream) {
          stream.PostProcessingType = request.postProcessType;
        }
      }
      commit(
        "stream/updatePostProcessingType",
        {
          streamKey: request.streamKey,
          postProcessingType: request.postProcessType,
        },
        { root: true }
      );
      ToastService.showToast("success", "", "Changes saved", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't change ML Processing type",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async allowArchiving({ state, commit }, request: AllowArchivingRequest) {
    try {
      const url = `rest/BitPool_V2/stream/${request.streamKey}/AllowArchiving`;
      await axios.post(url, request.allow, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (
        streamsState &&
        streamsState.streamsPage &&
        streamsState.streamsPage.Streams &&
        streamsState.streamsPage.Streams.length
      ) {
        const stream = streamsState.streamsPage.Streams.find((stream) => {
          return stream.Id === request.streamKey;
        });
        if (stream) {
          stream.AllowArchiving = request.allow;
        }
      }
      commit(
        "stream/updateAllowArchiving",
        { streamKey: request.streamKey, allow: request.allow },
        { root: true }
      );
      ToastService.showToast("success", "", "Changes saved", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't repair stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async recalculateStream({ state, commit }, request: PoolStreamRequest) {
    try {
      const url = `rest/BitPool_V2/stream/${request.streamKey}/Recalculate`;
      await axios.post(url, null, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (
        streamsState &&
        streamsState.streamsPage &&
        streamsState.streamsPage.Streams &&
        streamsState.streamsPage.Streams.length
      ) {
        const stream = streamsState.streamsPage.Streams.find((stream) => {
          return stream.Id === request.streamKey;
        });
        if (stream) {
          stream.Recalculate = true;
        }
      }
      commit(
        "stream/updateRecalculate",
        { streamKey: request.streamKey, recalculate: true },
        { root: true }
      );
      ToastService.showToast("success", "", "Changes saved", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't repair stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async cleanStream({ state, dispatch }, request: PoolStreamRequest) {
    try {
      const url = `rest/BitPool_V2/stream/${request.streamKey}/CleanStream`;
      await axios.post(url, null, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      const streamsState = request.poolKey
        ? state.poolStreamsDictionary[request.poolKey]
        : null;
      if (streamsState && streamsState.isLoadedPage) {
        await dispatch("loadStreamsPage", streamsState.pageQuery);
      }
      ToastService.showToast("success", "", "Stream cleared", 5000);
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't clear stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
};

const PoolModule = {
  namespaced: true,
  state: new PoolState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default PoolModule;
