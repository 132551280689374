<template>
  <div>
    <DataTable
      :value="state.configs" 
      v-model:expandedRows="expandedRows" 
      dataKey="Id"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint haystack-table">
      <template #header>
        <div class="table-header">
          <Button label="Add Configuration" icon="pi pi-plus-circle" class="my-1" @click="openCreateUpdateDialog(null)" />
        </div>
      </template>
      <template #empty>
        <div v-if="state.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 48px;" bodyClass="no-label-in-stack">
        <template #body="slotProps">
          <div class="inline-flex expand-toggler-btn">
            <Button :icon="(preCloseState.findIndex(x => x === slotProps.data.Id) >= 0 || !expandedRows[slotProps.data.Id]) ? 'pi pi-chevron-right' : 'pi pi-chevron-down'" class="p-button-icon-only p-button-rounded p-button-text p-button-secondary" @click="toggleRow(slotProps.data)" />
          </div>
        </template>
      </Column>
      <Column field="Enabled" header="Enabled" headerStyle="width: 1%; min-width: 65px;">
        <template #body="slotProps">
          <div>
            {{ slotProps.data.Enabled ? 'Yes' : 'No' }}
          </div>
        </template>
      </Column>
      <Column field="Name" header="Name"></Column>
      <Column field="Endpoint" header="Endpoint" headerStyle="width: 43%;"></Column>
      <Column field="Organisation" header="Organisation"></Column>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 128px;" bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <div>
            <div class="inline-flex" v-tippy="{ content: (currentOrganisation?.Name === slotProps.data.Organisation && (userId === slotProps.data.UserId || authState.permissions && authState.permissions.FullAccess)) ? '' :(userId === slotProps.data.UserId || authState.permissions && authState.permissions.FullAccess) ? `Please switch to '${slotProps.data.Organisation}' organisation` : 'Only Owner and CoOwner can edit this record' }">
              <Button 
                icon="pi pi-book" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                @click="openLogsDialog(slotProps.data)"
                :disabled="currentOrganisation?.Name !== slotProps.data.Organisation || (userId !== slotProps.data.UserId && !(authState.permissions && authState.permissions.FullAccess))"
              />
              <Button 
                icon="pi pi-pencil" 
                class="p-button-icon-only p-button-rounded p-button-outlined mr-2" 
                @click="openCreateUpdateDialog(slotProps.data)" 
                :disabled="currentOrganisation?.Name !== slotProps.data.Organisation || (userId !== slotProps.data.UserId && !(authState.permissions && authState.permissions.FullAccess))"
              />
              <Button 
                icon="pi pi-trash" 
                class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                @click="openConfirmation(slotProps.data)" 
                :disabled="currentOrganisation?.Name !== slotProps.data.Organisation || (userId !== slotProps.data.UserId && !(authState.permissions && authState.permissions.FullAccess))"
              />
            </div>
          </div>
        </template>
      </Column>
      <template #expansion="slotProps">
        <transition name="p-toggleable-content" appear>
          <ul class="haystack-table-other-fields" v-if="preCloseState.findIndex(x => x === slotProps.data.Id) < 0">
            <li class="pt-3">
              <span><b>Login</b></span>
              <span><span class="break-word">{{slotProps.data.Login}}</span></span>
            </li>
            <li class="pb-3" v-if="slotProps.data.Password">
              <span><b>Password</b></span>
              <span class="flex align-items-center"><HiddenTextView :text="slotProps.data.Password"/></span>
            </li>                                       
          </ul>
        </transition>
      </template>
    </DataTable>
    <Dialog header="Haystack Client Configuration" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="haystack-config-dialog">
      <div class="dialog-content" v-if="editRecord">
        <BlockUI :blocked="testInProgress || updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="(testInProgress || updateInProgress) ? 'blockui-blocked' : ''">
          <div class="formgrid grid">
            <div class="field col-fixed mr-5 pt-1">
              <div class="flex align-items-center">
                <InputSwitch 
                  inputId="editRecordClean"
                  v-model="editRecord.Enabled"
                  class="vertical-align-top"
                />
                <label for="editRecordClean" class="mb-0 ml-2">Enabled</label>
              </div>
            </div>
            <div class="field col-12">
              <label for="editRecordName">Name</label>
              <div>
                <InputText 
                  id="editRecordName" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Name"
                />
              </div>
            </div>
            <div class="field col-12">
              <label for="editRecordEndpoint">Endpoint</label>
              <div>
                <InputText 
                  id="editRecordEndpoint" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Endpoint"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="editRecordLogin">Login</label>
              <div>
                <InputText 
                  id="editRecordLogin" 
                  class="inputfield p-inputtext-lg w-full"
                  type="text" 
                  v-model="editRecord.Login"
                />
              </div>
            </div>
            <div class="field col-12 md:col-6">
              <label for="editRecordPassword">Password</label>
              <div>
                <Password
                  id="editRecordPassword"
                  class="w-full"
                  inputClass="inputfield p-inputtext-lg w-full"
                  v-model="editRecord.Password"
                  :feedback="false"
                  toggleMask
                />
              </div>
            </div>
            <div class="field col-12 mb-0">
              <InlineMessage class="m-0" severity="warn">Test the Endpoint before clicking {{editRecord.Id ? '"Update"' : '"Create"'}}</InlineMessage>
            </div>
          </div>

          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #footer>
        <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
          <span class="block">
            <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
          </span>
          <span class="block ml-2">
            <Button 
              v-if="editRecord" 
              label="Test" 
              class="p-button-outlined" 
              :icon="testInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-play'" 
              @click="testEndpoint" 
              :disabled='!editRecord.Endpoint || !editRecord.Name || testInProgress || updateInProgress' 
            />
            <Button 
              v-if="editRecord"
              class="ml-2"
              :label="editRecord.Id ? 'Update' : 'Create'" 
              :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
              @click="saveRecord" 
              :disabled='!editRecord.Endpoint || !editRecord.Name || testInProgress || updateInProgress || (!testedEndpoint && editRecord.Enabled)' 
            />
          </span>
        </div>
      </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="displayConfirmation" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="flex align-items-center">
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete record?</span>
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary"/>
        <Button label="Yes" icon="pi pi-check" @click="deleteRecord" />
      </template>
    </Dialog>

    <Dialog header="Logs" v-model:visible="displayLogsDialog" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" class="connection-history-config-dialog">
      <div class="dialog-content" v-if="selectedRecord">
        <ConnectionHistoryView v-model="selectedRecord.Id" :type="logsType"/>
      </div>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import Password from 'primevue/password';
import Dropdown from 'primevue/dropdown';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import InlineMessage from 'primevue/inlinemessage';
import ProgressSpinner from 'primevue/progressspinner';
import { Watch } from "vue-facing-decorator";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import HiddenTextView from "@/components/views/HiddenTextView.vue";
import CustomWindow from '@/CustomWindow';
import HaystackClientState from "@/stores/states/HaystackClientState";
import { HaystackClientConfig } from "@/models/HaystackClientConfig";
import AuthState from "@/store/states/AuthState";
import { useOrganisationStore } from "@/stores/organisation";
import useHaystackClientStore from "@/stores/haystackClient";
import { ConnectionHistoryType } from "@/models/connection/ConnectionHistoryType";
import ConnectionHistoryView from "@/components/views/connections/ConnectionHistoryView.vue";

declare const window: CustomWindow;

@Component({
  
  components: {
    Button,
    InputText,
    Password,
    Dropdown,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    InlineMessage,
    ProgressSpinner,
    HiddenTextView,
    ConnectionHistoryView
  },
  directives: {
  }
})
class HaystackClientView extends Vue { 
  haystackClient = useHaystackClientStore();

  get state(): HaystackClientState {
    return this.haystackClient;
  }

  get userId(): string {
    const userData = this.allUserData;
    const result = userData && userData.userData ? userData.userData.UserId : "";
    return result;
  }

  expandedRows: Record<string, boolean> = {};
  preCloseState: string[] = [];

  toggleRow(row: HaystackClientConfig): void {
    if (this.expandedRows[row.Id]) {
      // close
      this.preCloseState.push(row.Id);
      window.setTimeout(() => {
        const idIndex = this.preCloseState.findIndex(x => x === row.Id);
        if (idIndex >= 0 && this.expandedRows[row.Id]) {
          const newExpandedRows = { ...this.expandedRows };
          delete newExpandedRows[row.Id];
          this.expandedRows = newExpandedRows;
          this.preCloseState.splice(idIndex, 1);
        }
      }, 450);
    } else {
      // open
      const idIndex = this.preCloseState.findIndex(x => x === row.Id);
      if (idIndex >= 0) {
        this.preCloseState.splice(idIndex, 1);
      }
      this.expandedRows = { ...this.expandedRows, [row.Id]: true };
    }
  }

  created(): void {
    this.haystackClient.load();
    
  }

  // #region new/edit
  displayCreateUpdateDialog = false;
  editRecord: HaystackClientConfig | null = null;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  openCreateUpdateDialog(record: HaystackClientConfig | null): void {
    this.testedEndpoint = false;
    this.editRecord = record ? Object.assign({}, record) : {
      Id: "",
      Endpoint: "",
      Login: "",
      Organisation: this.currentOrganisation ? this.currentOrganisation.Name : "",
      UserId: this.allUserData.userData.UserId,
      Password: "",
      Pool: "",
      PoolKey: "",
      Enabled: true,
      Name: ""
    };
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.state.updateInProgress;
  }

  get updateError(): boolean {
    return this.state.updateError;
  }

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      await this.haystackClient.createUpdate(this.editRecord);
      if (!this.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }

  get testInProgress(): boolean {
    return this.state.testInProgress;
  }

  get testError(): boolean {
    return this.state.testError;
  }

  testedEndpoint = false;

  @Watch('editRecord', { immediate: false, deep: true })
  onChangeEditRecord(val: HaystackClientConfig | null, oldVal: HaystackClientConfig | null): void {
    this.testedEndpoint = false;
  }

  async testEndpoint(): Promise<void> {
    if (this.editRecord) {
      await this.haystackClient.test(this.editRecord);
      if (!this.testError) {
        this.testedEndpoint = true; 
      }
    }
  }
  // #endregion new/edit

  // #region delete
  displayConfirmation = false;
  selectedRecord: HaystackClientConfig | null = null;

  openConfirmation(record: HaystackClientConfig | null): void {
    this.selectedRecord = record;
    this.displayConfirmation = true;
  }

  closeConfirmation(): void {
    this.displayConfirmation = false;
  }

  deleteRecord(): void {
    this.closeConfirmation();
    if (this.selectedRecord) {
      this.haystackClient.delete(this.selectedRecord.Id);
    }
  }
  // #endregion delete

  // #region logs
  displayLogsDialog = false;
  logsType = ConnectionHistoryType.HaystackExternal;

  openLogsDialog(record: HaystackClientConfig | null): void {
    this.selectedRecord = record;
    this.displayLogsDialog = true;
  }
  // #endregion logs
}

export default HaystackClientView;
</script>