<template>
  <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index" :class="updateInProgress ? 'blockui-blocked' : ''">
    <h2 class="account-settings-title hidden xl:block">{{ t('accountSettings.account.title') }}</h2>
    <p class="account-settings-description hidden xl:block">{{ t('accountSettings.account.subTitle') }}</p>

    <section>
      <h3>{{ t('accountSettings.account.profileImage') }}</h3>
      <div class="flex align-items-center">
        <Avatar v-if="avatarUrl" :image="avatarUrl" size="large" />
        <Avatar v-else :label="shortName(fullUsername)" size="large" />

        <div class="flex flex-column row-gap-2">
          <FileUpload 
              mode="basic" 
              name="files[]" 
              :url="uploadUrl" 
              @before-send="beforeSend" 
              @upload="onUpload" @error="onError" 
              :multiple="false"
              :auto="true" 
              accept="image/*" 
              :withCredentials="true" 
              chooseIcon="pi pi-plus-circle"
              :maxFileSize="500000"
              :chooseLabel="t('accountSettings.account.uploadPicture')"
            >
          </FileUpload>
          <Button 
            :label="t('accountSettings.account.removePicture')" 
            class="p-button-outlined p-button-danger delete-avatar"
            icon="pi pi-trash" 
            @click="removeAvatar" 
            :disabled="!avatar"
          />
        </div>
      </div>
    </section>
    <section class="xl:mb-0">
      <h3>{{ t('accountSettings.account.profileName') }}</h3>
      <div class="formgrid grid">
        <div class="field col-12 md:col-6 md:mb-0">
          <label for="firstname">{{ t('accountSettings.account.firstName') }}</label>
          <div>
            <InputText
              class="inputfield w-full"
              type="text"
              id="firstname"
              v-model="firstname"
              @keyup="userDataChange"
            />
          </div>
        </div>
        <div class="field col-12 md:col-6 mb-0">
          <label for="firstname">{{ t('accountSettings.account.lastName') }}</label>
          <div>
            <InputText
              class="inputfield w-full"
              type="text"
              id="lastname"
              v-model="lastname"
              @keyup="userDataChange"
            />
          </div>
        </div>
      </div>
    </section>
    <div class="block md:flex align-items-center justify-content-end account-settings-button-container">
      <Button 
        @click="closeDialog" 
        :label="t('common.close')" 
        icon="pi pi-times" 
        class="hidden md:inline-flex p-button-text p-button-secondary mr-2"
      />
      <Button 
        :label="t('common.saveChanges')" 
        :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-save'" 
        @click="saveAll" 
        class="account-settings-save-button"
        :disabled='updateInProgress || !isUserDataChaged' 
      />
    </div>

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import Avatar from 'primevue/avatar';
import FileUpload, { FileUploadBeforeSendEvent, FileUploadErrorEvent } from "primevue/fileupload";
import UserSvg from "@/components/svg/UserSvg.vue";
import AuthState from "@/store/states/AuthState";
import { AllUserData } from "@/models/user/AllUserData";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    InputText,
    Button,
    BlockUI,
    ProgressSpinner,
    Avatar,
    FileUpload,
    UserSvg
  },
})
class UserSettingsAccountView extends Vue {
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  get auth(): AuthState {
    return this.$store.state.auth;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get avatarUrl(): string {
    const apiUrl = this.$store.state.apiUrl;
    return this.avatar ? `${apiUrl}/rest/AWS_S3_V1/File/${this.avatar}` : "";
  }

  get fullUsername(): string {
    return this.$store.getters["auth/getFullUsername"];
  }

  shortName(fullname: string | null): string {
    return fullname ? fullname.split(" ").map(x => x[0]).join("") : "";
  }
  
  isUserDataChaged = false;
  firstname = "";
  lastname = "";
  avatar = "";
  updateInProgress = false;

  created(): void {
    this.firstname = this.allUserData.userData.Firstname;
    this.lastname = this.allUserData.userData.Lastname;
    this.avatar = this.allUserData.userData.Avatar;
  }

  userDataChange(): void {
    this.isUserDataChaged = true;
  }

  async saveAll(): Promise<boolean> {
    this.updateInProgress = true;
    let isOk = true;
    try {
      // check what need to save and call api
      if (this.isUserDataChaged) {
        // save user additional data
        this.allUserData.userData.Firstname = this.firstname;
        this.allUserData.userData.Lastname = this.lastname;
        this.allUserData.userData.Avatar = this.avatar;
        await this.$store.dispatch("auth/saveUserAdditionalData");
        if (this.auth.userAdditionalDataSaveError) {
          isOk = false;
        } else {
          this.isUserDataChaged = false;
        }
      }
      if (isOk) {
        ToastService.showToast("success", "", this.t("common.changesSaved"), 5000);
      }
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        this.t("common.cantSaveChanges"),
        errorMessage,
        5000
      );
    } finally {
      this.updateInProgress = false;
    }
    return isOk;
  }

  removeAvatar(): void {
    this.avatar = "";
    this.userDataChange();
  }

  // #region upload
  get uploadUrl() {
    return `${this.$store.state.apiUrl}/rest/AWS_S3_V1/File`;
  }

  // Callback to invoke before file send begins to customize the request such as adding headers.
  beforeSend(event: FileUploadBeforeSendEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.formData: FormData object.
    event.xhr.setRequestHeader("Authorization", this.$store.state.auth.authKey);
    event.xhr.setRequestHeader("Accept", "application/json");
  }

  // Callback to invoke when file upload is complete.
  onUpload(event: FileUploadErrorEvent): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Uploaded files.
    const response = JSON.parse(event.xhr.response);
    if (response && response.length) {
      this.avatar = response[0];
      this.userDataChange();
    }
    ToastService.showToast("success", "", this.t("common.uploadComplete"), 5000);
  }

  // Callback to invoke if file upload fails.
  onError(event: any): void {
    // event.xhr: XmlHttpRequest instance.
    // event.files: Files that are not uploaded.
    ToastService.showToast("error", this.t("common.cantUploadFile"), ErrorHelper.handleAxiosError(event.xhr).message, 5000);
  }
  // #endregion upload

  @Emit
  closeDialog(): void {
    // nothing to do, just emit an event
  }
}

export default UserSettingsAccountView;
</script>