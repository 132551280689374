<template>
  <div ng-non-bindable class="mini-layout hide-nav-menu">
    <DebugView/>

    <div class="page with-header legal-page">
      <!-- Page header -->
      <header class="page-header is-fixed no-print">
        <div>
          <a href="/">
            <img
              :src="'/assets/bitpool-logo-white.svg'"
              width="134"
              alt="Bitpool"
            />
          </a>
        </div>
      </header>
      
      <div class="page-container">
        <main>
          <div>
            <div class="progress-spinner-container">
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AngularOptions from "@/models/AngularOptions";
import Button from 'primevue/button';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import DebugView from "@/components/views/DebugView.vue";
import Ripple from 'primevue/ripple';
import { useAuthStore } from "@/stores/auth";
import ProgressSpinner from 'primevue/progressspinner';
import NavigationHelper from "@/helpers/NavigationHelper";
import ToastService from "@/services/ToastService";
import { usePageStore } from "@/stores/page";

@Component({
  components: {
    Button,
    TabView,
    TabPanel,
    DebugView,
    ProgressSpinner
  },
  directives: {
    'ripple': Ripple
  }
})
class AuthCallbackPage extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;
  
  authStore = useAuthStore();
  pageStore = usePageStore();

  created(): void {
    this.pageStore.$reset();
    this.goStep2();
  }

  async goStep2(): Promise<void> {
    const angularCredentials = await this.authStore.goExternalStep2(location.search);
    const authKey = angularCredentials.authKey;
    delete angularCredentials.authKey;
    if (authKey === "no-organisation") {
      this.$store.commit("login/setDisplaySection", "no-organisation");
      NavigationHelper.goTo("/login");
      ToastService.showToast(
        "error",
        "Can't login",
        "You are not a member of any organisation.",
        5000
      );
    } else {
      this.angularOptions.angularScope.processLogin(angularCredentials, authKey);
    }
  }
}

export default AuthCallbackPage;
</script>
