<template>
  <div>
    <div id="walkthrough-steps" class="walkthrough-steps" style="display: none">
      <!-- Step 1 -->
      <div class="walkthrough-step-content walkthrough-step-1-content">
        <div class="walkthrough-step-content-img">
          <img src="/assets/walkthrough/welcome.png" alt="Welcome to Bitpool" />
        </div>
        <div class="walkthrough-step-content-text">
          <p class="walkthrough-step-content-first-line">Hi there,</p>
          <h3>Welcome to Bitpool!</h3>
          <p>
            Congratulations on taking the first step towards tracking the
            performance of your building.
          </p>
        </div>
      </div>

      <!-- Step 2 -->
      <div class="walkthrough-step-content walkthrough-step-2-content">
        <div class="walkthrough-step-content-img">
          <img
            src="/assets/walkthrough/organisations.gif"
            alt="Organisations"
          />
        </div>
        <div class="walkthrough-step-content-text">
          <p class="walkthrough-step-content-first-line">
            Some terms to get started,
          </p>
          <h3>Organisations</h3>
          <p>
            Used to organise and maintain a portfolio. Organisations contain
            members, dashboard and data. Use to edit permissions, dashboards and
            manage data.
          </p>
        </div>
      </div>

      <!-- Step 3 -->
      <div class="walkthrough-step-content walkthrough-step-3-content">
        <div class="walkthrough-step-content-img">
          <img src="/assets/walkthrough/dashboards.gif" alt="Dashboards" />
        </div>
        <div class="walkthrough-step-content-text">
          <p class="walkthrough-step-content-first-line">
            Some terms to get started,
          </p>
          <h3>Dashboards</h3>
          <p>
            Widgets reside in dashboards which is like a canvas. Widgets are
            customised in dashboards. Dashboards can be nested, shared, printed
            and presented.
          </p>
        </div>
      </div>

      <!-- Step 4 -->
      <div class="walkthrough-step-content walkthrough-step-4-content">
        <div class="walkthrough-step-content-img">
          <img src="/assets/walkthrough/data.gif" alt="Data" />
        </div>
        <div class="walkthrough-step-content-text">
          <p class="walkthrough-step-content-first-line">
            Some terms to get started,
          </p>
          <h3>Data</h3>
          <p>
            Bitpool retrieves and processes data from your assets and can be
            used to help you analyze, monitor and manage performance, faults and
            abnormalities.
          </p>
        </div>
      </div>

      <!-- Step 5 -->
      <div class="walkthrough-step-content walkthrough-step-5-content">
        <div class="walkthrough-step-content-img">
          <img src="/assets/walkthrough/widgets.gif" alt="Widgets" />
        </div>
        <div class="walkthrough-step-content-text">
          <p class="walkthrough-step-content-first-line">
            Some terms to get started,
          </p>
          <h3>Widgets</h3>
          <p>
            Data is available as streams inside Bitpool. Added to widgets to
            visualise data, they will help you to identify faults, abnormalities
            and patterns in the data.
          </p>
        </div>
      </div>

      <!-- Step 6 -->
      <div class="walkthrough-step-content walkthrough-step-6-content">
        <div class="walkthrough-step-content-img">
          <img src="/assets/walkthrough/2fa.gif" alt="2FA" />
        </div>
        <div class="walkthrough-step-content-text">
          <p class="walkthrough-step-content-first-line">
            Set up your secure login,
          </p>
          <h3>2FA & Single <span class="white-space-nowrap">Sign-on</span></h3>
          <p>
            It's what we use to protect your details. 
            Navigate to 'Account Settings' to set up your 
            Two-factor Authentication and keep your 
            information safe!
          </p>
        </div>
      </div>

      <!-- Step 7 -->
      <div class="walkthrough-step-content walkthrough-step-7-content">
        <div class="walkthrough-step-content-img">
          <img src="/assets/walkthrough/finish.png" alt="Let's get started" />
        </div>
        <div class="walkthrough-step-content-text">
          <p class="walkthrough-step-content-first-line">Finished.</p>
          <h3>Let's get started!</h3>
          <p>
            Now that you are acquainted with the key concepts of Bitpool, let's
            start exploring Bitpool.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import DashboardState from "@/store/states/DashboardState";
import { Component, Vue } from "vue-facing-decorator";
import { useShepherd } from "vue-shepherd";

@Component({
  components: {},
})
class FirstRunView extends Vue {
  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  tour: any = null;
  isComplete = false;

  onComplete(): void {
    if (this.dashboardState.spaceSettings && !this.isComplete) {
      this.isComplete = true;
      this.dashboardState.spaceSettings.showWalkthrough = false;
      this.$store.dispatch("dashboard/saveSpaceSettings");
    }
  }

  mounted(): void {
    this.tour = useShepherd({
      useModalOverlay: true,
      modalContainer: document.getElementById("shepherd-wrapper"),
      stepsContainer: document.getElementById("shepherd-container-inner"),
    });
    // docs: https://shepherdjs.dev/docs/tutorial-02-usage.html
    this.tour.on("cancel", this.onComplete);
    this.tour.on("complete", this.onComplete);
    // Step 1
    this.tour.addStep({
      text: document
        .getElementById("walkthrough-steps")
        ?.getElementsByClassName("walkthrough-step-1-content")[0]
        .cloneNode(true),
      buttons: [
        {
          text: "Next",
          action: () => this.goNext(),
          classes: "p-component p-button p-button-secondary",
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "Skip",
      },
      classes: "walkthrough-step walkthrough-step-first walkthrough-step-1",
      when: {
        show: () => this.showStep(),
        hide: () => this.hideStep(),
      },
    });
    // Step 2
    this.tour.addStep({
      text: document
        .getElementById("walkthrough-steps")
        ?.getElementsByClassName("walkthrough-step-2-content")[0]
        .cloneNode(true),
      buttons: [
        {
          text: "Back",
          action: () => this.goBack(),
          classes: "p-component p-button p-button-secondary p-button-outlined",
        },
        {
          text: "Next",
          action: () => this.goNext(),
          classes: "p-component p-button p-button-secondary",
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "Skip",
      },
      classes: "walkthrough-step walkthrough-step-2",
      when: {
        show: () => this.showStep(),
        hide: () => this.hideStep(),
      },
    });
    // Step 3
    this.tour.addStep({
      text: document
        .getElementById("walkthrough-steps")
        ?.getElementsByClassName("walkthrough-step-3-content")[0]
        .cloneNode(true),
      buttons: [
        {
          text: "Back",
          action: () => this.goBack(),
          classes: "p-component p-button p-button-secondary p-button-outlined",
        },
        {
          text: "Next",
          action: () => this.goNext(),
          classes: "p-component p-button p-button-secondary",
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "Skip",
      },
      classes: "walkthrough-step walkthrough-step-3",
      when: {
        show: () => this.showStep(),
        hide: () => this.hideStep(),
      },
    });
    // Step 4
    this.tour.addStep({
      text: document
        .getElementById("walkthrough-steps")
        ?.getElementsByClassName("walkthrough-step-4-content")[0]
        .cloneNode(true),
      buttons: [
        {
          text: "Back",
          action: () => this.goBack(),
          classes: "p-component p-button p-button-secondary p-button-outlined",
        },
        {
          text: "Next",
          action: () => this.goNext(),
          classes: "p-component p-button p-button-secondary",
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "Skip",
      },
      classes: "walkthrough-step walkthrough-step-4",
      when: {
        show: () => this.showStep(),
        hide: () => this.hideStep(),
      },
    });
    // Step 5
    this.tour.addStep({
      text: document
        .getElementById("walkthrough-steps")
        ?.getElementsByClassName("walkthrough-step-5-content")[0]
        .cloneNode(true),
      buttons: [
        {
          text: "Back",
          action: () => this.goBack(),
          classes: "p-component p-button p-button-secondary p-button-outlined",
        },
        {
          text: "Next",
          action: () => this.goNext(),
          classes: "p-component p-button p-button-secondary",
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "Skip",
      },
      classes: "walkthrough-step walkthrough-step-5",
      when: {
        show: () => this.showStep(),
        hide: () => this.hideStep(),
      },
    });
    // Step 6
    this.tour.addStep({
      text: document
        .getElementById("walkthrough-steps")
        ?.getElementsByClassName("walkthrough-step-6-content")[0]
        .cloneNode(true),
      buttons: [
        {
          text: "Back",
          action: () => this.goBack(),
          classes: "p-component p-button p-button-secondary p-button-outlined",
        },
        {
          text: "Next",
          action: () => this.goNext(),
          classes: "p-component p-button p-button-secondary",
        },
      ],
      cancelIcon: {
        enabled: true,
        label: "Skip",
      },
      classes: "walkthrough-step walkthrough-step-6",
      when: {
        show: () => this.showStep(),
        hide: () => this.hideStep(),
      },
    });
    // Step 7
    this.tour.addStep({
      text: document
        .getElementById("walkthrough-steps")
        ?.getElementsByClassName("walkthrough-step-7-content")[0]
        .cloneNode(true),
      buttons: [
        {
          text: "Back",
          action: () => this.goBack(),
          classes: "p-component p-button p-button-secondary p-button-outlined",
        },
        {
          text: "Close",
          action: () => this.goNext(),
          classes: "p-component p-button p-button-secondary last",
        },
      ],
      classes: "walkthrough-step walkthrough-step-last walkthrough-step-7",
      when: {
        show: () => this.showStep(),
        hide: () => this.hideStep(),
      },
    });

    this.tour.start();
    const sw = document.getElementById("shepherd-wrapper");
    if (sw) {
      sw.className += "shepherd-wrapper-active";
    }
  }

  unmounted(): void {
    const sw = document.getElementById("shepherd-wrapper");
    if (sw) {
      sw.className = sw.className.replaceAll("shepherd-wrapper-active", "");
    }
    if (this.tour) {
      this.tour.cancel();
    }
  }

  goBack(): void {
    this.animateBack();
    this.tour.back();
  }

  goNext(): void {
    this.animateNext();
    this.tour.next();
  }

  animateBack(): void {
    const sw = document.getElementById("shepherd-wrapper");
    if (sw) {
      sw.className += " walkthrough-steps-back";
    }
  }

  animateNext(): void {
    const sw = document.getElementById("shepherd-wrapper");
    if (sw) {
      sw.className = sw.className.replaceAll("walkthrough-steps-back", "");
    }
  }

  allowTouchmove(event: Event): void {
    event.stopPropagation();
  }

  showStep(): void {
    const currentStep = this.tour.getCurrentStep();
    currentStep.getElement().className += " walkthrough-step-active";
    this.displayProgressIndicator();
    // fix touch scroll - https://github.com/shipshapecode/shepherd/issues/1717
    const target = currentStep.getElement();
    if (target) {
      target.addEventListener("touchmove", this.allowTouchmove);
    }
  }

  hideStep(): void {
    const currentStep = this.tour.getCurrentStep();
    currentStep.getElement().className = currentStep
      .getElement()
      .className.replaceAll("walkthrough-step-active", "");
    // fix touch scroll - https://github.com/shipshapecode/shepherd/issues/1717
    const target = currentStep.getElement();
    if (target) {
      target.removeEventListener("touchmove", this.allowTouchmove);
    }
  }

  displayProgressIndicator(): void {
    const currentStep = this.tour.getCurrentStep();
    const currentStepElement = currentStep?.getElement();
    const footer = currentStepElement?.querySelector(".shepherd-footer");
    const progress = document.createElement("span");
    progress.className = "walkthrough-progress";
    const currentStepIndex = this.tour.steps.indexOf(currentStep);
    for (let i = 0; i < this.tour.steps.length; i++) {
      const preogressStep = document.createElement("span");
      preogressStep.className = "walkthrough-progress-step";
      if (i === currentStepIndex) {
        preogressStep.className += " walkthrough-progress-step-active";
      } else {
        preogressStep.onclick = () => {
          if (i < currentStepIndex) {
            this.animateBack();
          } else {
            this.animateNext();
          }
          this.tour.show(i);
        };
      }
      progress.appendChild(preogressStep);
    }

    footer?.insertBefore(
      progress,
      currentStepElement.querySelector(".shepherd-button:last-child")
    );
  }
}

export default FirstRunView;
</script>
