<template>
  <div class="widget-type-file-attachment" :style="[ backgroundColor ? {backgroundColor: backgroundColor} : {}, {color: textColor}]">
    <div class="file-attachment" v-if="fileUrl">
      <div><a :href="fileUrl" target="_blank" v-html="fileIcon"></a></div>
      <p><a :href="fileUrl" target="_blank" :style="backgroundColor ? {color: textColor} : {}">{{filename}}</a></p>
    </div>
    <div v-else class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType" message="File have not been selected."/>
    </div>
  </div>
</template>

<script lang="ts">
import ColorHelper from '@/helpers/ColorHelper';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { getIcon } from 'material-file-icons';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';

@Component({
  components: {
    WidgetNoDataView
  }
})
class FileAttachmentWidget extends Vue {
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  noDataType = WidgetNoDataTypes.NotConfigured;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get backgroundColor(): string {
    const result = this.aws?.widgetColorEnabled && this.aws?.widgetColorHex ? this.aws?.widgetColorHex : '';
    return result;
  }

  get textColor(): string {
    return ColorHelper.getContrastColor(this.backgroundColor);
  }
  
  get fileUrl(): string {
    return this.aws?.widgetFileUrl ? this.aws.widgetFileUrl : "";
  }

  get filename(): string {
    let result: string | undefined = this.fileUrl;
    if (result.includes("api.bitpool.com") || result.includes("api-ui.bitpool.com")) {
      // internal link
      result = result.split('/').pop();
      if (result) {
        const underscoreIndex = result.indexOf("_");
        if (underscoreIndex > -1) {
          result = result.slice(underscoreIndex + 1);
        }
      }
    } else {
      // external link
      result = result.split('/').pop();
    }
    return result ? result : "";
  }

  get fileIcon() {
    return getIcon(this.filename).svg;
  }
}

export default FileAttachmentWidget;
</script>