import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import DateHelper from "@/helpers/DateHelper";
import OrganisationGroupsState from "./states/OrganisationGroupsState";
import { GroupFromDBDto } from "@/models/organisation/GroupFromDBDto";

export const useOrganisationGroupsStore = defineStore('organisationGroups', {
  state: (): OrganisationGroupsState => ({ 
    data: {},
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number) {
      const organisationIdStr = organisationId.toString();
      try {
        const guid = uuidv4();
        this.data[organisationIdStr] = {
          guid: guid,
          isLoaded: false,
          entities: null
        }
        const url = `rest/BitPool_V2/organisation/${organisationId}/groups`;
        const response = await axios.get<GroupFromDBDto[]>(url);
        if (this.data[organisationIdStr].guid === guid) {
          response.data.forEach(x => {
            x.DateCreated = DateHelper.parseFromMicrosoftString(x.DateCreated as string);
          });
          this.data[organisationIdStr].entities = response.data;
          this.data[organisationIdStr].isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load organisation groups",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data[organisationIdStr].entities = null;
        this.data[organisationIdStr].isLoaded = true;
      }
    },
    async save(body: GroupFromDBDto): Promise<number> {
      let result = body.Id;
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/organisation/groups`;
        const response = await axios.put<GroupFromDBDto>(url, body);
        if (response.data) {
          response.data.DateCreated = DateHelper.parseFromMicrosoftString(response.data.DateCreated as string);
          const organisationIdStr = body.OrganisationId.toString();
          if (body.Id) {
            const data = this.data[organisationIdStr];
            if (data?.isLoaded && data?.entities) {
              const index = data.entities.findIndex(x => x.Id === body.Id);
              if (index > -1) {
                data.entities[index] = response.data;
              }
            }
          } else {
            const data = this.data[organisationIdStr];
            if (data?.isLoaded && data?.entities) {
              data.entities.push(response.data);
            }
            result = response.data.Id;
          }
        }
        this.updateInProgress = false;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save organisation members",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
      return result;
    },
    async delete(organisationId: number, ids: number[]) {
      const organisationIdStr = organisationId.toString();
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/BitPool_V2/organisation/groups`;
        await axios.delete(url, {
          headers: {
            "Content-Type": "application/json",
          },
          data: ids,
        });
        ToastService.showToast("success", "Organisation", `Changes saved`, 5000);
        this.deleteInProgress = false;
        const data = this.data[organisationIdStr];
        if (data?.isLoaded && data?.entities) {
          for (const id of ids) {
            const index = data.entities.findIndex((x) => x.Id === id);
            if (index > -1) {
              data.entities.splice(index, 1);
            }
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete groups`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
      }
    }
  },
})
