<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="195.85" height="139.998" viewBox="0 0 195.85 139.998">
  <g id="Group_19481" data-name="Group 19481" transform="translate(-4091.25 -17649.25)">
    <rect id="Rectangle_18110" data-name="Rectangle 18110" width="170" height="130" rx="4" transform="translate(4092.5 17650.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="2.5" stroke-dasharray="4.5 5"/>
    <g id="Group_19469" data-name="Group 19469" transform="translate(292)">
      <g id="Group_19483" data-name="Group 19483">
        <line id="Line_151" data-name="Line 151" y2="125" transform="translate(3857.5 17653)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1.5" stroke-dasharray="0.5 3" opacity="0.2"/>
        <line id="Line_152" data-name="Line 152" y2="125" transform="translate(3913.5 17653)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1.5" stroke-dasharray="0.5 3" opacity="0.2"/>
        <line id="Line_158" data-name="Line 158" x1="165" transform="translate(3803 17694)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1.5" stroke-dasharray="0.5 3" opacity="0.2"/>
        <line id="Line_160" data-name="Line 160" x1="165" transform="translate(3803 17737)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1.5" stroke-dasharray="0.5 3" opacity="0.2"/>
      </g>
      <g id="Group_19467" data-name="Group 19467" opacity="0.8">
        <g id="Group_19464" data-name="Group 19464">
          <line id="Line_172" data-name="Line 172" y2="5" transform="translate(3857.5 17691.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
          <line id="Line_173" data-name="Line 173" y2="5" transform="translate(3860 17694) rotate(90)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
        </g>
        <g id="Group_19465" data-name="Group 19465" transform="translate(0 43)">
          <line id="Line_172-2" data-name="Line 172" y2="5" transform="translate(3857.5 17691.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
          <line id="Line_173-2" data-name="Line 173" y2="5" transform="translate(3860 17694) rotate(90)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
        </g>
        <g id="Group_19463" data-name="Group 19463">
          <line id="Line_174" data-name="Line 174" y2="5" transform="translate(3913.5 17691.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
          <line id="Line_175" data-name="Line 175" y2="5" transform="translate(3913.5 17691.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
          <line id="Line_176" data-name="Line 176" y2="5" transform="translate(3916 17694) rotate(90)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
        </g>
        <g id="Group_19466" data-name="Group 19466" transform="translate(0 43)">
          <line id="Line_174-2" data-name="Line 174" y2="5" transform="translate(3913.5 17691.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
          <line id="Line_175-2" data-name="Line 175" y2="5" transform="translate(3913.5 17691.5)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
          <line id="Line_176-2" data-name="Line 176" y2="5" transform="translate(3916 17694) rotate(90)" fill="none" stroke="var(--widget-svg-color-third)" stroke-linecap="round" stroke-width="1"/>
        </g>
      </g>
    </g>
    <path id="Path_19873" data-name="Path 19873" d="M25,0A25,25,0,1,1,0,25,25,25,0,0,1,25,0Z" transform="translate(4237.1 17739.248)" fill="#00adee"/>
    <g id="Group_19470" data-name="Group 19470" transform="translate(4246.6 17748.748)">
      <line id="Line_174-3" data-name="Line 174" y2="31" transform="translate(15.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
      <line id="Line_175-3" data-name="Line 175" y2="31" transform="translate(15.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
      <line id="Line_176-3" data-name="Line 176" y2="31" transform="translate(31 15.5) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
    </g>
  </g>
</svg>

</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class AddGridFullSvg extends Vue {
}

export default AddGridFullSvg;
</script>