<template>
  <div>
    <DataTable
      :value="pageData"
      dataKey="Id"
      :totalRecords="pageTotal"
      :paginator="true"
      :rows="10"
      :lazy="true"
      @page="onPage($event)"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint connection-history-table"
    >
      <template #empty>
        <div v-if="isLoadedPage" class="w-full" style="min-height: 100%;">
          <span class="inline-block py-2">No logs</span>
        </div>
        <div
          class="connection-history-table-loader"
          style="min-height: 100%;"
          v-else
        >
          <ProgressSpinner
            class="spinner-primary"
            style="width: 100px; height: 100px"
            strokeWidth="4"
            animationDuration="1s"
          />
        </div>
      </template>
      <Column field="EventType" header="Status" headerStyle="width: 1%; min-width: 48px;" headerClass="no-break-word" bodyStyle="text-align: center;">
        <template #body="slotProps">
          <i 
            class="pi pi-circle-fill vertical-align-middle" 
            :class="slotProps.data.EventType === 0 ? 'text-green-500' : (slotProps.data.EventType === 1 ? 'text-yellow-500' : 'text-red-500')"
            v-tippy="slotProps.data.EventType === 0 ? 'Success' : (slotProps.data.EventType === 1 ? 'Warning' : 'Error')"
          ></i>
        </template>
      </Column>
      <Column field="Source" header="Source" headerStyle="min-width: min-content; width: 25%;" headerClass="no-break-word" bodyClass="break-word"></Column>
      <Column field="Message" header="Message" headerStyle="min-width: min-content; width: 48%;" headerClass="no-break-word" bodyClass="no-break-word"></Column>
      <Column field="Date" header="Date" headerStyle="min-width: min-content; width: 17%;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.Date" timezone="local"/>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import Checkbox from "primevue/checkbox";
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import DateTimezoneView from '@/components/views/DateTimezoneView.vue';
import CustomWindow from '@/CustomWindow';
import { ConnectionHistoryType } from "@/models/connection/ConnectionHistoryType";
import { useConnectionHistoryStore } from "@/stores/connectionHistory";
import { ConnectionHistoryEntity } from "@/models/connection/ConnectionHistoryEntity";

declare const window: CustomWindow;

@Component({
  components: {
    Button,
    DataTable,
    Column,
    ProgressSpinner,
    Dialog,
    InputText,
    Checkbox,
    DateTimezoneView
  },
})
class ConnectionHistoryView extends Vue {
  @Model configId!: string;
  @Prop({ required: true }) type!: ConnectionHistoryType;

  connectionHistoryStore = useConnectionHistoryStore();

  get pageData(): ConnectionHistoryEntity[] {
    return this.connectionHistoryStore.isLoaded &&
      this.connectionHistoryStore.data &&
      this.connectionHistoryStore.data.Items
      ? this.connectionHistoryStore.data.Items
      : [];
  }

  get pageTotal(): number {
    return this.connectionHistoryStore.isLoaded && this.connectionHistoryStore.data
      ? this.connectionHistoryStore.data.Total
      : 0;
  }

  get isLoadedPage(): boolean {
    return this.connectionHistoryStore.isLoaded;
  }

  take = 10;
  skip = 0;

  created(): void {
    this.loadData();
  }

  loadData(): void {
    this.connectionHistoryStore.load(this.configId, this.type, this.skip, this.take);
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.skip = event.page * event.rows;
    this.take = event.rows;
    this.loadData();
  }
}

export default ConnectionHistoryView;
</script>
