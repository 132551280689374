<template>
  <DataTable
    v-model:selection="selectedRecords"
    :value="users" 
    dataKey="Id"
    showGridlines 
    responsiveLayout="stack" 
    breakpoint="660px" 
    class="responsive-breakpoint p-datatable-sm organisation-profile-users-groups-members-edit-table"
    sortField="DateAdded" 
    :sortOrder="-1"
    :paginator="true"
    :rows="20"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
    :rowsPerPageOptions="[10, 20, 50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    v-model:filters="filters"
    filterDisplay="menu"
    :globalFilterFields="['UserName']"
    removableSort
  >
    <template #header>
      <div class="table-header">
        <div class="md:flex md:align-items-center md:justify-content-between md:gap-3">
          <div class="md:flex-shrink-0">
            <Button 
              :disabled="!organisationGroupsUsersStore.isLoaded" 
              label="Add Member" 
              icon="pi pi-plus-circle" 
              class="my-1 mr-2" 
              @click="openCreateDialog"
            />
            <Button 
              v-if="selectedRecords.length > 0"
              label="Delete" 
              icon="pi pi-trash" 
              class="my-1 mr-2 p-button-outlined p-button-danger" 
              @click="openDeleteSelectedRecordsConfirmation()"
            />
          </div>
          <div class="mt-3 md:mt-0">
            <IconField iconPosition="left" class="w-full md:w-auto">
              <InputIcon class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Find Member" class="w-full md:w-auto" />
            </IconField>
          </div>
        </div>
      </div>
    </template>
    <template #empty>
      <div v-if="organisationGroupsUsersStore.isLoaded" class="w-full" style="min-height: 50vh;">
        <span class="inline-block py-2">No data found.</span>
      </div>
      <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 30vh;" v-else>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </div>
    </template>
    <Column selectionMode="multiple" headerStyle="width: 1%; min-width: 3rem;" headerClass="column-with-checkbox" bodyClass="column-with-checkbox"></Column>
    <Column :sortable="true" field="UserName" header="Member Name" headerStyle="min-width: min-content; width: 45%;" headerClass="no-break-word" bodyClass="break-word">
      <template #body="slotProps">
        <div class="organisation-profile-users-members-user-card">
          <Avatar
            v-if="slotProps.data.Avatar"
            :image="getAvatarUrl(slotProps.data.Avatar)"
            shape="circle"
            class="user-img"
          />
          <Avatar
            v-else
            :label="shortName(getFullname(slotProps.data))"
            shape="circle"
            class="user-label"
          />
          <div>
            <div class="organisation-profile-users-members-user-name">{{ getFullname(slotProps.data) }}</div>
            <div class="organisation-profile-users-members-user-email">{{ slotProps.data.UserName }}</div>
          </div>
        </div>
      </template>
    </Column>
    <Column :sortable="true" field="Access" header="Access" headerStyle="min-width: min-content; width: 15%;" headerClass="no-break-word" bodyClass="no-break-word">
      <template #body="slotProps">
        <div>
          <SelectButton 
            v-model="slotProps.data.Access" 
            :options="['Admin', 'User']" 
            :allowEmpty="false"
          />
        </div>
      </template>
    </Column>
    <Column :sortable="true" field="DateAdded" header="Date Added" headerStyle="min-width: min-content; width: 17%;" headerClass="no-break-word" bodyClass="no-break-word">
      <template #body="slotProps">
        <DateTimezoneView :date="slotProps.data.DateAdded" timezone="local"/>
      </template>
    </Column>
    <Column :exportable="false" headerStyle="width: 1%; min-width: 44px;" bodyStyle="text-align: right; justify-content: flex-end;">
      <template #body="slotProps">
        <div>
          <div class="inline-flex">
            <Button 
              icon="pi pi-trash" 
              class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
              @click="openConfirmation(slotProps.data)" 
              v-tippy="'Delete'"
            />
          </div>
        </div>
      </template>
    </Column>
  </DataTable>

  <Dialog header="Add Members" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'992px': '80vw'}" :style="{width: '56rem'}" class="organisation-profile-users-groups-members-config-dialog">
    <div class="dialog-content">
      <BlockUI :blocked="organisationGroupsUsersStore.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="organisationGroupsUsersStore.updateInProgress ? 'blockui-blocked' : ''">
        <div>
          <div class="formgrid grid align-items-end">
            <div class="field col md:mb-0">
              <label for="addRecord">Members</label>
              <div>
                <MultiSelect 
                  inputId="addRecord"
                  v-model="addRecord"
                  :options="availableUsers"
                  placeholder="Select Members"
                  display="chip"
                  :filter="true"
                  optionLabel="UserName"
                  class="p-multiselect-multiline inputfield w-full"
                />
              </div>
            </div>
            <div class="field col-fixed w-full md:w-auto mb-0 md:pb-1">
              <div class="flex align-items-center">
                <InputSwitch 
                  inputId="editRecordType"
                  v-model="addRecordAccess"
                  trueValue="Admin"
                  falseValue="User"
                  class="vertical-align-top"
                />
                <label for="editRecordType" class="mb-0 ml-2">Group Admin</label>
              </div>
            </div>
          </div>
        </div>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #header>
      <div>
        <div class="p-dialog-title">Add Members</div>
        <div class="p-dialog-subtitle">Please select new members for the group.</div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
        <span class="block">
          <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        </span>
        <span class="block ml-2">
          <Button 
            label="Add" 
            :icon="organisationGroupsUsersStore.updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
            @click="saveRecord" 
            :disabled='organisationGroupsUsersStore.updateInProgress || !addRecord.length' 
          />
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Model, Vue, Watch } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Avatar from "primevue/avatar";
import SelectButton from "primevue/selectbutton";
import MultiSelect from "primevue/multiselect";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import ConfirmationService from "@/services/ConfirmationService";
import { useOrganisationStore } from "@/stores/organisation";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";
import { UserFullInOrganisationDto } from "@/models/user/UserFullInOrganisationDto";
import moment from "moment";
import { GroupAccessString } from "@/models/user/GroupAccessString";
import { GroupFromDBDto } from "@/models/organisation/GroupFromDBDto";
import { useOrganisationGroupsUsersStore } from "@/stores/organisationGroupsUsers";
import DateHelper from "@/helpers/DateHelper";
import { UserFullForGroupDto } from "@/models/organisation/UserFullForGroupDto";
import { Permission } from "@/models/enums/Permission";
import { UserFullInOrganisation2Dto } from "@/models/user/UserFullInOrganisation2Dto";
import { PermissionScope } from "@/models/enums/PermissionScope";

@Component({
  components: {
    Button,
    InputText,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown,
    Avatar,
    SelectButton,
    MultiSelect,
    IconField,
    InputIcon,
    DateTimezoneView
  },
  directives: {
  }
})
class OrganisationUsersGroupsMembers extends Vue {
  @Model model!: GroupFromDBDto;

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  organisationUsersStore = useOrganisationUsersStore();
  organisationGroupsUsersStore = useOrganisationGroupsUsersStore();

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get availableOrganisations(): OrganisationFullDto[] {
    let result = this.organisationStore.entities ?? [];
    result = result.filter(x => x.Id !== this.organisationStore.currentOrganisation?.Id);
    return result;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  created(): void {
    this.loadData();
  }

  unmounted(): void {
    this.organisationGroupsUsersStore.$reset();
  }

  @Watch('model.Id', { immediate: false, deep: false })
  onModelChanged(val: number, oldVal: number): void {
    if (oldVal > 0) {
      this.loadData();
    } else {
      this.organisationGroupsUsersStore.groupId = this.model.Id;
    }
  }

  users: UserFullForGroupDto[] = [];

  async loadData(): Promise<void> {
    await this.organisationGroupsUsersStore.load(this.model.OrganisationId, this.model.Id);
    const users = JSON.parse(JSON.stringify(this.organisationGroupsUsersStore.entities ?? [])) as UserFullForGroupDto[];
    users.forEach(x => {
      x.DateAdded = DateHelper.parseFromMicrosoftString(x.DateAdded as string);
    });
    this.users = users;
  }

  isActiveUser(user: UserFullInOrganisationDto): boolean {
    const m = moment();
    const date = m.add(-2, "month").toDate();
    return !!(user.LastLogin && typeof user.LastLogin !== "string" && user.LastLogin > date);
  }

  getAvatarUrl(avatar: string): string {
    const apiUrl = this.$store.state.apiUrl;
    return `${apiUrl}/rest/AWS_S3_V1/File/${avatar}`;
  }

  shortName(fullname: string | null): string {
    return fullname ? fullname.split(" ").map(x => x[0]).join("") : "";
  }

  getFullname(user: UserFullInOrganisationDto): string {
    let result = user.UserName.split('@')[0];
    if (user.Firstname || user.Lastname) {
      result = user.Firstname ? user.Firstname : "";
      if (user.Lastname) {
        result = result.length ? `${result} ${user.Lastname}` : user.Lastname;
      }
    }
    return result;
  }

  async saveChanges(): Promise<boolean> {
    const createUpdateRecords: UserFullForGroupDto[] = [];
    const deleteRecotds: UserFullForGroupDto[] = [];
    this.users.forEach(x => {
      const oldRecord = this.organisationGroupsUsersStore.entities?.find(y => y.Id === x.Id);
      if (!oldRecord || oldRecord.Access !== x.Access) {
        // create or update
        createUpdateRecords.push(x);
      }
    });
    this.organisationGroupsUsersStore.entities?.forEach(x => {
      if (!this.users.find(y => y.Id === x.Id)) {
        // delete
        deleteRecotds.push(x);
      }
    })
    let isOk = true;
    if (createUpdateRecords.length) {
      isOk = await this.organisationGroupsUsersStore.save(createUpdateRecords);
    }
    if (isOk && deleteRecotds.length) {
      isOk = await this.organisationGroupsUsersStore.delete(deleteRecotds);
    }
    return isOk;
  }

  // #region add/update
  displayCreateUpdateDialog = false;
  addRecord: UserFullInOrganisation2Dto[] = [];
  addRecordAccess: GroupAccessString = GroupAccessString.User;

  get availableUsers(): UserFullInOrganisation2Dto[] {
    return ((this.organisationUsersStore.data[this.organisationIdStr] ?? []).entities ?? []).filter(x => !this.users.find(y => y.Id === x.Id)).sort((a, b) => a.UserName.localeCompare(b.UserName));
  }

  openCreateDialog(): void {
    if (this.currentOrganisation) {
      this.addRecord = [];
      this.addRecordAccess = GroupAccessString.User;
      this.displayCreateUpdateDialog = true;
    }
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  saveRecord(): void {
    for (const record of this.addRecord) {
      this.users.push({
        Id: record.Id,
        UserName: record.UserName,
        Firstname: record.Firstname,
        Lastname: record.Lastname,
        Avatar: record.Avatar,
        Access: this.addRecordAccess,
        DateAdded: new Date(),

        // following fields are not important
        DataPools: Permission.Disable,
        Dashboards: Permission.Disable,
        DashboardsScope: PermissionScope.Custom,
        Comments: Permission.Disable,
        Sharing: false,
        Bills: Permission.Disable,
        InviteMembers: false,
        Reports: Permission.Disable
      });
    }
    this.closeCreateUpdateDialog();
  }
  // #endregion add/update

  // #region delete
  selectedRecord: UserFullForGroupDto | null = null;

  openConfirmation(record: UserFullForGroupDto | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete member?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Member',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    const index = this.users.findIndex(x => x.Id === this.selectedRecord?.Id);
    if (index > -1) {
      this.users.splice(index, 1);
    }
  }

  selectedRecords: UserFullForGroupDto[] = [];

  openDeleteSelectedRecordsConfirmation(): void {
    const message = `Are you sure you want to delete selected members?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Members',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteSelectedRecords();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteSelectedRecords(): void {
    if (this.selectedRecords.length) {
      for (const record of this.selectedRecords) {
        const index = this.users.findIndex(x => x.Id === record.Id);
        if (index > -1) {
          this.users.splice(index, 1);
        }
      }
    }
  }
  // #endregion delete
}

export default OrganisationUsersGroupsMembers;
</script>