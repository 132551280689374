<template>
  <div class="widget-type-infographic">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="widget-type-infographic-inner" v-show="!isLodingData && !isNoData">
      <div class="infographic-block reduce-gap">
          <div class="infographic-range humidity-range">
            <div class="layer-1 outdoor-humidity" :style="`z-index: ${outsideZIndex};`">
              <span :style="`height: ${valueOutside}%;`"></span>
            </div>
            <div class="layer-2 indoor-humidity" :style="`z-index: ${insideZIndex};`">
              <span :style="`height: ${valueInside}%;`"></span>
            </div>
            <div class="infographic-object drop">
              <DropSvg/>
            </div>
          </div>

          <div class="infographic-legend">
            <div class="outdoor-humidity-legend">
              <span class="infographic-legend-img"></span>
              <div>
                <span class="infographic-legend-value">{{formatValue(valueOutside)}}</span>
                <span class="infographic-legend-description">Outdoor Humidity</span>
              </div>
            </div>
            <div class="indoor-humidity-legend">
              <span class="infographic-legend-img"></span>
              <div>
                <span class="infographic-legend-value">{{formatValue(valueInside)}}</span>
                <span class="infographic-legend-description">Indoor Humidity</span>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import numbro from "numbro";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import DropSvg from "@/components/svg/DropSvg.vue";

@Component({
  components: {
    ProgressSpinner,
    WidgetNoDataView,
    DropSvg
  }
})
class HumidityWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;
  valueInside : number | undefined = undefined;
  valueOutside : number | undefined = undefined;

  outsideZIndex = 1;

  insideZIndex = 2;

  updateZIndex(): void {
    if (typeof this.valueOutside === "number" &&
      typeof this.valueInside === "number" &&
      this.valueOutside > this.valueInside) {
      this.outsideZIndex = 1;
      this.insideZIndex = 2;
    } else {
      this.outsideZIndex = 2;
      this.insideZIndex = 1;
    }
  }

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.valueInside = undefined; 
    this.valueOutside = undefined; 
    if (this.wds && this.chartData.length) {
      let hasData = false;
      this.wds.streamOptions.forEach((stream, i) => {
        if (this.chartData.length > i) {
          const streamData = this.chartData[i];
          if (streamData.Error) {
            ToastService.showToast(
              "error",
              "Error",
              streamData.Error,
              5000
            );
          } else if (streamData.Data && streamData.Data.length) {
            const value = streamData.Data[0].y;
            if (i === 0) {
              if (typeof value === "number") {
                this.valueInside = value; 
                hasData = true;
              }
            } else if (i === 1) {
              if (typeof value === "number") {
                this.valueOutside = value; 
                hasData = true;
              }
            }
          }
        }
      });
      this.updateZIndex();
      if (!hasData) {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }

  formatValue(value: number | undefined) : string {
    let result: string;
    if (typeof value === "undefined" || value === null) {
      result = "-";
    } else if (typeof value === "string") {
      result = value + "%";
    } else {
      result = numbro(value).format({
        thousandSeparated: true,
        mantissa: 0
      }) + "%";
    }
    return result;
  }
}

export default HumidityWidget;
</script>