<template>
  <div v-if="isMounted" ref="root" class="widget-type-chart widget-type-peak-demand">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="chart-element basic-chart" v-else>
      <highcharts v-if="redrawChartToggle" ref="chartElement" class="w-full h-full flex-auto" :options="chartOptions"></highcharts>
      <Button 
        v-if="isZoomed" 
        @click="resetZoom" 
        icon="pi pi-search-minus text-xl font-bold" 
        v-tippy="'Reset Zoom'" 
        class="p-button-icon-only p-button-rounded p-button-light chart-zoom-out"/>
      <div class="plot-legend">
        <div class="infographic-legend">
          <div class="peak-demand-legend">
            <span class="infographic-legend-img"><DataIconView v-if="aws" :iconType="aws.dataIcon"/></span>
            <div>
              <span class="infographic-legend-value">{{formatValue(peakValue)}}</span>
              <span class="infographic-legend-value font-normal">{{peakDate}}</span>
              <span class="infographic-legend-description">{{ aws?.text }}</span>
            </div>
          </div>
          <div class="user-target-legend">
            <span class="infographic-legend-img"><DataIconView v-if="aws" :iconType="aws.dataIcon"/></span>
            <div>
              <span class="infographic-legend-value">{{formatValue(targetValue)}}</span>
              <span class="infographic-legend-value font-normal">{{targetPercent}}</span>
              <span class="infographic-legend-description">User Target</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import StreamOption from '../../models/dashboard/StreamOption';
import DateHelper from '../../helpers/DateHelper';
import moment from 'moment';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import ColorHelper from '../../helpers/ColorHelper';
import { AggregationPeriod } from '@/models/enums/AggregationPeriod';
import { AllUserData } from '@/models/user/AllUserData';
import CustomWindow from '@/CustomWindow';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { PropType, nextTick } from 'vue';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { BasicWidgetSettings } from '@/models/dashboard/BasicWidgetSettings';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import DashboardState from '@/store/states/DashboardState';
import DataHelper from '@/helpers/DataHelper';
import WidgetDataState from '@/store/states/WidgetDataState';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import { Watch } from "vue-facing-decorator";
import ToastService from '@/services/ToastService';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import numbro from 'numbro';
import DataIconView from "./settings/DataIconView.vue";

declare const window: CustomWindow;

@Component({
  components: {
    highcharts: Chart,
    ProgressSpinner,
    Button,
    WidgetNoDataView,
    DataIconView
  }
})
class PeakDemandWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  peakValue: number| undefined | null = null;
  peakDate = "";
  targetValue: number| undefined | null = null;
  targetPercent = "";
  streams: StreamOption[] = [];
  streamColors: string[] = [];
  names: string[] = [];
  dates: [Date, Date] | null = null;
  series: Highcharts.SeriesOptionsType[] = [];
  yAxis: Highcharts.YAxisOptions[] = [];
  requestBody: AggregatedDataRequest | null = null;

  isMounted = false;

  get allUserData() : AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  resetZoom(): void {
    const chart = this.getChartElement()
    if (chart && chart.chart) {
      chart.chart.zoomOut();
    }
  }

  isZoomed = false;

  get chartOptions(): Highcharts.Options {
    const dates = this.dates;
    const series = this.series;
    const yAxis = this.yAxis;
    const xAxisLabelsFormat = this.wds && this.wds.aggPeriod == 5 ? 'Total' : undefined;
    const units = this.aws?.widgetUnit ? this.aws.widgetUnit : "";
    
    let markerEnabled = !this.dashboardState.spaceSettings?.disablePlotMarks;
    // milliseconds
    const dateStep = this.requestBody ? DateHelper.extractDateStepFromRequestBody(this.requestBody) : null;
    const markerCount = dateStep && dates && dates.length === 2 ? Math.ceil((dates[1].getTime() - dates[0].getTime()) / dateStep) : 0;
    // redrawChartToggle - is used to recalculate value on resize
    if (markerCount > 0 && this.redrawChartToggle && markerEnabled) {
      let denseData = false;
      const element = this.$refs.root as HTMLElement;
      if (element) {
        // 8 - marker width
        // 0.7 - keep some space between markers
        const maxMarkerCount = (element.clientWidth / 8) * 0.7;
        if (markerCount > maxMarkerCount) {
          denseData = true;
        }
      }
      if (denseData) {
        markerEnabled = false;
      }
    }

    let startDate = (dates && dates.length > 0) ?
      (dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000)) : 
      undefined;
    if (startDate && this.requestBody && this.requestBody.AggregationPeriod === AggregationPeriod.Weekly) {
      const startOfWeek = moment.utc(startDate).startOf("isoWeek");
      startDate = startOfWeek.unix() * 1000;
    }
    
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "column",
        animation: true,
        zooming: {
          type: 'x'
        },
        style: {
          color: ColorHelper.getDefautTextColor(),
        },
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          },
          selection: (event: Highcharts.SelectEventObject): (boolean | undefined) => {
            if (event.resetSelection) {
              this.isZoomed = false;
            } else {
              this.isZoomed = true;
            }
            return true;
          }
        }
      },
      tooltip: {
        useHTML: true,
        outside: true,
        shared: !!this.dashboardState.spaceSettings?.groupTooltips,
        valueDecimals: 2,
        formatter: function() {
          if (this.points) {
            let result = `<small>${typeof this.x === "number" ? Highcharts.dateFormat('%b %e,%Y %H:%M', this.x) : this.x}</small>`;
            this.points.forEach(point => {
              result += `<br/><span style="color:${point.color};">\u25CF </span>${point.series.name}: <b>${(point.y ?? 0).toFixed(2)} ${units}</b>`
            });
            return result;
          } else {
            return `<small>${typeof this.x === "number" ? Highcharts.dateFormat('%b %e,%Y %H:%M', this.x) : this.x}</small><br/><span style="color:${this.color};">\u25CF </span>${this.series.name}: <b>${(this.y ?? 0).toFixed(2)} ${units}</b>`;
          }
        },
      },
      yAxis: yAxis,
      xAxis: {
        type: 'datetime',
        min: startDate,
        labels: {
          style: {
            font: "500 12px Arial, sans-serif",
          },
          format: xAxisLabelsFormat
        },
        tickWidth: 2,
        tickLength: 5,
        minorGridLineWidth: 0,
        minorTickWidth: 0
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          pointStart: startDate,
          dataGrouping: {
            enabled: this.wds ? this.wds.autoAggPeriod : false,
            approximation: 'average'
          },
          turboThreshold: 0,
          borderWidth: 0
        },
        line: {
          marker: {
            enabled: markerEnabled
          }
        },
        spline: {
          marker: {
            enabled: markerEnabled
          }
        }
      },
      series: series
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }
  
  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataRefreshInterval = 0;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
    this.isMounted = true;
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.resizeWidgetEvent();
  }

  loadConfig(): void {
    if (this.wds &&
      this.wds.streamOptions) {
      this.streams = this.wds.streamOptions;
      this.names = [];
      this.streamColors = [];
      this.streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
        this.streamColors.push(stream.hexStreamColor);
      });
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.loadConfig();
    this.dates = DateHelper.extractDateFromRequestBody(requestBody);
    const series: Highcharts.SeriesOptionsType[] = [];
    const yAxis: Highcharts.YAxisOptions[] = [];
    let i = 0;
    const formatLongNumber = this.formatLongNumber;
    const plotLines: Highcharts.YAxisPlotLinesOptions[] = [];
    // peak and target lines to plotLines
    plotLines.push({
      value: 0,
      width: 2,
      dashStyle: "ShortDash",
      label: {
        text: "Peak Overall",
        style: {
          display: "none"
        }
      },
      zIndex: 5,
      color: "#FF8F2E"
    });
    plotLines.push({
      value: this.aws?.target ? this.aws.target : 0,
      width: 2,
      dashStyle: "ShortDash",
      zIndex: 5,
      label: {
        text: "User Target",
        style: {
          display: "none"
        }
      },
      color: "#4EC8EB"
    });
    yAxis.push({
      id: "0",
      gridLineWidth: 1,
      title: {
        text: this.aws?.widgetUnit ? this.aws.widgetUnit : "",
        style: {
          font: "600 16px Arial, sans-serif",
        }
      },
      labels: {
        style: {
          font: "500 12px Arial, sans-serif",
        },
        formatter: function () {
          return formatLongNumber(this.value)
        }
      },
      plotLines: plotLines
    });
    i = 0;
    let maxValue: number | null = null;
    let maxValueDate: number | undefined | null = null;
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length) {
        const data = streamData.Data.slice();
        data.forEach(item => {
          if (maxValue === null && typeof item.y === "number" ||
          typeof maxValue === "number" && typeof item.y === "number" && item.y > maxValue) {
            maxValue = item.y;
            maxValueDate = item.x;
          }
        });
        const seriesType: "column" | "line" | "area" | "scatter" | "spline" = "area";
        series.push({
          name: this.names.length > i ? this.names[i] : '',
          color: this.streamColors.length > i ? this.streamColors[i] : '',
          type: seriesType,
          data: data as Highcharts.XrangePointOptionsObject[],
          yAxis: '0',
          pointRange: DateHelper.extractDateStepFromRequestBody(requestBody)
        });
      }
      i++;
    });
    if (maxValue === null) {
      this.peakValue = null;
      this.peakDate = "";
    } else {
      plotLines[0].value = maxValue;
      this.peakValue = maxValue;
      this.peakDate = maxValueDate ? moment.utc(maxValueDate).format("DD/MM/YYYY HH:mm") : "";
    }
    this.targetValue = plotLines[1].value;
    if (this.peakValue && this.targetValue) {
      const percent = this.peakValue / this.targetValue * 100;
      const percentStr = numbro(percent).format({
        thousandSeparated: true,
        mantissa: 2
      })
      this.targetPercent = `${percentStr}% of ${this.aws?.text}`;
    } else {
      this.targetPercent = "";
    }

    this.series = series;
    this.yAxis = yAxis;
    if (series.length) {
      this.isNoData = false;
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  redrawChartToggle = true;

  // chart reflow on widget resize
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async resizeWidgetEvent(): Promise<void> {
    this.redrawChartToggle = false;
    await nextTick();
    this.redrawChartToggle = true;
  }

  formatLongNumber(value: string | number): string {
    if (typeof value === "string") {
      return value;
    }
    if (value == 0) {
      return '0';
    }
    else {
      // hundreds
      if (value <= 999) {
        return `${value}`;
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return (value / 1000) + 'K';
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return (value / 1000000) + 'M';
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return (value / 1000000000) + 'B';
      }
      else
        return `${value}`;
    }
  }

  formatValue(value: number | undefined | null) : string {
    let result: string;
    const units = this.aws?.widgetUnit ? this.aws.widgetUnit : "";
    if (typeof value === "undefined" || value === null) {
      result = "-";
    } else if (typeof value === "string") {
      result = `${value} ${units}`;
    } else {
      result = `${numbro(value).format({
        thousandSeparated: true,
        mantissa: 2
      })} ${units}`;
    }
    return result;
  }
}

export default PeakDemandWidget;
</script>