<template>
  <div class="widget-type-tariff-simulator widget-with-datatable">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="widget-type-tariff-simulator-inner" v-show="!isLodingData && !isNoData">
      <div class="widget-datatable-container h-auto">
        <DataTable :value="selectedTariffs" showGridlines removableSort responsiveLayout="scroll" class="p-datatable-sm default-visual-table">
          <template #header>
            <div class="table-header">
              <Button
                label="Add"
                icon="pi pi-plus-circle"
                class="my-1"
                @click="openAddDialog"
              />
            </div>
          </template>
          <template #empty>
            <div class="flex justify-content-center align-items-center h-7rem">Please add tariffs</div>
          </template>
          <Column field="Id" header="Current" bodyClass="text-center" headerClass="no-break-word white-space-nowrap" headerStyle="width: 1%; min-width: 48px;">
            <template #body="slotProps">
              <span v-if="aws">
                <RadioButton name="currentTariff" :value="slotProps.data.Id" v-model="aws.currentTariff" @change="currentTariffOnChange" />
              </span>
            </template>
          </Column>
          <Column :sortable="true" field="Name" header="Name" headerClass="no-break-word white-space-nowrap">
            <template #body="slotProps">
              <div class="tariff-name">
                <span :style="{ backgroundColor: getColor(slotProps.data.Index) }"></span>
                <span>{{slotProps.data.Name}}</span>
              </div>
            </template>
          </Column>
          <Column :sortable="true" field="Cost" header="Cost" headerClass="no-break-word white-space-nowrap" headerStyle="min-width: 12%;" bodyClass="no-break-word">
            <template #body="slotProps">
              ${{formatFloat(slotProps.data.Cost)}}
            </template>
          </Column>
          <Column :sortable="true" field="Saving" header="Saving" headerClass="no-break-word white-space-nowrap" headerStyle="min-width: 16%;">
            <template #body="slotProps">
              <span class="font-semibold white-space-nowrap flex flex-column sm:flex-row gap-2" :class="slotProps.data.Saving > 0 ? 'font-green' : slotProps.data.Saving < 0 ? 'font-red' : ''">
                <span class="flex-none no-break-word">${{formatFloat(Math.abs(slotProps.data.Saving))}}</span>
                <span class="flex-none" v-if="slotProps.data.Saving > 0"><i class="pi pi-arrow-down mr-1"></i></span>
                <span class="flex-none" v-else-if="slotProps.data.Saving < 0"><i class="pi pi-arrow-up mr-1"></i></span>
              </span>
            </template>
          </Column>
          <Column
              :exportable="false"
              headerStyle="width: 1%; min-width: 44px;" 
              bodyStyle="text-align: right; justify-content: flex-end;"
            >
              <template #body="slotProps">
                <div class="inline-flex">
                  <Button
                    icon="pi pi-trash"
                    class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined"
                    @click="openDeleteConfirmation(slotProps.index)"
                  />
                </div>
              </template>
            </Column>
        </DataTable>
      </div>
      <div class="chart-element basic-chart" v-if="widgetData">
        <highcharts v-if="redrawChartToggle" ref="chartElement" class="w-full h-full flex-auto" :options="chartOptions"></highcharts>
        <Button 
          v-if="isZoomed" 
          @click="resetZoom" 
          icon="pi pi-search-minus text-xl font-bold" 
          v-tippy="'Reset Zoom'" 
          class="p-button-icon-only p-button-rounded p-button-light chart-zoom-out"/>
      </div>
    </div>

    <Dialog header="Add Tariff" v-model:visible="displayAddDialog" :modal="true" :style="{width: '56rem'}" class="add-tariff-dialog">
      <template #header>
        <div class="flex align-items-center">
          <span class="p-dialog-title">Add Tariff</span>
        </div>
      </template>
      <div class="dialog-content">
        <div class="formgrid grid">
          <div class="field col-12 sm:col-6">
            <label for="selectRetailer">Select a Retailer</label>
            <div>
              <Dropdown id="selectRetailer" class="w-full" v-model="addTariffSelectedRetailer" :options="retailers" placeholder="Select a Retailer" :filter="true" filterPlaceholder="Find Retailer" />
            </div>
          </div>
          <div class="field col-12 sm:col-6">
            <label for="selectTariff">Select a Tariff</label>
            <div>
              <Dropdown id="selectTariff" class="w-full" v-model="addTariffSelectedTariff" :options="getRetailerTariffs(addTariffSelectedRetailer)" optionLabel="Name" placeholder="Select a Tariff" :filter="true" filterPlaceholder="Find Tariff" />
            </div>
          </div>
        </div>

        <div v-if="addTariffSelectedTariff">
          <ul class="add-tariff-selected-tariff">
            <li><b>Category:</b> {{addTariffSelectedTariff.Category}}</li>
            <li><b>State:</b> {{addTariffSelectedTariff.State}}</li>
            <li><b>Notes:</b> <span style="white-space: pre-wrap;">{{addTariffSelectedTariff.Notes}}</span></li>
            <li v-for="(rule, index) in addTariffSelectedTariff.Rules" :key="index">
              <b>Rule {{index + 1}}</b>
              <ul>
                <li v-for="(value, key) in rule" :key="key" :class="value ? '' : 'hidden'">
                  <b>{{key}}:</b> {{value}}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeAddDialog" class="p-button-text p-button-secondary"/>
        <Button label="Add" icon="pi pi-check" @click="addTariff" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { nextTick, PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import RadioButton from 'primevue/radiobutton';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import numbro from "numbro";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import StreamOption from '@/models/dashboard/StreamOption';
import DateTimezoneView from '@/components/views/DateTimezoneView.vue';
import { TariffModel } from '@/models/tariff/TariffModel';
import { ApplyTariffDto } from '@/models/tariff/ApplyTariffDto';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import ColorHelper from '@/helpers/ColorHelper';
import ConfirmationService from '@/services/ConfirmationService';
import ToastService from '@/services/ToastService';
import { useTariffStore } from '@/stores/tariff';
import TariffState from '@/stores/states/TariffState';

@Component({
  components: {
    ProgressSpinner,
    DataTable,
    Column,
    Button,
    RadioButton,
    Dialog,
    Dropdown,
    WidgetNoDataView,
    DateTimezoneView,
    highcharts: Chart
  }
})
class TariffSimulatorWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;
  @Prop({ required: true }) dashboardId!: string;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  tariffStore = useTariffStore();

  get tariffState(): TariffState {
    return this.tariffStore;
  }

  widgetData: Record<string, number | null> | null = null;

  get retailers(): string[] {
    const result: string[] = [];
    if (this.tariffState.data) {
      for (const retailer in this.tariffState.data) {
        result.push(retailer);
      }
    }
    return result;
  }

  getRetailerTariffs(retailer: string | null): TariffModel[] {
    let result: TariffModel[] = [];
    if (this.tariffState.data && retailer && this.tariffState.data[retailer]) {
      const tariffs = this.tariffState.data[retailer];
      if (tariffs && tariffs.length) {
        result = tariffs;
      }
    }
    return result;
  }

  get allTariffs(): TariffModel[] {
    let result: TariffModel[] = [];
    if (this.tariffState.data) {
      for (const retailer in this.tariffState.data) {
        const tariffs = this.tariffState.data[retailer];
        if (tariffs && tariffs.length) {
          result = result.concat(tariffs);
        }
      }
    }
    return result;
  }

  get selectedTariffs(): any[] {
    const result: any[] = [];
    const data = this.widgetData;
    let currentCost: number | null = null;
    if (data && this.aws?.currentTariff) {
      if (data && Object.prototype.hasOwnProperty.call(data, this.aws.currentTariff)) {
        currentCost = data[this.aws.currentTariff];
      }
    } 
    if (this.aws?.tariffs?.length && this.allTariffs.length) {
      const tariffIds = this.aws.tariffs;
      tariffIds.forEach((tariffId, index) => {
        const tariff = this.allTariffs.find(x => x.Id === tariffId);
        if (tariff) {
          let cost: number | null = null; 
          if (data) {
            if (data && Object.prototype.hasOwnProperty.call(data, tariffId)) {
              cost = data[tariffId];
            }
          } 
          result.push({
            Id: tariff.Id,
            Name: tariff.Name,
            Number: tariff.Number,
            Index: index,
            Cost: cost,
            Saving: currentCost === null || cost === null ? null : (currentCost - cost)
          });
        }
      });
    }
    return result;
  }

  colors = ['#FC6E59','#3FBC72','#FCCB5F','#435772','#E27A3F','#17A3A5',
    '#9777AA','#DC143C','#0000FF','#404000','#FFE0C0','#800080',
    '#00FFFF','#C000C0','#C0C000','#3399FF','#D2B48C','#000000',
    '#99B4D1','#C04000','#00FF00'];

  getColor(index: number): string {
    return this.colors[index % this.colors.length];
  }

  redrawChartToggle = true;

  // chart reflow on widget resize
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async resizeWidgetEvent(): Promise<void> {
    this.redrawChartToggle = false;
    await nextTick();
    this.redrawChartToggle = true;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  series: Highcharts.SeriesOptionsType[] = [];

  resetZoom(): void {
    const chart = this.getChartElement()
    if (chart && chart.chart) {
      chart.chart.zoomOut();
    }
  }

  isZoomed = false;

  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  get categories(): string[] {
    return this.selectedTariffs.map(x => {
      let year = (x.Name as string).split(" ")[0];
      year = `${isNaN(+year) ? '' : year}`;
      const name = x.Number ?
        `${year ? year : ''}${year ? ' - ' : ''}Tariff ${x.Number}` :
        x.Name;
      const result = x.Id === this.aws?.currentTariff ? `◉ ${name}` : name;
      return result;
    });
  }

  get chartOptions(): Highcharts.Options {
    const series = this.series;
    const xAxisLabelsFormat = undefined;
    const formatLongNumber = this.formatLongNumber;
    const categories = this.categories;
    
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "column",
        animation: true,
        zooming: {
          type: 'x'
        },
        style: {
          color: ColorHelper.getDefautTextColor(),
        },
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          },
          selection: (event: Highcharts.SelectEventObject): (boolean | undefined) => {
            if (event.resetSelection) {
              this.isZoomed = false;
            } else {
              this.isZoomed = true;
            }
            return true;
          }
        }
      },
      tooltip: {
        useHTML: true,
        outside: true,
        shared: false,
        valueDecimals: 2
      },
      yAxis: [{
        gridLineWidth: 1,
        title: {
          text: 'Cost',
          style: {
            font: "600 16px Arial, sans-serif",
          }
        },
        labels: {
          style: {
            font: "500 12px Arial, sans-serif",
          },
          formatter: function () {
            return `$${formatLongNumber(this.value)}`
          }
        }
      }],
      xAxis: {
        type: 'datetime',
        min: 0,
        categories: categories,
        labels: {
          style: {
            font: "500 12px Arial, sans-serif",
          },
          format: xAxisLabelsFormat
        },
        tickWidth: 2,
        tickLength: 5,
        minorGridLineWidth: 0,
        minorTickWidth: 0
      },
      legend: {
        enabled: false
      },
      plotOptions: {
        series: {
          dataGrouping: {
            enabled: this.wds ? this.wds.autoAggPeriod : false,
            approximation: 'average'
          },
          turboThreshold: 0,
          borderWidth: 0
        },
        line: {
          marker: {
            enabled: false
          }
        }
      },
      series: series
    }
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.resizeWidgetEvent();
  }

  get canEditDashboard(): boolean {
    const canEditDashboard = this.$store.getters["dashboard/canEditDashboard"] as (id: string) => boolean;
    const result = canEditDashboard(this.dashboardId);
    return result;
  }

  async saveWidget(): Promise<void> {
    if (this.canEditDashboard && this.widgetConfig) {
      await this.$store.dispatch("dashboard/saveWidget", this.widgetConfig);
      const state = this.dashboardState.widgetState[this.widgetConfig.guid];
      if (state && state[0] && !state[2]) {
        // ok
      }
    }
  }

  preventReload = false;

  currentTariffOnChange(event: Event): void {
    this.preventReload = true;
    this.saveWidget();
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    if (this.preventReload) {
      this.preventReload = false;
    } else {
      this.reloadData();
    }
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.configuredStreams.length) {
      const hchRequest = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      const requestBody: ApplyTariffDto = {
        Streams: [],
        From: hchRequest.TimeFrom,
        To: hchRequest.TimeTo,
        TimeRange: hchRequest.TimeRange,
        Multiplier: 1
      };
      this.wds.streamOptions.forEach((streamOptions, index) => {
        if (streamOptions.StreamKey) {
          if (index === 0) {
            requestBody.Streams.push({
              StreamKey: streamOptions.StreamKey,
              UnitType: "kWh",
              AggregationType: streamOptions.Params.aggType
            });
          } else if (index === 1) {
            requestBody.Streams.push({
              StreamKey: streamOptions.StreamKey,
              UnitType: "kW",
              AggregationType: streamOptions.Params.aggType
            });
          }
        }
      });
      let isReady = false;
      const previousData = this.widgetDataState.dataTariffSimulation[this.widgetConfig.guid];
      if (init && previousData && previousData[0]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = `${JSON.stringify(this.aws?.tariffs)}---${JSON.stringify(requestBody)}`;
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        if (!this.tariffState.isLoaded) {
          await this.tariffStore.loadTariffs();
        }
        if (this.aws?.tariffs) {
          await this.$store.dispatch("widgetData/loadDataTariffSimulation", [this.widgetConfig.guid, this.aws.tariffs, requestBody]);    
        }
      }
      const data = this.widgetDataState.dataTariffSimulation[this.widgetConfig.guid];
      if (data && data[1]) {
        this.dataUpdate(data[1]);
      } else {
        this.widgetData = null;
        // isNoData is only for streams. If tariff is not configure, user should be able to add tariff. 
        this.isNoData = false;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  get configuredStreams(): StreamOption[] {
    const result = this.wds?.streamOptions?.filter(x => x.StreamKey);
    return result ? result : [];
  }

  dataUpdate(data: Record<string, number | null>): void {
    this.widgetData = data;
    const series: Highcharts.SeriesOptionsType[] = [];
    let i = 0;
    const values: Highcharts.PointOptionsObject[] = [];
    for (const key in this.widgetData) {
      const value = this.widgetData[key];
      const tariff = this.allTariffs.find(x => x.Id === key);
      values.push({
        name: tariff ? tariff.Name : key,
        color: this.getColor(i),
        y: value
      });
      i++;
    }
    series.push({
      name: "Total Cost ($)",
      type: "column",
      data: values,
      yAxis: 0
    });
    this.series = series;
    if (series.length) {
      this.isNoData = false;
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }

  formatFloat(value: number): string {
    let result: string;
    if (typeof value === "undefined" || value === null) {
      result = "";
    } else if (typeof value === "string") {
      result = value;
    } else {
      const decimals = this.aws?.decimals ? this.aws.decimals : 0;
      result = numbro(value).format({
        thousandSeparated: true,
        mantissa: decimals < 0 ? 0 : decimals
      });
    }
    return result;
  }

  formatLongNumber(value: string | number): string {
    if (typeof value === "string") {
      return value;
    }
    if (value == 0) {
      return '0';
    }
    else {
      // hundreds
      if (value <= 999) {
        return `${value}`;
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return (value / 1000) + 'K';
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return (value / 1000000) + 'M';
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return (value / 1000000000) + 'B';
      }
      else
        return `${value}`;
    }
  }

  // #region add/remove tariffs
  displayAddDialog = false;

  addTariffSelectedRetailer: string | null = null;
  addTariffSelectedTariff: TariffModel | null = null;

  openAddDialog(): void {
    if (!this.addTariffSelectedRetailer && this.retailers.length) {
      this.addTariffSelectedRetailer = this.retailers[0];
      const tariffs = this.getRetailerTariffs(this.addTariffSelectedRetailer);
      this.addTariffSelectedTariff = tariffs.length ? tariffs[0] : null;
    }
    this.displayAddDialog = true;
  }

  closeAddDialog(): void {
    this.displayAddDialog = false;
  }

  addTariff(): void {
    if (this.aws?.tariffs) {
      if (this.addTariffSelectedTariff) {
        if (this.aws.tariffs.length && this.aws.tariffs.includes(this.addTariffSelectedTariff.Id)) {
          ToastService.showToast("warn", "Tariff", `'${this.addTariffSelectedTariff.Name}' is already in list`, 5000);
        } else {
          this.aws.tariffs.push(this.addTariffSelectedTariff.Id);
          if (!this.aws.currentTariff) {
            this.aws.currentTariff = this.addTariffSelectedTariff.Id;
          }
          this.displayAddDialog = false;
          this.saveWidget();
        }
      } else {
        ToastService.showToast("warn", "Tariff", "Please select tariff", 5000);
      }
    }
  }

  openDeleteConfirmation(index: number): void {
    ConfirmationService.showConfirmation({
      message: "Are you sure you want to delete tariff?",
      header: 'Delete Tariff',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.aws && this.aws.tariffs) {
          const tariffId = this.aws.tariffs[index];
          this.aws.tariffs.splice(index, 1);
          if (tariffId === this.aws.currentTariff) {
            if (this.aws.tariffs.length > 0) {
              this.aws.currentTariff = this.aws.tariffs[0];
            } else {
              this.aws.currentTariff = null;
            }
          }
          await this.saveWidget();
          await this.reloadData();
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion add/remove tariffs
}

export default TariffSimulatorWidget;
</script>
