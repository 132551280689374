import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import EmailImportState from "./states/EmailImportState";
import { EmailImportScriptEntity } from "@/models/email-import/EmailImportScriptEntity";
import { EmailImportConfigEntity } from "@/models/email-import/EmailImportConfigEntity";
import { JSStreamLog } from "@/models/email-import/JSStreamLog";
import { MongoEntityPage } from "@/models/MongoEntityPage";
import { EmailImportHistoryEntity } from "@/models/email-import/EmailImportHistoryEntity";
import DateHelper from "@/helpers/DateHelper";

export const useEmailImportStore = defineStore('emailImport', {
  state: (): EmailImportState => ({ 
    isLoadedScripts: false,
    guidScripts: "",
    scripts: null,
    updateInProgressScripts: false,
    updateErrorScripts: false,
    inProgressScriptTest: false,
    testResult: null,
    testError: "",

    isLoadedConfigs: false,
    guidConfigs: "",
    configs: null,
    updateInProgressConfigs: false,
    updateErrorConfigs: false,

    isLoadedHistory: false,
    guidHistory: "",
    history: null,
    historyTake: 10,
    historySkip: 0,
    historyConfigId: ""
  }),
  getters: {
  },
  actions: {
    async loadScripts() {
      try {
        const guid = uuidv4();
        this.guidScripts = guid;
        this.isLoadedScripts = false;
        const url = `rest/EmailImportScript_V1/scripts`;
        const response = await axios.get<EmailImportScriptEntity[]>(url);
        if (this.guidScripts === guid) {
          this.scripts = response.data;
          this.isLoadedScripts = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load scripts",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.scripts = null;
        this.isLoadedScripts = true;
      }
    },
    async createUpdateScript(entity: EmailImportScriptEntity) {
      try {
        this.updateInProgressScripts = true;
        this.updateErrorScripts = false;
        const url = `rest/EmailImportScript_V1/scripts`;
        const response = await axios.post<EmailImportScriptEntity>(url, entity);
        ToastService.showToast("success", "Scripts", "Changes saved", 5000);
        this.updateInProgressScripts = false;
        if (this.isLoadedScripts && this.scripts) {
          if (entity.Id) {
            const index = this.scripts.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.scripts[index] = response.data;
            } else {
              this.scripts.push(response.data);
            }
          } else {
            this.scripts.push(response.data);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save script",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateErrorScripts = true;
        this.updateInProgressScripts = false;
      }
    },
    async testScript(entity: EmailImportScriptEntity) {
      try {
        this.inProgressScriptTest = true;
        this.testResult = null;
        this.testError = "";
        const url = `rest/EmailImportScript_V1/scripts/test`;
        const response = await axios.post<JSStreamLog[]>(url, entity);
        if (response.data && response.data){
          response.data.forEach((x) => {
            if (typeof x.date === "string") {
              x.date = DateHelper.parseFromMicrosoftString(x.date);
            }
          })
          this.testResult = response.data;
          ToastService.showToast("success", "The script test is completed", `Parsed ${response.data.length} records.`, 5000);
        }
      } catch (error) {
        this.testResult = null;
        const message = ErrorHelper.handleAxiosError(error).message;
        ToastService.showToast(
          "error",
          "The script test failed",
          message,
          5000
        );
        this.testError = message;
      }
      this.inProgressScriptTest = false;
    },
    async deleteScript(entity: EmailImportScriptEntity) {
      try {
        const url = `rest/EmailImportScript_V1/scripts/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Scripts", `${entity.Name} is deleted`, 5000);
        if (this.isLoadedScripts && this.scripts) {
          const index = this.scripts.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.scripts.splice(index, 1);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
    async loadConfigs() {
      try {
        const guid = uuidv4();
        this.guidConfigs = guid;
        this.isLoadedConfigs = false;
        const url = `rest/EmailImportConfig_V1/configs`;
        const response = await axios.get<EmailImportConfigEntity[]>(url);
        if (this.guidConfigs === guid) {
          this.configs = response.data;
          this.isLoadedConfigs = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load configurations",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.configs = null;
        this.isLoadedConfigs = true;
      }
    },
    async createUpdateConfig(entity: EmailImportConfigEntity) {
      try {
        this.updateInProgressConfigs = true;
        this.updateErrorConfigs = false;
        const url = `rest/EmailImportConfig_V1/configs`;
        const response = await axios.post<EmailImportConfigEntity>(url, entity);
        ToastService.showToast("success", "Configs", "Changes saved", 5000);
        this.updateInProgressConfigs = false;
        if (this.isLoadedConfigs && this.configs) {
          if (entity.Id) {
            const index = this.configs.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.configs[index] = response.data;
            } else {
              this.configs.push(response.data);
            }
          } else {
            this.configs.push(response.data);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save configuration",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateErrorConfigs = true;
        this.updateInProgressConfigs = false;
      }
    },
    async deleteConfig(entity: EmailImportConfigEntity) {
      try {
        const url = `rest/EmailImportConfig_V1/configs/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Configs", `${entity.Name} is deleted`, 5000);
        if (this.isLoadedConfigs && this.configs) {
          const index = this.configs.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.configs.splice(index, 1);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
    async loadHistory(configId: string, skip: number, take: number) {
      try {
        const guid = uuidv4();
        this.guidHistory = guid;
        this.isLoadedHistory = false;
        this.historyConfigId = configId;
        this.historySkip = skip;
        this.historyTake = take;
        const url = `rest/EmailImportHistory_V1/history/${configId}?skip=${skip}&take=${take}`;
        const response = await axios.get<MongoEntityPage<EmailImportHistoryEntity>>(url);
        if (this.guidHistory === guid) {
          if (response.data?.Items?.length) {
            response.data.Items.forEach((item) => {
              if (item.Date) {
                item.Date = DateHelper.parseFromMicrosoftString(
                  item.Date as string
                );
              }
            });
          }
          this.history = response.data;
          this.isLoadedHistory = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load history",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.history = null;
        this.isLoadedHistory = true;
      }
    }
  },
})
