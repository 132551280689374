import { AdvancedWidgetSettings } from "@/models/dashboard/AdvancedWidgetSettings";
import { Space } from "@/models/dashboard/Space";
import StreamOption from "@/models/dashboard/StreamOption";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import { WidgetDataSettings } from "@/models/dashboard/WidgetDataSettings";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import { AggregationType } from "@/models/enums/AggregationType";
import { AggregationTypeString } from "@/models/enums/AggregationTypeString";
import { AlarmOperators } from "@/models/enums/AlarmOperators";
import { TimeRange } from "@/models/enums/TimeRange";
import ColorHelper from "./ColorHelper";
import WidgetHelper from "./WidgetHelper";
import { DashboardType } from "@/models/dashboard/DashboardType";

class WidgetMigrationHelper {
  private migrateWDS(widgetType: string, aws: AdvancedWidgetSettings | undefined, wds: WidgetDataSettings): void {
    // migrate dates
    if (wds.startDate) {
      wds.startDate = wds.startDate.replaceAll("/", "-");
    }
    if (wds.endDate) {
      wds.endDate = wds.endDate.replaceAll("/", "-");
    }
    if (typeof wds.rangePreset === "undefined") {
      wds.rangePreset = TimeRange.Today;
    }
    if (typeof wds.rangePresetHolder === "undefined") {
      wds.rangePresetHolder = TimeRange.Today;
    }
    
    if (!wds.streamOptions) {
      wds.streamOptions = [];
    }    
    // migrate definedSlots, definedSlots is deprecated
    if (wds.definedSlots && wds.definedSlots.length) {
      wds.definedSlots.forEach(slot => {
        if (slot && slot.streamOptions) {
          wds.streamOptions.push(slot.streamOptions);
        }
      });
    }
    wds.definedSlots = undefined;
    // migrate streamOptions
    wds.streamOptions.forEach(stream => {
      // migrate colors
      if (!stream.hexStreamColor) {
        const theme = ColorHelper.getThemes()[aws && aws.widgetColor ? aws.widgetColor : 0];
        const colorIndex = parseInt(stream.streamColor);
        if (isNaN(colorIndex) || colorIndex >= theme.colors.length) {
          stream.hexStreamColor = ColorHelper.random();
        } else {
          stream.hexStreamColor = theme.colors[colorIndex];
        }
      }
      // migrate timeIntervals
      if (stream.Params.timeIntervals && stream.Params.timeIntervals.length) {
        stream.Params.timeIntervals.forEach(x => x.InvertTime = !!x.InvertTime);
      }
      // migrate time offset
      if (stream.Params.timeOffset) {
        if (typeof stream.Params.timeOffset.value === "string") {
          stream.Params.timeOffset.value = parseInt(stream.Params.timeOffset.value);
        }
      } else {
        stream.Params.timeOffset = {
          value: 0,
          period: "H"
        };
      }
      // migrate string aggregation
      if (widgetType === "stringchart" || widgetType === "pointwidget") {
        if (!stream.Params.aggTypeString) {
          stream.Params.aggTypeString = AggregationTypeString.Last;
        }
      }
      // add agg type for powerusage
      if (widgetType === "powerusage") {
        if (!stream.Params.aggType) {
          stream.Params.aggType = AggregationType.Sum;
        }
      }
    });
    if (widgetType === "baselinechart" && wds.streamOptions?.length > 1) {
      wds.streamOptions = wds.streamOptions.slice(0, 1);
    }
  }

  migrate(widgetConfig: WidgetConfig, apiUrl: string): void {
    const widgetDescription = WidgetHelper.getWidget(widgetConfig.widgetType);
    const latestVersion = widgetDescription?.latestVersion;
    switch (widgetConfig.widgetType) {
      case "titlewidget": {
        if (!widgetConfig.version) {
          // migrate color
          const colorThemes = ColorHelper.getThemes();
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            const color = (aws.bgColor !== undefined && aws.bgColor !== null && aws.bgColor > -1 && aws.widgetColor !== undefined && aws.widgetColor !== null) ?
              colorThemes[aws.widgetColor].colors[aws.bgColor] : '';
            if (color) {
              aws.widgetColorEnabled = true;
              aws.widgetColorHex = color;
            } else {
              aws.widgetColorEnabled = false;
              aws.widgetColorHex = "#FFFFFF";
            }
            // migrate internal link
            if (aws.link) {
              let parsed: Space;
              if (typeof aws.link === "string") {
                parsed = JSON.parse(aws.link);
              } else {
                parsed = aws.link;
              }
              if (parsed && parsed._id) {
                aws.link = parsed._id;
              } else {
                aws.link = "";
              }
            }
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
          }
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "textboxwidget":
      case "customizabletextwidget": {
        if (!widgetConfig.version) {
          // customizabletextwidget is deprecated, but we can migrate it to textboxwidget
          if (widgetConfig.widgetType === "customizabletextwidget") {
            widgetConfig.widgetType = "textboxwidget";
          }
          // migrate color
          const colorThemes = ColorHelper.getThemes();
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            const color = (aws.bgColor !== undefined && aws.bgColor !== null && aws.bgColor > -1 && aws.widgetColor !== undefined && aws.widgetColor !== null) ?
              colorThemes[aws.widgetColor].colors[aws.bgColor] : '';
            if (color) {
              aws.widgetColorEnabled = true;
              aws.widgetColorHex = color;
            } else {
              aws.widgetColorEnabled = false;
              aws.widgetColorHex = "#FFFFFF";
            }
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
          }
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "imagewidget": {
        if (widgetConfig.version !== latestVersion) {
          if (!widgetConfig.version) {
            const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
            if (aws) {
              if (!aws.minHeight) {
                aws.minHeight = 200;
              }
            } else {
              widgetConfig.widgetOptions.advancedWidgetSettings = 
                JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
            }
            widgetConfig.version = "vue_imagewidget_001";
          } 
          if (widgetConfig.version === "vue_imagewidget_001") {
            const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
            if (aws) {
              aws.linkType = 0;
            }
          }
          if (widgetConfig.version === "vue_imagewidget_002") {
            const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
            if (aws) {
              aws.useGDRS = 1;
              aws.enableOptionalData = false;
              aws.dataConditionsImage = [];
            }
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "fileattachment":{
        if (!widgetConfig.version) {
          // migrate color
          const colorThemes = ColorHelper.getThemes();
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            const color = (aws.bgColor !== undefined && aws.bgColor !== null && aws.bgColor > -1 && aws.widgetColor !== undefined && aws.widgetColor !== null) ?
              colorThemes[aws.widgetColor].colors[aws.bgColor] : '';
            if (color) {
              aws.widgetColorEnabled = true;
              aws.widgetColorHex = color;
            } else {
              aws.widgetColorEnabled = false;
              aws.widgetColorHex = "#FFFFFF";
            }
            // migrate file
            if (aws.awsKey?.length && aws.awsKey[0]) {
              aws.widgetFileUrl = `${apiUrl}/rest/AWS_S3_V1/File/${aws.awsKey[0]}`
            }
            
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
          }
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "cweather":
      case "fweather": {
        if (!widgetConfig.version) {
          // migrate coordinates
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws && aws.location) {
            if (typeof aws.location.lat === "string") {
              aws.location.lat = parseFloat(aws.location.lat);
            }
            if (typeof aws.location.lng === "string") {
              aws.location.lng = parseFloat(aws.location.lng);
            }
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
          }
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "pointwidget": {
        if (!widgetConfig.version) {
          const colorThemes = ColorHelper.getThemes();
          let aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (aws) {
            // migrate color
            const color = (aws.bgColor !== undefined && aws.bgColor !== null && aws.bgColor > -1 && aws.widgetColor !== undefined && aws.widgetColor !== null) ?
              colorThemes[aws.widgetColor].colors[aws.bgColor] : '';
            if (color) {
              aws.widgetColorEnabled = true;
              aws.widgetColorHex = color;
            } else {
              aws.widgetColorEnabled = false;
              aws.widgetColorHex = "#FFFFFF";
            }
            // fix decimals
            if (typeof aws.decimals === "string") {
              aws.decimals = parseInt(aws.decimals);
            }
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
            aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          }
          if (wds) {
            this.migrateWDS(widgetConfig.widgetType, aws, wds);
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = latestVersion;
        }
        if (widgetConfig.version === "vue_pointwidget_001") {
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            aws.dataConditions = [];
          }
          widgetConfig.version = "vue_pointwidget_002"
        }
        break;
      }
      case "alarm": {
        if (!widgetConfig.version) {
          let aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (aws) {
            // fix decimals
            if (typeof aws.decimals === "string") {
              aws.decimals = parseInt(aws.decimals);
            }
            // add units position
            aws.widgetUnitPos = 1;
            // fix alarm related fields
            if (typeof aws.alarmValue === "string") {
              aws.alarmValue = parseFloat(aws.alarmValue);
            }
            if (typeof aws.alarmCheckMins === "string") {
              aws.alarmCheckMins = parseFloat(aws.alarmCheckMins);
            }
            aws.aggregating = !!aws.aggregating;
            aws.alarmOperator = AlarmOperators.gte;
            // new fields
            aws.alarmShowTime = true;
            aws.alarmShowDate = false;
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
            aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          }
          if (wds && wds.streamOptions.length) {
            wds.streamOptions[0].Params = {
              aggType: 4,
              aggTypeString: 4,
              multiplier: 1,
              postProcessIfAvailable: 0,
              yaxis: 0
            };
            if (wds.streamOptions.length > 1) {
              wds.streamOptions = wds.streamOptions.splice(0, 1);
            }
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "reporting":
      case "reportingmkii": {
        if (!widgetConfig.version) {
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (wds) {
            if (widgetConfig.widgetType === "reporting") {
              wds.round = false;
            } else {
              wds.round = true;
            }
            // migrate GroupsStreams to streamOptions
            const groups = new Set<string>();
            const streamOptions: StreamOption[] = [];
            const wdsAny = wds as any;
            let streamIndex = 0;
            if (wdsAny.GroupsStreams && wdsAny.GroupsStreams.length) {
              wdsAny.GroupsStreams.forEach((group: any) => {
                const groupName = group.name ? group.name : "";
                groups.add(groupName);
                let groupIndex = 0;
                for (const value of groups) {
                  if (value === groupName) {
                    break; // this will stop the iteration
                  }
                  groupIndex++;
                }
                if (group.streams && group.streams.length) {
                  group.streams.forEach((streamOption: any) => {
                    const so = wds.streamOptions[streamIndex];
                    streamOptions.push({
                      type: "",
                      hexStreamColor: ColorHelper.random(),
                      StreamKey: streamOption.StreamKey,
                      Name: streamOption.Name,
                      Label: streamOption.Label,
                      period: "",
                      stackedType: "",
                      streamColor: "",
                      Params: {
                        aggType: 4,
                        aggTypeString: 4,
                        multiplier: so?.Params?.multiplier ? parseFloat(so.Params.multiplier as any) : 1,
                        postProcessIfAvailable: so?.Params?.postProcessIfAvailable ? 1 : 0,
                        yaxis: 0,
                        groupIndex: groupIndex,
                        skipInTotal: !!so?.Params?.skipInTotal,
                        IsAccumulating: !!so?.Params?.IsAccumulating
                      }
                    });
                    streamIndex++;
                  });
                }
              });
              delete wdsAny.GroupsStreams;
            }
            wds.groupNames = Array.from(groups);
            wds.streamOptions = streamOptions;
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.widgetType = "reporting";
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "dashboardwidget": {
        if (!widgetConfig.version) {
          let aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (aws) {
            // migrate trigger levels
            if (!aws.triggerLevels) {
              aws.triggerLevels = [];
            }
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
            aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          }
          if (wds) {
            this.migrateWDS(widgetConfig.widgetType, aws, wds);
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "peakdemand": {
        if (!widgetConfig.version) {
          let aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (aws) {
            if (!aws.target) {
              aws.target = 0;
            } else if (typeof aws.target === "string") {
              aws.target = parseFloat(aws.target);
            }
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
            aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          }
          if (wds) {
            if (typeof wds.aggPeriod === "undefined") {
              wds.aggPeriod = AggregationPeriod.Hourly;
            }
            if (typeof wds.autoAggPeriod === "undefined") {
              wds.autoAggPeriod = true;
            }
            wds.aggType = AggregationType.Max;
            this.migrateWDS(widgetConfig.widgetType, aws, wds);
            wds.streamOptions.forEach(so => {
              so.Params.aggType = AggregationType.Max;
            });
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = "vue_peakdemand_001";
        }
        if (widgetConfig.version === "vue_peakdemand_001") {
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            aws.text = "Peak Demand";
            aws.dataIcon = "plug";
          }
          widgetConfig.version = "vue_peakdemand_002"
        }
        break;
      }
      case "conditiondurationwidget":
      case "piechartwidget":
      case "donutwidget":
      case "activitygaugewidget":
      case "columnrangewidget":
      case "stackedchartwidget":
      case "funnelwidget":
      case "heatmap":
      case "radialgaugehc":
      case "stringchart":
      case "baselinechart":
      case "baselinemodelchart":
      case "periodcompare":
      case "datagridwidget":
      case "rawdatawidget":
      case "leaderboardwidget":
      case "tariffsimulator":
      case "temperature":
      case "humidity":
      case "powerusedgenerated": {
        const preVueWidget = !widgetConfig.version;
        if (preVueWidget) {
          if (widgetConfig.widgetType === "donutwidget") {
            // migrate "donutwidget" to "piechartwidget"
            widgetConfig.widgetType = "piechartwidget";
          }
          if (widgetConfig.widgetType === "baselinemodelchart" || widgetConfig.widgetType === "periodcompare") {
            // migrate "baselinemodelchart" and "periodcompare" to "baselinechart"
            widgetConfig.widgetType = "baselinechart";
          }
          let aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (aws) {
            if (widgetConfig.widgetType === "tariffsimulator") {
              const awsAny = aws as any;
              if (awsAny.tariffs && awsAny.tariffs.length) {
                const tariffs: string[] = [];
                awsAny.tariffs.forEach((tariff: any) => {
                  if (tariff.Id) {
                    tariffs.push(tariff.Id);
                  }
                });
                awsAny.tariffs = tariffs;
              } else {
                awsAny.tariffs = [];
              }
              if (awsAny.currentTariff && awsAny.currentTariff.Id) {
                awsAny.currentTariff = awsAny.currentTariff.Id;
              } else {
                awsAny.currentTariff = null;
              }
            }
            if (widgetConfig.widgetType === "leaderboardwidget") {
              // migrate orderSettings to aws.leaderboardDirection
              // orderSettings: {
              //   "enableOrder": true,
              //   "descOrder": true,
              //   "fieldNameOrder": "value"
              // }
              aws.leaderboardDirection = (widgetConfig.widgetOptions as any).orderSettings?.descOrder ? "desc" : "asc";
            }
            // add gaugeInnerSize
            if (widgetConfig.widgetType === "radialgaugehc" && !aws.gaugeInnerSize) {
              aws.gaugeInnerSize = 60;
            }
            // fix minMaxRange
            if (aws.minMaxRange) {
              for (let index = 0; index < aws.minMaxRange.length; index++) {
                const element = aws.minMaxRange[index];
                if (!element) {
                  aws.minMaxRange[index] = 0;
                } else if (typeof element === "string") {
                  aws.minMaxRange[index] = parseFloat(element);
                }
              }
            }
            if (widgetConfig.widgetType === "datagridwidget") {
              const awsAny = aws as any;
              if (awsAny.fields && awsAny.fields.length) {
                // migrate table fields
                const tableFields: string[] = [];
                awsAny.fields.forEach((element: any) => {
                  if (!element.hidden && element.field && element.field !== "name") {
                    let field = element.field as string;
                    if (field === "LastValue") {
                      field = "lastValue";
                    } else if (field === "FirstValue") {
                      field = "firstValue";
                    }
                    tableFields.push(field);
                  }
                });
                aws.fields = tableFields;
              }
            }
            // fix decimals
            if (typeof aws.decimals === "string") {
              aws.decimals = parseInt(aws.decimals);
            }
            if (typeof aws.setPoint === "string") {
              aws.setPoint = parseFloat(aws.setPoint);
            }
            if (widgetConfig.widgetType === "piechartwidget" && !aws.widgetDonutInnerSize) {
              // add donut fields to pie
              aws.widgetDonutInnerSize = 0;
            }
            if (widgetConfig.widgetType === "stackedchartwidget") {
              aws.stacking = aws.stackingModeNames && aws.stackingMode ?
                aws.stackingModeNames[aws.stackingMode] :
                "normal";
            }
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
            aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          }
          if (wds) {
            this.migrateWDS(widgetConfig.widgetType, aws, wds);
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = latestVersion;
        }
        if (widgetConfig.widgetType === "heatmap" && (preVueWidget || widgetConfig.version === "vue_heatmap_001")) {
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (wds) {
            wds.aggPeriod = AggregationPeriod.Hourly;
            wds.autoAggPeriod = false;
          }
          widgetConfig.version = "vue_heatmap_002";
        }
        if (widgetConfig.widgetType === "heatmap" && (preVueWidget || widgetConfig.version === "vue_heatmap_002")) {
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            aws.autoMinMax = false;
          }
          widgetConfig.version = "vue_heatmap_003";
        }
        if (widgetConfig.widgetType === "powerusedgenerated" && (preVueWidget || widgetConfig.version === "vue_powerusedgenerated_001")) {
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (wds) {
            wds.streamOptions?.forEach(streamOption => {
              streamOption.text = "";
            })
          }
          widgetConfig.version = "vue_powerusedgenerated_002";
        }
        if (widgetConfig.widgetType === "baselinechart" && (preVueWidget || widgetConfig.version === "vue_baselinechart_001")) {
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (wds) {
            wds.streamOptions?.forEach(streamOption => {
              streamOption.hexStreamColor2 = "#00b1f0";
            })
          }
          widgetConfig.version = "vue_baselinechart_002";
        }
        // there was an error in versioning, i.e. vue_rawdatawidget_001 was assigned to the version field
        if (widgetConfig.widgetType === "leaderboardwidget" && (preVueWidget || widgetConfig.version === "vue_rawdatawidget_001")) { 
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            aws.dataConditions = [];
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
          }
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (wds) {
            wds.streamOptions?.forEach(streamOption => {
              streamOption.linkType = 0;
              streamOption.link = "";
              streamOption.linkDashboardType = DashboardType.Organisation;
            });
          }
          widgetConfig.version = "vue_leaderboardwidget_002";
        }
        break;
      }
      case "powerusage": {
        if (!widgetConfig.version) {
          let aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (aws) {
            aws.widgetUnit = "kWh";
            aws.dataIcon = "electricity"
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
            aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          }
          if (wds) {
            this.migrateWDS(widgetConfig.widgetType, aws, wds);
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = "vue_powerusage_001";
        }
        if (widgetConfig.version === "vue_powerusage_001") {
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (wds) {
            wds.streamOptions.forEach(streamOption => {
              streamOption.hexStreamColor = "#FF8F2E";
            });
          }
          widgetConfig.version = "vue_powerusage_002";
        }
        if (widgetConfig.version === "vue_powerusage_002") {
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            aws.widgetMode = "arrowsOnly";
          }
          widgetConfig.version = "vue_powerusage_003";
        }
        if (widgetConfig.version === "vue_powerusage_003") {
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            aws.enableOptionalTimestamp = false;
            aws.enableOptionalLastPeriodValue = false;
            aws.dataNegativeState = "increase";
          }
          widgetConfig.version = "vue_powerusage_004";
        }
        if (widgetConfig.version === "vue_powerusage_004") {
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            aws.dataConditions = [];
            aws.dataConditionsMode = "percent";
          }
          widgetConfig.version = "vue_powerusage_005";
        }
        break;
      }
      case "solargenerated": {
        if (!widgetConfig.version) {
          let aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (aws) {
            // nothing
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
            aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          }
          if (wds) {
            this.migrateWDS(widgetConfig.widgetType, aws, wds);
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "tariffcalculator": {
        if (!widgetConfig.version) {
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (wds) {
            // migrate dates
            if (wds.startDate) {
              wds.startDate = wds.startDate.replaceAll("/", "-");
            }
            if (wds.endDate) {
              wds.endDate = wds.endDate.replaceAll("/", "-");
            }
            // fix types
            wds.includeGST = !!wds.includeGST;
            if (typeof wds.ReadingStart === "string") {
              wds.ReadingStart = parseFloat(wds.ReadingStart);
            }
            if (typeof wds.ReadingEnd === "string") {
              wds.ReadingEnd = parseFloat(wds.ReadingEnd);
            } 
            // add new field
            wds.kW = 0;
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = latestVersion;
        }
        break;
      }
      case "imagefreeformwidget": {
        if (widgetConfig.version === "vue_imagefreeformwidget_001") {
          const wds = widgetConfig.widgetOptions.widgetDataSettings;
          if (wds) {
            if (wds.streamOptions?.length) {
              wds.streamOptions?.forEach((so) => {
                so.autoMinMax = false;
              });
            }
          } else {
            widgetConfig.widgetOptions.widgetDataSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.widgetDataSettings));
          }
          widgetConfig.version = "vue_imagefreeformwidget_002";
        }
        break;
      }
      case "mapwidget": {
        if (widgetConfig.version === "vue_mapwidget_001") {
          const aws = widgetConfig.widgetOptions.advancedWidgetSettings;
          if (aws) {
            aws.minHeight = 400;
          } else {
            widgetConfig.widgetOptions.advancedWidgetSettings = 
              JSON.parse(JSON.stringify(widgetDescription?.defaultOptions?.advancedWidgetSettings));
          }
          widgetConfig.version = "vue_mapwidget_002";
        }
        break;
      }
    }
  }
}

export default new WidgetMigrationHelper();
