<template>
  <div>
    <LMap 
      ref="map"
      @ready="onReady"
      style="height: 100%"
      :zoom="zoom"
      @update:zoom="zoomUpdate"
      :center="center"
      @update:center="centerUpdate"
      :options="{ attributionControl: false, keyboard: false }"
    >
      <template v-if="mapReady">        
        <LMarker
          v-for="(item, index) in markers"
          :key="index"
          :lat-lng="item.latLng"
          :icon="buildMarker(item.color)"
          @click="clickMarker(item)"
        >
          <LTooltip :options="{ permanent: false, direction: 'top', className: `tooltip-colorized-${index}` }">
            <span>
              <b>{{ item.name }}</b>
            </span>
          </LTooltip>
        </LMarker>
      </template>
    </LMap>
    <!-- <span style="display:none" v-html="styleTooltipSiteColors"></span> -->
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { useNavTreeStore } from "@/stores/navTree";
import { LMap, LTileLayer, LMarker, LTooltip } from "@vue-leaflet/vue-leaflet";
import L from "leaflet";
import { v4 as uuidv4 } from "uuid";
import { generateColorRGB } from "@marko19907/string-to-color";
import ColorHelper from "@/helpers/ColorHelper";
import chroma from "chroma-js";

@Component({
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LTooltip
  },
})
class TagManagerMapView extends Vue {
  @Emit siteChanged() {
    return this.selectedModel;
  }

  selectedModel: TreeNodeForUI | null = null;

  navTreeStore = useNavTreeStore();
  
  get modelForTree(): TreeNodeForUI[] {
    return this.navTreeStore.structuredDataForUI ?? [];
  }

  zoom = 8;
  zoomUpdate(value: number): void {
    this.zoom = value;
  }
  
  center = [-27.3793729, 152.3337157];
  centerUpdate(value: any): void {
    // example: {lat: 1091.1028537158786, lng: 420.04585553556507}
    this.center = [value.lat, value.lng];
  }

  getMarkerSvg(color: string): string {
    const guid = uuidv4();
    const svg = `<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 28 28"><g filter="url(#a-${guid})"><path fill="url(#b-${guid})" d="M13.5 3A7.539 7.539 0 1 1 6 10.54 7.52 7.52 0 0 1 13.5 3Z"/></g><g filter="url(#c-${guid})"><path fill="#fff" d="M13.5 15a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9Z"/><path stroke="${color}" stroke-linecap="round" stroke-linejoin="round" d="M13.5 14.5a4 4 0 1 0 0-8 4 4 0 0 0 0 8Z"/></g><defs><filter id="a-${guid}" width="27.078" height="27.078" x="0" y="0" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="3"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_918_22"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_918_22" result="shape"/></filter><filter id="c-${guid}" width="21" height="21" x="3" y="3" color-interpolation-filters="sRGB" filterUnits="userSpaceOnUse"><feFlood flood-opacity="0" result="BackgroundImageFix"/><feColorMatrix in="SourceAlpha" result="hardAlpha" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/><feOffset dy="3"/><feGaussianBlur stdDeviation="3"/><feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/><feBlend in2="BackgroundImageFix" result="effect1_dropShadow_918_22"/><feBlend in="SourceGraphic" in2="effect1_dropShadow_918_22" result="shape"/></filter><radialGradient id="b-${guid}" cx="0" cy="0" r="1" gradientTransform="matrix(10.7959 0 0 8.25883 13.539 8.884)" gradientUnits="userSpaceOnUse"><stop stop-color="${color}"/><stop offset="1" stop-color="${color}" stop-opacity=".631"/></radialGradient></defs></svg>`;
    return svg;
  }

  buildMarker(color: string): L.DivIcon {
    // from https://stackoverflow.com/questions/23567203/leaflet-changing-marker-color
    const markerHtmlStyles = `
      width: 44px;
      height: 44px;
      display: block;
      position: relative;
      transform: translate(1px, 16px)`;
    const svg = this.getMarkerSvg(color);
    const icon = L.divIcon({
      className: "bitpool-custom-marker",
      iconSize: [44, 44],
      iconAnchor: [22, 44],
      tooltipAnchor: [0, -24],
      popupAnchor: [0, -24],
      html: `<span style="${markerHtmlStyles}">${svg}</span>`
    });
    return icon;
  }

  mapObject: any | null = null;
  mapReady = false;

  onReady(): void {
    if (this.$refs.map) {
      this.mapObject = (this.$refs.map as any).leafletObject;

      L.gridLayer
        .googleMutant({
          type: "roadmap", // valid values are 'roadmap', 'satellite', 'terrain' and 'hybrid'
        })
        .addTo(this.mapObject);

      this.mapReady = true;

      const coords: L.LatLng[] = [];
      this.markers.forEach((marker) => {
        coords.push(L.latLng(marker.latLng.lat, marker.latLng.lng));
      });
      const bounds = L.latLngBounds(coords)
      if (bounds.isValid()) {
        this.mapObject.fitBounds(bounds);
      }
    }
  }

  get markers(): any[] {
    const result: any[] = [];
    this.modelForTree.forEach((model, index) => {
      let geoCoordTag = model.tags?.find(x => x.startsWith("geoCoord="));
      if (!geoCoordTag) {
        // non standard
        const geoLatTag = model.tags?.find(x => x.startsWith("geoLat="));
        const geoLngTag = model.tags?.find(x => x.startsWith("geoLon="));
        if (geoLatTag && geoLngTag) {
          geoCoordTag = `geoCoord=${geoLatTag.replace("geoLat=", "")},${geoLngTag.replace("geoLon=", "")}`;
        }
      }
      if (geoCoordTag) {
        const coords = geoCoordTag.split("=")[1].split(",");
        if (coords.length === 2) {
          const lat = parseFloat(coords[0]);
          const lng = parseFloat(coords[1]);
          if (!isNaN(lat) && !isNaN(lng) && (lat !== 0 || lng !== 0)) {
            const name = model.label;
            // ColorHelper.getContrastColor doesn't work with hsl and rgbs, so we need to convert to hex
            // example: rgba(114.3853541438999, 223.125, 31.875, 1)
            const colorRGBA = generateColorRGB(name ?? model.key ?? "");
            const values = colorRGBA.replace("rgba(", "").replace(")", "").split(",");
            const colorHex = chroma.rgb(parseFloat(values[0]), parseFloat(values[1]), parseFloat(values[2]), parseFloat(values[3])).hex();
            result.push({ 
              index: index,
              latLng: { lat: lat, lng: lng }, 
              color: colorHex,
              name: name,
              node: model
            });
          }
        }
      }
    });
    // result.push({ 
    //   index: 123,
    //   latLng: { lat: 0, lng: 0 }, 
    //   color: "#000",
    //   name: "Test 0, 0"
    // });
    return result;
  }

  clickMarker(item: any): void {
    this.selectedModel = item.node;
    this.siteChanged();
  }

  get styleTooltipSiteColors(): string {
    let result = "";
    this.markers.forEach((marker, index) => {
      const color = marker.color;
      const fontColor = ColorHelper.getContrastColor(color);
      result += `.tooltip-colorized-${index} { --freeform-tooltip-bg-color: ${color}; --freeform-tooltip-color: ${fontColor}; }`
    });
    result = `<style>${result}</style>`;
    return result;
  }
}

export default TagManagerMapView;
</script>