<template>
<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 26 26"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="M9.508 9.4a3.6 3.6 0 0 1 6.996 1.2c0 2.4-3.6 3.6-3.6 3.6v2.3M13 19h.012M25 13c0 6.627-5.373 12-12 12S1 19.627 1 13 6.373 1 13 1s12 5.373 12 12Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class HelpIconSvg extends Vue {
}

export default HelpIconSvg;
</script>