import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { v4 as uuidv4 } from "uuid";
import DashboardState from "../states/DashboardState";
import { Space } from "@/models/dashboard/Space";
import DashboardMigrationHelper from "@/helpers/DashboardMigrationHelper";
import { WidgetConfig } from "@/models/dashboard/WidgetConfig";
import SpaceHelper from "@/helpers/SpaceHelper";
import WidgetMigrationHelper from "@/helpers/WidgetMigrationHelper";
import { GDRSModel } from "@/models/dashboard/GDRSModel";
import CustomWindow from '@/CustomWindow';
import { StreamModel } from "@/models/StreamModel";
import DateHelper from "@/helpers/DateHelper";
import { StreamDataType } from "@/models/enums/StreamDataType";
import { PostProcessingType } from "@/models/enums/PostProcessingType";
import { StreamVirtualType } from "@/models/enums/StreamVirtualType";
import WeatherHelper from "@/helpers/WeatherHelper";
import { Permission } from "@/models/enums/Permission";
import { BitpoolOSPermissions } from "@/models/BitpoolOSPermissions";
import { CopySpaceModel } from "@/models/dashboard/CopySpaceModel";
import { AggregationPeriod } from "@/models/enums/AggregationPeriod";
import { SpaceWidgetConfig } from "@/models/dashboard/SpaceWidgetConfig";
import { SpaceSettingsModel } from "@/models/dashboard/SpaceSettingsModel";
import { DashboardType } from "@/models/dashboard/DashboardType";
import { useOrganisationStore } from "@/stores/organisation";
import localeManager from "@/localeManager";

declare const window: CustomWindow;

const getters = <GetterTree<DashboardState, any>>{
  getDashboards(state): Space[] | null {
    return state.dashboardType === DashboardType.Organisation ?
      state.dashboards :
      state.dashboardType === DashboardType.Personal ?
        state.dashboardsPersonal :
        state.dashboardType === DashboardType.Shared ?
          state.dashboardsShared :
          state.dashboardsSlides;
  },
  getSelectedDashboards(state): Record<string, boolean> {
    return state.dashboardType === DashboardType.Organisation ?
      state.selectedDashboardsOrganisation :
      state.selectedDashboardsPersonal;
  },
  getSelectedDashboardsCountCurrent(state): number {
    return state.dashboardType === DashboardType.Organisation ?
      Object.keys(state.selectedDashboardsOrganisation).length :
      Object.keys(state.selectedDashboardsPersonal).length;
  },
  getSelectedDashboardsCountTotal(state): number {
    return Object.keys(state.selectedDashboardsOrganisation).length + Object.keys(state.selectedDashboardsPersonal).length;
  },
  isFullAccess(state, getters, rootState): boolean {
    if (rootState.auth.permissions) {
      const permissions = rootState.auth.permissions as BitpoolOSPermissions;
      if (permissions.FullAccess || permissions.GroupAccess) {
        return true;
      }
    }
    return false;
  },
  getPermissions(state, getters, rootState): (id: string) => Permission {
    return (id: string): Permission => {
      if (rootState.auth.permissions) {
        const permissions = rootState.auth.permissions as BitpoolOSPermissions;
        if (permissions.FullAccess || permissions.GroupAccess || permissions.DashboardsWriteAll) {
          return Permission.Write;
        }
        if (permissions.Spaces) {
          const permission = permissions.Spaces.find(x => x.Id === id);
          if (permission) {
            return permission.Permission;
          }
        }
        if (state.dashboardsPersonal?.find(x => x._id === id)) {
          return Permission.Write;
        }
        if (permissions.DashboardsReadAll) {
          return Permission.Read;
        }
      }
      return Permission.Disable;
    }
  },
  getCommentsPermissions(state, getters, rootState): Permission {
    if (rootState.auth.permissions) {
      const permissions = rootState.auth.permissions as BitpoolOSPermissions;
      if (permissions.FullAccess) {
        return Permission.Write;
      }
      return permissions.Comments;
    }
    return Permission.Disable;
  },
  canCreateDashboard(state, getters, rootState): boolean {
    if (rootState.auth.permissions) {
      const permissions = rootState.auth.permissions as BitpoolOSPermissions;
      if (permissions.FullAccess || permissions.GroupAccess || permissions.DashboardsWriteAll) {
        return true;
      }
      if (state.dashboardType === DashboardType.Personal) {
        return true;
      }
    }
    return false;
  },
  canDeleteDashboard(state, getters, rootState): boolean {
    if (rootState.auth.permissions) {
      const permissions = rootState.auth.permissions as BitpoolOSPermissions;
      if (permissions.FullAccess || permissions.GroupAccess || permissions.DashboardsWriteAll) {
        return true;
      }
      if (state.dashboardType === DashboardType.Personal) {
        return true;
      }
    }
    return false;
  },
  canEditDashboard(state, getters): (id: string) => boolean {
    return (id: string): boolean => {
      const permission = (getters.getPermissions as (id: string) => Permission)(id);
      return permission === Permission.Write;
    }
  }
};

const mutations = <MutationTree<DashboardState>>{
  setEditMode(state, value: boolean): void {
    state.editMode = value;
  },
  setEditModeTree(state, value: boolean): void {
    state.editModeTree = value;
    state.editModeTreeAnimation = value;
    if (value) {
      window.setTimeout(() => {
        state.editModeTreeAnimation = false;
      }, 2000);
    }
  },
  unloadDashboards(state): void {
    state.searchDashboard = "";
    state.searchDashboardResult = [];
    state.dashboardId = null;
    state.dashboardType = DashboardType.Organisation;
    state.currentDashboard = null;
    state.dashboards = null;
    state.dashboardsPersonal = null;
    state.widgets = null;
    state.isLoaded = false;
    state.isLoadedWidgets = false;
    state.openDashboards = {};
    state.selectedDashboardsOrganisation = {};
    state.selectedDashboardsPersonal = {};
    state.copiedDashboards = null;
    state.copiedWidget = null;
  },
  openDashboard(state, id: string): void {
    state.openDashboards[id] = true;
  },
  closeDashboard(state, id: string): void {
    state.openDashboards[id] = false;
  },
  toggleDashboard(state, id: string): void {
    state.openDashboards[id] = !state.openDashboards[id];
  },
  selectDashboard(state, payload: { dashboardType: string, id: string }): void {
    if (payload.dashboardType === DashboardType.Organisation) {
      state.selectedDashboardsOrganisation[payload.id] = true;
    } else {
      state.selectedDashboardsPersonal[payload.id] = true;
    }
  },
  unslectDashboard(state, payload: { dashboardType: string, id: string }): void {
    if (payload.dashboardType === DashboardType.Organisation) {
      state.selectedDashboardsOrganisation[payload.id] = false;
    } else {
      state.selectedDashboardsPersonal[payload.id] = false;
    }
  },
  unslectDashboardsAll(state): void {
    state.selectedDashboardsOrganisation = {};
    state.selectedDashboardsPersonal = {};
  },
  toggleSelectDashboard(state, payload: { dashboardType: string, id: string }): void {
    if (payload.dashboardType === DashboardType.Organisation) {
      state.selectedDashboardsOrganisation[payload.id] = !state.selectedDashboardsOrganisation[payload.id];
    } else {
      state.selectedDashboardsPersonal[payload.id] = !state.selectedDashboardsPersonal[payload.id];
    }
  },
  copyDashboards(state, data: { dashboards: Space[] | null, withChildren: boolean }): void {
    state.copiedDashboards = data.dashboards;
    state.copiedWithChildren = data.withChildren;
  },
  copyWidget(state, data: [boolean, string, string, SpaceWidgetConfig, WidgetConfig[]] | null): void {
    state.copiedWidget = data;
  },
  setDashboardId(state, dashboardId: string | null | undefined): void {
    state.dashboardId = dashboardId;
  },
  setDashboardType(state, dashboardType: DashboardType.Organisation): void {
    state.dashboardType = dashboardType ? dashboardType : DashboardType.Organisation;
  },
  searchDashboard(state, search: string): void {
    state.searchDashboard = search;
    state.searchDashboardResult = [];
    if (search) {
      // search by name, then show nodes, childrens(node id in children path) and parents(parent id in node path)
      const dashboards = state.dashboardType === DashboardType.Organisation ?
        state.dashboards :
        state.dashboardType === DashboardType.Personal ?
          state.dashboardsPersonal : 
          state.dashboardsShared;
      const regex = new RegExp(search, "i");
      const searchResult = dashboards?.filter(x => x.spaceName && x.spaceName.match(regex));
      if (searchResult && searchResult.length) {
        searchResult.forEach(x => {
          if (x._id) {
            state.searchDashboardResult.push(x._id);
            const children = dashboards?.filter(y => x._id && y.path && y.path.includes(x._id))
            if (children && children.length) {
              children.forEach(y => {
                if (y._id) {
                  state.searchDashboardResult.push(y._id);
                }
              })
            }
            const parent = dashboards?.filter(y => x.path && y._id && x.path.includes(y._id))
            if (parent && parent.length) {
              parent.forEach(y => {
                if (y._id) {
                  state.searchDashboardResult.push(y._id);
                }
              })
            }
          }
        });
      }
    }
  },
  unloadStream(state, streamKey: string) {
    delete state.streams[streamKey];
  },
  startDrag(state, space: Space): void {
    state.spaceDragId = space._id;
  },
  stopDrag(state): void {
    clearTimeout(state.spaceDragAutoOpenTimeout);
    state.spaceDragAutoOpenTimeout = 0;
    state.spaceDragId = undefined;
    state.spaceDragToId = undefined;
  },
  startDragTimer(state, toSpace: Space): void {
    if (toSpace._id && state.spaceDragId !== toSpace._id && !state.openDashboards[toSpace._id] && state.spaceDragToId !== toSpace._id) {
      const id = toSpace._id;
      state.spaceDragToId = id;
      if (state.spaceDragAutoOpenTimeout) {
        clearTimeout(state.spaceDragAutoOpenTimeout);
      }
      state.spaceDragAutoOpenTimeout = window.setTimeout(() => {
        state.openDashboards[id] = true;
      }, 600);
    }
  }
};

const actions = <ActionTree<DashboardState, any>>{
  async reloadDashboards({ state, rootState, dispatch }) {
    if (state.dashboardType === DashboardType.Organisation) {
      await dispatch("loadOrganisation", { id: state.dashboardId, silent: true });
    } else if (state.dashboardType === DashboardType.Personal) {
      await dispatch("loadPersonal", { id: state.dashboardId, silent: true });
    } else if (state.dashboardType === DashboardType.Shared) {
      await dispatch("loadShared", { id: state.dashboardId, silent: true });
    } else if (state.dashboardType === DashboardType.Slides) {
      await dispatch("loadSlides", { id: state.dashboardId, silent: true });
    }
  },
  async loadOrganisation({ state, rootState, dispatch, commit }, payload: { id: string | null, silent: boolean }) {
    try {
      const id = payload.id;
      const silent = payload.silent;
      const guid = uuidv4();
      state.guidDashboard = guid;
      if (!silent) {
        state.isLoaded = false;
        state.dashboards = null;
      }
      const organisationStore = useOrganisationStore();
      const organisationId = organisationStore.currentOrganisation?.Id;
      const url = `rest/BitPool_V2/os/space2?organisationId=${organisationId}`;
      const response = await axios.get<Space[]>(url);
      if (state.guidDashboard === guid) {
        state.dashboards = response.data;
        const dashboard = state.dashboards.find(x => x._id === id);
        if (id && (!silent || !dashboard)) {
          commit("setDashboardId", dashboard ? id : undefined);
          await dispatch("setCurrentDashboard", dashboard);
        }
        state.isLoaded = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load organisation dashboards",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.dashboards = null;
      state.isLoaded = true;
    }
  },
  async loadPersonal({ state, dispatch, commit }, payload: { id: string | null, silent: boolean }) {
    try {
      const id = payload.id;
      const silent = payload.silent;
      const guid = uuidv4();
      state.guidDashboardPersonal = guid;
      if (!silent) {
        state.isLoaded = false;
        state.dashboardsPersonal = null;
      }
      const url = `rest/BitPool_V2/os/space2`;
      const response = await axios.get<Space[]>(url);
      if (state.guidDashboardPersonal === guid) {
        state.dashboardsPersonal = response.data;
        const dashboard = state.dashboardsPersonal.find(x => x._id === id);
        if (id && (!silent || !dashboard)) {
          commit("setDashboardId", dashboard ? id : undefined);
          await dispatch("setCurrentDashboard", dashboard);
        }
        state.isLoaded = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load personal dashboards",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.dashboardsPersonal = null;
      state.isLoaded = true;
    }
  },
  async loadShared({ state, dispatch, commit }, payload: { id: string | null, silent: boolean }) {
    try {
      const id = payload.id;
      const silent = payload.silent;
      const guid = uuidv4();
      state.guidDashboardShared = guid;
      if (!silent) {
        state.isLoaded = false;
        state.dashboardsShared = null;
      }
      const path = window.location.pathname;
      const parts = path.split("/");
      const sharedSpaceId = parts[parts.length - 1];
      const url = `rest/BitPool_V2/os/space/shared/${sharedSpaceId}`;
      const response = await axios.get<Space[]>(url);
      if (state.guidDashboardShared === guid) {
        state.dashboardsShared = response.data;
        const dashboard = state.dashboardsShared.find(x => x._id === id);
        if (id && (!silent || !dashboard)) {
          commit("setDashboardId", dashboard ? id : undefined);
          await dispatch("setCurrentDashboard", dashboard);
        }
        state.isLoaded = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load shared dashboards",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.dashboardsShared = null;
      state.isLoaded = true;
    }
  },
  async loadSlides({ state, dispatch, commit }, payload: { id: string | null, silent: boolean }) {
    try {
      const id = payload.id;
      const silent = payload.silent;
      const guid = uuidv4();
      state.guidDashboardSlides = guid;
      if (!silent) {
        state.isLoaded = false;
        state.dashboardsSlides = null;
      }
      const path = window.location.pathname;
      const parts = path.split("/");
      const sharedSpaceId = parts[parts.length - 1];
      const url = `rest/BitPool_V2/os/spaces/shared/${sharedSpaceId}`;
      const response = await axios.get<Space[]>(url);
      if (state.guidDashboardSlides === guid) {
        state.dashboardsSlides = response.data;
        const dashboard = state.dashboardsSlides.find(x => x._id === id);
        if (id && (!silent || !dashboard)) {
          commit("setDashboardId", dashboard ? id : undefined);
          await dispatch("setCurrentDashboard", dashboard);
        }
        state.isLoaded = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load slides",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.dashboardsSlides = null;
      state.isLoaded = true;
    }
  },
  async saveDashboard({ state, rootGetters }, payload: { dashboard: Space, organisationId: number | undefined }) {
    const dashboard = payload.dashboard;
    const organisationId = payload.organisationId;
    const newDashboardId = "new_dashboard";
    const dashboardId = dashboard?._id ? dashboard._id : newDashboardId;
    try {
      if (rootGetters["auth/getDashboardReadOnlyMode"]) {
        if (!rootGetters["auth/getJailMode"]) {
          ToastService.showToast(
            "warn",
            "Read only mode",
            "Modern dashboard in read only mode, you can't save any changes.",
            5000
          );
        }
      } else if (dashboard) {
        state.dashboardState[dashboardId] = [false, true, null];
        let url = `rest/bitpool_v2/os/space2`;
        if (organisationId) {
          url = `${url}?organisationId=${organisationId}`;
        }
        const response = await axios.put<string>(url, dashboard);
        if (!dashboard._id) {
          // new dashboard
          dashboard._id = response.data;
          if (organisationId) {
            state.dashboards?.push(dashboard);
          } else {
            state.dashboardsPersonal?.push(dashboard);
          }
        }
        state.dashboardState[dashboardId][0] = true;
        state.dashboardState[dashboardId][1] = false;
      }
    } catch (error) {
      const message = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        "Can't save dashboard",
        message,
        5000
      );
      state.dashboardState[dashboardId] = [false, false, message];
    }
  },
  async saveDashboards({ state, rootGetters }, payload: { dashboards: Space[], organisationId: number | undefined }) {
    const dashboards = payload.dashboards;
    const organisationId = payload.organisationId;
    try {
      if (rootGetters["auth/getDashboardReadOnlyMode"]) {
        if (!rootGetters["auth/getJailMode"]) {
          ToastService.showToast(
            "warn",
            "Read only mode",
            "Modern dashboard in read only mode, you can't save any changes.",
            5000
          );
        }
      } else if (dashboards && dashboards.length) {
        let url = `rest/bitpool_v2/os/space2/many`;
        if (organisationId) {
          url = `${url}?organisationId=${organisationId}`;
        }
        const response = await axios.put<string[]>(url, dashboards);
        for (let index = 0; index < dashboards.length; index++) {
          const element = dashboards[index];
          if (!element._id) {
            element._id = response.data[index];
            if (organisationId) {
              state.dashboards?.push(element);
            } else {
              state.dashboardsPersonal?.push(element);
            }
          }
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save dashboards",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async copyDashboards({ state, rootGetters, dispatch }, payload: { input: CopySpaceModel[], organisationId: number | undefined, copyChildSpaces: boolean }) {
    const input = payload.input;
    const organisationId = payload.organisationId;
    const copyChildSpaces = payload.copyChildSpaces;
    try {
      if (rootGetters["auth/getDashboardReadOnlyMode"]) {
        if (!rootGetters["auth/getJailMode"]) {
          ToastService.showToast(
            "warn",
            "Read only mode",
            "Modern dashboard in read only mode, you can't save any changes.",
            5000
          );
        }
      } else if (input && input.length) {
        let url = `rest/bitpool_v2/os/space2/copy/many`;
        if (organisationId) {
          url = `${url}?organisationId=${organisationId}&copyChildSpaces=${copyChildSpaces}`;
        } else {
          url = `${url}?copyChildSpaces=${copyChildSpaces}`;
        }
        await axios.post<CopySpaceModel[]>(url, input);
        await dispatch("reloadDashboards");
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't copy dashboards",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async deleteDashboards({ state, rootGetters, dispatch }, payload: { ids: string[], organisationId: number | undefined, deleteChildSpaces: boolean }) {
    const ids = payload.ids;
    const organisationId = payload.organisationId;
    const deleteChildSpaces = payload.deleteChildSpaces;
    try {
      if (rootGetters["auth/getDashboardReadOnlyMode"]) {
        if (!rootGetters["auth/getJailMode"]) {
          ToastService.showToast(
            "warn",
            "Read only mode",
            "Modern dashboard in read only mode, you can't save any changes.",
            5000
          );
        }
      } else if (ids && ids.length) {
        let url = `rest/bitpool_v2/os/space/many`;
        if (organisationId) {
          url = `${url}?organisationId=${organisationId}&deleteChildSpaces=${deleteChildSpaces}`;
        } else {
          url = `${url}?deleteChildSpaces=${deleteChildSpaces}`;
        }
        await axios.delete(url, {
          headers: {
            "Content-Type": "application/json",
          },
          data: ids,
        });
        await dispatch("reloadDashboards");
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't delete dashboards",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async setCurrentDashboard({ state, dispatch, getters, commit }, dashboard: Space | null) {
    let isOk = true;
    if (state.editMode && (!dashboard || !getters.canEditDashboard(dashboard._id))) {
      commit("setEditMode", false);
    }
    if (dashboard && !dashboard.version) {
      // migrate to vue
      try {
        DashboardMigrationHelper.migrate(dashboard);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't migrate dashboard",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        isOk = false;
      }
    }
    if (isOk) {
      state.currentDashboard = dashboard;
      if (dashboard) {
        // open tree
        const parentIds = dashboard.path?.split(",").filter(x => x);
        if (parentIds && parentIds.length) {
          parentIds.forEach(x => {
            state.openDashboards[x] = true;
          });
        }
        // load widgets
        const widgets = SpaceHelper.getWidgets(dashboard.widgets);
        if (widgets.length) {
          const guids = widgets.map(x => x.guid);
          await dispatch("loadWidgets", guids);
        } else {
          state.widgets = [];
          state.isLoadedWidgets = true;
        }
      } else {
        state.widgets = null;
        state.isLoadedWidgets = false;
      }
    } else {
      state.currentDashboard = null;
      state.widgets = null;
      state.isLoadedWidgets = false;
    }
  },
  async loadWidgets({ state, rootState }, guids: string[]) {
    try {
      const guid = uuidv4();
      state.guidWidgets = guid;
      state.isLoadedWidgets = false;
      state.widgets = null;
      const url = `rest/bitpool_v2/os/widget`;
      const response = await axios.post<WidgetConfig[]>(url, guids);
      if (state.guidWidgets === guid) {
        let isOk = true;
        response.data.forEach(widget => {
          try {
            const apiUrl = rootState.apiUrl;
            WidgetMigrationHelper.migrate(widget, apiUrl);
          } catch (error) {
            ToastService.showToast(
              "error",
              "Can't migrate widget",
              ErrorHelper.handleAxiosError(error).message,
              5000
            );
            isOk = false;
          }
        });
        if (isOk) {
          state.widgets = response.data;
          state.isLoadedWidgets = true;
        } else {
          state.widgets = null;
          state.isLoadedWidgets = true;
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load widgets",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.widgets = null;
      state.isLoadedWidgets = true;
    }
  },
  async loadSharedWidget({ state, rootState }) {
    try {
      const guid = uuidv4();
      state.guidWidgets = guid;
      state.isLoadedWidgets = false;
      state.widgets = null;
      const path = window.location.pathname;
      const parts = path.split("/");
      const sharedWidgetId = parts[parts.length - 1];
      const url = `rest/bitpool_v2/os/widget/shared/${sharedWidgetId}`;
      const response = await axios.get<WidgetConfig[]>(url);
      if (state.guidWidgets === guid) {
        let isOk = true;
        response.data.forEach(widget => {
          try {
            const apiUrl = rootState.apiUrl;
            WidgetMigrationHelper.migrate(widget, apiUrl);
          } catch (error) {
            ToastService.showToast(
              "error",
              "Can't migrate widget",
              ErrorHelper.handleAxiosError(error).message,
              5000
            );
            isOk = false;
          }
        });
        if (isOk) {
          state.widgets = response.data;
          state.isLoadedWidgets = true;
        } else {
          state.widgets = null;
          state.isLoadedWidgets = true;
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load widgets",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.widgets = null;
      state.isLoadedWidgets = true;
    }
  },
  async saveWidget({ state, rootGetters }, widget: WidgetConfig) {
    try {
      if (rootGetters["auth/getDashboardReadOnlyMode"]) {
        if (!rootGetters["auth/getJailMode"]) {
          ToastService.showToast(
            "warn",
            "Read only mode",
            "Modern dashboard in read only mode, you can't save any changes.",
            5000
          );
        }
        state.widgetState[widget.guid] = [false, false, "Read only mode"];
      } else {
        state.widgetState[widget.guid] = [false, true, null];
        widget.DateUpdated = (new Date()).valueOf();
        const url = `rest/bitpool_v2/os/widget`;
        const response = await axios.put<string>(url, widget);
        if (!widget._id) {
          widget._id = response.data;
        }
        state.widgetState[widget.guid][0] = true;
        state.widgetState[widget.guid][1] = false;
      }
    } catch (error) {
      const message = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        "Can't save widget",
        message,
        5000
      );
      state.widgetState[widget.guid] = [false, false, message];
    }
  },
  async saveWidgets({ state, rootGetters }, widgets: WidgetConfig[]) {
    try {
      if (rootGetters["auth/getDashboardReadOnlyMode"]) {
        if (!rootGetters["auth/getJailMode"]) {
          ToastService.showToast(
            "warn",
            "Read only mode",
            "Modern dashboard in read only mode, you can't save any changes.",
            5000
          );
        }
        widgets.forEach(widget => {
          state.widgetState[widget.guid] = [false, false, "Read only mode"];
        });
      } else {
        const date = (new Date()).valueOf();
        widgets.forEach(widget => {
          state.widgetState[widget.guid] = [false, true, null];
          widget.DateUpdated = date;
        });
        const url = `rest/bitpool_v2/os/widgets`;
        const response = await axios.put<string[]>(url, widgets);
        widgets.forEach((widget, index) => {
          if (!widget._id) {
            widget._id = response.data[index];
          }
          state.widgetState[widget.guid][0] = true;
          state.widgetState[widget.guid][1] = false;
        });
      }
    } catch (error) {
      const message = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        "Can't save widget",
        message,
        5000
      );
      widgets.forEach(widget => {
        state.widgetState[widget.guid] = [false, false, message];
      });
    }
  },
  async deleteWidgets({ state, rootGetters }, guids: string[]) {
    try {
      if (rootGetters["auth/getDashboardReadOnlyMode"]) {
        if (!rootGetters["auth/getJailMode"]) {
          ToastService.showToast(
            "warn",
            "Read only mode",
            "Modern dashboard in read only mode, you can't save any changes.",
            5000
          );
        }
      } else {
        const id = guids.join(",");
        const url = `rest/bitpool_v2/os/widget/${id}`;
        await axios.delete(url);        
      }
    } catch (error) {
      const message = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        "Can't delete widget",
        message,
        5000
      );
    }
  },
  async loadGDRS({ state, rootState }) {
    try {
      const guid = uuidv4();
      state.guidGDRS = guid;
      state.isLoadedGDRS = false;
      state.gdrs = null;
      const url = `rest/bitpool_v2/os/gdrs`;
      const response = rootState.auth.jailMode ? null : await axios.get<GDRSModel>(url);
      if (state.guidGDRS === guid) {
        if (response !== null && response.data) {
          // init new fields
          if (typeof response.data.aggPeriod === "undefined"){
            response.data.aggPeriod = AggregationPeriod.Hourly;
          }
          if (typeof response.data.autoAggPeriod === "undefined"){
            response.data.autoAggPeriod = true;
          }
          state.gdrs = response.data;
          state.isLoadedGDRS = true;
        } else {
          const userData = window.angularUserprofile.getAllUserData();
          state.gdrs = {
            _id: undefined,
            active: false,
            username: userData && userData.userName ? userData.userName : "",
            rangePreset: 2,
            rangePresetHolder: 2,
            startDate: "",
            startTime: "",
            endDate: "",
            endTime: "",
            aggPeriod: AggregationPeriod.Hourly,
            autoAggPeriod: true
          };
          state.isLoadedGDRS = true;
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load gdrs",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.gdrs = null;
      state.isLoadedGDRS = true;
    }
  },
  async saveGDRS({ state }) {
    try {
      if (state.gdrs) {
        const url = `rest/bitpool_v2/os/gdrs`;
        const response = await axios.post<string>(url, state.gdrs);
        if (!state.gdrs._id) {
          state.gdrs._id = response.data;
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't save gdrs",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
    }
  },
  async loadSpaceSettings({ state }) {
    try {
      const guid = uuidv4();
      state.guidSpaceSettings = guid;
      state.isLoadedSpaceSettings = false;
      state.spaceSettings = null;
      const url = `rest/bitpool_v2/os/space-settings`;
      const response = await axios.get<SpaceSettingsModel>(url);
      if (state.guidSpaceSettings === guid) {
        if (response.data) {
          state.spaceSettings = response.data;
          state.isLoadedSpaceSettings = true;
        } else {
          const userData = window.angularUserprofile.getAllUserData();
          state.spaceSettings = {
            _id: undefined,
            username: userData && userData.userName ? userData.userName : "",
            showWalkthrough: true,
            groupTooltips: true,
            disablePlotMarks: false
          };
          state.isLoadedSpaceSettings = true;
        }
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load dashboard settings",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.spaceSettings = null;
      state.isLoadedSpaceSettings = true;
    }
  },
  async saveSpaceSettings({ state }) {
    try {
      if (state.spaceSettings) {
        const url = `rest/bitpool_v2/os/space-settings`;
        const response = await axios.post<string>(url, state.spaceSettings);
        if (!state.spaceSettings._id) {
          state.spaceSettings._id = response.data;
        }
        state.userSettingsSaveError = false;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        localeManager.t("accountSettings.dashboards.saveError"),
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.userSettingsSaveError = true;
    }
  },
  async loadStream({ state }, streamKey: string) {
    try {
      state.streams[streamKey] = [false, null];
      const url = `rest/BitPool_V1/stream/${streamKey}/web`;
      const response = await axios.get<StreamModel>(url);
      if (response.data.LastUpdates) {
        response.data.LastUpdates = DateHelper.parseFromMicrosoftString(
          response.data.LastUpdates as string
        );
      }
      if (response.data.FirstUpdates) {
        response.data.FirstUpdates = DateHelper.parseFromMicrosoftString(
          response.data.FirstUpdates as string
        );
      }
      if (response.data.Tags && response.data.Tags.length) {
        response.data.Tags = response.data.Tags.sort();
      }
      if (typeof response.data.DataType === "string") {
        response.data.DataType =
          StreamDataType[response.data.DataType as keyof typeof StreamDataType];
      }
      if (typeof response.data.PostProcessingType === "string") {
        response.data.PostProcessingType =
          PostProcessingType[
            response.data.PostProcessingType as keyof typeof PostProcessingType
          ];
      }
      if (typeof response.data.VirtualType === "string") {
        response.data.VirtualType =
          StreamVirtualType[
            response.data.VirtualType as keyof typeof StreamVirtualType
          ];
      }
      state.streams[streamKey][1] = response.data;
      state.streams[streamKey][0] = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load stream",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.streams[streamKey][1] = null;
      state.streams[streamKey][0] = true;
    }
  },
  async loadWeatherLocations({ state }, name: string) {
    try {
      const guid = uuidv4();
      state.guidWeatherLocations = guid;
      if (name) {
        const locations = await WeatherHelper.getLocation(name);
        if (state.guidWeatherLocations === guid) {
          state.weatherLocations = locations;
        }
      } else {
        state.weatherLocations = [];
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load locations",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.weatherLocations = undefined;
    }
  }
};

const DashboardModule = {
  namespaced: true,
  state: new DashboardState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default DashboardModule;
