<template>
  <div>
    <div class="mb-3">
      <Button label="Reload" class="p-button-outlined p-button-sm text-sm mr-2 mb-2" icon="pi pi-refresh" @click="loadData" :disabled="state.inProgressRequestPoolExport"/>
      <Button label="Export" class="p-button-outlined p-button-sm text-sm mr-2 mb-2" @click="exportPool" :disabled="state.inProgressRequestPoolExport"/>
    </div>

    <div v-if="state.inProgressRequestPoolExport" class="mb-4">
      <ProgressBar mode="indeterminate"/>
    </div>

    <DataTable :value="state.poolExports" responsiveLayout="stack" breakpoint="850px">
      <template #empty>
        <div v-if="state.isLoadedPoolExport" class="w-full" style="min-height: 30vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 30vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="User" header="User"></Column>
      <Column field="DateCreated" header="Date Created">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.DateCreated" :timezone="timezone"/>
        </template>
      </Column>
      <Column field="DateCompleted" header="Date Completed">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.DateCompleted" :timezone="timezone"/>
        </template>
      </Column>
      <Column field="Done" header="State">
        <template #body="slotProps">
          <div v-if="slotProps.data.Done && !slotProps.data.Error">
            <Button icon="pi pi-cloud-download" label="Export" class="p-button-outlined p-button-icon-only p-button-rounded" @click="download(slotProps.data.Filename)" />
          </div>
          <div v-else-if="slotProps.data.Done && slotProps.data.Error">Error, please contact support</div>
          <div v-else>In progress</div>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import PoolToolsState from "@/store/states/PoolToolsState";
import ToastService from "@/services/ToastService";

@Component({
  components: {
    Button,
    Checkbox,
    DataTable,
    Column,
    ProgressBar,
    ProgressSpinner,
    DateTimezoneView
  }
})
class PoolExportView extends Vue {
  @Prop({ required: true }) poolKey!: string;
  @Prop({ required: true }) timezone!: string;

  get state(): PoolToolsState {
    return this.$store.state.poolTools;
  }

  created(): void {
    this.loadData();
  }

  loadData(): void {
    this.$store.dispatch("poolTools/loadPoolExport", this.poolKey);
  }

  exportPool(): void {
    // don't allow to export again, if previous export is in progress
    if (this.state.poolExports.length === 0 || this.state.poolExports[0].Done) {
      this.$store.dispatch("poolTools/requestPoolExport", this.poolKey);
    } else {
      ToastService.showToast(
        "warn",
        "Pool Export",
        "Pool export is already in queue. Please wait. You will receive an email with your data.",
        8000
      );
    }
  }

  download(filename: string): void {
    const link = `${this.$store.state.apiUrl}/rest/BitPool_V2/PoolExport/download/${filename}`;
    window.open(link, '_blank');
  }
}

export default PoolExportView;
</script>