<template>
  <div v-if="rootState.debugSettigns.length" style="position: fixed; top: 0; left:0; width: 30%; min-height: 50px; display: flex; justify-content: center; align-items: center; background-color: rgba(255, 255, 255, 0.5); z-index: 1000;">
    <div v-if="rootState.debugSettigns.includes('appHeight')">
      <b>App Height: </b>{{rootState.appHeight}}
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import RootState from "@/store/states/RootState";

@Component({
  
  components: {
  },
})
class DebugView extends Vue {
  get rootState(): RootState {
    return this.$store.state;
  }
}

export default DebugView;
</script>
