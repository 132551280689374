<template>
  <div class="default-view-container organisation-profile-container">
    <div class="default-view-head-section flex flex-wrap sm:flex-nowrap align-items-center sm:justify-content-between column-gap-2 md:column-gap-3">
      <h1 class="mb-3 sm:mb-0 w-full sm:w-auto">Organisation Profile</h1>   
      <Button 
        label="Create Organisation" 
        icon="pi pi-plus-circle" 
        class="p-button-secondary flex-shrink-0" 
        @click="openNewOrganisationDialog()"
        v-if="authState.permissions?.BitpoolAdmin"
      />
    </div>
    <div v-if="authState.permissions?.FullAccess" class="organisation-profile mt-4 lg:mt-5">
      <TabView lazy>
        <TabPanel header="Profile">
          <OrganisationEditProfileView/>
        </TabPanel>
        <TabPanel header="Sites">
          <OrganisationSitesView/>
        </TabPanel>
        <TabPanel header="Data">
          <OrganisationPoolsView/>
        </TabPanel>
        <TabPanel header="Flows">
          <OrganisationFlowsView/>
        </TabPanel>
        <TabPanel header="Notification Groups">
          <OrganisationNotificationGroups/>
        </TabPanel>
      </TabView>
    </div>
    <section class="mt-4 lg:mt-5 mb-0" v-else>
      <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
    </section>

    <Dialog header="Create Organisation" v-model:visible="displayNewOrganisationModal" :modal="true" :breakpoints="{'992px': '80vw'}" :style="{width: '56rem'}" class="organisation-profile-create-organisation-config-dialog">
      <div class="dialog-content">
        <BlockUI :blocked="organisationStore.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="organisationStore.updateInProgress ? 'blockui-blocked' : ''">
          <div class="field mb-0">
            <label for="newOrganisationName">Organisation Name</label>
            <div>
              <InputText
                id="newOrganisationName"
                class="inputfield w-full"
                type="text"
                v-model="newOrganisationName"
                @keyup.enter="createOrganisation"
              />
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #header>
        <div>
          <div class="p-dialog-title">Create Organisation</div>
          <div class="p-dialog-subtitle"></div>
        </div>
      </template>
      <template #footer>
        <Button 
          label="Close" 
          icon="pi pi-times" 
          @click="displayNewOrganisationModal = false" 
          class="p-button-text p-button-secondary"
        />
        <Button 
          label="Create" 
          :icon="organisationStore.updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
          @click="createOrganisation" 
          :disabled='organisationStore.updateInProgress' 
        />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import InputText from 'primevue/inputtext';
import ProgressSpinner from 'primevue/progressspinner';
import BlockUI from 'primevue/blockui';
import AuthState from "@/store/states/AuthState";
import OrganisationEditProfileView from "@/components/views/organisation/OrganisationEditProfileView.vue";
import OrganisationSitesView from "@/components/views/organisation/OrganisationSitesView.vue";
import OrganisationPoolsView from "@/components/views/organisation/OrganisationPoolsView.vue";
import OrganisationFlowsView from "@/components/views/organisation/OrganisationFlowsView.vue";
import OrganisationNotificationGroups from "@/components/views/organisation/OrganisationNotificationGroups.vue";
import { useOrganisationStore } from "@/stores/organisation";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { BitpoolPlan } from "@/models/enums/BitpoolPlan";
import CustomWindow from "@/CustomWindow";

declare const window: CustomWindow;

@Component({
  components: {
    TabView,
    TabPanel,
    Message,
    Button,
    Dialog,
    InputText,
    ProgressSpinner,
    BlockUI,
    OrganisationEditProfileView,
    OrganisationSitesView,
    OrganisationPoolsView,
    OrganisationFlowsView,
    OrganisationNotificationGroups
  },
})
class OrganisationEditView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  displayNewOrganisationModal = false;
  newOrganisationName = "";

  openNewOrganisationDialog(): void {
    this.newOrganisationName = "";
    this.displayNewOrganisationModal = true;
  }

  async createOrganisation(): Promise<void> {
    const entity: OrganisationFullDto = {
      Id: 0,
      Name: this.newOrganisationName,
      Logo: "",
      Color1: "",
      Color2: "",
      // not used by the API, but we need these fields for UI
      Plan: BitpoolPlan.Buildings, 
      Members: 2, // + admin@bitpool.com 
      Pools: 0, 
      Groups: 1, // default group
    };
    await this.organisationStore.createUpdate(entity);
    if (!this.organisationStore.updateError) {
      this.displayNewOrganisationModal = false;
      window.angularInitService.switchOrganisation(entity);
    }
  }
}

export default OrganisationEditView;
</script>