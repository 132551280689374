import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { MongoEntityPage } from "@/models/MongoEntityPage";
import DateHelper from "@/helpers/DateHelper";
import ConnectionHistoryState from "./states/ConnectionHistoryState";
import { ConnectionHistoryType } from "@/models/connection/ConnectionHistoryType";
import { ConnectionHistoryEntity } from "@/models/connection/ConnectionHistoryEntity";

export const useConnectionHistoryStore = defineStore('connectionHistory', {
  state: (): ConnectionHistoryState => ({ 
    isLoaded: false,
    guid: "",
    data: null,
    take: 10,
    skip: 0,
    configId: "",
    type: ConnectionHistoryType.HaystackExternal
  }),
  getters: {
  },
  actions: {
    
    async load(configId: string, type: ConnectionHistoryType, skip: number, take: number) {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        this.configId = configId;
        this.type = type;
        this.skip = skip;
        this.take = take;
        const url = `rest/ConnectionHistory_V1/history/${configId}?type=${type}&skip=${skip}&take=${take}`;
        const response = await axios.get<MongoEntityPage<ConnectionHistoryEntity>>(url);
        if (this.guid === guid) {
          if (response.data?.Items?.length) {
            response.data.Items.forEach((item) => {
              if (item.Date) {
                item.Date = DateHelper.parseFromMicrosoftString(
                  item.Date as string
                );
              }
            });
          }
          this.data = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load history",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = null;
        this.isLoaded = true;
      }
    }
  },
})
