import { CollectedDataInfo } from "@/models/CollectedDataInfo";

export default class LoginState {
  username = "";
  password = "";
  displaySection: "login" | "forgot" | "sign" | "no-organisation" = "login";
  loginSuccess = false;
  signupSuccess = false;
  collectedDataInfo: CollectedDataInfo | null = null;
}
