<template>
  <div class="graphic-library-inner">
    <div v-if="isLoaded">
      <!-- Group is selected or Search results, Image tiles -->
      <ImageGalleryImagesView 
        v-if="imageGalleryGroupStore.selectedItem || search" 
        :selectable="selectable" 
        @imageSelected="imageSelected"
      />

      <!-- Group tiles -->
      <div v-else class="graphic-library-tiles">
        <div 
          v-for="imageGalleryGroup in imageGalleryGroupStore.entities" 
          :key="imageGalleryGroup.Id" 
          @click="selectGroup(imageGalleryGroup)">
          <div class="graphic-library-image">
            <img v-if="imageGalleryGroup.CoverBase64" :src="imageGalleryGroup.CoverBase64" style="max-width: 100%; max-height: 100%;" />
            <svg v-else class="graphic-library-image-placeholder" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 92 104"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M56.312 1v28.966a5.528 5.528 0 0 0 5.526 5.526h28.966M56.312 1 90.8 35.492v61.32a5.528 5.528 0 0 1-5.526 5.527H6.526A5.528 5.528 0 0 1 1 96.813V6.526A5.528 5.528 0 0 1 6.526 1h49.786Zm-45.28 82.454v5.404a2.947 2.947 0 0 0 2.948 2.948h63.966a2.947 2.947 0 0 0 2.947-2.948V73.374c0-.804-.328-1.574-.91-2.13L59.3 51.434a2.95 2.95 0 0 0-4.27.199L34.608 75.249 23.54 67.633a2.948 2.948 0 0 0-4.21.933l-7.896 13.392a2.927 2.927 0 0 0-.41 1.496h.008Zm25.613-41.316a9.531 9.531 0 1 1-19.062 0 9.531 9.531 0 0 1 19.062 0Z" /></svg>
          </div>
          <span>{{ imageGalleryGroup.Name }}</span>
          <i v-if="imageGalleryGroup.OrganisationId === 0" v-tippy="'Public'" class="pi pi-globe graphic-library-group-public-icon"></i>
        </div>
      </div>
    </div>
    <div v-else class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;">
      <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
    </div>
  </div>
</template>

<script lang="ts">
import { ImageGalleryGroupEntity } from "@/models/image-gallery/ImageGalleryGroupEntity";
import { useImageGalleryStore } from "@/stores/imageGallery";
import { useImageGalleryGroupStore } from "@/stores/imageGalleryGroup";
import { Component, Emit, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import Button from 'primevue/button';
import ProgressSpinner from 'primevue/progressspinner';
import InputGroup from 'primevue/inputgroup';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import ImageGalleryImagesView from "./ImageGalleryImagesView.vue";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { useOrganisationStore } from "@/stores/organisation";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import ConfirmationService from "@/services/ConfirmationService";
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";

@Component({
  components: {
    Button,
    ProgressSpinner,
    InputGroup,
    InputText,
    InputSwitch,
    ImageGalleryImagesView
  },
})
class ImageGalleryGroupsView extends Vue {
  @Model search!: string;
  @Prop({ required: false, default: false }) selectable!: boolean;

  @Emit imageSelected(entity: ImageGalleryEntity | null): ImageGalleryEntity | null {
    return entity;
  }

  get apiUrl(): string {
    return this.$store.state.apiUrl;
  }

  get imgUrl(): string {
    return `${this.apiUrl}/rest/AWS_S3_V1/File/`;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  imageGalleryGroupStore = useImageGalleryGroupStore();
  imageGalleryStore = useImageGalleryStore();
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  mounted(): void {
    this.imageGalleryGroupStore.$reset();
    this.imageGalleryStore.$reset();
    this.imageGalleryGroupStore.load();
  }

  get isLoaded(): boolean {
    const result = this.imageGalleryGroupStore.isLoaded;
    return result;
  }

  get selectedItem(): ImageGalleryGroupEntity | null {
    return this.imageGalleryGroupStore.selectedItem;
  }
  
  set selectedItem(value: ImageGalleryGroupEntity | null) {
    this.imageGalleryGroupStore.selectedItem = value;
  }

  selectGroup(imageGalleryGroup: ImageGalleryGroupEntity): void {
    this.selectedItem = imageGalleryGroup;
    this.loadData();
  }

  loadData(): void {
    if (this.search || this.selectedItem) {
      this.imageGalleryStore.load(this.selectedItem ? this.selectedItem.Id : "", 0, this.imageGalleryStore.take, this.search);
    } else {
      this.imageGalleryStore.$reset();
    }
  }

  @Watch('search', { immediate: false, deep: false })
  onSearch() {
    this.loadData();
  }
}

export default ImageGalleryGroupsView;
</script>