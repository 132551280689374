<template>
  <div class="presentation-controls" v-if="isLoaded">
    <span class="presentation-counter"><i class="pi pi-clock text-lg sm:text-xl"></i><span>{{counter}}</span></span>
    <Button v-if="isPaused || !interval" v-tippy="'Start'" icon="pi pi-play" class="p-button-rounded p-button-primary p-button-icon-only p-button-text p-button-lg" @click="play"></Button>
    <Button v-else v-tippy="'Pause'" icon="pi pi-pause" class="p-button-rounded p-button-primary p-button-icon-only p-button-text p-button-lg" @click="pause"></Button>
    <Button :disabled="!presentaionState.currentPresentation?.loop && isFirst" v-tippy="'Previous Slide'" icon="pi pi-step-backward" class="p-button-rounded p-button-primary p-button-icon-only p-button-text p-button-lg" @click="prev"></Button>
    <Button :disabled="!presentaionState.currentPresentation?.loop && isLast" v-tippy="'Next Slide'" icon="pi pi-step-forward" class="p-button-rounded p-button-primary p-button-icon-only p-button-text p-button-lg" @click="next"></Button>
  </div>
</template>

<script lang="ts">
import DashboardState from "@/store/states/DashboardState";
import { Component, Prop, Vue } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import PresentationState from "@/store/states/PresentationState";
import { Watch } from "vue-facing-decorator";
import { PresentationPage } from "@/models/dashboard/PresentationPage";
import Button from 'primevue/button';
import NavigationHelper from "@/helpers/NavigationHelper";

@Component({
  components: {
    Button
  },
})
class SharedPresentationControlsView extends Vue {
  @Prop({ required: true }) pane!: string;
  @Prop({ required: true }) slide!: string;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get presentaionState(): PresentationState {
    return this.$store.state.presentation;
  }

  get isLoaded(): boolean {
    return this.presentaionState.isLoadedCurrentPresentation;
  }

  isPaused = true;
  counter = 0;
  interval = 0;

  initCounter(): void {
    const slideInfo = this.getSlideInfo();
    if (slideInfo) {
      this.counter = slideInfo.duration;
    }
  }

  startTimer(): void {
    const slideInfo = this.getSlideInfo();
    if (slideInfo) {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.interval = window.setInterval(() => {
        this.counter--;
        if (this.counter <= 0) {
          clearInterval(this.interval);
          this.interval = 0;
          const slideNumber = parseInt(this.slide);
          const index = slideNumber ? (slideNumber - 1) : 0;
          const newSlideIndex = index + 1;
          if (this.presentaionState.currentPresentation)
          {
            if (newSlideIndex < this.presentaionState.currentPresentation.dashboards.length && newSlideIndex >= 0) {
              NavigationHelper.goToSlide(`${newSlideIndex + 1}`);
            } else if (this.presentaionState.currentPresentation.loop && newSlideIndex >= this.presentaionState.currentPresentation.dashboards.length) {
              NavigationHelper.goToSlide("1");
            }
          }
        }
      }, 1000);
    }
  }

  stopTimer(): void {
    clearInterval(this.interval);
    this.interval = 0;
  }

  getSlideInfo(): PresentationPage | null {
    if (this.presentaionState.currentPresentation && this.presentaionState.currentPresentation.dashboards.length) {
      const slideNumber = parseInt(this.slide);
      let index = slideNumber ? (slideNumber - 1) : 0;
      if (index >= this.presentaionState.currentPresentation.dashboards.length || index < 0) {
        index = 0;
      }
      const slideInfo = this.presentaionState.currentPresentation.dashboards[index];
      return slideInfo;
    }
    return null;
  }

  @Watch('isLoaded', { immediate: false, deep: false })
  onLoadedChanged(val: string, oldVal: string): void {
    this.initCounter();
    if (this.presentaionState.currentPresentation?.autoStart) {
      this.isPaused = false;
      this.startTimer();
    }
  }
  
  @Watch('slide', { immediate: false, deep: false })
  onSlideChanged(val: string, oldVal: string): void {
    this.initCounter();
    if (!this.isPaused) {
      this.startTimer();
    }
  }

  play(): void {
    this.isPaused = false;
    if (this.counter < 1) {
      this.initCounter();
    }
    this.startTimer();
  }

  pause(): void {
    this.isPaused = true;
    this.stopTimer();
  }

  prev(): void {
    if (this.presentaionState.currentPresentation?.dashboards.length) {
      let slideNumber = parseInt(this.slide);
      slideNumber = slideNumber ? slideNumber : 1;
      const newSlideNumber = slideNumber - 1;
      if (newSlideNumber >= 1) {
        NavigationHelper.goToSlide(`${newSlideNumber}`);
      } else {
        NavigationHelper.goToSlide(`${this.presentaionState.currentPresentation.dashboards.length}`);
      }
    }
  }

  next(): void {
    if (this.presentaionState.currentPresentation?.dashboards.length) {
      let slideNumber = parseInt(this.slide);
      slideNumber = slideNumber ? slideNumber : 1;
      const newSlideNumber = slideNumber + 1;
      if (newSlideNumber < this.presentaionState.currentPresentation.dashboards.length + 1) {
        NavigationHelper.goToSlide(`${newSlideNumber}`);
      } else {
        NavigationHelper.goToSlide("1"); 
      }
    }
  }

  get isLast(): boolean {
    let result = true;
    if (this.presentaionState.currentPresentation?.dashboards.length) {
      let slideNumber = parseInt(this.slide);
      slideNumber = slideNumber ? slideNumber : 1;
      result = slideNumber >= this.presentaionState.currentPresentation.dashboards.length;
    }
    return result;
  }

  get isFirst(): boolean {
    let result = true;
    if (this.presentaionState.currentPresentation?.dashboards.length) {
      let slideNumber = parseInt(this.slide);
      slideNumber = slideNumber ? slideNumber : 1;
      result = slideNumber <= 1;
    }
    return result;
  }
}

export default SharedPresentationControlsView;
</script>
