<template>
  <section class="organisation-profile-users-groups">
    <h3 class="mb-0">Notification Groups</h3>
    <span class="help-text">Create notification groups and add users who will receive specific notifications and alerts.</span>
    <div>
      <DataTable
        v-model:selection="selectedRecords"
        :value="notificationGroupStore.entities" 
        dataKey="Id"
        showGridlines 
        responsiveLayout="stack" 
        breakpoint="850px" 
        class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width"
        sortField="Name" 
        :sortOrder="1"
        :paginator="true"
        :rows="20"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="menu"
        :globalFilterFields="['Name']"
        removableSort
      >
        <template #header>
          <div class="table-header">
            <div class="sm:flex sm:align-items-center sm:justify-content-between sm:gap-3">
              <div class="sm:flex-shrink-0">
                <Button 
                  :disabled="!notificationGroupStore.isLoaded" 
                  label="Add Group" 
                  icon="pi pi-plus-circle" 
                  class="my-1 mr-2" 
                  @click="openCreateDialog"
                />
                <Button 
                  v-if="selectedRecords.length > 0"
                  label="Delete" 
                  icon="pi pi-trash" 
                  class="my-1 mr-2 p-button-outlined p-button-danger" 
                  @click="openDeleteSelectedRecordsConfirmation()"
                />
              </div>
              <div class="mt-3 sm:mt-0">
                <IconField iconPosition="left" class="w-full sm:w-auto">
                  <InputIcon class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Find Group" class="w-full sm:w-auto" />
                </IconField>
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          <div v-if="notificationGroupStore.isLoaded" class="w-full" style="min-height: 50vh;">
            <span class="inline-block py-2">No data found.</span>
          </div>
          <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
            <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
          </div>
        </template>
        <Column selectionMode="multiple" headerStyle="width: 1%; min-width: 3rem;" headerClass="column-with-checkbox" bodyClass="column-with-checkbox"/>
        <Column :sortable="true" field="Name" header="Name" headerStyle="min-width: min-content; width: 59%;" headerClass="no-break-word" bodyClass="break-word"/>
        <Column :sortable="true" field="Users.length" header="Users" eaderStyle="min-width: min-content; width: 11%;" headerClass="no-break-word" bodyClass="no-break-word"/>
        <Column :sortable="true" field="Created" header="Date Created" headerStyle="min-width: min-content; width: 20%;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <DateTimezoneView :date="slotProps.data.Created" timezone="local"/>
          </template>
        </Column>
        <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
          <template #body="slotProps">
            <div>
              <div class="inline-flex">
                <Button 
                  icon="pi pi-pencil" 
                  class="p-button-icon-only p-button-rounded p-button-outlined mr-2 p-button-opposite"
                  @click="openUpdateDialog(slotProps.data)"
                  v-tippy="'Edit'"
                />
                <Button 
                  icon="pi pi-trash" 
                  class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                  @click="openConfirmation(slotProps.data)" 
                  v-tippy="'Delete'"
                />
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </section>
  <Dialog :header="editRecord?.Id ? 'Edit Group' : 'Add New Group'" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'992px': '90vw'}" :style="{width: '60.5rem'}" class="organisation-profile-users-groups-config-dialog">
    <div class="dialog-content">
      <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="updateInProgress ? 'blockui-blocked' : ''">
        <div v-if="editRecord">
          <h4 class="organisation-profile-users-groups-section-title">Group Name</h4>
        
          <div class="formgrid grid align-items-end">
            <div class="field col">
              <div>
                <InputText 
                  id="editRecordName" 
                  v-model="editRecord.Name" 
                  class="inputfield w-full" 
                  placeholder="Group Name"
                />
              </div>
            </div>
            <div class="field col-fixed">
              <div class="form-element-size">
                <ColorSelectView
                  :color="editRecord.Color" 
                  :updateColor="(color: string) => { 
                    if (editRecord) {
                      editRecord.Color = color;
                    }
                  }"
                />
              </div>
            </div>
          </div>

          <h4 class="organisation-profile-users-groups-section-title">Group Members</h4>
          <OrganisationNotificationGroupsMembers v-model="editRecord"/>
        </div>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #header>
      <div>
        <div class="p-dialog-title">{{ editRecord?.Id ? 'Edit Group' : 'Add New Group' }}</div>
        <div class="p-dialog-subtitle">Please enter the details of your {{ editRecord?.Id ? 'existing' : 'new' }} group.</div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
        <span class="block">
          <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        </span>
        <span class="block ml-2">
          <Button 
            :label="editRecord?.Id ? 'Save' : 'Add'" 
            :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
            @click="saveRecord" 
            :disabled='updateInProgress || !editRecord || !editRecord.Name' 
          />
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import SelectButton from "primevue/selectbutton";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import ConfirmationService from "@/services/ConfirmationService";
import { useOrganisationStore } from "@/stores/organisation";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { Permission } from "@/models/enums/Permission";
import ToastService from "@/services/ToastService";
import { PermissionScope } from "@/models/enums/PermissionScope";
import { useNotificationGroupStore } from "@/stores/notificationGroup";
import { NotificationGroupEntity } from "@/models/notification/NotificationGroupEntity";
import ColorHelper from "@/helpers/ColorHelper";
import ColorSelectView from "@/components/widgets-next/settings/ColorSelectView.vue";
import OrganisationNotificationGroupsMembers from "@/components/views/organisation/OrganisationNotificationGroupsMembers.vue";
import DateHelper from "@/helpers/DateHelper";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";

@Component({
  components: {
    Button,
    InputText,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown,
    SelectButton,
    TabView,
    TabPanel,
    IconField,
    InputIcon,
    DateTimezoneView,
    ColorSelectView,
    OrganisationNotificationGroupsMembers
  },
  directives: {
  }
})
class OrganisationNotificationGroups extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  notificationGroupStore = useNotificationGroupStore();
  organisationUsersStore = useOrganisationUsersStore();

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  created(): void {
    this.notificationGroupStore.load();
    if (!this.organisationUsersStore.data[this.organisationIdStr]?.isLoaded && this.organisationStore.currentOrganisation) {
      this.organisationUsersStore.load(this.organisationStore.currentOrganisation.Id);
    }
  }

  // #region add/update
  displayCreateUpdateDialog = false;
  editRecord: NotificationGroupEntity | null = null;
  filtersGroups = {
    'global': {value: null, matchMode: 'contains'}
  };

  get permissions(): Permission[] {
    return Object.values(Permission).reverse();
  }

  get permissionsScope(): PermissionScope[] {
    return Object.values(PermissionScope).reverse();
  }

  get enableDisable(): [boolean, string][] {
    return [[true, "Enable"], [false, "Disable"]];
  }

  openCreateDialog(): void {
    if (this.currentOrganisation) {
      const now = new Date();
      this.editRecord = {
        OrganisationId: this.currentOrganisation.Id,
        Id: "",
        Name: "",
        Created: now,
        Updated: now,
        CreatedBy: "",
        UpdatedBy: "",
        Color: ColorHelper.random(),
        Users: []
      };
      this.displayCreateUpdateDialog = true;
    }
  }

  openUpdateDialog(record: NotificationGroupEntity): void {
    if (this.currentOrganisation) {
      this.editRecord = JSON.parse(JSON.stringify(record));
      if (this.editRecord) {
        this.editRecord.Created = DateHelper.parseFromMongoDate(this.editRecord.Created);
        this.editRecord.Updated = DateHelper.parseFromMongoDate(this.editRecord.Updated);
        this.editRecord.Users.forEach(user => {
          user.DateAdded = DateHelper.parseFromMongoDate(user.DateAdded);
        });
      }
      this.displayCreateUpdateDialog = true;
    }
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  get updateInProgress(): boolean {
    return this.updateInProgressLocal || 
      this.notificationGroupStore.updateInProgress
  }

  updateInProgressLocal = false;

  async saveRecord(): Promise<void> {
    if (this.editRecord) {
      this.updateInProgressLocal = true;
      const group = await this.notificationGroupStore.createUpdate(this.editRecord);
      if (!this.notificationGroupStore.updateError) {
        ToastService.showToast("success", "", "Changes saved", 5000);
        this.closeCreateUpdateDialog();
      }
      this.updateInProgressLocal = false;
    }
  }
  // #endregion add/update

  // #region delete
  selectedRecord: NotificationGroupEntity | null = null;

  openConfirmation(record: NotificationGroupEntity | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete group?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Group',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.notificationGroupStore.delete([this.selectedRecord]);
    }
  }

  selectedRecords: NotificationGroupEntity[] = [];

  openDeleteSelectedRecordsConfirmation(): void {
    const message = `Are you sure you want to delete selected groups?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Groups',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteSelectedRecords();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async deleteSelectedRecords(): Promise<void> {
    if (this.selectedRecords.length && this.currentOrganisation) {
      const result = await this.notificationGroupStore.delete(this.selectedRecords);
      if (result) {
        this.selectedRecords = [];
      }
    }
  }
  // #endregion delete
}

export default OrganisationNotificationGroups;
</script>