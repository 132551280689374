<template>
  <div>
    <div v-if="isLoaded">
      <template v-for="imageGalleryWithGroup in imagesByGroup" :key="imageGalleryWithGroup[0]">
        <h3 class="graphic-library-search-result-group-title" v-if="imageGalleryStore.search">{{ imageGalleryWithGroup[1] }}</h3>
        <div class="graphic-library-tiles-images">
          <div 
            v-for="imageGallery in imageGalleryWithGroup[2]" 
            :key="imageGallery.Id" 
            @click="selectImage(imageGallery)"
            :class="{ 'is-selected': imageGalleryStore.selectedItem?.Id === imageGallery.Id }"
          >
            <div class="graphic-library-image">
              <Image preview class="graphic-library-maximize-img-icon">
                <template #preview="slotProps">
                  <div class="w-screen h-screen flex justify-content-center align-items-center p-4">
                    <img 
                      :src="imageGallery.OptimizedFilename ? `${imgUrl}${imageGallery.OptimizedFilename}` : `${imgUrl}${imageGallery.Filename}`" 
                      alt="preview"
                      :style="slotProps.style" 
                      @click="slotProps.previewCallback" 
                      class="p-image-preview"
                    />
                  </div>
                </template>
              </Image>

              <img v-if="imageGallery.PreviewBase64" :src="imageGallery.PreviewBase64" />
              <img v-else-if="imageGallery.OptimizedFilename" :src="`${imgUrl}${imageGallery.OptimizedFilename}`" />
              <img v-else :src="`${imgUrl}${imageGallery.Filename}`" />
            </div>
            <span>{{ imageGallery.Name }}</span>
          </div>
        </div>
      </template>
      <Paginator 
        v-model:first="imageGalleryStore.skip" 
        :rows="imageGalleryStore.take" 
        :totalRecords="imageGalleryStore.data?.Total" 
        @page="onPageChange" 
        template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink JumpToPageDropdown"
      />

      <div class="graphic-library-actions-btns">
        <Button
          label="Actions" 
          icon="pi pi-ellipsis-h" 
          @click="toggleActionsMenu"
          :disabled="!selectedItem"
          class="p-button-secondary"
        />
        <Menu 
          id="overlay_actions" 
          ref="overlay_actions" 
          :model="actionsMenuItems" 
          :popup="true" 
        />
        <Button 
          v-if="selectable" 
          label="Select" 
          icon="pi pi-check" 
          @click="imageSelected" 
          :disabled="!selectedItem"
        />
      </div>

      <Dialog 
        header="Rename Image" 
        v-model:visible="displayRenameDialog" 
        :modal="true" 
        :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}"
      >
        <div class="dialog-content">
          <BlockUI 
            :blocked="saveInProgress" 
            :autoZIndex="false" 
            :baseZIndex="100"  
            class="blockui-with-spinner blockui-with-fixed-spinner" 
            :class="saveInProgress ? 'blockui-blocked' : ''"
          >
            <div class="field mb-0">
              <label for="newGroupName">Image Name</label>
              <div>
                <InputText
                  id="newGroupName"
                  class="inputfield w-full"
                  type="text"
                  v-model="imageName"
                  @keyup.enter="renameImage"
                />
              </div>
            </div>
            <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
          </BlockUI>
        </div>
        <template #footer>
          <Button 
            label="Close" 
            icon="pi pi-times" 
            @click="displayRenameDialog = false" 
            class="p-button-text p-button-secondary"
          />
          <Button 
            label="Save" 
            :icon="saveInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
            @click="renameImage" 
            :disabled='saveInProgress || !imageName' 
          />
        </template>
      </Dialog>
    </div>
    <div v-else class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;">
      <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
    </div>
  </div>
</template>

<script lang="ts">
import { useImageGalleryStore } from "@/stores/imageGallery";
import { useImageGalleryGroupStore } from "@/stores/imageGalleryGroup";
import { Component, Emit, Prop, Vue } from "vue-facing-decorator";
import Paginator, { PageState } from "primevue/paginator";
import ProgressSpinner from "primevue/progressspinner";
import Button from "primevue/button";
import Image from "primevue/image";
import Menu from "primevue/menu";
import Dialog from "primevue/dialog";
import BlockUI from "primevue/blockui";
import InputText from "primevue/inputtext";
import { MenuItem } from "primevue/menuitem";
import { ImageGalleryEntity } from "@/models/image-gallery/ImageGalleryEntity";
import { ImageGalleryGroupEntity } from "@/models/image-gallery/ImageGalleryGroupEntity";
import AuthState from "@/store/states/AuthState";
import ConfirmationService from "@/services/ConfirmationService";
import { reactive } from "vue";

@Component({
  components: {
    Paginator,
    ProgressSpinner,
    Button,
    Image,
    Menu,
    Dialog,
    BlockUI,
    InputText
  },
})
class ImageGalleryImagesView extends Vue {
  @Prop({ required: false, default: false }) selectable!: boolean;

  @Emit imageSelected(): ImageGalleryEntity | null {
    return this.imageGalleryStore.selectedItem;
  }
  
  get apiUrl(): string {
    return this.$store.state.apiUrl;
  }

  get imgUrl(): string {
    return `${this.apiUrl}/rest/AWS_S3_V1/File/`;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  imageGalleryGroupStore = useImageGalleryGroupStore();
  imageGalleryStore = useImageGalleryStore();

  get isLoaded(): boolean {
    const result = this.imageGalleryStore.isLoaded;
    return result;
  }

  get saveInProgress(): boolean {
    const result = this.imageGalleryStore.updateInProgress;
    return result;
  }

  get canEditGlobal(): boolean {
    return !!this.authState.permissions?.BitpoolAdmin;
  }

  get selectedGroup(): ImageGalleryGroupEntity | null {
    const group = this.imageGalleryGroupStore.selectedItem;
    return group;
  }

  get imagesByGroup(): [string, string, ImageGalleryEntity[]][] {
    const result: [string, string, ImageGalleryEntity[]][] = [];
    const map: Record<string, number> = {};
    if (this.imageGalleryStore.data) {
      for (let index = 0; index < this.imageGalleryStore.data.Items.length; index++) {
        const element = this.imageGalleryStore.data.Items[index];
        if (typeof map[element.GroupId] === "undefined") {
          map[element.GroupId] = result.length;
          const group = this.imageGalleryGroupStore.entities?.find(x => x.Id === element.GroupId);
          result.push([element.GroupId, group ? group.Name : "", [element]]);
        } else {
          result[map[element.GroupId]][2].push(element);
        }
      }
    }
    const group = this.imageGalleryGroupStore.selectedItem;
    if (result.length === 0 && group) {
      result.push([group.Id, group ? group.Name : "", []]);
    }
    return result;
  }

  onPageChange(event: PageState): void {
    this.imageGalleryStore.load(this.imageGalleryStore.groupId, event.first, this.imageGalleryStore.take, this.imageGalleryStore.search);
  }

  get selectedItem(): ImageGalleryEntity | null {
    return this.imageGalleryStore.selectedItem;
  }

  selectImage(image: ImageGalleryEntity): void {
    this.imageGalleryStore.selectedItem = image;
  }

  toggleActionsMenu(event: Event): void {
    if (this.$refs.overlay_actions) {
      (this.$refs.overlay_actions as Menu).toggle(event);
    }
  }

  get actionsMenuItems(): MenuItem[] {
    const result: MenuItem[] = [];
    if (this.selectedItem) {
      result.push({ 
        label: "Set as Cover", 
        icon: "pi pi-image", 
        command: () => this.setAsCover(), 
        visible: !!this.selectedItem?.PreviewBase64 && this.canExecuteAction()
      });
      result.push({ 
        label: "Rename", 
        icon: "pi pi-pencil", 
        command: () => this.openRenameDialog(), 
        visible: this.canExecuteAction()
      });
      result.push({ 
        label: "Delete", 
        icon: "pi pi-trash", 
        command: () => this.deleteImage() , 
        visible: this.canExecuteAction()
      });
    }
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  getImageGroup(): ImageGalleryGroupEntity | undefined {
    const selectedImage = this.selectedItem;
    const selectedGroup = this.selectedGroup ?? this.imageGalleryGroupStore.entities?.find(x => x.Id === selectedImage?.GroupId);
    return selectedGroup;
  }

  canExecuteAction(): boolean {
    const group = this.getImageGroup();
    const result = group && group.OrganisationId > 0 || group?.OrganisationId === 0 && this.canEditGlobal;
    return result;
  }

  setAsCover(): void {
    const group = this.getImageGroup();
    if (group && this.selectedItem) {
      group.CoverBase64 = this.selectedItem.PreviewBase64;
      this.imageGalleryGroupStore.createUpdate(group);
    }
  }

  displayRenameDialog = false;
  imageName = "";

  openRenameDialog(): void {
    this.imageName = this.selectedItem?.Name ?? "";
    this.displayRenameDialog = true;
  }

  async renameImage(): Promise<void> {
    if (this.imageName && this.selectedItem) {
      const copy: ImageGalleryEntity = JSON.parse(JSON.stringify(this.selectedItem));
      copy.Name = this.imageName;
      const result = await this.imageGalleryStore.createUpdate(copy);

      if (result) {
        this.displayRenameDialog = false;
      }
    }
  }

  deleteImage(): void {
    const message = `Are you sure you want to delete Image?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Image',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        if (this.selectedItem) {
          await this.imageGalleryStore.delete(this.selectedItem);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
}

export default ImageGalleryImagesView;
</script>