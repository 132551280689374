import { IsSharedWidgetModel } from "@/models/dashboard/IsSharedWidgetModel";

export default class ShareWidgetState {
  isLoadedShared = false;
  isShared: IsSharedWidgetModel | null = null;
  guidIsShared = "";

  isLoadedShareId = false;
  shareId: string | null = null;
  guidShareId = "";

  isLoadedShortUrl = false;
  shortUrl: string | null = null;
  guidShortUrl = "";
}
