<template>
  <div class="user-settings-content" :class="isMobileMode ? 'user-settings-content-mobile' : ''">
    <PanelMenu :model="menuItems" v-if="!isMobileMode" />
    <Accordion :activeIndex="activeIndex" class="user-settings-accordion">
      <AccordionTab :header="t('accountSettings.account.tabName')" headerClass="account">
        <UserSettingsAccountView @closeDialog="closeDialog" />
      </AccordionTab>
      <AccordionTab :header="t('accountSettings.password.tabName')" headerClass="password">
        <UserSettingsPasswordView @closeDialog="closeDialog" />
      </AccordionTab>
      <AccordionTab :header="t('accountSettings.notifications.tabName')" headerClass="notifications">
        <UserSettingsNotificationsView @closeDialog="closeDialog" />
      </AccordionTab>
      <AccordionTab :header="t('accountSettings.2fa.tabName')" headerClass="two-fa">
        <UserSettings2faView @closeDialog="closeDialog" />
      </AccordionTab>
      <AccordionTab :header="t('accountSettings.dashboards.tabName')" headerClass="dashboards">
        <UserSettingsDashboardsView @closeDialog="closeDialog" />
      </AccordionTab>
      <AccordionTab :header="t('accountSettings.appearance.tabName')" headerClass="appearance">
        <UserSettingsAppearanceView @closeDialog="closeDialog" />
      </AccordionTab>
      <AccordionTab :header="t('accountSettings.bitpoolAI.tabName')" headerClass="bitpool-ai-skills">
        <UserSettingsAIView @closeDialog="closeDialog" />
      </AccordionTab>
      <AccordionTab :header="t('accountSettings.terms.tabName')" headerClass="terms-use">
        <UserSettingsTermsUseView/>
      </AccordionTab>
    </Accordion>
  </div>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import UserSettingsAccountView from "@/components/views/user-settings/UserSettingsAccountView.vue";
import UserSettingsPasswordView from "@/components/views/user-settings/UserSettingsPasswordView.vue";
import UserSettingsNotificationsView from "@/components/views/user-settings/UserSettingsNotificationsView.vue";
import UserSettings2faView from "@/components/views/user-settings/UserSettings2faView.vue";
import UserSettingsDashboardsView from "@/components/views/user-settings/UserSettingsDashboardsView.vue";
import UserSettingsAppearanceView from "@/components/views/user-settings/UserSettingsAppearanceView.vue";
import UserSettingsAIView from "@/components/views/user-settings/UserSettingsAIView.vue";
import UserSettingsTermsUseView from "@/components/views/user-settings/UserSettingsTermsUseView.vue";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import PanelMenu from 'primevue/panelmenu';
import { MenuItem } from "primevue/menuitem";
import { reactive } from "vue";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    UserSettingsAccountView,
    UserSettingsPasswordView,
    UserSettingsNotificationsView,
    UserSettings2faView,
    UserSettingsDashboardsView,
    UserSettingsAppearanceView,
    UserSettingsAIView,
    UserSettingsTermsUseView,
    Accordion,
    AccordionTab,
    PanelMenu
  },
})
class UserSettingsView extends Vue {
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  activeIndex = 0;
  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();
  windowWidth = window.innerWidth;

  get isMobileMode(): boolean {
    return this.windowWidth < 1200;
  }

  get menuItems(): MenuItem[] {
    const result: MenuItem[] = [
      {
        key: `Account`,
        label: this.t('accountSettings.account.tabName'),
        command:() => {
          this.activeIndex = 0;
        },
        class: ((this.activeIndex === 0) ? "active" : "") + " account"
      },
      {
        key: `Password`,
        label: this.t('accountSettings.password.tabName'),
        command:() => {
          this.activeIndex = 1;
        },
        class: ((this.activeIndex === 1) ? "active" : "") + " password"
      },
      {
        key: `Notifications`,
        label: this.t('accountSettings.notifications.tabName'),
        command:() => {
          this.activeIndex = 2;
        },
        class: ((this.activeIndex === 2) ? "active" : "") + " notifications"
      },
      {
        key: `2FA`,
        label: this.t('accountSettings.2fa.tabName'),
        command:() => {
          this.activeIndex = 3;
        },
        class: ((this.activeIndex === 3) ? "active" : "") + " two-fa"
      },
      {
        key: `Dashboards`,
        label: this.t('accountSettings.dashboards.tabName'),
        command:() => {
          this.activeIndex = 4;
        },
        class: ((this.activeIndex === 4) ? "active" : "") + " dashboards"
      },
      {
        key: `Appearance`,
        label: this.t('accountSettings.appearance.tabName'),
        command:() => {
          this.activeIndex = 5;
        },
        class: ((this.activeIndex === 5) ? "active" : "") + " appearance"
      },
      {
        key: `BitpoolAISkill`,
        label: this.t('accountSettings.bitpoolAI.tabName'),
        command:() => {
          this.activeIndex = 6;
        },
        class: ((this.activeIndex === 6) ? "active" : "") + " bitpool-ai-skills"
      },
      {
        key: `Terms of use`,
        label: this.t('accountSettings.terms.tabName'),
        command:() => {
          this.activeIndex = 7;
        },
        class: ((this.activeIndex === 7) ? "active" : "") + " terms-use"
      }
    ];
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  created(): void {
    this.emitter.on("window_size_changed_debounce", this.onResize);
    this.onResize();
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.onResize);
  }

  onResize(): void {
    this.windowWidth = window.innerWidth;
  }

  @Emit
  closeDialog(): void {
    // nothing to do, just emit an event
  }
}

export default UserSettingsView;
</script>