import { defineStore } from "pinia";
import TagManagerState from "./states/TagManagerState";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import axios from "axios";
import { BP_TagChangeStreams } from "@/models/BP_TagChangeStreams";
import { TreeNodeForUI } from "@/models/nav-tree/NavTreeForUI";
import { NavTreeNode } from "@/models/nav-tree/NavTreeNode";
import { CopyNavTreeResult } from "@/models/nav-tree/CopyNavTreeResult";
import { NavTree } from "@/models/nav-tree/NavTree";
import { NavTreeNodeType } from "@/models/nav-tree/NavTreeNodeType";
import { StreamDataTypeStr } from "@/models/enums/StreamDataTypeStr";
import { StreamVirtualTypeStr } from "@/models/enums/StreamVirtualTypeStr";
import ConfirmationService from "@/services/ConfirmationService";
import { NavTreeNodeMinimalWithParents } from "@/models/nav-tree/NavTreeNodeMinimalWithParents";

export const useTagManagerStore = defineStore('tagManager', {
  state: (): TagManagerState => ({ 
    activeTabMiniMode: 0,
    activeTab: 0,
    isMiniMode: false,
    changeTagsInProgress: false,
    deleteNodesInProgress: false,
    duplicateInProgress: false,
    copyToOrganisationinProgress: false,
    createNodeInProgress: false,
    activeSite: null,
    activeParent: null,
    activeNode: null,
    isActiveNodeChanged: false
  }),
  getters: {
  },
  actions: {
    async createNode(name: string, tags: string[]): Promise<NavTreeNode | undefined> {
      try {
        this.createNodeInProgress = true;
        const url = `rest/BitPool_V1/streams/virtual/NoData`;
        const response1 = await axios.put<string>(
          url, name, {
            headers: {
              "Content-Type": "application/json",
            }
          }
        );
        const streamKey = response1.data;
        const addTagsRequest: BP_TagChangeStreams = {
          StreamKeys: [streamKey],
          TagsAdd: tags,
          TagsRemove: []
        };
        const apiTags = await this.changeTags(addTagsRequest);
        const result: NavTreeNode = {
          Name: name,
          Key: streamKey,
          NodeType: NavTreeNodeType.StreamGroup,
          Active: false,
          Tags: apiTags ? apiTags[streamKey] : [],
          Nodes: [],
          LastUpdate: null,
          DataType: StreamDataTypeStr.Double,
          Virtual: true,
          VirtualType: StreamVirtualTypeStr.NoData,
          Index: -1
        };
        return result;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't create nodes",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
      finally {
        this.createNodeInProgress = false;
      }
      return undefined;
    },
    async deleteNodes(streamKeys: string[]): Promise<boolean> {
      try {
        this.deleteNodesInProgress = true;
        const url = `rest/BitPool_V1/tags/nodesWithChilds`;
        await axios.delete(
          url, {
            headers: {
              "Content-Type": "application/json",
            },
            data: streamKeys,
          }
        );
        return true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete nodes",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
      finally {
        this.deleteNodesInProgress = false;
      }
      return false;
    },
    async duplicateNodes(streamKeys: string[], amount: number): Promise<Record<string, CopyNavTreeResult> | undefined> {
      try {
        this.duplicateInProgress = true;
        const url = `rest/BitPool_V1/tags/nodesWithChilds/duplicate?amount=${amount}`;
        const response = await axios.post<Record<string, CopyNavTreeResult>>(
          url, streamKeys
        );
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't duplicate nodes",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
      finally {
        this.duplicateInProgress = false;
      }
      return undefined;
    },
    async copyToOrganisationNodes(streamKey: string, organisationId: number): Promise<NavTree | undefined> {
      try {
        this.copyToOrganisationinProgress = true;
        const url = `rest/BitPool_V1/tags/nodesWithChilds/${streamKey}/${organisationId}/duplicate`;
        const response = await axios.post<NavTree>(
          url
        );
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't copy nodes",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
      finally {
        this.copyToOrganisationinProgress = false;
      }
      return undefined;
    },
    async copyNodesTo(streamKeys: string[], destinationStreamKey: string): Promise<Record<string, CopyNavTreeResult> | undefined> {
      try {
        this.duplicateInProgress = true;
        const url = `rest/BitPool_V1/tags/nodesWithChilds/copyTo/${destinationStreamKey}`;
        const response = await axios.post<Record<string, CopyNavTreeResult>>(
          url, streamKeys
        );
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't copy nodes",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
      finally {
        this.duplicateInProgress = false;
      }
      return undefined;
    },
    async changeTags(request: BP_TagChangeStreams): Promise<Record<string, string[]> | undefined> {
      try {
        this.changeTagsInProgress = true;
        const url = `public/v3/streams/tags/change`;
        const response = await axios.post<Record<string, string[]>>(
          url,
          request
        );
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't change tags",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
      finally {
        this.changeTagsInProgress = false;
      }
      return undefined;
    },
    replaceTreeNodeTags(treeNode: TreeNodeForUI, tags: string[]): void {
      const dis = tags.find(x => x.startsWith("dis="))?.replace("dis=", "") ?? "";
      if (dis) {
        treeNode.label = dis;
      }
      if (tags.includes("equip")) {
        if (tags.includes("elec")) {
          treeNode.icon = "pi pi-fw pi-bolt";
        } else if (tags.includes("light") || tags.includes("lighting")) {
          treeNode.icon = "pi pi-fw pi-lightbulb";
        } else if (tags.includes("water") && tags.includes("meter")) {
          treeNode.icon = "pi pi-fw pi-gauge";
        } else if (tags.includes("sensor")) {
          treeNode.icon = "pi pi-fw pi-microchip";
        } else {
          treeNode.icon = "pi pi-fw pi-wrench";
        }
      }
      treeNode.tags = tags;
    },
    async isItOkToChangeSelection(): Promise<boolean> {
      return new Promise<boolean>((resolve, reject) => {
        if (this.isActiveNodeChanged) {
          const message = `Do you want to leave without saving?`;
          ConfirmationService.showConfirmation({
            message: message,
            header: 'Changes not saved',
            icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
            acceptIcon: 'pi pi-check',
            rejectIcon: 'pi pi-times',
            rejectClass: 'p-button-secondary p-button-text',
            accept: () => {
              // callback to execute when user confirms the action
              resolve(true);
            },
            reject: () => {
              // callback to execute when user rejects the action
              resolve(false);
            }
          });
        } else {
          resolve(true);
        }
      })
    },
    async getStreamsTags(streamKeys: string[]): Promise<Record<string, string[]>> {
      try {
        const url = `rest/BitPool_V1/streams/tags`;
        const response = await axios.post<Record<string, string[]>>(
          url,
          streamKeys
        );
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load tags",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return {};
      }
    },
    async getStreamsTagsWithParents(streamKeys: string[]): Promise<Record<string, NavTreeNodeMinimalWithParents>> {
      try {
        const url = `rest/BitPool_V1/streams/tags/with-parents`;
        const response = await axios.post<Record<string, NavTreeNodeMinimalWithParents>>(
          url,
          streamKeys
        );
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load tags",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return {};
      }
    }
  },
});
