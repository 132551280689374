<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20"><path class="add-widget-grid-icon-fill" d="M17.43 10.98c.044-.325.067-.652.07-.98a7.798 7.798 0 0 0-.07-.98l2.11-1.65a.5.5 0 0 0 .12-.64l-2-3.46a.5.5 0 0 0-.61-.22l-2.49 1a7.305 7.305 0 0 0-1.69-.98L12.49.42A.488.488 0 0 0 12 0H8a.488.488 0 0 0-.49.42l-.38 2.65a7.683 7.683 0 0 0-1.69.98l-2.49-1a.5.5 0 0 0-.61.22l-2 3.46a.493.493 0 0 0 .12.64l2.11 1.65a7.932 7.932 0 0 0-.07.98c.003.328.026.655.07.98L.46 12.63a.5.5 0 0 0-.12.64l2 3.46a.5.5 0 0 0 .61.22l2.49-1c.517.4 1.085.73 1.69.98l.38 2.65A.488.488 0 0 0 8 20h4a.488.488 0 0 0 .49-.42l.38-2.65a7.684 7.684 0 0 0 1.69-.98l2.49 1a.5.5 0 0 0 .61-.22l2-3.46a.5.5 0 0 0-.12-.64l-2.11-1.65Zm-1.98-1.71c.033.242.05.486.05.73 0 .21-.02.43-.05.73l-.14 1.13.89.7 1.08.84-.7 1.21-1.27-.51-1.04-.42-.9.68c-.385.294-.805.54-1.25.73l-1.06.43-.16 1.13-.2 1.35H9.3l-.19-1.35-.16-1.13-1.06-.43a5.675 5.675 0 0 1-1.23-.71l-.91-.7-1.06.43-1.27.51-.7-1.21 1.08-.84.89-.7-.14-1.13c-.03-.31-.05-.54-.05-.74 0-.2.02-.43.05-.73l.14-1.13-.89-.7-1.08-.84.7-1.21 1.27.51 1.04.42.9-.68c.385-.294.805-.54 1.25-.73l1.06-.43.16-1.13L9.3 2h1.39l.19 1.35.16 1.13 1.06.43c.438.184.851.423 1.23.71l.91.7 1.06-.43 1.27-.51.7 1.21-1.07.85-.89.7.14 1.13ZM10 6a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 6a2 2 0 1 1 0-4 2 2 0 0 1 0 4Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class GearSvg extends Vue {
}

export default GearSvg;
</script>