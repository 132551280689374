<template>
  <div class="col-12 md:col-8 semantics-edit-str">
    <label :for="inputId">Value</label>
    <AutoComplete 
      :inputId="inputId"
      v-if="isEnum"
      v-model="strVal" 
      dropdown 
      :suggestions="filteredEnumOptions"
      :virtualScrollerOptions="{ itemSize: 38 }"
      @complete="filterEnumOptions"
      class="inputfield w-full"
      placeholder="Enum"
      :disabled="disabledFields.includes(field)"
    />
    <div v-else :class="field === 'geoAddr' ? 'flex' : ''">
      <Textarea 
        :id="inputId"
        rows="1"
        class="inputfield w-full flex-auto"
        v-model="strVal"
        :autoResize="true"
        :disabled="disabledFields.includes(field)"
      />
      <Button v-if="field === 'geoAddr'" v-tippy="'Select Location'" icon="pi pi-map-marker" class="semantics-tags-control-btn p-button-icon-only p-button-outlined p-button-rounded ml-2 flex-shrink-0" @click="locationIsOpen = true"/>
      <SelectLocationView v-if="field === 'geoAddr'" v-model="locationIsOpen" @locationChanged="locationChanged"/>
    </div>
  </div>
</template>

<script lang="ts">
import AutoComplete from 'primevue/autocomplete';
import Textarea from 'primevue/textarea';
import Button from 'primevue/button';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HDict, HList, HaysonCoord, HaysonDict, HaysonVal, Kind } from "haystack-core";
import HaystackDefsService from '@/services/HaystackDefsService';
import { AutoCompleteCompleteEvent } from 'primevue/autocomplete';
import SelectLocationView from "@/components/views/SelectLocationView.vue";
import { GoogleMapLocation } from '@/models/ChangeLocationEvent';

@Component({
  components: {
    AutoComplete,
    Textarea,
    Button,
    SelectLocationView
  },
})
class HaystackStrEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }

  get fieldDef(): HDict | undefined {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      const def = defs.get(this.field);
      return def;
    }
    return undefined;
  }

  // #region location
  locationIsOpen = false;
  newLocation: GoogleMapLocation | null = null;

  locationChanged(newLocation: GoogleMapLocation): void {
    this.newLocation = newLocation;
    const locationTags = HaystackDefsService.googleLocationToTags(this.newLocation);
    if (locationTags.length > 0) {
      for (const tag of locationTags) {
        const parts = tag.split("=");
        if (parts.length > 0) {
          if (parts[0] === "geoCoord") {
            const partsCoord = (parts.length > 1 && parts[1]) ? parts[1].split(",") : ["0", "0"];
            const haysonVal: HaysonCoord = {
              _kind: Kind.Coord,
              lat: parseFloat(partsCoord[0]),
              lng: parseFloat(partsCoord[1])
            };
            this.haysonDictModel[parts[0]] = haysonVal;
          } else {
            this.haysonDictModel[parts[0]] = parts.length > 1 ? parts[1] : "";
          }
        }
      }
    }
  }
  // #endregion location
  
  // #region Str
  // string of Unicode characters
  get strVal(): string {
    if (this.valueHayson) {
      return this.valueHayson as string;
    }
    return "";
  }

  set strVal(value: string) {
    this.valueHayson = value;
  }

  get isEnum(): boolean {
    const def = this.fieldDef;
    if (def?.keys.some(x => x === "enum")) {
      return true;
    }
    if (this.choices.length) {
      return true;
    }
    return false;
  }

  get choices(): string[] {
    let result: string[] = [];
    const def = this.fieldDef;
    if (def) {
      const isChoice = HaystackDefsService.isChoice(def);
      if (isChoice) {
        const defs = HaystackDefsService.getDefs();
        if (defs) {
          const choices = defs.choicesFor(this.field);
          if (choices.length) {
            result = choices.map(x => HaystackDefsService.hDictToString(x));
          }
          if (result.length === 0) {
            defs.allSubTypesOf(def.defName).forEach(x => result.push(x.defName));
          }
        }
      }
    }
    return result;
  }

  get enumOptions(): string[] {
    const result: string[] = [];
    const def = this.fieldDef;
    if (def?.keys.some(x => x === "enum")) {
      const enumStr = def.get("enum");
      if (enumStr) {
        // parse enum, example: geoCountry
        const lines = enumStr.toString().split("\n");
        lines.forEach(x => {
          let line = x.trim();
          if (line) {
            if (line.startsWith("-")) {
              line = line.substring(1).trim();
            }
          }
          if (line) {
            const parts = line.split(":");
            result.push(parts[0]);
          }
        });
      }
    }
    const choices = this.choices;
    if (choices.length) {
      result.push(...choices);
    }
    return result;
  }

  filteredEnumOptions: string[] = [];

  filterEnumOptions(event: AutoCompleteCompleteEvent): void {
    const query = event.query ? event.query.toLowerCase() : "";
    const result = event.query ? this.enumOptions.filter(x => x.toLowerCase().includes(query)) : this.enumOptions;
    this.filteredEnumOptions = [...result];
  }
  // #endregion Str
}

export default HaystackStrEditView;
</script>