<template>
  <section class="login animate__animated animate__bounceInLeft">
    <div class="logo-container mb-4">
      <img
        :src="'/assets/bitpool-logo-dark.svg'"
        width="470"
        alt="Bitpool"
        class="logo-light-theme"
      />
      <img
        :src="'/assets/bitpool-logo-white.svg'"
        width="470"
        alt="Bitpool"
        class="logo-dark-theme"
      />
    </div>

    <div class="auth-form">
      <div class="auth-form-inner">
        <h1 class="text-center text-3xl md:text-4xl mb-1">Please wait</h1>

        <div class="text-center login-waiting-approve">
          Your organisation admin will <br>bring you to the party shortly
        </div>
        
        <div class="text-center mt-2 pt-2 md:mt-3">
          <div>
            Already added? 
            <span @click="goLogin" class="link">Login</span>
          </div>
        </div>
      </div>
    </div>

    <div class="text-center mt-5 pt-2 md:mt-3 md:pt-0 auth-bottom">
      A problem? <br /><a href="mailto:support@bitpool.com">Click here</a> and
      let us help you.
    </div>
  </section>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import InputText from "primevue/inputtext";
import Checkbox from "primevue/checkbox";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Password from 'primevue/password';
import ExternalLoginView from "@/components/views/auth/ExternalLoginView.vue";

@Component({
  
  components: {
    InputText,
    Checkbox,
    Button,
    ProgressSpinner,
    Password,
    ExternalLoginView
  },
})
class NoOrganisationView extends Vue {
  get displaySection(): string {
    return this.$store.state.login.displaySection;
  }

  set displaySection(value: string) {
    this.$store.commit("login/setDisplaySection", value);
  }

  goLogin(): void {
    this.displaySection = "login";
  }
}

export default NoOrganisationView;
</script>