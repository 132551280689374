import { TimeZoneDto } from "@/models/TimeZoneDto";

export default class RootState {
  apiUrl = "";
  timezones: TimeZoneDto[] = [];
  isFullscreen = false;
  appHeight = "";
  debugSettigns: string[] = [];
  isChristmas = false;
  minimizeNav = false;
  isSidebarMode = false;
}
