export enum TimeRange {
  /**"from" to "to" */
  Custom,

  /**00:00 to 00:59 */
  ThisHour,

  /**00:00 to 23:59 */
  Today,

  /**Mon 00:00 to Sun 23:59 */
  ThisWeek,

  /**1st 00:00 to end-of-month 23:59 */
  ThisMonth,

  /**1st Jan 00:00 to Dec 31 23:59 */
  ThisYear,

  /**Hour before this hour */
  LastHour,

  /**Yesterday */
  Yesterday,

  /**Week before this week */
  LastWeek,

  /**Month before this month */
  LastMonth,

  /**Year before this year */
  LastYear,

  /**Last 24 hours */
  RollingDay,

  /**Last 7 days */
  RollingWeek,

  /**Last 30 days */
  RollingMonth,

  /**Last 365 days */
  RollingYear,

  /**Last hour */
  RollingHour
}
