<template>
  <div class="dialog-content share-dashboard-dialog-content">
    <TabView>
      <TabPanel header="Dashboard">
        <div class="share-dashboard-content">
          <div class="field pt-1">
            <div class="flex align-items-center">
              <InputSwitch @change="shareChanged" v-model="enableShare" inputId="enableShare" class="vertical-align-top" :disabled="loadingInProgress || !shareState.isLoadedShareId || !shareState.isLoadedShortUrl" />
              <label for="enableShare" class="mb-0 ml-2">Enable</label>
            </div>
          </div>

          <div class="flex justify-content-center align-items-center" v-if="loadingInProgress || !shareState.isLoadedShareId || !shareState.isLoadedShortUrl">
            <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
          </div>
          <div v-else-if="!shortUrl" class="flex justify-content-center align-items-center text-center text-lg font-semibold">
            Start telling your story now, send dashboards to anyone
          </div>

          <div class="md:flex gap-5" v-if="shortUrl">
            <section>
              <h3>Use the following link to share the selected dashboard only.</h3>
              <div class="flex align-items-center">
                <div class="flex-initial">
                  <span class="break-word">{{shortUrl}}</span>
                </div>
                <div class="flex-none ml-4">
                  <Button v-tippy="'Copy link'" @click="copyValue(shortUrl)" icon="pi pi-copy text-2xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem"></Button>
                  <Button v-tippy="'Refresh link'" @click="reshare" icon="pi pi-sync text-xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem ml-2"></Button>
                </div>
              </div>
            
              <div class="mt-4 inline-block cursor-pointer" @click="saveQR('dashboardShareQR')">
                <QrcodeVue id="dashboardShareQR" :value="shortUrl" render-as="svg" foreground="#133547" background="#f6f6f6" class="vertical-align-top"></QrcodeVue>
                <label class="block mt-2 text-center cursor-pointer">
                  Click to save
                </label>
              </div>
            </section>
            <section>
              <h3>Use the following html code to embed the selected dashboard only.</h3>
              <div class="flex align-items-center">
                <div class="flex-auto">
                  <span class="break-word">{{urlToIFrame(shortUrl)}}</span>
                </div>
                <div class="flex-none ml-4">
                  <Button v-tippy="'Copy HTML'" @click="copyValue(urlToIFrame(shortUrl))" icon="pi pi-copy text-2xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem"></Button>
                </div>
              </div>
            </section>
          </div>
          <div v-if="shortUrl">
            <section>
              <h3>Share dashboard via:</h3>
              <div class="share-network-buttons">
                <ShareNetwork
                  v-for="network in networks"
                  :network="network.network"
                  :key="network.network"
                  :style="{ backgroundColor: network.color, borderColor: network.color }"
                  :url="shortUrl"
                  :title="`Bitpool: ${$store.state.dashboard.currentDashboard ? $store.state.dashboard.currentDashboard.spaceName : ''}`"
                  hashtags="bitpool"
                  class="share-network-button p-button p-component"
                >
                  <i class="text-xl mr-2" :class="network.icon"></i>
                  <span>{{ network.name }}</span>
                </ShareNetwork>
              </div>
            </section>
          </div>
        </div>
      </TabPanel>

      <TabPanel header="Dashboard Group">
        <div class="share-dashboard-content">
          <div class="field pt-1">
            <div class="flex align-items-center">
              <InputSwitch @change="shareWithChildrenChanged" v-model="enableShareWithChildren" inputId="enableShare" class="vertical-align-top" :disabled="loadingInProgress || !shareState.isLoadedShareIdTree || !shareState.isLoadedShortUrlTree" />
              <label for="enableShare" class="mb-0 ml-2">Enable</label>
            </div>
          </div>

          <div class="flex justify-content-center align-items-center" v-if="loadingInProgress || !shareState.isLoadedShareIdTree || !shareState.isLoadedShortUrlTree">
            <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
          </div>
          <div v-else-if="!shortUrlTree" class="flex justify-content-center align-items-center text-center text-lg font-semibold">
            Start telling your story now, send dashboards to anyone
          </div>

          <div class="md:flex gap-5" v-if="shortUrlTree">
            <section>
              <h3>Use the following link to share the selected dashboard group.</h3>
              <div class="flex align-items-center">
                <div class="flex-initial">
                  <span class="break-word">{{shortUrlTree}}</span>
                </div>
                <div class="flex-none ml-4">
                  <Button v-tippy="'Copy link'" @click="copyValue(shortUrlTree)" icon="pi pi-copy text-2xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem"></Button>
                  <Button v-tippy="'Refresh link'" @click="reshareTree" icon="pi pi-sync text-xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem ml-2"></Button>
                </div>
              </div>

              <div class="mt-4 inline-block cursor-pointer" @click="saveQR('dashboardShareTreeQR')">
                <QrcodeVue id="dashboardShareTreeQR" :value="shortUrlTree" render-as="svg" foreground="#133547" background="#f6f6f6" class="vertical-align-top"></QrcodeVue>
                <label class="block mt-2 text-center cursor-pointer">
                  Click to save
                </label>
              </div>
            </section>

            <section>
              <h3>Use the following html code to embed the selected dashboard group.</h3>
              <div class="flex align-items-center">
                <div class="flex-auto">
                  <span class="break-word">{{urlToIFrame(shortUrlTree)}}</span>
                </div>
                <div class="flex-none ml-4">
                  <Button v-tippy="'Copy HTML'" @click="copyValue(urlToIFrame(shortUrlTree))" icon="pi pi-copy text-2xl" class="p-button-rounded p-button-icon-only w-3rem h-3rem"></Button>
                </div>
              </div>
            </section>
          </div>
          <div v-if="shortUrlTree">
            <section>
              <h3>Share dashboard via:</h3>
              <div class="share-network-buttons">
                <ShareNetwork
                  v-for="network in networks"
                  :network="network.network"
                  :key="network.network"
                  :style="{ backgroundColor: network.color, borderColor: network.color }"
                  :url="shortUrlTree"
                  :title="`Bitpool: ${$store.state.dashboard.currentDashboard ? $store.state.dashboard.currentDashboard.spaceName : ''}`"
                  hashtags="bitpool"
                  class="share-network-button p-button p-component"
                >
                  <i class="text-xl mr-2" :class="network.icon"></i>
                  <span>{{ network.name }}</span>
                </ShareNetwork>
              </div>
            </section>
          </div>
        </div>
      </TabPanel>
    </TabView>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import InputSwitch from "primevue/inputswitch";
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import QrcodeVue from 'qrcode.vue'
import { IsSharedModel3 } from "@/models/dashboard/IsSharedModel3";
import ShareState from "@/store/states/ShareState";
import copy from 'copy-to-clipboard';
import ToastService from "@/services/ToastService";
import PrintHelper from "@/helpers/PrintHelper";
import { saveAs } from 'file-saver';
import ConfirmationService from "@/services/ConfirmationService";

@Component({
  
  components: {
    Button,
    InputSwitch,
    BlockUI,
    ProgressSpinner,
    TabView,
    TabPanel,
    QrcodeVue
  },
})
class ShareView extends Vue {
  enableShare = false;
  enableShareWithChildren = false;

  get shareState(): ShareState {
    return this.$store.state.share;
  }

  get loadingInProgress(): boolean {
    return !this.shareState.isLoadedShared;
  }

  get sharingInProgress(): boolean {
    return !this.shareState.isLoadedShareId && !this.shareState.isLoadedShareIdTree &&
      !this.shareState.isLoadedShortUrl && !this.shareState.isLoadedShortUrlTree;
  }

  get isShared(): IsSharedModel3 | null {
    return this.shareState.isShared;
  }

  get shortUrl(): string | null {
    return this.shareState.shortUrl;
  }

  get shortUrlTree(): string | null {
    return this.shareState.shortUrlTree;
  }

  created(): void {
    this.loadData();
  }

  async loadData(): Promise<void> {
    await this.$store.dispatch("share/loadIsShared", this.$store.state.dashboard.dashboardId);
    this.enableShare = !!this.isShared?.Single;
    this.enableShareWithChildren = !!this.isShared?.Tree;
  }

  async shareChanged(): Promise<void> {
    if (this.enableShare) {
      await this.$store.dispatch("share/share", this.$store.state.dashboard.dashboardId);
    } else {
      await this.$store.dispatch("share/unshare");
    }
  }

  async shareWithChildrenChanged(): Promise<void> {
    if (this.enableShareWithChildren) {
      await this.$store.dispatch("share/shareTree", this.$store.state.dashboard.dashboardId);
    } else {
      await this.$store.dispatch("share/unshareTree");
    }
  }

  async reshare(): Promise<void> {
    const message = `Are you sure you want to refresh the share link? The existing share link will be deleted.`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Refresh Share Link',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        await this.$store.dispatch("share/reshare", this.$store.state.dashboard.dashboardId);
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async reshareTree(): Promise<void> {
    const message = `Are you sure you want to refresh the share link? The existing share link will be deleted.`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Refresh Share Link',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: async () => {
        // callback to execute when user confirms the action
        await this.$store.dispatch("share/reshareTree", this.$store.state.dashboard.dashboardId);
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  copyValue(value: string | null): void {
    if (value) {
      copy(value);
      ToastService.showToast("success", "", "Copied!", 5000);
    }
  }

  urlToIFrame(url: string | null): string {
    if (url) {
      return `<iframe src="${url}" frameborder="0" style="width: 100%; height: auto; min-height: 300px; border: 0 none; vertical-align: top;" allowfullscreen></iframe>`;
    } else { 
      return "";
    }
  }

  async saveQR(id: string): Promise<void> {
    const element = document.getElementById(id);
    if (element) {
      const result = await PrintHelper.svgToPng(300, 300, element.outerHTML, true, "#printCanvas");
      saveAs(result, "dashboard-qr.png");
    }
  }

  get networks(): any[] {
    return this.$store.state.share.networks;
  }
}

export default ShareView;
</script>
