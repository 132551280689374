<template>
  <section class="organisation-profile-edit-pools">
    <h3>All Data Pools</h3>
    <div v-if="organisationPoolsStore.data[organisationIdStr]">
      <DataTable
        v-model:selection="selectedRecords"
        :value="organisationPoolsStore.data[organisationIdStr].entities" 
        dataKey="PoolKey"
        showGridlines 
        responsiveLayout="stack" 
        breakpoint="850px" 
        class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width"
        sortField="Name" 
        :sortOrder="1"
        :paginator="true"
        :rows="20"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="menu"
        :globalFilterFields="['Name']"
        removableSort
      >
        <template #header>
          <div class="table-header">
            <div class="md:flex md:align-items-center md:justify-content-between md:gap-3">
              <div class="md:flex-shrink-0">
                <Button 
                  :disabled="!organisationPoolsStore.data[organisationIdStr].isLoaded" 
                  label="Add Pool" 
                  icon="pi pi-plus-circle" 
                  class="my-1 mr-2" 
                  @click="openCreateUpdateDialog()"
                />
                <Button 
                  label="Open Data" 
                  icon="pi pi-server" 
                  class="my-1 mr-2 p-button-outlined" 
                  @click="openData()"
                />
                <Button 
                  v-if="selectedRecords.length > 0"
                  label="Delete" 
                  icon="pi pi-trash" 
                  class="my-1 mr-2 p-button-outlined p-button-danger" 
                  @click="openDeleteSelectedRecordsConfirmation()"
                />
              </div>
              <div class="mt-3 md:mt-0">
                <IconField iconPosition="left" class="w-full md:w-auto">
                  <InputIcon class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Find Pool" class="w-full md:w-auto" />
                </IconField>
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          <div v-if="organisationPoolsStore.data[organisationIdStr].isLoaded" class="w-full" style="min-height: 50vh;">
            <span class="inline-block py-2">No data found.</span>
          </div>
          <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
            <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
          </div>
        </template>
        <Column selectionMode="multiple" headerStyle="width: 1%; min-width: 3rem;" headerClass="column-with-checkbox" bodyClass="column-with-checkbox"></Column>
        <Column :sortable="true" field="Name" header="Name" headerStyle="min-width: min-content; width: 50%;" headerClass="no-break-word" bodyClass="no-break-word">
        </Column>
        <Column :sortable="true" field="Streams" header="Streams" headerStyle="min-width: min-content; width: 15%;" headerClass="no-break-word" bodyClass="no-break-word">
        </Column>
        <Column :sortable="true" field="RegistrationDate" header="Registration Date" headerStyle="min-width: min-content; width: 25%;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <DateTimezoneView :date="slotProps.data.RegistrationDate" timezone="local"/>
          </template>
        </Column>
        <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
          <template #body="slotProps">
            <div>
              <div class="inline-flex">
                <Button 
                  icon="pi pi-external-link" 
                  class="p-button-icon-only p-button-rounded p-button-outlined mr-2 p-button-opposite"
                  @click="viewPool(slotProps.data.PoolKey)"
                  v-tippy="'Go to Pool'"
                />
                <Button 
                  icon="pi pi-trash" 
                  class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                  @click="openConfirmation(slotProps.data)" 
                  :disabled="slotProps.data.OrganisationId === 0 && !authState.permissions?.BitpoolAdmin"
                  v-tippy="'Delete'"
                />
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </section>
  <Dialog header="Add Pool" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'992px': '80vw'}" :style="{width: '56rem'}" class="organisation-profile-edit-pools-config-dialog">
    <div class="dialog-content">
      <BlockUI :blocked="organisationPoolsStore.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="organisationPoolsStore.updateInProgress ? 'blockui-blocked' : ''">
        <div class="pool-settings-container">
          <div class="formgrid grid">
            <div class="field col-12" :class="!selectedOrganisation ? 'mb-0' : ''">
              <label for="organisationForPools">From Organisation</label>
              <div>
                <Dropdown
                  inputId="organisationForPools" 
                  class="inputfield w-full"
                  v-model="selectedOrganisation"
                  :options="availableOrganisations"
                  optionLabel="Name"
                  optionValue="Id"
                  @change="onSelectedOrganisationChange()"
                  filter
                />
              </div>
            </div>
            <div class="field col-12 mb-0" v-if="selectedOrganisation">
              <label>Select Pools</label>
              <div v-if="organisationPoolsStore.data[selectedOrganisation.toString()]?.isLoaded">
                <Listbox
                  multiple 
                  v-model="selectedPoolKeys" 
                  :options="selectedOrganisationEntities" 
                  optionLabel="Name" 
                  optionValue="PoolKey"
                  class=" w-full lg:flex-auto"
                >
                </Listbox>
              </div>
              <div v-else>
                loading...
              </div>
            </div>
          </div>
        </div>

        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #header>
      <div>
        <div class="p-dialog-title">Add Pool</div>
        <div class="p-dialog-subtitle"></div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
        <span class="block">
          <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        </span>
        <span class="block ml-2">
          <Button 
            label="Add" 
            :icon="organisationPoolsStore.updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
            @click="saveRecord" 
            :disabled='!selectedOrganisation || !selectedPoolKeys.length || organisationPoolsStore.updateInProgress' 
          />
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Message from 'primevue/message';
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Textarea from 'primevue/textarea';
import Listbox from "primevue/listbox";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import ConfirmationService from "@/services/ConfirmationService";
import { useOrganisationStore } from "@/stores/organisation";
import { useOrganisationPoolsStore } from "@/stores/organisationPools";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import NavigationHelper from "@/helpers/NavigationHelper";
import { PoolDto } from "@/models/organisation/PoolDto";
import { PoolsToOrganisationDto } from "@/models/organisation/PoolsToOrganisationDto";

@Component({
  components: {
    Message,
    Button,
    InputText,
    InputNumber,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown,
    Textarea,
    Listbox,
    IconField,
    InputIcon,
    DateTimezoneView
  },
  directives: {
  }
})
class OrganisationPoolsView extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  organisationPoolsStore = useOrganisationPoolsStore();

  openData(): void {
    NavigationHelper.goTo("/data/pools")
  }

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get availableOrganisations(): OrganisationFullDto[] {
    let result = this.organisationStore.entities ?? [];
    result = result.filter(x => x.Id !== this.organisationStore.currentOrganisation?.Id);
    return result;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  created(): void {
    if (!this.organisationPoolsStore.data[this.organisationIdStr]?.isLoaded && this.organisationStore.currentOrganisation) {
      this.organisationPoolsStore.load(this.organisationStore.currentOrganisation.Id);
    }
  }

  unmounted() {
    this.organisationPoolsStore.$reset();
  }

  viewPool(poolKey: string | null | undefined): void {
    if (poolKey) {
      const newUrl = `/data/pools/${poolKey}`;
      window.open(newUrl, '_blank');
    }
  }

  // #region add
  displayCreateUpdateDialog = false;
  selectedOrganisation: number | null = null;
  selectedPoolKeys: string[] = [];

  get selectedOrganisationEntities(): PoolDto[] {
    if (this.selectedOrganisation) {
      let result = this.organisationPoolsStore.data[this.selectedOrganisation.toString()].entities ?? [];
      if (result.length) {
        const currentOrganisationPoolKeys = (this.organisationPoolsStore.data[this.organisationIdStr]?.entities ?? []).map(x => x.PoolKey); 
        if (currentOrganisationPoolKeys.length) {
          result = result.filter(x => !currentOrganisationPoolKeys.includes(x.PoolKey));
        }
      }
      result = result.sort((a, b) => a.Name.localeCompare(b.Name));
      return result;
    } else {
      return [];
    }
  }

  async onSelectedOrganisationChange(): Promise<void> {
    if (this.selectedOrganisation) {
      this.selectedPoolKeys = [];
      const organisationIdStr = this.selectedOrganisation.toString();
      if (!this.organisationPoolsStore.data[organisationIdStr]?.isLoaded) {
        await this.organisationPoolsStore.load(this.selectedOrganisation);
      }
    }
  }

  openCreateUpdateDialog(): void {
    this.selectedOrganisation = null;
    this.selectedPoolKeys = [];
    this.displayCreateUpdateDialog = true;
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  async saveRecord(): Promise<void> {
    if (this.organisationStore.currentOrganisation && this.selectedOrganisation && this.selectedPoolKeys.length > 0) {
      const body: PoolsToOrganisationDto = {
        Id: this.organisationStore.currentOrganisation.Id,
        PoolKeys: this.selectedPoolKeys
      };
      await this.organisationPoolsStore.add(body);
      if (!this.organisationPoolsStore.updateError) {
        this.displayCreateUpdateDialog = false;
      }
    }
  }
  // #endregion add

  // #region delete
  selectedRecord: PoolDto | null = null;

  openConfirmation(record: PoolDto | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete pool?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Pool',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord && this.currentOrganisation) {
      this.organisationPoolsStore.delete(this.currentOrganisation.Id, [this.selectedRecord.PoolKey]);
    }
  }

  selectedRecords: PoolDto[] = [];

  openDeleteSelectedRecordsConfirmation(): void {
    const message = `Are you sure you want to delete selected pools?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Pool',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteSelectedRecords();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async deleteSelectedRecords(): Promise<void> {
    if (this.selectedRecords.length && this.currentOrganisation) {
      await this.organisationPoolsStore.delete(this.currentOrganisation.Id, this.selectedRecords.map(x => x.PoolKey));
      if (!this.organisationPoolsStore.deleteError) {
        this.selectedRecords = [];
      }
    }
  }
  // #endregion delete
}

export default OrganisationPoolsView;
</script>