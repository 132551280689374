<template>
  <div class="widget-type-notifications-status">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType" message="There is no data to show"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-else class="flex flex-column justify-content-center align-items-center">
      <div class="flex-shrink-0">
        <h2 
          ref="textContainer"
          :style="[ {color: textColorMedium} ]"
        >
          <a :style="{color: textColorMedium}" :href="link" target="_self" @click="goToLink">
            <span>{{ text }}</span>
          </a>
        </h2>
        <p>
          <a :href="link" target="_self" @click="goToLink">
            <template v-if="notificationIcon === 0">
              <svg width="26" height="26" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20.5 10.7438L12.3073 18.3271L8.58333 14.8801M27 14C27 21.1797 21.1797 27 14 27C6.8203 27 1 21.1797 1 14C1 6.8203 6.8203 1 14 1C21.1797 1 27 6.8203 27 14Z" :stroke="textColorMedium" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </template>
            <template v-else>
              <svg width="26" height="26" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 8.8V14M14 19.2H14.013M27 14C27 21.1797 21.1797 27 14 27C6.8203 27 1 21.1797 1 14C1 6.8203 6.8203 1 14 1C21.1797 1 27 6.8203 27 14Z" :stroke="textColorMedium" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </template>
            
            <span v-if="notificationStatus" :style="{color: textColorLight}">
              <b :style="{color: textColorMedium}">{{ notificationStatus }}</b> notifications 
              <br>in <b :style="{color: textColorMedium}">{{ groupName }}</b>
            </span>
            <span v-else :style="{color: textColorMedium}">
              Notifications <br>in <b :style="{color: textColorMedium}">{{ groupName }}</b>
            </span>
          </a>
        </p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import ColorHelper from '@/helpers/ColorHelper';
import NavigationHelper from '@/helpers/NavigationHelper';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { nextTick } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import fitty, { FittyInstance, FittyOptions } from 'fitty';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import { useNotificationStore } from '@/stores/notification';
import { useNotificationGroupStore } from '@/stores/notificationGroup';
import numbro from 'numbro';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import ProgressSpinner from 'primevue/progressspinner';
import { MongoEntityPageWithGroupsTotal } from '@/models/MongoEntityPageWithGroupsTotal';
import { NotificationEntity } from '@/models/notification/NotificationEntity';

@Component({
  components: {
    WidgetNoDataView,
    ProgressSpinner
  }
})
class NotificationsStatusWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  backgroundColor = "#ffffff";
  groupName = "";

  get notificationStatus(): string | null {
    if (this.aws) {
      switch (this.aws.notificationStatus) {
        case true:
          return "Read";
        case false:
          return "Unread";
        case null:
        default:
          return null;
      }
    }
    return null;
  }

  get notificationIcon(): number {
    return this.countUnread > 0 ? 1 : 0;
  }

  get textColor(): string {
    return ColorHelper.getContrastColor(this.backgroundColor);
  }

  get textColorMedium(): string {
    return this.textColor === ColorHelper.defaultDarkFontColor ? "#1B2732" : "#ffffff";
  }

  get textColorLight(): string {
    return this.textColor === ColorHelper.defaultDarkFontColor ? "#525252" : "#A3A3A3";
  }

  text = "";
  count = 0;
  countRead = 0;
  countUnread = 0;

  get link(): string {
    return `/notifications`;
  }

  fittyInstance: FittyInstance | null = null;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  dataRefreshInterval = 0;
  reloadDataEverySeconds = 60;

  created(): void {
    this.reloadData(false);
  }

  mounted(): void {
    this.initFitty();
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.fittyInstance) {
      this.fittyInstance.unsubscribe();
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  gridSizeChangedEvent(): void {
    this.recalcFontSize();
  }

  initFitty(): void {
    if (this.$refs.textContainer) {
      const params = {
        minSize: 50,
        maxSize: 136,
        observeMutations: false
      } as unknown;
      (fitty as any).observeWindowDelay = 110;
      this.fittyInstance = fitty(this.$refs.textContainer as HTMLElement, params as FittyOptions);
    }
  }

  @Watch('aws', { immediate: false, deep: true })
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async recalcFontSize(): Promise<void> {
    if (this.fittyInstance) {
      this.fittyInstance.unsubscribe();
      await nextTick();
    }
    this.initFitty();
  }

  goToLink(event: Event): void {
    event.preventDefault();
    NavigationHelper.goTo(this.link);
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;

  notificationStore = useNotificationStore();
  notificationGroupStore = useNotificationGroupStore();

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData(true);
  }

  async reloadData(silent = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.aws?.notificationGroupId) {
      if (!this.notificationGroupStore.isLoaded) {
        if (this.notificationGroupStore.loadingInProgress) {
          let i = 0;
          while (this.notificationGroupStore.loadingInProgress) {
            // wait 0.5 second
            await new Promise(resolve => setTimeout(resolve, 500));
            i++;
            if (i > 60) {
              break;
            }
          }
        }
        if (!this.notificationGroupStore.isLoaded) {
         await this.notificationGroupStore.load();
        }
      }
      const notificationGroup = this.notificationGroupStore.entities?.find(x => x.Id === this.aws?.notificationGroupId);
      if (notificationGroup) {
        this.backgroundColor = notificationGroup.Color;
        this.groupName = notificationGroup.Name;
        const isRead = this.aws?.notificationStatus ?? null;
        const promises: Promise<MongoEntityPageWithGroupsTotal<NotificationEntity> | null>[] = [];
        if (isRead === null || isRead === true) {
          promises.push(this.notificationStore.loadCustom(
            0, 
            1, 
            true, 
            this.aws?.notificationFilter ?? "", 
            [notificationGroup.Id],
            false,
            null,
            this.aws.notificationSubtitles,
            this.aws.notificationSourceIds
          ));
        }
        if (isRead === null || isRead === false) {
          promises.push(this.notificationStore.loadCustom(
            0, 
            1, 
            false, 
            this.aws?.notificationFilter ?? "", 
            [notificationGroup.Id],
            false,
            null,
            this.aws.notificationSubtitles,
            this.aws.notificationSourceIds
          ));
        }
        const results = await Promise.all(promises);
        let total = 0;
        let totalRead = 0;
        let totalUnread = 0;
        if (results.find(x => x)) {
          if (results.length === 2) {
            if (results[0]) {
              total = results[0].Total;
              totalRead = results[0].Total;
            }
            if (results[1]) {
              total += results[1].Total;
              totalUnread = results[1].Total;
            }
          } else {
            if (results[0]) {
              total = results[0].Total;
              if (isRead) {
                totalRead = results[0].Total;
              } else {
                totalUnread = results[0].Total;
              }
            }
          }
          this.count = total;
          this.countRead = totalRead;
          this.countUnread = totalUnread;
          this.text = numbro(total).format({
            thousandSeparated: true,
            mantissa: 0
          });
          this.isNoData = false;
          this.isLodingData = false;

          // overwrite color
          const colorOverwriteArg = `guid:${this.widgetConfig.guid};color:${this.backgroundColor}`;
          this.emitter.emit("widgetColorOverwrite", colorOverwriteArg);

          await nextTick();
          this.recalcFontSize();
          // prevent color reset
          return;
        } else {
          this.isNoData = true;
          this.noDataType = WidgetNoDataTypes.NoData;
          this.isLodingData = false;
        }
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
        this.isLodingData = false;
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
    // no data or not configured
    // set color to default
    const colorOverwriteArg = `guid:${this.widgetConfig.guid};color:NONE`;
        this.emitter.emit("widgetColorOverwrite", colorOverwriteArg);
  }
}

export default NotificationsStatusWidget;
</script>