<template>
  <DataTable
    v-model:selection="selectedRecords"
    :value="model.Users" 
    dataKey="Id"
    showGridlines 
    responsiveLayout="stack" 
    breakpoint="660px" 
    class="responsive-breakpoint p-datatable-sm organisation-profile-users-groups-members-edit-table"
    sortField="DateAdded" 
    :sortOrder="-1"
    :paginator="true"
    :rows="20"
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
    :rowsPerPageOptions="[10, 20, 50]"
    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
    v-model:filters="filters"
    filterDisplay="menu"
    :globalFilterFields="['UserName']"
    removableSort
  >
    <template #header>
      <div class="table-header">
        <div class="md:flex md:align-items-center md:justify-content-between md:gap-3">
          <div class="md:flex-shrink-0">
            <Button 
              :disabled="!organisationUsersStore.data[organisationIdStr]?.isLoaded" 
              label="Add Member" 
              icon="pi pi-plus-circle" 
              class="my-1 mr-2" 
              @click="openCreateDialog"
            />
            <Button 
              v-if="selectedRecords.length > 0"
              label="Delete" 
              icon="pi pi-trash" 
              class="my-1 mr-2 p-button-outlined p-button-danger" 
              @click="openDeleteSelectedRecordsConfirmation()"
            />
          </div>
          <div class="mt-3 md:mt-0">
            <IconField iconPosition="left" class="w-full md:w-auto">
              <InputIcon class="pi pi-search" />
              <InputText v-model="filters['global'].value" placeholder="Find Member" class="w-full md:w-auto" />
            </IconField>
          </div>
        </div>
      </div>
    </template>
    <template #empty>
      <div class="w-full" style="min-height: 50vh;">
        <span class="inline-block py-2">No data found.</span>
      </div>
    </template>
    <Column selectionMode="multiple" headerStyle="width: 1%; min-width: 3rem;" headerClass="column-with-checkbox" bodyClass="column-with-checkbox"></Column>
    <Column :sortable="true" field="Username" header="Member Name" headerStyle="min-width: min-content; width: 45%;" headerClass="no-break-word" bodyClass="break-word">
      <template #body="slotProps">
        <div class="organisation-profile-users-members-user-card">
          <Avatar
            v-if="getAvatarUrl(slotProps.data.Username)"
            :image="getAvatarUrl(slotProps.data.Username)"
            shape="circle"
            class="user-img"
          />
          <Avatar
            v-else
            :label="shortName(getFullname(slotProps.data))"
            shape="circle"
            class="user-label"
          />
          <div>
            <div class="organisation-profile-users-members-user-name">{{ getFullname(slotProps.data) }}</div>
            <div class="organisation-profile-users-members-user-email">{{ slotProps.data.UserName }}</div>
          </div>
        </div>
      </template>
    </Column>
    <Column :sortable="true" field="DateAdded" header="Date Added" headerStyle="min-width: min-content; width: 17%;" headerClass="no-break-word" bodyClass="no-break-word">
      <template #body="slotProps">
        <DateTimezoneView :date="slotProps.data.DateAdded" timezone="local"/>
      </template>
    </Column>
    <Column :exportable="false" headerStyle="width: 1%; min-width: 44px;" bodyStyle="text-align: right; justify-content: flex-end;">
      <template #body="slotProps">
        <div>
          <div class="inline-flex">
            <Button 
              icon="pi pi-trash" 
              class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
              @click="openConfirmation(slotProps.data)" 
              v-tippy="'Delete'"
            />
          </div>
        </div>
      </template>
    </Column>
  </DataTable>

  <Dialog header="Add Members" v-model:visible="displayCreateUpdateDialog" :modal="true" :breakpoints="{'992px': '80vw'}" :style="{width: '56rem'}" class="organisation-profile-users-groups-members-config-dialog">
    <div class="dialog-content">
      <div>
        <div class="formgrid grid align-items-end">
          <div class="field col md:mb-0">
            <label for="addRecord">Members</label>
            <div>
              <MultiSelect 
                inputId="addRecord"
                v-model="addRecord"
                :options="availableUsers"
                placeholder="Select Members"
                display="chip"
                :filter="true"
                optionLabel="UserName"
                class="p-multiselect-multiline inputfield w-full"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #header>
      <div>
        <div class="p-dialog-title">Add Members</div>
        <div class="p-dialog-subtitle">Please select new members for the group.</div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
        <span class="block">
          <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        </span>
        <span class="block ml-2">
          <Button 
            label="Add" 
            icon="pi pi-check" 
            @click="saveRecord" 
            :disabled='!addRecord.length' 
          />
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Model, Vue, Watch } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import Dropdown from 'primevue/dropdown';
import Avatar from "primevue/avatar";
import MultiSelect from "primevue/multiselect";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import ConfirmationService from "@/services/ConfirmationService";
import { useOrganisationStore } from "@/stores/organisation";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";
import { UserFullInOrganisation2Dto } from "@/models/user/UserFullInOrganisation2Dto";
import { NotificationGroupEntity } from "@/models/notification/NotificationGroupEntity";
import { NotificationGroupUser } from "@/models/notification/NotificationGroupUser";

@Component({
  components: {
    Button,
    InputText,
    DataTable,
    Column,
    Dialog,
    Dropdown,
    Avatar,
    MultiSelect,
    IconField,
    InputIcon,
    DateTimezoneView
  },
  directives: {
  }
})
class OrganisationNotificationGroupsMembers extends Vue {
  @Model model!: NotificationGroupEntity;

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  organisationUsersStore = useOrganisationUsersStore();

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  getAvatarUrl(username: string): string {
    const user = this.organisationUsersStore.data[this.organisationIdStr].entities?.find(x => x.UserName === username);
    if (user?.Avatar) {
      const apiUrl = this.$store.state.apiUrl;
      return `${apiUrl}/rest/AWS_S3_V1/File/${user.Avatar}`;
    } else {
      return "";
    }
  }

  shortName(fullname: string | null): string {
    return fullname ? fullname.split(" ").map(x => x[0]).join("") : "";
  }

  getFullname(userInGroup: NotificationGroupUser): string {
    const user = this.organisationUsersStore.data[this.organisationIdStr].entities?.find(x => x.UserName === userInGroup.Username);
    if (user) {
      let result = user.UserName.split('@')[0];
      if (user.Firstname || user.Lastname) {
        result = user.Firstname ? user.Firstname : "";
        if (user.Lastname) {
          result = result.length ? `${result} ${user.Lastname}` : user.Lastname;
        }
      }
      return result;
    } else {
      return userInGroup.Username.split('@')[0];
    }
  }

  // #region add/update
  displayCreateUpdateDialog = false;
  addRecord: UserFullInOrganisation2Dto[] = [];

  get availableUsers(): UserFullInOrganisation2Dto[] {
    return ((this.organisationUsersStore.data[this.organisationIdStr] ?? []).entities ?? []).filter(x => !this.model.Users.find(y => y.Username === x.UserName)).sort((a, b) => a.UserName.localeCompare(b.UserName));
  }

  openCreateDialog(): void {
    if (this.currentOrganisation) {
      this.addRecord = [];
      this.displayCreateUpdateDialog = true;
    }
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  saveRecord(): void {
    for (const record of this.addRecord) {
      this.model.Users.push({
        Username: record.UserName,
        DateAdded: new Date()
      });
    }
    this.closeCreateUpdateDialog();
  }
  // #endregion add/update

  // #region delete
  selectedRecord: NotificationGroupUser | null = null;

  openConfirmation(record: NotificationGroupUser | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete member?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Member',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    const index = this.model.Users.findIndex(x => x.Username === this.selectedRecord?.Username);
    if (index > -1) {
      this.model.Users.splice(index, 1);
    }
  }

  selectedRecords: NotificationGroupUser[] = [];

  openDeleteSelectedRecordsConfirmation(): void {
    const message = `Are you sure you want to delete selected members?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Members',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteSelectedRecords();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteSelectedRecords(): void {
    if (this.selectedRecords.length) {
      for (const record of this.selectedRecords) {
        const index = this.model.Users.findIndex(x => x.Username === record.Username);
        if (index > -1) {
          this.model.Users.splice(index, 1);
        }
      }
    }
  }
  // #endregion delete
}

export default OrganisationNotificationGroupsMembers;
</script>