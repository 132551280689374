<template>
  <Dialog header="Select Location" v-model:visible="isOpen" :modal="true" :style="{width: '36rem'}" class="select-location-dialog">
    <div class="dialog-content">
      <LMap 
        ref="map"
        @ready="hookUpSearch"
        style="height: 300px"
        :zoom="zoom"
        @update:zoom="zoomUpdate"
        :center="center"
        @update:center="centerUpdate"
        :options="{ attributionControl: false, keyboard: false }"
      >
        <template v-if="mapReady">
          <LTileLayer :url="url"></LTileLayer>
        </template>
      </LMap>
    </div>
    <template #footer>
      <Button label="Close" icon="pi pi-times" @click="isOpen = false" class="p-button-text p-button-secondary"/>
      <Button label="Select" icon="pi pi-check" @click="selectNewLocation" :disabled="!newLocationInternal" />
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Emit, Model, Vue } from "vue-facing-decorator";
import Dialog from 'primevue/dialog';
import Button from "primevue/button";
import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";
import { SearchControl, GoogleProvider } from 'leaflet-geosearch';
import ChangeLocationEvent, { GoogleMapLocation } from "@/models/ChangeLocationEvent";

@Component({
  components: {
    Dialog,
    Button,
    LMap,
    LTileLayer
  },
})
class SelectLocationView extends Vue {
  @Model isOpen!: boolean;

  @Emit locationChanged() {
    return this.newLocationInternal;
  }

  newLocationInternal: GoogleMapLocation | null = null;

  url = 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png';

  zoom = 8;
  zoomUpdate(value: number): void {
    this.zoom = value;
  }
  
  center = [-27.3793729, 152.3337157];
  centerUpdate(value: any): void {
    // example: {lat: 1091.1028537158786, lng: 420.04585553556507}
    this.center = [value.lat, value.lng];
  }

  mapObject: any | null = null;
  mapReady = false;

  hookUpSearch(): void {
    if (this.$refs.map) {
      this.mapObject = (this.$refs.map as any).leafletObject;
      this.mapReady = true;
      this.initControls();
    }
  }

  initControls(): void {
    const options = {
      notFoundMessage: 'Sorry, that address could not be found.',
      provider: new GoogleProvider({ 
        apiKey: 'AIzaSyA7b54uUoJhUYbBrmWUBshOho2wyYaqVqs',
        language: 'en',
        region: 'US'
      }),
      style: 'bar',
    };
    // without any throw: 'new' expression, whose target lacks a construct signature, implicitly has an 'any' type.
    const searchControl = new (SearchControl as any)(options);
    this.mapObject.addControl(searchControl);
    this.mapObject.on('geosearch/showlocation', this.locationChangedEvent);
  }

  locationChangedEvent(showlocationEvent: ChangeLocationEvent<GoogleMapLocation>): void {
    this.newLocationInternal = showlocationEvent.location.raw;
  }

  selectNewLocation(): void {
    this.locationChanged();
    this.isOpen = false;
  }
}

export default SelectLocationView;
</script>