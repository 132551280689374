import { defineStore } from "pinia"
import AuthState from "./states/AuthState"
import axios from "axios";
import { AuthorizeState } from "@/models/auth/AuthorizeState";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import { LoginOAuth2Step2Model } from "@/models/auth/LoginOAuth2Step2Model";
import { LoginResult } from "@/models/auth/LoginResult";
import NavigationHelper from "@/helpers/NavigationHelper";
import { AUTH_Login2 } from "@/models/auth/AUTH_Login2";
import { Secret2faModel } from "@/models/auth/Secret2faModel";
import { AUTH_LoginAs } from "@/models/auth/AUTH_LoginAs";
import localeManager from "@/localeManager";

export const useAuthStore = defineStore('auth', {
  state: (): AuthState => ({ 
    authStateLatest: null, 
    providerLatest: null,
    registerEmailLatest: null,
    is2faEnabled: null
  }),
  getters: {
    //doubleCount: (state) => state.count * 2,
  },
  actions: {
    async loginAs(body: AUTH_LoginAs): Promise<string> {
      try {
        const url = `rest/Auth_V4/LoginAs`;
        const response = await axios.post<string>(url, body);
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          localeManager.t("login.loginError"),
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return "";
      }
    },
    async login(body: AUTH_Login2, code2fa: string): Promise<any> {
      try {
        let url = `public/v2/Login2`;
        if (code2fa) {
          url += `?code2fa=${code2fa}`;
        }
        const response = await axios.post<LoginResult>(url, body);
        // prepare data for angular
        const angularCredentials = {
          OrgId: response.data.OrganisationId,
          Organisation: response.data.Organisation,
          username: response.data.Username,
          password: "",
          Place: body.Place,
          authKey: response.data.AuthKey
        };
        return angularCredentials;
      } catch (error) {
        ToastService.showToast(
          "error",
          localeManager.t("login.loginError"),
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return null;
      }
    },
    async load2faStatus(): Promise<void> {
      try {
        if (this.is2faEnabled === null) {
          const url = `public/v2/Is2faEnabled`;
          const response = await axios.get<boolean>(url);
          this.is2faEnabled = response.data;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          localeManager.t("login.load2faError"),
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
    async disable2fa(password: string, code: string): Promise<void> {
      try {
        const url = `public/v2/Disable2fa?password=${password}&code=${code}`;
        await axios.get(url);
        this.is2faEnabled = false;
      } catch (error) {
        ToastService.showToast(
          "error",
          localeManager.t("login.disable2faError"),
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    },
    async generateSecret(password: string): Promise<Secret2faModel | null> {
      try {
        const url = `public/v2/Generate2faSecret?password=${password}`;
        const response = await axios.get<Secret2faModel>(url);
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          localeManager.t("login.secretGenerationError"),
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return null;
      }
    },
    async confirm2fa(code: string): Promise<boolean> {
      try {
        const url = `public/v2/Confirm2fa?code=${code}`;
        const response = await axios.get<boolean>(url);
        if (response.data) {
          this.is2faEnabled = true;
        } else {
          ToastService.showToast(
            "error",
            localeManager.t("login.confirm2faError"),
            localeManager.t("login.confirm2faErrorDetails"),
            5000
          );
        }
        return response.data;
      } catch (error) {
        ToastService.showToast(
          "error",
          localeManager.t("login.confirm2faError"),
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        return false;
      }
    },
    async goExternalStep1(provider: string, registerEmail: string) {
      try {
        this.providerLatest = provider;
        this.registerEmailLatest = registerEmail;
        const url = `rest/Auth_V4/LoginOAuth2/${provider}/Step1`;
        const response = await axios.get<AuthorizeState>(url);
        this.authStateLatest = response.data;
        // save state
        localStorage.setItem("authState", JSON.stringify(this.authStateLatest));
        localStorage.setItem("provider", this.providerLatest);
        localStorage.setItem("registerEmail", this.registerEmailLatest);
        // redirect
        location.href = this.authStateLatest.StartUrl;
      } catch (error) {
        ToastService.showToast(
          "error",
          localeManager.t("login.loginError"),
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.providerLatest = null;
        this.authStateLatest = null;
      }
    },
    async goExternalStep2(data: string): Promise<any> {
      try {
        // load state
        const as = localStorage.getItem("authState");
        this.authStateLatest = as ? JSON.parse(as) : null;
        this.providerLatest = localStorage.getItem("provider") ?? null;
        this.registerEmailLatest = localStorage.getItem("registerEmail") ?? null;
        if (this.authStateLatest && this.providerLatest) {
          // send data to api to decode
          const url = `rest/Auth_V4/LoginOAuth2/${this.providerLatest}/Step2`;
          const body: LoginOAuth2Step2Model = 
            { 
              Data: data, 
              State: this.authStateLatest,
              Place: window.location.host
            };
          const response = await axios.post<LoginResult>(url, body);
          // clear state
          localStorage.removeItem("authState");
          localStorage.removeItem("provider");
          localStorage.removeItem("registerEmail");
          // prepare data for angular
          const angularCredentials = {
            OrgId: response.data.OrganisationId,
            Organisation: response.data.Organisation,
            username: response.data.Username,
            password: "",
            Place: body.Place,
            authKey: response.data.AuthKey
          };
          return angularCredentials;
        } else {
          throw new Error(localeManager.t("login.invalidAuthStateOrProvider"));
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          localeManager.t("login.loginError"),
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        const rel = this.registerEmailLatest;
        this.providerLatest = null;
        this.authStateLatest = null;
        this.registerEmailLatest = null;
        // clear state
        localStorage.removeItem("authState");
        localStorage.removeItem("provider");
        localStorage.removeItem("registerEmail");
        // go back to login or signup
        if (rel) {
          NavigationHelper.goToParams("/login", { signup: rel });
        } else {
          NavigationHelper.goTo("/login");
        }
      }
    },
  },
})