<template>
  <div class="default-view-container">
    <h1 class="mb-0">Email Import</h1>
    <div class="default-view increase-padding-bottom mt-4 lg:mt-5">      
      <header class="default-view-header">
        <h2>History</h2>
      </header>
      <div class="default-view-body">
        <DataTable
          v-if="auth.permissions?.BitpoolAdmin"
          :value="history"
          dataKey="Id"
          :totalRecords="total"
          :paginator="true"
          :rows="20"
          :lazy="true"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
          :rowsPerPageOptions="[10, 20, 50]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          @page="onPage($event)"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="850px" 
          class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width email-import-table">
          <template #empty>
            <div v-if="emailImportState.isLoaded" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column field="Subject" header="Subject" headerStyle="width: 40%;"></Column>
          <Column field="From" header="From" headerStyle="width: 24%;"></Column>
          <Column field="Imported" header="Imported" headerStyle="max-width: 1%; white-space: nowrap;"></Column>
          <Column field="Info" header="Info" headerStyle="width: 35%;"></Column>
        </DataTable>

        <Message v-else severity="error" :closable="false" class="my-0">Not enough rights</Message>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Message from 'primevue/message';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressSpinner from "primevue/progressspinner";
import AuthState from "@/store/states/AuthState";
import EmailImportState from "@/store/states/EmailImportState";
import { EmailImport } from "@/models/EmailImport";
import EmailImportQuery from "@/models/EmailImportQuery";

@Component({
  
  components: {
    Message,
    DataTable,
    Column,
    ProgressSpinner
  },
})
class EmailImportView extends Vue {
  get auth(): AuthState {
    return this.$store.state.auth;
  }

  get emailImportState(): EmailImportState {
    return this.$store.state.emailImport;
  }

  get history(): EmailImport[] {
    return this.emailImportState.data?.Items ? this.emailImportState.data.Items : [];
  }

  get total(): number {
    return this.emailImportState.data?.Total ? this.emailImportState.data.Total : 0;
  }

  take = 20;
  skip = 0;

  created(): void {
    if (this.auth.permissions?.BitpoolAdmin) {
      this.loadData();
    }
  }

  loadData(): void {
    const query: EmailImportQuery = {
      take: this.take,
      skip: this.skip
    };
    this.$store.dispatch("emailImport/load", query);
  }

  onPage(event: any): void {
    // event.page: New page number
    // event.first: Index of first record
    // event.rows: Number of rows to display in new page
    // event.pageCount: Total number of pages
    this.skip = event.page * event.rows;
    this.take = event.rows;
    this.loadData();
  }
}

export default EmailImportView;
</script>
