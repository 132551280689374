import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import AdminOrganisationStatsState from "../states/AdminOrganisationStatsState";
import { OrganisationExportModel } from "@/models/OrganisationExportModel";

const getters = <GetterTree<AdminOrganisationStatsState, any>>{  
};

const mutations = <MutationTree<AdminOrganisationStatsState>>{
};

const actions = <ActionTree<AdminOrganisationStatsState, any>>{
  async loadData({ state }) {
    try {
      state.isLoaded = false;
      const url = `rest/BitPool_V2/organisations/stats`;
      const response = await axios.get<OrganisationExportModel[]>(url);
      state.data = response.data;
      state.isLoaded = true;
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load organisation stats",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.data = [];
      state.isLoaded = true;
    }
  }
};

const AdminOrganisationStatsModule = {
  namespaced: true,
  state: new AdminOrganisationStatsState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default AdminOrganisationStatsModule;
