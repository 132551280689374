import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { GetterTree, MutationTree, ActionTree } from "vuex";
import { v4 as uuidv4 } from "uuid";
import EmailImportState from "../states/EmailImportState";
import EmailImportQuery from "@/models/EmailImportQuery";
import { EmailImportPage } from "@/models/EmailImportPage";

const getters = <GetterTree<EmailImportState, any>>{};

const mutations = <MutationTree<EmailImportState>>{
};

const actions = <ActionTree<EmailImportState, any>>{
  async load({ state }, query: EmailImportQuery) {
    try {
      const guid = uuidv4();
      state.guidPage = guid;
      state.pageQuery = query;
      state.isLoaded = false;
      state.data = null;
      const url = `rest/BitPool_V2/email-import?take=${query.take}&skip=${query.skip}`;
      const response = await axios.get<EmailImportPage>(url);
      if (state.guidPage === guid) {
        state.data = response.data;
        state.isLoaded = true;
      }
    } catch (error) {
      ToastService.showToast(
        "error",
        "Can't load email import history",
        ErrorHelper.handleAxiosError(error).message,
        5000
      );
      state.data = null;
      state.isLoaded = true;
    }
  }
};

const EmailImportModule = {
  namespaced: true,
  state: new EmailImportState(),
  getters: getters,
  mutations: mutations,
  actions: actions,
};

export default EmailImportModule;
