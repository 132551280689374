<template>
  <BlockUI 
    :blocked="!reports3.isLoadedOne || updateInProgress" 
    :autoZIndex="false" 
    :baseZIndex="100"  
    class="blockui-with-spinner blockui-with-fixed-spinner" 
    :class="(!reports3.isLoadedOne || updateInProgress) ? 'blockui-blocked' : ''"
  >
    <div v-if="editRecord" class="flex flex-wrap lg:flex-nowrap h-full">
      <div v-if="isCompactMode">
        <Button
          icon="pi pi-cog"
          @click="openSettingsDialog"
        />
        
        <Dialog header="Configuration" v-model:visible="reports3.displayConfigurationDialog" :modal="true" :style="{width: '90vw'}">
          <ReportsReportLeftPanelView/>
        </Dialog>
        <Dialog header="Settings" v-model:visible="reports3.displaySettingsDialog" :modal="true" :style="{width: '90vw'}">
          <ReportsReportRightPanelView/>
        </Dialog>
      </div>

      <div 
        v-if="reports3.editMode && !isCompactMode"
        class="p-3 w-full lg:w-3 max-w-25rem bg-white"
      >
        <div class="h-full relative">
          <div class="report-manager-sidebar">
            <ReportsReportLeftPanelView/>
          </div>
        </div>
      </div>

      <div class="p-3 w-full lg:w-auto lg:flex-auto">
        <div class="h-full relative">
          <div class="report-manager-content">
            <div class="formgrid grid">
              <div class="field col-12 mb-0">
                <div class="overflow-x-auto">
                  <div class="report-constructor-area" :class="reports3.editMode ? 'edit-mode' : 'preview-mode'">
                    <!-- Header -->
                    <div
                      class="report-section report-header"
                      :class="`${reports3.editMode && editRecord.Header?.Uid === reports3.dataOneSelectedElement?.Uid ? 'is-selected' : ''}`"
                      id="reportConstructorElementContainer-header"
                    >
                      <div 
                        v-if="reports3.editMode"
                        class="report-section-head"
                        @click="editRecord.Header && selectElement(editRecord.Header)"
                      >
                        <Button
                          v-tippy="'Select Header'"
                          class="p-button-half-white"
                          @click="openElementsDialog($event, Reports3ElementRole.Header)"
                          :disabled="reports3.inProgressTest"
                        >
                          <AddReportElementSvg/>
                        </Button>
                        <Button
                          v-if="editRecord.Header"
                          v-tippy="'Delete'"
                          @click="deleteHeader"
                          class="p-button-half-white"
                        >
                          <ReportDeleteSvg/>
                        </Button>
                        <Button
                          v-if="editRecord.Header && reports3.isCompactMode"
                          v-tippy="'Edit'"
                          @click="openEditDialog($event, editRecord.Header)"
                          class="p-button-half-white"
                        >
                          <ReportDeleteSvg/>
                        </Button>
                        <Button
                          v-tippy="'More Actions'"
                          class="p-button-half-white"
                        >
                          <ReportMoreActionsSvg/>
                        </Button>
                      </div>
                      <div class="report-section-inner">
                        <ReportsHtmlElementView
                          v-if="editRecord.Header"
                          :element="getElement(editRecord.Header.ElementId)" 
                          :configuration="editRecord.Header"
                        />
                      </div>
                    </div>

                    <!-- Body -->
                    <div
                      class="report-section report-body"
                      :class="`${reports3.editMode && !reports3.dataOneSelectedElement ? 'is-selected' : ''}`"
                      id="reportConstructorElementContainer-body"
                    >
                      <div 
                        v-if="reports3.editMode"
                        class="report-section-head"
                        @click="reports3.dataOneSelectedElement = null"
                      >
                        <Button
                          v-tippy="'Add Grid'"
                          class="p-button-half-white"
                          @click="addGrid"
                          :disabled="reports3.inProgressTest"
                        >
                          <AddReportGridSvg/>
                        </Button>
                        <Button
                          v-tippy="'Add Element'"
                          @click="openElementsDialog($event, Reports3ElementRole.Body)"
                          class="p-button-half-white"
                        >
                          <AddReportElementSvg/>
                        </Button>
                        <Button
                          v-if="editRecord.Footer && reports3.isCompactMode"
                          v-tippy="'Edit'"
                          @click="openEditDialog($event, null)"
                          class="p-button-half-white"
                        >
                          <ReportDeleteSvg/>
                        </Button>
                        <Button
                          v-tippy="'More Actions'"
                          class="p-button-half-white"
                        >
                          <ReportMoreActionsSvg/>
                        </Button>
                      </div>
                      <div
                        class="report-section-inner"
                        :style="`padding-top: ${editRecord.MarginTop}cm; padding-bottom: ${editRecord.MarginBottom}cm; padding-left: ${editRecord.MarginLeft}cm; padding-right: ${editRecord.MarginRight}cm;`"
                      >
                        <ReportsHtmlGridView 
                          v-model="editRecord.Items" 
                        />
                      </div>
                    </div>

                    <!-- Footer -->
                    <div
                      class="report-section report-footer"
                      :class="`${reports3.editMode && editRecord.Footer?.Uid === reports3.dataOneSelectedElement?.Uid ? 'is-selected' : ''}`"
                      id="reportConstructorElementContainer-footer"
                    >
                      <div 
                        v-if="reports3.editMode"
                        class="report-section-head"
                        @click="editRecord.Footer && selectElement(editRecord.Footer)"
                      >
                        <Button
                          v-tippy="'Select Footer'"
                          class="p-button-half-white"
                          @click="openElementsDialog($event, Reports3ElementRole.Footer)"
                          :disabled="reports3.inProgressTest"
                        >
                          <AddReportElementSvg/>
                        </Button>
                        <Button
                          v-if="editRecord.Footer"
                          v-tippy="'Delete'"
                          @click="deleteFooter"
                          class="p-button-half-white"
                        >
                          <ReportDeleteSvg/>
                        </Button>
                        <Button
                          v-if="editRecord.Footer && reports3.isCompactMode"
                          v-tippy="'Edit'"
                          @click="openEditDialog($event, editRecord.Footer)"
                          class="p-button-half-white"
                        >
                          <ReportDeleteSvg/>
                        </Button>
                        <Button
                          v-tippy="'More Actions'"
                          class="p-button-half-white"
                        >
                          <ReportMoreActionsSvg/>
                        </Button>
                      </div>
                      <div class="report-section-inner">
                        <ReportsHtmlElementView
                          v-if="editRecord.Footer"
                          :element="getElement(editRecord.Footer.ElementId)" 
                          :configuration="editRecord.Footer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div 
        v-if="!isCompactMode"
        class="w-full lg:w-3 max-w-25rem bg-white"
      >
        <div class="h-full relative">
          <div class="report-manager-sidebar">
            <ReportsReportRightPanelView/>
          </div>
        </div>
      </div>
    </div>

    <ReportsSelectElementDialogView
      v-model="displayElementsDialog"
      :elementRole="elementRole"
      @elementSelected="addElement"
    />

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from "vue-facing-decorator";
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import SelectButton from 'primevue/selectbutton';
import Message from 'primevue/message';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import AuthState from "@/store/states/AuthState";
import { useOrganisationStore } from "@/stores/organisation";
import { useReports3Store } from "@/stores/reports3";
import { Reports3Entity } from "@/models/reports/v3/Reports3Entity";
import { nextTick, reactive } from "vue";
import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { v4 as uuidv4 } from "uuid";
import { Reports3ItemRole } from "@/models/reports/v3/Reports3ItemRole";
import { useReports3ElementsStore } from "@/stores/reports3Elements";
import ReportsHtmlGridView from "@/components/views/reports/ReportsHtmlGridView.vue";
import ReportsSelectElementDialogView from "@/components/views/reports/ReportsSelectElementDialogView.vue";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import ReportsHtmlElementView from "@/components/views/reports/ReportsHtmlElementView.vue";
import AddReportElementSvg from "@/components/svg/AddReportElementSvg.vue";
import AddReportGridSvg from "@/components/svg/AddReportGridSvg.vue";
import ReportMoreActionsSvg from "@/components/svg/ReportMoreActionsSvg.vue";
import ReportDeleteSvg from "@/components/svg/ReportDeleteSvg.vue";
import ReportsReportLeftPanelView from "@/components/views/reports/ReportsReportLeftPanelView.vue";
import ReportsReportRightPanelView from "@/components/views/reports/ReportsReportRightPanelView.vue";
import { Emitter } from "mitt";
import EventBusHelper from "@/helpers/EventBusHelper";
import { Report3ElementFeatures } from "@/models/reports/v3/Report3ElementFeatures";

@Component({
  components: {
    Accordion,
    AccordionTab,
    Button,
    InputText,
    InputNumber,
    Dialog,
    BlockUI,
    InputSwitch,
    SelectButton,
    Message,
    ProgressSpinner,
    Dropdown,
    TabView,
    TabPanel,
    ReportsHtmlGridView,
    ReportsSelectElementDialogView,
    ReportsHtmlElementView,
    AddReportElementSvg,
    AddReportGridSvg,
    ReportMoreActionsSvg,
    ReportDeleteSvg,
    ReportsReportLeftPanelView,
    ReportsReportRightPanelView
  },
  directives: {
  }
})
class ReportsReportView extends Vue { 
  @Prop({ required: true }) reportId!: string;

  reports3 = useReports3Store();
  reports3Elements = useReports3ElementsStore();

  get editRecord(): Reports3Entity | null {
    return this.reports3.dataOne;
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  @Watch('reportId', { immediate: false, deep: false })
  async onIdChanged(val: string, oldVal: string): Promise<void> {
    this.reports3.$reset();
    await nextTick();
    this.init();
  }

  @Watch('editRecord', { immediate: false, deep: true })
  onEditRecordChanged(val: Reports3Entity | null, oldVal: Reports3Entity | null): void {
    if (this.reports3.isLoadedOne && oldVal !== null && val !== null && val.Id === oldVal.Id) {
      this.reports3.isOneDirty = true;
    }
  }

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created() {
    this.emitter.on("window_size_changed_debounce", this.onResize);
    this.onResize();
    this.init();
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.onResize);
  }

  async init(): Promise<void> {
    if (this.authState.permissions?.FullAccess) {
      await this.reports3.loadOne(this.reportId);
    }
  }
  
  modbileModeFrom = 1400;
  windowWidth = window.innerWidth;

  get isCompactMode(): boolean {
    return this.reports3.isCompactMode;
  }

  set isCompactMode(value: boolean) {
    this.reports3.isCompactMode = value;
  }

  onResize(): void {
    this.windowWidth = window.innerWidth;
    const oldValue = this.isCompactMode;
    this.isCompactMode = this.windowWidth < this.modbileModeFrom;
    if (oldValue !== this.isCompactMode) {
      this.reports3.displayConfigurationDialog = false;
      this.reports3.displaySettingsDialog = false;
    }
  }

  get updateInProgress(): boolean {
    return this.reports3.updateInProgress;
  }

  addGrid(): void {
    if (this.editRecord) {
      const item: Reports3ElementConfiguration = {
        Uid: uuidv4(),
        Size: 12,
        Role: Reports3ItemRole.Grid,
        ElementId: "",
        Items: [],
        DatasourceId: null,
        FeaturesConfiguration: null,
        AdditionalParameters: null
      };
      this.editRecord.Items.push(item);
      this.reports3.dataOneSelectedElement = item;
    }
  }

  displayElementsDialog = false;
  elementRole = Reports3ElementRole.Body;
  Reports3ElementRole = Reports3ElementRole;

  openElementsDialog(event: Event, role: Reports3ElementRole): void {
    event.stopPropagation();
    this.elementRole = role;
    this.displayElementsDialog = true;
  }

  addElement(element: Reports3ElementEntity): void {
    if (this.editRecord) {
      const item: Reports3ElementConfiguration = {
        Uid: uuidv4(),
        Size: element.DefaultSize,
        Role: Reports3ItemRole.Element,
        ElementId: element.Id,
        Items: null,
        DatasourceId: null,
        FeaturesConfiguration: {} as Record<Report3ElementFeatures, any>,
        AdditionalParameters: {}
      };
      if (!this.reports3.dataOneElements) {
        this.reports3.dataOneElements = [];
      }
      if (!this.reports3.dataOneElements.find((x) => x.Id === element.Id)) {
        this.reports3.dataOneElements.push(element);
      }
      this.reports3.prepareAdditionalParameters(item);
      switch (this.elementRole) {
        //case Reports3ElementRole.Body:
        default:
          this.editRecord.Items.push(item);
          break;
        case Reports3ElementRole.Header:
          this.editRecord.Header = item;
          break;
        case Reports3ElementRole.Footer:
          this.editRecord.Footer = item;
          break;
      }
      this.reports3.dataOneSelectedElement = item;
      this.displayElementsDialog = false;
    }
  }

  getElement(id: string): Reports3ElementEntity | undefined {
    if (this.reports3.dataOneElements?.length) {
      const element = this.reports3.dataOneElements.find((x) => x.Id === id);
      return element;
    }
    return undefined;
  }

  selectElement(element: Reports3ElementConfiguration): void {
    if (this.reports3.dataOneSelectedElement?.Uid !== element.Uid) {
      this.reports3.prepareAdditionalParameters(element);
      this.reports3.dataOneSelectedElement = element;
    }
  }

  deleteHeader(event: Event): void {
    event.stopPropagation();
    if (this.editRecord) {
      this.editRecord.Header = null;
    }
  }

  deleteFooter(event: Event): void {
    event.stopPropagation();
    if (this.editRecord) {
      this.editRecord.Footer = null;
    }
  }

  openSettingsDialog(): void {
    this.reports3.displaySettingsDialog = true;
    }

  openEditDialog(event: Event, element: Reports3ElementConfiguration | null): void {
    event.stopPropagation();
    if (element) {
      this.selectElement(element);
    } else {
      this.reports3.dataOneSelectedElement = null;
    }
    if (this.reports3.isCompactMode) {
      this.reports3.displayConfigurationDialog = true;
    }
  }
}

export default ReportsReportView;
</script>