<template>
<svg width="106" height="127" viewBox="0 0 106 127" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M106 0H0V126.953H106V0ZM53.4253 10.7631C53.3081 10.6417 53.1466 10.5732 52.9779 10.5732C52.8092 10.5733 52.6477 10.642 52.5306 10.7635L22.9986 41.3998L22.9961 41.4024C15.0211 49.7711 10.5713 60.889 10.5689 72.4515C10.5666 84.0138 15.0119 95.1337 22.9835 103.506C26.8535 107.575 31.5098 110.815 36.6699 113.029C41.8302 115.244 47.3864 116.385 53.0011 116.385C58.6159 116.385 64.1721 115.244 69.3323 113.029C74.4924 110.815 79.1488 107.575 83.0188 103.506C90.9903 95.1337 95.4357 84.0138 95.4335 72.4515C95.4311 60.889 90.9811 49.7711 83.0062 41.4023L53.4253 10.7631Z" fill="var(--widget-svg-color-first)"/>
<path d="M83.7992 41.0572L83.797 41.0548L54.2161 10.4156C54.0047 10.1966 53.7132 10.0729 53.4088 10.073C53.1043 10.0731 52.813 10.197 52.6017 10.4162L23.0697 41.0525L23.0686 41.0536L23.0662 41.0562L23.0652 41.0572C15.0017 49.5189 10.5024 60.7603 10.5 72.4511C10.4976 84.1418 14.9924 95.3852 23.0525 103.85C26.9692 107.969 31.6816 111.248 36.9039 113.489C42.1264 115.73 47.7496 116.885 53.4322 116.885C59.1148 116.885 64.7381 115.729 69.9606 113.489C75.1828 111.248 79.8954 107.969 83.812 103.85C91.8721 95.3852 96.3668 84.1418 96.3646 72.4511C96.3621 60.7603 91.8628 49.5189 83.7992 41.0572ZM24.6888 42.6057L53.4098 12.8107L82.1751 42.6052L82.1763 42.6064L82.1766 42.6067L82.1767 42.6068C89.8418 50.6512 94.1188 61.3377 94.1211 72.4516C94.1233 83.566 89.8498 94.2553 82.1871 102.303L82.1869 102.303C78.4798 106.202 74.0192 109.306 69.0761 111.427C64.1331 113.547 58.8107 114.641 53.4322 114.641C48.0537 114.641 42.7313 113.547 37.7883 111.427C32.8452 109.306 28.3845 106.201 24.6772 102.303L24.677 102.303C17.0143 94.2551 12.7411 83.566 12.7434 72.4516C12.7457 61.3377 17.0227 50.651 24.6879 42.6067L24.6884 42.6061L24.6888 42.6057Z" fill="var(--widget-svg-color-fifth)" stroke="var(--widget-svg-color-first)" stroke-linecap="round" stroke-linejoin="round"/>
<g filter="url(#filter0_d_582_24)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M53.409 10.573C53.5777 10.5729 53.7392 10.6415 53.8564 10.7629L83.4372 41.4021C91.4122 49.7708 95.8622 60.8888 95.8646 72.4512C95.8668 84.0136 91.4214 95.1334 83.4499 103.505C79.5799 107.575 74.9235 110.815 69.7634 113.029C64.6032 115.243 59.047 116.385 53.4322 116.385C47.8174 116.385 42.2612 115.243 37.101 113.029C31.9409 110.815 27.2846 107.575 23.4146 103.505C15.443 95.1334 10.9977 84.0136 11 72.4512C11.0024 60.8888 15.4522 49.7708 23.4272 41.4021L23.4297 41.3995L52.9617 10.7632C53.0788 10.6417 53.2403 10.5731 53.409 10.573Z" fill="var(--widget-svg-color-fifth)" fill-opacity="0.01" shape-rendering="crispEdges"/>
<path d="M82.9972 103.074L82.9969 103.075C79.1854 107.083 74.5992 110.274 69.517 112.455C64.4346 114.636 58.9623 115.76 53.4322 115.76C47.9022 115.76 42.4298 114.636 37.3475 112.455C32.2652 110.274 27.6792 107.083 23.8675 103.075L23.8672 103.074C16.0064 94.8187 11.6227 83.8532 11.625 72.4514C11.6273 61.0494 16.0154 50.0858 23.8797 41.8333L23.8809 41.8319L53.4092 11.1995L82.9848 41.8333C82.9852 41.8337 82.9855 41.8341 82.9859 41.8345C90.8494 50.0868 95.2372 61.0499 95.2396 72.4514C95.2418 83.8532 90.8581 94.8187 82.9972 103.074Z" stroke="url(#paint0_linear_582_24)" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round" shape-rendering="crispEdges"/>
</g>
<defs>
<filter id="filter0_d_582_24" x="5" y="7.573" width="96.8646" height="117.812" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feOffset dy="3"/>
<feGaussianBlur stdDeviation="3"/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix v-if="isDarkTheme" type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.161 0"/>
<feColorMatrix v-else type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_582_24"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_582_24" result="shape"/>
</filter>
<linearGradient id="paint0_linear_582_24" x1="53.4323" y1="10.573" x2="53.4323" y2="116.385" gradientUnits="userSpaceOnUse">
<stop stop-color="var(--widget-svg-color-fifth)" stop-opacity="0"/>
<stop offset="1" stop-color="var(--widget-svg-color-fifth)"/>
</linearGradient>
</defs>
</svg>

</template>

<script lang="ts">
import AuthState from "@/store/states/AuthState";
import { Component, Vue } from "vue-facing-decorator";

@Component
class DropSvg extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get isDarkTheme(): boolean {
    return !!this.authState.userSettings?.darkTheme;
  }
}

export default DropSvg;
</script>