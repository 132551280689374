<template>
  <input 
    ref="locationInput" 
    type="text" 
    v-model="address" 
    @input="valueChanged"
    :placeholder="placeholder" 
    :class="inputClass"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";

declare const google: any;

@Component({
  components: {
  },
})
class AddressAutocompleteView extends Vue {
  @Prop({ required: true }) modelValue!: string;
  @Prop({ required: true }) placeholder!: string;
  @Prop({ required: true }) inputClass!: string;

  address = "";

  autocomplete: any | null = null;

  created() {
    this.address = this.modelValue;
  }
  
  mounted(): void {
    this.autocomplete = new google.maps.places.Autocomplete(this.$refs.locationInput as HTMLInputElement, {
      types: ["address"],
      fields: ["name"]
    });

    google.maps.event.addListener(this.autocomplete, "place_changed", () => {
      const place = this.autocomplete?.getPlace();
      this.address = place.name;
      this.valueChanged();
    });
  }

  unmounted(): void {
    if(this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
  }

  valueChanged(): void {
    this.$emit('update:modelValue', this.address);
  }
}

export default AddressAutocompleteView;
</script>