<template>
  <div class="pool-stream pool">

    <div class="pool-stream-main">
      <div class="pool-stream-info">

        <div class="pool-stream-info-top">
          <div>
            <Checkbox v-model="isSelected" :binary="true" class="mr-3" />
            <h2><a :href="'/data/pools/' + pool.Id">{{ pool.Name }}</a></h2>
          </div>

          <div>
            <span>Records&nbsp;<b>{{ pool.Records ? pool.Records.toLocaleString() : '0' }}</b></span>
            <span>Streams&nbsp;<b>{{ pool.Streams ? pool.Streams.toLocaleString() : '0' }}</b></span>
          </div>
        </div>

        <ul class="pool-stream-info-bottom">
          <li class="creator"><span v-tippy="'Creator'">{{ pool.Creator }}</span></li>
          <li class="access-mode"><span v-tippy="'Access mode'">{{ accessStr(pool.AccessMode) }} access</span></li>
          <li class="virtual-pool-stream"><span v-tippy="'Virtual pool'">Virtual pool {{ pool.Virtual }}</span></li>
          <li class="last-record-date"><span v-tippy="'Last record date'">
              <DateTimezoneView :date="pool.LastUpdates" :timezone="pool.TimeZone" />
            </span></li>
          <li class="timezone"><span v-tippy="'Timezone'">
              <PoolTimezoneView :poolKey="pool.Id" :timezone="pool.TimeZone" />
            </span></li>
        </ul>
      </div>

      <div class="pool-stream-actions">
        <div>
          <span class="view-stream">
            <a class="p-button p-button-sm use-link text-sm flex" :href="'/data/pools/' + pool.Id">
              <span class="p-button-label">View Streams</span>
              <span class="p-ink"></span>
            </a>
          </span>
          <span>
            <span>
              <Button label="Copy Key" @click="copyKey(pool.Id)" class="p-button-outlined p-button-sm text-sm" />
            </span>
            <span>
              <Button label="Remove" class="p-button-outlined p-button-sm text-sm p-button-danger"
                @click="openConfirmation" />
            </span>
          </span>
          <span class="pool-stream-show-more pb-0">
            <span @click="showDetails = !showDetails" class="link-dark" :class="{ 'is-open': showDetails }">
              Show More Details <i class="pi"
                :class="{ 'pi-chevron-up': showDetails, 'pi-chevron-down': !showDetails }"></i>
            </span>
          </span>
        </div>
      </div>
    </div>

    <transition name="p-toggleable-content">
      <div v-if="showDetails" class="pool-stream-additional">
        <div class="pool-stream-additional-inner">
          <div class="pool-stream-additional-btns">
            <Button label="Rename" class="p-button-outlined p-button-sm text-sm" @click="openRenamePool(pool.Name)"
              :disabled="!canEdit()" />
            <Button label="Merge" class="p-button-outlined p-button-sm text-sm" @click="openMergePool"
              :disabled="!canEdit()" />
            <Button label="Tools" class="p-button-outlined p-button-sm text-sm" @click="openPoolTools" />
            <Button :label="pool.Hide ? 'Unarchive' : 'Archive'" class="p-button-outlined p-button-sm text-sm"
              @click="hidePool" v-if="permissions && permissions.BitpoolAdmin" />
          </div>
          <div class="pool-stream-tags mt-5">
            <h3>Tags</h3>
            <PoolTagsView :poolKey="pool.Id" :tags="pool.Tags" />
          </div>
        </div>
      </div>
    </transition>

    <Dialog header="Rename Pool" v-model:visible="displayRenamePool" :modal="true"
      :breakpoints="{ '1400px': '65vw', '1024px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }">
      <div class="dialog-content">
        <div class="field mb-0">
          <label for="">Enter new name</label>
          <div>
            <InputText class="inputfield p-inputtext p-inputtext-lg w-full" placeholder="Pool Name" type="text"
              v-model="newPoolName" />
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeRenamePool" class="p-button-text p-button-secondary" />
        <Button label="Rename" icon="pi pi-check" @click="renamePool" :disabled='!newPoolName' />
      </template>
    </Dialog>

    <Dialog header="Confirmation" v-model:visible="displayConfirmation" :modal="true"
      :breakpoints="{ '1400px': '65vw', '1024px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }">
      <div class="dialog-content">
        <div class="flex align-items-center">
          <i class="pi pi-exclamation-triangle text-4xl mr-3" style="color: var(--error-500);" />
          <span>Are you sure you want to delete "<span class="break-word">{{ poolLongName(pool) }}</span>" pool?</span>
        </div>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" @click="closeConfirmation" class="p-button-text p-button-secondary" />
        <Button label="Yes" icon="pi pi-check" @click="deletePool" />
      </template>
    </Dialog>

    <Dialog header="Merge Pools" v-model:visible="displayMergePool" :modal="true"
      :breakpoints="{ '1400px': '65vw', '1024px': '75vw', '640px': '90vw' }" :style="{ width: '50vw' }">
      <div class="dialog-content">
        <div class="field mb-0">
          <label for="">Merge <span class="break-word">{{ poolLongName(pool) }}</span> pool with</label>
          <div>
            <Dropdown v-model="mergePoolWith" :options="allPools" :optionLabel="poolLongName" placeholder="Select pool"
              :filter="true" filterPlaceholder="Find pool" class="w-full dropdown-lg" />
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeMergePool" class="p-button-text p-button-secondary" />
        <Button label="Merge" icon="pi pi-check" @click="mergePool" :disabled='!mergePoolWith' />
      </template>
    </Dialog>

    <Dialog header="Pool Tools" v-model:visible="displayPoolTools" :modal="true"
      :breakpoints="{ '1400px': '70vw', '1024px': '80vw', '640px': '90vw' }" :style="{ width: '55vw' }">
      <div class="dialog-content">
        <TabView lazy>
          <TabPanel header="Duplicated Streams">
            <PoolDuplicatedStreamsView :poolKey="pool.Id" />
          </TabPanel>
          <TabPanel header="Empty Streams">
            <PoolEmptyStreamsView :poolKey="pool.Id" :timezone="pool.TimeZone" />
          </TabPanel>
          <TabPanel header="Pool Export">
            <PoolExportView :poolKey="pool.Id" :timezone="pool.TimeZone" />
          </TabPanel>
        </TabView>
      </div>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closePoolTools" class="p-button-text p-button-secondary" />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Dropdown from 'primevue/dropdown';
import ProgressSpinner from 'primevue/progressspinner';
import InputText from 'primevue/inputtext';
import Checkbox from "primevue/checkbox";
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import PoolTagsView from "./PoolTagsView.vue";
import PoolTimezoneView from "./PoolTimezoneView.vue";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import PoolDuplicatedStreamsView from "./PoolDuplicatedStreamsView.vue";
import PoolEmptyStreamsView from "./PoolEmptyStreamsView.vue";
import PoolExportView from "./PoolExportView.vue";
import { PoolModel } from "@/models/PoolModel";
import { BitPoolUserRolesTypes } from "@/models/enums/BitPoolUserRolesTypes";
import copy from 'copy-to-clipboard';
import ToastService from "@/services/ToastService";
import { PropType } from "vue";
import { BitpoolOSPermissions } from "@/models/BitpoolOSPermissions";
import { PoolModelLite } from "@/models/PoolModelLite";

@Component({
  components: {
    Dropdown,
    ProgressSpinner,
    InputText,
    Checkbox,
    Button,
    Dialog,
    TabView,
    TabPanel,
    PoolTagsView,
    PoolTimezoneView,
    DateTimezoneView,
    PoolDuplicatedStreamsView,
    PoolEmptyStreamsView,
    PoolExportView
  },
  directives: {
  }
})
class PoolView extends Vue {
  @Prop({ required: true }) pool!: PoolModel;

  get allPools(): PoolModelLite[] {
    const pools = this.$store.state.pools.poolsList && this.$store.state.pools.poolsList.Pools ?
      this.$store.state.pools.poolsList.Pools as PoolModelLite[] :
      [];
    return pools;
  }

  get isSelected(): boolean {
    const index = this.selectedPoolKeys.findIndex((poolKey) => {
      return poolKey === this.pool.Id;
    });
    return index > -1;
  }

  set isSelected(value: boolean) {
    if (value) {
      this.$store.commit("pools/selectPool", this.pool.Id);
    } else {
      this.$store.commit("pools/unselectPool", this.pool.Id);
    }
  }

  get selectedPoolKeys(): string[] {
    return this.$store.state.pools.selectedPoolKeys;
  }

  showDetails = false;

  get permissions(): BitpoolOSPermissions | null {
    return this.$store.state.auth.permissions;
  }

  accessStr(value: BitPoolUserRolesTypes | number | string): string {
    return typeof value === "string" ? value : BitPoolUserRolesTypes[value];
  }

  canEdit(): boolean {
    return this.pool.AccessMode === BitPoolUserRolesTypes.Administrator ||
      this.pool.AccessMode === BitPoolUserRolesTypes.Owner ||
      this.pool.AccessMode === BitPoolUserRolesTypes.CoOwner;
  }

  // #region rename pool
  displayRenamePool = false;
  newPoolName = "";

  openRenamePool(name: string) {
    this.newPoolName = name;
    this.displayRenamePool = true;
  }

  closeRenamePool() {
    this.displayRenamePool = false;
  }

  renamePool() {
    if (this.newPoolName) {
      this.closeRenamePool();
      const request = {
        poolKey: this.pool.Id,
        body: this.newPoolName
      };
      this.$store.dispatch("pools/rename", request);
    }
  }
  // #endregion rename pool

  // #region delete pool
  displayConfirmation = false;

  openConfirmation() {
    this.displayConfirmation = true;
  }

  closeConfirmation() {
    this.displayConfirmation = false;
  }

  deletePool() {
    this.closeConfirmation();
    const request = {
      poolKey: this.pool.Id,
      reload: true
    };
    this.$store.dispatch("pools/delete", request);
  }
  // #endregion delete pool

  // #region merge pool
  displayMergePool = false;
  mergePoolWith: PoolModelLite | null = null;

  openMergePool() {
    this.mergePoolWith = null;
    this.displayMergePool = true;
  }

  closeMergePool() {
    this.displayMergePool = false;
  }

  mergePool() {
    if (this.mergePoolWith) {
      this.closeMergePool();
      const request = {
        poolKey: this.pool.Id,
        poolKeyTo: this.mergePoolWith.Id
      };
      this.$store.dispatch("pools/mergePools", request);
    }
  }
  // #endregion merge pool

  // #region pool tools
  displayPoolTools = false;

  openPoolTools() {
    this.displayPoolTools = true;
  }

  closePoolTools() {
    this.displayPoolTools = false;
  }
  // #endregion pool tools

  poolLongName(pool: PoolModelLite): string {
    return `${pool.Name} [${pool.Id}]`;
  }

  hidePool() {
    const request = {
      poolKey: this.pool.Id,
      body: !this.pool.Hide
    };
    this.$store.dispatch("pools/hidePool", request);
  }

  copyKey(key: string) {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }
}

export default PoolView;
</script>