import DateHelper from "@/helpers/DateHelper";
import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { UserLastLoginEntity } from "@/models/user/UserLastLoginEntity";
import { AUTH_CreateUser } from "@/models/user/AUTH_CreateUser";
import { UserRenameModel } from "@/models/user/UserRenameModel";
import { AUTH_Login } from "@/models/user/AUTH_Login";
import { UserChangeRoleModel } from "@/models/user/UserChangeRoleModel";
import { UserPermissions } from "@/models/user/UserPermissions";
import { defineStore } from 'pinia';
import AdminUsersState from "./states/AdminUsersState";
import { AddUsersToOrganisation } from "@/models/user/AddUsersToOrganisation";
import { OrganisationRoleString } from "@/models/user/OrganisationRoleString";
import { UserInOrganisationRegResultDto } from "@/models/user/UserInOrganisationRegResultDto";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { UserInOrganisationDto } from "@/models/user/UserInOrganisationDto";

export const useAdminUsersStore = defineStore('adminUsers', {
  state: (): AdminUsersState => ({
    isLoaded: false,
    data: [],

    createInProgress: false,
    createError: false,

    inProgressDelete: false,

    inProgressResetPassword: false,
    errorResetPassword: false,

    inProgressRename: false,
    errorRename: false,

    inProgressChangeRole: false,
    errorChangeRole: false,

    inProgressChangePermissions: false,
    errorChangePermissions: false,

    inProgressAddRemoveOrganisations: false,
    errorAddRemoveOrganisations: false
  }),
  actions: {
    async loadData() {
      try {
        this.isLoaded = false;
        const url = `rest/BitPool_V1/users?includeOrganisations=true&includeUserPermissions=true`;
        const response = await axios.get<UserLastLoginEntity[]>(url);
        if (response.data && response.data.length) {
          response.data.forEach((user) => {
            if (user.LastLogin) {
              user.LastLogin = DateHelper.parseFromMicrosoftString(user.LastLogin as string);
            }
            if (user.RegistrationDate) {
              user.RegistrationDate = DateHelper.parseFromMicrosoftString(user.RegistrationDate as string);
            }
            if (!user.Organisations) {
              user.Organisations = [];
            }
          });
        }
        this.data = response.data;
        this.isLoaded = true;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load users",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data = [];
        this.isLoaded = true;
      }
    },
    async createUser(request: AUTH_CreateUser) {
      try {
        this.createInProgress = true;
        this.createError = false;
        const url = `/rest/Auth_V3/CreateUser`;
        await axios.post(url, request, {
          headers: {
            "Content-Type": "application/json",
          },
        });
        await this.loadData();
        ToastService.showToast("success", "", "User registered", 5000);
        this.createInProgress = false;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't register user",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.createInProgress = false;
        this.createError = true;
      }
    },
    async delete(username: string) {
      try {
        this.inProgressDelete = true;
        const url = `rest/BitPool_V1/users/${username}`;
        await axios.delete(url);
        for (let i = this.data.length - 1; i >= 0; --i) {
          if (this.data[i].Username === username) {
            this.data.splice(i, 1);
            break;
          }
        }
        this.inProgressDelete = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete user",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressDelete = false;
      }
    },
    async rename(body: UserRenameModel) {
      try {
        this.inProgressRename = true;
        this.errorRename = false;
        const url = `rest/BitPool_V1/users/rename`;
        await axios.post(url, body);
        for (let i = this.data.length - 1; i >= 0; --i) {
          if (this.data[i].Username === body.Username) {
            this.data[i].Username = body.NewUsername;
            break;
          }
        }
        this.inProgressRename = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't change username",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressRename = false;
        this.errorRename = true;
      }
    },
    async resetPassword(body: AUTH_Login) {
      try {
        this.inProgressResetPassword = true;
        this.errorResetPassword = false;
        const url = `rest/BitPool_V1/users/password/reset`;
        await axios.post(url, body);
        this.inProgressResetPassword = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't change password",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressResetPassword = false;
        this.errorResetPassword = true;
      }
    },
    async changeRole(body: UserChangeRoleModel) {
      try {
        this.inProgressChangeRole = true;
        this.errorChangeRole = false;
        const url = `rest/BitPool_V1/users/role/change`;
        await axios.post(url, body);
        for (let i = this.data.length - 1; i >= 0; --i) {
          if (this.data[i].Username === body.Username) {
            this.data[i].Role = body.NewRole;
            break;
          }
        }
        this.inProgressChangeRole = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't change role",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressChangeRole = false;
        this.errorChangeRole = true;
      }
    },
    async changePermissions(body: UserPermissions) {
      try {
        this.inProgressChangePermissions = true;
        this.errorChangePermissions = false;
        const url = `rest/BitPool_V1/users/permissions/change`;
        const response = await axios.post<string>(url, body);
        body.Id = response.data;
        for (let i = this.data.length - 1; i >= 0; --i) {
          if (this.data[i].UserId === body.UserId) {
            this.data[i].UserPermissions = body;
            break;
          }
        }
        this.inProgressChangePermissions = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't change permissions",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressChangePermissions = false;
        this.errorChangePermissions = true;
      }
    },
    async addRemoveToOrganisations(userId: string, addOrganisations: OrganisationFullDto[], removeOrganisations: OrganisationFullDto[]) {
      try {
        this.inProgressAddRemoveOrganisations = true;
        this.errorAddRemoveOrganisations = false;
        const url = `rest/BitPool_V2/organisations/users`;
        const user = this.data.find(x => x.UserId == userId);
        if (user) {
          // add
          if (addOrganisations.length > 0) {
            const addBody: AddUsersToOrganisation = {
              Message: "",
              Users: addOrganisations.map(x => { 
                return {
                  OrganisationId: x.Id,
                  Type: OrganisationRoleString.User,
                  Id: userId,
                  UserName: user.Username,
                  Groups: null
                };
              }),
            };
            const response = await axios.put<UserInOrganisationRegResultDto[]>(url, addBody);
            if (user.Organisations) {
              for (const organisation of addOrganisations) {
                user.Organisations.push(organisation.Name);
              }
            }
          }
          // remove
          if (removeOrganisations.length > 0) {
            const removeBody: UserInOrganisationDto[] = removeOrganisations.map(x => { 
              return {
                OrganisationId: x.Id,
                Type: OrganisationRoleString.User,
                Id: userId,
                UserName: user.Username
              }
            });
            await axios.delete(url, {
              headers: {
                "Content-Type": "application/json",
              },
              data: removeBody,
            });
            if (user.Organisations) {
              for (const organisation of removeOrganisations) {
                const index = user.Organisations.findIndex(x => x == organisation.Name);
                if (index > -1) {
                  user.Organisations.splice(index, 1);
                }
              }
            }
          }
        } else {
          throw new Error("User not found");
        }
        this.inProgressAddRemoveOrganisations = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't change organisations",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressAddRemoveOrganisations = false;
        this.errorAddRemoveOrganisations = true;
      }
    },
  },
});

export default useAdminUsersStore;
