<template>
  <div ng-non-bindable>
    <div v-if="loginSuccess" style="margin: 0 auto; position: absolute; z-index: 10000; width: 100%; height: 100%; left: 0; top: 0; background-color: white; padding: 3rem; font-size: 30px;">
      <span style="color: #2E4765;">{{ t('login.welcome') }}</span>
    </div>

    <div class="auth" :class="(displaySection === 'login' || displaySection === 'no-organisation') ? 'auth-login' : ''" v-else>
      <div v-if="(displaySection === 'login' || displaySection === 'no-organisation') && recordsCount" class="data-count animate__animated animate__bounceInDown">
        <div class="data-count-inner">
          <h3>{{ t('login.dataRecordsStored') }}</h3>
          <div class="data-count-items">
            <span
              v-for="(item, i) in recordsCount.split('')"
              :key="i"
              :class="`${item === ',' ? 'data-count-comma' : 'data-count-digit'}`"
            >
              {{item}}
            </span>
          </div>
        </div>
      </div>

      <LoginView
        v-if="displaySection === 'login'"
        :angular-options="angularOptions"
      ></LoginView>
      <ResetPasswordView
        v-else-if="displaySection === 'forgot'"
        :angular-options="angularOptions"
      ></ResetPasswordView>
      <SignupView
        v-else-if="displaySection === 'sign'"
        :angular-options="angularOptions"
      ></SignupView>
      <NoOrganisationView
        v-else-if="displaySection === 'no-organisation'"
        :angular-options="angularOptions"
      ></NoOrganisationView>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import AngularOptions from "@/models/AngularOptions";
import LoginView from "@/components/views/auth/LoginView.vue";
import ResetPasswordView from "@/components/views/auth/ResetPasswordView.vue";
import SignupView from "@/components/views/auth/SignupView.vue";
import NoOrganisationView from "@/components/views/auth/NoOrganisationView.vue";
import NumberHelper from "@/helpers/NumberHelper";
import { CollectedDataInfo } from "@/models/CollectedDataInfo";
import moment from "moment";
import { usePageStore } from "@/stores/page";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager'

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    LoginView,
    ResetPasswordView,
    SignupView,
    NoOrganisationView
  },
})
class LoginPage extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  now: Date = new Date();

  get recordsCount(): string {
    if (this.$store.state.login.collectedDataInfo) {
      const collectedDataInfo: CollectedDataInfo = this.$store.state.login.collectedDataInfo;
      const minutesSinceLastSnapshot = moment.duration(moment(this.now).diff(moment(collectedDataInfo.Date))).asMinutes();
      const value = collectedDataInfo.Count + collectedDataInfo.RecordsPerMinute * minutesSinceLastSnapshot;
      return value ? NumberHelper.intToString(value) : "";
    } else {
      return "";
    }
  }

  get displaySection(): string {
    return this.$store.state.login.displaySection;
  }

  set displaySection(value: string) {
    this.$store.commit("login/setDisplaySection", value);
  }

  get loginSuccess(): boolean {
    return this.$store.state.login.loginSuccess;
  }

  set loginSuccess(value: boolean) {
    this.$store.commit("login/setLoginSuccess", value);
  }

  dateInterval = 0;

  pageStore = usePageStore();
  
  created(): void {
    this.pageStore.$reset();
    this.updateState();
    const signupParam = this.getUrlParameter("signup", false);
    if (signupParam) {
      this.$store.commit("login/setUsername", signupParam);
      this.displaySection = "sign";
    } else {
      if (this.displaySection !== "no-organisation") {
        this.displaySection = "login";
      }
    }
    // uncomment to test no-organisation view
    // this.displaySection = "no-organisation";
    
    this.loginSuccess = false;
    this.$store.commit("login/setPassword", "");
    this.$store.commit("login/setSignupSuccess", false);
    this.$store.dispatch("login/loadCollectedDataInfo").then(() => {
      this.dateInterval = window.setInterval(() => {
        this.now = new Date();
      }, 1000)
    });
  }

  unmounted(): void {
    if (this.dateInterval) {
      clearInterval(this.dateInterval);
      this.dateInterval = 0;
    }
  }

  oldPath = "";
  oldSearch = "";
  urlSearchParams: URLSearchParams | null = null;

  getUrlParameter(name: string, toLower: boolean): string | null | undefined {
    if (!this.urlSearchParams) {
      this.urlSearchParams = new URLSearchParams(window.location.search);
    }
    let value = this.urlSearchParams?.get(name);
    if (value && toLower) {
      value = value.toLowerCase();
    }
    return value;
  }

  updateState(): void {
    this.urlSearchParams = new URLSearchParams(window.location.search);
    const path = window.location.pathname;
    const search = window.location.search;
    if (this.oldPath !== path || this.oldSearch !== search) {
      this.oldPath = path;
      this.oldSearch = search;
    }
  }
}

export default LoginPage;
</script>@/localeManager