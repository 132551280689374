<template>
  <div>
    <div class="mb-3">
      <Button label="Reload" class="p-button-outlined p-button-sm text-sm mr-2 mb-2" icon="pi pi-refresh" @click="loadEmpty" :disabled="inProgressDeleteEmpty"/>
      <Button label="Delete Empty Streams" class="p-button-outlined p-button-sm text-sm mr-2 mb-2" @click="deleteEmpty" :disabled="inProgressDeleteEmpty"/>
    </div>

    <div v-if="inProgressDeleteEmpty" class="mb-4">
      <ProgressBar mode="indeterminate"/>
    </div>

    <DataTable :value="streamsEmpty" responsiveLayout="stack" breakpoint="850px">
      <template #empty>
        <div v-if="isLoadedEmpty" class="w-full" style="min-height: 30vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 30vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Name" header="Name"></Column>
      <Column field="RegistrationDate" header="Registration Date">
        <template #body="slotProps">
          <DateTimezoneView :date="slotProps.data.RegistrationDate" :timezone="timezone"/>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import { BitStreamEntity } from "@/models/BitStreamEntity";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";

@Component({
  components: {
    Button,
    Checkbox,
    DataTable,
    Column,
    ProgressBar,
    ProgressSpinner,
    DateTimezoneView
  }
})
class PoolEmptyStreamsView extends Vue {
  @Prop({ required: true }) poolKey!: string;
  @Prop({ required: true }) timezone!: string;

  get isLoadedEmpty(): boolean {
    return this.$store.state.poolTools.isLoadedEmpty;
  }
  get streamsEmpty(): BitStreamEntity[] {
    return this.$store.state.poolTools.streamsEmpty;
  }
  get inProgressDeleteEmpty(): boolean {
    return this.$store.state.poolTools.inProgressDeleteEmpty;
  }

  created(): void {
    this.loadEmpty();
  }

  loadEmpty(): void {
    this.$store.dispatch("poolTools/loadEmpty", this.poolKey);
  }

  deleteEmpty(): void {
    if (this.streamsEmpty.length > 0) {
      this.$store.dispatch("poolTools/deleteEmpty", this.poolKey);
    }
  }
}

export default PoolEmptyStreamsView;
</script>