<template>
  <ul>
    <li>
      <i>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 14" fill="none"><path stroke-linecap="round" stroke-linejoin="round" d="M5.088 3.435h3.565M5.088 6.287h3.565M5.088 9.139h3.565m2.495 4.278V2.865c0-.799 0-1.198-.155-1.503a1.427 1.427 0 0 0-.623-.623C10.065.583 9.665.583 8.867.583H4.874c-.798 0-1.198 0-1.503.156a1.426 1.426 0 0 0-.623.623c-.155.305-.155.704-.155 1.503v10.552m9.981 0H1.167"/></svg>
      </i>
      <span>
        <b>{{ formatValue(summarySites) }}</b> 
        <span>Total Buildings</span>
      </span>
    </li>

    <li>
      <i>
        <svg xmlns="http://www.w3.org/2000/svg" class="fill-color" viewBox="0 -960 640 712.3"><path d="M300-295.31v-297.08L40-742.93v283.23q0 6.16 3.08 11.54 3.07 5.39 9.23 9.23zm40 0 247.69-143.62q6.16-3.84 9.23-9.23 3.08-5.38 3.08-11.54v-283.23L340-592.39Zm-20-331.46 257-148.54-244.69-141.62q-6.16-3.84-12.31-3.84t-12.31 3.84L63-775.31ZM32.31-403.54q-15.16-8.69-23.73-23.62Q0-442.08 0-459.47v-288.76q0-17.39 8.58-32.31 8.57-14.93 23.73-23.62l255.38-147.15Q302.85-960 320-960q17.15 0 32.31 8.69l255.38 147.15q15.16 8.69 23.73 23.62 8.58 14.92 8.58 32.31v288.76q0 17.39-8.58 32.31-8.57 14.93-23.73 23.62L352.31-256.39q-15.16 8.69-32.31 8.69-17.15 0-32.31-8.69zM320-603.85Z"/></svg>
      </i>
      <span>
        <b>{{ formatValue(summarySpaces) }}</b> 
        <span>Total Spaces</span>
      </span>
    </li>
    
    <li>
      <i>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" fill="none"><path stroke-linecap="round" stroke-linejoin="round" d="m3.8 4.107 3.15 3.15M3.8 4.107H1.7l-.7-2.1.7-.7 2.1.7v2.1Zm9.281-2.282-1.839 1.84c-.277.277-.416.416-.468.575a.7.7 0 0 0 0 .433c.052.16.19.299.468.576l.166.166c.277.277.416.416.576.468a.7.7 0 0 0 .432 0c.16-.052.299-.19.576-.468l1.72-1.72a3.85 3.85 0 0 1-4.312 5.24c-.34-.067-.51-.1-.613-.09a.585.585 0 0 0-.26.079c-.092.049-.184.14-.367.324l-5.01 5.01a1.485 1.485 0 1 1-2.1-2.1l5.01-5.01c.183-.184.275-.276.324-.367a.586.586 0 0 0 .08-.261c.01-.103-.024-.273-.091-.614a3.85 3.85 0 0 1 5.708-4.081ZM8 10.408l3.85 3.85a1.485 1.485 0 1 0 2.1-2.1L10.783 8.99a3.837 3.837 0 0 1-.654-.12c-.273-.074-.572-.02-.771.18L8 10.408Z"/></svg>
      </i>
      <span>
        <b>{{ formatValue(summaryEquips) }}</b> 
        <span>Total Equipment</span>
      </span>
    </li>
    
    <li>
      <i>
        <svg xmlns="http://www.w3.org/2000/svg" class="fill-color large-size" viewBox="0 -960 760.028 600"><path d="M324.15-526.15q21.31 21.3 54.89 20.23 33.57-1.08 49.27-24.23l217.07-314.46-315.23 216.3q-23.53 15.7-25.42 48.27-1.88 32.58 19.42 53.89zM380-960q57.08 0 106.96 14.96 49.89 14.96 96.35 45.27l-56.39 36.46q-34.15-18.15-70.8-27.42Q419.46-900 380-900q-133 0-226.5 93.5T60-580q0 42 11.5 83t32.5 77h552q23-38 33.5-79t10.5-85q0-36-8.88-72.88-8.89-36.89-27.43-70.04l36.46-56.39q29.62 48.54 44.43 97.12 14.8 48.58 15.42 100.96.61 54.31-12.23 102.65-12.85 48.35-38.69 94.58-7.93 13-22.31 20.5Q672.38-360 656-360H104q-16 0-30.19-7.69-14.19-7.7-22.89-21.85Q28-429.54 14-476.77 0-524 0-580q0-78.38 29.74-147.37 29.74-68.99 81.19-120.85 51.46-51.86 120.99-81.82Q301.46-960 380-960Zm4.31 295.69z"/></svg>
      </i>
      <span>
        <b>{{ formatValue(summaryMeters) }}</b> 
        <span>Total Meters</span>
      </span>
    </li>

    <li>
      <i>
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 16" fill="none"><path stroke-linecap="round" stroke-linejoin="round" d="M10.565 13.892h-.143c-1.198 0-1.797 0-2.254-.23a2.12 2.12 0 0 1-.935-.917C7 12.295 7 11.708 7 10.532v-4.48c0-1.176 0-1.764.233-2.214a2.12 2.12 0 0 1 .935-.917c.457-.23 1.056-.23 2.254-.23h.143m0 11.2c0 .774.638 1.4 1.426 1.4.787 0 1.426-.626 1.426-1.4 0-.773-.639-1.4-1.426-1.4-.788 0-1.426.627-1.426 1.4Zm0-11.2c0 .774.638 1.4 1.426 1.4.787 0 1.426-.626 1.426-1.4 0-.772-.639-1.4-1.426-1.4-.788 0-1.426.628-1.426 1.4Zm-7.13 5.6h7.13m-7.13 0c0 .774-.638 1.4-1.426 1.4-.787 0-1.426-.626-1.426-1.4 0-.772.639-1.4 1.426-1.4.788 0 1.426.628 1.426 1.4Zm7.13 0c0 .774.638 1.4 1.426 1.4.787 0 1.426-.626 1.426-1.4 0-.772-.639-1.4-1.426-1.4-.788 0-1.426.628-1.426 1.4Z"/></svg>
      </i>
      <span>
        <b>{{ formatValue(summaryPoints) }}</b> 
        <span>Total Streams</span>
      </span>
    </li>

    <li>
      <i>
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" d="m8.426 5.574 4.99-4.99m0 0H9.14m4.278 0V4.86M5.574 8.426l-4.99 4.99m0 0H4.86m-4.278 0V9.14"/></svg>
      </i>
      <span>
        <b>{{ formatValue(summaryArea) }} <b>m<span class="vertical-align-super">2</span></b></b> 
        <span>Total Area</span>
      </span>
    </li>
  </ul>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import numbro from "numbro";

@Component({
  components: {
  
  },
})
class TagManagerSummaryView extends Vue {
  @Prop({ required: true }) summarySites!: number;
  @Prop({ required: true }) summarySpaces!: number;
  @Prop({ required: true }) summaryEquips!: number;
  @Prop({ required: true }) summaryMeters!: number;
  @Prop({ required: true }) summaryPoints!: number;
  @Prop({ required: true }) summaryArea!: number;

  formatValue(value: number) {
    return numbro(value).format({ thousandSeparated: true });
  }
}

export default TagManagerSummaryView;
</script>