import { PoolsListModelLite } from "@/models/PoolsListModelLite";
import { PoolsListModelPage } from "@/models/PoolsListModelPage";
import PoolsSearchPageQuery from "@/models/PoolsSearchPageQuery";

export default class PoolsState {
  poolsList: PoolsListModelLite | null = null;
  isLoadedList = false;
  guidList = "";

  poolsPage: PoolsListModelPage | null = null;
  isLoadedPage = false;
  guidPage = "";
  pageQuery: PoolsSearchPageQuery | null = null;

  selectedPoolKeys: string[] = [];
}
