<template>
  <div>
    <DataTable
      v-if="haystackDefsStore.isLoaded"
      :value="tags" 
      dataKey="Id"
      filterDisplay="menu"
      v-model:filters="filters"
      :globalFilterFields="globalFilterFields"
      removableSort
      :paginator="true"
      :rows="20"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
      :rowsPerPageOptions="[10, 20, 50]"
      currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
      showGridlines 
      responsiveLayout="stack" 
      breakpoint="850px" 
      class="p-datatable-sm default-visual-table responsive-breakpoint semantics-config-tags-table">
      <template #header>
        <div class="table-header">
          <div class="sm:flex sm:align-items-center sm:justify-content-between sm:gap-3">
            <div class="sm:flex-shrink-0">
              <Button 
                label="Create Tag" 
                icon="pi pi-plus-circle" 
                class="my-1" 
                @click="openEditDialog(null)" 
                :disabled="!(canEditGlobal || canEditOrganisation)"
              />
          </div>
            <div class="mt-3 sm:mt-0">
              <IconField iconPosition="left" class="w-full sm:w-auto">
                <InputIcon class="pi pi-search" />
                <InputText v-model="filters['global'].value" placeholder="Find Tag" class="w-full sm:w-auto" />
              </IconField>
            </div>
          </div>
        </div>
      </template>
      <template #empty>
        <div v-if="haystackDefsStore.isLoaded" class="w-full" style="min-height: 50vh;">
          <span class="inline-block py-2">No data found.</span>
        </div>
        <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
          <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
        </div>
      </template>
      <Column field="Def" header="Def" sortable headerStyle="width: 17%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <span class="block with-inline-btn">
            {{ slotProps.data.Def }}
            <span v-if="slotProps.data.OrganisationId === 0" v-tippy="'Public'" class="table-cell-icon">
              <i class="pi pi-globe"></i>
            </span>
          </span>
        </template>
        <template #filter="{ filterModel }">
          <InputText 
            v-model="filterModel.value" 
            type="text" 
            class="p-column-filter" 
            placeholder="Filter" 
          />
        </template>
      </Column>
      <Column field="Is" header="Is" sortable headerStyle="width: 15%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <span>{{ slotProps.data.Is.join(", ") }}</span>
        </template>
        <template #filter="{ filterModel }">
          <InputText 
            v-model="filterModel.value" 
            type="text" 
            class="p-column-filter" 
            placeholder="Filter" 
          />
        </template>
      </Column>
      <Column field="TagOn" header="TagOn" sortable headerStyle="width: 16%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <span>{{ slotProps.data.TagOn.join(", ") }}</span>
        </template>
        <template #filter="{ filterModel }">
          <InputText 
            v-model="filterModel.value" 
            type="text" 
            class="p-column-filter" 
            placeholder="Filter" 
          />
        </template>
      </Column>
      <Column field="Lib" header="Lib" sortable headerStyle="width: 12%; min-width: min-content;" headerClass="no-break-word" bodyClass="no-break-word">
        <template #body="slotProps">
          <span>
            {{ slotProps.data.Lib }}
          </span>
        </template>
        <template #filter="{ filterModel }">
          <Dropdown
            v-model="filterModel.value"
            :options="libs"
            optionValue="Def"
            optionLabel="Def"
            class="p-column-filter"
            placeholder="Filter"
          />
        </template>
      </Column>
      <Column field="Trio" header="Doc" headerStyle="width: 30%; min-width: min-content;" headerClass="no-break-word">
        <template #body="slotProps">
          <div>
            <span class="semantics-config-doc" v-html="docToHtml(getCell(slotProps.data, 'doc'))"></span>
            <a 
              v-if="getCell(slotProps.data, 'wikipedia')" 
              :href="getCell(slotProps.data, 'wikipedia')" 
              target="_blank"
            >
              Wikipedia
            </a>
          </div>
        </template>
      </Column>
      <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
        <template #body="slotProps">
          <div class="inline-flex">
            <Button 
              v-tippy="'Edit'"
              icon="pi pi-pencil" 
              class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
              @click="openEditDialog(slotProps.data)" 
              :disabled="!(canEditGlobal || slotProps.data.OrganisationId > 0 && canEditOrganisation)"
            />
            <Button 
              v-tippy="'Delete'"
              icon="pi pi-trash" 
              class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
              @click="openDeleteConfirmation(slotProps.data)" 
              :disabled="!(canEditGlobal || slotProps.data.OrganisationId > 0 && canEditOrganisation)"
            />
          </div>
        </template>
      </Column>
    </DataTable>
    <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
      <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
    </div>
    
    <Dialog header="Tag" v-model:visible="displayEditDialog" class="semantics-config-dialog" :modal="true" :breakpoints="{'1599.98px': '56rem','991.98px': '90%'}" :style="{width: '62rem'}">
      <div class="dialog-content" v-if="selectedEntity">
        <BlockUI :blocked="haystackDefsStore.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="haystackDefsStore.updateInProgress ? 'blockui-blocked' : ''">
          <div class="semantics-settings-container" v-if="editorValue === 'Basic'">
            <div class="formgrid grid" v-if="keys.length">
              <HaystackDictEditView
                inputId="root"
                v-model="selectedEntityHaysonDict" 
                field="root" 
                :def="selectedEntity.Def"
                idPrefix="tag"
                :excludeFields="excludeFields"
                :mandatoryFields="mandatoryFields"
                :isLib="false"
                :isFirstLevel="true"
              />
            </div>
          </div>
          <div class="semantics-settings-container" v-else>
            <div class="field mt-0 mb-0 semantics-expert-field">
              <label>Trio</label>
              <div>
                <Textarea 
                  id="tag-edit-trio"
                  rows="3"
                  class="inputfield w-full"
                  v-model="selectedEntity.Trio"
                  :autoResize="true"
                />
              </div>
            </div>
          </div>
          <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
        </BlockUI>
      </div>
      <template #header>
        <div class="flex flex-wrap sm:flex-nowrap sm:flex-auto sm:justify-content-between sm:align-items-center">
          <span class="p-dialog-title w-full sm:w-auto mb-3 sm:mb-0">Tag</span>
          <SelectButton 
            v-model="editorValue" 
            :options="editorOptions"
            class="w-10rem"
            :allowEmpty="false"
          />
        </div>
      </template>
      <template #footer>
        <Button label="Close" icon="pi pi-times" @click="closeEditDialog" class="p-button-text p-button-secondary"/>
        <Button label="Save" :icon="haystackDefsStore.updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" @click="saveChanges" :disabled='haystackDefsStore.updateInProgress' />
      </template>
    </Dialog>
  </div>
</template>

<script lang="ts">
import BlockUI from 'primevue/blockui';
import Dialog from 'primevue/dialog';
import Button from "primevue/button";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ProgressSpinner from "primevue/progressspinner";
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import Textarea from 'primevue/textarea';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { Component, Vue, Watch } from "vue-facing-decorator";
import { useHaystackDefsStore } from "@/stores/haystackDefs";
import HaystackDefsService from "@/services/HaystackDefsService";
import { HaystackDefsEntity } from "@/models/nav-tree/HaystackDefsEntity";
import ConfirmationService from "@/services/ConfirmationService";
import moment from "moment";
import { AllUserData } from "@/models/user/AllUserData";
import { HDict, HaysonDict, HaysonKindVal, HaysonVal } from 'haystack-core';
import AuthState from '@/store/states/AuthState';
import ToastService from '@/services/ToastService';
import HaystackDictEditView from "@/components/views/tags/haystack-edit/HaystackDictEditView.vue";
import { useOrganisationStore } from '@/stores/organisation';

@Component({
  components: {
    BlockUI,
    Dialog,
    Button,
    DataTable,
    Column,
    ProgressSpinner,
    InputText,
    InputSwitch,
    Dropdown,
    SelectButton,
    Textarea,
    IconField,
    InputIcon,
    HaystackDictEditView
  },
})
class TagManagerConfigTagsView extends Vue {
  haystackDefsStore = useHaystackDefsStore();

  get auth(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get canEditGlobal(): boolean {
    return !!this.auth.permissions?.BitpoolAdmin;
  }

  get canEditOrganisation(): boolean {
    return !!this.auth.permissions?.FullAccess;
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  editorValue = "Basic";
  editorOptions = ["Basic", "Expert"];
  @Watch('editorValue', { immediate: false, deep: false })
  onEditorValueChanged(val: string, oldVal: string): void {
    if (val === "Basic") {
      this.selectedEntityToTuples();
    } else {
      this.tuplesToSelectedEntity();
    }
  }

  getCell(defEntity: HaystackDefsEntity, column: string): string {
    const grid = HaystackDefsService.trioStringToHGrid(defEntity.Trio);
    const rows = grid.getRows();
    if (rows.length) {
      const docCell = rows[0].get(column);
      if (docCell) {
        let result = docCell.toString();
        if (result.startsWith("[")) {
          result = result.replaceAll("[", "").replaceAll("]", "").trim()
        }
        return result;
      }
    }
    return "";
  }

  docToHtml(doc: string): string {
    return HaystackDefsService.docToHtml(doc);
  }

  get libs(): HaystackDefsEntity[] {
    const defs = HaystackDefsService.getDefs();
    if (defs && this.haystackDefsStore.data?.length) {
      const libs = HaystackDefsService.hDictArrayToStringArray(defs.libs);
      const entities: HaystackDefsEntity[] = [];
      this.haystackDefsStore.data.forEach(defEntity => {
        if (libs.includes(defEntity.Def)) {
          entities.push(defEntity);
        }
      });
      return entities;
    }
    return [];
  }

  get tags(): HaystackDefsEntity[] {
    const defs = HaystackDefsService.getDefs();
    if (defs && this.haystackDefsStore.data?.length) {
      const entities: HaystackDefsEntity[] = [];
      this.haystackDefsStore.data.forEach(defEntity => {
        if (!defEntity.Is.includes("lib")) {
          entities.push(defEntity);
        }
      });
      return entities;
    }
    return [];
  }

  globalFilterFields = ["Def", "Is", "TagOn", "Lib"];

  filters = {
    global: { value: null, matchMode: "contains" },
    Def: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
    Is: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
    TagOn: { operator: "and", constraints: [{ value: null, matchMode: "contains" }] },
    Lib: { operator: "and", constraints: [{ value: null, matchMode: "equals" }] },
  }

  // #region Edit
  displayEditDialog = false;
  selectedEntity: HaystackDefsEntity | null = null;
  selectedEntityHaysonDict: HaysonDict = {};

  get keys(): string[] {
    const result: string[] = [];
    const haysonDict = this.selectedEntityHaysonDict;
    for (const key in haysonDict) {
      result.push(key);
    }
    return result;
  }

  excludeFields: string[] = [];
  mandatoryFields = ["def", "doc", "lib"];

  get selectedEntityIsGlobal(): boolean {
    return this.selectedEntity?.OrganisationId === 0;
  }

  set selectedEntityIsGlobal(value: boolean) {
    if (this.selectedEntity) {
      if (value) {
        this.selectedEntity.OrganisationId = 0;
      } else {
        this.selectedEntity.OrganisationId = this.organisationStore.currentOrganisation?.Id ?? -1;
      }
    }
  }

  openEditDialog(defEntity: HaystackDefsEntity | null): void {
    if (defEntity) {
      this.selectedEntity = JSON.parse(JSON.stringify(defEntity));
    } else {
      if (this.libs.length < 1) {
        ToastService.showToast("warn", "", "No haystack libraries found", 5000);
      } else {
        const nowUtc = moment.utc().toDate();
        const trio = `def:^myTag
doc:myTag description
is:[]
tagOn:[]
lib:^${this.libs[this.libs.length - 1].Def}`;
        this.selectedEntity = {
          Id: "",
          Def: "",
          ContainedBy: "",
          TagOn: [],
          Is: [],
          Lib: "",
          Trio: trio,
          OrganisationId: this.organisationStore.currentOrganisation?.Id ?? -1,
          Created: nowUtc,
          Updated: nowUtc,
          CreatedBy: this.allUserData.userName,
          UpdatedBy: this.allUserData.userName
        };
      }
    }
    this.selectedEntityToTuples();
    this.displayEditDialog = true;
  }

  selectedEntityToTuples(): void {
    if (this.selectedEntity) {
      const grid = HaystackDefsService.trioStringToHGrid(this.selectedEntity.Trio);
      const rows = grid.getRows();
      if (rows.length) {
        const row = rows[0];
        const rowHayson = row.toJSON();
        this.selectedEntityHaysonDict = { root: rowHayson };
      } else {
        this.selectedEntityHaysonDict = {};
      }
    }
  }

  closeEditDialog(): void {
    this.displayEditDialog = false;
    this.selectedEntity = null;
  }

  async saveChanges(): Promise<void> {
    if (this.selectedEntity) {
      if (this.editorValue !== "Basic") {
        this.selectedEntityToTuples();
      }
      this.selectedEntity.UpdatedBy = this.allUserData.userName;
      const nowUtc = moment.utc().toDate();
      this.selectedEntity.Updated = nowUtc;
      const values: Record<string, HaysonVal> = {};
      let libEntity: HaystackDefsEntity | undefined = undefined;
      let def = "";
      const haysonDict: HaysonDict = this.selectedEntityHaysonDict["root"] ? this.selectedEntityHaysonDict["root"] as HaysonDict : {};
      for (const key in haysonDict) {
        const value = haysonDict[key];
        values[key] = value;

        // validation
        if (value) {
          if (key === "lib") {
            const lib = typeof value === "string" ? value : (value as HaysonKindVal).val;
            libEntity = this.libs.find(x => x.Lib === lib);
          } else if (key === "def") {
            def = typeof value === "string" ? value : (value as HaysonKindVal).val;
          }
        }
      }
      if (!libEntity) {
        ToastService.showToast("error", "", "Please select Lib", 5000);
      } else if (!def || def === "^") {
        ToastService.showToast("error", "", "Please enter Def", 5000);
      } else {
        this.selectedEntity.OrganisationId = libEntity.OrganisationId;
        const hDict = new HDict(values);
        this.selectedEntity.Trio = HaystackDefsService.hGridToTrioString(hDict.toGrid());

        await this.haystackDefsStore.createUpdate(this.selectedEntity);
        if (!this.haystackDefsStore.updateError) {
          this.closeEditDialog()
        }
      }
    }
  }

  tuplesToSelectedEntity(): void {
    if (this.selectedEntity) {
      const values: Record<string, HaysonVal> = {};
      const haysonDict: HaysonDict = this.selectedEntityHaysonDict["root"] ? this.selectedEntityHaysonDict["root"] as HaysonDict : {};
      for (const key in haysonDict) {
        const value = haysonDict[key];
        values[key] = value;
      }
      const hDict = new HDict(values);
      let result = HaystackDefsService.hGridToTrioString(hDict.toGrid());
      // if last line equal to "---" then remove line
      if (result.endsWith("---")) {
        result = result.substring(0, result.length - 3).trim();
      }
      this.selectedEntity.Trio = result;
    }
  }
  // #endregion Edit

  // #region Delete
  openDeleteConfirmation(defEntity: HaystackDefsEntity): void {
    const message = `Are you sure you want to delete Tag?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Tag',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        if (defEntity) {
          this.haystackDefsStore.delete(defEntity);
        }
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }
  // #endregion Delete
}

export default TagManagerConfigTagsView;
</script>