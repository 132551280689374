<template>
  <span class="with-inline-btn">
    <span>{{timezoneDisplayname}}</span>
    <Button @click="openEditor()" label="Change" icon="pi pi-pencil" class="p-button-icon-only p-button-rounded p-button-text" />

    <Dialog header="Select Timezone" v-model:visible="displayEditor" :modal="true" :breakpoints="{'1400px': '65vw', '1024px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}">
      <div class="dialog-content">
        <div class="field mb-0">
          <label for="" >Please select pool timezone</label>
          <div>
            <Dropdown v-model="timezoneEdit" :options="timezones" optionValue="Id" optionLabel="DisplayName" placeholder="Select a Timezone" :filter="true" filterPlaceholder="Find Timezone" class="w-full dropdown-lg"/>
          </div>
        </div>
      </div>
      <template #footer>
        <Button label="Cancel" icon="pi pi-times" @click="closeEditor" class="p-button-text p-button-secondary"/>
        <Button label="Save" icon="pi pi-check" @click="editTimezone" :disabled='!timezoneEdit' />
      </template>
    </Dialog>
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Dropdown from 'primevue/dropdown';
import Tag from 'primevue/tag';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import { TimeZoneDto } from "@/models/TimeZoneDto";

@Component({
  components: {
    Dropdown,
    Tag,
    Button,
    Dialog
  },
})
class PoolTimezoneView extends Vue {
  @Prop({ required: true }) poolKey!: string;
  @Prop({ required: true }) timezone!: string;

  get timezoneDisplayname(): string {
    const timezone = this.timezones.find((tz) => tz.Id === this.timezone);
    return timezone ? timezone.DisplayName : this.timezone;
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }

  displayEditor = false;
  timezoneEdit = "";

  openEditor(): void {
    this.timezoneEdit = this.timezone;
    this.displayEditor = true;
  }

  closeEditor(): void {
    this.displayEditor = false;
  }

  editTimezone(): void {
    if (this.timezoneEdit) {
      this.closeEditor();
      const request = {
        poolKey: this.poolKey,
        body: this.timezoneEdit
      };
      this.$store.dispatch("pools/changeTimezone", request);
    }
  }
}

export default PoolTimezoneView;
</script>