<template>
  <highcharts ng-non-bindable ref="chartElement" class="fullWidthHeight" v-if="isMounted" :options="chartOptions"></highcharts>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import AngularOptions from '../../models/AngularOptions';
import ChartDataResponse from '../../models/ChartDataResponse';
import ChartRequstBody from '../../models/ChartRequstBody';
import StreamOption from '../../models/dashboard/StreamOption';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import { PointOptionsObject } from 'highcharts';
import funnelModule from 'highcharts/modules/funnel';
funnelModule(Highcharts);
import ColorHelper from '../../helpers/ColorHelper';

@Component({

  components: {
    highcharts: Chart 
  }
})
class FunnelWidget extends Vue {
  @Prop({ required: true }) angularOptions!: AngularOptions;

  chartData: ChartDataResponse[] = [];
  streamColors: string[] = [];
  names: string[] = [];
  series: Highcharts.SeriesOptionsType[] = [];

  isMounted = false;

  get chartOptions(): Highcharts.Options {
    const units = this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetUnit as string;
    const series = this.series;
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "funnel",
        animation: true,
        plotShadow: false,
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          }
        }
      },
      tooltip: {
        formatter: function() {
          return `<span style="color:${this.color};">\u25CF </span><b>${this.key}:</b> ${(this.y ?? 0).toFixed(2)} ${units}`;
        },
        useHTML: true,
        outside: true
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          dataLabels: {
            format: '{point.name} - {point.percentage:.0f} %',
            style: {
              fontSize: "14px"
            },
            inside: true
          },
          showInLegend: true
        }
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        itemStyle: {
          color: this.angularOptions.angularScope.theme[this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor].title1,
          font: "500 10px Arial, sans-serif",
          verticalAlign: 'middle'
        },
        symbolHeight: 8,
        symbolWidth: 8,
        maxHeight: 40
      },
      series: series
    }
  }

  created(): void {
    if (this.angularOptions && this.angularOptions.angularScope) {
      if (this.angularOptions.angularScope.vueData && this.angularOptions.angularScope.vueRequestBody) {
        this.dataUpdate(
          this.angularOptions.angularScope.vueData as ChartDataResponse[],
          this.angularOptions.angularScope.vueRequestBody as ChartRequstBody);
      }
      if (this.angularOptions.angularScope.subscribeToDataUpdate) {
        this.angularOptions.angularScope.subscribeToDataUpdate(this.dataUpdate);
      }
    }
  }

  mounted(): void {
    this.isMounted = true;
    if (this.angularOptions && this.angularOptions.angularScope && this.angularOptions.angularScope.subscribeToWidgetResize) {
      this.angularOptions.angularScope.subscribeToWidgetResize(this.resizeWidgetEvent);
    }
  }

  loadConfig(): void {
    if (this.angularOptions && 
      this.angularOptions.angularScope &&
      this.angularOptions.angularScope.Widget &&
      this.angularOptions.angularScope.Widget.config &&
      this.angularOptions.angularScope.Widget.config.widgetOptions &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings &&
      this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions) {
      const streams = this.angularOptions.angularScope.Widget.config.widgetOptions.widgetDataSettings.streamOptions as StreamOption[];
      this.names = [];
      this.streamColors = [];
      streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
        if (stream.hexStreamColor) {
          this.streamColors.push(stream.hexStreamColor);
        } else {
          if (this.angularOptions.angularScope.theme &&
              this.angularOptions.angularScope.Widget &&
              this.angularOptions.angularScope.Widget.config &&
              this.angularOptions.angularScope.Widget.config.widgetOptions &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings &&
              this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor) {
            const theme = this.angularOptions.angularScope.theme[this.angularOptions.angularScope.Widget.config.widgetOptions.advancedWidgetSettings.widgetColor]
            this.streamColors.push(theme.colors[stream.streamColor]);
          } else {
            this.streamColors.push(ColorHelper.random());
          }
        }
      });
    }
  }

  dataUpdate(data: ChartDataResponse[], requestBody: ChartRequstBody): void {
    this.chartData = data;
    this.loadConfig();
    let i = 0;
    const result: Array<PointOptionsObject> = [];
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        // angular should show error message to user
        //console.error(streamData.Error);
      } else if (streamData.Data && streamData.Data.length) {
        result.push({
          name: this.names.length > i ? this.names[i] : '', 
          y: streamData.Data[0].y ? streamData.Data[0].y : 0,
          color: this.streamColors.length > i ? this.streamColors[i] : ''
        });
      }
      i++;
    });
    result.sort(function (a, b) {
      if (a.y !== null && b.y !== null && a.y !== undefined && b.y !== undefined) {
        if (a.y < b.y) {
          return 1;
        }
        if (a.y > b.y) {
          return -1;
        }
      }
      return 0;
    });
    this.series = [{
      name: '',
      colorByPoint: true,
      type: 'funnel',
      data: result
    }];
    let allZero = true;
    for(let i = 0; i < result.length; i++) {
      if (result[i].y) {
        allZero = false;
        break;
      }
    }
    if ((!result.length || allZero) && this.angularOptions.angularScope.showNoDataMessage) {
      this.angularOptions.angularScope.showNoDataMessage();
    } else {
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  // chart reflow on widget resize
  resizeWidgetEvent(): void {
    const chartElement = this.getChartElement();
    if (chartElement) {
      chartElement.chart.reflow();
    }
  }
}

export default FunnelWidget;
</script>
