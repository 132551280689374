<template>
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 18 21"><path fill="#989A9C" d="M10.53 9.174A3.965 3.965 0 0 1 9.373 6.36c.02-.457.123-.906.307-1.324a10.74 10.74 0 0 1 .828-1.513c0-.024.024-.024.024-.047A29.594 29.594 0 0 1 12.87.544L13.346 0l.473.544c1.466 1.7 3.5 4.043 3.5 5.816a4.049 4.049 0 0 1-1.16 2.814 3.964 3.964 0 0 1-2.813 1.159 4.05 4.05 0 0 1-2.814-1.159Z"/><path fill="#989A9C" d="M7.634 1.16c.348.418.743.86 1.138 1.348l.6.7a9.1 9.1 0 0 0-.7 1.326 4.5 4.5 0 0 0-.395 1.745 5.086 5.086 0 0 0 1.486 3.606 5.03 5.03 0 0 0 3.6 1.49c.312.004.624-.027.929-.094.116-.023.209-.047.325-.07l.139.558c.073.337.112.68.116 1.024a7.329 7.329 0 0 1-2.114 5.141 7.206 7.206 0 0 1-5.133 2.117 7.3 7.3 0 0 1-5.133-2.117 7.19 7.19 0 0 1-2.104-5.143c0-3.187 3.554-7.28 6.108-10.259.392-.512.767-.954 1.138-1.373Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class WaterDrop extends Vue {
}

export default WaterDrop;
</script>