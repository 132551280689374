<template>
  <div class="col-12 md:col-8 semantics-edit-date">
    <label :for="inputId">Value</label>
    <Calendar 
      :inputId="inputId"
      v-model="dateVal"
      dateFormat="yy-mm-dd"
      class="w-full"
      panelClass="with-max-width"
      :disabled="disabledFields.includes(field)"
    />
  </div>
</template>

<script lang="ts">
import Calendar from 'primevue/calendar';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonDate, HaysonDict, HaysonVal, Kind } from "haystack-core";
import moment from 'moment';

@Component({
  components: {
    Calendar
  },
})
class HaystackDateEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }
  
  // #region Date
  // an ISO 8601 date as year, month, day: 2011-06-07.
  get dateVal(): Date {
    if (this.valueHayson) {
      const str = (this.valueHayson as HaysonDate).val;
      const result = moment(str, "YYYY-MM-DD");
      return result.toDate();
    }
    return moment().toDate();
  }

  set dateVal(value: Date) {
    const m = moment(value);
    this.valueHayson = { 
      _kind: Kind.Date,
      val: m.format("YYYY-MM-DD")
    };
  }
  // #endregion Date
}

export default HaystackDateEditView;
</script>