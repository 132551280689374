<template>
  <div class="col-12 hd:col-8 semantics-edit-xstr">
    <div class="formgrid grid row-gap-2 flex-auto">
      <div class="col-12 md:col-6">
        <label :for="inputId + '-type'">Type</label>
        <div>
          <input
            :id="inputId + '-type'"
            class="inputfield w-full p-inputtext p-component"
            type="text"
            v-model="xStrType"
            @keydown="xStrTypeKeydown($event)"
            :disabled="disabledFields.includes(field)"
          />
        </div>
      </div>
      <div class="col-12 md:col-6">
        <label :for="inputId + '-value'">Value</label>
        <div>
          <InputText
            :id="inputId + '-value'"
            class="inputfield w-full"
            type="text"
            v-model="xStrVal"
            :disabled="disabledFields.includes(field)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import InputText from 'primevue/inputtext';
import { Component, Model, Prop, Vue, Watch } from "vue-facing-decorator";
import { HaysonXStr, HaysonVal, Kind, HaysonDict } from "haystack-core";

@Component({
  components: {
    InputText
  },
})
class HaystackXStrEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }

  // #region XStr
  // extended typed string
  get haysonXStr(): HaysonXStr {
    if (this.valueHayson) {
      return (this.valueHayson as HaysonXStr);
    }
    return {
      _kind: Kind.XStr,
      val: "",
      type: ""
    };
  }

  get xStrVal(): string {
    return this.haysonXStr.val;
  }

  set xStrVal(value: string) {
    this.haysonXStr.val = value;
  }

  get xStrType(): string {
    return this.haysonXStr.type;
  }

  set xStrType(value: string) {
    this.haysonXStr.type = value;
  }

  xStrTypeKeydown(e: KeyboardEvent) {
    if (/^\W$/.test(e.key)) {
      e.preventDefault();
    }
  }

  @Watch("xStrType", {immediate: false})
  onXStrTypeChange(val: string, oldVal: string) {
    if (val.length && !/^[A-Z]+$/.test(val[0])) {
      if (!oldVal.length || /^[A-Z]+$/.test(oldVal[0])) {
        this.xStrType = oldVal;
      }
    }
  }
  // #endregion XStr
}

export default HaystackXStrEditView;
</script>