<template>
  <div class="stream-details-view">
    <div v-if="isLoaded">
      <div class="stream-details-view-header">
        <div>
          <Breadcrumb 
            :home="breadcrumbHome" 
            :model="breadcrumbItems" 
            class="mb-0 page-breadcrumb reduce-last-item block-last-item"
          >
            <template #separator>
              <span class="pi pi-chevron-right" aria-hidden="true"></span>
            </template>
          </Breadcrumb>
        </div>
        <div class="stream-details-view-header-actions" v-if="stream">
          <div class="ml-processing-switch" v-if="stream.VirtualType !== 3">
            <label for="isPostProcessingEnabled" class="mb-0"><span>ML processing</span><span v-tippy="'ML processing refers to Machine Learned Processing. When enabled, your data will be processed with machine learning and any missing data will be substituted accordingly.'"><HelpIconSvg/></span></label>
            <InputSwitch 
              v-model="isPostProcessingEnabled" 
              inputId="isPostProcessingEnabled" 
              class="vertical-align-top"
              @change="changePostProcessType"
            />
          </div>
          <div class="flex align-items-center column-gap-2">
            <span class="flex-shrink-0" v-if="stream.VirtualType !== 3">
              <Button label="Re-process" @click="markForRecalculate" class="p-button-outlined text-base" />
            </span>
            <span class="flex-shrink-0">
              <Button @click="toggleStreamConfigMenu" text rounded class="stream-config-menu-init-btn">
                <span><CogwheelSvg/></span>
              </Button>
            </span>
          </div>
        </div>
      </div>

      <div v-if="stream && stream.Recalculate" class="flex justify-content-start md:mt-4 xl:mt-5">
        <InlineMessage severity="warn" class="flex-shrink-0">This stream is flagged for re-processing!</InlineMessage>
      </div>
      
      <div class="stream-details-info mt-5 md:mt-4 xl:mt-5" v-if="stream && stream.VirtualType !== 3">
        <div class="stream-details-info-item">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 42 41"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M29.485 7.676a11.473 11.473 0 0 1 0 16.528m-16.97 0a11.473 11.473 0 0 1 0-16.528M6.858 29.714c-7.81-7.607-7.81-19.94 0-27.547m28.284 0c7.81 7.607 7.81 19.94 0 27.547M21 19.836c2.21 0 4-1.744 4-3.896 0-2.151-1.79-3.896-4-3.896s-4 1.745-4 3.896c0 2.152 1.79 3.896 4 3.896Zm0 0v17.53"/></svg>
          </i>
          <div>
            <span>Reading status</span>
            <span class="flex align-items-center"><span class="tree-node-icon flex-shrink-0" :class="getReadingStatusColorClass(readingStatus)"></span>{{readingStatus}}</span>
          </div>
        </div>
        <div class="stream-details-info-item" v-if="stream.DataType === 0">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 43 43"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21.333 9.5v12l8 4m12-4c0 11.046-8.954 20-20 20s-20-8.954-20-20 8.954-20 20-20 20 8.954 20 20Z"/></svg>
          </i>
          <div>
            <span>Last log value</span>
            <span>{{stream.LastValue ? stream.LastValue.toLocaleString() : '0'}} {{units}}</span>
          </div>
        </div>
        <div class="stream-details-info-item">
          <i>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 41 41"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M37.333 37.167H6.963c-1.037 0-1.556 0-1.952-.202a1.852 1.852 0 0 1-.81-.81C4 35.76 4 35.242 4 34.205V3.834m31.481 9.259-7.257 7.745c-.275.294-.412.44-.578.516a.926.926 0 0 1-.468.08c-.182-.016-.36-.108-.718-.294l-6.032-3.132c-.357-.185-.535-.278-.717-.294a.926.926 0 0 0-.468.08c-.166.076-.303.222-.578.516l-7.258 7.746"/></svg>
          </i>
          <div>
            <span>Readings</span>
            <span><span>{{stream.Records ? stream.Records.toLocaleString() : '0'}}</span><span>Last at {{lastUpdateStr}}</span></span>
          </div>
        </div>
      </div>

      <StreamDetailsDataView v-if="stream && stream.VirtualType !== 3" />

      <StreamDetailsQualityView v-if="stream && stream.DataType === 0 && stream.VirtualType !== 3" />

      <div class="stream-details stream-details-visual mt-5 md:mt-4 xl:mt-5" v-if="stream">
        <header class="stream-details-visual-header">
          <h2 class="mb-0">Tags</h2>
        </header>
        <div class="stream-details-visual-body pool-stream-tags" v-if="stream">
          <StreamTagsView :poolKey="stream.PoolKey" :streamKey="stream.Id" :tags="stream.Tags"/>
        </div>
      </div>

      <Menu 
        ref="streamConfigMenu" 
        id="streamConfigMenu" 
        :model="streamConfigMenuItems" 
        :popup="true"
      />

      <Dialog header="Rename Stream" v-model:visible="displayRenameStream" :modal="true" :style="{width: '44rem'}">
        <div class="dialog-content">
          <div class="field mb-0">
            <label for="">Enter new name</label>
            <div>
              <InputText
                class="inputfield p-inputtext w-full"
                placeholder="Stream Name"
                type="text"
                v-model="newStreamName"
              />
            </div>
          </div>
        </div>
        <template #footer>
          <Button label="Cancel" icon="pi pi-times" @click="closeRenameStream" class="p-button-text p-button-secondary"/>
          <Button label="Rename" icon="pi pi-check" @click="renameStream" :disabled='!newStreamName' />
        </template>
      </Dialog>

      <Dialog header="Configure stream" v-model:visible="displayStreamAdditionalDialog" :modal="true" :style="{width: '44rem'}">
        <div class="dialog-content">
          <ul v-if="stream && isLoadedStreamAdditional && streamAdditional && streamAdditionalCopy && !inProgressSaveStreamAdditional" class="configure-stream-list">
            <li v-if="stream.DataType === 0">
              <div>
                <label>Auto type detection</label>
                <span>When enabled, machine learning will apply for recording stream data.</span>
              </div>
              <div class="flex align-items-start pt-1">
                <InputSwitch 
                  v-model="streamAdditionalCopy.IsAccumulatingAuto"
                  class="vertical-align-top" 
                />
              </div>
            </li>
            <li v-if="stream.DataType === 0">
              <div>
                <label>Accumulating value</label>
                <span>When enabled, stream data will record accumulating values.</span>
              </div>
              <div class="flex align-items-start pt-1">
                <InputSwitch 
                  v-model="streamAdditionalCopy.IsAccumulating"
                  :disabled="streamAdditionalCopy.IsAccumulatingAuto"
                  class="vertical-align-top"
                />
              </div>
            </li>
            <li v-if="stream.DataType === 0">
              <div>
                <label>Change of value</label>
                <span>When enabled, only changes in data value will be recorded.</span>
              </div>
              <div class="flex align-items-start pt-1">
                <InputSwitch 
                  v-model="streamAdditionalCopy.ChangeOfValue"
                  class="vertical-align-top" 
                />
              </div>
            </li>
            <li v-if="stream.DataType === 0" class="configure-stream-list-select">
              <div>
                <label>Select a default aggregation type</label>
              </div>
              <div class="flex align-items-center w-full md:w-15rem">
                <Dropdown 
                  v-model="streamAdditionalCopy.AggregateType" 
                  :options="aggregateTypes" 
                  optionValue="key" 
                  optionLabel="name" 
                  placeholder="Aggregation type"
                  class="w-full"
                />
              </div>
            </li>
          </ul>
          <div class="min-h-full flex justify-content-center align-items-center flex-auto" style="padding: 1px 0;" v-else>
            <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
          </div>
        </div>
        <template #footer>
          <Button label="Cancel" icon="pi pi-times" @click="closeStreamAdditionalDialog" class="p-button-text p-button-secondary"/>
          <Button label="Save" icon="pi pi-check" @click="saveStreamAdditional" :disabled='inProgressSaveStreamAdditional' />
        </template>
      </Dialog>
    </div>
    <div v-else class="progress-spinner-container">
      <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
    </div>
  </div>
  </template>

<script lang="ts">
import { StreamModel } from "@/models/StreamModel";
import { Component, Prop, Vue } from "vue-facing-decorator";
import ProgressSpinner from 'primevue/progressspinner';
import Breadcrumb from 'primevue/breadcrumb';
import Button from 'primevue/button';
import InlineMessage from 'primevue/inlinemessage';
import InputText from 'primevue/inputtext';
import InputSwitch from 'primevue/inputswitch';
import InputNumber from 'primevue/inputnumber';
import Dropdown from 'primevue/dropdown';
import SelectButton from 'primevue/selectbutton';
import Menu from "primevue/menu";
import Dialog from "primevue/dialog";
import { MenuItem, MenuItemCommandEvent } from "primevue/menuitem";
import StreamDetailsDataView from './StreamDetailsDataView.vue';
import StreamDetailsQualityView from './StreamDetailsQualityView.vue';
import StreamTagsView from "./StreamTagsView.vue";
import { TimeZoneDto } from "@/models/TimeZoneDto";
import moment from "moment";
import { PostProcessingType } from "@/models/enums/PostProcessingType";
import { AggregateType } from "@/models/enums/AggregateType";
import { StreamAdditionalDto } from "@/models/StreamAdditionalDto";
import numbro from "numbro";
import NavigationHelper from "@/helpers/NavigationHelper";
import DataHelper from "@/helpers/DataHelper";
import { reactive } from "vue";
import ConfirmationService from "@/services/ConfirmationService";
import { BitPoolUserRolesTypes } from "@/models/enums/BitPoolUserRolesTypes";
import HelpIconSvg from "@/components/svg/HelpIconSvg.vue";
import CogwheelSvg from "@/components/svg/CogwheelSvg.vue";

@Component({
  components: {
    ProgressSpinner,
    Breadcrumb,
    Button,
    InlineMessage,
    InputText,
    InputSwitch,
    InputNumber,
    Dropdown,
    SelectButton,
    Menu,
    Dialog,
    StreamDetailsDataView,
    StreamDetailsQualityView,
    StreamTagsView,
    HelpIconSvg,
    CogwheelSvg
  }
})
class StreamDetailsView extends Vue {
  @Prop({ required: true }) streamKey!: string;

  get isLoaded(): boolean {
    return this.$store.state.stream.isLoaded;
  }

  get stream(): StreamModel | null {
    return this.$store.state.stream.stream;
  }

  get firstUpdateStr(): string {
    return this.stream ? this.dateToStr(this.stream.FirstUpdates) : "";
  }

  get lastUpdateStr(): string {
    return this.stream ? this.dateToStr(this.stream.LastUpdates) : "";
  }

  dateToStr(date: Date | string | null): string {
    if (date && date instanceof Date) {
      const timezone = this.timezoneModel;
      const utcOffset = timezone ? timezone.UtcOffset : 0;
      const m = moment(date).utc().add(utcOffset, "hours");
      const result = m.format("DD/MM/YYYY HH:mm:ss");
      return result;
    }
    return "-";
  }

  dateToMomentWithTimezone(date: Date | string | null): moment.Moment | null {
    if (date && date instanceof Date) {
      const timezone = this.timezoneModel;
      const utcOffset = timezone ? timezone.UtcOffset : 0;
      const m = moment(date).utc().add(utcOffset, "hours");
      return m;
    }
    return null;
  }

  get timezoneModel(): TimeZoneDto | undefined {
    const timezoneStr = this.stream?.TimeZone;
    const timezone = this.stream ? this.timezones.find((tz) => tz.Id === timezoneStr) : undefined;
    return timezone;
  }

  get timezones(): TimeZoneDto[] {
    return this.$store.state.timezones;
  }

  get readingStatus(): string {
    return DataHelper.readingStatus(this.stream);
  }

  getReadingStatusColorClass(status: string): string {
    return DataHelper.readingStatusColorClass(status);
  }

  get tags(): string[] {
    return this.stream && this.stream.Tags ? this.stream.Tags : [];
  }

  get units(): string {
    const tags = this.tags;
    for (const i in tags) {
      const tag = tags[i];
      if (tag.startsWith("unit=")) {
        const result = tag.slice("unit=".length);
        return result;
      }
    }
    return "";
  }

  breadcrumbHome = {
    label: "Your Pools",
    url: "/data/pools",
    command: (event: MenuItemCommandEvent) => {
      if (!NavigationHelper.goTo("/data/pools")) {
        event.originalEvent.preventDefault();
      }
    }
  };

  get breadcrumbItems(): MenuItem[] {
    const poolUrl = `/data/pools/${this.stream?.PoolKey}`;
    const streamUrl = this.buildStreamHref();
    return this.isLoaded && this.stream ? [
      { 
        label: this.stream.PoolName, 
        url: poolUrl,
        command: (event: MenuItemCommandEvent) => {
          if (!NavigationHelper.goTo(poolUrl)) {
            event.originalEvent.preventDefault();
          }
        }
      },
      { 
        label: this.stream.Name, 
        url: streamUrl,
        command: (event: MenuItemCommandEvent) => {
          if (!NavigationHelper.goTo(streamUrl)) {
            event.originalEvent.preventDefault();
          }
        }
      }
    ] : [];
  }

  created(): void {
    this.reload();
  }

  async reload(): Promise<void> {
    const searchParams = new URLSearchParams(window.location.search);
    const poolKey = searchParams.get('poolKey');
    await Promise.all([
      this.$store.dispatch("stream/loadStream", { streamKey: this.streamKey, poolKey: poolKey }),
      this.$store.dispatch("stream/loadStreamAdditional", this.streamKey)
    ]);
  }

  buildStreamHref(): string {
    const href = this.stream ? this.stream.PoolVirtual ? `/data/streams/${this.stream.Id}?poolKey=${this.stream.PoolKey}` : `/data/streams/${this.stream.Id}` : "";
    return href;
  }
  
  // #region post process
  get isPostProcessEnabled(): boolean {
    return this.stream ? 
      this.postProcessStr(this.stream.PostProcessingType) === PostProcessingType[1] : 
      false;
  }

  postProcessStr(value: PostProcessingType | number | string): string {
    return typeof value === "string" ? value : PostProcessingType[value];
  }

  get isPostProcessingEnabled(): boolean {
    return this.stream ? this.stream.PostProcessingType === PostProcessingType.SmoothData : false;
  }

  set isPostProcessingEnabled(value: boolean) {
    if (this.stream) {
      this.stream.PostProcessingType = value ? PostProcessingType.SmoothData : PostProcessingType.None;
    }
  }

  changePostProcessType(): void {
    if (this.stream) {
      const request = {
        poolKey: this.stream.PoolKey,
        streamKey: this.stream.Id,
        postProcessType: (this.stream.PostProcessingType === PostProcessingType.None || this.stream.PostProcessingType === PostProcessingType[0]) ? 
          PostProcessingType.None : PostProcessingType.SmoothData
      };
      this.$store.dispatch("pool/changePostProcessType", request);
    }
  }
  // #endregion post process

  allowArchiving(): void {
    if (this.stream) {
      const request = {
        poolKey: this.stream.PoolKey,
        streamKey: this.stream.Id,
        allow: !this.stream.AllowArchiving
      };
      this.$store.dispatch("pool/allowArchiving", request);
    }
  }

  markForRecalculate(): void {
    if (this.stream) {
      const request = {
        poolKey: this.stream.PoolKey,
        streamKey: this.stream.Id
      };
      this.$store.dispatch("pool/recalculateStream", request);
    }
  }

  aggregateTypeStr(value: AggregateType | number | string): string {
    return typeof value === "string" ? value : AggregateType[value];
  }

  // #region stream config
  canEdit(): boolean {
    return !!this.stream && (this.stream.AccessMode === BitPoolUserRolesTypes.Administrator || 
      this.stream.AccessMode === BitPoolUserRolesTypes.Owner ||
      this.stream.AccessMode === BitPoolUserRolesTypes.CoOwner);
  }

  toggleStreamConfigMenu(event: Event): void {
    if (this.$refs.streamConfigMenu) {
      (this.$refs.streamConfigMenu as Menu).toggle(event);
    }
  }

  get streamConfigMenuItems(): MenuItem[] {
    const result: MenuItem[] = [
      {
        label: "Configure",
        icon: "",
        visible: this.stream?.DataType === 0 && this.stream?.VirtualType !== 3,
        command: () => {
          this.openStreamAdditionalDialog();
        }
      },
      {
        label: "Rename",
        icon: "",
        disabled: !this.canEdit(),
        command: () => {
          if (this.stream) {
            this.openRenameStream(this.stream.Name);
          }
        }
      },
      {
        label: "Delete",
        icon: "",
        disabled: !this.canEdit(),
        command: () => {
          const message = `All data associated with this stream will be deleted. This action is not reversible. Ensure all related dashboards are checked before continuing.`;
          ConfirmationService.showConfirmation({
            message: message,
            header: 'You are about to delete this stream',
            icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
            acceptIcon: 'pi pi-check',
            rejectIcon: 'pi pi-times',
            rejectClass: 'p-button-secondary p-button-text',
            accept: () => {
              // callback to execute when user confirms the action
              if (this.stream) {
                this.deleteStream();
              }
            },
            reject: () => {
              // callback to execute when user rejects the action
            }
          });
        }
      }
    ];
    // https://github.com/primefaces/primevue/issues/2268
    return result.map((item) => reactive(item));
  }

  displayRenameStream = false;
  newStreamName = "";

  openRenameStream(name: string): void {
    this.newStreamName = name;
    this.displayRenameStream = true;
  }

  closeRenameStream(): void {
    this.displayRenameStream = false;
  }

  async renameStream(): Promise<void> {
    if (this.newStreamName && this.stream) {
      this.closeRenameStream();
      const request = {
        poolKey: this.stream.PoolKey,
        streamKey: this.stream.Id,
        body: { Name: this.newStreamName, Description: null }
      };
      await this.$store.dispatch("pool/rename", request);
      this.stream.Name = this.newStreamName;
    }
  }

  async deleteStream(): Promise<void> {
    if (this.stream) {
      const poolUrl = `/data/pools/${this.stream.PoolKey}`;
      const request = {
        poolKey: this.stream.PoolKey,
        streamKey: this.stream.Id,
        reload: false
      };
      await this.$store.dispatch("pool/delete", request);
      NavigationHelper.goTo(poolUrl)
    }
  }
  // #endregion stream config

  // #region stream additional
  get isLoadedStreamAdditional(): boolean {
    return this.$store.state.stream.isLoadedStreamAdditional;
  }

  get streamAdditional(): StreamAdditionalDto | null {
    return this.$store.state.stream.streamAdditional;
  }

  displayStreamAdditionalDialog = false;
  streamAdditionalCopy: StreamAdditionalDto | null = null;

  aggregateTypes = [
    {name: 'Default', key: 0}, // None
    {name: 'Average', key: 1},
    {name: 'Maximum', key: 2},
    {name: 'Minimum', key: 3},
    {name: 'Difference', key: 4},
    {name: 'Sum', key: 5},
    // {name: 'HighDuration', key: 6}, deprecated
    // {name: 'LowDuration', key: 7}, deprecated
    {name: 'Accum', key: 8},
    {name: 'First', key: 9},
    {name: 'Last', key: 10}
  ];

  openStreamAdditionalDialog(): void {
    this.streamAdditionalCopy = this.streamAdditional ? JSON.parse(JSON.stringify(this.streamAdditional)) : null;
    this.displayStreamAdditionalDialog = true;
  }

  closeStreamAdditionalDialog(): void {
    this.displayStreamAdditionalDialog = false;
  }

  get inProgressSaveStreamAdditional(): boolean {
    return this.$store.state.stream.inProgressSaveStreamAdditional;
  }

  async saveStreamAdditional(): Promise<void> {
    this.$store.state.stream.streamAdditional = JSON.parse(JSON.stringify(this.streamAdditionalCopy));
    await this.$store.dispatch("stream/saveStreamAdditional");
    if (!this.$store.state.stream.errorStreamAdditional) {
      this.displayStreamAdditionalDialog = false;
      this.streamAdditionalCopy = null;
    }
  }
  // #endregion stream additional 
}

export default StreamDetailsView;
</script>