<template>
  <div class="widget-type-data-grid widget-with-datatable">    
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="min-h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div v-show="!isLodingData && !isNoData" class="widget-datatable-container widget-datatable-container-vertical-scrollable">
      <DataTable :value="unlockedRows" :frozenValue="lockedRows" stripedRows showGridlines removableSort responsiveLayout="scroll" class="p-datatable-sm">
        <Column headerStyle="width: 1%; min-width: 48px;">
          <template #body="{data,frozenRow,index}">
            <Button v-tippy="frozenRow ? 'Unlock' : 'Lock'" type="button" :icon="frozenRow ? 'pi pi-lock-open' : 'pi pi-lock'" :disabled="frozenRow ? false : lockedRows.length >= 2" class="p-button-sm p-button-icon-only p-button-rounded p-button-text p-button-secondary button-lock" @click="toggleLock(data, frozenRow, index)"/>
          </template>
        </Column>
        <Column :sortable="true" field="name" header="Name" headerStyle="min-width: 150px;" headerClass="no-break-word">
          <template #body="slotProps">
            <a target="_blank" :href="buildStreamHref(slotProps.data.streamKey)">{{slotProps.data.name}}</a>
          </template>
        </Column>
        <Column :sortable="true" field="streamLogsCount" header="Record Count" headerClass="no-break-word" v-if="isFieldVisible('streamLogsCount')">
          <template #body="slotProps">
            {{formatInt(slotProps.data.streamLogsCount)}}
          </template>
        </Column>
        <Column :sortable="true" field="firstValue" header="First Value" headerClass="no-break-word" v-if="isFieldVisible('firstValue')">
          <template #body="slotProps">
            {{formatFloat(slotProps.data.firstValue)}}
          </template>
        </Column>
        <Column :sortable="true" field="lastValue" header="Last Value" headerClass="no-break-word" v-if="isFieldVisible('lastValue')">
          <template #body="slotProps">
            {{formatFloat(slotProps.data.lastValue)}}
          </template>
        </Column>
        <Column :sortable="true" field="min" header="Minimum" headerClass="no-break-word" v-if="isFieldVisible('min')">
          <template #body="slotProps">
            {{formatFloat(slotProps.data.min)}}
          </template>
        </Column>
        <Column :sortable="true" field="max" header="Maximum" headerClass="no-break-word" v-if="isFieldVisible('max')">
          <template #body="slotProps">
            {{formatFloat(slotProps.data.max)}}
          </template>
        </Column>
        <Column :sortable="true" field="avg" header="Average" headerClass="no-break-word" v-if="isFieldVisible('avg')">
          <template #body="slotProps">
            {{formatFloat(slotProps.data.avg)}}
          </template>
        </Column>
        <Column :sortable="true" field="diff" header="Difference" headerClass="no-break-word" v-if="isFieldVisible('diff')">
          <template #body="slotProps">
            {{formatFloat(slotProps.data.diff)}}
          </template>
        </Column>
        <Column :sortable="true" field="sum" header="Sum" headerClass="no-break-word" v-if="isFieldVisible('sum')">
          <template #body="slotProps">
            {{formatFloat(slotProps.data.sum)}}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script lang="ts">
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { PropType } from 'vue';
import { Component, Prop, Vue } from 'vue-facing-decorator';
import { Watch } from 'vue-facing-decorator';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import DashboardState from '@/store/states/DashboardState';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import DataHelper from '@/helpers/DataHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import numbro from "numbro";
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import { StreamValuesRequest } from '@/models/StreamValuesRequest';
import { AggregationType } from '@/models/enums/AggregationType';
import { AggregationTypeString } from '@/models/enums/AggregationTypeString';
import { StreamModel } from '@/models/StreamModel';
import StreamOption from '@/models/dashboard/StreamOption';

@Component({
  components: {
    ProgressSpinner,
    DataTable,
    Column,
    Button,
    WidgetNoDataView
  }
})
class DataGridWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  requestBody: AggregatedDataRequest | null = null;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  dataRefreshInterval = 0;

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
  }

  unmounted(): void {
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.lockedIndexes = [];
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }

  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.configuredStreams.length) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType, this.aws?.fields);
      const streamKeys = new Set<string>();
      if (requestBody.Streams.length) {
        requestBody.Streams.forEach(streamRequest => {
          if (streamRequest.StreamKey) {
            streamKeys.add(streamRequest.StreamKey);
          }
        });        
      }
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await Promise.all([
          this.$store.dispatch("widgetData/loadStreams", [this.widgetConfig.guid, Array.from(streamKeys)]),
          this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody])
        ]);        
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      const streams = this.widgetDataState.streams[this.widgetConfig.guid][1];
      if (data || streams) {
        this.dataUpdate(data ? data : [], requestBody, streams ? streams : []);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  tableValues: any[] = [];

  lockedIndexes: string[] = [];

  get lockedRows(): any[] {
    const result: any[] = [];
    if (this.tableValues) {
      this.tableValues.forEach(row => {
        if (this.lockedIndexes.includes(row.rowIndex)) {
          result.push(row);
        }
      });
    }
    return result;
  }

  get unlockedRows(): any[] {
    const result: any[] = [];
    if (this.tableValues) {
      this.tableValues.forEach(row => {
        if (!this.lockedIndexes.includes(row.rowIndex)) {
          result.push(row);
        }
      });
    }
    return result;
  }

  toggleLock(data: any, frozen: boolean, index: number): void {
    const lockIndex = this.lockedIndexes.findIndex(x => x === data.rowIndex);
    if (lockIndex >= 0) {
      this.lockedIndexes.splice(lockIndex, 1);
    } else {
      this.lockedIndexes.push(data.rowIndex);
    }
  }

  get configuredStreams(): StreamOption[] {
    const result = this.wds?.streamOptions?.filter(x => x.StreamKey);
    return result ? result : [];
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest, streams: StreamModel[]): void {
    this.chartData = data;
    this.requestBody = requestBody;
    const tableValues: any[] = [];
    if (this.wds && this.configuredStreams.length) {
      this.configuredStreams.forEach((streamOption, index) => {
        const stream = streams.find(x => x.Id === streamOption.StreamKey);
        tableValues.push({
          rowIndex: index,
          streamKey: streamOption.StreamKey,
          name: streamOption.Label,
          streamLogsCount: stream ? stream.Records : undefined,
          firstValue: stream ? stream.FirstValue : undefined,
          lastValue: stream ? stream.LastValue : undefined,
          min: undefined,
          max: undefined,
          avg: undefined,
          diff: undefined,
          sum: undefined
        });
      });
    }
    this.chartData.forEach((streamData, index) => {
      const streamIndex = Math.floor(index / this.aggregationFieldCount);
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length) {
        const tableValue = tableValues[streamIndex];
        let aggregationType = streamData.StreamValuesRequest.AggregationType;
        if (typeof aggregationType === "string") {
          const aggregationTypeStr = aggregationType as unknown as string;
          aggregationType = AggregationType[aggregationTypeStr as keyof typeof AggregationType];
        }
        switch (aggregationType){
          case AggregationType.Min:
            tableValue.min = streamData.Data[0].y;
            break;
          case AggregationType.Max:
            tableValue.max = streamData.Data[0].y;
            break;
          case AggregationType.Avg:
            tableValue.avg = streamData.Data[0].y;
            break;
          case AggregationType.Diff:
            tableValue.diff = streamData.Data[0].y;
            break;
          case AggregationType.Sum:
            tableValue.sum = streamData.Data[0].y;
            break;
        }
      }
    });
    this.tableValues = tableValues;
    if (!tableValues.length) {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }

  formatFloat(value: number): string {
    let result: string;
    if (typeof value === "undefined" || value === null) {
      result = "";
    } else if (typeof value === "string") {
      result = value;
    } else {
      const decimals = this.aws?.decimals ? this.aws.decimals : 0;
      result = numbro(value).format({
        thousandSeparated: true,
        mantissa: decimals < 0 ? 0 : decimals
      });
    }
    return result;
  }

  formatInt(value: number): string {
    let result: string;
    if (typeof value === "undefined" || value === null) {
      result = "";
    } else if (typeof value === "string") {
      result = value;
    } else {
      result = numbro(value).format({
        thousandSeparated: true,
        mantissa: 0
      });
    }
    return result;
  }

  get aggregationFieldCount(): number {
    const result = this.aws?.fields?.length ? 
      this.aws.fields.filter(x => x !== "streamLogsCount" && x !== "firstValue" && x !== "lastValue").length : 
      0;
    return result;
  }

  isFieldVisible(field: string): boolean {
    const result = this.aws?.fields?.length ? this.aws.fields.includes(field) : false;
    return result;
  }

  buildStreamHref(streamKey: string): string {
    const href = `/data/streams/${streamKey}`;
    return href;
  }
}

export default DataGridWidget;
</script>