<template>
  <div ng-non-bindable>
    
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import SnowFall from "snow-fall-effect";
import { Watch } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";

@Component({
  
  components: {
  },
})
class HolidaysRoot extends Vue {
  snowFall: SnowFall | null = null;

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  get showHolidays(): boolean {
    return !!this.authState.userSettings?.showHolidays;
  }

  get isChristmasDev(): boolean {
    return this.$store.state.isChristmas;
  }

  get isChristmasTime(): boolean {
    const now = new Date();
    return now.getMonth() === 11 && now.getDate() >= 18 || now.getMonth() === 0 && now.getDate() <= 2
  }

  @Watch('isChristmasDev', { immediate: false, deep: false })
  onIsChristmasChanged(val: boolean, oldVal: boolean): void {
    if (val) {
      this.startSnow();
    } else {
      this.stopSnow();
    }
  }

  @Watch('showHolidays', { immediate: false, deep: false })
  onShowHolidaysChanged(val: boolean, oldVal: boolean): void {
    if (val && this.isChristmasTime) {
      this.startSnow();
    } else {
      this.stopSnow();
    }
  }

  mounted(): void {
    if (this.showHolidays && this.isChristmasTime) {
      this.startSnow();
    }
  }

  destroyed(): void {
    this.stopSnow();
  }

  startSnow(): void {
    if (!this.snowFall) {
      this.snowFall = new SnowFall({
        sizeRange: [5, 20],
        color: "#4EC8EB",
        speed: 10,
        density: 0.5,
      });
    }
    this.snowFall.init();
    this.snowFall.makeItSnow();
  }

  stopSnow(): void {
    this.snowFall?.stopTheSnowing();
  }
}

export default HolidaysRoot;
</script>