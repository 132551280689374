<template>
  <div class="col-12 md:col-8 semantics-edit-symbol">
    <label :for="inputId">Value</label>
    <Dropdown
      :inputId="inputId"
      v-if="field === 'lib'"
      v-model="symbolVal"
      :options="libsOptions"
      :optionLabel="(x: string): string => { return x; }"
      class="inputfield w-full"
      placeholder="Lib"
      :disabled="disabledFields.includes(field)"
    />
    <InputText
      :id="inputId"
      v-else
      class="inputfield w-full"
      type="text"
      v-model="symbolVal"
      :disabled="disabledFields.includes(field)"
    />
  </div>
</template>

<script lang="ts">
import Dropdown from 'primevue/dropdown';
import InputText from 'primevue/inputtext';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonDict, HaysonSymbol, HaysonVal, Kind } from "haystack-core";
import HaystackDefsService from '@/services/HaystackDefsService';

@Component({
  components: {
    Dropdown,
    InputText
  },
})
class HaystackSymbolEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }
  
  // #region Symbol
  // name constant used to identify a def
  get symbolVal(): string {
    if (this.valueHayson) {
      return (this.valueHayson as HaysonSymbol).val;
    }
    return "";
  }

  set symbolVal(value: string) {
    this.valueHayson = { 
      _kind: Kind.Symbol,
      val: value
    };
  }

  get libsOptions(): string[] {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      const libs = HaystackDefsService.hDictArrayToStringArray(defs.libs);
      return libs;
    }
    return [];
  }
  // #endregion Symbol
}

export default HaystackSymbolEditView;
</script>