import { Reports3ElementConfiguration } from "@/models/reports/v3/Reports3ElementConfiguration";
import { Reports3ElementEntity } from "@/models/reports/v3/Reports3ElementEntity";
import { Reports3ElementRole } from "@/models/reports/v3/Reports3ElementRole";
import { v4 as uuidv4 } from "uuid";

class ReportHtmlHelper {
  generateShadowPreview(element: Reports3ElementEntity, configuration: Reports3ElementConfiguration | null,
    globalStyles: string = "", organisationName: string = "Organisation", reportName: string = "Report"
  ): [string, string] {
    const result = this.generateShadowHtml(
      element,
      configuration,
      element.Role === Reports3ElementRole.Body ?
        globalStyles :
        `${globalStyles}
        .report-element {
          /* Header/footer emulation-ralated styles */
          margin: 0;
          padding: 0;
          width: 100%;
          height: ${element.Height}cm;
        }`,
      organisationName,
      reportName
    );
    return result;
  }

  private generateShadowHtml(element: Reports3ElementEntity, configuration: Reports3ElementConfiguration | null,
    globalStyles: string = "", organisationName: string = "Organisation", reportName: string = "Report"
  ): [string, string] {
    const parentId = `report-element-${uuidv4()}`;
    const parameters: Record<string, any> = {};
    if (element.AdditionalParameters) {
      for (const param of element.AdditionalParameters) {
        parameters[param.Name] = param.DefaultValue;
      }
    }
    if (configuration?.AdditionalParameters) {
      for (const key in configuration.AdditionalParameters) {
        parameters[key] = configuration.AdditionalParameters[key];
      }
    }
    parameters.organisationName = organisationName;
    parameters.reportName = reportName;
    let htmlWithParameters = element.HTML;
    let cssWithParameters = element.CSS;
    for (const key in parameters) {
      htmlWithParameters = htmlWithParameters.replace(new RegExp(`\\{${key}\\}`, 'g'), parameters[key]);
      cssWithParameters = cssWithParameters.replace(new RegExp(`\\{${key}\\}`, 'g'), parameters[key]);
    }
    const html = this.getGeneratedShadowPage(
      `<div id="${parentId}" class="report-element">${htmlWithParameters}</div>`,
      `${globalStyles} #${parentId} { ${cssWithParameters} }`
    );
    // function displayData(parent, parameters, data, dataConfiguration, libraries, onReadyEvent)
    const javascript = element.Role === Reports3ElementRole.Body ?
      `
      ${element.JavaScript}
      if (displayData) {
        const parameters = ${JSON.stringify(parameters)};
        const onReadyEvent = function() { console.log("${parentId} is ready"); };
        displayData(shadowRoot.querySelector("#${parentId}"), parameters, [], {}, libraries, onReadyEvent);
      }` :
      ``;
    return [html, javascript];
  }

  private getGeneratedShadowPage(html: string, css: string): string {
    const cssLine = css ? `<style>${css}</style>` : '';
    const source = `
      ${cssLine}
      ${html || ''}`;
    return source;
  }
}

export default new ReportHtmlHelper();
