import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import OrganisationState from "./states/OrganisationState";
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { OrganisationDto } from "@/models/OrganisationDto";

export const useOrganisationStore = defineStore('organisation', {
  state: (): OrganisationState => ({ 
    isLoaded: false,
    guid: "",
    entities: null,
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false,

    currentOrganisation: null,
    organisationUserMangmentTabIndex: 0
  }),
  getters: {
  },
  actions: {
    setCurrentOrganisation(organisation: OrganisationFullDto | null) {
      this.currentOrganisation = organisation;
    },
    setOrganisationsExternal(organisations: OrganisationFullDto[]) {
      // for angular
      const sorted = organisations.sort((a, b) => a.Name.localeCompare(b.Name));
      this.entities = sorted;
      this.isLoaded = true;
      this.guid = uuidv4();
    },
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        const url = `rest/BitPool_V2/organisations`;
        const response = await axios.get<OrganisationFullDto[]>(url);
        if (this.guid === guid) {
          this.entities = response.data;
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load organisations",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
      }
    },
    async createUpdate(entity: OrganisationFullDto, silentOnSuccess = false) {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/organisations`;
        const response = await axios.put<OrganisationDto>(url, entity, {
          headers: {
            "Content-Type": "application/json",
            "bp-branch": location.hostname.includes("tsv.") ? "tcc" : ""
          },
        });
        if (!silentOnSuccess) {
          ToastService.showToast("success", "Organisation", "Changes saved", 5000);
        }
        this.updateInProgress = false;
        if (this.isLoaded && this.entities) {
          if (entity.Id) {
            const index = this.entities.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.entities[index] = entity;
            } else {
              this.entities.push(entity);
            }
          } else {
            entity.Id = response.data.Id;
            this.entities.push(entity);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save organisation",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
    },
    async delete(entity: OrganisationFullDto) {
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/BitPool_V2/organisations/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Organisation", `${entity.Name} is deleted`, 5000);
        this.deleteInProgress = false;
        if (this.isLoaded && this.entities) {
          const index = this.entities.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.entities.splice(index, 1);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
      }
    }
  },
})
