<template>
  <div ref="root" v-if="isMounted" class="widget-type-chart widget-type-funnel">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="chart-element funnel" v-else>
      <highcharts v-if="redrawChartToggle" ref="chartElement" class="w-full h-full flex-auto" :options="chartOptions"></highcharts>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import StreamOption from '../../models/dashboard/StreamOption';
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import { DataLabelsOptions, PointOptionsObject } from 'highcharts';
import funnelModule from 'highcharts/modules/funnel';
funnelModule(Highcharts);
import ColorHelper from '../../helpers/ColorHelper';
import { nextTick, PropType } from 'vue';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { BasicWidgetSettings } from '@/models/dashboard/BasicWidgetSettings';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import DashboardState from '@/store/states/DashboardState';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import { Ref, Watch } from 'vue-facing-decorator';
import DataHelper from '@/helpers/DataHelper';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';

@Component({
  components: {
    highcharts: Chart,
    ProgressSpinner,
    WidgetNoDataView
  }
})
class FunnelWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  streamColors: string[] = [];
  names: string[] = [];
  series: Highcharts.SeriesOptionsType[] = [];

  isMounted = false;
  @Ref() readonly root!: HTMLDivElement;
  rootWidth = 150;

  get chartOptions(): Highcharts.Options {
    const units = this.aws ? this.aws.widgetUnit : "";
    const series = this.series;
    const width = this.rootWidth;
    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "funnel",
        animation: true,
        style: {
          color: ColorHelper.getDefautTextColor(),
        },
        plotShadow: false,
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          }
        }
      },
      tooltip: {
        formatter: function() {
          return `<span style="color:${this.color};">\u25CF </span><b style="word-wrap: break-word; white-space: normal;">${this.key}:</b> ${(this.y ?? 0).toFixed(2)} ${units}`;
        },
        useHTML: true,
        outside: true
      },
      plotOptions: {
        series: {
          cursor: 'pointer',
          dataLabels: {
            formatter: function (options: DataLabelsOptions): number|string|null|undefined {
              const text = `${this.key} - ${Math.round(this.percentage)} %`;
              return `<div class="funnel-chart-legend" style="width: ${width}px; word-wrap: break-word; white-space: normal;">${text}</div>`;
            },
            useHTML: true,
            style: {
              fontSize: "12px",
              width: width
            },
            inside: true
          },
          showInLegend: true,
          borderWidth: 0
        }
      },
      legend: {
        enabled: true,
        verticalAlign: 'top',
        itemStyle: {
          font: "500 10px Arial, sans-serif",
          verticalAlign: 'middle'
        },
        symbolHeight: 8,
        symbolWidth: 8,
        maxHeight: 40
      },
      series: series
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }
  
  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataRefreshInterval = 0;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
    this.isMounted = true;
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
    this.updateRootWidth();
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.resizeWidgetEvent();
  }

  loadConfig(): void {
    if (this.wds &&
      this.wds.streamOptions) {
      const streams = this.wds.streamOptions as StreamOption[];
      this.names = [];
      this.streamColors = [];
      streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
        this.streamColors.push(stream.hexStreamColor);
      });
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.loadConfig();
    let i = 0;
    const result: Array<PointOptionsObject> = [];
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length) {
        result.push({
          name: this.names.length > i ? this.names[i] : '', 
          y: typeof streamData.Data[0].y === "number" ? streamData.Data[0].y : 0,
          color: this.streamColors.length > i ? this.streamColors[i] : ''
        });
      }
      i++;
    });
    result.sort(function (a, b) {
      if (a.y !== null && b.y !== null && a.y !== undefined && b.y !== undefined) {
        if (a.y < b.y) {
          return 1;
        }
        if (a.y > b.y) {
          return -1;
        }
      }
      return 0;
    });
    this.series = [{
      name: '',
      colorByPoint: true,
      type: 'funnel',
      data: result
    }];
    let allZero = true;
    for(let i = 0; i < result.length; i++) {
      if (result[i].y) {
        allZero = false;
        break;
      }
    }
    if (result.length && !allZero) {
      this.isNoData = false;
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  redrawChartToggle = true;

  // chart reflow on widget resize
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async resizeWidgetEvent(): Promise<void> {
    this.redrawChartToggle = false;
    // uncomment in case of updateRootWidth removal
    // await nextTick();
    await this.updateRootWidth();
    this.redrawChartToggle = true;
  }

  async updateRootWidth(): Promise<void> {
    await nextTick();
    this.rootWidth = this.root ? this.root.clientWidth : 150;
  }
}

export default FunnelWidget;
</script>
