import { defineStore } from "pinia"
import PageState from "./states/PageState"

export const usePageStore = defineStore('page', {
  state: (): PageState => ({ 
    dirty: false
  }),
  getters: {
  },
  actions: {
  }
})