<template>
  <span class="masked-password-container" :class="{'with-two-buttons': copy}">
    <span class="inline-block break-word" v-if="showText">{{text}}</span>
    <span class="masked-password" v-else>
      <i class="pi pi-circle-fill"></i>
      <i class="pi pi-circle-fill"></i>
      <i class="pi pi-circle-fill"></i>
      <i class="pi pi-circle-fill"></i>
      <i class="pi pi-circle-fill"></i>
      <i class="pi pi-circle-fill"></i>
    </span>
    <Button 
      v-if="text" 
      :icon="showText ? 'pi pi-eye-slash text-lg' : 'pi pi-eye text-lg'" 
      class="p-button-icon-only p-button-rounded p-button-text" 
      @click="showText = !showText" 
    />
    <Button
      v-if="copy"
      icon="pi pi-copy text-lg" 
      class="p-button-icon-only p-button-rounded p-button-text" 
      @click="copyKey(text)"
    />
  </span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import copy from 'copy-to-clipboard';
import ToastService from "@/services/ToastService";

@Component({
  components: {
    Button
  },
})
class HiddenTextView extends Vue {
  @Prop({ required: true }) text!: string;
  @Prop({ required: false, default: false }) copy!: boolean;
  showText = false;  
  
  copyKey(key: string): void {
    copy(key);
    ToastService.showToast("success", "", "Copied!", 5000);
  }
}

export default HiddenTextView;
</script>