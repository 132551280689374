import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import MQTTSchedulerState from "./states/MQTTSchedulerState";
import DateHelper from "@/helpers/DateHelper";
import { MQTTSchedulerEntity } from "@/models/mqtt/MQTTSchedulerEntity";
import { MQTTSchedulerEvent } from "@/models/mqtt/MQTTSchedulerEvent";
import NavigationHelper from "@/helpers/NavigationHelper";

export const useMQTTSchedulerStore = defineStore('mqttScheduler', {
  state: (): MQTTSchedulerState => ({ 
    isLoaded: false,
    guid: "",
    entities: null,
    copyEntity: null,
    currentEntity: null,
    isLoadedCurrentEntity: false,
    guidCurrentEntity: "",
    updateInProgress: false,
    updateError: false
  }),
  getters: {
  },
  actions: {
    parseDates(entity: MQTTSchedulerEntity) {
      entity.Events.forEach(x => {
        this.parseDatesEvent(x);
      });
      if (typeof entity.Created === "string") {
        entity.Created = DateHelper.parseFromMicrosoftString(entity.Created);
      }
      if (typeof entity.Updated === "string") {
        entity.Updated = DateHelper.parseFromMicrosoftString(entity.Updated);
      }
    },
    parseDatesEvent(x: MQTTSchedulerEvent) {
      if (typeof x.Start === "string") {
        x.Start = DateHelper.parseFromMicrosoftString(x.Start);
      }
      if (typeof x.End === "string") {
        x.End = DateHelper.parseFromMicrosoftString(x.End);
      }
      if (x.RecurrenceSettings) {
        if (typeof x.RecurrenceSettings.StartRecur === "string") {
          x.RecurrenceSettings.StartRecur = DateHelper.parseFromMicrosoftString(x.RecurrenceSettings.StartRecur);
        }
        if (typeof x.RecurrenceSettings.EndRecur === "string") {
          x.RecurrenceSettings.EndRecur = DateHelper.parseFromMicrosoftString(x.RecurrenceSettings.EndRecur);
        }
      }
    },
    async load() {
      try {
        const guid = uuidv4();
        this.guid = guid;
        this.isLoaded = false;
        const url = `rest/BitPool_V2/mqtt/scheduler`;
        const response = await axios.get<MQTTSchedulerEntity[]>(url);
        if (this.guid === guid) {
          this.entities = response.data;
          this.entities.forEach(x => {
            this.parseDates(x);
          });
          this.isLoaded = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load scheduler",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.entities = null;
        this.isLoaded = true;
      }
    },
    async loadOne(id: string, organisationId: number) {
      try {
        if (id === "new") {
          if (this.copyEntity) {
            const entity = JSON.parse(JSON.stringify(this.copyEntity));
            entity.Id = "";
            entity.Name = entity.Name + " copy";
            this.parseDates(entity);
            this.currentEntity = entity;
            this.copyEntity = null;
          } else {
            this.currentEntity = {
              Id: "",
              Enabled: true,
              Name: "",
              OrganisationId: organisationId,
              Events: [],
              Created: new Date(),
              Updated: new Date(),
              CreatedBy: "", // api will fill
              UpdatedBy: "", // api will fill
              AdditionalData: null
            };
          }
          this.isLoadedCurrentEntity = true;
        } else {
          const guid = uuidv4();
          this.guidCurrentEntity = guid;
          this.isLoadedCurrentEntity = false;
          this.currentEntity = null;
          const url = `rest/BitPool_V2/mqtt/scheduler/${id}`;
          const response = await axios.get<MQTTSchedulerEntity>(url);
          if (this.guidCurrentEntity === guid) {
            this.parseDates(response.data);
            this.currentEntity = response.data;
            this.isLoadedCurrentEntity = true;
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load schedule",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.currentEntity = null;
        this.isLoadedCurrentEntity = true;
      }
    },
    unloadCurrentEntity() {
      this.currentEntity = null;
      this.isLoadedCurrentEntity = false;
    },
    async createUpdate(entity: MQTTSchedulerEntity): Promise<string> {
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/mqtt/scheduler`;
        const response = await axios.post<MQTTSchedulerEntity>(url, entity);
        ToastService.showToast("success", "Scheduler", "Changes saved", 5000);
        this.updateInProgress = false;
        this.parseDates(response.data);
        if (entity.Id) {
          if (this.isLoaded && this.entities) {
            const index = this.entities.findIndex((x) => x.Id === entity.Id);
            if (index > -1) {
              this.entities[index] = response.data;
            } else {
              this.entities.push(response.data);
            }
          }
        } else {
          if (this.isLoaded && this.entities) {
            this.entities.push(response.data);
          }
        }
        return response.data.Id;
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save schedule",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
        return "";
      }
    },
    async delete(entity: MQTTSchedulerEntity) {
      try {
        const url = `rest/BitPool_V2/mqtt/scheduler/${entity.Id}`;
        await axios.delete(url);
        ToastService.showToast("success", "Scheduler", `${entity.Name} is deleted`, 5000);
        if (this.isLoaded && this.entities) {
          const index = this.entities.findIndex((x) => x.Id === entity.Id);
          if (index > -1) {
            this.entities.splice(index, 1);
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete ${entity.Name}`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
      }
    }
  },
})
