<template>
  <div class="default-view-container">
    <h1 class="mb-0">Presentations</h1>
    <div v-if="authState.permissions?.FullAccess || authState.permissions?.Sharing" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Presentations List</h2>
      </header>
      <div class="default-view-body">
        <DataTable
          :value="state.configs"
          dataKey="Id"
          showGridlines 
          responsiveLayout="stack" 
          breakpoint="650px" 
          class="p-datatable-sm responsive-breakpoint presentations-table">
          <template #header>
            <div class="table-header">
              <Button label="Add Presentation" icon="pi pi-plus-circle" class="my-1" @click="openPresentation(null)" />
            </div>
          </template>
          <template #empty>
            <div v-if="state.isLoaded" class="w-full" style="min-height: 50vh;">
              <span class="inline-block py-2">No data found.</span>
            </div>
            <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
              <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
            </div>
          </template>
          <Column field="name" header="Name"></Column>
          <Column field="organisationId" headerStyle="min-width: 30%;" header="Organisation">
            <template #body="slotProps">
              {{getOrganisationName(slotProps.data.organisationId)}}
            </template>
          </Column>
          <Column field="dashboards" header="Slides">
            <template #body="slotProps">
              {{slotProps.data.dashboards?.length ?? 0}}
            </template>
          </Column>
          <Column :exportable="false" headerStyle="width: 1%; min-width: 169px;" bodyStyle="text-align: right; justify-content: flex-end;">
            <template #body="slotProps">
              <div>
                <div class="inline-flex">
                  <Button 
                    v-tippy="'Start'"
                    icon="pi pi-play" 
                    class="p-button-icon-only p-button-rounded p-button-success p-button-outlined mr-2"
                    @click="startPresentation(slotProps.data)" 
                  />
                  <Button 
                    v-tippy="'Copy URL'"
                    icon="pi pi-copy" 
                    class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                    @click="copyPresentationUrl(slotProps.data)" 
                  />
                  <span  v-tippy="{ content: (currentOrganisation?.Id === slotProps.data.organisationId && (userId === slotProps.data.userId || authState.permissions && authState.permissions.FullAccess)) ? '' : (userId === slotProps.data.userId || authState.permissions && authState.permissions.FullAccess) ? `Please switch to '${getOrganisationName(slotProps.data.organisationId)}' organisation` : 'Only Owner and CoOwner can edit this record' }">
                    <Button 
                      v-tippy="'Edit'"
                      icon="pi pi-pencil" 
                      class="p-button-icon-only p-button-rounded p-button-outlined mr-2"
                      @click="openPresentation(slotProps.data)" 
                      :disabled="currentOrganisation?.Id !== slotProps.data.organisationId || (userId !== slotProps.data.userId && !(authState.permissions && authState.permissions.FullAccess))"
                    />
                    <Button 
                      v-tippy="'Delete'"
                      icon="pi pi-trash" 
                      class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                      @click="openConfirmation(slotProps.data)" 
                      :disabled="currentOrganisation?.Id !== slotProps.data.organisationId || (userId !== slotProps.data.userId && !(authState.permissions && authState.permissions.FullAccess))"
                    />
                  </span>
                </div>
              </div>
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <div v-else class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Presentations List</h2>
      </header>
      <div class="default-view-body">
        <Message severity="error" :closable="false" class="my-0">Not enough rights</Message>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import ProgressSpinner from 'primevue/progressspinner';
import Message from 'primevue/message';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import CustomWindow from '@/CustomWindow';
import AuthState from "@/store/states/AuthState";
import PresentationState from "@/store/states/PresentationState";
import { PresentationModel } from "@/models/dashboard/PresentationModel";
import NavigationHelper from "@/helpers/NavigationHelper";
import ConfirmationService from "@/services/ConfirmationService";
import copy from 'copy-to-clipboard';
import ToastService from "@/services/ToastService";
import { useOrganisationStore } from "@/stores/organisation";

declare const window: CustomWindow;

@Component({
  
  components: {
    Button,
    DataTable,
    Column,
    Dialog,
    ProgressSpinner,
    Message
  },
  directives: {
  }
})
class PresentationsView extends Vue { 
  get state(): PresentationState {
    return this.$store.state.presentation;
  }

  get userId(): string {
    const userData = this.allUserData;
    const result = userData && userData.userData ? userData.userData.UserId : "";
    return result;
  }

  created(): void {
    this.$store.dispatch("presentation/load");
  }

  get authState(): AuthState {
    return this.$store.state.auth;
  }

  organisationStore = useOrganisationStore();

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  getOrganisationName(id: number): string {
    const organisation = this.organisationStore.entities?.find(x => x.Id === id) ?? null;
    return organisation ? organisation.Name : "UNKNOWN";
  }
  
  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  openPresentation(item: PresentationModel | null): void {
    NavigationHelper.goTo(`/dashboards/presentation/${item ? item._id : 'new'}`);
  }

  // #region delete
  selectedRecord: PresentationModel | null = null;

  openConfirmation(record: PresentationModel | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete presentation?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Confirmation',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord) {
      this.$store.dispatch("presentation/delete", this.selectedRecord._id);
    }
  }

  getPresentationUrl(item: PresentationModel): string {
    const url = `${window.location.protocol}//${window.location.host}/view/presentation/${item.share_id}`;
    return url;
  }

  startPresentation(item: PresentationModel): void {
    const url = this.getPresentationUrl(item);
    window.open(url, '_blank');
  }

  copyPresentationUrl(item: PresentationModel): void {
    const url = this.getPresentationUrl(item);
    copy(url);
    ToastService.showToast("success", "", "Copied!", 5000);
  }
  // #endregion delete
}

export default PresentationsView;
</script>