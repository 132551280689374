<template>
  <div class="default-view-container">
    <div class="xl:flex align-items-center justify-content-between">
      <Button label="Back to Semantics" icon="pi pi-angle-left text-lg -ml-1" class="p-button-outlined flex-order-2 mb-3 xl:mb-0 go-back-btn" @click="goToSemantics" />
      <Breadcrumb
        :home="breadcrumbHome"
        :model="breadcrumbItems"
        class="mb-0 lg:mr-5 page-breadcrumb"
      >
        <template #separator>
          <span class="pi pi-chevron-right" aria-hidden="true"></span>
        </template>
      </Breadcrumb>
    </div>
    <div class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <div class="default-view-body">
        <TabView lazy>
          <TabPanel header="Libs">
            <TagManagerConfigLibsView/>
          </TabPanel>
          <TabPanel header="Tags">
            <TagManagerConfigTagsView/>
          </TabPanel>
        </TabView>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Breadcrumb from "primevue/breadcrumb";
import Button from 'primevue/button';
import ProgressSpinner from "primevue/progressspinner";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import { Component, Vue } from "vue-facing-decorator";
import TagManagerConfigLibsView from '@/components/views/tags/TagManagerConfigLibsView.vue';
import TagManagerConfigTagsView from '@/components/views/tags/TagManagerConfigTagsView.vue';
import { useUnitsStore } from "@/stores/units";
import NavigationHelper from "@/helpers/NavigationHelper";
import { MenuItemCommandEvent } from "primevue/menuitem";

@Component({
  components: {
    Breadcrumb,
    Button,
    ProgressSpinner,
    TabView,
    TabPanel,
    TagManagerConfigLibsView,
    TagManagerConfigTagsView
  },
})
class TagManagerConfigView extends Vue {  
  unitsStore = useUnitsStore();

  breadcrumbHome = {
    label: "Semantics",
    url: "/semantics",
    command: (event: MenuItemCommandEvent) => {
      if (!NavigationHelper.goTo("/semantics")) {
        event.originalEvent.preventDefault();
      }
    }
  };

  breadcrumbItems = [
    {
      label: "Settings",
      url: `/semantics/settings`,
      command: (event: MenuItemCommandEvent) => {
        if (!NavigationHelper.goTo(`/semantics/settings`)) {
          event.originalEvent.preventDefault();
        }
      }
    },
  ];

  goToSemantics(): void {
    NavigationHelper.goTo("/semantics");
  }
}

export default TagManagerConfigView;
</script>