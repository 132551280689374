import { DashboardType } from '@/models/dashboard/DashboardType';
import CustomWindow from '../CustomWindow';
import { usePageStore } from '@/stores/page';

declare const window: CustomWindow;

class NavigationHelper {
  goToParams(url: string, params: Record<string, string>): boolean {
    const pageStore = usePageStore();
    if (pageStore.dirty && !window.confirm('Do you really want to leave? You have unsaved changes!')) {
      return false;
    } else {
      pageStore.$reset();
      // Exctract params from url
      if (url.includes("?")) {
        const parts = url.split("?");
        url = parts[0];
        const urlParams = new URLSearchParams(parts[1]);
        for (const [key, value] of urlParams.entries()) {
          if (typeof params[key] === "undefined") {
            params[key] = value;
          }
        }
      }
      window.angularLocation.path(url).search(params);
      window.angularRootScope.$apply();
      return true;
    }
  }

  goTo(url: string): boolean {
    return this.goToParams(url, {});
  }
  
  goToDashboard(dashboardType: DashboardType, id: string | null | undefined): boolean {
    const params: Record<string, string> = {
      pane: dashboardType
    };
    if (id) {
      params["id"] = id;
    }
    return this.goToParams(dashboardType === DashboardType.Shared ? window.location.pathname : "/dashboards", params);
  }

  goToSlide(slide: string | null | undefined): boolean {
    const params: Record<string, string> = {};
    if (slide) {
      params["slide"] = slide;
    }
    return this.goToParams(window.location.pathname, params);
  }

  logout(): void {
    window.angularUserprofile.logout();
    window.angularRootScope.$apply();
  }
}

export default new NavigationHelper();
