<template>
  <div>
    <div>
      <InputText
        type="text"
        v-model="name"
        @keyup.enter="info()"
      />
      <Button label="Info" @click="info()"/>
      <div>{{ result }}</div>
    </div>
    <div>
      <InputText
        type="text"
        v-model="base"
        @keyup.enter="fits()"
      />
      <Button label="Fits" @click="fits()"/>
      <div>{{ resultFits }}</div>
    </div>
    <div>
      <Button label="Show Compatible tags" @click="showCompatibleTags()"/>
      <div>{{ resultCompatible }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import Button from "primevue/button";
import InputText from 'primevue/inputtext';
import { Component, Vue } from "vue-facing-decorator";
import HaystackDefsService from "@/services/HaystackDefsService";

@Component({
  components: {
    Button,
    InputText
  },
})
class TestDefsView extends Vue {
  name = "ahu";
  base = "equip";
  result = "";
  resultFits = "";
  resultCompatible = "";
  
  info(): void {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      const subTypesOf = defs.subTypesOf(this.name);
      const str1 = JSON.stringify(HaystackDefsService.hDictArrayToStringArray(subTypesOf));

      const superTypesOf = defs.superTypesOf(this.name);
      const str2 = JSON.stringify(HaystackDefsService.hDictArrayToStringArray(superTypesOf));

      const implementation = defs.implementation(this.name);
      const str3 = JSON.stringify(HaystackDefsService.hDictArrayToStringArray(implementation));

      const choicesFor = defs.choicesFor(this.name);
      const str4 = JSON.stringify(HaystackDefsService.hDictArrayToStringArray(choicesFor));

      const tags = defs.tags(this.name);
      const str5 = JSON.stringify(HaystackDefsService.hDictArrayToStringArray(tags));

      this.result = `sub: ${str1}   super:${str2}   implementation: ${str3}   choices: ${str4}   tags: ${str5}`;

      // const def = defs.get(this.name);
      // if (def) {
      //   const reflection = defs.reflect(def);
      //   this.result += `   reflection: ${JSON.stringify(HaystackDefsService.hDictArrayToStringArray(reflection.toGrid().getRows()))}}`;
        
      //   const defOfDict = defs.defOfDict(def);
      //   const str5 = JSON.stringify(HaystackDefsService.hDictToString(defOfDict));
      //   this.result += `   defOfDict: ${str5}`;
      // }

      // toImplied - Returns a new dict with any implied tags that don't already exist on the dict.
      // validate  - Validates a dict for a tag.
      // isValid - same with try catch and bool result
      // validateAll - Validates all of the tags on the dict. Any tags that don't exist in the namespcace will be skipped.
      // newDict - Create a new dict with all the default required tags.
      // findConjuncts - Find all the conjuncts for the markers.
    }
  }

  fits(): void {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      const fits = defs.fits(this.name, this.base);
      this.resultFits = `fit: ${fits}`;
    }
  }

  showCompatibleTags(): void {
    const defs = HaystackDefsService.getDefs();
    if (defs) {
      const allTags = defs.defs;
      const result: string[] = [];
      for (const tagName in allTags) {
        if (defs.fits(tagName, this.name)) {
          result.push(tagName);
        }
      }
      this.resultCompatible = `compatible: ${result}`;
    }
  }
}

export default TestDefsView;
</script>