import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { defineStore } from "pinia";
import { SiteDto } from "@/models/organisation/SiteDto";
import { SitesToOrganisationDto } from "@/models/organisation/SitesToOrganisationDto";
import DateHelper from "@/helpers/DateHelper";
import OrganisationFlowsState from "./states/OrganisationFlowsState";
import { FlowEntity } from "@/models/flow/FlowEntity";
import { FlowsToOrganisationDto } from "@/models/organisation/FlowsToOrganisationDto";

export const useOrganisationFlowsStore = defineStore('organisationFlows', {
  state: (): OrganisationFlowsState => ({ 
    data: {},
    updateInProgress: false,
    updateError: false,
    deleteInProgress: false,
    deleteError: false
  }),
  getters: {
  },
  actions: {
    async load(organisationId: number) {
      const organisationIdStr = organisationId.toString();
      try {
        const guid = uuidv4();
        this.data[organisationIdStr] = {
          guid: guid,
          isLoaded: false,
          entities: null,
          isLoadingInProgress: true
        }
        const url = `rest/BitPool_V2/organisations/${organisationId}/flows`;
        const response = await axios.get<FlowEntity[]>(url);
        if (this.data[organisationIdStr].guid === guid) {
          response.data.forEach(x => x.CreatedDate = DateHelper.parseFromMongoDate(x.CreatedDate));
          this.data[organisationIdStr].entities = response.data;
          this.data[organisationIdStr].isLoaded = true;
          this.data[organisationIdStr].isLoadingInProgress = false;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load organisation flows",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.data[organisationIdStr].entities = null;
        this.data[organisationIdStr].isLoaded = true;
        this.data[organisationIdStr].isLoadingInProgress = false;
      }
    },
    async add(body: FlowsToOrganisationDto) {
      const organisationIdStr = body.OrganisationId.toString();
      try {
        this.updateInProgress = true;
        this.updateError = false;
        const url = `rest/BitPool_V2/organisations/flows`;
        const response = await axios.put<FlowEntity[]>(url, body);
        ToastService.showToast("success", "Organisation Site", "Changes saved", 5000);
        this.updateInProgress = false;
        const data = this.data[organisationIdStr];
        if (data?.isLoaded && data?.entities && response.data.length) {
          response.data.forEach(x => x.CreatedDate = DateHelper.parseFromMongoDate(x.CreatedDate));
          data.entities.push(...response.data);
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't save organisation flow",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.updateError = true;
        this.updateInProgress = false;
      }
    },
    async delete(organisationId: number, ids: string[]) {
      const organisationIdStr = organisationId.toString();
      try {
        this.deleteInProgress = true;
        this.deleteError = false;
        const url = `rest/BitPool_V2/organisations/${organisationId}/flows`;
        await axios.delete(url, {
          headers: {
            "Content-Type": "application/json",
          },
          data: ids,
        });
        ToastService.showToast("success", "Organisation", `Changes saved`, 5000);
        this.deleteInProgress = false;
        const data = this.data[organisationIdStr];
        if (data?.isLoaded && data?.entities) {
          for (const id of ids) {
            const index = data.entities.findIndex((x) => x._id === id);
            if (index > -1) {
              data.entities.splice(index, 1);
            }
          }
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          `Can't delete flow`,
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.deleteError = true;
        this.deleteInProgress = false;
      }
    }
  },
})
