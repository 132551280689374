export enum AggregationPeriod {
  Raw = 9,

  Minute = 6,

  Minutes5 = 12,

  Minutes10 = 10,

  Minutes15 = 7,

  Minutes30 = 11,

  Hourly = 0,

  Daily = 1,

  Weekly = 2,

  Monthly = 3,

  Yearly = 4,

  FinancialYearly = 8,

  None = 5,
}
