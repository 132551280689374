<template>
  <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index" :class="updateInProgress ? 'blockui-blocked' : ''">
    <h2 class="account-settings-title hidden xl:block">{{ t('accountSettings.dashboards.title') }}</h2>
    <p class="account-settings-description hidden xl:block">{{ t('accountSettings.dashboards.subTitle') }}</p>

    <section>
      <h3>{{ t('accountSettings.dashboards.dashboardOptions') }}</h3>
      <div class="flex align-items-start dashboards-settings-item pt-1 xl:pt-0">
        <InputSwitch v-model="showWalkthrough" inputId="showWalkthrough" class="vertical-align-top"  @change="settingsChange"/>
        <div class="ml-3 xl:ml-4 dashboards-settings-description">
          <label for="showWalkthrough" class="mb-0">{{ t('accountSettings.dashboards.showWalkthrough') }}</label>
          <p>{{ t('accountSettings.dashboards.showWalkthroughDescription') }}</p>
        </div>
      </div>
      
    </section>
    <section class="xl:mb-0">
      <h3>{{ t('accountSettings.dashboards.chartFunctions') }}</h3>
      <div class="flex align-items-start dashboards-settings-item pt-1 xl:pt-0">
        <InputSwitch v-model="groupTooltips" inputId="groupTooltips" class="vertical-align-top"  @change="settingsChange"/>
        <div class="ml-3 xl:ml-4 dashboards-settings-description">
          <label for="groupTooltips" class="mb-0">{{ t('accountSettings.dashboards.groupTooltips') }}</label>
          <p>{{ t('accountSettings.dashboards.groupTooltipsDescription') }}</p>
        </div>
      </div>
      <div class="flex align-items-start dashboards-settings-item pt-1 xl:pt-0">
        <InputSwitch v-model="disablePlotMarks" inputId="disablePlotMarks" class="vertical-align-top"  @change="settingsChange"/>
        <div class="ml-3 xl:ml-4 dashboards-settings-description">
          <label for="disablePlotMarks" class="mb-0">{{ t('accountSettings.dashboards.disablePlotMarkers') }}</label>
          <p>{{ t('accountSettings.dashboards.disablePlotMarkersDescription') }}</p>
        </div>
      </div>
    </section>

    <div class="block md:flex align-items-center justify-content-end account-settings-button-container">
      <Button 
        @click="closeDialog" 
        :label="t('common.close')" 
        icon="pi pi-times" 
        class="hidden md:inline-flex p-button-text p-button-secondary mr-2"
      />
      <Button 
        :label="t('common.saveChanges')" 
        :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-save'" 
        @click="saveAll"
        class="account-settings-save-button"
        :disabled='updateInProgress || !isSettingsChaged' 
      />
    </div>

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import InputSwitch from 'primevue/inputswitch';
import Dropdown from 'primevue/dropdown';
import AuthState from "@/store/states/AuthState";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import CustomWindow from '@/CustomWindow';
import localeManager from "@/localeManager";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';
import DashboardState from "@/store/states/DashboardState";

declare const window: CustomWindow;

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    Button,
    BlockUI,
    ProgressSpinner,
    InputSwitch,
    Dropdown
  },
})
class UserSettingsDashboardsView extends Vue {
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;
    
  get auth(): AuthState {
    return this.$store.state.auth;
  }
  
  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  isSettingsChaged = false;
  showWalkthrough = true;
  groupTooltips = false;
  disablePlotMarks = false;
  updateInProgress = false;

  created(): void {
    this.init();
  }

  async init(): Promise<void> {
    if (!this.dashboardState.isLoadedSpaceSettings) {
      await this.$store.dispatch("dashboard/loadSpaceSettings");
    }
    if (this.dashboardState.spaceSettings) {
      this.showWalkthrough = this.dashboardState.spaceSettings.showWalkthrough;
      this.groupTooltips = this.dashboardState.spaceSettings.groupTooltips;
      this.disablePlotMarks = this.dashboardState.spaceSettings.disablePlotMarks;
    }
  }

  settingsChange(): void {
    this.isSettingsChaged = true;
  }

  async saveAll(): Promise<boolean> {
    this.updateInProgress = true;
    let isOk = true;
    try {
      // check what need to save and call api
      if (this.isSettingsChaged && this.dashboardState.spaceSettings) {
        // save settings
        this.dashboardState.spaceSettings.showWalkthrough = this.showWalkthrough;
        this.dashboardState.spaceSettings.groupTooltips = this.groupTooltips;
        this.dashboardState.spaceSettings.disablePlotMarks = this.disablePlotMarks;
        await this.$store.dispatch("dashboard/saveSpaceSettings");
        if (this.dashboardState.userSettingsSaveError) {
          isOk = false;
        } else {
          this.isSettingsChaged = false;
        }
      }
      if (isOk) {
        ToastService.showToast("success", "", this.t("common.changesSaved"), 5000);
        if (this.dashboardState.spaceSettings?.showWalkthrough) {
          this.closeDialog();
        }
      }
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        this.t("common.cantSaveChanges"),
        errorMessage,
        5000
      );
    } finally {
      this.updateInProgress = false;
    }
    return isOk
  }

  get locales(): [string, string][] {
    return localeManager.supportedLocales;
  }

  @Emit
  closeDialog(): void {
    // nothing to do, just emit an event
  }
}

export default UserSettingsDashboardsView;
</script>