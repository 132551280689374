<template>
  <div ref="root" v-if="isMounted" class="widget-type-chart widget-type-condition-duration">
    <div v-if="isNoData" class="empty-data-container">
      <WidgetNoDataView :noDataType="noDataType"/>
    </div>
    <div class="progress-spinner-chart h-full flex justify-content-center align-items-center flex-auto" v-else-if="isLodingData">
      <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="4" animationDuration="1s" />
    </div>
    <div class="chart-element condition-duration" v-else>
      <highcharts v-if="redrawChartToggle" ref="chartElement" class="w-full h-full flex-auto" :options="chartOptions"></highcharts>
      <Button 
        v-if="isZoomed" 
        @click="resetZoom" 
        icon="pi pi-search-minus text-xl font-bold" 
        v-tippy="'Reset Zoom'" 
        class="p-button-icon-only p-button-rounded p-button-light chart-zoom-out"/>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator';
import StreamOption from '../../models/dashboard/StreamOption';
import HCPoint from '../../models/HCPoint';
import DateHelper from '../../helpers/DateHelper'
import { Chart } from 'highcharts-vue';
import * as Highcharts from 'highcharts';
import xRangeModule from 'highcharts/modules/xrange';
xRangeModule(Highcharts);
import { nextTick, PropType } from 'vue';
import { SpaceWidgetConfig } from '@/models/dashboard/SpaceWidgetConfig';
import { WidgetConfig } from '@/models/dashboard/WidgetConfig';
import { BasicWidgetSettings } from '@/models/dashboard/BasicWidgetSettings';
import { AdvancedWidgetSettings } from '@/models/dashboard/AdvancedWidgetSettings';
import { WidgetDataSettings } from '@/models/dashboard/WidgetDataSettings';
import DashboardState from '@/store/states/DashboardState';
import { GDRSModel } from '@/models/dashboard/GDRSModel';
import WidgetDataState from '@/store/states/WidgetDataState';
import { Ref, Watch } from 'vue-facing-decorator';
import DataHelper from '@/helpers/DataHelper';
import { Emitter } from 'mitt';
import EventBusHelper from '@/helpers/EventBusHelper';
import { AggregatedDataHighchartsResponse } from '@/models/AggregatedDataHighchartsResponse';
import { AggregatedDataRequest } from '@/models/AggregatedDataRequest';
import ToastService from '@/services/ToastService';
import ProgressSpinner from 'primevue/progressspinner';
import Button from 'primevue/button';
import { WidgetNoDataTypes } from '@/models/enums/WidgetNoDataTypes';
import WidgetNoDataView from './common/WidgetNoDataView.vue';
import ColorHelper from '@/helpers/ColorHelper';

@Component({
  components: {
    highcharts: Chart,
    ProgressSpinner,
    Button,
    WidgetNoDataView
  }
})
class ConditionDurationWidget extends Vue {
  @Prop({ required: true }) widget!: SpaceWidgetConfig;
  @Prop({ required: true }) widgetConfig!: WidgetConfig;

  get bws(): BasicWidgetSettings {
    return this.widgetConfig.widgetOptions.basicWidgetSettings;
  }

  get aws(): AdvancedWidgetSettings | undefined {
    return this.widgetConfig.widgetOptions.advancedWidgetSettings;
  }

  get wds(): WidgetDataSettings | undefined {
    return this.widgetConfig.widgetOptions.widgetDataSettings;
  }

  get dashboardState(): DashboardState {
    return this.$store.state.dashboard;
  }

  get gdrs(): GDRSModel | null {
    return this.dashboardState.gdrs;
  }

  get widgetDataState(): WidgetDataState {
    return this.$store.state.widgetData;
  }

  isLodingData = false;
  isNoData = false;
  noDataType = WidgetNoDataTypes.NoData;
  chartData: AggregatedDataHighchartsResponse[] = [];
  streamColors: string[] = [];
  rules: [string, number, string, number, string][] = [];
  names: string[] = [];
  categories: string[] = [];
  dates: [Date, Date] | null = null;
  series: Highcharts.SeriesOptionsType[] = [];
  requestBody: AggregatedDataRequest | null = null;

  isMounted = false;
  @Ref() readonly root!: HTMLDivElement;
  rootWidth = 150;

  resetZoom(): void {
    const chart = this.getChartElement()
    if (chart && chart.chart) {
      chart.chart.zoomOut();
    }
  }

  isZoomed = false;

  get chartOptions(): Highcharts.Options {
    const categories = this.categories;
    const rules = this.rules;
    const dates = this.dates;
    const series = this.series;

    const halfWidth = this.rootWidth / 2;
    let width = halfWidth < 150 ? halfWidth : 150;
    let maxStrWidth = 0;
    const lowerCaseWidgh = 7;
    const upperCaseWidgh = 10;
    categories.forEach(x => {
      const upperCaseCount = Array.from(x).reduce((acc, char) => {  
        return acc += /\p{Lu}/u.test(char) ? 1 : 0;
      }, 0);
      const xWidth = upperCaseCount * upperCaseWidgh + (x.length - upperCaseCount) * lowerCaseWidgh;
      if (xWidth > maxStrWidth) {
        maxStrWidth = xWidth;
      }
    });
    if (maxStrWidth > 0 && maxStrWidth < width) {
      width = maxStrWidth;
    }
    
    const startDate = this.requestBody?.NullWhenNoData && dates && dates.length > 1 ? (dates[0].getTime() - (dates[0].getTimezoneOffset() * 60000)) : undefined;
    const endDate = this.requestBody?.NullWhenNoData && dates && dates.length > 1 ? (dates[1].getTime() - (dates[1].getTimezoneOffset() * 60000)) : undefined;

    return {
      credits: {
        enabled: false
      },
      title: {
        text: ""
      },
      chart: {
        type: "xrange",
        animation: true,
        zooming: {
          type: 'x'
        },
        style: {
          color: ColorHelper.getDefautTextColor(),
        },
        events: {
          load() {
            window.setTimeout(this.reflow.bind(this)); 
          },
          selection: (event: Highcharts.SelectEventObject): (boolean | undefined) => {
            if (event.resetSelection) {
              this.isZoomed = false;
            } else {
              this.isZoomed = true;
            }
            return true;
          }
        }
      },
      tooltip: {
        formatter: function() {
          // x2 is missing, this is workaround
          const point = this as unknown as HCPoint;
          const pointDateDiff = point.x2 - (typeof this.x === "number" ? this.x : 0);
          const hours = pointDateDiff ? ((pointDateDiff) / (1000 * 60 * 60)).toFixed(2) : "0";
          let datesStr = ''
          if (dates) {
            const diff = dates[1].getTime() - dates[0].getTime();
            const percent = diff ? (100 * pointDateDiff / diff).toFixed(2) : "0"; 
            datesStr = ` - ${percent}% of selected date range`;
          }
          return `<small>${typeof this.x === "number" ? Highcharts.dateFormat('%b %e,%Y %H:%M', this.x) : this.x}-${Highcharts.dateFormat('%b %e,%Y %H:%M', point.x2)}</small><br/><span style="color:${this.color};">\u25CF </span><b style="word-wrap: break-word; white-space: normal;">${categories[this.y ?? 0]}:</b> ${rules[this.y ?? 0][2]}<br/>${hours} hours${datesStr}`;
        },
        useHTML: true,
        outside: true
      },
      plotOptions: {
        series: {
          borderWidth: 0
        }
      },
      xAxis: {
        type: 'datetime',
        min: startDate,
        max: endDate
      },
      yAxis: {
        title: {
          text: ''
        },
        type: 'category',
        categories: categories,
        reversed: true,
        labels: {
          formatter: function () {
            const text = this.value;
            return `<div class="condition-duration-legend" style="width:${width}px; word-wrap: break-word; white-space: normal; text-align: right;" title="${text}">${text}</div>`;
          },
          style: {
            fontSize: "12px",
            width: width
          },
          useHTML: true
        }
      },
      legend: {
        enabled: false
      },
      series: series
    }
  }

  @Watch('widgetConfig', { immediate: false, deep: true })
  onWidgetConfigChanged(): void {
    this.reloadData();
  }

  isGdrsActive = false;

  @Watch('gdrs', { immediate: false, deep: true })
  onGDRSChanged(val: GDRSModel, oldVal: GDRSModel): void {
    // little hack https://github.com/kaorun343/vue-property-decorator/issues/255
    const isActiveChanged = this.isGdrsActive !== val.active;
    if (isActiveChanged) {
      this.isGdrsActive = val.active;
    }
    if (this.aws?.useGDRS && (isActiveChanged || val.active)) {
      this.reloadData();
    }
  }
  
  async reloadData(silent = false, init = false): Promise<void> {
    if (!silent) {
      this.isLodingData = true;
      this.isNoData = false;
    }
    if (this.wds && this.wds.streamOptions && this.wds.streamOptions.length && this.wds.streamOptions.find(x => x.StreamKey)) {
      const requestBody = DataHelper.wdsToApiRequest(this.wds, this.aws?.useGDRS ? this.gdrs : null, this.widgetConfig.widgetType);
      let isReady = false;
      if (init && this.widgetDataState.isLoaded[this.widgetConfig.guid]) {
        const previousRequestBody = this.widgetDataState.requestBody[this.widgetConfig.guid];
        if (previousRequestBody) {
          const requestBodyStr = JSON.stringify(requestBody);
          const now = new Date();
          const diffSeconds = (now.getTime() - previousRequestBody[0].getTime()) / 1000;
          if (diffSeconds < this.reloadDataEverySeconds && requestBodyStr === previousRequestBody[1]) {
            isReady = true;
          }
        }
      }
      if (!isReady) {
        await this.$store.dispatch("widgetData/loadWidgetData", [this.widgetConfig.guid, requestBody]);
      }
      const data = this.widgetDataState.data[this.widgetConfig.guid];
      if (data) {
        this.dataUpdate(data, requestBody);
      } else {
        this.isNoData = true;
        this.noDataType = WidgetNoDataTypes.NoData;
      }
      this.isLodingData = false;
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NotConfigured;
      this.isLodingData = false;
    }
  }

  dataRefreshInterval = 0;

  emitter: Emitter<Record<string, string>> = EventBusHelper.getEmmiter();

  created(): void {
    this.isGdrsActive = !!this.gdrs?.active;
    this.reloadData(false, true);
  }

  reloadDataEverySeconds = 120;

  mounted(): void {
    this.emitter.on("window_size_changed_debounce", this.resizeWidgetEvent);
    this.isMounted = true;
    this.dataRefreshInterval = window.setInterval(() => {
      this.reloadData(true);
    }, this.reloadDataEverySeconds * 1000);
    this.emitter.on("size_changed", this.gridSizeChangedEvent);
    this.updateRootWidth();
  }

  unmounted(): void {
    this.emitter.off("window_size_changed_debounce", this.resizeWidgetEvent);
    if (this.dataRefreshInterval) {
      clearInterval(this.dataRefreshInterval);
      this.dataRefreshInterval = 0;
    }
    this.emitter.off("size_changed", this.gridSizeChangedEvent);
  }

  gridSizeChangedEvent(): void {
    this.resizeWidgetEvent();
  }

  loadConfig(): void {
    if (this.wds &&
      this.wds.streamOptions) {
      const streams = this.wds.streamOptions as StreamOption[];
      this.names = [];
      this.streamColors = [];
      this.rules = [];
      streams.forEach((stream) => {
        const name = stream.Label ? stream.Label : stream.Name;
        this.names.push(name);
        this.streamColors.push(stream.hexStreamColor);
        this.rules.push([stream.durationRule ?? "", stream.durationRuleValue ?? 0, stream.durationRuleText ?? "", stream.durationRuleValue2 ?? 0, stream.durationRuleValueStr ?? ""]);
      });
    }
  }

  dataUpdate(data: AggregatedDataHighchartsResponse[], requestBody: AggregatedDataRequest): void {
    this.chartData = data;
    this.requestBody = requestBody;
    this.loadConfig();
    this.categories = [];
    this.dates = DateHelper.extractDateFromRequestBody(requestBody);
    let i = 0;
    const result: Array<Highcharts.XrangePointOptionsObject> = [];
    this.chartData.forEach((streamData) => {
      if (streamData.Error) {
        ToastService.showToast(
          "error",
          "Error",
          streamData.Error,
          5000
        );
      } else if (streamData.Data && streamData.Data.length) {
        let periodStart: number | null | undefined = null;
        const ruleTuple = this.rules[i];
        if (ruleTuple) {
          const rule = ruleTuple[0];
          const ruleValue = ruleTuple[1];
          const ruleValue2 = ruleTuple[3];
          const ruleValueStr = ruleTuple[4];
          streamData.Data.forEach((record) => {
            let isOk = false;
            switch (rule){
              case ">": {
                isOk = typeof record.y === "number" && record.y > ruleValue;
                break;
              }
              case ">=": {
                isOk = typeof record.y === "number" && record.y >= ruleValue;
                break;
              }
              case "<": {
                isOk = typeof record.y === "number" && record.y < ruleValue;
                break;
              }
              case "<=": {
                isOk = typeof record.y === "number" && record.y <= ruleValue;
                break;
              }
              case "=": {
                if (typeof record.y === "number") {
                  isOk = record.y === ruleValue;
                } else if (typeof record.y === "string") {
                  isOk = record.y === ruleValueStr;
                }
                break;
              }
              case "!=": {
                if (typeof record.y === "number") {
                  isOk = record.y !== ruleValue;
                } else if (typeof record.y === "string") {
                  isOk = record.y !== ruleValueStr;
                }
                break;
              }
              case "Between": {
                isOk = typeof record.y === "number" && record.y > ruleValue && record.y < ruleValue2;
                break;
              }
            }
            if (isOk) {
              if (!periodStart) {
                periodStart = record.x;
              }
            } else {
              if (periodStart) {
                result.push({
                  x: periodStart,
                  x2: record.x,
                  y: i,
                  color: this.streamColors.length > i ? this.streamColors[i] : ''
                });
                periodStart = null;
              }
            }
          });
          if (periodStart) {
            result.push({
              x: periodStart,
              x2: streamData.Data[streamData.Data.length - 1].x,
              y: i,
              color: this.streamColors.length > i ? this.streamColors[i] : ''
            });
          }
        }
        this.categories.push(this.names[i]);
      }
      i++;
    });
    this.series = [{
      dataLabels: {
        enabled: true
      },
      type: 'xrange',
      data: result
    }];
    if (result.length) {
      this.isNoData = false;
      const chartElement = this.getChartElement();
      if (chartElement) {
        chartElement.chart.redraw();
      }
    } else {
      this.isNoData = true;
      this.noDataType = WidgetNoDataTypes.NoData;
    }
  }
  
  getChartElement(): typeof Chart | null {
    if (this.$refs.chartElement) {
      return this.$refs.chartElement as typeof Chart;
    } else {
      return null;
    }
  }

  get widgetSize(): any {
    return this.widget.size;
  }

  get editMode(): any {
    return this.dashboardState.editMode;
  }

  redrawChartToggle = true;

  // chart reflow on widget resize
  @Watch("widgetSize", { immediate: false, deep: true })
  @Watch("editMode", { immediate: false, deep: false })
  async resizeWidgetEvent(): Promise<void> {
    this.redrawChartToggle = false;
    // uncomment in case of updateRootWidth removal
    // await nextTick();
    await this.updateRootWidth();
    this.redrawChartToggle = true;
  }

  async updateRootWidth(): Promise<void> {
    await nextTick();
    this.rootWidth = this.root ? this.root.clientWidth : 150;
  }
}

export default ConditionDurationWidget;
</script>