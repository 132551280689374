<template>
  <section class="organisation-profile-users-members">
    <h3>All Members</h3>
    <div v-if="organisationUsersStore.data[organisationIdStr]">
      <DataTable
        v-model:selection="selectedRecords"
        :value="organisationUsersStore.data[organisationIdStr].entities" 
        dataKey="Id"
        showGridlines 
        responsiveLayout="stack" 
        breakpoint="850px" 
        class="p-datatable-sm default-visual-table responsive-breakpoint default-visual-table-stack-label-width"
        sortField="DateAdded" 
        :sortOrder="-1"
        :paginator="true"
        :rows="20"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown CurrentPageReport JumpToPageDropdown"
        :rowsPerPageOptions="[10, 20, 50]"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
        v-model:filters="filters"
        filterDisplay="menu"
        :globalFilterFields="['UserName']"
        removableSort
      >
        <template #header>
          <div class="table-header">
            <div class="sm:flex sm:align-items-center sm:justify-content-between sm:gap-3">
              <div class="sm:flex-shrink-0">
                <Button 
                  :disabled="!organisationUsersStore.data[organisationIdStr].isLoaded" 
                  label="Add Member" 
                  icon="pi pi-plus-circle" 
                  class="my-1 mr-2" 
                  @click="openCreateDialog"
                />
                <Button
                  label="Delete" 
                  icon="pi pi-trash" 
                  class="my-1 mr-2 p-button-outlined p-button-danger" 
                  @click="openDeleteSelectedRecordsConfirmation()"
                  :disabled="!selectedRecords.length"
                />
              </div>
              <div class="mt-3 sm:mt-0">
                <IconField iconPosition="left" class="w-full sm:w-auto">
                  <InputIcon class="pi pi-search" />
                  <InputText v-model="filters['global'].value" placeholder="Find Member" class="w-full sm:w-auto" />
                </IconField>
              </div>
            </div>
          </div>
        </template>
        <template #empty>
          <div v-if="organisationUsersStore.data[organisationIdStr].isLoaded" class="w-full" style="min-height: 50vh;">
            <span class="inline-block py-2">No data found.</span>
          </div>
          <div class="w-full flex justify-content-center align-items-center flex-auto" style="min-height: 50vh;" v-else>
            <ProgressSpinner class="spinner-primary" style="width: 100px; height: 100px" strokeWidth="4" animationDuration="1s" />
          </div>
        </template>
        <Column selectionMode="multiple" headerStyle="width: 1%; min-width: 3rem;" headerClass="column-with-checkbox" bodyClass="column-with-checkbox"></Column>
        <Column :sortable="true" field="UserName" header="Member Name" headerStyle="min-width: min-content; width: 45%;" headerClass="no-break-word" bodyClass="break-word">
          <template #body="slotProps">
            <div class="organisation-profile-users-members-user-card">
              <Avatar
                v-if="slotProps.data.Avatar"
                :image="getAvatarUrl(slotProps.data.Avatar)"
                shape="circle"
                class="user-img"
              />
              <Avatar
                v-else
                :label="shortName(getFullname(slotProps.data))"
                shape="circle"
                class="user-label"
              />
              <div>
                <div class="organisation-profile-users-members-user-name">{{ getFullname(slotProps.data) }}</div>
                <div class="organisation-profile-users-members-user-email">{{ slotProps.data.UserName }}</div>
              </div>
            </div>
          </template>
        </Column>
        <Column :sortable="true" field="Type" header="Access" headerStyle="width: 1%; min-width: 90px;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.Type === "User" ? "User" : "Admin" }}
            </div>
          </template>
        </Column>
        <Column :sortable="true" field="Groups.length" header="Groups" headerStyle="width: 1%; min-width: 90px;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <div>
              {{ slotProps.data.Groups.length }}
            </div>
          </template>
        </Column>
        <Column :sortable="true" field="DateAdded" header="Date Added" headerStyle="min-width: min-content; width: 15%;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <DateTimezoneView :date="slotProps.data.DateAdded" timezone="local"/>
          </template>
        </Column>
        <Column :sortable="true" field="LastLogin" header="Last Login" headerStyle="min-width: min-content; width: 20%;" headerClass="no-break-word" bodyClass="no-break-word">
          <template #body="slotProps">
            <span class="block with-inline-btn">
              <DateTimezoneView :date="slotProps.data.LastLogin" timezone="local"/>
              <span class="table-cell-icon" v-if="!isActiveUser(slotProps.data)">
                <i class="pi pi-exclamation-triangle text-yellow-500" style="font-size: 1.25rem;" v-tippy="'Inactive member. Logged in more than 2 months ago.'"></i>
              </span>
            </span>
          </template>
        </Column>
        <Column :exportable="false" headerStyle="width: 1%; min-width: 88px;" bodyStyle="text-align: right; justify-content: flex-end;">
          <template #body="slotProps">
            <div>
              <div class="inline-flex">
                <Button 
                  icon="pi pi-pencil" 
                  class="p-button-icon-only p-button-rounded p-button-outlined mr-2 p-button-opposite"
                  @click="openUpdateDialog(slotProps.data)"
                  v-tippy="'Edit'"
                />
                <Button 
                  icon="pi pi-trash" 
                  class="p-button-icon-only p-button-rounded p-button-danger p-button-outlined" 
                  @click="openConfirmation(slotProps.data)" 
                  v-tippy="'Delete'"
                />
              </div>
            </div>
          </template>
        </Column>
      </DataTable>
    </div>
  </section>
  <Dialog :header="editRecord?.Id ? 'Edit Member' : 'Add New Member'" v-model:visible="displayCreateUpdateDialog" :modal="true" :style="{width: '50rem'}" class="organisation-profile-users-members-config-dialog">
    <div class="dialog-content">
      <BlockUI :blocked="organisationUsersStore.updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-fixed-spinner" :class="organisationUsersStore.updateInProgress ? 'blockui-blocked' : ''">
        <div v-if="editRecord">
          <h4 class="organisation-profile-users-members-section-title">Member Details</h4>

          <div class="formgrid grid align-items-end">
            <div class="field col md:mb-4">
              <label for="emailAddress">Email Address</label>
              <div>
                <InputText id="emailAddress" v-model="editRecord.UserName" :disabled="!!editRecord.Id" class="inputfield w-full" />
              </div>
            </div>
            <div class="field col-fixed w-full md:w-auto mb-4 md:pb-1">
              <div class="flex align-items-center">
                <InputSwitch 
                  inputId="editRecordType"
                  v-model="editRecord.Type"
                  trueValue="CoOwner"
                  falseValue="User"
                  class="vertical-align-top"
                />
                <label for="editRecordType" class="mb-0 ml-2">Organisation Admin</label>
              </div>
            </div>
          </div>

          <h4 class="organisation-profile-users-members-section-title">Groups</h4>

          <DataTable 
            :value="editRecordGroups" 
            showGridlines 
            responsiveLayout="stack" 
            breakpoint="600px"
            class="responsive-breakpoint p-datatable-sm organisation-profile-users-members-edit-table"
            v-model:filters="filtersGroups"
            filterDisplay="menu"
            :globalFilterFields="['group.Name']"
          >
            <template #header>
              <div class="table-header">
                <div class="flex justify-content-end">
                  <IconField iconPosition="left" class=" w-full md:w-auto">
                    <InputIcon class="pi pi-search" />
                    <InputText v-model="filtersGroups['global'].value" placeholder="Find Group" class="w-full md:w-auto" />
                  </IconField>
                </div>
              </div>
            </template>
            <Column field="group.Name" header="Name" headerClass="text-sm no-break-word" bodyClass="text-sm" headerStyle="min-width: min-content; width: 50%;" bodyStyle="text-align: left; justify-content: flex-start;">
              <template #body="{ data }">
                {{ data.group.Name }}
              </template>
            </Column>
            <Column field="access" header="Access" headerClass="text-sm no-break-word" bodyClass="text-sm" headerStyle="min-width: min-content; width: 50%;" bodyStyle="align-items: center;">
              <template #body="{ data }">
                <SelectButton 
                  v-model="data.access" 
                  :options="['Admin', 'User', 'None']" 
                  :allowEmpty="false"
                />
              </template>
            </Column>
          </DataTable>
        </div>
        <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
      </BlockUI>
    </div>
    <template #header>
      <div>
        <div class="p-dialog-title">{{ editRecord?.Id ? 'Edit Member' : 'Add New Member' }}</div>
        <div class="p-dialog-subtitle">Please enter the details of your {{ editRecord?.Id ? 'existing' : 'new' }} member.</div>
      </div>
    </template>
    <template #footer>
      <div class="flex flex-wrap sm:flex-nowrap justify-content-end" style="row-gap: .5rem;">
        <span class="block">
          <Button label="Cancel" icon="pi pi-times" @click="closeCreateUpdateDialog" class="p-button-text p-button-secondary"/>
        </span>
        <span class="block ml-2">
          <Button 
            :label="editRecord?.Id ? 'Save' : 'Add'" 
            :icon="organisationUsersStore.updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
            @click="saveRecord" 
            :disabled='organisationUsersStore.updateInProgress || !editRecord || !editRecord.UserName' 
          />
        </span>
      </div>
    </template>
  </Dialog>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import AuthState from "@/store/states/AuthState";
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Dialog from 'primevue/dialog';
import BlockUI from 'primevue/blockui';
import InputSwitch from 'primevue/inputswitch';
import ProgressSpinner from 'primevue/progressspinner';
import Dropdown from 'primevue/dropdown';
import Avatar from "primevue/avatar";
import SelectButton from "primevue/selectbutton";
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import { OrganisationFullDto } from "@/models/OrganisationFullDto";
import { AllUserData } from "@/models/user/AllUserData";
import ConfirmationService from "@/services/ConfirmationService";
import { useOrganisationStore } from "@/stores/organisation";
import DateTimezoneView from "@/components/views/DateTimezoneView.vue";
import { useOrganisationUsersStore } from "@/stores/organisationUsers";
import { UserFullInOrganisationDto } from "@/models/user/UserFullInOrganisationDto";
import moment from "moment";
import { AddUsersToOrganisation } from "@/models/user/AddUsersToOrganisation";
import { OrganisationRoleString } from "@/models/user/OrganisationRoleString";
import { UserInOrganisationAndGroupsDto } from "@/models/user/UserInOrganisationAndGroupsDto";
import { useOrganisationGroupsStore } from "@/stores/organisationGroups";
import { GroupAccessString } from "@/models/user/GroupAccessString";
import { GroupFromDBDto } from "@/models/organisation/GroupFromDBDto";
import { UserFullInOrganisation2Dto } from "@/models/user/UserFullInOrganisation2Dto";

interface GroupWithAccess {
  group: GroupFromDBDto;
  access: GroupAccessString | "None";
}

@Component({
  components: {
    Button,
    InputText,
    DataTable,
    Column,
    Dialog,
    BlockUI,
    InputSwitch,
    ProgressSpinner,
    Dropdown,
    Avatar,
    SelectButton,
    IconField,
    InputIcon,
    DateTimezoneView
  },
  directives: {
  }
})
class OrganisationUsersMembers extends Vue {
  get authState(): AuthState {
    return this.$store.state.auth;
  }
  
  organisationStore = useOrganisationStore();
  organisationUsersStore = useOrganisationUsersStore();
  organisationGroupsStore = useOrganisationGroupsStore();

  filters = {
    'global': {value: null, matchMode: 'contains'}
  };

  get currentOrganisation(): OrganisationFullDto | null {
    return this.organisationStore.currentOrganisation;
  }

  get organisationIdStr(): string {
    return this.currentOrganisation ? this.currentOrganisation.Id.toString() : "";
  }

  get availableOrganisations(): OrganisationFullDto[] {
    let result = this.organisationStore.entities ?? [];
    result = result.filter(x => x.Id !== this.organisationStore.currentOrganisation?.Id);
    return result;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  isActiveUser(user: UserFullInOrganisationDto): boolean {
    const m = moment();
    const date = m.add(-2, "month").toDate();
    return !!(user.LastLogin && typeof user.LastLogin !== "string" && user.LastLogin > date);
  }

  getAvatarUrl(avatar: string): string {
    const apiUrl = this.$store.state.apiUrl;
    return `${apiUrl}/rest/AWS_S3_V1/File/${avatar}`;
  }

  shortName(fullname: string | null): string {
    return fullname ? fullname.split(" ").map(x => x[0]).join("") : "";
  }

  getFullname(user: UserFullInOrganisationDto): string {
    let result = user.UserName.split('@')[0];
    if (user.Firstname || user.Lastname) {
      result = user.Firstname ? user.Firstname : "";
      if (user.Lastname) {
        result = result.length ? `${result} ${user.Lastname}` : user.Lastname;
      }
    }
    return result;
  }

  // #region add/update
  displayCreateUpdateDialog = false;
  editRecord: UserInOrganisationAndGroupsDto | null = null;
  editRecordGroups: GroupWithAccess[] = [];
  filtersGroups = {
    'global': {value: null, matchMode: 'contains'}
  };

  get organisationGroups(): GroupFromDBDto[] {
    return this.organisationGroupsStore.data[this.organisationIdStr].entities?.sort((a, b) => {
      return Number(b.Default) - Number(a.Default) || a.Name.localeCompare(b.Name);
    }) ?? [];
  }

  openCreateDialog(): void {
    if (this.currentOrganisation) {
      this.editRecordGroups = this.organisationGroups.map(x => { return { group: x, access: x.Default ? GroupAccessString.User : "None"}});
      this.editRecord = {
        Groups: [],
        OrganisationId: this.currentOrganisation.Id,
        Type: OrganisationRoleString.User,
        UserName: "",
        Id: ""
      };
      this.displayCreateUpdateDialog = true;
    }
  }

  openUpdateDialog(record: UserFullInOrganisation2Dto): void {
    if (this.currentOrganisation) {
      this.editRecordGroups = this.organisationGroups.map(x => { return { group: x, access: record.Groups.find(y => y.GroupId === x.Id)?.Access ?? "None"}});
      this.editRecord = {
        Groups: record.Groups,
        OrganisationId: record.OrganisationId,
        Type: record.Type,
        UserName: record.UserName,
        Id: record.Id
      }
      this.displayCreateUpdateDialog = true;
    }
  }

  closeCreateUpdateDialog(): void {
    this.displayCreateUpdateDialog = false;
  }

  async saveRecord(): Promise<void> {
    if (this.currentOrganisation && this.editRecord) {
      this.editRecord.Groups = this.editRecordGroups.filter(x => x.access !== "None").map(x => { return { GroupId: x.group.Id, Access: x.access as GroupAccessString } });
      const addUsersToOrganisationBody: AddUsersToOrganisation = {
        Message: "",
        Users: [this.editRecord]
      };
      await this.organisationUsersStore.save(this.currentOrganisation.Id, addUsersToOrganisationBody);
      if (!this.organisationUsersStore.updateError) {
        this.closeCreateUpdateDialog();
      }
    }
  }
  // #endregion add/update

  // #region delete
  selectedRecord: UserFullInOrganisationDto | null = null;

  openConfirmation(record: UserFullInOrganisationDto | null): void {
    this.selectedRecord = record;
    const message = `Are you sure you want to delete member?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Member',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteRecord();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  deleteRecord(): void {
    if (this.selectedRecord && this.currentOrganisation) {
      this.organisationUsersStore.delete(this.currentOrganisation.Id, [this.selectedRecord]);
    }
  }

  selectedRecords: UserFullInOrganisationDto[] = [];

  openDeleteSelectedRecordsConfirmation(): void {
    const message = `Are you sure you want to delete selected members?`;
    ConfirmationService.showConfirmation({
      message: message,
      header: 'Delete Members',
      icon: 'pi pi-exclamation-triangle text-4xl text-red-500',
      acceptIcon: 'pi pi-check',
      rejectIcon: 'pi pi-times',
      rejectClass: 'p-button-secondary p-button-text',
      accept: () => {
        // callback to execute when user confirms the action
        this.deleteSelectedRecords();
      },
      reject: () => {
        // callback to execute when user rejects the action
      }
    });
  }

  async deleteSelectedRecords(): Promise<void> {
    if (this.selectedRecords.length && this.currentOrganisation) {
      await this.organisationUsersStore.delete(this.currentOrganisation.Id, this.selectedRecords);
      if (!this.organisationUsersStore.deleteError) {
        this.selectedRecords = [];
      }
    }
  }
  // #endregion delete
}

export default OrganisationUsersMembers;
</script>