<template>
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 25 25"><path fill="none" d="M17.907 11.544h-4.451V7.093a.956.956 0 1 0-1.912 0v4.451H7.093a.915.915 0 0 0-.956.956.925.925 0 0 0 .956.956h4.45v4.451a.925.925 0 0 0 .957.956.952.952 0 0 0 .956-.956v-4.451h4.45a.956.956 0 0 0 0-1.912Z"/><path fill="none" d="M12.5 1.683A10.813 10.813 0 1 1 4.85 4.85a10.746 10.746 0 0 1 7.65-3.167M12.5 0a12.5 12.5 0 1 0 0 25 12.5 12.5 0 0 0 0-25Z"/></svg>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";

@Component
class PlusCircleSvg extends Vue {
}

export default PlusCircleSvg;
</script>