<template>
  <div>
    <Button :disabled="spaceCommentsState.inProgressReaction || spaceCommentsState.inProgressDeleteReaction" @click="addReaction('dislike')" icon="pi pi-thumbs-down" class="p-button-rounded" :label="reactionDownCounter" :class="myReaction?.Reaction === 'dislike' ? 'dashboard-comments-reaction-button-active' : ''"/>
    <Button :disabled="spaceCommentsState.inProgressReaction || spaceCommentsState.inProgressDeleteReaction" @click="addReaction('like')" icon="pi pi-thumbs-up" class="p-button-rounded" :label="reactionUpCounter" :class="myReaction?.Reaction === 'like' ? 'dashboard-comments-reaction-button-active' : ''"/>
  </div>
</template>

<script lang="ts">
import SpaceCommentsState from "@/store/states/SpaceCommentsState";
import { Component, Prop, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import { AllUserData } from "@/models/user/AllUserData";
import { SpaceCommentsReactionEntity } from "@/models/dashboard/SpaceCommentsReactionEntity";

@Component({
  components: {
    Button
  },
})
class DashboardCommentsReactionView extends Vue {
  @Prop({ required: true }) dashboardId!: string;
  @Prop({ required: true }) commentId!: string;

  get allUserData() : AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }

  get spaceCommentsState(): SpaceCommentsState {
    return this.$store.state.spaceComments;
  }

  get reactionUpCounter(): string {
    let result = 0;
    this.spaceCommentsState.dataReaction.forEach(reaction => {
      if (reaction.CommentId === this.commentId) {
        switch (reaction.Reaction) {
          case "like":
            result++;
            break;
        }
      }
    });
    return result.toString();
  }
  
  get reactionDownCounter(): string {
    let result = 0;
    this.spaceCommentsState.dataReaction.forEach(reaction => {
      if (reaction.CommentId === this.commentId) {
        switch (reaction.Reaction) {
          case "dislike":
            result++;
            break;
        }
      }
    });
    return result.toString();
  }

  get myReaction(): SpaceCommentsReactionEntity | null {
    const reaction = this.spaceCommentsState
      .dataReaction
      .find(x => x.Username === this.allUserData.userName && x.CommentId === this.commentId);
    return reaction ?? null;
  }

  async addReaction(reaction: string): Promise<void> {
    const oldReaction = this.myReaction;
    if (oldReaction?.Reaction === reaction) {
      await this.$store.dispatch("spaceComments/deleteReaction", oldReaction.Id);
    } 
    else 
    {
      const body: SpaceCommentsReactionEntity = oldReaction ?
        oldReaction :
        {
          Id: "",
          Username: this.allUserData.userName,
          SpaceId: this.dashboardId,
          CommentId: this.commentId,
          Reaction: reaction
        };
      body.Reaction = reaction;
      await this.$store.dispatch("spaceComments/reaction", body);
    }
  }
}

export default DashboardCommentsReactionView;
</script>
