import { defineStore } from "pinia";
import AISpeechState from "./states/AISpeechState";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import { SpeechAIRequest } from "@/models/bitpool-ai/SpeechAIRequest";
import axios from "axios";
import { Howl, Howler } from 'howler';
import { v4 as uuidv4 } from "uuid";

export const useAISpeechStore = defineStore('AISpeech', {
  state: (): AISpeechState => ({ 
    speechSynthesisIsEnabled: false,
    isGloballyDisabled: false,
    guid: "",
    prerecordedSpeech: {},
    codec: "",
    isPlaying: false
  }),
  getters: {
  },
  actions: {
    toggleSpeechSynthesis() {
      this.speechSynthesisIsEnabled = !this.speechSynthesisIsEnabled;
      if (!this.speechSynthesisIsEnabled) {
        this.stopSpeak();
      }
    },
    initCodec(): void {
      if (!this.codec) {
        const codecs = [
          "opus",
          "aac"
        ]
        let result = "mp3";
        for (const codec of codecs) {
          if (Howler.codecs(codec)) {
            result = codec;
            break;
          }
        }
        this.codec = result;
      }
    },
    stopSpeak(): void {
      this.guid = "stop";
      Howler.stop();
    },
    async speakHtml(html: string, persona: string | undefined = undefined): Promise<void> {
      const body = new DOMParser().parseFromString(html, 'text/html').body;
      // remove higcharts block by "ai-embed-highcharts" class
      const nodes = body.getElementsByClassName("ai-embed-highcharts");
      for (let i = 0; i < nodes.length; i++) {
        nodes[i].parentNode?.removeChild(nodes[i]);
      }
      const text = body.textContent || "";
      await this.speak(text, persona);
    },
    async speak(text: string, persona: string | undefined = undefined): Promise<void> {
      this.stopSpeak();
      if (!this.isGloballyDisabled && this.speechSynthesisIsEnabled && text) {
        try {
          const guid = uuidv4();
          this.guid = guid;
          this.initCodec();
          const body: SpeechAIRequest = {
            message: text,
            persona: persona,
            format: this.codec
          };
          const contentType = `audio/${this.codec}`;
          const url = `rest/AI_V1/speech`;
          const response = await axios.post(url, body, { responseType: "arraybuffer" });
          if (this.guid === guid) {
            const blob = new Blob([response.data], { type: contentType });
            const blobUrl = URL.createObjectURL(blob);
            let disposed = false;
            this.isPlaying = true;
            const howl = new Howl({
              src: [blobUrl],
              format: this.codec,
              autoplay: true,
              // onload: () => {
              //   console.log('Loaded!');
              // },
              onend: () => {
                // console.log('Finished!');
                if (!disposed) {
                  this.isPlaying = false;
                  disposed = true;
                  howl.unload();
                  URL.revokeObjectURL(blobUrl);
                }
              },
              onstop: () => {
                // console.log('Stopped!');
                if (!disposed) {
                  this.isPlaying = false;
                  disposed = true;
                  howl.unload();
                  URL.revokeObjectURL(blobUrl);
                }
              },
            });
          }
        } catch (error) {
          ToastService.showToast(
            "error",
            "Can't generate speech",
            ErrorHelper.handleAxiosError(error).message,
            5000
          );
        }
      }
    },
    async speakPreRecordedHello(persona: string | undefined = undefined): Promise<void> {
      await this.speakPreRecorded("0", persona);
    },
    async speakPreRecordedWait(persona: string | undefined = undefined): Promise<void> {
      await this.speakPreRecorded("1", persona);
    },
    async speakPreRecorded(type: string, persona: string | undefined = undefined): Promise<void> {
      this.stopSpeak();
      if (!this.isGloballyDisabled && this.speechSynthesisIsEnabled && type) {
        try {
          this.initCodec();
          const key = `${persona}-${type}`;
          if (!this.prerecordedSpeech[key]) {
            const contentType = `audio/${this.codec}`;
            const url = `rest/AI_V1/speech/${persona}/${type}/${this.codec}`;
            const response = await axios.get(url, { responseType: "arraybuffer" });
            const blob = new Blob([response.data], { type: contentType });
            const blobUrl = URL.createObjectURL(blob);
            this.prerecordedSpeech[key] = blobUrl;
          }
          const blobUrl = this.prerecordedSpeech[key];
          let disposed = false;
          this.isPlaying = true;
          const howl = new Howl({
            src: [blobUrl],
            format: this.codec,
            autoplay: true,
            // onload: () => {
            //   console.log('Loaded!');
            // },
            onend: () => {
              // console.log('Finished!');
              if (!disposed) {
                this.isPlaying = false;
                disposed = true;
                howl.unload();
                // don't need to do it here, because we want to reuse the blobUrl
                // URL.revokeObjectURL(blobUrl);
              }
            },
            onstop: () => {
              // console.log('Stopped!');
              if (!disposed) {
                this.isPlaying = false;
                disposed = true;
                howl.unload();
                // don't need to do it here, because we want to reuse the blobUrl
                // URL.revokeObjectURL(blobUrl);
              }
            },
          });
        } catch (error) {
          ToastService.showToast(
            "error",
            "Can't load records",
            ErrorHelper.handleAxiosError(error).message,
            5000
          );
        }
      }
    }
  },
});
