<template>
  <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index" :class="updateInProgress ? 'blockui-blocked' : ''">
    <h2 class="account-settings-title hidden xl:block">{{ t('accountSettings.password.title') }}</h2>
    <p class="account-settings-description hidden xl:block">{{ t('accountSettings.password.subTitle') }}</p>

    <section class="xl:mb-0">
      <h3>{{ t('accountSettings.password.changePassword') }}</h3>
      <div class="formgrid grid">
        <div class="field col-12 md:col-8 xl:col-7">
          <label for="userOldPassword">{{ t('accountSettings.password.currentPassword') }}</label>
          <div>
            <Password
              class="w-full"
              id="userOldPassword"
              inputClass="inputfield w-full"
              v-model="oldPassword"
              :feedback="false"
              toggleMask
            />
          </div>
        </div>
        <div class="field col-12 md:col-8 xl:col-7">
          <label for="userPassword">{{ t('accountSettings.password.newPassword') }}</label>
          <div>      
            <Password
              class="w-full"
              id="userPassword"
              inputClass="inputfield w-full"
              v-model="password"
              toggleMask
            />
          </div>
        </div>
        <div class="field col-12 md:col-8 xl:col-7 mb-0"> 
          <label for="userConfirmPassword">{{ t('accountSettings.password.confirmPassword') }}</label>
          <div>        
            <Password
              class="w-full"
              :class="{ 'p-invalid': password2 && password !== password2 }"
              id="userConfirmPassword"
              inputClass="inputfield w-full"
              v-model="password2"
              :feedback="false"
              toggleMask
            />
          </div>
        </div>
      </div>
    </section>
    <div class="block md:flex align-items-center justify-content-end account-settings-button-container">
      <Button 
        @click="closeDialog" 
        :label="t('common.close')" 
        icon="pi pi-times" 
        class="hidden md:inline-flex p-button-text p-button-secondary mr-2"
      />
      <Button 
        :label="t('accountSettings.password.resetPassword')" 
        :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-check'" 
        @click="saveAll" 
        class="account-settings-save-button"
        :disabled='updateInProgress || !isFormFilled' 
      />
    </div>

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import Password from 'primevue/password';
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import AuthState from "@/store/states/AuthState";
import { AllUserData } from "@/models/user/AllUserData";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    Password,
    Button,
    BlockUI,
    ProgressSpinner
  },
})
class UserSettingsPasswordView extends Vue {
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  get auth(): AuthState {
    return this.$store.state.auth;
  }

  get allUserData(): AllUserData {
    return this.$store.getters["auth/getAllUserData"];
  }
  
  oldPassword = "";
  password = "";
  password2 = "";
  updateInProgress = false;

  get isFormFilled(): boolean {
    if (this.oldPassword &&
      this.password && 
      this.password2 &&
      this.password === this.password2) {
      return true;
    } else {
      return false;
    }
  }

  async saveAll(): Promise<boolean> {
    if ((this.password || this.password2 || this.oldPassword) && !this.isFormFilled) {
      ToastService.showToast(
        "error",
        this.t("common.password"),
        this.password === this.password2 ? this.t("accountSettings.password.fillPasswordFields") : this.t("accountSettings.password.passwordDoesntMatch"),
        5000
      );
    } else {
      this.updateInProgress = true;
      let isOk = true;
      try {
        // check what need to save and call api
        if (this.isFormFilled) {
          // change password
          await this.$store.dispatch("auth/changePassword", {
            Username: this.allUserData.userName, 
            NewPassword: this.password,
            OldPassword: this.oldPassword
          });
          if (this.auth.changePasswordError) {
            isOk = false;
          } else {
            this.password = "";
            this.password2 = "";
            this.oldPassword = "";
          }
        }
        if (isOk) {
          ToastService.showToast("success", "", this.t("common.changesSaved"), 5000);
        }
      } catch (error) {
        const errorMessage = ErrorHelper.handleAxiosError(error).message;
        ToastService.showToast(
          "error",
          this.t("common.cantSaveChanges"),
          errorMessage,
          5000
        );
      } finally {
        this.updateInProgress = false;
      }
      return isOk;
    }
    return false;
  }

  @Emit
  closeDialog(): void {
    // nothing to do, just emit an event
  }
}

export default UserSettingsPasswordView;
</script>