<template>
  <div class="default-view-container">
    <h1 class="mb-0">Nube iO</h1>
    <div v-if="authState.permissions?.FullAccess" class="default-view increase-padding-bottom mt-4 lg:mt-5">
      <header class="default-view-header">
        <h2>Nube iO Configurations</h2>
      </header>
      <div class="default-view-body">
        <NubeIOClientView></NubeIOClientView>
      </div>
    </div>
    <div v-else class="default-view">
      <Message severity="error" :closable="false">Not enough rights</Message>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-facing-decorator";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import Message from 'primevue/message';
import NubeIOClientView from './NubeIOClientView.vue';
import AuthState from "@/store/states/AuthState";

@Component({
  components: {
    TabView,
    TabPanel,
    Message,
    NubeIOClientView
  },
})
class NubeIOView extends Vue { 
  get authState(): AuthState {
    return this.$store.state.auth;
  }
}

export default NubeIOView;
</script>