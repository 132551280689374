<template>
  <BlockUI :blocked="updateInProgress" :autoZIndex="false" :baseZIndex="100"  class="blockui-with-spinner blockui-with-overlay-z-index" :class="updateInProgress ? 'blockui-blocked' : ''">
    <h2 class="account-settings-title hidden xl:block">{{ t('accountSettings.bitpoolAI.title') }}</h2>
    <p class="account-settings-description hidden xl:block">{{ t('accountSettings.bitpoolAI.subTitle') }}</p>

    <section :class="aiSpeechStore.isGloballyDisabled ? 'xl:mb-0' : ''">
      <h3>{{ t('accountSettings.bitpoolAI.bitpoolAISkill') }}</h3>
      <div class="flex flex-wrap row-gap-3 md:column-gap-5 xl:column-gap-6 xxl:column-gap-7 align-items-center ai-available-persona">
        <div v-for="persona in availablePersonas" :key="persona.Id" class="field w-full md:w-auto flex-shrink-0 pt-1 xxl:pt-0 mb-0">
          <div class="flex align-items-center">
            <RadioButton v-model="selectedPersona" :inputId="`persona-${persona.Id}`" name="persona" :value="persona.Id" @change="uiSettingsChange" class="vertical-align-top" />
            <label :for="`persona-${persona.Id}`" class="ml-2 mb-0">{{ persona.Name }}</label>
          </div>
        </div>
      </div>
      
      <div class="mt-3" v-if="selectedPersona === 'personal'">
        <Textarea 
          v-model="customPersona"
          style="white-space: pre-wrap;" 
          :autoResize="true" 
          :rows="5"
          :placeholder="t('accountSettings.bitpoolAI.personalPlaceholder')"
          class="w-full" 
          @change="uiSettingsChange"
        />
      </div>
    </section>
    <section class="xl:mb-0" v-if="!aiSpeechStore.isGloballyDisabled">
      <h3>{{ t('accountSettings.bitpoolAI.options') }}</h3>
      <div>
        <div class="flex align-items-center">
          <InputSwitch 
            v-model="isEnabledSpeech" 
            inputId="isEnabledSpeech" 
            class="vertical-align-top"
            @change="uiSettingsChange"
          />
          <label for="isEnabledSpeech" class="mb-0 ml-2">{{ t('accountSettings.bitpoolAI.enableSpeech') }}</label>
        </div>
      </div>
    </section>
    <div class="block md:flex align-items-center justify-content-end account-settings-button-container">
      <Button 
        @click="closeDialog" 
        :label="t('common.close')" 
        icon="pi pi-times" 
        class="hidden md:inline-flex p-button-text p-button-secondary mr-2"
      />
      <Button 
        :label="t('common.saveChanges')"
        :icon="updateInProgress ? 'pi pi-spin pi-spinner' : 'pi pi-save'" 
        @click="saveAll"
        class="account-settings-save-button"
        :disabled='updateInProgress || !isUISettingsChaged' 
      />
    </div>

    <ProgressSpinner class="spinner-primary" style="width: 60px; height: 60px" strokeWidth="3" animationDuration="1s" />
  </BlockUI>
</template>

<script lang="ts">
import { Component, Emit, Vue } from "vue-facing-decorator";
import Button from 'primevue/button';
import BlockUI from 'primevue/blockui';
import ProgressSpinner from 'primevue/progressspinner';
import RadioButton from 'primevue/radiobutton';
import Textarea from "primevue/textarea";
import InputSwitch from "primevue/inputswitch";
import AuthState from "@/store/states/AuthState";
import ToastService from "@/services/ToastService";
import ErrorHelper from "@/helpers/ErrorHelper";
import { useOpenAIChatStore } from "@/stores/openAIChat";
import { useAIPersonaStore } from "@/stores/aiPersona";
import { AIPersonaEntity } from "@/models/bitpool-ai/AIPersonaEntity";
import { useAISpeechStore } from "@/stores/aiSpeech";
import { ComposerTranslation, useI18n } from "vue-i18n";
import type { MessageSchema } from '@/localeManager';

@Component({
  setup() {
    const { t } = useI18n<{ message: MessageSchema}>({
      useScope: 'global',
      inheritLocale: true
    })
    return { t };
  },
  components: {
    Button,
    BlockUI,
    ProgressSpinner,
    RadioButton,
    Textarea,
    InputSwitch
  },
})
class UserSettingsAIView extends Vue {
  // locales from i18n
  t!: ComposerTranslation<MessageSchema>;

  get auth(): AuthState {
    return this.$store.state.auth;
  }

  isUISettingsChaged = false;
  selectedPersona = "";
  customPersona = "";
  isEnabledSpeech = false;
  updateInProgress = false;

  openAIChatStore = useOpenAIChatStore();
  aiPersonaStore = useAIPersonaStore();
  aiSpeechStore = useAISpeechStore();

  created(): void {
    this.selectedPersona = this.auth.userSettings?.selectedPersona ?? "";
    if (!this.selectedPersona) {
      const defaultPersona: AIPersonaEntity | undefined = this.aiPersonaStore.entities ? this.aiPersonaStore.entities.find(x => x.Enabled && x.Default) : undefined;
      this.selectedPersona = defaultPersona ? defaultPersona.Id : ""; 
    }
    this.customPersona = this.auth.userSettings?.customPersona ?? "";
    this.isEnabledSpeech = this.auth.userSettings?.isEnabledSpeech ?? false;
  }

  get availablePersonas(): AIPersonaEntity[] {
    const result: AIPersonaEntity[] = this.aiPersonaStore.entities ? this.aiPersonaStore.entities.filter(x => x.Enabled) : [];
    result.push({ 
      Id: "personal", 
      Name: "Personal",
      Model: "",
      Personality: "",
      Enabled: true,
      Default: false,
      Created: new Date(),
      Updated: new Date(),
      CreatedBy: "",
      UpdatedBy: "",
      Questions: [],
      Voice: "",
      Endpoint: "openai-chat"
    });
    return result;
  }

  uiSettingsChange(): void {
    this.isUISettingsChaged = true;
  }

  async saveAll(): Promise<boolean> {
    this.updateInProgress = true;
    let isOk = true;
    try {
      // check what need to save and call api
      if (this.isUISettingsChaged && this.auth.userSettings) {
        // save ai settings
        this.auth.userSettings.selectedPersona = this.selectedPersona;
        this.auth.userSettings.customPersona = this.customPersona;
        this.auth.userSettings.isEnabledSpeech = this.isEnabledSpeech;
        await this.$store.dispatch("auth/saveUserSettings");
        if (this.auth.userSettingsSaveError) {
          isOk = false;
        } else {
          this.isUISettingsChaged = false;
        }
      }
      if (isOk) {
        ToastService.showToast("success", "", this.t("common.changesSaved"), 5000);
      }
    } catch (error) {
      const errorMessage = ErrorHelper.handleAxiosError(error).message;
      ToastService.showToast(
        "error",
        this.t("common.cantSaveChanges"),
        errorMessage,
        5000
      );
    } finally {
      this.updateInProgress = false;
    }
    return isOk
  }

  @Emit
  closeDialog(): void {
    // nothing to do, just emit an event
  }
}

export default UserSettingsAIView;
</script>