<template>
  <div class="col-12 hd:col-8 semantics-edit-coord">
    <div class="formgrid grid row-gap-2 flex-auto">
      <div class="col-12 md:col-6">
        <label :for="inputId + '-latitude'">Latitude</label>
        <InputNumber
          :inputId="inputId + '-latitude'"
          class="inputfield w-full"
          type="text"
          v-model="coordLatitudeVal"
          :minFractionDigits="2"
          :maxFractionDigits="20"
          :disabled="disabledFields.includes(field)"
        />
      </div>
      <div class="col-12 md:col-6">
        <label :for="inputId + '-longitude'">Longitude</label>
        <InputNumber
          :inputId="inputId + '-longitude'"
          class="inputfield w-full"
          type="text"
          v-model="coordLongitudeVal"
          :minFractionDigits="2"
          :maxFractionDigits="20"
          :disabled="disabledFields.includes(field)"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import InputNumber from 'primevue/inputnumber';
import { Component, Model, Prop, Vue } from "vue-facing-decorator";
import { HaysonCoord, HaysonDict, HaysonVal, Kind } from "haystack-core";

@Component({
  components: {
    InputNumber
  },
})
class HaystackCoordEditView extends Vue {
  @Model haysonDictModel!: HaysonDict;
  @Prop field!: string;
  @Prop inputId!: string;
  @Prop def!: string;
  @Prop({ default: [] }) disabledFields!: string[];

  get valueHayson(): HaysonVal {
    if (this.haysonDictModel && this.field) {
      return this.haysonDictModel[this.field] as HaysonVal;
    }
    return {};
  }

  set valueHayson(value: HaysonVal) {
    if (this.haysonDictModel && this.field) {
      this.haysonDictModel[this.field] = value;
    }
  }

  // #region Coord
  // geographic coordinate in latitude/longitude
  get coordLatitudeVal(): number {
    if (this.valueHayson) {
      return (this.valueHayson as HaysonCoord).lat;
    }
    return 0;
  }

  set coordLatitudeVal(value: number) {
    this.valueHayson = {
      _kind: Kind.Coord,
      lat: value,
      lng: this.coordLongitudeVal
    };
  }

  get coordLongitudeVal(): number {
    if (this.valueHayson) {
      return (this.valueHayson as HaysonCoord).lng;
    }
    return 0;
  }

  set coordLongitudeVal(value: number) {
    this.valueHayson = {
      _kind: Kind.Coord,
      lat: this.coordLatitudeVal,
      lng: value
    };
  }
  // #endregion Coord
}

export default HaystackCoordEditView;
</script>