import { defineStore } from "pinia";
import ErrorHelper from "@/helpers/ErrorHelper";
import ToastService from "@/services/ToastService";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { SpaceBackupEntity } from "@/models/dashboard/SpaceBackupEntity";
import DateHelper from "@/helpers/DateHelper";
import { nextTick } from "vue";
import CustomWindow from "../CustomWindow";
import SpaceBackupState from "./states/SpaceBackupState";

declare const window: CustomWindow;

function strToDate(model: SpaceBackupEntity): void {
  if (typeof model.DateCreated === "string") {
    model.DateCreated = DateHelper.parseFromMicrosoftString(model.DateCreated);
  }
}

export const useSpaceBackupStore = defineStore("spaceBackup", {
  state: (): SpaceBackupState => ({
    isLoadedOrganisation: false,
    dataOrganisation: [] as SpaceBackupEntity[],
    guidOrganisation: "",

    isLoadedPersonal: false,
    dataPersonal: [] as SpaceBackupEntity[],
    guidPersonal: "",

    inProgressCreateBackupOrganisation: false,
    inProgressCreateBackupPersonal: false,
    inProgressDeleteBackupOrganisation: false,
    inProgressDeleteBackupPersonal: false,
    inProgressRestoreBackupOrganisation: false,
    inProgressRestoreBackupPersonal: false,
  }),

  actions: {
    async loadOrganisation() {
      try {
        const guid = uuidv4();
        this.guidOrganisation = guid;
        this.isLoadedOrganisation = false;
        const url = `rest/BitPool_V2/os/space-backup/organisation`;
        const response = await axios.get<SpaceBackupEntity[]>(url);
        if (this.guidOrganisation === guid) {
          response.data.forEach(element => {
            strToDate(element);
          });
          this.dataOrganisation = response.data;
          this.isLoadedOrganisation = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load organisation backups",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.dataOrganisation = [];
        this.isLoadedOrganisation = true;
      }
    },
    async loadPersonal() {
      try {
        const guid = uuidv4();
        this.guidPersonal = guid;
        this.isLoadedPersonal = false;
        const url = `rest/BitPool_V2/os/space-backup/personal`;
        const response = await axios.get<SpaceBackupEntity[]>(url);
        if (this.guidPersonal === guid) {
          response.data.forEach(element => {
            strToDate(element);
          });
          this.dataPersonal = response.data;
          this.isLoadedPersonal = true;
        }
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't load personal backups",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.dataPersonal = [];
        this.isLoadedPersonal = true;
      }
    },
    async createBackupOrganisation() {
      try {
        this.inProgressCreateBackupOrganisation = true;
        const url = `rest/BitPool_V2/os/space-backup/organisation/create`;
        const response = await axios.post<SpaceBackupEntity>(url, null);
        strToDate(response.data);
        this.dataOrganisation.splice(0, 0, response.data);
        this.inProgressCreateBackupOrganisation = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't create dashboard backup",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressCreateBackupOrganisation = false;
      }
    },
    async createBackupPersonal() {
      try {
        this.inProgressCreateBackupPersonal = true;
        const url = `rest/BitPool_V2/os/space-backup/personal/create`;
        const response = await axios.post<SpaceBackupEntity>(url, null);
        strToDate(response.data);
        this.dataPersonal.splice(0, 0, response.data);
        this.inProgressCreateBackupPersonal = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't create dashboard backup",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressCreateBackupPersonal = false;
      }
    },
    async deleteBackupOrganisation(id: string) {
      try {
        this.inProgressDeleteBackupOrganisation = true;
        const url = `rest/BitPool_V2/os/space-backup/organisation/${id}/delete`;
        await axios.delete(url);
        for (let i = this.dataOrganisation.length - 1; i >= 0; --i) {
          if (this.dataOrganisation[i].Id === id) {
            this.dataOrganisation.splice(i, 1);
            break;
          }
        }
        this.inProgressDeleteBackupOrganisation = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete dashboard backup",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressDeleteBackupOrganisation = false;
      }
    },
    async deleteBackupPersonal(id: string) {
      try {
        this.inProgressDeleteBackupPersonal = true;
        const url = `rest/BitPool_V2/os/space-backup/personal/${id}/delete`;
        await axios.delete(url);
        for (let i = this.dataPersonal.length - 1; i >= 0; --i) {
          if (this.dataPersonal[i].Id === id) {
            this.dataPersonal.splice(i, 1);
            break;
          }
        }
        this.inProgressDeleteBackupPersonal = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't delete dashboard backup",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressDeleteBackupPersonal = false;
      }
    },
    async restoreBackupOrganisation(id: string) {
      try {
        this.inProgressRestoreBackupOrganisation = true;
        const url = `rest/BitPool_V2/os/space-backup/organisation/${id}/restore`;
        await axios.post(url, null);
        this.inProgressRestoreBackupOrganisation = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
        window.vuexStore?.commit("auth/setOrganisationChangingInProgress", true, { root: true });
        await nextTick();
        window.vuexStore?.commit("auth/setOrganisationChangingInProgress", false, { root: true });
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't restore dashboard backup",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressRestoreBackupOrganisation = false;
      }
    },
    async restoreBackupPersonal(id: string) {
      try {
        this.inProgressRestoreBackupPersonal = true;
        const url = `rest/BitPool_V2/os/space-backup/personal/${id}/restore`;
        await axios.post(url, null);
        this.inProgressRestoreBackupPersonal = false;
        ToastService.showToast("success", "", "Changes saved", 5000);
        window.vuexStore?.commit("auth/setOrganisationChangingInProgress", true, { root: true });
        await nextTick();
        window.vuexStore?.commit("auth/setOrganisationChangingInProgress", false, { root: true });
      } catch (error) {
        ToastService.showToast(
          "error",
          "Can't restore dashboard backup",
          ErrorHelper.handleAxiosError(error).message,
          5000
        );
        this.inProgressRestoreBackupPersonal = false;
      }
    }
  },
});