<template>
  <div>
    <div v-if="noDataType === widgetNoDataTypes.NoData || noDataType === widgetNoDataTypes.NoDataSites" class="no-data">
      <NoDataSvg/>
    </div>
    <div v-else-if="noDataType === widgetNoDataTypes.NotConfigured || noDataType === widgetNoDataTypes.NotConfiguredSites" class="no-config">
      <NotConfiguredSvg/>
    </div>
    <div class="empty-data-text">
      <div>
        <h4>{{actualTitle}}</h4>
        <p>{{actualMessage}}</p>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { WidgetNoDataTypes } from "@/models/enums/WidgetNoDataTypes";
import { Component, Prop, Vue } from "vue-facing-decorator";
import NoDataSvg from "@/components/svg/NoDataSvg.vue";
import NotConfiguredSvg from "@/components/svg/NotConfiguredSvg.vue";

@Component({
  components: {
    NoDataSvg,
    NotConfiguredSvg
  },
})
class WidgetNoDataView extends Vue {
  @Prop({ required: true }) noDataType!: WidgetNoDataTypes;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) message!: string;

  widgetNoDataTypes = WidgetNoDataTypes;

  get actualTitle(): string {
    if (this.title) {
      return this.title;
    } else {
      if (this.noDataType === WidgetNoDataTypes.NoData || this.noDataType === WidgetNoDataTypes.NoDataSites) {
        return "No Data!";
      } else {
        // NotConfigured
        return "Not Configured!";
      }
    }
  }

  get actualMessage(): string {
    if (this.message) {
      return this.message;
    } else {
      if (this.noDataType === WidgetNoDataTypes.NoData) {
        return "No data on selected date range.";
      } else if (this.noDataType === WidgetNoDataTypes.NotConfigured) {
        return "Streams have not been selected.";
      } else if (this.noDataType === WidgetNoDataTypes.NoDataSites) {
        return "No sites found";
      } else if (this.noDataType === WidgetNoDataTypes.NotConfiguredSites) {
        return "Sites have not been selected.";
      } else {
        return "";
      }
    }
  }
}

export default WidgetNoDataView;
</script>